import {
  addDays,
  compareAsc,
  createCurrentDate,
  formatFullDate,
  isBefore,
  isFuture,
} from '@expane/date'
import { CARD_TYPES, CardType, ServerTransactionByIdType, SubscriptionInfo } from '@expane/data'

export type Card = {
  id: number
  activatedAt?: Date | null
  canceledDate?: Date | null
  cardPeriod: number
  subscriptionInfo: SubscriptionInfo
}
// Возвращает активные абонементы у которых действительный срок
// и есть доступные услуги для использования
export const getActiveSubscriptions = <T extends Card>(clientCards: T[], timezone: string) => {
  const clientCardsWithValidDate = clientCards.filter(
    ({ activatedAt, cardPeriod, canceledDate }) => {
      if (!activatedAt && !canceledDate) return true
      if (cardPeriod === 0 && !canceledDate) return true // if card doesn't have period, no need to check activation date
      if (
        activatedAt &&
        createCurrentDate(timezone) < addDays(activatedAt, cardPeriod) &&
        canceledDate === null
      )
        return true
      return false
    },
  )

  return clientCardsWithValidDate.filter(clientCard => {
    return checkIsFreeServicesInSubscription(clientCard)
  })
}

// Есть ли у абонемента не использованные услуги
export const checkIsFreeServicesInSubscription = <T extends Card>(clientSubscription: T) =>
  Object.values(getSubscriptionInfo(clientSubscription)).some(
    ({ used, quantity }) => used < quantity,
  )

// Сколько и каких услуг по абонементу было использовано
export const getSubscriptionInfo = <T extends Card>(clientSubscription: T): SubscriptionInfo =>
  clientSubscription.subscriptionInfo

// так как мы можем выбрать любую дату активации то нам нужно проверять - или активация в будущем или нет
export const checkIsCardActivated = (activatedAt: Date | undefined | null): boolean => {
  if (!activatedAt) return false
  else return !isFuture(activatedAt)
}

export const sortGiftCardByNotActivated = <
  T extends { canceledDate: Date | null; activatedAt: Date | null },
>(
  giftCards: T[] | undefined,
) => {
  return giftCards?.sort((a, b) => {
    // Check if both cards are either activated or cancelled
    const aNotActivatedNotCancelled = a.canceledDate === null && a.activatedAt === null
    const bNotActivatedNotCancelled = b.canceledDate === null && b.activatedAt === null

    if (aNotActivatedNotCancelled && !bNotActivatedNotCancelled) {
      return -1
    } else if (!aNotActivatedNotCancelled && bNotActivatedNotCancelled) {
      return 1
    } else {
      // If both are the same in terms of activation and cancellation, their order doesn't matter
      return 0
    }
  })
}

export const getClientGiftCards = (clientCards: CardType[] | undefined) =>
  clientCards?.filter(clientCard => clientCard.type === CARD_TYPES.giftCard)

export const getClientSubscriptions = (clientCards: CardType[] | undefined) =>
  clientCards?.filter(clientCard => clientCard.type === CARD_TYPES.subscription)

export const getSortedSubscriptions = (
  clientSubscriptions: CardType[],
  timezone: string | undefined,
): CardType[] => {
  const notActiveCards = sortCardByCardEnding(
    clientSubscriptions.filter(c => c.activatedAt === undefined && c.canceledDate === null),
  )
  const activeCards = sortCardByCardEnding(
    clientSubscriptions.filter(
      c =>
        c.activatedAt &&
        createCurrentDate(timezone) < addDays(c.activatedAt, c.cardPeriod) &&
        checkIsFreeServicesInSubscription(c) &&
        c.canceledDate === null,
    ),
  )

  const refundedCards = sortCardByCardEnding(
    clientSubscriptions.filter(c => c.canceledDate !== null),
  )

  const expiredCards = sortCardByCardEnding(
    clientSubscriptions.filter(
      c =>
        c.activatedAt &&
        createCurrentDate(timezone) > addDays(c.activatedAt, c.cardPeriod) &&
        checkIsFreeServicesInSubscription(c),
    ),
  )

  // функция определяет те абонементы, которые не входят в активинованные, не активированные и просроченные.
  // то есть это те которые закончились
  const endedCards = sortCardByCardEnding(
    clientSubscriptions.filter(
      ({ id: allCardsId }) =>
        ![...notActiveCards, ...activeCards, ...expiredCards, ...refundedCards].some(
          ({ id: endedCardsId }) => allCardsId === endedCardsId,
        ),
    ),
  )

  return [...notActiveCards, ...activeCards, ...expiredCards, ...endedCards, ...refundedCards]
}

const sortCardByCardEnding = (clientSubscriptions: CardType[]) =>
  clientSubscriptions.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const firstCardEnding = addDays(a.activatedAt!, a.cardPeriod)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const secondCardEnding = addDays(b.activatedAt!, b.cardPeriod)

    return compareAsc(firstCardEnding, secondCardEnding)
  })

export const getStatusSubscription = ({
  canceledDate,
  activatedAt,
  cardPeriod,
  isFreeServicesInSubscription,
  timezone,
}: {
  activatedAt: Date | null | undefined
  canceledDate: Date | null | undefined
  cardPeriod: number
  isFreeServicesInSubscription: boolean
  timezone: string | undefined
}) => {
  if (!isFreeServicesInSubscription) return SUBSCRIPTION_STATUS.ended
  if (canceledDate) return SUBSCRIPTION_STATUS.refunded
  if (!checkIsCardActivated(activatedAt)) return SUBSCRIPTION_STATUS.notActivated

  if (getIsCardEndless(cardPeriod) && canceledDate == null) return SUBSCRIPTION_STATUS.activated // card with endless period

  if (
    activatedAt &&
    createCurrentDate(timezone) < addDays(activatedAt, cardPeriod) &&
    canceledDate === null
  )
    return SUBSCRIPTION_STATUS.activated
  return SUBSCRIPTION_STATUS.expired
}

export const SUBSCRIPTION_STATUS = {
  notActivated: 'subscriptionStatus.notActivated',
  activated: 'subscriptionStatus.activated',
  ended: 'subscriptionStatus.ended',
  expired: 'subscriptionStatus.expired',
  refunded: 'subscriptionStatus.refunded',
}

const ENDLESS_CARD_PERIOD = 0

export const getIsCardEndless = (cardPeriod: number) => cardPeriod === ENDLESS_CARD_PERIOD

export const getActivateSubscriptionWarningMessage = (
  activatedAt: Date | undefined | null,
  timezone: string,
) => {
  let message = ''

  if (activatedAt) {
    if (isBefore(createCurrentDate(timezone), activatedAt))
      message = 'subscriptionsOptions.dateOfActivationIsAlreadySelected'
    else message = 'subscriptionsOptions.isActivated'
  }

  return message
}

export const getIsGiftCardValidForActivation = ({
  createdAt,
  cardPeriod,
  canceledDate,
  activatedAt,
  timezone,
}: {
  createdAt: Date
  cardPeriod: number
  activatedAt: Date | null
  canceledDate: Date | null
  timezone?: string
}) => {
  const today = createCurrentDate(timezone)
  const expiredDate = addDays(createdAt, cardPeriod)

  const isValidByDate = getIsCardEndless(cardPeriod) ? true : isBefore(today, expiredDate)

  return isValidByDate && canceledDate === null && activatedAt === null
}

export const getCardExpiredDate = ({
  createdAt,
  cardPeriod,
}: {
  createdAt: Date
  cardPeriod: number
}) => {
  return createdAt ? addDays(createdAt, cardPeriod) : undefined
}

// для того чтоби узнать пренадлежит ли сейчас абонемент тому кто его купил, нам нужно посмотреть на транзакцию оплаты
// используется для того чтобы понять можем ли мы вернуть абонемент - WEB
export const getIsSubscriptionReRegisteredByTransactionId = (
  transactionById: ServerTransactionByIdType | undefined,
  clientId: number | undefined,
) => transactionById?.client?.id !== clientId

// используется на MOBILE
export const getIsSubscriptionReRegisteredByClientSubscriptions = (
  clientSubscriptions: CardType[],
  subscriptionId: number,
) => !clientSubscriptions.some(subscription => subscription.id === subscriptionId)

export const getColorBySubscriptionStatus = (cardStatus: string): string => {
  let cardStatusStyle = ''

  if (cardStatus === SUBSCRIPTION_STATUS.ended || cardStatus === SUBSCRIPTION_STATUS.refunded)
    cardStatusStyle = ' text-error-600'
  if (cardStatus === SUBSCRIPTION_STATUS.expired) cardStatusStyle = ' text-yellow-500'
  if (cardStatus === SUBSCRIPTION_STATUS.activated) cardStatusStyle = ' text-primary-600'

  return cardStatusStyle
}

export const getValidUntilGiftCardText = <
  T extends {
    createdAt: Date
    canceledDate: Date | null
    activatedAt: Date | null
    cardPeriod: number
  },
>(
  giftCard: T,
) => {
  if (giftCard.canceledDate && !giftCard.activatedAt) return 'returned'
  else if (giftCard.activatedAt) {
    return '-'
  } else {
    if (getIsCardEndless(giftCard.cardPeriod)) return 'unlimitedTime'
    else {
      const expiredDate = getCardExpiredDate({
        createdAt: giftCard.createdAt,
        cardPeriod: giftCard.cardPeriod,
      })

      return expiredDate ? formatFullDate(expiredDate) : '-'
    }
  }
}

type CardWithCardTemplate = Card & {
  cardTemplate: {
    cardTemplateServices: {
      service: { id: number }
    }[]
  }
}

export const getAreServiceIdsInActiveSubscriptions = <T extends CardWithCardTemplate>(
  clientCards: T[],
  serviceIds: number[],
  timezone: string,
): boolean => {
  const activeSubscriptions = getActiveSubscriptions(clientCards, timezone)

  return activeSubscriptions.some(subscription =>
    subscription.cardTemplate.cardTemplateServices.some(service =>
      serviceIds.includes(service.service.id),
    ),
  )
}
