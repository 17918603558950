import { gql } from 'graphql-request'
import { queryClient, request, useMutation, useQuery, reportError, reportGqlError } from '../../api'
import {
  BOOKING_HISTORY_QUERY_KEY,
  BOOKINGS_BY_IDS_QUERY_KEY,
  BOOKINGS_QUERY_KEY,
} from '../booking/queryKeys'
import { UNITED_BOOKING_QUERY_KEY } from '../unitedBooking/queryKeys'
import { BookingInsertInput, UpdateBookingType } from '../booking'
import { bookingByIdFragment } from '../booking/booking.fragments'
import { invalidateBookings, parseDatesInBookingGqlResponse } from '../booking/logic'
import { NotGroupBooking } from '../booking/queries'
import { TRANSACTIONS_QUERY_KEY } from '../transaction/queryKeys'
import { DEFAULT_TIMEZONE } from '@expane/date'

export function useCreateUnitedBooking(options?: { customOnSuccessFunc?: () => void }) {
  return useMutation(
    async (
      bookingInsertInput: Array<BookingInsertInput>,
    ): Promise<
      | { insertUnitedBooking: { id: number; bookings: Array<{ id: number }> | undefined } }
      | undefined
    > => {
      return request(
        gql`
          mutation ($bookingInsertInput: [booking_insert_input!]!) {
            insertUnitedBooking(object: { bookings: { data: $bookingInsertInput } }) {
              bookings {
                id
              }
              id
            }
          }
        `,
        {
          bookingInsertInput,
        },
      )
    },
    {
      onSuccess: (response, data) => {
        invalidateBookings({
          // у всех мультисервис букингов должен быть один день
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          date: data[0].startDate!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          branchId: data[0].branchId!,
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKING_HISTORY_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(UNITED_BOOKING_QUERY_KEY),
        })
        options?.customOnSuccessFunc?.()
      },
      onError: reportGqlError,
    },
  )
}

export function useFetchUnitedBookingById(
  id: number | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
  showCanceled: boolean,
) {
  // в мультисервисах могут быть только букинги типа NotGroupBooking
  return useQuery<{ id: number; bookings: Array<NotGroupBooking> } | undefined>(
    [UNITED_BOOKING_QUERY_KEY, BOOKINGS_QUERY_KEY, TRANSACTIONS_QUERY_KEY, id, { branchId }],
    async () => {
      const whereCondition = showCanceled ? '' : ', where: { canceledDate: { _is_null: true } }'

      const queryString = `
        ${bookingByIdFragment}
        query ($id: Int!, $branchId: Int!) {
          unitedBookingById(id: $id) {
            id
            bookings(order_by: { id: asc }${whereCondition}) {
              ...bookingByIdType
            }
          }
        }
      `

      const result = await request(
        gql`
          ${queryString}
        `,
        { id, branchId },
      )

      if (result?.unitedBookingById) {
        return {
          ...result.unitedBookingById,
          bookings: result.unitedBookingById.bookings.map(booking =>
            parseDatesInBookingGqlResponse(booking, timezone ?? DEFAULT_TIMEZONE),
          ),
        }
      }
      reportError(new Error('unitedBookingById does not exist'), 'warning', {
        id,
        result,
      })
      return undefined
    },
    {
      queryName: 'useFetchUnitedBookingById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useUpdateBookings() {
  return useMutation(
    async (
      bookings: Array<UpdateBookingType>,
    ): Promise<{
      [key: string]: { affected_rows: number } | { id: number }
    }> => {
      const mutationVariables = getRequestMutationVariables(bookings)
      const requestBody = getRequestBody(bookings)

      const variables = getVariables(bookings)

      return request(
        gql`
          mutation (${mutationVariables}) {
            ${requestBody}
          }
        `,
        variables,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKING_HISTORY_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(UNITED_BOOKING_QUERY_KEY),
        })
        // возможно могут возникать проблемы с производительностью
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKINGS_BY_IDS_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}

const getRequestMutationVariables = (bookings: Array<UpdateBookingType>) =>
  bookings
    .map(
      booking => `$id${booking.id}: Int!
            $bookingSetInput${booking.id}: booking_set_input!
            $bookingServiceInsertInput${booking.id}: [bookingService_insert_input!]!
            $includeBookingServices${booking.id}: Boolean!
            $bookingServicesToBeRemovedIds${booking.id}: [Int]!
            $includeDeletingBookingServices${booking.id}: Boolean!
            $bookingProductInsertInput${booking.id}: [bookingProduct_insert_input!]!
            $includeBookingProduct${booking.id}: Boolean!
            $bookingProductsToBeRemovedIds${booking.id}: [Int]!
            $includeDeletingBookingProducts${booking.id}: Boolean!`,
    )
    .join('')
const getRequestBody = (bookings: Array<UpdateBookingType>) =>
  bookings
    .map(
      booking => `dbs${booking.id}: deleteBookingServices(where: { id: { _in: $bookingServicesToBeRemovedIds${booking.id} } })
              @include(if: $includeDeletingBookingServices${booking.id}) {
              affected_rows
            }
            ibs${booking.id}: insertBookingServices(objects: $bookingServiceInsertInput${booking.id})
              @include(if: $includeBookingServices${booking.id}) {
              affected_rows
            }
             dbp${booking.id}: deleteBookingProducts(where: { id: { _in: $bookingProductsToBeRemovedIds${booking.id} } })
              @include(if: $includeDeletingBookingProducts${booking.id}) {
              affected_rows
            }
            ibp${booking.id}: insertBookingProducts(
              objects: $bookingProductInsertInput${booking.id}  
              on_conflict: { update_columns: quantity, constraint: bookingProduct_pkey }
            ) @include(if: $includeBookingProduct${booking.id}) {
              affected_rows
            }
            ubID${booking.id}: updateBookingById(pk_columns: { id: $id${booking.id} }, _set: $bookingSetInput${booking.id}) {
              id
            }`,
    )
    .join('')
const getVariables = (bookings: Array<UpdateBookingType>) =>
  bookings
    .map(booking => ({
      [`id${booking.id}`]: booking.id,
      [`bookingSetInput${booking.id}`]: booking.bookingSetInput,
      // service
      [`bookingServiceInsertInput${booking.id}`]: booking.bookingServiceInsertInput ?? [],
      [`bookingServicesToBeRemovedIds${booking.id}`]: booking.bookingServicesToBeRemovedIds ?? [],
      [`includeBookingServices${booking.id}`]: Boolean(booking.bookingServiceInsertInput),
      [`includeDeletingBookingServices${booking.id}`]: Boolean(
        booking.bookingServicesToBeRemovedIds,
      ),
      // product
      [`bookingProductInsertInput${booking.id}`]: booking.bookingProductInsertInput ?? [],
      [`bookingProductsToBeRemovedIds${booking.id}`]: booking.bookingProductsToBeRemovedIds ?? [],
      [`includeBookingProduct${booking.id}`]: Boolean(booking.bookingProductInsertInput),
      [`includeDeletingBookingProducts${booking.id}`]: Boolean(
        booking.bookingProductsToBeRemovedIds,
      ),
    }))
    // трансформируем в объект переменных
    .reduce((obj, item) => Object.assign(obj, item), {})

export function useUpdateGroupBookings() {
  return useMutation(
    async (
      bookings: Array<UpdateBookingType>,
    ): Promise<{
      [key: string]: { affected_rows: number } | { id: number }
    }> => {
      const mutationVariables = getGroupBookingRequestMutationVariables(bookings)
      const requestBody = getGroupBookingRequestBody(bookings)

      const variables = getGroupBookingVariables(bookings)

      return request(
        gql`
          mutation (${mutationVariables}) {
            ${requestBody}
          }
        `,
        variables,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKING_HISTORY_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(UNITED_BOOKING_QUERY_KEY),
        })
        // возможно могут возникать проблемы с производительностью
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKINGS_BY_IDS_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}

const getGroupBookingRequestMutationVariables = (bookings: Array<UpdateBookingType>) =>
  bookings
    .map(
      booking => `$id${booking.id}: Int!
            $bookingSetInput${booking.id}: booking_set_input!
            $bookingClientInsertInput${booking.id}: [bookingClient_insert_input!]!
            $includeBookingClient${booking.id}: Boolean!
            $bookingClientsToBeRemovedIds${booking.id}: [Int!]
            $includeDeletingBookingClients${booking.id}: Boolean!
          `,
    )
    .join('')

const getGroupBookingRequestBody = (bookings: Array<UpdateBookingType>) =>
  bookings
    .map(
      booking => `
        dbp${booking.id}: deleteBookingClients(where: { id: { _in: $bookingClientsToBeRemovedIds${booking.id} } })
          @include(if: $includeDeletingBookingClients${booking.id}) {
          affected_rows
        }
        ibp${booking.id}: insertBookingClients(
          objects: $bookingClientInsertInput${booking.id}
          on_conflict: { constraint: unique_booking_client_pair, update_columns: [] }
        ) @include(if: $includeBookingClient${booking.id}) {
          affected_rows
        }
        ubID${booking.id}: updateBookingById(pk_columns: { id: $id${booking.id} }, _set: $bookingSetInput${booking.id}) {
          id
        }`,
    )
    .join('')

const getGroupBookingVariables = (bookings: Array<UpdateBookingType>) =>
  bookings.reduce(
    (acc, booking) => ({
      ...acc,
      [`id${booking.id}`]: booking.id,
      [`bookingSetInput${booking.id}`]: booking.bookingSetInput,
      [`bookingClientInsertInput${booking.id}`]: booking.bookingClientInsertInput,
      [`bookingClientsToBeRemovedIds${booking.id}`]: booking.bookingClientsToBeRemovedIds,
      [`includeBookingClient${booking.id}`]: Boolean(booking.bookingClientInsertInput),
      [`includeDeletingBookingClients${booking.id}`]: Boolean(booking.bookingClientsToBeRemovedIds),
    }),
    {},
  )
