import {
  ServerTransactionBriefWithBookingLocationType,
  ServerTransactionToCalcBalanceType,
  ServerTransactionType,
  TRANSACTION_TYPES,
} from '@expane/data'
import {
  getTransactionsWithAllTypesRevenueOnAccount,
  getTransactionsWithBookingsWithoutRefunds,
} from 'logic/finances/filters'
import { calcValues, roundValue } from '@expane/logic/utils'

// подсчёт общей суммы денег на всех счетах
export const calcTotalBalanceOnAllAccounts = (
  transactions: ServerTransactionToCalcBalanceType[],
) => {
  const result = { toAccountTotalValue: 0, fromAccountTotalValue: 0, totalValue: 0 }

  // исключаются транзакции перемещений между счетами
  const toAccount = transactions.filter(
    t => t.toAccountId !== null && t.type !== TRANSACTION_TYPES.operation.id,
  )
  const fromAccount = transactions.filter(
    t => t.fromAccountId !== null && t.type !== TRANSACTION_TYPES.operation.id,
  )

  result.toAccountTotalValue = toAccount ? calcValues(toAccount) : 0
  result.fromAccountTotalValue = fromAccount ? calcValues(fromAccount) : 0
  result.totalValue = result.toAccountTotalValue - result.fromAccountTotalValue

  return result
}

// подсчет денег на счету клиента: сюда должны прийти транзакции того клиента чей баланс мы считаем
export const calcClientBalance = <
  T extends {
    type: number
    typeVariation?: number | null
    amount: number
    cardId?: number | null
  },
>(
  transactionsByClient: T[],
): number => {
  return transactionsByClient.reduce((balance, transaction) => {
    if (
      transaction.type === TRANSACTION_TYPES.deposit.id ||
      transaction.type === TRANSACTION_TYPES.activateGiftCard.id ||
      (transaction.type === TRANSACTION_TYPES.refund.id &&
        (transaction.typeVariation ===
          TRANSACTION_TYPES.refund.variations.refundToClientAccount.id ||
          transaction.typeVariation ===
            TRANSACTION_TYPES.refund.variations.refundPaymentInCredit.id)) ||
      (transaction.type === TRANSACTION_TYPES.repaymentDeposit.id &&
        transaction.typeVariation !==
          TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByClientBalance.id)
    )
      return roundValue(balance + transaction.amount)
    if (
      ((transaction.type === TRANSACTION_TYPES.payment.id ||
        transaction.type === TRANSACTION_TYPES.paymentInCredit.id) &&
        transaction.cardId === null) ||
      transaction.type === TRANSACTION_TYPES.cashOut.id
    )
      return roundValue(balance - transaction.amount)

    return roundValue(balance)
  }, 0)
}

export const calcAllRevenueOnAccount = (id: number, transactions?: ServerTransactionType[]) => {
  const allTypesRevenueOnAccount = getTransactionsWithAllTypesRevenueOnAccount(id, transactions)

  return allTypesRevenueOnAccount ? calcValues(allTypesRevenueOnAccount) : 0
}

export const calcBookingsPayments = (
  transactions?: ServerTransactionType[] | ServerTransactionBriefWithBookingLocationType[],
): { bookingsTotalValue: number; bookingsQuantity: number } => {
  const result = {
    bookingsTotalValue: 0,
    bookingsQuantity: 0,
  }

  const bookingsPayments = transactions
    ? getTransactionsWithBookingsWithoutRefunds(transactions)
    : []

  result.bookingsQuantity = bookingsPayments.length
  result.bookingsTotalValue = calcValues(bookingsPayments)

  return result
}
