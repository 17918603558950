import {
  ServerEmployeeAllBranchesGroupType,
  ServerInterbranchServiceByIdType,
  ServerLocationAllBranchesBriefType,
} from '@expane/data'
import { getIsGroupService } from '@expane/logic/service'
import { TableBody, TableContainer, TableHeader, TableHeaderCell } from '@expane/ui'
import { FC } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InterbranchServiceByBranchRow } from 'widgets/InterbranchService/InterbranchServiceLogic/InterbranchServiceByBranchRow'
import {
  InterbranchServiceFormControl,
  InterbranchServiceFormGetFieldState,
  InterbranchServiceFormSetValue,
  InterbranchServiceFormTrigger,
} from '../logic'

interface InterbranchServiceByBranchListProps {
  control: InterbranchServiceFormControl
  interbranchServiceById: ServerInterbranchServiceByIdType | undefined
  disabled: boolean
  employeeGroups: ServerEmployeeAllBranchesGroupType[]
  formGetFieldState: InterbranchServiceFormGetFieldState
  formTrigger: InterbranchServiceFormTrigger
  locations: ServerLocationAllBranchesBriefType[]
  setValue: InterbranchServiceFormSetValue
}

export const InterbranchServiceByBranchList: FC<InterbranchServiceByBranchListProps> = ({
  control,
  interbranchServiceById,
  disabled,
  employeeGroups,
  formGetFieldState,
  formTrigger,
  locations,
  setValue,
}) => {
  const { t } = useTranslation()
  const { fields } = useFieldArray({ control, name: 'branchServices' })

  const watchedType = useWatch({ control, name: 'type' })
  const isGroup = getIsGroupService(watchedType)

  return (
    <div className="h-48">
      <TableContainer className="max-h-48">
        <TableHeader>
          <tr>
            <TableHeaderCell className="w-58">{t('branch.name')}</TableHeaderCell>
            <TableHeaderCell className="w-60">
              {t('locations.name')} <span className="text-error-600">*</span>
            </TableHeaderCell>
            <TableHeaderCell className="w-60">
              {t('employee.name')} {isGroup && <span className="text-error-600">*</span>}
            </TableHeaderCell>
            <TableHeaderCell className="w-60">{t('consumables')}</TableHeaderCell>
          </tr>
        </TableHeader>

        <TableBody>
          {fields?.map((branchService, index) => (
            <InterbranchServiceByBranchRow
              key={branchService.branchId}
              interbranchServiceById={interbranchServiceById}
              branchService={branchService}
              control={control}
              disabled={disabled}
              index={index}
              locations={locations}
              employeeGroups={employeeGroups}
              isGroup={isGroup}
              formTrigger={formTrigger}
              formGetFieldState={formGetFieldState}
              setValue={setValue}
            />
          ))}
        </TableBody>
      </TableContainer>
    </div>
  )
}
