import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline, IoHelpOutline } from 'react-icons/io5'
import { useFetchCurrentBranchTimezone, useFetchLastBillingInfo } from '@expane/data'
import { useFetchPlans } from '@expane/data/src/gql/plan'
import { store } from 'store'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import {
  checkIfIsActiveBillingPlan,
  checkIfIsBillingInfo,
  findCurrentBillingPlan,
} from '@expane/logic/billing'

export const CurrentPlanInfo: FC = () => {
  const { t } = useTranslation()

  const formatDate = useDateFormatting()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { data: plans } = useFetchPlans()
  const { data: billingInfo } = useFetchLastBillingInfo(timezone)

  const isThereABillingInfo = checkIfIsBillingInfo(billingInfo)
  const isActive = checkIfIsActiveBillingPlan(billingInfo)

  const currentPlan = findCurrentBillingPlan(plans, billingInfo)

  const className = getContainerClassName(isActive)

  return (
    <div className={className}>
      {isThereABillingInfo && (
        <>
          <IoCalendarOutline
            size="3.5rem"
            className={'mr-2' + (isActive ? ' text-billing-main-color' : '')}
          />

          <div>
            <p className={'font-medium text-lg' + (isActive ? ' text-billing-main-color' : '')}>
              {t(isActive ? 'planInfo.current' : 'planInfo.last')} {t('plan.' + currentPlan?.name)}
            </p>
            <p className="text-billing-secondary-color">
              {t(isActive ? 'planInfo.endOn' : 'planInfo.ended')}{' '}
              {billingInfo && formatDate('date', billingInfo.toDate)}
            </p>
          </div>
        </>
      )}

      {!isThereABillingInfo && (
        <>
          <IoHelpOutline size="4rem" />
          <div>
            <p className="font-medium text-lg">{t('planInfo.noPlan')}</p>
            <p>{t('planInfo.pickOne')}</p>
          </div>
        </>
      )}
    </div>
  )
}

const getContainerClassName = (isActive: boolean) => {
  let className = 'flex items-center border-2 rounded-lg p-4 mb-4'

  className += isActive
    ? ' border-primary-400 bg-accent'
    : ' border-gray-400 border-dashed bg-gray-50 dark:bg-gray-700 text-label-color'

  return className
}
