import { ServerEmployeeBriefType, ServerEmployeeGroupBriefsType } from '@expane/data'
import { transformPersonName } from '@expane/logic/utils'
import { ChooseButton, CloseButton, Dialog, Modal, Paper } from '@expane/ui'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders, transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { onChangeMultiTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { useOpenCreateEmployeesSchedulesDialog } from './CreateEmployeesSchedulesDialog'

interface ImportEmployeesDialogLogicProps extends Pick<DialogProps, 'closeDialog'> {
  employees: ServerEmployeeBriefType[]
  allEmployees: ServerEmployeeBriefType[]
  employeeGroups: ServerEmployeeGroupBriefsType[]
}

export const BranchImportEmployeesDialogLogic: FC<ImportEmployeesDialogLogicProps> = ({
  employees,
  allEmployees,
  employeeGroups,
  closeDialog,
}) => {
  const { t } = useTranslation()

  const { createEmployeesSchedulesDialog, openCreateEmployeesSchedulesDialog } =
    useOpenCreateEmployeesSchedulesDialog()

  const [selectedItems, onSelectedItems] = useState<TreeMenuItem[]>([])

  const treeItems = useMemo(() => {
    const employeesInBranchIds: number[] = []
    for (const employee of employees) {
      employeesInBranchIds.push(employee.id)
    }

    const filteredEmployees: { id: number; name: string; groupId?: number | null }[] = []

    for (const employee of allEmployees) {
      if (!employeesInBranchIds.includes(employee.id)) {
        filteredEmployees.push({
          id: employee.id,
          name: transformPersonName(employee),
          groupId: employee.groupId,
        })
      }
    }

    return transformDataForTreeMenu(employeeGroups, filteredEmployees, {
      keepEmptyFolders: false,
    })
  }, [allEmployees, employees, employeeGroups])

  return (
    <Modal close={closeDialog} animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>{t('importEmployees.select')}</Dialog.Title>
        <Dialog.Body className="h-120 flex flex-col">
          <Paper className="h-full overflow-y-scroll flex-1 py-4 px-2">
            <TreeMenu
              type="MultiPickMode"
              items={treeItems}
              selected={selectedItems}
              onSelected={item =>
                onSelectedItems(onChangeMultiTreeMenu(item, selectedItems, treeItems))
              }
              openSwitch
            />
          </Paper>
        </Dialog.Body>
        <Dialog.Footer>
          <ChooseButton
            onClick={() => {
              openCreateEmployeesSchedulesDialog({
                items: extractItemsFromFolders(selectedItems),
                closeChooseEmployeesDialog: closeDialog,
              })
            }}
            disabled={selectedItems.length === 0}
          />

          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>

      {createEmployeesSchedulesDialog}
    </Modal>
  )
}
