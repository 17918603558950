import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { convertFloatToMinutes, DAYSWEEK } from '@expane/logic/utils'
import { ScheduleDto } from '@expane/data'

interface BranchCreateScheduleBlockProps {
  schedule: ScheduleDto | undefined
}

export const ScheduleInfoWidget: FC<BranchCreateScheduleBlockProps> = ({ schedule }) => {
  const { t } = useTranslation()

  return (
    <div className="h-16 mb-2 flex justify-center items-center p-2  rounded-md border-2 border-gray-100 dark:border-gray-500">
      {!schedule ? (
        <p className="text-main-color">{t('branch.scheduleNotSelected')}</p>
      ) : (
        DAYSWEEK.map((el, index) => {
          const formattedData = schedule.data

          const workTime =
            formattedData &&
            (formattedData[index] === null
              ? t('dayOff').toLocaleLowerCase()
              : convertFloatToMinutes(formattedData[index][0]) +
                '-' +
                convertFloatToMinutes(formattedData[index][1]))

          return (
            <div key={index} className="flex flex-col justify-start items-center text-sm pr-2 w-22">
              <span className="text-primary-700 font-medium">{t(el)}</span>
              <span className="text-main-color">{workTime}</span>
            </div>
          )
        })
      )}
    </div>
  )
}
