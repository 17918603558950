import { useFetchBranchFinancialSettings } from '@expane/data'
import { useTranslation } from 'react-i18next'

const convertNumberToMoneyWithoutSymbol = (value: number) =>
  new Intl.NumberFormat('uk-UA', { maximumFractionDigits: 0 }).format(value)

export const convertNumberToFractionalMoneyWithoutSymbol = (value: number) =>
  new Intl.NumberFormat('uk-UA', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
    value,
  )

export const useConvertNumberToMoney = (branchId: number | undefined) => {
  const { data: businessSettings } = useFetchBranchFinancialSettings(branchId)

  const convert = (value: number) =>
    businessSettings?.displayCoins
      ? convertNumberToFractionalMoneyWithoutSymbol(value)
      : convertNumberToMoneyWithoutSymbol(value)

  return convert
}

export const useConvertNumberToMoneyCode = ({
  branchId,
  strictNoFraction = false,
}: {
  strictNoFraction?: boolean
  branchId: number | undefined
}) => {
  const { i18n } = useTranslation()
  const { data: businessSettings } = useFetchBranchFinancialSettings(branchId)

  const convert = (value: number) => {
    if (!businessSettings) return value.toString()
    let maximumFractionDigits = 0
    let minimumFractionDigits = 0
    if (businessSettings?.displayCoins && !strictNoFraction) {
      maximumFractionDigits = 2
      minimumFractionDigits = 2
    }

    const formattedValue = new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits,
      minimumFractionDigits,
      style: 'currency',
      currency: businessSettings?.currency,
    }).format(value)

    return formattedValue
  }

  return convert
}

export const useConvertNumberToMoneyCodeManually = () => {
  const { i18n } = useTranslation()

  const convert = (value: number, options: { currency: string; displayCoins?: boolean }) => {
    let maximumFractionDigits = 0
    let minimumFractionDigits = 0
    if (options.displayCoins) {
      maximumFractionDigits = 2
      minimumFractionDigits = 2
    }

    const formattedValue = new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits,
      minimumFractionDigits,
      style: 'currency',
      currency: options.currency,
    }).format(value)

    return formattedValue
  }

  return convert
}

export const useConvertNumberToMoneySymbol = (branchId: number | undefined) => {
  const { i18n } = useTranslation()
  const { data: businessSettings } = useFetchBranchFinancialSettings(branchId)

  const convert = (value: number) => {
    if (!businessSettings) return value.toString()

    let maximumFractionDigits = 0
    let minimumFractionDigits = 0
    if (businessSettings?.displayCoins) {
      maximumFractionDigits = 2
      minimumFractionDigits = 2
    }

    const formattedValue = new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits,
      minimumFractionDigits,
      style: 'currency',
      currencyDisplay: 'code',
      currency: businessSettings.currency,
    }).format(value)

    return formattedValue.replace(
      businessSettings.currency,
      currencySymbolMap[businessSettings.currency],
    )
  }

  return convert
}

export const useGetCurrencySymbol = (branchId: number | undefined) => {
  const { data: businessSettings } = useFetchBranchFinancialSettings(branchId)

  if (!businessSettings) return ''

  return currencySymbolMap[businessSettings.currency]
}

export const currencySymbolMap = {
  UAH: '₴',
  USD: '$',
  EUR: '€',
}

// Code in ISO4217
export const currencyItems: Array<{ id: number; name: string }> = [
  {
    id: 1,
    name: 'USD',
  },
  {
    id: 2,
    name: 'UAH',
  },
  {
    id: 3,
    name: 'EUR',
  },
]

export const getCurrencyIdByCode = (code: string): number => {
  const currencyItem = currencyItems.find(item => item.name === code)

  if (currencyItem === undefined) return currencyItems[0].id

  return currencyItem.id
}

export const getCurrencyCodeById = (id: number): string => {
  const currencyItem = currencyItems.find(item => item.id === id)

  if (currencyItem === undefined) return currencyItems[0].name

  return currencyItem.name
}

export const useConvertNumberToMoneySymbolByCurrency = () => {
  const { i18n } = useTranslation()

  const convert = (value: number, currency: string | undefined) => {
    if (!currency) return value.toString()

    const formattedValue = new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
      currencyDisplay: 'code',
      currency,
    }).format(value)

    return formattedValue.replace(currency, currencySymbolMap[currency])
  }

  return convert
}
