import { FC } from 'react'
import { CARD_TYPES, ServerTransactionByIdType, TRANSACTION_TYPES } from '@expane/data'
import { useTranslation } from 'react-i18next'
import { TransactionDialog } from 'widgets/TransactionDialog/index'
import { useOpenTransactionSubscriptionDialog } from 'widgets/TransactionSubscriptionDialog'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'

export const ParentTransactionInfo: FC<{
  transactionById: ServerTransactionByIdType
}> = ({ transactionById }) => {
  const { t } = useTranslation()

  const { dialog: transactionDialog, openEditDialog: openTransactionDialog } =
    useOpenDialog(TransactionDialog)

  const { transactionSubscriptionDialog, openTransactionSubscriptionDialog } =
    useOpenTransactionSubscriptionDialog()

  const isParentId = Boolean(transactionById.parentId)
  const isRefundTransaction = transactionById.type === TRANSACTION_TYPES.refund.id

  const isRefundedSubscription =
    isRefundTransaction &&
    transactionById.transactionsCards.some(card => card.card.type === CARD_TYPES.subscription)

  if (!isParentId || !isRefundTransaction) return null

  return (
    <div className="mt-3 text-sm flex flex-col">
      <p className="text-secondary-color">{`${t('transaction.createdRefund')}:`}</p>

      <div
        className="text-primary-400 cursor-pointer hover:text-primary-500 max-w-max"
        onClick={() =>
          transactionById.parentId &&
          (isRefundedSubscription
            ? openTransactionSubscriptionDialog(transactionById.parentId)
            : openTransactionDialog(transactionById.parentId))
        }
      >
        {`${t(TRANSACTION_TYPES.payment.name)} №${transactionById.parentId}`}
      </div>

      {transactionSubscriptionDialog}
      {transactionDialog}
    </div>
  )
}
