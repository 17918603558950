import { ChangeEvent, forwardRef, InputHTMLAttributes, useLayoutEffect, useRef } from 'react'
import { IconType } from 'react-icons'
import { ErrorMessageType } from '../ErrorMessage'
import { BaseInput } from '../Input/BaseInput'

export type InputHeight = 'small' | 'medium' | 'normal'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  hint?: string
  Icon?: IconType
  containerClassName?: string
  errorMessage?: ErrorMessageType
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  height?: InputHeight
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    label,
    value,
    hint,
    containerClassName,
    errorMessage,
    disabled,
    onChange,
    Icon,
    ...rest
  } = props

  const innerRef = useRef<HTMLInputElement>(null)

  useLayoutEffect(() => {
    const innerRefCurrent = innerRef.current

    if (!innerRefCurrent) return

    const preventDefault = (e: WheelEvent) => e.preventDefault()

    innerRefCurrent.addEventListener('wheel', preventDefault)

    return () => {
      innerRefCurrent.removeEventListener('wheel', preventDefault)
    }
  }, [])

  return (
    <BaseInput
      ref={ref}
      label={label}
      hint={hint}
      Icon={Icon}
      containerClassName={containerClassName}
      errorMessage={errorMessage}
      value={value}
      onChangeInput={onChange}
      disabled={disabled}
      {...rest}
    />
  )
})
