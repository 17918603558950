import { FC } from 'react'
import {
  EmployeeGroupWithSchedule,
  useCreateTimeOffReason,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
} from '@expane/data'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { useTranslation } from 'react-i18next'
import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  Modal,
  PlaceholderInput,
  SelectDropDownItem,
} from '@expane/ui'
import { SaveButton } from 'widgets/Buttons'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { MultiSelect } from 'ui/MultiSelect'
import { store } from 'store'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyEmployee } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'

export const TimeOffReasonDialog: FC<DialogProps> = ({ onCreate, closeDialog }) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } = useFetchEmployeesGroups(
    timezone,
    branchId,
  )
  const { data: myEmployee, isLoading: isLoadingMyEmployee } = useFetchMyEmployee(
    timezone,
    branchId,
  )

  const isNoData = !timezone || !myEmployee || !employeeGroups
  const isLoading = isLoadingEmployeeGroups || isLoadingMyEmployee

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
  if (!isLoading && isNoData) return null

  return (
    <Modal close={closeDialog}>
      <Dialog>
        {isLoading ? (
          <TimeOffReasonDialogPlaceholder closeDialog={closeDialog} />
        ) : (
          <TimeOffReasonDialogLogic
            closeDialog={closeDialog}
            employeeGroups={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              employeeGroups!
            }
            myEmployeeGroupId={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              myEmployee!.groupId!
            }
            onCreate={onCreate}
          />
        )}
      </Dialog>
    </Modal>
  )
}

const TimeOffReasonDialogLogic: FC<{
  employeeGroups: EmployeeGroupWithSchedule[]
  myEmployeeGroupId: number
  closeDialog: () => void
  onCreate?: (id: number) => void
}> = ({ employeeGroups, myEmployeeGroupId, closeDialog, onCreate }) => {
  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const { mutateAsync: createTimeOffReason, isLoading: isLoadingCreateTimeOffReason } =
    useCreateTimeOffReason()

  const { control, handleSubmit } = useForm<TimeOffReasonFormValues>({
    defaultValues: {
      name: '',
      employeeGroups: [],
    },
  })

  const mutateTimeOffReason: SubmitHandler<TimeOffReasonFormValues> = async ({
    name,
    employeeGroups,
  }) => {
    const isMyEmployeeGroupIdIsInSelectedEmployeeGroups = employeeGroups.some(
      employeeGroup => employeeGroup.id === myEmployeeGroupId,
    )

    if (!isMyEmployeeGroupIdIsInSelectedEmployeeGroups && employeeGroups.length) {
      openSnackbar(t('timeOffReason.myEmployeeGroupError'), 'error', 3000)
    } else {
      const result = await createTimeOffReason({
        name,
        timeOffReasonEmployeeGroups: {
          data: employeeGroups.map(employeeGroup => ({ employeeGroupId: employeeGroup.id })),
        },
      })

      if (result.insertTimeOffReason?.id) {
        onCreate?.(result.insertTimeOffReason?.id)
        openSnackbar(t('timeOffReason.createdSuccess'), 'success', 3000)
      } else {
        openSnackbar(t('submitError'), 'error', 3000)
      }

      closeDialog()
    }
  }

  return (
    <>
      <Dialog.Title>{t('timeOffReason.name')}</Dialog.Title>

      <Dialog.Body>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <Input
              label={t('title')}
              errorMessage={{
                isShown: Boolean(errors.name),
                text: t('formError.required'),
              }}
              value={value}
              onChange={onChange}
              autoFocus
              required
            />
          )}
        />

        <Controller
          control={control}
          name={'employeeGroups'}
          render={({ field: { value, onChange } }) => (
            <MultiSelect
              items={employeeGroups ?? []}
              onItemSelect={onChange}
              selectedItems={value}
              dropdownInputPlaceholder={t('placeholders.defaultSelect')}
              placeholder={t('choose')}
              label={t('employeeGroup.name')}
              hint={t('timeOffReason.employeeGroupsHint')}
            />
          )}
        />
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton
          onClick={handleSubmit(mutateTimeOffReason)}
          disabled={isLoadingCreateTimeOffReason}
        />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}

const TimeOffReasonDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('timeOffReason.name')}</Dialog.Title>

      <Dialog.Body>
        <PlaceholderInput label={t('title')} className="w-1/2 pr-2" />
        <PlaceholderInput label={t('employeeGroup.name')} className="w-1/2" />
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton disabled />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}

interface TimeOffReasonFormValues {
  name: string
  employeeGroups: SelectDropDownItem[]
}
