import { InputLabel } from '../InputLabel'
import { ErrorMessage, ErrorMessageType } from '../ErrorMessage'
import { getCommonInputStyles, stopEnterPropagation } from '../../logic'
import { ChangeEvent, FC, forwardRef, InputHTMLAttributes, KeyboardEvent, useCallback } from 'react'

export interface TextareaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  placeholder: string
  convertDashToDots?: boolean
  onAction?: () => void
  label?: string
  containerClassName?: string
  className?: string
  errorMessage?: ErrorMessageType
  rows?: number
  disabled?: boolean
  hint?: string
}

export const Textarea: FC<TextareaProps> = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      label,
      onAction,
      onChange,
      placeholder,
      containerClassName = '',
      errorMessage,
      rows = 4,
      disabled,
      className,
      required,
      convertDashToDots = false,
      hint,
      ...restProps
    } = props

    let textAreaStyles =
      getCommonInputStyles(errorMessage?.isShown, disabled) +
      'block w-full grow px-3 text-sm border-2 rounded-lg focus:ring-0 transition-all resize-none '

    textAreaStyles += className ?? ''

    // Used to run an action on Ctrl + Enter(Command on Macs). Enter - move to a new line
    const handlePressShortcut = useCallback(
      (e: KeyboardEvent) => {
        const isCtrlOrMetaPress = navigator.userAgent.includes('Macintosh') ? e.metaKey : e.ctrlKey

        if (e.code === 'Enter' && isCtrlOrMetaPress) {
          e.preventDefault()
          onAction?.()
        } else stopEnterPropagation(e)
      },
      [onAction],
    )

    const handleOnChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>): void => {
        if (convertDashToDots) {
          // Нужно для корректировки каретки
          let transformFlag = false

          let value = event.currentTarget.value
          const carriage = event.currentTarget?.selectionStart ?? 0
          let dashOffset = carriage - DASH.length
          // Если <0 - то каретка в начале текста
          dashOffset = dashOffset < 0 ? 0 : dashOffset

          if (value.startsWith(dashOffset === 0 ? DASH_WITHOUT_NEW_LINE : DASH, dashOffset)) {
            value =
              value.slice(0, dashOffset) +
              (dashOffset === 0 ? DOT_WITHOUT_NEW_LINE : DOT) +
              value.slice(carriage)
            transformFlag = true
          }

          // В React nativeEvent имеет тип Event, хотя реально здесь тип InputEvent
          const inputEvent = event.nativeEvent as InputEvent

          if (inputEvent.inputType === 'insertLineBreak') {
            // Находим индексы начала строки в тексте
            const indexes: number[] = [0]
            for (let i = 0; i < value.length; i += 1) {
              if (value[i] === NEW_LINE) {
                indexes.push(i)
              }
            }
            // Находим предыдущую строку
            const startOfPrevLine = indexes
              .filter(index => index < carriage - 1)
              .reduce((prev, current) => {
                if (carriage - current < carriage - prev) return current
                return prev
              }, 0)

            if (
              value.startsWith(startOfPrevLine === 0 ? DOT_WITHOUT_NEW_LINE : DOT, startOfPrevLine)
            ) {
              value = value.slice(0, carriage - 1) + DOT + value.slice(carriage)
              transformFlag = true
            }
          }

          event.currentTarget.value = value
          // Так как мы мутируем сам ивент, каретка соскакивает в конец
          // В зависимости от того что мы изменили, корректируем положение каретки
          const carriageOffset =
            inputEvent.inputType === 'insertLineBreak' ? 2 : dashOffset === 0 ? 1 : 0
          event.currentTarget.selectionStart = carriage + (transformFlag ? carriageOffset : 0)
          event.currentTarget.selectionEnd = carriage + (transformFlag ? carriageOffset : 0)
          onChange?.(event)
        } else onChange?.(event)
      },
      [onChange, convertDashToDots],
    )

    return (
      <div className={'flex flex-col ' + containerClassName}>
        <InputLabel label={label} required={required} hint={hint} />

        <textarea
          onKeyDown={handlePressShortcut}
          ref={ref}
          className={textAreaStyles}
          rows={rows}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleOnChange}
          {...restProps}
        />

        <ErrorMessage errorMessage={errorMessage} />
      </div>
    )
  },
)

const NEW_LINE = '\n'
const DASH = '\n- '
const DOT = '\n• '
// Для поиска если в начале текста
const DASH_WITHOUT_NEW_LINE = '- '
const DOT_WITHOUT_NEW_LINE = '• '
