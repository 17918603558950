import {
  useFetchClientsBriefs,
  useFetchClientsPhones,
  useFetchClientsWithFullNameFuzzSearch,
  useFetchCurrentBranchTimezone,
  useFetchExtendedEmployees,
  useFetchGiftCardsBriefs,
  useFetchProducts,
  useFetchServicesBriefs,
  useFetchSubscriptionsBriefs,
  useFetchSuppliersBrief,
} from '@expane/data'
import { useAreManyEmployeesAllowed } from '@expane/logic/billing'
import { addPhonesToClients } from '@expane/logic/client'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import {
  FadeIn,
  Input,
  modalsHTMLElement,
  Paper,
  PlaceholderString,
  usePopupOpenState,
} from '@expane/ui'
import { offset, useFloating } from '@floating-ui/react-dom'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { useShortQuickFindCut } from 'logic/hooks/useShortQuickFindCut'
import { ChangeEvent, FC, KeyboardEvent, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoSearch } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { routes as appRoutes } from 'routes/logic'
import { store } from 'store'
import { ClientDialog } from 'widgets/ClientDialog'
import { EmployeeDialog } from 'widgets/EmployeeDialog'
import { GiftCardDialog } from 'widgets/GiftCardDialog'
import { useOpenProductDialog } from 'widgets/ProductDialog'
import { ServiceDialog } from 'widgets/ServiceDialog'
import { SubscriptionDialog } from 'widgets/SubscriptionDialog'
import { SupplierDialog } from 'widgets/SupplierDialog'
import {
  getDataToDisplay,
  QuickFindMenuItem,
  storeResentSearch,
  transformCardsForQuickInput,
  transformPersonsForQuickInput,
  transformProductsForQuickInput,
  transformRoutesForQuickInput,
  transformServicesForQuickInput,
  transformSuppliersForQuickInput,
} from './logic'
import { QuickFindInputItem } from './QuickFindInputItem'

interface Props {
  containerClassName?: string
  disabled?: boolean
}

export const QuickFindInput: FC<Props> = ({ containerClassName, disabled }) => {
  const navigate = useNavigate()

  const branchId = store.branch.branchId
  const [searchValue, setSearchValue] = useState<string>('')

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: employees } = useFetchExtendedEmployees(timezone, branchId)
  const { data: clients } = useFetchClientsBriefs(branchId, timezone)
  const { data: clientsPhones } = useFetchClientsPhones()
  const { data: suppliers } = useFetchSuppliersBrief(branchId)
  const { data: services } = useFetchServicesBriefs(branchId)
  const { data: subscriptions } = useFetchSubscriptionsBriefs(branchId)
  const { data: giftCards } = useFetchGiftCardsBriefs(branchId)
  const { data: myPermissions } = useFetchMyPermissions()
  const { data: products } = useFetchProducts(branchId)

  const { enabledModules } = useBusinessModulesSettings()
  const { areManyEmployeesAllowed } = useAreManyEmployeesAllowed()

  const { data: clientsBySearchValue, isFetching: isFetchingClientsBySearchValue } =
    useFetchClientsWithFullNameFuzzSearch(searchValue, branchId, timezone)

  const clientsForDisplay = searchValue ? clientsBySearchValue : clients

  const { t } = useTranslation()

  const clientWithPhones = addPhonesToClients(clientsForDisplay, clientsPhones)

  const routes: QuickFindMenuItem[] = useMemo(
    () => [
      ...transformRoutesForQuickInput([
        ...appRoutes.getNavigationFlatRoutes(
          myPermissions,
          enabledModules,
          areManyEmployeesAllowed,
        ),
        ...appRoutes.getMenu(myPermissions, enabledModules),
        ...appRoutes.getArchiveRoutes(myPermissions, enabledModules),
      ]),
      ...transformPersonsForQuickInput(employees ?? [], 'employee'),
      ...transformPersonsForQuickInput(clientWithPhones, 'client'),
      ...transformSuppliersForQuickInput(suppliers ?? []),
      ...transformServicesForQuickInput(services ?? []),
      ...transformCardsForQuickInput(subscriptions ?? [], 'subscription'),
      ...transformCardsForQuickInput(giftCards ?? [], 'giftCard'),
      ...transformProductsForQuickInput(products ?? []),
    ],
    [
      myPermissions,
      enabledModules,
      areManyEmployeesAllowed,
      employees,
      clientWithPhones,
      suppliers,
      services,
      subscriptions,
      giftCards,
      products,
    ],
  )

  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const [activeLink, setActiveLink] = useState<number>(0)

  const itemsToDisplay = getDataToDisplay(routes, searchValue)

  const { dialog: employeeDialog, openEditDialog: openEmployeeEditDialog } =
    useOpenDialog(EmployeeDialog)

  const { openEditDialog: openClientEditDialog, dialog: clientDialog } = useOpenDialog(ClientDialog)

  const { openEditDialog: openSupplierEditDialog, dialog: supplierDialog } =
    useOpenDialog(SupplierDialog)

  const { openEditDialog: openServiceEditDialog, dialog: serviceDialog } =
    useOpenDialog(ServiceDialog)

  const { openEditDialog: openSubscriptionEditDialog, dialog: subscriptionDialog } =
    useOpenDialog(SubscriptionDialog)

  const { openEditDialog: openGiftCardEditDialog, dialog: giftCardDialog } =
    useOpenDialog(GiftCardDialog)

  const { openEditDialog: openProductEditDialog, dialog: productDialog } = useOpenProductDialog()

  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const onEscAction = () => {
    if (searchValue !== '') {
      setSearchValue('')
      searchInputRef.current?.focus()
    } else {
      closePopup()
    }
  }

  useShortQuickFindCut(searchInputRef, onEscAction)

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return

    openPopup()

    const inputValue =
      e.target.value.startsWith('/') || e.target.value.startsWith('.')
        ? e.target.value.substring(1)
        : e.target.value

    setSearchValue(inputValue)
    setActiveLink(0)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (disabled) return

    const lastMenuItem = itemsToDisplay.length - 1

    if (e.key === 'ArrowDown') setActiveLink(lastMenuItem > activeLink ? activeLink + 1 : 0)
    if (e.key === 'ArrowUp') setActiveLink(activeLink === 0 ? lastMenuItem : activeLink - 1)
    if (e.key === 'Enter' && itemsToDisplay[activeLink])
      goToSelectedPage(itemsToDisplay[activeLink])
  }

  const goToSelectedPage = (menuItem: QuickFindMenuItem) => {
    if (menuItem.type === 'page') navigate(menuItem.path ?? '')
    if (menuItem.type === 'employee' && menuItem.id) openEmployeeEditDialog(menuItem.id)
    if (menuItem.type === 'client' && menuItem.id) openClientEditDialog(menuItem.id)
    if (menuItem.type === 'supplier' && menuItem.id) openSupplierEditDialog(menuItem.id)
    if (menuItem.type === 'service' && menuItem.id) openServiceEditDialog(menuItem.id)
    if (menuItem.type === 'subscription' && menuItem.id) openSubscriptionEditDialog(menuItem.id)
    if (menuItem.type === 'giftCard' && menuItem.id) openGiftCardEditDialog(menuItem.id)
    if (menuItem.type === 'product' && menuItem.id) openProductEditDialog(menuItem.id)

    storeResentSearch(menuItem)

    searchInputRef.current?.blur()
    closePopup()
    setSearchValue('')
  }

  const inputProps = {
    onChange: handleInput,
    onFocus: () => {
      setActiveLink(0)
      openPopup()
    },
    placeholder: '',
    Icon: IoSearch,
    value: searchValue,
    ref: searchInputRef,
    autoComplete: 'off',
  }

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(5)],
  })

  const iconContainerStyle = isOpen
    ? 'hidden'
    : 'absolute right-2 top-2/4 -translate-y-2/4 pointer-events-none flex'

  const iconStyle =
    'text-gray-300 dark:text-gray-500 border border-current px-0.5 py-0.5 text-xs font-medium rounded '

  return (
    <>
      <div
        ref={reference}
        className={`relative my-1.5 ${containerClassName}`}
        onKeyDown={handleKeyDown}
      >
        <Input {...inputProps} height="medium" disabled={disabled} />
        <div className={iconContainerStyle}>
          <p className={iconStyle + ' mr-0.5'}>
            {navigator.userAgent.includes('Macintosh') ? '⌘' : 'Ctrl'}
          </p>
          <p className={iconStyle}>K</p>
        </div>
      </div>

      {isOpen && modalsHTMLElement
        ? createPortal(
            <>
              <div
                className="fixed inset-0"
                onClick={() => {
                  setSearchValue('')
                  closePopup()
                }}
              />
              <FadeIn aria-hidden="true">
                <Paper
                  ref={floating}
                  style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                  }}
                  className="overflow-hidden w-max p-1 ring-1 ring-black ring-opacity-5"
                >
                  <ul>
                    {isFetchingClientsBySearchValue ? (
                      <>
                        <li
                          className="rounded-lg text-sm flex items-center pl-1 pr-3 py-2 
                            text-primary-600 bg-hover 
                            hover:text-primary-700 hover:dark:text-primary-500 cursor-pointer"
                        >
                          <PlaceholderString />
                        </li>
                        <li
                          className="rounded-lg text-sm flex items-center pl-1 pr-3 py-2 
                            text-primary-600 bg-hover 
                            hover:text-primary-700 hover:dark:text-primary-500 cursor-pointer"
                        >
                          <PlaceholderString />
                        </li>
                        <li
                          className="rounded-lg text-sm flex items-center pl-1 pr-3 py-2 
                            text-primary-600 bg-hover 
                            hover:text-primary-700 hover:dark:text-primary-500 cursor-pointer"
                        >
                          <PlaceholderString />
                        </li>
                      </>
                    ) : null}
                    {!isFetchingClientsBySearchValue ? (
                      itemsToDisplay.length > 0 ? (
                        itemsToDisplay.map((el, index) => {
                          return (
                            <li
                              onClick={() => {
                                goToSelectedPage(el)
                              }}
                              onMouseEnter={() => {
                                if (index !== activeLink) setActiveLink(index)
                              }}
                              key={`${el.type}_${el.id ?? el.label}`}
                              className={`${
                                index === activeLink && activeLinkStyle
                              } rounded-lg text-sm flex items-center pl-1 pr-3 py-2 
                            text-primary-600 bg-hover 
                            hover:text-primary-700 hover:dark:text-primary-500 cursor-pointer`}
                            >
                              <QuickFindInputItem {...el} />
                            </li>
                          )
                        })
                      ) : (
                        <li className="p-5 text-gray-400 font-medium min-w-40">
                          {t('nothingFoundForRequest')}
                        </li>
                      )
                    ) : null}
                  </ul>
                </Paper>
              </FadeIn>
            </>,
            modalsHTMLElement,
          )
        : null}

      {employeeDialog}
      {clientDialog}
      {supplierDialog}
      {serviceDialog}
      {subscriptionDialog}
      {giftCardDialog}
      {productDialog}
    </>
  )
}

const activeLinkStyle =
  'bg-primary-50 dark:bg-primary-500/20 text-primary-700 dark:text-primary-500'
