import { App } from 'App'
import { initApp } from 'logic/app'
import { createRoot } from 'react-dom/client'
import { getElementSafelyById } from '@expane/logic/dom'

import './main.css'

initApp()

const container = getElementSafelyById('root')
const root = createRoot(container)

root.render(<App />)
