import {
  NotGroupBooking,
  useFetchBookingById,
  useFetchBranchById,
  useFetchDeactivatedServices,
  useFetchExtendedEmployees,
  useFetchExtendedServices,
  useFetchServiceGroups,
  useFetchStorageById,
  useGetLeftovers,
} from '@expane/data'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { ErrorMessage, InputLabel, Spinner } from '@expane/ui'
import { getPaidServices } from 'logic/booking'
import {
  addDisabledForServices,
  addInactiveReasonsAndWarningMessagesForServices,
  filterInactiveItemsFromSelected,
  getServicesForTreeMenu,
  ServiceGroupTreeItem,
} from 'logic/bookingServiceLogic'
import { useCheckBookingDateEditable } from 'logic/calendar'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { Controller, UseFormReturn, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { onChangeMultiTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { BookingInactiveReason } from 'widgets/BookingInactiveReason'
import { BookingDialogFormValues } from '.'

interface ServiceBlockProps {
  bookingId: number | undefined
  form: UseFormReturn<BookingDialogFormValues>
  disabled: boolean
  isCreate: boolean
}

export const ServiceBlock: FC<ServiceBlockProps> = observer(
  ({ form, disabled, bookingId, isCreate }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()
    const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

    const { data: branch } = useFetchBranchById(branchId)

    const {
      isLoading: isBookingLoading,
      data: bookingById,
      fetchStatus: bookingFetchStatus,
    } = useFetchBookingById(bookingId, branch?.timezone, branchId)
    const isBookingLoadingWithCheck = isBookingLoading && bookingFetchStatus === 'fetching'

    const paidServices = bookingById
      ? getPaidServices(
          (bookingById as NotGroupBooking).bookingServices.map(
            bookingService => bookingService.service,
          ),
          bookingById.transactions,
        )
      : []

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userId = store.me.employeeId!
    const { control, setValue } = form
    const watchedLocationId = useWatch({ control, name: 'locationId' })
    const watchedEmployeeId = useWatch({ control, name: 'employeeId' })
    const watchedServices = useWatch({ control, name: 'services' })
    const watchedStartDate = useWatch({ control, name: 'startDate' })

    const isBookingTodayOrLater = useCheckBookingDateEditable(watchedStartDate)

    const { isFetching: isServicesFetching, data: services } = useFetchExtendedServices(
      branchId,
      'all',
    )
    const {
      isLoading: isArchivedServicesLoading,
      data: archivedServices,
      fetchStatus: archivedServicesFetchStatus,
    } = useFetchDeactivatedServices(branchId, 'all', !isBookingTodayOrLater && !isCreate)
    const isArchivedServicesLoadingWithCheck =
      isArchivedServicesLoading && archivedServicesFetchStatus === 'fetching'

    const { isLoading: isServiceGroupsLoading, data: servicesGroups } =
      useFetchServiceGroups(branchId)
    const isFetching =
      isServicesFetching ||
      isServiceGroupsLoading ||
      isArchivedServicesLoadingWithCheck ||
      isBookingLoadingWithCheck

    const { data: employees } = useFetchExtendedEmployees(branch?.timezone, branchId)
    const storageIdByEmployee = employees?.find(employee => employee.id === watchedEmployeeId)
      ?.employeeGroup?.storageId
    const defaultStorageId = storageIdByEmployee ?? branch?.defaultStorage?.id
    const { data: storageById } = useFetchStorageById(defaultStorageId)
    const { data: productLeftovers } = useGetLeftovers(branchId, defaultStorageId)
    const leftovers = defaultStorageId && productLeftovers ? productLeftovers : undefined

    const notGroupServices = getServicesForTreeMenu({
      checkArchived: !isCreate && !isBookingTodayOrLater,
      services,
      archivedServices,
      servicesFromBooking: (bookingById as NotGroupBooking)?.bookingServices.map(
        bookingService => bookingService.service,
      ),
    })
    const servicesWithInactiveReasons = addInactiveReasonsAndWarningMessagesForServices({
      services: notGroupServices,
      locationId: watchedLocationId,
      employeeId: watchedEmployeeId,
      leftovers,
      userId,
      t,
    })

    const servicesWithDisabled = addDisabledForServices(servicesWithInactiveReasons, paidServices)

    const serviceItems = transformDataForTreeMenu<ServiceGroupTreeItem>(
      servicesGroups,
      servicesWithDisabled,
    )

    useEffect(() => {
      // если поменялся список доступных услуг, оставляем в выбранных только те, которые подходят
      if (!isServicesFetching) {
        const onlyActiveServices = filterInactiveItemsFromSelected(
          watchedServices as ServiceGroupTreeItem[],
          serviceItems,
        )
        if (onlyActiveServices.length < watchedServices.length)
          setValue('services', onlyActiveServices, { shouldDirty: true })
      }
    }, [isServicesFetching, watchedServices, serviceItems, setValue])

    return (
      <div className="w-88 pl-2 shrink-0">
        <div className="flex items-start justify-between">
          <InputLabel label={t('services')} required />
          {Boolean(storageById) && (
            <p className={storageLabelClassName}>
              {t('storage.name')}: {storageById?.name}
            </p>
          )}
        </div>
        <Controller
          control={control}
          name="services"
          rules={{
            required: true,
            validate: value => value.length > 0,
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) =>
            isFetching ? (
              <Spinner expandCentered />
            ) : (
              <>
                <TreeMenu
                  type="MultiPickMode"
                  onSelected={(item, isInactive) => {
                    if (!isInactive)
                      onChange(onChangeMultiTreeMenu(item, value as TreeMenuItem[], serviceItems))
                  }}
                  selected={value}
                  items={disabled ? value : serviceItems}
                  disabled={disabled}
                  priceConversionHandlerFunction={convertToMoneyCode}
                  className={getClassName(Boolean(error))}
                  customInactiveReasonRender={item =>
                    // @ts-expect-error items can be value where customInactiveReason is not present
                    item.isFolder || item.customInactiveReason === undefined ? null : (
                      <BookingInactiveReason
                        item={item}
                        onLocationClick={id => setValue('locationId', id)}
                        onEmployeeClick={id => setValue('employeeId', id)}
                        onFinalAction={() => {
                          onChange(
                            onChangeMultiTreeMenu(
                              item,
                              value as TreeMenuItem[],
                              serviceItems,
                              true,
                            ),
                          )
                        }}
                      />
                    )
                  }
                />
                <ErrorMessage
                  errorMessage={{ isShown: Boolean(error), text: t('pickAtLeastOneService') }}
                />
              </>
            )
          }
        />
      </div>
    )
  },
)

const getClassName = (error: boolean) =>
  error ? className + ' border-error-400' : className + ' border-input-disabled-color'
const className = 'overflow-y-auto border-2 rounded-lg pt-1 h-101'
const storageLabelClassName = 'text-gray-400 text-sm'
