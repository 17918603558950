import {
  useFetchCurrentBranchTimezone,
  useFetchTransactionsBriefWithCardsByDate,
} from '@expane/data'
import { defineStartAndEndDateByPeriodOfTime, usePeriodOfTime } from '@expane/logic/analytics'
import {
  convertTransactionsToCardReportItem,
  getNotRefundedReportItems,
  getSubscriptionReportItems,
} from '@expane/logic/reports/cards'
import { calcReportItemsTotalPrice } from '@expane/logic/reports/logic'
import { Paper } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { AnalyticsBlockBody, AnalyticsBlockItem, AnalyticsBlockTitle } from 'pages/AnalyticsPage'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'

export const SubscriptionsBlock: FC<{ className?: string }> = observer(({ className }) => {
  const { t } = useTranslation()
  const { periodOfTimeName, periodOfTimeId, onSelectChange } = usePeriodOfTime()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const [startDate, endDate] = defineStartAndEndDateByPeriodOfTime(periodOfTimeId, timezone)

  const { data: transactionsWithCards, isLoading } = useFetchTransactionsBriefWithCardsByDate(
    startDate,
    endDate,
    timezone,
    branchId,
  )

  const reportItems = convertTransactionsToCardReportItem(transactionsWithCards ?? [])
  const subscriptionReportItems = getSubscriptionReportItems(getNotRefundedReportItems(reportItems))

  const subscriptionsQuantity = subscriptionReportItems.length
  const subscriptionsTotalValue = calcReportItemsTotalPrice(subscriptionReportItems)

  return (
    <Paper className={`p-4 ${className ?? ''}`}>
      <AnalyticsBlockTitle
        title={t('subscriptions')}
        onSelectChange={onSelectChange}
        namePeriodOfTime={periodOfTimeName}
        periodOfTimeId={periodOfTimeId}
        hintMessage={t('subscriptionsBlock.numberSold')}
        isLoading={isLoading}
      />

      <AnalyticsBlockBody
        value={subscriptionsQuantity}
        text={`${t('subscriptionsBlock.numberSold')} ${periodOfTimeName}`}
        isLoading={isLoading}
      >
        <AnalyticsBlockItem
          title={t('amount')}
          value={subscriptionsTotalValue}
          isValueMoney
          isLoading={isLoading}
        />
      </AnalyticsBlockBody>
    </Paper>
  )
})
