import {
  useCreateBookings,
  useFetchBranchById,
  useFetchExtendedEmployees,
  useFetchLocations,
} from '@expane/data'
import { createCurrentDate, isDateBefore, fromZonedTime } from '@expane/date'
import { checkBusinessIsOpen } from '@expane/logic/business'
import { defineDayTiming } from '@expane/logic/calendar'
import { permissions } from '@expane/logic/permission'
import { CancelButton, Dialog, Modal, Spinner, usePopupOpenState } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { SubmitButton } from 'widgets/Buttons'
import { getAllBookings } from './logic'
import { CheckedBooking } from './PickedBookingListItem'
import { PickedBookingsList } from './PickedBookingsList'
import { SettAllDatesInput } from './SetAllDatesInput'

interface MultiBookingDialogProps {
  closeDialog: () => void
}

const MultiBookingDialog: FC<MultiBookingDialogProps> = observer(({ closeDialog }) => {
  const {
    multiBooking: { reset, firstBooking, additionalBookings },
    branch: { branchId },
  } = store
  const { t } = useTranslation()
  const { data: myPermissions, isLoading: isLoadingPermissions } = useFetchMyPermissions()
  const canCreateInPast = myPermissions?.includes(permissions.booking.editPast) ?? false

  const bookings = getAllBookings(firstBooking, additionalBookings) ?? []

  const { data: branch, isLoading: isBranchLoading } = useFetchBranchById(branchId)
  const branchSchedule = branch?.schedule
  const timezone = branch?.timezone
  const { data: employees, isLoading: isEmployeesLoading } = useFetchExtendedEmployees(
    timezone,
    branchId,
  )
  const { data: locations, isLoading: isLocationsLoading } = useFetchLocations(branchId)

  const isLoading =
    isEmployeesLoading || isLocationsLoading || isBranchLoading || isLoadingPermissions

  // TODO: need rerender improvements, React.memo on items didn't work
  const checkedBookings: CheckedBooking[] = isLoading
    ? []
    : bookings.map(booking => {
        const serviceIds = booking.isGroupBooking
          ? [booking.serviceId]
          : booking.bookingServices?.data.map(service => service.serviceId) ?? []
        const isEmployeesAreRequired = serviceIds.some(serviceId =>
          employees?.some(employee =>
            employee.serviceEmployees.some(sE => sE.service.id === serviceId),
          ),
        )

        const employee = employees?.find(employee => employee.id === booking.employeeId)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const location = locations!.find(location => location.id === booking.locationId)!

        const errorMessages: string[] = []
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (!canCreateInPast && isDateBefore(booking.startDate!, createCurrentDate(timezone)))
          errorMessages.push(t('bookingValidation.notAllowedCreatePast'))
        if (employee !== undefined) {
          if (
            !isEmployeesAreRequired ||
            !serviceIds.every(serviceId =>
              employee.serviceEmployees.some(sE => sE.service.id === serviceId),
            )
          )
            errorMessages.push(t('invalidEmployee'))
        } else {
          if (isEmployeesAreRequired) errorMessages.push(t('invalidEmployee'))
        }

        if (
          // если на локации нельзя предоставить услугу
          !serviceIds.every(serviceId =>
            location.serviceLocations.some(sL => sL.serviceId === serviceId),
          )
        )
          errorMessages.push(t('invalidLocation'))

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const branchDayTiming = defineDayTiming(branchSchedule ?? undefined, booking.startDate!)

        if (
          !checkBusinessIsOpen(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            booking.startDate!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            booking.duration!,
            branchDayTiming[0],
            branchDayTiming[1],
          )
        )
          errorMessages.push(t('bookingValidation.businessIsClosed'))

        return {
          ...booking,
          errorMessage: errorMessages.length ? errorMessages.join('\n') : undefined,
        }
      })
  const isSomeBookingsInvalid = checkedBookings.some(booking => booking.errorMessage !== undefined)

  const { mutateAsync: createBookings, isLoading: isCreating } = useCreateBookings()

  const [openSnackbar] = useSnackbar()

  const handleSubmit = async () => {
    try {
      await createBookings(
        bookings.map(booking => ({
          ...booking,
          startDate:
            booking.startDate && timezone
              ? fromZonedTime(booking.startDate, timezone)
              : booking.startDate,
          specialId: undefined,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          branchId: branchId!,
        })),
      )
      openSnackbar(t('bookings.success'), 'success')
      reset()
      closeDialog()
    } catch (error) {
      openSnackbar(t('submitError'), 'error')
    }
  }

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('bookingsCreation')}</Dialog.Title>
        <Dialog.Body>
          {isBranchLoading && <Spinner expandCentered />}
          {!isBranchLoading && (
            <>
              <SettAllDatesInput timezone={branch?.timezone} />
              <PickedBookingsList
                timezone={branch?.timezone}
                bookings={checkedBookings}
                locations={locations}
                employees={employees}
                isLoading={isLoading}
              />
            </>
          )}
        </Dialog.Body>
        <Dialog.Footer>
          <SubmitButton
            disabled={isLoading || isCreating || isSomeBookingsInvalid || bookings.length === 0}
            spinner={isCreating}
            onClick={handleSubmit}
          />
          <CancelButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
})

export const useMultiBookingDialog = () => {
  const { openPopup, closePopup, isOpen } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()

  const openMultiBookingDialog = (onClose?: () => void) => {
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const multiBookingDialog = isOpen ? <MultiBookingDialog closeDialog={closeDialog} /> : null

  return {
    multiBookingDialog,
    openMultiBookingDialog,
  }
}
