import { addMonths, addYears, subMonths, subYears } from '@expane/date'
import { getCommonInputStyles } from '@expane/ui'
import { Detail } from 'react-calendar'

export type DayTiming = [number, number]

export const PLACEHOLDERS = {
  date: '01.11.1991',
  time: '11:11',
  week: '03.06 - 09.06.2002',
}

export const getNewActiveStartDateOnForward = (date: Date, view: Detail) => {
  if (view === 'decade') return addYears(date, 10)

  if (view === 'year') return addYears(date, 1)

  return addMonths(date, 1)
}
export const getNewActiveStartDateOnBack = (date: Date, view: Detail) => {
  if (view === 'decade') return subYears(date, 10)

  if (view === 'year') return subYears(date, 1)

  return subMonths(date, 1)
}

export const getContainerStyle = ({ disabled, error }: { disabled: boolean; error: boolean }) => {
  let containerStyle = 'flex transition-all w-full h-9.5 border-2 rounded-lg overflow-hidden '

  containerStyle += getCommonInputStyles(error, disabled)

  return containerStyle
}

export const transformToDateFormat = (value?: string) => {
  if (!value) return ''

  const data = value.replace(/[^\d.]/g, '')

  const result: Array<string> = []

  data.split('.').forEach((el, index, array) => {
    // при вводе даты без точек, после 2го символа ставим точку
    if (array.length === 1) {
      if (el.length <= 2) result.push(el)
      if (el.length === 3) result.push(el.slice(0, 2), el.slice(2))
    }

    // когда число перед точкой длинной 1, добавляем 0 перед этим числом
    if (array.length === 2) {
      if (index === 0) {
        if (el.length === 1 && array[1].length === 0) result.push('0' + el)
        // дополнительная проверка числа после точки, дает возможность редактировать число перед точкой
        else result.push(el.slice(0, 2))
      } else {
        // при вводе даты без точек, после 2го символа ставим точку
        if (el.length <= 2) result.push(el)
        if (el.length === 3) result.push(el.slice(0, 2), el.slice(2))
      }
    }

    if (array.length === 3) {
      if (index === 0) {
        // при редактировании первого елемента, ограничиваем ввод
        result.push(el.slice(0, 2))
      }
      if (index === 1) {
        // когда число перед точкой длинной 1, добавляем 0 перед этим числом
        if (el.length === 1 && array[2].length === 0) result.push('0' + el)
        // дополнительная проверка числа после точки, дает возможность редактировать число перед точкой
        else result.push(el.slice(0, 2))
      }
      if (index === 2) {
        // ограничиваем ввод
        result.push(el.slice(0, 4))
      }
    }
  })

  return result.join('.')
}

export const transformToTimeFormat = (value?: string) => {
  if (!value) return ''

  const data = value.replace(/[^\d:]/g, '')

  const result: Array<string> = []

  data.split(':').forEach((el, index, array) => {
    // при вводе времени без разделителя, после 2го символа добавляем его
    if (array.length === 1) {
      if (el.length <= 2) result.push(el)
      if (el.length === 3) result.push(el.slice(0, 2), el.slice(2))
    }

    if (array.length === 2) {
      if (index === 0) {
        // когда число перед разделителем длинной 1, добавляем 0 перед этим числом
        if (el.length === 1 && array[1].length === 0) result.push('0' + el)
        // дополнительная проверка числа после точки, дает возможность редактировать число перед точкой
        else result.push(el.slice(0, 2))
      }
      if (index === 1) {
        // ограничеваем ввод
        result.push(el.slice(0, 2))
      }
    }
  })

  return result.join(':')
}

const inputStyle = 'w-full text-center placeholder-color'
export const getInputStyle = (disabled: boolean): string => {
  return (
    inputStyle + (disabled ? ' text-label-color bg-input-disabled' : ' text-main-color bg-input')
  )
}
