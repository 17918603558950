import { useCallback, useState } from 'react'
import { safeWebLocalStorage } from '../safeWebLocalStorage'
import { ReportErrorFunc } from '@expane/logic/utils'

export function useWebPersistedState<T = undefined>(
  key: string,
  reportError: ReportErrorFunc,
): {
  storage: T | undefined
  updateStorage: (value: T | undefined) => void
  clearPersistedStorage: () => void
}

export function useWebPersistedState<T>(
  key: string,
  reportError: ReportErrorFunc,
  fallbackInitialState: T,
): {
  storage: T
  updateStorage: (value: T) => void
  clearPersistedStorage: () => void
}
export function useWebPersistedState<T>(
  key: string,
  reportError: ReportErrorFunc,
  fallbackInitialState?: T,
) {
  const [storage, setStorage] = useState<T>(() => {
    const rawData = safeWebLocalStorage.getItem(key)

    if (!rawData) return fallbackInitialState
    // JSON.parse('undefined') throws error
    if (rawData === 'undefined') return fallbackInitialState

    try {
      return JSON.parse(rawData)
    } catch (error) {
      reportError(error as Error, 'warning', { key })
      return fallbackInitialState
    }
  })

  const updateStorage = useCallback(
    (value: T) => {
      setStorage(value)
      safeWebLocalStorage.setItem(key, JSON.stringify(value))
    },
    [key],
  )

  const clearPersistedStorage = () => {
    safeWebLocalStorage.removeItem(key)
  }

  return { storage, updateStorage, clearPersistedStorage }
}
