import { ServerTransactionInBookingType } from '@expane/data'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import {
  getPaymentAndRefundTransactionAdditionalInfo,
  transformTransactionType,
} from '@expane/logic/transaction'
import { Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'

type TransactionsDialogListProps = {
  transactions: ServerTransactionInBookingType[]
  onClickItem: (id: number) => void
}
export const TransactionsDialogList: FC<TransactionsDialogListProps> = observer(
  ({ transactions, onClickItem }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()
    const formatDate = useDateFormatting()
    const convertToMoney = useConvertNumberToMoneyCode({ branchId })
    const columns = useMemo<ColumnDef<ServerTransactionInBookingType>[]>(
      () => [
        {
          accessorKey: 'date',
          header: t('timeTitle'),
          cell: data => formatDate('historyDateTime', data.getValue<Date>()),
          size: 200,
        },
        {
          accessorKey: 'type',
          header: t('type'),
          cell: data => t(transformTransactionType(data.getValue<number>())),
          size: 160,
        },
        {
          id: 'additionalInfo',
          header: t('additionalInfo.short'),
          cell: data => {
            const transaction = data.row.original

            return getPaymentAndRefundTransactionAdditionalInfo(transaction, t)
          },
          size: 240,
        },
        {
          accessorKey: 'amount',
          header: () => <span className="w-full text-right">{t('amount')}</span>,
          cell: data => (
            <div className="text-right">
              {data.row.original.cardId
                ? t('subscription.name')
                : convertToMoney(data.getValue<number>())}
            </div>
          ),
          size: 140,
        },
      ],
      [convertToMoney, formatDate, t],
    )

    return (
      <Table
        columns={columns}
        data={transactions}
        onRowClick={transaction => onClickItem(transaction.id)}
      />
    )
  },
)
