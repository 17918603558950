import { convertFloatToMinutes } from '@expane/logic/utils'
import { FC } from 'react'
import { Range as RangeComponent, getTrackBackground } from 'react-range'

export interface RangeProps {
  values: number[] | null
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  markType?: 'time' | 'number'
  markSeparators?: boolean
  onChange?: (values: number[]) => void
  className?: string
  labeled?: boolean
  placeholderValues: number[]
}

export const Range: FC<RangeProps> = ({
  values,
  min = 0,
  max = 100,
  step = 0.1,
  disabled,
  markType,
  markSeparators = false,
  onChange,
  className,
  labeled,
  placeholderValues,
}) => {
  const valuesToDisplay = values ?? placeholderValues

  return (
    <RangeComponent
      values={valuesToDisplay}
      step={step}
      min={min}
      max={max}
      disabled={disabled}
      onChange={(rangeValues: number[]) => {
        if (markType === 'time')
          if (rangeValues[1] - rangeValues[0] === 0) {
            if (values?.[0] === rangeValues[0]) {
              onChange?.([rangeValues[0], rangeValues[1] + step])
            } else {
              onChange?.([rangeValues[0] - step, rangeValues[1]])
            }
            return
          }

        onChange?.(rangeValues)
      }}
      renderMark={
        markSeparators
          ? ({ props, index }) => {
              const position: number = index * step + min
              const renderStep: boolean = position % 2 === 0
              const points: boolean = renderStep || position === min || position === max

              if (!points) return undefined

              const label: string =
                markType === 'time' ? convertFloatToMinutes(position) : position.toString()

              let separatorsStyle = 'w-1 h-1 mx-auto rounded '
              separatorsStyle +=
                position < valuesToDisplay[0] || position > valuesToDisplay[1] || disabled
                  ? 'bg-primary-100'
                  : 'bg-primary-500'

              const numberStyle = 'mt-1 text-xs text-gray-400'

              return (
                <div
                  {...props}
                  style={{ ...props.style }}
                  className="-bottom-2 h-6 flex flex-col justify-start"
                >
                  <div className={separatorsStyle} />
                  {renderStep && <p className={numberStyle}>{label}</p>}
                </div>
              )
            }
          : undefined
      }
      renderTrack={({ props, children }) => {
        const colors: Array<string> =
          valuesToDisplay.length === 1
            ? ['rgb(var(--500))', 'rgb(var(--100))']
            : ['rgb(var(--100))', 'rgb(var(--500))', 'rgb(var(--100))']

        return (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{ ...props.style }}
            className={'flex h-7 ' + className}
          >
            <div
              ref={props.ref}
              className="h-0.5 w-full self-center rounded"
              style={{
                background: disabled
                  ? 'rgb(var(--100))'
                  : getTrackBackground({
                      values: valuesToDisplay,
                      colors,
                      min,
                      max,
                    }),
              }}
            >
              {children}
            </div>
          </div>
        )
      }}
      renderThumb={({ props, index }) => {
        const label: string | number =
          markType === 'time'
            ? convertFloatToMinutes(valuesToDisplay[index])
            : valuesToDisplay[index]

        let thumbStyle = 'w-3.5 h-3.5 rounded-full'
        thumbStyle += disabled
          ? ' bg-primary-100 pointer-events-none'
          : ' bg-primary-500 ring-primary-500 ring-opacity-30 transition-shadow focus:ring-8 active:ring-8'

        let labelStyle = '-translate-y-4 -translate-x-1/3 text-xs text-primary-500 '

        if (markType === 'time') {
          if (valuesToDisplay[1] - valuesToDisplay[0] <= 1) {
            labelStyle += valuesToDisplay[1] - valuesToDisplay[0] < 1 ? 'w-14 ' : 'w-11 '
            labelStyle += index === 1 ? 'text-right ' : 'text-left '
          } else {
            labelStyle += 'w-12 text-center '
          }
        } else {
          labelStyle += 'w-12 text-center '
        }
        return (
          <div {...props} style={{ ...props.style }} className={thumbStyle}>
            {labeled && <div className={labelStyle}>{label}</div>}
          </div>
        )
      }}
    />
  )
}
