import { offset, useFloating } from '@floating-ui/react-dom'
import { FC, useLayoutEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { usePopupOpenState } from '../../hooks'
import { modalsHTMLElement } from '../../logic'

// reserveIndent нужен для того, что у нас есть случаи, когда не нужно учитывать отступ для ошибок
// Например если инпуты в таблицах
export type ErrorMessageType = {
  isShown: boolean
  text?: string
  reserveIndent?: boolean
}

interface Props {
  errorMessage?: ErrorMessageType
  className?: string
}

export const ErrorMessage: FC<Props> = ({ errorMessage, className }) => {
  const innerRef = useRef<HTMLParagraphElement>(null)

  const { isOpen, closePopup, openPopup } = usePopupOpenState()
  const { x, y, floating, reference, strategy } = useFloating({
    middleware: [offset({ mainAxis: -15 })],
  })

  useLayoutEffect(() => {
    reference(innerRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, innerRef.current])

  if (!errorMessage) return null

  const elWidth = innerRef.current?.clientWidth
  const scrollWidth = innerRef.current?.scrollWidth

  let isOverflowing = false
  if (elWidth && scrollWidth) isOverflowing = scrollWidth > elWidth

  const textStyle = ' text-xs text-error-500 '
  const style = 'absolute top-0 w-full truncate ' + textStyle

  return (
    <div className="relative" onMouseLeave={closePopup}>
      {errorMessage.reserveIndent !== false && <div className="pb-4" />}

      {errorMessage.isShown && (
        <>
          <p ref={innerRef} className={style + (className ?? '')} onMouseEnter={openPopup}>
            {errorMessage.text}
          </p>

          {isOpen &&
            isOverflowing &&
            createPortal(
              <div
                ref={floating}
                className={
                  textStyle + ' bg-block border border-error-500 p-1 rounded-md break-words'
                }
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  width: elWidth,
                }}
              >
                {errorMessage.text}
              </div>,
              modalsHTMLElement,
            )}
        </>
      )}
    </div>
  )
}
