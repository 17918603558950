import {
  ErrorMessage,
  ErrorMessageType,
  FadeInTranslateTop,
  getCommonInputStyles,
  getPlacementStylesForArrow,
  InputLabel,
  modalsHTMLElement,
  Paper,
  usePopupOpenState,
} from '@expane/ui'
import { arrow, flip, offset, Side, useFloating } from '@floating-ui/react-dom'
import { forwardRef, Fragment, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import { IoCaretDownSharp } from 'react-icons/io5'
import { Currency, PercentIcon } from './Icons'

export type DiscountInputValue = { value: string; type: 'percent' | 'fixed' }

export interface DiscountInputProps {
  placeholder?: string
  containerClassName?: string
  label?: string
  required?: boolean
  hint?: string
  disabled?: boolean
  disabledReason?: string
  errorMessage?: ErrorMessageType
  value?: DiscountInputValue
  onChange: (event: DiscountInputValue) => void
  height?: 'small' | 'normal'
  // для storybook
  customModal?: HTMLElement | null
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DiscountInput = forwardRef<HTMLInputElement, DiscountInputProps>((props, ref) => {
  const {
    placeholder,
    containerClassName,
    label,
    hint,
    required,
    disabled,
    errorMessage,
    onChange,
    value = { value: '', type: 'percent' },
    height = 'normal',
    disabledReason,
    customModal,
    ...restProps
  } = props

  const { t } = useTranslation()

  let inputStyle =
    getCommonInputStyles(errorMessage?.isShown, disabled) +
    'w-full px-2 text-sm border-2 rounded-r-lg transition-all focus:ring-0'

  inputStyle += height === 'normal' ? ' h-9.5' : ' h-7'

  let discountTypeStyle =
    'h-full w-8 flex items-center justify-between border-2 rounded-l-lg cursor-pointer'

  if (errorMessage?.isShown) {
    discountTypeStyle +=
      ' text-btn-primary border-error-500 bg-error-500 outline-none' +
      ' hover:border-error-700  hover:bg-error-700 focus:outline-none '
  } else {
    discountTypeStyle += disabled
      ? ' border-input-disabled-color bg-gray-100 dark:bg-gray-600 pointer-events-none text-placeholder-color'
      : ' bg-primary-500 border-primary-500 hover:border-primary-700 hover:bg-primary-700 text-btn-primary'
  }

  const inputRef = useRef<HTMLInputElement | null>(null)

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
  })

  const {
    isOpen: isReasonOpen,
    openPopup: openReason,
    closePopup: closeReason,
  } = usePopupOpenState()
  const arrowRef = useRef(null)
  const {
    reference: reasonReference,
    floating: reasonFloating,
    strategy: reasonStrategy,
    x: reasonX,
    y: reasonY,
    placement,
    middlewareData: { arrow: arrowData },
  } = useFloating({
    middleware: [flip(), offset(7), arrow({ element: arrowRef })],
  })
  const side = placement.split('-')[0] as Side
  const arrowPlacementStyles = getPlacementStylesForArrow(side, arrowData)

  const { isOpen, closePopup, openPopup } = usePopupOpenState()

  const onOptionClicked = (index: number) => {
    // При переключении типа скидки - стираем старое значение
    onChange({ value: '', type: discountType[index].type })
    inputRef.current?.focus()
    closePopup()
  }

  const Icon = discountType.find(el => el.type === value.type)?.Icon

  return (
    <div className={containerClassName}>
      <InputLabel label={label} hint={hint} required={required} />

      <div
        ref={reasonReference}
        onMouseEnter={() => {
          if (disabled && disabledReason) openReason()
        }}
        onMouseLeave={() => {
          if (disabled && disabledReason) closeReason()
        }}
        className="flex"
      >
        <div className="relative">
          <div className={discountTypeStyle} ref={reference} onClick={openPopup}>
            <div className="grow flex items-center justify-center">
              {Icon && <Icon size="1rem" />}
            </div>
            <IoCaretDownSharp size="0.5rem" className="shrink-0" />
          </div>
        </div>

        <input
          onChange={e => {
            onChange({ value: e.target.value.replace(/[^\d.]/g, ''), type: value.type })
          }}
          className={inputStyle}
          placeholder={placeholder}
          value={value.value ?? ''}
          ref={inputRef}
          disabled={disabled}
          {...restProps}
        />
      </div>

      <ErrorMessage errorMessage={errorMessage} />
      {isOpen &&
        createPortal(
          <>
            <div className="fixed inset-0" onClick={closePopup} />
            <div
              ref={floating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
            >
              <FadeInTranslateTop
                as={Paper}
                className="flex flex-col absolute right-0 py-0.5 mt-1 ring-1 ring-black ring-opacity-5 overflow-hidden min-w-min left-0"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
                onClick={closePopup}
              >
                {discountType.map(({ Icon, type }, index) => (
                  <Fragment key={type}>
                    <button
                      className="flex items-center rounded-lg mx-0.5 p-1 text-sm text-primary-500 bg-hover hover:text-primary-700 transition-all"
                      role="menuitem"
                      onClick={() => {
                        onOptionClicked(index)
                      }}
                    >
                      <Icon size="1.2rem" />
                      <span className="text-xs pl-1">{t(type)}</span>
                    </button>
                  </Fragment>
                ))}
              </FadeInTranslateTop>
            </div>
          </>,
          customModal ?? modalsHTMLElement,
        )}
      {isReasonOpen &&
        createPortal(
          <div
            ref={reasonFloating}
            style={{
              position: reasonStrategy,
              top: reasonY ?? 0 + 'px',
              left: reasonX ?? 0 + 'px',
            }}
            className={messageStyle}
          >
            <div
              ref={arrowRef}
              style={arrowPlacementStyles}
              className="absolute transform rotate-45 w-2 h-2 bg-gray-400"
            />
            {disabledReason}
          </div>,
          customModal ?? modalsHTMLElement,
        )}
    </div>
  )
})
const messageStyle = 'px-2 py-1 text-sm rounded text-white bg-gray-400 whitespace-pre-line'

const discountType: {
  type: 'percent' | 'fixed'
  Icon: IconType
}[] = [
  {
    type: 'percent',
    Icon: PercentIcon,
  },
  {
    type: 'fixed',
    Icon: Currency,
  },
]
