import { FC } from 'react'
import { store } from 'store'
import { useFetchBookingClientSettings, useFetchMyBusinesses } from '@expane/data'
import { Spinner } from '@expane/ui'
import { OnlineBookingSettingsLogic } from 'pages/SettingsPage/OnlineBookingSettings/OnlineBookingSettingsLogic'

export const OnlineBookingSettings: FC = () => {
  const currentBusinessId = store.me.businessId

  const { data: businesses, isLoading: areBusinessesLoading } = useFetchMyBusinesses(
    store.me.isAuthorised,
  )

  const { data: bookingClientSettings, isLoading: areBookingClientSettingsLoading } =
    useFetchBookingClientSettings()

  const myBusiness = businesses?.find(business => business.id === currentBusinessId)

  if (areBookingClientSettingsLoading || areBusinessesLoading) return <Spinner expandCentered />
  if (!myBusiness || !bookingClientSettings) return null

  return <OnlineBookingSettingsLogic bookingClientSettings={bookingClientSettings} />
}
