import { blobToBase64, formatFileSize, MB } from '@expane/logic/file'
import { UploadInput, useShowConfirmationPopup, useShowCustomWarningPopup } from '@expane/ui'
import { FC, FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  containerClassName?: string
  // only set onChange to undefined if the component is permanently disabled
  onChange: undefined | ((base64File?: string) => void)
  onChangeFileName?: (name: string) => void
  defaultName?: string
  defaultFile?: string
  disabled?: boolean
  accept?: string
}

export const EditableFile: FC<Props> = ({
  onChange,
  onChangeFileName,
  containerClassName,
  defaultFile,
  defaultName,
  disabled = false,
  accept = '.jpg,.png,.jpeg,.pdf',
}) => {
  const { t } = useTranslation()

  const [fileUrl, setFileUrl] = useState(defaultFile ?? '')
  const [fileName, setFileName] = useState('')
  const [fileSize, setFileSize] = useState<number | undefined>()

  const getFile = async (blob?: Blob) => {
    if (blob) {
      setFileUrl(URL.createObjectURL(blob))
      const base64File = await blobToBase64(blob)
      onChange?.(base64File)
    }
  }

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()
  const { customWarningModal, showCustomWarningPopup } = useShowCustomWarningPopup()

  const onChangeFile = async (e: FormEvent<HTMLInputElement>) => {
    const currentFile = e?.currentTarget?.files?.[0]

    if (currentFile && currentFile.size > MAX_FILE_SIZE_IN_BYTES) {
      showCustomWarningPopup(
        t('error'),
        <>
          {t('file.sizeRestriction.1')}{' '}
          <span className="font-medium text-error-500">{formatFileSize(currentFile.size)}</span>,{' '}
          {t('file.sizeRestriction.2')}{' '}
          <span className="font-medium text-error-500">{MAX_FILE_SIZE} MB</span>
        </>,
      )

      return
    }

    if (currentFile) {
      setFileName(currentFile.name)
      onChangeFileName?.(currentFile.name)
      setFileSize(currentFile.size)
      await getFile(currentFile)
    }
  }

  const onDeleteFile = () => {
    showConfirmation({
      title: t('delete'),
      description: t('file.deleteConfirmation'),
      onConfirm: () => {
        setFileUrl('')
        setFileName('')
        onChange?.('')
        setFileSize(undefined)
      },
    })
  }

  return (
    <>
      <UploadInput
        containerClassName={containerClassName}
        onClickDownload={() => {
          window.open(fileUrl, '_blank', 'popup=yes')
        }}
        disabled={disabled}
        accept={accept}
        onChangeFile={onChangeFile}
        onDeleteFile={onDeleteFile}
        fileName={fileName || (defaultFile ? defaultName : undefined)}
        fileSize={fileSize}
      />
      {confirmationModal}
      {customWarningModal}
    </>
  )
}

const MAX_FILE_SIZE = 10 // MB
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE * MB
