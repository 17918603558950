import { useShowConfirmationPopup } from '@expane/ui'
import { useTranslation } from 'react-i18next'
import { IoClose } from 'react-icons/io5'

// Hooks
// Используется для подтверждения закрытия диалога с формой, чтобы не потерять
// внесённые изменения
export const useShowPopupOnDirtyFormClose = (
  formState: { isDirty: boolean; isSubmitted: boolean } | null,
  closeDialog: () => void,
) => {
  const { t } = useTranslation()

  const isDirty = formState?.isDirty
  const isSubmitted = formState?.isSubmitted

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const checkChange = () => {
    if (isDirty && !isSubmitted) {
      showConfirmation({
        title: t('popupOnDirtyFormClose.title'),
        description: t('popupOnDirtyFormClose.description'),
        confirmButton: { text: t('close'), Icon: IoClose },
        cancelButton: { text: t('popupOnDirtyFormClose.cancelButton') },
        onConfirm: closeDialog,
      })
    } else {
      closeDialog()
    }
  }

  return {
    confirmPopup: confirmationModal,
    closePopups: checkChange,
  }
}
