import {
  BranchWithSchedule,
  ServerServiceType,
  useFetchBranches,
  useFetchServiceGroups,
  useFetchServices,
} from '@expane/data'
import {
  ChooseButton,
  CloseButton,
  Dialog,
  Modal,
  Paper,
  PlaceholderDialog,
  SelectDropdown,
  Spinner,
  Switch,
  usePopupOpenState,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useBranchImportState } from 'logic/hooks/useBranchImportState'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { onChangeMultiTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { useBranchImportServicesLocationsDialog } from 'widgets/BranchImportServiceDialog/ServiceLocationDialog'

type Props = {
  closeDialog: () => void
}
const BranchImportServiceDialog: FC<Props> = ({ closeDialog }) => {
  const { data: branches, isLoading } = useFetchBranches()

  useErrorOpeningDialog(!isLoading && !branches, closeDialog)
  if (isLoading) return <BranchImportServiceDialogPlaceholder closeDialog={closeDialog} />
  if (!branches) return null

  return <LoadedBranchImportServiceDialog branches={branches} closeDialog={closeDialog} />
}

type ServiceTreeItem = TreeMenuItem & ServerServiceType

const LoadedBranchImportServiceDialog: FC<{
  closeDialog: () => void
  branches: BranchWithSchedule[]
}> = ({ branches, closeDialog }) => {
  const { t } = useTranslation()

  const { selectedBranchId, setSelectedBranchId, branchesWithoutCurrent } =
    useBranchImportState(branches)

  const [selectedItems, setSelectedItems] = useState<ServiceTreeItem[]>([])
  const [ignoreGroups, setIgnoreGroups] = useState(false)

  const [showSnackbar] = useSnackbar()

  const { openBranchImportServiceLocationsDialog, branchImportServicesLocationsDialog } =
    useBranchImportServicesLocationsDialog()

  const { data: services, isFetching: isFetchingServices } = useFetchServices(
    selectedBranchId,
    'service',
  )
  const { data: serviceGroups, isFetching: isFetchingServiceGroups } =
    useFetchServiceGroups(selectedBranchId)
  const isFetchingTreeItems = isFetchingServiceGroups || isFetchingServices

  const treeItems: ServiceTreeItem[] = useMemo(
    () =>
      transformDataForTreeMenu(ignoreGroups ? [] : serviceGroups, services, {
        keepEmptyFolders: true,
      }),
    [ignoreGroups, services, serviceGroups],
  )

  const areThereGroups = Boolean(serviceGroups?.length)

  return (
    <>
      <Modal close={closeDialog} animation="onlyFadeOut">
        <Dialog>
          <Dialog.Title>{t('importServices.select')}</Dialog.Title>

          <Dialog.Body className="flex flex-col h-120">
            {branchesWithoutCurrent.length > 1 ? (
              <SelectDropdown
                items={branchesWithoutCurrent}
                value={selectedBranchId}
                onSelectChange={value => {
                  setSelectedItems([])
                  setSelectedBranchId(value)
                }}
                label={t('branch.name')}
                className="mb-2"
              />
            ) : null}
            {areThereGroups && (
              <Switch
                containerClassName="mb-2"
                onChange={isChecked => {
                  setIgnoreGroups(isChecked)
                  setSelectedItems([])
                  if (isChecked)
                    showSnackbar(t('importServices.ignoreServiceGroupsWarning'), 'warning', 5000)
                }}
                checked={ignoreGroups}
                label={t('importServices.ignoreServiceGroups')}
              />
            )}

            <Paper className={'flex-1 py-4 px-2 overflow-y-scroll'}>
              {isFetchingTreeItems ? (
                <Spinner expandCentered />
              ) : (
                <TreeMenu
                  items={treeItems}
                  type="MultiPickMode"
                  selected={selectedItems}
                  onSelected={item => {
                    setSelectedItems(onChangeMultiTreeMenu(item, selectedItems, treeItems))
                  }}
                />
              )}
            </Paper>
          </Dialog.Body>

          <Dialog.Footer>
            <ChooseButton
              onClick={() =>
                openBranchImportServiceLocationsDialog({
                  items: selectedItems,
                  closeChooseServiceDialog: closeDialog,
                  ignoreGroups,
                })
              }
              disabled={selectedItems.length === 0}
            />

            <CloseButton onClick={closeDialog} />
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {branchImportServicesLocationsDialog}
    </>
  )
}

export const useBranchImportServiceDialog = () => {
  const { closePopup, openPopup, isOpen } = usePopupOpenState()

  const branchImportServiceDialog = isOpen ? (
    <BranchImportServiceDialog closeDialog={closePopup} />
  ) : null

  return { branchImportServiceDialog, openBranchImportServiceDialog: openPopup }
}

export const BranchImportServiceDialogPlaceholder: FC<{ closeDialog: () => void }> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()
  return (
    <PlaceholderDialog className="h-120" title={t('services')} closeDialog={closeDialog}>
      <Spinner expandCentered />
    </PlaceholderDialog>
  )
}
