import { EmployeeByIdExtended, EmployeeScheduleDto } from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline } from 'react-icons/io5'
import { ButtonWithBillingCheck, SaveButton } from 'widgets/Buttons'
import { useEmployeeScheduleDialog } from 'widgets/EmployeeScheduleDialog'
import { useOpenStopEmployeeScheduleDialog } from 'widgets/StopEmployeeScheduleDialog'

type Props = {
  employee: EmployeeByIdExtended
  myPermissions: string[]
  isBusinessHasOneBranch: boolean
}

export const EmployeeDialogScheduleTab: FC<Props> = ({
  employee,
  myPermissions,
  isBusinessHasOneBranch,
}) => {
  const { t } = useTranslation()

  const {
    employeeScheduleDialog,
    openEditEmployeeScheduleDialog,
    openCreateEmployeeScheduleDialog,
  } = useEmployeeScheduleDialog()

  const { showStopEmployeeScheduleDialog, stopEmployeeScheduleDialog } =
    useOpenStopEmployeeScheduleDialog()

  const formatDate = useDateFormatting()

  const columns = useMemo<ColumnDef<EmployeeScheduleDto>[]>(
    () => [
      {
        id: 'name',
        accessorFn: employeeSchedule =>
          employeeSchedule.isDynamic ? t('schedule.type.dynamic') : employeeSchedule.schedule.name,
        header: t('schedule.name'),
        cell: data => data.getValue(),
        size: 200,
      },
      {
        accessorKey: 'startDate',
        header: t('startDate'),
        cell: data => formatDate('historyDate', data.getValue<Date>()),
        size: 200,
      },
      {
        accessorKey: 'endDate',
        header: t('endDate'),
        size: 200,
        cell: data => {
          if (data.getValue()) return formatDate('historyDate', data.getValue<Date>())

          return (
            <ButtonWithBillingCheck
              type="outline"
              size="small"
              Icon={IoCalendarOutline}
              onClick={event => {
                event.stopPropagation()
                showStopEmployeeScheduleDialog({
                  employeeScheduleId: data.row.original.id,
                  employeeId: data.row.original.employeeId,
                })
              }}
            >
              {t('stopSchedule.shortName')}
            </ButtonWithBillingCheck>
          )
        },
      },
      ...(isBusinessHasOneBranch
        ? []
        : [
            {
              id: 'branchName',
              header: t('branch.name'),
              accessorFn: employeeSchedule => employeeSchedule.branch.name,
              cell: data => data.getValue(),
              size: 400,
            },
          ]),
    ],
    [formatDate, isBusinessHasOneBranch, showStopEmployeeScheduleDialog, t],
  )

  const isCreateAllowed = myPermissions.includes(permissions.schedule.set) && !employee.archived

  return (
    <>
      {isCreateAllowed && (
        <SaveButton
          onClick={() => openCreateEmployeeScheduleDialog(employee.id)}
          isCreate
          className="mb-2"
        />
      )}
      <Table
        columns={columns}
        data={employee.employeeSchedules}
        onRowClick={employeeSchedule => openEditEmployeeScheduleDialog(employeeSchedule.id)}
      />
      {employeeScheduleDialog}
      {stopEmployeeScheduleDialog}
    </>
  )
}
