import { ServerTransactionInBookingType } from '@expane/data'
import { CloseButton, Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TransactionDialog } from 'widgets/TransactionDialog'
import { TransactionsDialogList } from 'widgets/TransactionsDialog/List'

interface Props {
  transactions: ServerTransactionInBookingType[]
  closeDialog: () => void
}

export const useOpenTransactionsDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()
  const dialogTransactions = useRef<ServerTransactionInBookingType[] | null>(null)

  const openTransactionsDialog = (
    transactions: ServerTransactionInBookingType[],
    onClose?: () => void,
  ) => {
    dialogTransactions.current = transactions
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const transactionsDialog =
    isOpen && dialogTransactions.current ? (
      <TransactionsDialog closeDialog={closeDialog} transactions={dialogTransactions.current} />
    ) : null

  return { openTransactionsDialog, transactionsDialog }
}

const TransactionsDialog: FC<Props> = ({ transactions, closeDialog }) => {
  const { dialog: transactionDialog, openEditDialog: editTransactionDialog } =
    useOpenDialog(TransactionDialog)

  const { t } = useTranslation()

  return (
    <>
      <Modal close={closeDialog}>
        <Dialog>
          <Dialog.Title>{t('transactionsList')}</Dialog.Title>

          <Dialog.Body className="w-192">
            <TransactionsDialogList
              transactions={transactions}
              onClickItem={editTransactionDialog}
            />
          </Dialog.Body>

          <Dialog.Footer>
            <CloseButton onClick={closeDialog} />
          </Dialog.Footer>
        </Dialog>
      </Modal>
      {transactionDialog}
    </>
  )
}
