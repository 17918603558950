import {
  ClientPhones,
  ServerAccountType,
  TRANSACTION_TYPES,
  useCreateTransactions,
  useFetchAccounts,
  useFetchClientBalance,
  useFetchClientsBriefs,
  useFetchClientsPhones,
  useFetchCurrentBranchTimezone,
  useGetBranchDefaultAccountId,
} from '@expane/data'
import { getCurrentAccount } from '@expane/logic/accounts'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { addPhonesToClients } from '@expane/logic/client'
import { checkOnlyPositiveAmount, PLACEHOLDERS } from '@expane/logic/form'
import { getDepositTransactions } from '@expane/logic/transaction'
import { findById, getPercentOfAmount } from '@expane/logic/utils'
import {
  Button,
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  Modal,
  PlaceholderInput,
  SelectDropdown,
  usePopupOpenState,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCashOutline } from 'react-icons/io5'
import { store } from 'store'
import { ClientSelectDropdown } from 'widgets/ClientSelectDropdown'
import { OnClientAccount } from 'widgets/OperationsWithClientAccount/OnClientAccount'
import { ButtonWithBillingCheck } from 'widgets/Buttons'
import { useAccountsWithoutCheckbox } from '@expane/logic/payment/checkbox'

type AddToAccountFormValues = {
  amount: string
  toAccountId: number
  clientId: number | null
}

interface AddToClientAccountDialogProps {
  // eslint doesn't understand that we use this prop
  clientId?: number // eslint-disable-line react/no-unused-prop-types
  closeDialog: () => void
}

interface AddToClientAccountDialogLogicProps extends AddToClientAccountDialogProps {
  defaultClientId: number | undefined
  clientsPhones: ClientPhones[]
  accounts: ServerAccountType[]
  defaultAccountId: number
  branchId: number
  timezone: string
}

const AddToClientAccountDialog: FC<AddToClientAccountDialogProps> = observer(
  ({ clientId, closeDialog }) => {
    const branchId = store.branch.branchId
    const timezone = useFetchCurrentBranchTimezone(branchId)

    const { data: clients, isLoading: isLoadingClients } = useFetchClientsBriefs(branchId, timezone)
    const { data: clientsPhones, isLoading: isLoadingClientsPhones } = useFetchClientsPhones()
    const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)
    const { data: defaultAccountId, isLoading: isLoadingDefaultAccountId } =
      useGetBranchDefaultAccountId(branchId)

    const isLoading =
      isLoadingAccounts ||
      isLoadingDefaultAccountId ||
      isLoadingClients ||
      isLoadingClientsPhones ||
      !timezone
    const isNoData = !accounts || !clients || !defaultAccountId || !clientsPhones || !branchId

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
    if (isLoading) return <AddToClientAccountDialogPlaceholder closeDialog={closeDialog} />
    else if (isNoData) return null

    return (
      <AddToClientAccountDialogLogic
        timezone={timezone}
        closeDialog={closeDialog}
        defaultClientId={clientId ?? clients[0]?.id}
        clientsPhones={clientsPhones}
        clientId={clientId}
        defaultAccountId={defaultAccountId}
        accounts={accounts}
        branchId={branchId}
      />
    )
  },
)

const AddToClientAccountDialogLogic: FC<AddToClientAccountDialogLogicProps> = ({
  closeDialog,
  accounts,
  defaultAccountId,
  clientsPhones,
  branchId,
  defaultClientId,
  timezone,
}) => {
  const { accountsWithoutCheckbox } = useAccountsWithoutCheckbox(accounts)

  const { t } = useTranslation()
  const { formState, control, handleSubmit } = useForm<AddToAccountFormValues>({
    defaultValues: {
      clientId: defaultClientId,
      amount: '',
      toAccountId: findById(defaultAccountId, accountsWithoutCheckbox)?.id,
    },
  })

  const { mutateAsync: insertTransactions } = useCreateTransactions()

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackbar] = useSnackbar()

  const watchedClientId = useWatch({
    control,
    name: 'clientId',
    defaultValue: defaultClientId,
  })
  const { data: clients } = useFetchClientsBriefs(branchId, timezone, watchedClientId)

  const { data: clientBalance, isFetching } = useFetchClientBalance(watchedClientId, branchId)

  const mutateAccounts: SubmitHandler<AddToAccountFormValues> = async data => {
    const { amount, clientId, toAccountId } = data

    if (clientId) {
      const currentAccount = getCurrentAccount(accountsWithoutCheckbox, toAccountId)

      const transactions = getDepositTransactions({
        clientBalance: clientBalance ?? 0,
        amount,
        clientId,
        toAccountId,
        branchId,
      })

      try {
        // затраты на комиссию
        if (currentAccount?.commission) {
          const commissionAmount = getPercentOfAmount(Number(amount), currentAccount.commission)
          transactions.push({
            fromAccountId: toAccountId,
            type: TRANSACTION_TYPES.expenses.id,
            amount: commissionAmount,
            branchId,
          })
        }
        await insertTransactions({ paymentTransactions: transactions })

        openSnackbar(t('transaction.addingToAccountCompletedSuccessfully'), 'success')
      } catch (error) {
        if (isRestrictionError(error)) openSnackbar(t('planRestriction'), 'error')
        else if (isPlanError(error)) openSnackbar(t('planInfo.noPlan'), 'error')
        else openSnackbar(t('submitError'), 'error')
      }
    }

    closeDialog()
  }

  const clientsWithPhones = addPhonesToClients(clients, clientsPhones)

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (!formState.isSubmitting && formState.isDirty) handleSubmit(mutateAccounts)()
        }}
      >
        <Dialog>
          <Dialog.Title>{t('transactionNames.addToClientAccount')}</Dialog.Title>

          <Dialog.Body>
            <Controller
              name="clientId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <ClientSelectDropdown
                  value={value}
                  clients={clientsWithPhones}
                  onChange={onChange}
                  errorMessage={{
                    isShown: Boolean(formState.errors.clientId),
                    text: t('formError.required'),
                  }}
                  required
                />
              )}
            />

            <OnClientAccount
              clientBalance={clientBalance}
              isFetching={isFetching}
              branchId={branchId}
            />

            <div className="mt-3 flex">
              <Controller
                name="amount"
                control={control}
                rules={{ required: true, validate: checkOnlyPositiveAmount }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="number"
                    label={t('amount')}
                    placeholder={PLACEHOLDERS.servicePrice}
                    value={value}
                    onChange={onChange}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.amount),
                      text:
                        formState.errors.amount?.type === 'required'
                          ? t('formError.required')
                          : t('formError.invalidValue'),
                    }}
                    containerClassName="w-1/2 mr-2"
                    autoFocus
                  />
                )}
              />

              <Controller
                name="toAccountId"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <SelectDropdown
                    value={value}
                    label={t('account.name')}
                    placeholder={t('placeholders.defaultSelect')}
                    items={accountsWithoutCheckbox}
                    noDataMessage={t('noSuchAccount')}
                    onSelectChange={onChange}
                    popupHeight="small"
                    errorMessage={{
                      isShown: Boolean(formState.errors.toAccountId),
                      text: t('formError.required'),
                    }}
                    required
                    className="w-1/2"
                  />
                )}
              />
            </div>
          </Dialog.Body>

          <Dialog.Footer>
            <ButtonWithBillingCheck
              className="ml-2"
              onClick={handleSubmit(mutateAccounts)}
              disabled={formState.isSubmitting || !formState.isDirty}
              spinner={formState.isSubmitting}
              Icon={IoCashOutline}
            >
              {t('addToAccount')}
            </ButtonWithBillingCheck>

            <CloseButton
              onClick={closePopups}
              disabled={formState.isSubmitting}
              className="mr-auto"
            />
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {confirmPopup}
    </>
  )
}

const AddToClientAccountDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('transactionNames.addToClientAccount')}</Dialog.Title>

        <Dialog.Body>
          <PlaceholderInput label={t('client.name')} className="mb-4" />

          <OnClientAccount clientBalance={undefined} isFetching={true} branchId={undefined} />

          <div className="mt-3 flex mb-4">
            <PlaceholderInput label={t('amount')} className="w-1/2 mr-2" />
            <PlaceholderInput label={t('account.name')} className="w-1/2" />
          </div>
        </Dialog.Body>

        <Dialog.Footer>
          <Button disabled Icon={IoCashOutline}>
            {t('addToAccount')}
          </Button>

          <CloseButton onClick={closeDialog} className="mr-auto" />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

export const useOpenAddToClientAccountDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const clientId = useRef<number | undefined>()
  const onCloseDialog = useRef<() => void>()

  const openAddToClientAccountDialog = (id?: number, onClose?: () => void) => {
    clientId.current = id
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const addToClientAccountDialog = isOpen ? (
    <AddToClientAccountDialog closeDialog={closeDialog} clientId={clientId.current} />
  ) : null

  return { openAddToClientAccountDialog, addToClientAccountDialog }
}
