import { FC } from 'react'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { onChangeSingleTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { treeMenuClassName } from 'ui/styles'
import { PaperResizable } from 'ui/PaperResizable'

export const ProductsTreeMenu: FC<{
  onChange: (item: TreeMenuItem | undefined) => void
  editProductGroup: (id: number) => void
  editProduct: (id: number) => void
  selectedItem: TreeMenuItem | undefined
  items: TreeMenuItem[]
}> = ({ onChange, selectedItem, editProductGroup, editProduct, items }) => {
  return (
    <PaperResizable defaultWidth={'1/4'} withoutBorder>
      <TreeMenu
        type="SinglePickMode"
        className={treeMenuClassName + ' h-full'}
        items={items}
        selected={selectedItem}
        onSelected={item => onChange(onChangeSingleTreeMenu(item, selectedItem))}
        onEditFolder={folder => editProductGroup(folder.id)}
        onEditItem={item => editProduct(item.id)}
        openSwitch
      />
    </PaperResizable>
  )
}
