import { FC } from 'react'

interface StarRatingProps {
  rating: number
  size: string
  className?: string
  onChange?: (rating: number) => void
}

export const StarRating: FC<StarRatingProps> = ({ rating, size, onChange, className = '' }) => {
  const fullStars = Math.floor(rating)
  const hasHalfStar = rating % 1 !== 0

  return (
    <div className={`flex items-center ${className}`}>
      {[...Array(TOTAL_STARS)].map((_, index) => {
        if (index < fullStars) {
          return (
            <svg
              key={index}
              width={size}
              height={size}
              className={`text-yellow-500 mr-1.5 ${onChange ? 'cursor-pointer' : ''}`}
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => onChange?.(index + 1)}
            >
              <path
                d="M12.1728 21.1833C12.6812 20.8764 13.3179 20.8764 13.8263 21.1833L19.0562 24.3399C20.2679 25.0712 21.7627 23.9848 21.4411 22.6065L20.0531 16.6579C19.9181 16.0795 20.1147 15.4739 20.5636 15.085L25.1864 11.0804C26.2562 10.1537 25.6843 8.39639 24.274 8.27678L18.191 7.76087C17.5997 7.71072 17.0847 7.33739 16.8531 6.79099L14.4727 1.17479C13.9213 -0.126014 12.0778 -0.126011 11.5264 1.1748L9.14596 6.79099C8.91437 7.33739 8.39937 7.71072 7.80804 7.76087L1.72512 8.27678C0.314801 8.39639 -0.257083 10.1537 0.812708 11.0804L5.43547 15.085C5.8844 15.4739 6.08096 16.0795 5.94599 16.6579L4.55799 22.6065C4.23638 23.9848 5.73119 25.0712 6.94292 24.3399L12.1728 21.1833Z"
                fill="#FDE047"
              />
            </svg>
          )
        } else if (index === fullStars && hasHalfStar) {
          return (
            <div
              key={index}
              className={`relative mr-1.5 ${onChange ? 'cursor-pointer' : ''}`}
              style={{ width: `${size}px`, height: `${size}px` }}
              onClick={() => onChange?.(index + 1)}
            >
              <svg
                width={size}
                height={size}
                viewBox="0 0 26 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-0 left-0"
                style={{ clipPath: 'inset(0 50% 0 0)' }}
              >
                <path
                  d="M12.1728 21.1833C12.6812 20.8764 13.3179 20.8764 13.8263 21.1833L19.0562 24.3399C20.2679 25.0712 21.7627 23.9848 21.4411 22.6065L20.0531 16.6579C19.9181 16.0795 20.1147 15.4739 20.5636 15.085L25.1864 11.0804C26.2562 10.1537 25.6843 8.39639 24.274 8.27678L18.191 7.76087C17.5997 7.71072 17.0847 7.33739 16.8531 6.79099L14.4727 1.17479C13.9213 -0.126014 12.0778 -0.126011 11.5264 1.1748L9.14596 6.79099C8.91437 7.33739 8.39937 7.71072 7.80804 7.76087L1.72512 8.27678C0.314801 8.39639 -0.257083 10.1537 0.812708 11.0804L5.43547 15.085C5.8844 15.4739 6.08096 16.0795 5.94599 16.6579L4.55799 22.6065C4.23638 23.9848 5.73119 25.0712 6.94292 24.3399L12.1728 21.1833Z"
                  fill="#FDE047"
                />
              </svg>
              <svg
                width={size}
                height={size}
                viewBox="0 0 26 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-0 left-0"
                style={{ clipPath: 'inset(0 0 0 50%)' }}
              >
                <path
                  d="M12.1728 21.1833C12.6812 20.8764 13.3179 20.8764 13.8263 21.1833L19.0562 24.3399C20.2679 25.0712 21.7627 23.9848 21.4411 22.6065L20.0531 16.6579C19.9181 16.0795 20.1147 15.4739 20.5636 15.085L25.1864 11.0804C26.2562 10.1537 25.6843 8.39639 24.274 8.27678L18.191 7.76087C17.5997 7.71072 17.0847 7.33739 16.8531 6.79099L14.4727 1.17479C13.9213 -0.126014 12.0778 -0.126011 11.5264 1.1748L9.14596 6.79099C8.91437 7.33739 8.39937 7.71072 7.80804 7.76087L1.72512 8.27678C0.314801 8.39639 -0.257083 10.1537 0.812708 11.0804L5.43547 15.085C5.8844 15.4739 6.08096 16.0795 5.94599 16.6579L4.55799 22.6065C4.23638 23.9848 5.73119 25.0712 6.94292 24.3399L12.1728 21.1833Z"
                  fill="#D1D5DB"
                />
              </svg>
            </div>
          )
        } else {
          return (
            <svg
              key={index}
              width={size}
              height={size}
              className={`text-gray-300 mr-1.5 ${onChange ? 'cursor-pointer' : ''}`}
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => onChange?.(index + 1)}
            >
              <path
                d="M12.1728 21.1833C12.6812 20.8764 13.3179 20.8764 13.8263 21.1833L19.0562 24.3399C20.2679 25.0712 21.7627 23.9848 21.4411 22.6065L20.0531 16.6579C19.9181 16.0795 20.1147 15.4739 20.5636 15.085L25.1864 11.0804C26.2562 10.1537 25.6843 8.39639 24.274 8.27678L18.191 7.76087C17.5997 7.71072 17.0847 7.33739 16.8531 6.79099L14.4727 1.17479C13.9213 -0.126014 12.0778 -0.126011 11.5264 1.1748L9.14596 6.79099C8.91437 7.33739 8.39937 7.71072 7.80804 7.76087L1.72512 8.27678C0.314801 8.39639 -0.257083 10.1537 0.812708 11.0804L5.43547 15.085C5.8844 15.4739 6.08096 16.0795 5.94599 16.6579L4.55799 22.6065C4.23638 23.9848 5.73119 25.0712 6.94292 24.3399L12.1728 21.1833Z"
                fill="#D1D5DB"
              />
            </svg>
          )
        }
      })}
    </div>
  )
}

const TOTAL_STARS = 5
