import { useAreManyEmployeesAllowed } from '@expane/logic/billing'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { Button } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { Component, ReactNode } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { IoAlertCircleOutline, IoHomeOutline } from 'react-icons/io5'
import { routes } from 'routes/logic'
import { queryClient } from 'services/api'
import { navigation } from 'services/navigation'
import { reportError } from 'services/sentry'
import { useFeatureFlags } from '@expane/logic/featureFlags'

const ErrorBoundary = Component => {
  return observer(function WrappedComponent(props) {
    const { data: myPermissions } = useFetchMyPermissions(Boolean(queryClient))
    const { enabledModules } = useBusinessModulesSettings()
    const { areManyEmployeesAllowed } = useAreManyEmployeesAllowed()
    const { getFeatureFlag } = useFeatureFlags()

    const navigationRootRoutes = routes.getTopBarRoutes(
      myPermissions,
      enabledModules,
      areManyEmployeesAllowed,
      getFeatureFlag,
    )

    return <Component {...props} route={navigationRootRoutes?.[0].path} />
  })
}

interface PropsType extends WithTranslation {
  children: ReactNode
  route: string
}

class ErrorBoundaryItem extends Component<PropsType, { hasError: boolean }> {
  constructor(props: PropsType) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    reportError(error, 'fatal', { errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex-1 flex-centered flex-col h-full bg-main">
          <IoAlertCircleOutline size="6rem" className="text-error-200 dark:text-error-400 mb-6" />
          <h2 className="text-gray-400 text-4xl">{this.props.t('errorBoundary.occurred')}</h2>
          <p className="text-gray-400 text-xl mt-3">{this.props.t('errorBoundary.fixing')}</p>

          <Button
            type="outline"
            className="mt-12"
            onClick={() => {
              navigation.push(this.props.route)
              window.location.reload()
            }}
            Icon={IoHomeOutline}
          >
            {this.props.t('errorPage.3')}
          </Button>
        </div>
      )
    }

    return this.props.children
  }
}

const ErrorBoundaryItemWithTranslation = withTranslation()(ErrorBoundaryItem)

export default ErrorBoundary(ErrorBoundaryItemWithTranslation)
