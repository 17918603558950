import {
  EmployeeGroupWithSchedule,
  useCancelTimeOffs,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchTimeOffsOfPeriod,
} from '@expane/data'
import {
  CloseButton,
  CommonPlaceholderDialogProps,
  PlaceholderInput,
  usePopupOpenState,
} from '@expane/ui'
import { useFetchMyEmployee } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { observer } from 'mobx-react-lite'
import { FC, RefObject, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { Dialog, Modal, useShortCut } from '@expane/ui'
import { RangeDatePicker } from 'ui/RangeDatePicker'
import { ArchiveButton, SaveButton } from 'widgets/Buttons'
import { TreeSelect } from 'ui/TreeSelect'
import { TreeMenuItem } from 'ui/TreeMenu'
import { convertEmployeeGroupsToTreeItems } from 'ui/TreeMenu/logic.employee'
import { useSnackbar } from '@expane/widgets'
import { getDefaultTimeOffsPeriod } from 'widgets/TimeOffsDialog/logic'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import { DEFAULT_TIMEZONE, endOfDay, startOfDay } from '@expane/date'

interface Props {
  closeDialog: () => void
}

const TimeOffsArchiveDialog: FC<Props> = observer(props => {
  const modalRef = useRef<HTMLDivElement>(null)

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { data: myEmployee, isLoading: isLoadingMyEmployee } = useFetchMyEmployee(
    timezone,
    branchId,
  )
  const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } = useFetchEmployeesGroups(
    timezone,
    branchId,
  )

  const isLoading = isLoadingMyEmployee || isLoadingEmployeeGroups

  const isNoData = !timezone || !myEmployee || !employeeGroups

  useErrorOpeningDialog(!isLoading && isNoData, props.closeDialog)
  if (!isLoading && isNoData) return null

  return (
    <Modal close={props.closeDialog} ref={modalRef}>
      <Dialog>
        {isLoading ? (
          <TimeOffsArchiveDialogPlaceholder closeDialog={props.closeDialog} />
        ) : (
          <TimeOffsArchiveDialogLogic
            closeDialog={props.closeDialog}
            timezone={timezone ?? DEFAULT_TIMEZONE}
            modalRef={modalRef}
            employeeGroups={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              employeeGroups!
            }
          />
        )}
      </Dialog>
    </Modal>
  )
})

const TimeOffsArchiveDialogLogic: FC<{
  closeDialog: () => void
  timezone: string
  modalRef: RefObject<HTMLDivElement>
  employeeGroups: EmployeeGroupWithSchedule[]
}> = ({ closeDialog, timezone, modalRef, employeeGroups }) => {
  const { mutateAsync: cancelTimeOffs } = useCancelTimeOffs()

  const { t } = useTranslation()

  const employeeTreeMenuItems = convertEmployeeGroupsToTreeItems(employeeGroups)

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<TimeOffsArchiveDialogForm>({
    defaultValues: {
      date: getDefaultTimeOffsPeriod({
        timezone,
      }),
    },
  })

  const watchedPeriod = watch('date')

  const { data: timeOffs, isLoading } = useFetchTimeOffsOfPeriod(
    startOfDay(watchedPeriod[0]),
    endOfDay(watchedPeriod[1]),
    timezone,
  )

  const [openSnackbar] = useSnackbar()

  const archiveTimeOffs: SubmitHandler<TimeOffsArchiveDialogForm> = async ({ employees }) => {
    const extractedEmployees = extractItemsFromFolders(employees)
    const employeeIds = extractedEmployees.map(({ id }) => id)

    const timeOffsByEmployeeIds =
      timeOffs
        ?.filter(timeOff => employeeIds.some(id => id === timeOff.employeeId))
        .map(({ id }) => id) ?? []

    if (timeOffsByEmployeeIds.length > 0) {
      const result = await cancelTimeOffs({ ids: timeOffsByEmployeeIds })

      if (result?.update_timeOff_many) {
        openSnackbar(t('timeOffs.archivedSuccess'), 'success')
      } else {
        openSnackbar(t('submitError'), 'error')
      }
    } else openSnackbar(t('timeOffs.archivedError'), 'error')

    closeDialog()
  }

  useShortCut(
    ['Enter'],
    () => {
      handleSubmit(archiveTimeOffs)()
    },
    modalRef.current,
  )

  if (isLoading) return <TimeOffsArchiveDialogPlaceholder closeDialog={closeDialog} />

  return (
    <>
      <Dialog.Title>{t('timeOffs.archiveTimeOffs')}</Dialog.Title>

      <Dialog.Body className="w-148">
        <Controller
          control={control}
          name="employees"
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TreeSelect
              type="MultiPickMode"
              items={employeeTreeMenuItems}
              onSelected={onChange}
              selected={value as TreeMenuItem[]}
              placeholder={t('placeholders.defaultSelect')}
              label={t('employees.name')}
              errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
              required
            />
          )}
        />

        <Controller
          control={control}
          name="date"
          render={({ field: { value, onChange } }) => (
            <RangeDatePicker
              timezone={timezone}
              value={value}
              onChange={onChange}
              label={t('timeOffs.period')}
            />
          )}
        />
      </Dialog.Body>

      <Dialog.Footer>
        <ArchiveButton onClick={handleSubmit(archiveTimeOffs)} disabled={isSubmitting} />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}

export const useOpenTimeOffsArchiveDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const openTimeOffsArchiveDialog = () => {
    openPopup()
  }

  const timeOffsArchiveDialog = isOpen ? <TimeOffsArchiveDialog closeDialog={closePopup} /> : null

  return { openTimeOffsArchiveDialog, timeOffsArchiveDialog }
}

const TimeOffsArchiveDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('timeOffs.name')}</Dialog.Title>

      <Dialog.Body className="w-148">
        <PlaceholderInput label={t('employees.name')} className={'w-full'} />

        <PlaceholderInput label={t('timeOffs.period')} className={'w-full'} />
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton disabled />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}

type TimeOffsArchiveDialogForm = {
  employees: TreeMenuItem[]
  date: [Date, Date]
}
