import { gql } from 'graphql-request'
import { reportError, reportGqlError, requestAnonymously, useQuery } from '../api'
import { ServerFeatureFlags } from '../generated/graphql-types'

const FEATURE_FLAGS_QUERY_KEY = 'featureFlags'

export function useFetchFeatureFlags() {
  return useQuery<ServerFeatureFlags[]>(
    [FEATURE_FLAGS_QUERY_KEY],
    async () => {
      const result = await requestAnonymously(
        gql`
          query {
            featureFlags {
              key
              enabled
            }
          }
        `,
      )

      if (Array.isArray(result?.featureFlags)) {
        return result.featureFlags
      } else {
        reportError(new Error('featureFlags is not an array'), 'error', { result })
        return []
      }
    },
    {
      queryName: 'useFetchFeatureFlags',
      onError: reportGqlError,
    },
  )
}
