import { Gender } from '@expane/logic/client'
import { GENDER_OPTIONS } from '@expane/logic/utils'
import { HorizontalButtonSwitch, HorizontalButtonSwitchProps } from '@expane/ui'
import { translateData } from 'logic/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoFemaleSharp, IoMaleSharp } from 'react-icons/io5'

type GenderPickerProps = Omit<HorizontalButtonSwitchProps, 'options' | 'onChange'> & {
  onChange: (value: Gender) => void
  value: Gender | undefined
}

export const GenderPicker: FC<GenderPickerProps> = props => {
  const { t } = useTranslation()

  return (
    <HorizontalButtonSwitch
      {...props}
      onChange={props.onChange as (id: string) => void}
      options={translateData(genderOptions, t)}
    />
  )
}

const genderOptions = [
  {
    id: GENDER_OPTIONS.male,
    name: 'gender.m',
    Icon: IoMaleSharp,
  },
  {
    id: GENDER_OPTIONS.female,
    name: 'gender.w',
    Icon: IoFemaleSharp,
  },
]
