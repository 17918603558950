import {
  EmployeeScheduleDto,
  ScheduleType,
  ServerSalaryIssueType,
  ServerTransactionSalaryType,
} from '@expane/data'
import {
  areIntervalsOverlapping,
  eachDayOfInterval,
  isBefore,
  isDateSameDayOrAfter,
  isSameDay,
  isWithinInterval,
} from '@expane/date'
import { getCurrentEmployeeSchedule } from '../employeeSchedule'
import { getIsWorkingDayFromIntervalSchedule, getWeekDay } from '../calendar'
import {
  CountedNotPaidSalaryType,
  CountedSalariesFullInfoByEmployeeType,
  CountedSalariesFullInfoType,
} from './logic'
import { SalaryItem } from './converting'

export const getAmountOfDaysInRange = ({
  fromDate,
  toDate,
  employeeSchedules,
}: {
  fromDate: Date
  toDate: Date
  employeeSchedules: EmployeeScheduleDto[]
}): number => {
  // For each day in interval, we need to find a schedule that covers that day
  // and check if it's working day by that schedule
  const daysOfInterval = eachDayOfInterval({ start: fromDate, end: toDate })

  let daysCount = 0

  for (const day of daysOfInterval) {
    const currentEmployeeSchedule = getCurrentEmployeeSchedule(employeeSchedules, day)
    if (!currentEmployeeSchedule) continue

    if (currentEmployeeSchedule.isDynamic) {
      if (
        currentEmployeeSchedule.dynamicDates.some(dynamicDate => isSameDay(dynamicDate.date, day))
      ) {
        daysCount++
      }
    } else {
      const scheduleStartDate = currentEmployeeSchedule.startDate
      const schedule = currentEmployeeSchedule.schedule

      if (schedule.type === ScheduleType.INTERVAL) {
        if (getIsWorkingDayFromIntervalSchedule(schedule, scheduleStartDate, day)) {
          daysCount++
        }
      }
      if (schedule.type === ScheduleType.WEEKLY) {
        if (schedule.data[getWeekDay(day)] !== null) {
          daysCount++
        }
      }
    }
  }

  return daysCount
}

export const getTransactionsWithSalariesThatOverlappingWithPeriod = (
  transactionsWithSalaries: ServerTransactionSalaryType[],
  fromDate: Date,
  toDate: Date,
) =>
  transactionsWithSalaries.filter(transaction =>
    areIntervalsOverlapping(
      { start: fromDate, end: toDate },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      { start: transaction.startPeriod!, end: transaction.endPeriod! },
    ),
  )

export const getSalaryIssuesByPeriod = (
  salaryIssues: ServerSalaryIssueType[],
  fromDate: Date,
  toDate: Date,
): {
  salaryIssuesInSelectedPeriod: ServerSalaryIssueType[]
  salaryIssuesInPreviousPeriod: ServerSalaryIssueType[]
} => {
  const salaryIssuesInSelectedPeriod = salaryIssues.filter(
    salaryIssue =>
      isDateSameDayOrAfter(salaryIssue.date, fromDate) && isBefore(salaryIssue.date, toDate),
  )

  const salaryIssuesInPreviousPeriod = salaryIssues.filter(salaryIssue =>
    isBefore(salaryIssue.date, fromDate),
  )

  return {
    salaryIssuesInSelectedPeriod,
    salaryIssuesInPreviousPeriod,
  }
}

export const calcTotalSalaryItemsSum = (
  salaryItems: Array<SalaryItem | CountedNotPaidSalaryType>,
) =>
  salaryItems.reduce(
    (sum, salaryItem) => (salaryItem.totalSum ? sum + salaryItem.totalSum : sum),
    0,
  )

export const getPaidSalaryItems = (salaryItems: SalaryItem[]) =>
  salaryItems.filter(item => item.isSalaryPaid)

export const getNotPaidSalaryItems = (salaryItems: SalaryItem[]) =>
  salaryItems.filter(item => !item.isSalaryPaid)

export const getEmployeeSalaryFromCountedSalariesFullInfo = (
  salariesFullInfo: Array<CountedSalariesFullInfoType>,
  employeeId: number,
) => {
  for (let i = 0; i < salariesFullInfo.length; i++) {
    for (let s = 0; s < salariesFullInfo[i].salaries.length; s++) {
      if (salariesFullInfo[i].salaries[s].employeeId === employeeId) {
        return salariesFullInfo[i].salaries[s]
      }
    }
  }
}

export const checkFromDate = (contractStartDate: Date, fromDate: Date, toDate: Date) => {
  const isContractStartsWithinSelectedInterval = isWithinInterval(contractStartDate, {
    start: fromDate,
    end: toDate,
  })

  return isContractStartsWithinSelectedInterval ? contractStartDate : fromDate
}

export const extractNotPaidSalaryFromCountedSalariesFullInfo = (
  countedSalariesFullInfo: Array<CountedSalariesFullInfoByEmployeeType>,
) => {
  return countedSalariesFullInfo.map(({ notPaidSalary }) => notPaidSalary)
}
