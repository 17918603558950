import { CloseButton, Dialog, Modal, Paper, Spinner } from '@expane/ui'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ImportButton } from 'widgets/Buttons'

export const BranchImportLocationsDialogPlaceholder: FC<Pick<DialogProps, 'closeDialog'>> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('importLocations.name')}</Dialog.Title>
        <Dialog.Body className="h-120">
          <Paper className="h-full">
            <Spinner expandCentered />
          </Paper>
        </Dialog.Body>
        <Dialog.Footer>
          <ImportButton disabled />
          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
