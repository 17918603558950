import {
  CardType,
  ServerAccountType,
  ServerTransactionByIdType,
  ServerTransactionType,
  useFetchAccounts,
  useFetchCurrentBranchTimezone,
  useFetchSubscriptionsByClientId,
  useFetchTransactionById,
  useFetchTransactionsByParentId,
  useGetBranchDefaultAccountId,
} from '@expane/data'
import { getCurrentAccount } from '@expane/logic/accounts'
import { checkWithdrawPossibility } from '@expane/logic/form'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { checkIsTransactionPaymentInCredit } from '@expane/logic/transaction'
import {
  getRefundItemsAndType,
  getSubscriptionRefundPriceAndHint,
  removeUsedSubscriptions,
  TransactionSubscriptionFormValues,
} from '@expane/logic/transaction/refund'
import { findById } from '@expane/logic/utils'
import {
  Checkbox,
  Dialog,
  Input,
  Modal,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
  usePopupOpenState,
} from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { AccountSelectDropdown } from 'widgets/AccountSelectDropdown'
import { TransactionSubscriptionRefundDialogFooter } from 'widgets/TransactionSubscriptionDialog/RefundDialog/Footer'
import { TransactionInCreditExplanation } from 'widgets/TransactionInCreditExplanation'

interface TransactionSubscriptionRefundDialogProps {
  onSuccess: () => void
  subscriptionId: number
  transactionId: number
  closeDialog: () => void
}

interface OpenTransactionSubscriptionRefundDialogProps {
  transactionId: number
  subscriptionId: number
  onSuccess: () => void
}

export const useOpenTransactionSubscriptionRefundDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()
  const props = useRef<OpenTransactionSubscriptionRefundDialogProps | null>(null)

  const openTransactionSubscriptionRefundDialog = ({
    transactionId,
    subscriptionId,
    onSuccess,
    onClose,
  }: OpenTransactionSubscriptionRefundDialogProps & {
    onClose?: () => void
  }) => {
    props.current = { transactionId, subscriptionId, onSuccess }
    onCloseDialog.current = onClose

    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const transactionSubscriptionRefundDialog =
    isOpen && props.current ? (
      <TransactionSubscriptionRefundDialog closeDialog={closeDialog} {...props.current} />
    ) : null

  return { openTransactionSubscriptionRefundDialog, transactionSubscriptionRefundDialog }
}

const TransactionSubscriptionRefundDialog: FC<TransactionSubscriptionRefundDialogProps> = observer(
  ({ subscriptionId, transactionId, closeDialog, onSuccess }) => {
    const branchId = store.branch.branchId

    const timezone = useFetchCurrentBranchTimezone(branchId)

    const { data: transactionById } = useFetchTransactionById(transactionId, timezone, branchId)
    const { data: accounts } = useFetchAccounts(branchId)
    const { data: defaultAccountId } = useGetBranchDefaultAccountId(branchId)
    const { data: refundsForTransaction } = useFetchTransactionsByParentId(
      transactionId,
      timezone,
      branchId,
    )
    const { data: clientSubscriptions } = useFetchSubscriptionsByClientId(
      transactionById?.client?.id,
      timezone,
      branchId,
    )

    if (
      !transactionById ||
      !defaultAccountId ||
      !refundsForTransaction ||
      !clientSubscriptions ||
      !accounts
    )
      return null

    return (
      <TransactionSubscriptionRefundDialogLogic
        closeDialog={closeDialog}
        onSuccess={onSuccess}
        transactionById={transactionById}
        subscriptionId={subscriptionId}
        defaultAccountId={defaultAccountId}
        refundsForTransaction={refundsForTransaction}
        clientSubscriptions={clientSubscriptions}
        accounts={accounts}
      />
    )
  },
)

interface TransactionSubscriptionRefundDialogLogicProps {
  defaultAccountId: number
  refundsForTransaction: ServerTransactionType[]
  clientSubscriptions: CardType[]
  transactionById: ServerTransactionByIdType
  onSuccess: () => void
  closeDialog: () => void
  accounts: ServerAccountType[]
  subscriptionId: number
}

export const TransactionSubscriptionRefundDialogLogic: FC<
  TransactionSubscriptionRefundDialogLogicProps
> = ({
  closeDialog,
  transactionById,
  refundsForTransaction,
  clientSubscriptions,
  subscriptionId,
  defaultAccountId,
  accounts,
  onSuccess,
}) => {
  const refundInfo = removeUsedSubscriptions(
    getRefundItemsAndType(transactionById, refundsForTransaction),
    clientSubscriptions,
  )

  const { t } = useTranslation()

  const refundSubscription = refundInfo.items.find(item => item.id === subscriptionId)
  const subscription = transactionById.transactionsCards.find(
    subscription => subscription.card.id === subscriptionId,
  )

  const { totalRefundPrice, hintForTotalRefundPrice } = getSubscriptionRefundPriceAndHint(
    clientSubscriptions,
    t,
    refundSubscription,
  )

  const { getModuleSetting } = useBusinessModulesSettings()
  const isCheckboxEnabled = getModuleSetting('checkbox')

  const { handleSubmit, formState, control } = useForm<TransactionSubscriptionFormValues>({
    defaultValues: {
      item: refundSubscription,
      refundToClientAccount: false,
      fromAccountId: defaultAccountId,
      totalRefundPrice: totalRefundPrice.toString(),
    },
  })

  const watchedRefundToClientAccount = useWatch({ control, name: 'refundToClientAccount' })
  const watchedTotalRefundPrice = useWatch({ control, name: 'totalRefundPrice' })
  const watchedFromAccountId = useWatch({ control, name: 'fromAccountId' })

  const isTransactionInCredit = checkIsTransactionPaymentInCredit(transactionById)

  const noItemsToRefund = refundInfo.items.length === 0
  const isSoftwarePOSAccount = isCheckboxEnabled
    ? Boolean(findById(watchedFromAccountId, accounts)?.accountSoftwarePOS.length)
    : false

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('transaction.creatingRefund')}</Dialog.Title>
        <Dialog.Body className="w-160">
          {noItemsToRefund ? (
            <p>{t('transaction.noRefunds')}</p>
          ) : (
            <>
              <TableContainer>
                <TableHeader>
                  <tr>
                    <TableHeaderCell className="w-112">{t('position')}</TableHeaderCell>
                    <TableHeaderCell className="w-40">{t('price')}</TableHeaderCell>
                  </tr>
                </TableHeader>

                <TableBody>
                  {subscription && (
                    <TableRow>
                      <TableCell>{subscription.card.cardTemplate.name}</TableCell>
                      <TableCell>{subscription.price}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </TableContainer>

              <div className="my-3 flex justify-end">
                <Controller
                  control={control}
                  name="totalRefundPrice"
                  rules={{
                    required: true,
                    validate: value => Number(value) <= totalRefundPrice,
                  }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Input
                      label={`${t('cancellationOfBookingPayment.amountToBeReturned')}:`}
                      hint={hintForTotalRefundPrice}
                      placeholder={totalRefundPrice.toString()}
                      type="number"
                      value={value}
                      onChange={onChange}
                      disabled={totalRefundPrice === 0}
                      errorMessage={{
                        isShown: Boolean(error),
                        text:
                          error?.type === 'required'
                            ? t('formError.required')
                            : `${t('maximumAmount')}: ${totalRefundPrice}`,
                      }}
                    />
                  )}
                />
              </div>

              <div className="flex justify-between">
                {isTransactionInCredit ? (
                  <TransactionInCreditExplanation />
                ) : (
                  <>
                    <div className="flex flex-col justify-center">
                      <Controller
                        control={control}
                        name="refundToClientAccount"
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            label={t('transaction.refundToClientAccount')}
                            disabled={isTransactionInCredit || isSoftwarePOSAccount}
                            hint={t('transaction.refundToClientAccountHint')}
                            onChange={onChange}
                            checked={value}
                          />
                        )}
                      />
                    </div>

                    <Controller
                      name="fromAccountId"
                      control={control}
                      rules={{
                        validate: value => {
                          if (isTransactionInCredit) return true
                          else
                            return checkWithdrawPossibility({
                              currentAccount: getCurrentAccount(accounts, value),
                              value: totalRefundPrice,
                            })
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <AccountSelectDropdown
                          value={value}
                          label={t('fromAccount')}
                          accounts={accounts}
                          onSelectChange={onChange}
                          popupHeight="small"
                          errorMessage={{
                            isShown: Boolean(formState.errors.fromAccountId),
                            text: t('formError.insufficientFunds'),
                          }}
                          required
                          className="w-1/2"
                          disabled={watchedRefundToClientAccount || isTransactionInCredit}
                        />
                      )}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </Dialog.Body>

        <TransactionSubscriptionRefundDialogFooter
          control={control}
          handleSubmit={handleSubmit}
          closeDialog={closeDialog}
          onSuccess={onSuccess}
          transactionById={transactionById}
          totalRefundPrice={Number(watchedTotalRefundPrice)}
          isSubmitting={formState.isSubmitting}
          isCreateRefundButton={!noItemsToRefund}
          accounts={accounts}
        />
      </Dialog>
    </Modal>
  )
}
