import { gql } from 'graphql-request'

export const locationBriefFragment = gql`
  fragment locationBriefType on location {
    id
    name
    maxEmployees
    maxClients
    serviceLocations(where: { branchId: { _eq: $branchId } }) {
      serviceId
    }
  }
`

export const locationAllBranchesBriefFragment = gql`
  fragment locationAllBranchesBriefType on location {
    id
    name
    branchId
  }
`

export const locationByIdFragment = gql`
  fragment locationByIdType on location {
    id
    name
    groupId
    description
    maxClients
    maxEmployees
    archived
    defaultForBranch {
      id
    }
    locationGroup {
      id
      name
    }
  }
`

export const locationWithServiceIdsFragment = gql`
  ${locationByIdFragment}
  fragment locationWithServiceIdsType on location {
    ...locationByIdType
    serviceLocations(where: { branchId: { _eq: $branchId } }) {
      serviceId
    }
  }
`
