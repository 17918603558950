import { useGetLeftovers } from '@expane/data'
import { TableBody, TableContainer, TableHeader, TableHeaderCell } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { ArrivalMovementProps } from 'widgets/MovementCreateDialogs/ArrivalMovementDialog'
import { ProductDto } from 'widgets/MovementCreateDialogs/ChooseMovementProductsDialog'
import { ArrivalMovementRow } from './Row'

interface ListProps extends ArrivalMovementProps {
  products: ProductDto[]
}

export const ArrivalMovementProductsList: FC<ListProps> = observer(({ control, products }) => {
  const { t } = useTranslation()
  const branchId = store.branch.branchId

  const toStorageId = useWatch({ control, name: 'toStorageId' })

  const { data: leftoverProducts, isLoading: isLoadingLeftovers } = useGetLeftovers(
    branchId,
    toStorageId,
  )

  const { fields, remove } = useFieldArray({ control, name: 'products', keyName: 'generatedId' })

  return (
    <TableContainer className="max-h-64">
      <TableHeader>
        <tr>
          <TableHeaderCell className="w-64">{t('product.name')}</TableHeaderCell>
          <TableHeaderCell className="w-24">{t('qty')}</TableHeaderCell>
          <TableHeaderCell className="w-24 text-right">
            {toStorageId ? t('inStock') : t('inStocks')}
          </TableHeaderCell>
          <TableHeaderCell>{t('unit.shortName')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('discount')}</TableHeaderCell>
          <TableHeaderCell>{t('price')}</TableHeaderCell>
          <TableHeaderCell />
          <TableHeaderCell>{t('total')}</TableHeaderCell>
          <TableHeaderCell />
        </tr>
      </TableHeader>
      <TableBody>
        {fields.map((product, index) => (
          <ArrivalMovementRow
            key={product.id}
            index={index}
            control={control}
            disabled={fields.length === 1}
            products={products}
            onDelete={() => remove(index)}
            amount={(leftoverProducts ?? {})[product.id] ?? 0}
            isLoadingLeftovers={isLoadingLeftovers}
            branchId={branchId}
          />
        ))}
      </TableBody>
    </TableContainer>
  )
})
