import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PermissionPageBlockProps, allSelectedPermissions } from '../logic'
import { PermissionBlock, PermissionBlockTitle, PermissionCheckbox } from '.'
import { PermissionPageRowProps } from '@expane/logic/permission/logic'

export const BusinessBlock: FC<PermissionPageBlockProps> = ({
  control,
  checkRelation,
  checkRelationBulk,
  isItOwnerRole,
  setValue,
}) => {
  const { t } = useTranslation()

  const onSelectAll = () => {
    checkRelationBulk({
      permissions: [
        'permissions.business.set',
        'permissions.branch.set',
        'permissions.branch.getAll',
        'permissions.billingInfo.get',
        'permissions.billingInfo.set',
      ],
      onConfirm: () => {
        setValue('permissions.business', allSelectedPermissions.business)
        setValue('permissions.branch', allSelectedPermissions.branch)
        setValue('permissions.billingInfo', allSelectedPermissions.billingInfo)
      },
    })
  }

  return (
    <>
      <PermissionBlockTitle onSelectAll={onSelectAll} title={t('business')} />

      <BusinessPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <BillingInfoPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
    </>
  )
}

const BusinessPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('business')}>
      <Controller
        name="permissions.business.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.business.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.business.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.branch.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.branch.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.branch.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.branch.getAll"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.branch.getAll',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.branch.getAll')}
            hint={t('permissions.branch.getAllHint')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const BillingInfoPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('billingInfo')}>
      <Controller
        name="permissions.billingInfo.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.billingInfo.get',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.billingInfo.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.billingInfo.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.billingInfo.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.billingInfo.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}
