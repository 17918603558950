import {
  useDeactivatedServiceAndGroupsWithInterBranchServiceGroup,
  useServiceAndGroupsWithInterBranchServiceGroup,
} from '@expane/logic/service'
import { Spinner } from '@expane/ui'
import { ServicePageData } from 'pages/ServicesPage/ServicePageData'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'

export const ServicesPage: FC = () => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId

  const { services, serviceGroups, isLoading } = useServiceAndGroupsWithInterBranchServiceGroup(
    t('interbranchService.groupName'),
    branchId,
  )

  if (isLoading) return <Spinner expandCentered />

  return <ServicePageData services={services ?? []} serviceGroups={serviceGroups ?? []} />
}

export const ArchiveServicesPage: FC = () => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId

  const { services, serviceGroups, isLoading } =
    useDeactivatedServiceAndGroupsWithInterBranchServiceGroup(
      t('interbranchService.groupName'),
      branchId,
    )

  if (isLoading) return <Spinner expandCentered />

  return <ServicePageData services={services ?? []} serviceGroups={serviceGroups ?? []} archive />
}
