import { useFetchBookingsOfDate, useFetchCurrentBranchTimezone } from '@expane/data'
import { getIsBookingPaid } from '@expane/logic/booking'
import { findById, translateItemsName } from '@expane/logic/utils'
import { Page, ResetFilterButton, SelectDropdown, SelectDropDownItem } from '@expane/ui'
import { useCustomDatePicker } from 'logic/hooks/useCustomDatePicker'
import { FC, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { useOpenBookingDialog } from 'widgets/BookingDialog'
import { useOpenBookingMultiServiceDialog } from 'widgets/BookingMultiServicesDialog'
import { useOpenGroupBookingDialog } from 'widgets/GroupBookingDialog'
import { BookingsList } from './BookingsList'

export const BookingsListPage: FC = () => {
  const { t } = useTranslation()
  const { customDatePicker, period, datePickerType, reset } = useCustomDatePicker({
    initialType: 'date',
  })

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const initialPeriod = useRef(period)

  const { openEditBookingDialog, bookingDialog } = useOpenBookingDialog()
  const { openEditGroupBookingDialog, groupBookingDialog } = useOpenGroupBookingDialog()
  const { openEditBookingMultiServicesDialog, bookingMultiServicesDialog } =
    useOpenBookingMultiServiceDialog()

  const { data: bookings, isLoading } = useFetchBookingsOfDate(
    datePickerType === 'date' ? period.start : [period.start, period.end],
    timezone,
    branchId,
    { includeCanceled: true },
  )

  const [canceledFilter, setCanceledFilter] = useState<number | null>()
  const [isPaidFilter, setIsPaidFilter] = useState<number | null>()

  const filteredBookings = useMemo(() => {
    if (!bookings) return []

    const filteredByCanceled = bookings.filter(booking => {
      if (canceledFilter === CANCELED_FILTER_ITEMS[0].id) {
        return booking.canceledDate !== null
      }
      if (canceledFilter === CANCELED_FILTER_ITEMS[1].id) {
        return booking.canceledDate === null
      }
      // if out of scope, then do not filter
      return true
    })

    const filteredByPaid = filteredByCanceled.filter(booking => {
      if (isPaidFilter === IS_PAID_FILTER_ITEMS[0].id) {
        return getIsBookingPaid(booking)
      }
      if (isPaidFilter === IS_PAID_FILTER_ITEMS[1].id) {
        return !getIsBookingPaid(booking)
      }
      // if out of scope, then do not filter
      return true
    })

    return filteredByPaid
  }, [canceledFilter, bookings, isPaidFilter])

  const handleFilterReset = () => {
    setCanceledFilter(undefined)
    setIsPaidFilter(undefined)
    reset()
  }

  const isResetButtonDisabled =
    !canceledFilter &&
    !isPaidFilter &&
    initialPeriod.current.start.valueOf() === period.start.valueOf() &&
    initialPeriod.current.end.valueOf() === period.end.valueOf()

  return (
    <Page>
      <div className="flex mb-2">
        {customDatePicker}
        <SelectDropdown
          value={canceledFilter}
          onSelectChange={setCanceledFilter}
          placeholder={t('cancellationStatus')}
          className="ml-2 w-44"
          items={translateItemsName(CANCELED_FILTER_ITEMS, t)}
          isClearable
        />
        <SelectDropdown
          value={isPaidFilter}
          onSelectChange={setIsPaidFilter}
          placeholder={t('paymentState')}
          className="ml-2 w-44"
          items={translateItemsName(IS_PAID_FILTER_ITEMS, t)}
          isClearable
        />
        <ResetFilterButton
          className="ml-2 w-28"
          disabled={isResetButtonDisabled}
          onClick={handleFilterReset}
        />
      </div>

      <BookingsList
        bookings={filteredBookings}
        isLoading={isLoading}
        onClick={id => {
          const booking = findById(id, filteredBookings)
          if (booking) {
            if (booking.unitedBooking?.id)
              openEditBookingMultiServicesDialog(booking.unitedBooking.id, true)
            else {
              if (!booking.isGroupBooking) openEditBookingDialog(booking.id)
              else openEditGroupBookingDialog(booking.id)
            }
          }
        }}
      />
      {bookingMultiServicesDialog}
      {bookingDialog}
      {groupBookingDialog}
    </Page>
  )
}

const CANCELED_FILTER_ITEMS: SelectDropDownItem[] = [
  { id: 1, name: 'canceled' },
  { id: 2, name: 'notCanceled' },
]

const IS_PAID_FILTER_ITEMS: SelectDropDownItem[] = [
  { id: 1, name: 'paid' },
  { id: 2, name: 'notPaid' },
]
