import {
  addDays,
  addMinutes,
  createCurrentDate,
  eachDayOfInterval,
  fromZonedTime,
} from '@expane/date'
import { TreeMenuItem } from 'ui/TreeMenu'
import { BookingUnion, ServerTimeOffInsertInput } from '@expane/data'
import { checkBookingsOverlap } from '@expane/logic/bookingChecks'
import { setTime } from 'widgets/WaitingListDialog/logic'

export const getDefaultTimeOffsPeriod = ({ timezone }: { timezone: string }): [Date, Date] => {
  const start = createCurrentDate(timezone)
  const end = addDays(createCurrentDate(timezone), 1)

  return [start, end]
}

export const getEmployeeIdsWithBookingsByDateTiming = ({
  employeeIds,
  bookingsOfPeriod,
  date,
  timing,
}: {
  employeeIds: number[]
  bookingsOfPeriod: BookingUnion[]
  date: [Date, Date]
  timing: number[]
}) => {
  const employeeIdsWithBookings: number[] = []

  const daysOfTimeOffs = eachDayOfInterval({
    start: date[0],
    end: date[1],
  })

  for (const employeeId of employeeIds) {
    const bookingsWithThisEmployee = bookingsOfPeriod.filter(
      booking => booking.employee?.id === employeeId,
    )

    for (const dayOfTimeOff of daysOfTimeOffs) {
      const thereAreBookings = bookingsWithThisEmployee.some(booking =>
        checkBookingsOverlap(
          {
            startDate: setTime(dayOfTimeOff, timing[0]),
            endDate: setTime(dayOfTimeOff, timing[1]),
          },
          {
            startDate: booking.startDate,
            endDate: addMinutes(booking.startDate, booking.duration),
          },
        ),
      )

      if (thereAreBookings) {
        // Для предотвращения добавления дубликатов сотрудников
        if (!employeeIdsWithBookings.some(id => id === employeeId)) {
          employeeIdsWithBookings.push(employeeId)
        }
        break
        // Если найдено перекрытие, прерываем цикл для данного сотрудника так как он уже будет в списке
        // Указывать конкретные даты и букинги ?
      }
    }
  }

  return employeeIdsWithBookings
}

export const transformTimeOffsDataForServer = ({
  date,
  timing,
  timeOffReasonId,
  employees,
  timezone,
}: {
  date: [Date, Date]
  timing: number[]
  timeOffReasonId: number
  employees: TreeMenuItem[]
  timezone: string
}) => {
  const daysOfTimeOffs = eachDayOfInterval({
    start: date[0],
    end: date[1],
  })

  const timeOffs: ServerTimeOffInsertInput[] = []

  for (const employee of employees) {
    for (const dayOfTimeOff of daysOfTimeOffs) {
      timeOffs.push({
        employeeId: employee.id,
        start: fromZonedTime(setTime(dayOfTimeOff, timing[0]), timezone),
        end: fromZonedTime(setTime(dayOfTimeOff, timing[1]), timezone),
        timeOffReasonId,
      })
    }
  }

  return timeOffs
}
