import { useTranslation } from 'react-i18next'
import { createCurrentDate, format as formatDate } from '@expane/date'

export type Format =
  | 'historyDate'
  | 'historyDateTime'
  | 'historyMonth'
  | 'dateTime'
  | 'date'
  | 'shortDateTime'
  | 'shortDate'
  | 'shortDateWithoutYear'
  | 'time'
  | 'dateTimeWithSeparator'

const formatWithoutYear: Omit<
  Record<Format, string>,
  'shortDateTime' | 'shortDate' | 'time' | 'day' | 'shortDateWithoutYear'
> = {
  historyMonth: 'historyMonth',
  historyDate: 'historyDateWithoutCurrentYear',
  historyDateTime: 'historyDateTimeWithoutCurrentYear',
  dateTime: 'dateTimeWithoutCurrentYear',
  date: 'dateWithoutCurrentYear',
  dateTimeWithSeparator: 'dateTimeWithSeparator',
}

const getFormatForDate = (format: Format, date: Date | null, timezone: string) => {
  if (!date) return format
  const currentYear = createCurrentDate(timezone).getFullYear()
  const valueYear = date.getFullYear()

  return currentYear === valueYear ? formatWithoutYear[format] : format
}

/**
 * Для правильної роботи useDateFormatting треба при ініціалізації i18n додати налаштування interpolation
 */
export const useDateFormatting = (timezone: string | undefined) => {
  const { t } = useTranslation()

  const dateFormatting = (format: Format, date?: Date | null) => {
    if (!date || !timezone) return '-'

    if (format === 'shortDate') return date ? formatDate(date, 'dd.MM.yy') : '-'
    if (format === 'shortDateWithoutYear') return date ? formatDate(date, 'dd.MM HH:mm') : '-'
    if (format === 'shortDateTime') return date ? formatDate(date, 'dd.MM.yy HH:mm') : '-'
    if (format === 'time') return date ? formatDate(date, 'HH:mm') : '-'

    return t(getFormatForDate(format, date, timezone), { date })
  }

  return dateFormatting
}
