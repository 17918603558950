import React, { useState, useCallback, useEffect, PropsWithChildren, FC } from 'react'

// Добавить другие размеры по надобности
type PaperResizableWidth = '1/3' | '1/4' | '1/5'

interface PaperResizableProps {
  defaultWidth: PaperResizableWidth
  withoutBorder?: boolean
  className?: string
}

export const PaperResizable: FC<PropsWithChildren<PaperResizableProps>> = ({
  defaultWidth,
  withoutBorder = false,
  className,
  children,
}) => {
  // Вычисляем начальную ширину на основе размера окна браузера
  const defaultWidthInPx = getWidthInPx(defaultWidth)

  const [width, setWidth] = useState(defaultWidthInPx)
  const [isResizing, setIsResizing] = useState(false)
  const [startX, setStartX] = useState(0)

  const startResize = useCallback(e => {
    setIsResizing(true)
    setStartX(e.clientX)
    e.preventDefault()
  }, [])

  const resize = useCallback(
    e => {
      if (isResizing) {
        const currentX = e.clientX
        const deltaX = currentX - startX
        const newWidth = Math.max(defaultWidthInPx, width + deltaX) // Устанавливаем 100px как минимальную ширину
        setWidth(newWidth)
        setStartX(currentX)
      }
    },
    [defaultWidthInPx, isResizing, startX, width],
  )

  const stopResize = useCallback(() => {
    setIsResizing(false)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWidth(defaultWidthInPx) // Обновляем ширину при изменении размеров окна
    }

    window.addEventListener('resize', handleResize)

    if (isResizing) {
      document.addEventListener('mousemove', resize)
      document.addEventListener('mouseup', stopResize)

      return () => {
        document.removeEventListener('mousemove', resize)
        document.removeEventListener('mouseup', stopResize)
      }
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [defaultWidth, defaultWidthInPx, isResizing, resize, stopResize])

  const borderStyle = withoutBorder ? '' : 'border border-block-color rounded-lg '

  return (
    <div
      className={'bg-block relative overflow-hidden ' + borderStyle + className}
      style={{ width: `${width}px` }}
    >
      {children}
      <div
        onMouseDown={startResize}
        className="absolute right-0 top-0 h-full w-3 cursor-ew-resize"
      />
    </div>
  )
}

const getWidthInPx = (defaultWidth: PaperResizableWidth) => {
  const windowWidth = window.innerWidth
  if (defaultWidth === '1/3') return windowWidth / 3
  if (defaultWidth === '1/4') return windowWidth / 4
  if (defaultWidth === '1/5') return windowWidth / 5
  return 0
}
