import { ServerTag } from '@expane/data'
import {
  ErrorMessage,
  ErrorMessageType,
  FadeInTranslateTop,
  getCommonSelectButtonStyle,
  InputLabel,
  modalsHTMLElement,
  Tag,
  useHandleClickOutside,
  usePopupOpenState,
} from '@expane/ui'
import { offset, useFloating } from '@floating-ui/react-dom'
import { FC, KeyboardEvent, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoCheckmark, IoChevronDown, IoClose } from 'react-icons/io5'

type TagsDto = Pick<ServerTag, 'id' | 'name' | 'color' | 'description'>

export interface TagSelectProps {
  items: TagsDto[]
  onItemSelect: (value: TagsDto[]) => void
  selectedItems: TagsDto[] | undefined
  placeholder?: string
  label?: string
  hint?: string
  className?: string
  errorMessage?: ErrorMessageType
  required?: boolean
  disabled?: boolean
  // для storybook
  customModal?: HTMLElement | null
}

export const TagSelect: FC<TagSelectProps> = ({
  items,
  onItemSelect,
  selectedItems,
  placeholder,
  className,
  label,
  hint,
  errorMessage,
  required,
  disabled = false,
  customModal,
}) => {
  const { t } = useTranslation()
  const { isOpen, openPopup, closePopup } = usePopupOpenState()
  const [activeLink, setActiveLink] = useState<number>(0)

  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const [filterRef, setFilterRef] = useState<HTMLDivElement | null>(null)

  useHandleClickOutside([buttonRef.current, filterRef], closePopup, isOpen)

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(5)],
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  useLayoutEffect(() => {
    reference(buttonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, buttonRef.current])
  useLayoutEffect(() => {
    floating(filterRef)
  }, [filterRef, floating])

  const handlePopUp = () => {
    if (isOpen) {
      closePopup()
    } else {
      setActiveLink(0)
      openPopup()
    }
  }

  const handleSelectedItems = (item: TagsDto) => {
    if (checkIfItemIsSelected(selectedItems, item))
      onItemSelect(selectedItems?.filter((el: TagsDto) => el.id !== item?.id) ?? [])
    else {
      if (selectedItems !== undefined) onItemSelect([...selectedItems, item])
      else onItemSelect([item])
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Tab') closePopup()
    if (e.key === 'ArrowDown') setActiveLink(activeLink === items.length - 1 ? 0 : activeLink + 1)
    if (e.key === 'ArrowUp') setActiveLink(activeLink === 0 ? items.length - 1 : activeLink - 1)
    if (e.key === 'Enter') {
      const selectedDropDownItem = items.find((el, index) => activeLink === index)
      if (selectedDropDownItem) handleSelectedItems(selectedDropDownItem)
    }
    if (e.key === 'Escape') {
      closePopup()
    }

    e.stopPropagation()
  }

  const filterBtnStyle = getCommonSelectButtonStyle({
    isDisabled: disabled,
    isError: Boolean(errorMessage),
    isOpen,
  })

  return (
    <div className={className}>
      <div>
        <InputLabel label={label} hint={hint} required={required} />

        <button
          ref={buttonRef}
          className={filterBtnStyle}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          onFocus={handlePopUp}
        >
          <div className="w-full flex items-center justify-between">
            {selectedItems?.length ? (
              <>
                <div className="grow text-left text-main-color line-clamp-1 -ml-1 h-full">
                  {selectedItems?.map(item => (
                    <div key={item.id} className="py-1 inline-block mr-0.5">
                      <Tag color={item.color} name={item.name} className="ml-1 line-clamp-1" />
                    </div>
                  ))}
                </div>
                <div
                  className="text-gray-200 hover:text-error-600 pl-1"
                  onClick={e => {
                    e.stopPropagation()
                    onItemSelect([])
                  }}
                >
                  <IoClose size="1.2rem" />
                </div>
              </>
            ) : (
              <>
                <div className="flex-centered text-gray-300">
                  {disabled ? '' : placeholder ?? ''}
                </div>
                <div className="shrink-0 ml-2 text-gray-200 hover:text-primary-500">
                  <IoChevronDown size="1rem" />
                </div>
              </>
            )}
          </div>
        </button>
        <ErrorMessage errorMessage={errorMessage} />

        {isOpen &&
          createPortal(
            <div
              ref={setFilterRef}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
              onKeyDown={handleKeyDown}
            >
              <FadeInTranslateTop
                className="border-2 border-primary-400 rounded-md box-border bg-block"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="tag-list"
                style={{ minWidth: buttonRef.current?.offsetWidth }}
              >
                <ul className="max-h-45 overflow-x-hidden py-1 overflow-y-auto">
                  {items.length > 0 ? (
                    items.map((item, index) => (
                      <Item
                        key={item.id}
                        isSelected={Boolean(checkIfItemIsSelected(selectedItems, item))}
                        isActive={index === activeLink}
                        item={item}
                        onMouseEnter={() => {
                          if (index !== activeLink) setActiveLink(index)
                        }}
                        onClick={() => handleSelectedItems(item)}
                      />
                    ))
                  ) : (
                    <li className="text-center text-label-color flex items-left px-3.5 py-2 cursor-pointer">
                      {t('noItems')}
                    </li>
                  )}
                </ul>
              </FadeInTranslateTop>
            </div>,
            customModal ?? modalsHTMLElement,
          )}
      </div>
    </div>
  )
}

interface ItemProps {
  isSelected?: boolean
  onMouseEnter: () => void
  item: TagsDto
  onClick: () => void
  isActive?: boolean
}

const Item: FC<ItemProps> = ({ isSelected, onMouseEnter, item, onClick, isActive }) => {
  return (
    <li
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      className={addAdditionalStyles(isSelected, isActive)}
    >
      <Tag color={item.color} name={item.name} className="ml-1" />

      {isSelected && <IoCheckmark size="1.2rem" />}
    </li>
  )
}

const checkIfItemIsSelected = (array: TagsDto[] | undefined, item: TagsDto | undefined) =>
  array?.find(el => item?.id === el.id)

const addAdditionalStyles = (isSelected?: boolean, isActive?: boolean) => {
  let additionalStyles =
    'flex justify-between items-center mx-2 py-2 pr-1 cursor-pointer rounded-md'
  if (isSelected) additionalStyles += ' text-primary-500 font-medium'
  if (isActive) additionalStyles += ' bg-hover rounded-md text-primary-600'
  return additionalStyles
}
