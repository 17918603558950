import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  ServerOnlineBookingUrlInsertInput,
  ServerOnlineBookingUrlSetInput,
} from '../../generated/graphql-types'
import { ONLINE_BOOKING_URL_QUERY_KEY } from './queryKeys'
import { gql } from 'graphql-request'

export function useCreateOnlineBookingURL() {
  return useMutation(
    async (
      onlineBookingURLsInsertInput: ServerOnlineBookingUrlInsertInput &
        Required<Pick<ServerOnlineBookingUrlInsertInput, 'branchId' | 'name'>>,
    ): Promise<{ insertOneOnlineBookingURL?: { id?: number } }> => {
      return request(
        gql`
          mutation ($onlineBookingURLsInsertInput: onlineBookingURL_insert_input!) {
            insertOneOnlineBookingURL(object: $onlineBookingURLsInsertInput) {
              id
            }
          }
        `,
        { onlineBookingURLsInsertInput },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ONLINE_BOOKING_URL_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateOnlineBookingURL() {
  return useMutation(
    async (dto: {
      id: number
      onlineBookingURLSetInput: ServerOnlineBookingUrlSetInput
    }): Promise<{ updateOnlineBookingURLById?: { id?: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $onlineBookingURLSetInput: onlineBookingURL_set_input!) {
            updateOnlineBookingURLById(pk_columns: { id: $id }, _set: $onlineBookingURLSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ONLINE_BOOKING_URL_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useDeleteOnlineBookingURLById() {
  return useMutation(
    async (id: number): Promise<{ deleteOnlineBookingURLById?: { id?: number } }> => {
      return request(
        gql`
          mutation ($id: Int!) {
            deleteOnlineBookingURLById(id: $id) {
              id
            }
          }
        `,
        { id },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ONLINE_BOOKING_URL_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
