import { gql } from 'graphql-request'

export const employeeSettingsFragment = gql`
  fragment employeeSettingsType on employeeSettings {
    id
    notificationSettings
    preferredConnections
    lang
  }
`
