import { gql } from 'graphql-request'

export const softwarePOSFragment = gql`
  fragment softwarePOSType on softwarePOS {
    id
    name
    login
    password
    token
    licenseKey
    authorId
    accountSoftwarePOSs {
      id
      accountId
    }
  }
`

export const accountSoftwarePOSFragment = gql`
  fragment accountSoftwarePOSType on accountSoftwarePOS {
    id
    accountId
    softwarePOSId
  }
`
