import { gql } from 'graphql-request'
import { reportGqlError, request, useMutation } from '../api'
import {
  ServerCustomFirebaseTokenResponse,
  ServerSetCurrentBusinessAsClientResult,
  ServerSetCurrentBusinessResult,
  ServerSetCurrentClaimsForReferrerResult,
} from '../generated/graphql-types'

export async function generateCustomAuthToken() {
  const result = await request<{
    generateCustomFirebaseToken: ServerCustomFirebaseTokenResponse
  }>(gql`
    query {
      generateCustomFirebaseToken {
        customToken
      }
    }
  `)
  return result.generateCustomFirebaseToken.customToken
}

export const getCheckIfUserExistsEndpoint = (firebaseEndpoint: string, email: string) =>
  firebaseEndpoint + '/expane/check-if-user-exists?email=' + email

export function useSetCurrentBusinessAsEmployee(onSuccess?: () => void) {
  return useMutation(
    async (businessId: number): Promise<{ setCurrentBusiness: ServerSetCurrentBusinessResult }> => {
      return request(
        gql`
          mutation ($businessId: Int!) {
            setCurrentBusiness(businessId: $businessId) {
              businessId
              employeeId
              availableBranchIds
              message
            }
          }
        `,
        { businessId },
      )
    },
    {
      onSuccess,
      onError: reportGqlError,
    },
  )
}

export function useSetCurrentBusinessAsClient(onSuccess?: () => void) {
  return useMutation(
    async (
      businessId: number,
    ): Promise<{
      setCurrentBusinessAsClient: ServerSetCurrentBusinessAsClientResult
    }> => {
      return await request(
        gql`
          mutation ($businessId: Int!) {
            setCurrentBusinessAsClient(businessId: $businessId) {
              businessId
              clientId
              availableBranchIds
              message
            }
          }
        `,
        { businessId },
      )
    },
    {
      onSuccess,
      onError: reportGqlError,
    },
  )
}

export function useSetCurrentClaimsForReferrer(onSuccess?: () => void) {
  return useMutation(
    async (): Promise<{ setCurrentClaimsForReferrer: ServerSetCurrentClaimsForReferrerResult }> => {
      return request(
        gql`
          mutation {
            setCurrentClaimsForReferrer {
              referrerId
            }
          }
        `,
      )
    },
    {
      onSuccess,
      onError: reportGqlError,
    },
  )
}
