import { ServerTransactionByIdType, ServerTransactionType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { getIsRefundAllowed } from '@expane/logic/transaction/refund'
import { Button, CloseButton, Dialog, TableBody, TableContainer } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoArrowUndoOutline, IoReceiptOutline } from 'react-icons/io5'
import { useOpenReceiptDialog } from 'widgets/ReceiptDialog'
import { TransactionMainInfoDialog } from 'widgets/TransactionDialog/TransactionMainInfo'
import { TransactionRefundInfo } from 'widgets/TransactionDialog/TransactionRefundInfo'
import { useOpenTransactionSubscriptionRefundDialog } from 'widgets/TransactionSubscriptionDialog/RefundDialog'
import {
  TransactionSubscriptionTableHeader,
  TransactionSubscriptionTableListItem,
} from 'widgets/TransactionSubscriptionDialog/table'

export interface TransactionSubscriptionDialogProps {
  subscriptionId: number
  transactionById: ServerTransactionByIdType
  myPermissions: string[]
  closeDialog: () => void
  refundsForTransaction: ServerTransactionType[]
}

export const TransactionSubscriptionDialog: FC<
  PropsWithBranchId<TransactionSubscriptionDialogProps>
> = ({
  transactionById,
  myPermissions,
  subscriptionId,
  closeDialog,
  refundsForTransaction,
  branchId,
}) => {
  const { t } = useTranslation()

  const { transactionSubscriptionRefundDialog, openTransactionSubscriptionRefundDialog } =
    useOpenTransactionSubscriptionRefundDialog()
  const { openReceiptDialog, receiptDialog } = useOpenReceiptDialog()

  const subscription =
    transactionById.transactionsCards.length === 0
      ? transactionById?.transactionsCards[0]
      : transactionById?.transactionsCards.find(card => card.card.id === subscriptionId)

  const isRefundAllowed = getIsRefundAllowed(transactionById, myPermissions)

  return (
    <>
      <Dialog.Title>{t('transaction.name')}</Dialog.Title>
      <Dialog.Body className="w-148">
        <TransactionMainInfoDialog transactionById={transactionById} />

        <TableContainer>
          <TransactionSubscriptionTableHeader />

          <TableBody>
            {subscription ? (
              <TransactionSubscriptionTableListItem
                key={subscription.card.id}
                name={subscription.card.cardTemplate.name}
                discount={subscription.discount}
                price={subscription.price}
                branchId={branchId}
              />
            ) : null}
          </TableBody>
        </TableContainer>

        <TransactionRefundInfo refundsForTransaction={refundsForTransaction} />
      </Dialog.Body>

      <Dialog.Footer>
        {isRefundAllowed ? (
          <Button
            disabled={refundsForTransaction.length !== 0}
            onClick={() =>
              openTransactionSubscriptionRefundDialog({
                subscriptionId,
                transactionId: transactionById.id,
                onSuccess: closeDialog,
              })
            }
            Icon={IoArrowUndoOutline}
          >
            {t('transaction.createRefund')}
          </Button>
        ) : null}

        {transactionById.transactionReceipts && transactionById.transactionReceipts.length > 0 && (
          <Button
            type="outline"
            className="ml-auto"
            Icon={IoReceiptOutline}
            onClick={() => openReceiptDialog(transactionById.transactionReceipts[0].receiptId)}
          >
            {t('receipt.title')}
          </Button>
        )}

        <CloseButton onClick={closeDialog} className="mr-auto" />
      </Dialog.Footer>

      {transactionSubscriptionRefundDialog}
      {receiptDialog}
    </>
  )
}
