import { ExtendedEmployee, ServerLocationWithServiceIdsType } from '@expane/data'
import { transformPersonName } from '@expane/logic/utils'
import { DeleteButtonCell, Hint, TableCell, TableRow } from '@expane/ui'
import { DateTimePicker } from '@expane/widgets'
import { FC } from 'react'
import { store } from 'store'
import { BookingInsertInputWithSpecialId } from './logic'

export type CheckedBooking = BookingInsertInputWithSpecialId & {
  errorMessage?: string
}

interface PickedBookingListItemProps {
  booking: CheckedBooking
  location: ServerLocationWithServiceIdsType
  employee: ExtendedEmployee
  initial: boolean
  timezone: string
}

export const PickedBookingListItem: FC<PickedBookingListItemProps> = ({
  employee,
  location,
  booking,
  initial,
  timezone,
}) => {
  const {
    multiBooking: { removeBooking, editDateOfAdditionalBooking, editDateOfFirstBooking },
  } = store

  const handleEditAdditionalBooking = (newDate: Date) => {
    editDateOfAdditionalBooking(booking.specialId, newDate)
  }

  return (
    <TableRow>
      <TableCell>
        <DateTimePicker
          timezone={timezone}
          type="dateTime"
          value={
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            booking.startDate!
          }
          onChange={initial ? editDateOfFirstBooking : handleEditAdditionalBooking}
        />
      </TableCell>
      <TableCell>{location?.name}</TableCell>
      <TableCell borderless>{employee ? transformPersonName(employee) : null}</TableCell>
      <TableCell borderless>
        {booking.errorMessage ? (
          <div className="px-2 flex-centered h-full">
            <Hint type="warning">{booking.errorMessage}</Hint>
          </div>
        ) : null}
      </TableCell>
      <DeleteButtonCell onClick={initial ? undefined : () => removeBooking(booking.specialId)} />
    </TableRow>
  )
}
