import { parsePhoneNumber } from '@expane/logic/phone'
import { SelectDropDownItem } from './'

export const formatPhoneNumberForSelect = (phone: string) => {
  const splittedPhone = phone.split(' ')
  splittedPhone.shift()
  return splittedPhone.join(' ')
}

export const filterBySearchValue = <T extends SelectDropDownItem>(
  array: T[],
  searchValue: string,
): T[] => {
  const transformPhoneToString = (phone?: string) => {
    const phoneForSearch = phone ? parsePhoneNumber(phone).number : ''
    return phoneForSearch.replaceAll(' ', '')
  }
  return searchValue
    ? array.filter(item =>
        (item.name + transformPhoneToString(item.phoneItem))
          .split(' ')
          .join('')
          .toLowerCase()
          .includes(searchValue.replaceAll(' ', '').toLowerCase()),
      )
    : array
}
