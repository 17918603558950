import { ServerScheduleType } from '../../generated/graphql-types'

export type DayTiming = [number, number]

export const enum ScheduleType {
  WEEKLY = 1,
  INTERVAL = 2,
}

export type IntervalSchedule = Omit<ServerScheduleType, 'type' | 'data'> & {
  type: ScheduleType.INTERVAL
  data: {
    workDays: number
    breakDays: number
    timing: DayTiming
  }
}

export type WeeklySchedule = Omit<ServerScheduleType, 'type' | 'data'> & {
  type: ScheduleType.WEEKLY
  data: [
    DayTiming | null,
    DayTiming | null,
    DayTiming | null,
    DayTiming | null,
    DayTiming | null,
    DayTiming | null,
    DayTiming | null,
  ]
}

export type ScheduleDto = WeeklySchedule | IntervalSchedule
export type ScheduleDtoWithBranchIdAndEmployee = ScheduleDto & {
  branch: { id: number; name: string }[]
  employeeSchedules: {
    employeeId: number
    employee: {
      firstName: string
      lastName: string
    }
  }[]
}
