import { gql } from 'graphql-request'

export const locationGroupFragment = gql`
  fragment locationGroupType on locationGroup {
    description
    id
    name
    archived
  }
`
