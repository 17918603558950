import {
  ExtendedEmployee,
  useFetchBookingById,
  useFetchCurrentBranchTimezone,
  useUpdateBookingStatus,
} from '@expane/data'
import { BOOKING_STATUSES, useDisabledStatusReasons } from '@expane/logic/booking'
import { InputLabel, useShowConfirmationPopup, useShowWarningPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useCurrentTime } from 'logic/hooks/useCurrentTime'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { StatusTracker } from 'ui/StatusTracker'
import { getNameOfStatus } from 'ui/StatusTracker/logic'
import { useFetchCurrentBusiness } from 'gql/business'
import { checkBusinessIsNotPaid } from '@expane/logic/business'

type Props = {
  bookingId: number
  isDirty: boolean
  salarySettingsWarning: boolean
}

export const BookingStatusTracker: FC<Props> = ({ bookingId, isDirty, salarySettingsWarning }) => {
  const { t } = useTranslation()

  const { data: currentBusiness } = useFetchCurrentBusiness()

  const { data: myPermissions } = useFetchMyPermissions()

  const [showSnackbar] = useSnackbar()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: bookingById } = useFetchBookingById(bookingId, timezone, branchId)

  const translatedStatusItems = useGetBookingStatusItems(bookingById?.startDate)

  const { showWarningPopup, warningModal } = useShowWarningPopup(
    t('booking.name'),
    t('needToSaveChanges'),
  )
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()
  const { mutateAsync: updateBookingStatus } = useUpdateBookingStatus()

  const { disabledSetStatusReason, disabledUnsetStatusReason } = useDisabledStatusReasons({
    bookingById,
    timezone,
    myPermissions,
  })

  const handleConfirm = async (status: number) => {
    try {
      await updateBookingStatus({ id: bookingId, status })
    } catch (error) {
      showSnackbar(t('submitError'), 'error')
    }
  }

  const handleChangeStatus = (status: number) => {
    if (checkBusinessIsNotPaid(currentBusiness)) showSnackbar(t('planInfo.noPlan'), 'error')
    else {
      if (status === BOOKING_STATUSES.done && isDirty) {
        showWarningPopup()
        return
      }

      const nameOfStatus = getNameOfStatus(status, translatedStatusItems)

      const description =
        salarySettingsWarning && status === BOOKING_STATUSES.done
          ? `${t('employeeSalaryAlert')}.
${t('employeeSalaryAlertEffects')}.
${t('changeStatus.confirmation', { nameOfStatus })}`
          : t('changeStatus.confirmation', { nameOfStatus })
      showConfirmation({
        title: t('changeStatus.title'),
        description,
        // эта функция не может быть вызвана, если нет bookingId
        onConfirm: () => handleConfirm(status),
      })
    }
  }

  return (
    <div>
      <InputLabel label={t('status')} />
      <StatusTracker
        value={bookingById?.status ?? 0}
        onChange={handleChangeStatus}
        items={translatedStatusItems}
        disabledSetReason={disabledSetStatusReason}
        disabledUnsetReason={disabledUnsetStatusReason}
      />
      {confirmationModal}
      {warningModal}
    </div>
  )
}

export const useGetBookingStatusItems = (bookingStartDate: Date | Date[] | undefined) => {
  const currentTime = useCurrentTime()
  const { t } = useTranslation()

  if (!bookingStartDate) return []

  const isBookingInFuture = Array.isArray(bookingStartDate)
    ? bookingStartDate.some(startDate => startDate > currentTime)
    : bookingStartDate > currentTime

  return [
    {
      id: BOOKING_STATUSES.booking,
      name: t('statusItems.booking'),
      color: BOOKING_STATUS_COLORS[0], // sky-500
    },
    {
      id: BOOKING_STATUSES.notified,
      name: t('statusItems.notified'),
      color: BOOKING_STATUS_COLORS[1], // indigo-600
    },
    [
      {
        id: BOOKING_STATUSES.inPlace,
        name: t('statusItems.inPlace'),
        color: BOOKING_STATUS_COLORS[2], // green-400
      },
      {
        id: BOOKING_STATUSES.didNotCome,
        name: t('statusItems.didNotCome'),
        color: BOOKING_STATUS_COLORS[3], // red-600
      },
    ],
    {
      id: BOOKING_STATUSES.done,
      name: t('statusItems.done'),
      color: BOOKING_STATUS_COLORS[4], // green-700
      disabledReason: isBookingInFuture ? t('disabledBookingStatusReasons.startDate') : undefined,
    },
  ]
}

type ColorType = 'sky-500' | 'indigo-600' | 'green-400' | 'green-700' | 'red-600'

export const BOOKING_STATUS_COLORS: { [key: number]: ColorType } = {
  0: 'sky-500',
  1: 'indigo-600',
  2: 'green-400',
  3: 'red-600',
  4: 'green-700',
}

// // we just have this string in code and it guarantees these classes will be present on production
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const COLOR_STYLES = `bg-primary-400 bg-sky-500 bg-indigo-600 bg-green-700 bg-red-600 bg-green-400
border-primary-400 border-sky-500 border-indigo-600 border-green-700 border-red-600 border-green-400`

export const getSalarySettingsWarning = (
  employeeId: number | null | undefined,
  employees: ExtendedEmployee[] | undefined,
) => {
  if (!employeeId || !employees) return false

  const employee = employees.find(employee => employee.id === employeeId)
  if (!employee) return false

  return !Boolean(employee.salarySettings_aggregate.aggregate?.count)
}
