import { formatFullDate, parseNewDateFromText } from '@expane/date'
import { Input } from '@expane/ui'
import { transformToDateFormat } from '@expane/widgets'
import { FC, KeyboardEvent, useState } from 'react'
import { TimeInput } from 'ui/TimeInput'

type TextInputsProps = {
  date: Date
  onChange: (date: Date) => void
  className: string
  timezone: string
}

export const TextInputs: FC<TextInputsProps> = ({ date, className, onChange, timezone }) => {
  const [dateTextValue, setDateTextValue] = useState<string | undefined>()

  const handleDateChange = () => {
    const newDate = parseNewDateFromText(dateTextValue ?? '', date, timezone)
    onChange(newDate)
    setDateTextValue(undefined)
  }

  return (
    <div className={'flex ' + className}>
      <Input
        value={dateTextValue ?? formatFullDate(date)}
        onChange={e => setDateTextValue(transformToDateFormat(e.target.value))}
        onKeyDown={handleKeyDown}
        onBlur={handleDateChange}
        className="text-center"
        containerClassName="w-28"
      />
      <TimeInput date={date} onChange={onChange} className="ml-1 w-16" timezone={timezone} />
    </div>
  )
}

const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
  if (e.code === 'Enter' || e.code === 'NumpadEnter') e.currentTarget.blur()
}
