import { forwardRef, PropsWithChildren, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useShortCut } from '../../hooks'
import { modalsHTMLElement } from '../../logic'
import { FadeIn, FadeOut } from '../animations'

type ModalAnimation = 'all' | 'onlyFadeOut'
type ModalProps = { close?: () => void; confirm?: () => void; animation?: ModalAnimation }

export const Modal = forwardRef<HTMLDivElement, PropsWithChildren<ModalProps>>(
  ({ close, confirm, animation = 'all', children }, ref) => {
    const [modalRef, setModalRef] = useState<HTMLDivElement | null>(null)

    // Сбрасываем фокус с "нижних" элементов при открытии модалки
    useEffect(() => {
      const activeElement = document.activeElement as HTMLElement | null

      if (!modalRef?.contains(activeElement)) {
        modalRef?.focus()
      }
      return () => {
        // On modal closing set focus back
        activeElement?.focus()
      }
    }, [modalRef])

    const modalConfirm = () => {
      if (confirm) confirm()
    }

    useShortCut(['Escape'], () => close?.(), modalRef)
    useShortCut(['Enter'], modalConfirm, modalRef)

    return createPortal(
      <div
        ref={node => {
          setModalRef(node)
          if (typeof ref === 'function') {
            ref(node)
          } else if (ref) {
            ref.current = node
          }
        }}
        tabIndex={0}
        className="fixed inset-0 overflow-y-auto flex-centered"
      >
        {animation === 'all' ? (
          <FadeIn className="fixed inset-0" aria-hidden="true" onClick={close}>
            <div className="absolute inset-0 bg-gray-900 opacity-60" />
          </FadeIn>
        ) : (
          <FadeOut className="fixed inset-0" aria-hidden="true" onClick={close}>
            <div className="absolute inset-0 bg-gray-900 opacity-60" />
          </FadeOut>
        )}

        {children}
      </div>,
      modalsHTMLElement,
    )
  },
)
