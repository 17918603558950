import { formatTimeFromDate, parseNewTimeFromText } from '@expane/date'
import {
  FadeInTranslateTop,
  modalsHTMLElement,
  useHandleClickOutside,
  usePopupOpenState,
} from '@expane/ui'
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react-dom'
import { FC, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { IoTimeOutline } from 'react-icons/io5'
import { TimePickerPopup } from '../TimePicker'
import { dateTimePickerPopupStyle } from './'
import { DayTiming, getInputStyle, PLACEHOLDERS, transformToTimeFormat } from './logic'

interface TimeButtonProps {
  className: string
  value: Date | undefined
  onChange: (date: Date | undefined) => void
  disabled: boolean
  isClearable?: boolean
  timing?: DayTiming
  customPopupsElement?: HTMLDivElement
  timezone: string
}

export const TimeButton: FC<TimeButtonProps> = ({
  onChange,
  className,
  value,
  disabled,
  isClearable,
  timing,
  customPopupsElement,
  timezone,
}) => {
  const {
    isOpen: timeIsOpen,
    closePopup: closeTimePopup,
    openPopup: openTimePopup,
  } = usePopupOpenState()

  const displayedTime = getDisplayedTime(value)

  const [textInputValue, setTextInputValue] = useState<string | undefined>()

  const handleChange = () => {
    let newDate: Date | undefined
    if (isClearable) {
      // если ввели дату в инпути или инпут не изменялся
      if (textInputValue || (textInputValue === undefined && value)) {
        newDate = parseNewTimeFromText(textInputValue ?? '', value, timezone)
      }

      // это условие выполняется только если в инпуте стереть значение
      if (textInputValue?.length === 0) newDate = undefined
    }
    if (!isClearable) newDate = parseNewTimeFromText(textInputValue ?? '', value, timezone)

    onChange(newDate)
    setTextInputValue(undefined)
    closeTimePopup()
  }

  const [timePopoverRef, setTimePopoverRef] = useState<HTMLDivElement | null>(null)
  const timeButtonRef = useRef<HTMLDivElement | null>(null)
  useHandleClickOutside([timeButtonRef.current, timePopoverRef], handleChange, timeIsOpen)
  const { reference, floating, strategy, x, y } = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip()],
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  // useLayoutEffect instead of useEffect for proper position animation
  useLayoutEffect(() => {
    reference(timeButtonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, timeButtonRef.current])
  useLayoutEffect(() => {
    floating(timePopoverRef)
  }, [floating, timePopoverRef])

  return (
    <>
      <div
        ref={timeButtonRef}
        className={className}
        onClick={() => {
          if (!disabled) openTimePopup()
        }}
      >
        <input
          value={textInputValue ?? displayedTime}
          placeholder={PLACEHOLDERS.time}
          onChange={e => setTextInputValue(transformToTimeFormat(e.target.value))}
          className={getInputStyle(disabled)}
          onKeyDown={e => {
            e.stopPropagation()
            if (e.key === 'Enter') {
              handleChange()
              closeTimePopup()
            }
          }}
          disabled={disabled}
        />
        <div className="mr-1.5 text-gray-300 hover:text-gray-500">
          <IoTimeOutline />
        </div>
      </div>

      {timeIsOpen &&
        createPortal(
          <div
            ref={setTimePopoverRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <FadeInTranslateTop
              className={dateTimePickerPopupStyle}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="time-picker"
            >
              <TimePickerPopup
                timezone={timezone}
                value={value}
                onChange={date => {
                  setTextInputValue(undefined)
                  onChange(date)
                }}
                closeTimePopup={closeTimePopup}
                timing={timing}
              />
            </FadeInTranslateTop>
          </div>,
          customPopupsElement ?? modalsHTMLElement,
        )}
    </>
  )
}

const getDisplayedTime = (value: Date | undefined): string => {
  if (value) {
    return formatTimeFromDate(value)
  }

  return ''
}
