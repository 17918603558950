import { ServerTransactionType } from '@expane/data'

export const groupRevenueReason = (
  reasons: ServerTransactionType[],
): { [key: string]: ServerTransactionType[] } =>
  reasons.reduce((reason, obj: ServerTransactionType) => {
    if (obj.revenueReason)
      reason[obj.revenueReason.name] = (reason[obj.revenueReason.name] || []).concat(obj)
    return reason
  }, {})

export const groupExpensesReason = (
  reasons: ServerTransactionType[],
): { [key: string]: ServerTransactionType[] } =>
  reasons.reduce((reason, obj: ServerTransactionType) => {
    if (obj.expensesReason)
      reason[obj.expensesReason.name] = (reason[obj.expensesReason.name] || []).concat(obj)
    return reason
  }, {})
