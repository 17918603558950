import { gql } from 'graphql-request'
import { queryClient, request, useMutation, reportGqlError } from '../../api'
import {
  ServerArchiveEmployeeTypes,
  ServerEmployeeBusinessOwnerViewSetInput,
  ServerEmployeeEmailViewMutationResponse,
  ServerEmployeeInsertInput,
  ServerEmployeeScheduleInsertInput,
  ServerEmployeeSetInput,
  ServerEmployeeSettingsMutationResponse,
  ServerEmployeeSettingsSetInput,
  ServerServiceEmployeeInsertInput,
  ServerServiceEmployeeMutationResponse,
} from '../../generated/graphql-types'
import {
  ARCHIVED_EMPLOYEES_QUERY_KEY,
  EMPLOYEES_FOR_CALENDAR_QUERY_KEY,
  EMPLOYEES_QUERY_KEY,
} from './queryKeys'
import { EMPLOYEE_GROUPS_QUERY_KEY } from '../employeeGroup/queryKeys'
import { SERVICES_QUERY_KEY } from '../service/queryKeys'
import { PRODUCTS_QUERY_KEY } from '../product/queryKeys'
import { PRODUCT_GROUPS_QUERY_KEY } from '../productGroup/queryKeys'
import { ArchiveEmployeeResult } from './logic'

type EmployeeScheduleRelInsertInput = {
  data: Array<
    ServerEmployeeScheduleInsertInput &
      Required<Pick<ServerEmployeeScheduleInsertInput, 'branchId'>>
  >
}
type EmployeeInsertInput = ServerEmployeeInsertInput & {
  employeeSchedules: EmployeeScheduleRelInsertInput
  serviceEmployees: {
    data: Array<
      ServerServiceEmployeeInsertInput &
        Required<Pick<ServerServiceEmployeeInsertInput, 'branchId' | 'serviceId'>>
    >
  }
}
export function useCreateEmployee() {
  return useMutation(
    async (
      employeeInsertInput: EmployeeInsertInput,
    ): Promise<{ insertEmployee?: { id?: number } }> => {
      return request(
        gql`
          mutation ($employeeInsertInput: employee_insert_input!) {
            insertEmployee(object: $employeeInsertInput) {
              id
            }
          }
        `,
        {
          employeeInsertInput: {
            ...employeeInsertInput,
            employeeSettings: {
              data: { preferredConnections: { viber: false, telegram: false, expo: false } },
            },
          },
        },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([EMPLOYEES_QUERY_KEY])
        queryClient.invalidateQueries([SERVICES_QUERY_KEY])
        queryClient.invalidateQueries([EMPLOYEE_GROUPS_QUERY_KEY])
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEES_FOR_CALENDAR_QUERY_KEY),
        })
      },
    },
  )
}

export function useUpdateEmployee() {
  return useMutation(
    async (dto: {
      id: number
      branchId: number
      employeeSetInput: ServerEmployeeSetInput
      // should be used in pair
      serviceEmployeeInsertInputs?: Array<
        ServerServiceEmployeeInsertInput &
          Required<Pick<ServerServiceEmployeeInsertInput, 'branchId' | 'serviceId' | 'employeeId'>>
      >
      serviceIdsToDelete: number[]
      employeeSettingsSetInput: ServerEmployeeSettingsSetInput
    }): Promise<{
      updateEmployeeById?: { id?: number }
      deleteServiceEmployees?: ServerServiceEmployeeMutationResponse
      insertServiceEmployees?: ServerServiceEmployeeMutationResponse
      updateMultipleEmployeeSettings?: ServerEmployeeSettingsMutationResponse
    }> => {
      return request(
        gql`
          mutation (
            $id: Int!
            $branchId: Int!
            $employeeSetInput: employee_set_input!
            $serviceEmployeeInsertInputs: [serviceEmployee_insert_input!] = []
            $includeServiceEmployee: Boolean!
            $serviceIdsToDelete: [Int!]!
            $employeeSettingsSetInput: employeeSettings_set_input!
            $includeUpdateEmployeeSettings: Boolean!
          ) {
            updateEmployeeById(pk_columns: { id: $id }, _set: $employeeSetInput) {
              id
            }
            deleteServiceEmployees(
              where: {
                employeeId: { _eq: $id }
                serviceId: { _in: $serviceIdsToDelete }
                branchId: { _eq: $branchId }
              }
            ) @include(if: $includeServiceEmployee) {
              affected_rows
            }
            insertServiceEmployees(objects: $serviceEmployeeInsertInputs)
              @include(if: $includeServiceEmployee) {
              affected_rows
            }
            updateMultipleEmployeeSettings(
              where: { employeeId: { _eq: $id } }
              _set: $employeeSettingsSetInput
            ) @include(if: $includeUpdateEmployeeSettings) {
              affected_rows
            }
          }
        `,
        {
          ...dto,
          includeServiceEmployee: Boolean(
            dto.serviceEmployeeInsertInputs || dto.serviceIdsToDelete,
          ),
          includeUpdateEmployeeSettings: Boolean(dto.employeeSettingsSetInput),
        },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([EMPLOYEES_QUERY_KEY])
        queryClient.invalidateQueries([EMPLOYEE_GROUPS_QUERY_KEY])
        queryClient.invalidateQueries([SERVICES_QUERY_KEY])
        queryClient.invalidateQueries([PRODUCTS_QUERY_KEY])
        queryClient.invalidateQueries([PRODUCT_GROUPS_QUERY_KEY])
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEES_FOR_CALENDAR_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateOwnerEmployee() {
  return useMutation(
    async (dto: {
      id: number
      branchId: number
      employeeSetInput: ServerEmployeeBusinessOwnerViewSetInput
      serviceEmployeeInsertInputs: Array<
        ServerServiceEmployeeInsertInput &
          Required<Pick<ServerServiceEmployeeInsertInput, 'branchId' | 'serviceId' | 'employeeId'>>
      >
      employeeSettingsSetInput?: ServerEmployeeSettingsSetInput
      serviceIdsToDelete: number[]
    }): Promise<{
      updateEmployeeBusinessOwners: { affected_rows: number }
      deleteServiceEmployees: ServerServiceEmployeeMutationResponse
      insertServiceEmployees: ServerServiceEmployeeMutationResponse
      updateMultipleEmployeeSettings: ServerEmployeeSettingsMutationResponse
    }> => {
      return request(
        gql`
          mutation (
            $id: Int!
            $branchId: Int!
            $employeeSetInput: employeeBusinessOwnerView_set_input!
            $serviceEmployeeInsertInputs: [serviceEmployee_insert_input!]!
            $serviceIdsToDelete: [Int!]!
            $employeeSettingsSetInput: employeeSettings_set_input!
            $includeUpdateEmployeeSettings: Boolean!
          ) {
            updateEmployeeBusinessOwners(where: { id: { _eq: $id } }, _set: $employeeSetInput) {
              affected_rows
            }
            deleteServiceEmployees(
              where: {
                employeeId: { _eq: $id }
                serviceId: { _in: $serviceIdsToDelete }
                branchId: { _eq: $branchId }
              }
            ) {
              affected_rows
            }
            insertServiceEmployees(objects: $serviceEmployeeInsertInputs) {
              affected_rows
            }
            updateMultipleEmployeeSettings(
              where: { employeeId: { _eq: $id } }
              _set: $employeeSettingsSetInput
            ) @include(if: $includeUpdateEmployeeSettings) {
              affected_rows
            }
          }
        `,
        { ...dto, includeUpdateEmployeeSettings: Boolean(dto.employeeSettingsSetInput) },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([EMPLOYEES_QUERY_KEY])
        queryClient.invalidateQueries([SERVICES_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useArchiveEmployee() {
  return useMutation(
    async (dto: {
      employeeId: number
      archiveType: ServerArchiveEmployeeTypes
    }): Promise<{
      archiveEmployee: ArchiveEmployeeResult
    }> => {
      return request(
        gql`
          mutation ($employeeId: Int!, $archiveType: ArchiveEmployeeTypes!) {
            archiveEmployee(employeeId: $employeeId, archiveType: $archiveType) {
              message
              code
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes(EMPLOYEES_QUERY_KEY),
        })
        queryClient.invalidateQueries([ARCHIVED_EMPLOYEES_QUERY_KEY])
        queryClient.invalidateQueries([EMPLOYEE_GROUPS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateEmployeeEmail() {
  return useMutation(
    async (dto: {
      id: number
      email: string | null
    }): Promise<{ updateEmployeesEmailsView: ServerEmployeeEmailViewMutationResponse }> => {
      return request(
        gql`
          mutation ($email: String!, $id: Int!) {
            updateEmployeeEmails(where: { id: { _eq: $id } }, _set: { email: $email }) {
              affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([EMPLOYEES_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}
