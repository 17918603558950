import { ServerLocationGroupType, ServerLocationWithServiceIdsType } from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { findById } from '@expane/logic/utils'
import { EmptyPlaceholder, Page, Paper } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { PageDataType } from 'pages/CreateBusinessPage/logic'
import { LocationQuickInfo } from 'pages/LocationsPage/LocationQuickInfo'
import { FC, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { IoAddCircle, IoReaderOutline } from 'react-icons/io5'
import { treeMenuClassName } from 'ui/styles'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { onChangeSingleTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { ButtonWithBillingCheck, CreateCategoryButton } from 'widgets/Buttons'
import { LocationDialog } from 'widgets/LocationDialog'
import { LocationGroupDialog } from 'widgets/LocationGroupDialog'
import { ArchiveListNavigationButton, ListNavigationButton } from 'widgets/NavigationButtons'
import { PaperResizable } from 'ui/PaperResizable'

interface LocationsPageDataProps {
  locations: ServerLocationWithServiceIdsType[] | undefined
  locationGroups: ServerLocationGroupType[] | undefined
  type?: PageDataType
  archive?: boolean
}

export const LocationPageData: FC<LocationsPageDataProps> = ({
  locations,
  locationGroups,
  type = 'page',
  archive = false,
}) => {
  const { data: myPermissions } = useFetchMyPermissions()

  const [selectedItem, setSelectedItem] = useState<TreeMenuItem | undefined>()

  const { t } = useTranslation()

  const locationTreeItems: LocationTreeItem[] = transformDataForTreeMenu(
    locationGroups,
    locations,
    {
      keepEmptyFolders: true,
    },
  )

  const locationTreeItemsWithDefaultTag = addTagToLocationTreeMenuItems(locationTreeItems, t)

  const {
    openEditDialog: openEditLocationDialog,
    openCreateDialog: openCreateLocationDialog,
    dialog: locationDialog,
  } = useOpenDialog(LocationDialog)

  const {
    openEditDialog: openEditLocationGroupDialog,
    openCreateDialog: openCreateLocationGroupDialog,
    dialog: locationGroupDialog,
  } = useOpenDialog(LocationGroupDialog)

  const isEditingAllowed = myPermissions?.includes(permissions.location.set)

  return (
    <Page isPadding={type === 'page'}>
      {isEditingAllowed && (
        <div className="flex mb-2 gap-2">
          {archive ? (
            <ListNavigationButton title={t('locations.name')} />
          ) : (
            <>
              <ButtonWithBillingCheck onClick={() => openCreateLocationDialog()} Icon={IoAddCircle}>
                {t('location.name')}
              </ButtonWithBillingCheck>
              <CreateCategoryButton onClick={() => openCreateLocationGroupDialog()} />
              {type !== 'createBusiness' && <ArchiveListNavigationButton className="ml-auto" />}
            </>
          )}
        </div>
      )}

      {locations?.length === 0 && locationGroups?.length === 0 ? (
        <EmptyPlaceholder Icon={IoReaderOutline} text={t('emptyPlaceholder.archive')} />
      ) : (
        <Paper className="w-full h-full overflow-auto flex">
          <PaperResizable defaultWidth={type === 'page' ? '1/4' : '1/3'} withoutBorder>
            <TreeMenu
              type="SinglePickMode"
              items={locationTreeItemsWithDefaultTag}
              className={treeMenuClassName + ' h-full'}
              selected={selectedItem}
              onSelected={item => setSelectedItem(onChangeSingleTreeMenu(item, selectedItem))}
              openSwitch
              onEditFolder={folder => openEditLocationGroupDialog(folder.id)}
              onEditItem={item => openEditLocationDialog(item.id)}
            />
          </PaperResizable>

          {selectedItem && (
            <LocationQuickInfo
              editLocation={openEditLocationDialog}
              location={findById(selectedItem.id, locations)}
              key={selectedItem.id}
            />
          )}
        </Paper>
      )}

      {locationDialog}
      {locationGroupDialog}
    </Page>
  )
}

interface LocationTreeItem extends TreeMenuItem {
  defaultForBranch: {
    id: number
  } | null
}

const addTagToLocationTreeMenuItems = (locationTreeItems: LocationTreeItem[], t: TFunction) =>
  locationTreeItems.map(item =>
    item.defaultForBranch?.id
      ? { ...item, tag: { name: t('forQuickSale'), color: 'primary-300' } }
      : item,
  )
