import {
  ServerMovementType,
  ServerTransactionType,
  useFetchCurrentBranchTimezone,
  useFetchMovementsByDate,
  useFetchTransactionsByDate,
} from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { EnabledModules, ModuleKey, useBusinessModulesSettings } from '@expane/logic/modules'
import { ReportsPermissions } from '@expane/logic/reports/permissions'
import { findById } from '@expane/logic/utils'
import { EmptyPlaceholder, Page, Paper } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useCustomDatePicker } from 'logic/hooks/useCustomDatePicker'
import { translateData } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { GiftCardsReport } from 'pages/ReportsPage/CardsReports/GiftCardsReport'
import { SubscriptionsReport } from 'pages/ReportsPage/CardsReports/SubscriptionsReport'
import { LeadReport } from 'pages/ReportsPage/LeadReport'
import { ProductsReport } from 'pages/ReportsPage/ProductsReport'
import { RevenueByAccountReport } from 'pages/ReportsPage/RevenueByAccountReport'
import { RevenueExpensesReport } from 'pages/ReportsPage/RevenueExpensesReport'
import { ServicesReport } from 'pages/ReportsPage/ServicesReport'
import { VisitsReports, VisitsReportsProps } from 'pages/ReportsPage/VisitsReport'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoDocumentsOutline } from 'react-icons/io5'
import { store } from 'store'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { onChangeSingleTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { IncomingReport } from './IncomingReport'
import { OutgoingReport } from './OutgoingPayments'

export const ReportsPage: FC = observer(() => {
  const { data: myPermissions } = useFetchMyPermissions()
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { t } = useTranslation()

  const { enabledModules } = useBusinessModulesSettings()

  const allowedReports = filterReportsListByPermissionsAndModules(myPermissions, enabledModules)

  const [selectedReport, setSelectedReport] = useState<TreeMenuItem | undefined>(allowedReports[0])
  const CurrentComponent = selectedReport
    ? findById(selectedReport.id, allowedReports)?.component
    : undefined

  const { customDatePicker, period } = useCustomDatePicker({
    datePickerClassName: 'w-1/5 mr-4',
    containerClassName: 'mb-2',
  })

  const { data: movements, isLoading: isLoadingMovements } = useFetchMovementsByDate(
    period.start,
    period.end,
    timezone,
    branchId,
  )
  const { data: transactions, isLoading: isLoadingTransactions } = useFetchTransactionsByDate({
    startDate: period.start,
    endDate: period.end,
    timezone,
    branchId,
  })

  const isLoading = isLoadingMovements || isLoadingTransactions

  if ((!transactions || !movements) && !isLoading) return null

  if (allowedReports.length === 0)
    return <EmptyPlaceholder Icon={IoDocumentsOutline} text={t('noReports')} />

  return (
    <Page>
      {customDatePicker}

      <div className="flex justify-between items-start overflow-hidden">
        <Paper className="w-1/5 mr-4 p-3">
          <TreeMenu
            type="SinglePickMode"
            className="h-full overflow-auto"
            items={translateData(allowedReports, t)}
            isSearch={false}
            selected={selectedReport}
            onSelected={item => {
              setSelectedReport(onChangeSingleTreeMenu(item, selectedReport))
            }}
            isItemsTruncate={false}
          />
        </Paper>

        <div className="w-4/5 h-full">
          {CurrentComponent && (
            <CurrentComponent
              transactions={transactions ?? []}
              movements={movements ?? []}
              startDate={period.start}
              endDate={period.end}
              isLoading={isLoading}
              branchId={branchId}
            />
          )}
        </div>
      </div>
    </Page>
  )
})

export interface ReportProps {
  transactions: ServerTransactionType[]
  movements: ServerMovementType[]
  isLoading: boolean
  startDate: Date
  endDate: Date
}

interface ReportListItem {
  id: number
  name: string
  component: FC<PropsWithBranchId<ReportProps>> | FC<PropsWithBranchId<VisitsReportsProps>>
  permission: string
  module?: ModuleKey
}

const reportsList: ReportListItem[] = [
  {
    id: ReportsPermissions.financialTurnover.id,
    name: 'reports.financialTurnover',
    component: RevenueExpensesReport,
    permission: ReportsPermissions.financialTurnover.permission,
  },
  {
    id: ReportsPermissions.revenueByAccounts.id,
    name: 'reports.revenueByAccounts',
    component: RevenueByAccountReport,
    permission: ReportsPermissions.revenueByAccounts.permission,
  },
  {
    id: ReportsPermissions.servicesSales.id,
    name: 'reports.servicesSales',
    component: ServicesReport,
    permission: ReportsPermissions.servicesSales.permission,
  },
  {
    id: ReportsPermissions.subscriptionsSales.id,
    name: 'reports.subscriptionsSales',
    component: SubscriptionsReport,
    permission: ReportsPermissions.subscriptionsSales.permission,
    module: 'subscriptions',
  },
  {
    id: ReportsPermissions.giftCardsSales.id,
    name: 'reports.giftCardsSales',
    component: GiftCardsReport,
    permission: ReportsPermissions.giftCardsSales.permission,
    module: 'giftCards',
  },
  {
    id: ReportsPermissions.productsSales.id,
    name: 'reports.productsSales',
    component: ProductsReport,
    permission: ReportsPermissions.productsSales.permission,
    module: 'productsForSale',
  },
  {
    id: ReportsPermissions.leads.id,
    name: 'reports.leads',
    component: LeadReport,
    permission: ReportsPermissions.leads.permission,
  },
  {
    id: ReportsPermissions.visits.id,
    name: 'reports.visits',
    component: VisitsReports,
    permission: ReportsPermissions.visits.permission,
  },
  {
    id: ReportsPermissions.incomingPayments.id,
    name: 'reports.incomingPayments',
    component: IncomingReport,
    permission: ReportsPermissions.incomingPayments.permission,
  },
  {
    id: ReportsPermissions.outgoingPayments.id,
    name: 'reports.outgoingPayments',
    component: OutgoingReport,
    permission: ReportsPermissions.outgoingPayments.permission,
  },
]

const filterReportsListByPermissionsAndModules = (
  permissions: string[] | undefined,
  enabledModules: EnabledModules | null,
): ReportListItem[] => {
  if (!permissions && !enabledModules) return reportsList

  const filtered: ReportListItem[] = []

  for (const item of reportsList) {
    const byPermission = permissions ? permissions.includes(item.permission) : true
    const byModules = enabledModules && item.module ? enabledModules.includes(item.module) : true

    if (byPermission && byModules) filtered.push(item)
  }

  return filtered
}
