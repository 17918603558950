import { BookingUnion } from '@expane/data'
import { BOOKING_STATUSES } from '.'
import { createCurrentDate } from '@expane/date'

export const checkIsBookingDoneStatusCanUpdate = (
  booking: BookingUnion | undefined,
  timezone: string | undefined,
): boolean => {
  if (!booking || !timezone) return false

  return booking.startDate < createCurrentDate(timezone)
}

export const checkIsBookingAlreadyDoneStatus = (booking?: BookingUnion): boolean => {
  if (!booking) return false

  return booking.status === BOOKING_STATUSES.done
}
