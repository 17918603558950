import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  ServerClientInsertInput,
  ServerClientRelationArrRelInsertInput,
  ServerClientRelationInsertInput,
  ServerClientSetInput,
  ServerClientSettingsInsertInput,
  ServerClientSettingsMutationResponse,
  ServerClientSettingsSetInput,
  ServerClientTagArrRelInsertInput,
  ServerClientTagInsertInput,
  ServerLanguages,
  ServerMutationRootCreateNewClientArgs,
  ServerTagMutationResponse,
} from '../../generated/graphql-types'
import { Connections, NotificationSettings } from '../clientSettings'
import {
  CLIENTS_AMOUNT_QUERY_KEY,
  CLIENTS_QUERY_KEY,
  DEACTIVATED_CLIENTS_QUERY_KEY,
  MY_CLIENT_QUERY_KEY,
} from './queryKeys'
import { BOOKINGS_QUERY_KEY } from '../booking/queryKeys'
import { TAGS_QUERY_KEY } from '../tag/queryKeys'
import { CloudFunctionResult } from '../responses'

type ClientInsertInput = ServerClientInsertInput &
  Required<Pick<ServerClientInsertInput, 'initialBranchId'>>

export function useCreateClient() {
  return useMutation(
    async (dto: {
      clientsSetInput: ClientInsertInput
      clientTagArrRelInsertInput: ServerClientTagArrRelInsertInput
      clientSettings: {
        data: Omit<ServerClientSettingsInsertInput, 'preferredConnections'> & {
          preferredConnections: Connections
        }
      }
      clientRelationsWhereClientIsFirst?: ServerClientRelationArrRelInsertInput
      clientRelationsWhereClientIsSecond?: ServerClientRelationArrRelInsertInput
    }): Promise<{ insertClient?: { id?: number } }> => {
      return request(
        gql`
          mutation (
            $firstName: String!
            $lastName: String
            $phone: String!
            $altPhone: String
            $photo: String
            $middleName: String
            $gender: String
            $email: String
            $birthDate: timestamptz
            $leadId: Int
            $initialBranchId: Int!
            $maximumDebt: numeric
            $managingEmployeeId: Int
            $clientTagArrRelInsertInput: clientTag_arr_rel_insert_input!
            $clientRelationsWhereClientIsFirst: clientRelation_arr_rel_insert_input
            $clientRelationsWhereClientIsSecond: clientRelation_arr_rel_insert_input
            $clientSettings: clientSettings_obj_rel_insert_input!
          ) {
            insertClient(
              object: {
                firstName: $firstName
                lastName: $lastName
                phone: $phone
                altPhone: $altPhone
                photo: $photo
                middleName: $middleName
                email: $email
                gender: $gender
                birthDate: $birthDate
                leadId: $leadId
                maximumDebt: $maximumDebt
                managingEmployeeId: $managingEmployeeId
                clientTags: $clientTagArrRelInsertInput
                clientRelationsWhereClientIsFirst: $clientRelationsWhereClientIsFirst
                clientRelationsWhereClientIsSecond: $clientRelationsWhereClientIsSecond
                clientSettings: $clientSettings
                initialBranchId: $initialBranchId
              }
            ) {
              id
            }
          }
        `,
        {
          ...dto.clientsSetInput,
          clientTagArrRelInsertInput: dto.clientTagArrRelInsertInput,
          clientRelationsWhereClientIsFirst: dto.clientRelationsWhereClientIsFirst,
          clientRelationsWhereClientIsSecond: dto.clientRelationsWhereClientIsSecond,
          clientSettings: dto.clientSettings,
        },
      )
    },
    {
      onSuccess: () => {
        queryClient.setQueryData([CLIENTS_AMOUNT_QUERY_KEY], (prev: number | undefined) =>
          prev ? prev + 1 : 1,
        )
        queryClient.invalidateQueries([CLIENTS_QUERY_KEY])
        queryClient.invalidateQueries([TAGS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateClient() {
  return useMutation(
    async (dto: {
      id: number
      // TODO: Після релізу треба буде видалити в БД можливість оновлювати через це поле
      clientSetInput: Omit<ServerClientSetInput, 'email'>
      clientEmail: string | undefined
      clientTagInsertInput?: ServerClientTagInsertInput[]
      clientRelationInsertInput?: ServerClientRelationInsertInput[]
      clientSettingSetInput: Omit<
        ServerClientSettingsSetInput,
        'preferredConnections' | 'notificationSettings'
      > & {
        preferredConnections: Connections
      }
    }): Promise<{
      updateClientById?: { id?: number }
      updateClientEmailsView?: { affectedRows?: number }
      deleteClientTags?: ServerTagMutationResponse
      insertClientTags?: ServerTagMutationResponse
      updateClientsSettings: ServerClientSettingsMutationResponse
    }> => {
      return request(
        gql`
          mutation (
            $id: Int!
            $clientSetInput: client_set_input!
            $clientEmail: String
            $includeUpdateClientEmail: Boolean!
            $clientTagInsertInput: [clientTag_insert_input!]!
            $clientRelationInsertInput: [clientRelation_insert_input!] = []
            $includeUpdateClientRelations: Boolean!
            $clientSettingSetInput: clientSettings_set_input!
          ) {
            updateClientById(pk_columns: { id: $id }, _set: $clientSetInput) {
              id
            }

            deleteClientTags(where: { clientId: { _eq: $id } }) {
              affected_rows
            }
            insertClientTags(objects: $clientTagInsertInput) {
              affected_rows
            }
            updateClientsSettings(where: { clientId: { _eq: $id } }, _set: $clientSettingSetInput) {
              affected_rows
            }
            updateClientEmailsView(where: { id: { _eq: $id } }, _set: { email: $clientEmail })
              @include(if: $includeUpdateClientEmail) {
              affectedRows: affected_rows
            }
            deleteClientRelations(
              where: { _or: [{ firstClientId: { _eq: $id } }, { secondClientId: { _eq: $id } }] }
            ) @include(if: $includeUpdateClientRelations) {
              affected_rows
            }
            insertClientRelations(objects: $clientRelationInsertInput)
              @include(if: $includeUpdateClientRelations) {
              affected_rows
            }
          }
        `,
        {
          ...dto,
          includeUpdateClientRelations: Boolean(dto.clientRelationInsertInput),
          includeUpdateClientEmail: Boolean(dto.clientEmail),
        },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CLIENTS_QUERY_KEY])
        queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])
        queryClient.invalidateQueries([TAGS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export type ClientSetInputAsClient = Pick<
  ServerClientSetInput,
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'phone'
  | 'birthDate'
  | 'altPhone'
  | 'additionalInformation'
>
export interface ClientSettingsSetInputAsClient {
  lang?: ServerLanguages | null
  preferredConnections: Connections
  notificationSettings: NotificationSettings
}

export function useUpdateMyClientAsClient() {
  return useMutation(
    async (dto: {
      id: number
      clientSetInput: ClientSetInputAsClient
      clientSettingSetInput: ClientSettingsSetInputAsClient
    }): Promise<{
      updateClientById?: { id?: number }
      updateClientsSettings: ServerClientSettingsMutationResponse
    }> => {
      return request(
        gql`
          mutation (
            $id: Int!
            $clientSetInput: client_set_input!
            $clientSettingSetInput: clientSettings_set_input!
          ) {
            updateClientById(pk_columns: { id: $id }, _set: $clientSetInput) {
              id
            }
            updateClientsSettings(where: { clientId: { _eq: $id } }, _set: $clientSettingSetInput) {
              affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MY_CLIENT_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateMyClientLanguageAsClient() {
  return useMutation(
    async (dto: {
      id: number
      lang: string
    }): Promise<{
      updateClientsSettings: ServerClientSettingsMutationResponse
    }> => {
      return request(
        gql`
          mutation ($id: Int!, $lang: String!) {
            updateClientsSettings(where: { clientId: { _eq: $id } }, _set: { lang: $lang }) {
              affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MY_CLIENT_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useArchiveClient() {
  return useMutation(
    async (dto: { branchId: number; clientId: number }) => {
      return await request(
        gql`
          mutation ($branchId: Int!, $clientId: Int!) {
            insertClientBranchArchived(object: { branchId: $branchId, clientId: $clientId }) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CLIENTS_QUERY_KEY])
        queryClient.invalidateQueries([DEACTIVATED_CLIENTS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUnarchiveClient() {
  return useMutation(
    async (dto: { branchId: number; clientId: number }) => {
      return await request(
        gql`
          mutation ($branchId: Int!, $clientId: Int!) {
            deleteClientBranchArchived(
              where: { branchId: { _eq: $branchId }, clientId: { _eq: $clientId } }
            ) {
              affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CLIENTS_QUERY_KEY])
        queryClient.invalidateQueries([DEACTIVATED_CLIENTS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useCreateNewClient() {
  return useMutation(
    async (
      dto: ServerMutationRootCreateNewClientArgs,
    ): Promise<{
      createNewClient: CloudFunctionResult
    }> => {
      return request(
        gql`
          mutation (
            $branchPhone: String!
            $firstName: String!
            $lastName: String
            $phone: String!
            $additionalInformation: String
            $lang: Languages
          ) {
            createNewClient(
              branchPhone: $branchPhone
              firstName: $firstName
              lastName: $lastName
              phone: $phone
              additionalInformation: $additionalInformation
              lang: $lang
            ) {
              code
              message
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MY_CLIENT_QUERY_KEY])
        queryClient.invalidateQueries([CLIENTS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
