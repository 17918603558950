import {
  CARD_TYPES,
  CardType,
  ServerTransactionByIdType,
  ServerTransactionType,
} from '@expane/data'
import { isSameDay } from '@expane/date'
import { TFunction } from 'i18next'
import { BOOKING_STATUSES } from '../booking'
import { checkIsFreeServicesInSubscription, getSubscriptionInfo } from '../cards'
import { permissions } from '../permission'
import { convertUnitValueFromServer } from '../product'

export type TransactionRefundFormValues = {
  fromAccountId: number
  dataType: RefundObjDataType
  items: RefundItem[]
  refundToClientAccount: boolean
  isRefundConsumables: boolean
}

export interface TransactionSubscriptionFormValues {
  item: RefundItem
  refundToClientAccount: boolean
  fromAccountId: number
  totalRefundPrice: string
}

const getQuantityServicesMap = (refundsForTransaction: ServerTransactionType[] | undefined) => {
  if (refundsForTransaction === undefined || refundsForTransaction.length === 0) return undefined

  // keys === id, value === количество
  const result: Record<number, number> = {}

  refundsForTransaction.forEach(transaction => {
    if (transaction.transactionsServices) {
      transaction.transactionsServices.forEach(el => {
        if (!result[el.service.id]) {
          result[el.service.id] = 1
        } else {
          result[el.service.id] += 1
        }
      })
    }
  })

  return result
}
const getQuantityCardsMap = (refundsForTransaction: ServerTransactionType[] | undefined) => {
  if (refundsForTransaction === undefined || refundsForTransaction.length === 0) return undefined

  // keys === id, value === количество
  const result: Record<number, number> = {}

  refundsForTransaction.forEach(transaction => {
    if (transaction.transactionsCards) {
      transaction.transactionsCards.forEach(el => {
        if (!result[el.card.id]) {
          result[el.card.id] = 1
        } else {
          result[el.card.id] += 1
        }
      })
    }
  })

  return result
}
const getQuantityProductsMap = (refundsForTransaction: ServerTransactionType[] | undefined) => {
  if (refundsForTransaction === undefined || refundsForTransaction.length === 0) return undefined

  // keys === id, value === количество
  const result: Record<number, number> = {}

  refundsForTransaction.forEach(transaction => {
    if (transaction.movement?.movementProducts) {
      transaction.movement.movementProducts.forEach(el => {
        if (!result[el.product.id]) {
          result[el.product.id] = el.quantity
        } else {
          result[el.product.id] += el.quantity
        }
      })
    }
  })

  return result
}

export const uniteSameTransactionServices = (transaction: ServerTransactionByIdType) => {
  const servicesWithQuantity: Array<{
    quantity: number
    service: { id: number; name: string }
    discount?: number | null
    price: number
  }> = []

  for (const transactionService of transaction.transactionsServices) {
    if (
      !servicesWithQuantity.some(service => service.service.id === transactionService.service.id)
    ) {
      servicesWithQuantity.push({
        ...transactionService,
        quantity: 1,
      })
    } else {
      const index = servicesWithQuantity.findIndex(
        service => service.service.id === transactionService.service.id,
      )
      servicesWithQuantity[index] = {
        ...transactionService,
        quantity: servicesWithQuantity[index].quantity + 1,
      }
    }
  }

  return servicesWithQuantity
}

export const getCheckForReturn = (
  transaction: ServerTransactionByIdType,
  refundsForTransaction: ServerTransactionType[] | undefined,
) => {
  if (refundsForTransaction === undefined || refundsForTransaction.length === 0) return true

  if (transaction.transactionsServices?.length) {
    const quantityServicesMap = getQuantityServicesMap(refundsForTransaction)
    const servicesWithQuantity = uniteSameTransactionServices(transaction)

    if (!quantityServicesMap) return true

    for (const transactionService of transaction.transactionsServices) {
      for (const service of servicesWithQuantity) {
        if (
          !quantityServicesMap[transactionService.service.id] ||
          quantityServicesMap[transactionService.service.id] < service.quantity
        )
          return true
      }
    }
  }

  if (transaction.transactionsCards?.length) {
    const quantitySubscriptionsMap = getQuantityCardsMap(refundsForTransaction)
    if (!quantitySubscriptionsMap) return true

    for (const el of transaction.transactionsCards) {
      if (!quantitySubscriptionsMap[el.card.id]) return true
    }
  }

  if (transaction.movement?.movementProducts?.length) {
    const quantityProductsMap = getQuantityProductsMap(refundsForTransaction)
    if (!quantityProductsMap) return true

    for (const el of transaction.movement.movementProducts) {
      if (!quantityProductsMap[el.product.id] || quantityProductsMap[el.product.id] < el.quantity)
        return true
    }
  }

  return false
}

export const getIsRefundAllowed = (
  transaction: ServerTransactionByIdType,
  myPermissions: string[],
) => {
  const isBookingDone = transaction.booking?.status === BOOKING_STATUSES.done
  if (isBookingDone) return false

  const isTransactionSetAllowed = myPermissions?.includes(permissions.transaction.set)
  const isPastTransactionRefundAllowed = myPermissions?.includes(permissions.transaction.editPast)

  return isSameDay(transaction.date, Date.now())
    ? isTransactionSetAllowed
    : isPastTransactionRefundAllowed && isTransactionSetAllowed
}

export type RefundObjDataType = 'product' | 'service' | 'subscription' | 'giftCard'

export type RefundItem = {
  price: number
  id: number
  name: string
  quantity: number
  unit?: number
  discount?: number | null
}

export type RefundObj = {
  type: RefundObjDataType
  items: RefundItem[]
}

export const removeUsedSubscriptions = (refund: RefundObj, clientSubscriptions: CardType[]) => {
  const usableSubscriptions = refund.items.filter(item => {
    const subscription = clientSubscriptions.find(el => el.id === item.id)

    if (subscription) {
      if (!subscription.canceledDate) return checkIsFreeServicesInSubscription(subscription)
      return false
    }
    return false
  })

  return { ...refund, items: usableSubscriptions }
}

export const removeUsedGiftCards = (refund: RefundObj, transaction: ServerTransactionByIdType) => {
  if (refund.type === 'giftCard') {
    const transactionGiftCards = transaction.transactionsCards.filter(
      transactionsCard => transactionsCard.card.type === CARD_TYPES.giftCard,
    )

    const unusedGiftCard = refund.items.filter(item => {
      return !transactionGiftCards.find(el => el.card.id === item.id)?.card.activatedAt
    })

    return { ...refund, items: unusedGiftCard }
  }

  return refund
}

export const getRefundItemsAndType = (
  transaction: ServerTransactionByIdType,
  refundsForTransaction?: ServerTransactionType[],
): RefundObj => {
  let result = {} as RefundObj

  if (transaction.transactionsServices.length) {
    const quantityServicesMap = getQuantityServicesMap(refundsForTransaction)

    const servicesWithQuantity = uniteSameTransactionServices(transaction)

    result = {
      type: 'service',
      items: servicesWithQuantity
        .map(ts => {
          const quantity = quantityServicesMap?.[ts.service.id]
            ? ts.quantity - quantityServicesMap?.[ts.service.id]
            : ts.quantity

          return {
            price: ts.price,
            id: ts.service.id,
            quantity,
            name: ts.service.name,
          }
        })
        .filter(service => service.quantity > 0),
    }

    return result
  }

  if (transaction.transactionsCards.length) {
    const subscriptions = transaction.transactionsCards.filter(
      card => card.card.type === CARD_TYPES.subscription,
    )
    const giftCards = transaction.transactionsCards.filter(
      card => card.card.type === CARD_TYPES.giftCard,
    )

    if (subscriptions.length) {
      const quantitySubscriptionsMap = getQuantityCardsMap(refundsForTransaction)
      // фильтруем-убираем из списка абонементы по которым уже провели возврат
      const items = transaction.transactionsCards.filter(ts => {
        return !quantitySubscriptionsMap?.[ts.card.id]
      })

      result = {
        type: 'subscription',
        items: items.map(ts => ({
          price: ts.price,
          id: ts.card.id,
          quantity: DEFAULT_QUANTITY,
          name: ts.card.cardTemplate.name,
          discount: ts.discount,
        })),
      }

      return result
    }

    if (giftCards.length) {
      const quantityGiftCardsMap = getQuantityCardsMap(refundsForTransaction)
      // фильтруем-убираем из списка абонементы по которым уже провели возврат
      const items = transaction.transactionsCards.filter(ts => {
        return !quantityGiftCardsMap?.[ts.card.id]
      })

      result = {
        type: 'giftCard',
        items: items.map(ts => ({
          price: ts.price,
          id: ts.card.id,
          quantity: DEFAULT_QUANTITY,
          name: ts.card.cardTemplate.name,
        })),
      }

      return result
    }
  }

  if (transaction.movement?.movementProducts.length) {
    const quantityProductsMap = getQuantityProductsMap(refundsForTransaction)
    // фильтруем-убираем из списка товары по которым уже провели возврат
    const items = transaction.movement.movementProducts.filter(el => {
      return (
        !quantityProductsMap?.[el.product.id] ||
        el.quantity - quantityProductsMap?.[el.product.id] > 0
      )
    })

    result = {
      type: 'product',
      items: items.map(el => {
        const quantity = quantityProductsMap?.[el.product.id]
          ? el.quantity - quantityProductsMap?.[el.product.id]
          : el.quantity

        return {
          price: el.price,
          id: el.product.id,
          quantity: convertUnitValueFromServer(quantity, el.product.unit),
          name: el.product.name,
          unit: el.product.unit,
        }
      }),
    }

    return result
  }

  return result
}

export const getTotalRefundItemsPrice = (refundItems: RefundItem[]) =>
  refundItems.reduce((total, current) => total + current.price * current.quantity, 0)

const DEFAULT_QUANTITY = 1

export const getSubscriptionRefundPriceAndHint = (
  clientSubscriptions: CardType[],
  t: TFunction,
  refundSubscription?: RefundItem,
) => {
  // если был возвращен, то до контента с hint не дойдет
  // если есть есть сумма для возврата то ниже hint определяется
  // refundSubscription нет в случае если в абонементе не осталось неиспользованных услуг
  if (!refundSubscription)
    return { totalRefundPrice: 0, hintForTotalRefundPrice: t('subscription.usedFully') }

  let totalRefundPrice = 0
  let hint = ''

  const subscription = clientSubscriptions.find(clientSubscription => {
    return clientSubscription.id === refundSubscription.id
  })

  if (subscription) {
    const unusedSubscription = !subscription?.activatedAt && !subscription?.canceledDate

    if (unusedSubscription) {
      totalRefundPrice = refundSubscription.price
    } else {
      hint += `${t('subscription.usedServices', { name: refundSubscription.name })}:`

      const usedServices = getSubscriptionInfo(subscription)

      totalRefundPrice = subscription.cardTemplate.cardTemplateServices.reduce(
        (amount, cardTemplateService) => {
          const usedServiceQuantity = usedServices[cardTemplateService.serviceId].used

          const unusedServiceQuantity =
            usedServices[cardTemplateService.serviceId].quantity - usedServiceQuantity

          const ratio =
            ((cardTemplateService.serviceQuantity * cardTemplateService.servicePrice) /
              subscription.cardTemplate.price) *
            100

          const discountPerService = refundSubscription.discount
            ? ((refundSubscription.discount / 100) * ratio) / cardTemplateService.serviceQuantity
            : 0

          const price = parseFloat(
            (cardTemplateService.servicePrice - discountPerService).toFixed(2),
          )

          hint += `${t('subscription.usedServicesWithPrice', {
            serviceName: cardTemplateService.service.name,
            usedServiceQuantity,
            serviceQuantity: cardTemplateService.serviceQuantity,
            servicePrice: price,
          })} ${discountPerService !== 0 ? `${t('withDiscount')}. ` : ''}`

          return amount + parseFloat((unusedServiceQuantity * price).toFixed(2))
        },
        0,
      )
    }
  } else {
    totalRefundPrice = refundSubscription.price
  }

  const hintForTotalRefundPrice = hint.length ? hint : undefined

  return { totalRefundPrice, hintForTotalRefundPrice }
}
