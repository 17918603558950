import { StatusItem } from '.'

export const getNameOfStatus = (statusId: number, items: StatusItem[]): string | undefined => {
  for (const status of items) {
    if (Array.isArray(status)) {
      for (const nestedStatus of status) {
        if (nestedStatus.id === statusId) return nestedStatus.name
      }
    } else {
      if (status.id === statusId) return status.name
    }
  }
}
