import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../api'
import { clientByIdQueryKeys, MY_CLIENT_QUERY_KEY } from './client/queryKeys'

export function useBootstrapCalendar(clientId: number) {
  return useMutation(
    async (): Promise<{ bootstrapCalendar: { message: string } }> => {
      return request(
        gql`
          mutation ($clientId: Int!) {
            bootstrapCalendar(clientId: $clientId) {
              message
            }
          }
        `,
        { clientId },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(clientByIdQueryKeys(clientId))
        queryClient.invalidateQueries([MY_CLIENT_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
