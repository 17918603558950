import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { ServerBookingClientSettingsSetInput } from '../../generated/graphql-types'
import { BOOKING_CLIENT_SETTINGS_QUERY_KEY } from './queryKeys'

export function useUpdateBookingClientSettings() {
  return useMutation(
    async (dto: {
      id: number
      bookingClientSettingsSetInput: ServerBookingClientSettingsSetInput
    }) => {
      return request<{ updateBookingClientSettingsById: { id: number } }>(
        gql`
          mutation ($id: Int!, $bookingClientSettingsSetInput: bookingClientSettings_set_input!) {
            updateBookingClientSettingsById(
              pk_columns: { id: $id }
              _set: $bookingClientSettingsSetInput
            ) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([BOOKING_CLIENT_SETTINGS_QUERY_KEY]),
    },
  )
}
