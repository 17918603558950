import { FC } from 'react'
import { TreeMenuItem } from 'ui/TreeMenu'
import { TreeSelect } from 'ui/TreeSelect'
import { Control, Controller } from 'react-hook-form'
import { ServiceDialogFormData } from 'widgets/ServiceDialog/ServiceDialogLogic'
import { useTranslation } from 'react-i18next'

interface Props {
  control: Control<ServiceDialogFormData>
  disabled?: boolean
  employeeTreeMenuItems: TreeMenuItem[]
  className?: string
}

export const ServicePermissionsInputs: FC<Props> = ({
  control,
  disabled,
  employeeTreeMenuItems,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name="servicePermissions"
      render={({ field: { value, onChange } }) => (
        <TreeSelect
          type="MultiPickMode"
          className={className}
          label={t('whoCanSellService')}
          items={employeeTreeMenuItems}
          selected={value as TreeMenuItem[]}
          onSelected={onChange}
          disabled={disabled}
          placeholder={t('choose')}
          hint={t('whoCanSellServiceHint')}
          size="small"
        />
      )}
    />
  )
}
