import { gql } from 'graphql-request'

export const salarySettingFragment = gql`
  fragment salarySettingType on salarySetting {
    id
    employeeId
    rateType
    rateValue
    referralPercentage
    start
    end
    employee {
      preferredPayment
    }
    salarySettingServices {
      id
      type
      value
      fallbackType
      fallbackValue
      dependsOnRealizedValue
      service {
        id
        name
      }
    }
  }
`

export const salaryRateSettingFragment = gql`
  fragment salaryRateSettingType on salarySetting {
    id
    employeeId
    rateType
    rateValue
    referralPercentage
    start
    end
  }
`

export const lastSalarySettingFragment = gql`
  fragment lastSalarySettingType on salarySetting {
    id
    employeeId
    rateType
  }
`
