import {
  EmployeeGroupWithSchedule,
  ServerProductGroupType,
  useArchiveProductGroup,
  useCreateProductGroup,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchProductGroupById,
  useFetchProductGroups,
  useUpdateProductGroup,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import {
  CloseButton,
  Dialog,
  Input,
  Modal,
  SelectDropdown,
  SelectDropDownItem,
  Textarea,
  useShowWarningPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowArchiveConfirmationPopup } from 'logic/hooks/popup/useShowArchiveConfirmationPopup'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { MultiSelect } from 'ui/MultiSelect'
import { ArchiveButton, RestoreButton, SaveButton } from 'widgets/Buttons'

export const ProductGroupDialog: FC<DialogProps> = observer(({ closeDialog, id, isCreate }) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: productGroups } = useFetchProductGroups(branchId)
  const { data: employeeGroups } = useFetchEmployeesGroups(timezone, branchId)
  const { data: productGroupById } = useFetchProductGroupById(id, branchId)

  if ((!isCreate && !productGroupById) || !productGroups || !employeeGroups) return null

  return (
    <ProductGroupDialogLogic
      isCreate={isCreate}
      productGroupById={productGroupById}
      employeeGroups={employeeGroups}
      productGroups={productGroups}
      closeDialog={closeDialog}
    />
  )
})

const ProductGroupDialogLogic: FC<ProductGroupDialogLogicProps> = observer(
  ({ isCreate, productGroupById, employeeGroups, productGroups, closeDialog }) => {
    const { t } = useTranslation()

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const branchId = store.branch.branchId!
    const { data: myPermissions } = useFetchMyPermissions()
    const productGroupsWithoutThis = productGroups.filter(
      productGroup => productGroup.id !== productGroupById?.id,
    )
    const productGroupsWithoutMaxNesting =
      filterProductGroupsWithMaxNesting(productGroupsWithoutThis)

    const { formState, control, handleSubmit } = useForm<ProductGroupDialogFormValues>({
      defaultValues: {
        name: productGroupById?.name ?? '',
        employeeGroups:
          productGroupById?.productGroupEmployeeGroups?.map(g => ({
            id: g.employeeGroup.id,
            name: g.employeeGroup.name,
          })) ?? [],
        description: productGroupById?.description ?? '',
        parentId: productGroupById?.parentId ?? undefined,
      },
    })
    const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
    const [openSnackbar] = useSnackbar()

    const { mutateAsync: createProductGroup } = useCreateProductGroup()
    const { mutateAsync: updateProductGroup } = useUpdateProductGroup()
    const { mutateAsync: archiveServiceGroup, isLoading: isLoadingOnArchive } =
      useArchiveProductGroup()

    const mutateArchiveProductGroup = async (archived: boolean) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await archiveServiceGroup({ id: productGroupById!.id, archived })

      closeDialog()
    }

    const isPermissionEditingAllowed = myPermissions?.includes(permissions.rolePermission.set)

    const mutateProductDialog: SubmitHandler<ProductGroupDialogFormValues> = async ({
      name,
      description,
      parentId,
      employeeGroups,
    }) => {
      if (isCreate) {
        const dataEmployeeGroup = employeeGroups.map(g => ({
          employeeGroupId: g.id,
        }))

        const result = await createProductGroup({
          name,
          branchId,
          description,
          parentId,
          productGroupEmployeeGroups:
            isPermissionEditingAllowed && dataEmployeeGroup
              ? {
                  data: dataEmployeeGroup,
                }
              : undefined,
        })
        if (result?.insertProductGroup)
          openSnackbar(t('productGroup.createdSuccessfully'), 'success', 3000)
        else openSnackbar(t('submitError'), 'error', 3000)
      } else if (productGroupById) {
        const result = await updateProductGroup({
          id: productGroupById.id,
          productGroupSetInput: { name, description, parentId: parentId ?? null },
          productGroupProductEmployeeInsertInputs: isPermissionEditingAllowed
            ? employeeGroups.map(g => ({
                productGroupId: productGroupById.id,
                employeeGroupId: g.id,
              }))
            : undefined,
        })

        if (result?.updateProductGroupById)
          openSnackbar(t('productGroup.updatedSuccessfully'), 'success', 3000)
        else openSnackbar(t('submitError'), 'error', 3000)
      }

      closeDialog()
    }

    const { showArchiveConfirmationPopup, archiveConfirmationModal } =
      useShowArchiveConfirmationPopup(productGroupById?.name ?? '', () =>
        mutateArchiveProductGroup(true),
      )

    const { warningModal, showWarningPopup } = useShowWarningPopup(
      t('warning'),
      t('productGroup.archiveWarning'),
    )

    const handleOnArchive = () => {
      const products = productGroupById?.products?.filter(s => s.archived === null)

      if (products?.length !== 0) showWarningPopup()
      else showArchiveConfirmationPopup()
    }

    const isEditingAllowed = myPermissions?.includes(permissions.product.set)
    const isArchivingAllowed = myPermissions?.includes(permissions.service.archive)

    const myEmployeeGroup = employeeGroups.find(eG =>
      eG.employees.find(e => e.id === store.me.employeeId),
    )

    return (
      <Modal
        close={closePopups}
        confirm={() => {
          if (isEditingAllowed && !formState.isSubmitting && formState.isDirty)
            handleSubmit(mutateProductDialog)()
        }}
      >
        <Dialog>
          <Dialog.Title>{t('productGroup.name')}</Dialog.Title>

          <Dialog.Body className="w-128">
            <div className="flex">
              <Controller
                control={control}
                name="name"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    label={t('title')}
                    required
                    value={value}
                    errorMessage={{
                      isShown: Boolean(formState.errors.name),
                      text: t('formError.required'),
                    }}
                    onChange={onChange}
                    disabled={!isEditingAllowed}
                    containerClassName="w-1/2 pr-2"
                    autoFocus
                  />
                )}
              />

              <Controller
                control={control}
                name="parentId"
                render={({ field: { value, onChange } }) => (
                  <SelectDropdown
                    label={t('parentGroup')}
                    value={value}
                    className="w-1/2"
                    onSelectChange={onChange}
                    items={productGroupsWithoutMaxNesting ?? []}
                    disabled={!isEditingAllowed}
                    isClearable
                    popupHeight="small"
                  />
                )}
              />
            </div>

            <Controller
              control={control}
              name="employeeGroups"
              rules={{
                validate: values => {
                  if (values.length === 0) return true
                  return Boolean(values.find(group => group.id === myEmployeeGroup?.id))
                },
              }}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  label={t('employeeGroups.name')}
                  items={employeeGroups ?? []}
                  onItemSelect={onChange}
                  selectedItems={value as SelectDropDownItem[]}
                  dropdownInputPlaceholder=""
                  hint={t('employeeGroups.hint')}
                  disabled={!isPermissionEditingAllowed || !isEditingAllowed}
                  errorMessage={{
                    isShown: Boolean(formState.errors.employeeGroups),
                    text: t('employeeGroups.error', { name: myEmployeeGroup?.name ?? '' }),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field: { value, onChange } }) => (
                <Textarea
                  label={t('description')}
                  placeholder={t('placeholders.productGroupDescription')}
                  value={value}
                  onChange={onChange}
                  disabled={!isEditingAllowed}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            {isEditingAllowed && (
              <SaveButton
                onClick={handleSubmit(mutateProductDialog)}
                disabled={formState.isSubmitting || !formState.isDirty}
                spinner={formState.isSubmitting}
                isCreate={isCreate}
              />
            )}

            <CloseButton onClick={closePopups} />

            {isArchivingAllowed &&
              !isCreate &&
              (productGroupById?.archived ? (
                <RestoreButton
                  className="mr-auto"
                  onClick={() => mutateArchiveProductGroup(false)}
                  disabled={isLoadingOnArchive}
                  spinner={isLoadingOnArchive}
                />
              ) : (
                <ArchiveButton
                  className="mr-auto"
                  onClick={handleOnArchive}
                  disabled={isLoadingOnArchive}
                  spinner={isLoadingOnArchive}
                />
              ))}
          </Dialog.Footer>
        </Dialog>
        {confirmPopup}
        {archiveConfirmationModal}
        {warningModal}
      </Modal>
    )
  },
)

const MAX_NESTING_LEVEL = 3

const getDoesGroupHaveMaxNesting = (
  productGroup: ServerProductGroupType,
  productGroups: ServerProductGroupType[],
  nestingLevel?: number,
): boolean => {
  if (productGroup.parentId === undefined) return false

  const parent = productGroups.find(
    maybeParentGroup => maybeParentGroup.id === productGroup.parentId,
  )
  if (!parent) return false

  const newNestingLevel = (nestingLevel ?? 1) + 1
  if (newNestingLevel === MAX_NESTING_LEVEL) return true

  return getDoesGroupHaveMaxNesting(parent, productGroups, newNestingLevel)
}

const filterProductGroupsWithMaxNesting = (productGroups: ServerProductGroupType[]) => {
  return productGroups.filter(
    productGroup => !getDoesGroupHaveMaxNesting(productGroup, productGroups),
  )
}

type ProductGroupDialogFormValues = {
  name: string
  description: string | undefined
  parentId: number | undefined
  employeeGroups: SelectDropDownItem[]
}

interface ProductGroupDialogLogicProps extends Omit<DialogProps, 'id'> {
  productGroupById: ServerProductGroupType | undefined
  employeeGroups: EmployeeGroupWithSchedule[]
  productGroups: ServerProductGroupType[]
}
