import { ServerClientNoteType, useArchiveClientNoteById } from '@expane/data'
import { onlyOwnersPermissions, permissions } from '@expane/logic/permission'
import { ConfirmationArgs, Hint, Pencil } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { observer } from 'mobx-react-lite'
import { FC, memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { IoTrash } from 'react-icons/io5'
import { store } from 'store'
import { CommentLabel } from 'widgets/ClientDialog/CommentsTab/CommentLabel'
import { OpenEditCommentDialogFunc } from './EditCommentDialog'
import { useOnClickWithBillingCheck } from 'widgets/Buttons'

interface Props {
  comment: ServerClientNoteType
  myPermission: string[]
  openEditCommentDialog: OpenEditCommentDialogFunc
  showConfirmation: (dto: ConfirmationArgs) => void
}

const NotMemoizedComment: FC<Props> = ({
  comment,
  myPermission,
  openEditCommentDialog,
  showConfirmation,
}) => {
  const myEmployeeId = store.me.employeeId

  const { t } = useTranslation()
  const [openSnackBar] = useSnackbar()

  const { mutateAsync: archiveNote, isLoading: isLoadingArchiveNote } = useArchiveClientNoteById()

  const {
    id,
    text,
    employee: { id: employeeId },
  } = comment

  const archiveCommentMutation = useCallback(async () => {
    const result = await archiveNote(id)

    if (result.updateClientNoteById.id) openSnackBar(t('comment.archivedSuccessfully'), 'success')
    else openSnackBar(t('submitError'), 'error')
  }, [archiveNote, id, openSnackBar, t])

  const isItOwner = myPermission.includes(onlyOwnersPermissions.owner.set)
  const isEditingClientNoteAllowed = myPermission.includes(permissions.clientNote.set)
  const isEditingAllowed = isItOwner || (isEditingClientNoteAllowed && employeeId === myEmployeeId)

  const onDeleteCommentWithBillingCheck = useOnClickWithBillingCheck(() =>
    showConfirmation({
      onConfirm: archiveCommentMutation,
      title: t('archive.action'),
      description: `${t('archive.confirmation')} ${t('comment.title').toLocaleLowerCase()}?`,
    }),
  )
  const onEditCommentWithBillingCheck = useOnClickWithBillingCheck(() =>
    openEditCommentDialog(comment, isEditingAllowed),
  )

  return (
    <li className="flex items-center mb-3">
      <div className="w-full pt-1 px-3 border-2 border-input-disabled-color rounded-lg">
        <CommentLabel comment={comment} border />

        <div className="flex justify-between gap-1 my-3">
          <p className="w-11/12 text-sm text-gray-400 dark:text-gray-400 break-words">{text}</p>

          <div className="flex gap-1 w-1/12 justify-end">
            {isEditingAllowed && (
              <>
                <button onClick={onEditCommentWithBillingCheck}>
                  <Pencil
                    size="1.2rem"
                    className="justify-self-end text-gray-600 hover:text-primary-500 cursor-pointer"
                  />
                </button>

                <button onClick={onDeleteCommentWithBillingCheck} disabled={isLoadingArchiveNote}>
                  <IoTrash
                    size="1.2rem"
                    className="justify-self-end text-gray-600 hover:text-red-500 cursor-pointer"
                  />
                </button>
              </>
            )}
            {isEditingClientNoteAllowed && !isEditingAllowed && <Hint>{t('comment.hint')}</Hint>}
          </div>
        </div>
      </div>
    </li>
  )
}

export const Comment = memo(observer(NotMemoizedComment))
