import { gql } from 'graphql-request'
import {
  LONG_STALE_TIME,
  queryClient,
  reportError,
  reportGqlError,
  request,
  useQuery,
  useQueryOriginal,
} from '../../api'
import {
  BRANCHES_BRIEF_QUERY_KEY,
  BRANCHES_FINANCIAL_SETTINGS_QUERY_KEY,
  BRANCHES_QUERY_KEY,
} from './queryKeys'
import { SCHEDULES_QUERY_KEY } from '../schedule'
import {
  branchBriefFragment,
  branchFinancialSettingsFragment,
  branchFragment,
} from './branch.fragments'
import { BranchToSwitchAsClientType, BranchWithSchedule } from './logic'
import {
  ServerBranchBriefType,
  ServerBranchFinancialSettingsType,
} from '../../generated/graphql-types'
import { AS_CLIENT_QUERY_KEY } from '../client'

export function useFetchBranchesBrief(enabled?: boolean) {
  return useQuery(
    [BRANCHES_QUERY_KEY, BRANCHES_BRIEF_QUERY_KEY],
    async () => {
      const result = await request<{ branches: ServerBranchBriefType[] }>(gql`
        ${branchBriefFragment}
        query {
          branches {
            ...branchBriefType
          }
        }
      `)
      return result.branches
    },
    {
      enabled,
      onError: reportGqlError,
      staleTime: LONG_STALE_TIME,
      queryName: 'useFetchBranchesBrief',
    },
  )
}

export function useFetchBranches() {
  return useQuery<BranchWithSchedule[] | undefined>(
    [BRANCHES_QUERY_KEY, SCHEDULES_QUERY_KEY, BRANCHES_FINANCIAL_SETTINGS_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${branchFragment}
          query {
            branches {
              ...branchType
            }
          }
        `,
      )

      if (Array.isArray(result?.branches)) {
        return result.branches
      } else {
        return undefined
      }
    },
    {
      onSuccess: data => {
        if (data)
          for (const branch of data) {
            queryClient.setQueryData([[BRANCHES_FINANCIAL_SETTINGS_QUERY_KEY, branch.id]], {
              currency: branch.currency,
              displayCoins: branch.displayCoins,
            })
            queryClient.setQueryData(
              [
                BRANCHES_QUERY_KEY,
                SCHEDULES_QUERY_KEY,
                BRANCHES_FINANCIAL_SETTINGS_QUERY_KEY,
                branch.id,
              ],
              { ...branch },
            )
          }
      },
      queryName: 'useFetchBranches',
      staleTime: LONG_STALE_TIME,
      onError: reportGqlError,
    },
  )
}

export function useFetchBranchByIdBrief(id: number | undefined) {
  return useQuery(
    [BRANCHES_QUERY_KEY, BRANCHES_BRIEF_QUERY_KEY, id],
    async () => {
      const result = await request<{ branchById: ServerBranchBriefType }>(
        gql`
          ${branchBriefFragment}
          query ($id: Int!) {
            branchById(id: $id) {
              ...branchBriefType
            }
          }
        `,
        { id },
      )
      return result.branchById
    },
    {
      onError: reportGqlError,
      queryName: 'useFetchBranchByIdBrief',
      enabled: Boolean(id),
    },
  )
}

export function useFetchBranchById(id: number | undefined) {
  return useQuery<BranchWithSchedule | undefined>(
    [BRANCHES_QUERY_KEY, SCHEDULES_QUERY_KEY, BRANCHES_FINANCIAL_SETTINGS_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${branchFragment}
          query ($id: Int!) {
            branchById(id: $id) {
              ...branchType
            }
          }
        `,
        { id },
      )

      return result.branchById
    },
    {
      queryName: 'useFetchBranchById',
      staleTime: LONG_STALE_TIME,
      onError: reportGqlError,
      enabled: Boolean(id),
    },
  )
}

export function useFetchCurrentBranchTimezone(branchId: number | undefined) {
  // TODO: return all query state instead of only data
  const query = useQueryOriginal(
    [BRANCHES_QUERY_KEY, 'timezone', { branchId }],
    async () => {
      const result = await request<{ branchById: { timezone: string } }>(
        gql`
          query ($branchId: Int!) {
            branchById(id: $branchId) {
              timezone
            }
          }
        `,
        { branchId },
      )

      return result.branchById.timezone
    },
    {
      onError: reportGqlError,
      enabled: Boolean(branchId),
    },
  )

  return query.data
}
export function useGetBranchDefaultAccountId(branchId: number | undefined) {
  const result = useFetchBranchById(branchId)

  return { ...result, data: result.data?.defaultAccount?.id }
}

export function useGetBranchDefaultStorageId(branchId: number | undefined) {
  const result = useFetchBranchById(branchId)

  return { ...result, data: result.data?.defaultStorage?.id }
}

export function useGetBranchDefaultSchedule(branchId: number | undefined) {
  const result = useFetchBranchById(branchId)

  return { ...result, data: result.data?.schedule }
}

export function useFetchBranchFinancialSettings(branchId: number | undefined, enabled = true) {
  return useQuery<ServerBranchFinancialSettingsType | undefined>(
    [BRANCHES_FINANCIAL_SETTINGS_QUERY_KEY, branchId],
    async () => {
      const result = await request(
        gql`
          ${branchFinancialSettingsFragment}
          query ($branchId: Int!) {
            branchById(id: $branchId) {
              ...branchFinancialSettingsType
            }
          }
        `,
        { branchId },
      )

      if (result?.branchById) {
        return result.branchById
      }

      reportError(new Error('Error on fetch financial settings'), 'error', { result })
      return null
    },
    {
      queryName: 'useFetchBranchFinancialSettings',
      staleTime: LONG_STALE_TIME,
      onError: reportGqlError,
      enabled: enabled && Boolean(branchId),
    },
  )
}

export interface BranchWithBusinessId {
  id: number
  name: string
  businessId: number
}

export function useFetchAllBranches() {
  return useQuery<BranchWithBusinessId[] | undefined>(
    [BRANCHES_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          query {
            branchView {
              id
              name
              businessId
            }
          }
        `,
      )

      if (Array.isArray(result?.branchView)) {
        return result.branchView
      } else {
        return undefined
      }
    },
    {
      queryName: 'useFetchAllBranches',
      onError: reportGqlError,
    },
  )
}

export function useFetchAllBranchesAsClient(enabled?: boolean) {
  return useQuery<BranchToSwitchAsClientType[] | undefined>(
    [BRANCHES_QUERY_KEY, AS_CLIENT_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          query {
            branchToSwitchAsClientView {
              id
              name
              businessId
              address
              phone
            }
          }
        `,
      )

      if (Array.isArray(result?.branchToSwitchAsClientView)) {
        return result.branchToSwitchAsClientView
      } else {
        return undefined
      }
    },
    {
      queryName: 'useFetchAllBranchesAsClient',
      onError: reportGqlError,
      enabled,
    },
  )
}
