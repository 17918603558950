import { FC, useState } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { ClientSelectDropdown } from 'widgets/ClientSelectDropdown'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import {
  ClientPhones,
  useFetchClientsBriefs,
  useFetchClientsWithFullNameFuzzSearch,
} from '@expane/data'
import { addPhonesToClients } from '@expane/logic/client'
import { SubmitQuickSaleDialogFormValues } from './logic'

interface ClientInputProps {
  control: Control<SubmitQuickSaleDialogFormValues>
  timezone: string
  clientsPhones: ClientPhones[]
  defaultClientId?: number | null
  focusToModal: () => void
}

export const ClientInput: FC<ClientInputProps> = ({
  control,
  defaultClientId,
  focusToModal,
  clientsPhones,
  timezone,
}) => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')

  const watchedClientId = useWatch({
    control,
    name: 'clientId',
  })

  const { data: clientsWithoutPhones } = useFetchClientsBriefs(branchId, timezone, watchedClientId)
  const { data: clientsBySearchValue } = useFetchClientsWithFullNameFuzzSearch(
    searchValue,
    branchId,
    timezone,
  )
  const clientsToDisplay = searchValue ? clientsBySearchValue : clientsWithoutPhones
  const clients = addPhonesToClients(clientsToDisplay, clientsPhones)

  return (
    <div className="w-1/2">
      <Controller
        name="clientId"
        defaultValue={defaultClientId ?? undefined}
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <ClientSelectDropdown
            clients={clients}
            onChange={onChange}
            value={value}
            errorMessage={{
              isShown: Boolean(error),
              text: t('formError.required'),
            }}
            isCreateClient
            required
            shiftFocus={focusToModal}
            onSearchValueChange={setSearchValue}
            customSearchPlaceholder={
              clientsWithoutPhones && clientsWithoutPhones.length === 0
                ? t('noItems')
                : clients.length === 0
                ? t('noClient')
                : undefined
            }
          />
        )}
      />
    </div>
  )
}
