import { useFetchReceiptPngById } from '@expane/data'
import { Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { FC, useRef } from 'react'
import { ReceiptDialogLogic } from './ReceiptDialogLogic'
import { ReceiptDialogPlaceholder } from './ReceiptDialogPlaceholder'

const ReceiptDialog: FC<{ receiptId: string; closeDialog: () => void }> = ({
  receiptId,
  closeDialog,
}) => {
  const { data, isLoading } = useFetchReceiptPngById(receiptId)

  useErrorOpeningDialog(!isLoading && !data, closeDialog)

  if (!isLoading && !data) return null

  return (
    <Modal>
      <Dialog>
        {isLoading ? (
          <ReceiptDialogPlaceholder closeDialog={closeDialog} receiptId={receiptId} />
        ) : (
          <ReceiptDialogLogic
            src={URL.createObjectURL(data)}
            receiptId={receiptId}
            closeDialog={closeDialog}
          />
        )}
      </Dialog>
    </Modal>
  )
}

export const useOpenReceiptDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogReceiptId = useRef<string | undefined>()

  const openReceiptDialog = (receiptId: string) => {
    dialogReceiptId.current = receiptId
    openPopup()
  }

  const receiptDialog =
    isOpen && dialogReceiptId.current ? (
      <ReceiptDialog closeDialog={closePopup} receiptId={dialogReceiptId.current} />
    ) : null

  return { openReceiptDialog, receiptDialog }
}
