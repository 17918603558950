import { gql } from 'graphql-request'
import { reportError, reportGqlError, request, useQuery } from '../../api'
import { ServerTimeOffReasonType } from '../../generated/graphql-types'
import { timeOffReasonFragment } from './timeOffReason.fragments'
import { TIME_OFF_REASONS_QUERY_KEY } from './queryKeys'

export const useFetchTimeOffReasons = () => {
  return useQuery<ServerTimeOffReasonType[]>(
    [TIME_OFF_REASONS_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${timeOffReasonFragment}
          query {
            timeOffReasons(order_by: { id: desc }) {
              ...timeOffReasonType
            }
          }
        `,
      )

      if (Array.isArray(result?.timeOffReasons)) {
        return result.timeOffReasons
      } else {
        reportError(new Error('timeOffReasons is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchTimeOffReasons',
      onError: reportGqlError,
    },
  )
}
