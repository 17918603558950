import { ServerExtendedServiceType, SERVICE_TYPE } from '@expane/data'
import {
  RATE_FROM_SERVICES_FALLBACK_TYPES,
  RATE_FROM_SERVICES_TYPES,
} from '@expane/logic/salarySettings'
import { findById } from '@expane/logic/utils'
import { Hint, Input, SelectDropdown, TableCell, TableRow } from '@expane/ui'
import { translateData } from 'logic/utils'
import { FC } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SalarySettingFormValues, validateServiceRateSetting } from '../logic'
import { Checkbox } from '@expane/ui'
import { PLACEHOLDERS } from '@expane/logic/form'

interface RateFromServicesItemProps {
  services: ServerExtendedServiceType[]
  control: Control<SalarySettingFormValues>
  index: number
  disabled?: boolean
}

export const RateFromServicesItem: FC<RateFromServicesItemProps> = ({
  services,
  control,
  index,
  disabled,
}) => {
  const { t } = useTranslation()

  const watchedDependsOnRealizedValue = useWatch({
    control,
    name: `salarySettingServices.${index}.dependsOnRealizedValue`,
  })

  const watchedType = useWatch({
    control,
    name: `salarySettingServices.${index}.type`,
  })
  const watchedFallbackType = useWatch({
    control,
    name: `salarySettingServices.${index}.fallbackType`,
  })

  const watchedServiceId = useWatch({
    control,
    name: `salarySettingServices.${index}.serviceId`,
  })

  const service = findById(watchedServiceId, services)
  const isServiceForQuickSale = service?.type === SERVICE_TYPE.forSale

  const isFixed = watchedType === RATE_FROM_SERVICES_TYPES.fixedValue.id

  return (
    <TableRow>
      <TableCell>
        <Controller
          name={`salarySettingServices.${index}.name` as 'salarySettingServices.0.name'}
          control={control}
          render={({ field: { value } }) => (
            <div className="flex items-center">
              <p className="line-clamp-1 mr-2">{value}</p>
              {isServiceForQuickSale && !isFixed && (
                <Hint>{t('salarySetting.servicesForSaleHint')}</Hint>
              )}
            </div>
          )}
        />
      </TableCell>

      <TableCell>
        <Controller
          name={`salarySettingServices.${index}.type` as 'salarySettingServices.0.type'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectDropdown
              value={value}
              items={translateData(Object.values(RATE_FROM_SERVICES_TYPES), t)}
              placeholder={t('placeholders.defaultSelect')}
              noDataMessage={t('noSuchRateType')}
              onSelectChange={onChange}
              height="small"
              disabled={disabled}
            />
          )}
        />
      </TableCell>

      <TableCell>
        <div className="flex">
          <Controller
            name={`salarySettingServices.${index}.value` as 'salarySettingServices.0.value'}
            control={control}
            rules={{
              validate: value =>
                validateServiceRateSetting(value, watchedType, watchedDependsOnRealizedValue),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                containerClassName="w-24 mr-4"
                type="number"
                placeholder={PLACEHOLDERS.servicePrice}
                value={value}
                onChange={e => onChange(e.target.value)}
                height="small"
                errorMessage={{ isShown: Boolean(error), text: ' ', reserveIndent: false }}
                disabled={disabled}
              />
            )}
          />
        </div>
      </TableCell>
      <TableCell>
        <Controller
          name={
            `salarySettingServices.${index}.dependsOnRealizedValue` as 'salarySettingServices.0.dependsOnRealizedValue'
          }
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              checked={value}
              onChange={onChange}
              disabled={disabled || isFixed}
              label={t('fromRealized')}
            />
          )}
        />
      </TableCell>

      <TableCell>
        {watchedDependsOnRealizedValue ? (
          <Controller
            name={
              `salarySettingServices.${index}.fallbackType` as 'salarySettingServices.0.fallbackType'
            }
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectDropdown
                value={value}
                items={translateData(Object.values(RATE_FROM_SERVICES_FALLBACK_TYPES), t)}
                placeholder={t('placeholders.defaultSelect')}
                noDataMessage={t('noSuchRateType')}
                onSelectChange={onChange}
                height="small"
                disabled={disabled || !watchedDependsOnRealizedValue || isServiceForQuickSale}
              />
            )}
          />
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell>
        {watchedDependsOnRealizedValue ? (
          <Controller
            name={
              `salarySettingServices.${index}.fallbackValue` as 'salarySettingServices.0.fallbackValue'
            }
            control={control}
            rules={{
              validate: value => {
                if (isServiceForQuickSale) return true

                return validateServiceRateSetting(
                  value,
                  watchedFallbackType,
                  watchedDependsOnRealizedValue,
                )
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="number"
                placeholder={PLACEHOLDERS.servicePrice}
                value={value}
                onChange={e => onChange(e.target.value)}
                height="small"
                errorMessage={{ isShown: Boolean(error), text: ' ', reserveIndent: false }}
                disabled={disabled || !watchedDependsOnRealizedValue || isServiceForQuickSale}
              />
            )}
          />
        ) : (
          '-'
        )}
      </TableCell>
    </TableRow>
  )
}
