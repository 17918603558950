import { ServerExtendedServiceType, ServerServiceGroupType } from '@expane/data'
import { useCheckIsOneBranchInBusiness } from '@expane/logic/branch'
import { permissions } from '@expane/logic/permission'
import { INTERBRANCH_SERVICE_GROUP_ID } from '@expane/logic/service'
import { findById } from '@expane/logic/utils'
import { EmptyPlaceholder, Page, Paper } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { PageDataType } from 'pages/CreateBusinessPage/logic'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAddCircle, IoReaderOutline } from 'react-icons/io5'
import { useSnackbar } from '@expane/widgets'
import { treeMenuClassName } from 'ui/styles'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { onChangeSingleTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { ButtonWithBillingCheck, CreateCategoryButton } from 'widgets/Buttons'
import { InterbranchServiceDialog } from 'widgets/InterbranchService'
import { ArchiveListNavigationButton, ListNavigationButton } from 'widgets/NavigationButtons'
import { ServiceDialog } from 'widgets/ServiceDialog'
import { ServiceGroupDialog } from 'widgets/ServiceGroupDialog'
import { ServiceQuickInfo } from './ServiceQuickInfo'
import { PaperResizable } from 'ui/PaperResizable'

interface ServicePageDataProps {
  services: ServerExtendedServiceType[]
  serviceGroups: ServerServiceGroupType[]
  archive?: boolean
  type?: PageDataType
}

export const ServicePageData: FC<ServicePageDataProps> = ({
  serviceGroups,
  services,
  archive = false,
  type = 'page',
}) => {
  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()

  const { isOneBranchInBusiness } = useCheckIsOneBranchInBusiness()

  const [openSnackbar] = useSnackbar()

  const {
    openEditDialog: openEditServiceDialog,
    openCreateDialog: openCreateServiceDialog,
    dialog: serviceDialog,
  } = useOpenDialog(ServiceDialog)

  const {
    openEditDialog: openEditServiceGroupDialog,
    openCreateDialog: openCreateServiceGroupDialog,
    dialog: serviceGroupDialog,
  } = useOpenDialog(ServiceGroupDialog)

  const {
    openEditDialog: openEditInterbranchServiceDialog,
    openCreateDialog: openCreateInterbranchServiceDialog,
    dialog: generalServiceDialog,
  } = useOpenDialog(InterbranchServiceDialog)

  const [selectedItem, setSelectedItem] = useState<TreeMenuItem | undefined>()

  const treeItems = transformDataForTreeMenu(serviceGroups, services, {
    keepEmptyFolders: true,
  })

  const isCreatingAllowed = myPermissions?.includes(permissions.service.set)

  const openEditDialog = (id: number) => {
    const service = findById(id, services)
    if (service?.availableToAllBranches) openEditInterbranchServiceDialog(id)
    else openEditServiceDialog(id)
  }

  return (
    <Page isPadding={type === 'page'}>
      {isCreatingAllowed && (
        <div className="flex mb-2 gap-2">
          {archive ? (
            <ListNavigationButton title={t('services')} />
          ) : (
            <>
              <ButtonWithBillingCheck onClick={() => openCreateServiceDialog()} Icon={IoAddCircle}>
                {t('service.name')}
              </ButtonWithBillingCheck>

              <CreateCategoryButton onClick={() => openCreateServiceGroupDialog()} />

              {type !== 'createBusiness' && (
                <>
                  {!isOneBranchInBusiness && (
                    <ButtonWithBillingCheck
                      onClick={() => openCreateInterbranchServiceDialog()}
                      Icon={IoAddCircle}
                    >
                      {t('interbranchService.name')}
                    </ButtonWithBillingCheck>
                  )}

                  <ArchiveListNavigationButton className="ml-auto" />
                </>
              )}
            </>
          )}
        </div>
      )}

      {services?.length === 0 && serviceGroups?.length === 0 ? (
        <EmptyPlaceholder
          Icon={IoReaderOutline}
          text={archive ? t('emptyPlaceholder.archive') : t('emptyPlaceholder.service')}
        />
      ) : (
        <Paper className="w-full h-full overflow-auto flex">
          <PaperResizable defaultWidth={type === 'page' ? '1/4' : '1/3'} withoutBorder>
            <TreeMenu
              type="SinglePickMode"
              items={treeItems}
              className={treeMenuClassName + ' h-full'}
              selected={selectedItem}
              onSelected={item => setSelectedItem(onChangeSingleTreeMenu(item, selectedItem))}
              openSwitch
              onEditFolder={folder => {
                if (folder.id === INTERBRANCH_SERVICE_GROUP_ID) {
                  openSnackbar(t('interbranchService.interbranchServiceGroupOnEditInfo'), 'success')
                  return
                }

                openEditServiceGroupDialog(folder.id)
              }}
              onEditItem={item => {
                openEditDialog(item.id)
              }}
            />
          </PaperResizable>

          {selectedItem && (
            <ServiceQuickInfo
              editService={openEditDialog}
              service={findById(selectedItem.id, services)}
              key={selectedItem.id}
              type={type}
            />
          )}
        </Paper>
      )}

      {serviceDialog}
      {serviceGroupDialog}
      {generalServiceDialog}
    </Page>
  )
}
