import { ErrorMessageType, stopEnterPropagation } from '@expane/ui'
import { useGetFocusedElement } from 'logic/hooks/useGetFocusedElement'
import { Dispatch, FC, SetStateAction, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCloseSharp } from 'react-icons/io5'
import { PropsWithImageCropperOptions } from 'ui/ImageCropper'

export interface UploadPhotoProps {
  containerClassName?: string
  errorMessage?: ErrorMessageType
  urlPhoto?: string
  setUrlPhoto?: Dispatch<SetStateAction<string>>
  size?: SizeTypes
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectFile: (selectedFile: any) => void
  disabled?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reportErrorHandlerFunction?: (e: Error, level: 'error', extra?: Record<string, any>) => void
}

export type SizeTypes = 'small' | 'medium' | 'big' | 'xl'

export const UploadPhoto: FC<PropsWithImageCropperOptions<UploadPhotoProps>> = ({
  containerClassName,
  errorMessage,
  urlPhoto,
  setUrlPhoto,
  size = 'medium',
  onSelectFile,
  disabled = false,
  reportErrorHandlerFunction,
  cropShape,
}) => {
  const [isHoverFile, setIsHoverFile] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const editButtonRef = useRef<HTMLButtonElement>(null)
  const deleteButtonRef = useRef<HTMLButtonElement>(null)

  const focusedElement = useGetFocusedElement([inputRef, editButtonRef, deleteButtonRef])

  const { t } = useTranslation()

  const isFocus =
    focusedElement !== null &&
    !disabled &&
    (inputRef.current === focusedElement ||
      editButtonRef.current === focusedElement ||
      deleteButtonRef.current === focusedElement)

  const onChangeUploadInput = e => {
    const file = e.currentTarget.files[0]

    if (file.type === 'image/png' || file.type === 'image/webp') {
      const img = new Image()
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let error: any

      if (ctx) {
        const reader = new FileReader()

        reader.onload = e => {
          try {
            img.src = e.target?.result as string
          } catch (e) {
            error = e
          }
        }

        img.onload = async () => {
          try {
            canvas.height = img.height
            canvas.width = img.width

            ctx.fillStyle = '#f3f4f6' // bg-gray-100
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(img, 0, 0)

            const res = await fetch(canvas.toDataURL())
            const blob = await res.blob()
            onSelectFile(new File([blob], file.name, { type: file.type }))
          } catch (e) {
            error = e
          }
        }

        reader.readAsDataURL(file)

        // If there is any error in the file reader or the canvas,
        // just goes through this step and returns the file
        if (!error) return
      }
    }

    onSelectFile(file)
  }

  const onClickEditButton = () => {
    inputRef.current?.click()
  }

  const onClickDeleteButton = () => {
    if (urlPhoto)
      if (inputRef.current?.value) {
        inputRef.current.value = ''
      }
    onSelectFile(null)
  }

  let styles = 'flex-centered flex-col overflow-hidden relative border-dashed transition-transform'

  const roundedStyle = cropShape === 'rect' ? ' rounded-2xl ' : ' rounded-full '

  styles += roundedStyle

  if (isFocus || isHoverFile) {
    styles += ' bg-accent transform scale-105'
  }

  styles += mapSizeToStyles[size]
  styles += urlPhoto ? ' border-transparent' : ' border-2'

  if (!disabled) {
    styles += errorMessage?.isShown
      ? ' border-error-500 text-error-500'
      : ' border-primary-400 bg-hover text-primary-400'
  } else {
    styles += ' border-gray-300 text-gray-300 pointer-events-none'
  }

  const commonButtonStyle =
    'flex-centered flex-col w-8 h-8 overflow-hidden rounded-full border-2 border-white dark:border-gray-700 absolute ' +
    'text-white cursor-pointer transition-all focus:ring-1 '

  let editButtonStyle = commonButtonStyle + 'bg-primary-400 hover:bg-primary-600 ring-primary-600'

  let deleteButtonStyle = commonButtonStyle + 'bg-error-600 hover:bg-error-400 ring-error-600'

  if (isFocus || isHover) {
    editButtonStyle += ' opacity-100'
    deleteButtonStyle += ' opacity-100'
    editButtonStyle += size === 'small' ? ' -bottom-1 -right-1' : ' bottom-1 right-1'
    deleteButtonStyle += size === 'small' ? ' -top-1 -right-1' : ' top-1 right-1'
  } else {
    editButtonStyle += ' bottom-1/2 right-1/2 translate-y-1/2 translate-x-1/2 opacity-0'
    deleteButtonStyle += ' top-1/2 right-1/2 -translate-y-1/2 translate-x-1/2 opacity-0'
  }

  return (
    <div className={containerClassName}>
      <div
        className="relative max-w-min max-h-min"
        onMouseEnter={() => {
          if (!isHover) setIsHover(true)
        }}
        onMouseLeave={() => {
          if (isHover) setIsHover(false)
        }}
      >
        <div className={styles}>
          <label
            className={`flex flex-col justify-center items-center w-full h-full overflow-hidden relative ${roundedStyle}`}
          >
            <span className="m-2 text-base leading-3 text-primary font-bold pointer-events-none">
              {t('photo.name')}
            </span>
            <input
              // needed for hover effect when drag file
              onDragOver={() => {
                if (!isHoverFile) setIsHoverFile(true)
              }}
              onDragLeave={() => {
                if (isHoverFile) setIsHoverFile(false)
              }}
              onDrop={() => {
                if (isHoverFile) setIsHoverFile(false)
              }}
              onKeyDown={stopEnterPropagation}
              onChange={onChangeUploadInput}
              ref={inputRef}
              type="file"
              className={`block absolute w-full h-full overflow-hidden opacity-0 cursor-pointer ${roundedStyle}`}
              accept="image/*"
            />

            {urlPhoto && (
              <img
                src={urlPhoto}
                alt=""
                className="absolute w-full h-full cursor-pointer bg-gray-100"
                onError={() => {
                  reportErrorHandlerFunction?.(new Error('Error loading image'), 'error', {
                    url: urlPhoto,
                  })
                  setUrlPhoto?.('')
                }}
              />
            )}
          </label>
        </div>

        {!disabled && (
          <button
            ref={editButtonRef}
            onKeyDown={stopEnterPropagation}
            className={editButtonStyle}
            onClick={onClickEditButton}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.6895 1.25C9.97079 0.968795 10.3523 0.810822 10.75 0.810822C11.1477 0.810822 11.5292 0.968795 11.8105 1.25L13.75 3.1895C14.0312 3.47079 14.1892 3.85226 14.1892 4.25C14.1892 4.64775 14.0312 5.02921 13.75 5.3105L12.5605 6.5L8.5 2.4395L9.6895 1.25ZM7.4395 3.5L0.6895 10.25C0.408176 10.5312 0.250085 10.9127 0.25 11.3105V13.25C0.25 13.6478 0.408035 14.0294 0.68934 14.3107C0.970644 14.592 1.35218 14.75 1.75 14.75H3.6895C4.08729 14.7499 4.46876 14.5918 4.75 14.3105L11.5 7.5605L7.4395 3.5Z"
                fill="white"
              />
            </svg>
          </button>
        )}

        {urlPhoto && !disabled && (
          <button
            ref={deleteButtonRef}
            onKeyDown={stopEnterPropagation}
            className={deleteButtonStyle}
            onClick={onClickDeleteButton}
          >
            <IoCloseSharp />
          </button>
        )}
      </div>
    </div>
  )
}

const mapSizeToStyles = {
  small: ' w-20 h-20',
  medium: ' w-28 h-28',
  big: ' w-32 h-32',
  xl: ' w-64 h-64',
}
