import { useEffect, useState } from 'react'

interface Size {
  width: number
  height: number
}

export const useWindowSize = () => {
  const [size, setSize] = useState<Size>({ width: window.innerWidth, height: window.innerHeight })

  const updateSize = () => {
    setSize({ width: window.innerWidth, height: window.innerHeight })
  }
  useEffect(() => {
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}
