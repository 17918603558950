import { FC, Fragment } from 'react'
import { CalendarCell, CalendarColumn, CalendarTitleCell } from 'ui/Calendar'
import { EmployeeGroupWithSchedule } from '@expane/data'
import { useTranslation } from 'react-i18next'
import { transformPersonName } from '@expane/logic/utils'

interface SchedulePageCalendarEmployeeColumnType {
  employeeGroups: EmployeeGroupWithSchedule[]
}

export const SchedulePageCalendarEmployeeColumn: FC<SchedulePageCalendarEmployeeColumnType> = ({
  employeeGroups,
}) => {
  const { t } = useTranslation()

  const employeeGroupsWithEmployees = employeeGroups.filter(group => group.employees.length !== 0)

  return (
    <CalendarColumn className="grow basis-0 bg-block relative border-l border-l-primary-100 dark:border-l-primary-100/30">
      <CalendarTitleCell rightBorder>
        <p>{t('employees.name')}</p>
      </CalendarTitleCell>

      {employeeGroupsWithEmployees.map((group, index) => {
        const isFirst = index === 0

        const additionalIsFirstStyles = isFirst
          ? ' border-t border-t-primary-100 dark:border-t-primary-100/30'
          : ''

        return (
          group.employees.length !== 0 && (
            <Fragment key={group.id}>
              <div className={cellStyle + additionalIsFirstStyles}>{group.name}</div>

              {group.employees.map(employee => {
                return (
                  <CalendarCell key={employee.id} fullHour>
                    {transformPersonName(employee)}
                  </CalendarCell>
                )
              })}
            </Fragment>
          )
        )
      })}
    </CalendarColumn>
  )
}

const cellStyle = `text-center h-10 px-1 bg-primary-50 dark:bg-gray-500 text-primary-500 dark:text-primary-300 font-medium flex-centered leading-3 text-xs whitespace-pre-line line-clamp-3 `
