import { FC, useState } from 'react'
import {
  isSameDay,
  isWithinInterval,
  isSameMonth,
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  addMinutes,
  subMinutes,
  set,
  getHours,
  getMinutes,
  addHours,
} from '@expane/date'
import { CalendarTileProperties, Detail } from 'react-calendar'
import { TextInputs } from './TextInputs'
import { CustomCalendar } from './CustomCalendar'
import { RangeDatePickerType } from '.'
import { getNewActiveStartDateOnBack, getNewActiveStartDateOnForward } from '@expane/widgets'

type CalendarsProps = {
  type: RangeDatePickerType
  value: Date[]
  onChange: (value: Date[]) => void
  firstActiveStartDate: Date
  setFirstActiveStartDate: (date: Date) => void
  secondActiveStartDate: Date
  setSecondActiveStartDate: (date: Date) => void
  timezone: string
}

export const Calendars: FC<CalendarsProps> = ({
  type,
  value,
  onChange,
  firstActiveStartDate,
  setFirstActiveStartDate,
  secondActiveStartDate,
  setSecondActiveStartDate,
  timezone,
}) => {
  const [newStart, setNewStart] = useState<Date | undefined>()
  const [newEnd, setNewEnd] = useState<Date | undefined>()

  const [firstView, setFirstView] = useState<Detail>('month')
  const [secondView, setSecondView] = useState<Detail>('month')

  const start = value[0]
  const end: Date | undefined = value[1]

  const getTileClassName = ({ date, activeStartDate, view }: CalendarTileProperties) => {
    const tileStyle = 'transition-all py-0.5 text-sm my-0.5'

    // числа другого месяца
    if (!isSameMonth(date, activeStartDate) && view === 'month' && !newStart)
      return tileStyle + ' text-placeholder-color'
    if (!newStart) {
      if (
        view === 'year' &&
        isWithinInterval(date, { start: startOfMonth(start), end: endOfMonth(end) })
      )
        return tileStyle + ' bg-primary-500 rounded text-btn-primary font-medium'

      if (isSameDay(date, start) && isSameDay(date, end))
        return tileStyle + ' bg-primary-500 rounded text-btn-primary font-medium'

      if (isSameDay(date, start)) {
        return tileStyle + ' bg-primary-500 rounded-l text-btn-primary font-medium'
      }
      if (end && isSameDay(date, end)) {
        return tileStyle + ' bg-primary-500 rounded-r text-btn-primary font-medium'
      }

      if (
        end &&
        isWithinInterval(date, {
          start,
          end,
        })
      ) {
        return tileStyle + ' bg-accent-more text-btn-primary'
      }
    }

    if (newStart && isSameDay(date, newStart))
      return tileStyle + ' rounded-l bg-accent-more text-btn-primary'
    if (newEnd && isSameDay(date, newEnd))
      return tileStyle + ' rounded-r bg-accent-more text-btn-primary'
    if (
      newStart &&
      newEnd &&
      isWithinInterval(date, {
        start: newStart,
        end: newEnd,
      })
    )
      return tileStyle + ' bg-accent-more text-btn-primary'

    return tileStyle + ' text-main-color rounded bg-hover'
  }

  const onChangeHandler = (newValue: Date) => {
    if (newStart === undefined) {
      setNewStart(
        set(newValue, {
          hours: getHours(value[0]),
          minutes: getMinutes(value[0]),
        }),
      )
    } else {
      let newEnd = set(newValue, {
        hours: getHours(value[1]),
        minutes: getMinutes(value[1]),
      })
      if (newEnd <= newStart) {
        if (isSameDay(newEnd, newStart)) newEnd = addHours(newStart, 1)
        else return
      }

      onChange([newStart, newEnd])
      setNewStart(undefined)
      setNewEnd(undefined)
    }
  }

  const handleFirstChevronForward = () => {
    const newActiveStartDate = getNewActiveStartDateOnForward(firstActiveStartDate, firstView)
    setFirstActiveStartDate(newActiveStartDate)

    if (newActiveStartDate >= secondActiveStartDate) {
      const newSecondActiveStartDate = addMonths(newActiveStartDate, 1)
      setSecondActiveStartDate(newSecondActiveStartDate)
    }
  }
  const handleFirstChevronBack = () => {
    const newActiveStartDate = getNewActiveStartDateOnBack(firstActiveStartDate, firstView)
    setFirstActiveStartDate(newActiveStartDate)
  }

  const handleSecondChevronForward = () => {
    const newActiveStartDate = getNewActiveStartDateOnForward(secondActiveStartDate, secondView)
    setSecondActiveStartDate(newActiveStartDate)
  }
  const handleSecondChevronBack = () => {
    const newActiveStartDate = getNewActiveStartDateOnBack(secondActiveStartDate, secondView)
    setSecondActiveStartDate(newActiveStartDate)

    if (newActiveStartDate <= firstActiveStartDate) {
      const newFirstActiveStartDate = subMonths(newActiveStartDate, 1)
      setFirstActiveStartDate(newFirstActiveStartDate)
    }
  }

  let className = 'p-2'
  if (type === 'date') className += ' pb-3'

  return (
    <div className={className}>
      <div className="flex">
        <CustomCalendar
          value={value}
          onChange={onChangeHandler}
          onViewChange={({ view }) => setFirstView(view)}
          onChevronForward={handleFirstChevronForward}
          onChevronBack={handleFirstChevronBack}
          activeStartDate={firstActiveStartDate}
          className="w-70 mr-3"
          tileClassName={getTileClassName}
          tileContent={args => (
            <div
              onMouseOver={
                newStart && newStart < args.date ? () => setNewEnd(args.date) : undefined
              }
              className="absolute top-0 bottom-0 right-0 left-0"
            />
          )}
        />
        <CustomCalendar
          value={value}
          onChange={onChangeHandler}
          activeStartDate={secondActiveStartDate}
          onViewChange={({ view }) => setSecondView(view)}
          onChevronBack={handleSecondChevronBack}
          onChevronForward={handleSecondChevronForward}
          tileClassName={getTileClassName}
          className="w-70"
          tileContent={args => (
            <div
              onMouseOver={
                newStart && newStart < args.date ? () => setNewEnd(args.date) : undefined
              }
              className="absolute top-0 bottom-0 right-0 left-0"
            />
          )}
        />
      </div>
      {type === 'dateTime' && (
        <div className="flex justify-center">
          <TextInputs
            date={value[0]}
            timezone={timezone}
            onChange={date => {
              if (date < value[1]) {
                onChange([date, value[1]])
              } else {
                onChange([date, addMinutes(date, 1)])
              }
              setFirstActiveStartDate(startOfMonth(date))
            }}
            className="mt-3 mr-3"
          />

          <TextInputs
            timezone={timezone}
            date={value[1]}
            onChange={date => {
              if (date > value[0]) {
                onChange([value[0], date])
              } else {
                onChange([subMinutes(date, 1), date])
              }
              setSecondActiveStartDate(startOfMonth(date))
            }}
            className="mt-3"
          />
        </div>
      )}
    </div>
  )
}
