import { formatFullDate, formatFullDateTime } from '@expane/date'
import {
  ErrorMessage,
  ErrorMessageType,
  InputLabel,
  modalsHTMLElement,
  useHandleClickOutside,
  usePopupOpenState,
} from '@expane/ui'
import { getContainerStyle } from '@expane/widgets'
import { flip, offset, shift, useFloating } from '@floating-ui/react-dom'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { IoCalendarOutline } from 'react-icons/io5'
import { PopupBody } from './PopupBody'

export type RangeDatePickerType = 'date' | 'dateTime'

export interface RangeDatePickerProps {
  type?: RangeDatePickerType
  value: Date[]
  onChange: (value: Date[]) => void
  className?: string
  label?: string
  hint?: string
  required?: boolean
  disabled?: boolean
  errorMessage?: ErrorMessageType
  customPopupsElement?: HTMLDivElement
  timezone: string
}

export const RangeDatePicker: FC<RangeDatePickerProps> = ({
  className,
  label,
  hint,
  required,
  disabled = false,
  customPopupsElement,
  value,
  onChange,
  errorMessage,
  type = 'date',
  timezone,
}) => {
  const buttonRef = useRef(null)
  const [popupRef, setPopupRef] = useState<HTMLDivElement | null>(null)
  const { isOpen, closePopup, openPopup } = usePopupOpenState()

  useHandleClickOutside([buttonRef.current, popupRef], closePopup, isOpen)

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(5), flip(), shift({ padding: 17 })],
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  useEffect(() => {
    reference(buttonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, buttonRef.current])
  useLayoutEffect(() => {
    floating(popupRef)
  }, [floating, popupRef])

  const containerStyle = getContainerStyle({ disabled, error: Boolean(errorMessage) })

  const displayedValue = getDisplayedValue(value, type)

  return (
    <div className={className}>
      <InputLabel label={label} hint={hint} required={required} />
      <div ref={buttonRef} className={containerStyle}>
        <button onClick={openPopup} className={dateTimeButtonStyle}>
          <IoCalendarOutline className="ml-1.5 text-gray-300" />
          <span className="grow">{displayedValue}</span>
        </button>
      </div>
      {isOpen &&
        createPortal(
          <div
            ref={setPopupRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <PopupBody
              timezone={timezone}
              value={value}
              type={type}
              onChange={onChange}
              close={closePopup}
            />
          </div>,
          customPopupsElement ?? modalsHTMLElement,
        )}
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  )
}

const getDisplayedValue = (value: Date[], type: RangeDatePickerType) => {
  const formatDate = type === 'date' ? formatFullDate : formatFullDateTime
  const displayedStart = value[0] ? formatDate(value[0]) : ''
  const displayedEnd = value[1] ? formatDate(value[1]) : '???'
  return displayedStart + ' - ' + displayedEnd
}

const dateTimeButtonStyle = 'grow relative flex items-center justify-between h-full text-center'
