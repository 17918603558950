import { FC } from 'react'
import {
  IMAGE_SIZE,
  LandingSettingsBlockProps,
} from 'pages/SettingsPage/BusinessLandingSettings/logic'
import { Input, InputLabel, Textarea, ThemePicker } from '@expane/ui'
import { EditablePhoto } from 'widgets/EditablePhoto'
import { useTranslation } from 'react-i18next'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import { IoAddOutline } from 'react-icons/io5'

export const UISettings: FC<LandingSettingsBlockProps> = ({ control, disabled }) => {
  const { t } = useTranslation()

  const watchedFields = useWatch({ control, name: 'images' })

  return (
    <div>
      <div className={'flex gap-4'}>
        <div className="grow">
          <Controller
            name="bannerTitle"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={t('businessLandingSettings.bannerTitle')}
                required
                value={value}
                onChange={onChange}
                disabled={disabled}
                errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
              />
            )}
          />

          <Controller
            name="bannerDescription"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Textarea
                placeholder=""
                required
                label={t('businessLandingSettings.bannerDescription')}
                value={value}
                onChange={onChange}
                disabled={disabled}
                errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
              />
            )}
          />
        </div>

        <Controller
          name="bannerImage"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <EditablePhoto
              defaultPhoto={value}
              onChange={onChange}
              size="xl"
              cropShape="rect"
              disabled={disabled}
              errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
              croppedSize={IMAGE_SIZE}
            />
          )}
        />
      </div>

      <div className="mb-4">
        <InputLabel label={t('businessLandingSettings.chooseTheme')} />

        <Controller
          name="theme"
          control={control}
          render={({ field: { value, onChange } }) => (
            <ThemePicker value={value} onChange={onChange} disabled={disabled} />
          )}
        />
      </div>

      <InputLabel
        label={t('businessLandingSettings.ourWorks')}
        hint={t('businessLandingSettings.ourWorksHint')}
        className={'w-fit'}
      />

      <div className="flex gap-4 flex-wrap">
        {watchedFields.map((_, index) => (
          <Controller
            key={`images.${index}`}
            name={`images.${index}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <EditablePhoto
                cropShape="rect"
                size="big"
                defaultPhoto={value.url}
                onChange={base64File => {
                  onChange({ url: base64File })
                }}
                disabled={disabled}
                croppedSize={IMAGE_SIZE}
              />
            )}
          />
        ))}

        <AddImageButton control={control} disabled={disabled} />
      </div>
    </div>
  )
}

const AddImageButton: FC<LandingSettingsBlockProps> = ({ control, disabled }) => {
  let addButtonStyle =
    'flex flex-centered cursor-pointer border-2 border-dashed rounded-2xl w-32 h-32'
  addButtonStyle += disabled
    ? ' border-gray-300 text-gray-300 pointer-events-none'
    : ' border-primary-400 bg-hover text-primary-400'

  const { fields, append } = useFieldArray({ control, name: 'images' })

  if (fields.length > 9) return null

  return (
    <div className={addButtonStyle} onClick={() => append({ url: '' })}>
      <IoAddOutline size="2rem" />
    </div>
  )
}
