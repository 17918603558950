import { useFetchLocationGroups, useFetchLocationsWithDefaultForBranch } from '@expane/data'
import { Paper, Spinner } from '@expane/ui'
import { ProceedToNextStep, useCreateBusinessSteps } from 'pages/CreateBusinessPage'
import { stepStyle } from 'pages/CreateBusinessPage/logic'
import { LocationPageData } from 'pages/LocationsPage/LocationPageData'
import { FC } from 'react'
import { store } from 'store'

export const LocationsStep: FC = () => {
  const { proceedToNextStep } = useCreateBusinessSteps()

  const branchId = store.branch.branchId
  const { data: locations, isLoading: isLoadingLocations } =
    useFetchLocationsWithDefaultForBranch(branchId)
  const { data: locationGroups, isLoading: isLoadingLocationGroups } =
    useFetchLocationGroups(branchId)

  const isLoading = isLoadingLocationGroups || isLoadingLocations

  if (isLoading) return <Spinner expandCentered />

  return (
    <div className={'flex items-end'}>
      <Paper className={stepStyle + ' flex flex-col w-160'}>
        <div className="h-126">
          {isLoading ? (
            <Spinner expandCentered />
          ) : (
            <LocationPageData
              locations={locations ?? []}
              locationGroups={locationGroups ?? []}
              type="createBusiness"
            />
          )}
        </div>
      </Paper>

      <ProceedToNextStep className="mt-2" onClick={proceedToNextStep} />
    </div>
  )
}
