import {
  useFetchClientsBriefs,
  useFetchClientsPhones,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { addPhonesToClients } from '@expane/logic/client'
import { transformPersonName } from '@expane/logic/utils'
import {
  CancelButton,
  FadeInTranslateBottom,
  modalsHTMLElement,
  OpenButton,
  usePopupOpenState,
} from '@expane/ui'
import { config } from 'config'
import { formatPhoneNumber } from 'logic/ui'
import { FC, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoCallOutline } from 'react-icons/io5'
import { formatPhoneFromBinotel } from 'services/telephony'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'

export interface CallNumbers {
  incomingNumber: string
  toNumber: string
}

interface OpenClientDialogProps {
  openEditDialog: (id: number) => void
  openCreateDialog: (phone: string) => void
}

interface IncomingCallDialogProps extends CallNumbers, OpenClientDialogProps {
  closeDialog: () => void
}

const IncomingCallDialog: FC<IncomingCallDialogProps> = ({
  incomingNumber,
  openCreateDialog,
  openEditDialog,
  closeDialog,
}) => {
  const { t } = useTranslation()
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clients } = useFetchClientsBriefs(branchId, timezone)
  const { data: clientsPhones } = useFetchClientsPhones()

  const data = addPhonesToClients(clients, clientsPhones)

  const formattedIncomingNumber = formatPhoneFromBinotel(incomingNumber)

  const client = data?.find(client => client.phone === formattedIncomingNumber)
  const doesClientExist = client !== undefined
  const clientName = doesClientExist ? transformPersonName(client) : t('noClient')

  const handleClick = () => {
    if (doesClientExist) openEditDialog(client.id)
    else openCreateDialog(formattedIncomingNumber)
  }
  return createPortal(
    <FadeInTranslateBottom className={className}>
      <div className="flex-centered bg-primary-200 text-white rounded-t">
        <IoCallOutline className="mr-1" size="1.1rem" />
        {t('call')}
      </div>
      <div className="flex-centered flex-col p-1">
        <img
          className="shrink-0 h-10 w-10 rounded-full"
          src={client?.photo?.length ? client.photo : config.PHOTO_PLACEHOLDER_URL}
          alt="Фото"
        />
        <p className="text-lg text-primary-900">{formatPhoneNumber(formattedIncomingNumber)}</p>
        <p>{clientName}</p>
      </div>
      <div className="flex justify-between px-1.5 mt-24">
        <CancelButton onClick={closeDialog} size="small" />
        {doesClientExist ? (
          <OpenButton onClick={handleClick} size="small" />
        ) : (
          <SaveButton isCreate onClick={handleClick} size="small" />
        )}
      </div>
    </FadeInTranslateBottom>,
    modalsHTMLElement,
  )
}
const className = 'fixed bottom-2 right-2 rounded-md bg-white border-2 border-primary-200 w-48 h-64'

export const useOpenIncomingCallDialog = (params: OpenClientDialogProps) => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const incomingNumber = useRef<string>('')
  const toNumber = useRef<string>('')

  const openIncomingCallDialog: (params: CallNumbers) => void = params => {
    incomingNumber.current = params.incomingNumber
    toNumber.current = params.toNumber
    openPopup()
  }

  const closeDialog = () => closePopup()

  const incomingCallDialog = isOpen ? (
    <IncomingCallDialog
      closeDialog={closeDialog}
      incomingNumber={incomingNumber.current}
      toNumber={toNumber.current}
      openEditDialog={id => {
        params.openEditDialog(id)
        closeDialog()
      }}
      openCreateDialog={number => {
        params.openCreateDialog(number)
        closeDialog()
      }}
    />
  ) : null

  return { openIncomingCallDialog, incomingCallDialog, closeIncomingCallDialog: closeDialog }
}
