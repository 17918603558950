import { EmployeeScheduleDto } from '@expane/data'
import {
  areIntervalsOverlapping,
  eachDayOfInterval,
  getOverLappingInterval,
  isBefore,
  isDateAfter,
  isDateBefore,
  isWithinInterval,
} from '@expane/date'

// TODO: Sync with functions\src\logic\schedule.ts
export const getCurrentEmployeeSchedule = (
  employeeSchedules: EmployeeScheduleDto[],
  date: Date,
): EmployeeScheduleDto | undefined => {
  const currentSchedule = employeeSchedules.find(employeeSchedule => {
    if (isDateAfter(employeeSchedule.startDate, date)) return false
    if (employeeSchedule.endDate && isDateBefore(employeeSchedule.endDate, date)) return false

    return true
  })

  return currentSchedule
}
// TODO: Made in analogy with getOverlappingSalaryRateSettingAndPeriod
export const getOverlappingEmployeeScheduleAndPeriod = ({
  fromDate,
  toDate,
  employeeSchedule,
}: {
  fromDate: Date
  toDate: Date
  employeeSchedule: EmployeeScheduleDto
}): Date[] => {
  if (!employeeSchedule.endDate) {
    //     <scheduleStartDate ----
    // <fromDate     ----       toDate>
    if (isWithinInterval(employeeSchedule.startDate, { start: fromDate, end: toDate })) {
      return eachDayOfInterval({ start: employeeSchedule.startDate, end: toDate })
    }

    // <scheduleStartDate ----
    //      <fromDate     ----       toDate>
    if (isBefore(employeeSchedule.startDate, fromDate))
      return eachDayOfInterval({ start: fromDate, end: toDate })
    else return []
  }

  //     <scheduleStartDate ---- scheduleEndDate>
  // <fromDate     ----       toDate>
  if (
    areIntervalsOverlapping(
      {
        start: employeeSchedule.startDate,
        end: employeeSchedule.endDate,
      },
      { start: fromDate, end: toDate },
    )
  ) {
    return getOverLappingInterval(
      { start: fromDate, end: toDate },
      {
        start: employeeSchedule.startDate,
        end: employeeSchedule.endDate,
      },
    )
  }

  return []
}

export const SCHEDULE_TYPES = {
  schedule: { id: 1, name: 'schedule.type.template' },
  dynamic: { id: 2, name: 'schedule.type.dynamic' },
}

export const getIsScheduleTypeDynamic = (id: number) => id === SCHEDULE_TYPES.dynamic.id
