import { safeWebLocalStorage } from '../safeWebLocalStorage'
import { ALLOWED_LANGUAGES, DEFAULT_LANG } from '@expane/logic/lang'

export const getWebPreferredUserLanguage = (localStorageKey: string): string => {
  const language = safeWebLocalStorage.getItem(localStorageKey)

  if (!language) {
    const navigatorLanguage = window.navigator.language.slice(0, 2)

    if (ALLOWED_LANGUAGES.includes(navigatorLanguage)) {
      safeWebLocalStorage.setItem(localStorageKey, navigatorLanguage)
      return navigatorLanguage
    }

    // Ukrainian as default
    safeWebLocalStorage.setItem(localStorageKey, DEFAULT_LANG)
    return DEFAULT_LANG
  }

  return language
}
