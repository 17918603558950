import { FC } from 'react'
import {
  createCurrentDate,
  formatDayMonth,
  getWeekDayNumber,
  isAfter,
  isBefore,
  isDateBefore,
  isDateSameDayOrAfter,
  isDateSameDayOrBefore,
  isSameDay,
  startOfDay,
} from '@expane/date'
import { checkCellIsDisabledByRegularSchedule, RowDto } from 'logic/calendar'
import { CalendarCell, CalendarColumn, CalendarTitleCell } from 'ui/Calendar'
import {
  DayTiming,
  ScheduleDto,
  ServerDynamicScheduleDateType,
  useFetchCurrentBranchTimezone,
  WeeklySchedule,
} from '@expane/data'
import i18next from 'i18next'
import { IoAddCircleOutline, IoCloseCircleOutline } from 'react-icons/io5'
import { WeeklyColumnData } from 'pages/BookingsPage/BookingsCalendar/logic'
import { DynamicDateBox } from './DynamicDateBox'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import { ScheduleDialogFormValues } from './logic'
import { store } from 'store'
import { getIsScheduleTypeDynamic } from '@expane/logic/employeeSchedule'

type ColumnProps = {
  control: Control<ScheduleDialogFormValues>
  pickedSchedule: ScheduleDto | undefined
  pickedStartDate: Date | undefined
  branchSchedule: WeeklySchedule
  rows: RowDto[]
  column: WeeklyColumnData
  columnIndex: number
  last: boolean
  startHour: number
  endHour: number
  defaultDynamicDates: ServerDynamicScheduleDateType[]
  scheduleEndDate?: Date
  isEditAllowed: boolean
  isEditPastAllowed: boolean
}
export const ScheduleCalendarColumn: FC<ColumnProps> = ({
  control,
  rows,
  column,
  columnIndex,
  last,
  startHour,
  endHour,
  pickedSchedule,
  branchSchedule,
  pickedStartDate,
  defaultDynamicDates,
  scheduleEndDate,
  isEditAllowed,
  isEditPastAllowed,
}) => {
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const scheduleType = useWatch({ control, name: 'scheduleType' })
  const isScheduleTypeDynamic = getIsScheduleTypeDynamic(scheduleType.id)
  const {
    fields: dynamicDates,
    append,
    update,
    remove,
  } = useFieldArray({ control, name: 'dynamicDates', keyName: 'generatedId' })

  const dynamicDateIndex = isScheduleTypeDynamic
    ? dynamicDates.findIndex(dynamicDate => isSameDay(dynamicDate.date, column.date))
    : null
  const dynamicDate = dynamicDateIndex !== null ? dynamicDates[dynamicDateIndex] : null
  const isDynamicDateDisabled =
    !isEditAllowed &&
    Boolean(dynamicDate) &&
    dynamicDate &&
    isDateSameDayOrBefore(dynamicDate.date, createCurrentDate(timezone))

  const branchTiming = branchSchedule.data[getWeekDayNumber(column.date)]
  const displayAddDynamicDate = getIsAddDynamicDateButtonDisplayed({
    date: column.date,
    isDynamic: isScheduleTypeDynamic,
    branchTiming,
    dynamicDate,
    scheduleStartDate: pickedStartDate,
    scheduleEndDate,
    isEditAllowed,
    isEditPastAllowed,
    timezone,
  })
  const isTodayAddedEarlier = defaultDynamicDates.some(dynamicDate =>
    isSameDay(dynamicDate.date, createCurrentDate(timezone)),
  )

  const displayRemoveDynamicDate =
    isEditAllowed &&
    dynamicDate &&
    !pickedSchedule &&
    // если мы только что добавили сегоднешний день и еще не сохранили этот день в расписание, то мы можем его удалить
    (isTodayAddedEarlier
      ? isAfter(dynamicDate.date, createCurrentDate(timezone))
      : isDateSameDayOrAfter(dynamicDate.date, createCurrentDate(timezone)))

  const locale = i18next.language
  const columnName = column.date.toLocaleDateString(locale, {
    weekday: 'short',
  })
  const columnWeekdayLabel = formatDayMonth(column.date)

  const handleCreateDynamicDate = () => {
    append({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      id: undefined,
      date: column.date,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      timingStart: branchTiming![0],
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      timingEnd: branchTiming![1],
    })
  }
  const handleRemoveDynamicDate = () => {
    if (dynamicDateIndex) remove(dynamicDateIndex)
  }

  return (
    <CalendarColumn className="grow basis-0 relative">
      <div>
        {rows.map(row => {
          const isDisabled =
            checkCellIsDisabledByRegularSchedule({
              schedule: pickedSchedule,
              startHour: row.startHour,
              currentWeekDate: column.date,
              scheduleStartDate: pickedStartDate,
              scheduleEndDate,
            }) ||
            checkCellIsDisabledByRegularSchedule({
              schedule: branchSchedule,
              startHour: row.startHour,
              currentWeekDate: column.date,
            })
          return (
            <CalendarCell
              key={row.startHour + column.id}
              small
              fullHour
              className={isDisabled ? undefined : 'bg-block'}
              lastColumn={last}
            >
              {Number.isInteger(row.startHour) && columnIndex % 2 === 1 && (
                <div className="text-calendar-time-color font-medium text-lg w-full pl-2">
                  <p>{row.startHour}:00</p>
                </div>
              )}
            </CalendarCell>
          )
        })}

        {dynamicDate && dynamicDateIndex && !pickedSchedule ? (
          <DynamicDateBox
            disabled={Boolean(isDynamicDateDisabled)}
            dynamicDate={dynamicDate}
            dynamicDateIndex={dynamicDateIndex}
            calendarStartHour={startHour}
            calendarEndHour={endHour}
            update={update}
          />
        ) : null}
      </div>

      <CalendarTitleCell>
        {`${columnName} ${columnWeekdayLabel}`}{' '}
        {displayAddDynamicDate && <AddDynamicDateButton onClick={handleCreateDynamicDate} />}
        {displayRemoveDynamicDate && <RemoveDynamicDateButton onClick={handleRemoveDynamicDate} />}
      </CalendarTitleCell>
    </CalendarColumn>
  )
}

const getIsAddDynamicDateButtonDisplayed = ({
  date,
  dynamicDate,
  isDynamic,
  branchTiming,
  scheduleEndDate,
  scheduleStartDate,
  isEditAllowed,
  isEditPastAllowed,
  timezone,
}: {
  isEditAllowed: boolean
  isEditPastAllowed: boolean
  date: Date
  dynamicDate: ServerDynamicScheduleDateType | null
  isDynamic: boolean
  branchTiming: DayTiming | null
  scheduleStartDate: Date | undefined
  scheduleEndDate: Date | undefined
  timezone: string | undefined
}): boolean => {
  if (!isEditAllowed) return false
  // If dynamic date is already added, we don't need to display add button
  if (dynamicDate) return false
  // If schedule is picked, we don't have dynamic dates
  if (!isDynamic) return false
  // If branch is closed on this day
  if (!branchTiming) return false
  // If date is before today and user doesn't have a permission to edit schedules in past
  if (isBefore(date, startOfDay(createCurrentDate(timezone))) && !isEditPastAllowed) return false
  // If date is after schedule end date
  if (scheduleEndDate && isAfter(date, scheduleEndDate)) return false
  if (scheduleStartDate && isDateBefore(date, scheduleStartDate)) return false

  return true
}

const AddDynamicDateButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="text-primary-400 hover:text-primary-600 rounded-full focus:ring-1 ring-primary-400"
  >
    <IoAddCircleOutline size={'1.2rem'} />
  </button>
)
const RemoveDynamicDateButton: FC<{ onClick: () => void; disabled?: boolean }> = ({
  onClick,
  disabled = false,
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className="text-error-400 hover:text-error-600 rounded-full focus:ring-1 ring-error-400"
  >
    <IoCloseCircleOutline size={'1.2rem'} />
  </button>
)
