import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../api'
import { ServerMutationRootBranchImportProductsArgs } from '../generated/graphql-types'
import { PRODUCTS_QUERY_KEY } from './product/queryKeys'
import { PRODUCT_GROUPS_QUERY_KEY } from './productGroup/queryKeys'

export function useBranchImportProducts() {
  return useMutation(
    (dto: ServerMutationRootBranchImportProductsArgs) => {
      return request(
        gql`
          mutation ($productIds: [Int!]!, $productGroupIds: [Int!]!, $toBranchId: Int!) {
            branchImportProducts(
              productIds: $productIds
              productGroupIds: $productGroupIds
              toBranchId: $toBranchId
            ) {
              success
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes(PRODUCTS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes(PRODUCT_GROUPS_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}
