import {
  ServerService,
  ServerServiceBriefWithProductType,
  ServerTransactionInBookingType,
} from '@expane/data'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import { TreeMenuItem } from 'ui/TreeMenu'
import {
  getCountOfPaymentAndRefundTransactionsByService,
  getIsAllProductsPaid,
  getIsAllServicesPaid,
  getIsServicePaid,
  getIsSomeProductsPaid,
  getIsSomeServicesPaid,
} from '@expane/logic/booking'
import { Consumable } from '@expane/logic/payment/booking'

export const getIsServicePaidByTransactions = (
  serviceId: number,
  transactions: ServerTransactionInBookingType[],
) => {
  const { paymentsByService, refundsByService } =
    getCountOfPaymentAndRefundTransactionsByService(transactions)

  return getIsServicePaid({ serviceId, paymentsByService, refundsByService })
}

export const getPaidServices = (
  services: { id: number }[],
  transactions: ServerTransactionInBookingType[],
) => {
  const { paymentsByService, refundsByService } =
    getCountOfPaymentAndRefundTransactionsByService(transactions)

  return services.filter(service =>
    getIsServicePaid({ serviceId: service.id, paymentsByService, refundsByService }),
  )
}

export const getBookingDurations = (services: TreeMenuItem[]) => {
  const onlyServices = extractItemsFromFolders(services)
  return onlyServices.reduce((sum, item) => {
    if ((item as ServerService).defaultDuration !== undefined)
      return sum + (item as ServerService).defaultDuration

    const sumOfSubServicesDurations = item.nodes?.reduce(
      (sum, service) => sum + service.defaultDuration,
      0,
    )
    return sumOfSubServicesDurations ? sum + sumOfSubServicesDurations : sum
  }, 0)
}

type FilteredConsumable = {
  bookingProductId: number | undefined
  productId: number
  quantity: string
  price?: number
}

export const checkIsBookingPaid = ({
  consumables,
  services,
  transactions,
}: {
  consumables: Consumable[]
  services: TreeMenuItem[]
  transactions: ServerTransactionInBookingType[]
}) => {
  const extractedServices = extractItemsFromFolders(services)
  const isAllServicesPaid = getIsAllServicesPaid(extractedServices, transactions)
  const isSomeServicesPaid = getIsSomeServicesPaid(extractedServices, transactions)

  // Only include consumables with a productId
  const filteredConsumables = consumables.filter(
    consumable => consumable.productId !== undefined,
  ) as FilteredConsumable[]

  const consumablesWithQuantityAsNumber = filteredConsumables.map(consumable => ({
    ...consumable,
    productId: consumable.productId,
    bookingProductId: consumable.bookingProductId,
    quantity: Number(consumable.quantity),
  }))

  const isAllConsumablesPaid = getIsAllProductsPaid(consumablesWithQuantityAsNumber, transactions)
  const isSomeProductsPaid = getIsSomeProductsPaid(consumablesWithQuantityAsNumber, transactions)
  const isBookingPaid = isAllServicesPaid && isAllConsumablesPaid

  return {
    isAllServicesPaid,
    isSomeServicesPaid,
    isAllConsumablesPaid,
    isSomeProductsPaid,
    isBookingPaid,
  }
}

export const prepareBookingData = ({
  initialBookingServices,
  serviceIds,
  initialBookingProducts,
  consumables,
  bookingId,
}: {
  initialBookingServices: { id: number; service: ServerServiceBriefWithProductType }[]
  serviceIds: number[]
  initialBookingProducts: { id: number; productId: number; quantity: number }[]
  consumables: Consumable[]
  bookingId: number | undefined
}) => {
  // Determine booking services to be removed
  const bookingServicesToBeRemovedIds = initialBookingServices
    .filter(({ service }) => !serviceIds.includes(service.id))
    .map(({ id }) => id)

  // Determine services to be inserted
  const servicesToBeInsertedIds = serviceIds.filter(
    id => !initialBookingServices.some(({ service }) => service.id === id),
  )

  // Prepare booking service insert input
  const bookingServiceInsertInput = servicesToBeInsertedIds.map(serviceId => ({
    serviceId,
    bookingId,
  }))

  // Determine booking products to be removed
  const bookingProductsToBeRemovedIds = initialBookingProducts
    .filter(({ id }) => !consumables.some(({ bookingProductId }) => bookingProductId === id))
    .map(({ id }) => id)

  // Prepare booking product insert input
  const bookingProductInsertInput = consumables.map(
    ({ productId, quantity, bookingProductId }) => ({
      id: bookingProductId,
      bookingId,
      productId,
      quantity: Number(quantity),
    }),
  )

  return {
    bookingServicesToBeRemovedIds,
    bookingServiceInsertInput,
    bookingProductsToBeRemovedIds,
    bookingProductInsertInput,
  }
}
