import { Page } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ClientDialog } from 'widgets/ClientDialog'
import { ListNavigationButton } from 'widgets/NavigationButtons'
import { ArchiveClientsList } from './List'

export const ArchiveClientsPage: FC = () => {
  const { openEditDialog, dialog } = useOpenDialog(ClientDialog)

  const { t } = useTranslation()

  return (
    <Page>
      <div className="flex mb-2">
        <ListNavigationButton title={t('clients')} />
      </div>

      <ArchiveClientsList onRowClick={openEditDialog} />
      {dialog}
    </Page>
  )
}
