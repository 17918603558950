import { useFetchTags } from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { Page } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { SaveButton } from 'widgets/Buttons'
import { ArchiveListNavigationButton } from 'widgets/NavigationButtons'
import { TagsDialog } from 'widgets/TagDialog'
import { TagsList } from './List'

export const TagsPage: FC = () => {
  const { data: myPermissions } = useFetchMyPermissions()
  const { openCreateDialog, openEditDialog, dialog } = useOpenDialog(TagsDialog)

  const { data, isLoading } = useFetchTags()

  const isEditingAllowed = myPermissions?.includes(permissions.tag.set)

  return (
    <Page>
      {isEditingAllowed && (
        <div className="flex mb-2">
          <SaveButton onClick={() => openCreateDialog()} className="self-start" isCreate />

          <ArchiveListNavigationButton className="ml-auto" />
        </div>
      )}

      <TagsList onRowClick={tag => openEditDialog(tag.id)} tags={data} isLoading={isLoading} />

      {dialog}
    </Page>
  )
}
