import { toZonedTime } from '@expane/date'
import { ServerEmployeeScheduleType } from '../../generated/graphql-types'
import { ScheduleDto } from '../schedule'

export type DynamicSchedule = Omit<ServerEmployeeScheduleType, 'schedule' | 'isDynamic'> & {
  isDynamic: true
}
type RegularSchedule = Omit<
  ServerEmployeeScheduleType,
  'schedule' | 'dynamicDates' | 'isDynamic'
> & {
  schedule: ScheduleDto
  isDynamic: false
}

export type EmployeeScheduleDto = DynamicSchedule | RegularSchedule

export type DynamicDateType = { id: string; date: Date; timingStart: number; timingEnd: number }

export type EmployeeScheduleToParse = {
  startDate?: string | Date | null
  endDate?: string | Date | null
  dynamicDates?: {
    date: string | Date
  }[]
}
export const parseDatesInEmployeeScheduleGqlResponse = <T extends EmployeeScheduleToParse>(
  employeeSchedule: T,
  timezone: string,
) => {
  if (employeeSchedule.startDate) {
    employeeSchedule.startDate = toZonedTime(employeeSchedule.startDate, timezone)
  }
  if (employeeSchedule.endDate) {
    employeeSchedule.endDate = toZonedTime(employeeSchedule.endDate, timezone)
  }
  if (employeeSchedule.dynamicDates) {
    employeeSchedule.dynamicDates = employeeSchedule.dynamicDates.map(dynamicDate => ({
      ...dynamicDate,
      date: toZonedTime(dynamicDate.date, timezone),
    }))
  }
  return employeeSchedule
}
