import {
  BookingUnion,
  invalidateBookings,
  useCancelBookings,
  useFetchBranchById,
} from '@expane/data'
import { defineDayTiming } from '@expane/logic/calendar'
import { permissions } from '@expane/logic/permission'
import { Button, CloseButton, Dialog, useShortCut } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowCancelBookingPopup } from 'logic/hooks/popup/useShowCancelBookingPopup'
import { FC } from 'react'
import {
  Control,
  UseFormGetValues,
  UseFormHandleSubmit,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCalendarNumberOutline, IoCashOutline, IoTrashBin } from 'react-icons/io5'
import { reportError } from 'services/sentry'
import { store } from 'store'
import { InitBookingUpdateData } from 'store/UnitedBookingUpdate'
import { useCancelPaymentBookingMultiServicesPopup } from 'widgets/BookingMultiServicesDialog/CancelPaymentBookingPopup'
import { checkIsUnitedBookingPaid } from 'widgets/BookingMultiServicesDialog/checkisPaidLogic'
import {
  BookingMultiServicesDialogFormValues,
  BookingMultiServicesInitialDto,
} from 'widgets/BookingMultiServicesDialog/index'
import { useSubmitMultiServicesBooking } from 'widgets/BookingMultiServicesDialog/useSubmitBooking'
import { useBookingPaymentDialog } from 'widgets/BookingPaymentDialog'
import { QuickSaleButton, SaveButton } from 'widgets/Buttons'
import { useQuickSaleDialog } from 'widgets/QuickSaleDialog'
import { getIsBookingDone } from '@expane/logic/booking'

interface BookingMultiServicesFooterProps {
  control: Control<BookingMultiServicesDialogFormValues>
  handleSubmit: UseFormHandleSubmit<BookingMultiServicesDialogFormValues>
  initialData: BookingMultiServicesInitialDto
  closeDialog: () => void
  initialBookings: Array<BookingUnion>
  isBookingTodayOrLater: boolean
  getValues: UseFormGetValues<BookingMultiServicesDialogFormValues>
}

export const BookingMultiServicesFooter: FC<BookingMultiServicesFooterProps> = ({
  control,
  handleSubmit,
  initialData,
  closeDialog,
  initialBookings,
  isBookingTodayOrLater,
  getValues,
}) => {
  const { isCreate, id } = initialData

  const {
    unitedBookingUpdate: { setInitialBookingUpdateData },
  } = store
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!

  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()
  const { mutateAsync: cancelBookings } = useCancelBookings()

  const { quickSaleDialog, openQuickSaleDialog } = useQuickSaleDialog()
  const { openPaymentDialog, paymentDialog } = useBookingPaymentDialog()
  const { cancelPaymentBookingPopup, openCancelPaymentBookingPopup } =
    useCancelPaymentBookingMultiServicesPopup({ initialBookings: initialBookings ?? [] })

  const { isDirty, isSubmitting } = useFormState({ control })
  const watchedClientId = useWatch({
    control,
    name: 'clientId',
  })

  const watchedMultiServicesDto = useWatch({
    control,
    name: 'multiServicesDto',
  })

  const bookingPaidInfo = checkIsUnitedBookingPaid({
    initialBookings,
    multiServicesDto: watchedMultiServicesDto,
  })
  const isBookingPaid = bookingPaidInfo.every(booking => booking.isBookingPaid)
  const isSomeServicesPaid = bookingPaidInfo.every(booking => booking.isSomeServicesPaid)
  const isSomeProductsPaid = bookingPaidInfo.every(booking => booking.isSomeProductsPaid)

  // у всех мултисервисов должен быть один день поэтому дату первого можно смотреть
  const watchedDate = useWatch({ control, name: `multiServicesDto.0.startDate` })
  const { data: branch } = useFetchBranchById(store.branch.branchId)
  const branchSchedule = branch?.schedule
  const branchTiming = defineDayTiming(branchSchedule ?? undefined, watchedDate)

  const { submitBooking, confirmationModal } = useSubmitMultiServicesBooking({
    control,
    isCreate,
    unitedBookingId: id,
    dayEnd: branchTiming[1],
    dayStart: branchTiming[0],
    initialBookings,
  })

  const [openSnackbar] = useSnackbar()
  const { cancelBookingModal, showConfirmCancelBooking } = useShowCancelBookingPopup()

  const cancelExistingBooking = async (reason: string | null) => {
    const bookingsIds = initialBookings.map(({ id }) => id)

    const result = await cancelBookings({
      ids: bookingsIds,
      canceledReason: reason ?? null,
    })

    if (result.updateBookings.affectedRows) {
      openSnackbar(t('cancelBookings.successfully'), 'success', 3000)
    } else {
      openSnackbar(t('submitError'), 'error', 3000)
    }

    closeDialog()
  }

  const handleCancelBooking = () => {
    showConfirmCancelBooking({
      onConfirm: cancelExistingBooking,
    })
  }

  const handleCancelPaymentBooking = () => {
    if (id) openCancelPaymentBookingPopup()
  }

  const isSomeBookingDone = initialBookings.some(booking => getIsBookingDone(booking))
  const isDone =
    Boolean(initialBookings.length) && initialBookings.every(booking => getIsBookingDone(booking))

  const isCanceled = initialBookings.every(booking => booking.canceledDate)

  const isEditable =
    isBookingTodayOrLater &&
    (myPermissions?.includes(permissions.booking.set) ||
      myPermissions?.includes(permissions.booking.setOwn))

  const isPaymentAllowed = myPermissions?.includes(permissions.transaction.set)
  const disabledSaveButton = isSubmitting || (!isDirty && !initialData.fromCalendar)

  useShortCut(['Enter'], () => {
    if (isEditable && !isDone && !disabledSaveButton)
      handleSubmit(data =>
        submitBooking(data as BookingMultiServicesDialogFormValues, closeDialog),
      )()
  })

  return (
    <Dialog.Footer className="justify-between">
      <div className="flex gap-2">
        <CloseButton onClick={closeDialog} />

        {isEditable && !isDone && (
          <SaveButton
            onClick={handleSubmit(data =>
              submitBooking(data as BookingMultiServicesDialogFormValues, closeDialog),
            )}
            disabled={disabledSaveButton}
            spinner={isSubmitting}
          />
        )}
      </div>

      <div className="flex gap-2">
        {isCreate && (
          <Button
            onClick={() => {
              const data = getValues()

              const validMultiServicesDto = data.multiServicesDto.filter(
                dto => dto.services.length > 0,
              )
              if (validMultiServicesDto.length === 0) {
                openSnackbar(t('multiServicesBooking.atLeastOneService'), 'error')
                return
              }

              try {
                const dto: InitBookingUpdateData[] = validMultiServicesDto.map(dto => ({
                  employeeId: dto.employeeId,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  locationId: dto.locationId!,
                  startDate: dto.startDate,
                  serviceIds: dto.services.map(service => service.id),
                  duration: dto.duration,
                  consumables: dto.consumables,
                }))
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                setInitialBookingUpdateData(dto, data.clientId!)
                closeDialog()
              } catch (error) {
                openSnackbar(t('submitError'), 'error')
                reportError(error)
              }
            }}
            type="outline"
            Icon={IoCalendarNumberOutline}
          >
            {t('seeInCalendar')}
          </Button>
        )}
        {!isCreate &&
          // TODO: Make more readable
          !isCanceled &&
          // сейчас при отмене оплаты или записи будет отмена всех букингов,
          // поэтому если хотя бы один done то действие невозможно
          !isSomeBookingDone &&
          isEditable &&
          (isSomeServicesPaid || isSomeProductsPaid ? (
            <Button type="danger" onClick={handleCancelPaymentBooking} Icon={IoCashOutline}>
              {t('cancelPayment')}
            </Button>
          ) : (
            <Button type="danger" onClick={handleCancelBooking} Icon={IoTrashBin}>
              {t('cancelBooking.title')}
            </Button>
          ))}

        {isPaymentAllowed && !isCanceled && (
          <>
            <Button
              type="outline"
              disabled={isBookingPaid}
              onClick={
                isDirty || isCreate
                  ? handleSubmit(data =>
                      submitBooking(data as BookingMultiServicesDialogFormValues, bookingsIds => {
                        openPaymentDialog({
                          bookingsIds: bookingsIds ?? [],
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          clientId: data.clientId!,
                          onClose: isCreate ? closeDialog : undefined,
                        })
                      }),
                    )
                  : () => {
                      if (initialData.id && watchedClientId)
                        openPaymentDialog({
                          bookingsIds: initialBookings.map(({ id }) => id),
                          clientId: watchedClientId,
                          onClose: () => invalidateUnitedBooking(initialBookings, branchId),
                        })
                    }
              }
              Icon={IoCashOutline}
            >
              {isBookingPaid ? t('paid') : t('pay')}
            </Button>

            {watchedClientId !== undefined && (
              <QuickSaleButton onClick={() => openQuickSaleDialog(watchedClientId ?? undefined)} />
            )}
          </>
        )}
      </div>
      {confirmationModal}
      {quickSaleDialog}
      {cancelBookingModal}
      {paymentDialog}
      {cancelPaymentBookingPopup}
    </Dialog.Footer>
  )
}

export const invalidateUnitedBooking = (initialBookings: Array<BookingUnion>, branchId: number) => {
  for (const booking of initialBookings) {
    invalidateBookings({ id: booking.id, date: booking?.startDate, branchId })
  }
}
