import { FC, MouseEvent } from 'react'
import { IoCheckmarkDone } from 'react-icons/io5'

export interface CheckMarkButtonProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  className?: string
  disabled?: boolean
  size?: string
}

export const CheckMarkButton: FC<CheckMarkButtonProps> = ({
  onClick,
  className,
  disabled,
  size = '1.2rem',
}) => {
  let style = ''

  if (disabled) style += 'cursor-default '

  if (disabled) {
    style += 'text-gray-200 '
  } else {
    style += 'text-primary-200  hover:text-primary-500 '
  }

  if (className) style += className

  return (
    <button onClick={onClick} className={style} disabled={disabled}>
      <IoCheckmarkDone size={size} />
    </button>
  )
}
