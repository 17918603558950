import { useMemo } from 'react'
import { TFunction } from 'i18next'
import { ServerRolePermissionType } from '@expane/data'

export type Role = {
  id: number
  name: string
  roleId: string
}

export const useTranslatedRoles = (t: TFunction): Role[] =>
  useMemo(
    () => [
      {
        id: 1,
        name: t('roles.director'),
        roleId: 'director',
      },
      {
        id: 2,
        name: t('roles.directorAssistant'),
        roleId: 'directorAssistant',
      },
      {
        id: 3,
        name: t('roles.manager'),
        roleId: 'manager',
      },
      {
        id: 4,
        name: t('roles.specialist'),
        roleId: 'specialist',
      },
      {
        id: 5,
        name: t('roles.assistant'),
        roleId: 'assistant',
      },
      {
        id: 6,
        name: t('roles.accountant'),
        roleId: 'account',
      },
      {
        id: 7,
        name: t('roles.serviceStaff'),
        roleId: 'service',
      },
    ],
    [t],
  )

export type RoleWithWarningMessage = Role & { warningMessage?: string }

export const useRolesWithWarningMessages = (
  rolePermissions: ServerRolePermissionType[],
  t: TFunction,
) => {
  const roles = useTranslatedRoles(t)

  return useMemo<RoleWithWarningMessage[]>(() => {
    const rolesWithWarnings: RoleWithWarningMessage[] = []
    for (const role of roles) {
      const permissions = rolePermissions.filter(rP => rP.roleId === role.roleId)
      if (permissions.length === 0) {
        rolesWithWarnings.push({ ...role, warningMessage: t('notConfiguredRole') })
      } else {
        rolesWithWarnings.push(role)
      }
    }

    return rolesWithWarnings
  }, [roles, rolePermissions, t])
}
