import {
  ClientByIdType,
  useCreateClientNote,
  useFetchClientNotes,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { EmptyPlaceholder, Textarea, useShowConfirmationPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoChatboxEllipsesOutline } from 'react-icons/io5'
import { store } from 'store'
import { Comment } from 'widgets/ClientDialog/CommentsTab/Comment'
import { useOpenEditCommentDialog } from './EditCommentDialog'
import { useOnClickWithBillingCheck } from 'widgets/Buttons'

interface ClientDialogCommentsTabProps {
  client: ClientByIdType
  myPermission: string[]
  disabled: boolean
}

interface ClientNoteFormValue {
  text: string
}

export const ClientDialogCommentsTab: FC<ClientDialogCommentsTabProps> = ({
  client,
  disabled,
  myPermission,
}) => {
  const branchId = store.branch.branchId
  const [openSnackBar] = useSnackbar()

  const { formState, handleSubmit, control, reset } = useForm<ClientNoteFormValue>()

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clientsNotes } = useFetchClientNotes(client.id, timezone)

  const { editCommentDialog, openEditClientNoteDialog } = useOpenEditCommentDialog()
  const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

  const { mutateAsync: createClientNote } = useCreateClientNote()

  const isCreatingAllowed = myPermission.includes(permissions.clientNote.set)

  const { t } = useTranslation()

  const createComment: SubmitHandler<ClientNoteFormValue> = async data => {
    if (client?.id && isCreatingAllowed) {
      const result = await createClientNote({ clientId: client.id, text: data.text })

      if (result.insertClientNote.id) openSnackBar(t('comment.createdSuccessfully'), 'success')
      else openSnackBar(t('submitError'), 'error')
      reset({ text: '' })
    }
  }

  let commentButtonStyles = `
  inline-flex items-center justify-center
  rounded-md border-2 focus:outline-none ring-btn shadow-sm 
  bg-btn-outline font-medium text-sm h-8 px-2 transition-all`

  if (disabled) {
    commentButtonStyles += ' border-btn-disabled-outline text-btn-disabled-outline cursor-default '
  } else {
    commentButtonStyles += ' text-btn-outline border-btn-outline '
  }

  const onCreateCommentWithBillingCheck = useOnClickWithBillingCheck(handleSubmit(createComment))

  return (
    <div>
      {isCreatingAllowed && (
        <>
          <Controller
            control={control}
            name="text"
            rules={{
              required: true,
              validate: value => value.trim().length > 0,
            }}
            render={({ field: { value, onChange } }) => (
              <Textarea
                value={value}
                onAction={handleSubmit(createComment)}
                className="pr-12"
                onChange={onChange}
                disabled={disabled}
                placeholder={t('placeholders.clientComment')}
                errorMessage={{
                  isShown: Boolean(formState.errors.text),
                  text: t('formError.required'),
                }}
                rows={3}
              />
            )}
          />

          <div className="flex flex-row-reverse mb-6">
            <button
              className={commentButtonStyles}
              onClick={onCreateCommentWithBillingCheck}
              disabled={disabled}
            >
              {t('send')}
            </button>
          </div>
        </>
      )}

      {clientsNotes?.length ? (
        <div className={`${clientsNotes.length > 3 ? 'pr-2' : ''} h-72 overflow-y-auto`}>
          <ul className="whitespace-pre-line">
            {clientsNotes.map(item => (
              <Comment
                key={item.id}
                comment={item}
                myPermission={myPermission}
                openEditCommentDialog={openEditClientNoteDialog}
                showConfirmation={showConfirmation}
              />
            ))}
          </ul>
        </div>
      ) : (
        <EmptyPlaceholder Icon={IoChatboxEllipsesOutline} text={t('noComments')} />
      )}

      {editCommentDialog}
      {confirmationModal}
    </div>
  )
}
