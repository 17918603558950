import { FC } from 'react'
import { isSameDay } from '@expane/date'
import { TAILWIND_TO_REM_RATIO } from 'logic/ui'
import { observer } from 'mobx-react-lite'
import { getTimeFromDate } from '@expane/date'
import { TimeOff } from 'pages/BookingsPage/BookingsCalendar/logic'
import { getPositionOfBookingCard } from 'pages/BookingsPage/BookingsCalendar/BookingCard/logic'
import { getTopPositionForBooking } from 'pages/BookingsPage/BookingsCalendar/columns/logic'
import { DayTiming } from '@expane/data'

export interface CommonProps {
  timeOff: TimeOff
  onClick: () => void
  dayTiming: DayTiming
  firstCellTime: number
  columnDate: Date
  cellHeight: number
  titleCellHeight: number
}

export const CalendarTimeOffCard: FC<CommonProps> = observer(props => {
  const { timeOff, onClick, dayTiming, firstCellTime, columnDate, cellHeight, titleCellHeight } =
    props

  const isStartDate = isSameDay(columnDate, timeOff.start)
  const isEndDate = isSameDay(columnDate, timeOff.end)
  const startTime = isStartDate ? getTimeFromDate(timeOff.start) : dayTiming[0]
  const endTime = isEndDate ? getTimeFromDate(timeOff.end) : dayTiming[1]

  const topInCells = getTopPositionForBooking(startTime, firstCellTime)
  const topPositionInRem = (topInCells * cellHeight + titleCellHeight) / TAILWIND_TO_REM_RATIO

  const cardStyle = `absolute bg-gray-300 border border-gray-400 rounded-md pl-1.5 cursor-pointer hover:z-10`

  const { leftOffsetInPercents, widthInPercents } = getPositionOfBookingCard(timeOff.positionInfo)

  const info = timeOff.timeOffReason.name

  const scale = cellHeight / 2
  const duration = endTime === 0 ? dayTiming[1] - startTime : endTime - startTime
  const heightRem = scale * duration - DEFAULT_OFFSET_REM

  const bookingHeight = Math.max(heightRem, cellHeight / 7)

  return (
    <div
      onClick={onClick}
      style={{
        top: topPositionInRem + 'rem',
        left: leftOffsetInPercents + '%',
        width: widthInPercents - HORIZONTAL_OFFSET_PERCENTS + '%',
        height: bookingHeight + 'rem',
      }}
      className={cardStyle}
    >
      <div className="text-sm text-black leading-4 break-all overflow-hidden max-h-full ml-0.5">
        {info}
      </div>
    </div>
  )
})

const DEFAULT_OFFSET_REM = 0.2
const HORIZONTAL_OFFSET_PERCENTS = 5
