import { SelectDropDownItem } from '@expane/ui'

export interface ProductSelectDropDownItem extends SelectDropDownItem {
  vendorCode?: string | null
}
export const customSelectDropDownProductSearchFilterFunction = <
  T extends ProductSelectDropDownItem,
>(
  items: T[] | undefined,
  searchValue: string,
): T[] => {
  if (items === undefined) return []

  if (searchValue === '') return items

  return items.filter(({ name, vendorCode }) => (name + (vendorCode ?? '')).includes(searchValue))
}
