import { gql } from 'graphql-request'
import { queryClient, request, useMutation, useQuery, reportError, reportGqlError } from '../../api'
import {
  ServerTagBriefType,
  ServerTagInsertInput,
  ServerTagSetInput,
  ServerTagType,
} from '../../generated/graphql-types'
import { tagBriefFragment, tagFragment } from './tag.fragments'
import { CLIENTS_QUERY_KEY } from '../client/queryKeys'
import { ARCHIVED_TAGS_QUERY_KEY, TAGS_QUERY_KEY, TAGS_BRIEF_QUERY_KEY } from './queryKeys'

export function useFetchTagsBrief() {
  return useQuery<ServerTagBriefType[]>(
    [TAGS_QUERY_KEY, TAGS_BRIEF_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${tagBriefFragment}
          query {
            tags(order_by: { createdAt: desc }, where: { archived: { _is_null: true } }) {
              ...tagBriefType
            }
          }
        `,
      )

      if (Array.isArray(result?.tags)) {
        return result.tags
      } else {
        reportError(new Error('tags is not an array'), 'error', { result })
        return []
      }
    },
    {
      queryName: 'useFetchTags',
      onError: reportGqlError,
    },
  )
}

export function useFetchTags() {
  return useQuery<ServerTagType[]>(
    [TAGS_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${tagFragment}
          query {
            tags(order_by: { createdAt: desc }, where: { archived: { _is_null: true } }) {
              ...tagType
            }
          }
        `,
      )

      if (Array.isArray(result?.tags)) {
        return result.tags
      } else {
        reportError(new Error('tags is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchTags',
      onError: reportGqlError,
      onSuccess: tags =>
        tags.forEach(tag => queryClient.setQueryData([TAGS_QUERY_KEY, tag.id], tag)),
    },
  )
}

export function useFetchArchivedTags() {
  return useQuery<ServerTagType[]>(
    [ARCHIVED_TAGS_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${tagFragment}
          query {
            tags(order_by: { createdAt: desc }, where: { archived: { _is_null: false } }) {
              ...tagType
            }
          }
        `,
      )

      if (Array.isArray(result?.tags)) {
        return result.tags
      } else {
        reportError(new Error('tags is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchArchivedTags',
      onError: reportGqlError,
      onSuccess: tags =>
        tags.forEach(tag => queryClient.setQueryData([ARCHIVED_TAGS_QUERY_KEY, tag.id], tag)),
    },
  )
}

export function useFetchTagById(id: number | undefined) {
  return useQuery<ServerTagType | undefined>(
    [TAGS_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${tagFragment}
          query ($id: Int!) {
            tagById(id: $id) {
              ...tagType
            }
          }
        `,
        { id },
      )

      if (result?.tagById) {
        return result.tagById
      } else {
        reportError(new Error('tagById does not exist'), 'warning', { id, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchTagById',
      enabled: Boolean(id),
      onError: reportGqlError,
    },
  )
}

export function useArchiveTag() {
  return useMutation(
    async (dto: { id: number; archived: boolean }): Promise<{ updateTagById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $archived: timestamptz!) {
            updateTagById(pk_columns: { id: $id }, _set: { archived: $archived }) {
              id
            }
          }
        `,
        { id: dto.id, archived: dto.archived ? new Date().toISOString() : null },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TAGS_QUERY_KEY])
        queryClient.invalidateQueries([CLIENTS_QUERY_KEY])
        queryClient.invalidateQueries([ARCHIVED_TAGS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useCreateTag() {
  return useMutation(
    async (tagInsertInput: ServerTagInsertInput): Promise<{ insertTag: { id: number } }> => {
      return request(
        gql`
          mutation ($tagInsertInput: tag_insert_input!) {
            insertTag(object: $tagInsertInput) {
              id
            }
          }
        `,
        { tagInsertInput },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([TAGS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useUpdateTag() {
  return useMutation(
    async (dto: {
      id: number
      tagSetInput: ServerTagSetInput
    }): Promise<{ updateTagById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $tagSetInput: tag_set_input!) {
            updateTagById(pk_columns: { id: $id }, _set: $tagSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TAGS_QUERY_KEY])
        queryClient.invalidateQueries([CLIENTS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
