import { useTranslation } from 'react-i18next'
import { TreeMenuItem } from 'ui/TreeMenu'
import { TreeSelect } from 'ui/TreeSelect'

interface ProductsTreeSelectProps<MTreeMenuItem extends TreeMenuItem> {
  items: MTreeMenuItem[]
  value: MTreeMenuItem[]
  onChange: (value: MTreeMenuItem[]) => void
  className?: string
  customSearchFilterFunction?: (
    items: MTreeMenuItem[] | undefined,
    searchValue: string,
  ) => MTreeMenuItem[]
}

export const ProductsTreeSelect = <MTreeMenuItem extends TreeMenuItem>(
  props: ProductsTreeSelectProps<MTreeMenuItem>,
) => {
  const { items, value, onChange, className, customSearchFilterFunction } = props

  const { t } = useTranslation()

  return (
    <TreeSelect
      type="MultiPickMode"
      className={className}
      placeholder={t('products')}
      selected={value}
      onSelected={onChange}
      items={items}
      isFilter
      displayedItems={2}
      customSearchFilterFunction={customSearchFilterFunction}
    />
  )
}
