import { useCallback, useEffect, useState } from 'react'

export const useShortCut = (
  shortcutKeys: string[],
  callback: () => void,
  scope?: HTMLElement | null,
) => {
  const initialKeyMapping = shortcutKeys.reduce((currentKeys, key) => {
    currentKeys[key] = false
    return currentKeys
  }, {})
  const [keys, setKeys] = useState<Record<string, boolean>>(initialKeyMapping)

  const keydownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.repeat) return
      if (keys[event.code] === undefined || keys[event.code] === true) return

      if (scope) {
        if (event.target instanceof Node && scope.contains(event.target))
          setKeys({ ...keys, [event.code]: true })
      }

      if (!scope) setKeys({ ...keys, [event.code]: true })
    },
    [keys, scope],
  )
  const keyupHandler = useCallback(
    (event: KeyboardEvent) => {
      if (keys[event.code] === undefined || keys[event.code] === false) return

      if (scope) {
        if (event.target instanceof Node && scope.contains(event.target))
          setKeys({ ...keys, [event.code]: false })
      }

      if (!scope) setKeys({ ...keys, [event.code]: false })
    },
    [keys, scope],
  )

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler)
    return () => document.removeEventListener('keydown', keydownHandler)
  }, [keydownHandler])
  useEffect(() => {
    document.addEventListener('keyup', keyupHandler)
    return () => document.removeEventListener('keyup', keyupHandler)
  }, [keyupHandler])

  useEffect(() => {
    if (Object.values(keys).every(isPressed => isPressed)) {
      callback()
      setKeys(initialKeyMapping)
    }
  }, [callback, keys, initialKeyMapping])
}
