import { gql } from 'graphql-request'
import { employeeScheduleFragment } from '../schedule/schedule.fragments'
import { salarySettingFragment } from '../salarySetting/salarySetting.fragments'

export const employeeGroupBriefsFragment = gql`
  fragment employeeGroupBriefsType on employeeGroup {
    id
    name
  }
`

export const employeeInEmployeeGroupFragment = gql`
  ${employeeScheduleFragment}
  ${salarySettingFragment}
  fragment employeeInEmployeeGroupType on employee {
    id
    lastName
    firstName
    archived
    serviceEmployees(where: { branchId: { _eq: $branchId } }) {
      id
      serviceId
    }
    employeeSchedules(where: { branchId: { _eq: $branchId } }) {
      ...employeeScheduleType
    }
    salarySettings(where: { branchId: { _eq: $branchId } }) {
      ...salarySettingType
    }
  }
`

export const employeeAllBranchesInEmployeeGroupFragment = gql`
  fragment employeeAllBranchesInEmployeeGroupType on employee {
    id
    lastName
    firstName
    archived
    # Потрібно для сортування співробітників по філіям в яких вони працюють
    employeeSchedules {
      branchId
    }
  }
`

export const employeeGroupFragment = gql`
  ${employeeInEmployeeGroupFragment}
  fragment employeeGroupType on employeeGroup {
    id
    name
    roleId
    description
    archived
    employees(
      where: { archived: { _is_null: true }, employeeSchedules: { branchId: { _eq: $branchId } } }
    ) {
      ...employeeInEmployeeGroupType
    }
  }
`

export const employeeAllBranchesGroupFragment = gql`
  ${employeeAllBranchesInEmployeeGroupFragment}
  fragment employeeAllBranchesGroupType on employeeGroup {
    id
    name
    roleId
    description
    archived
    employees(where: { archived: { _is_null: true } }) {
      ...employeeAllBranchesInEmployeeGroupType
    }
  }
`

export const archivedEmployeeGroupFragment = gql`
  fragment archivedEmployeeGroupType on employeeGroup {
    id
    name
    roleId
    description
    archived
    employees(
      where: { archived: { _is_null: false }, employeeSchedules: { branchId: { _eq: $branchId } } }
    ) {
      id
      lastName
      firstName
    }
  }
`

export const employeeGroupByIdFragment = gql`
  fragment employeeGroupByIdType on employeeGroup {
    id
    name
    roleId
    description
    maxDiscount
    archived
    employees {
      id
      archived
      # Для перевірки при архівуванні що є активні графіки в інших філіях
      employeeSchedules {
        branchId
        endDate
      }
    }
    storageId
  }
`
