import {
  ServerSupplierBriefWithMovementsType,
  useFetchCurrentBranchTimezone,
  useFetchSuppliersMutualSettlements,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { EmptyPlaceholder, Page, Paper } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useCustomDatePicker } from 'logic/hooks/useCustomDatePicker'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { MutualSettlements } from 'pages/SuppliersPage/MutualSettlements'
import { FC, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { IoPeopleOutline } from 'react-icons/io5'
import { store } from 'store'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { onChangeSingleTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { SaveButton } from 'widgets/Buttons'
import { SupplierDialog } from 'widgets/SupplierDialog'

export const SuppliersPage: FC = () => {
  const { t } = useTranslation()

  const { customDatePicker, period } = useCustomDatePicker({ containerClassName: 'mb-2 mr-2' })

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: suppliers, isLoading } = useFetchSuppliersMutualSettlements(
    period.end,
    timezone,
    branchId,
  )

  const [selectedSupplier, setSelectedSupplier] = useState<TreeMenuItem | undefined>({
    ...allSuppliersItem,
    name: t(allSuppliersItem.name),
  })

  const { openEditDialog, openCreateDialog, dialog } = useOpenDialog(SupplierDialog)
  const { data: myPermissions } = useFetchMyPermissions()

  const isEditingAllowed = myPermissions?.includes(permissions.supplier.set)

  if (!suppliers && !isLoading) return null

  return (
    <Page>
      <div className="flex flex-wrap items-center">
        {isEditingAllowed && (
          <SaveButton onClick={() => openCreateDialog()} className="self-start mr-2" isCreate />
        )}

        {suppliers?.length !== 0 && customDatePicker}
      </div>

      {suppliers?.length === 0 ? (
        <EmptyPlaceholder Icon={IoPeopleOutline} text={t('emptyPlaceholder.archive')} />
      ) : (
        <Paper className="h-full flex overflow-hidden">
          <TreeMenu
            // в MultiPickMode можно выбрать папку
            type="MultiPickMode"
            className="w-1/4 py-3 px-2 border-r border-primary-50 h-full overflow-auto"
            items={transformSuppliersForTreeMenu(suppliers, t)}
            selected={selectedSupplier ? [selectedSupplier] : undefined}
            onSelected={item => {
              // onChangeSingleTreeMenu потому что нужен только 1 выбранный айтем папка или склад
              setSelectedSupplier(onChangeSingleTreeMenu(item, selectedSupplier))
            }}
            onEditItem={item => {
              openEditDialog(item.id)
            }}
            alwaysOpen
            showHighlight={false}
          />

          {selectedSupplier && suppliers ? (
            <MutualSettlements
              suppliers={suppliers}
              selectedSupplier={selectedSupplier}
              setSelectedSuppliers={setSelectedSupplier}
              isLoading={isLoading}
              period={period}
              branchId={branchId}
            />
          ) : null}
        </Paper>
      )}

      {dialog}
    </Page>
  )
}

export const transformSuppliersForTreeMenu = (
  suppliers: ServerSupplierBriefWithMovementsType[] | undefined,
  t: TFunction,
) => {
  const result: TreeMenuItem[] = []

  if (!suppliers) return result

  const allSuppliers = {
    ...allSuppliersItem,
    name: t(allSuppliersItem.name),
  }

  const parentFolder: TreeMenuItem = {
    ...allSuppliers,
    nodes: suppliers.map(supplier => ({ ...supplier, parentId: allSuppliersItem.id })),
  }

  result.push(parentFolder)

  return result
}

export const allSuppliersItem: TreeMenuItem = {
  id: -1,
  name: 'allSuppliers',
  isFolder: true,
}
