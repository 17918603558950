import { FadeInTranslateBottom, modalsHTMLElement } from '@expane/ui'
import { createContext, FC, PropsWithChildren, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import {
  IoAlertCircleOutline,
  IoCheckmarkCircleOutline,
  IoClose,
  IoWarningOutline,
} from 'react-icons/io5'

export type SnackType = 'success' | 'error' | 'warning'

export type OpenSnackbarFunc = (text: string, type: SnackType, duration?: number) => void

export interface SnackbarContextInterface {
  openSnackbar: OpenSnackbarFunc
  closeSnackbar: () => void
  isOpen: boolean
  type: SnackType
}

const defaultDuration = 5000
export const defaultReopeningInterval = 250

export const SnackbarContext = createContext<SnackbarContextInterface | null>(null)

export const SnackbarProvider: FC<PropsWithChildren<{ customModal?: HTMLElement | null }>> = ({
  customModal,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [text, setText] = useState('')
  const [type, setType] = useState<SnackType>('success')
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null)

  const triggerSnackbar: OpenSnackbarFunc = (text, type, duration) => {
    setText(text)
    setType(type)
    setIsOpen(true)
    setTimeoutId(setTimeout(() => setIsOpen(false), duration))
  }

  const openSnackbar: OpenSnackbarFunc = (text, type, duration) => {
    if (isOpen) {
      setIsOpen(false)
      if (timeoutId) clearTimeout(timeoutId)
      setTimeout(() => {
        triggerSnackbar(text, type, duration ?? defaultDuration)
      }, defaultReopeningInterval)
    } else {
      triggerSnackbar(text, type, duration ?? defaultDuration)
    }
  }

  const closeSnackbar = () => setIsOpen(false)

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar, isOpen, type }}>
      {children}

      <SnackbarDialog
        text={text}
        closeSnackbar={closeSnackbar}
        isOpen={isOpen}
        type={type}
        customModal={customModal}
      />
    </SnackbarContext.Provider>
  )
}

const SnackbarDialog = ({
  text,
  closeSnackbar,
  isOpen,
  type,
  customModal,
}: {
  text: string
  closeSnackbar: () => void
  isOpen: boolean
  type: SnackType
  customModal?: HTMLElement | null
}) => {
  if (!isOpen) return null

  const snackBarModal = customModal ?? modalsHTMLElement

  const Icon = icons[type]

  let snackbarStyle =
    'flex items-center m-2 fixed top-2 left-2 justify-start text-white rounded max-w-screen shadow '

  snackbarStyle += bgColor[type]

  return createPortal(
    <FadeInTranslateBottom className={snackbarStyle}>
      <div className="ml-5">
        <Icon size="1.25rem" />
      </div>

      <div className="grow max-w-2xl p-4 whitespace-pre-wrap">{text}</div>

      <div
        onClick={closeSnackbar}
        className="w-8 h-8 p-2 mr-2 shrink-0 flex-centered cursor-pointer rounded-2xl overflow-hidden hover:bg-white hover:bg-opacity-20"
      >
        <IoClose size="1rem" />
      </div>
    </FadeInTranslateBottom>,
    snackBarModal,
  )
}

export const useSnackbar = (): [openSnackbar: OpenSnackbarFunc, closeSnackbar: () => void] => {
  const Context = useContext<SnackbarContextInterface | null>(SnackbarContext)
  const openSnackbar = Context?.openSnackbar
  const closeSnackbar = Context?.closeSnackbar

  if (!openSnackbar || !closeSnackbar) throw new Error('SnackbarContext error!')

  // Returns methods in hooks array way
  return [openSnackbar, closeSnackbar]
}

const icons = {
  success: IoCheckmarkCircleOutline,
  error: IoAlertCircleOutline,
  warning: IoWarningOutline,
}

const bgColor = {
  success: 'bg-primary-500',
  error: 'bg-error-500',
  warning: 'bg-amber-600',
}
