import { useFetchCurrentBranchTimezone, useFetchSubscriptions } from '@expane/data'
import { Paper } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { ProceedToNextStep, useCreateBusinessSteps } from 'pages/CreateBusinessPage/index'
import { stepStyle } from 'pages/CreateBusinessPage/logic'
import { SubscriptionsPageList } from 'pages/SubscriptionsPage/List'
import React, { FC } from 'react'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'
import { SubscriptionDialog } from 'widgets/SubscriptionDialog'

export const SubscriptionsStep: FC = () => {
  const { proceedToNextStep } = useCreateBusinessSteps()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: subscriptions, isLoading } = useFetchSubscriptions(timezone, branchId)

  const { openEditDialog, openCreateDialog, dialog } = useOpenDialog(SubscriptionDialog)

  return (
    <div className={'flex items-end'}>
      <Paper className={stepStyle + ' flex flex-col w-160'}>
        <div className="h-126">
          <SaveButton onClick={() => openCreateDialog()} className="mb-2" isCreate />

          <div className="h-86">
            <SubscriptionsPageList
              onRowClick={subscription => openEditDialog(subscription.id)}
              subscriptions={subscriptions}
              isLoading={isLoading}
              branchId={branchId}
            />
          </div>
        </div>
      </Paper>

      <ProceedToNextStep className="mt-2" onClick={proceedToNextStep} />

      {dialog}
    </div>
  )
}
