import {
  FadeInTranslateTop,
  getPlacementStylesForArrow,
  modalsHTMLElement,
  useHandleClickOutside,
  usePopupOpenState,
} from '@expane/ui'
import { arrow, flip, offset, Side, useFloating } from '@floating-ui/react-dom'
import { FC, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { IoChevronDownOutline } from 'react-icons/io5'

export type StatusItem =
  | { id: number; name: string; color?: string; disabledReason?: string }
  | { id: number; name: string; color?: string; disabledReason?: string }[]

export interface StatusTrackerProps {
  items: StatusItem[]
  value: number
  onChange: (id: number) => void
  disabledSetReason: string | undefined
  disabledUnsetReason: string | undefined
  className?: string
  modal?: HTMLElement | null
}

export const StatusTracker: FC<StatusTrackerProps> = ({
  items,
  value,
  onChange,
  disabledSetReason,
  disabledUnsetReason,
  className = '',
  modal,
}) => {
  return (
    <div className={'flex h-7 isolate ' + className}>
      {items.map((item, index) => {
        const isFirst = index === 0
        const isLast = index === items.length - 1

        const itemId = Array.isArray(item) ? item[0].id : item.id

        let disabledReason: string | undefined
        if (disabledSetReason && itemId > value) disabledReason = disabledSetReason
        if (disabledUnsetReason && itemId < value) disabledReason = disabledUnsetReason

        if (Array.isArray(item))
          return (
            <MultipleTrackerItem
              key={itemId}
              items={item}
              selectedId={value}
              isFirst={isFirst}
              onClick={onChange}
              modal={modal ?? modalsHTMLElement}
              isLast={isLast}
              disabledReason={item[value]?.disabledReason ?? disabledReason}
              index={index}
            />
          )

        return (
          <TrackerItem
            key={item.id}
            onClick={() => onChange(item.id)}
            name={item.name}
            isSelected={value === item.id}
            isFirst={isFirst}
            isLast={isLast}
            disabledReason={item.disabledReason ?? disabledReason}
            color={item.color}
            modal={modal ?? modalsHTMLElement}
            index={index}
          />
        )
      })}
    </div>
  )
}

const TrackerItem: FC<{
  index: number
  name: string
  isFirst: boolean
  isSelected: boolean
  isLast: boolean
  onClick: () => void
  disabledReason: string | undefined
  color?: string
  modal: HTMLElement
}> = ({ name, isFirst, isLast, onClick, isSelected, disabledReason, color, modal, index }) => {
  const { isOpen, closePopup, openPopup } = usePopupOpenState()

  const disabled = Boolean(disabledReason)
  const className = getItemClassName({ isFirst, isSelected, isLast, disabled, color })

  const arrowRef = useRef(null)
  const {
    reference,
    floating,
    strategy,
    x,
    y,
    middlewareData: { arrow: arrowData },
    placement,
  } = useFloating({
    middleware: [flip(), offset(7), arrow({ element: arrowRef })],
  })

  const side = placement.split('-')[0] as Side
  const arrowPlacementStyles = getPlacementStylesForArrow(side, arrowData)

  return (
    <>
      <div
        ref={reference}
        onMouseEnter={() => {
          if (disabled) openPopup()
        }}
        onMouseLeave={() => {
          if (disabled) closePopup()
        }}
        onClick={() => {
          if (!disabled && !isSelected) {
            onClick()
          }
        }}
        className={className}
        style={{ zIndex: -index }}
      >
        {name}
      </div>
      {isOpen &&
        createPortal(
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? 0 + 'px',
              left: x ?? 0 + 'px',
            }}
            className={messageStyle}
          >
            <div
              ref={arrowRef}
              style={arrowPlacementStyles}
              className="absolute transform rotate-45 w-2 h-2 bg-gray-400"
            />
            {disabledReason}
          </div>,
          modal,
        )}
    </>
  )
}
const messageStyle = 'px-2 py-1 text-sm rounded text-white bg-gray-400 whitespace-pre-line'

const MultipleTrackerItem: FC<{
  items: { id: number; name: string; color?: string }[]
  selectedId: number
  isFirst: boolean
  isLast: boolean
  onClick: (id: number) => void
  modal: HTMLElement
  disabledReason: string | undefined
  index: number
}> = ({ selectedId, items, isFirst, isLast, onClick, modal, disabledReason, index }) => {
  const disabled = Boolean(disabledReason)

  const { openPopup, isOpen, closePopup } = usePopupOpenState()
  const buttonRef = useRef<HTMLDivElement | null>(null)
  const [popupRef, setPopupRef] = useState<HTMLDivElement | null>(null)
  useHandleClickOutside([buttonRef.current, popupRef], closePopup, isOpen)
  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(5)],
  })

  const selectedItem = items.find(item => item.id === selectedId)
  const displayedItem = selectedItem ?? items[0]
  const isSelected = selectedId === displayedItem.id
  const className = getItemClassName({
    isFirst,
    isSelected,
    isLast,
    disabled,
    color: selectedItem?.color,
  })

  const {
    isOpen: isDisabledReasonOpen,
    openPopup: openDisabledReason,
    closePopup: closeDisabledReason,
  } = usePopupOpenState()
  const arrowRef = useRef(null)
  const {
    reference: disabledReasonReference,
    floating: disabledReasonFloating,
    strategy: disabledReasonStrategy,
    x: disabledReasonX,
    y: disabledReasonY,
    middlewareData: { arrow: arrowData },
    placement,
  } = useFloating({
    middleware: [flip(), offset(7), arrow({ element: arrowRef })],
  })

  const side = placement.split('-')[0] as Side
  const arrowPlacementStyles = getPlacementStylesForArrow(side, arrowData)

  // We need direct control of element, so we store our own ref and sync it with useFloating
  useEffect(() => {
    reference(buttonRef.current)
    disabledReasonReference(buttonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledReasonReference, reference, buttonRef.current])
  useEffect(() => {
    floating(popupRef)
  }, [floating, popupRef])

  return (
    <>
      <div
        ref={buttonRef}
        onMouseEnter={() => {
          if (disabled) openDisabledReason()
        }}
        onMouseLeave={() => {
          if (disabled) closeDisabledReason()
        }}
        onClick={() => {
          if (!disabled && !isSelected) onClick(displayedItem.id)
        }}
        className={className}
        style={{ zIndex: -index }}
      >
        {displayedItem.name}
        <button
          onClick={e => {
            e.stopPropagation()
            openPopup()
          }}
          disabled={disabled}
          className={disabled ? 'cursor-default' : undefined}
        >
          <IoChevronDownOutline className="ml-0.5" size="1rem" />
        </button>
      </div>
      {isOpen &&
        createPortal(
          <div
            ref={setPopupRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <FadeInTranslateTop
              className="px-1.5 text-sm border-2 rounded-xl border-primary-400 divide-y-2 divide-primary-400 bg-block"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="status-list"
            >
              {items
                .filter(item => item.id !== displayedItem.id)
                .map(alternativeItem => (
                  <div
                    key={alternativeItem.id}
                    className="h-7 text-primary-400 hover:text-primary-600 cursor-pointer flex-centered"
                    onClick={() => {
                      onClick(alternativeItem.id)
                      closePopup()
                    }}
                  >
                    {alternativeItem.name}
                  </div>
                ))}
            </FadeInTranslateTop>
          </div>,
          modal,
        )}
      {isDisabledReasonOpen &&
        createPortal(
          <div
            ref={disabledReasonFloating}
            style={{
              position: disabledReasonStrategy,
              top: disabledReasonY ?? 0 + 'px',
              left: disabledReasonX ?? 0 + 'px',
            }}
            className={messageStyle}
          >
            <div
              ref={arrowRef}
              style={arrowPlacementStyles}
              className="absolute transform rotate-45 w-2 h-2 bg-gray-400"
            />
            {disabledReason}
          </div>,
          modal,
        )}
    </>
  )
}

const notSelectedStyles =
  ' border-primary-400 bg-block text-primary-400 hover:border-primary-600 hover:text-primary-600 cursor-pointer'

const getItemClassName = ({
  isFirst,
  isLast,
  isSelected,
  disabled,
  color,
}: {
  isFirst: boolean
  isSelected: boolean
  isLast: boolean
  disabled: boolean
  color?: string
}) => {
  let className = `grow flex-centered pr-1 border-t-2 border-r-2 border-b-2 transition-colors text-sm `
  className += isFirst ? ' pl-1 border-l-2 rounded-l' : ' pl-4 -ml-3'

  className += isLast ? ' rounded-r' : ' rounded-r-xl'

  if (isSelected) {
    const currentColor = color ?? DEFAULT_COLOR
    return className + ` border-${currentColor} text-white cursor-default bg-${currentColor}`
  }

  if (!isSelected) {
    if (disabled)
      className += ' border-input-disabled-color bg-input-disabled cursor-default text-main-color'
    else className += notSelectedStyles
  }

  return className
}

const DEFAULT_COLOR = 'primary-400'
