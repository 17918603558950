import { ChangeEvent, FC, InputHTMLAttributes, useState } from 'react'
import { ErrorMessageType } from '../ErrorMessage'
import { IconType } from 'react-icons'
import { BaseInput, InputHeight } from '../Input/BaseInput'

export interface NumberInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string
  hint?: string
  Icon?: IconType
  containerClassName?: string
  errorMessage?: ErrorMessageType
  onChange: (value: string) => void
  height?: InputHeight
}

export const NumberInput: FC<NumberInputProps> = ({
  label,
  hint,
  onChange,
  value,
  containerClassName,
  errorMessage,
  disabled,
  Icon,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value || '')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value

    // Разрешаем ввод только чисел, запятой и точки, и проверяем допустимый формат
    const isValidFormat =
      /^[0-9]*[.,]?[0-9]*$/.test(rawValue) &&
      !(rawValue.match(/[.,]/g) || []).some((char, i, arr) => i !== arr.indexOf(char))

    if (isValidFormat) {
      setInputValue(rawValue)

      // Преобразуем запятую в точку для внутреннего парсинга
      const parsedValue = rawValue.replace(',', '.')

      if (!isNaN(Number(parsedValue)) && parsedValue !== '') {
        const numberValue = parseFloat(parsedValue)
        let formattedValue = numberValue.toFixed(DECIMAL_PLACES)

        // Убираем незначащие нули в дробной части
        formattedValue = parseFloat(formattedValue).toString()

        onChange(formattedValue) // Используем кастомный onValueChange
      } else if (rawValue === '') {
        onChange('')
      }
    }
  }

  return (
    <BaseInput
      label={label}
      hint={hint}
      Icon={Icon}
      containerClassName={containerClassName}
      errorMessage={errorMessage}
      value={inputValue}
      onChangeInput={handleChange}
      disabled={disabled}
      {...rest}
    />
  )
}

const DECIMAL_PLACES = 3
