import { isValidPhoneNumber, parsePhoneNumber, AsYouType } from 'libphonenumber-js/min'
import { CountryList } from './countryList'
export { parsePhoneNumber, AsYouType } from 'libphonenumber-js/min'

export const checkValidPhone = (value: string) => isValidPhoneNumber(value)

export const onChangePhoneInput = (
  value: string,
  dialCode: string,
  onChange: (value: string) => void,
) => {
  // If user inserting full phone
  if (checkValidPhone(value)) {
    const parsedNumber = parsePhoneNumber(value)

    const parsedDialCodeWithPlus = '+' + parsedNumber.countryCallingCode

    const isThereSuchCodeInCountryList = Boolean(
      CountryList.find(item => item.dialCode === parsedDialCodeWithPlus),
    )

    if (isThereSuchCodeInCountryList) {
      onChange(parsedNumber.formatInternational())
      return
    }
  }

  // For inserting numbers like 066 000 0000 (only Ukrainian numbers).
  // There may be problems with other countries
  if (value.length >= 10 && value[0] === '0') {
    const newValue = new AsYouType().input(DEFAULT_UKR_PHONE_CODE + value.slice(1))
    onChange(newValue)
    return
  }

  const number = new AsYouType().input(dialCode + value)
  onChange(number)
}

/**
 * Splits a phone number string into its country dial code and the remaining phone number.
 *
 * @param {string} value - The phone number to be split.
 * @returns {Object} An object containing `dialCode` (country code) and `phone` (phone number).
 *                   If the country is not found, it returns the default dial code of Ukraine with an empty phone number.
 *
 * @example
 * splitPhone('+380 66 123 4567')
 * // returns { dialCode: '+380', phone: '66 123 4567' }
 *
 * @example
 * splitPhone('+1 703 123 4567')
 * // returns { dialCode: '+1', phone: '703 123 4567' }
 */
export const splitPhone = (value: string): { dialCode: string; phone: string } => {
  // Searching for countries where the telephone code matches
  const foundCountries = CountryList.filter(country => value.startsWith(country.dialCode))

  // If the country is not found, the standard code of Ukraine without a phone number is returned
  if (foundCountries.length === 0) {
    return { dialCode: DEFAULT_UKR_PHONE_CODE, phone: '' }
  }

  // If there are countries with NANP, then we are looking for countries with a 4-digit code
  for (let i = 0; i < foundCountries.length; i += 1) {
    if (foundCountries[i].dialCode.length > 4) {
      const dialCode = foundCountries[i].dialCode

      const phone = value.replace(dialCode, '')
      return { dialCode, phone: phone.trimStart() }
    }
  }

  // Otherwise, we return the first one found
  const dialCode = foundCountries[0].dialCode

  const phone = value.replace(dialCode, '')
  return { dialCode, phone: phone.trimStart() }
}

export const DEFAULT_UKR_PHONE_CODE = '+380'
