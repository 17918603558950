import { gql } from 'graphql-request'

export const dynamicScheduleDateFragment = gql`
  fragment dynamicScheduleDateType on dynamicScheduleDate {
    id
    date
    timingStart
    timingEnd
  }
`
