import {
  ServerAccountSoftwarePosType,
  ServerAccountType,
  ServerSoftwarePosType,
} from '@expane/data'
import { SelectDropDownItem } from '@expane/ui'
import { TFunction } from 'i18next'

/**
 * Трансформує рахунки:
 * - Помічає спосіб оплати за рахунком
 * - Показує які рахунки вже не можна вибрати, бо вони вже використовуються в інших РРО
 * - Показує які рахунки не можна вибрати, бо в них налаштовано знімання грошей вкінці дня
 */
export const transformAccountsForSelect = ({
  accountSoftwarePOSs,
  accounts,
  softwarePOSbyId,
  t,
}: {
  accountSoftwarePOSs: ServerAccountSoftwarePosType[]
  accounts: ServerAccountType[]
  softwarePOSbyId: ServerSoftwarePosType | undefined
  t: TFunction
}): SelectDropDownItem[] => {
  const thisAccountSoftwarePOSIds = softwarePOSbyId?.accountSoftwarePOSs.map(({ id }) => id) ?? []

  return accounts
    .filter(({ forPayments }) => forPayments)
    .map(({ id, name, cash, withdrawalToAccountId }) => {
      const item: SelectDropDownItem = {
        id,
        name: `${name} (${cash ? t('cash') : t('cashless')})`,
      }

      if (withdrawalToAccountId) {
        item.disabled = true
        item.warningMessage = t('softwarePOS.withdrawalToAccountWarningMessage')
      }

      const accountSoftwarePOS = accountSoftwarePOSs.find(({ accountId }) => accountId === id)
      if (accountSoftwarePOS)
        if (!thisAccountSoftwarePOSIds.includes(accountSoftwarePOS.id)) {
          item.disabled = true
          item.warningMessage = t('softwarePOS.alreadyUsedAccountWarningMessage')
        }

      return item
    })
}

export const getSoftwarePOSAccountsForForm = (
  accounts: SelectDropDownItem[],
  softwarePOSbyId: ServerSoftwarePosType | undefined,
): SelectDropDownItem[] => {
  if (!softwarePOSbyId) return []

  const result: SelectDropDownItem[] = []

  for (const accountSoftwarePOS of softwarePOSbyId.accountSoftwarePOSs) {
    const account = accounts.find(account => account.id === accountSoftwarePOS.accountId)
    if (account) result.push(account)
  }

  return result
}
