import { BranchWithSchedule, ServerScheduleType, useCreateEmployeesSchedules } from '@expane/data'
import { permissions } from '@expane/logic/permission'
import {
  CloseButton,
  Dialog,
  Hint,
  SelectDropdown,
  stopEnterPropagation,
  Switch,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { DateTimePicker, useSnackbar } from '@expane/widgets'
import { FC } from 'react'
import { Controller, SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline } from 'react-icons/io5'
import { SaveButton } from 'widgets/Buttons'
import { useOpenScheduleDialog } from 'widgets/ScheduleDialog'
import { CreateEmployeesSchedulesDialogProps } from './CreateEmployeesSchedulesDialog'
import {
  getDataForEmployeesSchedulesMutation,
  getDefaultValuesForImportEmployeesSchedulesForm,
  ImportEmployeesSchedulesFormData,
} from './logic'

interface CreateEmployeesSchedulesDialogLogicProps extends CreateEmployeesSchedulesDialogProps {
  schedules: ServerScheduleType[]
  currentBranch: BranchWithSchedule
  myPermissions: string[]
}

export const CreateEmployeesSchedulesDialogLogic: FC<CreateEmployeesSchedulesDialogLogicProps> = ({
  closeChooseEmployeesDialog,
  closeDialog,
  currentBranch,
  items,
  myPermissions,
  schedules,
}) => {
  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const { mutateAsync: createEmployeesSchedules } = useCreateEmployeesSchedules()

  const { dialog: scheduleDialog, openCreateDialog: openCreateScheduleDialog } =
    useOpenScheduleDialog()

  const { control, handleSubmit } = useForm<ImportEmployeesSchedulesFormData>({
    defaultValues: {
      employeesSchedules: getDefaultValuesForImportEmployeesSchedulesForm(
        items,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        currentBranch.schedule!.id,
        currentBranch.timezone,
      ),
    },
  })

  const { fields } = useFieldArray({ control, name: 'employeesSchedules' })
  const watchedEmployeesSchedules = useWatch({ control, name: 'employeesSchedules' })

  const onSubmit: SubmitHandler<ImportEmployeesSchedulesFormData> = async data => {
    const result = await createEmployeesSchedules({
      employeesSchedulesInsertInput: getDataForEmployeesSchedulesMutation(data, currentBranch.id),
    })

    if (result?.insertEmployeeSchedules)
      openSnackbar(t('employeeSchedules.createSuccess'), 'success', 3000)
    else openSnackbar(t('submitError'), 'error', 3000)

    closeDialog()
    closeChooseEmployeesDialog()
  }

  const isScheduleEditingAllowed = myPermissions.includes(permissions.schedule.set)

  return (
    <>
      <Dialog.Title>{t('employeeSchedules.adding')}</Dialog.Title>
      <Dialog.Body className="w-192 h-74.5 flex flex-col">
        <TableContainer>
          <TableHeader>
            <tr>
              <TableHeaderCell className="w-52">{t('employee.name')}</TableHeaderCell>
              <TableHeaderCell className="w-48">{t('startDate')}</TableHeaderCell>
              <TableHeaderCell className="w-36">
                <div className="flex items-center gap-1">
                  {t('dynamicSchedule.name')}
                  <Hint>{t('dynamicSchedule.hint')}</Hint>
                </div>
              </TableHeaderCell>
              <TableHeaderCell className="w-48">
                <div className="flex items-center gap-1">
                  {t('schedule.name')}{' '}
                  {isScheduleEditingAllowed && (
                    <button
                      onKeyDown={stopEnterPropagation}
                      onClick={() => openCreateScheduleDialog()}
                      className="text-primary-500 hover:text-primary-700 hover:dark:text-primary-600 rounded-full focus:ring-1 ring-primary-500"
                    >
                      <IoAddCircleOutline size="1.2rem" />
                    </button>
                  )}
                </div>
              </TableHeaderCell>
            </tr>
          </TableHeader>
          <TableBody>
            {fields.map((employeeSchedule, index) => (
              <TableRow key={employeeSchedule.employeeId}>
                <TableCell>{employeeSchedule.employeeName}</TableCell>
                <TableCell>
                  <Controller
                    name={`employeesSchedules.${index}.startDate`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DateTimePicker
                        timezone={currentBranch.timezone}
                        value={value}
                        type="date"
                        onChange={onChange}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`employeesSchedules.${index}.isDynamic`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="flex flex-centered">
                        <Switch checked={value} onChange={onChange} />
                      </div>
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`employeesSchedules.${index}.scheduleId`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        {watchedEmployeesSchedules[index].isDynamic ? (
                          <>-</>
                        ) : (
                          <SelectDropdown
                            value={value}
                            onSelectChange={onChange}
                            items={schedules}
                            disabled={watchedEmployeesSchedules[index].isDynamic}
                          />
                        )}
                      </>
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton onClick={handleSubmit(onSubmit)} disabled={!watchedEmployeesSchedules.length} />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>

      {scheduleDialog}
    </>
  )
}
