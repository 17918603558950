import { CloseButton, Dialog, ExplanationText, PlaceholderTextarea } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplyButton } from 'widgets/Buttons'

export const MessageTemplateSettingsDialogPlaceholder: FC<{ closeDialog: () => void }> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('messageTemplate.title')}</Dialog.Title>
      <Dialog.Body className="w-205">
        <div className="flex flex-col">
          <ExplanationText text={t('messageTemplate.warning')} type="warning" className="mb-2" />
          <ExplanationText
            text={t('messageTemplate.languageWarning')}
            type="warning"
            className="mb-2"
          />

          <div className="flex gap-2 mb-2">
            <PlaceholderTextarea label={t('bookingReminderMessage.title')} className="w-1/2" />

            <PlaceholderTextarea label={t('messageExample.title')} className="w-1/2" />
          </div>

          <ExplanationText text={t('bookingReminderMessage.info')} type="info" />
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <ApplyButton disabled />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
