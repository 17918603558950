import { queryClient, reportError, reportGqlError, request, useQuery } from '../../api'
import { ServerServiceGroupType } from '../../generated/graphql-types'
import { gql } from 'graphql-request'
import { serviceGroupFragment } from './serviceGroup.fragments'
import { DEACTIVATED_SERVICE_GROUPS_QUERY_KEY, SERVICE_GROUPS_QUERY_KEY } from './queryKeys'

export function useFetchServiceGroups(branchId: number | undefined | null) {
  return useQuery(
    [SERVICE_GROUPS_QUERY_KEY, { branchId }],
    async (): Promise<ServerServiceGroupType[]> => {
      const result = await request(
        gql`
          ${serviceGroupFragment}
          query ($branchId: Int!) {
            serviceGroups(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, archived: { _is_null: true } }
            ) {
              ...serviceGroupType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.serviceGroups)) {
        return result.serviceGroups
      } else {
        reportError(new Error('serviceGroups is not an array'), 'warning', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchServiceGroups',
      onError: reportGqlError,
      onSuccess: serviceGroups =>
        serviceGroups.forEach(serviceGroup =>
          queryClient.setQueryData([SERVICE_GROUPS_QUERY_KEY, serviceGroup.id], serviceGroup),
        ),
    },
  )
}

export function useFetchDeactivatedServiceGroups(branchId: number | undefined) {
  return useQuery(
    [DEACTIVATED_SERVICE_GROUPS_QUERY_KEY, { branchId }],
    async (): Promise<ServerServiceGroupType[]> => {
      const result = await request(
        gql`
          ${serviceGroupFragment}
          query ($branchId: Int!) {
            serviceGroups(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, archived: { _is_null: false } }
            ) {
              ...serviceGroupType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.serviceGroups)) {
        return result.serviceGroups
      } else {
        reportError(new Error('deactivatedServiceGroups is not an array'), 'warning', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchDeactivatedServiceGroups',
      onError: reportGqlError,
      onSuccess: serviceGroups =>
        serviceGroups.forEach(serviceGroup =>
          queryClient.setQueryData(
            [DEACTIVATED_SERVICE_GROUPS_QUERY_KEY, serviceGroup.id],
            serviceGroup,
          ),
        ),
    },
  )
}

export function useFetchServiceGroupById(id: number | undefined) {
  return useQuery<ServerServiceGroupType | undefined>(
    [SERVICE_GROUPS_QUERY_KEY, DEACTIVATED_SERVICE_GROUPS_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${serviceGroupFragment}
          query ($id: Int!) {
            serviceGroupById(id: $id) {
              ...serviceGroupType
            }
          }
        `,
        { id },
      )

      if (result?.serviceGroupById) {
        return result.serviceGroupById
      }

      reportError(new Error('serviceGroupById does not exist'), 'warning', { id, result })
      return undefined
    },
    { queryName: 'useFetchServiceGroupById', enabled: Boolean(id), onError: reportGqlError },
  )
}
