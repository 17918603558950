import { ServerTimeOffReasonType } from '@expane/data'

export const checkIsTimeOffReasonAllowedForCreation = (
  timeOffReason: ServerTimeOffReasonType | undefined,
  myEmployeeGroupId: number | undefined,
) => {
  if (timeOffReason?.timeOffReasonEmployeeGroups.length === 0) return true
  else
    return timeOffReason?.timeOffReasonEmployeeGroups.some(
      group => group.employeeGroupId === myEmployeeGroupId,
    )
}

export function getTimeOffReasonEmployeeGroupNames(
  timeOffReason: ServerTimeOffReasonType | undefined,
) {
  const groupNames = timeOffReason?.timeOffReasonEmployeeGroups?.map(
    group => group.employeeGroup.name,
  )

  return groupNames?.join(', ')
}
