import {
  EmployeeGroupWithSchedule,
  ServerSalaryIssueType,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchSalaryIssues,
} from '@expane/data'
import {
  getEmployeeGroupsWithEmployeesWithSalarySettings,
  getSalariesIssuesByTypesAndPeriod,
} from '@expane/logic/salaryIssues/filters'
import {
  calcTotalSalaryItemsSum,
  extractNotPaidSalaryFromCountedSalariesFullInfo,
} from '@expane/logic/salaryIssues/helpers'
import {
  countedFullSalariesByEmployee,
  CountedNotPaidSalaryType,
} from '@expane/logic/salaryIssues/logic'
import { Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import {
  convertEmployeeGroupsToTreeItems,
  transformEmployeeIdToTreeItem,
} from 'ui/TreeMenu/logic.employee'
import { TreeSelect } from 'ui/TreeSelect'
import { useOpenSalaryDebtIssueDialog } from 'widgets/SalaryIssueDialog/SalaryDebtIssueDialog'
import { SalaryIssueDialogFooter } from 'widgets/SalaryIssueDialog/SalaryIssueDialogFooter'
import { SalaryIssueDialogPlaceholder } from 'widgets/SalaryIssueDialog/SalaryIssueDialogPlaceholder'
import { SalaryIssueTable } from 'widgets/SalaryIssueDialog/SalaryIssueTable'
import { SalaryIssueTotal } from 'widgets/SalaryIssueDialog/SalaryIssueTotal'

export interface IssueSalaryDialogFormValues {
  accountId: number
  employees?: undefined | TreeMenuItem[]
  countedNotPaidSalaries?: CountedNotPaidSalaryType[]
}

interface SalaryIssueDialogProps {
  defaultEmployeeId?: number
  fromDate: Date
  toDate: Date
  closeDialog: () => void
}

const SalaryIssueDialog: FC<SalaryIssueDialogProps> = observer(
  ({ defaultEmployeeId, fromDate, toDate, closeDialog }) => {
    const branchId = store.branch.branchId

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } = useFetchEmployeesGroups(
      timezone,
      branchId,
    )
    const { data: salaryIssues, isLoading: isLoadingSalaryIssues } = useFetchSalaryIssues(
      timezone,
      branchId,
    )

    const isLoading = isLoadingEmployeeGroups || isLoadingSalaryIssues || !timezone
    const isNoData = !employeeGroups || !salaryIssues

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
    if (!isLoading && isNoData) return null

    return (
      <Modal close={closeDialog}>
        <Dialog>
          {isLoading ? (
            <SalaryIssueDialogPlaceholder closeDialog={closeDialog} />
          ) : (
            <SalaryIssueDialogLogic
              defaultEmployeeId={defaultEmployeeId}
              fromDate={fromDate}
              toDate={toDate}
              closeDialog={closeDialog}
              salaryIssues={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                salaryIssues!
              }
              employeeGroups={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                employeeGroups!
              }
            />
          )}
        </Dialog>
      </Modal>
    )
  },
)

interface SalaryIssueDialogLogicProps {
  defaultEmployeeId?: number
  salaryIssues: ServerSalaryIssueType[]
  employeeGroups: EmployeeGroupWithSchedule[]
  fromDate: Date
  toDate: Date
  closeDialog: () => void
}

const SalaryIssueDialogLogic: FC<SalaryIssueDialogLogicProps> = ({
  defaultEmployeeId,
  closeDialog,
  salaryIssues,
  employeeGroups,
  fromDate,
  toDate,
}) => {
  const salariesIssuesByTypesAndPeriod = getSalariesIssuesByTypesAndPeriod({
    salaryIssues,
    fromDate,
    toDate,
  })

  const dateFormatting = useDateFormatting()

  const { t } = useTranslation()

  const employeeGroupsWithEmployeesWithSalarySettings =
    getEmployeeGroupsWithEmployeesWithSalarySettings(employeeGroups, fromDate, toDate)

  const employeeTreeMenuItems = convertEmployeeGroupsToTreeItems(
    employeeGroupsWithEmployeesWithSalarySettings ?? [],
  )

  const availableEmployees = employeeGroupsWithEmployeesWithSalarySettings
    .map(({ employees }) => employees)
    .flat()

  const countedSalariesFullInfo = availableEmployees.map(employee =>
    countedFullSalariesByEmployee({
      toDate,
      fromDate,
      employee,
      ...salariesIssuesByTypesAndPeriod,
    }),
  )

  const { openSalaryDebtIssueDialog, salaryDebtIssueDialog } = useOpenSalaryDebtIssueDialog({
    countedSalariesFullInfo,
  })

  const countedNotPaidSalaries =
    extractNotPaidSalaryFromCountedSalariesFullInfo(countedSalariesFullInfo)

  const { control, handleSubmit, setValue } = useForm<IssueSalaryDialogFormValues>({
    defaultValues: {
      employees: defaultEmployeeId
        ? transformEmployeeIdToTreeItem(defaultEmployeeId, employeeGroups)
        : undefined,
      countedNotPaidSalaries: defaultEmployeeId
        ? [countedNotPaidSalaries.find(salary => salary.employeeId === defaultEmployeeId)]
        : undefined,
    },
  })

  const { fields: selectedEmployeesCountedNotPaidSalaries } = useFieldArray({
    control,
    name: 'countedNotPaidSalaries',
  })

  const watchedEmployees = useWatch({
    control,
    name: 'employees',
  })

  const watchedCountedNotPaidSalaries = useWatch({
    control,
    name: 'countedNotPaidSalaries',
  })

  const totalNotPaidSalaries = watchedCountedNotPaidSalaries
    ? calcTotalSalaryItemsSum(watchedCountedNotPaidSalaries)
    : 0

  return (
    <>
      <Dialog.Title>
        <div className="flex items-center justify-between">
          <p>{t('salary.name')}</p>
          <p className="text-sm leading-3 font-medium text-gray-500">
            {`${t('period')}: `}
            <span className={labelClassName}>{`${dateFormatting(
              'date',
              fromDate,
            )} - ${dateFormatting('date', toDate)}`}</span>
          </p>
        </div>
      </Dialog.Title>

      <Dialog.Body className="flex flex-col h-122 w-220">
        <Controller
          control={control}
          name="employees"
          render={({ field: { onChange, value } }) => (
            <TreeSelect
              type="MultiPickMode"
              items={employeeTreeMenuItems}
              onSelected={selectedEmployees => {
                const newCountedSalariesFullInfo = onChangeEmployeeSalary({
                  watchedEmployees,
                  employees: selectedEmployees,
                  countedNotPaidSalaries,
                  watchedCountedNotPaidSalaries: watchedCountedNotPaidSalaries ?? [],
                })

                setValue('countedNotPaidSalaries', newCountedSalariesFullInfo)
                onChange(selectedEmployees)
              }}
              className="w-1/2 mr-2 mb-2"
              selected={value as TreeMenuItem[]}
              placeholder={t('placeholders.defaultSelect')}
              label={t('employees.name')}
            />
          )}
        />

        {watchedEmployees && watchedEmployees.length !== 0 ? (
          <>
            <SalaryIssueTable
              control={control}
              employeeGroups={employeeGroups}
              countedNotPaidSalaries={selectedEmployeesCountedNotPaidSalaries}
              fromDate={fromDate}
              toDate={toDate}
              onPayDebtClick={openSalaryDebtIssueDialog}
              countedSalariesFullInfo={countedSalariesFullInfo}
            />
            {countedNotPaidSalaries.length === 0 ? null : (
              <SalaryIssueTotal control={control} totalNotPaidSalaries={totalNotPaidSalaries} />
            )}
          </>
        ) : null}
      </Dialog.Body>

      <SalaryIssueDialogFooter
        handleSubmit={handleSubmit}
        totalNotPaidSalaries={totalNotPaidSalaries}
        isShownIssueButton={watchedEmployees && watchedEmployees.length !== 0}
        closeDialog={closeDialog}
        startPeriod={fromDate}
        endPeriod={toDate}
      />

      {salaryDebtIssueDialog}
    </>
  )
}

export const useOpenIssueSalaryDialog = (props: Omit<SalaryIssueDialogProps, 'closeDialog'>) => {
  const { isOpen, closePopup, openPopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()

  const openIssueSalaryDialog = (onClose?: () => void) => {
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const issueSalaryDialog = isOpen ? (
    <SalaryIssueDialog closeDialog={closeDialog} {...props} />
  ) : null

  return {
    issueSalaryDialog,
    openIssueSalaryDialog,
  }
}

const labelClassName = 'text-primary-600 text-base'

const onChangeEmployeeSalary = (dto: {
  watchedEmployees: TreeMenuItem[] | undefined
  employees: TreeMenuItem[]
  countedNotPaidSalaries: CountedNotPaidSalaryType[]
  watchedCountedNotPaidSalaries: CountedNotPaidSalaryType[]
}) => {
  const { watchedEmployees, employees, countedNotPaidSalaries, watchedCountedNotPaidSalaries } = dto

  // извлекаем сотрудников из TreeMenu
  const extractedPreviousAddedEmployees = watchedEmployees
    ? extractItemsFromFolders(watchedEmployees)
    : []

  const extractedEmployees = extractItemsFromFolders(employees)

  // находим только что добавленных сотрудников, у которых еще не подсчитана выплаченная зп
  const justAddedEmployees = extractedEmployees.filter(
    ({ id: extractedEmployeeId }) =>
      !extractedPreviousAddedEmployees.some(
        ({ id: extractedWatchedEmployeeId }) => extractedEmployeeId === extractedWatchedEmployeeId,
      ),
  )

  const justAddedEmployeesIds = justAddedEmployees.map(({ id }) => id)

  // находим сотрудников, которые были добавлены ранее и у них уже отображается зарплата,
  // нужно на случай если кто-то уже удален из списка
  const reviewedPreviousAddedEmployees = extractedPreviousAddedEmployees.filter(
    ({ id: previousAddedEmployeeId }) =>
      extractedEmployees.some(({ id: employeeId }) => previousAddedEmployeeId === employeeId),
  )

  const reviewedPreviousAddedEmployeesIds = reviewedPreviousAddedEmployees.map(({ id }) => id)

  // фильтруем список зарплат по тем сотрудникам которые сейчас отображаются в TreeMenu
  const filteredNotPaidSalaries = watchedCountedNotPaidSalaries.filter(({ employeeId }) =>
    reviewedPreviousAddedEmployeesIds.some(id => employeeId === id),
  )

  const salariesJustAddedEmployees = countedNotPaidSalaries.filter(employee =>
    justAddedEmployeesIds.some(id => employee.employeeId === id),
  )

  return [...filteredNotPaidSalaries, ...salariesJustAddedEmployees]
}
