import { FC, PropsWithChildren, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

export const usePrintWrapper = (title?: string) => {
  const componentRef = useRef<HTMLDivElement | null>(null)

  const handleAfterPrint = useRef<() => void | undefined>()
  const handleBeforePrint = useRef<() => void | undefined>()

  const onPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: title,
    pageStyle: '',
    bodyClass: '',
    onBeforeGetContent: () => {
      handleBeforePrint.current?.()
    },
    onAfterPrint: () => {
      handleAfterPrint.current?.()
    },
  })

  const PrintWrapper: FC<
    PropsWithChildren<{
      className?: string
      onBeforePrint?: () => void
      onAfterPrint?: () => void
    }>
  > = ({ children, className, onAfterPrint, onBeforePrint }) => {
    handleAfterPrint.current = onAfterPrint
    handleBeforePrint.current = onBeforePrint

    return (
      <div ref={componentRef} className={className}>
        {children}
      </div>
    )
  }

  return { PrintWrapper, onPrint }
}
