import {
  Checkbox,
  CloseButton,
  Dialog,
  InputLabel,
  Paper,
  PlaceholderInput,
  PlaceholderString,
  PlaceholderTextarea,
  Spinner,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline } from 'react-icons/io5'
import { ApplyButton, SaveButton } from 'widgets/Buttons'
import { InfoTabClientSubscriptionPlaceholder } from 'widgets/ClientDialog/InfoTab/InfoTabClientSubscription'

export const BookingDialogPlaceholder: FC<{ closeDialog: () => void }> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('booking.name')}</Dialog.Title>

      <Dialog.Body className="w-288 h-120 flex">
        <div className="w-96 pr-2 relative">
          <PlaceholderInput label={t('client.name')} className="pb-5" />

          <div>
            <div className="mb-2">
              <ClientInfoItemPlaceholder title={t('onAccount')} />
              <ClientInfoItemPlaceholder title={t('lead')} />
              <ClientInfoItemPlaceholder title={t('lastVisit')} />
            </div>

            <InfoTabClientSubscriptionPlaceholder />
          </div>

          <PlaceholderTextarea label={t('note')} rows={1} />
        </div>
        <div className="w-100">
          <div className="flex pb-4">
            <PlaceholderInput label={t('location.name')} className="w-1/2 pr-1 shrink-0" />
            <PlaceholderInput label={t('employee.name')} className="w-1/2 pl-1 shrink-0" />
          </div>

          <div className="flex justify-between">
            <PlaceholderInput label={t('duration')} className="pr-2 w-1/3" width="small" />
            <PlaceholderInput label={t('dateTitle')} className="w-2/3 flex flex-col" />
          </div>
        </div>
        <div className="w-88 pl-2 shrink-0">
          <div className="flex items-start justify-between">
            <InputLabel label={t('services')} />
          </div>
          <div className="border-2 rounded-lg h-101 border-input-disabled-color">
            <Spinner expandCentered />
          </div>
        </div>
      </Dialog.Body>

      <Dialog.Footer>
        <SaveButton disabled />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}

const ClientInfoItemPlaceholder: FC<{ title: string }> = ({ title }) => (
  <div className="flex items-center justify-between">
    <p className="text-xs font-medium text-gray-400 whitespace-nowrap">{title}:</p>
    <PlaceholderString width="small" />
  </div>
)

export const MultiBookingDialogPlaceholder: FC<{ closeDialog: () => void }> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('multiServicesBooking.name')}</Dialog.Title>
      <Dialog.Body className="w-288 h-120 flex">
        <div className="flex w-full">
          <div className="w-1/3 pr-2">
            <div className="relative">
              <PlaceholderInput label={t('client.name')} className="pb-5" />
              <div>
                <div className="mb-2">
                  <ClientInfoItemPlaceholder title={t('onAccount')} />
                  <ClientInfoItemPlaceholder title={t('lead')} />
                  <ClientInfoItemPlaceholder title={t('lastVisit')} />
                </div>

                <div className={'flex flex-col text-sm'}>
                  <InputLabel label={t('subscriptions')} />

                  <Paper className="w-full h-36">
                    <Spinner expandCentered />
                  </Paper>
                </div>
              </div>
            </div>

            <PlaceholderTextarea label={t('note')} rows={1} className="mt-2" />
          </div>

          <div className="w-2/3 flex flex-col">
            <div className="h-10" />

            <div className="flex">
              <PlaceholderInput label={t('dateTitle')} className="w-1/3 pr-2" />
              <PlaceholderInput label={t('location.name')} className="w-1/3 pr-2" />
              <PlaceholderInput label={t('employee.name')} className="w-1/3 pr-2" />

              <ApplyButton type="outline" className="self-end" disabled />
            </div>
            <button disabled className="ml-auto text-gray-500 mt-2 flex items-center">
              <IoAddCircleOutline size="1.3rem" className="mr-1" />
              <p>{t('multiService')}</p>
            </button>
            <Paper className="overflow-auto flex-1 p-2 mt-2">
              <Spinner expandCentered />
            </Paper>
          </div>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton disabled />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}

export const GroupBookingDialogPlaceholder: FC<{ closeDialog: () => void }> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('groupBooking.name')}</Dialog.Title>

      <Dialog.Body className="w-148">
        <PlaceholderInput label={t('clients')} />

        <div className="flex mt-2 mb-4">
          <PlaceholderInput label={t('location.name')} className="mr-2 w-1/2" />
          <PlaceholderInput label={t('employee.name')} className="w-1/2" />
        </div>

        <PlaceholderInput label={t('service.name')} className="pb-4" />

        <div className="flex">
          <PlaceholderInput label={t('dateTitle')} className="mr-2 w-1/2" />
          <PlaceholderInput label={t('duration')} className="w-1/2" />
        </div>

        <PlaceholderTextarea label={t('note')} rows={1} />

        <Checkbox
          containerClassName="mt-2 ml-auto"
          label={t('statusItems.done')}
          checked={false}
          disabled
        />
      </Dialog.Body>

      <Dialog.Footer>
        <SaveButton disabled />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
