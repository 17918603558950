import {
  EmployeeScheduleDto,
  SALARY_ISSUE_TYPES,
  ServerSalaryIssueType,
  ServerSalaryRateSettingType,
  ServerTransactionSalaryType,
} from '@expane/data'
import { getOverlappingEmployeeScheduleAndPeriod } from '../employeeSchedule'
import {
  getOverlappingSalaryRateSettingAndPeriod,
  isSalaryRateDaily,
  isSalaryRateMonthly,
} from '../salarySettings'
import {
  areIntervalsOverlapping,
  formatFullDate,
  isBefore,
  isIntervalWithinLastDaysOfMonth,
  startOfMonth,
} from '@expane/date'
import {
  getPremiumAndPenaltySalaryIssues,
  getReferralPercentageSalaryIssues,
  getServicesSalaryIssues,
} from './filters'
import { calcAccruedRate } from './calcAccruedRate'

export interface SalaryItem {
  id: number
  date: Date
  name: string
  price: number
  totalSum: number | null
  type: 'service' | 'premium' | 'salaryRate' | 'referralPercentage' | 'salary'
  isSalaryPaid?: boolean
  additionalInfo?: string
  isCountedByBackUpFormula?: boolean
  client?: string
  transactionId?: number | null
}

export const convertAccruedSalaryRateToSalaryItems = ({
  salaryRateSettings,
  fromDate,
  toDate,
  contractStartDate,
  employeeSchedules,
  salaryRateIssues,
}: {
  salaryRateSettings: ServerSalaryRateSettingType[]
  employeeSchedules: EmployeeScheduleDto[]
  contractStartDate: Date
  fromDate: Date
  toDate: Date
  salaryRateIssues: ServerSalaryIssueType[]
}): SalaryItem[] => {
  const currentEmployeeSchedules = employeeSchedules.filter(
    employeeSchedule =>
      getOverlappingEmployeeScheduleAndPeriod({
        fromDate,
        toDate,
        employeeSchedule,
      }).length,
  )

  const currentSalaryRateSettings = salaryRateSettings.filter(
    salaryRateSetting =>
      getOverlappingSalaryRateSettingAndPeriod({
        fromDate,
        toDate,
        salaryRateSetting,
      }).length,
  )

  const salaryItems: SalaryItem[] = currentSalaryRateSettings.length
    ? currentSalaryRateSettings.map(salaryRateSetting => {
        const filteredSalaryRateIssues = salaryRateIssues.filter(
          salaryIssue =>
            salaryIssue.salarySettingId === salaryRateSetting.id &&
            salaryIssue.startPeriod &&
            salaryIssue.endPeriod &&
            areIntervalsOverlapping(
              {
                start: salaryIssue.startPeriod,
                end: salaryIssue.endPeriod,
              },
              { start: fromDate, end: toDate },
            ),
        )

        const accruedSalaryRate = calcAccruedRate({
          fromDate,
          toDate,
          salaryRateSetting,
          employeeSchedules: currentEmployeeSchedules,
          contractStartDate,
        }).totalSum

        const paidSalaryRate = filteredSalaryRateIssues.reduce((acc, value) => acc + value.value, 0)

        // если начало ставки до начала выбранного периода
        // то дата начала отображаемой ставки = дата начала выбранного периода
        const startDate = isBefore(salaryRateSetting.start, fromDate)
          ? fromDate
          : salaryRateSetting.start

        const isMonthly = isSalaryRateMonthly(salaryRateSetting)

        return {
          id: salaryRateSetting.id,
          date: startOfMonth(fromDate),
          name: `${isMonthly ? 'salary.monthlyRate' : 'salary.dailyRate'}`,
          price: 0,
          totalSum:
            isIntervalWithinLastDaysOfMonth(fromDate, toDate) ||
            isSalaryRateDaily(salaryRateSetting)
              ? accruedSalaryRate
              : 0,
          type: 'salaryRate',
          additionalInfo: isMonthly
            ? '-'
            : `${formatFullDate(startDate)} - ${formatFullDate(salaryRateSetting.end ?? toDate)}`,
          isSalaryPaid: accruedSalaryRate - paidSalaryRate === 0,
        }
      })
    : []

  return salaryItems.filter(salaryItem => salaryItem.totalSum !== 0)
}

export const getServiceSalaryIssueTotalSum = (salaryIssue: ServerSalaryIssueType) => {
  if (
    salaryIssue.type === SALARY_ISSUE_TYPES.service ||
    salaryIssue.type === SALARY_ISSUE_TYPES.quickSaleService
  )
    return salaryIssue.value
  // если сумма зп зависит от реализованной стоимости то возвращаем ее
  // если оплаты нет то считаем по запасной формуле и считаем это значение
  if (salaryIssue.type === SALARY_ISSUE_TYPES.serviceWithActualSoldPrice) {
    if (salaryIssue.valueWhenDependentOnRealized) return salaryIssue.valueWhenDependentOnRealized
    else return salaryIssue.value
  } else return 0
}

export const convertSalaryIssuesToServiceSalaryItems = (
  salaryIssues: ServerSalaryIssueType[],
): SalaryItem[] => {
  if (!salaryIssues) return []

  const servicesSalaryIssues = getServicesSalaryIssues(salaryIssues)

  return servicesSalaryIssues.map(salaryIssue => ({
    id: salaryIssue.id,
    date: salaryIssue.date,
    name: salaryIssue.salarySettingService?.service.name ?? '',
    price: gerServiceSalaryIssuePrice(salaryIssue),
    totalSum: getServiceSalaryIssueTotalSum(salaryIssue),
    isSalaryPaid: Boolean(salaryIssue.salaryTransactionId),
    type: 'service',
    isCountedByBackUpFormula:
      salaryIssue.type === SALARY_ISSUE_TYPES.serviceWithActualSoldPrice &&
      salaryIssue.valueWhenDependentOnRealized === null,
    transactionId: salaryIssue.paymentTransactionId,
  }))
}

const gerServiceSalaryIssuePrice = (salaryIssue: ServerSalaryIssueType): number => {
  if (
    salaryIssue.type === SALARY_ISSUE_TYPES.serviceWithActualSoldPrice ||
    salaryIssue.type === SALARY_ISSUE_TYPES.quickSaleService
  )
    return salaryIssue.paymentTransactionService?.price ?? 0
  // если тип SALARY_ISSUE_TYPES.service то paymentTransactionService не формируется
  if (salaryIssue.type === SALARY_ISSUE_TYPES.service) {
    return salaryIssue.salarySettingService?.service.price ?? 0
  } else return 0
}

export const convertSalaryIssuesToPremiumSalaryItems = (
  salaryIssues: ServerSalaryIssueType[],
): SalaryItem[] => {
  if (!salaryIssues) return []

  const premiumAndPenaltySalaryIssues = getPremiumAndPenaltySalaryIssues(salaryIssues)

  return premiumAndPenaltySalaryIssues.map(salaryIssue => ({
    id: salaryIssue.id,
    date: salaryIssue.date,
    // ранее отфильтровали только начисления премий и штрафов
    name:
      salaryIssue.type === SALARY_ISSUE_TYPES.premium
        ? 'transactionNames.premium'
        : 'transactionNames.penalty',
    price: 0,
    totalSum:
      salaryIssue.type === SALARY_ISSUE_TYPES.premium ? salaryIssue.value : -salaryIssue.value,
    isSalaryPaid: Boolean(salaryIssue.salaryTransactionId),
    type: 'premium',
    additionalInfo: salaryIssue.comment ?? undefined,
  }))
}

export const convertSalariesToSalaryItems = (
  salaries: ServerTransactionSalaryType[],
): SalaryItem[] => {
  return salaries.map(salary => ({
    id: salary.id,
    transactionId: salary.id,
    name: 'transactionNames.salary',
    price: 0,
    date: salary.date,
    totalSum: salary.amount,
    type: 'salary',
    additionalInfo:
      salary.startPeriod && salary.endPeriod
        ? `${formatFullDate(salary.startPeriod)} - ${formatFullDate(salary.endPeriod)}`
        : undefined,
  }))
}

export const convertSalaryIssuesToReferralPercentageSalaryItems = (
  salaryIssues: ServerSalaryIssueType[],
): SalaryItem[] => {
  if (!salaryIssues) return []

  const servicesSalaryIssues = getReferralPercentageSalaryIssues(salaryIssues)

  return servicesSalaryIssues.map(salaryIssue => ({
    id: salaryIssue.id,
    date: salaryIssue.date,
    name: salaryIssue.paymentTransactionService?.service.name ?? '',
    price: salaryIssue.paymentTransactionService?.price ?? 0,
    totalSum: salaryIssue.value,
    isSalaryPaid: Boolean(salaryIssue.salaryTransactionId),
    type: 'referralPercentage',
    client: `${salaryIssue.paymentTransaction?.client?.firstName ?? ''} ${
      salaryIssue.paymentTransaction?.client?.lastName ?? ''
    }`,
    transactionId: salaryIssue.paymentTransactionId,
  }))
}
