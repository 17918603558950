import { gql } from 'graphql-request'
import { request } from '../../api'
import {
  ServerValidateBookingInput,
  ServerValidateBookingOutput,
  ServerValidationResult,
} from '../../generated/graphql-types'

/** Validates one booking
 * @param data Booking to validate
 * @returns Validation result, if `type` is null, then the booking is valid
 */
export async function validateBooking(
  data: ServerValidateBookingInput,
): Promise<ServerValidationResult> {
  const result = await request<{ validateBookings: ServerValidateBookingOutput }>(
    gql`
      query MyQuery($data: [ValidateBookingInput!]!) {
        validateBookings(data: $data) {
          validationResults {
            type
            busyClientIds
          }
        }
      }
    `,
    { data: [data] },
  )

  return result.validateBookings.validationResults[0]
}

/** Validates several bookings
 * @param data Array of bookings to validate
 * @returns Array of validation results in the same order as input array. If `type` is null, then the booking is valid
 */
export async function validateBookings(
  data: ServerValidateBookingInput[],
): Promise<ServerValidationResult[]> {
  const result = await request<{ validateBookings: ServerValidateBookingOutput }>(
    gql`
      query MyQuery($data: [ValidateBookingInput!]!) {
        validateBookings(data: $data) {
          validationResults {
            type
          }
        }
      }
    `,
    { data },
  )

  return result.validateBookings.validationResults
}
