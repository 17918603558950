import { gql } from 'graphql-request'
import { DEFAULT_TIMEZONE, toZonedTime } from '@expane/date'
import {
  reportError,
  reportGqlError,
  request,
  SHORT_STALE_TIME,
  useInfiniteQuery,
  useQuery,
} from '../../api'
import { ServerClientNotificationType } from '../../generated/graphql-types'
import { clientNotificationFragment, notificationFragment } from './notification.fragments'
import {
  CLIENT_NOTIFICATIONS_QUERY_KEY,
  DONE_NOTIFICATIONS_QUERY_KEY,
  NOTIFICATIONS_AGGREGATE_QUERY_KEY,
  NOTIFICATIONS_QUERY_KEY,
} from './queryKeys'
import {
  formNotificationBoolExp,
  InfiniteNotifications,
  NotificationStatusForFetchType,
  parseDatesInNotificationGqlResponse,
} from './logic'

export function useFetchClientNotificationByClientId(
  clientId: number,
  timezone: string | undefined,
) {
  return useQuery(
    [CLIENT_NOTIFICATIONS_QUERY_KEY, clientId],
    async (): Promise<ServerClientNotificationType[]> => {
      const result = await request(
        gql`
          ${clientNotificationFragment}
          query ($clientId: Int!) {
            notifications(order_by: { sentAt: desc }, where: { clientId: { _eq: $clientId } }) {
              ...clientNotificationType
            }
          }
        `,
        { clientId },
      )

      if (Array.isArray(result?.notifications)) {
        return result.notifications.map(cM => ({
          ...cM,
          sentAt: cM.sentAt ? toZonedTime(cM.sentAt, timezone ?? DEFAULT_TIMEZONE) : null,
        }))
      } else {
        reportError(new Error('clientNotification is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchClientNotificationByClientId',
      onError: reportGqlError,
    },
  )
}

export function useFetchNotificationsAggregateByEmployeeId(
  employeeId: number | null,
  branchId: number | null | undefined,
) {
  return useQuery(
    [NOTIFICATIONS_AGGREGATE_QUERY_KEY, { employeeId, branchId }],
    async (): Promise<number | undefined> => {
      const result = await request(
        gql`
          query ($notificationBoolExp: notification_bool_exp!) {
            notificationsAggregate(where: $notificationBoolExp) {
              aggregate {
                count
              }
            }
          }
        `,
        {
          notificationBoolExp: formNotificationBoolExp(employeeId, branchId, 'undone'),
        },
      )

      return result?.notificationsAggregate.aggregate.count
    },
    {
      enabled: Boolean(employeeId),
      queryName: 'useFetchNotificationsAggregate',
      onError: reportGqlError,
      // staleTime only indicates that the data after this time is out of date.
      // Indicates that the data should not be taken from the cache but from the server when requesting
      staleTime: SHORT_STALE_TIME,
      // refetchInterval indicates that data must be reloaded after the specified time
      refetchInterval: SHORT_STALE_TIME,
    },
  )
}

export function useFetchInfiniteNotificationsByEmployeeId({
  employeeId,
  branchId,
  perPage,
  timezone,
  type,
}: {
  employeeId: number | null
  branchId: number | null | undefined
  perPage: number
  timezone: string | undefined
  type: NotificationStatusForFetchType
}) {
  return useInfiniteQuery(
    [
      type === 'undone' ? NOTIFICATIONS_QUERY_KEY : DONE_NOTIFICATIONS_QUERY_KEY,
      { employeeId, branchId },
    ],
    async ({ pageParam = 1 }): Promise<InfiniteNotifications> => {
      const result = await request(
        gql`
          ${notificationFragment}
          query ($perPage: Int!, $offset: Int!, $notificationBoolExp: notification_bool_exp!) {
            notifications(
              limit: $perPage
              offset: $offset
              order_by: { sentAt: desc_nulls_first }
              where: $notificationBoolExp
            ) {
              ...notificationType
            }
            notificationsAggregate(where: $notificationBoolExp) {
              aggregate {
                count
              }
            }
          }
        `,
        {
          perPage,
          offset: (pageParam - 1) * perPage,
          notificationBoolExp: formNotificationBoolExp(employeeId, branchId, type),
        },
      )

      if (Array.isArray(result?.notifications)) {
        return {
          notifications: result.notifications.map(notification =>
            parseDatesInNotificationGqlResponse(notification, timezone ?? DEFAULT_TIMEZONE),
          ),
          totalNotifications: result?.notificationsAggregate.aggregate.count ?? 0,
          currentPage: pageParam,
        }
      } else {
        reportError(new Error('notifications is not an array'), 'warning', {
          result,
        })
        return { notifications: [], totalNotifications: 0, currentPage: 1 }
      }
    },
    {
      enabled: Boolean(employeeId) && Boolean(timezone) && Boolean(branchId),
      onError: reportGqlError,
      staleTime: SHORT_STALE_TIME,
      getNextPageParam: lastPage => {
        if (lastPage.totalNotifications - lastPage.currentPage * perPage > 0)
          return lastPage.currentPage + 1

        return undefined
      },
    },
  )
}
