import { gql } from 'graphql-request'
import { reportError, reportGqlError, request, requestAnonymously, useQuery } from '../../api'
import {
  ServerAnonymouslyServiceType,
  ServerExtendedInterbranchServiceType,
  ServerExtendedServiceType,
  ServerInterbranchServiceByIdType,
  ServerServiceBoolExp,
  ServerServiceBriefWithEmployeeType,
  ServerServiceBriefWithProductAndEmployeeType,
  ServerServiceBriefWithProductType,
  ServerServiceByIdType,
  ServerServiceType,
} from '../../generated/graphql-types'
import {
  anonymouslyServiceFragment,
  extendedServiceFragment,
  serviceBriefWithEmployeeFragment,
  serviceBriefWithProductAndEmployeeFragment,
  serviceBriefWithProductFragment,
  serviceByIdFragment,
  serviceFragment,
} from './service.fragments'
import { EMPLOYEES_QUERY_KEY } from '../employee/queryKeys'
import { LOCATIONS_QUERY_KEY } from '../location/queryKeys'
import { PRODUCTS_QUERY_KEY } from '../product/queryKeys'
import {
  ALL_SERVICES_QUERY_KEY,
  DEACTIVATED_SERVICES_QUERY_KEY,
  INTERBRANCH_SERVICES_QUERY_KEY,
  SERVICES_ANONYMOUSLY_QUERY_KEY,
  SERVICES_BRIEF_FOR_SALE_QUERY_KEY,
  SERVICES_BRIEF_QUERY_KEY,
  SERVICES_QUERY_KEY,
  SERVICES_WITHOUT_BRANCH_LOCATION,
} from './queryKeys'
import { SERVICE_TYPE, ServiceFetchType } from './logic'
import {
  extendedInterbranchServiceFragment,
  interbranchServiceByIdFragment,
} from './interbranchService.fragments'

// Незалежно від типу послуг в зв'язаних таблицях (*serviceEmployees,serviceLocations*) будуть повертатися
//  дані відфільтровані по *branchId*
export function useFetchServices(branchId: number | undefined | null, fetchType: ServiceFetchType) {
  return useQuery<ServerServiceType[] | undefined>(
    [SERVICES_QUERY_KEY, { branchId, fetchType }],
    async () => {
      const serviceBoolExp: ServerServiceBoolExp =
        fetchType === 'service'
          ? { branchId: { _eq: branchId } }
          : fetchType === 'interbranchService'
          ? { availableToAllBranches: { _eq: true } }
          : // all services
            {
              _or: [{ branchId: { _eq: branchId } }, { availableToAllBranches: { _eq: true } }],
            }

      serviceBoolExp.archived = { _is_null: true }

      const result = await request(
        gql`
          ${serviceFragment}
          query ($serviceBoolExp: service_bool_exp!, $branchId: Int!) {
            services(where: $serviceBoolExp) {
              ...serviceType
            }
          }
        `,
        { serviceBoolExp, branchId },
      )

      if (Array.isArray(result?.services)) {
        return result.services
      } else {
        reportError(new Error('services is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchServices',
      onError: reportGqlError,
      enabled: Boolean(branchId),
    },
  )
}

// Незалежно від типу послуг в зв'язаних таблицях (*serviceEmployees,serviceLocations*) будуть повертатися
//  дані відфільтровані по *branchId*
export function useFetchExtendedServices(
  branchId: number | undefined,
  fetchType: ServiceFetchType,
) {
  return useQuery<ServerExtendedServiceType[]>(
    [
      SERVICES_QUERY_KEY,
      EMPLOYEES_QUERY_KEY,
      LOCATIONS_QUERY_KEY,
      PRODUCTS_QUERY_KEY,
      SERVICES_WITHOUT_BRANCH_LOCATION,
      { branchId, fetchType },
      fetchType === 'all' ? ALL_SERVICES_QUERY_KEY : undefined,
      fetchType === 'interbranchService' ? INTERBRANCH_SERVICES_QUERY_KEY : undefined,
    ],

    async () => {
      const serviceBoolExp: ServerServiceBoolExp =
        fetchType === 'service'
          ? {
              branchId: { _eq: branchId },
            }
          : fetchType === 'interbranchService'
          ? { availableToAllBranches: { _eq: true } }
          : // all services
            {
              _or: [{ branchId: { _eq: branchId } }, { availableToAllBranches: { _eq: true } }],
            }

      serviceBoolExp.archived = { _is_null: true }
      serviceBoolExp.serviceLocations = {
        _and: [{ location: { _not: { defaultForBranch: {} } } }, { branchId: { _eq: branchId } }],
      }

      const result = await request(
        gql`
          ${extendedServiceFragment}
          query ($serviceBoolExp: service_bool_exp!, $branchId: Int!) {
            services(order_by: { createdAt: desc }, where: $serviceBoolExp) {
              ...extendedServiceType
            }
          }
        `,
        { branchId, serviceBoolExp },
      )

      if (Array.isArray(result?.services)) {
        return result.services.filter(
          service =>
            !service.serviceProducts.some(serviceProduct => serviceProduct.product === null),
        )
      } else {
        reportError(new Error('services is not an array'), 'warning', { result })
        return []
      }
    },
    { enabled: Boolean(branchId), queryName: 'useFetchExtendedServices', onError: reportGqlError },
  )
}

// Незалежно від типу послуг в зв'язаних таблицях (*serviceEmployees,serviceLocations*) будуть повертатися
//  дані відфільтровані по *branchId*
export function useFetchAllExtendedServices(
  branchId: number | undefined,
  fetchType: ServiceFetchType,
) {
  return useQuery<ServerExtendedServiceType[]>(
    [
      SERVICES_QUERY_KEY,
      EMPLOYEES_QUERY_KEY,
      LOCATIONS_QUERY_KEY,
      PRODUCTS_QUERY_KEY,
      { branchId, fetchType },
      fetchType === 'all' ? ALL_SERVICES_QUERY_KEY : undefined,
      fetchType === 'interbranchService' ? INTERBRANCH_SERVICES_QUERY_KEY : undefined,
    ],

    async () => {
      const serviceBoolExp: ServerServiceBoolExp =
        fetchType === 'service'
          ? {
              branchId: { _eq: branchId },
            }
          : fetchType === 'interbranchService'
          ? { availableToAllBranches: { _eq: true } }
          : // all services
            {
              _or: [{ branchId: { _eq: branchId } }, { availableToAllBranches: { _eq: true } }],
            }

      serviceBoolExp.archived = { _is_null: true }

      const result = await request(
        gql`
          ${extendedServiceFragment}
          query ($branchId: Int!, $serviceBoolExp: service_bool_exp!) {
            services(order_by: { createdAt: desc }, where: $serviceBoolExp) {
              ...extendedServiceType
            }
          }
        `,
        { branchId, serviceBoolExp },
      )

      if (Array.isArray(result?.services)) {
        return result.services
      } else {
        reportError(new Error('services is not an array'), 'warning', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchAllExtendedServices',
      onError: reportGqlError,
    },
  )
}

export function useFetchExtendedInterbranchServices() {
  return useQuery<ServerExtendedInterbranchServiceType[]>(
    [
      INTERBRANCH_SERVICES_QUERY_KEY,
      SERVICES_QUERY_KEY,
      EMPLOYEES_QUERY_KEY,
      LOCATIONS_QUERY_KEY,
      PRODUCTS_QUERY_KEY,
    ],

    async () => {
      const result = await request(
        gql`
          ${extendedInterbranchServiceFragment}
          query {
            services(
              order_by: { createdAt: desc }
              where: { availableToAllBranches: { _eq: true }, archived: { _is_null: true } }
            ) {
              ...extendedInterbranchServiceType
            }
          }
        `,
      )

      if (Array.isArray(result?.services)) {
        return result.services
      } else {
        reportError(new Error('services is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchExtendedInterbranchServices',
      onError: reportGqlError,
    },
  )
}

export function useFetchServicesBriefs(branchId: number | undefined) {
  return useQuery<ServerServiceBriefWithProductType[]>(
    [SERVICES_QUERY_KEY, SERVICES_BRIEF_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${serviceBriefWithProductFragment}
          query ($branchId: Int!) {
            services(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, archived: { _is_null: true } }
            ) {
              ...serviceBriefWithProductType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.services)) {
        return result.services
      } else {
        reportError(new Error('servicesBrief is not an array'), 'warning', { result })
        return []
      }
    },
    { queryName: 'useFetchServicesBriefs', onError: reportGqlError, enabled: Boolean(branchId) },
  )
}

/**
 * Потрібно для завантаження всіх сервісів (для посилань онлайн-записів)
 */
export function useFetchALlServicesForURLSBriefs() {
  return useQuery<ServerServiceBriefWithEmployeeType[]>(
    [SERVICES_QUERY_KEY, ALL_SERVICES_QUERY_KEY, SERVICES_BRIEF_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${serviceBriefWithEmployeeFragment}
          query {
            services(
              order_by: { createdAt: desc }
              where: { archived: { _is_null: true }, availableForClient: { _eq: true } }
            ) {
              ...serviceBriefWithEmployeeType
            }
          }
        `,
      )

      if (Array.isArray(result?.services)) {
        return result.services
      } else {
        reportError(new Error('allServicesBrief is not an array'), 'warning', { result })
        return []
      }
    },
    { queryName: 'useFetchALlServicesForClientsBriefs', onError: reportGqlError },
  )
}

export function useFetchServicesBriefsForSale(branchId: number | undefined) {
  return useQuery<ServerServiceBriefWithProductAndEmployeeType[]>(
    [SERVICES_QUERY_KEY, SERVICES_BRIEF_FOR_SALE_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${serviceBriefWithProductAndEmployeeFragment}
          query ($forSale: smallint!, $branchId: Int!) {
            services(
              order_by: { createdAt: desc }
              where: {
                archived: { _is_null: true }
                branchId: { _eq: $branchId }
                type: { _eq: $forSale }
              }
            ) {
              ...serviceBriefWithProductAndEmployeeType
            }
          }
        `,
        {
          forSale: SERVICE_TYPE.forSale,
          branchId,
        },
      )

      if (Array.isArray(result?.services)) {
        return result.services
      } else {
        reportError(new Error('servicesBrief is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchServicesBriefsForSale',
      onError: reportGqlError,
      enabled: Boolean(branchId),
    },
  )
}

export function useFetchServiceById(id: number | undefined, branchId: number | undefined) {
  return useQuery<ServerServiceByIdType>(
    [
      SERVICES_QUERY_KEY,
      EMPLOYEES_QUERY_KEY,
      LOCATIONS_QUERY_KEY,
      PRODUCTS_QUERY_KEY,
      id,
      { branchId },
    ],
    async () => {
      const result = await request(
        gql`
          ${serviceByIdFragment}
          query ($id: Int!, $branchId: Int!) {
            serviceById(id: $id) {
              ...serviceByIdType
            }
          }
        `,
        { id, branchId },
      )

      if (result?.serviceById) {
        return result.serviceById
      } else {
        reportError(new Error('serviceById does not exist'), 'warning', { id, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchServiceById',
      enabled: Boolean(id) && Boolean(branchId),
      onError: reportGqlError,
    },
  )
}

export function useFetchInterbranchServiceById(id: number | undefined) {
  return useQuery<ServerInterbranchServiceByIdType>(
    [
      INTERBRANCH_SERVICES_QUERY_KEY,
      SERVICES_QUERY_KEY,
      EMPLOYEES_QUERY_KEY,
      LOCATIONS_QUERY_KEY,
      PRODUCTS_QUERY_KEY,
      id,
    ],
    async () => {
      const result = await request(
        gql`
          ${interbranchServiceByIdFragment}
          query ($id: Int!) {
            serviceById(id: $id) {
              ...interbranchServiceByIdType
            }
          }
        `,
        { id },
      )

      if (result?.serviceById) {
        return result.serviceById
      } else {
        reportError(new Error('interbranchServiceById does not exist'), 'warning', { id, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchInterbranchServiceById',
      enabled: Boolean(id),
      onError: reportGqlError,
    },
  )
}

// Незалежно від типу послуг в зв'язаних таблицях (*serviceEmployees,serviceLocations*) будуть повертатися
//  дані відфільтровані по *branchId*
export function useFetchDeactivatedServices(
  branchId: number | undefined,
  fetchType: ServiceFetchType,
  enabled?: boolean,
) {
  return useQuery(
    [
      DEACTIVATED_SERVICES_QUERY_KEY,
      { branchId },
      fetchType === 'all' ? ALL_SERVICES_QUERY_KEY : undefined,
      fetchType === 'interbranchService' ? INTERBRANCH_SERVICES_QUERY_KEY : undefined,
    ],
    async (): Promise<ServerExtendedServiceType[]> => {
      const serviceBoolExp: ServerServiceBoolExp =
        fetchType === 'service'
          ? {
              branchId: { _eq: branchId },
            }
          : fetchType === 'interbranchService'
          ? { availableToAllBranches: { _eq: true } }
          : // all services
            {
              _or: [{ branchId: { _eq: branchId } }, { availableToAllBranches: { _eq: true } }],
            }

      serviceBoolExp.archived = { _is_null: false }

      const result = await request(
        gql`
          ${extendedServiceFragment}
          query ($branchId: Int!, $serviceBoolExp: service_bool_exp!) {
            services(order_by: { createdAt: desc }, where: $serviceBoolExp) {
              ...extendedServiceType
            }
          }
        `,
        { branchId, serviceBoolExp },
      )

      if (Array.isArray(result?.services)) {
        return result.services.map(service => service)
      }
      reportError(new Error('services is not an array'), 'warning', { result })
      return []
    },
    {
      queryName: 'useFetchDeactivatedServices',
      onError: reportGqlError,
      enabled: enabled && Boolean(branchId),
    },
  )
}

export function useFetchAnonymouslyServices(
  branchId: number | null | undefined,
  businessId: number | null | undefined,
) {
  return useQuery(
    [SERVICES_ANONYMOUSLY_QUERY_KEY, { branchId }],
    async (): Promise<ServerAnonymouslyServiceType[]> => {
      const result = await requestAnonymously<{ services: ServerAnonymouslyServiceType[] }>(
        gql`
          ${anonymouslyServiceFragment}
          query ($branchId: Int!, $businessId: Int!, $typeToExclude: smallint!) {
            services(
              where: {
                _or: [{ branchId: { _eq: $branchId } }, { availableToAllBranches: { _eq: true } }]
                business: { id: { _eq: $businessId } }
                type: { _neq: $typeToExclude }
              }
            ) {
              ...anonymouslyServiceType
            }
          }
        `,
        { branchId, businessId, typeToExclude: SERVICE_TYPE.forSale },
      )

      return result.services
    },
    {
      enabled: Boolean(branchId) && Boolean(businessId),
      queryName: 'useFetchAnonymouslyServices',
      onError: reportGqlError,
    },
  )
}

// используется в кабинете клиента и при анонимной онлайн записи, нужен так как нужны одинаковые поля для UI компонентов
export function useFetchAnonymouslyServicesNotAnonymous(
  branchId: number | null | undefined,
  businessId: number | null | undefined,
) {
  return useQuery(
    [SERVICES_ANONYMOUSLY_QUERY_KEY, { branchId }],
    async (): Promise<ServerAnonymouslyServiceType[]> => {
      const result = await request<{ services: ServerAnonymouslyServiceType[] }>(
        gql`
          ${anonymouslyServiceFragment}
          query ($branchId: Int!, $businessId: Int!, $typeToExclude: smallint!) {
            services(
              where: {
                _or: [{ branchId: { _eq: $branchId } }, { availableToAllBranches: { _eq: true } }]
                business: { id: { _eq: $businessId } }
                type: { _neq: $typeToExclude }
                archived: { _is_null: true }
              }
            ) {
              ...anonymouslyServiceType
            }
          }
        `,
        { branchId, businessId, typeToExclude: SERVICE_TYPE.forSale },
      )

      return result.services
    },
    {
      enabled: Boolean(branchId) && Boolean(businessId),
      queryName: 'useFetchAnonymouslyServices',
      onError: reportGqlError,
    },
  )
}
