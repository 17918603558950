import { MOVEMENT_TYPES, ServerAccountType } from '@expane/data'
import { useAccountsWithoutCheckbox } from '@expane/logic/payment/checkbox'
import { Input, SelectDropdown } from '@expane/ui'
import { TFunction } from 'i18next'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { MovementInfoDialogFormValues } from './'

export const PaymentBlock: FC<{
  movementType: number
  control: Control<MovementInfoDialogFormValues>
  accounts: ServerAccountType[] | undefined
  t: TFunction
  priceToPay: number
  accountBalance: number
}> = ({ movementType, control, accounts, t, priceToPay, accountBalance }) => {
  const { accountsWithoutCheckbox } = useAccountsWithoutCheckbox(accounts ?? [])

  return (
    <div className="flex justify-end">
      <Controller
        control={control}
        name="accountId"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <SelectDropdown
            className="w-52"
            label={movementType === MOVEMENT_TYPES.arrival.id ? t('fromAccount') : t('toAccount')}
            required
            items={accountsWithoutCheckbox}
            onSelectChange={onChange}
            value={value}
            errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
          />
        )}
      />

      <Controller
        control={control}
        name="amount"
        rules={{
          required: true,
          validate: value => Number(value) <= accountBalance,
        }}
        defaultValue={priceToPay.toString()}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            required
            value={value}
            onChange={onChange}
            containerClassName="w-36 ml-2"
            label={t('amount')}
            type="number"
            errorMessage={{ isShown: Boolean(error), text: t('formError.notEnoughFunds') }}
          />
        )}
      />
    </div>
  )
}
