import { useFetchSchedules } from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { Page } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { ScheduleNavigationButton } from 'pages/SchedulesPage/buttons'
import { SchedulesPageList } from 'pages/SchedulesPage/ScheduleTemplateSettings/List'
import { FC } from 'react'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'
import { ArchiveListNavigationButton } from 'widgets/NavigationButtons'
import { useOpenScheduleDialog } from 'widgets/ScheduleDialog'

export const ScheduleTemplates: FC = observer(() => {
  const branchId = store.branch.branchId

  const { data, isLoading } = useFetchSchedules()

  const { data: myPermission } = useFetchMyPermissions()
  const { openEditDialog, openCreateDialog, dialog } = useOpenScheduleDialog()

  const isEditingAllowed = myPermission?.includes(permissions.schedule.set)

  return (
    <Page>
      <div className="flex mb-2">
        {isEditingAllowed && (
          <SaveButton onClick={() => openCreateDialog()} className="self-start mr-2" isCreate />
        )}

        <ScheduleNavigationButton className="mr-2" />

        {isEditingAllowed && <ArchiveListNavigationButton className="ml-auto" />}
      </div>

      <SchedulesPageList
        onRowClick={openEditDialog}
        schedules={data}
        branchId={branchId}
        isLoading={isLoading}
      />

      {dialog}
    </Page>
  )
})
