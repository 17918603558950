import { reportGqlError, request, useMutation } from '../../api'
import { gql } from 'graphql-request'
import { ExtendBillingDTO, ExtendBillingResult } from './logic'

export function useExtendBilling() {
  return useMutation(
    async (dto: ExtendBillingDTO) => {
      return await request<{ extendBilling: ExtendBillingResult }>(
        gql`
          mutation ($period: Period!, $planId: Int!) {
            extendBilling(period: $period, planId: $planId) {
              code
              message
              planId
              paidMonths
              value
              billingId
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
    },
  )
}
