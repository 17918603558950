import {
  BranchWithSchedule,
  useFetchLocationGroups,
  useFetchLocations,
  useImportLocations,
} from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { CloseButton, Dialog, Modal, Paper, SelectDropdown, Spinner, Switch } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useBranchImportState } from 'logic/hooks/useBranchImportState'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TreeMenu } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { onChangeMultiTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { ImportButton } from 'widgets/Buttons'
import { LocationUnionTreeMenuItem, transformItemForMutation } from './logic'

interface ImportLocationsDialogLogicProps extends Pick<DialogProps, 'closeDialog'> {
  branches: BranchWithSchedule[]
}

export const BranchImportLocationsDialogLogic: FC<
  PropsWithBranchId<ImportLocationsDialogLogicProps>
> = ({ closeDialog, branches, branchId }) => {
  const { t } = useTranslation()

  const { selectedBranchId, setSelectedBranchId, branchesWithoutCurrent } =
    useBranchImportState(branches)

  const { data: locationGroups, isFetching: isFetchingLocationGroups } =
    useFetchLocationGroups(selectedBranchId)
  const { data: locations, isFetching: isFetchingLocations } = useFetchLocations(selectedBranchId)
  const isFetchingTreeItems = isFetchingLocationGroups || isFetchingLocations

  const { mutateAsync: importLocations } = useImportLocations()

  const [openSnackbar] = useSnackbar()

  const areThereGroups = Boolean(locationGroups?.length)

  const [ignoreGroups, setIgnoreGroups] = useState<boolean>(() => !areThereGroups)
  const [selectedItems, setSelectedItems] = useState<LocationUnionTreeMenuItem[]>([])

  const locationTreeItems: LocationUnionTreeMenuItem[] = useMemo(
    () =>
      transformDataForTreeMenu(ignoreGroups ? [] : locationGroups, locations, {
        keepEmptyFolders: true,
      }),
    [ignoreGroups, locations, locationGroups],
  )

  const mutateImportLocations = async () => {
    const result = await importLocations(transformItemForMutation(selectedItems, branchId))

    if (result.insertLocations.affectedRows || result.insertMultipleLocationGroups.affectedRows)
      openSnackbar(t('importLocations.importingSuccess'), 'success', 3000)
    else openSnackbar(t('submitError'), 'error', 3000)

    closeDialog()
  }

  return (
    <Modal close={closeDialog} animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>{t('importLocations.name')}</Dialog.Title>
        <Dialog.Body className="flex flex-col h-120">
          {branchesWithoutCurrent.length > 1 ? (
            <SelectDropdown
              items={branchesWithoutCurrent}
              value={selectedBranchId}
              onSelectChange={value => {
                setSelectedItems([])
                setSelectedBranchId(value)
              }}
              label={t('branch.name')}
              className="mb-2"
            />
          ) : null}
          {areThereGroups && (
            <Switch
              containerClassName="pb-4"
              onChange={isChecked => {
                setIgnoreGroups(isChecked)
                setSelectedItems([])
                if (isChecked)
                  openSnackbar(t('importLocations.ignoreLocationGroupsWarning'), 'warning', 5000)
              }}
              checked={ignoreGroups}
              label={t('importLocations.ignoreLocationGroups')}
            />
          )}

          <Paper className={'py-4 px-2 overflow-y-scroll flex-1'}>
            {isFetchingTreeItems ? (
              <Spinner expandCentered />
            ) : (
              <TreeMenu
                items={locationTreeItems}
                type="MultiPickMode"
                selected={selectedItems}
                onSelected={item =>
                  setSelectedItems(onChangeMultiTreeMenu(item, selectedItems, locationTreeItems))
                }
                openSwitch={!ignoreGroups}
              />
            )}
          </Paper>
        </Dialog.Body>
        <Dialog.Footer>
          <ImportButton onClick={mutateImportLocations} disabled={selectedItems.length === 0} />

          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
