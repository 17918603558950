import {
  useFetchAllEmployeesForImport,
  useFetchEmployeesBriefs,
  useFetchEmployeesGroupsBriefs,
} from '@expane/data'
import { usePopupOpenState } from '@expane/ui'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { store } from 'store'
import { BranchImportEmployeesDialogLogic } from 'widgets/BranchImportEmployeesDialog/ImportEmployeesDialogLogic'
import { BranchImportEmployeesDialogPlaceholder } from 'widgets/BranchImportEmployeesDialog/ImportEmployeesDialogPlaceholder'

const BranchImportEmployeesDialog: FC<Pick<DialogProps, 'closeDialog'>> = observer(
  ({ closeDialog }) => {
    const branchId = store.branch.branchId

    const { data: allEmployees, isLoading: isLoadingAllEmployees } =
      useFetchAllEmployeesForImport(branchId)
    const { data: employees, isLoading: isLoadingEmployees } = useFetchEmployeesBriefs(branchId)
    const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } =
      useFetchEmployeesGroupsBriefs()

    const isLoading = isLoadingEmployees || isLoadingAllEmployees || isLoadingEmployeeGroups
    const isNoData = !employees || !allEmployees || !employeeGroups

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

    return (
      <>
        {isLoading ? (
          <BranchImportEmployeesDialogPlaceholder closeDialog={closeDialog} />
        ) : (
          <BranchImportEmployeesDialogLogic
            employees={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              employees!
            }
            allEmployees={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              allEmployees!
            }
            employeeGroups={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              employeeGroups!
            }
            closeDialog={closeDialog}
          />
        )}
      </>
    )
  },
)

export const useOpenBranchImportEmployeesDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const openBranchImportEmployeesDialog = () => {
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
  }

  const branchImportEmployeesDialog = isOpen ? (
    <BranchImportEmployeesDialog closeDialog={closeDialog} />
  ) : null

  return { openBranchImportEmployeesDialog, branchImportEmployeesDialog }
}
