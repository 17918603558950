import { permissions } from '@expane/logic/permission'
import { Button } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IoBusinessOutline,
  IoCubeOutline,
  IoPersonAddOutline,
  IoReaderOutline,
} from 'react-icons/io5'
import { TruckIcon } from 'ui/Icons'
import { useOpenBranchImportEmployeesDialog } from 'widgets/BranchImportEmployeesDialog'
import { useOpenBranchImportLocationsDialog } from 'widgets/BranchImportLocationsDialog'
import { useOpenImportProductsDialog } from 'widgets/BranchImportProductsDialog'
import { useBranchImportServiceDialog } from 'widgets/BranchImportServiceDialog'
import { useOpenBranchImportSuppliersDialog } from 'widgets/BranchImportSuppliersDialog'
import { SettingsWrapper } from '..'

export const BranchImportSettings: FC = () => {
  const { openBranchImportEmployeesDialog, branchImportEmployeesDialog } =
    useOpenBranchImportEmployeesDialog()
  const { openBranchImportLocationsDialog, branchImportLocationsDialog } =
    useOpenBranchImportLocationsDialog()
  const { branchImportProductsDialog, openBranchImportProductsDialog } =
    useOpenImportProductsDialog()
  const { branchImportServiceDialog, openBranchImportServiceDialog } =
    useBranchImportServiceDialog()
  const { branchImportSuppliersDialog, openBranchImportSuppliersDialog } =
    useOpenBranchImportSuppliersDialog()

  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()

  const isImportEmployeesAllowed =
    myPermissions?.includes(permissions.schedule.set) &&
    myPermissions?.includes(permissions.employee.get)

  const isImportLocationsAllowed =
    myPermissions?.includes(permissions.location.set) &&
    myPermissions?.includes(permissions.location.get)

  const isImportProductsAllowed = myPermissions?.includes(permissions.product.set)

  const isImportServicesAllowed = myPermissions?.includes(permissions.service.set)

  const isSuppliersEditAllowed =
    myPermissions?.includes(permissions.supplier.get) &&
    myPermissions?.includes(permissions.supplier.set)

  return (
    <SettingsWrapper>
      <div className="flex flex-col gap-2 mb-1">
        {isImportLocationsAllowed && (
          <Button
            onClick={openBranchImportLocationsDialog}
            className={buttonStyle}
            type="outline"
            Icon={IoBusinessOutline}
          >
            {t('locations.name')}
          </Button>
        )}
        {isImportEmployeesAllowed && (
          <Button
            onClick={openBranchImportEmployeesDialog}
            className={buttonStyle}
            type="outline"
            Icon={IoPersonAddOutline}
          >
            {t('employees.name')}
          </Button>
        )}
        {isImportProductsAllowed && (
          <Button
            type="outline"
            onClick={openBranchImportProductsDialog}
            className={buttonStyle}
            Icon={IoCubeOutline}
          >
            {t('products')}
          </Button>
        )}
        {isImportServicesAllowed && (
          <Button
            type="outline"
            onClick={openBranchImportServiceDialog}
            className={buttonStyle}
            Icon={IoReaderOutline}
          >
            {t('services')}
          </Button>
        )}
        {isSuppliersEditAllowed && (
          <Button
            type="outline"
            onClick={openBranchImportSuppliersDialog}
            className={buttonStyle}
            Icon={TruckIcon}
          >
            {t('suppliers')}
          </Button>
        )}
      </div>

      {branchImportProductsDialog}
      {branchImportServiceDialog}
      {branchImportEmployeesDialog}
      {branchImportLocationsDialog}
      {branchImportSuppliersDialog}
    </SettingsWrapper>
  )
}

const buttonStyle = 'w-48'
