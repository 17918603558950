import { TreeMenuItem } from 'ui/TreeMenu'
import { TFunction } from 'react-i18next'

export const findTreeMenuItem = <T extends TreeMenuItem>(
  id: number,
  isFolder: boolean,
  allItems: T[],
): T | undefined => {
  const item = allItems.find(item => item.id === id && Boolean(isFolder) === Boolean(item.isFolder))

  if (item !== undefined) return { ...item }

  for (const itemFromAll of allItems) {
    if (itemFromAll.nodes) {
      const item = findTreeMenuItem(id, isFolder, itemFromAll.nodes)
      if (item !== undefined) return { ...item }
    }
  }

  return undefined
}

export const removeEmptyFolders = (items: TreeMenuItem[]): TreeMenuItem[] => {
  if (items.length === 0) return []

  const result: TreeMenuItem[] = []

  for (const item of items) {
    if (item.isFolder) {
      const checkedNodes = item.nodes ? removeEmptyFolders(item.nodes) : []
      if (checkedNodes.length > 0) result.push({ ...item, nodes: checkedNodes })
    } else {
      result.push({ ...item })
    }
  }

  return result
}

const transformGroupsToFolders = (
  groups: { id: number; name: string; parentId?: number | null }[],
  items: { id: number; name: string; groupId?: number | null }[],
): TreeMenuItem[] => {
  return groups.map(group => {
    const childrenOfThisGroup = items.filter(item => item.groupId === group.id)

    return {
      ...group,
      parentId: group.parentId ?? undefined,
      isFolder: true,
      nodes: childrenOfThisGroup.map(child => ({ ...child, parentId: child.groupId ?? undefined })),
    }
  })
}

const distributeTreeFolders = (items: TreeMenuItem[]): TreeMenuItem[] => {
  for (const item of items) {
    const parent = items.find(parent => parent.id === item.parentId)
    if (parent !== undefined) {
      const itemsWithoutParentAndChild = items.filter(
        possiblyItem => possiblyItem.id !== item.id && possiblyItem.id !== parent.id,
      )
      const newItems = parent.nodes ? [...parent.nodes, { ...item }] : [{ ...item }]
      return distributeTreeFolders([
        ...itemsWithoutParentAndChild,
        {
          ...parent,
          nodes: newItems,
        },
      ])
    }
  }
  return items
}

export const transformDataForTreeMenu = <T extends TreeMenuItem>(
  groups: { id: number; name: string }[] | undefined,
  items: { id: number; name: string; groupId?: number | null }[] | undefined,
  options?: { keepEmptyFolders?: boolean },
): T[] => {
  // items without groupId and items that have a groupId but no group in groups list
  const groupsIds = groups?.map(({ id }) => id)
  const ungroupedItems: TreeMenuItem[] =
    items?.filter(item => !item.groupId || !groupsIds?.includes(item.groupId)) ?? []

  const foldersWithNodes = groups && items ? transformGroupsToFolders(groups, items) : []
  const distributedTreeFolders = distributeTreeFolders(foldersWithNodes)

  const folders = options?.keepEmptyFolders
    ? distributedTreeFolders
    : removeEmptyFolders(distributedTreeFolders)

  return [...folders, ...ungroupedItems] as T[]
}

export const extractItemsFromFolders = <T>(
  items: TreeMenuItem[],
): Array<T extends TreeMenuItem ? T : TreeMenuItem> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = []
  for (const item of items) {
    if (item.isFolder) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const children = extractItemsFromFolders(item.nodes!)
      result = [...result, ...children]
    } else {
      result.push(item)
    }
  }
  return result
}

export const groupInitialTreeItems = <T extends TreeMenuItem>(
  initialItems: Array<T>,
  allTreeItems: Array<T>,
): Array<T> => {
  for (const initialItem of initialItems) {
    if (!initialItem.parentId) continue
    const parent = findTreeMenuItem(initialItem.parentId, true, allTreeItems)
    if (!parent) continue

    const childrenCount = initialItems.reduce(
      (count, service) => (service.parentId === initialItem.parentId ? count + 1 : count),
      0,
    )
    if (parent.nodes?.length === childrenCount)
      return groupInitialTreeItems(
        [...initialItems.filter(i => i.parentId !== parent.id), parent] as T[],
        allTreeItems,
      )
  }

  return initialItems
}

export const removeAllItemsExceptDisabled = <T extends TreeMenuItem>(items: T[]) => {
  const result: T[] = []

  for (const item of items) {
    if (item.isFolder && item.nodes) {
      const onlyDisabledChildren = removeAllItemsExceptDisabled(item.nodes)

      // if all children still in value - keep whole parent
      if (item.nodes.length === onlyDisabledChildren.length) result.push(item)
      else result.push(...removeAllItemsExceptDisabled(item.nodes))
    }

    if (item.disabled) result.push(item)
  }

  return result
}

export const translateTreeMenuItems = <T extends TreeMenuItem>(items: T[], t: TFunction): T[] => {
  const translatedItems: T[] = []
  for (const item of items) {
    if (item.isFolder) {
      translatedItems.push({
        ...item,
        name: t(item.name),
        ...(item.nodes ? { nodes: translateTreeMenuItems(item.nodes, t) } : undefined),
      })
    } else {
      translatedItems.push({ ...item, name: t(item.name) })
    }
  }

  return translatedItems
}

export const sortTreeMenuItemsByAlphabet = <T extends TreeMenuItem>(items: T[]): T[] => {
  items.sort((a, b) => {
    // Проверяем, являются ли элементы папками
    const isFolderA = a.isFolder ? 1 : 0
    const isFolderB = b.isFolder ? 1 : 0

    // Если оба элемента являются папками или не являются папками, сравниваем их имена
    if (isFolderA === isFolderB) {
      return a.name.localeCompare(b.name)
    }

    // Папки должны идти перед не-папками
    return isFolderB - isFolderA
  })

  // Применяем сортировку рекурсивно к вложенным элементам
  items.forEach(item => {
    if (item.nodes && item.nodes.length > 0) {
      sortTreeMenuItemsByAlphabet(item.nodes)
    }
  })

  return items
}
