import { FC } from 'react'
import { useFetchLeads, useMutateLeads } from '@expane/data'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { SimpleEntityPlaceholder } from 'widgets/SimpleEntitiesDialog/SimpleEntityPlaceholder'
import { useTranslation } from 'react-i18next'
import { SimpleEntitiesDialogLogic } from 'widgets/SimpleEntitiesDialog'
import { SimpleEntitiesMutateFunc } from 'widgets/SimpleEntitiesDialog/logic'
import { useSnackbar } from '@expane/widgets'

export const LeadsDialog: FC<DialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  const { data: leads, isLoading } = useFetchLeads()
  const { mutateAsync: mutateLeads } = useMutateLeads()

  useErrorOpeningDialog(!isLoading && !leads, closeDialog)

  const [openSnackbar] = useSnackbar()

  const mutateFunc: SimpleEntitiesMutateFunc = async insertInput => {
    const result = await mutateLeads(insertInput)
    if (result.insertLeads.affectedRows) {
      openSnackbar(t('leads.updateSuccess'), 'success', 3000)
    } else {
      openSnackbar(t('submitError'), 'error', 3000)
    }
  }

  return isLoading ? (
    <SimpleEntityPlaceholder closeDialog={closeDialog} title={t('leads.name')} />
  ) : (
    <SimpleEntitiesDialogLogic
      closeDialog={closeDialog}
      items={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        leads!
      }
      title={t('leads.name')}
      mutateFunc={mutateFunc}
    />
  )
}
