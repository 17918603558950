export const MODULES_STORAGE_KEY = 'create-business/modules'

export const MAIN_INFO_STORAGE_KEY = 'create-business/mainInfo'

export const CREATE_BUSINESS_KEY = 'createBusiness'

export const stepStyle = 'p-4 max-h-full overflow-auto'

export const labelStyle = 'font-medium text-primary-700 text-left mb-2'

export type PageDataType = 'page' | 'createBusiness'
