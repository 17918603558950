import { EmployeeGroupWithSchedule } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { CountedSalariesFullInfoType } from '@expane/logic/salaryIssues/logic'
import { TableBody, TableContainer, TableHeader, TableHeaderCell } from '@expane/ui'
import { DatePickerType } from 'logic/hooks/useCustomDatePicker'
import {
  sumTotalAccruedSalary,
  sumTotalDebtSalary,
  sumTotalPaidSalary,
} from 'logic/salaryIssues/helpers'
import { SalaryEmployeeListItem, SalaryEmployeeListItemLoading } from 'pages/SalaryPage/ListItem'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

interface SalaryListProps {
  countedSalariesFullInfo: Array<CountedSalariesFullInfoType>
  employeeGroups: EmployeeGroupWithSchedule[]
  fromDate: Date
  toDate: Date
  isLoading: boolean
  datePickerType: DatePickerType
}

export const SalaryList: FC<PropsWithBranchId<SalaryListProps>> = ({
  countedSalariesFullInfo,
  employeeGroups,
  fromDate,
  toDate,
  isLoading,
  datePickerType,
  branchId,
}) => {
  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  return (
    <TableContainer className="w-10/12">
      <TableHeader>
        <tr>
          <TableHeaderCell className="w-2/5">{t('employee.name')}</TableHeaderCell>
          <TableHeaderCell className="w-1/5 text-right">{t('salary.debt')}</TableHeaderCell>
          <TableHeaderCell className="w-1/5 text-right">{t('salary.accrued')}</TableHeaderCell>
          <TableHeaderCell className="w-1/5 text-right">{t('salary.paid')}</TableHeaderCell>
          <TableHeaderCell className="w-1/5 text-right">{t('salary.toPay')}</TableHeaderCell>
        </tr>
      </TableHeader>

      <TableBody>
        {isLoading ? (
          <>
            <SalaryEmployeeListItemLoading />
            <SalaryEmployeeListItemLoading />
            <SalaryEmployeeListItemLoading />
          </>
        ) : (
          employeeGroups.map(group => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const groupSalaries = countedSalariesFullInfo.find(
              salary => salary.groupId === group.id,
            )!

            const paidSalary = sumTotalPaidSalary(groupSalaries?.salaries)
            const debtSalary = sumTotalDebtSalary(groupSalaries?.salaries)
            const accruedSalary = sumTotalAccruedSalary(groupSalaries?.salaries)

            const totalSalaryToPay = accruedSalary - paidSalary

            return group.employees.length > 0 ? (
              <Fragment key={group.id}>
                <tr className="bg-tr-accent cursor-pointer">
                  <td className="pl-4 text-primary-500 text-sm font-medium w-2/5">{group.name}</td>
                  <td className={rowHeaderStyle}>{convertNumberToMoney(debtSalary)}</td>
                  <td className={rowHeaderStyle}>{convertNumberToMoney(accruedSalary)}</td>
                  <td className={rowHeaderStyle}>{convertNumberToMoney(paidSalary)}</td>
                  <td className={rowHeaderStyle + ' pr-4'}>
                    {convertNumberToMoney(totalSalaryToPay)}
                  </td>
                </tr>

                {group.employees.map(employee => (
                  <SalaryEmployeeListItem
                    key={employee.id}
                    employee={employee}
                    fromDate={fromDate}
                    toDate={toDate}
                    countedSalariesFullInfo={countedSalariesFullInfo}
                    datePickerType={datePickerType}
                    branchId={branchId}
                  />
                ))}
              </Fragment>
            ) : null
          })
        )}
      </TableBody>
    </TableContainer>
  )
}

const rowHeaderStyle = 'font-medium pr-1 py-1 text-right text-primary-500 text-sm w-1/5'
