import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { SpinnerSmall } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const OnClientAccount: FC<
  PropsWithBranchId<{ clientBalance: number | undefined; isFetching: boolean }>
> = ({ clientBalance, isFetching, branchId }) => {
  const { t } = useTranslation()

  const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

  return (
    <div className="text-xs leading-3 font-medium text-gray-400 flex items-center">
      {`${t('onAccount')}:`}
      {isFetching ? (
        <SpinnerSmall />
      ) : (
        <span className="text-sm text-main-color ml-2">
          {convertToMoneyCode(clientBalance ?? 0)}
        </span>
      )}
    </div>
  )
}
