import { gql } from 'graphql-request'
import { queryClient, request, useMutation, useQuery, reportError, reportGqlError } from '../../api'
import {
  ServerRevenueReasonType,
  ServerRevenueReasonInsertInput,
} from '../../generated/graphql-types'
import { REVENUE_REASONS_QUERY_KEY } from './queryKeys'
import { revenueReasonFragment } from './revenueReason.fragments'

export function useFetchRevenueReasons(branchId: number | undefined) {
  return useQuery<ServerRevenueReasonType[]>(
    [REVENUE_REASONS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${revenueReasonFragment}
          query ($branchId: Int!) {
            revenueReasons(order_by: { id: desc }, where: { branchId: { _eq: $branchId } }) {
              ...revenueReasonType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.revenueReasons)) {
        return result.revenueReasons
      } else {
        reportError(new Error('revenueReasons is not an array'), 'warning', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchRevenueReasons',
      onError: reportGqlError,
      onSuccess: revenueReasons => {
        revenueReasons.forEach(rR =>
          queryClient.setQueryData([REVENUE_REASONS_QUERY_KEY, rR.id], rR),
        )
      },
    },
  )
}

export function useCreateRevenueReason() {
  return useMutation(
    async (
      revenueReasonInsertInput: ServerRevenueReasonInsertInput &
        Required<Pick<ServerRevenueReasonInsertInput, 'branchId'>>,
    ): Promise<{ insertRevenueReason: { id: number; name: string } }> => {
      return request(
        gql`
          mutation ($revenueReasonInsertInput: revenueReason_insert_input!) {
            insertRevenueReason(object: $revenueReasonInsertInput) {
              id
              name
            }
          }
        `,
        { revenueReasonInsertInput },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([REVENUE_REASONS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useMutateRevenueReasons() {
  return useMutation(
    (
      revenueReasonInsertInput: ServerRevenueReasonInsertInput[],
    ): Promise<{ insertRevenueReasons: { affectedRows: number } }> => {
      return request(
        gql`
          mutation ($revenueReasonInsertInput: [revenueReason_insert_input!]!) {
            insertRevenueReasons(
              objects: $revenueReasonInsertInput
              on_conflict: { constraint: revenueReason_pkey, update_columns: [name] }
            ) {
              affectedRows: affected_rows
            }
          }
        `,
        { revenueReasonInsertInput },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([REVENUE_REASONS_QUERY_KEY]),
    },
  )
}
