import {
  ServerClientDocumentType,
  useCreateClientDocument,
  useFetchClientDocumentsById,
  useFetchCurrentBranchTimezone,
  useFetchExtendedEmployees,
  useUpdateClientDocument,
} from '@expane/data'
import { useUpdateFile } from '@expane/logic/file'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import {
  CloseButton,
  Dialog,
  Input,
  Modal,
  PlaceholderDialog,
  PlaceholderInput,
  SelectDropdown,
  SelectDropDownItem,
  usePopupOpenState,
} from '@expane/ui'
import { DateTimePicker, EditableFile, useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps, useOpenDialog } from 'logic/hooks/useOpenDialog'
import { transformPersonsForSelect, translateData } from 'logic/utils'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'
import { EmployeeDialog } from 'widgets/EmployeeDialog'

interface ClientDocumentDialogProps extends DialogProps {
  clientId: number
}

// TODO: Loading state
export const ClientDocumentDialog: FC<ClientDocumentDialogProps> = ({
  id,
  isCreate,
  closeDialog,
  clientId,
}) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clientDocumentById, isLoading: isLoadingClientDocument } =
    useFetchClientDocumentsById(id, timezone)
  const { getModuleSetting, isLoadingBusinessModulesSettings } = useBusinessModulesSettings()
  const isLoading =
    (isLoadingClientDocument && !isCreate) || isLoadingBusinessModulesSettings || !timezone

  if (isLoading) return <ClientDialogDocumentPlaceholder closeDialog={closeDialog} />

  return (
    <ClientDocumentDialogLogic
      clientDocument={clientDocumentById}
      timezone={timezone}
      isCreate={isCreate}
      closeDialog={closeDialog}
      clientId={clientId}
      showManagingEmployee={getModuleSetting('managingEmployee')}
    />
  )
}

const ClientDialogDocumentPlaceholder: FC<{ closeDialog: () => void }> = ({ closeDialog }) => {
  const { t } = useTranslation()
  return (
    <PlaceholderDialog title={t('document.name')} className="w-148" closeDialog={closeDialog}>
      <div className="flex">
        <div className="grow mr-3">
          <PlaceholderInput label={t('name')} />

          <PlaceholderInput label={t('documentType')} />

          <div className="flex">
            <PlaceholderInput label={t('document.issueDate')} className="w-1/2 mr-2 mt-4" />
            <PlaceholderInput label={t('document.endDate')} className="w-1/2 mt-4" />
          </div>
        </div>
        <EditableFile disabled onChange={undefined} />
      </div>
    </PlaceholderDialog>
  )
}

interface ClientDocumentDialogLogicProps {
  clientDocument: ServerClientDocumentType | undefined
  isCreate: boolean
  closeDialog: () => void
  clientId: number
  showManagingEmployee: boolean
  timezone: string
}

interface UseFormValues {
  name: string
  type: number
  startDate: Date
  endDate: Date
  fileUrl?: string
  managingEmployeeId?: number
}

export const ClientDocumentDialogLogic: FC<ClientDocumentDialogLogicProps> = ({
  clientDocument,
  closeDialog,
  isCreate,
  clientId,
  showManagingEmployee,
  timezone,
}) => {
  const branchId = store.branch.branchId

  const { control, formState, handleSubmit, setValue } = useForm<UseFormValues>({
    defaultValues: {
      name: clientDocument?.name ?? '',
      type: clientDocument?.type,
      startDate: clientDocument?.startDate ?? undefined,
      endDate: clientDocument?.endDate ?? undefined,
      fileUrl: clientDocument?.fileUrl ?? '',
      managingEmployeeId: clientDocument?.managingEmployeeId ?? undefined,
    },
  })

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

  const { mutateAsync: createClientDocumentMutation } = useCreateClientDocument()
  const { mutateAsync: updateClientDocumentMutation } = useUpdateClientDocument()
  const updateDocument = useUpdateFile()

  const { data: employees } = useFetchExtendedEmployees(timezone, branchId)

  const [openSnackbar] = useSnackbar()

  const { t } = useTranslation()

  const { openEditDialog: openEmployeeDialog, dialog: employeeDialog } =
    useOpenDialog(EmployeeDialog)

  const mutateClientDocument: SubmitHandler<UseFormValues> = async ({
    name,
    type,
    startDate,
    endDate,
    fileUrl,
    managingEmployeeId,
  }) => {
    const url = await updateDocument({
      prevFile: clientDocument?.fileUrl ?? undefined,
      file: fileUrl,
      isDocument: true,
    })
    if (isCreate) {
      const result = await createClientDocumentMutation({
        clientId,
        name,
        type,
        startDate,
        endDate,
        managingEmployeeId: managingEmployeeId ?? null,
        fileUrl: url ?? null,
      })
      if (result?.insertClientDocument?.id)
        openSnackbar(t('document.createSuccess'), 'success', 5000)
      else openSnackbar(t('submitError'), 'error', 5000)
    } else if (clientDocument) {
      const result = await updateClientDocumentMutation({
        id: clientDocument.id,
        clientDocumentSetInput: {
          name,
          type,
          startDate,
          endDate,
          fileUrl: url ?? null,
          managingEmployeeId: managingEmployeeId ?? null,
        },
      })

      if (result?.updateClientDocumentById?.id)
        openSnackbar(t('document.updateSuccess'), 'success', 5000)
      else openSnackbar(t('submitError'), 'error', 5000)
    }

    closeDialog()
  }

  const watchedName = useWatch({ control, name: 'name' })

  const onChangeFileName = (name: string) => {
    if (!watchedName) {
      setValue('name', name)
    }
  }

  return (
    <Modal close={closePopups}>
      <Dialog>
        <Dialog.Title>{t('document.name')}</Dialog.Title>

        <Dialog.Body className="w-148">
          <div className="flex">
            <div className="grow mr-3">
              <Controller
                control={control}
                name="name"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    label={t('name')}
                    value={value}
                    onChange={onChange}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.name),
                      text: t('formError.required'),
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="type"
                rules={{ required: true }}
                defaultValue={CLIENT_DOCUMENTS_TYPES[0].id}
                render={({ field: { value, onChange } }) => (
                  <SelectDropdown
                    label={t('documentType')}
                    items={translateData(CLIENT_DOCUMENTS_TYPES, t)}
                    value={value}
                    onSelectChange={onChange}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.type),
                      text: t('formError.required'),
                    }}
                  />
                )}
              />

              {showManagingEmployee && (
                <Controller
                  control={control}
                  name="managingEmployeeId"
                  render={({ field: { value, onChange } }) => (
                    <SelectDropdown
                      label={t('referral')}
                      value={value}
                      items={employees ? transformPersonsForSelect(employees) : []}
                      placeholder={t('placeholders.defaultSelect')}
                      noDataMessage={t('noSuchEmployee')}
                      onSelectChange={onChange}
                      onEditClick={id => openEmployeeDialog(id)}
                      isClearable
                    />
                  )}
                />
              )}

              <div className="flex">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { value, onChange } }) => (
                    <DateTimePicker
                      timezone={timezone}
                      label={t('document.issueDate')}
                      type="date"
                      className="w-1/2 mr-2 mt-4"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="endDate"
                  rules={{ required: true }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <DateTimePicker
                      timezone={timezone}
                      label={t('document.endDate')}
                      type="date"
                      className="w-1/2 mt-4"
                      value={value}
                      onChange={onChange}
                      errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                      required
                    />
                  )}
                />
              </div>
            </div>

            <Controller
              control={control}
              name="fileUrl"
              render={({ field: { value, onChange } }) => (
                <EditableFile
                  onChange={onChange}
                  defaultFile={value}
                  defaultName={watchedName}
                  onChangeFileName={onChangeFileName}
                  accept={'.jpg,.png,.jpeg,.pdf,.xlsx,.docx,xls,.doc'}
                />
              )}
            />
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <SaveButton
            onClick={handleSubmit(mutateClientDocument)}
            disabled={formState.isSubmitting}
            spinner={formState.isSubmitting}
            isCreate={isCreate}
          />
          <CloseButton onClick={closePopups} />
        </Dialog.Footer>
      </Dialog>
      {confirmPopup}
      {employeeDialog}
    </Modal>
  )
}

export const CLIENT_DOCUMENTS_TYPES: SelectDropDownItem[] = [
  { id: 1, name: 'documentTypes.certificate' },
]
export const getNameOfDocumentType = (type: number, t: TFunction) => {
  const item = CLIENT_DOCUMENTS_TYPES.find(item => item.id === type)
  if (!item) throw new Error(`Document type ${type} was not found`)

  return t(item.name)
}

export const useOpenClientDocumentDialog = (clientId: number) => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogId = useRef<number | undefined>()
  const dialogIsCreate = useRef<boolean>(false)
  const dialogOnCreate = useRef<(id: number) => void>()

  const openEditClientDocumentDialog = (id: number) => {
    dialogId.current = id
    dialogIsCreate.current = false
    openPopup()
  }

  const openCreateClientDocumentDialog = () => {
    dialogId.current = undefined
    dialogIsCreate.current = true
    openPopup()
  }

  const clientDocumentDialog = isOpen ? (
    <ClientDocumentDialog
      closeDialog={closePopup}
      id={dialogId.current}
      clientId={clientId}
      isCreate={dialogIsCreate.current}
      onCreate={dialogOnCreate.current}
    />
  ) : null

  return { openEditClientDocumentDialog, openCreateClientDocumentDialog, clientDocumentDialog }
}
