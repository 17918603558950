import {
  addDays,
  addMonths,
  createCurrentDate,
  getDate,
  getMonth,
  getYear,
  isSameMonth,
  set,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
} from '@expane/date'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CalendarView, PickerType } from './DatePicker'

interface QuickDateButtonsProps {
  type: PickerType
  view: CalendarView
  setView: (view: CalendarView) => void
  value: Date | undefined
  onChange: (date: Date) => void
  activeStartDate: Date
  setActiveStartDate: (date: Date) => void
  closePopup?: () => void
  className?: string
  disabled?: boolean
  timezone: string
}

export const QuickDateButtons: FC<QuickDateButtonsProps> = ({
  closePopup,
  onChange,
  type,
  view,
  setView,
  value,
  activeStartDate,
  setActiveStartDate,
  className,
  disabled,
  timezone,
}) => {
  const setDate = (date: Date) => {
    closePopup?.()

    let newValue = date
    if (type === 'week') newValue = startOfWeek(date, { weekStartsOn: 1 })
    if (type === 'month') newValue = startOfMonth(date)

    onChange(
      set(value ?? createCurrentDate(timezone), {
        date: getDate(newValue),
        month: getMonth(newValue),
        year: getYear(newValue),
      }),
    )
    if (!isSameMonth(date, activeStartDate)) setActiveStartDate(startOfMonth(date))
    if (view === 'year') setView('month')
  }

  const containerClassName =
    CONTAINER_CLASS_NAME +
    (disabled
      ? ' divide-datepicker-btn-disabled border-datepicker-btn-disabled'
      : ' divide-datepicker-btn border-datepicker-btn')
  const buttonClassName =
    BUTTON_CLASS_NAME + (disabled ? ' text-gray-300' : ' bg-hover text-primary-500')

  return (
    <div className={className ? containerClassName + ' ' + className : containerClassName}>
      {view === 'year' ? (
        <YearViewButtons
          disabled={disabled}
          setDate={setDate}
          buttonClassName={buttonClassName}
          timezone={timezone}
        />
      ) : (
        <MonthViewButtons
          disabled={disabled}
          setDate={setDate}
          buttonClassName={buttonClassName}
          timezone={timezone}
        />
      )}
    </div>
  )
}

interface ButtonsProps {
  setDate: (date: Date) => void
  buttonClassName: string
  timezone: string
  disabled?: boolean
}

const MonthViewButtons: FC<ButtonsProps> = ({ disabled, setDate, buttonClassName, timezone }) => {
  const { t } = useTranslation()

  return (
    <>
      <button
        disabled={disabled}
        className={buttonClassName}
        onClick={() => setDate(subDays(createCurrentDate(timezone), 1))}
      >
        {t('yesterday')}
      </button>
      <button
        disabled={disabled}
        className={buttonClassName}
        onClick={() => setDate(createCurrentDate(timezone))}
      >
        {t('today')}
      </button>
      <button
        disabled={disabled}
        className={buttonClassName}
        onClick={() => setDate(addDays(createCurrentDate(timezone), 1))}
      >
        {t('tomorrow')}
      </button>
    </>
  )
}

const YearViewButtons: FC<ButtonsProps> = ({ disabled, setDate, buttonClassName, timezone }) => {
  const { t } = useTranslation()

  return (
    <>
      <button
        disabled={disabled}
        className={buttonClassName}
        onClick={() => setDate(subMonths(createCurrentDate(timezone), 1))}
      >
        {t('pastMonth')}
      </button>
      <button
        disabled={disabled}
        className={buttonClassName}
        onClick={() => setDate(createCurrentDate(timezone))}
      >
        {t('currentMonth')}
      </button>
      <button
        disabled={disabled}
        className={buttonClassName}
        onClick={() => setDate(addMonths(createCurrentDate(timezone), 1))}
      >
        {t('futureMonth')}
      </button>
    </>
  )
}

const BUTTON_CLASS_NAME = 'w-1/3 py-0.5'
const CONTAINER_CLASS_NAME = 'flex border-t divide-x'
