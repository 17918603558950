import { useEffect } from 'react'

/**
 * Displays a browser warning when the user tries to leave the page with unsaved changes.
 * @param hasUnsavedChanges pass true if there are unsaved changes. Usually `formState.isDirty`
 */
export const useUnsavedChangesOnPageWarning = (hasUnsavedChanges: boolean) => {
  useEffect(() => {
    const listener = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', listener)

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [hasUnsavedChanges])
}
