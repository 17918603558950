import { TableRow } from '@expane/ui'
import { FC, PropsWithChildren } from 'react'
import { SelectAllIcon } from 'ui/Icons'
import { Checkbox, CheckboxProps } from '@expane/ui'

export const SelectAllButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button className="px-1 rounded-sm text-xs font-normal" onClick={onClick}>
      <SelectAllIcon size="1rem" className="-mb-0.5" />
    </button>
  )
}

export const PermissionBlockTitle: FC<{ title: string; onSelectAll: () => void }> = ({
  title,
  onSelectAll,
}) => (
  <TableRow>
    <td className="bg-tr-accent p-1 text-sm text-primary-500 font-medium">
      <SelectAllButton onClick={onSelectAll} />
    </td>
    <td className="bg-tr-accent p-1 pl-1.5 text-sm text-primary-500 font-medium" colSpan={2}>
      {title}
    </td>
  </TableRow>
)

export const PermissionBlock: FC<PropsWithChildren<{ title: string }>> = ({ children, title }) => (
  <TableRow>
    <td></td>
    <td className={TABLE_ROW_TITLE_STYLE}>{title}</td>
    <td className={TABLE_ROW_PERMISSIONS_STYLE}>{children}</td>
  </TableRow>
)

export const PermissionCheckbox: FC<Omit<CheckboxProps, 'containerClassName'>> = props => {
  return <Checkbox containerClassName="p-1 w-1/2 lg:w-1/3 2xl:w-1/4" {...props} />
}
export const TABLE_ROW_TITLE_STYLE = 'w-40 p-1 pl-1.5 text-sm'
export const TABLE_ROW_PERMISSIONS_STYLE = 'flex items-center flex-wrap'
