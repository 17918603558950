import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// create a hook that will return a boolean if the viewport is mobile or not
const TABLET_WIDTH = 768
export const useMobileViewport = () => {
  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.outerWidth < TABLET_WIDTH) {
        navigate('/mobile', { replace: true })
        setIsMobile(true)
      } else if (location.pathname === '/mobile') {
        navigate('/', { replace: true })
        setIsMobile(false)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [navigate])

  return isMobile
}
