import { ServerClientBriefType } from '@expane/data'
import { formatPhoneNumber } from 'logic/ui'
import { FC } from 'react'
import { ClientDialog } from '.'
import { transformPersonName } from '@expane/logic/utils'
import { useTranslation } from 'react-i18next'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'

interface ClientDuplicateConfirmationProps {
  duplicateClients: ServerClientBriefType[]
  phone: string
}

export const ClientDuplicateConfirmation: FC<ClientDuplicateConfirmationProps> = ({
  duplicateClients,
  phone,
}) => {
  const { t } = useTranslation()

  const { openEditDialog, dialog } = useOpenDialog(ClientDialog)

  return (
    <>
      <div>
        {t('clientDuplicate.alreadyExist', { phone: formatPhoneNumber(phone) })}
        {duplicateClients.map(duplicateClient => (
          <div
            key={duplicateClient.id}
            className=" text-primary-400 cursor-pointer hover:text-primary-500"
            onClick={() => openEditDialog(duplicateClient.id)}
          >
            {transformPersonName(duplicateClient)}
          </div>
        ))}
      </div>
      <div className="mt-3">{t('clientDuplicate.confirmation')}</div>
      {dialog}
    </>
  )
}
