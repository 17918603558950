import { reportError, reportGqlError, useMutation } from '../../api'
import { Geocode, GeocodeResponse, gMapApi } from './logic'

export function useGeocodeAddress() {
  return useMutation(
    async ({
      address,
      apiKey,
    }: {
      address: string
      apiKey: string
    }): Promise<Geocode | undefined> => {
      const response = await fetch(gMapApi.geocode(address, apiKey))

      if (!response.ok) {
        reportError(new Error('Error while trying to geocode address'), 'error', {
          response,
          address,
        })

        return undefined
      }

      const result = (await response.json()) as GeocodeResponse | undefined

      if (result?.status === 'OK') return result.results[0]

      return undefined
    },
    {
      onError: reportGqlError,
    },
  )
}
