import { useEffect, useRef, useState } from 'react'

type Err = string | null

export function useModuleLazy<Type>(moduleToImport: () => Promise<Type>): [Type | null, Err] {
  const [isModuleLoaded, setIsModuleLoaded] = useState<boolean>(false)
  const [error, setError] = useState<Err>(null)

  const module = useRef<Type | null>(null)

  useEffect(() => {
    if (!isModuleLoaded) {
      moduleToImport()
        .then((loadedModule: Type) => {
          module.current = loadedModule
          setIsModuleLoaded(true)
        })
        .catch((e: Error) => {
          setError(e.message)
        })
    }
  }, [moduleToImport, isModuleLoaded, module])

  return [module.current, error]
}
