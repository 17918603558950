import {
  MouseEventHandler,
  useCallback,
  useRef,
  TouchEventHandler,
  EventHandler,
  UIEvent,
} from 'react'

export const useLongPress = (
  onLongPress: (() => void) | undefined,
  options?: { delay?: number; shouldPreventDefault?: boolean },
):
  | {
      onMouseDown: MouseEventHandler<HTMLDivElement>
      onTouchStart: TouchEventHandler<HTMLDivElement>
      onMouseUp: MouseEventHandler<HTMLDivElement>
      onMouseLeave: MouseEventHandler<HTMLDivElement>
      onTouchEnd: TouchEventHandler<HTMLDivElement>
    }
  | undefined => {
  const timeout = useRef<NodeJS.Timeout>()
  const target = useRef<EventTarget>()

  const delay = options?.delay ?? 300
  const shouldPreventDefault = options?.shouldPreventDefault ?? true

  const start: EventHandler<UIEvent> = useCallback(
    event => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, { passive: false })
      }
      timeout.current = setTimeout(() => {
        onLongPress?.()
      }, delay)
    },
    [shouldPreventDefault, delay, onLongPress],
  )

  // За правилом використання хуків опціональний return повинен бути після них
  if (!onLongPress) return

  const clear = () => {
    if (timeout.current) clearTimeout(timeout.current)
    if (shouldPreventDefault && target.current) {
      target.current.removeEventListener('touchend', preventDefault)
    }
  }

  return {
    onMouseDown: start,
    onTouchStart: start,
    onMouseLeave: clear,
    onMouseUp: clear,
    onTouchEnd: clear,
  }
}

const preventDefault = (event: Event) => {
  event.preventDefault()
}
