import { gql } from 'graphql-request'
import { request, useQueryOriginal, reportGqlError } from '../../api'
import { ServerPlanType } from '../../generated/graphql-types'
import { planFragment } from './plan.fragments'

export function useFetchPlans() {
  return useQueryOriginal<ServerPlanType[]>(
    ['plans'],
    async () => {
      const result = await request(gql`
        ${planFragment}
        query {
          plans {
            ...planType
          }
        }
      `)

      return result.plans
    },
    {
      onError: reportGqlError,
    },
  )
}
