import { Textarea } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generateTextFromTemplate, MessageTemplateData } from './logic'

export const MessageTemplateReview: FC<{
  template: string | undefined
  defaultTemplate: string
  className?: string
  data: MessageTemplateData
}> = ({ template, defaultTemplate, className, data }) => {
  const { t } = useTranslation()

  const text = template ? generateTextFromTemplate(template, data) : defaultTemplate

  return (
    <Textarea
      containerClassName={className}
      label={template ? t('messageExample.title') : t('messageExample.default')}
      placeholder=""
      value={text}
      disabled
      rows={4}
    />
  )
}
