import {
  CheckboxCreateBulkReceiptsResult,
  CheckboxCreateReceiptResult,
  CheckboxReturnReceipt,
  CloudFunctionResponseCodes,
  DataForCheckboxBulkReturnReceipts,
  DataForCheckboxReceipt,
  DataForCheckboxReturnReceipt,
  ServerAccountType,
  ServerDiscount,
  ServerDiscountType,
  ServerGood,
  ServerPaymentOption,
  ServerReceiptStatus,
  ServerReceiptWithStatus,
  ServerTransactionByIdType,
  ServerTransactionInBookingType,
  TRANSACTION_TYPES,
  TransactionFromAccount,
} from '@expane/data'
import { TFunction } from 'i18next'
import {
  CardItemForPayment,
  ProductItemForPayment,
  ServiceItemForPayment,
} from '../quickSale/types'
import { BookingPaymentData } from './booking'
import { DEFAULT_SERVICE_QUANTITY } from './booking/services'
import { useTranslation } from 'react-i18next'
import { PAYMENT_OPTION, PaymentOption, PaymentToAccount, spreadPaymentItemsQuantity } from '.'
import { useBusinessModulesSettings } from '../modules'
import { UNITS_IDS } from '../product'
import {
  TransactionRefundFormValues,
  TransactionSubscriptionFormValues,
} from '../transaction/refund'
import { DiscountInputInfo, findById } from '../utils'

interface UsualPaymentType {
  type: 'usual'
}
interface WithSoftwarePOSPaymentType {
  type: 'softwarePOS'
  softwarePOSId: number
  error: boolean
}

export const getPaymentType = (dto: {
  accountId: number
  paymentOption: PaymentOption
  paymentToAccounts: PaymentToAccount[]
  accounts: ServerAccountType[]
}): UsualPaymentType | WithSoftwarePOSPaymentType => {
  // Коли оплата з одного рахунку
  if (dto.paymentOption.id === PAYMENT_OPTION.oneAccount) {
    const account = dto.accounts.find(account => account.id === dto.accountId)

    if (account?.accountSoftwarePOS[0]?.softwarePOS.id)
      return {
        type: 'softwarePOS',
        softwarePOSId: account.accountSoftwarePOS[0].softwarePOS.id,
        error: false,
      }
    else return { type: 'usual' }
  } else {
    // Коли оплата з декількох рахунків
    const providedAccounts = dto.paymentToAccounts.filter(account => Boolean(account.value))
    const providedAccountIds = providedAccounts.map(account => account.id)

    const filteredAccounts = dto.accounts.filter(account => providedAccountIds.includes(account.id))

    const accountWithPOS = filteredAccounts.find(
      account => account.accountSoftwarePOS[0]?.softwarePOS.id,
    )

    // Якщо нема жодного рахунку з РРО
    if (!accountWithPOS) return { type: 'usual' }

    const softwarePOSId = accountWithPOS.accountSoftwarePOS[0].softwarePOS.id

    for (const providedAccount of providedAccounts) {
      const account = filteredAccounts.find(account => account.id === providedAccount.id)

      if (!account)
        return {
          type: 'softwarePOS',
          softwarePOSId,
          error: true,
        }

      if (account.accountSoftwarePOS[0]?.softwarePOS.id !== softwarePOSId)
        return {
          type: 'softwarePOS',
          softwarePOSId,
          error: true,
        }
    }

    return {
      type: 'softwarePOS',
      softwarePOSId,
      error: false,
    }
  }
}

export const transformPaymentOptionsForCheckboxReceipt = <
  T extends {
    paymentAmount: string | number
    accountId: number
    paymentOption: PaymentOption
    paymentToAccounts: PaymentToAccount[]
  },
>(
  data: T,
) => {
  const payments: ServerPaymentOption[] = []

  if (data.paymentOption.id === PAYMENT_OPTION.oneAccount)
    payments.push({ accountId: data.accountId, value: Number(data.paymentAmount) })
  else {
    const providedAccounts = data.paymentToAccounts
      .filter(account => Boolean(account.value))
      .map(account => ({ accountId: account.id, value: Number(account.value) }))
    payments.push(...providedAccounts)
  }

  return payments
}

export const transformDiscountForCheckboxReceipt = (
  discount?: DiscountInputInfo,
): ServerDiscount | undefined =>
  discount && discount.value
    ? {
        value: discount.value,
        type: discount.type === 'fixed' ? ServerDiscountType.Fixed : ServerDiscountType.Percent,
      }
    : undefined

export const getItemPriceAndDiscount = <T extends { price: number; discount: number | null }>(
  item: T,
  quantity: number,
): { price: number; discount: { type: ServerDiscountType; value: string } | undefined } => {
  const price = item.price + (item.discount ?? 0)
  const discount = item.discount
    ? {
        type: ServerDiscountType.Fixed,
        value: (item.discount * quantity).toString(),
      }
    : undefined

  return { price, discount }
}

export const handleUpdateCreateReceiptResponse = ({
  t,
  createReceiptResult,
  onError,
}: {
  t: TFunction
  createReceiptResult: CheckboxCreateReceiptResult | undefined
  onError: (message: string) => void
}): string | undefined => {
  if (
    createReceiptResult?.code === CloudFunctionResponseCodes.successful &&
    createReceiptResult?.receipt?.status === ServerReceiptStatus.Done
  ) {
    return createReceiptResult.receipt.receiptId
  } else if (
    createReceiptResult?.code === CloudFunctionResponseCodes.checkboxCredentialsError ||
    createReceiptResult?.code === CloudFunctionResponseCodes.noCheckboxToken ||
    createReceiptResult?.code === CloudFunctionResponseCodes.noCheckboxLicenseKey
  )
    onError(t('softwarePOS.receiptError'))
  else if (createReceiptResult?.code === CloudFunctionResponseCodes.checkboxError)
    onError(t('softwarePOS.checkboxError'))
  else {
    onError(t('submitError'))
  }
}

export const handleUpdateCreateBulkReceiptsResponse = ({
  t,
  createBulkReceiptsResult,
  onError,
}: {
  t: TFunction
  createBulkReceiptsResult: CheckboxCreateBulkReceiptsResult | undefined
  onError: (message: string) => void
}): ServerReceiptWithStatus[] | null | undefined => {
  if (createBulkReceiptsResult?.code === CloudFunctionResponseCodes.successful) {
    return createBulkReceiptsResult.receipts
  } else if (
    createBulkReceiptsResult?.code === CloudFunctionResponseCodes.checkboxCredentialsError ||
    createBulkReceiptsResult?.code === CloudFunctionResponseCodes.noCheckboxToken ||
    createBulkReceiptsResult?.code === CloudFunctionResponseCodes.noCheckboxLicenseKey
  )
    onError(t('softwarePOS.receiptError'))
  else if (createBulkReceiptsResult?.code === CloudFunctionResponseCodes.checkboxError)
    onError(t('softwarePOS.checkboxError'))
  else {
    onError(t('submitError'))
  }
}

/**
 * Перевіряє, чи всі рахунки з наданих мають підключений РРО.
 *
 * @param  accountIds - Масив ідентифікаторів рахунків для перевірки.
 * @param  accounts - Масив всіх рахунків.
 * @returns Повертає `true`, якщо всі рахунки в списку мають підключений РРО, інакше повертає `false`.
 */
export const checkIfAllAccountsHavePOS = (
  accountIds: number[],
  accounts: ServerAccountType[],
): boolean => {
  if (accountIds.length < 2) return false

  return accountIds.every(accountId => findById(accountId, accounts)?.accountSoftwarePOS?.length)
}

/**
 * @returns Повертає дані для чекбокс запиту та транзакції з ID чеків або null
 */
export const transformRefundByCashForCheckboxBulkReceipts = ({
  refundByCash,
  accounts,
  transactions,
  clientId,
  fromAccountId,
  generateUUIDv4,
}: {
  refundByCash: TransactionFromAccount[]
  accounts: ServerAccountType[]
  transactions: ServerTransactionInBookingType[]
  clientId: number | undefined
  fromAccountId: number | undefined
  generateUUIDv4: () => string
}): {
  checkboxBulkReturnReceipts: DataForCheckboxBulkReturnReceipts
  refundByCashWithReceiptId: TransactionFromAccount[]
} | null => {
  if (!fromAccountId || !clientId) return null

  const account = findById(fromAccountId, accounts)
  const softwarePOSId = account?.accountSoftwarePOS?.length
    ? account?.accountSoftwarePOS?.[0].softwarePOS?.id
    : undefined
  // Перевіряємо чи рахунок підключений до РРО
  if (!softwarePOSId) return null

  const refundByCashWithReceiptId: TransactionFromAccount[] = []
  const receipts: CheckboxReturnReceipt[] = []

  for (const refundTransaction of refundByCash) {
    // Про всяк випадок перевіряємо, щоб були тільки повернення з зарахуванням коштів на рахунок
    if (
      refundTransaction.type === TRANSACTION_TYPES.refund.id &&
      refundTransaction.typeVariation === TRANSACTION_TYPES.refund.variations.refundFromAccount.id
    ) {
      const parentTransaction = transactions.find(
        transaction => transaction.id === refundTransaction.parentId,
      )
      if (refundTransaction.amount && parentTransaction) {
        const paymentOption: ServerPaymentOption = {
          accountId: fromAccountId,
          value: refundTransaction.amount,
        }

        const receiptId = generateUUIDv4()

        const receipt: CheckboxReturnReceipt = {
          receiptId,
          relatedReceiptId: parentTransaction.transactionReceipts?.[0]?.receiptId,
          isReturn: true,
          payments: [paymentOption],
          goods: [],
        }

        if (
          refundTransaction.movement?.data?.movementProducts?.data.length &&
          parentTransaction.movement?.movementProducts.length
        ) {
          for (const { product, price, quantity, discount } of parentTransaction.movement
            ?.movementProducts) {
            receipt.goods.push({
              code: product.id.toString(),
              name: product.name,
              unit: product.unit ?? UNITS_IDS.pcs,
              quantity,
              ...getItemPriceAndDiscount({ price, discount }, quantity),
            })
          }
        }

        if (
          refundTransaction.transactionsServices?.data.length &&
          parentTransaction.transactionsServices.length
        ) {
          for (const {
            price,
            discount,
            service,
            serviceId,
          } of parentTransaction.transactionsServices) {
            receipt.goods.push({
              code: serviceId.toString(),
              name: service.name,
              unit: UNITS_IDS.pcs,
              quantity: 1,
              ...getItemPriceAndDiscount({ price, discount }, 1),
            })
          }
        }

        receipts.push(receipt)
        refundByCashWithReceiptId.push({
          ...refundTransaction,
          transactionReceipts: { data: [{ receiptId, softwarePOSId }] },
        })
      } else refundByCashWithReceiptId.push(refundTransaction)
    }
  }

  return {
    checkboxBulkReturnReceipts: { clientId, softwarePOSId, receipts },
    refundByCashWithReceiptId,
  }
}

export const transformBookingPaymentDataForCheckboxReceipt = ({
  bookingPaymentData,
  softwarePOSId,
  clientId,
  generateUUIDv4,
}: {
  bookingPaymentData: BookingPaymentData
  softwarePOSId: number
  clientId: number
  generateUUIDv4: () => string
}): DataForCheckboxReceipt => {
  const goods: ServerGood[] = []

  for (const consumable of bookingPaymentData.consumablesDto) {
    goods.push({
      name: consumable.name,
      code: consumable.id.toString(),
      price: consumable.price,
      quantity: Number(consumable.quantity),
      discount: transformDiscountForCheckboxReceipt(consumable.discount),
      unit: consumable.unit,
    })
  }

  for (const service of bookingPaymentData.servicesDto) {
    if (!service.subscriptionId)
      goods.push({
        name: service.name,
        code: service.id.toString(),
        price: service.price,
        quantity: DEFAULT_SERVICE_QUANTITY,
        discount: transformDiscountForCheckboxReceipt(service.discount),
        unit: UNITS_IDS.pcs,
      })
  }

  return {
    softwarePOSId,
    clientId,
    receipt: {
      receiptId: generateUUIDv4(),
      goods,
      payments: transformPaymentOptionsForCheckboxReceipt(bookingPaymentData),
    },
  }
}

export const transformTransactionRefundFormValuesForReturnReceipt = ({
  data,
  totalSum,
  accounts,
  transaction,
  generateUUIDv4,
}: {
  data: TransactionRefundFormValues
  totalSum: number
  accounts: ServerAccountType[]
  transaction: ServerTransactionByIdType
  generateUUIDv4: () => string
}): DataForCheckboxReturnReceipt | null => {
  const clientId = transaction.client?.id
  const relatedReceiptId = transaction.transactionReceipts?.[0]?.receiptId

  if (!clientId) return null

  const account = findById(data.fromAccountId, accounts)
  const softwarePOSId = account?.accountSoftwarePOS?.length
    ? account?.accountSoftwarePOS?.[0].softwarePOS?.id
    : undefined

  if (!softwarePOSId) return null

  const payment: ServerPaymentOption = { value: totalSum, accountId: data.fromAccountId }

  const goods: ServerGood[] = []

  if (data.dataType === 'product')
    for (const { name, unit, id, quantity } of data.items) {
      const movementProduct = transaction.movement?.movementProducts.find(
        ({ productId }) => productId === id,
      )

      if (movementProduct)
        goods.push({
          code: id.toString(),
          name,
          unit: unit ?? UNITS_IDS.pcs,
          quantity,
          ...getItemPriceAndDiscount(movementProduct, quantity),
        })
    }

  if (data.dataType === 'service') {
    const spreadedServices = spreadPaymentItemsQuantity(data.items)

    for (const { name, id, quantity } of spreadedServices) {
      const transactionService = transaction.transactionsServices.find(tS => tS.service.id === id)

      if (transactionService)
        goods.push({
          code: id.toString(),
          name,
          unit: UNITS_IDS.pcs,
          quantity,
          ...getItemPriceAndDiscount(transactionService, quantity),
        })
    }
  }

  if (data.dataType === 'giftCard')
    for (const { name, id, quantity } of data.items) {
      const transactionCard = transaction.transactionsCards.find(
        transactionCard => transactionCard.card.id === id,
      )

      if (transactionCard) {
        const cardTemplateId = transactionCard.card.cardTemplate.id

        goods.push({
          code: cardTemplateId.toString(),
          name,
          unit: UNITS_IDS.pcs,
          quantity,
          ...getItemPriceAndDiscount(transactionCard, quantity),
        })
      }
    }

  return {
    clientId,
    softwarePOSId,
    receipt: {
      receiptId: generateUUIDv4(),
      goods,
      isReturn: true,
      payments: [payment],
      relatedReceiptId,
    },
  }
}

export const transformTransactionSubscriptionFormValuesForReturnReceipt = ({
  data,
  totalSum,
  accounts,
  transaction,
  generateUUIDv4,
}: {
  data: TransactionSubscriptionFormValues
  totalSum: number
  accounts: ServerAccountType[]
  transaction: ServerTransactionByIdType
  generateUUIDv4: () => string
}): DataForCheckboxReturnReceipt | null => {
  const clientId = transaction.client?.id
  const relatedReceiptId = transaction.transactionReceipts?.[0]?.receiptId

  if (!clientId) return null

  const account = findById(data.fromAccountId, accounts)
  const softwarePOSId = account?.accountSoftwarePOS?.length
    ? account?.accountSoftwarePOS?.[0].softwarePOS?.id
    : undefined

  if (!softwarePOSId) return null

  const transactionCard = transaction.transactionsCards.find(
    transactionCard => transactionCard.card.id === data.item.id,
  )

  if (!transactionCard) return null

  const price = transactionCard.price + (transactionCard.discount ?? 0)
  const difference = price - totalSum
  const discount = difference
    ? { type: ServerDiscountType.Fixed, value: difference.toString() }
    : undefined

  const cardTemplateId = transactionCard.card.cardTemplate.id

  const payment: ServerPaymentOption = { value: totalSum, accountId: data.fromAccountId }

  return {
    clientId,
    softwarePOSId,
    receipt: {
      receiptId: generateUUIDv4(),
      goods: [
        {
          code: cardTemplateId.toString(),
          unit: UNITS_IDS.pcs,
          price,
          discount,
          quantity: data.item.quantity,
          name: data.item.name,
        },
      ],
      relatedReceiptId,
      isReturn: true,
      payments: [payment],
    },
  }
}

export const transformQuickSaleFormValuesForCheckboxReceipt = ({
  data,
  softwarePOSId,
  generateUUIDv4,
}: {
  data: {
    clientId: number
    giftCardItems: CardItemForPayment[]
    serviceItems: ServiceItemForPayment[]
    subscriptionItems: CardItemForPayment[]
    productItems: ProductItemForPayment[]
    paymentAmount: string | number
    accountId: number
    paymentOption: PaymentOption
    paymentToAccounts: PaymentToAccount[]
  }
  softwarePOSId: number
  generateUUIDv4: () => string
}): DataForCheckboxReceipt => {
  const goods: ServerGood[] = []

  for (const giftCard of data.giftCardItems) {
    goods.push({
      name: giftCard.name,
      code: giftCard.id.toString(),
      price: giftCard.price,
      quantity: giftCard.quantity,
      discount: transformDiscountForCheckboxReceipt(giftCard.discount),
      unit: UNITS_IDS.pcs,
    })
  }

  for (const product of data.productItems) {
    goods.push({
      name: product.name,
      code: product.id.toString(),
      price: product.price,
      quantity: product.quantity,
      discount: transformDiscountForCheckboxReceipt(product.discount),
      unit: product.unit,
    })
  }

  for (const subscription of data.subscriptionItems) {
    goods.push({
      name: subscription.name,
      code: subscription.id.toString(),
      price: subscription.price,
      quantity: subscription.quantity,
      discount: transformDiscountForCheckboxReceipt(subscription.discount),
      unit: UNITS_IDS.pcs,
    })
  }

  for (const service of data.serviceItems) {
    goods.push({
      name: service.name,
      code: service.id.toString(),
      price: service.price,
      quantity: service.quantity,
      discount: transformDiscountForCheckboxReceipt(service.discount),
      unit: UNITS_IDS.pcs,
    })
  }

  return {
    softwarePOSId,
    clientId: data.clientId,
    receipt: {
      receiptId: generateUUIDv4(),
      goods,
      payments: transformPaymentOptionsForCheckboxReceipt(data),
    },
  }
}

export const useAccountsWithCheckbox = (accounts: ServerAccountType[]) => {
  const { t } = useTranslation()
  const { getModuleSetting } = useBusinessModulesSettings()
  const isCheckboxEnabled = getModuleSetting('checkbox')

  const accountsWithPOSLabels = isCheckboxEnabled
    ? accounts.map(account => {
        if (!account.accountSoftwarePOS?.[0]) return account

        const pos = account.accountSoftwarePOS?.[0].softwarePOS.name

        return { ...account, name: `${account.name} (${t('POS')}: ${pos})` }
      })
    : accounts

  const accountsWithPOSTags: Array<
    ServerAccountType & { tag?: { id: number; name: string; color: string } }
  > = isCheckboxEnabled
    ? accounts.map(account => {
        if (!account.accountSoftwarePOS?.[0]) return account

        const pos = account.accountSoftwarePOS?.[0].softwarePOS.name

        return {
          ...account,
          tag: { id: -1, name: pos, color: 'yellow-600' },
        }
      })
    : accounts

  return { accountsWithPOSLabels, accountsWithPOSTags, isCheckboxEnabled }
}

export const useAccountsWithoutCheckbox = (accounts: ServerAccountType[]) => {
  const { getModuleSetting } = useBusinessModulesSettings()
  const isCheckboxEnabled = getModuleSetting('checkbox')

  const accountsWithoutCheckbox = isCheckboxEnabled
    ? accounts.filter(account => !account.accountSoftwarePOS.length)
    : accounts

  return { accountsWithoutCheckbox, isCheckboxEnabled }
}
