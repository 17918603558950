import hmacMd5 from 'crypto-js/hmac-md5'
import Hex from 'crypto-js/enc-hex'

type Dto = {
  secret: string
  merchantAccount: string
  merchantDomainName: string
  orderReference: string
  orderDate: string
  amount: string
  currency: string
  productName: string[]
  productCount: string[]
  productPrice: string[]
}

export const generateMerchantSignature = ({
  merchantAccount,
  merchantDomainName,
  orderReference,
  orderDate,
  amount,
  currency,
  productName,
  productCount,
  productPrice,
  secret,
}: Dto) => {
  const message = [
    merchantAccount,
    merchantDomainName,
    orderReference,
    orderDate,
    amount,
    currency,
    ...productName,
    ...productCount,
    ...productPrice,
  ].join(';')

  if (!secret) throw new Error('Secret needs to be present!')
  const merchantSignature = Hex.stringify(hmacMd5(message, secret))

  return merchantSignature
}
