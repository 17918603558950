import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  ServerClientDocumentInsertInput,
  ServerClientDocumentSetInput,
} from '../../generated/graphql-types'
import { CLIENT_DOCUMENTS_QUERY_KEY } from './queryKeys'

export function useCreateClientDocument() {
  return useMutation(
    async (
      clientDocumentInsertInput: ServerClientDocumentInsertInput,
    ): Promise<{
      insertClientDocument?: { id?: number; clientId?: number }
    }> => {
      return request(
        gql`
          mutation ($clientDocumentInsertInput: clientDocument_insert_input!) {
            insertClientDocument(object: $clientDocumentInsertInput) {
              id
              clientId
            }
          }
        `,
        { clientDocumentInsertInput },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CLIENT_DOCUMENTS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateClientDocument() {
  return useMutation(
    async (dto: {
      id: number
      clientDocumentSetInput: ServerClientDocumentSetInput
    }): Promise<{
      updateClientDocumentById?: { id?: number; clientId?: number }
    }> => {
      return request(
        gql`
          mutation ($id: Int!, $clientDocumentSetInput: clientDocument_set_input!) {
            updateClientDocumentById(pk_columns: { id: $id }, _set: $clientDocumentSetInput) {
              id
              clientId
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CLIENT_DOCUMENTS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
