import { Maybe, ServerBusinessSettingsType } from '../../generated/graphql-types'

export type BinotelIntegration = {
  key?: string
  secret?: string
}

type DefaultTelegramIntegration = {
  isUseDefault: true
  name?: string
  token?: string
}

type OwnTelegramIntegration = {
  isUseDefault: false
  name: string
  token: string
}

export type TelegramIntegration = DefaultTelegramIntegration | OwnTelegramIntegration

export type BusinessSettingsType = ServerBusinessSettingsType & {
  binotelIntegration: Maybe<BinotelIntegration>
  telegramIntegration: Maybe<TelegramIntegration>
}

export enum UpdateModulesSettingsResponseCodes {
  checkError,
  serverError,
  updateError,
  noRequiredFields,
  noPermission,
  success,
}
