import { gql } from 'graphql-request'
import { bookingInReviewFragment } from '../booking'

export const bookingReviewFragment = gql`
  fragment bookingReviewType on bookingReviewView {
    id
    rating
    text
    clientFirstName
    clientLastName
    clientPhoto
    createdAt
    employee {
      id
      firstName
      lastName
    }
  }
`

export const employeeReviewFragment = gql`
  fragment employeeReviewType on employeeReviewView {
    employeeId
    firstName
    lastName
    quantity
    averageRating
  }
`

export const reviewFragment = gql`
  ${bookingInReviewFragment}
  fragment reviewType on review {
    id
    rating
    text
    createdAt
    approved
    archived
    approvedByEmployee {
      firstName
      lastName
      photo
    }
    client {
      firstName
      lastName
      photo
    }
    booking {
      ...bookingInReviewType
    }
  }
`

export const reviewAsClientFragment = gql`
  ${bookingInReviewFragment}
  fragment reviewAsClientType on review {
    id
    rating
    text
    createdAt
    approved
    archived
    booking {
      ...bookingInReviewType
    }
  }
`
