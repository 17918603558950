import { checkArrayNotEmpty } from '@expane/logic/form'
import { permissions } from '@expane/logic/permission'
import { getIsGroupService, getIsServiceForSale } from '@expane/logic/service'
import { transformPersonName } from '@expane/logic/utils'
import { Switch } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TreeMenuItem } from 'ui/TreeMenu'
import { TreeSelect } from 'ui/TreeSelect'
import { EmployeeDialog } from 'widgets/EmployeeDialog'
import { LocationDialog } from 'widgets/LocationDialog'
import { ServiceDialogFormData } from 'widgets/ServiceDialog/ServiceDialogLogic'

interface Props {
  control: Control<ServiceDialogFormData>
  employeeTreeMenuItemsWithDisabled: TreeMenuItem[]
  disabled: boolean
  myPermissions: string[]
  locationTreeItemsWithDisabled: TreeMenuItem[]
  areManyEmployeesAllowed: boolean
}

export const ServiceDialogLocationsEmployeesInputs: FC<Props> = ({
  control,
  employeeTreeMenuItemsWithDisabled,
  disabled,
  myPermissions,
  locationTreeItemsWithDisabled,
  areManyEmployeesAllowed,
}) => {
  const { t } = useTranslation()
  const watchedType = useWatch({ control, name: 'type' })
  const isGroup = getIsGroupService(watchedType)
  const isForSale = getIsServiceForSale(watchedType)

  const { openCreateDialog: openLocationDialog, dialog: locationDialog } =
    useOpenDialog(LocationDialog)
  const { openCreateDialog: openEmployeeDialog, dialog: employeeDialog } =
    useOpenDialog(EmployeeDialog)

  const isLocationEditingAllowed = myPermissions.includes(permissions.location.set)
  const isEmployeeEditingAllowed = myPermissions.includes(permissions.employee.set)

  return (
    <div>
      <Controller
        control={control}
        name="availableForClient"
        render={({ field: { onChange, value } }) => (
          <Switch
            onChange={onChange}
            checked={value}
            disabled={disabled || isForSale}
            label={t('availableForClient.name')}
            hint={t('availableForClient.hint')}
          />
        )}
      />

      <Controller
        control={control}
        name="locations"
        rules={{
          validate: {
            checkArrayNotEmpty: value => {
              if (isForSale) return true
              return checkArrayNotEmpty(value)
            },
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TreeSelect
            type="MultiPickMode"
            errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
            required={!isForSale}
            label={t('locations.name')}
            onPlusClick={
              isLocationEditingAllowed
                ? () =>
                    openLocationDialog((id, dto) => {
                      const newLocation = { id, name: dto?.name }
                      const newValue = value ? [...value, newLocation] : [newLocation]
                      onChange(newValue)
                    })
                : undefined
            }
            placeholder={t('choose')}
            items={locationTreeItemsWithDisabled}
            onSelected={onChange}
            selected={isForSale ? [] : value}
            disabled={disabled || isForSale}
            className={'mt-2'}
          />
        )}
      />

      <Controller
        control={control}
        name="employees"
        rules={{
          validate: {
            checkArrayNotEmpty: value => {
              if (!isGroup) return true

              return checkArrayNotEmpty(value)
            },
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TreeSelect
            type="MultiPickMode"
            errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
            required={isGroup}
            label={t('employee.name')}
            onPlusClick={
              isEmployeeEditingAllowed && areManyEmployeesAllowed
                ? () =>
                    openEmployeeDialog((id, dto) => {
                      if (dto) {
                        const newEmployee: TreeMenuItem = {
                          id,
                          name: transformPersonName(dto),
                          parentId: dto.groupId,
                        }
                        const newValue = value ? [...value, newEmployee] : [newEmployee]
                        onChange(newValue)
                      }
                    })
                : undefined
            }
            placeholder={t('choose')}
            items={employeeTreeMenuItemsWithDisabled}
            onSelected={onChange}
            selected={value as TreeMenuItem[]}
            disabled={disabled}
          />
        )}
      />
      {locationDialog}
      {employeeDialog}
    </div>
  )
}
