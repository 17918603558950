import { findById, translateItemsName } from '../utils'
import {
  ServerProductGroupType,
  ServerProductType,
  ServerTransactionBriefWithMovementsType,
  useFetchProducts,
} from '@expane/data'
import { TFunction } from 'react-i18next'

export const UNITS_IDS: Record<string, number> = {
  pcs: 1,
  l: 2,
  ml: 3,
  kg: 4,
  g: 5,
  m: 6,
  mm: 7,
}

export const UNITS: { id: number; name: string }[] = [
  { id: UNITS_IDS.pcs, name: 'unit.pcs' },
  { id: UNITS_IDS.l, name: 'unit.l' },
  { id: UNITS_IDS.ml, name: 'unit.ml' },
  { id: UNITS_IDS.kg, name: 'unit.kg' },
  { id: UNITS_IDS.g, name: 'unit.g' },
  { id: UNITS_IDS.m, name: 'unit.m' },
  { id: UNITS_IDS.mm, name: 'unit.mm' },
]

export const CONVERTIBLE_UNITS_IDS = [UNITS_IDS.l, UNITS_IDS.kg, UNITS_IDS.m]

export const RATIO = 1000 // 1000ml in 1l etc.

export const convertUnitValueFromServer = (value: number, unitId: number) =>
  CONVERTIBLE_UNITS_IDS.includes(unitId) ? value / RATIO : value

export const convertUnitValueToServer = (value: number, unitId: number) =>
  CONVERTIBLE_UNITS_IDS.includes(unitId) ? value * RATIO : value

export const getUnitsName = (unitId: number, t: TFunction): string | undefined =>
  findById(unitId, translateItemsName(UNITS, t))?.name

// проверяем или группа товаров доступна только для определенных групп сотрудников
export const checkProductGroupForAccess = (
  productGroups: ServerProductGroupType[],
  productGroupId: number,
) => {
  const productGroup = productGroups.find(productGroup => productGroup.id === productGroupId)

  if (productGroup === undefined) throw new Error('No product group with such id')

  if (productGroup.productGroupEmployeeGroups.length > 0) return false

  if (productGroup.parentId) return checkProductGroupForAccess(productGroups, productGroup.parentId)

  return true
}

type ProductsTransactionInfo = Array<{
  quantity: number
  price: number
  product: { name: string; id: number }
}>

export const calcProductsTotalPrice = (productsInfo: ProductsTransactionInfo) =>
  productsInfo.reduce((acc, value) => acc + value.quantity * value.price, 0)

// при использовании фун-и важно обратить внимание,
// чтоб само значение тоже было конвертировано в эту ед измерения
export const useGetUnitsNameForConsumable = (branchId: number | undefined) => {
  const { data: products } = useFetchProducts(branchId)

  const getUnit = (productId: number | undefined): string => {
    const product = products?.find(product => product.id === productId)
    if (!product) return ''

    const unitId = getUnitIdForConsumable(product.unit)
    return getUnitNameById(unitId)
  }

  return getUnit
}

export const getUnitNameById = (unitId: number) =>
  UNITS.find(unit => unit.id === unitId)?.name ?? ''

export const getUnitIdForConsumable = (unitId: number) => {
  if (unitId === UNITS_IDS.kg) return UNITS_IDS.g
  if (unitId === UNITS_IDS.l) return UNITS_IDS.ml
  if (unitId === UNITS_IDS.m) return UNITS_IDS.mm

  return unitId
}

export const checkUnitCanBeFloat = (unitId: number): boolean =>
  CONVERTIBLE_UNITS_IDS.includes(unitId)

export const generateProductTypeHint = (
  areUsedAsConsumables: boolean,
  thereWasAlreadySale: boolean,
  t: TFunction,
): string | undefined => {
  if (areUsedAsConsumables) return t('productType.areUsedAsConsumablesHint')
  if (thereWasAlreadySale) return t('productType.thereWasAlreadySaleHint')
}

export const checkAvailableProductTypeChanges = (
  productById: ServerProductType | undefined,
  transactionsWithProduct: ServerTransactionBriefWithMovementsType[] | undefined,
) => {
  // проверяет есть ли этот товар в расходниках услуги
  const areUsedAsConsumables = Boolean(productById?.serviceProducts.length)
  // проверяет были ли продажи по этому товару
  const thereWasAlreadySale = Boolean(transactionsWithProduct?.length)

  return { areUsedAsConsumables, thereWasAlreadySale }
}
