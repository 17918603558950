import { FC } from 'react'
import { BusinessLandingSettingsType, useFetchOnlineBookingURLs } from '@expane/data'
import { Spinner } from '@expane/ui'
import { NewOnlineBookingURLsLogic } from 'pages/SettingsPage/BusinessLandingSettings/OnlineBookingURLs/OnlineBookingURLsLogic'
import { Path } from 'pages/SettingsPage/BusinessLandingSettings/Blocks/Path'
import { LandingSettingsBlockProps } from 'pages/SettingsPage/BusinessLandingSettings/logic'

export const NewOnlineBookingURLs: FC<
  LandingSettingsBlockProps & { businessLandingSettings: BusinessLandingSettingsType }
> = ({ control, businessLandingSettings, disabled }) => {
  const { data: onlineBookingURLs, isLoading } = useFetchOnlineBookingURLs()

  if (isLoading) return <Spinner expandCentered />
  if (!businessLandingSettings || !onlineBookingURLs) return null

  return (
    <div>
      <Path
        control={control}
        disabled={disabled}
        businessLandingSettings={businessLandingSettings}
      />

      <NewOnlineBookingURLsLogic
        businessLandingSettings={businessLandingSettings}
        onlineBookingURLs={onlineBookingURLs}
      />
    </div>
  )
}
