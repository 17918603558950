import { EmployeeScheduleDto, ServerEmployeeType, useStopEmployeeSchedule } from '@expane/data'
import { createCurrentDate, endOfDay, isBefore, startOfDay } from '@expane/date'
import { transformPersonName } from '@expane/logic/utils'
import { Button, CloseButton, Dialog } from '@expane/ui'
import { DateTimePicker, useSnackbar } from '@expane/widgets'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline } from 'react-icons/io5'
import { labelStyle, textStyle } from './'

interface StopEmployeeScheduleDialogLogicProps extends Pick<DialogProps, 'closeDialog'> {
  employeeSchedule: EmployeeScheduleDto
  employee: ServerEmployeeType
  timezone: string
}

interface StopEmployeeScheduleFormValues {
  endDate: Date
}

export const StopEmployeeScheduleDialogLogic: FC<StopEmployeeScheduleDialogLogicProps> = ({
  closeDialog,
  employeeSchedule,
  employee,
  timezone,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatting()

  const { control, handleSubmit, formState } = useForm<StopEmployeeScheduleFormValues>({
    defaultValues: { endDate: createCurrentDate(timezone) },
  })

  const { mutateAsync: stopEmployeeSchedule } = useStopEmployeeSchedule({
    employeeId: employee.id,
    branchId: employeeSchedule.branch.id,
  })

  const [openSnackbar] = useSnackbar()
  const onSubmit: SubmitHandler<StopEmployeeScheduleFormValues> = async ({ endDate }) => {
    try {
      await stopEmployeeSchedule({
        scheduleId: employeeSchedule.id,
        endDate: endOfDay(endDate),
      })
      openSnackbar(t('stopSchedule.stoppedSuccessfully'), 'success', 3000)
    } catch {
      openSnackbar(t('submitError'), 'error', 3000)
    } finally {
      closeDialog()
    }
  }

  return (
    <>
      <Dialog.Body>
        <div className="flex">
          <div className="mr-2">
            <p className={labelStyle}>{t('employee.name')}:</p>
            <p className={labelStyle}>{t('branch.name')}:</p>
            <p className={labelStyle}>{t('startDate')}:</p>
            <p className={labelStyle}>{t('endDate')}:</p>
          </div>

          <div>
            <p className={textStyle}>{transformPersonName(employee)}</p>
            <p className={textStyle}>{employeeSchedule.branch.name}</p>
            <p className={textStyle}>{formatDate('historyDate', employeeSchedule.startDate)}</p>
            <Controller
              name="endDate"
              control={control}
              rules={{
                validate: {
                  past: endDate => !isBefore(endDate, startOfDay(createCurrentDate(timezone))),
                  beforeStart: endDate => !isBefore(endDate, employeeSchedule.startDate),
                },
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <DateTimePicker
                  value={value}
                  onChange={onChange}
                  type="date"
                  timezone={timezone}
                  errorMessage={{
                    isShown: Boolean(error),
                    text:
                      error?.type === 'past'
                        ? t('stopSchedule.errorPast')
                        : t('stopSchedule.errorBeforeStart'),
                  }}
                />
              )}
            />
          </div>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          Icon={IoCalendarOutline}
          onClick={handleSubmit(onSubmit)}
          spinner={formState.isSubmitting}
          disabled={formState.isSubmitting}
        >
          {t('stopSchedule.shortName')}
        </Button>
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
