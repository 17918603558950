import { useFetchBusinessesToSwitch, useFetchCurrentBranchTimezone } from '@expane/data'
import { useCheckIsOneBranchInBusiness } from '@expane/logic/branch'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { SpinnerSmall, TopBar as TopBarUI, usePopupOpenState } from '@expane/ui'
import { config } from 'config'
import { useFetchMyEmployee, useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoLogOutOutline, IoSwapHorizontalOutline } from 'react-icons/io5'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { routes, RouteType } from 'routes/logic'
import { firebase } from 'services/firebase'
import { reportError } from 'services/sentry'
import { store } from 'store'
import { Avatar } from 'ui/Avatar'
import { DropdownMenu } from 'ui/DropdownMenu'
import { BranchSwitchSelect } from 'widgets/BranchSwitchSelect'
import { useOpenChooseBusinessDialog } from 'widgets/ChooseBusinessDialog'
import { EmployeeDialog } from 'widgets/EmployeeDialog'
import { NotificationButton } from 'widgets/NotificationDialog/Button'
import { QuickFindInput } from 'widgets/QuickFindInput'
import ExpaneLogo from './expane-logo.svg?react'
import { MainMenu } from 'widgets/TopBar/MainMenu'
import ExpaneLogoMini from './expane-logo-mini.svg?react'

export const TopBar: FC = observer(() => {
  const location = useLocation()

  const {
    multiBooking: { isMultiBookingMode },
    unitedBookingUpdate: { isUnitedBookingUpdateMode },
    me: { isAuthorised },
    branch: { branchId },
  } = store
  const shadowed = isMultiBookingMode || isUnitedBookingUpdateMode

  const isOnline = !store.serverStatus.offline
  const isPageRequiresMenu = !location.pathname.includes('create-business')

  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const { openEditDialog: openEmployeeDialog, dialog: employeeDialog } =
    useOpenDialog(EmployeeDialog)

  const { openChooseBusinessDialog, chooseBusinessDialog } = useOpenChooseBusinessDialog()

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: myEmployee, isLoading: isMyEmployeeLoading } = useFetchMyEmployee(
    timezone,
    branchId,
  )

  const { isLoading, isOneBranchInBusiness } = useCheckIsOneBranchInBusiness()

  return (
    <>
      <TopBarUI>
        <div className="flex mr-1.5">
          <Link to="/bookings">
            <ExpaneLogoMini className="mt-1.5 ml-1 w-6 text-primary-500 xl:hidden" />
            <ExpaneLogo className="w-36 text-primary-500 hidden xl:inline" />
          </Link>

          {isAuthorised && isOnline && isPageRequiresMenu && (
            <QuickFindInput disabled={shadowed} containerClassName="flex-1 ml-2 w-40" />
          )}
        </div>

        {isLoading && <SpinnerSmall className="text-main-color" />}
        {!isOneBranchInBusiness && <BranchSwitchSelect />}

        {!shadowed && (
          <Link
            to="/changelog"
            className="absolute bottom-0 text-primary-500 hover:text-primary-700 hover:dark:text-primary-600 ml-0.5 cursor-pointer"
            style={{ fontSize: '0.6rem' }}
          >
            {config.VERSION}
          </Link>
        )}

        {isAuthorised && isOnline && isPageRequiresMenu && (
          <div className="flex items-center space-x-0.5">
            <MainMenu />

            <NotificationButton />

            <div onMouseOver={openPopup} onMouseLeave={closePopup} className="relative shrink-0">
              {isOpen && (
                <AdditionalMenu
                  closePopup={closePopup}
                  openChooseBusinessDialog={openChooseBusinessDialog}
                />
              )}

              <Avatar
                onClick={() => myEmployee && openEmployeeDialog(myEmployee.id)}
                className="ml-1 relative cursor-pointer"
                name={myEmployee?.firstName ?? ''}
                url={myEmployee?.photo ?? ''}
                isLoading={isMyEmployeeLoading}
                reportErrorHandlerFunction={reportError}
              />
            </div>
          </div>
        )}
      </TopBarUI>
      {chooseBusinessDialog}

      {shadowed && <div className="absolute inset-0 bg-gray-900 opacity-40" />}
      {employeeDialog}
    </>
  )
})

interface AdditionalMenuProps {
  closePopup: () => void
  openChooseBusinessDialog: () => void
}

const AdditionalMenu: FC<AdditionalMenuProps> = ({ closePopup, openChooseBusinessDialog }) => {
  const navigate = useNavigate()

  const { data: myPermissions } = useFetchMyPermissions()
  const { enabledModules } = useBusinessModulesSettings()

  const { t } = useTranslation()
  const { data: businesses } = useFetchBusinessesToSwitch()
  const businessesAsEmployee = businesses?.filter(business => business.asEmployee)

  const menu: RouteType[] = routes
    .getMenu(myPermissions, enabledModules)
    .map(item => ({ ...item, name: t(item.name) }))

  const expandedMenu = [
    ...menu.map(route => ({
      ...route,
      onClick: () => navigate(route.path ?? route.children?.[0].path ?? '/'),
    })),
    ...(businessesAsEmployee && businessesAsEmployee.length > 1
      ? [
          {
            Icon: IoSwapHorizontalOutline,
            name: t('switch'),
            onClick: () => openChooseBusinessDialog(),
          },
        ]
      : []),
    {
      Icon: IoLogOutOutline,
      name: t('signOut'),
      onClick: () => {
        store.branch.dropBranchId()
        firebase.signOut()
      },
    },
  ]

  return (
    <>
      <div className="absolute h-10 w-36 -left-24 top-5 -rotate-12" />
      <DropdownMenu
        withoutBackground
        menu={expandedMenu}
        close={closePopup}
        // TODO: переделать на createPortal
        className="top-8 z-10"
      />
    </>
  )
}
