import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from '@expane/widgets'

export const useErrorOpeningDialog = (isError: boolean, closeDialog: () => void) => {
  const { t } = useTranslation()
  const [openSnackBar] = useSnackbar()

  useEffect(() => {
    if (isError) {
      openSnackBar(t('dialogFailed'), 'error', 2000)
      closeDialog()
    }
  }, [closeDialog, isError, openSnackBar, t])
}
