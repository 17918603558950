import {
  BookingUnion,
  NotGroupBooking,
  useFetchClientsBriefsAndCards,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { transformPersonName } from '@expane/logic/utils'
import { Checkbox, EmptyPlaceholder, Table } from '@expane/ui'
import { ColumnDef, OnChangeFn, RowSelectionState } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { Control, UseFormGetValues, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCashOutline, IoListOutline } from 'react-icons/io5'
import { store } from 'store'
import { BookingsSelectionDialogFormType } from 'widgets/BookingsSelectionDialog'
import { BookingStatusIndicator } from './BookingStatusIndicator'
import { getBookingsServices, getServicesWithSubscription } from './logic'
import { BookingServiceCellContent } from 'widgets/BookingServiceListCell'
import { getPaidServices } from 'logic/booking'

interface BookingsSelectionListProps {
  bookings: BookingUnion[]
  onRowClick: (id: number) => void
  isLoading: boolean
  control: Control<BookingsSelectionDialogFormType>
  setValue: UseFormSetValue<BookingsSelectionDialogFormType>
  getValues: UseFormGetValues<BookingsSelectionDialogFormType>
}

export const BookingsSelectionList: FC<BookingsSelectionListProps> = observer(
  ({ bookings, onRowClick, isLoading, control, setValue, getValues }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()
    const formatDate = useDateFormatting()
    const convertNumberToMoney = useConvertNumberToMoney(branchId)

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { data: clients } = useFetchClientsBriefsAndCards(timezone, branchId)

    const selectedBookings =
      useWatch({
        control,
        name: 'selectedBookings',
      }) ?? {}

    const setSelected: OnChangeFn<RowSelectionState> = newSelected => {
      if (typeof newSelected === 'function')
        setValue('selectedBookings', newSelected(getValues('selectedBookings')))
      else setValue('selectedBookings', newSelected)
    }

    const columns = useMemo<ColumnDef<BookingUnion>[]>(
      () => [
        {
          id: 'select',
          header: ({ table }) => (
            <Checkbox
              containerClassName="ml-2"
              checked={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          ),
          cell: ({ row }) => (
            <div className={'flex-centered'}>
              <BookingStatusIndicator status={row.original.status} />
              <Checkbox
                onClick={e => e.stopPropagation()}
                checked={row.getIsSelected()}
                onChange={row.getToggleSelectedHandler()}
              />
            </div>
          ),
          size: 50,
        },
        {
          accessorKey: 'startDate',
          header: t('dateTitle'),
          cell: data => formatDate('shortDateWithoutYear', data.getValue<Date>()),
          size: 140,
        },
        {
          id: 'client',
          accessorFn: booking =>
            booking.isGroupBooking ? (
              <div className="flex items-center">{t('groupBooking.name')}</div>
            ) : (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              transformPersonName(booking.client!)
            ),
          header: t('client.name'),
          cell: data => data.getValue(),
          size: 145,
        },
        {
          accessorKey: 'service.name',
          header: t('service.name'),
          cell: data => <BookingServiceCellContent booking={data.row.original} />,
          size: 185,
        },
        {
          accessorKey: 'location.name',
          header: t('location.name'),
          cell: data => data.getValue(),
          size: 185,
        },
        {
          id: 'employee',
          accessorFn: booking => (booking.employee ? transformPersonName(booking.employee) : '-'),
          header: t('employee.name'),
          cell: data => data.getValue(),
          size: 185,
        },
        {
          id: 'totalSum',
          accessorFn: booking => {
            if (booking && !booking?.isGroupBooking) {
              const client = clients?.find(client => client.id === booking.client?.id)

              const clientSubscriptions = client?.cards ?? []

              const services = getBookingsServices([booking])

              const paidServices = getPaidServices(
                (booking as NotGroupBooking).bookingServices.map(
                  bookingService => bookingService.service,
                ),
                booking.transactions,
              )

              const servicesWithSubscription = getServicesWithSubscription(
                services,
                clientSubscriptions,
                timezone,
              )

              const isAllServicesByCard = servicesWithSubscription.every(service =>
                Boolean(service.bySubscription?.subscriptionId),
              )

              if (booking.isPaid) return 0
              if (isAllServicesByCard) return t('subscription.name').toLocaleLowerCase()
              else {
                const notPaidServices = services.filter(
                  service => !paidServices.some(paidService => paidService.id === service.id),
                )

                // если часть по абонементу, часть нет - то считаем только ту сумму которая за деньги
                const notPaidServicesWithoutSubscription = notPaidServices.filter(
                  service =>
                    !servicesWithSubscription.some(
                      serviceWithSubscription =>
                        serviceWithSubscription.bySubscription?.serviceId === service.id,
                    ),
                )

                return notPaidServicesWithoutSubscription.reduce((sum, item) => {
                  return sum + item.price
                }, 0)
              }
            } else return '-'
          },
          header: () => <div className="text-right w-full">{t('toPay')}</div>,
          cell: data => {
            const toPay = data.getValue<string>()

            return (
              <div className="text-right">
                {typeof toPay === 'number' ? convertNumberToMoney(Number(toPay)) : toPay}
              </div>
            )
          },
        },
        {
          accessorKey: 'isPaid',
          header: () => <span className="text-right w-full">{t('paid')}</span>,
          cell: data =>
            data.getValue() ? (
              <div className="flex justify-end">
                <IoCashOutline size="1.2rem" className="text-primary-500" />
              </div>
            ) : (
              <div className="px-2 text-right">-</div>
            ),
          size: 50,
        },
      ],
      [clients, convertNumberToMoney, formatDate, t, timezone],
    )

    if (bookings.length === 0 && !isLoading) {
      return <EmptyPlaceholder Icon={IoListOutline} text={t('noBookings')} />
    }

    return (
      <div className={'flex flex-col h-full'}>
        <Table
          columns={columns}
          data={bookings}
          isLoading={isLoading}
          onRowClick={booking => onRowClick(booking.id)}
          rowSelection={selectedBookings}
          onRowSelectionChange={setSelected}
        />
      </div>
    )
  },
)
