import { FC } from 'react'
import {
  useFetchAllBranchesEmployeesGroups,
  useFetchAllBranchesLocations,
  useFetchBranches,
  useFetchInterbranchServiceById,
} from '@expane/data'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { InterbranchServicePlaceholder } from './InterbranchServicePlaceholder'
import { InterbranchServiceLogic } from './InterbranchServiceLogic'
import { useFetchMyPermissions } from 'gql/employee'

export const InterbranchServiceDialog: FC<DialogProps> = ({ isCreate, closeDialog, id }) => {
  const { data: interbranchServiceById, isLoading: isLoadingInterbranchServiceById } =
    useFetchInterbranchServiceById(id)
  const { data: branches, isLoading: isLoadingBranches } = useFetchBranches()
  const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()
  const { data: locations, isLoading: isLoadingLocations } = useFetchAllBranchesLocations()
  const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } =
    useFetchAllBranchesEmployeesGroups()

  const isLoading =
    (isLoadingInterbranchServiceById && !isCreate) ||
    isLoadingBranches ||
    isLoadingMyPermissions ||
    isLoadingLocations ||
    isLoadingEmployeeGroups

  const isNoData =
    (!isCreate && !interbranchServiceById) ||
    !branches ||
    !myPermissions ||
    !locations ||
    !employeeGroups

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

  if (isLoading) return <InterbranchServicePlaceholder closeDialog={closeDialog} />
  else if (isNoData) return null

  return (
    <InterbranchServiceLogic
      isCreate={isCreate}
      interbranchServiceById={interbranchServiceById}
      closeDialog={closeDialog}
      myPermissions={myPermissions}
      branches={branches}
      locations={locations}
      employeeGroups={employeeGroups}
    />
  )
}
