import { useFetchBranches } from '@expane/data'
import { SelectDropdown } from '@expane/ui'
import { reloadApp } from 'logic/app'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'

const BranchSwitchSelectWithoutObserver: FC = () => {
  const { t } = useTranslation()
  const { branchId, setBranchId } = store.branch
  const { data: branches, isLoading: isLoadingBranches } = useFetchBranches()

  if (isLoadingBranches) return null
  if (!branches) return <div>{t('errorBoundary.occurred')}</div>

  const handleOnChange = (branchId: number | null) => {
    if (branchId) {
      setBranchId(branchId)
      // TODO: реалізувати більш м'яку зміну бранчі (якщо є в цьому сенс)
      reloadApp()
    }
  }

  return (
    <SelectDropdown
      height="small"
      items={branches}
      value={branchId}
      onSelectChange={handleOnChange}
    />
  )
}

export const BranchSwitchSelect = observer(BranchSwitchSelectWithoutObserver)
