import { FC, PropsWithChildren, useState } from 'react'
import { useHandleClickOutside } from '../../hooks'
import { FadeIn } from '../animations'

export const DialogWithClickOutside: FC<PropsWithChildren<{ onClickOutside: () => void }>> = ({
  children,
  onClickOutside,
}) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  useHandleClickOutside([ref], onClickOutside, true)

  return (
    <FadeIn className="bg-block rounded-lg transform min-w-128" role="dialog" aria-modal="true">
      <div ref={setRef}>{children}</div>
    </FadeIn>
  )
}

export const Dialog: DialogComponent = ({ children }) => {
  return (
    <FadeIn
      className="bg-block rounded-lg transform transition-all min-w-128"
      role="dialog"
      aria-modal="true"
    >
      {children}
    </FadeIn>
  )
}

const DialogTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="p-3 rounded-t-lg border-b-2 border-dialog-color">
      <h3 className="text-lg text-main-color font-medium">{children}</h3>
    </div>
  )
}

interface DialogBodyProps {
  className?: string
}

export const DialogBody: FC<PropsWithChildren<DialogBodyProps>> = ({
  children,
  className = '',
}) => {
  return <div className={'p-3 ' + className}>{children}</div>
}

const DialogFooter: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className = '',
}) => {
  return (
    <div
      className={
        className + ' border-t-2 border-dialog-color rounded-b-lg p-3 flex flex-row-reverse gap-2'
      }
    >
      {children}
    </div>
  )
}

type DialogComponent = FC<PropsWithChildren> & {
  Title: typeof DialogTitle
  Body: typeof DialogBody
  Footer: typeof DialogFooter
}

Dialog.Title = DialogTitle
Dialog.Body = DialogBody
Dialog.Footer = DialogFooter
