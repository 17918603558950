import { FC, ReactElement, useRef } from 'react'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { CloseButton } from '../components/Button/buttons'
import { Dialog } from '../components/Dialog'
import { Modal } from '../components/Modal'
import { usePopupOpenState } from '../hooks/usePopupOpenState'

interface WarningDialogProps {
  title: string
  description: string | ReactElement
  closePopup: () => void
}

const WarningDialog: FC<WarningDialogProps> = ({ description, closePopup, title }) => (
  <Modal close={closePopup}>
    <Dialog>
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Body className="max-w-2xl flex items-center gap-2">
        <IoAlertCircleOutline size="4rem" className="mr-2 text-primary-500" />
        <p className="text-main-color">{description}</p>
      </Dialog.Body>
      <Dialog.Footer>
        <CloseButton onClick={closePopup} />
      </Dialog.Footer>
    </Dialog>
  </Modal>
)

export const useShowWarningPopup = (title: string, description: string | ReactElement) => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const warningModal = isOpen ? (
    <WarningDialog closePopup={closePopup} title={title} description={description} />
  ) : null

  return {
    warningModal,
    showWarningPopup: openPopup,
  }
}

export const useShowCustomWarningPopup = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const titleRef = useRef<string>()
  const descriptionRef = useRef<string | ReactElement>()

  const showCustomWarningPopup = (title: string, description: string | ReactElement) => {
    titleRef.current = title
    descriptionRef.current = description

    openPopup()
  }

  const customWarningModal =
    isOpen && titleRef.current && descriptionRef.current ? (
      <WarningDialog
        closePopup={closePopup}
        title={titleRef.current}
        description={descriptionRef.current}
      />
    ) : null

  return {
    customWarningModal,
    showCustomWarningPopup,
  }
}
