import {
  useFetchCurrentBranchTimezone,
  useFetchScheduleById,
  useGetBranchDefaultSchedule,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { usePopupOpenState } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { useSnackbar } from '@expane/widgets'
import { ScheduleDialogLogic, SchedulerDialogPlaceholder } from './ScheduleDialogLogic'

const ScheduleDialog: FC<DialogProps & { isCreateNewBranchSchedule: boolean }> = ({
  id: scheduleId,
  closeDialog,
  isCreate,
  isCreateNewBranchSchedule,
  onCreate,
}) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: myPermission } = useFetchMyPermissions()
  const { data: scheduleById, isLoading: isLoadingScheduleById } = useFetchScheduleById(scheduleId)
  const { data: branchSchedule, isLoading: isLoadingBranchSchedule } =
    useGetBranchDefaultSchedule(branchId)

  const [openSnackbar] = useSnackbar()

  const isEditingAllowed = Boolean(myPermission?.includes(permissions.schedule.set))

  const isLoading = isLoadingScheduleById || isLoadingBranchSchedule

  if ((isLoading && !isCreate) || !timezone || !branchSchedule)
    return <SchedulerDialogPlaceholder closeDialog={closeDialog} />
  if (!isCreate && !scheduleById) {
    openSnackbar(t('dialogFailed'), 'error')
    return null
  }

  return (
    <ScheduleDialogLogic
      timezone={timezone}
      closeDialog={closeDialog}
      isEditingAllowed={isEditingAllowed}
      isCreate={isCreate}
      isCreateNewBranchSchedule={isCreateNewBranchSchedule}
      onCreate={onCreate}
      scheduleById={scheduleById}
      branchSchedule={branchSchedule}
    />
  )
}

export const useOpenScheduleDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogId = useRef<number | undefined>()
  const dialogIsCreate = useRef<boolean>(false)
  const dialogOnCreate = useRef<(id: number) => void>()
  const dialogIsCreateNewBranchSchedule = useRef<boolean>(false)

  const openEditDialog = (id: number) => {
    dialogId.current = id
    dialogIsCreate.current = false
    openPopup()
  }

  const openCreateDialog = (isCreateNewBranchSchedule = false, onCreate?: (id: number) => void) => {
    dialogId.current = undefined
    dialogIsCreate.current = true
    dialogIsCreateNewBranchSchedule.current = isCreateNewBranchSchedule
    dialogOnCreate.current = onCreate
    openPopup()
  }

  const dialog = isOpen ? (
    <ScheduleDialog
      closeDialog={closePopup}
      id={dialogId.current}
      isCreate={dialogIsCreate.current}
      isCreateNewBranchSchedule={dialogIsCreateNewBranchSchedule.current}
      onCreate={dialogOnCreate.current}
    />
  ) : null

  return { openEditDialog, openCreateDialog, dialog }
}
