import { FadeInTranslateTop } from '@expane/ui'
import { FC, Fragment } from 'react'
import { IconType } from 'react-icons'

type MenuItem = { Icon?: IconType; name: string; onClick?: () => void }

export interface PopupMenuProps {
  menu: MenuItem[]
  close: () => void
  className?: string
  underlineLast?: boolean
  withoutBackground?: boolean
}

export const DropdownMenu: FC<PopupMenuProps> = ({
  menu,
  close,
  className = 'w-44',
  underlineLast = true,
  withoutBackground = false,
}) => {
  return (
    <>
      {!withoutBackground && <div className="fixed inset-0" onClick={close} />}

      <FadeInTranslateTop
        className={
          'flex flex-col absolute right-0 py-1 mt-1.5 overflow-hidden border-2 border-primary-50 dark:border-primary-700 bg-white dark:bg-gray-700 rounded-md ' +
          className
        }
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
        onClick={close}
      >
        {menu.map(({ Icon, name, onClick }, index) => (
          <Fragment key={name}>
            {underlineLast && index === menu.length - 1 && (
              <div className="h-px my-1 border-t border-gray-100 dark:border-gray-600" />
            )}
            <button
              className="flex items-center rounded-md mx-1 px-1.5 py-1 text-sm text-primary-500 bg-hover hover:text-primary-700 hover:dark:text-primary-500 transition-all"
              role="menuitem"
              onClick={onClick}
            >
              {Icon && <Icon size="1rem" className="mr-2" />} {name}
            </button>
          </Fragment>
        ))}
      </FadeInTranslateTop>
    </>
  )
}
