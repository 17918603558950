import {
  EmployeeByIdExtended,
  ServerRolePermissionType,
  useFetchCurrentBranchTimezone,
  useFetchRolePermissions,
  useUpdateRolesPermissions,
} from '@expane/data'
import {
  PermissionPageFormData,
  transformPermissionsForServer,
  transformPermissionsIntoFormData,
} from '@expane/logic/permission/logic'
import { useRolesWithWarningMessages } from '@expane/logic/role'
import {
  Page,
  Paper,
  Spinner,
  TableBody,
  TableContainer,
  TableHeader,
  TableHeaderCell,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyEmployee } from 'gql/employee'
import { useUnsavedChangesOnPageWarning } from 'logic/hooks/useUnsavedChangesOnPageWarning'
import { translateTableTitles } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoWarningOutline } from 'react-icons/io5'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'
import { SelectAllButton } from './Blocks'
import { AnalyticsBlock } from './Blocks/AnalyticsBlock'
import { BookingBlock } from './Blocks/BookingBlock'
import { BusinessBlock } from './Blocks/BusinessBlock'
import { ClientBlock } from './Blocks/ClientBlock'
import { EmployeeBlock } from './Blocks/EmployeeBlock'
import { FinancesBlock } from './Blocks/FinancesBlock'
import { ServiceBlock } from './Blocks/ServiceBlock'
import { StorageBlock } from './Blocks/StorageBlock'
import { allSelectedPermissions } from './logic'
import { RoleSelect } from './RoleSelect'
import { useRelationsWithConfirmation } from './useRelationsWithConfirmation'

export const PermissionsPage: FC = observer(() => {
  const { data: rolePermissions, isLoading: isLoadingRolePermissions } = useFetchRolePermissions()
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: myEmployee, isLoading: isLoadingMyEmployee } = useFetchMyEmployee(
    timezone,
    branchId,
  )

  const isLoading = isLoadingRolePermissions || isLoadingMyEmployee

  return (
    <Page>
      <Paper className="w-full h-full p-3">
        {isLoading ? (
          <Spinner expandCentered />
        ) : rolePermissions && myEmployee ? (
          <ConvenientPermissionPageLogic
            rolePermissions={rolePermissions}
            myEmployee={myEmployee}
          />
        ) : null}
      </Paper>
    </Page>
  )
})

const ConvenientPermissionPageLogic: FC<{
  rolePermissions: ServerRolePermissionType[]
  myEmployee: EmployeeByIdExtended
}> = ({ rolePermissions, myEmployee }) => {
  const { t } = useTranslation()

  const roles = useRolesWithWarningMessages(rolePermissions, t)

  const { mutateAsync: updateRolesPermissionsMutation } = useUpdateRolesPermissions()

  const { control, handleSubmit, setValue, formState } = useForm<PermissionPageFormData>({
    defaultValues: {
      roleId: roles[0].roleId,
      permissions: transformPermissionsIntoFormData({
        roleId: roles[0].roleId,
        rolePermissions,
      }),
    },
  })
  useUnsavedChangesOnPageWarning(formState.isDirty && !formState.isSubmitSuccessful)

  const { confirmationModal, checkRelation, checkRelationBulk } = useRelationsWithConfirmation(
    control,
    setValue,
  )

  const [openSnackBar] = useSnackbar()

  const submitHandler: SubmitHandler<PermissionPageFormData> = async data => {
    const isItMyRole = data.roleId === myEmployee.employeeGroup?.roleId
    const rolePermissionInsertInput = transformPermissionsForServer(data, isItMyRole)

    try {
      await updateRolesPermissionsMutation({
        roleId: data.roleId,
        rolePermissionInsertInput,
        isItMyRole,
      })
      openSnackBar(t('permission.successChange'), 'success', 3000)
    } catch (error) {
      openSnackBar(t('submitError'), 'error', 3000)
    }
  }

  const watchedRole = useWatch({ control, name: 'roleId' })

  const isItOwnerRole = watchedRole === 'owner'

  return (
    <>
      <div className="flex items-end mb-3">
        <RoleSelect
          roles={roles}
          rolePermissions={rolePermissions}
          setValue={setValue}
          control={control}
        />
        {isItOwnerRole && (
          <p className="pb-2 text-black flex items-center gap-1">
            <IoWarningOutline size="1.2rem" className="text-orange-300" />
            {t('ownerCannotBeChanged')}
          </p>
        )}
        <SaveButton
          className="ml-auto"
          onClick={handleSubmit(submitHandler)}
          spinner={formState.isSubmitting}
          disabled={formState.isSubmitting || isItOwnerRole}
        />
      </div>
      <TableContainer className="h-[89%]">
        <TableHeader>
          <tr>
            <th>
              <SelectAllButton onClick={() => setValue('permissions', allSelectedPermissions)} />
            </th>
            {translateTableTitles(TITLES, t).map(({ title, width }, index) => (
              <TableHeaderCell
                style={{ textAlign: 'start' }}
                key={index}
                className={width}
                borderless
              >
                {title}
              </TableHeaderCell>
            ))}
          </tr>
        </TableHeader>
        <TableBody>
          <BookingBlock
            control={control}
            checkRelation={checkRelation}
            checkRelationBulk={checkRelationBulk}
            isItOwnerRole={isItOwnerRole}
            setValue={setValue}
          />
          <ClientBlock
            control={control}
            checkRelation={checkRelation}
            checkRelationBulk={checkRelationBulk}
            isItOwnerRole={isItOwnerRole}
            setValue={setValue}
          />
          <ServiceBlock
            control={control}
            checkRelation={checkRelation}
            checkRelationBulk={checkRelationBulk}
            isItOwnerRole={isItOwnerRole}
            setValue={setValue}
          />
          <EmployeeBlock
            control={control}
            checkRelation={checkRelation}
            checkRelationBulk={checkRelationBulk}
            isItOwnerRole={isItOwnerRole}
            setValue={setValue}
          />
          <FinancesBlock
            control={control}
            checkRelation={checkRelation}
            checkRelationBulk={checkRelationBulk}
            isItOwnerRole={isItOwnerRole}
            setValue={setValue}
          />
          <StorageBlock
            control={control}
            checkRelation={checkRelation}
            checkRelationBulk={checkRelationBulk}
            isItOwnerRole={isItOwnerRole}
            setValue={setValue}
          />
          <AnalyticsBlock
            control={control}
            checkRelation={checkRelation}
            checkRelationBulk={checkRelationBulk}
            isItOwnerRole={isItOwnerRole}
            setValue={setValue}
          />
          <BusinessBlock
            control={control}
            checkRelation={checkRelation}
            checkRelationBulk={checkRelationBulk}
            isItOwnerRole={isItOwnerRole}
            setValue={setValue}
          />
        </TableBody>
      </TableContainer>
      {confirmationModal}
    </>
  )
}

const TITLES: { title: string; width?: string }[] = [
  { title: 'title', width: 'w-40' },
  { title: 'permission.name' },
]
