import { ScheduleDto, ScheduleType } from '@expane/data'
import { convertFloatToMinutes, DAYSWEEK } from '@expane/logic/utils'
import { PlaceholderString, TableRow, Tag } from '@expane/ui'
import { SYSTEM_TAG_COLOR } from 'logic/ui'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  item?: ScheduleDto
  onClick?: (itemId: number) => void
  branchScheduleId: number | undefined
}
export const SchedulePageListItem: FC<Props> = memo(({ item, onClick, branchScheduleId }) => {
  const { t } = useTranslation()

  const isBranchSchedule = item && item.id === branchScheduleId
  let infoContent

  if (item && item.type === ScheduleType.INTERVAL) {
    const workTime =
      convertFloatToMinutes(item.data.timing[0]) + '-' + convertFloatToMinutes(item.data.timing[1])

    infoContent = (
      <div className="flex flex-col justify-start text-sm pl-1">
        <span className="text-primary-700 font-medium">
          {t('schedule.workersThroughDaysOff', {
            workDays: item.data.workDays,
            breakDays: item.data.breakDays,
          })}
        </span>
        <span>{`${t('schedule.workTime')}: ${workTime}`}</span>
      </div>
    )
  } else {
    infoContent = (
      <>
        {DAYSWEEK.map((el, index) => {
          const workTime =
            item &&
            (item.data[index] === null
              ? t('dayOff').toLocaleLowerCase()
              : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                convertFloatToMinutes(item.data[index]![0]) +
                '-' +
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                convertFloatToMinutes(item.data[index]![1]))

          return (
            <div key={index} className="flex flex-col justify-start items-center text-sm pr-2 w-22">
              {item ? (
                <>
                  <span className="text-primary-700 font-medium">{t(el)}</span>
                  <span>{workTime}</span>
                </>
              ) : (
                <PlaceholderString />
              )}
            </div>
          )
        })}
      </>
    )
  }

  return (
    <TableRow onClick={item ? () => onClick?.(item.id) : undefined}>
      <td className="pl-4 py-3 w-2/6">
        <div className="flex items-center">
          <span className="line-clamp-1">{item ? item.name : <PlaceholderString />}</span>

          {isBranchSchedule && (
            <Tag name={t('branchSchedule')} color={SYSTEM_TAG_COLOR} className="ml-1 shrink-0" />
          )}
        </div>
      </td>
      <td className="px-4 w-4/6">
        <div className="flex">{infoContent}</div>
      </td>
    </TableRow>
  )
})
