import React, { FC, useMemo } from 'react'
import {
  BusinessLandingSettingsType,
  ServerOnlineBookingUrlType,
  useDeleteOnlineBookingURLById,
} from '@expane/data'
import { useTranslation } from 'react-i18next'
import { ColumnDef } from '@tanstack/react-table'
import { Table, useShowConfirmationPopup } from '@expane/ui'
import { URLContainer } from 'ui/URLContainer'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { useSnackbar } from '@expane/widgets'
import { getBusinessLandingExtURL } from '@expane/logic/links'
import { config } from 'config'

interface OnlineBookingURLsListProps {
  onlineBookingURLs: ServerOnlineBookingUrlType[]
  businessLandingSettings: BusinessLandingSettingsType
  onRowClick: (id: number) => void
}

export const NewOnlineBookingURLsList: FC<OnlineBookingURLsListProps> = ({
  onlineBookingURLs,
  businessLandingSettings,
  onRowClick,
}) => {
  const { t } = useTranslation()

  const columns = useMemo<ColumnDef<ServerOnlineBookingUrlType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name'),
        cell: data => data.getValue(),
        size: 180,
      },
      {
        accessorKey: 'description',
        header: t('description'),
        cell: data => data.getValue(),
        size: 250,
      },
      {
        accessorKey: 'branchId',
        header: t('link'),
        cell: data => (
          <div
            onClick={e => e.stopPropagation()}
            className="flex items-center justify-between gap-2"
          >
            <URLContainer
              url={getBusinessLandingExtURL(businessLandingSettings, data.row.original, config.ENV)}
            />
            <DeleteButton onlineBookingURL={data.row.original} />
          </div>
        ),
        size: 680,
      },
    ],
    [businessLandingSettings, t],
  )

  return (
    <Table
      containerClassName="max-w-6xl"
      columns={columns}
      data={onlineBookingURLs}
      onRowClick={item => {
        onRowClick(item.id)
      }}
    />
  )
}

const DeleteButton: FC<{ onlineBookingURL: ServerOnlineBookingUrlType }> = ({
  onlineBookingURL,
}) => {
  const { t } = useTranslation()

  const { mutateAsync: deleteOnlineBookingURLById, isLoading } = useDeleteOnlineBookingURLById()

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const [openSnackbar] = useSnackbar()

  const onDelete = async () => {
    try {
      const result = await deleteOnlineBookingURLById(onlineBookingURL.id)

      if (result.deleteOnlineBookingURLById?.id)
        openSnackbar(t('onlineBookingURL.successfullyDeleted'), 'success')
      else openSnackbar(t('submitError'), 'warning')
    } catch {}
  }

  return (
    <>
      <button
        className="flex flex-centered"
        disabled={isLoading}
        onClick={e => {
          e.stopPropagation()
          showConfirmation({
            title: t('onlineBookingURL.deleteConfirmationTitle'),
            description: t('onlineBookingURL.deleteConfirmationDescription', {
              name: onlineBookingURL.name,
            }),
            onConfirm: onDelete,
          })
        }}
      >
        <IoCloseCircleOutline size="1.2rem" className="text-error-500 hover:text-error-700" />
      </button>

      {confirmationModal}
    </>
  )
}
