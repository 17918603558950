import { MutableRefObject, useEffect } from 'react'

export function useAttachFunctionForModal(
  calledFunction: () => void,
  onSubmitRef: MutableRefObject<(() => void) | undefined>,
) {
  useEffect(() => {
    onSubmitRef.current = calledFunction
  }, [onSubmitRef, calledFunction])
}
