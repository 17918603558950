import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  addDays,
  createCurrentDate,
  eachDayOfInterval,
  isSameDay,
  startOfDay,
  subDays,
  subMonths,
} from '@expane/date'

export const PERIODS_OF_TIME = {
  today: { id: 1, name: 'today' },
  yesterday: { id: 2, name: 'yesterday' },
  sevenDays: { id: 3, name: 'periods.7days' },
  thirtyDays: { id: 4, name: 'periods.30days' },
}
export const useGetTransactionsByPeriod = <T extends { date: Date }>(
  transactions: T[],
  timezone: string | undefined,
) => {
  const { periodOfTimeName, periodOfTimeId, onSelectChange } = usePeriodOfTime()

  const [startDate, endDate] = defineStartAndEndDateByPeriodOfTime(periodOfTimeId, timezone)

  const transactionsByPeriod = getTransactionsByPeriod(
    periodOfTimeId,
    startDate,
    endDate,
    transactions,
  )
  return {
    transactionsByPeriod,
    periodOfTimeName,
    periodOfTimeId,
    onSelectChange,
  }
}

export const usePeriodOfTime = () => {
  const { t } = useTranslation()

  const [periodOfTimeId, setPeriodOfTimeId] = useState(PERIODS_OF_TIME.sevenDays.id)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const keyForTranslation = Object.values(PERIODS_OF_TIME).find(
    period => period.id === periodOfTimeId,
  )!.name
  const periodOfTimeName = t(keyForTranslation)

  return {
    periodOfTimeName,
    periodOfTimeId,
    onSelectChange: id => setPeriodOfTimeId(id),
  }
}

export const defineStartAndEndDateByPeriodOfTime = (
  periodOfTimeId: number,
  timezone: string | undefined,
): [Date, Date] => {
  const date = createCurrentDate(timezone)

  if (periodOfTimeId === PERIODS_OF_TIME.today.id)
    return [startOfDay(date), startOfDay(addDays(date, 1))]

  if (periodOfTimeId === PERIODS_OF_TIME.yesterday.id)
    return [startOfDay(subDays(date, 1)), startOfDay(date)]

  if (periodOfTimeId === PERIODS_OF_TIME.sevenDays.id)
    return [startOfDay(subDays(date, 6)), startOfDay(addDays(date, 1))]

  if (periodOfTimeId === PERIODS_OF_TIME.thirtyDays.id)
    return [startOfDay(subDays(date, 29)), startOfDay(addDays(date, 1))]

  if (periodOfTimeId === THREE_MONTHS.id)
    return [startOfDay(subMonths(date, 3)), startOfDay(addDays(date, 1))]

  if (periodOfTimeId === SIX_MONTHS.id)
    return [startOfDay(subMonths(date, 6)), startOfDay(addDays(date, 1))]
  else return [date, date]
}

const getTransactionsByPeriod = <T extends { date: Date }>(
  periodOfTimeId: number,
  startDate: Date,
  endDate: Date,
  transactions: T[],
) => {
  // находим все даты в заданном промежутке,
  // если период = вчера, то баг что конец дня это следующий день, соответсвенно попадают лишние транзакции
  // поэтому начало и конец должен быть один и тот же день
  const periodOfTime = eachDayOfInterval({
    start: startDate,
    end: periodOfTimeId === PERIODS_OF_TIME.yesterday.id ? startDate : endDate,
  })
  // транзакции те которые были созданы в этом промежутке
  return transactions.filter(({ date }) =>
    periodOfTime.some(dateFromPeriod => isSameDay(date, dateFromPeriod)),
  )
}

export const THREE_MONTHS = { id: 5, name: '3 месяца' }
export const SIX_MONTHS = { id: 6, name: '6 месяцев' }
