import { CancelButton, useShortCut } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { SubmitButton } from 'widgets/Buttons'

export const MultiBookingBar: FC<{ onSubmit: () => void }> = observer(({ onSubmit }) => {
  const {
    multiBooking: { additionalBookings, reset },
  } = store
  // изначальный букинг + количество выбранных
  const bookingsCount = additionalBookings.length + 1

  const { t } = useTranslation()

  useShortCut(['Escape'], reset)

  const nodeRef = useRef(null)

  return (
    <Draggable nodeRef={nodeRef}>
      <div
        ref={nodeRef}
        className="bg-block border-2 border-primary-200 rounded-lg absolute bottom-2 right-2 mr-2 mb-2 p-2 flex cursor-move"
      >
        <div className="mr-2 self-stretch flex-centered font-medium text-main-color">
          {t('totalBookings')}: {bookingsCount}
        </div>
        <CancelButton onClick={reset} className="mr-2" />
        {/* без стрелочной функции в onSubmit попадает MouseEvent что вызывает ошибку */}
        <SubmitButton onClick={() => onSubmit()} />
      </div>
    </Draggable>
  )
})
