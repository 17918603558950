import {
  ServerTransactionType,
  TRANSACTION_TYPES,
  useFetchAccounts,
  useFetchClients,
  useFetchClientsPhones,
  useFetchCurrentBranchTimezone,
  useFetchTransactionsByDate,
  useFetchTransactionsToCalcBalance,
} from '@expane/data'
import { addDays, createCurrentDate, isToday, startOfDay } from '@expane/date'
import { checkBusinessIsNotPaid } from '@expane/logic/business'
import { addPhonesToClients } from '@expane/logic/client'
import { checkIsTransactionPayment } from '@expane/logic/finances/filters'
import { ItemWithModules, useBusinessModulesSettings } from '@expane/logic/modules'
import { permissions } from '@expane/logic/permission'
import { Button, Page, ResetFilterButton, SelectDropdown, Spinner } from '@expane/ui'
import { DateTimePicker, useSnackbar } from '@expane/widgets'
import { useFetchCurrentBusiness } from 'gql/business'
import { useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { CashBoxInfoBlock } from 'pages/FinancesPage/FinancesInfoBlock'
import { CashBoxPageList } from 'pages/FinancesPage/List'
import {
  getTransactionPaymentVariationsToDisplay,
  transformTransactionTypesToTreeMenu,
} from 'pages/FinancesPage/logic'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IoAddCircleOutline,
  IoArrowUndoCircleOutline,
  IoCashOutline,
  IoRemoveCircleOutline,
  IoRepeatOutline,
} from 'react-icons/io5'
import { store } from 'store'
import { TreeMenuItem } from 'ui/TreeMenu'
import { translateTreeMenuItems } from 'ui/TreeMenu/logic.common'
import { TreeSelect } from 'ui/TreeSelect'
import { useOpenAddExpenseDialog } from 'widgets/AddExpenseDialog'
import { useOpenAddRevenueDialog } from 'widgets/AddRevenueDialog'
import { ClientSelectDropdown } from 'widgets/ClientSelectDropdown'
import { useOpenMovingBetweenAccountsDialog } from 'widgets/MovingBetweenAccountsDialog'
import { useOpenAddToClientAccountDialog } from 'widgets/OperationsWithClientAccount/AddToClientAccountDialog'
import { useOpenCashOutFromClientAccountDialog } from 'widgets/OperationsWithClientAccount/CashOutFromClientAccountDialog'

export const FinancesPage: FC = observer(() => {
  const [clientId, setClientId] = useState<number | null>(null)
  const [types, setTypes] = useState<TreeMenuItem[] | undefined>(undefined)
  const [paymentVariationId, setPaymentVariationId] = useState<number | undefined>(undefined)
  const [accountId, setAccountId] = useState<number | undefined>(undefined)

  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const [pickedDate, setPickedDate] = useState(createCurrentDate(timezone))

  const startDate = startOfDay(pickedDate)
  const endDate = addDays(startDate, 1)

  const { data: transactions, isLoading } = useFetchTransactionsByDate({
    startDate,
    endDate,
    timezone,
    branchId,
  })
  const { data: transactionsToCalcBalance, isLoading: isLoadingTransactionsToCalcBalance } =
    useFetchTransactionsToCalcBalance(timezone, branchId)
  const { data: clients } = useFetchClients(timezone, branchId)
  const { data: clientsPhones } = useFetchClientsPhones()
  const clientsWithPhones = addPhonesToClients(clients, clientsPhones)

  const { data: accounts } = useFetchAccounts(branchId)

  const { data: myPermissions } = useFetchMyPermissions()

  const { getFilteredItemsByModules, getModuleSetting } = useBusinessModulesSettings()

  const isShowPaymentVariationSelect =
    getModuleSetting('subscriptions') ||
    getModuleSetting('giftCards') ||
    getModuleSetting('consumables') ||
    getModuleSetting('productsForSale')

  const filteredTransactions = useMemo<ServerTransactionType[]>(() => {
    if (!transactions) return []

    const transactionsByClient = clientId
      ? transactions.filter(transaction => transaction.clientId === clientId)
      : [...transactions]

    const transactionsByType = types?.length
      ? transactionsByClient.filter(transaction => types.some(type => transaction.type === type.id))
      : [...transactionsByClient]

    const transactionsByPaymentVariationId = paymentVariationId
      ? transactionsByType.filter(
          transaction =>
            transaction.typeVariation === paymentVariationId &&
            checkIsTransactionPayment(transaction),
        )
      : [...transactionsByType]

    const transactionsByAccountId = accountId
      ? transactionsByPaymentVariationId.filter(
          transaction =>
            transaction.fromAccountId === accountId || transaction.toAccountId === accountId,
        )
      : [...transactionsByPaymentVariationId]

    return transactionsByAccountId
  }, [transactions, clientId, types, paymentVariationId, accountId])

  const isResetButtonDisabled = !clientId && !types && isToday(pickedDate, timezone) && !accountId

  const handleFilterReset = () => {
    if (!timezone) {
      return
    }
    setClientId(null)
    setTypes(undefined)
    setPaymentVariationId(undefined)
    setPickedDate(createCurrentDate(timezone))
    setAccountId(undefined)
  }

  const { openMovingBetweenAccountsDialog, movingBetweenAccountsDialog } =
    useOpenMovingBetweenAccountsDialog()

  const { openAddToClientAccountDialog, addToClientAccountDialog } =
    useOpenAddToClientAccountDialog()

  const { openCashOutFromClientAccountDialog, cashOutFromClientAccountDialog } =
    useOpenCashOutFromClientAccountDialog()

  const { openAddRevenueDialog, addRevenueDialog } = useOpenAddRevenueDialog()

  const { openExpensesDialog, addExpensesDialog } = useOpenAddExpenseDialog()

  const { t } = useTranslation()
  const { data: currentBusiness } = useFetchCurrentBusiness()
  const [openSnackbar] = useSnackbar()

  const transactionTypes = useMemo<TreeMenuItem[]>(
    () => translateTreeMenuItems(transformTransactionTypesToTreeMenu(), t),
    [t],
  )

  const paymentVariations = getTransactionPaymentVariationsToDisplay()

  const paymentVariationsWithModules: ItemWithModules<{ id: number; name: string }>[] =
    paymentVariations.map(({ name, id }) => {
      if (id === TRANSACTION_TYPES.payment.variations.subscription.id)
        return { id, name, modules: ['subscriptions'] }
      if (id === TRANSACTION_TYPES.payment.variations.giftCard.id)
        return { id, name, modules: ['giftCards'] }
      if (id === TRANSACTION_TYPES.payment.variations.product.id)
        return { id, name, modules: ['productsForSale', 'consumables'] }

      return { id, name }
    })

  const filteredPaymentVariationsWithModules = getFilteredItemsByModules(
    paymentVariationsWithModules,
  )

  if (!timezone) return <Spinner expandCentered />
  if (transactions === undefined && !isLoading && !isLoadingTransactionsToCalcBalance) return null

  const isTransactionSetAllowed = myPermissions?.includes(permissions.transaction.set)
  const isMovingBetweenAccountsAllowed = myPermissions?.includes(
    permissions.transaction.betweenAccounts,
  )

  const handleMovingBetweenAccounts = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else openMovingBetweenAccountsDialog()
  }
  const handleAddToClientAccount = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else openAddToClientAccountDialog()
  }
  const handleCashOutFromClientAccount = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else openCashOutFromClientAccountDialog()
  }
  const handleAddRevenue = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else openAddRevenueDialog()
  }
  const handleExpenses = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else openExpensesDialog()
  }

  return (
    <Page>
      <div className="flex flex-wrap items-center">
        <DateTimePicker
          timezone={timezone}
          onChange={data => {
            setPickedDate(data)
          }}
          value={pickedDate}
          className="mr-2 mb-2 w-44 shrink-0"
          nextPreviousButtons
          showQuickButtons
        />

        {isTransactionSetAllowed && (
          <>
            {isMovingBetweenAccountsAllowed && (
              <Button
                type="outline"
                className="w-40 mr-2 mb-2 shrink-0"
                onClick={handleMovingBetweenAccounts}
                twoLines
                Icon={IoRepeatOutline}
              >
                {t('financeActions.movingBetweenAccounts')}
              </Button>
            )}

            <Button
              type="outline"
              className="w-36 mr-2 mb-2 shrink-0"
              onClick={handleAddToClientAccount}
              twoLines
              Icon={IoCashOutline}
            >
              {t('financeActions.addToClientsAccount')}
            </Button>

            <Button
              type="outline"
              className="w-36 mr-2 mb-2 shrink-0"
              onClick={handleCashOutFromClientAccount}
              twoLines
              Icon={IoArrowUndoCircleOutline}
            >
              {t('financeActions.withdrawFromClientsAccount')}
            </Button>

            <Button
              type="outline"
              className="w-28 mr-2 mb-2"
              onClick={handleAddRevenue}
              twoLines
              Icon={IoAddCircleOutline}
            >
              {t('financeActions.addRevenue')}
            </Button>

            <Button
              type="outline"
              className="w-28 mr-2 mb-2"
              onClick={handleExpenses}
              twoLines
              Icon={IoRemoveCircleOutline}
            >
              {t('financeActions.addExpense')}
            </Button>
          </>
        )}
      </div>

      <div className="flex flex-wrap items-center">
        <TreeSelect
          type="MultiPickMode"
          items={transactionTypes}
          onSelected={types => setTypes([...types])}
          selected={types}
          className="w-56 mr-2 mb-2 shrink-0"
          displayedItems={1}
          placeholder={t('type')}
        />
        <ClientSelectDropdown
          inline
          clients={clientsWithPhones}
          value={clientId}
          className="w-56 mr-2 mb-2 shrink-0"
          onChange={value => {
            if (value) {
              setClientId(value)
            } else {
              setClientId(null)
            }
          }}
          isFilter
        />

        {isShowPaymentVariationSelect && (
          <SelectDropdown
            items={filteredPaymentVariationsWithModules.map(variation => ({
              ...variation,
              name: t(variation.name),
            }))}
            value={paymentVariationId}
            placeholder={t('position')}
            noDataMessage={t('noSuchPosition')}
            className="w-56 mr-2 mb-2 shrink-0"
            onSelectChange={value => {
              if (value) {
                setPaymentVariationId(value)
              } else {
                setPaymentVariationId(undefined)
              }
            }}
            isClearable
            isFilter
          />
        )}

        <SelectDropdown
          items={accounts}
          value={accountId}
          placeholder={t('account.name')}
          className="w-56 mr-2 mb-2 shrink-0"
          onSelectChange={value => {
            if (value) {
              setAccountId(value)
            } else {
              setAccountId(undefined)
            }
          }}
          isClearable
          isFilter
        />

        <ResetFilterButton
          className="w-28 mb-2"
          onClick={handleFilterReset}
          disabled={isResetButtonDisabled}
        />
      </div>

      <div className="max-h-full overflow-hidden flex justify-between">
        <CashBoxInfoBlock
          date={pickedDate}
          transactions={transactions ?? []}
          transactionsToCalcBalance={transactionsToCalcBalance ?? []}
        />

        <div className="w-4/5 mb-2">
          <CashBoxPageList
            transactions={filteredTransactions}
            isLoading={isLoading || isLoadingTransactionsToCalcBalance}
            branchId={branchId}
          />
        </div>
      </div>

      {cashOutFromClientAccountDialog}
      {addToClientAccountDialog}
      {movingBetweenAccountsDialog}
      {addRevenueDialog}
      {addExpensesDialog}
    </Page>
  )
})
