import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { ServerClientNoteInsertInput } from '../../generated/graphql-types'
import { CLIENT_NOTES_BRIEFS_QUERY_KEY, CLIENT_NOTES_QUERY_KEY } from './queryKeys'

export function useCreateClientNote() {
  return useMutation(
    async (
      clientNoteInsertInput: ServerClientNoteInsertInput,
    ): Promise<{ insertClientNote: { id: number } }> => {
      return await request(
        gql`
          mutation ($clientNoteInsertInput: clientNote_insert_input!) {
            insertClientNote(object: $clientNoteInsertInput) {
              id
            }
          }
        `,
        { clientNoteInsertInput },
      )
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          CLIENT_NOTES_QUERY_KEY,
          CLIENT_NOTES_BRIEFS_QUERY_KEY,
          { clientId: variables.clientId },
        ])
        queryClient.invalidateQueries([CLIENT_NOTES_QUERY_KEY, { clientId: variables.clientId }])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateClientNoteById() {
  return useMutation(
    async (dto: {
      id: number
      text: string
    }): Promise<{ updateClientNoteById: { id: number; clientId: number } }> => {
      return await request(
        gql`
          mutation ($id: Int!, $text: String!) {
            updateClientNoteById(_set: { text: $text }, pk_columns: { id: $id }) {
              id
              clientId
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries([
          CLIENT_NOTES_QUERY_KEY,
          CLIENT_NOTES_BRIEFS_QUERY_KEY,
          { clientId: data.updateClientNoteById.clientId },
        ])
        queryClient.invalidateQueries([
          CLIENT_NOTES_QUERY_KEY,
          { clientId: data.updateClientNoteById.clientId },
        ])
      },
      onError: reportGqlError,
    },
  )
}

export function useArchiveClientNoteById() {
  return useMutation(
    async (id: number): Promise<{ updateClientNoteById: { id: number; clientId: number } }> => {
      return await request(
        gql`
          mutation ($id: Int!) {
            updateClientNoteById(_set: { archived: "now()" }, pk_columns: { id: $id }) {
              id
              clientId
            }
          }
        `,
        { id },
      )
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries([
          CLIENT_NOTES_QUERY_KEY,
          CLIENT_NOTES_BRIEFS_QUERY_KEY,
          { clientId: data.updateClientNoteById.clientId },
        ])
        queryClient.invalidateQueries([
          CLIENT_NOTES_QUERY_KEY,
          { clientId: data.updateClientNoteById.clientId },
        ])
      },
      onError: reportGqlError,
    },
  )
}
