import { FC } from 'react'
import { useRolesWithWarningMessages } from '@expane/logic/role'
import { useGetIsShownProductsByModules } from '@expane/logic/modules'
import { useOpenGroupSalarySettingDialog } from 'widgets/SalarySettingsDialogs/EmployeeGroupSalarySettingDialog'
import { useSnackbar } from '@expane/widgets'
import {
  ServerEmployeeGroupByIdType,
  ServerRolePermissionType,
  ServerStorageType,
  useArchiveEmployeeGroup,
  useCreateEmployeeGroup,
  useUpdateEmployeeGroup,
  useUpdateOwnerEmployeeGroup,
} from '@expane/data'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { onlyOwnersPermissions, OWNER_ROLE_ID, permissions } from '@expane/logic/permission'
import { useShowArchiveConfirmationPopup } from 'logic/hooks/popup/useShowArchiveConfirmationPopup'
import {
  Button,
  CloseButton,
  Dialog,
  Input,
  Modal,
  SelectDropdown,
  Textarea,
  useShowWarningPopup,
} from '@expane/ui'
import { validateLessOrEqual100 } from 'logic/form'
import { checkOnlyPositiveAmount, PLACEHOLDERS } from '@expane/logic/form'
import { StorageSelectDropdown } from 'widgets/StoragesSelectDropdown'
import { ArchiveButton, RestoreButton, SaveButton } from 'widgets/Buttons'
import { CoinsIcon } from 'ui/Icons'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { useTranslation } from 'react-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

interface EmployeeGroupDialogLogicProps extends Omit<DialogProps, 'id'> {
  employeeGroupById: ServerEmployeeGroupByIdType | undefined
  myPermissions: string[]
  rolePermissions: ServerRolePermissionType[]
  storages: ServerStorageType[]
  defaultStorageId: number
  branchId: number
}

type EmployeeGroupsFormValues = {
  name: string
  role: number
  storageId: number | undefined
  maxDiscount: string
  description: string
}

export const EmployeeGroupDialogLogic: FC<EmployeeGroupDialogLogicProps> = ({
  employeeGroupById,
  isCreate,
  myPermissions,
  rolePermissions,
  closeDialog,
  storages,
  defaultStorageId,
  onCreate,
  branchId,
}) => {
  const { t } = useTranslation()
  const roles = useRolesWithWarningMessages(rolePermissions, t)

  const { isConsumablesEnabled } = useGetIsShownProductsByModules()

  const { formState, control, handleSubmit } = useForm<EmployeeGroupsFormValues>({
    defaultValues: {
      name: employeeGroupById?.name ?? '',
      role: roles.find(r => r.roleId === employeeGroupById?.roleId)?.id,
      maxDiscount: employeeGroupById?.maxDiscount?.toString() ?? '',
      description: employeeGroupById?.description ?? '',
      storageId: employeeGroupById?.storageId ?? undefined,
    },
  })

  const { groupSalarySettingDialog, openGroupSalarySettingDialog } =
    useOpenGroupSalarySettingDialog()

  const [openSnackbar] = useSnackbar()

  const { mutateAsync: createMutation } = useCreateEmployeeGroup()
  const { mutateAsync: updateMutation } = useUpdateEmployeeGroup()
  const { mutateAsync: ownerUpdateMutation } = useUpdateOwnerEmployeeGroup()
  const { mutateAsync: archiveEmployeeGroup } = useArchiveEmployeeGroup()

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

  const isItOwnerRole = !isCreate && employeeGroupById?.roleId === OWNER_ROLE_ID
  const isItOwner = myPermissions.includes(onlyOwnersPermissions.owner.set)

  const mutateEmployeeGroup: SubmitHandler<EmployeeGroupsFormValues> = async ({
    name,
    description,
    maxDiscount,
    role,
    storageId,
  }) => {
    const roleId = roles.find(r => r.id === role)?.roleId

    if (!isItOwnerRole && !roleId) {
      openSnackbar(t('submitError'), 'error')

      return
    }

    if (isCreate) {
      let result: { insertEmployeeGroup?: { id?: number | undefined } } | undefined

      try {
        result = await createMutation({
          name,
          description,
          maxDiscount: Number(maxDiscount) || null,
          roleId,
          storageId,
        })
      } catch {
      } finally {
        if (result?.insertEmployeeGroup?.id) {
          onCreate?.(result?.insertEmployeeGroup?.id)
          openSnackbar(t('employeeGroup.createSuccess'), 'success')
        } else openSnackbar(t('submitError'), 'error')
      }
    } else if (employeeGroupById) {
      let result:
        | {
            updateEmployeeGroupById?: { id?: number | undefined }
            updateEmployeeGroupOwnerView?: { affected_rows?: number | undefined }
          }
        | undefined

      try {
        const commonSetInput = {
          name,
          description,
          maxDiscount: Number(maxDiscount) || null,
          storageId: storageId ?? null,
        }

        if (isItOwnerRole) {
          result = await ownerUpdateMutation({
            id: employeeGroupById.id,
            employeeGroupSetInput: commonSetInput,
          })
        } else {
          result = await updateMutation({
            id: employeeGroupById.id,
            employeeGroupSetInput: {
              ...commonSetInput,
              roleId,
            },
          })
        }
      } catch {
      } finally {
        if (
          (isItOwnerRole && result?.updateEmployeeGroupOwnerView?.affected_rows) ||
          result?.updateEmployeeGroupById?.id
        ) {
          openSnackbar(t('employeeGroup.updateSuccess'), 'success')
        } else openSnackbar(t('submitError'), 'error')
      }
    }

    closeDialog()
  }

  const mutateArchiveEmployeeGroup = async (archive: boolean) => {
    if (employeeGroupById)
      try {
        await archiveEmployeeGroup({ id: employeeGroupById.id, archive })

        openSnackbar(
          archive
            ? t('employeeGroup.archivingSuccessful', { name: employeeGroupById?.name })
            : t('employeeGroup.restorationSuccessful', { name: employeeGroupById?.name }),
          'success',
        )
      } catch (_) {
        openSnackbar(t('submitError'), 'error')
      }

    closeDialog()
  }

  const { showArchiveConfirmationPopup, archiveConfirmationModal } =
    useShowArchiveConfirmationPopup(employeeGroupById?.name ?? '', () =>
      mutateArchiveEmployeeGroup(true),
    )

  const { warningModal, showWarningPopup } = useShowWarningPopup(
    t('warning'),
    t('employeeGroup.archivingWarning'),
  )

  const { warningModal: otherBranchWarningModal, showWarningPopup: showOtherBranchWarningPopup } =
    useShowWarningPopup(t('warning'), t('employeeGroup.otherBranchArchivingWarning'))

  const handleOnArchive = () => {
    const employees = employeeGroupById?.employees?.filter(employee => employee.archived === null)

    const employeesInOtherBranches = employees?.some(employee =>
      employee.employeeSchedules.some(eS => eS.branchId !== branchId && eS.endDate === null),
    )

    if (employees?.length !== 0)
      if (employeesInOtherBranches) showOtherBranchWarningPopup()
      else showWarningPopup()
    else showArchiveConfirmationPopup()
  }

  const isEditingAllowed = isItOwnerRole
    ? isItOwner
    : myPermissions.includes(permissions.employee.set)

  const isArchivingAllowed = myPermissions.includes(permissions.employee.archive)
  const isSalaryEditingAllowed = Boolean(myPermissions.includes(permissions.salary.set))

  const showSalaryButton =
    isSalaryEditingAllowed &&
    Boolean(employeeGroupById?.employees) &&
    employeeGroupById &&
    employeeGroupById.employees.length > 0 &&
    !employeeGroupById?.archived

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (isEditingAllowed && !formState.isSubmitting && formState.isDirty)
            handleSubmit(mutateEmployeeGroup)()
        }}
        animation="onlyFadeOut"
      >
        <Dialog>
          <Dialog.Title>{t('employeeGroup.name')}</Dialog.Title>

          <Dialog.Body className="w-180">
            <div className="flex">
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={t('title')}
                    placeholder={t('placeholders.employeeGroup')}
                    required
                    containerClassName="w-1/2 pr-1"
                    errorMessage={{
                      isShown: Boolean(formState.errors.name),
                      text: t('formError.required'),
                    }}
                    value={value}
                    onChange={onChange}
                    disabled={!isEditingAllowed}
                    autoFocus
                  />
                )}
              />
              {isItOwnerRole ? (
                <Input
                  containerClassName="w-1/2 pl-1"
                  label={t('role.name')}
                  required
                  disabled
                  value={t('roles.owner')}
                />
              ) : (
                <Controller
                  name="role"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <SelectDropdown
                      label={t('role.name')}
                      required
                      items={roles}
                      onSelectChange={onChange}
                      value={value}
                      placeholder={t('placeholders.role')}
                      noDataMessage={t('noRole')}
                      className="w-1/2 pl-1"
                      errorMessage={{
                        isShown: Boolean(formState.errors.role),
                        text: t('formError.required'),
                      }}
                      disabled={!isEditingAllowed}
                    />
                  )}
                />
              )}
            </div>

            <div className="flex">
              <Controller
                name="maxDiscount"
                control={control}
                rules={{
                  validate: { validateLessOrEqual100, checkOnlyPositiveAmount },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label={t('maxDiscount')}
                    type="number"
                    placeholder={PLACEHOLDERS.maxDiscount}
                    value={value.toString()}
                    onChange={onChange}
                    disabled={!isEditingAllowed}
                    containerClassName="w-1/2 pr-1"
                    errorMessage={{
                      isShown: Boolean(error),
                      text:
                        error?.type === 'validateLessOrEqual100'
                          ? t('maxDiscountError')
                          : t('formError.invalidValue'),
                    }}
                    hint={t('maxDiscountHint')}
                  />
                )}
              />

              {isConsumablesEnabled && (
                <Controller
                  control={control}
                  name="storageId"
                  render={({ field: { onChange, value } }) => (
                    <StorageSelectDropdown
                      value={value}
                      onSelectChange={onChange}
                      label={t('storageForConsumables')}
                      storages={storages}
                      defaultStorageId={defaultStorageId}
                      hint={t('storageForConsumablesHint')}
                      className="w-1/2 pl-1"
                      isClearable
                      disabled={!isEditingAllowed}
                    />
                  )}
                />
              )}
            </div>

            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  label={t('description')}
                  placeholder={t('placeholders.employeeGroupDescription')}
                  value={value}
                  onChange={onChange}
                  disabled={!isEditingAllowed}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            {isEditingAllowed && (
              <SaveButton
                onClick={handleSubmit(mutateEmployeeGroup)}
                disabled={formState.isSubmitting || !formState.isDirty}
                spinner={formState.isSubmitting}
                isCreate={isCreate}
              />
            )}

            <CloseButton className="ml-auto" onClick={closePopups} />

            {showSalaryButton ? (
              <Button
                type="outline"
                onClick={() => openGroupSalarySettingDialog(employeeGroupById?.id)}
                Icon={CoinsIcon}
              >
                {t('setUpSalary')}
              </Button>
            ) : null}

            {isArchivingAllowed &&
              !isItOwnerRole &&
              !isCreate &&
              (employeeGroupById?.archived ? (
                <RestoreButton onClick={() => mutateArchiveEmployeeGroup(false)} />
              ) : (
                <ArchiveButton onClick={handleOnArchive} />
              ))}
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {groupSalarySettingDialog}
      {confirmPopup}
      {warningModal}
      {otherBranchWarningModal}
      {archiveConfirmationModal}
    </>
  )
}
