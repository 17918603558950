import { useCheckIsOneBranchInBusiness } from '@expane/logic/branch'
import { FEATURE_FLAGS, useFeatureFlags } from '@expane/logic/featureFlags'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { Paper } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import { NavLink, NavLinkProps, Outlet } from 'react-router-dom'
import { routes } from 'routes/logic'

export const SettingsPage: FC = () => {
  const { t } = useTranslation()
  const { data: myPermissions } = useFetchMyPermissions()

  const { isOneBranchInBusiness } = useCheckIsOneBranchInBusiness()
  const { enabledModules } = useBusinessModulesSettings()

  const { getFeatureFlag } = useFeatureFlags()
  const isEnableBusinessModules = getFeatureFlag(FEATURE_FLAGS.enableBusinessModules)

  const checkedRoutes = routes.getSettingsRoutes({
    permissions: myPermissions,
    enabledModules,
    isOneBranchInBusiness,
    isEnableBusinessModules,
    getFeatureFlag,
  })

  return (
    <div className="p-page flex flex-row items-start max-h-full">
      <Paper className="py-4 pl-4">
        <nav className="">
          <ul>
            {checkedRoutes.map(route => (
              <SettingsItem
                key={route.path}
                to={route.path}
                Icon={route.Icon}
                title={t(route.name)}
              />
            ))}
          </ul>
        </nav>
      </Paper>

      <Outlet />
    </div>
  )
}

const basicItemStyle =
  'block w-64 py-2 pr-2 text-md font-medium border-white dark:border-gray-600 border-l-4 border-solid transition-all'

export const SettingsWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper className="ml-4 flex flex-1 max-h-full">
      <div className="flex-1 p-4 overflow-y-auto">{children}</div>
    </Paper>
  )
}

const SettingsItem: FC<NavLinkProps & { Icon?: IconType; title: string }> = ({
  title,
  to,
  Icon,
}) => {
  const linkStyle =
    basicItemStyle +
    ' text-label-color hover:text-primary-500 bg-hover hover:border-primary-500 hover:dark:border-primary-500/20'
  const activeLinkStyle =
    basicItemStyle +
    ' bg-primary-50 dark:bg-primary-500/30 border-primary-500 dark:border-primary-500 text-primary-500'

  return (
    <NavLink to={to} className={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}>
      <li className="flex px-2 flex-row items-center justify-start">
        {Icon && <Icon size="1.5rem" className={'shrink-0'} />}
        <h2 className="pl-2 text-current">{title}</h2>
      </li>
    </NavLink>
  )
}
