import {
  NotGroupBooking,
  useFetchCurrentBranchTimezone,
  useFetchDeactivatedServices,
  useFetchExtendedServices,
  useFetchServiceGroups,
  useFetchUnitedBookingById,
} from '@expane/data'
import { Consumable, getDefaultConsumablesForBookingForm } from '@expane/logic/payment/booking'
import { Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useCheckBookingDateEditable } from 'logic/calendar'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { FC, MutableRefObject, useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import type { BookingUpdateData } from 'store/UnitedBookingUpdate'
import { TreeMenuItem } from 'ui/TreeMenu'
import { MultiBookingDialogPlaceholder } from 'widgets/BookingDialogPlaceholder'
import { BookingMultiServicesBody } from 'widgets/BookingMultiServicesDialog/Body'
import { BookingMultiServicesFooter } from 'widgets/BookingMultiServicesDialog/Footer'
import { generateDefaultValues, getDefaultTransformedServices } from './logic'

export interface BookingMultiServicesInitialDtoCreate {
  isCreate: true
  fromCalendar: false
  clientId: undefined
  data: undefined
  id: undefined
  startDate: Date
  locationId: number | undefined
  employeeId: number | undefined
  showCanceled: false
}
interface BookingMultiServiceDtoFromCalendar {
  isCreate: true
  fromCalendar: true
  clientId: number
  data: BookingUpdateData[]
  id: undefined
  startDate: undefined
  locationId: undefined
  employeeId: undefined
  showCanceled: false
}

export interface BookingMultiServicesInitialDtoUpdate {
  isCreate: false
  fromCalendar: false
  clientId: undefined
  data: undefined
  id: number
  startDate: undefined
  locationId: undefined
  employeeId: undefined
  showCanceled: boolean
}

export type BookingMultiServicesInitialDto =
  | BookingMultiServicesInitialDtoCreate
  | BookingMultiServicesInitialDtoUpdate
  | BookingMultiServiceDtoFromCalendar

interface BookingMultiServicesDialogProps {
  closeDialog: () => void
  initialData: BookingMultiServicesInitialDto
}

const BookingMultiServicesDialog: FC<BookingMultiServicesDialogProps> = ({
  initialData,
  closeDialog,
}) => {
  const mutableCloseFunction = useRef(closeDialog)

  const { isCreate, id, showCanceled } = initialData

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: unitedBookingById, isLoading: isLoadingUnitedBookingById } =
    useFetchUnitedBookingById(id, timezone, branchId, showCanceled)
  const { isLoading: isLoadingExtendedServices } = useFetchExtendedServices(branchId, 'all')
  const { isLoading: isLoadingServiceGroups } = useFetchServiceGroups(branchId)
  const { isLoading: isLoadingDeactivatedServices } = useFetchDeactivatedServices(
    branchId,
    'all',
    !initialData.isCreate,
  )

  const isLoading =
    isLoadingUnitedBookingById ||
    isLoadingExtendedServices ||
    isLoadingServiceGroups ||
    isLoadingDeactivatedServices

  const showPlaceholder = (!isCreate && isLoading) || !timezone

  const closeModal = () => {
    // если передавать ref в модал, компонент не реагирует, что поменялась функция закрытия
    // и будет вызываться первая фун-ция которая была в ref
    mutableCloseFunction.current()
  }

  return (
    <Modal close={closeModal}>
      <Dialog>
        {showPlaceholder ? (
          <MultiBookingDialogPlaceholder closeDialog={closeDialog} />
        ) : (
          <BookingMultiServicesDialogLogic
            closeDialog={closeDialog}
            initialData={initialData}
            mutableCloseFunction={mutableCloseFunction}
            unitedBookingById={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              unitedBookingById!
            }
            timezone={timezone}
          />
        )}
      </Dialog>
    </Modal>
  )
}

const BookingMultiServicesDialogLogic: FC<
  BookingMultiServicesDialogProps & {
    mutableCloseFunction: MutableRefObject<() => void>
    unitedBookingById: { id: number; bookings: Array<NotGroupBooking> }
    timezone: string
  }
> = ({ closeDialog, initialData, mutableCloseFunction, unitedBookingById, timezone }) => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()

  const { isCreate, startDate, fromCalendar } = initialData

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  let bookingStartDate = isCreate ? startDate! : unitedBookingById?.bookings[0].startDate
  if (fromCalendar) bookingStartDate = initialData.data[0].startDate

  const dateIsEditable = useCheckBookingDateEditable(bookingStartDate)

  const { data: services } = useFetchExtendedServices(branchId, 'all')
  const { data: archivedServices } = useFetchDeactivatedServices(
    branchId,
    'all',
    !dateIsEditable && !isCreate,
  )
  const { data: serviceGroups } = useFetchServiceGroups(branchId)

  const transformedBookings = useMemo(
    () =>
      unitedBookingById?.bookings.map(booking => ({
        bookingId: booking.id,
        locationId: booking.location?.id,
        employeeId: booking.employee?.id ?? null,
        startDate: booking.startDate,
        services: getDefaultTransformedServices({
          isCreate,
          dateIsEditable,
          services,
          archivedServices,
          bookingById: booking as NotGroupBooking,
          serviceGroups,
        }),
        consumables: getDefaultConsumablesForBookingForm(isCreate, booking),
        duration: booking.duration.toString(),
      })),
    [
      archivedServices,
      dateIsEditable,
      isCreate,
      serviceGroups,
      services,
      unitedBookingById?.bookings,
    ],
  )

  const form = useForm<BookingMultiServicesDialogFormValues>({
    defaultValues: generateDefaultValues({
      initialData,
      serviceGroups,
      services,
      archivedServices,
      unitedBookingById,
      timezone,
      canEditPast: dateIsEditable,
    }),
  })

  // To synchronize startDates after dragging booking cards in calendar
  useEffect(() => {
    if (!isCreate && transformedBookings) form.setValue('multiServicesDto', transformedBookings)
  }, [form, isCreate, transformedBookings])

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(form.formState, closeDialog)

  useEffect(() => {
    // посылаем модифицированное закрытие через нажатие на фон
    mutableCloseFunction.current = closePopups
  }, [closePopups, mutableCloseFunction])

  return (
    <>
      <Dialog.Title>
        <div className="flex items-center justify-between">
          <p>{t('multiServicesBooking.name')}</p>
        </div>
      </Dialog.Title>
      <BookingMultiServicesBody
        form={form}
        timezone={timezone}
        initialData={initialData}
        closeDialog={closeDialog}
        initialBookings={unitedBookingById?.bookings ?? []}
      />
      <BookingMultiServicesFooter
        isBookingTodayOrLater={dateIsEditable}
        closeDialog={closeDialog}
        control={form.control}
        handleSubmit={form.handleSubmit}
        initialData={initialData}
        initialBookings={unitedBookingById?.bookings ?? []}
        getValues={form.getValues}
      />
      {confirmPopup}
    </>
  )
}

export const useOpenBookingMultiServiceDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  // edit
  const dialogBookingId = useRef<number>()
  const dialogShowCanceled = useRef<boolean>()

  // create
  const dialogStartDate = useRef<Date>()
  const dialogLocationId = useRef<number>()
  const dialogEmployeeId = useRef<number>()

  // from calendar
  const dialogClientId = useRef<number>()
  const dialogData = useRef<BookingUpdateData[]>()

  // common
  const dialogIsCreate = useRef<boolean>()
  const dialogFromCalendar = useRef<boolean>()

  const onCloseCreateDialog = useRef<() => void>()
  const onCloseEditDialog = useRef<() => void>()

  const openMultiServicesCreateDialogFromCalendar = (
    clientId: number,
    data: BookingUpdateData[],
  ) => {
    dialogFromCalendar.current = true
    dialogIsCreate.current = true
    dialogClientId.current = clientId
    dialogData.current = data
    onCloseCreateDialog.current = undefined
    openPopup()
  }

  const openEditBookingMultiServicesDialog = (
    bookingId: number,
    showCanceled?: boolean,
    onClose?: () => void,
  ) => {
    dialogIsCreate.current = false
    dialogFromCalendar.current = false
    dialogBookingId.current = bookingId
    onCloseEditDialog.current = onClose
    dialogShowCanceled.current = showCanceled
    openPopup()
  }

  const openCreateBookingMultiServicesDialog = ({
    startDate,
    employeeId,
    locationId,
    onClose,
  }: {
    startDate: Date
    employeeId: number | undefined
    locationId: number | undefined
    onClose?: () => void
  }) => {
    dialogBookingId.current = undefined
    dialogStartDate.current = startDate
    dialogEmployeeId.current = employeeId
    dialogLocationId.current = locationId
    dialogIsCreate.current = true
    dialogFromCalendar.current = false
    onCloseCreateDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()

    if (dialogIsCreate.current === true) onCloseCreateDialog.current?.()
    if (dialogIsCreate.current === false) onCloseEditDialog.current?.()
  }

  const bookingMultiServicesDialog = isOpen ? (
    <BookingMultiServicesDialog
      closeDialog={closeDialog}
      // @ts-expect-error ругается на isCreate
      initialData={{
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        isCreate: dialogIsCreate.current!,
        id: dialogBookingId.current,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fromCalendar: dialogFromCalendar.current!,
        clientId: dialogClientId.current,
        data: dialogData.current,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        startDate: dialogStartDate.current!,
        locationId: dialogLocationId.current,
        employeeId: dialogEmployeeId.current,
        showCanceled: dialogShowCanceled.current ?? false,
      }}
    />
  ) : null
  return {
    openEditBookingMultiServicesDialog,
    openCreateBookingMultiServicesDialog,
    openMultiServicesCreateDialogFromCalendar,
    bookingMultiServicesDialog,
  }
}

export interface MultiServiceDto {
  bookingId: number | undefined
  locationId: number | undefined
  employeeId: number | null
  duration: string
  startDate: Date
  consumables: Consumable[]
  services: TreeMenuItem[]
}

export type BookingMultiServicesDialogFormValues = {
  clientId: number | null
  date: Date
  multiServicesDto: MultiServiceDto[]
  note: string
}
