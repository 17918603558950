import {
  CloseButton,
  Dialog,
  PlaceholderString,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const DialogPlaceholder: FC<{ close: () => void }> = ({ close }) => {
  const { t } = useTranslation()

  return (
    <Dialog>
      <Dialog.Title>
        <div className="flex items-center">
          {t('inventory.name')} <PlaceholderString />
        </div>
      </Dialog.Title>
      <Dialog.Body className="w-256">
        <div className="h-64">
          <PlaceholderList />
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <CloseButton onClick={close} />
      </Dialog.Footer>
    </Dialog>
  )
}

const PlaceholderList: FC = () => {
  const { t } = useTranslation()

  return (
    <TableContainer>
      <TableHeader>
        <tr>
          <TableHeaderCell>{t('vendorCode')}</TableHeaderCell>
          <TableHeaderCell>{t('product.name')}</TableHeaderCell>
          <TableHeaderCell>{t('unit.shortName')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('price')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('calcLeftover')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('actualLeftover')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('difference')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('excessShortage')}</TableHeaderCell>
        </tr>
      </TableHeader>
      <TableBody>
        <ListItem />
        <ListItem />
        <ListItem />
      </TableBody>
    </TableContainer>
  )
}

const ListItem: FC = () => {
  return (
    <TableRow>
      <TableCell>
        <PlaceholderString width="small" />
      </TableCell>
      <TableCell>
        <PlaceholderString width="small" />
      </TableCell>
      <TableCell>
        <PlaceholderString width="small" />
      </TableCell>
      <TableCell>
        <PlaceholderString width="small" />
      </TableCell>
      <TableCell>
        <PlaceholderString width="small" />
      </TableCell>
      <TableCell>
        <PlaceholderString width="small" />
      </TableCell>
      <TableCell>
        <PlaceholderString width="small" />
      </TableCell>
      <TableCell>
        <PlaceholderString width="small" />
      </TableCell>
    </TableRow>
  )
}
