import { getReceiptPngUrl } from '@expane/data'
import { CloseButton, Dialog, InputLabel, Spinner } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoPrintOutline } from 'react-icons/io5'
import { URLContainer } from 'ui/URLContainer'

export const ReceiptDialogPlaceholder: FC<{ receiptId: string; closeDialog: () => void }> = ({
  receiptId,
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>
        <div className="flex items-center">
          {t('receipt.title')}
          <div className="ml-auto text-primary-400">
            <IoPrintOutline size="1.2rem" />
          </div>
        </div>
      </Dialog.Title>
      <Dialog.Body className="flex flex-col items-center justify-center">
        <div className="flex mb-2">
          <InputLabel label={t('receipt.link')} className="mr-2" />
          <URLContainer className="w-64" url={getReceiptPngUrl(receiptId)} />
        </div>

        <div className="w-80 h-128 overflow-auto">
          <Spinner expandCentered />
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
