import { ServerServiceByIdType } from '@expane/data'
import { TreeMenuItem } from 'ui/TreeMenu'
import { groupInitialTreeItems } from 'ui/TreeMenu/logic.common'
import { generateDefaultServiceEmployees } from 'ui/TreeMenu/logic.employee'

// TODO: нужны ли эти функции? оптимизировать
export const getDefaultEmployees = (
  serviceById: ServerServiceByIdType | undefined,
  employeeTreeItems: TreeMenuItem[],
) => {
  if (!serviceById) return []

  const employeeIds: number[] = []
  for (const serviceEmployee of serviceById.serviceEmployees) {
    if (!serviceEmployee.employee) continue
    employeeIds.push(serviceEmployee.employee.id)
  }

  return groupInitialTreeItems(
    generateDefaultServiceEmployees(employeeIds, employeeTreeItems),
    employeeTreeItems,
  )
}

export const getDefaultLocations = (
  serviceById: ServerServiceByIdType | undefined,
  locationTreeItems: TreeMenuItem[],
) => {
  if (!serviceById) return []

  const locationIds: number[] = []

  for (const serviceLocation of serviceById.serviceLocations) {
    if (!serviceLocation.location) continue
    locationIds.push(serviceLocation.location.id)
  }

  return groupInitialTreeItems(
    generateDefaultServiceEmployees(locationIds, locationTreeItems),
    locationTreeItems,
  )
}

export const getDefaultServicePermissions = (
  serviceById: ServerServiceByIdType | undefined,
  employeeTreeMenuItems: TreeMenuItem[],
) => {
  if (!serviceById) return []

  const employeeIds: number[] = []

  for (const serviceEmployee of serviceById.servicePermissions) {
    if (!serviceEmployee.employee) continue
    employeeIds.push(serviceEmployee.employee.id)
  }

  return groupInitialTreeItems(
    generateDefaultServiceEmployees(employeeIds, employeeTreeMenuItems),
    employeeTreeMenuItems,
  )
}
