import {
  useFetchArchivedEmployees,
  useFetchArchivedEmployeesGroups,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchExtendedEmployeesWithOwner,
} from '@expane/data'
import { Spinner } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { store } from 'store'
import { EmployeePageData } from './EmployeePageData'

export const EmployeesPage: FC = observer(() => {
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: employees } = useFetchExtendedEmployeesWithOwner(timezone, branchId)
  const { data: employeeGroups } = useFetchEmployeesGroups(timezone, branchId)
  const { data: myPermissions } = useFetchMyPermissions()

  return (
    <>
      {employees && employeeGroups && myPermissions ? (
        <EmployeePageData
          employees={employees}
          employeeGroups={employeeGroups}
          myPermissions={myPermissions}
        />
      ) : (
        <Spinner expandCentered />
      )}
    </>
  )
})

export const ArchiveEmployeesPage: FC = observer(() => {
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: archivedEmployees } = useFetchArchivedEmployees(timezone, branchId)
  const { data: archivedEmployeeGroups } = useFetchArchivedEmployeesGroups(branchId)
  const { data: myPermissions } = useFetchMyPermissions()

  return (
    <>
      {archivedEmployees && myPermissions && archivedEmployeeGroups ? (
        <EmployeePageData
          employees={archivedEmployees}
          employeeGroups={archivedEmployeeGroups}
          myPermissions={myPermissions}
          archive
        />
      ) : (
        <Spinner expandCentered />
      )}
    </>
  )
})
