import { useFetchProductsForConsumables } from '@expane/data'
import { useGetUnitsNameForConsumable } from '@expane/logic/product'
import {
  DeleteButtonCell,
  Input,
  InputLabel,
  SelectDropdown,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { useGetConsumableAmount, useGetConsumablesTotalAmount } from 'logic/consumable'
import { customSelectDropDownProductSearchFilterFunction } from 'logic/product'
import React, { FC } from 'react'
import { Control, Controller, useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline } from 'react-icons/io5'
import { store } from 'store'
import { BookingMultiServicesDialogFormValues } from 'widgets/BookingMultiServicesDialog/index'

interface BookingMultiServicesConsumablesBlockProps {
  control: Control<BookingMultiServicesDialogFormValues>
  multiServicesDtoIndex: number
  disabled: boolean
}

export const BookingMultiServicesConsumablesBlock: FC<
  BookingMultiServicesConsumablesBlockProps
> = ({ control, multiServicesDtoIndex, disabled }) => {
  const { t } = useTranslation()
  const branchId = store.branch.branchId

  const { append, fields, remove } = useFieldArray({
    control,
    name: `multiServicesDto.${multiServicesDtoIndex}.consumables`,
  })

  const handleAppend = () =>
    append({ productId: undefined, bookingProductId: undefined, quantity: DEFAULT_QUANTITY })

  const watchedConsumables = useWatch({
    control,
    name: `multiServicesDto.${multiServicesDtoIndex}.consumables`,
  })

  const getUnitName = useGetUnitsNameForConsumable(branchId)
  const getProductAmount = useGetConsumableAmount()
  const getTotalAmount = useGetConsumablesTotalAmount()

  return (
    <div className={`${fields.length ? ' flex flex-col max-h-48' : ''}`}>
      {fields.length ? (
        <>
          <InputLabel label={t('consumables')} />

          <TableContainer>
            <TableHeader>
              <tr>
                <TableHeaderCell className="w-72 text-left">{t('title')}</TableHeaderCell>
                <TableHeaderCell className="w-40">{t('qty')}</TableHeaderCell>
                <TableHeaderCell className="w-16 text-right">{t('unit.shortName')}</TableHeaderCell>
                <TableHeaderCell className="w-32 text-right">{t('price')}</TableHeaderCell>
                <th className="cursor-pointer w-6">
                  <div className="px-2 flex-centered">
                    <button onClick={handleAppend} disabled={disabled}>
                      <IoAddCircleOutline
                        size="1.3rem"
                        className={disabled ? 'text-icon-color' : 'text-primary-500'}
                      />
                    </button>
                  </div>
                </th>
              </tr>
            </TableHeader>
            <TableBody>
              {fields.map((consumable, consumableIndex) => (
                <TableRow key={consumable.id} className="text-sm">
                  <TableCell>
                    <ProductSelect
                      control={control}
                      index={multiServicesDtoIndex}
                      consumableIndex={consumableIndex}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`multiServicesDto.${multiServicesDtoIndex}.consumables.${consumableIndex}.quantity`}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          value={value}
                          onChange={onChange}
                          type="number"
                          min={0}
                          height="small"
                          disabled={disabled}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell className="text-right">
                    {t(getUnitName(watchedConsumables[consumableIndex]?.productId))}
                  </TableCell>
                  <TableCell className="text-right">
                    {getProductAmount(
                      watchedConsumables[consumableIndex]?.productId,
                      watchedConsumables[consumableIndex]?.quantity,
                      watchedConsumables[consumableIndex]?.price,
                    )}
                  </TableCell>
                  <DeleteButtonCell
                    className="w-6"
                    onClick={() => remove(consumableIndex)}
                    disabled={disabled}
                  />
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>

          <div className="p-2 text-right text-sm text-gray-500">
            {t('total')}: {getTotalAmount(watchedConsumables)}
          </div>
        </>
      ) : (
        !disabled && (
          <button
            onClick={handleAppend}
            className="ml-auto text-gray-500 hover:text-primary-400 mt-2 flex items-center"
          >
            <IoAddCircleOutline size="1.3rem" className="mr-1" />
            <p>{t('addConsumable')}</p>
          </button>
        )
      )}
    </div>
  )
}

type ProductSelectProps = {
  control: Control<BookingMultiServicesDialogFormValues>
  index: number
  consumableIndex: number
}

const ProductSelect: FC<ProductSelectProps> = ({ control, index, consumableIndex }) => {
  const branchId = store.branch.branchId
  const { data: products } = useFetchProductsForConsumables(branchId)

  const watchedConsumables = useWatch({ control, name: `multiServicesDto.${index}.consumables` })

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={`multiServicesDto.${index}.consumables.${consumableIndex}.productId`}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const filteredItems = products?.filter(
          product =>
            product.id === value ||
            watchedConsumables.every(consumable => consumable.productId !== product.id),
        )
        return (
          <SelectDropdown
            value={value}
            onSelectChange={onChange}
            items={filteredItems}
            errorMessage={{ isShown: Boolean(error), text: ' ', reserveIndent: false }}
            height="small"
            customSearchFilterFunction={customSelectDropDownProductSearchFilterFunction}
          />
        )
      }}
    />
  )
}

const DEFAULT_QUANTITY = '1'
