import { addDays } from '@expane/date'
import { DEFAULT_LANG } from '@expane/logic/lang'

export interface MessageTemplateData {
  person: { firstName: string; lastName?: string }
  date: string
  time: string
  orgName: string
}

export const getTomorrowDateTime = (language?: string) => {
  const tomorrow = addDays(new Date(), 1)

  const date = tomorrow.toLocaleDateString(language ?? DEFAULT_LANG, {
    month: 'numeric',
    day: 'numeric',
  })
  const time = tomorrow.toLocaleTimeString(language ?? DEFAULT_LANG, {
    hour: '2-digit',
    minute: '2-digit',
  })

  return { date, time }
}

export const generateTextFromTemplate = (template: string, data: MessageTemplateData): string =>
  template
    .replace(/{{booking_date}}/gi, data.date)
    .replace(/{{booking_time}}/gi, data.time)
    .replace(/{{user_first_name}}/gi, data.person.firstName)
    .replace(/{{user_last_name}}/gi, data.person.lastName ?? '')
    .replace(/{{business_name}}/gi, data.orgName)
