import { PlaceholderDialog, Spinner } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ImportProductsDialogPlaceholder: FC<{ closeDialog: () => void }> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()
  return (
    <PlaceholderDialog className="h-120" title={t('importProducts.name')} closeDialog={closeDialog}>
      <Spinner expandCentered />
    </PlaceholderDialog>
  )
}
