import { RadioGroupOption } from '@expane/ui'

const forSaleOption: RadioGroupOption = { id: 1, name: 'productType.forSaleToClients' }
const forConsumableOption: RadioGroupOption = { id: 2, name: 'productType.forConsumables' }

export const productTypeOptions = [forSaleOption, forConsumableOption]

export const transformProductTypeOptionToServer = (option: RadioGroupOption): boolean =>
  option.id === forSaleOption.id

export const transformServerForSaleToProductTypeOption = (
  forSale: boolean | undefined,
): RadioGroupOption | undefined => {
  if (forSale === true) return forSaleOption
  if (forSale === false) return forConsumableOption
}

export const generateToProductTypeOptionByModules = ({
  isProductsForSaleEnabled,
  isConsumablesEnabled,
}: {
  isProductsForSaleEnabled: boolean
  isConsumablesEnabled: boolean
}) => {
  if (isProductsForSaleEnabled && !isConsumablesEnabled) return forSaleOption
  if (isConsumablesEnabled && !isProductsForSaleEnabled) return forConsumableOption
}
