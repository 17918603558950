import {
  ServerEmployeeAllBranchesGroupType,
  ServerInterbranchServiceByIdType,
  ServerLocationAllBranchesBriefType,
} from '@expane/data'
import { checkArrayNotEmpty } from '@expane/logic/form'
import {
  getEmployeeIdsThatWereUsedHashMap,
  getLocationIdsThatWereUsedHashMap,
} from '@expane/logic/service'
import { TableCell, TableRow } from '@expane/ui'
import {
  provideDefaultSelectedForEmployeeItems,
  provideDisabledForUsedLocationsByIds,
} from 'logic/service'
import { filterByBranchId } from 'logic/utils'
import { FC, useMemo } from 'react'
import { Controller, FieldArrayWithId, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MultiSelect } from 'ui/MultiSelect'
import { TreeMenuItem } from 'ui/TreeMenu'
import { convertEmployeeGroupsToTreeItems } from 'ui/TreeMenu/logic.employee'
import { TreeSelect } from 'ui/TreeSelect'
import { InterbranchServiceConsumableSell } from 'widgets/InterbranchService/InterbranchServiceLogic/InterbranchServiceConsumableSell'
import { useOpenInterbranchServiceConsumableDialog } from '../InterbranchServiceConsumableDialog'
import {
  InterbranchServiceDialogFormData,
  InterbranchServiceFormControl,
  InterbranchServiceFormGetFieldState,
  InterbranchServiceFormSetValue,
  InterbranchServiceFormTrigger,
} from '../logic'
import { filterEmployeeGroupsEmployeesByBranchId } from './logic'

interface InterbranchServiceByBranchRowProps {
  branchService: FieldArrayWithId<InterbranchServiceDialogFormData, 'branchServices', 'id'>
  interbranchServiceById: ServerInterbranchServiceByIdType | undefined
  control: InterbranchServiceFormControl
  disabled: boolean
  employeeGroups: ServerEmployeeAllBranchesGroupType[]
  formGetFieldState: InterbranchServiceFormGetFieldState
  formTrigger: InterbranchServiceFormTrigger
  index: number
  isGroup: boolean
  locations: ServerLocationAllBranchesBriefType[]
  setValue: InterbranchServiceFormSetValue
}

export const InterbranchServiceByBranchRow: FC<InterbranchServiceByBranchRowProps> = ({
  branchService,
  interbranchServiceById,
  control,
  disabled,
  employeeGroups,
  formGetFieldState,
  formTrigger,
  index,
  isGroup,
  locations,
  setValue,
}) => {
  const { t } = useTranslation()

  const { openInterbranchServiceConsumableDialog, interbranchServiceConsumableDialog } =
    useOpenInterbranchServiceConsumableDialog()

  const watchedFormState = useFormState({ control })

  const filteredEmployeeGroups = useMemo(
    () => filterEmployeeGroupsEmployeesByBranchId(employeeGroups, branchService.branchId),
    [branchService.branchId, employeeGroups],
  )

  const employeeTreeMenuItems = convertEmployeeGroupsToTreeItems(filteredEmployeeGroups)
  const employeeIdsHashMap = getEmployeeIdsThatWereUsedHashMap(interbranchServiceById)
  const employeesWithDisabled = provideDefaultSelectedForEmployeeItems(
    employeeTreeMenuItems,
    employeeIdsHashMap,
    t,
  )

  const filteredLocations = useMemo(() => {
    const filtered = filterByBranchId(locations, branchService.branchId)

    const locationIdsHashMap = getLocationIdsThatWereUsedHashMap(interbranchServiceById)
    return provideDisabledForUsedLocationsByIds(filtered, locationIdsHashMap, t)
  }, [locations, branchService.branchId, interbranchServiceById, t])

  const rowError =
    Boolean(watchedFormState.errors.branchServices?.[index]?.locations) ||
    Boolean(watchedFormState.errors.branchServices?.[index]?.employees)

  return (
    <TableRow errorMessage={{ isShown: rowError, text: t('formError.required') }}>
      <TableCell>{branchService.branchName}</TableCell>

      <TableCell>
        <Controller
          control={control}
          name={`branchServices.${index}.locations`}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <MultiSelect
              items={filteredLocations}
              onItemSelect={onChange}
              selectedItems={value}
              dropdownInputPlaceholder={t('placeholders.defaultSelect')}
              placeholder={t('choose')}
              disabled={disabled}
              errorMessage={{ isShown: Boolean(error), reserveIndent: false }}
            />
          )}
        />
      </TableCell>

      <TableCell>
        <Controller
          control={control}
          name={`branchServices.${index}.employees`}
          rules={{
            validate: {
              checkArrayNotEmpty: value => {
                if (!isGroup) return true

                return checkArrayNotEmpty(value)
              },
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TreeSelect
              type="MultiPickMode"
              required={isGroup}
              placeholder={t('choose')}
              items={employeesWithDisabled}
              onSelected={onChange}
              selected={value as TreeMenuItem[]}
              disabled={disabled}
              errorMessage={{ isShown: isGroup && Boolean(error), reserveIndent: false }}
            />
          )}
        />
      </TableCell>
      <InterbranchServiceConsumableSell
        branchId={branchService.branchId}
        control={control}
        index={index}
        disabled={disabled}
        onClick={() =>
          openInterbranchServiceConsumableDialog({
            branchId: branchService.branchId,
            control,
            disabled,
            formGetFieldState,
            formTrigger,
            index,
            setValue,
          })
        }
      />

      {interbranchServiceConsumableDialog}
    </TableRow>
  )
}
