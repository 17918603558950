import { FC } from 'react'
import { ServerAccountType } from '@expane/data'
import { store } from 'store'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { findById } from '@expane/logic/utils'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const BalanceOnAccountAfterTransaction: FC<{
  accounts: ServerAccountType[]
  accountId: number
  sum: number
}> = observer(({ accountId, accounts, sum }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId

  const convertToMoneyCode = useConvertNumberToMoney(branchId)

  const account = findById(accountId, accounts)

  if (!account) return <p className={getTextStyle(false)}>{t('selectAccount')}</p>

  const accountBalance = account.balance ?? 0

  const restAccountValue = accountBalance - sum

  if (restAccountValue < 0)
    return <p className={getTextStyle(true)}>{t('formError.notEnoughFunds')}</p>

  return (
    <p className={getTextStyle(false)}>
      {`${t('salary.accountBalanceAfterIssuance')}: `}
      <span>{convertToMoneyCode(restAccountValue)}</span>
    </p>
  )
})
const getTextStyle = (error: boolean) =>
  `${error ? 'text-error-500' : 'text-gray-400'} text-xs text-right mt-1`
