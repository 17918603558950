import { gql } from 'graphql-request'
import { queryClient, request, useMutation, reportGqlError } from '../../api'
import {
  ServerStorageEmployeeGroupInsertInput,
  ServerStorageEmployeeGroupMutationResponse,
  ServerStorageInsertInput,
  ServerStorageSetInput,
} from '../../generated/graphql-types'
import { STORAGES_QUERY_KEY } from './queryKeys'

export function useCreateStorage() {
  return useMutation(
    (
      serverStorageInsertInput: ServerStorageInsertInput &
        Required<Pick<ServerStorageInsertInput, 'name' | 'branchId'>>,
    ): Promise<{ insertStorage: { id: number } }> => {
      return request(
        gql`
          mutation ($serverStorageInsertInput: storage_insert_input!) {
            insertStorage(object: $serverStorageInsertInput) {
              id
            }
          }
        `,
        { serverStorageInsertInput },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([STORAGES_QUERY_KEY]),
    },
  )
}

export function useUpdateStorage() {
  return useMutation(
    (dto: {
      id: number
      storageSetInput: ServerStorageSetInput
      storageEmployeeGroupsInsertInput: ServerStorageEmployeeGroupInsertInput[] | undefined
    }): Promise<{
      updateStorageById: { id: number }
      deleteStorageEmployeeGroups: ServerStorageEmployeeGroupMutationResponse
      insertStorageEmployeeGroups: ServerStorageEmployeeGroupMutationResponse
    }> => {
      return request(
        gql`
          mutation (
            $id: Int!
            $storageSetInput: storage_set_input!
            $storageEmployeeGroupsInsertInput: [storageEmployeeGroup_insert_input!]!
            $isUpdateStorageEmployeeGroup: Boolean!
          ) {
            updateStorageById(pk_columns: { id: $id }, _set: $storageSetInput) {
              id
            }
            deleteStorageEmployeeGroups(where: { storageId: { _eq: $id } })
              @include(if: $isUpdateStorageEmployeeGroup) {
              affected_rows
            }
            insertStorageEmployeeGroups(objects: $storageEmployeeGroupsInsertInput)
              @include(if: $isUpdateStorageEmployeeGroup) {
              affected_rows
            }
          }
        `,
        {
          ...dto,
          storageEmployeeGroupsInsertInput: dto.storageEmployeeGroupsInsertInput ?? [],
          isUpdateStorageEmployeeGroup: Boolean(dto.storageEmployeeGroupsInsertInput),
        },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([STORAGES_QUERY_KEY]),
    },
  )
}
