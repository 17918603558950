import { gql } from 'graphql-request'

export const referrerFragment = gql`
  fragment referrerType on referrer {
    id
    name
    email
    percentageReward
    createdAt
    maxDiscount
  }
`

// Повинно повторювати referrerFragment бо використовуємо ServerReferrerType в запиті
export const referrerAsReferrerFragment = gql`
  fragment referrerAsReferrerType on referrerView {
    id
    name
    email
    percentageReward
    createdAt
    maxDiscount
  }
`
