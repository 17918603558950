import {
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchExtendedEmployees,
} from '@expane/data'
import { Paper, Spinner } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { ProceedToNextStep, useCreateBusinessSteps } from 'pages/CreateBusinessPage'
import { stepStyle } from 'pages/CreateBusinessPage/logic'
import { EmployeePageData } from 'pages/EmployeesPage/EmployeePageData'
import { FC } from 'react'
import { store } from 'store'

export const EmployeesStep: FC = observer(() => {
  const { proceedToNextStep } = useCreateBusinessSteps()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: employees, isLoading: employeesIsLoading } = useFetchExtendedEmployees(
    timezone,
    branchId,
  )
  const { data: employeeGroups, isLoading: employeeGroupsIsLoading } = useFetchEmployeesGroups(
    timezone,
    branchId,
  )
  const { data: myPermissions, isLoading: myPermissionsIsLoading } = useFetchMyPermissions()
  const isLoading = employeeGroupsIsLoading || employeesIsLoading || myPermissionsIsLoading

  return (
    <div className={'flex items-end'}>
      <Paper className={stepStyle + ' flex flex-col w-160'}>
        <div className="h-126">
          {isLoading ? (
            <Spinner expandCentered />
          ) : (
            <EmployeePageData
              employees={employees ?? []}
              employeeGroups={employeeGroups ?? []}
              myPermissions={myPermissions ?? []}
              type="createBusiness"
            />
          )}
        </div>
      </Paper>

      <ProceedToNextStep className="mt-2" onClick={proceedToNextStep} />
    </div>
  )
})
