import {
  BusinessSettingsType,
  EmployeeByIdExtended,
  ServerBranchBriefType,
  useUpdateBusinessSettingsById,
} from '@expane/data'
import { CloseButton, Dialog, ExplanationText } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MessageTemplateInput } from 'ui/MessageTemplateInput'
import { ApplyButton } from 'widgets/Buttons'
import { getTomorrowDateTime } from 'widgets/MessageTemplateSettingsDialog/logic'
import { MessageTemplateReview } from './MessageTemplateReview'

interface MessageTemplateSettingsFormData {
  messageTemplate: string
}

export const MessageTemplateSettingsDialogLogic: FC<{
  closeDialog: () => void
  businessSettings: BusinessSettingsType
  branch: ServerBranchBriefType
  myEmployee: EmployeeByIdExtended
}> = ({ closeDialog, businessSettings, branch, myEmployee }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { mutateAsync: updateBusinessSettings } = useUpdateBusinessSettingsById()

  const { control, handleSubmit, formState } = useForm<MessageTemplateSettingsFormData>({
    defaultValues: {
      messageTemplate: businessSettings.messageTemplate ?? '',
    },
  })

  const [openSnackbar] = useSnackbar()

  const handleUpdateBusinessSettings: SubmitHandler<MessageTemplateSettingsFormData> = async ({
    messageTemplate,
  }) => {
    const result = await updateBusinessSettings({
      id: businessSettings.id,
      businessSettingsSetInput: {
        messageTemplate: messageTemplate,
      },
    })

    if (result?.updateBusinessSettingsById?.id) {
      openSnackbar(t('changesSaved'), 'success', 3000)
      closeDialog()
    } else openSnackbar(t('submitError'), 'error', 3000)
  }

  const { date, time } = getTomorrowDateTime(language)

  const watchedTemplate = useWatch({ control, name: 'messageTemplate' })

  return (
    <>
      <Dialog.Title>{t('messageTemplate.title')}</Dialog.Title>
      <Dialog.Body className="w-205">
        <div className="flex flex-col">
          <ExplanationText text={t('messageTemplate.warning')} type="warning" className="mb-2" />
          <ExplanationText
            text={t('messageTemplate.languageWarning')}
            type="warning"
            className="mb-2"
          />

          <div className="flex gap-2 mb-2">
            <Controller
              name="messageTemplate"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MessageTemplateInput
                  label={t('bookingReminderMessage.title')}
                  hint={t('messageTemplate.hint')}
                  containerClassName="w-1/2"
                  value={value}
                  onChange={onChange}
                  placeholder={t('placeholders.messageTemplate')}
                />
              )}
            />

            <MessageTemplateReview
              className="w-1/2"
              template={watchedTemplate}
              defaultTemplate={t('defaultBookingNotification', {
                client: myEmployee.firstName,
                date,
                time,
                branch: branch.name,
              })}
              data={{ person: myEmployee, date, time, orgName: branch.name }}
            />
          </div>

          <ExplanationText text={t('bookingReminderMessage.info')} type="info" />
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <ApplyButton
          onClick={handleSubmit(handleUpdateBusinessSettings)}
          disabled={formState.isSubmitting || !formState.isDirty}
        />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
