import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  PlaceholderInput,
  PlaceholderString,
  Spinner,
} from '@expane/ui'
import { CALENDAR_OPTIONS } from 'logic/hooks/useCustomDatePicker'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PlaceholderHorizontalButtonSwitch } from 'ui/PlaceholderContent'

export const SalaryEmployeeDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>
        <div className="flex items-center">
          {t('salary.name')}: <PlaceholderString className="ml-2" />
        </div>
      </Dialog.Title>

      <Dialog.Body className="w-246 h-120 flex flex-col">
        <div className="mb-2 flex ">
          <PlaceholderInput className="w-56 mr-2" />
          <PlaceholderHorizontalButtonSwitch options={CALENDAR_OPTIONS} />
        </div>

        <div className="grow">
          <Spinner expandCentered />
        </div>
      </Dialog.Body>

      <Dialog.Footer>
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
