import { ErrorMessage, ErrorMessageType, InputLabel } from '@expane/ui'
import { FC } from 'react'
import { DayTiming, getContainerStyle } from './logic'
import { TimeButton } from './TimeButton'

interface TimePickerProps {
  timing: DayTiming
  value: Date | undefined
  label?: string
  hint?: string
  required?: boolean
  disabled?: boolean
  errorMessage?: ErrorMessageType
  className?: string
  customPopupsElement?: HTMLDivElement
  timezone: string
}

type ClearableProps =
  | { isClearable: true; onChange: (date: Date | undefined) => void }
  | { isClearable?: false; onChange: (date: Date) => void }

export const TimePicker: FC<TimePickerProps & ClearableProps> = ({
  timing,
  value,
  label,
  hint,
  disabled = false,
  errorMessage,
  required = false,
  className,
  customPopupsElement,
  timezone,
  ...props
}) => {
  let buttonStyle = getContainerStyle({
    disabled,
    error: Boolean(errorMessage?.isShown),
  })

  if (disabled) {
    buttonStyle += ' bg-gray-50'
  }

  const handleChange = (date: Date | undefined) => {
    if (props.isClearable && date === undefined) props.onChange(undefined)
    else if (date && value?.getTime() !== date.getTime()) props.onChange(date)
  }

  return (
    <div className={className}>
      <InputLabel label={label} hint={hint} required={required} />

      <TimeButton
        timezone={timezone}
        className={`${buttonStyle} items-center border-gray-100`}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        isClearable={props.isClearable}
        timing={timing}
        customPopupsElement={customPopupsElement}
      />

      <ErrorMessage errorMessage={errorMessage} />
    </div>
  )
}
