import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

enum Line {
  hidden,
  dotted,
  standard,
}

interface Step {
  step: number
  name: string
}

interface TransformedStep extends Step {
  show: boolean
  line: Line
}

export interface Props {
  containerClassName?: string
  steps: Step[]
  currentStep?: number
}

export const ProgressBar: FC<Props> = ({ steps, currentStep = 0, containerClassName }) => {
  const { t } = useTranslation()

  const transformed = transformSteps(steps, currentStep)

  return (
    <div className={`${containerClassName}`}>
      {transformed.map(item => {
        let stepStyle =
          'w-8 h-8 border-2 rounded-full flex-initial grow-0 flex-centered font-medium'
        let lineStyle = ' flex-auto mx-2 w-0.5 h-20 '
        let titleStyle = ' text-center leading-4 text-lg font-medium '
        let dotStyle = ' absolute h-2 w-2 rounded-full -right-[3px]'

        if (item.step === currentStep) {
          stepStyle += ' border-primary-500 dark:bg-primary-500 text-primary-500 dark:text-gray-300'
          titleStyle += ' text-primary-500'
        }
        if (item.step < currentStep) {
          lineStyle += ' bg-primary-500 dark:bg-primary-500/30'
          stepStyle +=
            ' border-primary-500 dark:border-primary-500/30 bg-primary-500 dark:bg-primary-500/30 text-white dark:text-primary-700'
          titleStyle += ' text-primary-500 dark:text-primary-700'
          dotStyle += ' bg-primary-500'
        }
        if (item.step >= currentStep) {
          lineStyle += ' bg-primary-100 dark:bg-primary-500'
        }
        if (item.step > currentStep) {
          stepStyle +=
            ' border-primary-100 dark:border-primary-500 text-primary-100 dark:text-primary-500'
          titleStyle += ' text-primary-100 dark:text-primary-500'
          dotStyle += ' bg-primary-100 dark:bg-primary-500'
        }

        return (
          <React.Fragment key={item.step}>
            {item.show && (
              <div className="flex items-start justify-between">
                <p
                  className={
                    titleStyle +
                    ' w-full min-h-8 mr-2 text-right flex flex-col justify-center items-end'
                  }
                >
                  {t(item.name)}
                </p>

                <div className={'flex flex-col justify-center items-center'}>
                  <div className={stepStyle}>{item.step}</div>
                  {item.line === Line.standard && <div className={lineStyle} />}

                  {item.line === Line.dotted && (
                    <div className={lineStyle + ' relative'}>
                      <div className={dotStyle + ' top-1/3'} />
                      <div className={dotStyle + ' top-2/3'} />
                    </div>
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const transformSteps = (steps: Step[], current: number): TransformedStep[] =>
  steps.map(item => {
    // Последний пункт
    if (steps.length === item.step) return { ...item, show: true, line: Line.hidden }

    // Если в начале списка
    if (current <= 3 && item.step <= 3) return { ...item, show: true, line: Line.standard }
    else if (item.step === 1) return { ...item, show: true, line: Line.dotted }

    // Если в конце списка
    if (current >= steps.length - 2 && item.step >= steps.length - 3) {
      if (steps.length === item.step) return { ...item, show: true, line: Line.hidden }
      return { ...item, show: true, line: Line.standard }
    }

    // Если в середине списка
    if (item.step >= current - 1 && item.step <= current + 1) {
      if (item.step === current + 1) return { ...item, show: true, line: Line.dotted }
      return { ...item, show: true, line: Line.standard }
    }

    return {
      ...item,
      show: false,
      line: Line.hidden,
    }
  })
