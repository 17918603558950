import { gql } from 'graphql-request'
import { reportGqlError, request, useMutation } from '../../api'
import { ServerManipulateSuperAdminTypes } from '../../generated/graphql-types'
import { ManipulateSuperAdminResult } from './logic'

export * from './logic'

export function useManipulateSuperAdmin() {
  return useMutation(
    async (
      type: ServerManipulateSuperAdminTypes,
    ): Promise<{ manipulateSuperAdmin: ManipulateSuperAdminResult }> => {
      return request(
        gql`
          mutation ($type: ManipulateSuperAdminTypes!) {
            manipulateSuperAdmin(type: $type) {
              message
              code
            }
          }
        `,
        { type },
      )
    },
    {
      onError: reportGqlError,
    },
  )
}
