import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkSharp } from 'react-icons/io5'

export const BookingRepeatLabel: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <p
      className={
        'flex-centered bg-table-header font-normal text-table-title-color bg-table-title-color text-sm px-4 py-0.5 rounded-md ' +
        className
      }
    >
      <IoCheckmarkSharp className={'mr-2'} size={'1rem'} />

      {t('repeatLabel')}
    </p>
  )
}
