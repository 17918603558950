import {
  useFetchArchivedLocationGroups,
  useFetchArchivedLocations,
  useFetchLocationGroups,
  useFetchLocationsWithDefaultForBranch,
} from '@expane/data'
import { Spinner } from '@expane/ui'
import { LocationPageData } from 'pages/LocationsPage/LocationPageData'
import { FC } from 'react'
import { store } from 'store'

export const LocationsPage: FC = () => {
  const branchId = store.branch.branchId
  const { data: locations, isLoading: isLoadingLocations } =
    useFetchLocationsWithDefaultForBranch(branchId)
  const { data: locationGroups, isLoading: isLoadingLocationGroups } =
    useFetchLocationGroups(branchId)

  if (isLoadingLocations || isLoadingLocationGroups) return <Spinner expandCentered />

  return <LocationPageData locations={locations} locationGroups={locationGroups} />
}

export const ArchiveLocationsPage: FC = () => {
  const branchId = store.branch.branchId
  const { data: locations, isLoading: isLoadingLocations } = useFetchArchivedLocations(branchId)
  const { data: locationGroups, isLoading: isLoadingLocationGroups } =
    useFetchArchivedLocationGroups(branchId)

  if (isLoadingLocations || isLoadingLocationGroups) return <Spinner expandCentered />

  return <LocationPageData locations={locations} locationGroups={locationGroups} archive />
}
