import { useFetchFeatureFlags } from '@expane/data'
import featureFlags from './featureFlags.json'

/**
 * An object representing the feature flags used in the server.
 *
 * This variable indicates that the server has the following future flags
 * (you have to enter them manually on hasura and don't forget production)
 * It is preferred that all feature flags begin with the word "enable" for
 * clarity and consistency in their naming.
 *
 *
 */
export const FEATURE_FLAGS = Object.keys(featureFlags).reduce((acc, name) => {
  acc[name] = name

  return acc
}, {} as Record<keyof typeof featureFlags, string>)

export type GetFeatureFlagFunc = (key: string) => boolean

/**
 * Custom React hook for managing and accessing feature flags.
 *
 * It exposes a method `getFeatureFlag` to check the enabled status of a
 * specific feature flag by its key. Key must be from `FUTURE_FLAGS` object.
 * If a feature flag with the given key exists and is enabled,
 * `getFeatureFlag` returns true; otherwise, it returns false.
 *
 * @returns {Object} An object containing the `getFeatureFlag` method.
 *
 * @example
 * const { getFeatureFlag } = useFeatureFlags();
 * const isFeatureXEnabled = getFeatureFlag(FEATURE_FLAGS.featureX);
 */
export const useFeatureFlags = (): { getFeatureFlag: GetFeatureFlagFunc } => {
  const { data: featureFlags } = useFetchFeatureFlags()

  const getFeatureFlag = (key: string): boolean =>
    featureFlags?.find(featureFlag => featureFlag.key === key)?.enabled || false

  return { getFeatureFlag }
}
