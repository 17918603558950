import { gql } from 'graphql-request'
import { request, useQueryOriginal, reportGqlError } from '../../api'
import { EMPLOYEES_COUNT_QUERY_KEY, EMPLOYEES_QUERY_KEY } from './queryKeys'

export function useFetchEmployeesCount() {
  return useQueryOriginal(
    [EMPLOYEES_COUNT_QUERY_KEY, EMPLOYEES_QUERY_KEY],
    async () => {
      const result = await request<{ employeesAggregate: { aggregate: { count: number } } }>(gql`
        query {
          employeesAggregate(where: { archived: { _is_null: true } }) {
            aggregate {
              count
            }
          }
        }
      `)

      return result.employeesAggregate.aggregate.count
    },
    {
      onError: reportGqlError,
    },
  )
}
