import { TableCell } from '@expane/ui'
import { useGetConsumablesTotalAmountInBranch } from 'logic/consumable'
import { DEFAULT_CONSUMABLE_QUANTITY } from 'logic/service'
import { FC } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EditButton } from 'ui/EditButton'
import { InterbranchServiceFormControl } from '../logic'

export const InterbranchServiceConsumableSell: FC<{
  control: InterbranchServiceFormControl
  index: number
  branchId: number
  onClick: () => void
  disabled: boolean
}> = ({ control, index, branchId, onClick, disabled }) => {
  const { t } = useTranslation()

  const { append } = useFieldArray({
    control,
    name: `branchServices.${index}.consumables`,
  })

  const watchedConsumables = useWatch({ control, name: `branchServices.${index}.consumables` })
  const getTotalAmount = useGetConsumablesTotalAmountInBranch(branchId)

  return (
    <TableCell className="relative">
      {Boolean(watchedConsumables.length) ? (
        <p>
          {watchedConsumables.length}{' '}
          {(watchedConsumables.length === 1
            ? t('consumable')
            : t('consumables')
          ).toLocaleLowerCase()}
          {'. '}
          {t('total')}: {getTotalAmount(watchedConsumables)}
        </p>
      ) : (
        '-'
      )}

      <EditButton
        className="absolute right-1 top-3"
        onClick={() => {
          if (!watchedConsumables.length)
            if (!disabled)
              append({
                productId: undefined,
                bookingProductId: undefined,
                quantity: DEFAULT_CONSUMABLE_QUANTITY,
              })

          onClick()
        }}
      />
    </TableCell>
  )
}
