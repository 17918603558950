import { FC, MouseEvent, PropsWithChildren } from 'react'
import { IconType } from 'react-icons'
import { stopEnterPropagation } from '../../logic'
import { SpinnerSmall } from '../SpinnerSmall'

export type ButtonSize = 'default' | 'small'

export type ButtonProps = PropsWithChildren<{
  // Common
  className?: string
  disabled?: boolean
  spinner?: boolean
  // Used in regular mode
  Icon?: IconType
  iconSide?: 'left' | 'right'
  type?: 'primary' | 'outline' | 'danger'
  size?: ButtonSize
  twoLines?: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}>

export type OtherButtonProps = Omit<ButtonProps, 'type' | 'Icon' | 'iconSide'>
export type ButtonWithoutIconProps = Omit<ButtonProps, 'Icon' | 'iconSide'>

export const Button: FC<ButtonProps> = ({
  className,
  type = 'primary',
  disabled,
  spinner,
  children,
  twoLines,
  onClick,
  size = 'default',
  Icon,
  iconSide = 'left',
  ...props
}) => {
  let styles = 'rounded-md border-2 font-medium focus:outline-none ring-btn transition-all '

  if (twoLines) {
    styles += ' flex items-center text-left px-1.5 py-0.5 leading-3 text-xs min-h-9.5 '
  } else {
    styles += ' inline-flex items-center justify-center shadow-sm '
    styles += size === 'default' ? 'text-base h-9.5 px-4 ' : 'text-sm h-6 px-2 '
  }

  if (disabled) styles += 'cursor-default '

  if (type === 'primary') {
    styles += 'border-transparent text-btn-primary '

    if (disabled) {
      styles += 'bg-btn-disabled-primary '
    } else {
      styles += 'bg-btn-primary focus:ring-primary-400 '
    }
  } else if (type === 'outline') {
    styles += 'bg-btn-outline  '

    if (disabled) {
      styles += ' border-btn-disabled-outline text-btn-disabled-outline '
    } else {
      styles += 'text-btn-outline border-btn-outline '
    }
  } else if (type === 'danger') {
    styles += ' bg-btn-error '
    if (disabled) {
      styles += 'border-error-100 text-error-100 '
    } else {
      styles += ' text-btn-error border-btn-error '
    }
  }

  if (className) styles += className

  const iconSize = size === 'small' ? '0.8rem' : '1.5rem'

  return (
    <button
      className={styles}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={stopEnterPropagation}
      {...props}
    >
      {Icon && iconSide === 'left' && <Icon size={iconSize} className="shrink-0 mr-1" />}
      {children}
      {Icon && iconSide === 'right' && <Icon size={iconSize} className="shrink-0 ml-1" />}
      {spinner && <SpinnerSmall className="ml-1" />}
    </button>
  )
}
