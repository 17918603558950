import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetchMyPermissions } from 'gql/employee'
import {
  useFetchBranchById,
  useFetchEmployeesGroups,
  useFetchLocationGroups,
  useFetchLocations,
  useFetchProductsForConsumables,
  useFetchServiceById,
  useFetchServiceGroups,
  useFetchServicesBriefs,
} from '@expane/data'
import {
  ServiceDialogLogic,
  ServiceDialogPlaceholder,
} from 'widgets/ServiceDialog/ServiceDialogLogic'
import { useSnackbar } from '@expane/widgets'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { useAreManyEmployeesAllowed } from '@expane/logic/billing'

export interface OnCreateServiceDto {
  name: string
  groupId?: number
}

export const ServiceDialog: FC<DialogProps<OnCreateServiceDto>> = observer(
  ({ id: serviceId, closeDialog, isCreate = false, onCreate }) => {
    const { t } = useTranslation()
    const branchId = store.branch.branchId

    const { data: branch, isLoading: isLoadingBranch } = useFetchBranchById(branchId)
    const { data: myPermissions } = useFetchMyPermissions()
    const { data: serviceById, isLoading: isLoadingServiceById } = useFetchServiceById(
      serviceId,
      branchId,
    )
    const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } = useFetchEmployeesGroups(
      branch?.timezone,
      branchId,
    )
    const { data: locations, isLoading: isLoadingLocations } = useFetchLocations(branchId)
    const { data: locationGroups, isLoading: isLoadingLocationGroups } =
      useFetchLocationGroups(branchId)
    const { data: serviceGroups, isLoading: isLoadingServiceGroups } =
      useFetchServiceGroups(branchId)
    const { data: products, isLoading: isLoadingProducts } =
      useFetchProductsForConsumables(branchId)
    const { data: services, isLoading: isLoadingServices } = useFetchServicesBriefs(branchId)
    const { areManyEmployeesAllowed, isLoading: isLoadingCurrentMaxEmployeesInBusiness } =
      useAreManyEmployeesAllowed()

    const [openSnackbar] = useSnackbar()

    const isLoading =
      (isLoadingServiceById && !isCreate) ||
      isLoadingBranch ||
      isLoadingEmployeeGroups ||
      isLoadingLocations ||
      isLoadingLocationGroups ||
      isLoadingServiceGroups ||
      isLoadingProducts ||
      isLoadingServices ||
      isLoadingCurrentMaxEmployeesInBusiness

    if (isLoading) return <ServiceDialogPlaceholder closeDialog={closeDialog} />
    else if (
      (!isCreate && !serviceById) ||
      !myPermissions ||
      !employeeGroups ||
      !locations ||
      !locationGroups ||
      !serviceGroups ||
      !branch ||
      !products ||
      !services
    ) {
      openSnackbar(t('dialogFailed'), 'error')
      return null
    }

    return (
      <ServiceDialogLogic
        isCreate={isCreate}
        myPermissions={myPermissions}
        serviceById={serviceById}
        employeeGroups={employeeGroups}
        locations={locations}
        serviceGroups={serviceGroups}
        branch={branch}
        products={products}
        closeDialog={closeDialog}
        services={services}
        onCreate={onCreate}
        locationGroups={locationGroups}
        areManyEmployeesAllowed={areManyEmployeesAllowed}
      />
    )
  },
)
