import { getHours, getMinutes, set } from '@expane/date'
import { ServerBookingInsertInput } from '@expane/data'
import { makeAutoObservable } from 'mobx'

export type AdditionalBookingDto = {
  specialId: number
  date: Date
  locationId?: number
  employeeId?: number
}

export class MultiBookingStore {
  constructor() {
    makeAutoObservable(this)
  }

  firstBooking: ServerBookingInsertInput | null = null
  additionalBookings: AdditionalBookingDto[] = []
  idCounter: number = INITIAL_ID_COUNTER

  get isMultiBookingMode() {
    return this.firstBooking !== null
  }

  setFirstBooking = (booking: ServerBookingInsertInput) => {
    this.firstBooking = booking
  }

  pushBooking = (dto: Omit<AdditionalBookingDto, 'specialId'>) => {
    this.additionalBookings.push({ ...dto, specialId: this.idCounter })
    this.idCounter++
  }

  removeBooking = (id: number) => {
    this.additionalBookings = this.additionalBookings.filter(dto => dto.specialId !== id)
  }

  setTimeForAllBookings = (date: Date) => {
    const hours = getHours(date)
    const minutes = getMinutes(date)

    if (!this.firstBooking?.startDate) throw new Error('No first booking')

    this.editDateOfFirstBooking(
      set(this.firstBooking.startDate, {
        hours,
        minutes,
      }),
    )

    this.additionalBookings = this.additionalBookings.map(booking => ({
      ...booking,
      date: set(booking.date, {
        hours,
        minutes,
      }),
    }))
  }

  editDateOfFirstBooking = (date: Date) => {
    if (this.firstBooking === null) throw new Error('No first booking')
    this.firstBooking = { ...this.firstBooking, startDate: date }
  }

  editDateOfAdditionalBooking = (id: number, newDate: Date) => {
    const newArray = [...this.additionalBookings]
    const indexOfBooking = newArray.findIndex(booking => booking.specialId === id)
    newArray[indexOfBooking] = { ...newArray[indexOfBooking], date: newDate }
    this.additionalBookings = newArray
  }

  reset = () => {
    this.firstBooking = null
    this.additionalBookings = []
    this.idCounter = INITIAL_ID_COUNTER
  }
}

const INITIAL_ID_COUNTER = 1
