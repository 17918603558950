import { gql } from 'graphql-request'
import { ACCOUNTS_QUERY_KEY } from '../account'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  ACCOUNT_SOFTWARE_POSS_QUERY_KEY,
  SOFTWARE_POSS_QUERY_KEY,
  SOFTWARE_POSS_SHIFT_QUERY_KEY,
} from '../softwarePOS/queryKeys'
import {
  CheckboxCreateBulkReceiptsResult,
  CheckboxCreateReceiptResult,
  DataForCheckboxBulkReturnReceipts,
  DataForCheckboxReceipt,
  DataForCheckboxReturnReceipt,
  DataForCheckboxServiceReceipt,
  UpdatePOSSetInput,
} from './logic'
import { CloudFunctionResult } from '../responses'

export const useUpdatePOS = () => {
  return useMutation(
    async (dto: UpdatePOSSetInput): Promise<{ updatePOS?: CloudFunctionResult }> => {
      return request(
        gql`
          mutation (
            $licenseKey: String
            $login: String
            $name: String
            $password: String
            $softwarePOSId: Int
            $accounts: [Int!]
            $branchId: Int!
          ) {
            updatePOS(
              licenseKey: $licenseKey
              password: $password
              login: $login
              name: $name
              softwarePOSId: $softwarePOSId
              accounts: $accounts
              type: update
              branchId: $branchId
            ) {
              code
              message
            }
          }
        `,
        { ...dto },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SOFTWARE_POSS_QUERY_KEY])
        queryClient.invalidateQueries([ACCOUNT_SOFTWARE_POSS_QUERY_KEY])
        queryClient.invalidateQueries([ACCOUNTS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export const useCreatePOS = () => {
  return useMutation(
    async (dto: {
      licenseKey: string
      login: string
      name: string
      password: string
      accounts: number[]
      branchId: number
    }): Promise<{ updatePOS?: CloudFunctionResult }> => {
      return request(
        gql`
          mutation (
            $licenseKey: String
            $login: String
            $name: String
            $password: String
            $accounts: [Int!]
            $branchId: Int!
          ) {
            updatePOS(
              licenseKey: $licenseKey
              password: $password
              login: $login
              name: $name
              accounts: $accounts
              type: create
              branchId: $branchId
            ) {
              code
              message
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SOFTWARE_POSS_QUERY_KEY])
        queryClient.invalidateQueries([ACCOUNT_SOFTWARE_POSS_QUERY_KEY])
        queryClient.invalidateQueries([ACCOUNTS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export const useCashierSignIn = () => {
  return useMutation(
    async (dto: {
      softwarePOSId: number
    }): Promise<{ checkboxCashierSignin?: CloudFunctionResult }> => {
      return request(
        gql`
          mutation ($softwarePOSId: Int!) {
            checkboxCashierSignin(softwarePOSId: $softwarePOSId) {
              code
              message
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SOFTWARE_POSS_QUERY_KEY])
        queryClient.invalidateQueries([ACCOUNT_SOFTWARE_POSS_QUERY_KEY])
        queryClient.invalidateQueries([SOFTWARE_POSS_SHIFT_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export const useCashierSignOut = () => {
  return useMutation(
    async (dto: {
      softwarePOSId: number
    }): Promise<{ checkboxCashierSignout?: CloudFunctionResult }> => {
      return request(
        gql`
          mutation ($softwarePOSId: Int!) {
            checkboxCashierSignout(softwarePOSId: $softwarePOSId) {
              code
              message
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SOFTWARE_POSS_QUERY_KEY])
        queryClient.invalidateQueries([ACCOUNT_SOFTWARE_POSS_QUERY_KEY])
        queryClient.invalidateQueries([SOFTWARE_POSS_SHIFT_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export const useCheckboxOpenShift = () => {
  return useMutation(
    async (dto: {
      softwarePOSId: number
    }): Promise<{ checkboxOpenShift?: CloudFunctionResult }> => {
      return request(
        gql`
          mutation ($softwarePOSId: Int!) {
            checkboxOpenShift(softwarePOSId: $softwarePOSId) {
              code
              message
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SOFTWARE_POSS_QUERY_KEY])
        queryClient.invalidateQueries([ACCOUNT_SOFTWARE_POSS_QUERY_KEY])
        queryClient.invalidateQueries([SOFTWARE_POSS_SHIFT_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export const useCheckboxCloseShift = () => {
  return useMutation(
    async (dto: {
      softwarePOSId: number
    }): Promise<{ checkboxCloseShift?: CloudFunctionResult }> => {
      return request(
        gql`
          mutation ($softwarePOSId: Int!) {
            checkboxCloseShift(softwarePOSId: $softwarePOSId) {
              code
              message
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([SOFTWARE_POSS_SHIFT_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export const useCheckboxCreateReceipt = () => {
  return useMutation(
    async (
      dto: DataForCheckboxReceipt,
    ): Promise<{ checkboxCreateReceipt?: CheckboxCreateReceiptResult }> => {
      return request(
        gql`
          mutation ($clientId: Int!, $softwarePOSId: Int!, $receipt: CreateReceiptDto!) {
            checkboxCreateReceipt(
              clientId: $clientId
              softwarePOSId: $softwarePOSId
              receipt: $receipt
            ) {
              code
              message
              receipt {
                receiptId
                status
              }
            }
          }
        `,
        { ...dto },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([SOFTWARE_POSS_SHIFT_QUERY_KEY]),
    },
  )
}

export const useCheckboxCreateReturnReceipt = () => {
  return useMutation(
    async (
      dto: DataForCheckboxReturnReceipt,
    ): Promise<{ checkboxCreateReceipt?: CheckboxCreateReceiptResult }> => {
      return request(
        gql`
          mutation ($clientId: Int!, $softwarePOSId: Int!, $receipt: CreateReceiptDto!) {
            checkboxCreateReceipt(
              clientId: $clientId
              softwarePOSId: $softwarePOSId
              receipt: $receipt
            ) {
              code
              message
              receipt {
                receiptId
                status
              }
            }
          }
        `,
        { ...dto },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([SOFTWARE_POSS_SHIFT_QUERY_KEY]),
    },
  )
}

export const useCheckboxCreateBulkReturnReceipts = () => {
  return useMutation(
    async (
      dto: DataForCheckboxBulkReturnReceipts,
    ): Promise<{ checkboxCreateBulkReceipts?: CheckboxCreateBulkReceiptsResult }> => {
      return request(
        gql`
          mutation ($clientId: Int!, $softwarePOSId: Int!, $receipts: [CreateReceiptDto!]!) {
            checkboxCreateBulkReceipts(
              clientId: $clientId
              softwarePOSId: $softwarePOSId
              receipts: $receipts
            ) {
              code
              message
              receipts {
                receiptId
                status
              }
            }
          }
        `,
        { ...dto },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([SOFTWARE_POSS_SHIFT_QUERY_KEY]),
    },
  )
}

export const useCheckboxCreateServiceReceipt = () => {
  return useMutation(
    async (
      dto: DataForCheckboxServiceReceipt,
    ): Promise<{ checkboxCreateServiceReceipt?: CheckboxCreateReceiptResult }> => {
      return request(
        gql`
          mutation ($value: Float!, $softwarePOSId: Int!, $type: CreateServiceReceiptType!) {
            checkboxCreateServiceReceipt(
              softwarePOSId: $softwarePOSId
              type: $type
              value: $value
            ) {
              code
              message
              receipt {
                receiptId
                status
              }
            }
          }
        `,
        { ...dto },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([SOFTWARE_POSS_SHIFT_QUERY_KEY]),
    },
  )
}
