import { ServerTransactionInBookingType } from '@expane/data'
import { getRoundedDate } from '@expane/date'

export const findIdOfTransactionWithoutRefund = (
  transactions: ServerTransactionInBookingType[],
  clientId: number,
) => {
  const transactionsWithClient = transactions.filter(
    transaction => transaction.clientId === clientId,
  )

  return transactionsWithClient.find(
    transaction =>
      transaction.parentId === null &&
      !transactionsWithClient.some(possiblyRefund => transaction.id === possiblyRefund.parentId),
  )?.id
}

const ROUND_TO_MINUTES = 5

export type InitData =
  | {
      isCreate: false
      id: number
      startDate: undefined
    }
  | {
      isCreate: true
      id: undefined
      startDate: Date
      employeeId: number | undefined
      locationId: number | undefined
    }
export const getGroupBookingDefaultDate = (
  bookingStartDate: Date | undefined,
  initData: InitData,
) => {
  if (!initData.isCreate) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return bookingStartDate!
  } else {
    return getRoundedDate(ROUND_TO_MINUTES, initData.startDate)
  }
}
