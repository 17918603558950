import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetchRevenueReasons, useMutateRevenueReasons } from '@expane/data'
import { store } from 'store'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { SimpleEntityPlaceholder } from 'widgets/SimpleEntitiesDialog/SimpleEntityPlaceholder'
import { SimpleEntitiesDialogLogic } from 'widgets/SimpleEntitiesDialog'
import { SimpleEntitiesMutateFunc } from 'widgets/SimpleEntitiesDialog/logic'
import { useSnackbar } from '@expane/widgets'

export const RevenueReasonDialog: FC<DialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!
  const { data: revenueReasons, isLoading } = useFetchRevenueReasons(branchId)
  const { mutateAsync: mutateRevenueReason } = useMutateRevenueReasons()

  useErrorOpeningDialog(!isLoading && !revenueReasons, closeDialog)

  const [openSnackbar] = useSnackbar()

  const mutateFunc: SimpleEntitiesMutateFunc = async insertInput => {
    const dataForMutation = insertInput.map(item => ({
      ...item,
      branchId,
    }))

    const result = await mutateRevenueReason(dataForMutation)
    if (result.insertRevenueReasons.affectedRows) {
      openSnackbar(t('transaction.revenueReasonUpdatedSuccessfully'), 'success', 3000)
    } else {
      openSnackbar(t('submitError'), 'error', 3000)
    }
  }

  return isLoading ? (
    <SimpleEntityPlaceholder closeDialog={closeDialog} title={t('transaction.revenueReason')} />
  ) : (
    <SimpleEntitiesDialogLogic
      items={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        revenueReasons!
      }
      closeDialog={closeDialog}
      title={t('transaction.revenueReason')}
      mutateFunc={mutateFunc}
    />
  )
}
