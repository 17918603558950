import { useShowConfirmationPopup } from '@expane/ui'
import { useTranslation } from 'react-i18next'

export const useShowArchiveConfirmationPopup = (
  text: string,
  onConfirm: () => void,
  additionalText?: string,
) => {
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()
  const { t } = useTranslation()

  const showArchiveConfirmationPopup = () => {
    showConfirmation({
      title: t('archive.action'),
      description: (
        <>
          {t('archive.confirmation')} <span className="text-main-color italic">{text}</span>?
          {additionalText && `\n${additionalText}`}
        </>
      ),
      onConfirm,
    })
  }

  return { archiveConfirmationModal: confirmationModal, showArchiveConfirmationPopup }
}
