import {
  CloseButton,
  Dialog,
  Modal,
  stopEnterPropagation,
  TableBody,
  TableContainer,
  TableHeader,
  TableHeaderCell,
} from '@expane/ui'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline } from 'react-icons/io5'
import { SaveButton } from 'widgets/Buttons'
import {
  generateDefaultSimpleEntitiesValues,
  generateSimpleEntitiesDataForMutation,
  SimpleEntitiesDialogFormValues,
  SimpleEntitiesMutateFunc,
  SimpleEntity,
} from './logic'
import { SimpleEntityRow } from './SimpleEntityRow'

interface SimpleEntitiesDialogLogicProps extends Pick<DialogProps, 'closeDialog'> {
  title: string
  mutateFunc: SimpleEntitiesMutateFunc
  items: SimpleEntity[]
}

export const SimpleEntitiesDialogLogic: FC<SimpleEntitiesDialogLogicProps> = ({
  title,
  mutateFunc,
  items,
  closeDialog,
}) => {
  const { t } = useTranslation()

  const { control, handleSubmit, formState } = useForm<SimpleEntitiesDialogFormValues>({
    defaultValues: { items: generateDefaultSimpleEntitiesValues(items) },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
    keyName: 'generatedId',
  })

  const onAdd = () => {
    append({
      name: '',
      editable: true,
      updatable: false,
    })
  }

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

  const onSubmit: SubmitHandler<SimpleEntitiesDialogFormValues> = async data => {
    await mutateFunc(generateSimpleEntitiesDataForMutation(data))

    closeDialog()
  }

  return (
    <Modal close={closePopups} animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>{title}</Dialog.Title>

        <Dialog.Body className="h-70">
          {fields?.length ? (
            <TableContainer className="max-h-64">
              <TableHeader>
                <tr>
                  <TableHeaderCell className="w-120">
                    <div className="flex items-center justify-between">
                      {t('name')}
                      <button
                        onKeyDown={stopEnterPropagation}
                        onClick={onAdd}
                        className="mr-2 text-primary-500 hover:text-primary-700 hover:dark:text-primary-600 rounded-full focus:ring-1 ring-primary-500"
                      >
                        <IoAddCircleOutline size="1.4rem" />
                      </button>
                    </div>
                  </TableHeaderCell>
                </tr>
              </TableHeader>
              <TableBody>
                {fields.map((item, index) => (
                  <SimpleEntityRow
                    key={item.generatedId}
                    control={control}
                    index={index}
                    remove={remove}
                  />
                ))}
              </TableBody>
            </TableContainer>
          ) : (
            <div className="h-full flex items-center justify-center">
              <p className="text-lg text-secondary-color">{t('noItems')}</p>
            </div>
          )}
        </Dialog.Body>
        <Dialog.Footer>
          <SaveButton disabled={!formState.isDirty} onClick={handleSubmit(onSubmit)} />
          <CloseButton onClick={closePopups} />
          {!fields.length && <SaveButton className="mr-auto" isCreate onClick={onAdd} />}
        </Dialog.Footer>
      </Dialog>
      {confirmPopup}
    </Modal>
  )
}
