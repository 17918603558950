import { FC } from 'react'
import { IoInformationCircleOutline, IoWarningOutline } from 'react-icons/io5'

interface ExplanationTextProps {
  type?: 'info' | 'warning'
  text: string
  className?: string
}

export const ExplanationText: FC<ExplanationTextProps> = ({ type = 'info', text, className }) => (
  <div className={`flex items-center gap-2 ${className ?? ''}`}>
    <div className="w-4">
      {type === 'warning' ? (
        <IoWarningOutline size="1.2rem" className="text-yellow-300" />
      ) : (
        <IoInformationCircleOutline size="1.2rem" className="text-primary-500" />
      )}
    </div>

    <p className="text-sm text-secondary-color">{text}</p>
  </div>
)
