import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function useCheckBrowser() {
  const navigate = useNavigate()

  useEffect(() => {
    const nameAndVersion = getBrowser()
    const supportedVersion = SUPPORTED_VERSIONS.find(
      version => version.name === nameAndVersion.name,
    )
    if (supportedVersion === undefined || supportedVersion.version > nameAndVersion.version)
      navigate('/version-update', { replace: true })
    // Якщо додати navigate то буде оновлюватись кожен раз при переключенні
    // на любий роут (нам достатньо один раз при завантаженні)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const SUPPORTED_VERSIONS: BrowserInfo[] = [
  { name: 'Chrome', version: 96 },
  { name: 'Safari', version: 14 },
  { name: 'Firefox', version: 94 },
]

type BrowserInfo = {
  name: string
  version: number
}

function getBrowser(): BrowserInfo {
  const ua = navigator.userAgent
  let tem
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return { name: 'IE', version: tem[1] || '' }
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/)
    if (tem != null) {
      return { name: 'Opera', version: tem[1] }
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1])
  }
  return {
    name: M[0],
    version: Number(M[1]),
  }
}
