import { HorizontalTabs } from 'ui/HorizontalTabs'
import { FC } from 'react'
import { t } from 'i18next'

export const InstructionTabs: FC<{
  activeTabId: number
  setActiveTabId: (id: number) => void
  title?: string
}> = ({ activeTabId, setActiveTabId, title = '' }) => {
  return (
    <HorizontalTabs
      tabs={instructionTabs.map(tab => ({ ...tab, label: t(tab.label) }))}
      activeTabId={activeTabId}
      setActiveTabId={setActiveTabId}
      title={title}
    />
  )
}

export const instructionTabs = [
  {
    id: 0,
    label: 'businessLandingSettings.design',
  },
  {
    id: 1,
    label: 'businessLandingSettings.socMedia',
  },
  {
    id: 2,
    label: 'businessLandingSettings.onlineBooking',
  },
  {
    id: 3,
    label: 'businessLandingSettings.notification',
  },

  { id: 4, label: 'businessLandingSettings.links' },
]
