import type { GroupBooking } from '@expane/data'
import {
  useFetchBranchById,
  useFetchClientsBriefs,
  useFetchClientsPhones,
  useFetchClientsWithFullNameFuzzSearch,
  useFetchExtendedEmployees,
  useFetchExtendedServices,
  useFetchLocations,
  useGetLeftovers,
} from '@expane/data'
import { checkIfClientHasPaid } from '@expane/logic/booking'
import { CLOSED_DAY_TIMING, getWeekDay } from '@expane/logic/calendar'
import { addPhonesToClients } from '@expane/logic/client'
import { permissions } from '@expane/logic/permission'
import { getIsGroupService } from '@expane/logic/service'
import {
  Dialog,
  Input,
  SelectDropdown,
  SelectDropDownItem,
  Textarea,
  useShowConfirmationPopup,
} from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import {
  addInactiveReasonsAndWarningMessagesForServices,
  InactiveReasonForService,
} from 'logic/bookingServiceLogic'
import { checkBookingIsTodayOrLater } from 'logic/calendar'
import { filterEmployeesProvideServices } from 'logic/employees'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { transformPersonsForSelect } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { MultiSelect } from 'ui/MultiSelect'
import { TreeMenuItem } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { BookingInactiveReason } from 'widgets/BookingInactiveReason'
import { getSalarySettingsWarning } from 'widgets/BookingStatusTracker'
import { ClientDialog } from 'widgets/ClientDialog'
import { useOpenTransactionRefundDialog } from 'widgets/TransactionDialog/RefundDialog'
import { GroupBookingDialogFormValues } from '.'
import { DoneStatusCheckbox } from './DoneStatusCheckbox'
import { findIdOfTransactionWithoutRefund, InitData } from './logic'
import { RepeatButton } from 'widgets/Buttons'
import { useOpenRecurringDialog } from 'widgets/RecurringDialog'
import { DateTimePicker } from '@expane/widgets'
import { RecurringType } from '@expane/logic/recurringBookings'

type Props = {
  initData: InitData
  control: Control<GroupBookingDialogFormValues>
  setValue: UseFormSetValue<GroupBookingDialogFormValues>
  getValues: UseFormGetValues<GroupBookingDialogFormValues>
  formIsDisabled: boolean
  groupBookingById: GroupBooking | undefined
  timezone: string
}

export const GroupBookingDialogBody: FC<Props> = observer(
  ({ control, getValues, initData, setValue, formIsDisabled, groupBookingById, timezone }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userId = store.me.employeeId!
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const branchId = store.branch.branchId!

    const { isDirty } = useFormState({ control })
    const watchedStartDate = useWatch({ control, name: 'startDate' })
    const watchedLocationId = useWatch({ control, name: 'locationId' })
    const watchedEmployeeId = useWatch({ control, name: 'employeeId' })
    const watchedServiceId = useWatch({ control, name: 'serviceId' })
    const watchedClients = useWatch({
      control,
      name: 'clients',
    })

    const { data: myPermissions } = useFetchMyPermissions()

    const { data: clientsPhones } = useFetchClientsPhones()

    const [searchValue, setSearchValue] = useState('')
    const { data: clients } = useFetchClientsBriefs(
      branchId,
      timezone,
      watchedClients.map(client => client.id),
    )
    const { data: clientsBySearch } = useFetchClientsWithFullNameFuzzSearch(
      searchValue,
      branchId,
      timezone,
    )
    const clientsForDisplay = searchValue ? clientsBySearch : clients
    const clientsWithPhones = addPhonesToClients(clientsForDisplay, clientsPhones)
    const clientsForSelect = transformPersonsForSelect(clientsWithPhones, true)

    const { data: branch } = useFetchBranchById(branchId)
    const { data: employees } = useFetchExtendedEmployees(branch?.timezone, branchId)
    const employeesWithServices = filterEmployeesProvideServices(employees)
    const employeesForSelect = employeesWithServices
      ? transformPersonsForSelect(employeesWithServices)
      : []
    const storageIdByEmployee = employees?.find(employee => employee.id === watchedEmployeeId)
      ?.employeeGroup?.storageId

    const { data: locations } = useFetchLocations(branchId)

    const branchSchedule = branch?.schedule
    const currentDateBranchDayTiming =
      branchSchedule?.data[getWeekDay(watchedStartDate)] ?? CLOSED_DAY_TIMING
    const defaultStorageId = storageIdByEmployee ?? branch?.defaultStorage?.id

    const salarySettingsWarning = getSalarySettingsWarning(watchedEmployeeId, employees)

    const { data: productsLeftovers } = useGetLeftovers(branchId, defaultStorageId)
    const leftovers = defaultStorageId && productsLeftovers ? productsLeftovers : undefined

    const { data: services } = useFetchExtendedServices(branchId, 'all')
    const groupServices = services?.filter(service => getIsGroupService(service.type)) ?? []
    const servicesWithInactiveReasons = addInactiveReasonsAndWarningMessagesForServices({
      services: groupServices,
      locationId: watchedLocationId,
      employeeId: watchedEmployeeId,
      leftovers,
      userId,
      t,
    })
    const serviceItems = transformDataForTreeMenu<ServiceTreeItem>([], servicesWithInactiveReasons)

    const { dialog: clientDialog, openCreateDialog: openCreateClientDialog } =
      useOpenDialog(ClientDialog)
    const { confirmationModal, showConfirmation } = useShowConfirmationPopup()
    const { transactionRefundDialog, openTransactionRefundDialog } =
      useOpenTransactionRefundDialog()

    const { recurringDialog, openRecurringDialog } = useOpenRecurringDialog()

    const handleClientsChange = ({
      oldClients,
      newClients,
      onChange,
    }: {
      oldClients: SelectDropDownItem[]
      newClients: SelectDropDownItem[]
      onChange: (value: SelectDropDownItem[]) => void
    }) => {
      const deletedClientId = oldClients.find(
        oldClient => !newClients.some(newClient => newClient.id === oldClient.id),
      )?.id
      if (deletedClientId === undefined) return onChange(newClients) // If no client were deleted

      const didClientPay = checkIfClientHasPaid(groupBookingById, deletedClientId)
      if (!didClientPay) return onChange(newClients)

      const transactionId = findIdOfTransactionWithoutRefund(
        groupBookingById?.transactions ?? [],
        deletedClientId,
      )

      if (transactionId)
        showConfirmation({
          title: t('removingClient.title'),
          description: t('removingClient.description'),
          onConfirm: () =>
            openTransactionRefundDialog({ transactionId, onSuccess: () => onChange(newClients) }),
        })
    }

    // If selected service is no more available - remove it
    useEffect(() => {
      const selectedService = serviceItems.find(service => service.id === watchedServiceId)
      if (selectedService) {
        if (
          selectedService.customInactiveReason ||
          selectedService.disabled ||
          selectedService.inactiveReason ||
          selectedService.status === 'inactive'
        )
          setValue('serviceId', undefined)
      }
    }, [serviceItems, setValue, watchedServiceId])

    const setDurationOnServiceChange = (serviceId: number | null) => {
      if (serviceId) {
        const defaultDuration = groupServices
          .find(service => service.id === serviceId)
          ?.defaultDuration.toString()

        if (defaultDuration) {
          setValue('duration', defaultDuration)

          return
        }
      }

      setValue('duration', '0')
    }

    const watchedRecurring = useWatch({ control, name: 'recurring' })

    return (
      <Dialog.Body className="w-148">
        <Controller
          control={control}
          name="clients"
          render={({ field: { value, onChange } }) => (
            <MultiSelect
              label={t('clients')}
              selectedItems={value as SelectDropDownItem[]}
              onItemSelect={newClients =>
                handleClientsChange({
                  newClients,
                  oldClients: value as SelectDropDownItem[],
                  onChange,
                })
              }
              items={clientsForSelect}
              dropdownInputPlaceholder={t('placeholders.firstName')}
              disabled={formIsDisabled}
              onPlusClick={() =>
                openCreateClientDialog((id, dto) => {
                  if (dto) {
                    const newClient = { id, ...dto }
                    onChange([...value, ...transformPersonsForSelect([newClient])])
                  }
                })
              }
              popupHeight="big"
              onSearchValueChange={setSearchValue}
              customSearchPlaceholder={
                clients && clients.length === 0
                  ? t('noItems')
                  : clientsForSelect.length === 0
                  ? t('noClient')
                  : undefined
              }
            />
          )}
        />

        <div className="flex mt-2">
          <Controller
            control={control}
            name="locationId"
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectDropdown
                className="mr-2 w-1/2"
                required
                label={t('location.name')}
                value={value}
                onSelectChange={onChange}
                items={locations}
                errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                disabled={formIsDisabled}
                popupHeight="small"
              />
            )}
          />
          <Controller
            control={control}
            name="employeeId"
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectDropdown
                className="w-1/2"
                required
                label={t('employee.name')}
                value={value}
                onSelectChange={onChange}
                items={employeesForSelect}
                errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                disabled={formIsDisabled}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="serviceId"
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <SelectDropdown
                label={t('service.name')}
                required
                value={value}
                onSelectChange={serviceId => {
                  setDurationOnServiceChange(serviceId)
                  onChange(serviceId)
                }}
                items={serviceItems}
                errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                disabled={formIsDisabled}
                customInactiveReasonRender={item =>
                  item.customInactiveReason === undefined ? null : (
                    <BookingInactiveReason
                      item={item}
                      onLocationClick={id => setValue('locationId', id)}
                      onEmployeeClick={id => setValue('employeeId', id)}
                      onFinalAction={() => {
                        setDurationOnServiceChange(item.id)
                        onChange(item.id)
                      }}
                    />
                  )
                }
              />
            )
          }}
        />

        <div className="flex gap-2">
          <Controller
            control={control}
            name="duration"
            rules={{ required: true, validate: value => Number(value) > 0 }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                required
                label={t('duration')}
                containerClassName="w-1/2"
                type="number"
                value={value}
                onChange={onChange}
                errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                disabled={formIsDisabled}
              />
            )}
          />

          <div className={'w-1/2'}>
            <Controller
              control={control}
              name="startDate"
              rules={{
                required: true,
                validate: startDate => {
                  const canEditPast = myPermissions?.includes(permissions.booking.editPast) ?? false
                  return canEditPast || checkBookingIsTodayOrLater(startDate, timezone)
                },
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <DateTimePicker
                    timezone={timezone}
                    label={t('dateTitle')}
                    required
                    type="dateTime"
                    value={value}
                    onChange={onChange}
                    timing={currentDateBranchDayTiming}
                    disabled={formIsDisabled}
                    errorMessage={{
                      isShown: Boolean(error),
                      text:
                        error?.type === 'validate'
                          ? t('bookingValidation.notAllowedCreatePast')
                          : t('formError.required'),
                    }}
                  />
                )
              }}
            />

            {initData.isCreate && (
              <RepeatButton
                onClick={() => {
                  const recurring = getValues('recurring')
                  const startDate = getValues('startDate')

                  openRecurringDialog(
                    (data: RecurringType | null) => setValue('recurring', data),
                    recurring,
                    startDate,
                  )
                }}
                isRecurring={Boolean(watchedRecurring)}
                className={'ml-auto'}
              />
            )}
          </div>
        </div>

        <Controller
          control={control}
          name="note"
          render={({ field: { value, onChange } }) => (
            <Textarea
              placeholder={t('placeholders.bookingComment')}
              label={t('note')}
              value={value}
              disabled={formIsDisabled}
              onChange={onChange}
              rows={1}
              containerClassName="mt-2"
            />
          )}
        />

        {!initData.isCreate && (
          <DoneStatusCheckbox
            bookingId={initData.id}
            isDirty={isDirty}
            salarySettingsWarning={salarySettingsWarning}
          />
        )}
        {clientDialog}
        {confirmationModal}
        {transactionRefundDialog}
        {recurringDialog}
      </Dialog.Body>
    )
  },
)

type ServiceTreeItem = TreeMenuItem & {
  customInactiveReason?: InactiveReasonForService
}
