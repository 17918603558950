import { FC } from 'react'
import { DesignInstruction } from 'pages/SettingsPage/BusinessLandingSettings/Instruction/DesignInstruction'
import { SocialMediaInstruction } from 'pages/SettingsPage/BusinessLandingSettings/Instruction/SocialMediaInstruction'
import { LinksInstruction } from 'pages/SettingsPage/BusinessLandingSettings/Instruction/LinksInstruction'
import { NotificationsInstruction } from 'pages/SettingsPage/BusinessLandingSettings/Instruction/NotificationsInstruction'
import { OnlineBookingInstruction } from 'pages/SettingsPage/BusinessLandingSettings/Instruction/OnlineBookingInstruction'
import { instructionTabs } from 'pages/SettingsPage/BusinessLandingSettings/Instruction/Tabs'

export const Instruction: FC<{ activeTabId: number }> = ({ activeTabId }) => {
  return (
    <div className={'pt-4 dark:text-white'}>
      {instructionTabs[0].id === activeTabId && <DesignInstruction />}
      {instructionTabs[1].id === activeTabId && <SocialMediaInstruction />}
      {activeTabId === instructionTabs[2].id && <OnlineBookingInstruction />}
      {activeTabId === instructionTabs[3].id && <NotificationsInstruction />}
      {activeTabId === instructionTabs[4].id && <LinksInstruction />}
    </div>
  )
}
