import {
  NotGroupBooking,
  useFetchBookingById,
  useFetchCurrentBranchTimezone,
  useFetchDeactivatedServices,
  useFetchExtendedEmployees,
  useFetchExtendedServices,
  useFetchServiceGroups,
} from '@expane/data'
import {
  getIsBookingCanceled,
  getIsBookingDone,
  getIsBookingRecurring,
  getIsSomeServicesPaid,
} from '@expane/logic/booking'
import { Consumable, getDefaultConsumablesForBookingForm } from '@expane/logic/payment/booking'
import { permissions } from '@expane/logic/permission'
import { Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { convertServicesToTreeItems, getServicesForTreeMenu } from 'logic/bookingServiceLogic'
import { useCheckBookingDateEditable } from 'logic/calendar'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { FC, MutableRefObject, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { TreeMenuItem } from 'ui/TreeMenu'
import { groupInitialTreeItems, transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { BookingDialogPlaceholder } from 'widgets/BookingDialogPlaceholder'
import { BookingDialogBody } from './Body'
import { BookingDialogFooter } from './Footer'
import { getDefaultLocationId } from './logic'

import { BookingRepeatLabel } from 'widgets/BookingRepeatLabel'
import { DEFAULT_TIMEZONE } from '@expane/date'

import { RecurringType } from '@expane/logic/recurringBookings'

// Component should not be used directly, use `useOpenBookingDialog` hook instead
type BookingDialogInitialDtoCreate = {
  isCreate: true
  id: undefined
  startDate: Date
  locationId: number | undefined
  employeeId: number | undefined
}

type BookingDialogInitialDtoUpdate = {
  isCreate: false
  id: number
  startDate: undefined
  locationId: undefined
  employeeId: undefined
}

export type BookingDialogInitialDto = BookingDialogInitialDtoCreate | BookingDialogInitialDtoUpdate

interface BookingDialogProps {
  initialData: BookingDialogInitialDto
  closeDialog: () => void
}

const BookingDialog: FC<BookingDialogProps> = ({ initialData, closeDialog }) => {
  const mutableCloseFunction = useRef(closeDialog)

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { isLoading: isBookingLoading, isRefetching: isBookingRefetching } = useFetchBookingById(
    initialData.id,
    timezone,
    branchId,
  )
  const { isLoading: isServiceLoading } = useFetchExtendedServices(branchId, 'all')
  const { isLoading: isEmployeesLoading } = useFetchExtendedEmployees(timezone, branchId)
  const { isLoading: isDeactivatedServicesLoading } = useFetchDeactivatedServices(
    branchId,
    'all',
    !initialData.isCreate,
  )
  const { isLoading: isServiceGroupsLoading } = useFetchServiceGroups(branchId)
  const { isLoading: isMyPermissionsLoading } = useFetchMyPermissions()

  const isLoading =
    isBookingLoading ||
    isServiceLoading ||
    isEmployeesLoading ||
    isServiceGroupsLoading ||
    isMyPermissionsLoading ||
    isDeactivatedServicesLoading ||
    isBookingRefetching

  const showPlaceholder = !initialData.isCreate && isLoading

  const closeModal = () => {
    // если передавать ref в модал, компонент не реагирует, что поменялась функция закрытия
    // и будет вызываться первая фун-ция которая была в ref
    mutableCloseFunction.current()
  }

  return (
    <Modal close={closeModal}>
      <Dialog>
        {showPlaceholder ? (
          <BookingDialogPlaceholder closeDialog={closeDialog} />
        ) : (
          <BookingDialogLogic
            closeDialog={closeDialog}
            mutableCloseFunction={mutableCloseFunction}
            initialData={initialData}
          />
        )}
      </Dialog>
    </Modal>
  )
}

const BookingDialogLogic: FC<
  BookingDialogProps & {
    mutableCloseFunction: MutableRefObject<() => void>
  }
> = ({ closeDialog, initialData, mutableCloseFunction }) => {
  const { t } = useTranslation()
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { id, isCreate, locationId, employeeId, startDate } = initialData
  const { data: bookingById } = useFetchBookingById(id, timezone, branchId)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const bookingStartDate = isCreate ? startDate! : bookingById!.startDate!

  const canEditBookingByDate = useCheckBookingDateEditable(bookingStartDate)

  const { data: employees } = useFetchExtendedEmployees(timezone, branchId)

  const { data: services } = useFetchExtendedServices(branchId, 'all')
  const { data: archivedServices } = useFetchDeactivatedServices(
    branchId,
    'all',
    !canEditBookingByDate && !isCreate,
  )
  const { data: serviceGroups } = useFetchServiceGroups(branchId)
  const notGroupServices = getServicesForTreeMenu({
    checkArchived: !isCreate && !canEditBookingByDate,
    services,
    archivedServices,
    servicesFromBooking: (bookingById as NotGroupBooking)?.bookingServices.map(
      bookingService => bookingService.service,
    ),
  })

  const serviceItems = transformDataForTreeMenu(serviceGroups, notGroupServices)
  const convertedInitialServiceItems = isCreate
    ? []
    : convertServicesToTreeItems(
        (bookingById as NotGroupBooking).bookingServices.map(bS => bS.service),
      )
  const groupedInitialServiceItems = groupInitialTreeItems(
    convertedInitialServiceItems,
    serviceItems,
  )

  const defaultLocationId = getDefaultLocationId({
    isCreate,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    employees: employees!,
    initialEmployeeId: employeeId,
    initialLocationId: locationId,
    locationIdFromBooking: bookingById?.location.id,
  })

  const form = useForm<BookingDialogFormValues>({
    defaultValues: {
      locationId: defaultLocationId,
      employeeId: isCreate ? employeeId : bookingById?.employee?.id,
      clientId: isCreate ? undefined : (bookingById as NotGroupBooking).client?.id,
      startDate: bookingStartDate,
      duration: isCreate ? INITIAL_DURATION.toString() : bookingById?.duration.toString(),
      note: isCreate ? '' : bookingById?.note ?? '',
      services: isCreate ? [] : groupedInitialServiceItems,
      consumables: getDefaultConsumablesForBookingForm(isCreate, bookingById),
    },
  })

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(form.formState, closeDialog)

  useEffect(() => {
    // посылаем модифицированное закрытие через нажатие на фон
    mutableCloseFunction.current = closePopups
  }, [closePopups, mutableCloseFunction])

  const { data: myPermissions } = useFetchMyPermissions()

  const isSomeServicesPaid = bookingById
    ? getIsSomeServicesPaid(
        (bookingById as NotGroupBooking).bookingServices.map(bS => bS.service),
        bookingById?.transactions,
      )
    : false

  const isDone = getIsBookingDone(bookingById)
  const isCanceled = getIsBookingCanceled(bookingById)

  const isEditable =
    canEditBookingByDate &&
    !isDone &&
    (Boolean(myPermissions?.includes(permissions.booking.set)) ||
      Boolean(myPermissions?.includes(permissions.booking.setOwn)))

  const isRecurring = bookingById ? getIsBookingRecurring(bookingById) : false

  return (
    <>
      <Dialog.Title>
        <div className={'flex items-center'}>
          {t('booking.name')}
          {isRecurring && <BookingRepeatLabel className={'ml-2'} />}
        </div>
      </Dialog.Title>
      <BookingDialogBody
        form={form}
        disabled={!isEditable || isCanceled}
        isSomeServicesPaid={isSomeServicesPaid}
        isCreate={isCreate}
        bookingId={id}
      />
      <BookingDialogFooter
        initialData={initialData}
        control={form.control}
        timezone={timezone ?? DEFAULT_TIMEZONE}
        handleSubmit={form.handleSubmit}
        availableForEdit={isEditable}
        isCanceled={isCanceled}
        transactions={bookingById?.transactions}
        closeDialog={closeDialog}
        closePopups={closePopups}
      />
      {confirmPopup}
    </>
  )
}

export const useOpenBookingDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  // common
  const dialogIsCreate = useRef<boolean>()

  // edit
  const dialogBookingId = useRef<number>()

  // create
  const dialogStartDate = useRef<Date>()
  const dialogLocationId = useRef<number>()
  const dialogEmployeeId = useRef<number>()

  const onCloseEditDialog = useRef<() => void>()
  const onCloseCreatDialog = useRef<() => void>()

  const openEditBookingDialog = (bookingId: number, onClose?: () => void) => {
    dialogIsCreate.current = false
    dialogBookingId.current = bookingId
    onCloseEditDialog.current = onClose
    openPopup()
  }
  const openCreateBookingDialog = ({
    startDate,
    employeeId,
    locationId,
    onClose,
  }: {
    startDate: Date
    employeeId: number | undefined
    locationId: number | undefined
    onClose?: () => void
  }) => {
    dialogBookingId.current = undefined
    dialogIsCreate.current = true
    dialogStartDate.current = startDate
    dialogEmployeeId.current = employeeId
    dialogLocationId.current = locationId
    onCloseCreatDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()

    if (dialogIsCreate.current === true) onCloseCreatDialog.current?.()
    if (dialogIsCreate.current === false) onCloseEditDialog.current?.()
  }

  const bookingDialog = isOpen ? (
    <BookingDialog
      // @ts-expect-error ругается на isCreate
      initialData={{
        id: dialogBookingId.current,
        startDate: dialogStartDate.current,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        locationId: dialogLocationId.current!,
        employeeId: dialogEmployeeId.current,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        isCreate: dialogIsCreate.current!,
      }}
      closeDialog={closeDialog}
    />
  ) : null
  return { openEditBookingDialog, openCreateBookingDialog, bookingDialog }
}

export const INITIAL_DURATION = 5

export type BookingDialogFormValues = {
  clientId: number | null
  duration: string | undefined
  services: Array<TreeMenuItem>
  consumables: Consumable[]
  startDate: Date
  locationId: number | undefined
  employeeId: number | undefined
  note: string
  recurring: RecurringType | null
}
