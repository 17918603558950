import { ExtendedEmployee } from '@expane/data'
import { TFunction } from 'react-i18next'
import { TreeMenuItem } from 'ui/TreeMenu'
import { ServiceIdsHashMap } from '@expane/logic/employee'

export const validateEmployeeEmail = (
  email: string | undefined,
  employees: ExtendedEmployee[] | undefined,
  currentEmail: string | undefined,
) => {
  if (!email) return true
  if (!employees) return false

  if (currentEmail && currentEmail === email) return true

  return !employees.some(employee => employee.email === email)
}

export const generateEmailErrorMessage = (
  type: string | undefined,
  t: TFunction,
): string | undefined => {
  if (type === 'validateEmail') return t('authForm.wrongEmail')
  if (type === 'validateEmployeeEmail') return t('alreadyInUse')
}

// Disable service for unselect if it was used
export const provideDisabledServicesTreeMenuForUsedServices = (
  services: TreeMenuItem[],
  serviceIdsHashMap: ServiceIdsHashMap,
  t: TFunction,
): TreeMenuItem[] => {
  return services.map(service => {
    if (service.isFolder && service.nodes) {
      return {
        ...service,
        nodes: provideDisabledServicesTreeMenuForUsedServices(service.nodes, serviceIdsHashMap, t),
      }
    }

    if (serviceIdsHashMap[service.id])
      return { ...service, disabled: true, warningMessage: t('employee.serviceWasUsed') }

    return service
  })
}
