type CountryData = {
  code: string
  dialCode: string
  flag: string
  en: string
  uk: string
  ru: string
}

export const CountryList: CountryData[] = [
  {
    en: 'Afghanistan',
    uk: 'Афганістан',
    ru: 'Афганистан',
    dialCode: '+93',
    flag: '🇦🇫',
    code: 'AF',
  },
  {
    en: 'Åland Islands',
    uk: 'Аландські острови',
    ru: 'Аландские острова',
    dialCode: '+358',
    flag: '🇦🇽',
    code: 'AX',
  },
  { en: 'Albania', uk: 'Албанія', ru: 'Албания', dialCode: '+355', flag: '🇦🇱', code: 'AL' },
  { en: 'Algeria', uk: 'Алжир', ru: 'Алжир', dialCode: '+213', flag: '🇩🇿', code: 'DZ' },
  {
    en: 'American Samoa',
    uk: 'Американське Самоа',
    ru: 'Американское Самоа',
    dialCode: '+1 684',
    flag: '🇦🇸',
    code: 'AS',
  },
  { en: 'Andorra', uk: 'Андорра', ru: 'Андорра', dialCode: '+376', flag: '🇦🇩', code: 'AD' },
  { en: 'Angola', uk: 'Ангола', ru: 'Ангола', dialCode: '+244', flag: '🇦🇴', code: 'AO' },
  { en: 'Anguilla', uk: 'Ангілья', ru: 'Ангилья', dialCode: '+1 264', flag: '🇦🇮', code: 'AI' },
  {
    en: 'Antarctica',
    uk: 'Антарктида',
    ru: 'Антарктида',
    dialCode: '+672',
    flag: '🇦🇶',
    code: 'AQ',
  },
  {
    en: 'Antigua and Barbuda',
    uk: 'Антигуа і Барбуда',
    ru: 'Антигуа и Барбуда',
    dialCode: '+1 268',
    flag: '🇦🇬',
    code: 'AG',
  },
  { en: 'Argentina', uk: 'Аргентина', ru: 'Аргентина', dialCode: '+54', flag: '🇦🇷', code: 'AR' },
  { en: 'Armenia', uk: 'Вірменія', ru: 'Армения', dialCode: '+374', flag: '🇦🇲', code: 'AM' },
  { en: 'Aruba', uk: 'Аруба', ru: 'Аруба', dialCode: '+297', flag: '🇦🇼', code: 'AW' },
  { en: 'Australia', uk: 'Австралія', ru: 'Австралия', dialCode: '+61', flag: '🇦🇺', code: 'AU' },
  { en: 'Austria', uk: 'Австрія', ru: 'Австрия', dialCode: '+43', flag: '🇦🇹', code: 'AT' },
  {
    en: 'Azerbaijan',
    uk: 'Азербайджан',
    ru: 'Азербайджан',
    dialCode: '+994',
    flag: '🇦🇿',
    code: 'AZ',
  },
  {
    en: 'Bahamas',
    uk: 'Багамські острови',
    ru: 'Багамские острова',
    dialCode: '+1 242',
    flag: '🇧🇸',
    code: 'BS',
  },
  { en: 'Bahrain', uk: 'Бахрейн', ru: 'Бахрейн', dialCode: '+973', flag: '🇧🇭', code: 'BH' },
  { en: 'Bangladesh', uk: 'Бангладеш', ru: 'Бангладэш', dialCode: '+880', flag: '🇧🇩', code: 'BD' },
  { en: 'Barbados', uk: 'Барбадос', ru: 'Барбадос', dialCode: '+1 246', flag: '🇧🇧', code: 'BB' },
  { en: 'Belarus', uk: 'Білорусь', ru: 'Беларусь', dialCode: '+375', flag: '🇧🇾', code: 'BY' },
  { en: 'Belgium', uk: 'Бельгія', ru: 'Бельгия', dialCode: '+32', flag: '🇧🇪', code: 'BE' },
  { en: 'Belize', uk: 'Беліз', ru: 'Белиз', dialCode: '+501', flag: '🇧🇿', code: 'BZ' },
  { en: 'Benin', uk: 'Бенін', ru: 'Бенин', dialCode: '+229', flag: '🇧🇯', code: 'BJ' },
  {
    en: 'Bermuda',
    uk: 'Бермудські острови',
    ru: 'Бермудские острова',
    dialCode: '+1 441',
    flag: '🇧🇲',
    code: 'BM',
  },
  { en: 'Bhutan', uk: 'Бутан', ru: 'Бутан', dialCode: '+975', flag: '🇧🇹', code: 'BT' },
  {
    en: 'Bolivia, Plurinational State of',
    uk: 'Болівія, Багатонаціональна Держава',
    ru: 'Боливия, Многонациональное государство',
    dialCode: '+591',
    flag: '🇧🇴',
    code: 'BO',
  },
  {
    en: 'Bosnia and Herzegovina',
    uk: 'Боснія і Герцеговина',
    ru: 'Босния и Герцеговина',
    dialCode: '+387',
    flag: '🇧🇦',
    code: 'BA',
  },
  { en: 'Botswana', uk: 'Ботсвана', ru: 'Ботсвана', dialCode: '+267', flag: '🇧🇼', code: 'BW' },
  { en: 'Brazil', uk: 'Бразилія', ru: 'Бразилия', dialCode: '+55', flag: '🇧🇷', code: 'BR' },
  {
    en: 'British Indian Ocean Territory',
    uk: 'Британська територія в Індійському океані',
    ru: 'Британская территория в Индийском океане',
    dialCode: '+246',
    flag: '🇮🇴',
    code: 'IO',
  },
  {
    en: 'Brunei Darussalam',
    uk: 'Бруней Даруссалам',
    ru: 'Бруней Даруссалам',
    dialCode: '+673',
    flag: '🇧🇳',
    code: 'BN',
  },
  { en: 'Bulgaria', uk: 'Болгарія', ru: 'Болгария', dialCode: '+359', flag: '🇧🇬', code: 'BG' },
  {
    en: 'Burkina Faso',
    uk: 'Буркіна Фасо',
    ru: 'Буркина Фасо',
    dialCode: '+226',
    flag: '🇧🇫',
    code: 'BF',
  },
  { en: 'Burundi', uk: 'Бурунді', ru: 'Бурунди', dialCode: '+257', flag: '🇧🇮', code: 'BI' },
  { en: 'Cambodia', uk: 'Камбоджа', ru: 'Камбоджа', dialCode: '+855', flag: '🇰🇭', code: 'KH' },
  { en: 'Cameroon', uk: 'Камерун', ru: 'Камерун', dialCode: '+237', flag: '🇨🇲', code: 'CM' },
  { en: 'Canada', uk: 'Канада', ru: 'Канада', dialCode: '+1', flag: '🇨🇦', code: 'CA' },
  {
    en: 'Canary Islands',
    uk: 'Канарські острови',
    ru: 'Канарские острова',
    dialCode: '+34',
    flag: '🇮🇨',
    code: 'IC',
  },
  {
    en: 'Cape Verde',
    uk: 'Кабо-Верде',
    ru: 'Кабо-Верде',
    dialCode: '+238',
    flag: '🇨🇻',
    code: 'CV',
  },
  {
    en: 'Caribbean Netherlands',
    uk: 'Карибські Нідерланди',
    ru: 'Карибские Нидерланды',
    dialCode: '+599',
    flag: '🇧🇶',
    code: 'BQ',
  },
  {
    en: 'Cayman Islands',
    uk: 'Кайманові острови',
    ru: 'Каймановы острова',
    dialCode: '+345',
    flag: '🇰🇾',
    code: 'KY',
  },
  {
    en: 'Central African Republic',
    uk: 'Центральноафриканська Республіка',
    ru: 'Центральноафриканская Республика',
    dialCode: '+236',
    flag: '🇨🇫',
    code: 'CF',
  },
  { en: 'Chad', uk: 'Чад', ru: 'Чад', dialCode: '+235', flag: '🇹🇩', code: 'TD' },
  { en: 'Chile', uk: 'Чилі', ru: 'Чили', dialCode: '+56', flag: '🇨🇱', code: 'CL' },
  { en: 'China', uk: 'Китай', ru: 'Китай', dialCode: '+86', flag: '🇨🇳', code: 'CN' },
  {
    en: 'Christmas Island',
    uk: 'Острів Різдва',
    ru: 'Остров Рождества',
    dialCode: '+61',
    flag: '🇨🇽',
    code: 'CX',
  },
  {
    en: 'Cocos (Keeling) Islands',
    uk: 'Кокосові (Кілінгові) острови',
    ru: 'Кокосовые (Килинговые) острова',
    dialCode: '+61',
    flag: '🇨🇨',
    code: 'CC',
  },
  { en: 'Colombia', uk: 'Колумбія', ru: 'Колумбия', dialCode: '+57', flag: '🇨🇴', code: 'CO' },
  {
    en: 'Comoros',
    uk: 'Коморські острови',
    ru: 'Коморские острова',
    dialCode: '+269',
    flag: '🇰🇲',
    code: 'KM',
  },
  { en: 'Congo', uk: 'Конго', ru: 'Конго', dialCode: '+242', flag: '🇨🇬', code: 'CG' },
  {
    en: 'Congo, The Democratic Republic of the',
    uk: 'Конго, Демократична Республіка',
    ru: 'Конго, Демократическая Республика',
    dialCode: '+243',
    flag: '🇨🇩',
    code: 'CD',
  },
  {
    en: 'Cook Islands',
    uk: 'Острови Кука',
    ru: 'Острова Кука',
    dialCode: '+682',
    flag: '🇨🇰',
    code: 'CK',
  },
  {
    en: 'Costa Rica',
    uk: 'Коста-Ріка',
    ru: 'Коста-Рика',
    dialCode: '+506',
    flag: '🇨🇷',
    code: 'CR',
  },
  {
    en: "Cote d'Ivoire",
    uk: "Кот д'Івуар",
    ru: "Кот д'Ивуар",
    dialCode: '+225',
    flag: '🇨🇮',
    code: 'CI',
  },
  { en: 'Croatia', uk: 'Хорватія', ru: 'Хорватия', dialCode: '+385', flag: '🇭🇷', code: 'HR' },
  { en: 'Cuba', uk: 'Куба', ru: 'Куба', dialCode: '+53', flag: '🇨🇺', code: 'CU' },
  { en: 'Curaçao', uk: 'Кюрасао', ru: 'Кюрасао', dialCode: '+599', flag: '🇨🇼', code: 'CW' },
  { en: 'Cyprus', uk: 'Кіпр', ru: 'Кипр', dialCode: '+357', flag: '🇨🇾', code: 'CY' },
  {
    en: 'Czech Republic',
    uk: 'Чеська Республіка',
    ru: 'Чехия',
    dialCode: '+420',
    flag: '🇨🇿',
    code: 'CZ',
  },
  { en: 'Denmark', uk: 'Данія', ru: 'Дания', dialCode: '+45', flag: '🇩🇰', code: 'DK' },
  { en: 'Djibouti', uk: 'Джібуті', ru: 'Джибути', dialCode: '+253', flag: '🇩🇯', code: 'DJ' },
  { en: 'Dominica', uk: 'Домініка', ru: 'Доминика', dialCode: '+1 767', flag: '🇩🇲', code: 'DM' },
  {
    en: 'Dominican Republic',
    uk: 'Домініканська республіка',
    ru: 'Доминиканская республика',
    dialCode: '+1 849',
    flag: '🇩🇴',
    code: 'DO',
  },
  {
    en: 'Dominican Republic',
    uk: 'Домініканська республіка',
    ru: 'Доминиканская республика',
    dialCode: '+1 829',
    flag: '🇩🇴',
    code: 'DO',
  },
  {
    en: 'Dominican Republic',
    uk: 'Домініканська республіка',
    ru: 'Доминиканская республика',
    dialCode: '+1 809',
    flag: '🇩🇴',
    code: 'DO',
  },
  { en: 'Ecuador', uk: 'Еквадор', ru: 'Эквадор', dialCode: '+593', flag: '🇪🇨', code: 'EC' },
  { en: 'Egypt', uk: 'Єгипет', ru: 'Египет', dialCode: '+20', flag: '🇪🇬', code: 'EG' },
  { en: 'El Salvador', uk: 'Сальвадор', ru: 'Сальвадор', dialCode: '+503', flag: '🇸🇻', code: 'SV' },
  {
    en: 'Equatorial Guinea',
    uk: 'Екваторіальна Гвінея',
    ru: 'Экваториальная Гвинея',
    dialCode: '+240',
    flag: '🇬🇶',
    code: 'GQ',
  },
  { en: 'Eritrea', uk: 'Еритрея', ru: 'Эстония', dialCode: '+291', flag: '🇪🇷', code: 'ER' },
  { en: 'Estonia', uk: 'Естонія', ru: 'Эритрея', dialCode: '+372', flag: '🇪🇪', code: 'EE' },
  { en: 'Ethiopia', uk: 'Ефіопія', ru: 'Эфиопия', dialCode: '+251', flag: '🇪🇹', code: 'ET' },
  {
    en: 'Falkland Islands (Malvinas)',
    uk: 'Фолклендські (Мальвінські) острови',
    ru: 'Фолклендские (Мальвинские) острова',
    dialCode: '+500',
    flag: '🇫🇰',
    code: 'FK',
  },
  {
    en: 'Faroe Islands',
    uk: 'Фарерські острови',
    ru: 'Фарерские острова',
    dialCode: '+298',
    flag: '🇫🇴',
    code: 'FO',
  },
  { en: 'Fiji', uk: 'Фіджі', ru: 'Фиджи', dialCode: '+679', flag: '🇫🇯', code: 'FJ' },
  { en: 'Finland', uk: 'Фінляндія', ru: 'Финляндия', dialCode: '+358', flag: '🇫🇮', code: 'FI' },
  { en: 'France', uk: 'Франція', ru: 'Франция', dialCode: '+33', flag: '🇫🇷', code: 'FR' },
  {
    en: 'French Guiana',
    uk: 'Французька Гвіана',
    ru: 'Французская Гвиана',
    dialCode: '+594',
    flag: '🇬🇫',
    code: 'GF',
  },
  {
    en: 'French Polynesia',
    uk: 'Французька Полінезія',
    ru: 'Французская Полинезия',
    dialCode: '+689',
    flag: '🇵🇫',
    code: 'PF',
  },
  {
    en: 'French Southern Territories',
    uk: 'Французькі Південні території',
    ru: 'Французские Южные территории',
    dialCode: '+262',
    flag: '🇹🇫',
    code: 'TF',
  },
  { en: 'Gabon', uk: 'Габон', ru: 'Габон', dialCode: '+241', flag: '🇬🇦', code: 'GA' },
  { en: 'Gambia', uk: 'Гамбія', ru: 'Гамбия', dialCode: '+220', flag: '🇬🇲', code: 'GM' },
  { en: 'Georgia', uk: 'Грузія', ru: 'Грузия', dialCode: '+995', flag: '🇬🇪', code: 'GE' },
  { en: 'Germany', uk: 'Німеччина', ru: 'Германия', dialCode: '+49', flag: '🇩🇪', code: 'DE' },
  { en: 'Ghana', uk: 'Гана', ru: 'Гана', dialCode: '+233', flag: '🇬🇭', code: 'GH' },
  { en: 'Gibraltar', uk: 'Гібралтар', ru: 'Гибралтар', dialCode: '+350', flag: '🇬🇮', code: 'GI' },
  { en: 'Greece', uk: 'Греція', ru: 'Греция', dialCode: '+30', flag: '🇬🇷', code: 'GR' },
  { en: 'Greenland', uk: 'Гренландія', ru: 'Гренландия', dialCode: '+299', flag: '🇬🇱', code: 'GL' },
  { en: 'Grenada', uk: 'Гренада', ru: 'Гренада', dialCode: '+1 473', flag: '🇬🇩', code: 'GD' },
  { en: 'Guadeloupe', uk: 'Гваделупа', ru: 'Гваделупа', dialCode: '+590', flag: '🇬🇵', code: 'GP' },
  { en: 'Guam', uk: 'Гуам', ru: 'Гуам', dialCode: '+1 671', flag: '🇬🇺', code: 'GU' },
  { en: 'Guatemala', uk: 'Гватемала', ru: 'Гватемала', dialCode: '+502', flag: '🇬🇹', code: 'GT' },
  { en: 'Guernsey', uk: 'Гернсі', ru: 'Гернси', dialCode: '+44', flag: '🇬🇬', code: 'GG' },
  { en: 'Guinea', uk: 'Гвінея', ru: 'Гвинея', dialCode: '+224', flag: '🇬🇳', code: 'GN' },
  {
    en: 'Guinea-Bissau',
    uk: 'Гвінея-Бісау',
    ru: 'Гвинея-Бисау',
    dialCode: '+245',
    flag: '🇬🇼',
    code: 'GW',
  },
  { en: 'Guyana', uk: 'Гайана', ru: 'Гайана', dialCode: '+592', flag: '🇬🇾', code: 'GY' },
  { en: 'Haiti', uk: 'Гаїті', ru: 'Гаити', dialCode: '+509', flag: '🇭🇹', code: 'HT' },
  {
    en: 'Heard & McDonald Islands',
    uk: 'Острови Херд і Макдональд',
    ru: 'Острова Херд и Макдональд',
    dialCode: '+672',
    flag: '🇭🇲',
    code: 'HM',
  },
  {
    en: 'Holy See (Vatican City State)',
    uk: 'Святий Престол (місто-держава Ватикан)',
    ru: 'Святой Престол (город-государство Ватикан)',
    dialCode: '+379',
    flag: '🇻🇦',
    code: 'VA',
  },
  { en: 'Honduras', uk: 'Гондурас', ru: 'Гондурас', dialCode: '+504', flag: '🇭🇳', code: 'HN' },
  { en: 'Hong Kong', uk: 'Гонконг', ru: 'Гонконг', dialCode: '+852', flag: '🇭🇰', code: 'HK' },
  { en: 'Hungary', uk: 'Угорщина', ru: 'Венгрия', dialCode: '+36', flag: '🇭🇺', code: 'HU' },
  { en: 'Iceland', uk: 'Ісландія', ru: 'Исландия', dialCode: '+354', flag: '🇮🇸', code: 'IS' },
  { en: 'India', uk: 'Індія', ru: 'Индия', dialCode: '+91', flag: '🇮🇳', code: 'IN' },
  { en: 'Indonesia', uk: 'Індонезія', ru: 'Индонезия', dialCode: '+62', flag: '🇮🇩', code: 'ID' },
  {
    en: 'Iran, Islamic Republic of',
    uk: 'Іран, Ісламська Республіка',
    ru: 'Иран, Исламская Республика',
    dialCode: '+98',
    flag: '🇮🇷',
    code: 'IR',
  },
  { en: 'Iraq', uk: 'Ірак', ru: 'Ирак', dialCode: '+964', flag: '🇮🇶', code: 'IQ' },
  { en: 'Ireland', uk: 'Ірландія', ru: 'Ирландия', dialCode: '+353', flag: '🇮🇪', code: 'IE' },
  { en: 'Isle of Man', uk: 'Острів Мен', ru: 'Остров', dialCode: '+44', flag: '🇮🇲', code: 'IM' },
  { en: 'Israel', uk: 'Ізраїль', ru: 'Израиль', dialCode: '+972', flag: '🇮🇱', code: 'IL' },
  { en: 'Italy', uk: 'Італія', ru: 'Италия', dialCode: '+39', flag: '🇮🇹', code: 'IT' },
  { en: 'Jamaica', uk: 'Ямайка', ru: 'Ямайка', dialCode: '+1 876', flag: '🇯🇲', code: 'JM' },
  { en: 'Jamaica', uk: 'Ямайка', ru: 'Ямайка', dialCode: '+1 658', flag: '🇯🇲', code: 'JM' },
  { en: 'Japan', uk: 'Японія', ru: 'Япония', dialCode: '+81', flag: '🇯🇵', code: 'JP' },
  { en: 'Jersey', uk: 'Джерсі', ru: 'Джерси', dialCode: '+44', flag: '🇯🇪', code: 'JE' },
  { en: 'Jordan', uk: 'Йорданія', ru: 'Иордания', dialCode: '+962', flag: '🇯🇴', code: 'JO' },
  { en: 'Kazakhstan', uk: 'Казахстан', ru: 'Казахстан', dialCode: '+77', flag: '🇰🇿', code: 'KZ' },
  { en: 'Kenya', uk: 'Кенія', ru: 'Кения', dialCode: '+254', flag: '🇰🇪', code: 'KE' },
  { en: 'Kiribati', uk: 'Кірібаті', ru: 'Кирибати', dialCode: '+686', flag: '🇰🇮', code: 'KI' },
  {
    en: "Korea, Democratic People's Republic of",
    uk: 'Корея, Народно-Демократична Республіка',
    ru: 'Корея, Народно-демократическая Республика',
    dialCode: '+850',
    flag: '🇰🇵',
    code: 'KP',
  },
  {
    en: 'Korea, Republic of',
    uk: 'Республіка Корея',
    ru: 'Республика Корея',
    dialCode: '+82',
    flag: '🇰🇷',
    code: 'KR',
  },
  { en: 'Kosovo', uk: 'Косово', ru: 'Косово', dialCode: '+383', flag: '🇽🇰', code: 'XK' },
  { en: 'Kuwait', uk: 'Кувейт', ru: 'Кувейт', dialCode: '+965', flag: '🇰🇼', code: 'KW' },
  {
    en: 'Kyrgyzstan',
    uk: 'Киргизстан',
    ru: 'Кыргызстан',
    dialCode: '+996',
    flag: '🇰🇬',
    code: 'KG',
  },
  {
    en: "Lao People's Democratic Republic",
    uk: 'Лаоська Народно-Демократична Республіка',
    ru: 'Лаосская Народно-Демократическая Республика',
    dialCode: '+856',
    flag: '🇱🇦',
    code: 'LA',
  },
  { en: 'Latvia', uk: 'Латвія', ru: 'Латвия', dialCode: '+371', flag: '🇱🇻', code: 'LV' },
  { en: 'Lebanon', uk: 'Ліван', ru: 'Ливан', dialCode: '+961', flag: '🇱🇧', code: 'LB' },
  { en: 'Lesotho', uk: 'Лесото', ru: 'Лесото', dialCode: '+266', flag: '🇱🇸', code: 'LS' },
  { en: 'Liberia', uk: 'Ліберія', ru: 'Либерия', dialCode: '+231', flag: '🇱🇷', code: 'LR' },
  {
    en: 'Libyan Arab Jamahiriya',
    uk: 'Лівійська Арабська Джамахірія',
    ru: 'Ливийская Арабская Джамахирия',
    dialCode: '+218',
    flag: '🇱🇾',
    code: 'LY',
  },
  {
    en: 'Liechtenstein',
    uk: 'Ліхтенштейн',
    ru: 'Лихтенштейн',
    dialCode: '+423',
    flag: '🇱🇮',
    code: 'LI',
  },
  { en: 'Lithuania', uk: 'Литва', ru: 'Литва', dialCode: '+370', flag: '🇱🇹', code: 'LT' },
  {
    en: 'Luxembourg',
    uk: 'Люксембург',
    ru: 'Люксембург',
    dialCode: '+352',
    flag: '🇱🇺',
    code: 'LU',
  },
  { en: 'Macao', uk: 'Макао', ru: 'Макао', dialCode: '+853', flag: '🇲🇴', code: 'MO' },
  {
    en: 'Macedonia, The Former Yugoslav Republic of',
    uk: 'Македонія, Колишня Югославська Республіка',
    ru: 'Македония, Бывшая Югославская Республика',
    dialCode: '+389',
    flag: '🇲🇰',
    code: 'MK',
  },
  {
    en: 'Madagascar',
    uk: 'Мадагаскар',
    ru: 'Мадагаскар',
    dialCode: '+261',
    flag: '🇲🇬',
    code: 'MG',
  },
  { en: 'Malawi', uk: 'Малаві', ru: 'Малави', dialCode: '+265', flag: '🇲🇼', code: 'MW' },
  { en: 'Malaysia', uk: 'Малайзія', ru: 'Малайзия', dialCode: '+60', flag: '🇲🇾', code: 'MY' },
  { en: 'Maldives', uk: 'Мальдіви', ru: 'Мальдивы', dialCode: '+960', flag: '🇲🇻', code: 'MV' },
  { en: 'Mali', uk: 'Малі', ru: 'Мали', dialCode: '+223', flag: '🇲🇱', code: 'ML' },
  { en: 'Malta', uk: 'Мальта', ru: 'Мальта', dialCode: '+356', flag: '🇲🇹', code: 'MT' },
  {
    en: 'Marshall Islands',
    uk: 'Маршаллові острови',
    ru: 'Маршалловы острова',
    dialCode: '+692',
    flag: '🇲🇭',
    code: 'MH',
  },
  { en: 'Martinique', uk: 'Мартініка', ru: 'Мартиника', dialCode: '+596', flag: '🇲🇶', code: 'MQ' },
  {
    en: 'Mauritania',
    uk: 'Мавританія',
    ru: 'Мавритания',
    dialCode: '+222',
    flag: '🇲🇷',
    code: 'MR',
  },
  { en: 'Mauritius', uk: 'Маврикій', ru: 'Маврикий', dialCode: '+230', flag: '🇲🇺', code: 'MU' },
  { en: 'Mayotte', uk: 'Майотта', ru: 'Майотта', dialCode: '+262', flag: '🇾🇹', code: 'YT' },
  { en: 'Mexico', uk: 'Мексика', ru: 'Мексика', dialCode: '+52', flag: '🇲🇽', code: 'MX' },
  {
    en: 'Micronesia, Federated States of',
    uk: 'Мікронезія, Федеративні Штати',
    ru: 'Микронезия, Федеративные Штаты',
    dialCode: '+691',
    flag: '🇫🇲',
    code: 'FM',
  },
  {
    en: 'Moldova, Republic of',
    uk: 'Республіка Молдова',
    ru: 'Республика Молдова',
    dialCode: '+373',
    flag: '🇲🇩',
    code: 'MD',
  },
  { en: 'Monaco', uk: 'Монако', ru: 'Монако', dialCode: '+377', flag: '🇲🇨', code: 'MC' },
  { en: 'Mongolia', uk: 'Монголія', ru: 'Монголия', dialCode: '+976', flag: '🇲🇳', code: 'MN' },
  {
    en: 'Montenegro',
    uk: 'Чорногорія',
    ru: 'Черногория',
    dialCode: '+382',
    flag: '🇲🇪',
    code: 'ME',
  },
  {
    en: 'Montserrat',
    uk: 'Монсеррат',
    ru: 'Монсеррат',
    dialCode: '+1 664',
    flag: '🇲🇸',
    code: 'MS',
  },
  { en: 'Morocco', uk: 'Марокко', ru: 'Марокко', dialCode: '+212', flag: '🇲🇦', code: 'MA' },
  { en: 'Mozambique', uk: 'Мозамбік', ru: 'Мозамбик', dialCode: '+258', flag: '🇲🇿', code: 'MZ' },
  { en: 'Myanmar', uk: "М'янма", ru: 'Мьянма', dialCode: '+95', flag: '🇲🇲', code: 'MM' },
  { en: 'Namibia', uk: 'Намібія', ru: 'Намибия', dialCode: '+264', flag: '🇳🇦', code: 'NA' },
  { en: 'Nauru', uk: 'Науру', ru: 'Науру', dialCode: '+674', flag: '🇳🇷', code: 'NR' },
  { en: 'Nepal', uk: 'Непал', ru: 'Непал', dialCode: '+977', flag: '🇳🇵', code: 'NP' },
  {
    en: 'Netherlands',
    uk: 'Нідерланди',
    ru: 'Нидерланды',
    dialCode: '+31',
    flag: '🇳🇱',
    code: 'NL',
  },
  {
    en: 'New Caledonia',
    uk: 'Нова Каледонія',
    ru: 'Новая Каледония',
    dialCode: '+687',
    flag: '🇳🇨',
    code: 'NC',
  },
  {
    en: 'New Zealand',
    uk: 'Нова Зеландія',
    ru: 'Новая Зеландия',
    dialCode: '+64',
    flag: '🇳🇿',
    code: 'NZ',
  },
  { en: 'Nicaragua', uk: 'Нікарагуа', ru: 'Никарагуа', dialCode: '+505', flag: '🇳🇮', code: 'NI' },
  { en: 'Niger', uk: 'Нігер', ru: 'Нигер', dialCode: '+227', flag: '🇳🇪', code: 'NE' },
  { en: 'Nigeria', uk: 'Нігерія', ru: 'Нигерия', dialCode: '+234', flag: '🇳🇬', code: 'NG' },
  { en: 'Niue', uk: 'Ніуе', ru: 'Ниуэ', dialCode: '+683', flag: '🇳🇺', code: 'NU' },
  {
    en: 'Norfolk Island',
    uk: 'Острів Норфолк',
    ru: 'Остров Норфолк',
    dialCode: '+672',
    flag: '🇳🇫',
    code: 'NF',
  },
  {
    en: 'Northern Mariana Islands',
    uk: 'Північні Маріанські острови',
    ru: 'Северные Марианские острова',
    dialCode: '+1670',
    flag: '🇲🇵',
    code: 'MP',
  },
  { en: 'Norway', uk: 'Норвегія', ru: 'Норвегия', dialCode: '+47', flag: '🇳🇴', code: 'NO' },
  { en: 'Oman', uk: 'Оман', ru: 'Оман', dialCode: '+968', flag: '🇴🇲', code: 'OM' },
  { en: 'Pakistan', uk: 'Пакистан', ru: 'Пакистан', dialCode: '+92', flag: '🇵🇰', code: 'PK' },
  { en: 'Palau', uk: 'Палау', ru: 'Палау', dialCode: '+680', flag: '🇵🇼', code: 'PW' },
  {
    en: 'Palestinian Territory, Occupied',
    uk: 'Палестинська територія, окупована',
    ru: 'Палестинская территория, оккупированная',
    dialCode: '+970',
    flag: '🇵🇸',
    code: 'PS',
  },
  { en: 'Panama', uk: 'Панама', ru: 'Панама', dialCode: '+507', flag: '🇵🇦', code: 'PA' },
  {
    en: 'Papua New Guinea',
    uk: 'Папуа-Нова Гвінея',
    ru: 'Папуа-Новая Гвинея',
    dialCode: '+675',
    flag: '🇵🇬',
    code: 'PG',
  },
  { en: 'Paraguay', uk: 'Парагвай', ru: 'Парагвай', dialCode: '+595', flag: '🇵🇾', code: 'PY' },
  { en: 'Peru', uk: 'Перу', ru: 'Перу', dialCode: '+51', flag: '🇵🇪', code: 'PE' },
  { en: 'Philippines', uk: 'Філіппіни', ru: 'Филиппины', dialCode: '+63', flag: '🇵🇭', code: 'PH' },
  { en: 'Pitcairn', uk: 'Піткерн', ru: 'Питкерн', dialCode: '+872', flag: '🇵🇳', code: 'PN' },
  { en: 'Poland', uk: 'Польща', ru: 'Польша', dialCode: '+48', flag: '🇵🇱', code: 'PL' },
  { en: 'Portugal', uk: 'Португалія', ru: 'Португалия', dialCode: '+351', flag: '🇵🇹', code: 'PT' },
  {
    en: 'Puerto Rico',
    uk: 'Пуерто Ріко',
    ru: 'Пуэрто Рико',
    dialCode: '+1 939',
    flag: '🇵🇷',
    code: 'PR',
  },
  {
    en: 'Puerto Rico',
    uk: 'Пуерто Ріко',
    ru: 'Пуэрто Рико',
    dialCode: '+1 787',
    flag: '🇵🇷',
    code: 'PR',
  },
  { en: 'Qatar', uk: 'Катар', ru: 'Катар', dialCode: '+974', flag: '🇶🇦', code: 'QA' },
  { en: 'Réunion', uk: 'Реюньйон', ru: 'Реюньон', dialCode: '+262', flag: '🇷🇪', code: 'RE' },
  { en: 'Romania', uk: 'Румунія', ru: 'Румыния', dialCode: '+40', flag: '🇷🇴', code: 'RO' },
  { en: 'Russia', uk: 'Росія', ru: 'Россия', dialCode: '+7', flag: '🇷🇺', code: 'RU' },
  { en: 'Rwanda', uk: 'Руанда', ru: 'Руанда', dialCode: '+250', flag: '🇷🇼', code: 'RW' },
  {
    en: 'Saint Barthélemy',
    uk: 'Сен-Бартелемі',
    ru: 'Сен-Бартелеми',
    dialCode: '+590',
    flag: '🇧🇱',
    code: 'BL',
  },
  {
    en: 'Saint Helena, Ascension and Tristan Da Cunha',
    uk: 'Свята Єлена, Вознесіння і Трістан да Кунья',
    ru: 'Святая Елена, Вознесение и Тристан да Кунья',
    dialCode: '+290',
    flag: '🇸🇭',
    code: 'SH',
  },
  {
    en: 'Saint Kitts and Nevis',
    uk: 'Сент-Кітс і Невіс',
    ru: 'Сент-Китс и Невис',
    dialCode: '+1 869',
    flag: '🇰🇳',
    code: 'KN',
  },
  {
    en: 'Saint Lucia',
    uk: 'Сент-Люсія',
    ru: 'Сент-Люсия',
    dialCode: '+1 758',
    flag: '🇱🇨',
    code: 'LC',
  },
  {
    en: 'Saint Martin',
    uk: 'Сен-Мартен',
    ru: 'Сен-Мартен',
    dialCode: '+590',
    flag: '🇲🇫',
    code: 'MF',
  },
  {
    en: 'Saint Pierre and Miquelon',
    uk: "Сен-П'єр і Мікелон",
    ru: 'Сен-Пьер и Микелон',
    dialCode: '+508',
    flag: '🇵🇲',
    code: 'PM',
  },
  {
    en: 'Saint Vincent and the Grenadines',
    uk: 'Сент-Вінсент і Гренадини',
    ru: 'Сент-Винсент и Гренадины',
    dialCode: '+1 784',
    flag: '🇻🇨',
    code: 'VC',
  },
  { en: 'Samoa', uk: 'Самоа', ru: 'Самоа', dialCode: '+685', flag: '🇼🇸', code: 'WS' },
  {
    en: 'San Marino',
    uk: 'Сан-Марино',
    ru: 'Сан-Марино',
    dialCode: '+378',
    flag: '🇸🇲',
    code: 'SM',
  },
  {
    en: 'Sao Tome and Principe',
    uk: 'Сан-Томе і Прінсіпі',
    ru: 'Сан-Томе и Принсипи',
    dialCode: '+239',
    flag: '🇸🇹',
    code: 'ST',
  },
  {
    en: 'Saudi Arabia',
    uk: 'Саудівська Аравія',
    ru: 'Саудовская Аравия',
    dialCode: '+966',
    flag: '🇸🇦',
    code: 'SA',
  },
  { en: 'Senegal', uk: 'Сенегал', ru: 'Сенегал', dialCode: '+221', flag: '🇸🇳', code: 'SN' },
  { en: 'Serbia', uk: 'Сербія', ru: 'Сербия', dialCode: '+381', flag: '🇷🇸', code: 'RS' },
  {
    en: 'Seychelles',
    uk: 'Сейшельські острови',
    ru: 'Сейшельские острова',
    dialCode: '+248',
    flag: '🇸🇨',
    code: 'SC',
  },
  { en: 'Sierra Leone', uk: 'Сьєрра', ru: 'Сьерра', dialCode: '+232', flag: '🇸🇱', code: 'SL' },
  { en: 'Singapore', uk: 'Сінгапур', ru: 'Сингапур', dialCode: '+65', flag: '🇸🇬', code: 'SG' },
  {
    en: 'Sint Maarten',
    uk: 'Сінт-Мартен',
    ru: 'Синт-Мартен',
    dialCode: '+721',
    flag: '🇸🇽',
    code: 'SX',
  },
  { en: 'Slovakia', uk: 'Словаччина', ru: 'Словакия', dialCode: '+421', flag: '🇸🇰', code: 'SK' },
  { en: 'Slovenia', uk: 'Словенія', ru: 'Словения', dialCode: '+386', flag: '🇸🇮', code: 'SI' },
  {
    en: 'Solomon Islands',
    uk: 'Соломонові острови',
    ru: 'Соломоновые острова',
    dialCode: '+677',
    flag: '🇸🇧',
    code: 'SB',
  },
  { en: 'Somalia', uk: 'Сомалі', ru: 'Сомали', dialCode: '+252', flag: '🇸🇴', code: 'SO' },
  {
    en: 'South Africa',
    uk: 'Південна Африка',
    ru: 'Южная Африка',
    dialCode: '+27',
    flag: '🇿🇦',
    code: 'ZA',
  },
  {
    en: 'South Georgia and the South Sandwich Islands',
    uk: 'Південна Джорджія та Південні Сандвічеві острови',
    ru: 'Южная Джорджия и Южные Сандвичевые острова',
    dialCode: '+500',
    flag: '🇬🇸',
    code: 'GS',
  },
  {
    en: 'South Sudan',
    uk: 'Південний Судан',
    ru: 'Южный Судан',
    dialCode: '+211',
    flag: '🇸🇸',
    code: 'SS',
  },
  { en: 'Spain', uk: 'Іспанія', ru: 'Испания', dialCode: '+34', flag: '🇪🇸', code: 'ES' },
  { en: 'Sri Lanka', uk: 'Шрі Ланка', ru: 'Шри Ланка', dialCode: '+94', flag: '🇱🇰', code: 'LK' },
  { en: 'Sudan', uk: 'Судан', ru: 'Судан', dialCode: '+249', flag: '🇸🇩', code: 'SD' },
  { en: 'Suriname', uk: 'Сурінам', ru: 'Суринам', dialCode: '+597', flag: '🇸🇷', code: 'SR' },
  {
    en: 'Svalbard and Jan Mayen',
    uk: 'Свальбард і Ян-Маєн',
    ru: 'Свальбард и Ян-Майен',
    dialCode: '+47',
    flag: '🇸🇯',
    code: 'SJ',
  },
  { en: 'Swaziland', uk: 'Свазіленд', ru: 'Свазіленд', dialCode: '+268', flag: '🇸🇿', code: 'SZ' },
  { en: 'Sweden', uk: 'Швеція', ru: 'Швеція', dialCode: '+46', flag: '🇸🇪', code: 'SE' },
  { en: 'Switzerland', uk: 'Швейцарія', ru: 'Швейцарія', dialCode: '+41', flag: '🇨🇭', code: 'CH' },
  {
    en: 'Syrian Arab Republic',
    uk: 'Сирійська Арабська Республіка',
    ru: 'Сирийская Арабская Республика',
    dialCode: '+963',
    flag: '🇸🇾',
    code: 'SY',
  },
  {
    en: 'Taiwan, Province of China',
    uk: 'Тайвань, провінція Китаю',
    ru: 'Тайвань, провинция Китая',
    dialCode: '+886',
    flag: '🇹🇼',
    code: 'TW',
  },
  {
    en: 'Tajikistan',
    uk: 'Таджикистан',
    ru: 'Таджикистан',
    dialCode: '+992',
    flag: '🇹🇯',
    code: 'TJ',
  },
  {
    en: 'Tanzania, United Republic of',
    uk: "Танзанія, Об'єднана Республіка",
    ru: 'Танзания, Объединенная Республика',
    dialCode: '+255',
    flag: '🇹🇿',
    code: 'TZ',
  },
  { en: 'Thailand', uk: 'Таїланд', ru: 'Таиланд', dialCode: '+66', flag: '🇹🇭', code: 'TH' },
  {
    en: 'Timor-Leste',
    uk: 'Тимор-Лешті',
    ru: 'Тимор-Лешти',
    dialCode: '+670',
    flag: '🇹🇱',
    code: 'TL',
  },
  { en: 'Togo', uk: 'Того', ru: 'Того', dialCode: '+228', flag: '🇹🇬', code: 'TG' },
  { en: 'Tokelau', uk: 'Токелау', ru: 'Токелау', dialCode: '+690', flag: '🇹🇰', code: 'TK' },
  { en: 'Tonga', uk: 'Тонга', ru: 'Тонга', dialCode: '+676', flag: '🇹🇴', code: 'TO' },
  {
    en: 'Trinidad and Tobago',
    uk: 'Тринідад і Тобаго',
    ru: 'Тринидад и Тобаго',
    dialCode: '+1 868',
    flag: '🇹🇹',
    code: 'TT',
  },
  { en: 'Tunisia', uk: 'Туніс', ru: 'Тунис', dialCode: '+216', flag: '🇹🇳', code: 'TN' },
  { en: 'Turkey', uk: 'Туреччина', ru: 'Турция', dialCode: '+90', flag: '🇹🇷', code: 'TR' },
  {
    en: 'Turkmenistan',
    uk: 'Туркменістан',
    ru: 'Туркменистан',
    dialCode: '+993',
    flag: '🇹🇲',
    code: 'TM',
  },
  {
    en: 'Turks and Caicos Islands',
    uk: 'Острови Теркс і Кайкос',
    ru: 'Острова Теркс и Кайкос',
    dialCode: '+1649',
    flag: '🇹🇨',
    code: 'TC',
  },
  { en: 'Tuvalu', uk: 'Тувалу', ru: 'Тувалу', dialCode: '+688', flag: '🇹🇻', code: 'TV' },
  { en: 'Uganda', uk: 'Уганда', ru: 'Уганда', dialCode: '+256', flag: '🇺🇬', code: 'UG' },
  { en: 'Ukraine', uk: 'Україна', ru: 'Украина', dialCode: '+380', flag: '🇺🇦', code: 'UA' },
  {
    en: 'United Arab Emirates',
    uk: "Об'єднані Арабські Емірати",
    ru: 'Объединенные Арабские Эмираты',
    dialCode: '+971',
    flag: '🇦🇪',
    code: 'AE',
  },
  {
    en: 'United Kingdom',
    uk: 'Велика Британія',
    ru: 'Великобритания',
    dialCode: '+44',
    flag: '🇬🇧',
    code: 'GB',
  },
  {
    en: 'United States',
    uk: 'Сполучені Штати Америки',
    ru: 'Соединенные Штаты Америки',
    dialCode: '+1',
    flag: '🇺🇸',
    code: 'US',
  },
  { en: 'Uruguay', uk: 'Уругвай', ru: 'Уругвай', dialCode: '+598', flag: '🇺🇾', code: 'UY' },
  {
    en: 'Uzbekistan',
    uk: 'Узбекистан',
    ru: 'Узбекистан',
    dialCode: '+998',
    flag: '🇺🇿',
    code: 'UZ',
  },
  { en: 'Vanuatu', uk: 'Вануату', ru: 'Вануату', dialCode: '+678', flag: '🇻🇺', code: 'VU' },
  {
    en: 'Venezuela, Bolivarian Republic of',
    uk: 'Венесуела, Боліваріанська Республіка',
    ru: 'Венесуэла, Боливарианская Республика',
    dialCode: '+58',
    flag: '🇻🇪',
    code: 'VE',
  },
  { en: 'Viet Nam', uk: "В'єтнам", ru: 'Вьетнам', dialCode: '+84', flag: '🇻🇳', code: 'VN' },
  {
    en: 'Virgin Islands, British',
    uk: 'Британські Віргінські острови',
    ru: 'Британские Виргинские острова',
    dialCode: '+1 284',
    flag: '🇻🇬',
    code: 'VG',
  },
  {
    en: 'Virgin Islands, U.S.',
    uk: 'Віргінські острови, США',
    ru: 'Виргинские острова, США',
    dialCode: '+1 340',
    flag: '🇻🇮',
    code: 'VI',
  },
  {
    en: 'Wallis and Futuna',
    uk: 'Уолліс і Футуна',
    ru: 'Уоллис и Футуна',
    dialCode: '+681',
    flag: '🇼🇫',
    code: 'WF',
  },
  {
    en: 'Western Sarah',
    uk: 'Західна Сара',
    ru: 'Западная Сара',
    dialCode: '+212',
    flag: '🇪🇭',
    code: 'EH',
  },
  { en: 'Yemen', uk: 'Ємен', ru: 'Йемен', dialCode: '+967', flag: '🇾🇪', code: 'YE' },
  { en: 'Zambia', uk: 'Замбія', ru: 'Замбия', dialCode: '+260', flag: '🇿🇲', code: 'ZM' },
  { en: 'Zimbabwe', uk: 'Зімбабве', ru: 'Зимбабве', dialCode: '+263', flag: '🇿🇼', code: 'ZW' },
]

export const searchCountries = (searchValue: string, language: string): CountryData[] => {
  if (!searchValue) return CountryList

  return CountryList.filter(item => {
    const normalizedValue = searchValue.toLocaleLowerCase()

    return item[language]?.toLocaleLowerCase()?.startsWith(normalizedValue) ?? false
  })
}
