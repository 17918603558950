import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ServerServiceBriefWithProductType, useFetchServiceGroups } from '@expane/data'
import { ServiceDialog } from 'widgets/ServiceDialog'
import { store } from 'store'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'

interface ServiceDuplicateConfirmationProps {
  duplicateServices: ServerServiceBriefWithProductType[]
  name: string
}

export const ServiceDuplicateConfirmation: FC<ServiceDuplicateConfirmationProps> = ({
  duplicateServices,
  name,
}) => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()
  const { openEditDialog, dialog } = useOpenDialog(ServiceDialog)
  const { data: serviceGroups } = useFetchServiceGroups(branchId)

  return (
    <>
      <div>
        {t('serviceDuplicateConfirmation.isAlreadyInDatabase', { name })}
        {duplicateServices.map(duplicateService => {
          const serviceGroup = duplicateService.groupId
            ? serviceGroups?.find(group => group.id === duplicateService.groupId)
            : undefined
          const serviceGroupName = serviceGroup
            ? `${t('serviceDuplicateConfirmation.inGroup')} ${serviceGroup.name}`
            : t('serviceDuplicateConfirmation.withoutGroup')

          return (
            <div
              key={duplicateService.id}
              className=" text-primary-400 cursor-pointer hover:text-primary-500"
              onClick={() => openEditDialog(duplicateService.id)}
            >
              {duplicateService.name}
              <span>{serviceGroupName}</span>
            </div>
          )
        })}
      </div>
      <div className="mt-3">{t('serviceDuplicateConfirmation.saveNew')}</div>
      {dialog}
    </>
  )
}
