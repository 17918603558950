import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import {
  convertTransactionsToCardReportItem,
  getNotRefundedReportItems,
  getSubscriptionReportItems,
} from '@expane/logic/reports/cards'
import {
  calcReportItemsCreditTotalPrice,
  calcReportItemsCreditTotalQuantity,
  calcReportItemsTotalPrice,
  groupReportItemsById,
} from '@expane/logic/reports/logic'
import {
  EmptyPlaceholder,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderCell,
} from '@expane/ui'
import { ReportProps } from 'pages/ReportsPage'
import {
  CardReportItem,
  cardReportItemsTitles,
} from 'pages/ReportsPage/CardsReports/CardReportItem'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const SubscriptionsReport: FC<PropsWithBranchId<ReportProps>> = ({
  transactions,
  branchId,
}) => {
  const { t } = useTranslation()

  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const reportItems = convertTransactionsToCardReportItem(transactions)
  const subscriptionReportItems = getSubscriptionReportItems(getNotRefundedReportItems(reportItems))

  const groupedSubscriptions = groupReportItemsById(subscriptionReportItems)

  const creditTotalQuantity = calcReportItemsCreditTotalQuantity(subscriptionReportItems)
  const creditTotalPrice = calcReportItemsCreditTotalPrice(subscriptionReportItems)
  const totalPrice = calcReportItemsTotalPrice(subscriptionReportItems)

  const subscriptionsQuantity = subscriptionReportItems.length
  const subscriptionsToDisplay = Object.keys(groupedSubscriptions)

  if (subscriptionsToDisplay.length === 0)
    return <EmptyPlaceholder text={t('emptyPlaceholder.archive')} />

  return (
    <TableContainer>
      <TableHeader>
        <tr>
          {cardReportItemsTitles.map(({ title, isAlignRight }) => {
            const className = isAlignRight ? 'text-right' : undefined
            return (
              <TableHeaderCell key={title} className={className}>
                {t(title)}
              </TableHeaderCell>
            )
          })}
        </tr>
      </TableHeader>
      <TableBody>
        {subscriptionsToDisplay.map(groupId => (
          <CardReportItem
            key={groupId}
            cardReportItems={groupedSubscriptions[groupId]}
            name={subscriptionReportItems.find(item => item.id === Number(groupId))?.name ?? ''}
            branchId={branchId}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableCell>{t('total')}</TableCell>
        <TableCell className="text-right">{subscriptionsQuantity}</TableCell>
        <TableCell className="text-right">{creditTotalQuantity}</TableCell>
        <TableCell className="text-right">{convertNumberToMoney(totalPrice)}</TableCell>
        <TableCell className="text-right">{convertNumberToMoney(creditTotalPrice)}</TableCell>
      </TableFooter>
    </TableContainer>
  )
}
