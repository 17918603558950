import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { QuickFindMenuItem } from 'widgets/QuickFindInput/logic'

export const QuickFindInputItem: FC<QuickFindMenuItem> = item => {
  const { t } = useTranslation()

  const type = item.type

  return (
    <div className="flex items-center">
      <div className="text-gray-300 p-2">
        {type === 'page' && t('page') + ':'}
        {type === 'employee' && t('employee.name') + ':'}
        {type === 'client' && t('client.name') + ':'}
        {type === 'supplier' && t('supplier.name') + ':'}
        {type === 'service' && t('service.name') + ':'}
        {type === 'subscription' && t('subscription.name') + ':'}
        {type === 'giftCard' && t('giftCard.name') + ':'}
        {type === 'product' && t('product.name') + ':'}
      </div>

      <div className="flex flex-col">{setQuickFindInputItemContent(item)}</div>
    </div>
  )
}

interface MarkedItemProps {
  indexes: { start: number; end: number }
  label: string
  className?: string
}

const MarkedItem: FC<MarkedItemProps> = ({ indexes, label, className }) => (
  <span className={className}>
    {label.slice(0, indexes.start)}
    <span className={selectionStyle}>{label.slice(indexes.start, indexes.end)}</span>
    {label.slice(indexes.end, label.length)}
  </span>
)

const additionalInfoStyle = 'text-gray-400 text-xs'
const selectionStyle = 'bg-primary-100 dark:bg-primary-500/30 text-primary-600 rounded-sm'

const setQuickFindInputItemContent = ({
  foundIn,
  indexes,
  additionalInfo,
  label,
}: QuickFindMenuItem) => {
  if (foundIn && indexes) {
    if (foundIn === 'label') {
      return (
        <>
          <MarkedItem label={label} indexes={indexes} />
          {additionalInfo && <span className={additionalInfoStyle}>{additionalInfo}</span>}
        </>
      )
    }
    if (foundIn === 'additionalInfo') {
      return (
        <>
          <span>{label}</span>
          {additionalInfo && (
            <MarkedItem label={additionalInfo} indexes={indexes} className={additionalInfoStyle} />
          )}
        </>
      )
    }
  } else {
    return (
      <>
        <span>{label}</span>
        {label && <span className={additionalInfoStyle}>{additionalInfo}</span>}
      </>
    )
  }
}
