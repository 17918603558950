import {
  MOVEMENT_TYPES,
  ServerMovementType,
  useFetchCurrentBranchTimezone,
  useFetchMovementsByDate,
  useFetchProductGroups,
  useFetchProducts,
  useFetchStorages,
  useFetchSuppliersBrief,
  useGetBranchDefaultStorageId,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { Page, ResetFilterButton, SelectDropdown, SelectDropDownItem } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useCustomDatePicker } from 'logic/hooks/useCustomDatePicker'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { translateData } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { ButtonsBar } from 'pages/MovementsPage/ButtonsBar'
import { MovementsList } from 'pages/MovementsPage/MovementsList'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { TreeMenuItem } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { customProductSearchFilterFunction, ProductTreeMenuItem } from 'ui/TreeMenu/logic.product'
import { MovementInfoDialog } from 'widgets/MovementInfoDialog'
import { StorageSelectDropdown } from 'widgets/StoragesSelectDropdown'
import { filterMovements, getFullInfoForProducts, statusItems } from './logic'
import { MovementPresetSelect } from './MovementPresetSelect'
import { ProductsTreeSelect } from './ProductsTreeSelect'

export const MovementsPage = observer(() => {
  const { t } = useTranslation()

  const [selectedTypeId, setSelectedTypeId] = useState<number | null>(null)
  const [selectedSupplierId, setSelectedSupplierId] = useState<number | null>(null)
  const [selectedFromStorageId, setSelectedFromStorageId] = useState<number | null>(null)
  const [selectedToStorageId, setSelectedToStorageId] = useState<number | null>(null)
  const [selectedProducts, setSelectedProducts] = useState<TreeMenuItem[]>([])
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null)

  const [presetId, setPresetId] = useState<number | null>(null)
  const resetPreset = () => {
    if (presetId !== undefined) setPresetId(null)
  }

  const { control, reset, handleSubmit, setValue, getValues } = useForm<SelectMovementsFormType>({
    defaultValues: {
      selected: {},
    },
  })

  const { customDatePicker, period } = useCustomDatePicker({ containerClassName: 'mb-2 mr-2' })

  const handleFilterReset = () => {
    setSelectedTypeId(null)
    setSelectedSupplierId(null)
    setSelectedFromStorageId(null)
    setSelectedToStorageId(null)
    setSelectedProducts([])
    setPresetId(null)
    setSelectedStatus(null)
  }

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: myPermissions } = useFetchMyPermissions()
  const { data: movements, isLoading } = useFetchMovementsByDate(
    period.start,
    period.end,
    timezone,
    branchId,
  )
  const { data: suppliers } = useFetchSuppliersBrief(branchId)
  const { data: storages } = useFetchStorages(branchId)
  const { data: defaultStorageId } = useGetBranchDefaultStorageId(branchId)

  const { data: productGroups } = useFetchProductGroups(branchId)
  const { data: products } = useFetchProducts(branchId)

  const productTreeItems = useMemo(
    () => transformDataForTreeMenu<ProductTreeMenuItem>(productGroups, products),
    [productGroups, products],
  )

  const { dialog: movementMovementInfoDialog, openEditDialog: openMovementInfoDialog } =
    useOpenDialog(MovementInfoDialog)
  const onRowClick = (movement: ServerMovementType) => openMovementInfoDialog(movement.id)

  const filteredMovements = useMemo<ServerMovementType[]>(
    () =>
      filterMovements({
        movements,
        typeId: selectedTypeId,
        supplierId: selectedSupplierId,
        fromStorageId: selectedFromStorageId,
        toStorageId: selectedToStorageId,
        products: selectedProducts,
        statusId: selectedStatus,
      }),
    [
      movements,
      selectedFromStorageId,
      selectedSupplierId,
      selectedToStorageId,
      selectedTypeId,
      selectedProducts,
      selectedStatus,
    ],
  )

  useEffect(() => {
    reset({
      selected: {},
    })
  }, [filteredMovements, reset])

  const isSuppliersGetAllowed = myPermissions?.includes(permissions.supplier.get)
  const isStoragesGetAllowed = myPermissions?.includes(permissions.storage.get)
  const isPaidAllowed = Boolean(myPermissions?.includes(permissions.transaction.set))

  return (
    <Page>
      <ButtonsBar
        customDatePicker={customDatePicker}
        handleSubmit={handleSubmit}
        control={control}
        showProductsMovementButton={(storages?.length ?? 0) > 1}
        selectedStorageId={selectedFromStorageId || selectedToStorageId}
        allMovements={filteredMovements}
        isPaidAllowed={isPaidAllowed}
      />

      <div className="flex mb-2 w-full">
        <div className="grow pr-2 grid grid-cols-6 gap-2">
          <SelectDropdown
            onSelectChange={typeId => {
              resetPreset()
              setSelectedTypeId(typeId)
            }}
            value={selectedTypeId}
            items={translateData(transformMovementTypesForSelectInMovementTable(MOVEMENT_TYPES), t)}
            isClearable
            placeholder={t('type')}
            isFilter
          />
          {isSuppliersGetAllowed && (
            <SelectDropdown
              onSelectChange={supplierId => {
                resetPreset()
                setSelectedSupplierId(supplierId)
              }}
              value={selectedSupplierId}
              items={suppliers ?? []}
              isClearable
              placeholder={t('supplier.name')}
              isFilter
            />
          )}

          {isStoragesGetAllowed && (
            <>
              <StorageSelectDropdown
                onSelectChange={fromStorageId => {
                  resetPreset()
                  setSelectedFromStorageId(fromStorageId)
                }}
                value={selectedFromStorageId}
                storages={storages}
                defaultStorageId={defaultStorageId}
                isClearable
                placeholder={t('fromStorage')}
                isFilter
              />
              <StorageSelectDropdown
                onSelectChange={toStorageId => {
                  resetPreset()
                  setSelectedToStorageId(toStorageId)
                }}
                value={selectedToStorageId}
                storages={storages}
                defaultStorageId={defaultStorageId}
                isClearable
                placeholder={t('toStorage')}
                isFilter
              />
            </>
          )}

          <SelectDropdown
            onSelectChange={statusId => {
              resetPreset()
              setSelectedStatus(statusId)
            }}
            value={selectedStatus}
            items={Object.values(statusItems).map(item => ({ ...item, name: t(item.name) }))}
            isClearable
            placeholder={t('status')}
            isFilter
          />
          <ProductsTreeSelect
            items={productTreeItems}
            value={selectedProducts}
            onChange={selectedProducts => {
              resetPreset()
              setSelectedProducts(selectedProducts)
            }}
            customSearchFilterFunction={customProductSearchFilterFunction}
          />
        </div>
        <div className="flex shrink-0">
          <MovementPresetSelect
            className="self-center mr-2"
            value={presetId}
            onChange={setPresetId}
            selectedTypeId={selectedTypeId}
            setSelectedTypeId={setSelectedTypeId}
            selectedSupplierId={selectedSupplierId}
            setSelectedSupplierId={setSelectedSupplierId}
            selectedFromStorageId={selectedFromStorageId}
            setSelectedFromStorageId={setSelectedFromStorageId}
            selectedToStorageId={selectedToStorageId}
            setSelectedToStorageId={setSelectedToStorageId}
            selectedProducts={selectedProducts.map(product => ({
              id: product.id,
              isFolder: product.isFolder ?? false,
            }))}
            setSelectedProducts={presetProducts =>
              setSelectedProducts(getFullInfoForProducts(presetProducts, productTreeItems))
            }
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
          />
          <ResetFilterButton
            className="w-28"
            disabled={
              !selectedTypeId &&
              !selectedSupplierId &&
              !selectedFromStorageId &&
              !selectedToStorageId &&
              selectedProducts.length === 0 &&
              !selectedStatus
            }
            onClick={handleFilterReset}
          />
        </div>
      </div>

      <MovementsList
        movements={filteredMovements}
        onRowClick={onRowClick}
        control={control}
        setValue={setValue}
        isLoading={isLoading}
        disabled={!isPaidAllowed}
        getValues={getValues}
        branchId={branchId}
      />

      {movementMovementInfoDialog}
    </Page>
  )
})

const transformMovementTypesForSelectInMovementTable = (
  types: typeof MOVEMENT_TYPES,
): SelectDropDownItem[] => {
  const items = Object.keys(types).map(key => ({ id: types[key].id, name: types[key].name }))

  // Логика возврата на данный момент работает неправильно
  return items.filter(item => item.id !== MOVEMENT_TYPES.return.id)
}

export type SelectMovementsFormType = {
  selected: Record<string, boolean>
}
