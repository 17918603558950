import {
  useFetchClientsBriefs,
  useFetchCurrentBranchTimezone,
  useFetchTransactionsBriefWithClient,
} from '@expane/data'
import { useGetTransactionsByPeriod } from '@expane/logic/analytics'
import { getClientsQuantity, getNewClients, useGetActiveClients } from '@expane/logic/client'
import { getTransactionsWithClientsDepositsAndPayments } from '@expane/logic/finances/filters'
import { Paper } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { AnalyticsBlockBody, AnalyticsBlockItem, AnalyticsBlockTitle } from 'pages/AnalyticsPage'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'

export const ClientsBlock: FC<{ className?: string }> = observer(({ className }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clients, isLoading: isLoadingClients } = useFetchClientsBriefs(branchId, timezone)
  const { data: transactions, isLoading: isLoadingTransactions } =
    useFetchTransactionsBriefWithClient(timezone, branchId)

  const { transactionsByPeriod, periodOfTimeName, periodOfTimeId, onSelectChange } =
    useGetTransactionsByPeriod(transactions ?? [], timezone)

  const trWithClientsAll = getTransactionsWithClientsDepositsAndPayments(transactions ?? [])

  const trWithClientsByPeriod = getTransactionsWithClientsDepositsAndPayments(transactionsByPeriod)

  const { newClientsQuantity } = getNewClients(trWithClientsByPeriod, trWithClientsAll)

  const clientsQuantityByPeriod = getClientsQuantity(trWithClientsByPeriod)

  // считаем сколько уникальных клиентов было за 30 дней (активные клиенты)
  const { activeClientsQuantity } = useGetActiveClients(timezone, branchId)

  return (
    <Paper className={`p-4 ${className ?? ''}`}>
      <AnalyticsBlockTitle
        title={t('clients')}
        onSelectChange={onSelectChange}
        namePeriodOfTime={periodOfTimeName}
        periodOfTimeId={periodOfTimeId}
        hintMessage={t('clientsBlock.clientsIn')}
        isLoading={isLoadingClients || isLoadingTransactions}
      />

      <AnalyticsBlockBody
        value={clientsQuantityByPeriod}
        text={`${t('clientsBlock.clientsIn')} ${periodOfTimeName}`}
        isLoading={isLoadingClients || isLoadingTransactions}
      >
        <AnalyticsBlockItem
          title={t('clientsBlock.new')}
          value={newClientsQuantity}
          isLoading={isLoadingClients || isLoadingTransactions}
        />

        <AnalyticsBlockItem
          title={t('clientsBlock.active')}
          value={activeClientsQuantity}
          isLoading={isLoadingClients || isLoadingTransactions}
        />

        <AnalyticsBlockItem
          title={t('clientsBlock.total')}
          value={clients ? clients.length : 0}
          isLoading={isLoadingClients || isLoadingTransactions}
        />
      </AnalyticsBlockBody>
    </Paper>
  )
})
