import { ServerTransactionType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { getExpensesTransactionsWithReason } from '@expane/logic/finances/filters'
import { groupExpensesReason } from '@expane/logic/reports/helpers'
import { calcValues } from '@expane/logic/utils'
import { TableBody, TableContainer, TableHeader, TableHeaderCell } from '@expane/ui'
import { ListItem } from 'pages/ReportsPage/RevenueExpensesReport/ListItem'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface ExpensesListProps {
  transactions: ServerTransactionType[]
  clientsPaymentsTotalValue: number
  totalCredit: number
  refundsTotalValue: number
  salariesTotalValue: number
  productsPurchaseTotalValue: number
  transportCostsTotalValue: number
  otherBusinessExpensesTotalValue: number
  bankExpensesTotalValue: number
  writeOffExpensesTotalValue: number
  cashOutTotalValue: number
  isLoading: boolean
}

export const ExpensesList: FC<PropsWithBranchId<ExpensesListProps>> = ({
  transactions,
  clientsPaymentsTotalValue,
  totalCredit,
  refundsTotalValue,
  salariesTotalValue,
  productsPurchaseTotalValue,
  transportCostsTotalValue,
  otherBusinessExpensesTotalValue,
  bankExpensesTotalValue,
  writeOffExpensesTotalValue,
  cashOutTotalValue,
  isLoading,
  branchId,
}) => {
  const { t } = useTranslation()

  // находим транзакции у которых есть "причина"
  const expensesTransactionsWithReason = getExpensesTransactionsWithReason(transactions)

  const groupedOtherBusinessExpenses = expensesTransactionsWithReason
    ? groupExpensesReason(expensesTransactionsWithReason)
    : []

  return (
    <TableContainer className="h-fit">
      <TableHeader>
        <tr>
          <TableHeaderCell className="w-64">{t('expenses')}</TableHeaderCell>
          <TableHeaderCell className="text-right w-32">{t('amount')}</TableHeaderCell>
        </tr>
      </TableHeader>

      <TableBody>
        {isLoading ? (
          <>
            <ListItem branchId={branchId} />
            <ListItem branchId={branchId} />
            <ListItem branchId={branchId} />
          </>
        ) : (
          <>
            <ListItem
              title={t('reports.spentFromClientsAccounts')}
              value={clientsPaymentsTotalValue}
              isTitle
              branchId={branchId}
            />

            {totalCredit ? (
              <ListItem
                title={t('reports.inLoanAmount')}
                value={totalCredit}
                className="pl-8"
                branchId={branchId}
              />
            ) : null}

            {refundsTotalValue ? (
              <ListItem
                title={t('returns')}
                value={refundsTotalValue}
                isTitle
                branchId={branchId}
              />
            ) : null}
            {cashOutTotalValue ? (
              <ListItem
                title={t('reports.withdrawFromClientsAccounts')}
                value={cashOutTotalValue}
                isTitle
                branchId={branchId}
              />
            ) : null}

            {salariesTotalValue ? (
              <ListItem
                title={t('salary.name')}
                value={salariesTotalValue}
                isTitle
                branchId={branchId}
              />
            ) : null}
            {productsPurchaseTotalValue ? (
              <ListItem
                title={t('reports.suppliersPayments')}
                value={productsPurchaseTotalValue}
                isTitle
                branchId={branchId}
              />
            ) : null}
            {transportCostsTotalValue ? (
              <ListItem
                title={t('logistics')}
                value={transportCostsTotalValue}
                isTitle
                branchId={branchId}
              />
            ) : null}
            {writeOffExpensesTotalValue ? (
              <ListItem
                title={t('writeOff.nameWithProducts')}
                value={writeOffExpensesTotalValue}
                isTitle
                branchId={branchId}
              />
            ) : null}
            {otherBusinessExpensesTotalValue ? (
              <ListItem
                title={t('reports.outgoingPayments')}
                value={otherBusinessExpensesTotalValue}
                isTitle
                branchId={branchId}
              />
            ) : null}

            {Object.keys(groupedOtherBusinessExpenses).map(ex => (
              <ListItem
                key={ex}
                title={ex}
                className="pl-8"
                value={calcValues(groupedOtherBusinessExpenses[ex])}
                branchId={branchId}
              />
            ))}
            {bankExpensesTotalValue ? (
              <ListItem
                title={t('transactionNames.commissionOfBank')}
                value={bankExpensesTotalValue}
                className="pl-8"
                branchId={branchId}
              />
            ) : null}
          </>
        )}
      </TableBody>
    </TableContainer>
  )
}
