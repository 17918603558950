import { ChangeEvent, forwardRef, TextareaHTMLAttributes } from 'react'
import { InputLabel } from '../InputLabel'
import { ErrorMessage } from '../ErrorMessage'

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  className?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  errorMessage?: string
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const { required, label, onChange, className, errorMessage, rows = 4, ...restProps } = props

  let style = 'p-4 w-full rounded border bg-input text-black dark:text-white focus:ring-0'
  if (errorMessage) style += ' border-error-500 focus:border-error-500'
  else style += ' border-gray-300 focus:border-primary-500'

  return (
    <div className={className}>
      {label ? <InputLabel label={label} required={required} /> : null}

      <textarea ref={ref} className={style} onChange={onChange} rows={rows} {...restProps} />

      {errorMessage ? <ErrorMessage errorMessage={errorMessage} /> : null}
    </div>
  )
})
