import { permissions } from '@expane/logic/permission'
import { Page } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { AccountsList } from 'pages/AccountsPage/List'
import { FC } from 'react'
import { AccountDialog } from 'widgets/AccountsDialog'
import { SaveButton } from 'widgets/Buttons'

export const AccountsPage: FC = () => {
  const { data: myPermission } = useFetchMyPermissions()
  const { openEditDialog, openCreateDialog, dialog } = useOpenDialog(AccountDialog)

  const isEditingAllowed = myPermission?.includes(permissions.account.set)

  return (
    <Page>
      {isEditingAllowed && (
        <div className="flex mb-3">
          <SaveButton onClick={() => openCreateDialog()} className="self-start" isCreate />
        </div>
      )}

      <AccountsList onRowClick={account => openEditDialog(account.id)} />
      {dialog}
    </Page>
  )
}
