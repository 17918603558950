import { differenceInDays, formatTimeFromDate, isToday, isTomorrow } from '@expane/date'
import { ClientPhones, ServerWaitingListType } from '@expane/data'
import { addPhonesToClient, ClientWithPhones } from '../client'
import { UseTranslationResponse, useTranslation } from 'react-i18next'

// Function to format the date
const formatDate = (date: Date, language: string) => {
  return date.toLocaleDateString(language, {
    day: 'numeric',
    month: 'long',
  })
}

const getCustomWaitingListFormatData = (
  dateStart: Date,
  dateEnd: Date,
  timezone: string | undefined,
  translation: UseTranslationResponse<'translation'>,
) => {
  if (differenceInDays(dateStart, dateEnd) === 0) {
    if (isToday(dateStart, timezone)) return translation.t('today')
    if (isTomorrow(dateStart)) return translation.t('tomorrow')

    return formatDate(dateStart, translation.i18n.language)
  }

  return `${formatDate(dateStart, translation.i18n.language)} - ${formatDate(
    dateEnd,
    translation.i18n.language,
  )}`
}

export const useWaitingListConvenientDate = () => {
  const translation = useTranslation()

  const getWaitingListConvenientDate = (
    waitingList: ServerWaitingListType | undefined,
    timezone: string | undefined,
  ) => {
    if (!waitingList) return ''
    else {
      const { start, end } = waitingList

      const date = getCustomWaitingListFormatData(start, end, timezone, translation)

      const startTime = formatTimeFromDate(start)
      const endTime = formatTimeFromDate(end)

      return `${date} ${startTime}-${endTime}`
    }
  }

  return getWaitingListConvenientDate
}

export type WaitingListWithClientPhones = ServerWaitingListType & {
  client: ClientWithPhones<ServerWaitingListType['client']>
}

export const addPhonesToClientsInWaitingList = (
  waitingLists: ServerWaitingListType[] | undefined,
  clientPhones: ClientPhones[] | undefined,
): WaitingListWithClientPhones[] | undefined => {
  return waitingLists?.map(waitingList => {
    const clientPhone = clientPhones?.find(clientPhone => clientPhone.id === waitingList.client.id)
    return {
      ...waitingList,
      client: addPhonesToClient(waitingList.client, clientPhone),
    }
  })
}
