import { ServerPlanType } from '@expane/data'
import { useFetchPlans } from '@expane/data/src/gql/plan'
import { CloseButton, Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BillingPlanCard } from 'widgets/BillingPlanCard'
import { MainInfoFormValues } from '@expane/logic/business'

export const useOpenPlansDialog = (control: Control<MainInfoFormValues>) => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const plansDialog = isOpen ? <PlansDialog control={control} closeDialog={closePopup} /> : null

  return { openPlansDialog: openPopup, plansDialog }
}

interface PlansDialogProps {
  control: Control<MainInfoFormValues>
  closeDialog: () => void
}
const PlansDialog: FC<PlansDialogProps> = ({ control, closeDialog }) => {
  const { data: plans } = useFetchPlans()

  if (!plans) return null

  return <PlansDialogLogic plans={plans} control={control} closeDialog={closeDialog} />
}

const PlansDialogLogic: FC<PlansDialogProps & { plans: ServerPlanType[] }> = ({
  plans,
  control,
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('billingInfo')}</Dialog.Title>

        <Dialog.Body className="w-192 flex">
          <Controller
            control={control}
            name="planId"
            render={({ field: { value, onChange } }) => (
              <>
                {plans.map(plan => (
                  <div key={plan.id} className="w-1/3 pr-2 last:pr-0">
                    <BillingPlanCard
                      plan={plan}
                      selected={value === plan.id}
                      disabled={value === plan.id}
                      onClick={() => {
                        onChange(plan.id)
                        closeDialog()
                      }}
                      type="choose"
                    />
                  </div>
                ))}
              </>
            )}
          />
        </Dialog.Body>
        <Dialog.Footer>
          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
