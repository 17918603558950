import { AuthFormWidget, useSnackbar } from '@expane/widgets'
import { sendAuthEmail } from 'logic/auth'
import { FC, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { queryClient } from 'services/api'
import { firebase } from 'services/firebase'
import { reportError } from 'services/sentry'
import { store } from 'store'
import { LANGUAGE_LOCAL_STORAGE_KEY } from 'i18n'

export const AuthPage: FC = () => {
  const location = useLocation()
  const [openSnackbar] = useSnackbar()
  const locationState = location.state as { from: string; errorMessage?: string } | undefined

  // Clear all the cache in case of test account will log in with no redirection from email
  useEffect(() => {
    queryClient.clear()
  }, [])

  useEffect(() => {
    if (locationState?.errorMessage) {
      openSnackbar(locationState?.errorMessage, 'error', 15000)
      window.history.pushState({ errorMessage: undefined }, '')
    }
  }, [locationState?.errorMessage, openSnackbar])

  if (store.me.isAuthorised) return <Navigate to={{ pathname: '/' }} />

  return (
    <div className="flex-centered flex-1">
      <AuthFormWidget
        pathname={location.pathname}
        redirectedFrom={locationState?.from}
        signInWithEmailAndPassword={firebase.signInWithEmailAndPassword}
        safetyLoginWithPopup={firebase.safetyLoginWithPopup}
        sendAuthEmail={sendAuthEmail}
        reportError={reportError}
        languageLocalStorageKey={LANGUAGE_LOCAL_STORAGE_KEY}
      />
    </div>
  )
}
