import { useLongPress } from 'logic/hooks/useLongPress'
import { FC, forwardRef, HTMLAttributes, PropsWithChildren, MouseEvent } from 'react'
import { ContextMenuItem } from 'logic/hooks/useContextMenu'

type CalendarContainerProps = {
  fullWidth?: boolean
  shrinked?: boolean
}
// CalendarContainer
export const CalendarContainer: FC<PropsWithChildren<CalendarContainerProps>> = ({
  children,
  fullWidth = false,
  shrinked = false,
}) => {
  let className = 'flex isolate select-none relative stripes h-fit'
  className += fullWidth ? ' w-full' : ' w-fit'
  if (!shrinked) className += ' grow'
  return <div className={className}>{children}</div>
}

type CalendarColumnProps = {
  isTime?: boolean
  className?: string
} & HTMLAttributes<HTMLDivElement>
// CalendarColumn
export const CalendarColumn = forwardRef<HTMLDivElement, CalendarColumnProps>(
  ({ children, isTime, className, ...restProps }, ref) => {
    let columnStyle = 'flex flex-col transition-all h-min '

    columnStyle += isTime
      ? 'shrink-0 border border-calendar-cell z-50 '
      : 'relative border-b border-calendar-cell '

    return (
      <div ref={ref} className={columnStyle + className} {...restProps}>
        {children}
      </div>
    )
  },
)

// CalendarCell
export type CalendarCellProps = {
  onClick?: () => void
  onLongPress?: () => void
  className?: string
  small?: boolean
  fullHour?: boolean
  solidBorder?: boolean
  lastColumn?: boolean
  disabled?: boolean
  contextMenuItems?: ContextMenuItem[]
  onContextMenu?: (e: MouseEvent<HTMLDivElement>) => void
  onContextMenuItemClick?: (id: number) => void
}
export const CalendarCell: FC<PropsWithChildren<CalendarCellProps>> = ({
  onClick,
  children,
  className,
  small,
  fullHour = false,
  solidBorder = false,
  lastColumn = false,
  disabled = false,
  onLongPress,
  onContextMenu,
}) => {
  const onLongPressProps = useLongPress(onLongPress)

  const height = small ? SMALL_CELL_HEIGHT : CELL_HEIGHT

  let cellStyle = `h-${height} flex-centered leading-3 text-xs text-main-color whitespace-pre-line line-clamp-3 border-calendar-cell border-b last:border-b-0 `
  if (!solidBorder) cellStyle += ' border-dotted '
  if (!fullHour) cellStyle += 'odd:border-b-0 '
  if (!lastColumn) cellStyle += 'border-r-solid border-r border-r-primary-300 '
  if (onClick && !disabled) cellStyle += 'cursor-pointer '

  const handleClick = () => {
    if (!disabled) onClick?.()
  }

  return (
    <div
      className={cellStyle + className}
      onClick={handleClick}
      onContextMenu={onContextMenu}
      {...onLongPressProps}
    >
      {children}
    </div>
  )
}

// CalendarTitleCell
interface CalendarTitleCellProps {
  grouped?: boolean
  bordered?: boolean
  height?: 'middle' | 'large' | 'small'
  rightBorder?: boolean
  last?: boolean
  onClick?: () => void
}
export const CalendarTitleCell = forwardRef<
  HTMLDivElement,
  PropsWithChildren<CalendarTitleCellProps>
>(
  (
    {
      bordered,
      onClick,
      children,
      rightBorder = false,
      height = 'middle',
      last = false,
      grouped = false,
    },
    ref,
  ) => {
    // order-first нужен из-за того, что этот элемент должен размещаться последним для правильного отображения
    let className =
      'capitalize w-full text-sm flex-centered leading-4 border-t bg-calendar-title text-table-title-color border-calendar-cell order-first sticky z-40 '
    if (height === 'large') className += TALL_TITLE_CELL_HEIGHT_STYLE
    if (height === 'middle') className += TITLE_CELL_HEIGHT_STYLE
    if (height === 'small') className += SMALL_TITLE_CELL_HEIGHT_STYLE

    if (bordered) {
      className += ' border-4 last:rounded-bl-md'
    }

    if (height === 'large') {
      className += ' flex-col'
    }
    // Removes tiny space between titles and top of container
    className += grouped ? ' top-[1.25rem]' : ' -top-0.5'

    if (rightBorder && !last) className += ' border-r'

    className += onClick
      ? ' cursor-pointer hover:bg-primary-100 hover:dark:bg-primary-darkened '
      : ''

    return (
      <div ref={ref} className={className} onClick={onClick}>
        {children}
      </div>
    )
  },
)

// should change together
export const SMALL_TITLE_HEIGHT = 6
export const SMALL_TITLE_CELL_HEIGHT_STYLE = 'h-6'
export const TITLE_CELL_HEIGHT = 10
export const TITLE_CELL_HEIGHT_STYLE = 'h-10'
export const TALL_TITLE_CELL_HEIGHT = 14
export const TALL_TITLE_CELL_HEIGHT_STYLE = 'h-14'

export const CELL_HEIGHT = 10
export const CELL_HEIGHT_STYLE = 'h-10'
export const SMALL_CELL_HEIGHT = 6
export const SMALL_HEIGHT_STYLE = 'h-6'
