import { gql } from 'graphql-request'
import { productBriefFragment } from '../product/product.fragments'
import { serviceBriefWithProductFragment } from '../service/service.fragments'
import { bookingProductFragment } from '../booking/booking.fragments'
import { transactionInBookingFragment } from '../transaction/transaction.fragments'

export const recurringBookingByIdFragment = gql`
  ${serviceBriefWithProductFragment}
  ${productBriefFragment}
  ${bookingProductFragment}
  ${transactionInBookingFragment}
  fragment recurringBookingByIdType on booking {
    id
    startDate
    status
    canceledDate
    duration
    bookingServices {
      id
      service {
        ...serviceBriefWithProductType
      }
    }
    bookingProducts {
      ...bookingProductType
    }
    transactions {
      ...transactionInBookingType
    }
  }
`
