import { gql } from 'graphql-request'
import { request, useQueryOriginal } from '../../api'
import { TRANSACTIONS_QUERY_KEY, TURNOVER_QUERY_KEY } from './queryKeys'

export function useFetchBusinessTurnover(isEnableCheckBillingTurnover: boolean) {
  return useQueryOriginal(
    [TURNOVER_QUERY_KEY, TRANSACTIONS_QUERY_KEY],
    async () => {
      const result = await request<{
        transactionsAggregate: { aggregate: { sum: { amount: number } } }
      }>(gql`
        query {
          transactionsAggregate(
            where: { fromAccountId: { _is_null: true }, toAccountId: { _is_null: false } }
          ) {
            aggregate {
              sum {
                amount
              }
            }
          }
        }
      `)

      return result.transactionsAggregate.aggregate.sum.amount ?? 0
    },
    { enabled: isEnableCheckBillingTurnover },
  )
}
