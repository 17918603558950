import { gql } from 'graphql-request'
import { dynamicScheduleDateFragment } from '../dynamicScheduleDate/dynamicScheduleDate.fragments'

export const scheduleFragment = gql`
  fragment scheduleType on schedule {
    id
    name
    type
    data
    archived
  }
`

export const employeeScheduleFragment = gql`
  ${scheduleFragment}
  ${dynamicScheduleDateFragment}
  fragment employeeScheduleType on employeeSchedule {
    id
    isDynamic
    schedule {
      ...scheduleType
    }
    dynamicDates {
      ...dynamicScheduleDateType
    }
    startDate
    endDate
    employeeId
    branch {
      id
      name
    }
  }
`
