import {
  BookingUnion,
  ServerReceiptStatus,
  ServerReceiptWithStatus,
  TransactionFromAccount,
  TransactionRefundBySubscription,
  useCheckboxCreateBulkReturnReceipts,
  useCreateTransactionsFromAccount,
  useCreateTransactionsRefundBySubscription,
  useFetchAccounts,
  useGetBranchDefaultAccountId,
} from '@expane/data'
import { prepareDtoForFullRefund } from '@expane/logic/cancelPayment'
import {
  handleUpdateCreateBulkReceiptsResponse,
  transformRefundByCashForCheckboxBulkReceipts,
} from '@expane/logic/payment/checkbox'
import { usePopupOpenState } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { generateUUIDv4 } from 'logic/utils'
import { useRef } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import {
  BookingCancelDialogForm,
  BookingCancelPaymentDialog,
} from 'widgets/BookingCancelPaymentDialog'
import { invalidateUnitedBooking } from 'widgets/BookingMultiServicesDialog/Footer'

type HookProps = {
  initialBookings: Array<BookingUnion>
}

export const useCancelPaymentBookingMultiServicesPopup = ({ initialBookings }: HookProps) => {
  const onCloseDialog = useRef<() => void>()
  const { isOpen, openPopup, closePopup } = usePopupOpenState()
  const [openSnackBar] = useSnackbar()
  const { t } = useTranslation()

  const closeCancelPaymentBooking = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const openCancelPaymentBookingPopup = (onClose?: () => void) => {
    onCloseDialog.current = onClose
    openPopup()
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!
  const { data: defaultAccountId } = useGetBranchDefaultAccountId(branchId)
  const { data: accounts } = useFetchAccounts(branchId)

  const { mutateAsync: refundByCashBookingTransactions } = useCreateTransactionsFromAccount()
  const { mutateAsync: refundBySubscriptionBookingTransactions } =
    useCreateTransactionsRefundBySubscription()
  const { mutateAsync: createBulkReturnReceipts } = useCheckboxCreateBulkReturnReceipts()

  const { control, handleSubmit, setValue, formState } = useForm<BookingCancelDialogForm>({
    defaultValues: {
      accountId: defaultAccountId,
      refundToClientAccount: false,
      returnConsumables: true,
    },
  })

  const onConfirm: SubmitHandler<BookingCancelDialogForm> = async data => {
    const { refundByCash, refundBySubscription } = getRefundedInfo(initialBookings, data)

    if (refundByCash.length > 0) {
      const clientId = initialBookings[0].isGroupBooking
        ? undefined
        : initialBookings?.[0].client?.id
      const transactions = initialBookings.flatMap(booking => booking.transactions)

      const dataForCheckboxReturnBulkReceipts = transformRefundByCashForCheckboxBulkReceipts({
        clientId,
        fromAccountId: data.accountId,
        accounts: accounts ?? [],
        refundByCash,
        transactions,
        generateUUIDv4,
      })
      let receipts: ServerReceiptWithStatus[] | null | undefined = null
      if (
        dataForCheckboxReturnBulkReceipts &&
        dataForCheckboxReturnBulkReceipts.refundByCashWithReceiptId.length > 0 &&
        dataForCheckboxReturnBulkReceipts.checkboxBulkReturnReceipts.receipts.length > 0
      ) {
        const result = await createBulkReturnReceipts(
          dataForCheckboxReturnBulkReceipts.checkboxBulkReturnReceipts,
        )

        receipts = handleUpdateCreateBulkReceiptsResponse({
          t,
          onError: message => openSnackBar(message, 'error', 3000),
          createBulkReceiptsResult: result.checkboxCreateBulkReceipts,
        })

        if (!receipts) {
          closeCancelPaymentBooking()
          return
        }

        // Додатково фільтруємо тільки ті транзакції в яких чеки пройшли
        const filteredTransactionsFromAccount =
          dataForCheckboxReturnBulkReceipts.refundByCashWithReceiptId.filter(transaction =>
            receipts?.some(
              ({ receiptId, status }) =>
                receiptId === transaction.transactionReceipts?.data[0].receiptId &&
                status === ServerReceiptStatus.Done,
            ),
          )

        if (!filteredTransactionsFromAccount.length) {
          openSnackBar(t('submitError'), 'error')
          closeCancelPaymentBooking()
          return
        }

        if (
          filteredTransactionsFromAccount.length !==
          dataForCheckboxReturnBulkReceipts.refundByCashWithReceiptId.length
        )
          openSnackBar(t('softwarePOS.bulkReceiptsError'), 'error')

        await refundByCashBookingTransactions(
          {
            transactionsFromAccount: filteredTransactionsFromAccount,
          },
          {
            onSuccess: () => invalidateUnitedBooking(initialBookings, branchId),
          },
        )
      } else {
        await refundByCashBookingTransactions(
          { transactionsFromAccount: refundByCash },
          {
            onSuccess: () => invalidateUnitedBooking(initialBookings, branchId),
          },
        )
      }
    }
    if (refundBySubscription.length > 0) {
      await refundBySubscriptionBookingTransactions(
        {
          transactionRefundBySubscription: refundBySubscription,
        },
        {
          onSuccess: () => invalidateUnitedBooking(initialBookings, branchId),
        },
      )
    }
    closeCancelPaymentBooking()
  }

  const transactions = initialBookings.map(({ transactions }) => transactions).flat()

  let cancelPaymentBookingPopup: JSX.Element | null = null

  if (isOpen)
    cancelPaymentBookingPopup = (
      <BookingCancelPaymentDialog
        isSubmitting={formState.isSubmitting}
        transactions={transactions}
        control={control}
        onConfirm={onConfirm}
        setValue={setValue}
        handleSubmit={handleSubmit}
        closeCancelPaymentBooking={closeCancelPaymentBooking}
      />
    )

  return {
    cancelPaymentBookingPopup,
    openCancelPaymentBookingPopup,
  }
}

const getRefundedInfo = (initialBookings: Array<BookingUnion>, data: BookingCancelDialogForm) => {
  const refundInfo: Array<{
    refundByCash: TransactionFromAccount[]
    refundBySubscription: TransactionRefundBySubscription[]
  }> = []

  for (const booking of initialBookings) {
    const preparedDtoForFullRefund = prepareDtoForFullRefund({
      transactions: booking.transactions,
      bookingId: booking.id,
      fromAccountId: data.accountId,
      refundToClientAccount: data.refundToClientAccount,
      returnConsumables: data.returnConsumables,
      branchId: booking.branchId,
    })

    refundInfo.push(preparedDtoForFullRefund)
  }

  return {
    refundByCash: refundInfo.map(({ refundByCash }) => refundByCash).flat(),
    refundBySubscription: refundInfo.map(({ refundBySubscription }) => refundBySubscription).flat(),
  }
}
