import { Transition } from '@headlessui/react'

export const FadeIn = ({ children, ...props }) => {
  return (
    <Transition
      appear
      show
      enter="transition-all ease-in-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      {...props}
    >
      {children}
    </Transition>
  )
}

export const FadeOut = ({ children, ...props }) => {
  return (
    <Transition
      show
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      {...props}
    >
      {children}
    </Transition>
  )
}

export const FadeInTranslateBottom = ({ children, ...props }) => {
  return (
    <Transition
      appear
      show
      enter="transform-gpu ease-out duration-200"
      enterFrom="translate-y-6 opacity-50"
      enterTo="translate-y-0 opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      {...props}
    >
      {children}
    </Transition>
  )
}

export const FadeInTranslateTop = ({ children, ...props }) => {
  return (
    <Transition
      appear
      show
      enter="transform-gpu ease-out duration-200"
      enterFrom="-translate-y-2 opacity-50"
      enterTo="translate-y-0 opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      {...props}
    >
      {children}
    </Transition>
  )
}
