import { FC } from 'react'
import {
  endOfDay,
  startOfDay,
  subDays,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addMonths,
  isSameMonth,
  createCurrentDate,
} from '@expane/date'
import { useTranslation } from 'react-i18next'

type QuickRangeButtonsProps = {
  value: Date[]
  onChange: (value: Date[]) => void
  close: () => void
  setFirstActiveStartDate: (date: Date) => void
  setSecondActiveStartDate: (date: Date) => void
  timezone: string
}

export const QuickRangeButtons: FC<QuickRangeButtonsProps> = ({
  onChange,
  value,
  setFirstActiveStartDate,
  setSecondActiveStartDate,
  close,
  timezone,
}) => {
  const { t } = useTranslation()

  const setActiveStartDate = (date: Date) => {
    const dateForView = startOfMonth(date)
    setFirstActiveStartDate(dateForView)
    setSecondActiveStartDate(addMonths(dateForView, 1))
  }

  const setDay = (date: Date) => {
    const dayRange = getDayRange(date)
    onChange(dayRange)

    setActiveStartDate(date)
  }

  const setWeek = (date: Date) => {
    const weekRange = getWeekRange(date)
    onChange(weekRange)

    if (isSameMonth(weekRange[0], weekRange[1])) {
      setActiveStartDate(date)
    } else {
      setFirstActiveStartDate(startOfMonth(weekRange[0]))
      setSecondActiveStartDate(startOfMonth(weekRange[1]))
    }
  }

  const setMonth = (date: Date) => {
    const monthRange = getMonthRange(date)

    onChange(monthRange)

    setActiveStartDate(date)
  }

  return (
    <div className="border-r border-primary-100 flex flex-col">
      <ul className="w-28 p-1 grow flex flex-col justify-center">
        <li className={listItemClassName}>
          <RangeButton
            isActive={compareRanges(value, getDayRange(createCurrentDate(timezone)))}
            onClick={() => setDay(createCurrentDate(timezone))}
            text={t('today')}
          />
        </li>
        <li className={listItemClassName}>
          <RangeButton
            isActive={compareRanges(value, getDayRange(getYesterday(timezone)))}
            onClick={() => setDay(getYesterday(timezone))}
            text={t('yesterday')}
          />
        </li>
        <li className={listItemClassName}>
          <RangeButton
            isActive={compareRanges(value, getDayRange(getTomorrow(timezone)))}
            onClick={() => setDay(getTomorrow(timezone))}
            text={t('tomorrow')}
          />
        </li>
        <li className={listItemClassName}>
          <RangeButton
            isActive={compareRanges(value, getWeekRange(createCurrentDate(timezone)))}
            onClick={() => setWeek(createCurrentDate(timezone))}
            text={t('calendarPeriod.week')}
          />
        </li>
        <li className={listItemClassName}>
          <RangeButton
            isActive={compareRanges(value, getMonthRange(createCurrentDate(timezone)))}
            onClick={() => setMonth(createCurrentDate(timezone))}
            text={t('calendarPeriod.month')}
          />
        </li>
      </ul>
      <button onClick={close} className={closeButtonStyle}>
        {t('close')}
      </button>
    </div>
  )
}
const listItemClassName = 'flex justify-center'
const closeButtonStyle =
  'flex-centered w-full mt-3 py-0.5 border-t border-primary-50 dark:border-primary-500/30 h-14 transition-colors text-primary-500 bg-hover'

type RangeButtonProps = {
  onClick: () => void
  isActive: boolean
  text: string
}

const RangeButton: FC<RangeButtonProps> = ({ onClick, isActive, text }) => {
  let className = 'px-1 my-0.5 border-2 rounded transition-all'

  if (isActive) className += ' border-primary-400 text-primary-500'
  else className += ' border-transparent hover:text-primary-400 text-main-color'

  return (
    <button className={className} onClick={onClick}>
      {text}
    </button>
  )
}

const setWeekOptions: { weekStartsOn: 0 | 2 | 1 | 5 | 3 | 4 | 6 | undefined } = { weekStartsOn: 1 }

const getYesterday = (timezone: string) => {
  const currentDate = createCurrentDate(timezone)
  const yesterdayDate = subDays(currentDate, 1)
  return yesterdayDate
}
const getTomorrow = (timezone: string) => {
  const currentDate = createCurrentDate(timezone)
  const tomorrowDate = addDays(currentDate, 1)

  return tomorrowDate
}

const getDayRange = (date: Date) => {
  const start = startOfDay(date)
  const end = endOfDay(date)

  return [start, end]
}
const getWeekRange = (date: Date) => {
  const start = startOfWeek(date, setWeekOptions)
  const end = endOfWeek(date, setWeekOptions)

  return [start, end]
}
const getMonthRange = (date: Date) => {
  const start = startOfMonth(date)
  const end = endOfMonth(date)

  return [start, end]
}

const compareRanges = (firstRange: Date[], secondRange: Date[]) => {
  return (
    firstRange[0].getTime() === secondRange[0].getTime() &&
    firstRange[1].getTime() === secondRange[1].getTime()
  )
}
