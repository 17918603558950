import { translateItemsName } from '@expane/logic/utils'
import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Modal,
  PlaceholderInput,
  PlaceholderTextarea,
  RadioGroupButtons,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PlaceholderEditablePhoto } from 'ui/PlaceholderContent'
import { SaveButton } from 'widgets/Buttons'
import { productTypeOptions } from './logic'

export const ProductDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('product.name')}</Dialog.Title>

        <Dialog.Body className="w-192">
          <div className="flex">
            <div className="w-full">
              <div className="flex w-full gap-2 mb-4">
                <PlaceholderInput className="w-1/2" label={t('title')} />
                <PlaceholderInput className="w-1/4" label={t('unit.name')} />
                <PlaceholderInput className="w-1/4" label={t('criticalQuantity.shortName')} />
              </div>
              <div className="flex w-full gap-2 mb-4">
                <PlaceholderInput className="w-1/2" label={t('category')} />
                <PlaceholderInput className="w-1/4" label={t('costPrice')} />
                <PlaceholderInput className="w-1/4" label={t('price')} />
              </div>
              <div className="flex w-full gap-2 mb-4">
                <div className="w-1/2">
                  <div className="flex gap-2 mb-4">
                    <PlaceholderInput className="w-1/2" label={t('vendorCode')} />
                    <PlaceholderInput className="w-1/2" label={t('barcode')} />
                  </div>
                  <RadioGroupButtons
                    className="mb-4"
                    options={translateItemsName(productTypeOptions, t)}
                    onChange={() => {
                      return
                    }}
                    value={undefined}
                    disabled
                  />
                </div>
                <PlaceholderTextarea rows={5} label={t('description')} className="w-1/2" />
              </div>
            </div>

            <PlaceholderEditablePhoto className="ml-2 mt-4" />
          </div>
        </Dialog.Body>

        <Dialog.Footer>
          <SaveButton disabled />
          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
