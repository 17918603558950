import { FC } from 'react'
import { useFetchMyEmployee, useFetchMyPermissions } from 'gql/employee'
import {
  useFetchBranchById,
  useFetchClientsBriefs,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchExtendedServices,
  useFetchWaitingListById,
} from '@expane/data'
import {
  WaitingListDialogLogic,
  WaitingListDialogPlaceholder,
} from 'widgets/WaitingListDialog/waitingListDialogLogic'
import { store } from 'store'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'

export const WaitingListDialog: FC<DialogProps> = ({
  id: waitingListId,
  closeDialog,
  isCreate,
}) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { data: branch, isLoading: isLoadingBranch } = useFetchBranchById(branchId)
  const { data: myEmployee } = useFetchMyEmployee(branch?.timezone, branchId)
  const { data: myPermissions } = useFetchMyPermissions()
  const { data: waitingListById, isLoading: isLoadingWaitingListById } = useFetchWaitingListById(
    waitingListId,
    branch?.timezone,
  )
  const { data: services, isLoading: isLoadingServices } = useFetchExtendedServices(branchId, 'all')
  const { data: clients, isLoading: isLoadingClients } = useFetchClientsBriefs(branchId, timezone)
  const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } = useFetchEmployeesGroups(
    branch?.timezone,
    branchId,
  )

  const isLoading =
    (isLoadingWaitingListById && !isCreate) ||
    isLoadingServices ||
    isLoadingBranch ||
    isLoadingClients ||
    isLoadingEmployeeGroups
  const isNoData =
    (!isCreate && !waitingListById) ||
    !branch ||
    !myEmployee ||
    !myPermissions ||
    !services ||
    !clients ||
    !employeeGroups

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
  if (isLoading) return <WaitingListDialogPlaceholder closeDialog={closeDialog} />
  if (isNoData) return null

  return (
    <WaitingListDialogLogic
      timezone={branch.timezone}
      waitingListById={waitingListById}
      closeDialog={closeDialog}
      services={services}
      clients={clients}
      employeeGroups={employeeGroups}
      myPermissions={myPermissions}
      isCreate={isCreate}
      myEmployee={myEmployee}
      branchSchedule={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        branch.schedule!
      }
    />
  )
}
