import {
  EmployeeFromGroup,
  ServerSalaryIssueType,
  ServerTransactionSalaryType,
  useFetchCurrentBranchTimezone,
  useFetchSalaryIssues,
  useFetchTransactionsWithSalaries,
} from '@expane/data'
import { compareDesc, getOverlappingDaysInIntervals } from '@expane/date'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { permissions } from '@expane/logic/permission'
import {
  convertAccruedSalaryRateToSalaryItems,
  convertSalariesToSalaryItems,
  convertSalaryIssuesToPremiumSalaryItems,
  convertSalaryIssuesToServiceSalaryItems,
  SalaryItem,
} from '@expane/logic/salaryIssues/converting'
import {
  filterSalaryIssuesByEmployeeId,
  getPremiumAndPenaltySalaryIssues,
  getRateSalaryIssues,
  getSalariesIssuesByTypesAndPeriod,
  getServicesSalaryIssues,
} from '@expane/logic/salaryIssues/filters'
import { getSalaryIssuesByPeriod } from '@expane/logic/salaryIssues/helpers'
import { countedFullSalariesByEmployee } from '@expane/logic/salaryIssues/logic'
import { getContactStartDate } from '@expane/logic/salarySettings'
import { transformPersonName, translateItemsName } from '@expane/logic/utils'
import {
  Button,
  CloseButton,
  Dialog,
  EmptyPlaceholder,
  HorizontalButtonSwitch,
  Modal,
  usePopupOpenState,
} from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { DatePickerType, useCustomDatePicker } from 'logic/hooks/useCustomDatePicker'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { observer } from 'mobx-react-lite'
import { FC, ReactElement, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoReaderOutline, IoWalletOutline } from 'react-icons/io5'
import { IconType } from 'react-icons/lib'
import { store } from 'store'
import { CoinsIcon, PercentageIcon } from 'ui/Icons'
import { PremiumButton } from 'widgets/PremiumDialog/PremiumButton'
import { ReferralPercentageList } from 'widgets/SalaryEmployeeDialog/ReferralPercentageList'
import { SalaryEmployeeDialogPlaceholder } from 'widgets/SalaryEmployeeDialog/SalaryEmployeeDialogPlaceholder'
import { SalaryPayList } from 'widgets/SalaryEmployeeDialog/SalaryPayList'
import { useOpenIssueSalaryDialog } from 'widgets/SalaryIssueDialog'

export const useOpenSalaryEmployeeDialog: (dto: Omit<SalaryEmployeeDialogProps, 'closeDialog'>) => {
  openEditSalaryEmployeeDialog: () => void
  salaryEmployeeDialog: ReactElement | null
} = dto => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()

  const openEditSalaryEmployeeDialog = (onClose?: () => void) => {
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const salaryEmployeeDialog = isOpen ? (
    <SalaryEmployeeDialog {...dto} closeDialog={closeDialog} />
  ) : null

  return { openEditSalaryEmployeeDialog, salaryEmployeeDialog }
}

interface SalaryEmployeeDialogProps {
  employee: EmployeeFromGroup
  fromDate: Date
  toDate: Date
  closeDialog: () => void
  datePickerType: DatePickerType
}

export const SalaryEmployeeDialog: FC<SalaryEmployeeDialogProps> = observer(
  ({ employee, fromDate, toDate, closeDialog, datePickerType }) => {
    const { isLoadingBusinessModulesSettings } = useBusinessModulesSettings()
    const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()

    const branchId = store.branch.branchId

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { data: salaryIssues, isLoading: isLoadingSalaryIssues } = useFetchSalaryIssues(
      timezone,
      branchId,
    )

    const { data: transactionsWithSalaries, isLoading: isLoadingTransactionsWithSalaries } =
      useFetchTransactionsWithSalaries(timezone, branchId)

    const { customDatePicker, period } = useCustomDatePicker({
      containerClassName: 'mr-2',
      defaultPeriod: [fromDate, toDate],
      initialType: datePickerType,
    })

    const isLoading =
      isLoadingBusinessModulesSettings ||
      isLoadingSalaryIssues ||
      isLoadingTransactionsWithSalaries ||
      isLoadingMyPermissions

    const isNoData = !transactionsWithSalaries || !salaryIssues || !myPermissions

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
    if (!isLoading && isNoData) return null

    return (
      <Modal close={closeDialog}>
        <Dialog>
          {isLoading ? (
            <SalaryEmployeeDialogPlaceholder closeDialog={closeDialog} />
          ) : (
            <SalaryEmployeeDialogLogic
              salaryIssues={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                salaryIssues!
              }
              closeDialog={closeDialog}
              transactionsWithSalaries={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                transactionsWithSalaries!
              }
              myPermissions={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                myPermissions!
              }
              employee={employee}
              fromDate={period.start}
              toDate={period.end}
              customDatePicker={customDatePicker}
              isLoading={isLoading}
              branchId={branchId}
            />
          )}
        </Dialog>
      </Modal>
    )
  },
)

interface SalaryEmployeeDialogLogicType {
  salaryIssues: ServerSalaryIssueType[]
  myPermissions: string[]
  transactionsWithSalaries: ServerTransactionSalaryType[]
  employee: EmployeeFromGroup
  fromDate: Date
  toDate: Date
  customDatePicker
  isLoading: boolean
  closeDialog: () => void
}

const SalaryEmployeeDialogLogic: FC<PropsWithBranchId<SalaryEmployeeDialogLogicType>> = ({
  myPermissions,
  salaryIssues,
  transactionsWithSalaries,
  closeDialog,
  employee,
  fromDate,
  toDate,
  customDatePicker,
  isLoading,
  branchId,
}) => {
  const { id, salarySettings: salaryRateSettingsByEmployeeId } = employee

  const { t } = useTranslation()

  const { getModuleSetting } = useBusinessModulesSettings()

  const [currentSwitchTab, setCurrentSwitchTab] = useState<SwitchTypes>(switchOptions[0].id)

  const salariesIssuesByTypesAndPeriod = getSalariesIssuesByTypesAndPeriod({
    salaryIssues,
    fromDate,
    toDate,
  })

  const employeeCountedSalariesFullInfo = countedFullSalariesByEmployee({
    toDate,
    fromDate,
    employee,
    ...salariesIssuesByTypesAndPeriod,
  })

  const { openIssueSalaryDialog, issueSalaryDialog } = useOpenIssueSalaryDialog({
    fromDate,
    toDate,
    defaultEmployeeId: id,
  })

  const isNotPaidSalary =
    employeeCountedSalariesFullInfo.notPaidSalary.totalSum > 0 ||
    employeeCountedSalariesFullInfo.debtSalary.totalSum > 0

  const { salaryIssuesInSelectedPeriod } = getSalaryIssuesByPeriod(salaryIssues, fromDate, toDate)

  const transactionsWithSalariesThatOverlappingWithCurrentPeriod =
    getTransactionsWithSalariesThatOverlappingWithPeriod(
      filterTransactionsByEmployeeId(transactionsWithSalaries, id),
      fromDate,
      toDate,
    )

  const salariesSalaryItems = convertSalariesToSalaryItems(
    transactionsWithSalariesThatOverlappingWithCurrentPeriod,
  )

  const salaryIssuesByEmployeeId = filterSalaryIssuesByEmployeeId(
    salaryIssuesInSelectedPeriod,
    employee.id,
  )

  const serviceSalaryItems = convertSalaryIssuesToServiceSalaryItems(
    getServicesSalaryIssues(salaryIssuesByEmployeeId),
  )

  const premiumSalaryItems = convertSalaryIssuesToPremiumSalaryItems(
    getPremiumAndPenaltySalaryIssues(salaryIssuesByEmployeeId),
  )

  const accruedSalaryRateSalaryItems = convertAccruedSalaryRateToSalaryItems({
    fromDate,
    toDate,
    salaryRateSettings: salaryRateSettingsByEmployeeId,
    contractStartDate: getContactStartDate(salaryRateSettingsByEmployeeId),
    employeeSchedules: employee.employeeSchedules,
    salaryRateIssues: getRateSalaryIssues(
      filterSalaryIssuesByEmployeeId(salaryIssues, employee.id),
    ),
  })

  const salaryItems: Array<SalaryItem> = sortSalaryItemsByDate([
    ...serviceSalaryItems,
    ...premiumSalaryItems,
    ...accruedSalaryRateSalaryItems,
    ...salariesSalaryItems,
  ])

  const showManagingEmployee = getModuleSetting('managingEmployee')

  const isSalarySetAllowed = myPermissions.includes(permissions.salary.set)

  return (
    <>
      <Dialog.Title>{`${t('salary.name')}: ${transformPersonName(employee)}`}</Dialog.Title>

      <Dialog.Body className="w-246 h-120 flex flex-col">
        <div className="mb-2 flex justify-between">
          {customDatePicker}

          {showManagingEmployee && (
            <HorizontalButtonSwitch
              options={translateItemsName(switchOptions, t)}
              value={currentSwitchTab}
              onChange={id => setCurrentSwitchTab(id as SwitchTypes)}
            />
          )}
        </div>

        {salaryItems?.length === 0 ? (
          <EmptyPlaceholder Icon={IoReaderOutline} text={t('salary.noDataAboutProvidedServices')} />
        ) : (
          <>
            {currentSwitchTab === switchOptions[0].id && (
              <SalaryPayList salaryItems={salaryItems} isLoading={isLoading} branchId={branchId} />
            )}
            {currentSwitchTab === switchOptions[1].id && (
              <ReferralPercentageList
                salaryIssuesByEmployeeId={salaryIssuesByEmployeeId}
                branchId={branchId}
              />
            )}
          </>
        )}
      </Dialog.Body>

      <Dialog.Footer>
        <CloseButton onClick={closeDialog} />

        {isSalarySetAllowed && (
          <>
            {isNotPaidSalary ? (
              <Button
                type="outline"
                twoLines
                className="w-28 mr-auto"
                onClick={() => openIssueSalaryDialog()}
                Icon={CoinsIcon}
              >
                {t('salary.issueSalary')}
              </Button>
            ) : null}

            <PremiumButton
              type="penalty"
              employeeId={id}
              className={isNotPaidSalary ? '' : 'mr-auto'}
              myPermissions={myPermissions}
            />
            <PremiumButton type="premium" employeeId={id} myPermissions={myPermissions} />
          </>
        )}
      </Dialog.Footer>

      {issueSalaryDialog}
    </>
  )
}

type SwitchTypes = 'accruing' | 'referralPercentage'

const switchOptions: { id: SwitchTypes; name: string; Icon: IconType }[] = [
  { id: 'accruing', name: 'salary.accruing', Icon: IoWalletOutline },
  { id: 'referralPercentage', name: 'salary.refPercentage', Icon: PercentageIcon },
]

const sortSalaryItemsByDate = (salaryItems: Array<SalaryItem>) =>
  salaryItems.sort((a, b) => compareDesc(a.date, b.date))

export const filterTransactionsByEmployeeId = (
  transactions: Array<ServerTransactionSalaryType>,
  employeeId: number,
) => transactions.filter(transaction => transaction.employeeId === employeeId)

export const getTransactionsWithSalariesThatOverlappingWithPeriod = (
  transactionsWithSalaries: ServerTransactionSalaryType[],
  fromDate: Date,
  toDate: Date,
) =>
  transactionsWithSalaries.filter(transaction =>
    getOverlappingDaysInIntervals(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      { start: transaction.startPeriod!, end: transaction.endPeriod! },
      { start: fromDate, end: toDate },
    ),
  )
