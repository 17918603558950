export const G_MAPS_URL = 'https://maps.googleapis.com/maps/'

export interface Geocode {
  formatted_address: string
  geometry: {
    location: {
      lat: string
      lng: string
    }
  }
  place_id: string
}

export interface GeocodeResponse {
  results: [Geocode]
  status:
    | 'OK'
    | 'ZERO_RESULTS'
    | 'OVER_DAILY_LIMIT'
    | 'OVER_QUERY_LIMIT'
    | 'REQUEST_DENIED'
    | 'INVALID_REQUEST'
    | 'UNKNOWN_ERROR'
}

export const gMapApi = {
  geocode: (address: string, key: string) => {
    const searchParams = new URLSearchParams({ address, key })

    return G_MAPS_URL + 'api/geocode/json?' + searchParams
  },
  getIFrameURL: (address: string, key: string) => {
    const searchParams = new URLSearchParams({ q: address, key })

    return G_MAPS_URL + 'embed/v1/place?' + searchParams
  },
}
