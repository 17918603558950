import {
  InfiniteNotifications,
  NotificationUnionType,
  useFetchCurrentBranchTimezone,
  useFetchInfiniteNotificationsByEmployeeId,
  useMakeDoneInternalNotifications,
} from '@expane/data'
import {
  Button,
  Checkbox,
  FadeInTranslateTop,
  HorizontalButtonSwitch,
  HorizontalButtonSwitchItem,
  modalsHTMLElement,
  usePopupOpenState,
} from '@expane/ui'
import { InfiniteData } from '@tanstack/react-query'
import { translateData } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkDoneOutline, IoClose, IoMailOpenOutline, IoMailOutline } from 'react-icons/io5'
import { store } from 'store'
import { useSnackbar } from '@expane/widgets'
import { NotificationList } from 'widgets/NotificationDialog/List'
import { DEFAULT_TIMEZONE } from '@expane/date'

interface NotificationDialogProps {
  closeDialog: () => void
  openBookingDialog: ((number) => void) | undefined
}

const NotificationDialog: FC<NotificationDialogProps> = observer(
  ({ closeDialog, openBookingDialog }) => {
    const branchId = store.branch.branchId
    const employeeId = store.me.employeeId

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const {
      data: notificationsData,
      fetchNextPage: fetchNextNotificationPage,
      hasNextPage: hasNotificationNextPage,
      isFetchingNextPage: isFetchingNotificationNextPage,
    } = useFetchInfiniteNotificationsByEmployeeId({
      employeeId,
      perPage: PER_PAGE,
      timezone,
      branchId,
      type: 'undone',
    })

    const {
      data: doneNotificationData,
      fetchNextPage: fetchNextDoneNotificationPage,
      hasNextPage: hasDoneNotificationNextPage,
      isFetchingNextPage: isFetchingDoneNotificationNextPage,
    } = useFetchInfiniteNotificationsByEmployeeId({
      employeeId,
      perPage: PER_PAGE,
      timezone,
      branchId,
      type: 'done',
    })

    const [doneNotificationObservableContainer, setDoneNotificationObservableContainer] =
      useState<HTMLDivElement | null>(null)
    const [notificationObservableContainer, setNotificationObservableContainer] =
      useState<HTMLDivElement | null>(null)

    const { t } = useTranslation()

    const [selectedNotifications, setNotifications] = useState<Array<number>>([])
    const [currentSwitchTab, setCurrentSwitchTab] = useState<SwitchTypes>('notDone')

    const [areAllNotificationsSelected, setAreAllNotificationsSelected] = useState(false)

    const handleNextNotificationPage: IntersectionObserverCallback = useCallback(
      entries => {
        const [entry] = entries

        if (entry.isIntersecting && hasNotificationNextPage) {
          fetchNextNotificationPage()
        }
      },
      [fetchNextNotificationPage, hasNotificationNextPage],
    )

    const handleNextDoneNotificationPage: IntersectionObserverCallback = useCallback(
      entries => {
        const [entry] = entries

        if (entry.isIntersecting && hasDoneNotificationNextPage) {
          fetchNextDoneNotificationPage()
        }
      },
      [fetchNextDoneNotificationPage, hasDoneNotificationNextPage],
    )

    useEffect(() => {
      const observer = new IntersectionObserver(handleNextNotificationPage)
      if (notificationObservableContainer) observer.observe(notificationObservableContainer)

      return () => {
        if (notificationObservableContainer) observer.unobserve(notificationObservableContainer)
      }
    }, [handleNextNotificationPage, notificationObservableContainer])

    useEffect(() => {
      const observer = new IntersectionObserver(handleNextDoneNotificationPage)
      if (doneNotificationObservableContainer) observer.observe(doneNotificationObservableContainer)

      return () => {
        if (doneNotificationObservableContainer)
          observer.unobserve(doneNotificationObservableContainer)
      }
    }, [doneNotificationObservableContainer, handleNextDoneNotificationPage])

    const translatedSwitchOptions = translateData(switchOptions, t)

    const { mutateAsync: makeDoneNotifications } = useMakeDoneInternalNotifications()

    const [openSnackbar] = useSnackbar()

    const notifications = flatNotifications(notificationsData)
    const doneNotifications = flatNotifications(doneNotificationData)

    const handleMakeDoneNotification = async () => {
      setAreAllNotificationsSelected(false)

      const result = await makeDoneNotifications(selectedNotifications)

      if (result?.updateNotifications?.affectedRows)
        openSnackbar(t('notificationStatuses.success'), 'success')
      else openSnackbar(t('submitError'), 'error')
    }

    const handleSelectAllNotifications = (checked: boolean) => {
      setAreAllNotificationsSelected(checked)

      if (checked) setNotifications(notifications.map(({ id }) => id) ?? [])
      else setNotifications([])
    }

    return createPortal(
      <FadeInTranslateTop className="fixed top-12 right-2 rounded-md bg-block border-2 border-primary-200 dark:border-primary-500 w-120">
        <div className="flex items-center justify-between w-full p-3 bg-primary-50 dark:bg-gray-700 rounded-t-md border-b-2 border-primary-200 dark:border-primary-500">
          <p className="font-medium text-primary-700 mr-2">{t('notifications')}</p>

          <HorizontalButtonSwitch
            options={translatedSwitchOptions}
            value={currentSwitchTab}
            onChange={id => {
              setCurrentSwitchTab(id as SwitchTypes)
              // если ранее были выбраны нотификейшны - убираем выбранные
              setAreAllNotificationsSelected(false)
              setNotifications([])
            }}
          />

          <div className="text-error-500 rounded-md hover:bg-error-500/30 cursor-pointer">
            <IoClose size="1.2rem" onClick={closeDialog} />
          </div>
        </div>

        <div className="p-3">
          {currentSwitchTab === 'notDone' && notifications.length !== 0 && (
            <div className="flex items-center justify-between pl-3.5 mb-3">
              <Checkbox
                checked={areAllNotificationsSelected}
                onChange={e => {
                  handleSelectAllNotifications(e.target.checked)
                }}
              />

              <Button
                twoLines
                className="w-32"
                onClick={handleMakeDoneNotification}
                type="outline"
                disabled={!selectedNotifications.length}
                Icon={IoCheckmarkDoneOutline}
              >
                {t('notificationStatuses.mark')}
              </Button>
            </div>
          )}
          <>
            {currentSwitchTab === 'notDone' && Boolean(timezone) && (
              <NotificationList
                timezone={timezone ?? DEFAULT_TIMEZONE}
                notifications={notifications}
                setNotifications={setNotifications}
                selectedNotifications={selectedNotifications}
                isFetchingNextPage={isFetchingNotificationNextPage}
                currentSwitchTab={currentSwitchTab}
                setObservableRef={setNotificationObservableContainer}
                openBookingDialog={openBookingDialog}
              />
            )}

            {currentSwitchTab === 'done' && Boolean(timezone) && (
              <NotificationList
                timezone={timezone ?? DEFAULT_TIMEZONE}
                notifications={doneNotifications}
                setNotifications={setNotifications}
                selectedNotifications={selectedNotifications}
                isFetchingNextPage={isFetchingDoneNotificationNextPage}
                currentSwitchTab={currentSwitchTab}
                setObservableRef={setDoneNotificationObservableContainer}
                openBookingDialog={openBookingDialog}
              />
            )}
          </>
        </div>
      </FadeInTranslateTop>,
      modalsHTMLElement,
    )
  },
)

export const useOpenNotificationDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const openBookingDialog = useRef<(bookingId: number) => void>()

  const openNotificationDialog = (openBookingDialogFunc: (number) => void) => {
    openBookingDialog.current = openBookingDialogFunc
    openPopup()
  }

  const notificationDialog = isOpen ? (
    <NotificationDialog closeDialog={closePopup} openBookingDialog={openBookingDialog.current} />
  ) : null

  return { openNotificationDialog, notificationDialog, closeDialog: closePopup }
}

export type SwitchTypes = 'notDone' | 'done'

const switchOptions: HorizontalButtonSwitchItem[] = [
  {
    id: 'notDone',
    name: 'notificationStatuses.notRead',
    Icon: IoMailOutline,
  },
  {
    id: 'done',
    name: 'notificationStatuses.read',
    Icon: IoMailOpenOutline,
  },
]

const flatNotifications = (
  data: InfiniteData<InfiniteNotifications> | undefined,
): NotificationUnionType[] => data?.pages.flatMap(({ notifications }) => notifications) ?? []

const PER_PAGE = 10
