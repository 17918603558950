import {
  useFetchEmployeeGroupById,
  useFetchRolePermissions,
  useFetchStorages,
  useGetBranchDefaultStorageId,
} from '@expane/data'
import { useFetchMyPermissions } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { store } from 'store'
import { EmployeeGroupDialogLogic } from './EmployeeGroupDialogLogic'
import { EmployeeGroupDialogPlaceholder } from './EmployeeGroupDialogPlaceholder'

export const EmployeeGroupDialog: FC<DialogProps> = observer(
  ({ id: employeeGroupId, closeDialog, isCreate, onCreate }) => {
    const branchId = store.branch.branchId

    const { data: employeeGroupById, isLoading: isLoadingEmployeeGroupById } =
      useFetchEmployeeGroupById(employeeGroupId)
    const { data: myPermissions } = useFetchMyPermissions()
    const { data: storages, isLoading: isLoadingStorages } = useFetchStorages(branchId)
    const { data: defaultStorageId, isLoading: isLoadingDefaultStorageId } =
      useGetBranchDefaultStorageId(branchId)
    const { data: rolePermissions, isLoading: isLoadingRolePermissions } = useFetchRolePermissions()

    const isLoading =
      (isLoadingEmployeeGroupById && !isCreate) ||
      isLoadingStorages ||
      isLoadingDefaultStorageId ||
      isLoadingRolePermissions
    const isNoData =
      (!isCreate && !employeeGroupById) ||
      !myPermissions ||
      !storages ||
      !defaultStorageId ||
      !rolePermissions ||
      !branchId

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
    if (isLoading) return <EmployeeGroupDialogPlaceholder closeDialog={closeDialog} />
    if (isNoData) return null

    return (
      <EmployeeGroupDialogLogic
        storages={storages}
        defaultStorageId={defaultStorageId}
        branchId={branchId}
        employeeGroupById={employeeGroupById}
        myPermissions={myPermissions}
        rolePermissions={rolePermissions}
        closeDialog={closeDialog}
        isCreate={isCreate}
        onCreate={onCreate}
      />
    )
  },
)
