import { ServerServiceType, useBranchImportServices, useFetchLocations } from '@expane/data'
import {
  CloseButton,
  Dialog,
  Input,
  Modal,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
  usePopupOpenState,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { MultiSelect } from 'ui/MultiSelect'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import {
  PickedService,
  prepareDtoForBranchImportServices,
} from 'widgets/BranchImportServiceDialog/logic'
import { BranchImportServicesLocationsDialogPlaceholder } from 'widgets/BranchImportServiceDialog/ServiceDialogPlaceholder'
import { ImportButton } from 'widgets/Buttons'

type ServiceTreeItem = TreeMenuItem & ServerServiceType

interface BranchImportServicesLocationsDialogProps extends Pick<DialogProps, 'closeDialog'> {
  items: ServiceTreeItem[]
  closeChooseServiceDialog: () => void
  ignoreGroups: boolean
}

export const BranchImportServicesLocationsDialog: FC<BranchImportServicesLocationsDialogProps> = ({
  items,
  closeChooseServiceDialog,
  ignoreGroups,
  closeDialog,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!
  const { data: locations, isLoading } = useFetchLocations(branchId)

  const { mutateAsync: importServices, isLoading: isCreatingServices } = useBranchImportServices()

  const { t } = useTranslation()

  const defaultPickedServices = generateNewSelectedServices(items)

  const { control, handleSubmit } = useForm<ServiceCustomFieldsForm>({
    defaultValues: {
      pickedServices: defaultPickedServices,
    },
  })

  const { fields: selectedServices } = useFieldArray({ control, name: 'pickedServices' })

  const [showSnackbar] = useSnackbar()

  const submitHandler: SubmitHandler<ServiceCustomFieldsForm> = async data => {
    const dto = prepareDtoForBranchImportServices({
      serviceDtos: data.pickedServices,
      selectedItems: items,
      branchId,
      ignoreGroups,
    })

    try {
      await importServices(dto)
      showSnackbar(t('menu.branchImport.success'), 'success')
    } catch (error) {
      showSnackbar(t('submitError'), 'error')
    }

    closeDialog()
    closeChooseServiceDialog()
  }

  useErrorOpeningDialog(!isLoading && !locations, closeDialog)

  if (isLoading) return <BranchImportServicesLocationsDialogPlaceholder closeDialog={closeDialog} />
  if (!locations) return null

  return (
    <Modal animation="onlyFadeOut" close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('importServices.name')}</Dialog.Title>

        <Dialog.Body className="h-74.5 flex flex-col">
          <TableContainer className="h-fit">
            <TableHeader>
              <tr>
                <TableHeaderCell className="w-32">{t('name')}</TableHeaderCell>
                <TableHeaderCell className="w-32">{t('price')}</TableHeaderCell>
                <TableHeaderCell className="w-64">{t('locations.name')}</TableHeaderCell>
              </tr>
            </TableHeader>

            <TableBody>
              {selectedServices.map((pickedService, index) => (
                <TableRow key={pickedService.id}>
                  <TableCell>{pickedService.name}</TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`pickedServices.${index}.price`}
                      render={({ field }) => <Input type="number" {...field} />}
                    />
                  </TableCell>

                  <TableCell>
                    <Controller
                      control={control}
                      rules={{
                        validate: value => value.length > 0,
                      }}
                      name={`pickedServices.${index}.locations`}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <MultiSelect
                          items={locations}
                          selectedItems={value}
                          onItemSelect={onChange}
                          errorMessage={{
                            isShown: Boolean(error),
                            reserveIndent: false,
                          }}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
        </Dialog.Body>

        <Dialog.Footer>
          <ImportButton
            onClick={handleSubmit(submitHandler)}
            disabled={selectedServices.length === 0 || isCreatingServices}
            spinner={isCreatingServices}
          />

          <CloseButton onClick={closeDialog} disabled={isCreatingServices} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

export type ServiceCustomFieldsForm = {
  pickedServices: PickedService[]
}

export const useBranchImportServicesLocationsDialog = () => {
  const { closePopup, openPopup, isOpen } = usePopupOpenState()

  const props = useRef<Omit<BranchImportServicesLocationsDialogProps, 'closeDialog'>>()

  const openBranchImportServiceLocationsDialog = (
    dto: Omit<BranchImportServicesLocationsDialogProps, 'closeDialog'>,
  ) => {
    props.current = dto

    openPopup()
  }

  const closeDialog = () => {
    closePopup()
  }

  const branchImportServicesLocationsDialog =
    isOpen && props.current ? (
      <BranchImportServicesLocationsDialog closeDialog={closeDialog} {...props.current} />
    ) : null

  return { branchImportServicesLocationsDialog, openBranchImportServiceLocationsDialog }
}

const generateNewSelectedServices = (
  newSelectedGroupedItems: ServiceTreeItem[],
): PickedService[] => {
  const newSelectedItems = extractItemsFromFolders<ServiceTreeItem>(newSelectedGroupedItems)

  const addedServices: PickedService[] = []

  for (const newSelectedItem of newSelectedItems) {
    addedServices.push({
      id: newSelectedItem.id,
      name: newSelectedItem.name,
      price: String(newSelectedItem.price),
      locations: [],
    })
  }

  return addedServices
}
