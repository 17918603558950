import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  ServerBranchSetInput,
  ServerMutationRootCreateBranchArgs,
} from '../../generated/graphql-types'
import { BRANCHES_FINANCIAL_SETTINGS_QUERY_KEY, BRANCHES_QUERY_KEY } from './queryKeys'
import { SCHEDULES_QUERY_KEY } from '../schedule'
import { ACCOUNTS_QUERY_KEY } from '../account'
import { STORAGES_QUERY_KEY } from '../storage'
import { LOCATIONS_QUERY_KEY } from '../location'
import { Geocode } from '../maps'

export interface MutationRootCreateBranchArgs extends ServerMutationRootCreateBranchArgs {
  geocode?: Geocode | null
}

export function useCreateBranch() {
  return useMutation(
    async (dto: MutationRootCreateBranchArgs) => {
      return await request(
        gql`
          mutation (
            $accountName: String!
            $description: String
            $scheduleId: Int!
            $name: String!
            $storageName: String
            $timezone: String!
            $address: String
            $phone: String
            $geocode: jsonb
          ) {
            createBranch(
              accountName: $accountName
              scheduleId: $scheduleId
              timezone: $timezone
              description: $description
              name: $name
              storageName: $storageName
              address: $address
              phone: $phone
              geocode: $geocode
            ) {
              success
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([BRANCHES_QUERY_KEY])
        queryClient.invalidateQueries([STORAGES_QUERY_KEY])
        queryClient.invalidateQueries([ACCOUNTS_QUERY_KEY])
        queryClient.invalidateQueries([LOCATIONS_QUERY_KEY])
        queryClient.invalidateQueries([BRANCHES_FINANCIAL_SETTINGS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateBranchById() {
  return useMutation(
    async (dto: {
      id: number
      branchSetInput: ServerBranchSetInput
    }): Promise<{ updateBranchById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $branchSetInput: branch_set_input!) {
            updateBranchById(pk_columns: { id: $id }, _set: $branchSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([BRANCHES_QUERY_KEY])
        queryClient.invalidateQueries([SCHEDULES_QUERY_KEY])
        queryClient.invalidateQueries([BRANCHES_FINANCIAL_SETTINGS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateBranchDefaults() {
  return useMutation(
    async (dto: {
      branchId: number
      branchSetInput: Pick<
        ServerBranchSetInput,
        'defaultAccountId' | 'defaultStorageId' | 'locationId'
      >
    }): Promise<{ updateBranchById: { id: number } }> => {
      return request(
        gql`
          mutation ($branchId: Int!, $branchSetInput: branch_set_input!) {
            updateBranchById(pk_columns: { id: $branchId }, _set: $branchSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([BRANCHES_QUERY_KEY])
        queryClient.invalidateQueries([ACCOUNTS_QUERY_KEY])
        queryClient.invalidateQueries([STORAGES_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
