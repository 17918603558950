import { Switch, TableCell, TableRow } from '@expane/ui'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { ModuleSettingsControl, ModulesSettingsRowProps } from './logic'

export const ModulesSettingsRow: FC<ModulesSettingsRowProps & ModuleSettingsControl> = ({
  moduleName,
  moduleDescr,
  name,
  control,
}) => (
  <TableRow>
    <TableCell customContainer>
      <p>{moduleName}</p>
    </TableCell>
    <TableCell customContainer>
      <p>{moduleDescr}</p>
    </TableCell>

    <TableCell>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => <Switch checked={value} onChange={onChange} />}
      />
    </TableCell>
  </TableRow>
)
