import { ServerClientNoteType } from '@expane/data'
import { transformPersonName } from '@expane/logic/utils'
import { Tag } from '@expane/ui'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/sentry'
import { Avatar } from 'ui/Avatar'

interface CommentLabelProps {
  comment: ServerClientNoteType
  border?: boolean
}

export const CommentLabel: FC<CommentLabelProps> = ({ comment, border = false }) => {
  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()

  return (
    <div
      className={`flex items-center pb-1 ${border ? 'border-b border-input-disabled-color' : ''}`}
    >
      <Avatar
        url={comment.employee.photo ?? ''}
        name={comment.employee.firstName ?? ''}
        className="mr-2"
        type="small"
        reportErrorHandlerFunction={reportError}
      />

      <span className="text-xs text-label-color">
        {transformPersonName(comment.employee)}
        {comment.employee.archived ? (
          <Tag name={t('archival')} color="red-500" className="ml-1" />
        ) : (
          ''
        )}
      </span>
      <div className="ml-auto">
        {comment.edited && <span className="text-[10px] mr-1 text-gray-400">{t('changed')}</span>}
        <span className="ml-auto text-xs text-label-color">
          {transformDate(dateFormatting('dateTime', comment.createdAt))}
        </span>
      </div>
    </div>
  )
}

const transformDate = (date: string) => date.slice(0, 6) + ' | ' + date.slice(6, date.length)
