import { FC } from 'react'
import {
  getAdditionalInfoByTransactionType,
  transformTransactionType,
} from '@expane/logic/transaction'
import { transformPersonName } from '@expane/logic/utils'
import { ServerTransactionByIdType } from '@expane/data'
import { useTranslation } from 'react-i18next'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'

export const TransactionMainInfoDialog: FC<{ transactionById: ServerTransactionByIdType }> = ({
  transactionById,
}) => {
  const { t } = useTranslation()

  const dateFormatting = useDateFormatting()
  const createdData = dateFormatting('historyDateTime', transactionById?.date)

  const type = t(transformTransactionType(transactionById?.type))

  return (
    <div className="flex">
      <div className="mb-3 mr-3">
        <p className={labelStyle}>{`${t('dateTitle')}:`}</p>
        <p className={labelStyle}>{`${t('type')}:`}</p>
        <p className={labelStyle}>{`${t('additionalInfo.short')}:`} </p>
        {transactionById.fromAccount && (
          <p className={labelStyle}>{`${t('transaction.accountSender')}:`}</p>
        )}
        {transactionById.toAccount && (
          <p className={labelStyle}>{`${t('transaction.accountReceiver')}:`}</p>
        )}
        {transactionById.author && <p className={labelStyle}>{`${t('created')}:`}</p>}
        {transactionById.client && <p className={labelStyle}>{`${t('client.name')}:`}</p>}
        {transactionById.employee && <p className={labelStyle}>{`${t('employee.name')}:`}</p>}
        {transactionById.revenueReason?.name && (
          <p className={labelStyle}>{`${t('transaction.revenueReason')}:`}</p>
        )}
        {transactionById.expensesReason?.name && (
          <p className={labelStyle}>{`${t('transaction.expenseReason')}:`}</p>
        )}
        {transactionById.comment ? <p className={labelStyle}>{`${t('comment.title')}:`}</p> : null}
      </div>

      <div>
        <p className={textStyle}>{createdData}</p>
        <p className={textStyle}>{type}</p>
        <p className={textStyle}>{getAdditionalInfoByTransactionType(transactionById, t)}</p>
        {transactionById.fromAccount && (
          <p className={textStyle}>{transactionById.fromAccount.name}</p>
        )}
        {transactionById.toAccount && <p className={textStyle}>{transactionById.toAccount.name}</p>}
        {transactionById.author && (
          <p className={textStyle}>{transformPersonName(transactionById.author)}</p>
        )}
        {transactionById.client && (
          <p className={textStyle}>{transformPersonName(transactionById.client)}</p>
        )}
        {transactionById.employee && (
          <p className={textStyle}>{transformPersonName(transactionById.employee)}</p>
        )}
        {transactionById.revenueReason?.name && (
          <p className={textStyle}>{transactionById.revenueReason?.name}</p>
        )}
        {transactionById.expensesReason?.name && (
          <p className={textStyle}>{transactionById.expensesReason?.name}</p>
        )}
        {transactionById.comment ? <p className={textStyle}>{transactionById.comment}</p> : null}
      </div>
    </div>
  )
}

const labelStyle = 'mr-1 text-gray-400 text-sm mb-1'
const textStyle = 'text-main-color font-medium text-sm mb-1'
