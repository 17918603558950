import {
  ServerBookingClientSettingsBriefType,
  ServerBusinessByIdType,
  ServerGcfPromoCode,
} from '@expane/data'
import { getTimeFromDate } from '@expane/date'

export interface MainInfoFormValues {
  businessName: string
  ownerFirstName: string
  ownerLastName: string
  ownerPhone: string
  photo?: string
  timezoneId: number
  planId: number
  currencyId: number
  branchPhone: string
  branchAddress: string
  promoCode?: ServerGcfPromoCode
}

export const checkBusinessIsNotPaid = (
  currentBusiness: ServerBusinessByIdType | undefined,
): boolean => {
  if (!currentBusiness) return true

  return Boolean(currentBusiness.onHold)
}

export function checkBusinessIsOpen(
  startDate: Date,
  duration: number,
  dayStart: number,
  dayEnd: number,
): boolean {
  const startHour = getTimeFromDate(startDate)
  const endHour = startHour + duration / 60

  if (startHour < dayStart) return false
  if (endHour > dayEnd) return false

  return true
}

// если хотя бы одно из значений true, то клиентам разрешено манипулировать с записями в моб приложении
export const isClientPermissionUseMobAppForBooking = (
  bookingClientSettings: ServerBookingClientSettingsBriefType,
) => {
  const { allowedToCreateWithoutFunds, allowedToCreateWithFunds, allowedToCreateWithSubscription } =
    bookingClientSettings

  return allowedToCreateWithoutFunds || allowedToCreateWithFunds || allowedToCreateWithSubscription
}

export const generateLinkForBookingWidget = (businessId: number, isDev: boolean) => {
  const subdomain = isDev ? 'booking-dev' : 'booking'

  return `https://${subdomain}.expane.pro/?x=${businessId}`
}
