import { sortItemsByAlphabet } from '@expane/logic/utils'
import { autoUpdate, flip, useFloating } from '@floating-ui/react-dom'
import { KeyboardEvent, ReactElement, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import { IoChevronDown, IoClose, IoFilter } from 'react-icons/io5'
import { useHandleClickOutside, usePopupOpenState, useRefCollection } from '../../hooks'
import {
  commonSelectClearIconStyle,
  commonSelectIconStyle,
  getCommonSelectButtonStyle,
  modalsHTMLElement,
} from '../../logic'
import { FadeInTranslateTop } from '../animations'
import { ErrorMessage, ErrorMessageType } from '../ErrorMessage'
import { Hint } from '../Hint'
import { Pencil } from '../Icons'
import { InputLabel } from '../InputLabel'
import { TagType } from '../Tag'
import { filterBySearchValue, formatPhoneNumberForSelect } from './logic'
import { DropdownItem } from './SelectDropdownItem'

export interface SelectDropDownItem {
  id: number
  name: string
  Icon?: IconType
  phoneItem?: string
  hint?: string
  disabled?: boolean
  warningMessage?: string
  inactiveReason?: string
  tag?: TagType
}

export interface SelectDropdownProps<T extends SelectDropDownItem> {
  items?: T[]
  onSelectChange: (value: number | null) => void
  value?: number | null
  placeholder?: string
  label?: string
  hint?: string
  className?: string
  errorMessage?: ErrorMessageType
  required?: boolean
  disabled?: boolean
  // Використовується там де прокидуються тільки знайдені items(пошук клієнтів в інших філіях)
  customSearchPlaceholder?: string
  noDataMessage?: string
  isClearable?: boolean
  customClearFunction?: () => void
  // размер выпадающего списка
  popupHeight?: 'small' | 'big'
  // высота самого ипута
  height?: 'small' | 'normal'
  // для storybook
  customModal?: HTMLElement | null
  noSearch?: boolean
  onEditClick?: (id: number) => void
  onPlusClick?: () => void
  isFilter?: boolean
  customSearchFilterFunction?: (items: T[] | undefined, searchValue: string) => T[]
  customInactiveReasonRender?: (item: T) => ReactElement | null
  onSearchValueChange?: (value: string) => void
}

export const SelectDropdown = <T extends SelectDropDownItem>(props: SelectDropdownProps<T>) => {
  const {
    items = [],
    onSelectChange,
    value,
    placeholder,
    className,
    label,
    hint,
    errorMessage,
    required,
    disabled = false,
    customSearchPlaceholder,
    noDataMessage,
    isClearable,
    popupHeight = 'small',
    customModal,
    noSearch = false,
    customClearFunction,
    onEditClick,
    onPlusClick,
    height = 'normal',
    isFilter = false,
    customSearchFilterFunction,
    customInactiveReasonRender,
    onSearchValueChange,
  } = props

  const selectedItem = items.find(el => el.id === value) ?? null

  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const [searchValue, setSearchValue] = useState<string>('')
  const handleChangeSearchValue = (value: string) => {
    onSearchValueChange?.(value)
    setSearchValue(value)
  }
  const [activeLink, setActiveLink] = useState<number>(
    selectedItem ? items.findIndex(el => el.id === value) : 0,
  )

  const { t } = useTranslation()

  const itemsWithNames = items.filter(item => item.name !== '-')
  const sortedItemsWithNames = sortItemsByAlphabet(itemsWithNames) ?? []

  const filteredData = customSearchFilterFunction
    ? customSearchFilterFunction(sortedItemsWithNames, searchValue)
    : filterBySearchValue(sortedItemsWithNames, searchValue)

  const buttonRef = useRef<HTMLDivElement | null>(null)
  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const [filterRef, setFilterRef] = useState<HTMLDivElement | null>(null)
  const { refs, addRef, removeRef } = useRefCollection<HTMLElement>()

  useHandleClickOutside(
    [buttonRef.current, filterRef, ...refs],
    () => {
      closePopup()
      handleChangeSearchValue('')
    },
    isOpen,
  )

  const { reference, floating, strategy, x, y } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [flip()],
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  useLayoutEffect(() => {
    reference(buttonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, buttonRef.current])
  useLayoutEffect(() => {
    floating(filterRef)
  }, [filterRef, floating])

  const handlePopUp = () => {
    if (isOpen) {
      closePopup()
    } else {
      openPopup()
      setActiveLink(0)
      searchInputRef.current?.focus()
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab') closePopup()
    if (e.key === 'ArrowDown')
      setActiveLink(activeLink === filteredData.length - 1 ? 0 : activeLink + 1)
    if (e.key === 'ArrowUp')
      setActiveLink(activeLink === 0 ? filteredData.length - 1 : activeLink - 1)
    if (e.key === 'Enter') {
      const selectedDropDownItem = filteredData.find((el, index) => activeLink === index)
      if (!selectedDropDownItem) return
      const inactiveReason =
        customInactiveReasonRender?.(selectedDropDownItem) ??
        selectedDropDownItem.inactiveReason ??
        null

      if (!inactiveReason) handleSelectedItem(selectedDropDownItem)
    }
    if (e.key === 'Escape') {
      closePopup()
      handleChangeSearchValue('')
      searchInputRef.current?.blur()
    }

    e.stopPropagation()
  }

  const handleSelectedItem = (item: SelectDropDownItem) => {
    if (item.id !== value) onSelectChange(item.id)
    closePopup()
    handleChangeSearchValue('')
    searchInputRef.current?.blur()
  }

  const filterBtnStyle = getCommonSelectButtonStyle({
    height,
    isDisabled: disabled,
    isOpen,
    isError: Boolean(errorMessage?.isShown),
  })

  let valueStyle = 'flex items-center justify-between w-full -ml-1'
  if (selectedItem?.name) {
    valueStyle += ' text-main-color'
  } else {
    valueStyle += ' text-placeholder-color'
  }

  let popupContainerStyle = 'overflow-x-hidden overflow-y-auto p-1 text-sm text-label-color '
  popupContainerStyle += popupHeight === 'small' ? 'max-h-45' : 'max-h-90'

  return (
    <div className={'truncate ' + className}>
      <div>
        <InputLabel label={label} hint={hint} required={required} onPlusClick={onPlusClick} />

        <div
          ref={buttonRef}
          className={filterBtnStyle}
          onClick={handlePopUp}
          onKeyDown={handleKeyDown}
          // нужен фокус на div чтобы срабатывал onKeyDown
          tabIndex={!disabled && noSearch ? 0 : undefined}
        >
          <div className="flex items-center w-full overflow-hidden pl-1">
            <div className="flex w-full overflow-hidden">
              <input
                ref={searchInputRef}
                className={
                  (!searchValue ? ' w-1' : ' w-full') + ' bg-transparent text-main-color shrink'
                }
                value={searchValue}
                onChange={e => {
                  handleChangeSearchValue(e.target.value)
                }}
                onFocus={e => {
                  if (!disabled) openPopup()
                  e.target.select()
                }}
                disabled={noSearch || disabled}
              />

              {!searchValue && (
                <div className={valueStyle}>
                  <div className="flex items-center overflow-hidden shrink-0">
                    {selectedItem?.Icon && <selectedItem.Icon size="0.9rem" className="mr-1" />}
                    <div className="truncate">
                      {selectedItem?.name ?? (!disabled && placeholder)}
                    </div>
                    {selectedItem?.warningMessage && (
                      <Hint type="warning">{selectedItem.warningMessage}</Hint>
                    )}
                  </div>
                  {selectedItem?.phoneItem && (
                    <div className="ml-2 whitespace-nowrap">
                      {formatPhoneNumberForSelect(selectedItem.phoneItem)}
                    </div>
                  )}
                </div>
              )}
            </div>
            {!searchValue && value && onEditClick && (
              <button
                disabled={disabled}
                className="ml-2 text-primary-300 dark:text-primary-500 hover:text-primary-600 hover:dark:text-primary-700 pointer-events-auto"
                onClick={e => {
                  e.stopPropagation()
                  onEditClick(value)
                }}
              >
                <Pencil size="1.1rem" />
              </button>
            )}
          </div>

          {selectedItem && isClearable ? (
            <div
              className={commonSelectClearIconStyle}
              onClick={e => {
                e.stopPropagation()
                if (customClearFunction) {
                  customClearFunction()
                } else {
                  onSelectChange(null)
                }
              }}
            >
              <IoClose size="1.2rem" />
            </div>
          ) : (
            <div className={commonSelectIconStyle}>
              {isFilter ? <IoFilter size="1.2rem" /> : <IoChevronDown size="1.2rem" />}
            </div>
          )}
        </div>

        <ErrorMessage errorMessage={errorMessage} />

        {isOpen &&
          createPortal(
            <div
              ref={setFilterRef}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                minWidth: buttonRef.current?.offsetWidth,
              }}
              className="pt-1"
            >
              <FadeInTranslateTop
                className="border-2 border-primary-400 rounded-md box-border bg-block"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="items-list"
                style={{ minWidth: buttonRef.current?.offsetWidth }}
              >
                <ul className={popupContainerStyle}>
                  {filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                      <DropdownItem
                        key={item.id}
                        isSelected={item.id === selectedItem?.id}
                        isActive={index === activeLink}
                        onMouseEnter={() => {
                          if (index !== activeLink) setActiveLink(index)
                        }}
                        item={item}
                        onClick={() => {
                          handleSelectedItem(item)
                          closePopup()
                        }}
                        customInactiveReasonRender={customInactiveReasonRender}
                        customModal={customModal}
                        addRef={addRef}
                        removeRef={removeRef}
                      />
                    ))
                  ) : (
                    <li className="text-center flex items-left px-3.5 py-2 cursor-pointer">
                      {customSearchPlaceholder
                        ? customSearchPlaceholder
                        : items.length === 0
                        ? t('noItems')
                        : noDataMessage ?? t('nothingFoundForRequest')}
                    </li>
                  )}
                </ul>
              </FadeInTranslateTop>
            </div>,
            customModal ?? modalsHTMLElement,
          )}
      </div>
    </div>
  )
}

export * from './logic'
export * from './SelectDropdownItem'
