import { forwardRef, HTMLProps } from 'react'

type Props = { className?: string } & HTMLProps<HTMLDivElement>

export const Paper = forwardRef<HTMLDivElement, Props>(
  ({ className = '', children, ...props }, ref) => {
    return (
      <div
        className={'bg-block border border-block-color rounded-lg ' + className}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  },
)
