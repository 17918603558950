import {
  BranchToSwitchAsClientType,
  ScheduleDtoWithBranchIdAndEmployee,
  ServerBusinessToSwitch,
  useFetchBranches,
} from '@expane/data'

export type PropsWithBranchId<P = unknown> = P & { branchId: number | undefined }

export const useCheckIsOneBranchInBusiness = () => {
  const { data: branches, isLoading } = useFetchBranches()

  const isOneBranchInBusiness = branches ? branches.length === 1 : false

  return { isOneBranchInBusiness, isLoading }
}

export const checkIsBranchSchedule = (schedule: ScheduleDtoWithBranchIdAndEmployee | undefined) =>
  Boolean(schedule?.branch.length)

export const getBranchOrBusinessNameForSelect = (
  branchName: string,
  businessName: string,
  address?: string | null,
) => {
  const businessWithBranchName =
    businessName.includes(branchName) || branchName.includes(businessName)
      ? branchName
      : `${businessName}, ${branchName}`

  return address ? `${businessWithBranchName}, ${address}` : businessWithBranchName
}

export const getBranchOrBusinessNameToSwitchAsClient = (
  branch: BranchToSwitchAsClientType,
  businesses: ServerBusinessToSwitch[],
) => {
  const businessName = businesses.find(business => business.id === branch.businessId)?.name

  return getBranchOrBusinessNameForSelect(branch.name, businessName ?? '', branch?.address)
}
