import { IntervalSchedule as IntervalScheduleType } from '@expane/data'
import { checkOnlyPositiveAmount } from '@expane/logic/form'
import {
  defaultScheduleTemplateWorkingHoursForDay,
  SCHEDULE_RANGE_MAX,
  SCHEDULE_RANGE_MIN,
  SCHEDULE_RANGE_STEP,
  ScheduleTemplateFormData,
} from '@expane/logic/schedule'
import { Input } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Range } from 'ui/Range'

interface Props {
  containerClassName?: string
  schedule?: IntervalScheduleType
  control: Control<ScheduleTemplateFormData>
  disabled?: boolean
}

export const IntervalSchedule: FC<Props> = ({
  control,
  containerClassName,
  schedule,
  disabled = false,
}) => {
  const { t } = useTranslation()

  return (
    <div className={'w-full pl-6 ' + (containerClassName ?? '')}>
      <div className="flex items-center">
        <p className="w-2/6 text-sm font-medium text-gray-500">{t('schedule.workings/weekends')}</p>

        <div className="w-4/6 flex items-center justify-start">
          <Controller
            name="workDays"
            control={control}
            defaultValue={schedule?.data.workDays.toString() ?? '1'}
            rules={{ required: true, validate: checkOnlyPositiveAmount }}
            render={({ field, fieldState: { error } }) => (
              <Input
                placeholder="1"
                containerClassName="w-16"
                {...field}
                disabled={disabled}
                errorMessage={{
                  isShown: Boolean(error),
                  text: t('formError.invalidValue'),
                  reserveIndent: false,
                }}
              />
            )}
          />
          <p className="w-16 text-xs text-primary-600 font-medium text-center">
            {t('through').toLocaleLowerCase()}
          </p>

          <Controller
            name="breakDays"
            control={control}
            defaultValue={schedule?.data.breakDays.toString() ?? '1'}
            rules={{ required: true, validate: checkOnlyPositiveAmount }}
            render={({ field, fieldState: { error } }) => (
              <Input
                placeholder="1"
                containerClassName="w-16"
                {...field}
                disabled={disabled}
                errorMessage={{
                  isShown: Boolean(error),
                  text: t('formError.invalidValue'),
                  reserveIndent: false,
                }}
              />
            )}
          />
        </div>
      </div>

      <div className="flex items-center mt-6">
        <p className="w-2/6 text-sm font-medium text-gray-500">{t('timeTitle')}</p>

        <Controller
          name="timing"
          control={control}
          defaultValue={
            (schedule?.data.timing ?? defaultScheduleTemplateWorkingHoursForDay) as [number, number]
          }
          render={({ field: { onChange, value } }) => (
            <Range
              className="w-4/6 pr-4"
              markType="time"
              min={SCHEDULE_RANGE_MIN}
              max={SCHEDULE_RANGE_MAX}
              step={SCHEDULE_RANGE_STEP}
              values={value}
              onChange={onChange}
              labeled
              markSeparators
              placeholderValues={defaultScheduleTemplateWorkingHoursForDay}
              disabled={disabled}
            />
          )}
        />
      </div>
    </div>
  )
}
