import {
  DayTiming,
  EmployeeGroupWithSchedule,
  useFetchBranchById,
  useFetchEmployeesGroups,
  useMutateDynamicDates,
  WeeklySchedule,
} from '@expane/data'
import { getWeekDayNumber } from '@expane/date'
import { getCurrentEmployeeSchedule } from '@expane/logic/employeeSchedule'
import { SCHEDULE_RANGE_MAX, SCHEDULE_RANGE_MIN, SCHEDULE_RANGE_STEP } from '@expane/logic/schedule'
import { CloseButton, Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoPersonAddSharp } from 'react-icons/io5'
import { store } from 'store'
import { Range } from 'ui/Range'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import { convertEmployeeGroupsToTreeItems } from 'ui/TreeMenu/logic.employee'
import { TreeSelect } from 'ui/TreeSelect'
import { SaveButton } from 'widgets/Buttons'

interface AddWorkingDayToEmployeesDialogProps {
  closeDialog: () => void
  date: Date
}

export const useOpenAddWorkingDayToEmployeesDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogDate = useRef<Date>()

  const openAddWorkingDayToEmployeesDialog = ({ date }: { date: Date; onClose?: () => void }) => {
    dialogDate.current = date

    openPopup()
  }

  const addWorkingDayToEmployeesDialog =
    isOpen && dialogDate.current ? (
      <AddWorkingDayToEmployeesDialog closeDialog={closePopup} date={dialogDate.current} />
    ) : null

  return {
    openAddWorkingDayToEmployeesDialog,
    addWorkingDayToEmployeesDialog,
  }
}

interface AddWorkingDayToEmployeesForm {
  employees: TreeMenuItem[]
  time: DayTiming
}

const AddWorkingDayToEmployeesDialog: FC<AddWorkingDayToEmployeesDialogProps> = observer(
  ({ closeDialog, date }) => {
    const branchId = store.branch.branchId
    const { data: branch } = useFetchBranchById(branchId)
    const { data: employeeGroups } = useFetchEmployeesGroups(branch?.timezone, branchId)
    const branchSchedule = branch?.schedule

    if (!branchSchedule || !employeeGroups) return null

    return (
      <AddWorkingDayToEmployeesDialogLogic
        employeeGroups={employeeGroups}
        branchSchedule={branchSchedule}
        date={date}
        closeDialog={closeDialog}
      />
    )
  },
)

interface AddEmployeeWorkingDayDialogLogicProps {
  employeeGroups: EmployeeGroupWithSchedule[]
  branchSchedule: WeeklySchedule
  date: Date
  closeDialog: () => void
}

const AddWorkingDayToEmployeesDialogLogic: FC<AddEmployeeWorkingDayDialogLogicProps> = ({
  employeeGroups,
  date,
  branchSchedule,
  closeDialog,
}) => {
  const { mutateAsync: createDynamicDates } = useMutateDynamicDates()

  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const dateFormatting = useDateFormatting()
  const formattedDate = dateFormatting('historyDate', date)

  const branchTiming = branchSchedule.data[getWeekDayNumber(date)]

  const employeeGroupsWithDynamicScheduleEmployees = filterEmployeeGroupsByDynamicScheduleEmployees(
    employeeGroups,
    date,
  )

  const employeeTreeMenuItems = convertEmployeeGroupsToTreeItems(
    employeeGroupsWithDynamicScheduleEmployees,
  )

  const { control, handleSubmit } = useForm<AddWorkingDayToEmployeesForm>({
    defaultValues: {
      time: branchTiming ?? [SCHEDULE_RANGE_MIN, SCHEDULE_RANGE_MAX],
    },
  })

  const handleAddingDynamicDates: SubmitHandler<AddWorkingDayToEmployeesForm> = async ({
    employees,
    time,
  }) => {
    const extractedEmployeesScheduleIds = getSelectedEmployeesScheduleIds(
      employeeGroups,
      employees,
      date,
    )

    const result = await createDynamicDates({
      dynamicScheduleDates: extractedEmployeesScheduleIds.map(employeeScheduleId => ({
        timingStart: time[0],
        timingEnd: time[1],
        date,
        generatedId: undefined,
        employeeScheduleId,
      })),
      // мы только добавляем даты
      dynamicDateIdsToDelete: [],
    })

    if (result?.insertDynamicScheduleDates?.affected_rows) {
      openSnackbar(t('schedule.successfullyReassigned'), 'success')
      closeDialog()
    } else openSnackbar(t('submitError'), 'error')
  }

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('schedule.addDay')}</Dialog.Title>

        <Dialog.Body className="w-144">
          {branchTiming === null ? (
            <p>{t('branchIsClosed')}</p>
          ) : (
            <>
              <Controller
                control={control}
                name="employees"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TreeSelect
                    type="MultiPickMode"
                    errorMessage={{
                      isShown: Boolean(error),
                      text: t('formError.required'),
                    }}
                    label={t('employees.withDynamicSchedule')}
                    placeholder={t('choose')}
                    items={employeeTreeMenuItems}
                    onSelected={onChange}
                    selected={value as TreeMenuItem[]}
                    required
                  />
                )}
              />

              <div className="flex mb-1">
                <p className="mr-1 text-secondary-color text-sm font-medium">{`${t(
                  'dateTitle',
                )}:`}</p>
                <p className="text-main-color text-sm font-medium">{formattedDate}</p>
              </div>

              <Controller
                name="time"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Range
                    className="w-full mb-4"
                    markType="time"
                    min={SCHEDULE_RANGE_MIN}
                    max={SCHEDULE_RANGE_MAX}
                    step={SCHEDULE_RANGE_STEP}
                    values={value}
                    onChange={onChange}
                    markSeparators
                    placeholderValues={[SCHEDULE_RANGE_MIN, SCHEDULE_RANGE_MAX]}
                  />
                )}
              />
            </>
          )}
        </Dialog.Body>

        <Dialog.Footer>
          {branchTiming !== null && <SaveButton onClick={handleSubmit(handleAddingDynamicDates)} />}
          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

interface AddWorkingDayToEmployeesButtonProps {
  onClick: () => void
  className?: string
}

export const AddWorkingDayToEmployeesButton: FC<AddWorkingDayToEmployeesButtonProps> = ({
  onClick,
  className,
}) => {
  return (
    <button className={buttonClassName + className} onClick={onClick}>
      <IoPersonAddSharp size="1rem" />
    </button>
  )
}

const filterEmployeeGroupsByDynamicScheduleEmployees = (
  employeeGroups: EmployeeGroupWithSchedule[],
  date: Date,
) => {
  const filteredEmployeeGroups: EmployeeGroupWithSchedule[] = []

  for (const group of employeeGroups) {
    filteredEmployeeGroups.push({
      ...group,
      employees: group.employees.filter(employee => {
        const currentEmployeeSchedule = getCurrentEmployeeSchedule(employee.employeeSchedules, date)
        if (!currentEmployeeSchedule) return false

        return currentEmployeeSchedule.isDynamic
      }),
    })
  }

  return (
    // если в группе нет сотрудников с динамическим графиков, то группу не возращаем
    filteredEmployeeGroups.filter(group => group.employees.length > 0)
  )
}

const getSelectedEmployeesScheduleIds = (
  employeeGroups: EmployeeGroupWithSchedule[],
  employees: TreeMenuItem[],
  date: Date,
) => {
  const extractedEmployeesIds = extractItemsFromFolders(employees).map(({ id }) => id)

  const employeeScheduleIds: Array<number> = []

  for (const group of employeeGroups) {
    for (const id of extractedEmployeesIds) {
      const employee = group.employees.find(employee => employee.id === id)

      if (employee) {
        const currentEmployeeSchedule = getCurrentEmployeeSchedule(employee.employeeSchedules, date)
        if (currentEmployeeSchedule) employeeScheduleIds.push(currentEmployeeSchedule.id)
      }
    }
  }

  return employeeScheduleIds
}

const buttonClassName =
  'h-7 w-7 rounded-full flex-centered border-2 border-primary-500 text-primary-500 hover:border-primary-700 hover:text-primary-700 transition-colors '
