import {
  SALARY_ISSUE_TYPES,
  useCreateSalaryIssue,
  useFetchCurrentBranchTimezone,
  useFetchExtendedEmployees,
} from '@expane/data'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { checkOnlyPositiveAmount } from '@expane/logic/form'
import { getEmployeesWithSalarySettings } from '@expane/logic/salaryIssues/filters'
import {
  CloseButton,
  Dialog,
  Input,
  Modal,
  SelectDropdown,
  Textarea,
  usePopupOpenState,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { transformPersonsForSelect } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { SubmitButton } from 'widgets/Buttons'

export type TypeOfPremiumDialog = 'premium' | 'penalty'

interface PremiumDialogProps {
  closeDialog: () => void
  type: TypeOfPremiumDialog
  defaultEmployeeId?: number
}

const PremiumDialog: FC<PremiumDialogProps> = observer(
  ({ closeDialog, type, defaultEmployeeId }) => {
    const { t } = useTranslation()

    const { formState, control, handleSubmit } = useForm<PremiumDialogFormValues>()

    const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
    const [openSnackbar] = useSnackbar()

    const branchId = store.branch.branchId

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { data: employees, isLoading } = useFetchExtendedEmployees(timezone, branchId)

    const employeesWithSalarySettings = getEmployeesWithSalarySettings(employees)

    const { mutateAsync: createSalaryIssue } = useCreateSalaryIssue()

    const createPremium: SubmitHandler<PremiumDialogFormValues> = async data => {
      if (!branchId) {
        openSnackbar(t('submitError'), 'error')
        closeDialog()
        return
      }

      try {
        await createSalaryIssue({
          type: type === 'premium' ? SALARY_ISSUE_TYPES.premium : SALARY_ISSUE_TYPES.penalty,
          value: Number(data.amount),
          employeeId: data.employeeId,
          comment: data.comment,
          branchId,
        })
        openSnackbar(
          type === 'premium' ? t('salary.premiumIsAccrued') : t('salary.penaltyIsAccrued'),
          'success',
        )
      } catch (error) {
        if (isRestrictionError(error)) openSnackbar(t('planRestriction'), 'error')
        else if (isPlanError(error)) openSnackbar(t('planInfo.noPlan'), 'error')
        else openSnackbar(t('submitError'), 'error')
      }
      closeDialog()
    }

    // TODO: add loading
    if (isLoading) return null

    return (
      <>
        <Modal
          close={closePopups}
          confirm={() => {
            if (!formState.isSubmitting && formState.isDirty) handleSubmit(createPremium)()
          }}
        >
          <Dialog>
            <Dialog.Title>
              {type === 'premium' ? t('transactionNames.premium') : t('transactionNames.penalty')}
            </Dialog.Title>
            <Dialog.Body>
              <Controller
                control={control}
                name="employeeId"
                // defaultEmployeeId есть только тогда когда мы открываем диалог из страницы зарплат
                // где только сотрудники с зарплатами
                defaultValue={defaultEmployeeId ?? employeesWithSalarySettings?.[0].id}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  <SelectDropdown
                    label={t('employee.name')}
                    value={value}
                    onSelectChange={onChange}
                    items={transformPersonsForSelect(employeesWithSalarySettings ?? [])}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.employeeId),
                      text: t('formError.required'),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="amount"
                defaultValue=""
                rules={{ required: true, validate: checkOnlyPositiveAmount }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="number"
                    label={t('amount')}
                    value={value}
                    onChange={onChange}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.amount),
                      text:
                        formState.errors?.amount?.type === 'required'
                          ? t('formError.required')
                          : t('formError.invalidValue'),
                    }}
                    autoFocus
                  />
                )}
              />
              <Controller
                control={control}
                name="comment"
                render={({ field: { value, onChange } }) => (
                  <Textarea
                    label={t('comment.title')}
                    placeholder={t('placeholders.premiumDescription')}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Dialog.Body>
            <Dialog.Footer>
              <SubmitButton
                onClick={handleSubmit(createPremium)}
                disabled={formState.isSubmitting || !formState.isDirty}
                spinner={formState.isSubmitting}
              />
              <CloseButton onClick={closePopups} />
            </Dialog.Footer>
          </Dialog>
        </Modal>
        {confirmPopup}
      </>
    )
  },
)

export const useOpenPremiumDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const defaultEmployeeId = useRef<number | undefined>(undefined)
  const dialogType = useRef<TypeOfPremiumDialog>()

  const openPremiumDialog = ({
    type,
    employeeId,
  }: {
    type: TypeOfPremiumDialog
    employeeId?: number
  }) => {
    dialogType.current = type
    defaultEmployeeId.current = employeeId
    openPopup()
  }

  const premiumDialog =
    isOpen && dialogType.current ? (
      <PremiumDialog
        type={dialogType.current}
        defaultEmployeeId={defaultEmployeeId.current}
        closeDialog={closePopup}
      />
    ) : null

  return { openPremiumDialog, premiumDialog }
}

interface PremiumDialogFormValues {
  employeeId: number
  amount: string
  comment?: string
}
