import { FC } from 'react'
import { WeeklyScheduleByDay } from 'widgets/ScheduleDialog/WeeklySchedule/WeeklyScheduleByDay'
import { QuickFixTimeInput } from 'widgets/ScheduleDialog/QuickFixTimeInput'
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { WeeklySchedule as WeeklyScheduleType } from '@expane/data'
import {
  defaultScheduleTemplateWorkingHoursForWeek,
  getWeekDayName,
  ScheduleTemplateFormData,
} from '@expane/logic/schedule'

interface Props {
  containerClassName?: string
  schedule?: WeeklyScheduleType
  branchSchedule: WeeklyScheduleType
  control: Control<ScheduleTemplateFormData>
  setValue: UseFormSetValue<ScheduleTemplateFormData>
  getValues: UseFormGetValues<ScheduleTemplateFormData>
  disabled?: boolean
  timezone: string | undefined
}

export const WeeklySchedule: FC<Props> = ({
  control,
  containerClassName,
  schedule,
  branchSchedule,
  setValue,
  getValues,
  disabled = false,
  timezone,
}) => {
  const { t } = useTranslation()

  const scheduleData = schedule?.data?.length
    ? schedule?.data
    : defaultScheduleTemplateWorkingHoursForWeek

  return (
    <div className={'w-full flex flex-col justify-between pr-6 ' + (containerClassName ?? '')}>
      <QuickFixTimeInput
        setValue={setValue}
        getValues={getValues}
        label={t('allDays') + ':'}
        disabled={disabled}
      />

      <div className="pl-6 mt-2">
        {scheduleData.map((timing, index) => (
          <WeeklyScheduleByDay
            isBreakDay={timing === null}
            key={index}
            label={getWeekDayName(index, timezone)}
            day={index}
            defaultValues={timing}
            branchSchedule={branchSchedule}
            isThisBranchSchedule={branchSchedule.id === schedule?.id}
            control={control}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  )
}
