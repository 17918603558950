import { ServerAccountByIdType, ServerAccountType } from '@expane/data'

export const ACCOUNT_TYPES = [
  { id: 1, name: 'accountType.permanent', hint: 'accountType.permanentHint' },
  { id: 2, name: 'accountType.internal', hint: 'accountType.internalHint' },
]

export const filterAccountsWithoutChosen = (
  accounts: ServerAccountType[],
  chosenAccount: number | undefined,
): ServerAccountType[] => accounts.filter(account => account.id !== chosenAccount)

export const getCurrentAccount = (
  accounts: ServerAccountType[] | undefined,
  currentAccountId: number,
): ServerAccountType | undefined => accounts?.find(account => account.id === currentAccountId)

export const isAccountForPayments = (accountTypeId: number): boolean => {
  return accountTypeId === ACCOUNT_TYPES[0].id
}

export const getIsShowWithdrawal = (accountById: ServerAccountByIdType | undefined) =>
  accountById ? !Boolean(accountById.accountSoftwarePOS.length) : true
