import { FC } from 'react'
import { ValidatedBookingWarningType } from '@expane/logic/bookingChecks'

interface ConfirmationDescriptionProps {
  text?: ValidatedBookingWarningType[]
  question?: string
}

export const ConfirmationDescription: FC<ConfirmationDescriptionProps> = ({ text, question }) => {
  return (
    <>
      {text
        ? text.map(el => {
            return (
              <p key={el.text} className="pb-1">
                {el.text}
              </p>
            )
          })
        : null}

      {question ? <p className={text ? 'mt-3' : ''}>{question}</p> : null}
    </>
  )
}
