import { useBusinessModulesSettings } from '@expane/logic/modules'
import {
  Button,
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Modal,
  Paper,
  PlaceholderInput,
  Spinner,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCashOutline } from 'react-icons/io5'
import { PlaceholderHorizontalButtonSwitch } from 'ui/PlaceholderContent'
import { saleSections } from './SaleItemsInput'

export const QuickSaleDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  const { getFilteredItemsByModules } = useBusinessModulesSettings()
  const filteredSaleSections = getFilteredItemsByModules(saleSections)

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('quickSale')}</Dialog.Title>
        <Dialog.Body className="min-w-256 max-full">
          <div className="flex">
            <div className="grow pr-3 w-192 flex flex-col h-128">
              <div className="flex">
                <PlaceholderInput label={t('client.name')} className="mb-4 w-1/2" />
              </div>

              <Paper className="p-2 grow">
                <Spinner expandCentered />
              </Paper>
            </div>

            <div className="flex flex-col w-96 h-128">
              <PlaceholderHorizontalButtonSwitch options={filteredSaleSections} className="mb-3" />
              <div className="grow">
                <Spinner expandCentered />
              </div>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <Button disabled Icon={IoCashOutline}>
            {t('sell')}
          </Button>

          <CloseButton className="mr-auto" onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
