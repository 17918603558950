import { gql } from 'graphql-request'

export const storageFragment = gql`
  fragment storageType on storage {
    id
    name
    description
    storageEmployeeGroups {
      employeeGroup {
        id
        name
      }
    }
  }
`
