import { SUPPORTED_VERSIONS } from 'logic/hooks/useCheckBrowser'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoRefreshOutline } from 'react-icons/io5'

export const UpdateBrowserPage: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex-centered flex-col items-center h-full">
      <IoRefreshOutline className="text-primary-100" size="10rem" />
      <h1 className="text-2xl mb-6 font-medium text-primary-700">{t('updateBrowser.1')}</h1>
      <p>{t('updateBrowser.2')}</p>
      <ul>
        {SUPPORTED_VERSIONS.map(version => (
          <li key={version.name}>
            {version.name} {version.version}
          </li>
        ))}
      </ul>
    </div>
  )
}
