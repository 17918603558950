import {
  Maybe,
  ServerCheckPromoCodeResult,
  ServerGcfPromoCode,
} from '../../generated/graphql-types'

export enum CheckPromoCodeResponseCodes {
  noRequiredFields,
  noFound,
  expired,
  success,
}

export interface CheckPromoCodeResult extends ServerCheckPromoCodeResult {
  code: CheckPromoCodeResponseCodes
  promoCode: Maybe<ServerGcfPromoCode>
}

export interface BusinessPromoCodeViewType {
  businessId: number
  discount: number
  promoCodeId: string
}
