import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PermissionBlock, PermissionBlockTitle, PermissionCheckbox } from '.'
import { PermissionPageBlockProps, allSelectedPermissions } from '../logic'
import { PermissionPageRowProps } from '@expane/logic/permission/logic'

export const EmployeeBlock: FC<PermissionPageBlockProps> = ({
  control,
  checkRelation,
  checkRelationBulk,
  isItOwnerRole,
  setValue,
}) => {
  const { t } = useTranslation()

  const onSelectAll = () => {
    checkRelationBulk({
      permissions: [
        'permissions.employee.archive',
        'permissions.employee.email',
        'permissions.employee.get',
        'permissions.employee.set',
        'permissions.schedule.editPast',
        'permissions.schedule.get',
        'permissions.schedule.set',
        'permissions.timeOff.get',
        'permissions.timeOff.set',
        'permissions.timeOffReason.set',
        'permissions.notification.billing',
        'permissions.notification.get',
        'permissions.rolePermission.set',
      ],
      onConfirm: () => {
        setValue('permissions.employee', allSelectedPermissions.employee)
        setValue('permissions.schedule', allSelectedPermissions.schedule)
        setValue('permissions.timeOff', allSelectedPermissions.timeOff)
        setValue('permissions.timeOffReason', allSelectedPermissions.timeOffReason)
        setValue('permissions.notification', allSelectedPermissions.notification)
        setValue('permissions.rolePermission', allSelectedPermissions.rolePermission)
      },
    })
  }

  return (
    <>
      <PermissionBlockTitle onSelectAll={onSelectAll} title={t('employees.name')} />

      <EmployeePermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <SchedulePermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <TimeOffPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <NotificationPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <RolePermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
    </>
  )
}

const EmployeePermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('employees.name')}>
      <Controller
        name="permissions.employee.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.employee.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.employee.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.employee.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.employee.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.employee.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.employee.email"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.employee.email',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.employee.email')}
            hint={t('permissions.employee.emailHint')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.employee.archive"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.employee.archive',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.employee.archive')}
            hint={t('permissions.employee.archiveHint')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const SchedulePermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('schedule.name')}>
      <Controller
        name="permissions.schedule.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.schedule.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.schedule.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.schedule.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.schedule.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.schedule.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.schedule.editPast"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.schedule.editPast',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.schedule.editPast')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const TimeOffPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('timeOffs.name')}>
      <Controller
        name="permissions.timeOff.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.timeOff.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.timeOff.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.timeOff.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.timeOff.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.timeOff.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.timeOffReason.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.timeOffReason.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.timeOffReason.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const RolePermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('accessRoles')}>
      <Controller
        name="permissions.rolePermission.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.rolePermission.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.rolePermission.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const NotificationPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('notifications')}>
      <Controller
        name="permissions.notification.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.notification.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.notification.get')}
            hint={t('permissions.notification.getHint')}
            disabled={isItOwnerRole}
          />
        )}
      />

      <Controller
        name="permissions.notification.billing"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.notification.billing',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.notification.billing')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}
