import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { CardReportItemType } from '@expane/logic/reports/cards'
import {
  calcReportItemsCreditTotalPrice,
  calcReportItemsCreditTotalQuantity,
  calcReportItemsTotalPrice,
} from '@expane/logic/reports/logic'
import { TableCell, TableRow } from '@expane/ui'
import { FC } from 'react'

interface CardReportItemProps {
  name: string
  cardReportItems: CardReportItemType[]
}

export const CardReportItem: FC<PropsWithBranchId<CardReportItemProps>> = ({
  name,
  cardReportItems,
  branchId,
}) => {
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const creditTotalQuantity = calcReportItemsCreditTotalQuantity(cardReportItems)
  const creditTotalPrice = calcReportItemsCreditTotalPrice(cardReportItems)
  const totalPrice = calcReportItemsTotalPrice(cardReportItems)

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>
        <div className="text-right">{cardReportItems.length}</div>
      </TableCell>
      <TableCell>
        <div className="text-right">{creditTotalQuantity}</div>
      </TableCell>
      <TableCell>
        <div className="text-right">{convertNumberToMoney(totalPrice)}</div>
      </TableCell>
      <TableCell>
        <div className="text-right">{convertNumberToMoney(creditTotalPrice)}</div>
      </TableCell>
    </TableRow>
  )
}

export const cardReportItemsTitles: {
  title: string
  isAlignRight?: boolean
}[] = [
  { title: 'name' },
  { title: 'qty', isAlignRight: true },
  { title: 'reports.inLoan', isAlignRight: true },
  { title: 'amount', isAlignRight: true },
  { title: 'reports.inLoanAmount', isAlignRight: true },
]
