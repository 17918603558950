import { useFetchCurrentBranchTimezone } from '@expane/data'
import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  add,
  createCurrentDate,
} from '@expane/date'
import { TFunction, useTranslation } from 'react-i18next'
import { store } from 'store'

export const useAgeDeclension = () => {
  const { t } = useTranslation()
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const ageDeclension = (birthDate: Date) => {
    const currentDate = createCurrentDate(timezone)
    const differenceYears = differenceInYears(currentDate, birthDate)

    if (differenceYears > 0) return declensionOfYears(differenceYears, t)

    const differenceMonth = differenceInMonths(currentDate, birthDate)

    return declensionOfMonths(differenceMonth, t)
  }

  return ageDeclension
}

export const bookingCountDeclension = (count: number, t: TFunction): string => {
  const lastTwoDigits = count % 100
  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) return t('bookingCountDeclension.lot')

  const lastDigit = count % 10
  if (lastDigit === 1) return t('bookingCountDeclension.one')
  if (lastDigit >= 2 && lastDigit <= 4) return t('bookingCountDeclension.lotAnotherVariant')

  return t('bookingCountDeclension.lot')
}

export const useDeclensionOfTimeWithUs = () => {
  const { t } = useTranslation()
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const declensionOfTimeWithUs = (startDate?: Date): string | undefined => {
    if (!startDate) return undefined

    const currentDate = createCurrentDate(timezone)

    const differenceYears = differenceInYears(currentDate, startDate)

    const differenceMonth = differenceInMonths(
      currentDate,
      add(startDate, { years: differenceYears }),
    )

    const differenceDays = differenceInDays(
      currentDate,
      add(startDate, {
        years: differenceYears,
        months: differenceMonth,
      }),
    )

    // если если разница между startDate и new Date < 1 дня, возвращаем 1 день
    if (differenceYears === 0 && differenceMonth === 0 && differenceDays === 0)
      return declensionOfDays(1, t)

    return [
      declensionOfYears(differenceYears, t),
      declensionOfMonths(differenceMonth, t),
      declensionOfDays(differenceDays, t),
    ].join(' ')
  }

  return declensionOfTimeWithUs
}

const declensionOfYears = (years: number, t: TFunction): string => {
  if (years < 1) return ''
  if (years % 10 === 1) return `${years} ${t('yearDeclension.singular')}`
  if (years % 10 === 0) return `${years} ${t('yearDeclension.plural')}`
  if (years > 20 && years % 10 <= 4) return `${years} ${t('yearDeclension.pluralAlt')}`

  return `${years} ${t('yearDeclension.plural')}`
}

const declensionOfMonths = (months: number, t: TFunction): string => {
  if (months < 1) return ''
  if (months === 1) return `${months} ${t('monthDeclension.singular')}`
  if (months <= 4) return `${months} ${t('monthDeclension.pluralAlt')}`
  return `${months} ${t('monthDeclension.plural')}`
}

const declensionOfDays = (days: number, t: TFunction): string | undefined => {
  if (days < 1) return undefined
  if (days === 1) return `${days} ${t('dayDeclension.singular')}`
  if (days < 5) return `${days} ${t('dayDeclension.pluralAlt')}`
  if (days > 10 && days < 20) return `${days} ${t('dayDeclension.plural')}`
  if (days % 10 === 1) return `${days} ${t('dayDeclension.singular')}`
  if (days % 10 >= 2 && days % 10 <= 4) return `${days} ${t('dayDeclension.pluralAlt')}`

  return `${days} ${t('dayDeclension.plural')}`
}
