export const EMPLOYEE_PERMISSIONS_QUERY_KEY = 'employeePermissions'
export const EMPLOYEES_QUERY_KEY = 'employees'
export const ANONYMOUSLY_EMPLOYEES_QUERY_KEY = 'anonymouslyEmployees'
export const EMPLOYEE_BY_ID = 'employeeById'
export const EMPLOYEES_BRIEF_QUERY_KEY = 'employeesBrief'
export const ALL_EMPLOYEES_QUERY_KEY = 'allEmployees'
export const EMPLOYEES_FOR_IMPORT = 'employeesForImport'
export const EMPLOYEES_EXTENDED_QUERY_KEY = 'employeesExtended'
export const ARCHIVED_EMPLOYEES_QUERY_KEY = 'archivedEmployees'
export const EMPLOYEES_FOR_CALENDAR_QUERY_KEY = 'employeesForCalendar'
export const EMPLOYEES_COUNT_QUERY_KEY = 'employeesCount'
export const EMPLOYEES_WITH_OWNER_QUERY_KEY = 'employeesWithOwner'
