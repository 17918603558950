import { FC } from 'react'
import { ErrorMessage, Input, InputLabel } from '@expane/ui'
import { URLContainer } from 'ui/URLContainer'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { getPathInputError, LandingSettingsBlockProps } from '../logic'
import { BusinessLandingSettingsType } from '@expane/data'
import { useCheckBusinessLandingPathsAvailability } from '@expane/logic/BusinessLanding'
import { validatePath } from '@expane/logic/form'
import { getBusinessLandingURL } from '@expane/logic/links'
import { config } from 'config'

export const Path: FC<
  LandingSettingsBlockProps & { businessLandingSettings: BusinessLandingSettingsType }
> = ({ control, disabled, businessLandingSettings }) => {
  const { t } = useTranslation()

  const { checkBusinessLandingPathsAvailability } = useCheckBusinessLandingPathsAvailability()

  return (
    <div className={'mb-4'}>
      <InputLabel
        label={t('link')}
        hint={t('businessLandingSettings.linkHint')}
        className={'mb-1 w-fit'}
      />

      <Controller
        name="path"
        control={control}
        rules={{
          validate: {
            availability: async path => {
              if (businessLandingSettings.path && businessLandingSettings.path === path) return true
              if (path) return await checkBusinessLandingPathsAvailability(path)
              return true
            },
            symbols: path => (path ? validatePath(path) : true),
          },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          const url = getBusinessLandingURL(
            { id: businessLandingSettings.id, path: value },
            config.ENV,
          )

          return (
            <div>
              <div className="flex items-center gap-4">
                <Input
                  containerClassName="w-1/3"
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  height="small"
                  errorMessage={{
                    isShown: Boolean(error),
                    text: '',
                    reserveIndent: false,
                  }}
                />
                <URLContainer url={url} />
              </div>
              <ErrorMessage
                errorMessage={{
                  isShown: Boolean(error),
                  text: getPathInputError(error?.type, t),
                  reserveIndent: false,
                }}
              />
            </div>
          )
        }}
      />
    </div>
  )
}
