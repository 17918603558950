import { FC } from 'react'
import { IoArrowUndoOutline, IoCashOutline, IoCloseCircleOutline, IoRemove } from 'react-icons/io5'
import { IconType } from 'react-icons'
import { useTranslation } from 'react-i18next'
import { InformationPopup } from 'widgets/InformationPopup'

export const BookingInfoHint: FC<{ authorName: string; createdAt: string }> = ({
  authorName,
  createdAt,
}) => {
  const { t } = useTranslation()

  return (
    <InformationPopup size="1.2rem" openByClick containerClassName={'self-top'}>
      <BookingHintItem description={`${t('created')}:`} text={authorName} />
      <BookingHintItem description={`${t('dateOfCreation')}:`} text={createdAt} />
    </InformationPopup>
  )
}
const BookingHintItem: FC<{ description: string; text: string }> = ({ description, text }) => {
  return (
    <div className="flex justify-between">
      <p>{description}</p>
      <p>{text}</p>
    </div>
  )
}

export const BookingStatusIcons: FC<{
  isCanceled: boolean
  isBookingPaid: boolean
  isReturned: boolean
}> = ({ isCanceled, isBookingPaid, isReturned }) => {
  const { Icon, style, message } = useGetIcon({ isBookingPaid, isCanceled, isReturned })

  return (
    <InformationPopup CustomIcon={Icon} size="1.2rem" containerClassName={style}>
      {message}
    </InformationPopup>
  )
}

const useGetIcon = ({
  isBookingPaid,
  isCanceled,
  isReturned,
}: {
  isBookingPaid: boolean
  isCanceled: boolean
  isReturned: boolean
}): { Icon: IconType; message: string; style?: string } => {
  const { t } = useTranslation()

  if (isCanceled)
    return { Icon: IoCloseCircleOutline, message: t(BOOKING_IS_CANCELED), style: 'text-red-500' }
  if (isBookingPaid) {
    if (isReturned) return { Icon: IoArrowUndoOutline, message: t(BOOKING_IS_RETURNED) }
    return { Icon: IoCashOutline, message: t(BOOKING_IS_PAID), style: 'text-primary-500' }
  }

  return { Icon: IoRemove, message: t(BOOKING_IS_NOT_PAID) }
}

const BOOKING_IS_PAID = 'paid'
const BOOKING_IS_RETURNED = 'returned'
const BOOKING_IS_CANCELED = 'canceledStatus'
const BOOKING_IS_NOT_PAID = 'notPaid'
