import {
  CloudFunctionResponseCodes,
  CloudFunctionResult,
  ServerSoftwarePosType,
  UpdatePOSSetInput,
  useCreatePOS,
  useUpdatePOS,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import {
  CloseButton,
  Dialog,
  Input,
  Modal,
  SelectDropDownItem,
  useShowConfirmationPopup,
} from '@expane/ui'
import { OpenSnackbarFunc, useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { TFunction } from 'i18next'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MultiSelect } from 'ui/MultiSelect'
import { SaveButton } from 'widgets/Buttons'
import { getSoftwarePOSAccountsForForm } from 'widgets/SoftwarePOSDialog/logic'
import { SoftwarePosCredentialsDialog } from './SoftwarePOSCredentialsDialog'
import { IoWarningOutline } from 'react-icons/io5'
import { observer } from 'mobx-react-lite'
import { store } from 'store'

interface SoftwarePOSDialogLogicProps {
  softwarePOSbyId: ServerSoftwarePosType | undefined
  accounts: SelectDropDownItem[]
  closeDialog: () => void
  isCreate: boolean
}

export const SoftwarePOSDialogLogic: FC<SoftwarePOSDialogLogicProps> = observer(
  ({ softwarePOSbyId, accounts, closeDialog, isCreate }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()

    const { control, formState, handleSubmit, getFieldState } = useForm<SoftwarePOSFormValues>({
      defaultValues: {
        name: softwarePOSbyId?.name ?? '',
        login: softwarePOSbyId?.login ?? '',
        isChangingCredentials: !Boolean(softwarePOSbyId),
        isChangingLicenseKey: !Boolean(softwarePOSbyId),
        licenseKey: '',
        password: '',
        accounts: getSoftwarePOSAccountsForForm(accounts, softwarePOSbyId),
      },
    })

    const { data: myPermission } = useFetchMyPermissions()

    const { mutateAsync: mutateUpdatePOS, isLoading: isLoadingMutateUpdatePOS } = useUpdatePOS()
    const { mutateAsync: mutateCreatePOS, isLoading: isLoadingMutateCreatePOS } = useCreatePOS()

    const [openSnackbar] = useSnackbar()
    const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
    const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

    const isEditingAllowed = myPermission?.includes(permissions.account.set) ?? false

    const handleBeforeMutate: SubmitHandler<SoftwarePOSFormValues> = async (data, e) => {
      if (softwarePOSbyId) {
        if (data.accounts && data.accounts.length && getFieldState('accounts').isDirty) {
          showConfirmation({
            title: t('softwarePOS.changeAccountsConfirmation.title'),
            description: t('softwarePOS.changeAccountsConfirmation.description'),
            onConfirm: async () => await mutateSoftwarePOS(data, e),
            confirmButton: {
              text: t('softwarePOS.changeAccountsConfirmation.confirmButton'),
              Icon: IoWarningOutline,
            },
          })

          return
        }
      }

      await mutateSoftwarePOS(data, e)
    }

    const mutateSoftwarePOS: SubmitHandler<SoftwarePOSFormValues> = async ({
      name,
      licenseKey,
      login,
      password,
      isChangingCredentials,
      isChangingLicenseKey,
      accounts,
    }) => {
      if (!branchId) {
        openSnackbar(t('submitError'), 'error', 3000)

        return
      }

      if (softwarePOSbyId) {
        const updatePOSSetInput: UpdatePOSSetInput = {
          softwarePOSId: softwarePOSbyId.id,
          name,
          branchId,
        }
        if (isChangingCredentials) {
          updatePOSSetInput.login = login
          updatePOSSetInput.password = password
        }
        if (isChangingLicenseKey) updatePOSSetInput.licenseKey = licenseKey
        if (accounts && accounts.length && getFieldState('accounts').isDirty)
          updatePOSSetInput.accounts = accounts.map(item => item.id)

        const result = await mutateUpdatePOS(updatePOSSetInput)

        handleUpdatePOSResponse({
          type: 'update',
          closeDialog,
          updatePOSResult: result.updatePOS,
          t,
          openSnackbar,
        })
      } else {
        const result = await mutateCreatePOS({
          name,
          licenseKey,
          login,
          password,
          accounts: accounts.map(item => item.id),
          branchId,
        })

        handleUpdatePOSResponse({
          type: 'create',
          closeDialog,
          updatePOSResult: result.updatePOS,
          t,
          openSnackbar,
        })
      }
    }

    const disabled = formState.isSubmitting || isLoadingMutateUpdatePOS || isLoadingMutateCreatePOS

    return (
      <>
        <Modal
          close={closePopups}
          confirm={handleSubmit(handleBeforeMutate)}
          animation="onlyFadeOut"
        >
          <Dialog>
            <Dialog.Title>{t('softwarePOS.name')}</Dialog.Title>

            <Dialog.Body>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={t('title')}
                    placeholder={t('placeholders.accountName')}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.name),
                      text: t('formError.required'),
                    }}
                    value={value}
                    onChange={onChange}
                    disabled={!isEditingAllowed}
                    autoFocus
                    hint={t('softwarePOS.titleHint')}
                  />
                )}
              />

              <Controller
                name="accounts"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <MultiSelect
                    label={t('accounts')}
                    required
                    items={accounts}
                    onItemSelect={onChange}
                    selectedItems={value}
                    errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                  />
                )}
              />

              <SoftwarePosCredentialsDialog
                control={control}
                isEditingAllowed={isEditingAllowed}
                isCreate={isCreate}
              />
            </Dialog.Body>

            <Dialog.Footer>
              {isEditingAllowed && (
                <SaveButton
                  onClick={handleSubmit(handleBeforeMutate)}
                  disabled={disabled || !formState.isDirty}
                  spinner={disabled}
                  isCreate={!softwarePOSbyId}
                />
              )}

              <CloseButton onClick={closePopups} disabled={disabled} />
            </Dialog.Footer>
          </Dialog>
        </Modal>

        {confirmPopup}
        {confirmationModal}
      </>
    )
  },
)

export type SoftwarePOSFormValues = {
  name: string
  licenseKey: string
  login: string
  password: string
  isChangingCredentials: boolean
  isChangingLicenseKey: boolean
  accounts: SelectDropDownItem[]
}

const handleUpdatePOSResponse = ({
  type,
  closeDialog,
  openSnackbar,
  t,
  updatePOSResult,
}: {
  type: 'update' | 'create'
  closeDialog: () => void
  openSnackbar: OpenSnackbarFunc
  t: TFunction
  updatePOSResult: CloudFunctionResult | undefined
}) => {
  if (updatePOSResult?.code === CloudFunctionResponseCodes.successful) {
    openSnackbar(
      t(type === 'create' ? 'softwarePOS.createSuccess' : 'softwarePOS.updateSuccess'),
      'success',
      3000,
    )
    closeDialog()
  } else if (updatePOSResult?.code === CloudFunctionResponseCodes.checkboxCredentialsError)
    openSnackbar(t('softwarePOS.credentialsError'), 'error', 3000)
  else if (updatePOSResult?.code === CloudFunctionResponseCodes.checkboxError)
    openSnackbar(t('softwarePOS.checkboxError'), 'error', 3000)
  else {
    openSnackbar(t('submitError'), 'error', 3000)
    closeDialog()
  }
}
