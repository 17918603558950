import { translateItemsName } from '@expane/logic/utils'
import {
  HorizontalButtonSwitch,
  HorizontalButtonSwitchItem,
  PlaceholderInputProps,
  Switch,
  SwitchProps,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SizeTypes } from 'ui/UploadPhoto'
import { EditablePhoto } from 'widgets/EditablePhoto'
import { GenderPicker } from 'widgets/GenderPicker'

export const PlaceholderAvatar: FC = () => {
  return (
    <div className="w-8 h-8">
      <div className="relative w-7 h-7 rounded-full bg-placeholder overflow-hidden">
        <div className="animate-placeholder absolute top-0 -left-full w-full h-full bg-placeholder-gradient" />
      </div>
    </div>
  )
}

type PlaceholderSwitchProps = Pick<SwitchProps, 'containerClassName' | 'label'>

export const PlaceholderSwitch: FC<PlaceholderSwitchProps> = ({ containerClassName, label }) => {
  return (
    <Switch
      onChange={() => {
        return
      }}
      containerClassName={containerClassName}
      disabled
      label={label}
    />
  )
}

type PlaceholderRangePickerProps = Pick<PlaceholderInputProps, 'className' | 'label'>
export const PlaceholderRangePicker: FC<PlaceholderRangePickerProps> = ({ className, label }) => {
  return (
    <GenderPicker
      label={label}
      value={undefined}
      onChange={() => {
        return
      }}
      disabled
      containerClassName={className}
    />
  )
}

type PlaceholderEditablePhotoProps = {
  className?: string
  size?: SizeTypes
}
export const PlaceholderEditablePhoto: FC<PlaceholderEditablePhotoProps> = ({
  className,
  size,
}) => {
  return (
    <EditablePhoto
      defaultPhoto={undefined}
      onChange={() => {
        return
      }}
      disabled
      containerClassName={className}
      size={size}
    />
  )
}

type PlaceholderHorizontalButtonSwitchProps = Pick<PlaceholderInputProps, 'className' | 'label'> & {
  options: HorizontalButtonSwitchItem[]
}
export const PlaceholderHorizontalButtonSwitch: FC<PlaceholderHorizontalButtonSwitchProps> = ({
  options,
  className,
  label,
}) => {
  const { t } = useTranslation()

  return (
    <HorizontalButtonSwitch
      label={label}
      containerClassName={className}
      value={undefined}
      onChange={() => {
        return
      }}
      options={translateItemsName(options, t)}
      disabled
    />
  )
}
