import {
  BookingUnion,
  useFetchCurrentBranchTimezone,
  useFetchExtendedEmployees,
  useUpdateBookingsStatuses,
} from '@expane/data'
import {
  BOOKING_STATUSES,
  checkIsSalaryIssuedByBooking,
  getIsBookingDone,
} from '@expane/logic/booking'
import { permissions } from '@expane/logic/permission'
import { Hint, InputLabel, useShowConfirmationPopup, useShowWarningPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { StatusTracker } from 'ui/StatusTracker'
import { getNameOfStatus } from 'ui/StatusTracker/logic'
import { getSalarySettingsWarning, useGetBookingStatusItems } from 'widgets/BookingStatusTracker'

interface QuickFixMultiServicesStatusProps {
  bookings: Array<BookingUnion>
  isDirty: boolean
  closeDialog: () => void
}

export const QuickFixMultiServicesStatus: FC<QuickFixMultiServicesStatusProps> = ({
  bookings,
  isDirty,
  closeDialog,
}) => {
  const { t } = useTranslation()

  const { mutateAsync: updateBookingsStatuses } = useUpdateBookingsStatuses()

  const [openSnackbar] = useSnackbar()

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()
  const { showWarningPopup, warningModal } = useShowWarningPopup(
    t('booking.name'),
    t('needToSaveChanges'),
  )

  const employeeIdsHashMap = bookings.reduce((employeeIds, booking) => {
    if (booking.employee) {
      employeeIds[booking.employee.id] = true
    }
    return employeeIds
  }, {} as { [x: number]: true })
  const employeeIds = Object.keys(employeeIdsHashMap)
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: employees } = useFetchExtendedEmployees(timezone, branchId)
  const anyEmployeeDoesNotHaveSalarySettings = employeeIds.length
    ? employeeIds.some(employeeId => getSalarySettingsWarning(Number(employeeId), employees))
    : false

  const { data: myPermissions } = useFetchMyPermissions()

  const isSalaryIssued = checkIsSomeBookingSalaryIssued(bookings)

  const disabledSetStatus = !myPermissions?.includes(permissions.bookingStatus.set)
    ? t('disabledBookingStatusReasons.set')
    : undefined

  let disabledUnsetStatus: string | undefined
  if (!myPermissions?.includes(permissions.bookingStatus.unset))
    disabledUnsetStatus = t('disabledBookingStatusReasons.unset')
  else if (isSalaryIssued) disabledUnsetStatus = t('disabledBookingStatusReasons.salary')

  const translatedStatusItems = useGetBookingStatusItems(bookings.map(booking => booking.startDate))

  const handleConfirm = async (status: number) => {
    const result = await updateBookingsStatuses({
      ids: bookings.map(({ id }) => id),
      status,
    })

    if (result.updateBookingStatuses.affectedRows) {
      openSnackbar(t('makeDone.updateSuccess'), 'success', 3000)
    } else {
      openSnackbar(t('submitError'), 'error', 3000)
    }
    closeDialog()
  }

  const handleChangeStatus = (status: number) => {
    if (status === BOOKING_STATUSES.done && isDirty) {
      showWarningPopup()
      return
    }

    const nameOfStatus = getNameOfStatus(status, translatedStatusItems)
    const description = anyEmployeeDoesNotHaveSalarySettings
      ? `${t('employeeSalaryAlertSome')}.
${t('employeeSalaryAlertEffects')}.
${t('changeGeneralStatus.confirmation', {
  nameOfStatus,
})}`
      : t('changeGeneralStatus.confirmation', { nameOfStatus })
    showConfirmation({
      title: t('changeGeneralStatus.title'),
      description,
      onConfirm: () => handleConfirm(status),
    })
  }

  const isBookingMultiServicesDone = bookings.every(booking => getIsBookingDone(booking))

  return (
    <div className="flex justify-end items-center mb-3">
      <InputLabel label={`${t('generalStatus.name')}:`} />
      <Hint className="mr-2">{t('generalStatus.hint')}</Hint>

      <StatusTracker
        value={isBookingMultiServicesDone ? BOOKING_STATUSES.done : DEFAULT_STATUS}
        onChange={handleChangeStatus}
        items={translatedStatusItems}
        disabledSetReason={disabledSetStatus}
        disabledUnsetReason={disabledUnsetStatus}
        className="w-1/2"
      />
      {confirmationModal}
      {warningModal}
    </div>
  )
}

const checkIsSomeBookingSalaryIssued = (bookingIds: Array<BookingUnion>) =>
  bookingIds.some(booking => checkIsSalaryIssuedByBooking(booking))

const DEFAULT_STATUS = -1
