import { FC } from 'react'
import { Control, UseFormClearErrors, UseFormResetField, UseFormSetValue } from 'react-hook-form'
import { SalarySettingFormValues } from '../logic'
import { RateFromServices } from './RateFromServices'
import { CommonSetting } from './CommonSetting'
import { AllServicesRateFromServices } from './AllServicesRateFromServices'
import { ServerExtendedServiceType } from '@expane/data'

interface SalarySettingProps {
  services: ServerExtendedServiceType[]
  control: Control<SalarySettingFormValues>
  setValue: UseFormSetValue<SalarySettingFormValues>
  resetField: UseFormResetField<SalarySettingFormValues>
  clearErrors: UseFormClearErrors<SalarySettingFormValues>
  isCreate: boolean
  disabled?: boolean
  employeeId?: number
  type?: 'single' | 'group'
  timezone: string
  areEmployeesFirstSalarySetting?: boolean | undefined
}

export const SalarySetting: FC<SalarySettingProps> = ({
  services,
  control,
  setValue,
  resetField,
  clearErrors,
  disabled,
  isCreate,
  employeeId,
  type,
  timezone,
  areEmployeesFirstSalarySetting,
}) => {
  return (
    <div className="h-120">
      <CommonSetting
        timezone={timezone}
        control={control}
        disabled={disabled}
        clearErrors={clearErrors}
        employeeId={employeeId}
        type={type}
        areEmployeesFirstSalarySetting={areEmployeesFirstSalarySetting}
      />
      {isCreate && (
        <AllServicesRateFromServices
          services={services}
          control={control}
          setValue={setValue}
          resetField={resetField}
        />
      )}
      <RateFromServices control={control} disabled={disabled} services={services} />
    </div>
  )
}
