import { TreeMenuItem } from 'ui/TreeMenu/index'
import { checkVendorCodeIsBySearch } from '@expane/logic/utils'

export interface ProductTreeMenuItem extends TreeMenuItem {
  vendorCode?: string | null
}

export const customProductSearchFilterFunction = <T extends ProductTreeMenuItem>(
  items: T[] | undefined,
  searchValue: string,
) => {
  if (items === undefined) return []

  if (searchValue === '') return items

  const result: T[] = []

  for (let i = 0; i < items.length; i++) {
    if (items[i].isFolder) {
      const filteredNodes = customProductSearchFilterFunction(items[i].nodes, searchValue)
      if (filteredNodes.length) {
        // если это папка и после фильтрации массив `nodes` пустой, то папку в результат не пушим
        result.push({ ...items[i], nodes: filteredNodes })
      }
    } else {
      const foundInName = items[i].name.toLowerCase().includes(searchValue.toLowerCase(), 0)
      const foundInVendorCode = checkVendorCodeIsBySearch({
        vendorCode: items[i].vendorCode,
        searchValue,
      })

      if (foundInName || foundInVendorCode) result.push({ ...items[i] })
    }
  }
  return result
}
