import { CSSProperties, FC, forwardRef, ReactNode } from 'react'
import { IoAddCircleOutline, IoClose } from 'react-icons/io5'
import { stopEnterPropagation } from '../../logic'
import { ErrorMessage, ErrorMessageType } from '../ErrorMessage'

const defaultTableClassName = 'h-full border-spacing-0 border-collapse'
const tableContainerClassName =
  'border-table-color border-2 rounded-md overflow-auto w-fit max-w-full relative isolate'
export const TableContainer = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode
    className?: string
    tableClassName?: string
  }
>(({ className, children, tableClassName }, ref) => {
  const actualClassName = className
    ? tableContainerClassName + ' ' + className
    : tableContainerClassName
  const actualTableClassName = tableClassName
    ? defaultTableClassName + ' ' + tableClassName
    : defaultTableClassName
  return (
    <div ref={ref} className={actualClassName}>
      <table className={actualTableClassName}>{children}</table>
    </div>
  )
})
export const TableHeader: FC<{ children: ReactNode }> = ({ children }) => (
  <thead className="sticky top-0 m-0 bg-table-header text-table-title-color text-xs uppercase border-table-color border-b-2 z-10 ">
    {children}
  </thead>
)

const tableHeaderCellClassName = 'text-left p-2 relative'
const borderClasses =
  ' border-x border-primary-200 dark:border-gray-600 first:border-l-0 last:border-r-0'
export const TableHeaderCell: FC<{
  children?: ReactNode
  style?: CSSProperties
  className?: string
  colSpan?: number
  borderless?: boolean
}> = ({ children, style, colSpan, className, borderless = false }) => {
  let actualClassName = className
    ? tableHeaderCellClassName + ' ' + className
    : tableHeaderCellClassName

  if (!borderless) actualClassName += borderClasses
  return (
    <th colSpan={colSpan} style={style} className={actualClassName}>
      {children}
    </th>
  )
}

export const TableBody: FC<{ children: ReactNode }> = ({ children }) => (
  <tbody className="text-sm">{children}</tbody>
)

const TableErrorMessage: FC<{ errorMessage?: ErrorMessageType }> = ({ errorMessage }) =>
  errorMessage?.isShown ? (
    <tr className="w-full">
      <td colSpan={100}>
        <ErrorMessage errorMessage={errorMessage} className="px-2" />
      </td>
    </tr>
  ) : null

type TableRowProps = {
  children: ReactNode
  className?: string
  onClick?: () => void
  errorMessage?: ErrorMessageType
}
export const TableRow: FC<TableRowProps> = ({ children, className, onClick, errorMessage }) => {
  let rowClassName = 'text-main-color even:bg-zinc-100 bg-block dark:even:bg-gray-600'
  if (onClick) rowClassName += ' cursor-pointer bg-hover even:bg-hover'
  if (className) rowClassName = rowClassName + ' ' + className
  return (
    <>
      <tr className={rowClassName} onClick={onClick}>
        {children}
      </tr>
      <TableErrorMessage errorMessage={errorMessage} />
    </>
  )
}

const cellClassName = 'px-2 py-1'
export const TableCell: FC<{
  children?: JSX.Element | ReactNode
  colSpan?: number
  className?: string
  customContainer?: boolean
  borderless?: boolean
}> = ({ children, colSpan, className, customContainer = false, borderless = false }) => {
  let actualClassName = className ? cellClassName + ' ' + className : cellClassName
  if (!borderless)
    actualClassName +=
      ' border-x first:border-l-0 last:border-r-0 border-zink-100 dark:border-gray-600'

  const inner = customContainer ? children : <span className="line-clamp-1">{children}</span>
  return (
    <td className={actualClassName} colSpan={colSpan}>
      {inner}
    </td>
  )
}

export const TableFooter: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <tfoot className="bg-primary-50 text-primary-400 dark:bg-primary-tinted">
      <tr>{children}</tr>
    </tfoot>
  )
}

type DeleteButtonProps = {
  onClick?: () => void
  disabled?: boolean
  className?: string
}
export const DeleteButtonInTable: FC<DeleteButtonProps> = ({ onClick, disabled, className }) => {
  let buttonStyle = 'rounded-full text-gray-300 transition-color '
  if (onClick)
    buttonStyle += ' hover:text-error-500 focus:ring-1 focus:text-error-500 ring-error-500'
  if (disabled) buttonStyle += ' pointer-events-none'
  if (className) buttonStyle = buttonStyle + ' ' + className
  return (
    <div className="px-2 flex-centered h-full">
      {onClick ? (
        <button
          onClick={onClick}
          disabled={disabled}
          className={buttonStyle}
          onKeyDown={stopEnterPropagation}
        >
          <IoClose size="1.4rem" />
        </button>
      ) : null}
    </div>
  )
}
export const DeleteButtonCell: FC<DeleteButtonProps> = props => {
  return (
    <td>
      <DeleteButtonInTable {...props} />
    </td>
  )
}

export const AddButtonHeaderCell: FC<{ onClick: () => void; disabled?: boolean }> = ({
  onClick,
  disabled = false,
}) => {
  return (
    <th className="cursor-pointer w-6">
      <div className="px-2 flex-centered">
        <button onClick={onClick} disabled={disabled}>
          <IoAddCircleOutline
            size="1.3rem"
            className={disabled ? 'text-icon-color' : 'text-primary-500'}
          />
        </button>
      </div>
    </th>
  )
}
