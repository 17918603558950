import { CountedNotPaidSalaryType } from '../salaryIssues/logic'
import { max, min, fromZonedTime } from '@expane/date'

export const convertNotPaidSalariesToServer = (
  employeesNotPaidSalaries: CountedNotPaidSalaryType[],
  timezone: string,
) =>
  employeesNotPaidSalaries
    .map(item => {
      // Измененный value может быть только если период за месяц, и месячная ЗП,
      // и потому мы уверены что там будет одна сущность RequestData
      if (
        item.notPaidRateSalaryRequestData.length === 1 &&
        item.notPaidRateSalaryRequestData[0].value !== item.notPaidSalaryRate
      ) {
        return {
          ...item.notPaidRateSalaryRequestData[0],
          value: item.notPaidSalaryRate,
          endPeriod: fromZonedTime(item.notPaidRateSalaryRequestData[0].endPeriod, timezone),
          startPeriod: fromZonedTime(item.notPaidRateSalaryRequestData[0].startPeriod, timezone),
        }
      }

      return item.notPaidRateSalaryRequestData.map(item => ({
        ...item,
        endPeriod: fromZonedTime(item.endPeriod, timezone),
        startPeriod: fromZonedTime(item.startPeriod, timezone),
      }))
    })
    .flat()

export const convertDebtSalariesToServer = (
  debtSalary: CountedNotPaidSalaryType,
  timezone: string,
) => {
  const requestData = debtSalary.notPaidRateSalaryRequestData

  let startPeriod
  let endPeriod

  if (requestData.length !== 0) {
    const startDates = requestData.map(({ startPeriod }) => fromZonedTime(startPeriod, timezone))
    const endDates = requestData.map(({ endPeriod }) => fromZonedTime(endPeriod, timezone))

    startPeriod = min(startDates)
    endPeriod = max(endDates)
  } else {
    startPeriod = debtSalary.period.startPeriod
    endPeriod = debtSalary.period.endPeriod
  }

  const convertedDataRequestData = requestData.map(request => ({
    ...request,
    startPeriod: fromZonedTime(request.startPeriod, timezone),
    endPeriod: fromZonedTime(request.endPeriod, timezone),
  }))

  return {
    startPeriod,
    endPeriod,
    requestData: convertedDataRequestData,
  }
}
