import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { ServerBusinessLandingSetInput } from '../../generated/graphql-types'
import { BUSINESS_LANDINGS_SETTINGS_QUERY_KEY } from './queryKeys'

export function useUpdateBusinessLandingSettings() {
  return useMutation(
    async (dto: { id: string; serverBusinessLandingSetInput: ServerBusinessLandingSetInput }) => {
      return request<{ updateBusinessLandingById: { id: string } }>(
        gql`
          mutation ($id: uuid!, $serverBusinessLandingSetInput: businessLanding_set_input!) {
            updateBusinessLandingById(
              pk_columns: { id: $id }
              _set: $serverBusinessLandingSetInput
            ) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([BUSINESS_LANDINGS_SETTINGS_QUERY_KEY]),
    },
  )
}
