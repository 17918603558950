import { RouteType } from 'routes/logic'
import {
  IoCallOutline,
  IoCardOutline,
  IoChatboxEllipsesOutline,
  IoCog,
  IoConstructOutline,
  IoDesktopOutline,
  IoFolderOpenOutline,
  IoPeopleOutline,
  IoPersonCircleOutline,
  IoSettingsOutline,
  IoSwapHorizontalOutline,
} from 'react-icons/io5'
import { MainSettings } from 'pages/SettingsPage/MainSettings'
import { permissions } from '@expane/logic/permission'
import { BusinessSettings } from 'pages/SettingsPage/BusinessSettings'
import { BranchesSettings } from 'pages/SettingsPage/BranchesSettings'
import { SettingsPage } from 'pages/SettingsPage'
import { NotificationsSettings } from 'pages/SettingsPage/NotificationsSettings'
import { TelephonySettings } from 'pages/SettingsPage/TelephonySettings'
import { BillingSettings } from 'pages/SettingsPage/BillingSettings'
import { BranchImportSettings } from 'pages/SettingsPage/BranchImportSettings'
import { BusinessModulesSettings } from 'pages/SettingsPage/BusinessModulesSettings'
import { TechnicalSupportSettings } from 'pages/SettingsPage/TechnicalSupportSettings'
import { BusinessLandingSettings } from 'pages/SettingsPage/BusinessLandingSettings'
import { OnlineBookingSettings } from 'pages/SettingsPage/OnlineBookingSettings'

export const BRANCH_IMPORT_PATH = '/settings/import'
export const MODULES_SETTINGS_PATH = '/settings/modules'

export const menuRoute: RouteType = {
  name: 'settings',
  path: undefined,
  isPrivate: true,
  Icon: IoSettingsOutline,
  element: SettingsPage,
  children: [
    {
      name: 'menu.main.title',
      path: '/settings/main',
      isPrivate: true,
      parentRoute: { path: '/settings', name: 'settings' },
      Icon: IoPersonCircleOutline,
      element: MainSettings,
    },
    {
      name: 'menu.business.title',
      path: '/settings/business',
      isPrivate: true,
      permissions: [permissions.business.set],
      parentRoute: { path: '/settings', name: 'settings' },
      Icon: IoFolderOpenOutline,
      element: BusinessSettings,
    },
    {
      name: 'menu.modules.title',
      path: MODULES_SETTINGS_PATH,
      isPrivate: true,
      permissions: [permissions.business.set],
      parentRoute: { path: '/settings', name: 'settings' },
      Icon: IoCog,
      element: BusinessModulesSettings,
    },
    {
      name: 'menu.billing.title',
      path: '/settings/billing',
      isPrivate: true,
      permissions: [permissions.billingInfo.get],
      parentRoute: { path: '/settings', name: 'settings' },
      Icon: IoCardOutline,
      element: BillingSettings,
    },
    {
      name: 'menu.branches.title',
      path: '/settings/branches',
      isPrivate: true,
      permissions: [permissions.branch.set],
      parentRoute: { path: '/settings', name: 'settings' },
      Icon: IoFolderOpenOutline,
      element: BranchesSettings,
    },
    {
      name: 'menu.businessLanding.title',
      path: '/settings/landing-settings',
      isPrivate: true,
      permissions: [permissions.business.set, permissions.employee.get, permissions.service.get],
      parentRoute: { path: '/settings', name: 'settings' },
      Icon: IoDesktopOutline,
      element: BusinessLandingSettings,
      featureFlags: ['enabledNewLanding'],
    },
    {
      name: 'menu.onlineBooking.title',
      path: '/settings/online-booking',
      isPrivate: true,
      permissions: [permissions.business.set],
      parentRoute: { path: '/settings', name: 'settings' },
      Icon: IoPeopleOutline,
      element: OnlineBookingSettings,
    },
    {
      name: 'menu.notifications.title',
      path: '/settings/notifications',
      isPrivate: true,
      permissions: [permissions.business.set],
      parentRoute: { path: '/settings', name: 'settings' },
      Icon: IoChatboxEllipsesOutline,
      element: NotificationsSettings,
    },
    // TODO: закончить функционал
    // {
    //   name: 'menu.receipt.title',
    //   description: 'menu.receipt.description',
    //   path: '/settings/receipt',
    //   permissions: [permissions.business.set],
    //   parentRoute: { path: '/settings', name: 'settings' },
    //   Icon: IoReceiptOutline,
    //   element: ReceiptSettings,
    // },
    {
      name: 'menu.telephony.title',
      path: '/settings/telephony',
      isPrivate: true,
      permissions: [permissions.business.set],
      parentRoute: { path: '/settings', name: 'settings' },
      Icon: IoCallOutline,
      element: TelephonySettings,
    },
    {
      name: 'menu.branchImport.title',
      path: BRANCH_IMPORT_PATH,
      isPrivate: true,
      // TODO: add permission for branch-related actions
      permissions: [permissions.business.set],
      parentRoute: { path: '/settings', name: 'settings' },
      Icon: IoSwapHorizontalOutline,
      element: BranchImportSettings,
    },
    {
      name: 'menu.technicalSupport.title',
      path: '/settings/technicalSupport',
      isPrivate: true,
      parentRoute: { path: '/settings', name: 'settings' },
      Icon: IoConstructOutline,
      element: TechnicalSupportSettings,
    },
  ],
}
