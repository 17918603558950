import { useTranslation } from 'react-i18next'
import { useGetIsDarkMode } from '@expane/web-logic/theme'

export const DesignInstruction = () => {
  const { t } = useTranslation()

  const isDarkMode = useGetIsDarkMode()

  return (
    <div>
      <p className={titleStyle}>{t('landingSettingsInstruction.bannerSettings.title')}</p>
      <p>{t('landingSettingsInstruction.bannerSettings.1')}</p>
      <p>{t('landingSettingsInstruction.bannerSettings.2')}</p>
      <p>{t('landingSettingsInstruction.bannerSettings.3')}</p>

      <p className={imgDescrStyle}>
        {t('landingSettingsInstruction.bannerSettings.settingsPreview')}
      </p>

      <img
        className={imgStyle}
        src={isDarkMode ? '/img/landing/banner_dark.png' : '/img/landing/banner.png'}
        alt="search"
      />

      <p className={imgDescrStyle}>
        {t('landingSettingsInstruction.bannerSettings.landingPreview')}
      </p>

      <img
        className={imgStyle}
        src={isDarkMode ? '/img/landing/bannerLanding_dark.png' : '/img/landing/bannerLanding.png'}
        alt="search"
      />

      <p className={titleStyle + 'mt-4'}>{t('landingSettingsInstruction.colorSettings.title')}</p>

      <p>{t('landingSettingsInstruction.colorSettings.1')}</p>
      <p>{t('landingSettingsInstruction.colorSettings.2')}</p>
      <p>{t('landingSettingsInstruction.colorSettings.3')}</p>

      <p className={imgDescrStyle}>
        {t('landingSettingsInstruction.colorSettings.settingsPreview')}
      </p>

      <img
        className={imgStyle}
        src={isDarkMode ? '/img/landing/color_dark.png' : '/img/landing/color.png'}
        alt="search"
      />

      <p className={imgDescrStyle}>
        {t('landingSettingsInstruction.colorSettings.landingPreview')}
      </p>

      <img
        className={imgStyle}
        src={isDarkMode ? '/img/landing/colorLanding_dark.png' : '/img/landing/colorLanding.png'}
        alt="search"
      />

      <p className={titleStyle + 'mt-4'}>{t('landingSettingsInstruction.worksSection.title')}</p>

      <p>{t('landingSettingsInstruction.worksSection.description')}</p>

      <p className={imgDescrStyle}>
        {t('landingSettingsInstruction.worksSection.settingsPreview')}
      </p>

      <img
        className={imgStyle}
        src={isDarkMode ? '/img/landing/works_dark.png' : '/img/landing/works.png'}
        alt="search"
      />

      <p className={imgDescrStyle}>{t('landingSettingsInstruction.worksSection.landingPreview')}</p>

      <img
        className={imgStyle}
        src={isDarkMode ? '/img/landing/worksLanding_dark.png' : '/img/landing/worksLanding.png'}
        alt="search"
      />
    </div>
  )
}

const imgStyle = 'rounded-lg max-w-4xl mt-2'
const imgDescrStyle = 'mt-4 italic text-sm '
const titleStyle = 'font-semibold mb-2 text-xl '
