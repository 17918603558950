import { ServerProductType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { ProductsAmount } from '@expane/logic/movement'
import {
  AddButtonHeaderCell,
  TableBody,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { FC } from 'react'
import { useFieldArray, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  InventoryCreateDialogFormValues,
  InventoryCreateProps,
} from 'widgets/InventoryCreateDialog'
import { InventoryCreateListItem } from 'widgets/InventoryCreateDialog/InventoryCreateListItem'

interface InventoryCreateListProps extends InventoryCreateProps {
  setValue: UseFormSetValue<InventoryCreateDialogFormValues>
  leftovers: ProductsAmount | undefined
  isLoadingLeftovers: boolean
  products: ServerProductType[]
}

export const InventoryCreateList: FC<PropsWithBranchId<InventoryCreateListProps>> = ({
  control,
  setValue,
  products,
  leftovers,
  isLoadingLeftovers,
  branchId,
}) => {
  const { t } = useTranslation()

  const { fields, append, remove } = useFieldArray({ control, name: 'products' })

  return (
    <TableContainer className={'overflow-auto'}>
      <TableHeader>
        <tr>
          <TableHeaderCell>{t('vendorCode')}</TableHeaderCell>
          <TableHeaderCell>{t('name')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('unit.shortName')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('purchasePrice')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('sellingPrice')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('calcLeftover')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('actualLeftover')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('difference')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('excessShortage')}</TableHeaderCell>
          <AddButtonHeaderCell
            onClick={() =>
              append({
                id: undefined,
                actualQuantity: '',
                price: undefined,
                costPrice: undefined,
                name: '',
                unit: undefined,
                vendorCode: '',
              })
            }
          />
        </tr>
      </TableHeader>
      <TableBody>
        {fields?.map((product, index) => {
          return (
            <TableRow key={product?.id ?? -index}>
              <InventoryCreateListItem
                index={index}
                products={products}
                leftovers={leftovers}
                isLoadingLeftovers={isLoadingLeftovers}
                control={control}
                setValue={setValue}
                onDelete={() => remove(index)}
                disabled={fields.length === 1}
                branchId={branchId}
              />
            </TableRow>
          )
        })}
      </TableBody>
    </TableContainer>
  )
}
