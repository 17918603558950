import { useAreManyEmployeesAllowed } from '@expane/logic/billing'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { NoFoundPage } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/logic'
import { useFeatureFlags } from '@expane/logic/featureFlags'

export const ErrorPage: FC = () => {
  const navigate = useNavigate()

  const { data: myPermissions } = useFetchMyPermissions()
  const { enabledModules } = useBusinessModulesSettings()
  const { areManyEmployeesAllowed } = useAreManyEmployeesAllowed()
  const { getFeatureFlag } = useFeatureFlags()

  const navigationRootRoutes = routes.getTopBarRoutes(
    myPermissions,
    enabledModules,
    areManyEmployeesAllowed,
    getFeatureFlag,
  )

  return <NoFoundPage onClick={() => navigate(navigationRootRoutes?.[0].path)} />
}
