import { useCallback, useState } from 'react'

export const useRefCollection = <T>() => {
  const [refs, setRefs] = useState<T[]>([])

  const addRef = useCallback((ref: T) => {
    setRefs(prev => [...prev, ref])
  }, [])

  const removeRef = useCallback((ref: T) => {
    setRefs(prev => prev.filter(item => item !== ref))
  }, [])

  return { refs, addRef, removeRef }
}
