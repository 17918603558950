import { BranchWithSchedule } from '@expane/data'
import { useState } from 'react'
import { store } from 'store'

export const useBranchImportState = (branches: BranchWithSchedule[]) => {
  const branchId = store.branch.branchId
  const branchesWithoutCurrent = branches.filter(branch => branch.id !== branchId)

  const [selectedBranchId, setSelectedBranchId] = useState(
    () => branches.find(branch => branch.id !== branchId)?.id ?? null,
  )

  return {
    selectedBranchId,
    setSelectedBranchId,
    branchesWithoutCurrent,
  }
}
