import { gql } from 'graphql-request'
import { DEFAULT_TIMEZONE, endOfDay, startOfDay } from '@expane/date'
import { queryClient, request, useMutation, useQuery, reportError, reportGqlError } from '../../api'
import {
  ServerTimeOffByIdType,
  ServerTimeOffInsertInput,
  ServerTimeOffSetInput,
  ServerTimeOffType,
} from '../../generated/graphql-types'
import { timeOffByIdFragment, timeOffFragment } from './timeOff.fragments'
import { TIME_OFF_BY_ID_QUERY_KEY, TIME_OFFS_QUERY_KEY } from './queryKeys'
import { parseDatesInTimeOffGqlResponse } from './logic'

export function useFetchTimeOffsOfDay(date: Date, timezone: string | undefined) {
  const start = startOfDay(date)
  const end = endOfDay(date)

  return useQuery<ServerTimeOffType[]>(
    [TIME_OFFS_QUERY_KEY, { start, end }],
    async () => {
      const result = await request(
        gql`
          ${timeOffFragment}
          query ($start: timestamptz!, $end: timestamptz!) {
            timeOffs(
              order_by: { start: desc }
              where: {
                canceledDate: { _is_null: true }
                _or: [{ start: { _gte: $start, _lt: $end } }, { end: { _gte: $start, _lt: $end } }]
              }
            ) {
              ...timeOffType
            }
          }
        `,
        { start, end },
      )

      return result.timeOffs.map(timeOff =>
        parseDatesInTimeOffGqlResponse(timeOff, timezone ?? DEFAULT_TIMEZONE),
      )
    },
    { queryName: 'useFetchTimeOffsOfDay', enabled: Boolean(timezone) },
  )
}

export function useFetchTimeOffsOfPeriodByEmployeeId(
  startOfPeriod: Date,
  endOfPeriod: Date,
  timezone: string | undefined,
  employeeId?: number,
) {
  return useQuery<ServerTimeOffType[]>(
    [TIME_OFFS_QUERY_KEY, { startOfPeriod, endOfPeriod, employeeId }],
    async () => {
      const result = await request(
        gql`
          ${timeOffFragment}
          query ($startOfPeriod: timestamptz!, $endOfPeriod: timestamptz!, $employeeId: Int!) {
            timeOffs(
              where: {
                _or: [
                  { start: { _gte: $startOfPeriod, _lt: $endOfPeriod } }
                  { end: { _gte: $startOfPeriod, _lt: $endOfPeriod } }
                ]
                employeeId: { _eq: $employeeId }
                canceledDate: { _is_null: true }
              }
            ) {
              ...timeOffType
            }
          }
        `,
        { startOfPeriod, endOfPeriod, employeeId },
      )

      if (Array.isArray(result?.timeOffs)) {
        return result.timeOffs.map(timeOff =>
          parseDatesInTimeOffGqlResponse(timeOff, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('timeOffs is not an array'), 'warning', {
          dto: { startOfPeriod, endOfPeriod, employeeId },
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchTimeOffsOfPeriodByEmployeeId',
      enabled: Boolean(employeeId) && Boolean(timezone),
      onError: reportGqlError,
      onSuccess: timeOffs =>
        timeOffs.forEach(timeOff =>
          queryClient.setQueryData([TIME_OFFS_QUERY_KEY, timeOff.id], timeOff),
        ),
    },
  )
}

export function useFetchTimeOffById(id: number | undefined, timezone: string | undefined) {
  return useQuery<ServerTimeOffByIdType>(
    [TIME_OFFS_QUERY_KEY, TIME_OFF_BY_ID_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${timeOffByIdFragment}
          query ($id: Int!) {
            timeOffById(id: $id) {
              ...timeOffByIdType
            }
          }
        `,
        { id },
      )

      if (result?.timeOffById) {
        return parseDatesInTimeOffGqlResponse(result.timeOffById, timezone ?? DEFAULT_TIMEZONE)
      } else {
        reportError(new Error('timeOffById does not exist'), 'warning', { id, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchTimeOffById',
      enabled: Boolean(id) && Boolean(timezone),
      onError: reportGqlError,
    },
  )
}

export function useCreateTimeOff() {
  return useMutation(
    (timeOffsInsertInput: ServerTimeOffInsertInput): Promise<{ insertTimeOff: { id: number } }> => {
      return request(
        gql`
          mutation ($timeOffsInsertInput: timeOff_insert_input!) {
            insertTimeOff(object: $timeOffsInsertInput) {
              id
            }
          }
        `,
        { timeOffsInsertInput },
      )
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(TIME_OFFS_QUERY_KEY),
        }),
      onError: reportGqlError,
    },
  )
}

export function useUpdateTimeOffById() {
  return useMutation(
    (dto: {
      id: number
      timeOffsSetInput: ServerTimeOffSetInput
    }): Promise<{ updateTimeOffById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $timeOffsSetInput: timeOff_set_input!) {
            updateTimeOffById(pk_columns: { id: $id }, _set: $timeOffsSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(TIME_OFFS_QUERY_KEY),
        })
        queryClient.invalidateQueries([TIME_OFF_BY_ID_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useCancelTimeOff() {
  return useMutation(
    (dto: { id: number }): Promise<{ updateTimeOffById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $canceledDate: timestamptz!) {
            updateTimeOffById(pk_columns: { id: $id }, _set: { canceledDate: $canceledDate }) {
              id
            }
          }
        `,
        { ...dto, canceledDate: new Date() },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () =>
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(TIME_OFFS_QUERY_KEY),
        }),
    },
  )
}

export function useCancelTimeOffs() {
  return useMutation(
    (dto: { ids: number[] }): Promise<{ update_timeOff_many: [{ affected_rows: number }] }> => {
      return request(
        gql`
          mutation ($ids: [Int!]!, $canceledDate: timestamptz!) {
            update_timeOff_many(
              updates: { where: { id: { _in: $ids } }, _set: { canceledDate: $canceledDate } }
            ) {
              affected_rows
            }
          }
        `,
        { ...dto, canceledDate: new Date() },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () =>
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(TIME_OFFS_QUERY_KEY),
        }),
    },
  )
}

export function useFetchTimeOffsOfPeriod(
  startOfPeriod: Date,
  endOfPeriod: Date,
  timezone: string | undefined,
) {
  return useQuery<ServerTimeOffType[]>(
    [TIME_OFFS_QUERY_KEY, { startOfPeriod, endOfPeriod }],
    async () => {
      const result = await request(
        gql`
          ${timeOffFragment}
          query ($startOfPeriod: timestamptz!, $endOfPeriod: timestamptz!) {
            timeOffs(
              where: {
                _or: [
                  { start: { _gte: $startOfPeriod, _lt: $endOfPeriod } }
                  { end: { _gte: $startOfPeriod, _lt: $endOfPeriod } }
                ]
                canceledDate: { _is_null: true }
              }
            ) {
              ...timeOffType
            }
          }
        `,
        { startOfPeriod, endOfPeriod },
      )

      if (Array.isArray(result?.timeOffs)) {
        return result.timeOffs.map(timeOff =>
          parseDatesInTimeOffGqlResponse(timeOff, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('timeOffs is not an array'), 'warning', {
          dto: { startOfPeriod, endOfPeriod },
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchTimeOffsOfPeriod',
      enabled: Boolean(timezone),
      onError: reportGqlError,
      onSuccess: timeOffs =>
        timeOffs.forEach(timeOff =>
          queryClient.setQueryData([TIME_OFFS_QUERY_KEY, timeOff.id], timeOff),
        ),
    },
  )
}

export function useCreateTimeOffs() {
  return useMutation(
    (
      timeOffsInsertInput: ServerTimeOffInsertInput[],
    ): Promise<{ insertTimeOffs?: { affected_rows: number } }> => {
      return request(
        gql`
          mutation ($timeOffsInsertInput: [timeOff_insert_input!]!) {
            insertTimeOffs(objects: $timeOffsInsertInput) {
              affected_rows
            }
          }
        `,
        { timeOffsInsertInput },
      )
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(TIME_OFFS_QUERY_KEY),
        }),
      onError: reportGqlError,
    },
  )
}
