import { FC } from 'react'
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { ButtonWithBillingCheck } from 'widgets/Buttons'
import { TypeOfPremiumDialog, useOpenPremiumDialog } from 'widgets/PremiumDialog/index'
import { permissions } from '@expane/logic/permission'

interface PremiumButtonProps {
  type: TypeOfPremiumDialog
  employeeId?: number
  myPermissions: string[] | undefined
  className?: string
}

export const PremiumButton: FC<PremiumButtonProps> = ({
  type,
  employeeId,
  myPermissions,
  className = '',
}) => {
  const { t } = useTranslation()

  const { premiumDialog, openPremiumDialog } = useOpenPremiumDialog()

  const isSalaryEditingAllowed = myPermissions?.includes(permissions.salary.set)

  const buttonStyle = 'w-28 ' + className

  return isSalaryEditingAllowed ? (
    <>
      <ButtonWithBillingCheck
        type="outline"
        twoLines
        onClick={() => {
          openPremiumDialog({ type, employeeId })
        }}
        className={buttonStyle}
        Icon={type === 'premium' ? IoAddCircleOutline : IoRemoveCircleOutline}
      >
        {type === 'premium' ? t('salary.addPremium') : t('salary.addPenalty')}
      </ButtonWithBillingCheck>
      {premiumDialog}
    </>
  ) : null
}
