import { TreeMenuItem } from '.'
import { findTreeMenuItem } from './logic.common'

const getParentFromUnitingChildren = (
  child: TreeMenuItem,
  selectedItems: TreeMenuItem[],
  allItems: TreeMenuItem[],
): TreeMenuItem | undefined => {
  const childrenCount =
    1 +
    selectedItems.reduce(
      (sum, selectedItem) => (child.parentId === selectedItem.parentId ? sum + 1 : sum),
      0,
    )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const parent = findTreeMenuItem(child.parentId!, true, allItems)

  if (childrenCount === parent?.nodes?.length) {
    const grandParent = parent?.parentId
      ? getParentFromUnitingChildren(parent, selectedItems, allItems)
      : undefined

    return grandParent ?? parent
  }

  return undefined
}

const removeAllNestedChildrenOfFolder = <T extends TreeMenuItem>(parent: T, selectedItems: T[]) => {
  const { nodes } = parent
  if (nodes === undefined) return selectedItems

  let selectedItemsWithoutChildren = selectedItems.filter(
    itemInValue => itemInValue.parentId !== parent.id,
  )

  for (const node of nodes) {
    selectedItemsWithoutChildren = removeAllNestedChildrenOfFolder(
      node as T,
      selectedItemsWithoutChildren,
    )
  }

  return selectedItemsWithoutChildren
}

const findParentOfItemInSelectedItems = (
  search: {
    item: TreeMenuItem
    child: TreeMenuItem | undefined
    firstParent: TreeMenuItem | undefined
  },
  selectedItems: TreeMenuItem[],
  allItems: TreeMenuItem[],
):
  | {
      // функция возвращает в item родительский элемент, который выбран,
      // а в firstParent - непосредственного родителя нажатого айтема
      item: TreeMenuItem
      child: TreeMenuItem
      firstParent: TreeMenuItem | undefined
    }
  | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const parent = findTreeMenuItem(search.item.parentId!, true, allItems)
  if (parent === undefined) return undefined

  const doesParentExistsInValue = selectedItems.find(
    selectedItem => selectedItem.id === parent.id && Boolean(selectedItem.isFolder),
  )

  if (doesParentExistsInValue)
    return {
      item: parent,
      child: search.item,
      firstParent: search.firstParent ?? parent,
    }

  return findParentOfItemInSelectedItems(
    {
      item: parent,
      child: search.item,
      firstParent: search.firstParent ?? parent,
    },
    selectedItems,
    allItems,
  )
}

// возвращает плоский массив со всеми выбранными элементами ([родитель без дочерних элементов
// (предполагается, что дочерние элементы выбраны), другие выбранные элементы])
export const onChangeMultiTreeMenu = <T extends TreeMenuItem>(
  item: T,
  selectedItems: T[],
  treeMenuItems: T[],
  ignoreDisabled = false,
  // `ignoreDisabled` Используется в тех случаях, когда мы знаем, что на след рендере этот айтем станет доступным
  // Используется в BookingInactiveReason
): T[] => {
  if (item.disabled && !ignoreDisabled) return selectedItems

  const isItemSelected = Boolean(
    selectedItems.find(
      itemFromValue =>
        itemFromValue.id === item.id && Boolean(itemFromValue.isFolder) === Boolean(item.isFolder),
    ),
  )

  if (isItemSelected) {
    if (item.isFolder) {
      const children = item.nodes
      if (children?.length) {
        // если у родителя есть дети isDefaultSelected, убираем все кроме дефолтно выбранных детей
        const disabledChildren = children.filter(child => child.disabled) as T[]

        const selectedItemsWithoutItem = selectedItems.filter(itemFromValue => {
          if (
            itemFromValue.id === item.id &&
            Boolean(item.isFolder) === Boolean(itemFromValue.isFolder)
          )
            return false

          return true
        })
        if (disabledChildren.length > 0) {
          return [...selectedItemsWithoutItem, ...disabledChildren]
        }
        return selectedItemsWithoutItem
      }
    }

    // если айтем уже выбран, то просто удаляем его
    return selectedItems.filter(itemFromValue => {
      if (
        itemFromValue.id === item.id &&
        Boolean(item.isFolder) === Boolean(itemFromValue.isFolder)
      )
        return false

      return true
    })
  }

  /* Если айтем, на который нажали, не выбран */

  if (item.parentId) {
    // если выбран родитель, то удаляем его и добавляем всех его детей, кроме нажатого айтема
    const infoAboutParents = findParentOfItemInSelectedItems(
      { item, firstParent: undefined, child: undefined },
      selectedItems,
      treeMenuItems,
    )

    if (infoAboutParents?.item) {
      const selectedItemsWithoutParent = selectedItems.filter(itemFromValue => {
        if (
          itemFromValue.id === infoAboutParents.item.id &&
          Boolean(itemFromValue.isFolder) === Boolean(infoAboutParents.item.isFolder)
        )
          return false
        return true
      })
      if (infoAboutParents.firstParent?.id === infoAboutParents.item.id)
        return [
          ...selectedItemsWithoutParent,
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain,@typescript-eslint/no-non-null-assertion
          ...infoAboutParents.firstParent?.nodes?.filter(node => node.id !== item.id)!,
        ]

      return [
        ...selectedItemsWithoutParent,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain,@typescript-eslint/no-non-null-assertion
        ...infoAboutParents.firstParent?.nodes?.filter(node => node.id !== item.id)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain,@typescript-eslint/no-non-null-assertion
        ...infoAboutParents.item.nodes?.filter(node => node.id !== infoAboutParents.child.id)!,
      ]
    }

    // если после добавления этого айтема какая-то часть дерева станет полностью выбранной
    // то удаляем всех детей и детей детей и добавляем соответствующего родителя
    const parent = getParentFromUnitingChildren(item, selectedItems, treeMenuItems) as T

    if (parent) return [...removeAllNestedChildrenOfFolder(parent, selectedItems), { ...parent }]
  }

  // удаляем всех детей и добавляем сам айтем
  return [...removeAllNestedChildrenOfFolder(item, selectedItems), { ...item }]
}

export const onChangeSingleTreeMenu = <MTreeMenuItem extends TreeMenuItem>(
  item: MTreeMenuItem,
  selectedItem?: TreeMenuItem,
) => {
  if (!selectedItem) return item
  const isSelected = selectedItem.id === item.id && selectedItem.parentId === item.parentId

  return isSelected ? undefined : item
}

export const onChangeMultiTreeMenuWithQuantity = (
  item: TreeMenuItem,
  selectedItems: TreeMenuItem[],
  // возвращает плоский массив выбранных елементов без папок
): TreeMenuItem[] => {
  const itemSelected = selectedItems.find(
    itemFromValue =>
      itemFromValue.id === item.id && Boolean(itemFromValue.isFolder) === Boolean(item.isFolder),
  )

  // при изменении количества приходит тот же обьек с другим значение quantity
  if (itemSelected) {
    if (itemSelected.quantity === item.quantity) {
      // если айтем уже выбран, то просто удаляем его
      return selectedItems.filter(selectedItem => {
        const isSelected =
          selectedItem.id === item.id && Boolean(item.isFolder) === Boolean(selectedItem.isFolder)
        return !isSelected
      })
    } else {
      return selectedItems.map(selectedItem => {
        if (
          selectedItem.id === item.id &&
          Boolean(selectedItem.isFolder) === Boolean(item.isFolder)
        ) {
          return { ...selectedItem, quantity: item.quantity }
        } else {
          return selectedItem
        }
      })
    }
  }

  if (!item.isFolder) {
    return [...selectedItems, { ...item }]
  }

  return selectedItems
}

export const onChangeSingleTreeMenuWithQuantity = (
  item: TreeMenuItem,
  selectedItem?: TreeMenuItem,
) => {
  if (!selectedItem) return item

  if (selectedItem && selectedItem.quantity === item.quantity) {
    return undefined
  } else {
    return item
  }
}
