import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { EMPLOYEES_REVIEWS_AS_CLIENT, REVIEWS_QUERY_KEY } from './queryKeys'
import { BOOKINGS_QUERY_KEY } from '../booking'
import {
  DONE_NOTIFICATIONS_QUERY_KEY,
  NOTIFICATIONS_AGGREGATE_QUERY_KEY,
  NOTIFICATIONS_QUERY_KEY,
} from '../notification/queryKeys'
import { NOTIFICATION_STATUS } from '../notification/logic'

export const useLeaveReviewAsClient = () => {
  return useMutation(
    async (dto: {
      bookingId: number
      text: string
      rating: number
    }): Promise<{
      insertReviews: { affectedRows: number }
    }> => {
      return request(
        gql`
          mutation ($bookingId: Int!, $text: String!, $rating: smallint!) {
            insertReviews(objects: { bookingId: $bookingId, text: $text, rating: $rating }) {
              affectedRows: affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([EMPLOYEES_REVIEWS_AS_CLIENT])
        queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])
        queryClient.invalidateQueries([REVIEWS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export const useAcceptReview = () => {
  return useMutation(
    async (
      id: number,
    ): Promise<{
      updateReviewById?: { id: number | null }
    }> => {
      return request(
        gql`
          mutation ($id: Int!, $doneStatus: smallint!) {
            updateReviewById(pk_columns: { id: $id }, _set: { approved: true }) {
              id
            }
            updateNotifications(where: { reviewId: { _eq: $id } }, _set: { status: $doneStatus }) {
              affected_rows
            }
          }
        `,
        { id, doneStatus: NOTIFICATION_STATUS.read },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([REVIEWS_QUERY_KEY])
        queryClient.invalidateQueries([NOTIFICATIONS_AGGREGATE_QUERY_KEY])
        queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY])
        queryClient.invalidateQueries([DONE_NOTIFICATIONS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export const useArchiveReview = () => {
  return useMutation(
    async (
      id: number,
    ): Promise<{
      updateReviewById?: { id: number | null }
    }> => {
      return request(
        gql`
          mutation ($id: Int!, $doneStatus: smallint!) {
            updateReviewById(
              pk_columns: { id: $id }
              _set: { archived: "now()", approved: false }
            ) {
              id
            }
            updateNotifications(where: { reviewId: { _eq: $id } }, _set: { status: $doneStatus }) {
              affected_rows
            }
          }
        `,
        { id, doneStatus: NOTIFICATION_STATUS.read },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([REVIEWS_QUERY_KEY])
        queryClient.invalidateQueries([NOTIFICATIONS_AGGREGATE_QUERY_KEY])
        queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY])
        queryClient.invalidateQueries([DONE_NOTIFICATIONS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
