import {
  ServerAccountType,
  ServerCreateServiceReceiptType,
  ServerRevenueReasonType,
  TRANSACTION_TYPES,
  TransactionInsertInput,
  useCheckboxCreateServiceReceipt,
  useCreateTransactions,
  useFetchAccounts,
  useFetchRevenueReasons,
  useGetBranchDefaultAccountId,
} from '@expane/data'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { checkOnlyPositiveAmount, PLACEHOLDERS } from '@expane/logic/form'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { permissions } from '@expane/logic/permission'
import { getPercentOfAmount } from '@expane/logic/utils'
import {
  CloseButton,
  Dialog,
  Input,
  InputLabel,
  Modal,
  PlaceholderInput,
  SelectDropdown,
  usePopupOpenState,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps, useOpenDialog } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { EditButton } from 'ui/EditButton'
import { AccountSelectDropdown } from 'widgets/AccountSelectDropdown'
import { AddButton } from 'widgets/Buttons'
import { RevenueReasonDialog } from 'widgets/FinanceOperationsReasonsDialog/RevenueReason'
import { handleUpdateCreateReceiptResponse } from '@expane/logic/payment/checkbox'

type AddRevenueToAccountFormValues = {
  amount: string
  toAccountId: number
  revenueReason: number
}

interface AddRevenueDialogLogicProps extends Pick<DialogProps, 'closeDialog'> {
  accounts: ServerAccountType[]
  defaultAccountId: number
  revenueReasons: ServerRevenueReasonType[]
  branchId: number
}

const AddRevenueDialog: FC<Pick<DialogProps, 'closeDialog'>> = observer(({ closeDialog }) => {
  const branchId = store.branch.branchId
  const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)
  const { data: defaultAccountId, isLoading: isLoadingDefaultAccountId } =
    useGetBranchDefaultAccountId(branchId)
  const { data: revenueReasons, isLoading: isLoadingRevenueReasons } =
    useFetchRevenueReasons(branchId)

  const isLoading = isLoadingAccounts || isLoadingDefaultAccountId || isLoadingRevenueReasons
  const isNoData = !accounts || !revenueReasons || !defaultAccountId || !branchId

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

  if (isLoading) return <AddRevenueDialogPlaceholder />
  else if (isNoData) return null

  return (
    <AddRevenueDialogLogic
      closeDialog={closeDialog}
      accounts={accounts}
      defaultAccountId={defaultAccountId}
      revenueReasons={revenueReasons}
      branchId={branchId}
    />
  )
})

const AddRevenueDialogPlaceholder = () => {
  const { t } = useTranslation()

  return (
    <Modal>
      <Dialog>
        <Dialog.Title>{t('transactionNames.addRevenue')}</Dialog.Title>

        <Dialog.Body>
          <div className="flex mb-4">
            <PlaceholderInput label={t('amount')} className="w-1/2 mr-2" />
            <PlaceholderInput label={t('account.name')} className="w-1/2" />
          </div>
          <PlaceholderInput label={t('transaction.revenueReason')} className="w-full mb-3" />
        </Dialog.Body>

        <Dialog.Footer>
          <AddButton disabled />
          <CloseButton disabled className="mr-auto" />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

const AddRevenueDialogLogic: FC<AddRevenueDialogLogicProps> = ({
  closeDialog,
  accounts,
  defaultAccountId,
  revenueReasons,
  branchId,
}) => {
  const { t } = useTranslation()
  const { data: myPermissions } = useFetchMyPermissions()

  const { formState, control, handleSubmit } = useForm<AddRevenueToAccountFormValues>({
    defaultValues: {
      amount: '',
      toAccountId: defaultAccountId,
      revenueReason: revenueReasons?.[0]?.id,
    },
  })

  const modalRef = useRef<HTMLDivElement>(null)

  const focusToModal = () => {
    modalRef.current?.focus()
  }

  const { mutateAsync: createTransactions } = useCreateTransactions()
  const { mutateAsync: createServiceReceipt } = useCheckboxCreateServiceReceipt()

  const { getModuleSetting } = useBusinessModulesSettings()
  const isCheckboxEnabled = getModuleSetting('checkbox')

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackBar] = useSnackbar()

  const { openCreateDialog: openRevenueReasonDialog, dialog: revenueReasonDialog } =
    useOpenDialog(RevenueReasonDialog)

  const mutateTransaction: SubmitHandler<AddRevenueToAccountFormValues> = async data => {
    const { amount, toAccountId, revenueReason } = data
    const currentAccount = accounts.find(account => account.id === toAccountId)

    try {
      const softwarePOSId = currentAccount?.accountSoftwarePOS?.[0]?.softwarePOS.id
      let receiptId: string | undefined
      if (softwarePOSId && isCheckboxEnabled) {
        const result = await createServiceReceipt({
          value: Number(amount),
          type: ServerCreateServiceReceiptType.Transaction,
          softwarePOSId,
        })

        receiptId = handleUpdateCreateReceiptResponse({
          t,
          onError: message => openSnackBar(message, 'error', 3000),
          createReceiptResult: result.checkboxCreateServiceReceipt,
        })

        if (!receiptId) return false
      }

      const transactionsDto: TransactionInsertInput[] = [
        {
          type: TRANSACTION_TYPES.revenue.id,
          amount: Number(amount),
          toAccountId,
          revenueReasonId: revenueReason,
          branchId,
          transactionReceipts:
            receiptId && softwarePOSId ? { data: [{ receiptId, softwarePOSId }] } : null,
        },
      ]
      if (currentAccount?.commission) {
        const commissionAmount = getPercentOfAmount(Number(amount), currentAccount.commission)
        transactionsDto.push({
          fromAccountId: toAccountId,
          type: TRANSACTION_TYPES.expenses.id,
          amount: commissionAmount,
          branchId,
        })
      }
      await createTransactions({ paymentTransactions: transactionsDto })

      openSnackBar(t('transaction.revenueAddedSuccessfully'), 'success')
    } catch (error) {
      if (isRestrictionError(error)) openSnackBar(t('planRestriction'), 'error')
      else if (isPlanError(error)) openSnackBar(t('planInfo.noPlan'), 'error')
      else openSnackBar(t('submitError'), 'error')
    }

    closeDialog()
  }

  const isRevenueReasonEditingAllowed = myPermissions?.includes(permissions.revenueReason.set)

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (!formState.isSubmitting && formState.isDirty) handleSubmit(mutateTransaction)()
        }}
        ref={modalRef}
        animation="onlyFadeOut"
      >
        <Dialog>
          <Dialog.Title>{t('transactionNames.addRevenue')}</Dialog.Title>

          <Dialog.Body>
            <div className="flex">
              <Controller
                name="amount"
                control={control}
                rules={{ required: true, validate: checkOnlyPositiveAmount }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="number"
                    label={t('amount')}
                    placeholder={PLACEHOLDERS.servicePrice}
                    value={value}
                    onChange={onChange}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.amount),
                      text:
                        formState.errors.amount?.type === 'required'
                          ? t('formError.required')
                          : t('formError.invalidValue'),
                    }}
                    containerClassName="w-1/2 mr-2"
                    autoFocus
                  />
                )}
              />

              <Controller
                name="toAccountId"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <AccountSelectDropdown
                    value={value}
                    label={t('account.name')}
                    accounts={accounts}
                    onSelectChange={onChange}
                    popupHeight="small"
                    errorMessage={{
                      isShown: Boolean(formState.errors.toAccountId),
                      text: t('formError.required'),
                    }}
                    className="w-1/2"
                    required
                  />
                )}
              />
            </div>

            <InputLabel label={t('transaction.revenueReason')} />

            <div className="flex items-center justify-between">
              <Controller
                name="revenueReason"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <SelectDropdown
                    value={value}
                    placeholder={t('placeholders.defaultSelect')}
                    items={revenueReasons}
                    noDataMessage={
                      revenueReasons?.length === 0
                        ? t('emptyPlaceholder.revenueReason')
                        : t('noSuchRevenueReason')
                    }
                    onSelectChange={onChange}
                    popupHeight="small"
                    errorMessage={{
                      isShown: Boolean(formState.errors.revenueReason),
                      text: t('formError.required'),
                    }}
                    required
                    className="w-full"
                  />
                )}
              />

              {isRevenueReasonEditingAllowed && (
                <EditButton
                  onClick={() => openRevenueReasonDialog(focusToModal)}
                  className="ml-1 mb-4"
                />
              )}
            </div>
          </Dialog.Body>

          <Dialog.Footer>
            <AddButton
              onClick={handleSubmit(mutateTransaction)}
              disabled={formState.isSubmitting || !formState.isDirty}
              spinner={formState.isSubmitting}
            />

            <CloseButton
              onClick={closePopups}
              disabled={formState.isSubmitting}
              className="mr-auto"
            />
          </Dialog.Footer>
        </Dialog>
      </Modal>
      {revenueReasonDialog}
      {confirmPopup}
    </>
  )
}

export const useOpenAddRevenueDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()

  const openAddRevenueDialog = (onClose?: () => void) => {
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const addRevenueDialog = isOpen ? <AddRevenueDialog closeDialog={closeDialog} /> : null

  return { openAddRevenueDialog, addRevenueDialog }
}
