import {
  SALARY_ISSUE_TYPES,
  ServerSalaryIssueType,
  useFetchCurrentBranchTimezone,
  useFetchSalaryIssuesByDateAndTypes,
} from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import {
  calcReportItemsCreditTotalPrice,
  calcReportItemsCreditTotalQuantity,
  calcReportItemsTotalPrice,
  groupReportItemsById,
} from '@expane/logic/reports/logic'
import {
  calcServiceReportItemsTotalQuantity,
  convertTransactionsToServiceReportItem,
  getNotGroupServiceReportItems,
  getServiceReportItemsNotByCard,
  ServiceReportItemType,
} from '@expane/logic/reports/services'
import { getServiceSalaryIssueTotalSum } from '@expane/logic/salaryIssues/converting'
import {
  EmptyPlaceholder,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { ReportProps } from 'pages/ReportsPage/index'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ServicesReport: FC<PropsWithBranchId<ReportProps>> = ({
  transactions,
  startDate,
  endDate,
  branchId,
}) => {
  const { t } = useTranslation()

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: salaryIssues } = useFetchSalaryIssuesByDateAndTypes({
    from: startDate,
    to: endDate,
    types: [
      SALARY_ISSUE_TYPES.service,
      SALARY_ISSUE_TYPES.quickSaleService,
      SALARY_ISSUE_TYPES.serviceWithActualSoldPrice,
    ],
    timezone,
    branchId,
  })

  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const serviceReportItems = convertTransactionsToServiceReportItem(transactions)
  // отчет по разовым услугам (убираем услуги по абонементу и групповые)
  const serviceReportItemsWithoutCard = getServiceReportItemsNotByCard(serviceReportItems)
  const filteredServiceReportItems = getNotGroupServiceReportItems(serviceReportItemsWithoutCard)

  // группируем по услуге
  const groupedServices = groupReportItemsById(filteredServiceReportItems)

  const creditTotalQuantity = calcReportItemsCreditTotalQuantity(filteredServiceReportItems)
  const creditTotalPrice = calcReportItemsCreditTotalPrice(filteredServiceReportItems)
  const totalPrice = calcReportItemsTotalPrice(filteredServiceReportItems)

  // в отчете услуги только с разовой оплатой, поэтому исключаем те, которые были использованы по абонементу
  const filteredServiceSalaryIssue = salaryIssues?.filter(
    issue => issue.paymentTransaction?.cardId === null,
  )
  const totalSalary = calcServiceSalaryIssue(filteredServiceSalaryIssue ?? [])

  const servicesQuantity = calcServiceReportItemsTotalQuantity(filteredServiceReportItems)

  const serviceIdsToDisplay = Object.keys(groupedServices)
  if (serviceIdsToDisplay.length === 0)
    return <EmptyPlaceholder text={t('emptyPlaceholder.archive')} />

  return (
    <TableContainer>
      <TableHeader>
        <tr>
          <TableHeaderCell>{t('name')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('qty')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('reports.inLoan')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('amount')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('reports.inLoanAmount')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('salary.name')}</TableHeaderCell>
        </tr>
      </TableHeader>

      <TableBody>
        {serviceIdsToDisplay.map(serviceId => (
          <ServiceReportItem
            key={serviceId}
            serviceReportItems={groupedServices[serviceId]}
            name={serviceReportItems.find(item => item.id === Number(serviceId))?.name ?? ''}
            salaryIssues={
              filteredServiceSalaryIssue?.filter(
                salaryIssue => salaryIssue.salarySettingService?.service.id === Number(serviceId),
              ) ?? []
            }
            branchId={branchId}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableCell>{t('total')}</TableCell>
        <TableCell>
          <div className="text-right">{servicesQuantity}</div>
        </TableCell>
        <TableCell>
          <div className="text-right">{creditTotalQuantity}</div>
        </TableCell>
        <TableCell>
          <div className="text-right">{convertNumberToMoney(totalPrice)}</div>
        </TableCell>
        <TableCell>
          <div className="text-right">{convertNumberToMoney(creditTotalPrice)}</div>
        </TableCell>
        <TableCell>
          <div className="text-right">{convertNumberToMoney(totalSalary)}</div>
        </TableCell>
      </TableFooter>
    </TableContainer>
  )
}

interface ServiceReportItemProps {
  name: string
  serviceReportItems: ServiceReportItemType[]
  salaryIssues: ServerSalaryIssueType[]
}

const ServiceReportItem: FC<PropsWithBranchId<ServiceReportItemProps>> = ({
  name,
  serviceReportItems,
  salaryIssues,
  branchId,
}) => {
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const creditTotalQuantity = calcReportItemsCreditTotalQuantity(serviceReportItems)
  const creditTotalPrice = calcReportItemsCreditTotalPrice(serviceReportItems)
  const totalPrice = calcReportItemsTotalPrice(serviceReportItems)
  const servicesQuantity = calcServiceReportItemsTotalQuantity(serviceReportItems)
  const totalSalary = calcServiceSalaryIssue(salaryIssues)

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>
        <div className="text-right">{servicesQuantity}</div>
      </TableCell>
      <TableCell>
        <div className="text-right">{creditTotalQuantity}</div>
      </TableCell>
      <TableCell>
        <div className="text-right">{convertNumberToMoney(totalPrice)}</div>
      </TableCell>
      <TableCell>
        <div className="text-right">{convertNumberToMoney(creditTotalPrice)}</div>
      </TableCell>

      <TableCell>
        <div className="text-right">{convertNumberToMoney(totalSalary)}</div>
      </TableCell>
    </TableRow>
  )
}

const calcServiceSalaryIssue = (salaryIssues: ServerSalaryIssueType[]) =>
  salaryIssues.reduce((acc, value) => acc + getServiceSalaryIssueTotalSum(value), 0)
