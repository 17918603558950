import { FC } from 'react'
import { PersonWithPhoto, transformPersonName } from '@expane/logic/utils'
import { Avatar } from 'ui/Avatar'
import { config } from 'config'

export const PersonCell: FC<{
  person: PersonWithPhoto
}> = ({ person }) => {
  const fullName = transformPersonName(person)

  return (
    <div className="flex items-center gap-1">
      <Avatar
        url={person.photo?.length ? person.photo : config.PHOTO_PLACEHOLDER_URL}
        name={fullName}
        className="mr-2"
      />
      <p>{fullName}</p>
    </div>
  )
}
