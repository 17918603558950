import { useFetchCurrentBranchTimezone } from '@expane/data'
import { useConvertNumberToMoney, useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { ItemWithModules, useBusinessModulesSettings } from '@expane/logic/modules'
import { BookingPaymentData } from '@expane/logic/payment/booking'
import { calcConsumableTotalSum } from '@expane/logic/payment/booking/consumables'
import { calcServiceTotalSum } from '@expane/logic/payment/booking/services'
import { convertUnitValueFromServer, useGetUnitsNameForConsumable } from '@expane/logic/product'
import { calcDiscountSum } from '@expane/logic/utils'
import {
  DeleteButtonCell,
  Input,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { useFetchMyEmployee } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { Control, Controller, useFieldArray, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCheckmark, IoRemove } from 'react-icons/io5'
import { store } from 'store'
import { DiscountInput, DiscountInputValue } from 'ui/DiscountInput'
import { PercentIcon } from 'ui/Icons'
import { Checkbox } from '@expane/ui'

type Props = {
  control: Control<BookingPaymentData>
  setValue: UseFormSetValue<BookingPaymentData>
}

export const InvoiceTable: FC<Props> = observer(({ control, setValue }) => {
  const branchId = store.branch.branchId

  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoney(branchId)
  const convertNumberToMoneyCode = useConvertNumberToMoneyCode({ branchId })

  const getUnitName = useGetUnitsNameForConsumable(branchId)

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: myEmployee } = useFetchMyEmployee(timezone, branchId)
  const maxDiscount = myEmployee?.employeeGroup?.maxDiscount ?? 0

  const { getFilteredItemsByModules, getModuleSetting } = useBusinessModulesSettings()
  const isSubscriptionsEnabled = getModuleSetting('subscriptions')

  const { fields: serviceFields, remove: removeService } = useFieldArray({
    control,
    name: 'servicesDto',
  })
  const { fields: consumablesFields, remove: removeConsumable } = useFieldArray({
    control,
    name: 'consumablesDto',
  })
  const watchedServicesDto = useWatch({ control, name: 'servicesDto' })
  const watchedConsumablesDto = useWatch({ control, name: 'consumablesDto' })

  const totalServicesSum = calcServiceTotalSum(watchedServicesDto)
  const totalConsumablesSum = calcConsumableTotalSum(watchedConsumablesDto)
  const totalSum = totalConsumablesSum + totalServicesSum

  const [isDiscountForEveryone, setIsDiscountForEveryone] = useState(false)
  const [discountForEveryone, setDiscountForEveryone] = useState('')

  const setDiscounts = (value: string) => {
    const discount: DiscountInputValue = { value, type: 'percent' }

    const updatedServices = watchedServicesDto.map(field => ({ ...field, discount }))
    setValue('servicesDto', updatedServices)

    const updatedConsumables = watchedConsumablesDto.map(field => ({ ...field, discount }))
    setValue('consumablesDto', updatedConsumables)
  }

  return (
    <div className="flex-1 flex flex-col overflow-y-auto">
      <TableContainer className="text-sm">
        <TableHeader>
          <tr>
            {getFilteredItemsByModules(getTitles(isSubscriptionsEnabled)).map(
              ({ title, width }) => (
                <TableHeaderCell key={title} className={width}>
                  {t(title)}
                </TableHeaderCell>
              ),
            )}
            <TableHeaderCell />
          </tr>
        </TableHeader>
        <TableBody>
          {serviceFields.map(({ serviceId, bookingId, subscriptionId, name }, index) => {
            return (
              <TableRow key={`service ${serviceId} ${bookingId}`}>
                <Controller
                  control={control}
                  name={`servicesDto.${index}`}
                  rules={{
                    validate: value => {
                      if (value.discount.type === 'percent') {
                        return Number(value.discount.value) <= maxDiscount
                      }
                      if (value.discount.type === 'fixed') {
                        return Number(value.discount.value) <= (value.price / 100) * maxDiscount
                      }
                    },
                  }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => {
                    const discountSum = calcDiscountSum({
                      discount: value.discount,
                      price: value.price,
                    })
                    const sum = convertNumberToMoney(value.price - discountSum)
                    return (
                      <>
                        <TableCell>{name}</TableCell>
                        <TableCell>{convertNumberToMoney(value.price)}</TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          <DiscountInput
                            value={value.discount}
                            onChange={e => {
                              if (e.type === 'percent') {
                                if (Number(e.value) >= 0 && Number(e.value) <= 100)
                                  onChange({
                                    ...value,
                                    discount: { value: e.value, type: e.type },
                                  })
                              }

                              if (e.type === 'fixed') {
                                if (Number(e.value) <= value.price)
                                  onChange({
                                    ...value,
                                    discount: { value: e.value, type: e.type },
                                  })
                              }
                            }}
                            height="small"
                            errorMessage={{
                              isShown: Boolean(error),
                              text: t('upToPercent', { percent: maxDiscount }),
                              reserveIndent: Boolean(error),
                            }}
                            disabled={
                              isDiscountForEveryone || !maxDiscount || Boolean(subscriptionId)
                            }
                            disabledReason={
                              !maxDiscount && !isDiscountForEveryone
                                ? t('discountPermission')
                                : undefined
                            }
                          />
                        </TableCell>
                        {isSubscriptionsEnabled && (
                          <TableCell>
                            <div className="flex-centered pr-4 text-primary-300">
                              {subscriptionId ? (
                                <IoCheckmark size="1.2rem" />
                              ) : (
                                <IoRemove size="1.2rem" />
                              )}
                            </div>
                          </TableCell>
                        )}

                        <TableCell>{subscriptionId ? 0 : sum}</TableCell>
                        <DeleteButtonCell onClick={() => removeService(index)} />
                      </>
                    )
                  }}
                />
              </TableRow>
            )
          })}
          {consumablesFields.map(({ productId, bookingId, quantity, price }, index) => {
            return (
              <TableRow key={`consumable ${productId} ${bookingId}`}>
                <Controller
                  name={`consumablesDto.${index}`}
                  control={control}
                  rules={{
                    validate: value => {
                      if (value.discount.type === 'percent') {
                        return Number(value.discount.value) <= maxDiscount
                      }
                      if (value.discount.type === 'fixed') {
                        return (
                          Number(value.discount.value) <=
                          ((value.price * Number(value.quantity)) / 100) * maxDiscount
                        )
                      }
                    },
                  }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => {
                    const convertedQuantity = convertUnitValueFromServer(
                      Number(quantity),
                      value.unit,
                    )
                    const totalProductPrice = value.price * convertedQuantity
                    const totalItemPrice = price * (convertedQuantity ?? 1)
                    const discountSum = calcDiscountSum({
                      discount: value.discount,
                      price: totalItemPrice,
                    })
                    const sum = convertNumberToMoney(totalItemPrice - discountSum)
                    return (
                      <>
                        <TableCell>{value.name}</TableCell>
                        <TableCell>{convertNumberToMoney(value.price)}</TableCell>
                        <TableCell>
                          <Input type="number" value={quantity} height="small" disabled={true} />
                        </TableCell>
                        <TableCell>{t(getUnitName(productId))}</TableCell>

                        <TableCell>
                          <DiscountInput
                            value={value.discount}
                            onChange={newDiscount => {
                              if (newDiscount.type === 'percent') {
                                if (
                                  Number(newDiscount.value) >= 0 &&
                                  Number(newDiscount.value) <= 100
                                )
                                  onChange({ ...value, discount: newDiscount })
                              }

                              if (newDiscount.type === 'fixed') {
                                if (Number(newDiscount.value) <= totalProductPrice)
                                  onChange({ ...value, discount: newDiscount })
                              }
                            }}
                            disabled={isDiscountForEveryone || !maxDiscount}
                            height="small"
                            errorMessage={{
                              isShown: Boolean(error),
                              text: `${t('until')} ${maxDiscount}%`,
                              reserveIndent: false,
                            }}
                          />
                        </TableCell>
                        {isSubscriptionsEnabled && (
                          <TableCell>
                            <div className="flex-centered pr-4 text-primary-300">
                              <IoRemove size="1.2rem" />
                            </div>
                          </TableCell>
                        )}

                        <TableCell>{sum}</TableCell>
                        <DeleteButtonCell onClick={() => removeConsumable(index)} />
                      </>
                    )
                  }}
                />
              </TableRow>
            )
          })}
        </TableBody>
      </TableContainer>

      <div className="mt-3 flex items-baseline justify-end">
        <Checkbox
          label={t('applyDiscountEntireReceipt')}
          checked={isDiscountForEveryone}
          onChange={e => {
            setIsDiscountForEveryone(e.currentTarget.checked)
            if (e.currentTarget.checked) {
              setDiscounts(discountForEveryone)
            } else {
              setDiscounts('')
              setDiscountForEveryone('')
            }
          }}
          disabled={!maxDiscount}
        />
        <Input
          Icon={PercentIcon}
          type="number"
          value={discountForEveryone}
          onChange={e => {
            if (Number(e.target.value) >= 0 && Number(e.target.value) <= 100) {
              setDiscountForEveryone(e.currentTarget.value)
              setDiscounts(e.currentTarget.value)
            }
          }}
          containerClassName="w-20 ml-2"
          disabled={!isDiscountForEveryone || !maxDiscount}
          height="small"
          errorMessage={{
            isShown: Number(discountForEveryone) > maxDiscount,
            text: t('upToPercent', { percent: maxDiscount }),
          }}
        />
        <span className="ml-3 font-medium text-label-color min-w-40">
          {t('total')}: {convertNumberToMoneyCode(totalSum)}
        </span>
      </div>
    </div>
  )
})

export const getTitles = (
  isSubscriptionsEnabled: boolean,
): Array<ItemWithModules<{ title: string; width: string }>> => [
  { title: 'name', width: isSubscriptionsEnabled ? 'w-80' : 'w-96' },
  { title: 'price', width: isSubscriptionsEnabled ? 'w-28' : 'w-32' },
  { title: 'qty', width: isSubscriptionsEnabled ? 'w-28' : 'w-32' },
  { title: 'unit.shortName', width: 'w-20' },
  { title: 'discount', width: 'w-28' },
  { title: 'subscription.name', width: 'w-28', modules: ['subscriptions'] },
  { title: 'amount', width: isSubscriptionsEnabled ? 'w-28' : 'w-32' },
]
