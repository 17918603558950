import {
  EmployeeGroupWithSchedule,
  ExtendedEmployee,
  SALARY_ISSUE_TYPES,
  ServerSalaryIssueType,
  ServerSalaryRateSettingType,
} from '@expane/data'
import { isDateSameDayOrBefore, isWithinInterval } from '@expane/date'
import { getSalaryIssuesByPeriod } from './helpers'

export const getRateSalaryIssues = (salaryIssues: ServerSalaryIssueType[]) =>
  salaryIssues.filter(salaryIssue => salaryIssue.type === SALARY_ISSUE_TYPES.salaryRate)

export const getServicesSalaryIssues = (salaryIssues: ServerSalaryIssueType[]) =>
  salaryIssues.filter(
    salaryIssue =>
      salaryIssue.type === SALARY_ISSUE_TYPES.service ||
      salaryIssue.type === SALARY_ISSUE_TYPES.quickSaleService ||
      salaryIssue.type === SALARY_ISSUE_TYPES.serviceWithActualSoldPrice,
  )

export const getPremiumAndPenaltySalaryIssues = (salaryIssues: ServerSalaryIssueType[]) =>
  salaryIssues.filter(
    salaryIssue =>
      salaryIssue.type === SALARY_ISSUE_TYPES.premium ||
      salaryIssue.type === SALARY_ISSUE_TYPES.penalty,
  )

export const filterSalaryIssuesByEmployeeId = (
  salaryIssues: ServerSalaryIssueType[],
  employeeId: number,
) => salaryIssues.filter(salaryIssue => salaryIssue.employeeId === employeeId)

export const getEmployeeGroupsWithEmployeesWithSalarySettings = (
  employeeGroups: EmployeeGroupWithSchedule[] | undefined,
  fromDate: Date,
  toDate: Date,
) => {
  if (!employeeGroups) return []

  const groupsWithEmployeesWithSalarySettings: EmployeeGroupWithSchedule[] = []

  for (const group of employeeGroups) {
    if (group.employees) {
      groupsWithEmployeesWithSalarySettings.push({
        ...group,
        employees: group.employees.filter(
          employee =>
            employee.salarySettings.length &&
            employee.salarySettings.some(
              setting =>
                isWithinInterval(setting.start, {
                  start: fromDate,
                  end: toDate,
                }) || isDateSameDayOrBefore(setting.start, fromDate),
            ),
        ),
      })
    }
  }

  return groupsWithEmployeesWithSalarySettings.filter(group => group.employees.length)
}

export const getEmployeesWithSalarySettings = (employees: ExtendedEmployee[] | undefined) =>
  employees?.filter(employee => employee.salarySettings_aggregate)

export const filterSalaryRateSettingsByEmployeeId = (
  salaryRateSettings: ServerSalaryRateSettingType[] | undefined,
  employeeId: number,
) => salaryRateSettings?.filter(setting => setting.employeeId === employeeId)

export type SalariesIssuesTypes = {
  referralSalaryIssuesInPreviousPeriod: ServerSalaryIssueType[]
  servicesSalaryIssuesInPreviousPeriod: ServerSalaryIssueType[]
  premiumsSalaryIssuesInPreviousPeriod: ServerSalaryIssueType[]
  servicesSalaryIssuesInSelectedPeriod: ServerSalaryIssueType[]
  referralSalaryIssuesInSelectedPeriod: ServerSalaryIssueType[]
  premiumsSalaryIssuesInSelectedPeriod: ServerSalaryIssueType[]
  salaryRatesSalaryIssues: ServerSalaryIssueType[]
}

export const getSalariesIssuesByTypesAndPeriod = ({
  salaryIssues,
  fromDate,
  toDate,
}: {
  salaryIssues: ServerSalaryIssueType[] | undefined
  fromDate: Date
  toDate: Date
}): SalariesIssuesTypes => {
  const { salaryIssuesInPreviousPeriod, salaryIssuesInSelectedPeriod } = getSalaryIssuesByPeriod(
    salaryIssues ?? [],
    fromDate,
    toDate,
  )

  const servicesSalaryIssuesInPreviousPeriod = getServicesSalaryIssues(salaryIssuesInPreviousPeriod)
  const referralSalaryIssuesInPreviousPeriod = getReferralPercentageSalaryIssues(
    salaryIssuesInPreviousPeriod,
  )
  const premiumsSalaryIssuesInPreviousPeriod = getPremiumAndPenaltySalaryIssues(
    salaryIssuesInPreviousPeriod,
  )

  const servicesSalaryIssuesInSelectedPeriod = getServicesSalaryIssues(salaryIssuesInSelectedPeriod)
  const referralSalaryIssuesInSelectedPeriod = getReferralPercentageSalaryIssues(
    salaryIssuesInSelectedPeriod,
  )
  const premiumsSalaryIssuesInSelectedPeriod = getPremiumAndPenaltySalaryIssues(
    salaryIssuesInSelectedPeriod,
  )

  const salaryRatesSalaryIssues = getRateSalaryIssues(salaryIssues ?? [])

  return {
    referralSalaryIssuesInPreviousPeriod,
    servicesSalaryIssuesInPreviousPeriod,
    premiumsSalaryIssuesInPreviousPeriod,
    servicesSalaryIssuesInSelectedPeriod,
    referralSalaryIssuesInSelectedPeriod,
    premiumsSalaryIssuesInSelectedPeriod,
    salaryRatesSalaryIssues,
  }
}

export const getReferralPercentageSalaryIssues = (salaryIssues: ServerSalaryIssueType[]) =>
  salaryIssues.filter(salaryIssue => salaryIssue.type === SALARY_ISSUE_TYPES.referralPercentage)
