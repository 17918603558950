import {
  ServerLocationWithServiceIdsType,
  ServerMutationRootBranchImportServicesArgs,
} from '@expane/data'
import { TreeMenuItem } from 'ui/TreeMenu'

const extractFolderIds = (items: TreeMenuItem[], ids: number[]) => {
  for (const item of items) {
    if (item.isFolder) {
      ids.push(item.id)
      if (item.nodes) {
        extractFolderIds(item.nodes, ids)
      }
    }
  }
}
export type PickedService = {
  id: number
  name: string
  price: string
  locations: ServerLocationWithServiceIdsType[]
}
export const prepareDtoForBranchImportServices = ({
  selectedItems,
  ignoreGroups,
  branchId,
  serviceDtos,
}: {
  selectedItems: TreeMenuItem[]
  ignoreGroups: boolean
  branchId: number
  serviceDtos: PickedService[]
}): ServerMutationRootBranchImportServicesArgs => {
  const serviceGroupIds: number[] = []
  if (!ignoreGroups) {
    extractFolderIds(selectedItems, serviceGroupIds)
  }

  return {
    serviceGroupIds,
    toBranchId: branchId,
    serviceDtos: serviceDtos.map(serviceDto => ({
      id: serviceDto.id,
      price: Number(serviceDto.price),
      locationIds: serviceDto.locations.map(location => location.id),
    })),
  }
}
