import { FC } from 'react'
import {
  useFetchBranchById,
  useFetchCardTemplateById,
  useFetchExtendedInterbranchServices,
  useFetchExtendedServices,
  useFetchServiceGroups,
} from '@expane/data'
import { SubscriptionDialogLogic, SubscriptionDialogPlaceholder } from './SubscriptionDialogLogic'
import { store } from 'store'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'

export const SubscriptionDialog: FC<DialogProps> = ({
  id: subscriptionId,
  closeDialog,
  isCreate,
}) => {
  const branchId = store.branch.branchId

  const { data: branch, isLoading: branchIsLoading } = useFetchBranchById(branchId)
  const { data: subscriptionById, isLoading: subscriptionByIdIsLoading } = useFetchCardTemplateById(
    subscriptionId,
    branch?.timezone,
  )
  const { data: services, isLoading: servicesIsLoading } = useFetchExtendedServices(
    branchId,
    'service',
  )
  const { data: interbranchServices, isLoading: interbranchServicesIsLoading } =
    useFetchExtendedInterbranchServices()
  const { data: serviceGroups, isLoading: servicesGroupIsLoading } = useFetchServiceGroups(branchId)

  const isLoading =
    (subscriptionByIdIsLoading && !isCreate) ||
    servicesIsLoading ||
    servicesGroupIsLoading ||
    branchIsLoading ||
    interbranchServicesIsLoading
  const isNoData =
    (!isCreate && !subscriptionById) ||
    !branch ||
    !services ||
    !serviceGroups ||
    !interbranchServices

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
  if (isLoading) return <SubscriptionDialogPlaceholder closeDialog={closeDialog} />
  else if (isNoData) return null

  return (
    <SubscriptionDialogLogic
      currentBranch={branch}
      subscriptionById={subscriptionById}
      closeDialog={closeDialog}
      services={services}
      interbranchServices={interbranchServices}
      serviceGroups={serviceGroups}
      isCreate={isCreate}
    />
  )
}
