import { Paper } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoWarningOutline } from 'react-icons/io5'

export const OfflinePage: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex-centered flex-1 flex-col bg-main overflow-hidden">
      <Paper className="w-176 min-h-88 flex-centered flex-col p-12 text-gray-600 dark:text-gray-300">
        <IoWarningOutline size="8rem" className="text-orange-400" />
        <div className="text-center border-b-2 ">
          <h2 className="mt-3 text-3xl">{t('offlineServerPage.1')}</h2>
          <p className="my-3 text-secondary-color">{t('offlineServerPage.2')}</p>
        </div>

        <div className="mt-5 text-center text-secondary-color">{t('offlineServerPage.3')}🥺</div>
      </Paper>
    </div>
  )
}
