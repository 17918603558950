import { useFetchArchivedSubscriptions } from '@expane/data'
import { Page } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { SubscriptionsPageList } from 'pages/SubscriptionsPage/List'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { ListNavigationButton } from 'widgets/NavigationButtons'
import { SubscriptionDialog } from 'widgets/SubscriptionDialog'

export const ArchiveSubscriptionsPage: FC = () => {
  const { t } = useTranslation()
  const branchId = store.branch.branchId

  const { data: subscriptions, isLoading } = useFetchArchivedSubscriptions(branchId)

  const { openEditDialog, dialog } = useOpenDialog(SubscriptionDialog)

  return (
    <Page>
      <ListNavigationButton title={t('subscriptions')} className="mb-2" />

      <SubscriptionsPageList
        onRowClick={subscription => openEditDialog(subscription.id)}
        subscriptions={subscriptions}
        isLoading={isLoading}
        archive
        branchId={branchId}
      />
      {dialog}
    </Page>
  )
}
