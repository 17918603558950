import {
  ManipulateSuperAdminResponseCodes,
  ServerManipulateSuperAdminTypes,
  useManipulateSuperAdmin,
} from '@expane/data'
import { Button, InputLabel, Paper } from '@expane/ui'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline, IoCloseCircleOutline } from 'react-icons/io5'
import { useSnackbar } from '@expane/widgets'

export const ManipulateSuperAdminWidget: FC = () => {
  const { t } = useTranslation()
  const [openSnackbar] = useSnackbar()

  const [isLoadingCheck, setIsLoadingCheck] = useState<boolean>(true)
  const [code, setCode] = useState<ManipulateSuperAdminResponseCodes>(
    ManipulateSuperAdminResponseCodes.success,
  )
  const { mutateAsync: manipulateSuperAdmin, isLoading: isLoadingManipulateSuperAdmin } =
    useManipulateSuperAdmin()

  const checkSuperAdmin = useCallback(async () => {
    const result = await manipulateSuperAdmin(ServerManipulateSuperAdminTypes.Check)

    setCode(result.manipulateSuperAdmin.code)
    setIsLoadingCheck(false)
  }, [manipulateSuperAdmin])

  useEffect(() => {
    checkSuperAdmin().then()
  }, [checkSuperAdmin])

  const createSuperAdmin = async () => {
    const result = await manipulateSuperAdmin(ServerManipulateSuperAdminTypes.Create)
    if (result.manipulateSuperAdmin.code === ManipulateSuperAdminResponseCodes.success) {
      openSnackbar(t('manipulateSuperAdmin.addedSuccessfully'), 'success')
    } else openSnackbar(t('submitError'), 'error')

    await checkSuperAdmin()
  }
  const deleteSuperAdmin = async () => {
    const result = await manipulateSuperAdmin(ServerManipulateSuperAdminTypes.Delete)

    if (result.manipulateSuperAdmin.code === ManipulateSuperAdminResponseCodes.deleted) {
      openSnackbar(t('manipulateSuperAdmin.deletedSuccessfully'), 'success')
    } else openSnackbar(t('submitError'), 'error')

    await checkSuperAdmin()
  }

  const isLoading = isLoadingCheck || isLoadingManipulateSuperAdmin

  return (
    <div className="mt-4">
      <InputLabel label={t('manipulateSuperAdmin.description')} />

      <Paper className="flex gap-4 mt-2 items-center py-1.5 px-3 h-16 text-secondary-color">
        {code === ManipulateSuperAdminResponseCodes.alreadyCreated && (
          <>
            <Button
              onClick={deleteSuperAdmin}
              type="primary"
              Icon={IoCloseCircleOutline}
              disabled={isLoading}
              spinner={isLoading}
              className="w-86"
            >
              {t('manipulateSuperAdmin.delete')}
            </Button>

            <div>
              <p>
                {t('manipulateSuperAdmin.account')}{' '}
                <span className="text-primary-200">{t('manipulateSuperAdmin.accountOn')}</span>
              </p>
              <p>{t('manipulateSuperAdmin.canDelete')}</p>
            </div>
          </>
        )}

        {code === ManipulateSuperAdminResponseCodes.doesNotHaveSuperAdmin && (
          <>
            <Button
              onClick={createSuperAdmin}
              type="primary"
              Icon={IoAddCircleOutline}
              disabled={isLoading}
              spinner={isLoading}
              className="w-86"
            >
              {t('manipulateSuperAdmin.add')}
            </Button>

            <div>
              <p>
                {t('manipulateSuperAdmin.account')}{' '}
                <span className="text-error-200">{t('manipulateSuperAdmin.accountOff')}</span>
              </p>
              <p>{t('manipulateSuperAdmin.canAdd')}</p>
            </div>
          </>
        )}

        {checkIsErrorShown(code) && (
          <p className="text-error-500">{t('manipulateSuperAdmin.error')}</p>
        )}
      </Paper>
    </div>
  )
}

const checkIsErrorShown = (code: ManipulateSuperAdminResponseCodes): boolean =>
  code !== ManipulateSuperAdminResponseCodes.doesNotHaveSuperAdmin &&
  code !== ManipulateSuperAdminResponseCodes.alreadyCreated &&
  code !== ManipulateSuperAdminResponseCodes.success &&
  code !== ManipulateSuperAdminResponseCodes.deleted
