import {
  useFetchBookingById,
  useFetchBranchById,
  useFetchExtendedEmployees,
  useFetchLocations,
} from '@expane/data'
import { defineDayTiming } from '@expane/logic/calendar'
import { permissions } from '@expane/logic/permission'
import { getDoesEmployeeHaveSalarySetting } from '@expane/logic/salarySettings'
import { transformPersonName } from '@expane/logic/utils'
import { Input, SelectDropdown, Spinner } from '@expane/ui'
import { DateTimePicker } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { getBookingDurations } from 'logic/booking'
import { checkBookingIsTodayOrLater } from 'logic/calendar'
import { filterEmployeesProvideServices } from 'logic/employees'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { transformPersonsForSelect } from 'logic/utils'
import { FC, useEffect } from 'react'
import { Controller, UseFormReturn, useFormState, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { BookingStatusTracker, getSalarySettingsWarning } from 'widgets/BookingStatusTracker'
import { EmployeeDialog } from 'widgets/EmployeeDialog'
import { BookingDialogFormValues, INITIAL_DURATION } from '.'
import { useOpenRecurringDialog } from 'widgets/RecurringDialog'
import { RepeatButton } from 'widgets/Buttons'
import { RecurringType } from '@expane/logic/recurringBookings'

interface CommonBlockProps {
  form: UseFormReturn<BookingDialogFormValues> // TODO: use useFormContext
  disabled: boolean
  isSomeServicesPaid: boolean
  bookingId: number | undefined
  isCreate: boolean
}

export const CommonBlock: FC<CommonBlockProps> = ({
  form,
  disabled,
  isSomeServicesPaid,
  isCreate,
  bookingId,
}) => {
  const { t } = useTranslation()

  const { control, getValues, setValue, formState } = form
  const {
    dirtyFields: { services: serviceDirty },
  } = formState
  const { isDirty } = useFormState({ control })
  // Wrong type provided and there can be different values such undefined, [] so we need to strictly compare to `true`
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const areServicesChanged = serviceDirty === true

  const branchId = store.branch.branchId
  const { data: branch, isLoading: isLoadingBranch } = useFetchBranchById(branchId)
  const { data: myPermissions } = useFetchMyPermissions()

  const { data: locations } = useFetchLocations(branchId)
  const { data: employees } = useFetchExtendedEmployees(branch?.timezone, branchId)
  const { data: bookingById } = useFetchBookingById(bookingId, branch?.timezone, branchId)
  const watchedEmployeeId = useWatch({ control, name: 'employeeId' })
  const salarySettingsWarning = getSalarySettingsWarning(watchedEmployeeId, employees)

  const employeesWithServices = filterEmployeesProvideServices(employees)
  const employeesWithInactiveReasons =
    employeesWithServices?.map(employee => ({
      ...employee,
      warningMessage: getDoesEmployeeHaveSalarySetting(employee)
        ? undefined
        : t('employeeSalaryAlert'),
    })) ?? []
  const employeesForSelect = transformPersonsForSelect(employeesWithInactiveReasons)
  const employeeIsInArray = employeesForSelect.some(employee => employee.id === watchedEmployeeId)
  const employeesWithArchived =
    !employeeIsInArray && bookingById?.employee
      ? [
          ...employeesForSelect,
          {
            id: bookingById.employee.id,
            name: transformPersonName(bookingById.employee),
          },
        ]
      : employeesForSelect

  const watchedDate = useWatch({ control, name: 'startDate' })
  const branchSchedule = branch?.schedule
  const branchTiming = defineDayTiming(branchSchedule ?? undefined, watchedDate)

  const { openEditDialog: openEmployeeDialog, dialog: employeeDialog } =
    useOpenDialog(EmployeeDialog)

  const watchedServices = useWatch({ control, name: 'services' })
  useEffect(() => {
    // если набор услуг поменялся, то длительность записи просчитывается из суммы длительностей услуг
    if (areServicesChanged) {
      const newBookingDuration = getBookingDurations(watchedServices)

      setValue('duration', (newBookingDuration || INITIAL_DURATION).toString(), {
        shouldValidate: true,
      })
    }
  }, [areServicesChanged, watchedServices, setValue])

  const { recurringDialog, openRecurringDialog } = useOpenRecurringDialog()

  const watchedRecurring = useWatch({ control, name: 'recurring' })

  if (isLoadingBranch) return <Spinner expandCentered />
  if (!branch) return null

  return (
    <>
      <div className="flex">
        <Controller
          control={control}
          name="locationId"
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectDropdown
              label={t('location.name')}
              value={value}
              items={locations}
              placeholder={t('placeholders.defaultSelect')}
              required
              disabled={disabled || isSomeServicesPaid}
              noDataMessage={t('noLocation')}
              onSelectChange={onChange}
              errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
              className="w-1/2 pr-1 shrink-0"
            />
          )}
        />
        <Controller
          control={control}
          name="employeeId"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectDropdown
              label={t('employee.name')}
              value={value}
              items={employeesWithArchived}
              placeholder={t('placeholders.defaultSelect')}
              className="w-1/2 pl-1 shrink-0"
              noDataMessage={t('noSuchEmployee')}
              onSelectChange={onChange}
              errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
              popupHeight="small"
              isClearable
              // useWatch не реагирует если в onChange приходит undefined
              customClearFunction={() => setValue('employeeId', undefined)}
              onEditClick={id => openEmployeeDialog(id)}
              disabled={disabled || isSomeServicesPaid}
            />
          )}
        />
      </div>

      <div className="flex justify-between">
        <Controller
          control={control}
          name="duration"
          rules={{ validate: value => (value ? Number(value) > INITIAL_DURATION : false) }}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                type="number"
                value={value ?? INITIAL_DURATION.toString()}
                onChange={onChange}
                min={INITIAL_DURATION.toString()}
                label={t('duration')}
                placeholder={t('placeholders.defaultSelect')}
                containerClassName="pr-2 w-1/3"
                disabled={disabled}
                errorMessage={{ isShown: Boolean(error), text: t('formError.invalidValue') }}
              />
            )
          }}
        />

        <Controller
          control={control}
          name="startDate"
          rules={{
            required: true,
            validate: startDate => {
              const canEditPast = myPermissions?.includes(permissions.booking.editPast) ?? false
              return canEditPast || checkBookingIsTodayOrLater(startDate, branch.timezone)
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <DateTimePicker
              timezone={branch.timezone}
              className="w-2/3 flex flex-col"
              label={t('dateTitle')}
              type="dateTime"
              disabled={disabled || isSomeServicesPaid}
              value={value}
              onChange={onChange}
              timing={branchTiming}
              showQuickButtons
              errorMessage={{
                isShown: Boolean(error),
                text:
                  error?.type === 'validate'
                    ? t('bookingValidation.notAllowedCreatePast')
                    : t('formError.required'),
              }}
            />
          )}
        />
      </div>

      {isCreate ? (
        <RepeatButton
          onClick={() => {
            const recurring = getValues('recurring')
            const startDate = getValues('startDate')

            openRecurringDialog(
              (data: RecurringType | null) => setValue('recurring', data),
              recurring,
              startDate,
            )
          }}
          isRecurring={Boolean(watchedRecurring)}
          className={'ml-auto'}
        />
      ) : null}

      {!isCreate && Boolean(bookingById) && bookingId && (
        <BookingStatusTracker
          bookingId={bookingId}
          isDirty={isDirty}
          salarySettingsWarning={salarySettingsWarning}
        />
      )}

      {recurringDialog}
      {employeeDialog}
    </>
  )
}
