import { FC } from 'react'
import {
  useFetchAccountById,
  useFetchAccounts,
  useFetchBranchById,
  useFetchEmployeesGroups,
} from '@expane/data'
import { AccountDialogLogic, AccountDialogPlaceholder } from './AccountDialogLogic'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'

export const AccountDialog: FC<DialogProps> = observer(
  ({ id: accountId, closeDialog, isCreate }) => {
    const branchId = store.branch.branchId
    const { data: branch, isLoading: branchIsLoading } = useFetchBranchById(branchId)
    const { data: accountById, isLoading: accountByIdIsLoading } = useFetchAccountById(accountId)
    const { data: accounts, isLoading: accountsIsLoading } = useFetchAccounts(branchId)
    const { data: employeeGroups, isLoading: employeeGroupIsLoading } = useFetchEmployeesGroups(
      branch?.timezone,
      branchId,
    )

    const isLoading =
      (accountByIdIsLoading && !isCreate) ||
      branchIsLoading ||
      accountsIsLoading ||
      employeeGroupIsLoading

    const isNoData = (!isCreate && !accountById) || !accounts || !employeeGroups || !branch

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

    if (isLoading) return <AccountDialogPlaceholder closeDialog={closeDialog} />
    else if (isNoData) return null

    return (
      <AccountDialogLogic
        accountById={accountById}
        accounts={accounts}
        employeeGroups={employeeGroups}
        branch={branch}
        closeDialog={closeDialog}
      />
    )
  },
)
