import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { ServerScheduleInsertInput, ServerScheduleSetInput } from '../../generated/graphql-types'
import { ARCHIVED_SCHEDULES_QUERY_KEY, SCHEDULES_QUERY_KEY } from './queryKeys'
import { EMPLOYEES_QUERY_KEY } from '../employee/queryKeys'

export function useCreateSchedule() {
  return useMutation(
    async (
      scheduleInsertInput: ServerScheduleInsertInput,
    ): Promise<{ insertSchedule?: { id?: number } }> => {
      return request(
        gql`
          mutation ($scheduleInsertInput: schedule_insert_input!) {
            insertSchedule(object: $scheduleInsertInput) {
              id
            }
          }
        `,
        { scheduleInsertInput },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([SCHEDULES_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useUpdateSchedule() {
  return useMutation(
    async (dto: {
      id: number
      scheduleSetInput: ServerScheduleSetInput
    }): Promise<{ updateScheduleById?: { id?: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $scheduleSetInput: schedule_set_input!) {
            updateScheduleById(pk_columns: { id: $id }, _set: $scheduleSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(SCHEDULES_QUERY_KEY),
        })
        queryClient.invalidateQueries([EMPLOYEES_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useArchiveSchedule() {
  return useMutation(
    async (dto: {
      id: number
      archived: boolean
    }): Promise<{ updateScheduleById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $archived: timestamptz!) {
            updateScheduleById(pk_columns: { id: $id }, _set: { archived: $archived }) {
              id
            }
          }
        `,
        { id: dto.id, archived: dto.archived ? new Date().toISOString() : null },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SCHEDULES_QUERY_KEY])
        queryClient.invalidateQueries([ARCHIVED_SCHEDULES_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
