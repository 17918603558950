import { startOfMonth } from '@expane/date'
import { FadeInTranslateTop } from '@expane/ui'
import { dateTimePickerPopupStyle } from '@expane/widgets'
import { FC, useState } from 'react'
import { RangeDatePickerType } from '.'
import { Calendars } from './Calendars'
import { QuickRangeButtons } from './QuickRangeButtons'

type PopupBodyProps = {
  value: Date[]
  type: RangeDatePickerType
  onChange: (value: Date[]) => void
  close: () => void
  timezone: string
}

export const PopupBody: FC<PopupBodyProps> = ({ value, onChange, close, type, timezone }) => {
  const [firstActiveStartDate, setFirstActiveStartDate] = useState(startOfMonth(value[0]))
  const [secondActiveStartDate, setSecondActiveStartDate] = useState(startOfMonth(value[1]))

  return (
    <FadeInTranslateTop
      className={dateTimePickerPopupStyle + ' flex'}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="calendar"
    >
      <QuickRangeButtons
        timezone={timezone}
        onChange={onChange}
        value={value}
        close={close}
        setFirstActiveStartDate={setFirstActiveStartDate}
        setSecondActiveStartDate={setSecondActiveStartDate}
      />
      <Calendars
        timezone={timezone}
        type={type}
        value={value}
        onChange={onChange}
        firstActiveStartDate={firstActiveStartDate}
        setFirstActiveStartDate={setFirstActiveStartDate}
        secondActiveStartDate={secondActiveStartDate}
        setSecondActiveStartDate={setSecondActiveStartDate}
      />
    </FadeInTranslateTop>
  )
}
