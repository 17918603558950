import { RouteType } from 'routes/logic'
import { AuthPageParams } from 'pages/AuthPageParams'
import { AuthPage } from 'pages/AuthPage'
import { ChangelogPage } from 'pages/ChangelogPage'
import { ErrorPage } from 'pages/ErrorPage'
import { UpdateBrowserPage } from 'pages/UpdateBrowserPage'
import { OfflinePage } from 'pages/OfflineServerPage'
import { ClientModePage } from 'pages/ClientModePage'
import { MobilePage } from 'pages/MobilePage'

export const publicRoutes: Array<RouteType> = [
  {
    name: 'authForm.authorization',
    path: '/auth/:businessId',
    isPrivate: false,
    element: AuthPageParams,
  },
  {
    name: 'authForm.authorization',
    path: '/auth',
    isPrivate: false,
    element: AuthPage,
  },
  {
    name: 'changeLog',
    path: '/changelog',
    isPrivate: false,
    element: ChangelogPage,
  },
  {
    name: 'pageNotFoundTitle',
    path: '/404',
    isPrivate: false,
    element: ErrorPage,
  },
  {
    name: 'outdatedBrowserVersion',
    path: '/version-update',
    isPrivate: false,
    element: UpdateBrowserPage,
  },
  {
    name: 'offlineServerPage.title',
    path: '/offline',
    isPrivate: false,
    element: OfflinePage,
  },
  {
    name: 'clientModePage.title',
    path: '/client-mode',
    isPrivate: false,
    element: ClientModePage,
  },
  {
    name: 'mobilePage.title',
    path: '/mobile',
    isPrivate: false,
    element: MobilePage,
  },
]
