import { ServerSupplierMutualSettlementType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { addInfoForSuppliers, Period, SupplierWithInfo } from '@expane/logic/suppliers'
import { Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { allSuppliersItem } from 'pages/SuppliersPage'
import { SupplierMutualSettlementsList } from 'pages/SuppliersPage/SupplierMutualSettlements'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TreeMenuItem } from 'ui/TreeMenu'
import { onChangeSingleTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { MovementInfoDialog } from 'widgets/MovementInfoDialog'
import { TransactionDialog } from 'widgets/TransactionDialog'

interface MutualSettlementsProps {
  suppliers: ServerSupplierMutualSettlementType[]
  setSelectedSuppliers: Dispatch<SetStateAction<TreeMenuItem | undefined>>
  selectedSupplier: TreeMenuItem
  isLoading: boolean
  period: Period
}

export type OpenMovementOrTransactionDialog = (id: number, type: 'movement' | 'transaction') => void

export const MutualSettlements: FC<PropsWithBranchId<MutualSettlementsProps>> = ({
  suppliers,
  setSelectedSuppliers,
  selectedSupplier,
  isLoading,
  period,
  branchId,
}) => {
  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const columns = useMemo<ColumnDef<SupplierWithInfo>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('supplier.name'),
        cell: data => data.getValue(),
        size: 300,
      },
      {
        id: 'initialBalance',
        accessorFn: supplier => supplier.info.startBalance,
        header: () => <span className="text-right w-full">{t('initialBalance')}</span>,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
        size: 200,
      },
      {
        id: 'arrival',
        accessorFn: supplier => supplier.info.arrival,
        header: () => <span className="w-full text-right">{t('coming')}</span>,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
        size: 170,
      },
      {
        id: 'expense',
        accessorFn: supplier => supplier.info.expense,
        header: () => <span className="w-full text-right">{t('expense')}</span>,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
        size: 170,
      },
      {
        id: 'endBalance',
        header: () => <span className="w-full text-right">{t('endBalance')}</span>,
        accessorFn: supplier => supplier.info.endBalance,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
        size: 170,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [convertNumberToMoney, t, period],
  )

  const { dialog: movementDialog, openEditDialog: openMovementDialog } =
    useOpenDialog(MovementInfoDialog)
  const { dialog: transactionDialog, openEditDialog: openTransactionDialog } =
    useOpenDialog(TransactionDialog)

  const onClick: OpenMovementOrTransactionDialog = (id, type) => {
    if (type === 'movement') openMovementDialog(id)
    if (type === 'transaction') openTransactionDialog(id)
  }

  const onClickSupplier = (supplier: SupplierWithInfo) => {
    if (supplier) setSelectedSuppliers(onChangeSingleTreeMenu(supplier, selectedSupplier))
  }

  const suppliersWithInfo = addInfoForSuppliers(suppliers, period)

  return (
    <div className="w-3/4 p-4 flex flex-col">
      {selectedSupplier.id === allSuppliersItem.id ? (
        <Table
          columns={columns}
          data={suppliersWithInfo}
          onRowClick={onClickSupplier}
          isLoading={isLoading}
        />
      ) : (
        <SupplierMutualSettlementsList
          supplier={suppliers.find(s => s.id === selectedSupplier.id)}
          onClick={onClick}
          period={period}
          branchId={branchId}
        />
      )}

      {movementDialog}
      {transactionDialog}
    </div>
  )
}
