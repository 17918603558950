import { getCardsSum, getProductsSum, getServicesSum } from '@expane/logic/quickSale'
import {
  CardItemForPayment,
  ProductItemForPayment,
  ServiceItemForPayment,
} from '@expane/logic/quickSale/types'
import { DiscountInputValue } from 'ui/DiscountInput'
import { TFunction } from 'i18next'

export interface SubmitQuickSaleDialogFormValues {
  clientId: number
  serviceItems: ServiceItemForPayment[]
  productItems: ProductItemForPayment[]
  subscriptionItems: CardItemForPayment[]
  giftCardItems: CardItemForPayment[]
  employeeId?: number
  storageId: number
  accountId: number
  paymentAmount: string
  totalDiscount: string
  addToClientAccount: boolean
  paymentOption: { id: number; name: string }
  paymentToAccounts: {
    id: number
    value: string
  }[]
}

export const getTotalSumOfInvoice = (dto: {
  products: ProductItemForPayment[]
  services: ServiceItemForPayment[]
  subscriptions: CardItemForPayment[]
  giftCards: CardItemForPayment[]
}) => {
  const productsSum = getProductsSum(dto.products)
  const servicesSum = getServicesSum(dto.services)
  const subscriptionsSum = getCardsSum(dto.subscriptions)
  const giftCardsSum = getCardsSum(dto.giftCards)

  return productsSum + servicesSum + subscriptionsSum + giftCardsSum
}

export const getAmountOfFixedDiscount = ({
  price,
  maxDiscount,
}: {
  price: number
  maxDiscount: number
}) => (price / 100) * maxDiscount

export const getDiscountErrorTextMessage = ({
  discount,
  price,
  maxDiscount,
  t,
  currencySymbol,
}: {
  discount: DiscountInputValue
  price: number
  maxDiscount: number
  t: TFunction
  currencySymbol: string
}) =>
  discount.type === 'percent'
    ? t('upToPercent', { percent: maxDiscount })
    : t('upToFixedDiscount', {
        amount: `${getAmountOfFixedDiscount({
          price,
          maxDiscount,
        })} ${currencySymbol}`,
      })
