import { FC } from 'react'
import { ServerAccountType, ServerTransactionToCalcBalanceType } from '@expane/data'
import { useTranslation } from 'react-i18next'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { getTransactionsBeforeDate } from '@expane/logic/finances/filters'
import { calcBalanceOnAccount } from '@expane/logic/finances/calculation'
import { PropsWithBranchId } from '@expane/logic/branch'

interface AccountsValuesStartOfDayProps {
  accounts?: ServerAccountType[]
  transactionsToCalcBalance: ServerTransactionToCalcBalanceType[]
  date: Date
}

export const AccountsValuesStartOfDay: FC<PropsWithBranchId<AccountsValuesStartOfDayProps>> = ({
  accounts,
  transactionsToCalcBalance,
  date,
  branchId,
}) => {
  const transactionsByStartOfDay = getTransactionsBeforeDate(transactionsToCalcBalance, date)
  const { t } = useTranslation()

  return (
    <>
      <p className={titleStyle}>{t('onAccountsOnMorning')}</p>

      <ul className={listStyle}>
        {accounts?.map(account => (
          <ValuesByAccount
            transactions={transactionsByStartOfDay}
            key={account.id}
            id={account.id}
            name={account.name}
            branchId={branchId}
          />
        ))}
      </ul>
    </>
  )
}

interface AccountsValuesEndOfDayProps {
  accounts?: ServerAccountType[]
  transactionsToCalcBalance: ServerTransactionToCalcBalanceType[]
  date: Date
}

export const AccountsValuesEndOfDay: FC<PropsWithBranchId<AccountsValuesEndOfDayProps>> = ({
  accounts,
  transactionsToCalcBalance,
  date,
  branchId,
}) => {
  const transactionsByEndOfDay = getTransactionsBeforeDate(transactionsToCalcBalance, date, true)

  const { t } = useTranslation()

  return (
    <>
      <p className={titleStyle}>{t('onAccountsOnEvening')}</p>

      <ul className={listStyle}>
        {accounts?.map(account => (
          <ValuesByAccount
            key={account.id}
            id={account.id}
            name={account.name}
            transactions={transactionsByEndOfDay}
            branchId={branchId}
          />
        ))}
      </ul>
    </>
  )
}

interface ValuesByAccountStartOfDayProps {
  id: number
  name: string
  transactions: ServerTransactionToCalcBalanceType[]
}

const ValuesByAccount: FC<PropsWithBranchId<ValuesByAccountStartOfDayProps>> = ({
  id,
  name,
  transactions,
  branchId,
}) => {
  const convertToMoney = useConvertNumberToMoney(branchId)

  const value = calcBalanceOnAccount(transactions, id)

  if (value === 0) return null

  return (
    <li className="flex justify-between items-center">
      <p>{name}</p>
      <p>{convertToMoney(value)}</p>
    </li>
  )
}

const titleStyle = 'text-primary-600 font-medium my-2'
const listStyle = 'text-sm text-main-color ml-2 mb-2'
