import { FC } from 'react'
import { CalendarTitleCell } from 'ui/Calendar'
import { EmployeeColumn } from './EmployeeColumn'
import { ExtendedEmployee, ServerLocationWithServiceIdsType, WeeklySchedule } from '@expane/data'
import { ContextMenuItem } from 'logic/hooks/useContextMenu'

type Props = {
  date: Date
  employeeGroup: { id: number; name: string }
  employees: ExtendedEmployee[]
  locationsFromFilter: ServerLocationWithServiceIdsType[]
  hours: number[]
  shrinked: boolean
  branchSchedule: WeeklySchedule | undefined
  boundaryElement: HTMLDivElement | undefined
  onTitleClick: (id: number) => void
  contextMenuItems: ContextMenuItem[]
}

export const EmployeeGroupColumn: FC<Props> = ({
  employeeGroup,
  employees,
  locationsFromFilter,
  date,
  hours,
  shrinked,
  branchSchedule,
  onTitleClick,
  boundaryElement,
  contextMenuItems,
}) => {
  const employeesFromThisGroup = employees.filter(employee => employee.groupId === employeeGroup.id)
  if (employeesFromThisGroup.length === 0) return null

  return (
    <div className={shrinked ? undefined : 'grow'}>
      <CalendarTitleCell rightBorder height="small">
        <span className={shrinked ? 'text-xs truncate text-center w-0 grow' : undefined}>
          {employeeGroup.name}
        </span>
      </CalendarTitleCell>

      <div className="flex">
        {employeesFromThisGroup.map((employee, employeeIndex) => {
          const columnIndex = employees.findIndex(
            employeeFromAll => employeeFromAll.id === employee.id,
          )
          const last = columnIndex === employees.length - 1
          return (
            <EmployeeColumn
              contextMenuItems={contextMenuItems}
              key={employee.id}
              grouped
              date={date}
              columnIndex={columnIndex}
              last={last}
              rightBorder={employeeIndex === employeesFromThisGroup.length - 1 && !last}
              employee={employee}
              employees={employees}
              locationsFromFilter={locationsFromFilter}
              hours={hours}
              shrinked={shrinked}
              branchSchedule={branchSchedule}
              onTitleClick={onTitleClick}
              boundaryElement={boundaryElement}
            />
          )
        })}
      </div>
    </div>
  )
}
