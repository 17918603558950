import { EmployeeByIdExtended } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { DiscountInputInfo } from '@expane/logic/utils'
import { Input } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PercentIcon } from 'ui/Icons'
import { getTotalSumOfInvoice, SubmitQuickSaleDialogFormValues } from '../../logic'
import { Checkbox } from '@expane/ui'

type Props = {
  control: Control<SubmitQuickSaleDialogFormValues>
  isDiscountForEveryone: boolean
  setIsDiscountForEveryone: (value: boolean) => void
  setValue: UseFormSetValue<SubmitQuickSaleDialogFormValues>
  myEmployee: EmployeeByIdExtended | undefined
}

export const TotalDiscountInput: FC<PropsWithBranchId<Props>> = ({
  control,
  setValue,
  isDiscountForEveryone,
  setIsDiscountForEveryone,
  myEmployee,
  branchId,
}) => {
  const { t } = useTranslation()
  const convertToMoneyCode = useConvertNumberToMoneyCode({ branchId })

  const maxDiscount = myEmployee?.employeeGroup?.maxDiscount

  const watchedProducts = useWatch({ control, name: 'productItems' })
  const watchedServices = useWatch({ control, name: 'serviceItems' })
  const watchedSubscriptions = useWatch({ control, name: 'subscriptionItems' })
  const watchedGiftCards = useWatch({ control, name: 'giftCardItems' })

  const totalSum = getTotalSumOfInvoice({
    products: watchedProducts,
    services: watchedServices,
    subscriptions: watchedSubscriptions,
    giftCards: watchedGiftCards,
  })

  const setDiscounts = (value: string) => {
    const discount: DiscountInputInfo = { value, type: 'percent' }

    const updatedProducts = watchedProducts.map(product => ({ ...product, discount }))
    setValue('productItems', updatedProducts)

    const updatedServices = watchedServices.map(service => ({ ...service, discount }))
    setValue('serviceItems', updatedServices)

    const updatedSubscriptions = watchedSubscriptions.map(subscription => ({
      ...subscription,
      discount,
    }))
    setValue('subscriptionItems', updatedSubscriptions)

    const updatedGiftCards = watchedGiftCards.map(giftCard => ({ ...giftCard, discount }))
    setValue('giftCardItems', updatedGiftCards)
  }

  return (
    <div className="mt-3 flex items-baseline justify-end">
      <Controller
        control={control}
        name="totalDiscount"
        render={({ field: { value: totalDiscount, onChange: setTotalDiscount } }) => (
          <>
            <Checkbox
              label={t('applyDiscountEntireReceipt')}
              checked={isDiscountForEveryone}
              onChange={e => {
                setIsDiscountForEveryone(e.currentTarget.checked)
                if (e.currentTarget.checked) {
                  setDiscounts(totalDiscount)
                } else {
                  setDiscounts('0')
                  setTotalDiscount('')
                }
              }}
              disabled={!maxDiscount}
            />

            <Input
              Icon={PercentIcon}
              type="number"
              value={totalDiscount}
              onChange={e => {
                if (Number(e.target.value) >= 0 && Number(e.target.value) <= 100) {
                  setDiscounts(e.currentTarget.value)
                  setTotalDiscount(e.currentTarget.value)
                }
              }}
              containerClassName="w-20 ml-2"
              disabled={!isDiscountForEveryone || !maxDiscount}
              height="small"
              errorMessage={{
                isShown: Boolean(maxDiscount && Number(totalDiscount) > maxDiscount),
                text: t('upToPercent', { percent: maxDiscount }),
              }}
            />
          </>
        )}
      />

      <span className="ml-3 font-medium text-label-color min-w-40">
        {t('total')}: {convertToMoneyCode(totalSum)}
      </span>
    </div>
  )
}
