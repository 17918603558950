import { gql } from 'graphql-request'
import { request, useQuery, reportError, reportGqlError } from '../../api'
import {
  ServerTransactionBriefWithBookingLocationType,
  ServerTransactionBriefWithCardsType,
  ServerTransactionBriefWithClientType,
  ServerTransactionBriefWithMovementsType,
  ServerTransactionByEmployeeIdType,
  ServerTransactionByIdType,
  ServerTransactionSalaryType,
  ServerTransactionToCalcBalanceType,
  ServerTransactionType,
} from '../../generated/graphql-types'
import {
  TRANSACTIONS_BRIEF,
  TRANSACTIONS_BRIEF_WITH_CARDS_QUERY_KEY,
  TRANSACTIONS_BRIEF_WITH_CLIENT_QUERY_KEY,
  TRANSACTIONS_BRIEF_WITH_MOVEMENTS_QUERY_KEY,
  TRANSACTIONS_QUERY_KEY,
  TRANSACTIONS_SALARIES_QUERY_KEY,
  TRANSACTIONS_WITH_BOOKING_LOCATION_QUERY_KEY,
} from './queryKeys'
import {
  transactionBriefWithBookingLocationFragment,
  transactionBriefWithCardsFragment,
  transactionBriefWithClientFragment,
  transactionBriefWithMovementsFragment,
  transactionByEmployeeIdFragment,
  transactionByIdFragment,
  transactionFragment,
  transactionSalaryFragment,
  transactionToCalcBalanceFragment,
} from './transaction.fragments'
import { TRANSACTION_TYPES, parseDatesInTransactionGqlResponse } from './logic'
import { DEFAULT_TIMEZONE } from '@expane/date'

export function useFetchTransactionsByDate({
  startDate,
  endDate,
  timezone,
  branchId,
}: {
  startDate: Date
  endDate: Date
  timezone: string | undefined
  branchId: number | undefined
}) {
  return useQuery<ServerTransactionType[]>(
    [TRANSACTIONS_QUERY_KEY, { startDate, endDate, branchId }],
    async () => {
      const dto = { startDate, endDate, branchId }

      const result = await request(
        gql`
          ${transactionFragment}
          query ($startDate: timestamptz!, $endDate: timestamptz!, $branchId: Int!) {
            transactions(
              order_by: { date: desc }
              where: { date: { _gte: $startDate, _lt: $endDate }, branchId: { _eq: $branchId } }
            ) {
              ...transactionType
            }
          }
        `,
        dto,
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(transaction =>
          parseDatesInTransactionGqlResponse(transaction, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('transactionsOfDay is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchTransactionsByDate',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchTransactionById(
  id: number | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerTransactionByIdType>(
    [TRANSACTIONS_QUERY_KEY, id, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${transactionByIdFragment}
          query ($id: Int!, $branchId: Int!) {
            transactionById(id: $id) {
              ...transactionByIdType
            }
          }
        `,
        { id, branchId },
      )

      if (result?.transactionById) {
        return parseDatesInTransactionGqlResponse(
          result.transactionById,
          timezone ?? DEFAULT_TIMEZONE,
        )
      }

      reportError(new Error('transactionById does not exist'), 'warning', { id, result })
      return []
    },
    {
      queryName: 'useFetchTransactionById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchEmployeeTransactions(
  employeeId: number | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerTransactionByEmployeeIdType[]>(
    [TRANSACTIONS_QUERY_KEY, { employeeId, branchId }],
    async () => {
      const result = await request(
        gql`
          ${transactionByEmployeeIdFragment}
          query ($employeeId: Int!, $branchId: Int!) {
            transactions(
              order_by: { date: desc }
              where: { employeeId: { _eq: $employeeId }, branchId: { _eq: $branchId } }
            ) {
              ...transactionByEmployeeIdType
            }
          }
        `,
        { employeeId, branchId },
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(t =>
          parseDatesInTransactionGqlResponse(t, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('transactions is not an array'), 'warning', { employeeId, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchEmployeeTransactions',
      enabled: Boolean(employeeId) && Boolean(timezone) && Boolean(branchId),
      onError: reportGqlError,
    },
  )
}

export function useFetchClientTransactions(
  clientId: number,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerTransactionType[]>(
    [TRANSACTIONS_QUERY_KEY, { clientId, branchId }],
    async () => {
      const result = await request(
        gql`
          ${transactionFragment}
          query ($clientId: Int!, $branchId: Int!) {
            # сортируем от меньшего к большему для правильного подсчета клиентского счета в диалоге клиента
            transactions(
              order_by: { id: asc }
              where: { clientId: { _eq: $clientId }, branchId: { _eq: $branchId } }
            ) {
              ...transactionType
            }
          }
        `,
        { clientId, branchId },
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(t =>
          parseDatesInTransactionGqlResponse(t, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('transactions is not an array'), 'warning', { clientId, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchClientTransactions',
      enabled: Boolean(clientId) && Boolean(timezone) && Boolean(branchId),
      onError: reportGqlError,
    },
  )
}

export function useFetchTransactionsToCalcBalance(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerTransactionToCalcBalanceType[]>(
    [TRANSACTIONS_QUERY_KEY, TRANSACTIONS_BRIEF, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${transactionToCalcBalanceFragment}
          query ($branchId: Int!) {
            transactions(order_by: { date: desc }, where: { branchId: { _eq: $branchId } }) {
              ...transactionToCalcBalanceType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(transaction =>
          parseDatesInTransactionGqlResponse(transaction, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('transactions is not an array'), 'warning', {
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchTransactionsToCalcBalance',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchTransactionsBriefWithCardsByDate(
  start: Date,
  end: Date,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerTransactionBriefWithCardsType[]>(
    [TRANSACTIONS_QUERY_KEY, TRANSACTIONS_BRIEF_WITH_CARDS_QUERY_KEY, { start, end, branchId }],
    async () => {
      const result = await request(
        gql`
          ${transactionBriefWithCardsFragment}
          query ($start: timestamptz!, $end: timestamptz!, $branchId: Int!) {
            transactions(
              order_by: { date: desc }
              where: { createdAt: { _gt: $start, _lt: $end }, branchId: { _eq: $branchId } }
            ) {
              ...transactionBriefWithCardsType
            }
          }
        `,
        { start, end, branchId },
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(transaction =>
          parseDatesInTransactionGqlResponse(transaction, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('transactions is not an array'), 'warning', {
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchTransactionsBriefWithCardsByDate',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchTransactionsBriefWithMovementsByDate(
  start: Date,
  end: Date,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerTransactionBriefWithMovementsType[]>(
    [TRANSACTIONS_QUERY_KEY, TRANSACTIONS_BRIEF_WITH_MOVEMENTS_QUERY_KEY, { start, end, branchId }],
    async () => {
      const result = await request(
        gql`
          ${transactionBriefWithMovementsFragment}
          query ($start: timestamptz!, $end: timestamptz!, $branchId: Int!) {
            transactions(
              order_by: { date: desc }
              where: { createdAt: { _gt: $start, _lt: $end }, branchId: { _eq: $branchId } }
            ) {
              ...transactionBriefWithMovementsType
            }
          }
        `,
        { start, end, branchId },
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(transaction =>
          parseDatesInTransactionGqlResponse(transaction, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('transactions is not an array'), 'warning', {
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchTransactionsBriefWithMovementsByDate',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchTransactionsBriefWithMovementsByProductId(
  productId: number | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerTransactionBriefWithMovementsType[]>(
    [TRANSACTIONS_QUERY_KEY, TRANSACTIONS_BRIEF_WITH_MOVEMENTS_QUERY_KEY, { productId, branchId }],
    async () => {
      const result = await request(
        gql`
          ${transactionBriefWithMovementsFragment}
          query ($productId: Int!, $type: smallint, $branchId: Int!) {
            transactions(
              where: {
                movement: { movementProducts: { productId: { _eq: $productId } } }
                type: { _eq: $type }
                branchId: { _eq: $branchId }
              }
            ) {
              ...transactionBriefWithMovementsType
            }
          }
        `,
        { productId, type: TRANSACTION_TYPES.payment.id, branchId },
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(transaction =>
          parseDatesInTransactionGqlResponse(transaction, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(
          new Error('Error in useFetchTransactionsBriefWithMovementsByProductId'),
          'warning',
          {
            result,
          },
        )
        return []
      }
    },
    {
      queryName: 'useFetchTransactionsBriefWithMovementsByProductId',
      onError: reportGqlError,
      enabled: Boolean(productId) && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export type ServerTransactionBriefWithClient = Omit<
  ServerTransactionBriefWithClientType,
  'clientId' | 'client'
> & {
  clientId: number
  client: {
    id: number
  }
}

export function useFetchTransactionsBriefWithClient(
  timezone: string | undefined,
  branchId: number | undefined,
  isFetchingAllowed = true,
) {
  return useQuery<ServerTransactionBriefWithClient[]>(
    [TRANSACTIONS_QUERY_KEY, TRANSACTIONS_BRIEF_WITH_CLIENT_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${transactionBriefWithClientFragment}
          query ($branchId: Int!) {
            transactions(
              order_by: { date: desc }
              where: { clientId: { _is_null: false }, branchId: { _eq: $branchId } }
            ) {
              ...transactionBriefWithClientType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(transaction =>
          parseDatesInTransactionGqlResponse(transaction, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('transactions is not an array'), 'warning', {
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchTransactionsBriefWithClient',
      onError: reportGqlError,
      enabled: isFetchingAllowed && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchTransactionsBriefWithClientByDate({
  startDate,
  endDate,
  timezone,
  branchId,
  isFetchingAllowed = true,
}: {
  startDate: Date
  endDate: Date
  timezone: string | undefined
  branchId: number | undefined
  isFetchingAllowed?: boolean
}) {
  return useQuery<ServerTransactionBriefWithClient[]>(
    [
      TRANSACTIONS_QUERY_KEY,
      TRANSACTIONS_BRIEF_WITH_CLIENT_QUERY_KEY,
      { startDate, endDate, branchId },
    ],
    async () => {
      const dto = { startDate, endDate, branchId }

      const result = await request(
        gql`
          ${transactionBriefWithClientFragment}
          query ($startDate: timestamptz!, $endDate: timestamptz!, $branchId: Int!) {
            transactions(
              order_by: { date: desc }
              where: {
                date: { _gte: $startDate, _lt: $endDate }
                clientId: { _is_null: false }
                branchId: { _eq: $branchId }
              }
            ) {
              ...transactionBriefWithClientType
            }
          }
        `,
        dto,
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(transaction =>
          parseDatesInTransactionGqlResponse(transaction, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('transactionsOfDay is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchTransactionsBriefWithClientByDate',
      onError: reportGqlError,
      enabled: isFetchingAllowed && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchTransactionsWithBookingLocations(
  startDate: Date,
  endDate: Date,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerTransactionBriefWithBookingLocationType[]>(
    [
      TRANSACTIONS_QUERY_KEY,
      TRANSACTIONS_WITH_BOOKING_LOCATION_QUERY_KEY,
      { startDate, endDate, branchId },
    ],
    async () => {
      const result = await request(
        gql`
          ${transactionBriefWithBookingLocationFragment}
          query ($startDate: timestamptz!, $endDate: timestamptz!, $branchId: Int!) {
            transactions(
              where: {
                date: { _gte: $startDate, _lt: $endDate }
                bookingId: { _is_null: false }
                branchId: { _eq: $branchId }
              }
            ) {
              ...transactionBriefWithBookingLocationType
            }
          }
        `,
        { startDate, endDate, branchId },
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(t =>
          parseDatesInTransactionGqlResponse(t, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(
          new Error('transactions is not an array, error in useFetchTransactionByBookingLocation'),
          'warning',
          { startDate, endDate, result },
        )
        return []
      }
    },
    {
      queryName: 'useFetchTransactionsWithBookingLocations',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchTransactionsByParentId(
  parentId: number | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerTransactionType[]>(
    [TRANSACTIONS_QUERY_KEY, { parentId, branchId }],
    async () => {
      const result = await request<{ transactions }>(
        gql`
          ${transactionFragment}
          query ($parentId: Int!, $type: smallint, $branchId: Int!) {
            transactions(
              order_by: { date: desc }
              where: {
                parentId: { _eq: $parentId }
                type: { _eq: $type }
                branchId: { _eq: $branchId }
              }
            ) {
              ...transactionType
            }
          }
        `,
        { parentId, type: TRANSACTION_TYPES.refund.id, branchId },
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(transaction =>
          parseDatesInTransactionGqlResponse(transaction, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('transactionsByParentId is not an array'), 'warning', {
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchTransactionsByParentId',
      onError: reportGqlError,
      enabled: Boolean(parentId) && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchTransactionsWithSalariesByEmployeeId(
  employeeId: number | null | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerTransactionSalaryType[]>(
    [TRANSACTIONS_QUERY_KEY, TRANSACTIONS_SALARIES_QUERY_KEY, { employeeId, branchId }],
    async () => {
      const type = TRANSACTION_TYPES.salary.id

      const result = await request(
        gql`
          ${transactionSalaryFragment}
          query ($type: smallint, $employeeId: Int!, $branchId: Int!) {
            transactions(
              order_by: { date: desc }
              where: {
                type: { _eq: $type }
                employeeId: { _eq: $employeeId }
                branchId: { _eq: $branchId }
              }
            ) {
              ...transactionSalaryType
            }
          }
        `,
        { type, employeeId, branchId },
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(transaction =>
          parseDatesInTransactionGqlResponse(transaction, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('transactionsWithSalaries is not an array'), 'error', {
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchTransactionsWithSalariesByEmployeeId',
      onError: reportGqlError,
      enabled: Boolean(employeeId) && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchTransactionsWithSalaries(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerTransactionSalaryType[]>(
    [TRANSACTIONS_QUERY_KEY, TRANSACTIONS_SALARIES_QUERY_KEY, { branchId }],
    async () => {
      const type = TRANSACTION_TYPES.salary.id

      const result = await request(
        gql`
          ${transactionSalaryFragment}
          query ($type: smallint, $branchId: Int!) {
            transactions(
              order_by: { date: desc }
              where: { type: { _eq: $type }, branchId: { _eq: $branchId } }
            ) {
              ...transactionSalaryType
            }
          }
        `,
        { type, branchId },
      )

      if (Array.isArray(result?.transactions)) {
        return result.transactions.map(transaction =>
          parseDatesInTransactionGqlResponse(transaction, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('transactionsWithSalaries is not an array'), 'warning', {
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchTransactionsWithSalaries',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}
