import { gql } from 'graphql-request'
import { queryClient, request, useMutation, reportGqlError } from '../../api'
import {
  ServerDynamicScheduleDateInsertInput,
  ServerEmployeeScheduleInsertInput,
} from '../../generated/graphql-types'
import { SCHEDULES_QUERY_KEY } from '../schedule/queryKeys'
import {
  EMPLOYEES_EXTENDED_QUERY_KEY,
  EMPLOYEES_FOR_CALENDAR_QUERY_KEY,
  EMPLOYEES_QUERY_KEY,
} from '../employee/queryKeys'
import { SERVICES_QUERY_KEY } from '../service/queryKeys'
import { EMPLOYEE_GROUPS_QUERY_KEY } from '../employeeGroup/queryKeys'

export type EmployeeScheduleInsertInput = ServerEmployeeScheduleInsertInput &
  Required<Pick<ServerEmployeeScheduleInsertInput, 'branchId'>>

export function useCreateEmployeeSchedule() {
  return useMutation(
    async (dto: {
      employeeScheduleInsertInput: EmployeeScheduleInsertInput
    }): Promise<{
      insertEmployeeSchedule?: { employeeId?: number }
    }> => {
      return request(
        gql`
          mutation ($employeeScheduleInsertInput: employeeSchedule_insert_input!) {
            insertEmployeeSchedule(object: $employeeScheduleInsertInput) {
              employeeId
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: (data, variables) => {
        // invalidate schedules calendar page
        queryClient.invalidateQueries([
          EMPLOYEE_GROUPS_QUERY_KEY,
          { branchId: variables.employeeScheduleInsertInput.branchId },
        ])
        queryClient.invalidateQueries([
          EMPLOYEES_QUERY_KEY,
          SCHEDULES_QUERY_KEY,
          SERVICES_QUERY_KEY,
          variables.employeeScheduleInsertInput.employeeId,
        ])
        // инвалидация расписания сотрудников при фетче сотрудников в календаре на моб
        // TODO: возможно надо будет удалить EMPLOYEES_FOR_CALENDAR_QUERY_KEY - станет не актуально
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEES_FOR_CALENDAR_QUERY_KEY),
        })
        // invalidate schedules calendar page
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEES_EXTENDED_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}

export function useStopEmployeeSchedule({
  employeeId,
  branchId,
}: {
  employeeId: number
  branchId: number
}) {
  return useMutation(
    async (dto: {
      scheduleId: number
      endDate: Date
    }): Promise<{
      stopEmployeeSchedule?: { message?: string }
    }> => {
      return request(
        gql`
          mutation ($scheduleId: Int!, $endDate: timestamptz!) {
            stopEmployeeSchedule(scheduleId: $scheduleId, endDate: $endDate) {
              message
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        // invalidate schedules calendar page
        queryClient.invalidateQueries([EMPLOYEE_GROUPS_QUERY_KEY, { branchId }])
        queryClient.invalidateQueries([
          EMPLOYEES_QUERY_KEY,
          SCHEDULES_QUERY_KEY,
          SERVICES_QUERY_KEY,
          employeeId,
        ])
        // инвалидация расписания сотрудников при фетче сотрудников в календаре на моб
        // TODO: возможно надо будет удалить EMPLOYEES_FOR_CALENDAR_QUERY_KEY - станет не актуально
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEES_FOR_CALENDAR_QUERY_KEY),
        })
        // invalidate schedules calendar page
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEES_EXTENDED_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}

export function useCreateEmployeesSchedules() {
  return useMutation(
    async (dto: {
      employeesSchedulesInsertInput: EmployeeScheduleInsertInput[]
    }): Promise<{
      insertEmployeeSchedules?: { affected_rows?: number }
    }> => {
      return request(
        gql`
          mutation ($employeesSchedulesInsertInput: [employeeSchedule_insert_input!]!) {
            insertEmployeeSchedules(objects: $employeesSchedulesInsertInput) {
              affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes(EMPLOYEES_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes(SCHEDULES_QUERY_KEY),
        })
        queryClient.invalidateQueries([SERVICES_QUERY_KEY])
        queryClient.invalidateQueries([EMPLOYEES_EXTENDED_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

// Creates a new dynamic date/overrides an existing one if present/removes by an array of ids
export function useMutateDynamicDates() {
  return useMutation(
    async (dto: {
      dynamicScheduleDates: ServerDynamicScheduleDateInsertInput[]
      dynamicDateIdsToDelete: string[]
    }): Promise<{
      insertDynamicScheduleDates?: { affected_rows?: number }
      deleteDynamicScheduleDates?: { affected_rows?: number }
    }> => {
      return request(
        gql`
          mutation (
            $dynamicScheduleDates: [dynamicScheduleDate_insert_input!]!
            $dynamicDateIdsToDelete: [uuid!]!
          ) {
            deleteDynamicScheduleDates(where: { id: { _in: $dynamicDateIdsToDelete } }) {
              affected_rows
            }
            insertDynamicScheduleDates(
              on_conflict: {
                update_columns: [timingStart, timingEnd]
                constraint: dynamicScheduleDate_pkey
              }
              objects: $dynamicScheduleDates
            ) {
              affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes(SCHEDULES_QUERY_KEY),
        })
        queryClient.invalidateQueries([EMPLOYEE_GROUPS_QUERY_KEY])
        // инвалидация расписания сотрудников при фетче сотрудников в календаре на моб
        // TODO: возможно надо будет удалить EMPLOYEES_FOR_CALENDAR_QUERY_KEY - станет не актуально
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEES_FOR_CALENDAR_QUERY_KEY),
        })
        // invalidate schedules calendar page
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEES_EXTENDED_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}
