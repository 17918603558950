import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { allSelectedPermissions, PermissionPageBlockProps } from '../logic'
import { PermissionBlock, PermissionBlockTitle, PermissionCheckbox } from '.'
import { PermissionPageRowProps } from '@expane/logic/permission/logic'

export const ClientBlock: FC<PermissionPageBlockProps> = ({
  control,
  checkRelation,
  checkRelationBulk,
  isItOwnerRole,
  setValue,
}) => {
  const { t } = useTranslation()

  const onSelectAll = () => {
    checkRelationBulk({
      permissions: [
        'permissions.client.get',
        'permissions.client.phone',
        'permissions.client.set',
        'permissions.client.archive',
        'permissions.client.email',
        'permissions.clientMessage.get',
        'permissions.clientMessage.set',
        'permissions.clientNote.get',
        'permissions.clientNote.set',
        'permissions.tag.set',
        'permissions.lead.set',
        'permissions.review.get',
        'permissions.review.set',
      ],
      onConfirm: () => {
        setValue('permissions.client', allSelectedPermissions.client)
        setValue('permissions.clientMessage', allSelectedPermissions.clientMessage)
        setValue('permissions.clientNote', allSelectedPermissions.clientNote)
        setValue('permissions.tag', allSelectedPermissions.tag)
        setValue('permissions.lead', allSelectedPermissions.lead)
        setValue('permissions.review', allSelectedPermissions.review)
      },
    })
  }

  return (
    <>
      <PermissionBlockTitle onSelectAll={onSelectAll} title={t('clients')} />

      <ClientPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <ClientNotePermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <ClientMessagePermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <TagPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <LeadPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />

      <ReviewPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
    </>
  )
}

const ClientPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('clients')}>
      <Controller
        name="permissions.client.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.client.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.client.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.client.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.client.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.client.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.client.phone"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.client.phone',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.client.phone')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.client.archive"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.client.archive',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.client.archive')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.client.email"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.client.email',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.client.email')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const ClientNotePermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('comments')}>
      <Controller
        name="permissions.clientNote.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.clientNote.get',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.clientNote.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.clientNote.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.clientNote.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.clientNote.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const ClientMessagePermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('notifications')}>
      <Controller
        name="permissions.clientMessage.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.clientMessage.get',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.clientMessage.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.clientMessage.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.clientMessage.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.clientMessage.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const TagPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('tags')}>
      <Controller
        name="permissions.tag.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.tag.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.tag.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const LeadPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('leads.name')}>
      <Controller
        name="permissions.lead.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.lead.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.lead.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const ReviewPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('reviews.name')}>
      <Controller
        name="permissions.review.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.review.get',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.review.get')}
            disabled={isItOwnerRole}
          />
        )}
      />

      <Controller
        name="permissions.review.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.review.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.review.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}
