import { Paper } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAlertCircleOutline, IoMailOutline, IoPaperPlaneOutline } from 'react-icons/io5'

interface Props {
  onClick: () => void
}

export const AuthEmailSentWidget: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <Paper className="py-12 px-8">
      <h2 className="text-2xl text-primary-600 mb-10 text-center font-medium">
        {t('authEmailSent.checkYourEmail')}
      </h2>

      <div className="flex items-start">
        <div className="flex-centered flex-col w-56 mx-6">
          <IoAlertCircleOutline size="1.75rem" className="text-secondary-color mb-2" />
          <p className="text-center text-main-color">
            {t('authEmailSent.toContinue')}
            <span className="font-medium">{t('authEmailSent.closeCurrentTab')}</span>
          </p>
        </div>

        <div className="flex-centered flex-col w-56 mx-6">
          <IoMailOutline size="1.75rem" className="text-secondary-color mb-2" />
          <p className="text-center text-main-color">{t('authEmailSent.noLetter')}</p>
        </div>
      </div>

      <div className="flex-centered flex-col w-64 m-auto mt-10">
        <IoPaperPlaneOutline size="1.75rem" className="text-secondary-color mb-2" />
        <p className="text-center text-main-color">
          {t('authEmailSent.emailNotReceived')}
          <span className="cursor-pointer text-primary-600 underline rounded-md" onClick={onClick}>
            {t('authEmailSent.reauthorize')}
          </span>
        </p>
      </div>
    </Paper>
  )
}
