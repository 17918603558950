import { TreeMenuItem, TreeMenuSelectedItem } from 'ui/TreeMenu'
import { findTreeMenuItem } from './logic.common'

export const getAllFoldersFromItems = <MTreeMenuItem extends TreeMenuItem>(
  items: MTreeMenuItem[],
) => {
  let result: MTreeMenuItem[] = []

  for (const item of items) {
    if (item.isFolder) result.push(item)

    if (item.nodes) {
      const resultFromNodes = getAllFoldersFromItems(item.nodes)
      result = [...result, ...resultFromNodes]
    }
  }

  return result
}

export const getNewFocusedItemOnArrowUp = <MTreeMenuItem extends TreeMenuItem>({
  focusedItem,
  openedFolders,
  allTree,
}: {
  focusedItem: MTreeMenuItem | undefined
  allTree: MTreeMenuItem[]
  openedFolders: MTreeMenuItem[]
}) => {
  if (!focusedItem) return allTree[allTree.length - 1]
  let items: MTreeMenuItem[] = allTree
  // если нет родительской папки, то фокус сбросится на инпут поиска
  let parent: MTreeMenuItem | undefined
  if (focusedItem.parentId) {
    const parentOfFocusedItem = findTreeMenuItem(focusedItem.parentId, true, allTree)

    if (parentOfFocusedItem?.nodes) {
      // @ts-expect-error TODO: improve child nodes type
      items = parentOfFocusedItem.nodes
      parent = parentOfFocusedItem
    }
  }

  const index = items.findIndex(
    item => item.id === focusedItem.id && item.isFolder === focusedItem.isFolder,
  )
  if (index === 0) return parent
  const newFocusedItem = items[index - 1]

  return checkForLastItemInOpenedFolders(newFocusedItem, openedFolders)
}

// если папка открыта, то возвращает последний дочерний элемент
const checkForLastItemInOpenedFolders = <MTreeMenuItem extends TreeMenuItem>(
  item: MTreeMenuItem,
  openedFolders: MTreeMenuItem[],
): MTreeMenuItem => {
  if (item.isFolder) {
    const isThisFolderOpened = Boolean(
      openedFolders.find(openedFolder => openedFolder.id === item.id),
    )

    if (isThisFolderOpened && item.nodes)
      // @ts-expect-error improve child nodes logic
      return checkForLastItemInOpenedFolders(item.nodes[item.nodes.length - 1], openedFolders)
  }

  return item
}

export const getNewFocusedItemOnArrowDown = ({
  focusedItem,
  openedFolders,
  allTree,
  ignoreOpenedFolders = false,
  alwaysOpen,
}: {
  focusedItem: TreeMenuItem | undefined
  openedFolders: TreeMenuItem[]
  allTree: TreeMenuItem[]
  ignoreOpenedFolders?: boolean
  alwaysOpen: boolean
}) => {
  if (!focusedItem) return allTree[0]
  if (focusedItem.isFolder) {
    const folderIsOpen = Boolean(openedFolders.find(folder => folder.id === focusedItem.id))
    if ((folderIsOpen || alwaysOpen) && !ignoreOpenedFolders && focusedItem.nodes?.[0])
      return focusedItem.nodes[0]
  }

  if (focusedItem.parentId === undefined) {
    const index = allTree.findIndex(
      item => item.id === focusedItem.id && item.isFolder === focusedItem.isFolder,
    )
    if (index === allTree.length - 1) return undefined
    return allTree[index + 1]
  }

  const parent = findTreeMenuItem(focusedItem.parentId, true, allTree)
  if (!parent?.nodes) return focusedItem

  const indexInNodes = parent.nodes.findIndex(item => item.id === focusedItem.id)
  if (indexInNodes !== parent.nodes.length - 1) return parent.nodes[indexInNodes + 1]

  return getNewFocusedItemOnArrowDown({
    focusedItem: parent,
    openedFolders,
    allTree,
    ignoreOpenedFolders: true,
    alwaysOpen,
  })
}

export const defaultSearchFilterFunction = <T extends TreeMenuItem>(
  items: T[] | undefined,
  searchValue: string,
) => {
  if (items === undefined) return []

  if (searchValue === '') return items

  const result: T[] = []

  for (let i = 0; i < items.length; i++) {
    if (items[i].isFolder) {
      const filteredNodes = defaultSearchFilterFunction(items[i].nodes, searchValue)
      if (filteredNodes.length) {
        // если это папка и после фильтрации массив `nodes` пустой, то папку в результат не пушим
        result.push({ ...items[i], nodes: filteredNodes })
      }
    } else {
      if (items[i].name.toLowerCase().includes(searchValue.toLowerCase(), 0))
        result.push({ ...items[i] })
    }
  }
  return result
}

export const markFoldersWithInactive = <T extends TreeMenuItem>(items: T[]): T[] => {
  const result: T[] = []

  for (const item of items) {
    if (!item.isFolder || !item.nodes) {
      result.push({ ...item })
      continue
    }

    const markedNodes = markFoldersWithInactive(item.nodes)

    const allNodesAreInactive = markedNodes.every(
      markedNode =>
        markedNode.inactiveReason || markedNode.status === 'inactive' || markedNode.disabled,
    )
    if (allNodesAreInactive) {
      result.push({ ...item, status: 'inactive', nodes: markedNodes })
      continue
    }

    const anyNoteIsInactive = markedNodes.some(
      markedNode =>
        markedNode.inactiveReason ||
        markedNode.status === 'inactive' ||
        markedNode.status === 'inactiveNodes' ||
        markedNode.disabled,
    )
    if (anyNoteIsInactive) {
      result.push({ ...item, status: 'inactiveNodes', nodes: markedNodes })
      continue
    }

    result.push({ ...item })
  }

  return result
}

const findAllParents = <MTreeMenuItem extends TreeMenuItem>(
  items: MTreeMenuItem[],
  selectedItem: TreeMenuSelectedItem,
) => {
  const result: MTreeMenuItem[] = []

  if (selectedItem.parentId) {
    const parentItem = findTreeMenuItem(selectedItem.parentId, true, items)
    if (parentItem) {
      result.push(parentItem)

      if (parentItem.parentId) {
        const olderParent = findAllParents(items, parentItem)
        if (olderParent.length > 0) {
          result.push(...olderParent)
        }
      }
    }
  }

  return result
}

export const findAllParentsForSelectedItems = <MTreeMenuItem extends TreeMenuItem>(
  items: MTreeMenuItem[],
  selectedItems: TreeMenuSelectedItem[],
) => {
  const result: MTreeMenuItem[] = []

  selectedItems.forEach(selectedItem => {
    const selectedItemParent = findAllParents(items, selectedItem)
    if (selectedItemParent.length > 0) {
      selectedItemParent.forEach(parent => {
        const isDuplicate = result.some(
          resultItem =>
            parent.id === resultItem.id &&
            parent.isFolder === resultItem.isFolder &&
            parent.parentId === resultItem.parentId,
        )
        if (!isDuplicate) result.push(parent)
      })
    }
  })
  return result
}
