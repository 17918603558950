import { CloseButton, Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { ChangeEvent, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MapPicker } from 'ui/MapPicker'
import { SubmitButton } from 'widgets/Buttons'

export const TAILWIND_TO_REM_RATIO = 4
export const REM_TO_PX_RATIO = 2

export const SYSTEM_TAG_COLOR = 'primary-300'
// we just have this string in code and it guarantees these classes will be present on production
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const COLOR_STYLES = 'bg-primary-300 border-primary-300 ring-primary-300 text-primary-300'

interface Position {
  lat: number
  lng: number
}

export const useMapPickerPopup = () => {
  const { t } = useTranslation()

  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const [detectedLocation, setDetectedLocation] = useState<null | Position>(null)

  const handlePosition = (position: SetStateAction<Position | null>) => {
    setDetectedLocation(position)
  }

  const message = detectedLocation
    ? t('detectedLocation.success')
    : t('detectedLocation.clickToDetect')

  const mapPickerUIContent = (
    <Modal close={closePopup}>
      <Dialog>
        <Dialog.Body>
          <div className="p-3">
            <p className="text-primary-600 text-center">{message}</p>
          </div>
          <MapPicker onSetPosition={handlePosition} />
        </Dialog.Body>

        <Dialog.Footer>
          <SubmitButton onClick={closePopup} />
          <CloseButton onClick={closePopup} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )

  const mapPicker = isOpen ? mapPickerUIContent : null

  return {
    mapPicker,
    openPopup,
    geo: detectedLocation === null ? null : `${detectedLocation?.lat}, ${detectedLocation?.lng}`,
  }
}

export const formatPhoneNumber = (number: string) => {
  const splitted = number.split(' ')
  let formatted = ''
  for (let i = 0; i < splitted.length; i++) {
    if (i === 0) {
      formatted += splitted[i]
    } else if (i === 1) {
      formatted += ' (' + splitted[i]
    } else if (i === 2) {
      formatted += ') ' + splitted[i]
    } else formatted += '-' + splitted[i]
  }
  return formatted
}

export const onChangeWithFirstUpperCase =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (onChange: (...event: any[]) => void) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 1)
      onChange({ ...e, target: { value: e.target.value.toLocaleUpperCase() } })
    else onChange(e)
  }
