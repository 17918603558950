import { MultiServiceDto } from 'widgets/BookingMultiServicesDialog/index'
import { BookingUnion } from '@expane/data'
import { checkIsBookingPaid } from 'logic/booking'

interface PaidInfoType {
  bookingId: number | undefined
  isAllServicesPaid: boolean
  isSomeServicesPaid: boolean
  isAllConsumablesPaid: boolean
  isSomeProductsPaid: boolean
  isBookingPaid: boolean
}

export const checkIsUnitedBookingPaid = ({
  initialBookings,
  multiServicesDto,
}: {
  initialBookings: Array<BookingUnion>
  multiServicesDto: MultiServiceDto[]
}) => {
  const paidInfo: Array<PaidInfoType> = []

  for (const multiService of multiServicesDto) {
    if (!multiService.bookingId) {
      paidInfo.push({
        bookingId: undefined,
        isAllServicesPaid: false,
        isSomeServicesPaid: false,
        isAllConsumablesPaid: false,
        isSomeProductsPaid: false,
        isBookingPaid: false,
      })
    } else {
      paidInfo.push({
        ...checkIsBookingPaid({
          consumables: multiService.consumables,
          services: multiService.services,
          transactions:
            initialBookings.find(booking => booking.id === multiService.bookingId)?.transactions ??
            [],
        }),
        bookingId: multiService.bookingId,
      })
    }
  }

  return paidInfo
}
