import { getAllTimezones } from 'countries-and-timezones'

export type TimezoneType = {
  id: number
  name: string
  time: string
}

export const TIMEZONES = Object.values(getAllTimezones())
  .map((timezone, index) => ({
    id: index,
    name: timezone.name,
    //  dstOffsetStr - Daylight Saving Time
    time: timezone.dstOffsetStr,
  }))
  .filter(timezone => !timezone.name.includes('Etc/')) as TimezoneType[]

export const getTimezoneById = (id: number) => TIMEZONES.find(zone => zone.id === id)

export const getTimezoneByName = (name: string | undefined) =>
  name ? TIMEZONES.find(zone => zone.name.includes(name)) : undefined

export const DEFAULT_UTC_DIFFERENCE = '+00:00'
