import { gql } from 'graphql-request'

export const clientSettingsFragment = gql`
  fragment clientSettingsType on clientSettings {
    id
    preferredConnections
    notificationSettings
    lang
  }
`
