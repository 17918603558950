import {
  DropdownItem,
  FadeInTranslateTop,
  getButtonHeightStyle,
  Input,
  InputProps,
  modalsHTMLElement,
  SelectDropDownItem,
  useHandleClickOutside,
  usePopupOpenState,
} from '@expane/ui'
import { flip, offset, useFloating } from '@floating-ui/react-dom'
import { FC, KeyboardEvent, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

export interface InputWithDropDownMenuProps extends InputProps {
  items?: Array<{ id: number; name: string }>
  onInputChange: (value: string) => void
  // размер выпадающего списка
  popupHeight?: 'small' | 'big'
  className?: string
  // для storybook
  customModal?: HTMLElement | null
}

export const InputWithDropDownMenu: FC<InputWithDropDownMenuProps> = ({
  items = [],
  onInputChange,
  value,
  className,
  errorMessage,
  disabled = false,
  popupHeight = 'small',
  customModal,
  height = 'normal',
  ...props
}) => {
  const selectedItem = items.find(item => item.name === value) ?? null
  const defaultInputValue = value ? value.toString() : ''

  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const [inputValue, setInputValue] = useState<string>(defaultInputValue)
  const [activeLink, setActiveLink] = useState<number>(
    selectedItem ? items.findIndex(item => item.id === value) : 0,
  )

  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const [filterRef, setFilterRef] = useState<HTMLDivElement | null>(null)

  useHandleClickOutside(
    [searchInputRef.current, filterRef],
    () => {
      closePopup()
    },
    isOpen,
  )

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(5), flip()],
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  useLayoutEffect(() => {
    reference(searchInputRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, searchInputRef.current])
  useLayoutEffect(() => {
    floating(filterRef)
  }, [filterRef, floating])

  const handlePopUp = () => {
    if (isOpen) {
      closePopup()
    } else {
      openPopup()
      setActiveLink(0)
      searchInputRef.current?.focus()
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab') closePopup()
    if (e.key === 'ArrowDown') setActiveLink(activeLink === items.length - 1 ? 0 : activeLink + 1)
    if (e.key === 'ArrowUp') setActiveLink(activeLink === 0 ? items.length - 1 : activeLink - 1)
    if (e.key === 'Enter') {
      const selectedDropDownItem = items.find((el, index) => activeLink === index)
      if (selectedDropDownItem) handleSelectedItem(selectedDropDownItem)
    }
    if (e.key === 'Escape') {
      closePopup()
      setInputValue('')
      searchInputRef.current?.blur()
    }

    e.stopPropagation()
  }

  const handleSelectedItem = (item: SelectDropDownItem) => {
    if (item.id !== value) onInputChange(item.name)
    setInputValue(item.name)

    closePopup()
    searchInputRef.current?.blur()
  }

  const btnHeightStyle = getButtonHeightStyle(height)

  let popupContainerStyle = 'overflow-x-hidden overflow-y-auto p-1 text-sm text-label-color '
  popupContainerStyle += popupHeight === 'small' ? 'max-h-45' : 'max-h-90'

  return (
    <div className={'truncate ' + className}>
      <Input
        ref={searchInputRef}
        className={btnHeightStyle}
        value={inputValue}
        onChange={e => {
          const value = e.target.value

          onInputChange(value)
          setInputValue(value)
        }}
        onFocus={handlePopUp}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        errorMessage={errorMessage}
        {...props}
      />

      {isOpen &&
        createPortal(
          <div
            ref={setFilterRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              minWidth: searchInputRef.current?.offsetWidth,
            }}
          >
            <FadeInTranslateTop
              className="border-2 border-primary-400 rounded-md box-border bg-block"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="items-list"
              style={{ minWidth: searchInputRef.current?.offsetWidth }}
            >
              <ul className={popupContainerStyle}>
                {items.map((item, index) => (
                  <DropdownItem
                    key={item.id}
                    isSelected={item.id === selectedItem?.id}
                    isActive={index === activeLink}
                    onMouseEnter={() => {
                      if (index !== activeLink) setActiveLink(index)
                    }}
                    item={item}
                    onClick={() => {
                      handleSelectedItem(item)
                      closePopup()
                    }}
                  />
                ))}
              </ul>
            </FadeInTranslateTop>
          </div>,
          customModal ?? modalsHTMLElement,
        )}
    </div>
  )
}
