import {
  CardTemplateWithSubscriptionInfoType,
  ServerBranchType,
  useArchiveCard,
  useCreateGiftCardTemplate,
  useFetchBranchById,
  useFetchCardTemplateById,
  useUpdateCardTemplateName,
  useUpdateGiftCardTemplate,
} from '@expane/data'
import { checkOnlyPositiveAmount, PLACEHOLDERS } from '@expane/logic/form'
import { permissions } from '@expane/logic/permission'
import {
  CloseButton,
  Dialog,
  Input,
  Modal,
  useShowConfirmationPopup,
  useShowWarningPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { getGiftCardsThatWasNotActivated } from 'logic/cards'
import { useShowArchiveConfirmationPopup } from 'logic/hooks/popup/useShowArchiveConfirmationPopup'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm, useFormState, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { ArchiveButton, DuplicateButton, RestoreButton, SaveButton } from 'widgets/Buttons'
import { getDirtyFields } from 'widgets/SubscriptionDialog/logic'
import { checkIfNameWasNotChanged } from '@expane/logic/utils'

export type GiftCardFormValues = {
  name: string
  cardPeriod: string
  price: string
  isItCopy: boolean
}

export const GiftCardDialog: FC<DialogProps> = ({ id: giftCardId, closeDialog, isCreate }) => {
  const branchId = store.branch.branchId
  const { data: branch } = useFetchBranchById(branchId)
  const { data: giftCardById } = useFetchCardTemplateById(giftCardId, branch?.timezone)

  if ((!isCreate && !giftCardById) || !branch) return null

  return (
    <GiftCardDialogLogic
      isCreate={isCreate}
      currentBranch={branch}
      giftCardById={giftCardById}
      closeDialog={closeDialog}
    />
  )
}

interface GiftCardDialogLogicProps {
  currentBranch: ServerBranchType
  giftCardById: CardTemplateWithSubscriptionInfoType | undefined
  closeDialog: () => void
  isCreate: boolean
}

const GiftCardDialogLogic: FC<GiftCardDialogLogicProps> = ({
  giftCardById,
  currentBranch,
  closeDialog,
  isCreate,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!
  const { data: myPermissions } = useFetchMyPermissions()
  const { mutateAsync: createMutation } = useCreateGiftCardTemplate()
  const { mutateAsync: updateMutation } = useUpdateGiftCardTemplate()
  const { mutateAsync: updateNameMutation } = useUpdateCardTemplateName()
  const { mutateAsync: updateArchiveMutation, isLoading: isLoadingOnArchive } = useArchiveCard()

  const { formState, control, handleSubmit } = useForm<GiftCardFormValues>({
    defaultValues: {
      name: giftCardById?.name ?? '',
      price: giftCardById?.price?.toString() ?? '',
      cardPeriod: giftCardById?.cardPeriod.toString() ?? '',
    },
  })

  const { dirtyFields } = useFormState({
    control,
  })

  const watchedName = useWatch({ control, name: 'name' })

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackbar] = useSnackbar()

  const { t } = useTranslation()

  const mutateGiftCard: SubmitHandler<GiftCardFormValues> = async ({
    name,
    price,
    cardPeriod,
    isItCopy,
  }) => {
    if (isCreate || isItCopy) {
      const result = await createMutation({
        name,
        price: Number(price),
        cardPeriod: Number(cardPeriod),
        branchId,
      })

      if (result?.insertCardTemplate?.id) {
        openSnackbar(t('giftCard.successfullyCreated'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else if (giftCardById) {
      const result = await updateMutation({
        id: giftCardById.id,
        cardTemplateSetInput: {
          name,
          price: Number(price),
          cardPeriod: Number(cardPeriod),
        },
      })

      if (result?.updateCardTemplateById?.id) {
        openSnackbar(t('giftCard.successfullyUpdated'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    }
    closeDialog()
  }

  const mutateArchiveGiftCard = async (archived: boolean) => {
    if (!giftCardById) {
      openSnackbar(t('submitError'), 'error')

      return
    }

    const result = await updateArchiveMutation({ id: giftCardById.id, archived })

    if (result?.updateCardTemplateById?.id)
      openSnackbar(
        `${archived ? t('giftCard.successfullyArchived') : t('giftCard.successfullyRestored')}`,
        'success',
      )
    else openSnackbar(t('submitError'), 'error')

    closeDialog()
  }

  const { warningModal: warningCopyModal, showWarningPopup: showCopyWarningPopup } =
    useShowWarningPopup(t('warning'), t('giftCardWarnings.canNotCopy'))

  const { warningModal: warningArchiveModal, showWarningPopup: showArchiveWarningPopup } =
    useShowWarningPopup(t('warning'), t('giftCardWarnings.canNotArchive'))

  const { warningModal, showWarningPopup } = useShowWarningPopup(
    t('warning'),
    t('giftCardWarnings.canNotChange'),
  )

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()
  const { archiveConfirmationModal, showArchiveConfirmationPopup } =
    useShowArchiveConfirmationPopup(`${giftCardById?.name}`, () => mutateArchiveGiftCard(true))

  const archived = Boolean(giftCardById?.archived)

  const isEditingAllowed = myPermissions?.includes(permissions.card.set)

  // не активированные в контексте сертификатов значит, что он был продан, но деньги на счет клиента не поступили
  // то есть архивировать нельзя, фактически он в "использовании"
  const notActivatedCardsByCurrentCardTemplate = getGiftCardsThatWasNotActivated(
    giftCardById?.cards ?? [],
    currentBranch.timezone,
  )

  const areNotActivatedCardsByCurrentCardTemplate = Boolean(
    notActivatedCardsByCurrentCardTemplate.length,
  )
  const areCardsByCurrentCardTemplate = Boolean(giftCardById?.cards.length)

  const handleArchiveProduct = () => {
    if (areNotActivatedCardsByCurrentCardTemplate) showArchiveWarningPopup()
    else showArchiveConfirmationPopup()
  }

  const mutateNameGiftCard = async ({ id, name }: { id: number; name: string }) => {
    const result = await updateNameMutation({ id, name })

    if (result.updateCardTemplatesName.affected_rows) {
      openSnackbar(t('giftCard.nameUpdatedSuccessfully'), 'success')
    } else openSnackbar(t('submitError'), 'error')

    closeDialog()
  }

  const handleOnSave: SubmitHandler<GiftCardFormValues> = async data => {
    // если создание или если редактируется сертификат, который не был продан клиенту
    if (isCreate || (giftCardById && !areCardsByCurrentCardTemplate)) {
      await mutateGiftCard(data)
    }
    // везде в условиях происходят действия с сертификатами, которые уже были ПРОДАНЫ клиентам
    else {
      const { isNameDirty, areFieldsExceptNameDirty } = getDirtyFields(dirtyFields)

      if (isNameDirty && !areFieldsExceptNameDirty && areCardsByCurrentCardTemplate)
        if (giftCardById) await mutateNameGiftCard({ id: giftCardById.id, name: data.name })

      // если не меняется название сертификата и при этом меняются другие поля,
      // показываем ворнинг что мы не можем обновить такой сертификат (так как мы можем менять только название)
      if (!isNameDirty && areCardsByCurrentCardTemplate) showWarningPopup()

      // если мы меняем и название и другие поля, то показываем диалог подтверждения, что мы поменяем только название
      if (isNameDirty && areFieldsExceptNameDirty && areCardsByCurrentCardTemplate) {
        // ранее есть проверка на isCreate, значит сертификат есть
        if (giftCardById)
          showConfirmation({
            title: t('warning'),
            description: t('giftCardWarnings.onlyNameWillBeChanged'),
            onConfirm: () => mutateNameGiftCard({ id: giftCardById.id, name: data.name }),
          })
      }
    }
  }

  const handleOnCopy: SubmitHandler<GiftCardFormValues> = data => {
    const { isNameDirty, areFieldsExceptNameDirty } = getDirtyFields(dirtyFields)

    // если не поменялось название мы не можем создать копию
    if (!isNameDirty && areFieldsExceptNameDirty) showCopyWarningPopup()
    else mutateGiftCard(data)
  }

  const showSaveButtons = isEditingAllowed && !archived

  const disabledSaveButtons = formState.isSubmitting || !formState.isDirty || isLoadingOnArchive
  const disabledArchiveButton = formState.isSubmitting || isLoadingOnArchive

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (showSaveButtons && !formState.isSubmitting && formState.isDirty)
            handleSubmit(mutateGiftCard)()
        }}
      >
        <Dialog>
          <Dialog.Title>{t('card')}</Dialog.Title>

          <Dialog.Body className="w-164">
            <div className="flex justify-between">
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={t('name')}
                    placeholder={t('placeholders.giftCard')}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.name),
                      text: t('formError.required'),
                    }}
                    value={value}
                    onChange={onChange}
                    disabled={!isEditingAllowed || archived}
                    containerClassName="w-1/2"
                    autoFocus
                  />
                )}
              />

              <Controller
                name="price"
                control={control}
                rules={{ required: true, validate: checkOnlyPositiveAmount }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="number"
                    label={t('giftCard.amount')}
                    placeholder={PLACEHOLDERS.servicePrice}
                    containerClassName="ml-2 w-1/2"
                    value={value}
                    onChange={onChange}
                    disabled={!isEditingAllowed || archived}
                    hint={t('giftCard.amountHint')}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.price),
                      text:
                        formState.errors.price?.type === 'required'
                          ? t('formError.required')
                          : t('formError.invalidValue'),
                    }}
                  />
                )}
              />
            </div>

            <Controller
              name="cardPeriod"
              control={control}
              rules={{ required: true, validate: checkOnlyPositiveAmount }}
              render={({ field: { onChange, value } }) => (
                <Input
                  type="number"
                  label={t('validityDays')}
                  placeholder={PLACEHOLDERS.subscriptionDurations}
                  containerClassName="w-1/2"
                  value={value}
                  onChange={onChange}
                  disabled={!isEditingAllowed || archived}
                  hint={t('giftCard.periodHint')}
                  required
                  errorMessage={{
                    isShown: Boolean(formState.errors.cardPeriod),
                    text:
                      formState.errors.cardPeriod?.type === 'required'
                        ? t('formError.required')
                        : t('formError.invalidValue'),
                  }}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            {showSaveButtons && (
              <SaveButton
                onClick={handleSubmit(data => handleOnSave({ ...data, isItCopy: false }))}
                disabled={disabledSaveButtons}
                spinner={formState.isSubmitting}
                isCreate={isCreate}
              />
            )}
            {showSaveButtons && !isCreate && (
              <DuplicateButton
                onClick={handleSubmit(data => handleOnCopy({ ...data, isItCopy: true }))}
                disabled={
                  formState.isSubmitting ||
                  checkIfNameWasNotChanged(giftCardById?.name ?? '', watchedName)
                }
              />
            )}

            <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />

            {isEditingAllowed &&
              !isCreate &&
              (giftCardById?.archived ? (
                <RestoreButton
                  className="mr-auto"
                  onClick={() => mutateArchiveGiftCard(false)}
                  spinner={isLoadingOnArchive}
                  disabled={disabledArchiveButton}
                />
              ) : (
                <ArchiveButton
                  className="mr-auto"
                  onClick={handleArchiveProduct}
                  spinner={isLoadingOnArchive}
                  disabled={disabledArchiveButton}
                />
              ))}
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {warningModal}
      {warningCopyModal}
      {warningArchiveModal}

      {confirmPopup}
      {confirmationModal}
      {archiveConfirmationModal}
    </>
  )
}
