import { gql } from 'graphql-request'
import { request, useQuery, reportError, reportGqlError } from '../../api'
import { ServerInventoryByIdType, ServerInventoryType } from '../../generated/graphql-types'
import { INVENTORIES_QUERY_KEY, INVENTORY_NUMBER_QUERY_KEY } from './queryKeys'
import { inventoryByIdFragment, inventoryFragment } from './inventory.fragments'
import { parseDatesInInventoryGqlResponse } from './logic'
import { DEFAULT_TIMEZONE } from '@expane/date'

export function useFetchInventories(timezone: string | undefined, branchId: number | undefined) {
  return useQuery(
    [INVENTORIES_QUERY_KEY, { branchId }],
    async (): Promise<ServerInventoryType[]> => {
      const result = await request(
        gql`
          ${inventoryFragment}
          query ($branchId: Int!) {
            inventories(where: { branchId: { _eq: $branchId } }, order_by: { createdAt: desc }) {
              ...inventoryType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.inventories)) {
        return result.inventories.map(inventory =>
          parseDatesInInventoryGqlResponse(inventory, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('inventories is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchInventories',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchInventoryById(id: number | undefined, timezone: string | undefined) {
  return useQuery<ServerInventoryByIdType | undefined>(
    [INVENTORIES_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${inventoryByIdFragment}
          query ($id: Int!) {
            inventoryById(id: $id) {
              ...inventoryByIdType
            }
          }
        `,
        { id },
      )

      if (result?.inventoryById) {
        return parseDatesInInventoryGqlResponse(result.inventoryById, timezone ?? DEFAULT_TIMEZONE)
      } else {
        reportError(new Error('inventoryById does not exist'), 'warning', {
          id,
          result,
        })
        return undefined
      }
    },
    {
      queryName: 'useFetchInventoryById',
      enabled: Boolean(id) && Boolean(timezone),
      onError: reportGqlError,
    },
  )
}

export const useGetInventoryNumber = (branchId: number | undefined) =>
  useQuery<number | undefined>(
    [INVENTORIES_QUERY_KEY, INVENTORY_NUMBER_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          query ($branchId: Int!) {
            inventoriesAggregate(where: { branchId: { _eq: $branchId } }) {
              aggregate {
                count
              }
            }
          }
        `,
        { branchId },
      )

      if (result?.inventoriesAggregate?.aggregate?.count !== undefined)
        return result.inventoriesAggregate.aggregate.count + 1
      else reportError(new Error('error in count movementNumber'), 'error', { result })

      return 1
    },
    { queryName: 'useGetInventoryNumber', enabled: Boolean(branchId) },
  )

export const useFetchInventoriesByDate = (
  start: Date,
  end: Date,
  timezone: string | undefined,
  branchId: number | undefined,
) =>
  useQuery<ServerInventoryType[]>(
    [INVENTORIES_QUERY_KEY, { start, end, branchId }],
    async () => {
      const result = await request(
        gql`
          ${inventoryFragment}
          query ($start: timestamptz!, $end: timestamptz!, $branchId: Int!) {
            inventories(
              order_by: { createdAt: desc }
              where: { inventoryDate: { _gt: $start, _lt: $end }, branchId: { _eq: $branchId } }
            ) {
              ...inventoryType
            }
          }
        `,
        { start, end, branchId },
      )

      if (Array.isArray(result?.inventories)) {
        return result.inventories.map(inventory =>
          parseDatesInInventoryGqlResponse(inventory, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('inventories is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchInventoriesByDate',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
