import { useFetchEmployeeSettingsByEmployeeId, useUpdateMyEmployeeLanguage } from '@expane/data'
import { safeWebLocalStorage } from '@expane/web-logic/safeWebLocalStorage'
import { LanguageSwitch as LanguageSwitchUI } from '@expane/ui'
import { LANGUAGE_LOCAL_STORAGE_KEY } from 'i18n'
import i18next from 'i18next'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { store } from 'store'

export const LanguageSwitch: FC<{
  withLabel?: boolean
  containerClassName?: string
}> = observer(({ withLabel = false, containerClassName }) => {
  const { mutate: updateMyEmployeeLanguage } = useUpdateMyEmployeeLanguage()
  const { data: myEmployeeSettings } = useFetchEmployeeSettingsByEmployeeId(store.me.employeeId)

  return (
    <LanguageSwitchUI
      withLabel={withLabel}
      containerClassName={containerClassName}
      onChange={id => {
        safeWebLocalStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, id)
        if (myEmployeeSettings?.id)
          updateMyEmployeeLanguage({ lang: id, id: myEmployeeSettings.id })
        i18next.changeLanguage(id)
      }}
    />
  )
})
