import { Geocode, ScheduleDto } from '@expane/data'

export const transformSchedulesForSelect = (data: Array<ScheduleDto>) => {
  return data.map(obj => ({ ...obj, name: obj.name ?? obj.type }))
}

export interface BranchFormData {
  name: string
  description: string
  scheduleId: number
  timezone: number
  displayCoins: boolean
  currencyId: number
  phone?: string
  address?: string
  geocode?: Geocode | null
}
