import {
  Checkbox,
  CloseButton,
  Dialog,
  InputLabel,
  Modal,
  Paper,
  usePopupOpenState,
} from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { ColumnScaleRange } from './ColumnScaleRange'

const BookingCalendarSettingsDialog: FC<{
  closeDialog: () => void
  columnsAreShrinked: boolean
  setColumnsAreShrinked: (value: boolean) => void
}> = observer(({ closeDialog, columnsAreShrinked, setColumnsAreShrinked }) => {
  const {
    isFirstNameShown,
    setIsFirstNameShown,
    isLastNameShown,
    setIsLastNameShown,
    isAgeShown,
    setIsAgeShown,
    isPhoneShown,
    setIsPhoneShown,
    isEndTimeShown,
    setIsEndTimeShown,
    isStartTimeShown,
    setIsStartTimeShown,
    isServiceShown,
    setIsServiceShown,
    isCalendarAlwaysOpen,
    setIsCalendarAlwaysOpen,
    isCommentIconShown,
    setIsCommentIconShown,
    isEmployeesSortedByGroups,
    setIsEmployeesSortedByGroups,
    isLocationsSortedByGroups,
    setIsLocationsSortedByGroups,
  } = store.bookingSettings

  const { t } = useTranslation()

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('settings')}</Dialog.Title>

        <Dialog.Body>
          <InputLabel label={t('bookingsCard')} />

          <Paper className="flex p-1.5 mb-2">
            <div className="px-2 pt-1 w-60">
              <InputLabel label={t('client.name')} />

              <div className="py-1">
                <Checkbox
                  label={t('firstName')}
                  containerClassName="mb-2"
                  checked={isFirstNameShown}
                  onChange={() => setIsFirstNameShown(!isFirstNameShown)}
                />
                <Checkbox
                  label={t('lastName')}
                  containerClassName="mb-2"
                  checked={isLastNameShown}
                  onChange={() => setIsLastNameShown(!isLastNameShown)}
                />
                <Checkbox
                  label={t('phone')}
                  containerClassName="mb-2"
                  checked={isPhoneShown}
                  onChange={() => setIsPhoneShown(!isPhoneShown)}
                />
                <Checkbox
                  label={t('age')}
                  containerClassName="mb-2"
                  checked={isAgeShown}
                  onChange={() => setIsAgeShown(!isAgeShown)}
                />
                <Checkbox
                  label={t('comment.title')}
                  checked={isCommentIconShown}
                  onChange={() => setIsCommentIconShown(!isCommentIconShown)}
                />
              </div>
            </div>

            <div className="px-2 pt-1 w-60">
              <InputLabel label={t('timeTitle')} />

              <div className="py-1">
                <Checkbox
                  label={t('start')}
                  checked={isStartTimeShown}
                  onChange={() => setIsStartTimeShown(!isStartTimeShown)}
                  containerClassName="mb-2"
                />
                <Checkbox
                  label={t('ending')}
                  checked={isEndTimeShown}
                  onChange={() => setIsEndTimeShown(!isEndTimeShown)}
                />
              </div>

              <InputLabel label={t('service.name')} />

              <div className="py-1">
                <Checkbox
                  label={t('name')}
                  checked={isServiceShown}
                  onChange={() => setIsServiceShown(!isServiceShown)}
                  containerClassName="mb-2"
                />
              </div>
            </div>
          </Paper>

          <InputLabel label={t('calendar')} />

          <Paper className="p-1.5">
            <Checkbox
              label={t('calendarSettings.dateIsOpened')}
              checked={isCalendarAlwaysOpen}
              onChange={() => setIsCalendarAlwaysOpen(!isCalendarAlwaysOpen)}
              containerClassName="px-2 py-1"
            />
            <Checkbox
              label={t('calendarSettings.sortEmployeesByGroups')}
              checked={isEmployeesSortedByGroups}
              onChange={() => setIsEmployeesSortedByGroups(!isEmployeesSortedByGroups)}
              containerClassName="px-2 py-1"
            />
            <Checkbox
              label={t('calendarSettings.sortLocationsByGroups')}
              checked={isLocationsSortedByGroups}
              onChange={() => setIsLocationsSortedByGroups(!isLocationsSortedByGroups)}
              containerClassName="px-2 py-1"
            />

            <ColumnScaleRange
              columnsAreShrinked={columnsAreShrinked}
              setColumnsAreShrinked={setColumnsAreShrinked}
            />
          </Paper>
        </Dialog.Body>

        <Dialog.Footer>
          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
})

export const useOpenBookingCardSettingsDialog = ({
  columnsAreShrinked,
  setColumnsAreShrinked,
}: {
  columnsAreShrinked: boolean
  setColumnsAreShrinked: (value: boolean) => void
}) => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()

  const openBookingCardSettingsDialog = (onClose?: () => void) => {
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const bookingCardSettingsDialog = isOpen ? (
    <BookingCalendarSettingsDialog
      closeDialog={closeDialog}
      columnsAreShrinked={columnsAreShrinked}
      setColumnsAreShrinked={setColumnsAreShrinked}
    />
  ) : null

  return {
    openBookingCardSettingsDialog,
    bookingCardSettingsDialog,
  }
}
