import {
  addDays,
  addMinutes,
  eachDayOfInterval,
  endOfDay,
  getOverlappingDaysInIntervals,
  getOverLappingInterval,
  isBefore,
  isEqual,
  isIntervalWithinLastDaysOfMonth,
  isWithinInterval,
  max,
  min,
  subMinutes,
} from '@expane/date'
import {
  EmployeeFromGroup,
  EmployeeGroupWithSchedule,
  EmployeeScheduleDto,
  ServerSalaryIssueType,
  ServerSalaryRateSettingType,
} from '@expane/data'
import {
  calcTotalSalaryItemsSum,
  checkFromDate,
  getNotPaidSalaryItems,
  getPaidSalaryItems,
} from './helpers'
import {
  getContactStartDate,
  getOverlappingSalaryRateSettingAndPeriod,
  isSalaryRateDaily,
  isSalaryRateMonthly,
} from '../salarySettings'
import { roundValue } from '../utils'
import {
  filterSalaryIssuesByEmployeeId,
  getSalariesIssuesByTypesAndPeriod,
  SalariesIssuesTypes,
} from './filters'
import {
  convertSalaryIssuesToPremiumSalaryItems,
  convertSalaryIssuesToReferralPercentageSalaryItems,
  convertSalaryIssuesToServiceSalaryItems,
} from './converting'
import {
  calcAccruedRate,
  RateSalaryRequestDataWithoutEmployeeType,
  AccruedRateType,
  RateSalaryRequestDataType,
} from './calcAccruedRate'

export interface CountedSalaryType {
  servicesSalary: number
  premiumSalary: number
  accruedSalary: number
  referralPercentageSalary: number
  rateSalary: number
}

export const getEmployeeSalaryForPeriod = ({
  servicesSalaryIssues,
  premiumsSalaryIssues,
  referralPercentagesSalaryIssues,
  salaryRateSettings,
  fromDate,
  toDate,
  employeeSchedules,
}: {
  servicesSalaryIssues: ServerSalaryIssueType[]
  premiumsSalaryIssues: ServerSalaryIssueType[]
  referralPercentagesSalaryIssues: ServerSalaryIssueType[]
  salaryRateSettings: ServerSalaryRateSettingType[] | undefined
  fromDate: Date
  toDate: Date
  employeeSchedules: EmployeeScheduleDto[]
}): CountedSalaryType => {
  const fullSalaryInfo: CountedSalaryType = {
    servicesSalary: 0,
    premiumSalary: 0,
    accruedSalary: 0,
    referralPercentageSalary: 0,
    rateSalary: 0,
  }

  if (!salaryRateSettings) return fullSalaryInfo

  const contractStartDate = getContactStartDate(salaryRateSettings)

  const rateSalaries = salaryRateSettings.length
    ? salaryRateSettings.map(salaryRateSetting =>
        isSalaryRateDaily(salaryRateSetting) || isIntervalWithinLastDaysOfMonth(fromDate, toDate)
          ? calcAccruedRate({
              fromDate,
              toDate,
              salaryRateSetting,
              employeeSchedules,
              contractStartDate,
            }).totalSum
          : 0,
      )
    : []

  const overallRateSalary = rateSalaries.reduce((acc, value) => acc + value, 0)

  const servicesSalaryItems = convertSalaryIssuesToServiceSalaryItems(servicesSalaryIssues)
  const premiumsSalaryItems = convertSalaryIssuesToPremiumSalaryItems(premiumsSalaryIssues)
  const referralPercentagesSalaryItems = convertSalaryIssuesToReferralPercentageSalaryItems(
    referralPercentagesSalaryIssues,
  )

  const overallServiceSalary = calcTotalSalaryItemsSum(servicesSalaryItems)
  const overallPremiumSalary = calcTotalSalaryItemsSum(premiumsSalaryItems)
  const overallReferralPercentageSalary = calcTotalSalaryItemsSum(referralPercentagesSalaryItems)

  const accruedSalary =
    overallServiceSalary +
    overallPremiumSalary +
    overallReferralPercentageSalary +
    overallRateSalary

  fullSalaryInfo.servicesSalary = overallServiceSalary
  fullSalaryInfo.premiumSalary = overallPremiumSalary
  fullSalaryInfo.referralPercentageSalary = overallReferralPercentageSalary
  fullSalaryInfo.rateSalary = overallRateSalary
  fullSalaryInfo.accruedSalary = accruedSalary

  return fullSalaryInfo
}

export const getEmployeePaidSalaryAmountForPeriod = ({
  servicesSalaryIssues,
  premiumsSalaryIssues,
  referralPercentagesSalaryIssues,
  salaryRateSettings,
  salaryRatesSalaryIssues,
  fromDate,
  toDate,
}: {
  servicesSalaryIssues: ServerSalaryIssueType[]
  premiumsSalaryIssues: ServerSalaryIssueType[]
  referralPercentagesSalaryIssues: ServerSalaryIssueType[]
  salaryRateSettings: ServerSalaryRateSettingType[] | undefined
  salaryRatesSalaryIssues: ServerSalaryIssueType[]
  fromDate: Date
  toDate: Date
}): number => {
  const paidServicesSalaryItems = getPaidSalaryItems(
    convertSalaryIssuesToServiceSalaryItems(servicesSalaryIssues),
  )
  const paidPremiumsSalaryItems = getPaidSalaryItems(
    convertSalaryIssuesToPremiumSalaryItems(premiumsSalaryIssues),
  )
  const paidReferralPercentagesSalaryItems = getPaidSalaryItems(
    convertSalaryIssuesToReferralPercentageSalaryItems(referralPercentagesSalaryIssues),
  )

  const overallServiceSalary = calcTotalSalaryItemsSum(paidServicesSalaryItems)
  const overallPremiumSalary = calcTotalSalaryItemsSum(paidPremiumsSalaryItems)
  const overallReferralPercentageSalary = calcTotalSalaryItemsSum(
    paidReferralPercentagesSalaryItems,
  )

  if (!salaryRateSettings) return 0

  const contractStartDate = getContactStartDate(salaryRateSettings)

  const currentSalaryRateSettings = salaryRateSettings.filter(
    salaryRateSetting =>
      getOverlappingSalaryRateSettingAndPeriod({
        fromDate,
        toDate,
        salaryRateSetting,
      }).length,
  )

  const rateSalaries = currentSalaryRateSettings.length
    ? currentSalaryRateSettings.map(salaryRateSetting => {
        return isSalaryRateDaily(salaryRateSetting) ||
          isIntervalWithinLastDaysOfMonth(fromDate, toDate)
          ? calcPaidMonthlyRate({
              contractStartDate,
              salaryRateSetting,
              salaryRatesSalaryIssues,
              fromDate,
              toDate,
            })
          : 0
      })
    : []

  const rateSalariesSum = rateSalaries.reduce((acc, value) => acc + value, 0)

  return (
    overallServiceSalary + overallPremiumSalary + overallReferralPercentageSalary + rateSalariesSum
  )
}

const calcPaidMonthlyRate = ({
  contractStartDate,
  salaryRateSetting,
  salaryRatesSalaryIssues,
  fromDate,
  toDate,
}: {
  contractStartDate: Date
  salaryRateSetting: ServerSalaryRateSettingType
  fromDate: Date
  toDate: Date
  salaryRatesSalaryIssues: ServerSalaryIssueType[]
}): number => {
  if (isBefore(toDate, contractStartDate)) return 0

  // пересечение текущей ставки и выплаченных salaryIssue ставок
  const overlappingSalaryRatesSalaryIssues = salaryRatesSalaryIssues.filter(salaryIssue =>
    getOverlappingDaysInIntervals(
      // у salaryIssue ставок всегда есть startPeriod и endPeriod
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      { start: salaryIssue.startPeriod!, end: salaryIssue.endPeriod! },
      { start: salaryRateSetting.start, end: salaryRateSetting.end ?? toDate },
    ),
  )

  if (overlappingSalaryRatesSalaryIssues.length === 0) return 0

  let totalSum = 0

  for (let i = 0; i < overlappingSalaryRatesSalaryIssues.length; i++) {
    const checkedFromDate = checkFromDate(
      contractStartDate,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      overlappingSalaryRatesSalaryIssues[i].startPeriod!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      overlappingSalaryRatesSalaryIssues[i].endPeriod!,
    )

    const eachDayOfTransactionInterval = eachDayOfInterval({
      start: checkedFromDate,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      end: overlappingSalaryRatesSalaryIssues[i].endPeriod!,
    })

    const rateForOneWorkingDayFromTransaction =
      overlappingSalaryRatesSalaryIssues[i].value / eachDayOfTransactionInterval.length

    // находим пересекается ли выбранный период времени с периодами выплаты транзакции
    const intersection = getOverLappingInterval(
      {
        start: checkedFromDate,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        end: overlappingSalaryRatesSalaryIssues[i].endPeriod!,
      },
      {
        start: fromDate,
        end: toDate,
      },
    )

    const monthRate = rateForOneWorkingDayFromTransaction * intersection.length

    // проверка на intersection.length !== 0 нужна чтоб не начислялась месячная зп лишний раз
    if (isSalaryRateMonthly(salaryRateSetting) && intersection.length !== 0) {
      totalSum += monthRate
    }
    if (isSalaryRateDaily(salaryRateSetting)) {
      totalSum += intersection.length !== 0 ? overlappingSalaryRatesSalaryIssues[i].value : 0
    }
  }

  return totalSum
}

export interface CountedNotPaidSalaryType {
  employeeId: number
  notPaidPremiums: number
  notPaidServices: number
  notPaidReferralPercentages: number
  notPaidSalaryRate: number
  notPaidRateSalaryRequestData: RateSalaryRequestDataType[]
  totalSum: number
  period: { startPeriod: Date; endPeriod: Date }
}

export const getEmployeeNotPaidSalaryForPeriod = ({
  employeeId,
  servicesSalaryIssues,
  premiumsSalaryIssues,
  referralPercentagesSalaryIssues,
  salaryRatesSalaryIssues,
  salaryRateSettings,
  fromDate,
  toDate,
  employeeSchedules,
  isCalcDebt,
}: {
  employeeId: number
  servicesSalaryIssues: ServerSalaryIssueType[]
  premiumsSalaryIssues: ServerSalaryIssueType[]
  referralPercentagesSalaryIssues: ServerSalaryIssueType[]
  salaryRatesSalaryIssues: ServerSalaryIssueType[]
  salaryRateSettings: ServerSalaryRateSettingType[] | undefined
  fromDate: Date
  toDate: Date
  employeeSchedules: EmployeeScheduleDto[]
  isCalcDebt?: boolean
}): CountedNotPaidSalaryType => {
  const defaultNotPaidSalaries = {
    employeeId,
    notPaidSalaryRate: 0,
    notPaidPremiums: 0,
    notPaidServices: 0,
    notPaidReferralPercentages: 0,
    notPaidRateSalaryRequestData: [],
    totalSum: 0,
    period: { startPeriod: fromDate, endPeriod: toDate },
  }

  const notPaidServicesSalaryItems = getNotPaidSalaryItems(
    convertSalaryIssuesToServiceSalaryItems(servicesSalaryIssues),
  )
  const notPaidPremiumsSalaryItems = getNotPaidSalaryItems(
    convertSalaryIssuesToPremiumSalaryItems(premiumsSalaryIssues),
  )
  const notPaidReferralPercentagesSalaryItems = getNotPaidSalaryItems(
    convertSalaryIssuesToReferralPercentageSalaryItems(referralPercentagesSalaryIssues),
  )

  const overallServiceSalary = calcTotalSalaryItemsSum(notPaidServicesSalaryItems)
  const overallPremiumSalary = calcTotalSalaryItemsSum(notPaidPremiumsSalaryItems)
  const overallReferralPercentageSalary = calcTotalSalaryItemsSum(
    notPaidReferralPercentagesSalaryItems,
  )

  const contractStartDate = getContactStartDate(salaryRateSettings ?? [])

  const toDatePreviousPeriod = subMinutes(fromDate, 1)

  const checkedFromDate = isCalcDebt ? contractStartDate : fromDate
  const checkedToDate = isCalcDebt ? toDatePreviousPeriod : toDate

  const isContractStartsWithinSelectedInterval = isWithinInterval(contractStartDate, {
    start: fromDate,
    end: toDate,
  })
  if (
    isCalcDebt &&
    (isContractStartsWithinSelectedInterval || isBefore(checkedToDate, contractStartDate))
  )
    return defaultNotPaidSalaries

  const currentSalaryRateSettings =
    salaryRateSettings?.filter(
      salaryRateSetting =>
        getOverlappingSalaryRateSettingAndPeriod({
          fromDate: checkedFromDate,
          toDate: checkedToDate,
          salaryRateSetting,
        }).length,
    ) ?? []

  const rateSalaries = currentSalaryRateSettings.length
    ? currentSalaryRateSettings.map(salaryRateSetting => {
        if (
          isSalaryRateDaily(salaryRateSetting) ||
          isIntervalWithinLastDaysOfMonth(fromDate, toDate)
        ) {
          const accruedRate = calcAccruedRate({
            fromDate: checkedFromDate,
            toDate: checkedToDate,
            salaryRateSetting,
            employeeSchedules,
            contractStartDate,
          })

          const paidRate = calcPaidMonthlyRate({
            contractStartDate,
            salaryRateSetting,
            salaryRatesSalaryIssues,
            fromDate: checkedFromDate,
            toDate: checkedToDate,
          })

          return {
            totalSum: accruedRate.totalSum - paidRate,
            rateSalaryRequestData: getNotPaidRateSalaryRequestDataByIntervals({
              salaryRateSetting,
              salaryRatesSalaryIssues,
              accruedRate,
              paidRate,
            }),
          }
        } else return { totalSum: 0, rateSalaryRequestData: [] }
      })
    : []

  const rateSalariesSum = roundValue(rateSalaries.reduce((acc, value) => acc + value.totalSum, 0))

  const totalSum =
    overallServiceSalary + overallPremiumSalary + overallReferralPercentageSalary + rateSalariesSum

  return {
    employeeId,
    notPaidPremiums: overallPremiumSalary,
    notPaidServices: overallServiceSalary,
    notPaidReferralPercentages: overallReferralPercentageSalary,
    notPaidSalaryRate: rateSalariesSum,
    notPaidRateSalaryRequestData: addEmployeeIdToRateSalaryRequestData(rateSalaries, employeeId),
    totalSum,
    period: { startPeriod: checkedFromDate, endPeriod: checkedToDate },
  }
}

// так как у нас может быть выплаченный отдельный период из выбранного (например из месяца уже оплачена неделя)
// то необхидимо начисленную ставку разделить на части, исключая выплаченный период
const getNotPaidRateSalaryRequestDataByIntervals = ({
  salaryRateSetting,
  salaryRatesSalaryIssues,
  accruedRate,
  paidRate,
}: {
  salaryRatesSalaryIssues: ServerSalaryIssueType[]
  salaryRateSetting: ServerSalaryRateSettingType
  accruedRate: AccruedRateType
  paidRate: number
}): RateSalaryRequestDataWithoutEmployeeType => {
  // если не выплаченная сумма = 0, то rateSalaryRequestData нет
  if (accruedRate.totalSum - paidRate === 0) return []
  else {
    if (isSalaryRateMonthly(salaryRateSetting)) return accruedRate.rateSalaryRequestData
    // так как начисленная ставка делится в зависимости от формул
    // то за выбранный период может быть несколько ставок(это касается дневных ставок)
    // и если в одной из начисленных ставок может быть выплаченный период, то этот период нужно так же исключить
    else
      return accruedRate.rateSalaryRequestData
        .map(data =>
          getIntervalsOfNotPaidDailyRates({
            salaryRatesSalaryIssues,
            salaryRateSetting,
            fromDate: data.startPeriod,
            toDate: data.endPeriod,
          }),
        )
        .flat()
  }
}

const getIntervalsOfNotPaidDailyRates = ({
  salaryRatesSalaryIssues,
  salaryRateSetting,
  fromDate,
  toDate,
}: {
  salaryRatesSalaryIssues: ServerSalaryIssueType[]
  salaryRateSetting: ServerSalaryRateSettingType
  fromDate: Date
  toDate: Date
}): RateSalaryRequestDataWithoutEmployeeType => {
  const paidPeriods = salaryRatesSalaryIssues
    .filter(tr =>
      getOverlappingDaysInIntervals(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { start: tr.startPeriod!, end: addMinutes(tr.endPeriod!, 1) },
        { start: fromDate, end: toDate },
      ),
    )
    .map(({ startPeriod, endPeriod }) => ({ startPeriod, endPeriod }))

  const eachDayOfSelectedPeriod = eachDayOfInterval({
    start: fromDate,
    end: toDate,
  })

  const notPaidDays = eachDayOfSelectedPeriod.filter(
    day =>
      !paidPeriods.some(({ startPeriod, endPeriod }) =>
        startPeriod && endPeriod
          ? isWithinInterval(day, { start: startPeriod, end: endPeriod })
          : null,
      ),
  )

  const notPaidDailyRatesDates: Array<Array<Date>> = []
  const indexOfSlicingDates: Array<number> = []

  // делим массив дат на массивы дат по интервалам
  for (let i = 0; i < notPaidDays.length; i++) {
    if (!isEqual(addDays(notPaidDays[i], 1), notPaidDays[i + 1])) {
      indexOfSlicingDates.push(notPaidDays.indexOf(notPaidDays[i]) + 1)
    }
  }

  for (let i = 0; i < indexOfSlicingDates.length; i++) {
    let eachDaysOfIntervalsNotPaidDays: Array<Date> = []

    eachDaysOfIntervalsNotPaidDays = notPaidDays.slice(
      indexOfSlicingDates[i],
      indexOfSlicingDates[i + 1],
    )

    notPaidDailyRatesDates.push(
      eachDaysOfIntervalsNotPaidDays.length === 0
        ? notPaidDays.slice(0, indexOfSlicingDates[0])
        : eachDaysOfIntervalsNotPaidDays,
    )
  }

  return notPaidDailyRatesDates.map(dates => ({
    salarySettingId: salaryRateSetting.id,
    startPeriod: min(dates),
    endPeriod: endOfDay(max(dates)),
    value: salaryRateSetting.rateValue,
  }))
}

const addEmployeeIdToRateSalaryRequestData = (
  rateSalaries: {
    rateSalaryRequestData: RateSalaryRequestDataWithoutEmployeeType
  }[],
  employeeId: number,
) => {
  const rateSalaryRequestDataWithEmployeeId: RateSalaryRequestDataType[] = []

  for (const { rateSalaryRequestData } of rateSalaries) {
    for (const rateInfo of rateSalaryRequestData) {
      rateSalaryRequestDataWithEmployeeId.push({ ...rateInfo, employeeId })
    }
  }

  return rateSalaryRequestDataWithEmployeeId
}

export interface CountedSalariesFullInfoType {
  groupId: number
  salaries: Array<CountedSalariesFullInfoByEmployeeType>
}

export interface CountedSalariesFullInfoByEmployeeType {
  employeeId: number
  accruedSalary: number
  paidSalary: number
  notPaidSalary: CountedNotPaidSalaryType
  debtSalary: CountedNotPaidSalaryType
}

export const getCountedSalariesFullInfo = ({
  salaryIssues,
  employeeGroups,
  toDate,
  fromDate,
}: {
  salaryIssues: ServerSalaryIssueType[] | undefined
  employeeGroups: EmployeeGroupWithSchedule[] | undefined
  fromDate: Date
  toDate: Date
}): Array<CountedSalariesFullInfoType> => {
  if (!employeeGroups) return []

  const salariesIssuesByTypesAndPeriod = getSalariesIssuesByTypesAndPeriod({
    salaryIssues,
    fromDate,
    toDate,
  })

  return employeeGroups.map(group => ({
    groupId: group.id,
    salaries: group.employees.map(employee =>
      countedFullSalariesByEmployee({
        toDate,
        fromDate,
        employee,
        ...salariesIssuesByTypesAndPeriod,
      }),
    ),
  }))
}

interface CountedFullSalariesByEmployeeParams extends SalariesIssuesTypes {
  fromDate: Date
  toDate: Date
  employee: EmployeeFromGroup
}

export const countedFullSalariesByEmployee = ({
  toDate,
  fromDate,
  employee,
  servicesSalaryIssuesInSelectedPeriod,
  referralSalaryIssuesInSelectedPeriod,
  premiumsSalaryIssuesInSelectedPeriod,
  servicesSalaryIssuesInPreviousPeriod,
  salaryRatesSalaryIssues,
  premiumsSalaryIssuesInPreviousPeriod,
  referralSalaryIssuesInPreviousPeriod,
}: CountedFullSalariesByEmployeeParams): CountedSalariesFullInfoByEmployeeType => {
  const { id, employeeSchedules, salarySettings: salaryRateSettingByEmployeeId } = employee

  const servicesSalaryIssuesByEmployeeIdInSelectedPeriod = filterSalaryIssuesByEmployeeId(
    servicesSalaryIssuesInSelectedPeriod,
    id,
  )

  const premiumsByEmployeeIdInSelectedPeriod = filterSalaryIssuesByEmployeeId(
    premiumsSalaryIssuesInSelectedPeriod,
    id,
  )

  const referralPercentagesInSelectedPeriod = filterSalaryIssuesByEmployeeId(
    referralSalaryIssuesInSelectedPeriod,
    id,
  )

  const salaryRatesSalaryIssuesByEmployeeId = filterSalaryIssuesByEmployeeId(
    salaryRatesSalaryIssues,
    id,
  )

  return {
    employeeId: id,
    accruedSalary: getEmployeeSalaryForPeriod({
      servicesSalaryIssues: servicesSalaryIssuesByEmployeeIdInSelectedPeriod,
      premiumsSalaryIssues: premiumsByEmployeeIdInSelectedPeriod,
      referralPercentagesSalaryIssues: referralPercentagesInSelectedPeriod,
      salaryRateSettings: salaryRateSettingByEmployeeId,
      fromDate,
      toDate,
      employeeSchedules,
    }).accruedSalary,
    paidSalary: getEmployeePaidSalaryAmountForPeriod({
      servicesSalaryIssues: servicesSalaryIssuesByEmployeeIdInSelectedPeriod,
      premiumsSalaryIssues: premiumsByEmployeeIdInSelectedPeriod,
      referralPercentagesSalaryIssues: referralPercentagesInSelectedPeriod,
      salaryRateSettings: salaryRateSettingByEmployeeId,
      salaryRatesSalaryIssues: salaryRatesSalaryIssuesByEmployeeId,
      fromDate,
      toDate,
    }),
    notPaidSalary: getEmployeeNotPaidSalaryForPeriod({
      employeeId: id,
      servicesSalaryIssues: servicesSalaryIssuesByEmployeeIdInSelectedPeriod,
      premiumsSalaryIssues: premiumsByEmployeeIdInSelectedPeriod,
      referralPercentagesSalaryIssues: referralPercentagesInSelectedPeriod,
      salaryRateSettings: salaryRateSettingByEmployeeId,
      salaryRatesSalaryIssues: salaryRatesSalaryIssuesByEmployeeId,
      fromDate,
      toDate,
      employeeSchedules,
    }),
    debtSalary: getEmployeeNotPaidSalaryForPeriod({
      employeeId: id,
      servicesSalaryIssues: filterSalaryIssuesByEmployeeId(
        servicesSalaryIssuesInPreviousPeriod,
        id,
      ),
      premiumsSalaryIssues: filterSalaryIssuesByEmployeeId(
        premiumsSalaryIssuesInPreviousPeriod,
        id,
      ),
      referralPercentagesSalaryIssues: filterSalaryIssuesByEmployeeId(
        referralSalaryIssuesInPreviousPeriod,
        id,
      ),
      salaryRatesSalaryIssues: salaryRatesSalaryIssuesByEmployeeId,
      salaryRateSettings: salaryRateSettingByEmployeeId,
      fromDate,
      toDate,
      employeeSchedules,
      isCalcDebt: true,
    }),
  }
}
