import { Geocode, useCreateBranch, useFetchSchedules } from '@expane/data'
import { filterOnlyWeeklySchedules } from '@expane/logic/schedule'
import { DEFAULT_TIMEZONE, getTimezoneById } from '@expane/date'
import {
  CloseButton,
  Dialog,
  Input,
  Modal,
  SelectDropdown,
  Textarea,
  usePopupOpenState,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { FC, useMemo } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SaveButton } from 'widgets/Buttons'
import { useOpenScheduleDialog } from 'widgets/ScheduleDialog'
import { ScheduleInfoWidget } from 'widgets/ScheduleInfoWidget'
import { TimezonesSelect } from 'widgets/TimezonesSelect'
import { transformSchedulesForSelect } from 'pages/SettingsPage/BranchesSettings/BranchBlock/logic'
import { BranchAddress } from 'widgets/BranchCreateDialog/BranchAddress'
import { checkValidPhone } from '@expane/logic/phone'
import { PhoneInput } from 'ui/PhoneInput'
import { showPhoneErrorMessage } from 'logic/form'
import { PLACEHOLDERS } from '@expane/logic/form'

export interface BranchCreateFormData {
  name: string
  scheduleId: number
  timezone: number
  storageName: string
  description: string
  phone?: string
  address?: string
  geocode?: Geocode | null
}
export const BranchCreateDialog: FC<{ closeDialog: () => void }> = ({ closeDialog }) => {
  const { t } = useTranslation()

  const { openCreateDialog, dialog } = useOpenScheduleDialog()

  const { data: schedules } = useFetchSchedules()

  const { mutateAsync: createBranch } = useCreateBranch()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<BranchCreateFormData>({
    defaultValues: { name: '' },
  })

  const [openSnackbar] = useSnackbar()

  const onCreateNewSchedule = (id: number) => {
    setValue('scheduleId', id)
  }
  const onSubmitForm: SubmitHandler<BranchCreateFormData> = async ({
    name,
    scheduleId,
    timezone,
    storageName,
    description,
    phone,
    address,
    geocode,
  }) => {
    try {
      await createBranch({
        name,
        scheduleId,
        timezone: getTimezoneById(timezone)?.name ?? DEFAULT_TIMEZONE,
        description,
        storageName,
        accountName: t('cashbox'),
        address,
        phone,
        geocode,
      })
      openSnackbar(t('branch.createSuccess'), 'success')
    } catch {
      openSnackbar(t('submitError'), 'error')
    }
    closeDialog()
  }

  const watchedScheduleId = useWatch({ control, name: 'scheduleId' })
  const schedule = useMemo(
    () => schedules?.find(schedule => schedule.id === watchedScheduleId),
    [watchedScheduleId, schedules],
  )

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('branch.name')}</Dialog.Title>

        <Dialog.Body className="w-288 flex">
          <div className="w-1/2 mr-2">
            <div className="flex">
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    label={t('title')}
                    placeholder={t('placeholders.businessName')}
                    required
                    hint={t('branchesHint')}
                    containerClassName="w-1/2 mr-1"
                    errorMessage={{
                      isShown: Boolean(error),
                      text: t('formError.required'),
                    }}
                    {...field}
                  />
                )}
              />

              <Controller
                control={control}
                name="timezone"
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TimezonesSelect
                    value={value}
                    onSelectChange={onChange}
                    className="mr-2 grow"
                    containerClassName="w-1/2 ml-1"
                    errorMessage={{
                      isShown: Boolean(error),
                      text: t('formError.required'),
                      reserveIndent: true,
                    }}
                    required
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                name="storageName"
                control={control}
                render={({ field }) => (
                  <Input
                    containerClassName="w-1/2 mr-1 mb-4"
                    label={t('branch.storageName')}
                    placeholder={t('placeholders.businessName')}
                    hint={t('branch.nameHint')}
                    {...field}
                  />
                )}
              />

              <Controller
                name="scheduleId"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <SelectDropdown
                    label={t('schedule.name')}
                    value={value}
                    items={transformSchedulesForSelect(filterOnlyWeeklySchedules(schedules ?? []))}
                    placeholder={t('placeholders.defaultSelect')}
                    required
                    noDataMessage={t('noSchedule')}
                    className="w-1/2 ml-1"
                    onSelectChange={onChange}
                    onPlusClick={() => openCreateDialog(true, onCreateNewSchedule)}
                    errorMessage={{
                      isShown: Boolean(error),
                      text: t('formError.required'),
                    }}
                  />
                )}
              />
            </div>

            <Controller
              name="phone"
              control={control}
              rules={{
                validate: value => {
                  if (value) return checkValidPhone(value)

                  return true
                },
              }}
              render={({ field: { onChange, value }, formState }) => (
                <PhoneInput
                  label={t('branchPhone')}
                  placeholder={PLACEHOLDERS.phone}
                  errorMessage={{
                    isShown: Boolean(formState.errors.phone),
                    text: showPhoneErrorMessage(formState.errors.phone?.type ?? '', t),
                  }}
                  value={value ?? ''}
                  onChange={onChange}
                  containerClassName="w-1/2 mt-4"
                />
              )}
            />

            <ScheduleInfoWidget schedule={schedule} />

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Textarea
                  containerClassName="mb-4"
                  label={t('description')}
                  placeholder={t('placeholders.branchDescription')}
                  {...field}
                />
              )}
            />
          </div>
          <div className="grow">
            <BranchAddress control={control} setValue={setValue} />
          </div>
        </Dialog.Body>

        <Dialog.Footer>
          <SaveButton
            disabled={isSubmitting}
            spinner={isSubmitting}
            onClick={handleSubmit(onSubmitForm)}
          />
          <CloseButton onClick={closeDialog} disabled={isSubmitting} />
        </Dialog.Footer>
      </Dialog>

      {dialog}
    </Modal>
  )
}

export const useOpenBranchCreateDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialog = isOpen ? <BranchCreateDialog closeDialog={closePopup} /> : null

  return { openDialog: openPopup, dialog }
}
