import { FC, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import { Area, Point } from 'react-easy-crop/types'
import { Range } from 'ui/Range'

import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5'
import { IconType } from 'react-icons'

export type ImageCropShape = 'rect' | 'round'

export type PropsWithImageCropperOptions<T> = T & {
  cropShape?: ImageCropShape
  croppedSize?: number
}

type Props = {
  getBlob: (img: Blob) => void
  inputImg?: File
  zoomImg?: number
  className?: string
}

export const ImageCropper: FC<PropsWithImageCropperOptions<Props>> = ({
  getBlob,
  inputImg,
  zoomImg,
  className,
  cropShape,
  croppedSize,
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(ZOOM_MIN)
  const [inputImgUrl, setInputImgUrl] = useState<string>()

  const onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
    const croppedImage = await getCroppedImg(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      inputImgUrl!,
      croppedAreaPixels,
      croppedSize || 300,
      croppedSize || 300,
    )
    getBlob(croppedImage)
  }

  useEffect(() => {
    setInputImgUrl(URL.createObjectURL(inputImg as Blob))
  }, [inputImg])

  useEffect(() => {
    if (zoomImg) {
      setZoom(zoomImg)
    }
  }, [zoomImg])

  const handleZoomIn = () => setZoom(Math.max(zoom - ZOOM_STEP, ZOOM_MIN))

  const handleZoomOut = () => setZoom(Math.min(zoom + ZOOM_STEP, ZOOM_MAX))

  return (
    <>
      <div className={'relative ' + (className ?? '')}>
        {/* родительский компонент должен быть `position: relative` */}
        <Cropper
          image={inputImgUrl}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropShape={cropShape ?? 'round'}
          showGrid={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className="flex-centered mt-6">
        <IconButton
          Icon={IoRemoveCircleOutline}
          onClick={handleZoomIn}
          disabled={zoom <= ZOOM_MIN}
        />
        <Range
          values={[zoom]}
          min={ZOOM_MIN}
          max={ZOOM_MAX}
          className="w-60 mx-4"
          onChange={values => {
            setZoom(values[0])
          }}
          placeholderValues={[0]}
        />
        <IconButton Icon={IoAddCircleOutline} onClick={handleZoomOut} disabled={zoom >= ZOOM_MAX} />
      </div>
    </>
  )
}

const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.src = url
  })

const getCroppedImg = async (imageSrc: string, crop: Area, width: number, height: number) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  canvas.width = width
  canvas.height = height

  ctx?.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height)

  return new Promise<Blob>(resolve => {
    canvas.toBlob(blob => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resolve(blob!)
    }, 'image/jpeg')
  })
}

const ZOOM_MIN = 1
const ZOOM_MAX = 3
const ZOOM_STEP = 0.1

interface IconButtonProps {
  Icon: IconType
  onClick: () => void
  className?: string
  iconSize?: string
  disabled?: boolean
}

// Needs to be moved into a separate ui component.
const IconButton: FC<IconButtonProps> = ({
  Icon,
  onClick,
  className,
  iconSize = '1.5em',
  disabled = false,
}) => {
  const styles = [
    'transition-all p-2',
    disabled ? 'text-primary-200' : 'text-primary-400',
    className,
  ].join(' ')

  return (
    <button onClick={onClick} disabled={disabled} className={styles}>
      <Icon size={iconSize} />
    </button>
  )
}
