import {
  useFetchClientById,
  useFetchCurrentBranchTimezone,
  useFetchNotActivatedGiftCards,
} from '@expane/data'
import { getHasClientMessageSendConnections } from '@expane/logic/client'
import { Button, CloseButton, Dialog, Modal, Spinner, usePopupOpenState } from '@expane/ui'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IoPaperPlane } from 'react-icons/io5'
import { store } from 'store'
import { useOpenSendMessageDialog } from 'widgets/SendMessageDialog'

type GiftCardSaleNoticeProps = {
  clientId: number
  cardIds: number[]
  closeDialog: () => void
}

const GiftCardSaleNotice: FC<GiftCardSaleNoticeProps> = ({ clientId, cardIds, closeDialog }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: allCards, isLoading } = useFetchNotActivatedGiftCards(timezone, branchId)
  const { data: client } = useFetchClientById(clientId, timezone)

  const isSendMessageAllowed = getHasClientMessageSendConnections(
    client?.clientSettings.preferredConnections,
  )

  const cards = allCards?.filter(card => cardIds.includes(card.id))

  const { dialog, openDialog } = useOpenSendMessageDialog()

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('giftCardActivation.code')}</Dialog.Title>
        <Dialog.Body className="min-w-128">
          {isLoading ? (
            <Spinner expandCentered />
          ) : (
            <div className="text-main-color">
              {cards?.map(card => {
                const cardName = card.cardTemplate.name
                const cardCode = card.code

                return (
                  <div key={card.id}>
                    {`${t('giftCardActivation.code')} "${cardName}": `}
                    <span className="font-medium text-gray-700 dark:text-gray-200">{cardCode}</span>
                  </div>
                )
              })}
            </div>
          )}
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            type="primary"
            // если у клиента не выбраны каналы уведомлений не можем отправить сообщение
            disabled={!isSendMessageAllowed}
            onClick={() => {
              const message = cards
                ?.map(
                  card =>
                    `${t('giftCardActivation.code')} "${card.cardTemplate.name}": ${card.code}`,
                )
                .join('\n')

              openDialog(clientId, message, closeDialog)
            }}
            Icon={IoPaperPlane}
          >
            {t('sendToClient')}
          </Button>

          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
      {dialog}
    </Modal>
  )
}

export const useGiftCardSaleNotice = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const cardIdsForNotice = useRef<number[]>([])
  const clientIdForNotice = useRef<number>()
  const onCloseFunction = useRef<() => void | undefined>(() => {
    return
  })

  const openGiftCardSaleNotice = (
    clientId: number,
    cardIds: number[],
    onClose?: () => void | undefined,
  ) => {
    if (onClose) onCloseFunction.current = onClose
    cardIdsForNotice.current = cardIds
    clientIdForNotice.current = clientId
    openPopup()
  }

  const closeGiftCardSaleNotice = () => {
    closePopup()
    onCloseFunction.current()
  }

  const giftCardSaleNotice =
    isOpen && clientIdForNotice.current ? (
      <GiftCardSaleNotice
        closeDialog={closeGiftCardSaleNotice}
        cardIds={cardIdsForNotice.current}
        clientId={clientIdForNotice.current}
      />
    ) : null

  return {
    openGiftCardSaleNotice,
    giftCardSaleNotice,
  }
}
