import { gql } from 'graphql-request'

export const briefClientDocumentFragment = gql`
  fragment briefClientDocumentType on clientDocument {
    startDate
    endDate
  }
`

export const clientDocumentFragment = gql`
  fragment clientDocumentType on clientDocument {
    id
    name
    type
    clientId
    author {
      firstName
      lastName
    }
    createdAt
    startDate
    endDate
    fileUrl
    managingEmployeeId
  }
`
