import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { ARCHIVED_EMPLOYEE_GROUPS_QUERY_KEY, EMPLOYEE_GROUPS_QUERY_KEY } from './queryKeys'
import {
  ServerEmployeeGroupInsertInput,
  ServerEmployeeGroupOwnerViewSetInput,
  ServerEmployeeGroupSetInput,
} from '../../generated/graphql-types'
import { EMPLOYEES_QUERY_KEY } from '../employee/queryKeys'

export function useCreateEmployeeGroup() {
  return useMutation(
    async (
      employeeGroupInsertInput: ServerEmployeeGroupInsertInput,
    ): Promise<{ insertEmployeeGroup?: { id: number } }> => {
      return request(
        gql`
          mutation ($employeeGroupInsertInput: employeeGroup_insert_input!) {
            insertEmployeeGroup(object: $employeeGroupInsertInput) {
              id
            }
          }
        `,
        { employeeGroupInsertInput },
      )
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes(EMPLOYEE_GROUPS_QUERY_KEY),
        }),
      onError: reportGqlError,
    },
  )
}

export function useUpdateEmployeeGroup() {
  return useMutation(
    async (dto: {
      id: number
      employeeGroupSetInput: ServerEmployeeGroupSetInput
    }): Promise<{ updateEmployeeGroupById?: { id?: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $employeeGroupSetInput: employeeGroup_set_input!) {
            updateEmployeeGroupById(pk_columns: { id: $id }, _set: $employeeGroupSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([EMPLOYEE_GROUPS_QUERY_KEY])
        // для того чтоб при редактировании скидки в диалоге продаж отображалась правильная скидка сотрудника
        queryClient.invalidateQueries([EMPLOYEES_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateOwnerEmployeeGroup() {
  return useMutation(
    async (dto: {
      id: number
      employeeGroupSetInput: ServerEmployeeGroupOwnerViewSetInput
    }): Promise<{ updateEmployeeGroupOwnerView?: { affected_rows?: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $employeeGroupSetInput: employeeGroupOwnerView_set_input!) {
            updateEmployeeGroupOwnerView(
              where: { id: { _eq: $id } }
              _set: $employeeGroupSetInput
            ) {
              affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([EMPLOYEE_GROUPS_QUERY_KEY])
        // для того чтоб при редактировании скидки в диалоге продаж отображалась правильная скидка сотрудника
        queryClient.invalidateQueries([EMPLOYEES_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useArchiveEmployeeGroup() {
  return useMutation(
    async (dto: {
      id: number
      archive: boolean
      // eslint-disable-next-line camelcase
    }): Promise<{ updateEmployeeGroupArchives: { affected_rows: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $archived: timestamptz) {
            updateEmployeeGroupArchives(
              where: { id: { _eq: $id } }
              _set: { archived: $archived }
            ) {
              affected_rows
            }
          }
        `,
        { id: dto.id, archived: dto.archive ? new Date().toISOString() : null },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ARCHIVED_EMPLOYEE_GROUPS_QUERY_KEY])
        queryClient.invalidateQueries([EMPLOYEE_GROUPS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
