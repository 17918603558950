import { InputLabel } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { MAX_COLUMN_WIDTH, MIN_COLUMN_WIDTH } from 'store/BookingCalendarSettings'
import { Range } from 'ui/Range'

export const ColumnScaleRange: FC<{
  columnsAreShrinked: boolean
  setColumnsAreShrinked: (value: boolean) => void
}> = observer(({ columnsAreShrinked, setColumnsAreShrinked }) => {
  const {
    bookingSettings: {
      locationColumnWidth,
      setLocationColumnWidth,
      employeeColumnWidth,
      setEmployeeColumnWidth,
    },
  } = store

  const handleChangeLocationColumnScale = useCallback(
    (value: number[]) => {
      setLocationColumnWidth(value[0])
    },
    [setLocationColumnWidth],
  )
  const handleChangeEmployeeColumnScale = useCallback(
    (value: number[]) => {
      setEmployeeColumnWidth(value[0])
    },
    [setEmployeeColumnWidth],
  )

  const { t } = useTranslation()

  const shrinkColumns = () => setColumnsAreShrinked(true)

  return (
    <div className="px-2 mt-5">
      <InputLabel label={t('calendarSettings.columnWidth')} />
      {!columnsAreShrinked && (
        <>
          <span className="text-sm text-gray-300">
            {t('calendarSettings.calendarNotCompressed')}.
          </span>{' '}
          <button onClick={shrinkColumns} className="text-sm text-gray-400 hover:text-gray-600">
            {t('switch')}
          </button>
        </>
      )}
      <div className="flex">
        <div className="w-1/2">
          <InputLabel label={t('location.name')} />
          <Range
            values={[locationColumnWidth]}
            min={MIN_COLUMN_WIDTH}
            max={MAX_COLUMN_WIDTH}
            step={4}
            placeholderValues={[0]}
            onChange={handleChangeLocationColumnScale}
            className="mx-2"
          />
        </div>
        <div className="w-1/2">
          <InputLabel label={t('employee.name')} />
          <Range
            values={[employeeColumnWidth]}
            min={MIN_COLUMN_WIDTH}
            max={MAX_COLUMN_WIDTH}
            step={4}
            placeholderValues={[0]}
            onChange={handleChangeEmployeeColumnScale}
            className="mx-2"
          />
        </div>
      </div>
    </div>
  )
})
