import { request, useQuery, reportError, reportGqlError } from '../../api'
import { DEFAULT_TIMEZONE, endOfDay, startOfDay } from '@expane/date'
import { ServerSalaryIssueType } from '../../generated/graphql-types'
import { TRANSACTIONS_QUERY_KEY } from '../transaction/queryKeys'
import { gql } from 'graphql-request'
import { salaryIssueFragments } from './salaryIssue.fragments'
import { SALARY_ISSUES_QUERY_KEY } from './queryKeys'
import { parseDatesInSalaryIssueGqlResponse } from './logic'

export function useFetchSalaryIssues(timezone: string | undefined, branchId: number | undefined) {
  return useQuery<ServerSalaryIssueType[]>(
    [SALARY_ISSUES_QUERY_KEY, TRANSACTIONS_QUERY_KEY, { branchId }],

    async () => {
      const result = await request(
        gql`
          ${salaryIssueFragments}
          query ($branchId: Int!) {
            salaryIssues(where: { branchId: { _eq: $branchId } }, order_by: { date: desc }) {
              ...salaryIssueType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.salaryIssues)) {
        return result.salaryIssues.map(salaryIssue =>
          parseDatesInSalaryIssueGqlResponse(salaryIssue, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('salaryIssues is not an array'), 'warning', {
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchSalaryIssues',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchSalaryIssuesByDate({
  from,
  to,
  timezone,
  branchId,
}: {
  from: Date
  to: Date
  timezone: string | undefined
  branchId: number | undefined
}) {
  return useQuery<ServerSalaryIssueType[]>(
    [SALARY_ISSUES_QUERY_KEY, { from, to, branchId }],

    async () => {
      const result = await request(
        gql`
          ${salaryIssueFragments}
          query ($from: timestamptz!, $to: timestamptz!, $branchId: Int!) {
            salaryIssues(
              order_by: { createdAt: desc }
              where: { createdAt: { _gte: $from, _lt: $to }, branchId: { _eq: $branchId } }
            ) {
              ...salaryIssueType
            }
          }
        `,
        {
          from: startOfDay(from),
          to: endOfDay(to),
          branchId,
        },
      )

      if (Array.isArray(result?.salaryIssues)) {
        return result.salaryIssues.map(salaryIssue =>
          parseDatesInSalaryIssueGqlResponse(salaryIssue, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('useFetchSalaryIssuesByDate is not an array'), 'error', {
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchSalaryIssuesByDate',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchSalaryIssuesByDateAndTypes({
  from,
  to,
  types,
  timezone,
  branchId,
}: {
  from: Date
  to: Date
  types: Array<number>
  timezone: string | undefined
  branchId: number | undefined
}) {
  return useQuery<ServerSalaryIssueType[]>(
    [TRANSACTIONS_QUERY_KEY, SALARY_ISSUES_QUERY_KEY, { from, to, types, branchId }],

    async () => {
      const result = await request(
        gql`
          ${salaryIssueFragments}
          query ($from: timestamptz!, $to: timestamptz!, $types: [smallint!]!, $branchId: Int!) {
            salaryIssues(
              order_by: { createdAt: desc }
              where: {
                date: { _gte: $from, _lt: $to }
                type: { _in: $types }
                branchId: { _eq: $branchId }
              }
            ) {
              ...salaryIssueType
            }
          }
        `,
        { from: startOfDay(from), to: endOfDay(to), types, branchId },
      )

      if (Array.isArray(result?.salaryIssues)) {
        return result.salaryIssues.map(salaryIssue =>
          parseDatesInSalaryIssueGqlResponse(salaryIssue, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('useFetchSalaryIssuesByDateAndTypes is not an array'), 'warning', {
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchSalaryIssuesByDatesAndTypes',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}
