import { TreeMenuItem } from 'ui/TreeMenu'

const extractIds = (dto: {
  items: TreeMenuItem[]
  productGroupIds: number[]
  productIds: number[]
  ignoreGroups: boolean
}) => {
  const { items, productGroupIds, productIds, ignoreGroups } = dto
  for (const item of items) {
    if (item.isFolder && item.nodes) {
      if (!ignoreGroups) {
        productGroupIds.push(item.id)
      }
      extractIds({ ...dto, items: item.nodes })
    }
    if (!item.isFolder) {
      productIds.push(item.id)
    }
  }
}
export const prepareDtoForBranchImportProducts = (
  items: TreeMenuItem[],
  ignoreGroups: boolean,
): {
  productGroupIds: number[]
  productIds: number[]
} => {
  const productGroupIds: number[] = []
  const productIds: number[] = []
  extractIds({ items, productGroupIds, productIds, ignoreGroups })

  return {
    productGroupIds,
    productIds,
  }
}
