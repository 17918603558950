import { BookingUnion, DayTiming, ExtendedEmployee } from '@expane/data'
import { getTimeFromDate, MINUTES_IN_HOUR } from '@expane/date'
import { TAILWIND_TO_REM_RATIO } from 'logic/ui'

export const getTopPositionForBooking = (startDateTime: number, firstCellTime: number) =>
  (startDateTime - firstCellTime) / 0.5

// should change together
export const COLUMN_WIDTH = 52
export const COLUMN_WIDTH_STYLE = 'grow basis-0 min-w-52'

export const EXPANDED_COLUMN_WIDTH_STYLE = 'w-96'

export const getColumnWidthInRem = (shrinked: boolean, shrinkedColumnWIdth: number) => {
  const width = shrinked ? shrinkedColumnWIdth : COLUMN_WIDTH

  return width * TAILWIND_TO_REM_RATIO
}

const getClassNameForShrinkedColumn = (width: number) => {
  return 'w-' + width
}
// To make sure that tailwind compile these classes
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stylesForColumns = 'w-12 w-16 w-20 w-24 w-28 w-32'

const BORDER_CLASS_NAME = 'border-r border-primary-100 '
export const getCalendarColumnClassName = ({
  weekly,
  shrinked,
  columnWidth,
  isHover,
  last,
}: {
  last: boolean
  weekly?: boolean
  shrinked: boolean
  columnWidth: number
  isHover: boolean
}) => {
  const className = last ? BORDER_CLASS_NAME : ''

  if (weekly) return className + 'grow basis-0'

  if (shrinked) {
    if (isHover) return className + EXPANDED_COLUMN_WIDTH_STYLE
    return className + getClassNameForShrinkedColumn(columnWidth)
  }

  return className + COLUMN_WIDTH_STYLE
}

export const sortEmployeesByGroups = (
  employees: ExtendedEmployee[],
  groups: { id: number; name: string }[],
) => {
  const result: ExtendedEmployee[] = []

  for (const group of groups) {
    const employeesFromThisGroup = employees.filter(employee => employee.groupId === group.id)
    result.push(...employeesFromThisGroup)
  }

  return result
}

export const getCellClassName = (
  isEditingAllowed: boolean,
  cellWarningMessage: string | null,
  isWeekly = false,
  isToday = false,
) => {
  let className = 'relative group '

  if (cellWarningMessage === null) {
    className += ' text-primary-400'
    className += isWeekly && isToday ? ' bg-accent-light' : ' bg-block'
  } else {
    className += ' text-calendar-cell-color'
  }

  if (isEditingAllowed)
    className += cellWarningMessage === null ? ' bg-hover' : ' bg-calendar-warning-cell-hover'

  return className
}

const checkBookingFitInDayTiming = (booking: BookingUnion, dayTiming: DayTiming): boolean => {
  const bookingStartHour = getTimeFromDate(booking.startDate)
  if (bookingStartHour < dayTiming[0]) {
    return false
  }
  const bookingEndHour = bookingStartHour + booking.duration / MINUTES_IN_HOUR
  if (bookingEndHour > dayTiming[1]) {
    return false
  }

  return true
}

export const filterBookingsForLocationColumn = ({
  bookings,
  locationId,
  employees,
  dayTiming,
}: {
  bookings: BookingUnion[] | undefined
  locationId: number
  employees: { id: number }[]
  dayTiming: DayTiming
}): BookingUnion[] => {
  if (!bookings) return []
  const result: BookingUnion[] = []

  for (const booking of bookings) {
    if (booking.location.id !== locationId) {
      continue
    }
    if (employees.length && !employees.some(employee => employee.id === booking.employee?.id)) {
      continue
    }

    if (!checkBookingFitInDayTiming(booking, dayTiming)) {
      continue
    }

    result.push(booking)
  }

  return result
}

export const filterBookingsForEmployeeColumn = ({
  bookings,
  employeeId,
  locations,
  dayTiming,
}: {
  bookings: BookingUnion[] | undefined
  employeeId: number
  locations: { id: number }[]
  dayTiming: DayTiming
}): BookingUnion[] => {
  if (!bookings) return []
  const result: BookingUnion[] = []

  for (const booking of bookings) {
    if (booking.employee?.id !== employeeId) {
      continue
    }
    if (locations.length && !locations.some(location => location.id === booking.location.id)) {
      continue
    }
    if (!checkBookingFitInDayTiming(booking, dayTiming)) {
      continue
    }

    result.push(booking)
  }

  return result
}

export const BOOKING_DIALOG_CONTEXT_MENU_ID = 1 as const
export const GROUP_BOOKING_DIALOG_CONTEXT_MENU_ID = 2 as const
export const MULTI_SERVICE_BOOKING_DIALOG_CONTEXT_MENU_ID = 3 as const
export const TIMEOFF_DIALOG_CONTEXT_MENU_ID = 4 as const
