import {
  ServerServiceEmployeeInsertInput,
  ServerServiceInsertInput,
  ServerServiceLocationInsertInput,
  ServerServicePermissionInsertInput,
  ServerServiceProductInsertInput,
  ServerServiceSetInput,
} from '../../generated/graphql-types'

export const SERVICE_TYPE = {
  service: 1,
  group: 2,
  forSale: 3,
}

export type ServiceFetchType = 'service' | 'interbranchService' | 'all'

export type ServiceLocationInsertInput = ServerServiceLocationInsertInput &
  Required<Pick<ServerServiceLocationInsertInput, 'branchId' | 'locationId'>>

export type ServiceProductInsertInput = ServerServiceProductInsertInput &
  Required<Pick<ServerServiceProductInsertInput, 'branchId' | 'productId'>>

export type ServiceEmployeeInsertInput = ServerServiceEmployeeInsertInput &
  Required<Pick<ServerServiceEmployeeInsertInput, 'branchId' | 'employeeId'>>

export type ServiceInsertInput = ServerServiceInsertInput &
  Required<Pick<ServerServiceInsertInput, 'branchId' | 'name' | 'defaultDuration'>> & {
    serviceLocations: {
      data: ServiceLocationInsertInput[]
    }
    serviceProducts: {
      data: ServiceProductInsertInput[]
    }
    serviceEmployees: {
      data: ServiceEmployeeInsertInput[]
    }
  }

export type InterbranchServiceInsertInput = ServerServiceInsertInput &
  Required<
    Pick<ServerServiceInsertInput, 'name' | 'defaultDuration' | 'availableToAllBranches'>
  > & {
    serviceLocations: {
      data: ServiceLocationInsertInput[]
    }
    serviceProducts: {
      data: ServiceProductInsertInput[]
    }
    serviceEmployees: {
      data: ServiceEmployeeInsertInput[]
    }
  }

export interface ServiceUpdateDto {
  id: number
  serviceSetInput: ServerServiceSetInput
  // Employees
  serviceEmployeeIdsToDelete: number[]
  serviceEmployeeInsertInputs: ServiceEmployeeInsertInput[]
  // Locations
  locationIdsToDelete: number[]
  serviceLocationInsertInputs: ServiceLocationInsertInput[]
  // Permissions
  permissionEmployeeIdsToDelete: number[]
  servicePermissionInsertInputs: ServerServicePermissionInsertInput[]
  // Consumables
  serviceProductInsertInputs: ServiceProductInsertInput[]
  consumableIdsToDelete: number[]
}
