import { FC, PropsWithChildren } from 'react'

export const Page: FC<PropsWithChildren<{ isPadding?: boolean; className?: string }>> = ({
  children,
  isPadding = true,
  className,
}) => {
  return (
    <div className={`${isPadding ? 'p-page ' : ''} flex flex-col h-full ` + className}>
      {children}
    </div>
  )
}
