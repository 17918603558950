import {
  ClientPhones,
  ServerAccountType,
  ServerServiceBriefWithProductAndEmployeeType,
  ServerStorageType,
  useFetchAccounts,
  useFetchClientBalance,
  useFetchClientById,
  useFetchClientsBriefs,
  useFetchClientsPhones,
  useFetchCurrentBranchTimezone,
  useFetchGiftCards,
  useFetchProductGroups,
  useFetchProductsForSale,
  useFetchServicesBriefsForSale,
  useFetchStorages,
  useFetchSubscriptions,
  useGetBranchDefaultAccountId,
  useGetBranchDefaultStorageId,
  useGetLeftovers,
} from '@expane/data'
import { PAYMENT_OPTIONS } from '@expane/logic/payment'
import { getEmployeesForServices } from '@expane/logic/quickSale'
import { Dialog, EmptyPlaceholder, Modal, Paper, Spinner, usePopupOpenState } from '@expane/ui'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoPeopleOutline } from 'react-icons/io5'
import { store } from 'store'
import { ClientInput } from 'widgets/QuickSaleDialog/ClientInput'
import { EmployeeInput } from 'widgets/QuickSaleDialog/EmployeeInput'
import { QuickSaleDialogFooter } from 'widgets/QuickSaleDialog/QuickSaleDialogFooter'
import { QuickSaleDialogPlaceholder } from 'widgets/QuickSaleDialog/QuickSaleDialogPlaceholder'
import { QuickSalePayment } from 'widgets/QuickSaleDialog/QuickSalePayment'
import { SaleItemsInput } from 'widgets/QuickSaleDialog/SaleItemsInput'
import { SubmitQuickSaleDialogFormValues } from './logic'

interface QuickSaleDialogProps {
  closeDialog: () => void
  defaultClientId?: number
}

const QuickSaleDialog: FC<QuickSaleDialogProps> = observer(({ closeDialog, defaultClientId }) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)
  const { data: storages, isLoading: isLoadingStorages } = useFetchStorages(branchId)
  const { data: defaultAccountId, isLoading: isLoadingDefaultAccountId } =
    useGetBranchDefaultAccountId(branchId)
  const { data: defaultStorageId, isLoading: isLoadingDefaultStorageId } =
    useGetBranchDefaultStorageId(branchId)
  const { data: clients, isLoading: isLoadingClients } = useFetchClientsBriefs(branchId, timezone)
  const { data: clientsPhones, isLoading: isLoadingClientsPhones } = useFetchClientsPhones()
  const { isLoading: isLoadingSubscriptions } = useFetchSubscriptions(timezone, branchId)
  const { isLoading: isLoadingGiftCards } = useFetchGiftCards(timezone, branchId)
  const { data: services, isLoading: isLoadingServices } = useFetchServicesBriefsForSale(branchId)
  const { isLoading: isLoadingProductGroups } = useFetchProductGroups(branchId)
  const { isLoading: isLoadingProducts } = useFetchProductsForSale(branchId)

  const isLoading =
    isLoadingAccounts ||
    isLoadingStorages ||
    isLoadingDefaultAccountId ||
    isLoadingDefaultStorageId ||
    isLoadingClients ||
    isLoadingSubscriptions ||
    isLoadingGiftCards ||
    isLoadingServices ||
    isLoadingProducts ||
    isLoadingProductGroups ||
    isLoadingClientsPhones ||
    !timezone

  const isNoData =
    !accounts?.length &&
    !storages?.length &&
    !clients?.length &&
    !clientsPhones?.length &&
    !services?.length

  useErrorOpeningDialog(isNoData && !isLoading, closeDialog)

  if (isLoading) return <QuickSaleDialogPlaceholder closeDialog={closeDialog} />
  else if (isNoData) return null

  return (
    <QuickSaleDialogLogic
      accounts={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        accounts!
      }
      storages={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        storages!
      }
      defaultAccountId={defaultAccountId}
      defaultStorageId={defaultStorageId}
      services={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        services!
      }
      closeDialog={closeDialog}
      defaultClientId={defaultClientId}
      timezone={timezone}
      clientsPhones={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        clientsPhones!
      }
    />
  )
})

interface QuickSaleDialogLogicProps {
  accounts: ServerAccountType[]
  storages: ServerStorageType[]
  defaultAccountId?: number
  defaultStorageId?: number
  clientsPhones: ClientPhones[]
  services: ServerServiceBriefWithProductAndEmployeeType[]
  closeDialog: () => void
  defaultClientId?: number
  timezone: string
}

const QuickSaleDialogLogic: FC<QuickSaleDialogLogicProps> = observer(
  ({
    accounts,
    storages,
    defaultAccountId,
    defaultStorageId,
    clientsPhones,
    services,
    closeDialog,
    defaultClientId,
    timezone,
  }) => {
    const branchId = store.branch.branchId
    const { t } = useTranslation()

    const {
      control,
      setValue,
      handleSubmit,
      formState: { isSubmitting, errors },
      clearErrors,
    } = useForm<SubmitQuickSaleDialogFormValues>({
      defaultValues: {
        paymentToAccounts: [],
        storageId: defaultStorageId ?? storages[0]?.id,
        serviceItems: [],
        productItems: [],
        giftCardItems: [],
        subscriptionItems: [],
        clientId: defaultClientId,
        accountId: defaultAccountId,
        paymentAmount: '',
        paymentOption: PAYMENT_OPTIONS[0],
        addToClientAccount: false,
        totalDiscount: '',
      },
    })

    const watchedClientId = useWatch({
      control: control,
      name: 'clientId',
    })

    const watchedStorageId = useWatch({
      control,
      name: 'storageId',
    })

    const { data: clientById, isLoading: isClientLoading } = useFetchClientById(
      watchedClientId,
      timezone,
    )
    const { data: clientBalance, isLoading: isClientBalanceLoading } = useFetchClientBalance(
      watchedClientId,
      branchId,
    )
    const { data: leftoverProductsOnWatchedStorage, isLoading: isLoadingLeftoverProducts } =
      useGetLeftovers(branchId, watchedStorageId)

    const isLoading =
      Boolean(watchedClientId) &&
      (isClientLoading || isClientBalanceLoading || isLoadingLeftoverProducts)

    const watchedProductItems = useWatch({ control, name: 'productItems' })
    const watchedServiceItems = useWatch({ control, name: 'serviceItems' })
    const watchedSubscriptionItems = useWatch({ control, name: 'subscriptionItems' })
    const watchedGiftCardItems = useWatch({ control, name: 'giftCardItems' })

    const standingAccounts = accounts.filter(account => account.forPayments)

    const employeesForServices = getEmployeesForServices(
      services.filter(service =>
        watchedServiceItems.some(watchedServiceItem => watchedServiceItem.id === service.id),
      ),
    )

    const modal = useRef<HTMLDivElement>(null)

    const focusToModal = () => {
      modal.current?.focus()
    }

    const noSelectedItems =
      watchedProductItems.length === 0 &&
      watchedServiceItems.length === 0 &&
      watchedSubscriptionItems.length === 0 &&
      watchedGiftCardItems.length === 0

    return (
      <Modal close={closeDialog} ref={modal} animation="onlyFadeOut">
        <Dialog>
          <Dialog.Title>{t('quickSale')}</Dialog.Title>
          <Dialog.Body className="min-w-256 max-full">
            <div className="flex">
              <div className="grow pr-3 w-192 flex flex-col h-128">
                <div className="flex">
                  <ClientInput
                    timezone={timezone}
                    control={control}
                    clientsPhones={clientsPhones}
                    defaultClientId={defaultClientId}
                    focusToModal={focusToModal}
                  />
                  {employeesForServices.length > 0 && (
                    <EmployeeInput control={control} employeesForServices={employeesForServices} />
                  )}
                </div>

                <Paper className="p-2 grow">
                  {clientById && !isLoading && (
                    <QuickSalePayment
                      control={control}
                      accounts={standingAccounts}
                      clientById={clientById}
                      clientBalance={clientBalance ?? 0}
                      leftoverProducts={leftoverProductsOnWatchedStorage ?? {}}
                      containerClassName="max-w-full"
                      setValue={setValue}
                      clearErrors={clearErrors}
                      isErrors={!(Object.keys(errors).length === 0)}
                      branchId={branchId}
                    />
                  )}
                  {isLoading && <Spinner expandCentered />}
                  {!clientById && !isLoading && (
                    <EmptyPlaceholder Icon={IoPeopleOutline} text={t('clientNotSelected')} />
                  )}
                </Paper>
              </div>

              <SaleItemsInput control={control} setValue={setValue} />
            </div>
          </Dialog.Body>
          <Dialog.Footer>
            <QuickSaleDialogFooter
              control={control}
              accounts={standingAccounts}
              handleSubmit={handleSubmit}
              closeDialog={closeDialog}
              disabledSubmit={noSelectedItems || isSubmitting || !clientById}
              showSpinner={isSubmitting}
            />
          </Dialog.Footer>
        </Dialog>
      </Modal>
    )
  },
)

export const useQuickSaleDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const clientId = useRef<number | undefined>()
  const onCloseDialog = useRef<() => void>()

  const openQuickSaleDialog = (id?: number, onClose?: () => void) => {
    clientId.current = id
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const quickSaleDialog = isOpen ? (
    <QuickSaleDialog closeDialog={closeDialog} defaultClientId={clientId.current} />
  ) : null

  return {
    openQuickSaleDialog,
    quickSaleDialog,
  }
}
