import { ExtendedEmployee, GroupBooking } from '@expane/data'
import { SelectDropDownItem } from '@expane/ui'

export const getDefaultLocationId = ({
  isCreate,
  initialLocationId,
  initialEmployeeId,
  locationIdFromBooking,
  employees,
}: {
  isCreate: boolean
  locationIdFromBooking: number | undefined
  initialLocationId: number | undefined
  initialEmployeeId: number | undefined
  employees: ExtendedEmployee[]
}) => {
  if (!isCreate) return locationIdFromBooking

  if (initialLocationId) return initialLocationId

  const employeeById = employees.find(employee => employee.id === initialEmployeeId)

  const locationIdsFromAllServices =
    employeeById?.serviceEmployees
      .map(serviceEmployee =>
        serviceEmployee.service.serviceLocations.map(serviceLocation => serviceLocation.locationId),
      )
      .flat() ?? []
  const countedIds: Record<number, number> = locationIdsFromAllServices?.reduce(
    (countedIds, id) => {
      countedIds[id] = (countedIds[id] ?? 0) + 1
      return countedIds
    },
    {},
  )

  let locationIdWithBiggestCount: string | undefined
  for (const locationId in countedIds) {
    if (locationIdWithBiggestCount === undefined) locationIdWithBiggestCount = locationId
    if (countedIds[locationId] > countedIds[locationIdWithBiggestCount])
      locationIdWithBiggestCount = locationId
  }

  return locationIdWithBiggestCount === undefined ? undefined : Number(locationIdWithBiggestCount)
}

export const getClientsToBeRemovedAndInsertedIds = (
  groupBookingById: GroupBooking,
  newClients: SelectDropDownItem[],
) => {
  const initialBookingClients = groupBookingById.isGroupBooking
    ? groupBookingById.bookingClients
    : []

  const bookingClientsToBeRemovedIds = initialBookingClients
    .filter(({ client }) => !newClients.some(({ id }) => id === client.id))
    .map(({ id }) => id)

  const bookingClientsToBeInserted = newClients.filter(
    ({ id }) => !initialBookingClients.some(({ client }) => client.id === id),
  )

  return { bookingClientsToBeRemovedIds, bookingClientsToBeInserted }
}
