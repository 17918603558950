import {
  useFetchAccounts,
  useFetchClientBalance,
  useFetchClientById,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import {
  addClientAccountToAccountsForPayment,
  CLIENT_ACCOUNT_ID,
  PAYMENT_OPTIONS,
} from '@expane/logic/payment'
import { BookingPaymentData } from '@expane/logic/payment/booking'
import { calcConsumableTotalSum } from '@expane/logic/payment/booking/consumables'
import { calcServiceTotalSum } from '@expane/logic/payment/booking/services'
import { translateItemsName } from '@expane/logic/utils'
import { Input, InputLabel, Paper, RadioGroupButtons } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import {
  Control,
  Controller,
  UseFormClearErrors,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { store } from 'store'
import { AccountSelectDropdown, AccountSelectDropdownType } from 'widgets/AccountSelectDropdown'
import { Checkbox } from '@expane/ui'

type Props = {
  clientId: number
  control: Control<BookingPaymentData>
  clearErrors: UseFormClearErrors<BookingPaymentData>
  setValue: UseFormSetValue<BookingPaymentData>
}

export const PaymentInputs: FC<Props> = observer(({ control, clearErrors, setValue, clientId }) => {
  const branchId = store.branch.branchId

  const { t } = useTranslation()
  const convertToMoneyCode = useConvertNumberToMoneyCode({ branchId })

  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { data: accounts } = useFetchAccounts(branchId)

  const standingAccounts = accounts?.filter(account => account.forPayments)

  const allAccounts = standingAccounts
    ? addClientAccountToAccountsForPayment(standingAccounts, t, clientId)
    : []

  const { data: client } = useFetchClientById(clientId, timezone)
  const { data: clientBalance } = useFetchClientBalance(clientId, branchId)

  const clientBalanceWithDebt = clientBalance
    ? clientBalance + (client?.maximumDebt ?? 0)
    : client?.maximumDebt ?? 0

  const { errors } = useFormState({ control })
  const isErrors = !(Object.keys(errors).length === 0)

  const watchedAccountId = useWatch({ control, name: 'accountId' })
  const selectedAccount = allAccounts?.find(account => account.id === watchedAccountId)

  const watchedPaymentAmount = useWatch({ control, name: 'paymentAmount' })

  const watchedServices = useWatch({ control, name: 'servicesDto' })
  const watchedConsumables = useWatch({ control, name: 'consumablesDto' })
  const totalServicesSum = calcServiceTotalSum(watchedServices)
  const totalConsumablesSum = calcConsumableTotalSum(watchedConsumables)
  const totalSum = totalConsumablesSum + totalServicesSum

  useEffect(() => {
    setValue('paymentAmount', totalSum.toString())
  }, [setValue, totalSum])

  const restMoney =
    totalSum < Number(watchedPaymentAmount) ? Number(watchedPaymentAmount) - totalSum : null
  const isShowRestMoney =
    Boolean(restMoney) && watchedAccountId !== CLIENT_ACCOUNT_ID && Boolean(selectedAccount?.cash)

  const watchedPaymentOption = useWatch({ control, name: 'paymentOption' })
  const paymentOptions = translateItemsName(PAYMENT_OPTIONS, t)
  const isOneAccount = watchedPaymentOption.id === paymentOptions[0].id
  const isMultiAccounts = watchedPaymentOption.id === paymentOptions[1].id

  const watchedPaymentToAccounts = useWatch({ control, name: 'paymentToAccounts' })
  const totalSumToPay = watchedPaymentToAccounts.reduce((sum, item) => sum + Number(item.value), 0)

  return (
    <Paper className={'mt-3 p-3' + (totalSum ? '' : ' hidden')}>
      <div className="flex">
        <div className="w-1/3 pr-3">
          <Controller
            name="paymentOption"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <RadioGroupButtons
                  label={t('paymentMethod')}
                  options={paymentOptions}
                  value={value}
                  onChange={e => {
                    clearErrors()
                    onChange(e)
                  }}
                />
              )
            }}
          />
        </div>

        <div className="grow">
          {/* контролы должны быть в ДОМ чтобы проходила валидация инпутов */}
          <div className={isOneAccount ? '' : 'hidden'}>
            <div className="flex justify-between">
              <Controller
                name="accountId"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <AccountSelectDropdown
                      label={t('account.name')}
                      value={value}
                      accounts={allAccounts}
                      onSelectChange={value => {
                        onChange(value)
                        clearErrors('paymentAmount')

                        if (!allAccounts?.find(account => account.id === value)?.cash) {
                          // при оплате по безналу ложим всю сумму на счет
                          setValue('keepChangeOnClientAccount', true)
                        } else {
                          // при оплате наличными опционально
                          setValue('keepChangeOnClientAccount', false)
                        }
                      }}
                      className="w-1/2"
                      popupHeight="small"
                      height="small"
                    />
                  )
                }}
              />

              <Controller
                name="paymentAmount"
                control={control}
                rules={{
                  validate: {
                    CheckAmountPayment: value => {
                      if (isOneAccount && totalSum > 0) {
                        return Number(value) >= totalSum
                      }
                    },
                    CheckClientAccountBalance: value => {
                      if (isOneAccount && watchedAccountId === CLIENT_ACCOUNT_ID) {
                        return (clientBalanceWithDebt ?? 0) >= Number(value)
                      }
                    },
                  },
                }}
                render={({ field: { value, onChange }, formState }) => {
                  return (
                    <Input
                      label={t('amount')}
                      type="number"
                      value={value}
                      onChange={onChange}
                      placeholder="100"
                      containerClassName="pl-3 grow"
                      errorMessage={{
                        isShown: Boolean(formState.errors.paymentAmount),
                        text: defineIsFormOrValueErrorMessage({
                          type: formState.errors.paymentAmount?.type ?? '',
                          clientBalance: clientBalanceWithDebt,
                          convertToMoneyCode,
                          t,
                        }),
                        reserveIndent: false,
                      }}
                      height="small"
                      autoFocus
                    />
                  )
                }}
              />
            </div>

            {isShowRestMoney && (
              <>
                <div className="mt-3 mb-1 text-sm flex items-center text-gray-500">
                  {t('change(money)')}: {convertToMoneyCode(restMoney ?? 0)}
                </div>
                <Controller
                  name="keepChangeOnClientAccount"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      label={t('depositToClientsAccount')}
                      checked={value}
                      onChange={onChange}
                      disabled={restMoney === null}
                    />
                  )}
                />
              </>
            )}
          </div>
          <div className={isMultiAccounts ? '' : ' hidden'}>
            <div className="flex">
              <InputLabel className="w-1/2" label={t('account.name')} />
              <InputLabel className="w-1/2 pl-2.5" label={t('amount')} />
            </div>
            <div className={accountListContainerStyles}>
              {allAccounts?.map((account, index) => {
                return (
                  <div key={account.id} className="flex justify-between mb-2">
                    <Controller
                      name={`paymentToAccounts.${index}.id` as 'paymentToAccounts.0.id'}
                      control={control}
                      defaultValue={account.id}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <AccountSelectDropdown
                            type={AccountSelectDropdownType.labels}
                            value={value}
                            accounts={allAccounts}
                            onSelectChange={onChange}
                            className="w-1/2"
                            disabled
                            popupHeight="small"
                            height="small"
                          />
                        )
                      }}
                    />
                    <Controller
                      name={`paymentToAccounts.${index}.value` as 'paymentToAccounts.0.value'}
                      defaultValue={''}
                      control={control}
                      rules={{
                        validate: {
                          CheckClientAccountBalance: value => {
                            if (isMultiAccounts && account.id === CLIENT_ACCOUNT_ID) {
                              return (clientBalanceWithDebt ?? 0) >= Number(value)
                            }
                          },
                          CheckAmountPayment: value => {
                            if (isMultiAccounts) {
                              if (watchedPaymentToAccounts.length === 0) return false
                              return checkAmountOfPayments(
                                watchedPaymentToAccounts,
                                { id: account.id, value },
                                totalSum,
                              )
                            }
                          },
                        },
                      }}
                      render={({ field: { value, onChange }, formState }) => {
                        return (
                          <Input
                            type="number"
                            value={value}
                            onChange={event => {
                              const newValue = event.target.value
                              const isEnoughForPay = checkAmountOfPayments(
                                watchedPaymentToAccounts,
                                { id: account.id, value: newValue },
                                totalSum,
                              )
                              if (isEnoughForPay) clearErrors('paymentToAccounts')

                              onChange(newValue)
                            }}
                            placeholder="100"
                            containerClassName="pl-3 grow"
                            errorMessage={{
                              isShown: Boolean(formState.errors.paymentToAccounts?.[index]?.value),
                              text: defineIsFormOrValueErrorMessage({
                                type:
                                  formState.errors.paymentToAccounts?.[index]?.value?.type ?? '',
                                clientBalance: clientBalanceWithDebt,
                                convertToMoneyCode,
                                t,
                              }),
                              reserveIndent: false,
                            }}
                            height="small"
                          />
                        )
                      }}
                    />
                  </div>
                )
              })}
            </div>

            {isErrors ? (
              <span className="mt-3 mb-1 text-sm text-error-500">
                {t('toPay')}: {convertToMoneyCode(totalSum)}
              </span>
            ) : (
              <span className="mt-3 mb-1 text-sm text-label-color">
                {t('toPay')}: {convertToMoneyCode(totalSumToPay)}
              </span>
            )}
          </div>
        </div>
      </div>
    </Paper>
  )
})

const accountListContainerStyles = 'max-h-27 overflow-y-auto pr-1'

const defineIsFormOrValueErrorMessage = ({
  type,
  clientBalance,
  convertToMoneyCode,
  t,
}: {
  type: string
  clientBalance: number | undefined
  t: TFunction
  convertToMoneyCode: (value: number) => void
}) => {
  if (type === 'CheckAmountPayment') return ' '
  if (type === 'CheckClientAccountBalance')
    return `${t('availableOnAccount')}: ${convertToMoneyCode(clientBalance ?? 0)}`
}

const checkAmountOfPayments = (
  paymentToAccounts: { id: number; value: string }[],
  currentAccount: { id: number; value: string },
  totalInvoiceSum: number,
) => {
  const totalSumToPay = paymentToAccounts
    .filter(account => account.id !== currentAccount.id)
    .reduce((sum, item) => sum + Number(item.value), Number(currentAccount.value))

  return totalSumToPay >= totalInvoiceSum
}
