import { BookingUnion, HistoryBookingUnion } from '@expane/data'
import { FC } from 'react'
import { getIsBookingRecurring } from '@expane/logic/booking'
import { Hint } from '@expane/ui'
import { useTranslation } from 'react-i18next'
import { IoPeopleCircleOutline, IoRepeatOutline } from 'react-icons/io5'

export const BookingServiceCellContent: FC<{
  booking: BookingUnion | HistoryBookingUnion
  disabled?: boolean
}> = ({ booking, disabled }) => {
  const { t } = useTranslation()

  const isRecurring = getIsBookingRecurring(booking)

  const color = !disabled ? 'bright' : 'normal'

  if (booking.isGroupBooking) {
    return (
      <div className={'flex items-center justify-between'}>
        <span className="line-clamp-1 mr-2">{booking.service?.name}</span>

        <div className={'flex'}>
          <Hint CustomIcon={IoPeopleCircleOutline} color={color}>
            {t('groupBooking.name')}
          </Hint>
          {isRecurring && (
            <Hint CustomIcon={IoRepeatOutline} color={color} className={'mr-2'}>
              {t('repeatLabel')}
            </Hint>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className="w-full flex items-center justify-between">
        <div className={'mr-2'}>
          {booking.bookingServices.map(bookingService => (
            <span className="line-clamp-1 align-top" key={bookingService.service.id}>
              {bookingService.service.name}
            </span>
          ))}
        </div>

        {isRecurring && (
          <Hint CustomIcon={IoRepeatOutline} color={color} className={'mr-2'}>
            {t('repeatLabel')}
          </Hint>
        )}
      </div>
    )
  }
}
