import {
  FadeInTranslateTop,
  modalsHTMLElement,
  Paper,
  useHandleClickOutside,
  usePopupOpenState,
} from '@expane/ui'
import { offset, useFloating } from '@floating-ui/react-dom'
import { FC, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline, IoEllipsisHorizontal } from 'react-icons/io5'
import { PresetItem } from './PresetItem'

type Item = {
  id: number
  name: string
}
interface PresetSelectProps {
  value: number | null
  items: Item[]
  className?: string
  onChange: (value: number) => void
  onDelete: (item: Item, closeCallback: () => void) => void
  onCreate: (closeCallback: () => void) => void
  createButtonDisabled?: boolean
}

export const PresetSelect: FC<PresetSelectProps> = ({
  onCreate,
  items,
  onChange,
  value,
  onDelete,
  className,
  createButtonDisabled = false,
}) => {
  const { t } = useTranslation()

  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const [popupRef, setPopupRef] = useState<HTMLDivElement | null>(null)
  useHandleClickOutside([buttonRef.current, popupRef], closePopup, isOpen)
  const { reference, floating, strategy, x, y } = useFloating({
    middleware: [offset(5)],
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  useLayoutEffect(() => {
    reference(buttonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, buttonRef.current])
  useLayoutEffect(() => {
    floating(popupRef)
  }, [floating, popupRef])

  let createButtonClassName = 'p-1 pl-2.5 flex-centered'
  createButtonClassName += createButtonDisabled
    ? ' text-gray-300'
    : ' text-primary-500 hover:text-primary-700'

  return (
    <>
      <button ref={buttonRef} onClick={openPopup} className={buttonClassName + className}>
        <IoEllipsisHorizontal size="1.2rem" />
      </button>
      {isOpen &&
        createPortal(
          <div
            ref={setPopupRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <FadeInTranslateTop
              as={Paper}
              className="overflow-hidden min-w-40"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="preset-list"
            >
              <button
                onClick={() => onCreate(closePopup)}
                disabled={createButtonDisabled}
                className={createButtonClassName}
              >
                <IoAddCircleOutline size="1.1rem" />
                <span className="ml-0.5">{t('create')}</span>
              </button>

              {items.length > 0 && (
                <ul className="border-t border-primary-100 dark:border-primary-100/30 p-1">
                  {items.map(item => (
                    <PresetItem
                      key={item.id}
                      isSelected={value === item.id}
                      onSelect={() => {
                        onChange(item.id)
                        closePopup()
                      }}
                      onDelete={() => onDelete(item, closePopup)}
                      name={item.name}
                    />
                  ))}
                </ul>
              )}
            </FadeInTranslateTop>
          </div>,
          modalsHTMLElement,
        )}
    </>
  )
}

const buttonClassName =
  'h-7 w-7 rounded-full flex-centered border-2 border-primary-500 text-primary-500 hover:border-primary-700 hover:text-primary-700 transition-colors '
