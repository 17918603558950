import { ServerDynamicScheduleDateType } from '@expane/data'
import { TAILWIND_TO_REM_RATIO } from 'logic/ui'
import { FC, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { UseFieldArrayUpdate } from 'react-hook-form'
import { SMALL_CELL_HEIGHT, TITLE_CELL_HEIGHT } from 'ui/Calendar'
import {
  getPositionStylesForDynamicDateBox,
  HALF_HOUR_STEP,
  ScheduleDialogFormValues,
} from './logic'

const yGrid = SMALL_CELL_HEIGHT * TAILWIND_TO_REM_RATIO
// y is a delta from the initial position, got from the drag event
const calcNewStart = (y: number, previousTimingStart: number) => {
  const cellsDelta = y / yGrid
  return cellsDelta * HALF_HOUR_STEP + previousTimingStart
}
const calcNewEnd = (y: number, previousTimingEnd: number) => {
  const cellsDelta = y / yGrid
  return cellsDelta * HALF_HOUR_STEP + previousTimingEnd
}

export const DynamicDateBox: FC<{
  disabled: boolean
  dynamicDateIndex: number
  dynamicDate: ServerDynamicScheduleDateType
  calendarStartHour: number
  calendarEndHour: number
  update: UseFieldArrayUpdate<ScheduleDialogFormValues, 'dynamicDates'>
}> = ({ dynamicDate, calendarStartHour, calendarEndHour, update, dynamicDateIndex, disabled }) => {
  const startRef = useRef<HTMLDivElement>(null)
  const endRef = useRef<HTMLDivElement>(null)

  const [startY, setStartY] = useState(0)
  const [endY, setEndY] = useState(0)

  const { top, bottom } = getPositionStylesForDynamicDateBox(
    dynamicDate,
    calendarStartHour,
    calendarEndHour,
  )

  const sideLinesPosition = calcSideLinesPosition({
    dynamicDate,
    calendarStartHour,
    calendarEndHour,
    dragStartY: startY,
    dragEndY: endY,
  })

  const backgroundColor = disabled ? 'bg-gray-400' : 'bg-primary-500'

  return (
    <>
      <Draggable
        nodeRef={startRef}
        disabled={disabled}
        axis="y"
        grid={[1, yGrid]}
        position={{ x: 0, y: startY }}
        onDrag={(e, data) => {
          const newStart = calcNewStart(data.y, dynamicDate.timingStart)
          if (
            newStart < dynamicDate.timingEnd &&
            newStart >= calendarStartHour &&
            newStart < calendarEndHour
          ) {
            setStartY(data.y)
          }
        }}
        onStop={(e, data) => {
          const newStart = calcNewStart(data.y, dynamicDate.timingStart)
          if (
            newStart < dynamicDate.timingEnd &&
            newStart >= calendarStartHour &&
            newStart < calendarEndHour
          ) {
            update(dynamicDateIndex, {
              ...dynamicDate,
              timingStart: newStart,
            })
          }
          setStartY(0)
        }}
      >
        <div
          ref={startRef}
          className={'left-0.5 right-0.5 h-1 rounded cursor-row-resize ' + backgroundColor}
          style={{ position: 'absolute', top }}
        />
      </Draggable>
      <div
        className={'left-0.5 w-1 absolute rounded ' + backgroundColor}
        style={{ top: sideLinesPosition.top, bottom: sideLinesPosition.bottom }}
      />
      <div
        className={'right-0.5 w-1 absolute rounded ' + backgroundColor}
        style={{ top: sideLinesPosition.top, bottom: sideLinesPosition.bottom }}
      />
      <Draggable
        nodeRef={endRef}
        axis="y"
        grid={[1, yGrid]}
        position={{ x: 0, y: endY }}
        onDrag={(e, data) => {
          const newEnd = calcNewEnd(data.y, dynamicDate.timingEnd)
          if (
            newEnd > dynamicDate.timingStart &&
            newEnd > calendarStartHour &&
            newEnd <= calendarEndHour
          ) {
            setEndY(data.y)
          }
        }}
        onStop={(e, data) => {
          const newEnd = calcNewEnd(data.y, dynamicDate.timingEnd)
          if (
            newEnd > dynamicDate.timingStart &&
            newEnd > calendarStartHour &&
            newEnd <= calendarEndHour
          ) {
            update(dynamicDateIndex, {
              ...dynamicDate,
              timingEnd: newEnd,
            })
          }
          setEndY(0)
        }}
      >
        <div
          ref={endRef}
          className={'left-0.5 right-0.5 h-1 rounded cursor-row-resize ' + backgroundColor}
          style={{ position: 'absolute', bottom }}
        />
      </Draggable>
    </>
  )
}

const calcSideLinesPosition = ({
  dynamicDate,
  calendarStartHour,
  calendarEndHour,
  dragStartY,
  dragEndY,
}: {
  dynamicDate: ServerDynamicScheduleDateType
  calendarStartHour: number
  calendarEndHour: number
  dragStartY: number
  dragEndY: number
}) => {
  const titleCellOffset = TITLE_CELL_HEIGHT * TAILWIND_TO_REM_RATIO
  const actualTimingStart = calcNewStart(dragStartY, dynamicDate.timingStart)
  const actualTimingEnd = calcNewEnd(dragEndY, dynamicDate.timingEnd)
  const top = ((actualTimingStart - calendarStartHour) / HALF_HOUR_STEP) * yGrid + titleCellOffset
  const bottom = ((calendarEndHour - actualTimingEnd) / HALF_HOUR_STEP) * yGrid
  return { top, bottom }
}
