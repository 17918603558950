import { ServerAccountType, useFetchAccounts } from '@expane/data'
import { Table, Tag } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'

export const AccountsList: FC<{ onRowClick: (item: ServerAccountType) => void }> = memo(
  ({ onRowClick }) => {
    const { t } = useTranslation()
    const columns = useMemo<ColumnDef<ServerAccountType>[]>(
      () => [
        {
          accessorKey: 'name',
          header: t('account.name'),
          cell: data => (
            <div className="flex items-center">
              <p>{data.getValue<string>()}</p>
              {data.row.original.defaultForBranch?.defaultAccountId && (
                <Tag name={t('default')} color={'primary-300'} className="ml-1" />
              )}
              {data.row.original.accountSoftwarePOS?.[0]?.softwarePOS?.id && (
                <Tag name={t('softwarePOS.name')} color={'yellow-600'} className="ml-1" />
              )}
            </div>
          ),
          size: 300,
        },
        {
          accessorKey: 'description',
          header: t('description'),
          cell: data => data.getValue(),
          size: 600,
        },
      ],
      [t],
    )
    const branchId = store.branch.branchId
    const { data, isLoading } = useFetchAccounts(branchId)

    return <Table columns={columns} data={data} isLoading={isLoading} onRowClick={onRowClick} />
  },
)
