import { useFetchSoftwarePOSs } from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { Page } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { SoftwarePOSsList } from 'pages/SoftwarePOSsPage/List'
import React, { FC } from 'react'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'
import { SoftwarePOSDialog } from 'widgets/SoftwarePOSDialog'

export const SoftwarePOSsPage: FC = observer(() => {
  const branchId = store.branch.branchId

  const { data: softwarePOSs, isLoading } = useFetchSoftwarePOSs(branchId)
  const { data: myPermissions } = useFetchMyPermissions()
  const { openCreateDialog, openEditDialog, dialog } = useOpenDialog(SoftwarePOSDialog)

  const isEditingAllowed = myPermissions?.includes(permissions.account.set)

  return (
    <Page>
      {isEditingAllowed && (
        <div className="flex mb-2">
          <SaveButton onClick={() => openCreateDialog()} className="self-start" isCreate />
        </div>
      )}

      <SoftwarePOSsList
        onRowClick={softwarePOS => openEditDialog(softwarePOS.id)}
        softwarePOSs={softwarePOSs}
        isLoading={isLoading}
      />

      {dialog}
    </Page>
  )
})
