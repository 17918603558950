import { ServerInventoryByIdType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { convertUnitValueFromServer, getUnitsName } from '@expane/logic/product'
import { Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface InventoryDocumentListProps {
  inventory: ServerInventoryByIdType
}
type InventoryProduct = ServerInventoryByIdType['inventoryProducts'][0]

export const InventoryDocumentList: FC<PropsWithBranchId<InventoryDocumentListProps>> = ({
  inventory,
  branchId,
}) => {
  const { t } = useTranslation()

  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const columns = useMemo<ColumnDef<InventoryProduct>[]>(
    () => [
      {
        accessorKey: 'product.vendorCode',
        header: t('vendorCode'),
        cell: data => data.getValue(),
        size: 100,
      },
      {
        accessorKey: 'product.name',
        header: t('name'),
        cell: data => data.getValue(),
        size: 210,
      },
      {
        accessorKey: 'product.unit',
        header: t('unit.shortName'),
        cell: data => getUnitsName(data.getValue<number>(), t),
        size: 60,
      },
      {
        accessorKey: 'price',
        header: () => <span className="w-full text-right">{t('purchasePrice')}</span>,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
        size: 100,
      },
      {
        id: 'calcLeftover',
        accessorFn: inventoryProduct =>
          convertUnitValueFromServer(
            inventoryProduct.calculatedQuantity,
            inventoryProduct.product.unit,
          ),
        header: () => <span className="w-full text-right">{t('calcLeftover')}</span>,
        cell: data => <div className="text-right">{data.getValue<number>()}</div>,
        size: 60,
      },
      {
        id: 'actualLeftover',
        accessorFn: inventoryProduct =>
          convertUnitValueFromServer(
            inventoryProduct.actualQuantity,
            inventoryProduct.product.unit,
          ),
        header: () => <span className="w-full text-right">{t('actualLeftover')}</span>,
        cell: data => <div className="text-right">{data.getValue<number>()}</div>,
      },
      {
        id: 'difference',
        accessorFn: inventoryProduct => {
          const divergence = inventoryProduct.actualQuantity - inventoryProduct.calculatedQuantity
          return convertUnitValueFromServer(divergence, inventoryProduct.product.unit)
        },
        header: () => <span className="w-full text-right">{t('difference')}</span>,
        cell: data => {
          const divergence = data.getValue<number>()

          let divergenceCellStyle = 'text-right'
          if (divergence > 0) divergenceCellStyle += ' text-primary-500'
          if (divergence < 0) divergenceCellStyle += ' text-error-500'

          return <div className={divergenceCellStyle}>{divergence !== 0 ? divergence : '-'}</div>
        },
      },
      {
        id: 'excessShortage',
        accessorFn: inventoryProduct => {
          const divergence = inventoryProduct.actualQuantity - inventoryProduct.calculatedQuantity
          const convertedDivergence = convertUnitValueFromServer(
            divergence,
            inventoryProduct.product.unit,
          )

          return convertedDivergence * inventoryProduct.price
        },
        header: () => <span className="w-full text-right">{t('excessShortage')}</span>,
        cell: data => {
          const shortage = data.getValue<number>()
          let divergenceCellStyle = 'text-right'
          if (shortage > 0) divergenceCellStyle += ' text-primary-500'
          if (shortage < 0) divergenceCellStyle += ' text-error-500'
          return (
            <div className={divergenceCellStyle}>
              {convertNumberToMoney(data.getValue<number>())}
            </div>
          )
        },
      },
    ],
    [convertNumberToMoney, t],
  )

  return (
    <Table
      columns={columns}
      data={inventory.inventoryProducts}
      containerClassName={'overflow-auto'}
    />
  )
}
