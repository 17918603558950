import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PermissionPageBlockProps, allSelectedPermissions } from '../logic'
import { PermissionBlock, PermissionBlockTitle, PermissionCheckbox } from '.'
import { PermissionPageRowProps } from '@expane/logic/permission/logic'

export const StorageBlock: FC<PermissionPageBlockProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
  setValue,
  checkRelationBulk,
}) => {
  const { t } = useTranslation()

  const onSelectAll = () => {
    checkRelationBulk({
      permissions: [
        'permissions.product.archive',
        'permissions.movement.get',
        'permissions.movement.set',
        'permissions.storage.get',
        'permissions.storage.set',
        'permissions.product.get',
        'permissions.product.set',
        'permissions.inventory.get',
        'permissions.inventory.set',
        'permissions.supplier.get',
        'permissions.supplier.set',
      ],
      onConfirm: () => {
        setValue('permissions.movement', allSelectedPermissions.movement)
        setValue('permissions.storage', allSelectedPermissions.storage)
        setValue('permissions.product', allSelectedPermissions.product)
        setValue('permissions.inventory', allSelectedPermissions.inventory)
        setValue('permissions.supplier', allSelectedPermissions.supplier)
      },
    })
  }

  return (
    <>
      <PermissionBlockTitle onSelectAll={onSelectAll} title={t('storage.name')} />

      <MovementPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <StoragePermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <ProductPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <InventoryPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <SupplierPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
    </>
  )
}

const MovementPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('movements')}>
      <Controller
        name="permissions.movement.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.movement.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.movement.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.movement.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.movement.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.movement.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const StoragePermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('storages')}>
      <Controller
        name="permissions.storage.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.storage.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.storage.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.storage.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.storage.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.storage.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const ProductPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('products')}>
      <Controller
        name="permissions.product.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.product.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.product.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.product.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.product.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.product.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.product.archive"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.product.archive',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.product.archive')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const InventoryPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('inventory.name')}>
      <Controller
        name="permissions.inventory.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.inventory.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.inventory.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.inventory.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.inventory.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.inventory.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}
const SupplierPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('suppliers')}>
      <Controller
        name="permissions.supplier.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.supplier.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.supplier.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.supplier.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.supplier.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.supplier.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}
