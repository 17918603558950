import { action, makeObservable, observable } from 'mobx'
import { createBrowserHistory } from 'history'
import { FC, useEffect } from 'react'

export const history = createBrowserHistory()
export const BrowserHistoryListener: FC = () => {
  useEffect(() => {
    navigation.setRoute(history.location.pathname)

    // Sync browser history pathname with navigation.currentRoute
    return history.listen(({ location }) => navigation.setRoute(location.pathname))
  }, [])

  return null
}

class Navigation {
  currentRoute = ''
  setRoute = (route: string) => (this.currentRoute = route)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  push = (route: string, state?: any) => history.push(route, state)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replace = (route: string, state?: any) => history.replace(route, state)

  constructor() {
    makeObservable(this, {
      currentRoute: observable,
      setRoute: action,
    })
  }
}

export const navigation = new Navigation()
