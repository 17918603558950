import { SubscriptionInfo } from '@expane/data'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InformationPopup } from 'widgets/InformationPopup'

export const SubscriptionServicesInfoIcon: FC<{
  usedServices: SubscriptionInfo
  subscriptionServices: Array<{
    serviceId: number
    serviceQuantity: number
    service: { name: string; id: number }
  }>
}> = ({ usedServices, subscriptionServices }) => {
  const { t } = useTranslation()

  return (
    <InformationPopup size="1.2rem" openByClick>
      <p className="text-main-color text-sm mb-2 font-medium">{`${t('services')}:`}</p>

      {subscriptionServices.map(subscriptionService => {
        return (
          <div
            className="flex justify-between text-main-color text-sm pl-0.5"
            key={subscriptionService.serviceId}
          >
            <span className="truncate mr-2">{subscriptionService.service.name}</span>
            <span>
              {usedServices[subscriptionService.serviceId].used}/
              {usedServices[subscriptionService.serviceId].quantity}
            </span>
          </div>
        )
      })}
    </InformationPopup>
  )
}
