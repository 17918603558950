import { checkBusinessIsNotPaid } from '@expane/logic/business'
import { useGetIsSaleByModules } from '@expane/logic/modules'
import { Button, ButtonProps, ButtonWithoutIconProps, OtherButtonProps } from '@expane/ui'
import { useFetchCurrentBusiness } from 'gql/business'
import { FC, MouseEventHandler, PropsWithChildren, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IoAddCircle,
  IoCartOutline,
  IoCashOutline,
  IoCheckmarkDoneOutline,
  IoCheckmarkDoneSharp,
  IoCheckmarkOutline,
  IoCheckmarkSharp,
  IoDocumentsOutline,
  IoDuplicateOutline,
  IoRepeatOutline,
  IoSaveOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { RestoreFromTrash } from 'ui/Icons'
import { useSnackbar } from '@expane/widgets'

export const useOnClickWithBillingCheck = (onClick?: MouseEventHandler<HTMLButtonElement>) => {
  const { t } = useTranslation()
  const { data: currentBusiness } = useFetchCurrentBusiness()

  const [openSnackbar] = useSnackbar()

  const onClickWithBillingCheck: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
      else onClick?.(event)
    },
    [currentBusiness, onClick, openSnackbar, t],
  )

  return onClickWithBillingCheck
}

export const ButtonWithBillingCheck: FC<PropsWithChildren<ButtonProps>> = ({
  onClick,
  ...props
}) => {
  const onClickWithBillingCheck = useOnClickWithBillingCheck(onClick)

  return <Button onClick={onClickWithBillingCheck} {...props} />
}

export const SaveButton: FC<OtherButtonProps & { isCreate?: boolean }> = ({
  isCreate = false,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <ButtonWithBillingCheck type="primary" Icon={isCreate ? IoAddCircle : IoSaveOutline} {...props}>
      {isCreate ? t('create') : t('save')}
    </ButtonWithBillingCheck>
  )
}

export const ApplyButton: FC<ButtonProps> = props => {
  const { t } = useTranslation()

  return (
    <ButtonWithBillingCheck Icon={IoCheckmarkDoneOutline} {...props}>
      {t('apply')}
    </ButtonWithBillingCheck>
  )
}

export const ArchiveButton: FC<OtherButtonProps> = props => {
  const { t } = useTranslation()

  return (
    <ButtonWithBillingCheck type="danger" Icon={IoTrashOutline} {...props}>
      {t('archive.action')}
    </ButtonWithBillingCheck>
  )
}

export const DuplicateButton: FC<OtherButtonProps> = props => {
  const { t } = useTranslation()

  return (
    <ButtonWithBillingCheck type="primary" Icon={IoDocumentsOutline} {...props}>
      {t('duplicate')}
    </ButtonWithBillingCheck>
  )
}

export const RestoreButton: FC<ButtonProps> = props => {
  const { t } = useTranslation()

  return (
    <ButtonWithBillingCheck Icon={RestoreFromTrash} {...props}>
      {t('restore.action')}
    </ButtonWithBillingCheck>
  )
}

export const ImportButton: FC<ButtonProps> = props => {
  const { t } = useTranslation()

  return (
    <ButtonWithBillingCheck Icon={IoDuplicateOutline} {...props}>
      {t('import')}
    </ButtonWithBillingCheck>
  )
}

export const SubmitButton: FC<OtherButtonProps> = props => {
  const { t } = useTranslation()

  return (
    <ButtonWithBillingCheck Icon={IoCheckmarkDoneSharp} {...props}>
      {t('submit')}
    </ButtonWithBillingCheck>
  )
}

export const AddButton: FC<OtherButtonProps> = props => {
  const { t } = useTranslation()

  return (
    <ButtonWithBillingCheck Icon={IoCheckmarkOutline} {...props}>
      {t('add')}
    </ButtonWithBillingCheck>
  )
}

export const PayButton: FC<ButtonWithoutIconProps> = props => {
  const { t } = useTranslation()

  return (
    <ButtonWithBillingCheck Icon={IoCashOutline} {...props}>
      {t('pay')}
    </ButtonWithBillingCheck>
  )
}

export const CreateCategoryButton: FC<ButtonWithoutIconProps> = props => {
  const { t } = useTranslation()

  return (
    <ButtonWithBillingCheck Icon={IoAddCircle} {...props}>
      {t('category')}
    </ButtonWithBillingCheck>
  )
}

export const QuickSaleButton: FC<OtherButtonProps & { shortText?: boolean }> = ({
  shortText = false,
  ...props
}) => {
  const { t } = useTranslation()

  const isQuickSaleButtonEnabled = useGetIsSaleByModules()

  return isQuickSaleButtonEnabled ? (
    <ButtonWithBillingCheck Icon={IoCartOutline} type="outline" {...props}>
      {shortText ? t('selling') : t('quickSale')}
    </ButtonWithBillingCheck>
  ) : null
}

export const RepeatButton: FC<{
  onClick: () => void
  isRecurring: boolean
  className?: string
}> = ({ onClick, isRecurring, className }) => {
  const { t } = useTranslation()

  return (
    <button
      className={
        'flex-centered bg-table-header text-table-title-color bg-table-title-color text-sm px-4 py-0.5 rounded-md ' +
        className
      }
      onClick={onClick}
    >
      {isRecurring ? (
        <IoCheckmarkSharp className={'mr-2'} size={'1.2rem'} />
      ) : (
        <IoRepeatOutline className={'mr-2'} size={'1.2rem'} />
      )}
      {t('repeat')}
    </button>
  )
}
