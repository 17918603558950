import { FC } from 'react'
import { IoCloseOutline } from 'react-icons/io5'

interface Props {
  topPositionInRem: number
  heightInRem: number
  initial?: boolean
  onClick?: () => void
}

export const GhostBookingCard: FC<Props> = ({
  onClick,
  topPositionInRem,
  heightInRem,
  initial = false,
}) => {
  let className = 'absolute left-0 right-0 rounded-lg '

  className += initial ? 'bg-primary-600' : 'bg-primary-400'

  return (
    <div
      className={className}
      style={{ top: topPositionInRem + 'rem', height: heightInRem + 'rem' }}
    >
      {!initial && (
        <button
          className="absolute right-1 top-1 text-primary-200 hover:text-white transition-colors"
          onClick={onClick}
        >
          <IoCloseOutline size="2rem" />
        </button>
      )}
    </div>
  )
}
