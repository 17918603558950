import { gql } from 'graphql-request'
import { queryClient, request, useQuery, reportError, reportGqlError } from '../../api'
import { ServerStorageType } from '../../generated/graphql-types'
import { storageFragment } from './storage.fragments'
import { STORAGES_QUERY_KEY } from './queryKeys'

export function useFetchStorages(branchId: number | undefined) {
  return useQuery<ServerStorageType[]>(
    [STORAGES_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${storageFragment}
          query ($branchId: Int!) {
            storages(where: { branchId: { _eq: $branchId } }, order_by: { createdAt: desc }) {
              ...storageType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.storages)) {
        return result.storages
      } else {
        reportError(new Error('storages is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchStorages',
      onError: reportGqlError,
      onSuccess: storages => {
        storages.forEach(storage =>
          queryClient.setQueryData([STORAGES_QUERY_KEY, storage.id], storage),
        )
      },
      enabled: Boolean(branchId),
    },
  )
}

export function useFetchStorageById(id: number | undefined) {
  return useQuery<ServerStorageType>(
    [STORAGES_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${storageFragment}
          query ($id: Int!) {
            storageById(id: $id) {
              ...storageType
            }
          }
        `,
        { id },
      )

      if (result?.storageById) {
        return result?.storageById
      } else {
        reportError(new Error('storageById does not exist'), 'error', { id, result })
        return undefined
      }
    },
    { queryName: 'useFetchStorageById', onError: reportGqlError, enabled: Boolean(id) },
  )
}
