import {
  BookingUnion,
  NotGroupBooking,
  useCancelBooking,
  useFetchBranchById,
  useFetchDeactivatedServices,
  useFetchExtendedEmployees,
  useFetchExtendedServices,
  useFetchLocations,
  useFetchServiceGroups,
  useGetLeftovers,
} from '@expane/data'
import { getIsBookingDone, getIsBookingPaid } from '@expane/logic/booking'
import { defineDayTiming } from '@expane/logic/calendar'
import { Input, Paper, SelectDropdown } from '@expane/ui'
import { TimePicker, useSnackbar } from '@expane/widgets'
import { getBookingDurations, getPaidServices } from 'logic/booking'
import {
  addDisabledForServices,
  addInactiveReasonsAndWarningMessagesForServices,
  filterInactiveItemsFromSelected,
  getServicesForTreeMenu,
  ServiceGroupTreeItem,
} from 'logic/bookingServiceLogic'
import { useCheckBookingDateEditable } from 'logic/calendar'
import { useShowCancelBookingPopup } from 'logic/hooks/popup/useShowCancelBookingPopup'
import { transformPersonsForSelect } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import {
  Controller,
  UseFieldArrayRemove,
  UseFormReturn,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoClose } from 'react-icons/io5'
import { store } from 'store'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { onChangeMultiTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { TreeSelect } from 'ui/TreeSelect'
import { BookingInactiveReason } from 'widgets/BookingInactiveReason'
import {
  BookingMultiServicesDialogFormValues,
  BookingMultiServicesInitialDto,
} from 'widgets/BookingMultiServicesDialog'
import { BookingMultiServicesConsumablesBlock } from 'widgets/BookingMultiServicesDialog/ConsumablesBlock'
import { BookingStatusTracker, getSalarySettingsWarning } from 'widgets/BookingStatusTracker'
import { useBusinessModulesSettings } from '@expane/logic/modules'

interface MultiServiceBlockProps {
  multiServicesDtoIndex: number
  form: UseFormReturn<BookingMultiServicesDialogFormValues>
  initialData: BookingMultiServicesInitialDto
  remove: UseFieldArrayRemove
  bookingsCount: number
  closeDialog: () => void
  initialBookings: Array<BookingUnion>
  timezone: string
}

export const MultiServiceBlock: FC<MultiServiceBlockProps> = observer(
  ({
    multiServicesDtoIndex,
    form,
    initialData,
    remove,
    bookingsCount,
    closeDialog,
    initialBookings,
    timezone,
  }) => {
    const { getModuleSetting } = useBusinessModulesSettings()
    const isConsumablesEnabled = getModuleSetting('consumables')

    const {
      control,
      setValue,
      getValues,
      formState: { dirtyFields },
    } = form

    const { isDirty } = useFormState({ control })

    const areServicesChanged = dirtyFields.multiServicesDto?.[multiServicesDtoIndex]?.services

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userId = store.me.employeeId!
    const { isCreate } = initialData

    const { t } = useTranslation()

    const watchedEmployeeId = useWatch({
      control,
      name: `multiServicesDto.${multiServicesDtoIndex}.employeeId`,
    })

    const watchedLocationId = useWatch({
      control,
      name: `multiServicesDto.${multiServicesDtoIndex}.locationId`,
    })

    const watchedDate = useWatch({
      control,
      name: `multiServicesDto.${multiServicesDtoIndex}.startDate`,
    })

    const watchedServices = useWatch({
      control,
      name: `multiServicesDto.${multiServicesDtoIndex}.services`,
    })

    const bookingId = getValues(`multiServicesDto.${multiServicesDtoIndex}.bookingId`)
    const bookingById = initialBookings?.find(booking => booking.id === bookingId)

    const paidServices = bookingById
      ? getPaidServices(
          (bookingById as NotGroupBooking).bookingServices.map(
            bookingService => bookingService.service,
          ),
          bookingById.transactions,
        )
      : []

    const branchId = store.branch.branchId
    const { data: branch } = useFetchBranchById(branchId)
    const { data: locations } = useFetchLocations(branchId)
    const { data: employees } = useFetchExtendedEmployees(branch?.timezone, branchId)
    const salarySettingsWarning = getSalarySettingsWarning(watchedEmployeeId, employees)
    const storageIdByEmployee = employees?.find(employee => employee.id === watchedEmployeeId)
      ?.employeeGroup?.storageId
    const defaultStorageId = storageIdByEmployee ?? branch?.defaultStorage?.id

    const { data: productsLeftovers } = useGetLeftovers(branchId, defaultStorageId)
    const leftovers = defaultStorageId && productsLeftovers ? productsLeftovers : undefined

    const branchSchedule = branch?.schedule
    const branchTiming = defineDayTiming(branchSchedule ?? undefined, watchedDate)

    const dateIsEditable = useCheckBookingDateEditable(watchedDate)

    const { data: services, isFetching: isServicesFetching } = useFetchExtendedServices(
      branchId,
      'all',
    )
    const { data: archivedServices } = useFetchDeactivatedServices(
      branchId,
      'all',
      !dateIsEditable && !isCreate,
    )
    const { data: serviceGroups } = useFetchServiceGroups(branchId)

    const { mutateAsync: cancelBooking } = useCancelBooking()

    const [openSnackbar] = useSnackbar()
    const { cancelBookingModal, showConfirmCancelBooking } = useShowCancelBookingPopup()

    const cancelExistingBooking = async (reason: string | null) => {
      if (!bookingId) {
        openSnackbar(t('submitError'), 'error')

        return
      }

      const result = await cancelBooking({ id: bookingId, canceledReason: reason })
      if (result?.updateBookingById?.id) {
        openSnackbar(t('cancelBooking.success'), 'success')
      } else {
        openSnackbar(t('submitError'), 'error')
      }
      closeDialog()
    }

    const handleOnClose = () => {
      if (bookingId) {
        showConfirmCancelBooking({
          onConfirm: cancelExistingBooking,
        })
      } else remove(multiServicesDtoIndex)
    }

    useEffect(() => {
      // если набор услуг поменялся, то длительность записи просчитывается из суммы длительностей услуг
      if (areServicesChanged) {
        const newBookingDuration = getBookingDurations(watchedServices)

        setValue(
          `multiServicesDto.${multiServicesDtoIndex}.duration`,
          (newBookingDuration || INITIAL_DURATION).toString(),
          {
            shouldValidate: true,
          },
        )
      }
    }, [areServicesChanged, watchedServices, setValue, multiServicesDtoIndex])

    const notGroupServices = getServicesForTreeMenu({
      checkArchived: !isCreate && !dateIsEditable,
      services,
      archivedServices,
      servicesFromBooking: (bookingById as NotGroupBooking)?.bookingServices.map(
        bookingService => bookingService.service,
      ),
    })

    const servicesWithInactiveReasons = addInactiveReasonsAndWarningMessagesForServices({
      services: notGroupServices,
      locationId: watchedLocationId,
      employeeId: watchedEmployeeId,
      leftovers,
      userId,
      t,
    })

    const servicesWithDisabled = addDisabledForServices(servicesWithInactiveReasons, paidServices)

    const serviceItems = transformDataForTreeMenu<ServiceGroupTreeItem>(
      serviceGroups,
      servicesWithDisabled,
    )

    useEffect(() => {
      // если поменялся список доступных услуг, оставляем в выбранных только те, которые подходят
      if (!isServicesFetching) {
        const onlyActiveServices = filterInactiveItemsFromSelected(
          watchedServices as ServiceGroupTreeItem[],
          serviceItems,
        )
        if (onlyActiveServices.length < watchedServices.length)
          setValue(`multiServicesDto.${multiServicesDtoIndex}.services`, onlyActiveServices, {
            shouldDirty: true,
          })
      }
    }, [isServicesFetching, watchedServices, serviceItems, setValue, multiServicesDtoIndex])

    const employeesWithInactiveReasons =
      employees?.map(employee => ({
        ...employee,
        warningMessage: employee.salarySettings_aggregate.aggregate?.count
          ? undefined
          : t('employeeSalaryAlert'),
      })) ?? []
    const employeesForSelect = transformPersonsForSelect(employeesWithInactiveReasons)

    const isBookingDone = getIsBookingDone(bookingById)
    const isBookingPaid = getIsBookingPaid(bookingById)

    // bookingsCount !== 1 - если в мультисервисах остался один букинг то нельзя удалять
    const isCancellable = !isBookingDone && !isBookingPaid && bookingsCount !== 1

    return (
      <div className="relative">
        {isCancellable ? (
          <button
            className="absolute top-0 right-0 text-gray-200 hover:text-error-600 pt-2 pr-2 pb-6 pl-6"
            onClick={handleOnClose}
          >
            <IoClose size="1.2rem" className="text-gray-200 hover:text-error-600" />
          </button>
        ) : null}

        <Paper className="p-4 my-2">
          <div className="flex">
            <div className="flex w-1/2 pr-2">
              <Controller
                control={control}
                name={`multiServicesDto.${multiServicesDtoIndex}.locationId`}
                rules={{ required: true }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <SelectDropdown
                    label={t('location.name')}
                    value={value}
                    items={locations}
                    placeholder={t('placeholders.defaultSelect')}
                    noDataMessage={t('noLocation')}
                    onSelectChange={onChange}
                    className="w-1/2 pr-2"
                    errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                    disabled={isBookingDone}
                  />
                )}
              />
              <Controller
                control={control}
                name={`multiServicesDto.${multiServicesDtoIndex}.employeeId`}
                render={({ field: { value, onChange } }) => (
                  <SelectDropdown
                    label={t('employee.name')}
                    value={value ?? undefined}
                    items={employeesForSelect}
                    placeholder={t('placeholders.defaultSelect')}
                    className="w-1/2"
                    noDataMessage={t('noSuchEmployee')}
                    onSelectChange={onChange}
                    popupHeight="small"
                    disabled={isBookingDone}
                    isClearable
                    customClearFunction={() => {
                      setValue(`multiServicesDto.${multiServicesDtoIndex}.employeeId`, null, {
                        shouldDirty: true,
                      })
                    }}
                  />
                )}
              />
            </div>

            <Controller
              name={`multiServicesDto.${multiServicesDtoIndex}.services`}
              control={control}
              rules={{
                required: true,
                validate: value => value.length > 0,
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TreeSelect
                  type="MultiPickMode"
                  items={serviceItems}
                  onSelected={onChange}
                  selected={value as ServiceGroupTreeItem[]}
                  placeholder={t('placeholders.defaultSelect')}
                  className="w-1/2"
                  label={t('service.name')}
                  errorMessage={{ isShown: Boolean(error), text: t('pickAtLeastOneService') }}
                  disabled={isBookingDone}
                  customInactiveReasonRender={item => {
                    return item.isFolder || !item.customInactiveReason ? null : (
                      <BookingInactiveReason
                        item={item}
                        onLocationClick={locationId => {
                          setValue(
                            `multiServicesDto.${multiServicesDtoIndex}.locationId`,
                            locationId,
                          )
                        }}
                        onEmployeeClick={employeeId => {
                          setValue(
                            `multiServicesDto.${multiServicesDtoIndex}.employeeId`,
                            employeeId,
                          )
                        }}
                        onFinalAction={() => {
                          onChange(
                            onChangeMultiTreeMenu(
                              item,
                              value as ServiceGroupTreeItem[],
                              serviceItems,
                              true,
                            ),
                          )
                        }}
                      />
                    )
                  }}
                />
              )}
            />
          </div>
          <div>
            <div className="flex justify-between items-start gap-2">
              {!isCreate && Boolean(bookingById) && bookingId && (
                <BookingStatusTracker
                  bookingId={bookingId}
                  isDirty={isDirty}
                  salarySettingsWarning={salarySettingsWarning}
                />
              )}

              <div className="flex w-1/3 gap-2">
                <Controller
                  control={control}
                  name={`multiServicesDto.${multiServicesDtoIndex}.duration`}
                  rules={{ validate: value => (value ? Number(value) >= INITIAL_DURATION : false) }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                      <Input
                        type="number"
                        value={value ?? INITIAL_DURATION.toString()}
                        onChange={onChange}
                        min={INITIAL_DURATION.toString()}
                        label={t('duration')}
                        placeholder={t('placeholders.defaultSelect')}
                        containerClassName="w-1/2"
                        errorMessage={{
                          isShown: Boolean(error),
                          text: t('formError.invalidValue'),
                        }}
                        disabled={isBookingDone}
                      />
                    )
                  }}
                />

                <Controller
                  control={control}
                  name={`multiServicesDto.${multiServicesDtoIndex}.startDate`}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      timezone={timezone}
                      className="w-1/2"
                      label={t('timeTitle')}
                      value={value}
                      onChange={onChange}
                      timing={branchTiming}
                      disabled={isBookingDone}
                    />
                  )}
                />
              </div>
            </div>

            {isConsumablesEnabled && (
              <BookingMultiServicesConsumablesBlock
                control={control}
                multiServicesDtoIndex={multiServicesDtoIndex}
                disabled={isBookingDone}
              />
            )}
          </div>
          {cancelBookingModal}
        </Paper>
      </div>
    )
  },
)

export const INITIAL_DURATION = 5
