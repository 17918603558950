import { toZonedTime } from '@expane/date'

export const SALARY_ISSUE_TYPES = {
  salaryRate: 1,
  premium: 2,
  penalty: 3,
  service: 4,
  serviceWithActualSoldPrice: 5,
  quickSaleService: 6,
  referralPercentage: 7,
} as const

type SalaryIssueToParse = {
  date?: string | Date | null
  startPeriod?: string | Date | null
  endPeriod?: string | Date | null
}
export const parseDatesInSalaryIssueGqlResponse = <T extends SalaryIssueToParse>(
  salaryIssue: T,
  timezone: string,
) => {
  if (salaryIssue.date) {
    salaryIssue.date = toZonedTime(salaryIssue.date, timezone)
  }
  if (salaryIssue.startPeriod) {
    salaryIssue.startPeriod = toZonedTime(salaryIssue.startPeriod, timezone)
  }
  if (salaryIssue.endPeriod) {
    salaryIssue.endPeriod = toZonedTime(salaryIssue.endPeriod, timezone)
  }

  return salaryIssue
}
