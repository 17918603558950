import { transformPersonName } from '@expane/logic/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { ClientByIdType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'

interface Props {
  client?: ClientByIdType
  clientBalance?: number
  clientCreditLimit: number
}

export const ClientInfo: FC<PropsWithBranchId<Props>> = ({
  client,
  clientBalance,
  clientCreditLimit,
  branchId,
}) => {
  const { t } = useTranslation()
  const convertToMoney = useConvertNumberToMoneyCode({ branchId })

  const balanceWithCredit = clientBalance ? clientBalance + clientCreditLimit : clientCreditLimit
  return (
    <div className="flex">
      <div className="mb-2 mr-3">
        <p className="mr-1 text-gray-400 text-sm mb-1">{t('client.name')}:</p>
        <p className="mr-1 text-gray-400 text-sm mb-1">{t('onAccount')}: </p>
      </div>

      <div>
        {client && (
          <p className="text-main-color font-medium text-sm mb-1">{transformPersonName(client)}</p>
        )}
        {clientBalance !== undefined && (
          <p className="text-main-color font-medium text-sm mb-1">
            {convertToMoney(clientBalance)}
            <span className="text-gray-500 text-xs pl-1">
              ({t('withCreditLimit')}: {convertToMoney(balanceWithCredit)})
            </span>
          </p>
        )}
      </div>
    </div>
  )
}
