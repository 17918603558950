import {
  modalsHTMLElement,
  SpinnerSmall,
  useHandleClickOutside,
  usePopupOpenState,
} from '@expane/ui'
import { flip, offset, useFloating } from '@floating-ui/react-dom'
import { FC, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { IoCaretDownOutline, IoCheckmarkOutline } from 'react-icons/io5'
import { useOnClickWithBillingCheck } from 'widgets/Buttons'

export type ButtonOption = {
  id: number
  label: string
  fullLabel?: string
}

type OptionalButtonProps = {
  optionId: number
  setOptionId: (optionId: number) => void
  onClick: () => void
  options: ButtonOption[]
  className?: string
  spinner?: boolean
  disabled?: boolean
  customPopupsElement?: HTMLDivElement | undefined
  pickedOptionLabelWidth?: string
}

export const ButtonWithAltOptions: FC<OptionalButtonProps> = ({
  options,
  className,
  disabled,
  spinner,
  customPopupsElement,
  pickedOptionLabelWidth,
  onClick,
  optionId,
  setOptionId,
}) => {
  const pickedOption = options.find(option => option.id === optionId)

  if (!pickedOption) throw new Error('Picked option not found')

  const buttonRef = useRef<HTMLButtonElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [popoverRef, setPopoverRef] = useState<HTMLUListElement | null>(null)

  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  useHandleClickOutside([buttonRef.current, popoverRef], closePopup, isOpen)
  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(5), flip()],
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  useEffect(() => {
    reference(buttonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, buttonRef.current])
  useEffect(() => {
    floating(popoverRef)
  }, [floating, popoverRef])

  let styles = `inline-flex items-center justify-center shadow-sm focus:outline-none 
    ring-btn font-medium text-btn-primary h-9.5 px-4 transition-all`

  styles += 'border-transparent text-btn-primary '

  if (disabled) {
    styles += 'bg-btn-disabled-primary cursor-default '
  } else {
    styles += 'bg-btn-primary focus:ring-primary-400 '
  }

  let labelStyle = styles
  if (pickedOptionLabelWidth) labelStyle += pickedOptionLabelWidth

  const onClickWithBillingCheck = useOnClickWithBillingCheck(onClick)

  return (
    <div ref={containerRef} className={className ? 'flex w-fit ' + className : 'flex w-fit'}>
      <button
        ref={buttonRef}
        onClick={() => {
          if (!isOpen) openPopup()
          else closePopup()
        }}
        className={styles + ' rounded-l-md border-r border-primary-600'}
      >
        <IoCaretDownOutline />
      </button>

      <button onClick={onClickWithBillingCheck} className={labelStyle + ' rounded-r-md'}>
        {pickedOption.label}
        {spinner && <SpinnerSmall className="ml-1" />}
      </button>

      {isOpen &&
        createPortal(
          <ul
            className="rounded-md overflow-hidden border-2 border-block-color bg-block min-w-40 max-w-sm"
            ref={setPopoverRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            {options.map(({ id, fullLabel, label }, index) => (
              <li key={id}>
                <button
                  onClick={() => {
                    setOptionId(id)
                    closePopup()
                  }}
                  className={
                    index === options.length - 1
                      ? altOptionClassName
                      : altOptionClassName + ' border-b border-input-disabled-color'
                  }
                >
                  <div className="min-h-9.5 flex text-base font-medium text-main-color ">
                    <div className="px-4 w-12 flex-centered border-r border-input-disabled-color">
                      <IoCheckmarkOutline className={optionId === id ? ' visible' : ' invisible'} />
                    </div>

                    <p className="px-4 text-left self-center">{fullLabel ?? label}</p>
                  </div>
                </button>
              </li>
            ))}
          </ul>,
          customPopupsElement ?? modalsHTMLElement,
        )}
    </div>
  )
}
const altOptionClassName = 'w-full bg-hover'
