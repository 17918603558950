import {
  ServerAccountType,
  ServerCreateServiceReceiptType,
  ServerExpensesReasonType,
  TRANSACTION_TYPES,
  useCheckboxCreateServiceReceipt,
  useCreateTransactionFromAccount,
  useFetchAccounts,
  useFetchExpensesReasons,
  useGetBranchDefaultAccountId,
} from '@expane/data'
import { getCurrentAccount } from '@expane/logic/accounts'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { checkOnlyPositiveAmount, checkWithdrawPossibility, PLACEHOLDERS } from '@expane/logic/form'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { permissions } from '@expane/logic/permission'
import {
  CloseButton,
  Dialog,
  Input,
  InputLabel,
  Modal,
  PlaceholderInput,
  SelectDropdown,
  usePopupOpenState,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { showAccountErrorMessage } from 'logic/form'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps, useOpenDialog } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { EditButton } from 'ui/EditButton'
import { AccountSelectDropdown } from 'widgets/AccountSelectDropdown'
import { AddButton } from 'widgets/Buttons'
import { ExpenseReasonDialog } from 'widgets/FinanceOperationsReasonsDialog/ExpenseReason'
import { handleUpdateCreateReceiptResponse } from '@expane/logic/payment/checkbox'

type AddExpenseFormValues = {
  amount: string
  fromAccountId: number
  expensesReason: number
}

interface AddExpenseDialogLogicProps extends Pick<DialogProps, 'closeDialog'> {
  accounts: ServerAccountType[]
  defaultAccountId: number
  expensesReasons: ServerExpensesReasonType[]
  branchId: number
}

const AddExpenseDialog: FC<Pick<DialogProps, 'closeDialog'>> = observer(({ closeDialog }) => {
  const branchId = store.branch.branchId
  const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)
  const { data: defaultAccountId, isLoading: isLoadingDefaultAccountId } =
    useGetBranchDefaultAccountId(branchId)
  const { data: expensesReasons, isLoading: isLoadingExpensesReasons } =
    useFetchExpensesReasons(branchId)

  const isLoading = isLoadingAccounts || isLoadingDefaultAccountId || isLoadingExpensesReasons
  const isNoData = !accounts || !defaultAccountId || !expensesReasons || !branchId

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

  if (isLoading) return <AddExpenseDialogPlaceholder />
  else if (isNoData) return null

  return (
    <AddExpenseDialogLogic
      closeDialog={closeDialog}
      expensesReasons={expensesReasons}
      defaultAccountId={defaultAccountId}
      accounts={accounts}
      branchId={branchId}
    />
  )
})

const AddExpenseDialogPlaceholder = () => {
  const { t } = useTranslation()

  return (
    <Modal>
      <Dialog>
        <Dialog.Title>{t('transactionNames.addExpense')}</Dialog.Title>

        <Dialog.Body>
          <div className="flex mb-4">
            <PlaceholderInput label={t('amount')} className="w-1/2 mr-2" />
            <PlaceholderInput label={t('fromAccount')} className="w-1/2" />
          </div>
          <PlaceholderInput label={t('transaction.expenseReason')} className="w-full mb-3" />
        </Dialog.Body>

        <Dialog.Footer>
          <AddButton disabled />

          <CloseButton disabled className="mr-auto" />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

const AddExpenseDialogLogic: FC<AddExpenseDialogLogicProps> = ({
  closeDialog,
  expensesReasons,
  defaultAccountId,
  accounts,
  branchId,
}) => {
  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()

  const { formState, control, handleSubmit } = useForm<AddExpenseFormValues>({
    defaultValues: {
      fromAccountId: defaultAccountId,
      amount: '',
      expensesReason: expensesReasons?.[0]?.id,
    },
  })

  const modalRef = useRef<HTMLDivElement>(null)

  const focusToModal = () => {
    modalRef.current?.focus()
  }

  const { mutateAsync: insertTransaction } = useCreateTransactionFromAccount()
  const { mutateAsync: createServiceReceipt } = useCheckboxCreateServiceReceipt()

  const { getModuleSetting } = useBusinessModulesSettings()
  const isCheckboxEnabled = getModuleSetting('checkbox')

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackBar] = useSnackbar()

  const { openCreateDialog: openExpenseReasonDialog, dialog: expenseReasonDialog } =
    useOpenDialog(ExpenseReasonDialog)

  const mutateTransaction: SubmitHandler<AddExpenseFormValues> = async data => {
    const { amount, fromAccountId, expensesReason } = data
    const currentAccount = accounts.find(account => account.id === fromAccountId)

    try {
      const softwarePOSId = currentAccount?.accountSoftwarePOS?.[0]?.softwarePOS.id
      let receiptId: string | undefined
      if (softwarePOSId && isCheckboxEnabled) {
        const result = await createServiceReceipt({
          value: -Number(amount),
          type: ServerCreateServiceReceiptType.Transaction,
          softwarePOSId,
        })

        receiptId = handleUpdateCreateReceiptResponse({
          t,
          onError: message => openSnackBar(message, 'error', 3000),
          createReceiptResult: result.checkboxCreateServiceReceipt,
        })

        if (!receiptId) return false
      }

      await insertTransaction({
        type: TRANSACTION_TYPES.expenses.id,
        amount: Number(amount),
        fromAccountId,
        expensesReasonId: expensesReason,
        branchId,
        transactionReceipts:
          receiptId && softwarePOSId ? { data: [{ receiptId, softwarePOSId }] } : null,
      })

      openSnackBar(t('transaction.expenseAddedSuccessfully'), 'success')
    } catch (error) {
      if (isRestrictionError(error)) openSnackBar(t('planRestriction'), 'error')
      else if (isPlanError(error)) openSnackBar(t('planInfo.noPlan'), 'error')
      else openSnackBar(t('submitError'), 'error')
    }

    closeDialog()
  }

  const isExpensesReasonEditingAllowed = myPermissions?.includes(permissions.expensesReason.set)

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (!formState.isSubmitting && formState.isDirty) handleSubmit(mutateTransaction)()
        }}
        ref={modalRef}
        animation="onlyFadeOut"
      >
        <Dialog>
          <Dialog.Title>{t('transactionNames.addExpense')}</Dialog.Title>

          <Dialog.Body>
            <Controller
              control={control}
              name="fromAccountId"
              rules={{ required: true }}
              render={({ field: { onChange, value: fromAccountId } }) => (
                <>
                  <div className="flex gap-2">
                    <Controller
                      name="amount"
                      control={control}
                      rules={{
                        required: true,
                        validate: {
                          checkWithdrawPossibility: value =>
                            checkWithdrawPossibility({
                              currentAccount: getCurrentAccount(accounts, fromAccountId),
                              value: Number(value),
                            }),
                          checkOnlyPositiveAmount,
                        },
                      }}
                      render={({ field: { onChange, value: amount } }) => (
                        <Input
                          type="number"
                          label={t('amount')}
                          containerClassName="w-1/2"
                          placeholder={PLACEHOLDERS.servicePrice}
                          value={amount}
                          onChange={onChange}
                          required
                          errorMessage={{
                            isShown: Boolean(formState.errors.amount),
                            text: showAccountErrorMessage(formState.errors.amount?.type ?? '', t),
                          }}
                          autoFocus
                        />
                      )}
                    />
                    <AccountSelectDropdown
                      value={fromAccountId}
                      label={t('fromAccount')}
                      accounts={accounts}
                      onSelectChange={onChange}
                      popupHeight="small"
                      errorMessage={{
                        isShown: Boolean(formState.errors.fromAccountId),
                        text: t('formError.required'),
                      }}
                      required
                      className="w-1/2"
                    />
                  </div>

                  <InputLabel label={t('transaction.expenseReason')} />

                  <div className="flex items-center justify-between">
                    <Controller
                      name="expensesReason"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <SelectDropdown
                          value={value}
                          placeholder={t('placeholders.defaultSelect')}
                          items={expensesReasons}
                          noDataMessage={
                            expensesReasons?.length === 0
                              ? t('emptyPlaceholder.expenseReason')
                              : t('noSuchExpenseReason')
                          }
                          onSelectChange={onChange}
                          popupHeight="small"
                          errorMessage={{
                            isShown: Boolean(formState.errors.expensesReason),
                            text: t('formError.required'),
                          }}
                          required
                          className="w-full"
                        />
                      )}
                    />

                    {isExpensesReasonEditingAllowed && (
                      <EditButton
                        onClick={() => openExpenseReasonDialog(focusToModal)}
                        className="ml-1 mb-4"
                      />
                    )}
                  </div>
                </>
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            <AddButton
              onClick={handleSubmit(mutateTransaction)}
              disabled={formState.isSubmitting || !formState.isDirty}
              spinner={formState.isSubmitting}
            />

            <CloseButton
              onClick={closePopups}
              disabled={formState.isSubmitting}
              className="mr-auto"
            />
          </Dialog.Footer>
        </Dialog>
      </Modal>
      {confirmPopup}
      {expenseReasonDialog}
    </>
  )
}

export const useOpenAddExpenseDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()

  const openExpensesDialog = (onClose?: () => void) => {
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const addExpensesDialog = isOpen ? <AddExpenseDialog closeDialog={closeDialog} /> : null

  return { openExpensesDialog, addExpensesDialog }
}
