import {
  AddButtonHeaderCell,
  CloseButton,
  DeleteButtonCell,
  Dialog,
  InputLabel,
  Modal,
  PlaceholderInput,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const InterbranchServiceConsumableDialogLogicPlaceholder: FC<
  Pick<DialogProps, 'closeDialog'>
> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('consumables')}</Dialog.Title>
        <Dialog.Body className="w-144 h-58">
          <div className=" flex flex-col h-full">
            <InputLabel label={t('consumables')} />

            <TableContainer>
              <TableHeader>
                <tr className={'w-full'}>
                  <TableHeaderCell className="w-48">{t('title')}</TableHeaderCell>
                  <TableHeaderCell className="w-24">{t('qty')}</TableHeaderCell>
                  <TableHeaderCell className="w-24">{t('unit.shortName')}</TableHeaderCell>
                  <TableHeaderCell className="w-24 text-right">{t('price')}</TableHeaderCell>
                  <AddButtonHeaderCell
                    disabled={true}
                    onClick={() => {
                      return
                    }}
                  />
                </tr>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <PlaceholderInput height="small" />
                  </TableCell>
                  <TableCell>
                    <PlaceholderInput height="small" />
                  </TableCell>
                  <TableCell className="text-right">
                    <PlaceholderInput width="small" />
                  </TableCell>
                  <TableCell className="text-right">
                    <PlaceholderInput width="small" />
                  </TableCell>
                  <DeleteButtonCell
                    onClick={() => {
                      return
                    }}
                    disabled={true}
                  />
                </TableRow>
              </TableBody>
            </TableContainer>

            <div className="py-2 text-right text-sm text-gray-500">
              {t('total')}: <PlaceholderInput />
            </div>
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
