import {
  BinotelIntegration,
  useFetchBusinessSettings,
  useUpdateBusinessSettingsById,
} from '@expane/data'
import { Input } from '@expane/ui'
import { SettingsWrapper } from 'pages/SettingsPage/index'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from '@expane/widgets'
import { SaveButton } from 'widgets/Buttons'
import { PLACEHOLDERS } from '@expane/logic/form'

interface FormData {
  telephonyToken: string
  telephonyPassword: string
}

export const TelephonySettings: FC = () => {
  const { data: businessSettings } = useFetchBusinessSettings()
  const { mutateAsync: updateBusinessSettings } = useUpdateBusinessSettingsById()

  const { control, handleSubmit, formState } = useForm<FormData>({
    defaultValues: {
      telephonyToken: businessSettings?.binotelIntegration?.key,
      telephonyPassword: businessSettings?.binotelIntegration?.secret,
    },
  })
  const { t } = useTranslation()
  const [openSnackbar] = useSnackbar()

  const handleUpdateBusinessSettings: SubmitHandler<FormData> = async data => {
    if (businessSettings) {
      const binotelIntegration: BinotelIntegration = {
        key: data.telephonyToken ?? null,
        secret: data.telephonyPassword ?? null,
      }

      const result = await updateBusinessSettings({
        id: businessSettings.id,
        businessSettingsSetInput: {
          binotelIntegration,
        },
      })

      if (result?.updateBusinessSettingsById?.id) openSnackbar(t('changesSaved'), 'success', 3000)
      else openSnackbar(t('submitError'), 'error', 3000)
    }
  }

  return (
    <SettingsWrapper>
      <h2 className="text-gray-500 font-medium mb-4">{t('integration.binotel')}</h2>

      <div className="w-1/2">
        <Controller
          name="telephonyToken"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              value={value}
              onChange={onChange}
              placeholder={PLACEHOLDERS.token}
              label={t('key')}
            />
          )}
        />

        <Controller
          name="telephonyPassword"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              value={value}
              onChange={onChange}
              label={t('password')}
              containerClassName="mt-2"
            />
          )}
        />

        <SaveButton
          className="mt-4"
          onClick={handleSubmit(handleUpdateBusinessSettings)}
          spinner={formState.isSubmitting}
          disabled={formState.isSubmitting || !formState.isDirty}
        />
      </div>
    </SettingsWrapper>
  )
}
