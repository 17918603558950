import { gql } from 'graphql-request'
import { ServerAccountByIdType, ServerAccountType } from '../../generated/graphql-types'
import { TRANSACTIONS_QUERY_KEY } from '../transaction/queryKeys'
import { accountByIdFragment, accountFragment } from './account.fragments'
import { useQuery, request, reportError, reportGqlError } from '../../api'
import { ACCOUNTS_QUERY_KEY } from './queryKeys'

export function useFetchAccounts(branchId: number | undefined) {
  return useQuery<ServerAccountType[]>(
    [ACCOUNTS_QUERY_KEY, TRANSACTIONS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${accountFragment}
          query ($branchId: Int!) {
            accounts(where: { branchId: { _eq: $branchId } }, order_by: { createdAt: desc }) {
              ...accountType
            }
          }
        `,
        {
          branchId,
        },
      )

      if (Array.isArray(result?.accounts)) {
        return result.accounts
      } else {
        reportError(new Error('useFetchAccounts result not array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchAccounts',
      onError: reportGqlError,
      enabled: Boolean(branchId),
    },
  )
}

export function useFetchAccountById(id: number | undefined) {
  return useQuery<ServerAccountByIdType | undefined>(
    [ACCOUNTS_QUERY_KEY, TRANSACTIONS_QUERY_KEY, id],
    async () => {
      if (id === 0 || id === undefined) return undefined
      const result = await request(
        gql`
          ${accountByIdFragment}
          query ($id: Int!) {
            accountById(id: $id) {
              ...accountByIdType
            }
          }
        `,
        { id },
      )

      if (result?.accountById) {
        return result.accountById
      } else {
        reportError(new Error('accountById does not exist'), 'warning', { id, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchAccountById',
      onError: reportGqlError,
      enabled: Boolean(id),
    },
  )
}
