import { gql } from 'graphql-request'
import { request, useQuery, reportError, reportGqlError } from '../../api'
import {
  ARCHIVED_EMPLOYEE_GROUPS_QUERY_KEY,
  EMPLOYEE_GROUPS_BRIEFS_QUERY_KEY,
  EMPLOYEE_GROUPS_QUERY_KEY,
} from './queryKeys'
import {
  ServerArchivedEmployeeGroupType,
  ServerEmployeeAllBranchesGroupType,
  ServerEmployeeGroupBriefsType,
  ServerEmployeeGroupByIdType,
} from '../../generated/graphql-types'
import {
  archivedEmployeeGroupFragment,
  employeeAllBranchesGroupFragment,
  employeeGroupBriefsFragment,
  employeeGroupByIdFragment,
  employeeGroupFragment,
} from './employeeGroup.fragments'
import { parseDatesInEmployeeGqlResponse } from '../employee/logic'
import { EmployeeGroupWithSchedule } from './logic'
import { ALL_BRANCHES_QUERY_KEYS } from '../branch'
import { DEFAULT_TIMEZONE } from '@expane/date'

export function useFetchEmployeesGroups(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery(
    [EMPLOYEE_GROUPS_QUERY_KEY, { branchId }],
    async (): Promise<EmployeeGroupWithSchedule[]> => {
      const result = await request(
        gql`
          ${employeeGroupFragment}
          query ($branchId: Int!) {
            employeeGroups(order_by: { name: asc }, where: { archived: { _is_null: true } }) {
              ...employeeGroupType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.employeeGroups)) {
        return result.employeeGroups.map(employeeGroup => ({
          ...employeeGroup,
          employees: employeeGroup.employees
            ? employeeGroup.employees.map(employee =>
                parseDatesInEmployeeGqlResponse(employee, timezone ?? DEFAULT_TIMEZONE),
              )
            : [],
        }))
      } else {
        reportError(new Error('employeeGroups is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchEmployeesGroups',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchAllBranchesEmployeesGroups() {
  return useQuery(
    [EMPLOYEE_GROUPS_QUERY_KEY, ALL_BRANCHES_QUERY_KEYS],
    async (): Promise<ServerEmployeeAllBranchesGroupType[]> => {
      const result = await request(
        gql`
          ${employeeAllBranchesGroupFragment}
          query {
            employeeGroups(order_by: { name: asc }, where: { archived: { _is_null: true } }) {
              ...employeeAllBranchesGroupType
            }
          }
        `,
      )

      if (Array.isArray(result?.employeeGroups)) {
        return result.employeeGroups
      } else {
        reportError(new Error('employeeGroups is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchAllBranchesEmployeesGroups',
      onError: reportGqlError,
    },
  )
}

export function useFetchEmployeesGroupsBriefs() {
  return useQuery(
    [EMPLOYEE_GROUPS_QUERY_KEY, EMPLOYEE_GROUPS_BRIEFS_QUERY_KEY],
    async (): Promise<ServerEmployeeGroupBriefsType[]> => {
      const result = await request(
        gql`
          ${employeeGroupBriefsFragment}
          query {
            employeeGroups(order_by: { name: asc }, where: { archived: { _is_null: true } }) {
              ...employeeGroupBriefsType
            }
          }
        `,
      )

      if (Array.isArray(result?.employeeGroups)) {
        return result.employeeGroups
      } else {
        reportError(new Error('employeeGroups is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchEmployeesGroupsBriefs',
      onError: reportGqlError,
    },
  )
}

export function useFetchArchivedEmployeesGroups(branchId: number | undefined) {
  return useQuery(
    [ARCHIVED_EMPLOYEE_GROUPS_QUERY_KEY, { branchId }],
    async (): Promise<ServerArchivedEmployeeGroupType[]> => {
      const result = await request(
        gql`
          ${archivedEmployeeGroupFragment}
          query ($branchId: Int!) {
            employeeGroups(order_by: { name: asc }, where: { archived: { _is_null: false } }) {
              ...archivedEmployeeGroupType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.employeeGroups)) {
        return result.employeeGroups
      } else {
        reportError(new Error('employeeGroups is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchArchivedEmployeesGroups',
      onError: reportGqlError,
      enabled: Boolean(branchId),
    },
  )
}

export function useFetchEmployeeGroupById(id: number | undefined) {
  return useQuery<ServerEmployeeGroupByIdType>(
    [EMPLOYEE_GROUPS_QUERY_KEY, { id }],
    async () => {
      const result = await request(
        gql`
          ${employeeGroupByIdFragment}
          query ($id: Int!) {
            employeeGroupById(id: $id) {
              ...employeeGroupByIdType
            }
          }
        `,
        { id },
      )

      if (result?.employeeGroupById) {
        return result.employeeGroupById
      } else {
        reportError(new Error('employeeGroupById does not exist'), 'warning', {
          employeeGroupById: id,
        })
        return undefined
      }
    },
    {
      queryName: 'useFetchEmployeeGroupById',
      enabled: Boolean(id),
      onError: reportGqlError,
    },
  )
}
