import { ServerEmployeeAllBranchesGroupType } from '@expane/data'

export const filterEmployeeGroupsEmployeesByBranchId = (
  employeeGroups: ServerEmployeeAllBranchesGroupType[],
  branchId: number,
) =>
  employeeGroups.map(employeeGroup => ({
    ...employeeGroup,
    employees: employeeGroup.employees.filter(employee =>
      employee.employeeSchedules.some(employeeSchedule => employeeSchedule.branchId === branchId),
    ),
  }))
