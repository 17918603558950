import { ServerAccountType } from '@expane/data'
import { useAccountsWithCheckbox } from '@expane/logic/payment/checkbox'
import { findById } from '@expane/logic/utils'
import { InputLabel, SelectDropdown, SelectDropdownProps, Tag } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export enum AccountSelectDropdownType {
  labels,
  tags,
}

interface AccountSelectDropdownProps
  extends Omit<
    SelectDropdownProps<ServerAccountType>,
    'items' | 'label' | 'placeholder' | 'noDataMessage'
  > {
  label?: string
  accounts: ServerAccountType[]
  type?: AccountSelectDropdownType
}

export const AccountSelectDropdown: FC<AccountSelectDropdownProps> = ({
  accounts,
  type = AccountSelectDropdownType.tags,
  label,
  onPlusClick,
  className,
  required,
  ...rest
}) => {
  const { t } = useTranslation()

  const { accountsWithPOSTags, accountsWithPOSLabels } = useAccountsWithCheckbox(accounts)

  const items =
    type === AccountSelectDropdownType.tags ? accountsWithPOSTags : accountsWithPOSLabels

  const tag =
    rest.value && type === AccountSelectDropdownType.tags
      ? findById(rest.value, accountsWithPOSTags)?.tag
      : undefined

  return (
    <div className={className}>
      {label && (
        <div className="flex items-start">
          <InputLabel required={required} label={label} onPlusClick={onPlusClick} />

          {tag && (
            <div className="line-clamp-1 overflow-hidden grow-1 -mt-1.5">
              <Tag className="ml-0.5 inline-block" {...tag} />
            </div>
          )}
        </div>
      )}

      <SelectDropdown
        placeholder={t('placeholders.defaultSelect')}
        items={items}
        noDataMessage={t('noSuchAccount')}
        {...rest}
      />
    </div>
  )
}
