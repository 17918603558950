import {
  CommonPlaceholderDialogProps,
  InputLabel,
  Paper,
  PlaceholderDialog,
  PlaceholderInput,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const SoftwarePOSDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog title={t('account.name')} closeDialog={closeDialog}>
      <PlaceholderInput label={t('title')} className="mb-4" />
      <Paper className="px-2 pt-2">
        <InputLabel className="mb-4" required label={t('softwarePOS.credentials')} />

        <InputLabel className="mb-4" required label={t('accounts')} />

        <div className="flex gap-2">
          <PlaceholderInput label={t('softwarePOS.login')} className="grow mb-4" />
          <PlaceholderInput label={t('softwarePOS.password')} className="grow" />
        </div>
      </Paper>
      <Paper className="px-2 pt-2 mt-2">
        <div className="flex gap-2 mb-1">
          <InputLabel label={t('softwarePOS.licenseKey')} required />
        </div>

        <PlaceholderInput className="mb-4" />
      </Paper>
    </PlaceholderDialog>
  )
}
