import { addDays, addMonths, subDays, subMonths } from '@expane/date'
import { ErrorMessage, ErrorMessageType, InputLabel } from '@expane/ui'
import { Placement } from '@floating-ui/react-dom'
import { FC } from 'react'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import { DateButton } from './DateButton'
import { PickerType } from './DatePicker'
import { DayTiming, getContainerStyle } from './logic'
import { TimeButton } from './TimeButton'

export interface DateTimePickerProps {
  className?: string
  value: Date | undefined
  type?: PickerType
  label?: string
  hint?: string
  disabled?: boolean
  nextPreviousButtons?: boolean
  required?: boolean
  timing?: DayTiming
  showQuickButtons?: boolean
  timezone: string
  errorMessage?: ErrorMessageType
  customDatePopupPlacement?: Placement | undefined
  customPopupsElement?: HTMLDivElement
}

type ClearableProps =
  | { isClearable: true; onChange: (date: Date | undefined) => void }
  | { isClearable?: false; onChange: (date: Date) => void }

export const DateTimePicker: FC<DateTimePickerProps & ClearableProps> = ({
  value,
  type = 'date',
  className,
  label,
  hint,
  disabled = false,
  nextPreviousButtons = false,
  timing,
  customDatePopupPlacement,
  timezone,
  customPopupsElement,
  showQuickButtons,
  required,
  errorMessage,
  ...props
}) => {
  const handleChange = (date: Date | undefined) => {
    if (props.isClearable && date === undefined) props.onChange(undefined)
    else if (date && value?.getTime() !== date.getTime()) props.onChange(date)
  }

  const containerStyle = getContainerStyle({
    disabled,
    error: Boolean(errorMessage?.isShown),
  })

  let dateTimeButtonStyle = 'relative flex items-center h-full text-center text-main-color'
  let buttonStyle = 'flex-centered h-full transition-color w-10 border-datepicker-color'
  if (disabled) {
    dateTimeButtonStyle += ' bg-input-disabled'
    buttonStyle += ' bg-input-disabled text-label-color'
  } else {
    buttonStyle += ' text-label-color hover:text-secondary-color '
  }

  return (
    <div className={className}>
      <InputLabel label={label} hint={hint} required={required} />
      <div className={containerStyle}>
        {nextPreviousButtons && (
          <button
            disabled={disabled}
            onClick={() => {
              if (value) {
                let newDate: Date

                if (type === 'month') {
                  newDate = subMonths(value, 1)
                } else {
                  newDate = subDays(value, type === 'week' ? 7 : 1)
                }

                handleChange(newDate)
              }
            }}
            className={buttonStyle + ' border-r-2'}
          >
            <IoChevronBack />
          </button>
        )}
        <div className="w-full flex items-center">
          <DateButton
            timezone={timezone}
            className={`${dateTimeButtonStyle} grow`}
            calendarType={type}
            onChange={handleChange}
            value={value}
            disabled={disabled}
            isClearable={props.isClearable}
            customDatePopupPlacement={customDatePopupPlacement}
            showQuickButtons={showQuickButtons}
            customPopupsElement={customPopupsElement}
          />
          {type === 'dateTime' && (
            <TimeButton
              timezone={timezone}
              className={`${dateTimeButtonStyle} w-2/5 shrink-0 border-l-2 border-datepicker-color`}
              value={value}
              onChange={handleChange}
              disabled={disabled}
              isClearable={props.isClearable}
              timing={timing}
              customPopupsElement={customPopupsElement}
            />
          )}
        </div>
        {nextPreviousButtons && (
          <button
            disabled={disabled}
            onClick={() => {
              if (value) {
                let newDate: Date

                if (type === 'month') {
                  newDate = addMonths(value, 1)
                } else {
                  newDate = addDays(value, type === 'week' ? 7 : 1)
                }

                props.onChange(newDate)
              }
            }}
            className={buttonStyle + ' border-l-2'}
          >
            <IoChevronForward />
          </button>
        )}
      </div>

      <ErrorMessage errorMessage={errorMessage} />
    </div>
  )
}

export const dateTimePickerPopupStyle =
  'bg-block border-2 border-primary-500 rounded-md overflow-hidden'

export * from './DateButton'
export * from './DatePicker'
export * from './QuickDateButtons'
export * from './TimePicker'
export * from './TimeButton'
export * from './logic'
