import { CancelButton, Dialog, Input, Modal, usePopupOpenState } from '@expane/ui'
import { useRef } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SubmitButton } from 'widgets/Buttons'

export const useTextInputPopup = () => {
  const { t } = useTranslation()

  const { isOpen, openPopup, closePopup } = usePopupOpenState()
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<{ value: string }>()

  const popupTitle = useRef('')
  const popupDescription = useRef<string | undefined>()
  const onConfirmFunction = useRef<(value: string) => void>()

  const confirmAction: SubmitHandler<{ value: string }> = data => {
    onConfirmFunction.current?.(data.value)
    setValue('value', '')
    closePopup()
  }

  const showInputPopup = ({
    title,
    description,
    onConfirm,
  }: {
    title: string
    description?: string
    onConfirm: (value: string) => void
  }) => {
    popupTitle.current = title
    if (description) popupDescription.current = description
    onConfirmFunction.current = onConfirm
    openPopup()
  }

  const inputPopup = isOpen ? (
    <Modal close={closePopup} confirm={handleSubmit(confirmAction)}>
      <Dialog>
        <Dialog.Title>{popupTitle.current}</Dialog.Title>

        <Dialog.Body>
          <Input
            label={popupDescription.current}
            placeholder={t('placeholders.reason')}
            autoFocus
            required
            errorMessage={{
              text: t('formError.required'),
              isShown: errors.value?.type === 'required',
            }}
            {...register('value', { required: true })}
          />
        </Dialog.Body>
        <Dialog.Footer>
          <SubmitButton onClick={handleSubmit(confirmAction)} />
          <CancelButton onClick={closePopup} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  ) : null

  return {
    inputPopup,
    showInputPopup,
  }
}
