import { InputLabel } from '@expane/ui'
import { FC } from 'react'

interface MeterProps {
  label: string
  value: number
  max: number
  className?: string
}

export const Meter: FC<MeterProps> = ({ label, max, value, className }) => {
  return (
    <div className={className}>
      <InputLabel label={label} />
      <meter className="block w-full" min="0" value={value} high={max * 0.75} low={1} max={max} />
    </div>
  )
}
