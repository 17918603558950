import { gql } from 'graphql-request'

export const salaryIssueFragments = gql`
  fragment salaryIssueType on salaryIssue {
    id
    employeeId
    date
    value
    valueWhenDependentOnRealized
    type
    comment
    startPeriod
    endPeriod
    salaryTransactionId
    salarySettingId
    salarySettingService {
      service {
        name
        price
        id
      }
    }
    paymentTransactionService {
      price
      service {
        name
      }
    }
    paymentTransactionId
    paymentTransaction {
      client {
        firstName
        lastName
      }
      cardId
    }
  }
`
