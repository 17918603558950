import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  ServerBusinessSettingsSetInput,
  ServerModulesSettingsInput,
  ServerUpdateModulesSettingsResult,
  ServerUpdateModulesSettingsTypes,
} from '../../generated/graphql-types'
import { BUSINESS_MODULES_SETTINGS_QUERY_KEY, BUSINESS_SETTINGS_QUERY_KEY } from './queryKeys'

export function useUpdateBusinessSettingsById() {
  return useMutation(
    async (dto: { id: number; businessSettingsSetInput: ServerBusinessSettingsSetInput }) => {
      return request<{ updateBusinessSettingsById: { id: number } }>(
        gql`
          mutation MyMutation($id: Int!, $businessSettingsSetInput: businessSettings_set_input!) {
            updateBusinessSettingsById(pk_columns: { id: $id }, _set: $businessSettingsSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([BUSINESS_SETTINGS_QUERY_KEY]),
    },
  )
}

export function useUpdateBusinessModulesSettings() {
  return useMutation(
    async (dto: {
      type: ServerUpdateModulesSettingsTypes
      modules: ServerModulesSettingsInput
    }) => {
      return request<{ updateModulesSettings: ServerUpdateModulesSettingsResult }>(
        gql`
          mutation ($type: UpdateModulesSettingsTypes!, $modules: ModulesSettingsInput!) {
            updateModulesSettings(type: $type, modules: $modules) {
              code
              message
              modulesSettingsThatCanUpdate
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([BUSINESS_MODULES_SETTINGS_QUERY_KEY]),
    },
  )
}
