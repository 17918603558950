import { EmployeeFromGroup, ExtendedEmployee, ServerSalaryRateSettingType } from '@expane/data'
import {
  areIntervalsOverlapping,
  eachDayOfInterval,
  getOverLappingInterval,
  isBefore,
  isWithinInterval,
  min,
} from '@expane/date'
import { TFunction } from 'i18next'
import { findById } from '../utils'

export const RATE_OPTIONS = [
  { id: 1, name: 'salary.monthly' },
  { id: 2, name: 'salary.daily' },
]

export const getDoesEmployeeHaveSalarySetting = (
  employee: Pick<ExtendedEmployee, 'salarySettings_aggregate'>,
): boolean => Boolean(employee.salarySettings_aggregate.aggregate?.count)

export const getEmployeeSalaryRateSettingsByEmployeeId = (
  salaryRateSettings: ServerSalaryRateSettingType[],
  employeeId: number,
) => salaryRateSettings.filter(salaryRateSetting => salaryRateSetting.employeeId === employeeId)

// TODO: RATE_OPTIONS вынести и сделать обьектом чтоб было более очевидно
export const isSalaryRateDaily = (salaryRateSettings: ServerSalaryRateSettingType | undefined) =>
  salaryRateSettings?.rateType === RATE_OPTIONS[1].id

export const isSalaryRateMonthly = (salaryRateSettings: ServerSalaryRateSettingType | undefined) =>
  salaryRateSettings?.rateType === RATE_OPTIONS[0].id

export const getOverlappingSalaryRateSettingAndPeriod = ({
  fromDate,
  toDate,
  salaryRateSetting,
}: {
  fromDate: Date
  toDate: Date
  salaryRateSetting: ServerSalaryRateSettingType
}) => {
  if (!salaryRateSetting.end) {
    if (
      isWithinInterval(salaryRateSetting.start, {
        start: fromDate,
        end: toDate,
      })
    ) {
      return eachDayOfInterval({ start: salaryRateSetting.start, end: toDate })
    }

    if (isBefore(salaryRateSetting.start, fromDate))
      return eachDayOfInterval({ start: fromDate, end: toDate })
    else return []
  } else {
    if (
      areIntervalsOverlapping(
        {
          start: salaryRateSetting.start,
          end: salaryRateSetting.end,
        },
        { start: fromDate, end: toDate },
      )
    ) {
      return getOverLappingInterval(
        { start: fromDate, end: toDate },
        {
          start: salaryRateSetting.start,
          end: salaryRateSetting.end,
        },
      )
    } else return []
  }
}

// датой начала контракта можно считать дату начала первой формула зп
export const getContactStartDate = (salaryRateSettings: ServerSalaryRateSettingType[]) => {
  const startDates = salaryRateSettings.map(({ start }) => start)

  return min(startDates)
}

export type SalarySettingServiceType = {
  id: number
  serviceId: number
  name: string
  type: number
  value: string
  fallbackType?: number
  fallbackValue?: string
  dependsOnRealizedValue: boolean
}

export const RATE_FROM_SERVICES_TYPES = {
  fixedValue: { id: 1, name: 'fixedAmount' },
  percentageOfServicePrice: {
    id: 2,
    name: 'salaryRatesFromServices.percentOfServicePrice',
  },
  roundedPercentageOfServicePrice: {
    id: 3,
    name: 'salaryRatesFromServices.roundedPercentageOfServicePrice',
  },
}

export const RATE_FROM_SERVICES_FALLBACK_TYPES = {
  fixedValue: RATE_FROM_SERVICES_TYPES.fixedValue,
  percentageOfServicePrice: RATE_FROM_SERVICES_TYPES.percentageOfServicePrice,
}

export const checkIsSomeOfCurrentSalaryRateSettingsIsMonthly = ({
  fromDate,
  toDate,
  salarySettings,
}: {
  fromDate: Date
  toDate: Date
  salarySettings: ServerSalaryRateSettingType[]
}) => {
  const currentSalaryRateSettings = salarySettings.filter(
    salaryRateSetting =>
      getOverlappingSalaryRateSettingAndPeriod({
        fromDate,
        toDate,
        salaryRateSetting,
      }).length,
  )

  return currentSalaryRateSettings.some(setting => isSalaryRateMonthly(setting))
}

export const checkAreEmployeesFirstSalarySetting = (employees: EmployeeFromGroup[]) =>
  employees.every(employee => employee.salarySettings.length === 0)

export const getRateTypeName = (rateType: number, t: TFunction): string => {
  const rateOption = findById(rateType, RATE_OPTIONS)

  if (rateOption) return t(rateOption.name)
  else return '-'
}
