import { Hint, Tag } from '@expane/ui'
import { ReactElement, useEffect, useRef } from 'react'
import { IconType } from 'react-icons'
import { IoCheckmark, IoCheckmarkDone } from 'react-icons/io5'
import { CheckMarkButton } from 'ui/CheckMarkButton'
import { EditButton } from 'ui/EditButton'
import { TreeMenuItem } from '.'
import { TreeItemQuantity } from './TreeItemQuantity'

export interface TreeItemProps<MTreeMenuItem extends TreeMenuItem> {
  Icon?: IconType
  onClick?: (item: MTreeMenuItem, isInactive: boolean) => void
  onClickCheckmarkAll?: (item: MTreeMenuItem, isInactive: boolean) => void
  onEditFolder?: (item: TreeMenuItem) => void
  onEditItem?: (item: TreeMenuItem) => void
  customInactiveReasonRender?: (item: MTreeMenuItem) => ReactElement | null
  setFocusedItem: (item: MTreeMenuItem) => void
  isSelectedTreeItem: boolean
  level: number
  isHighlight?: boolean
  item: MTreeMenuItem
  disabled: boolean
  focusedItem: TreeMenuItem | undefined
  showQuantity: boolean
  quantity?: number
  showIcon?: boolean
  isItemsTruncate?: boolean
  modalElement: HTMLElement
  priceConversionHandlerFunction?: (value: number) => string
}

export const TreeItem = <MTreeMenuItem extends TreeMenuItem>({
  item,
  Icon,
  onClick,
  onClickCheckmarkAll,
  onEditFolder,
  onEditItem,
  customInactiveReasonRender,
  isSelectedTreeItem,
  level,
  isHighlight,
  disabled,
  modalElement,
  focusedItem,
  setFocusedItem,
  showQuantity,
  quantity,
  priceConversionHandlerFunction,
  showIcon = true,
  isItemsTruncate = true,
}: TreeItemProps<MTreeMenuItem>) => {
  const isFocused =
    item.id === focusedItem?.id &&
    item.isFolder === focusedItem?.isFolder &&
    item.parentId === focusedItem?.parentId

  const itemRef = useRef<HTMLDivElement>(null)
  // решает проблему со скролом
  useEffect(() => {
    if (isFocused && itemRef.current) {
      // ищем родителя со скролом
      const scrollableParent = itemRef.current?.closest('[class*="overflow"]')
      if (scrollableParent) {
        // определяем размеры и позицию родителя и выбранного айтема оносительно viewport
        const itemPosition = itemRef.current.getBoundingClientRect()
        const parentPosition = scrollableParent.getBoundingClientRect()

        // сравниваем расположение айтема относительно родителя, чтобы понимать куда скролим вверх или вниз
        if (itemPosition.bottom > parentPosition.bottom)
          itemRef.current.scrollIntoView({ block: 'end' })
        if (itemPosition.top < parentPosition.top)
          itemRef.current.scrollIntoView({ block: 'start' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, itemRef.current])

  const inactiveReason =
    customInactiveReasonRender !== undefined
      ? customInactiveReasonRender(item) ?? item.inactiveReason
      : item.inactiveReason

  const isInactive =
    Boolean(inactiveReason) || item.status === 'inactiveNodes' || item.status === 'inactive'

  let itemStyle = 'flex items-center min-h-7 justify-start cursor-pointer pr-2 leading-none'

  if (disabled) {
    itemStyle += ' cursor-default'
    itemStyle += isSelectedTreeItem ? ' bg-icon text-gray-500' : ' text-placeholder-color'

    itemStyle += isHighlight ? ' text-secondary-color' : ' text-placeholder-color'
  } else if (item.inactiveReason || item.status === 'inactive') {
    itemStyle += ' text-placeholder-color'
    if (isFocused) itemStyle += ' bg-accent-gray'
  } else {
    if (isFocused) {
      itemStyle += item.disabled
        ? ' bg-accent-gray text-gray-400'
        : ' bg-accent-light text-primary-500'
    } else {
      if (isSelectedTreeItem) {
        itemStyle += item.disabled
          ? ' text-primary-200 dark:text-primary-500/50'
          : ' text-primary-500 bg-accent'
      } else {
        itemStyle += isHighlight ? ' text-primary-500' : ' text-label-color'
      }
    }
  }

  let showCheckmark = true

  if (!disabled) {
    if (onEditItem || onEditFolder || onClickCheckmarkAll) showCheckmark = !isFocused
  }

  return (
    <div
      ref={itemRef}
      className={itemStyle}
      onMouseOver={() => {
        setFocusedItem(item)
      }}
      onClick={() => {
        onClick?.({ ...item, quantity: quantity ?? 1 }, isInactive)
      }}
    >
      <div className={'shrink-0' + getPaddingItem(level)} />
      {showIcon && (
        <div className="w-6 self-stretch flex-centered shrink-0 text-label-color">
          {Icon && <Icon />}
        </div>
      )}

      <span
        className={
          'max-w-full h-full py-1 text-left ' +
          (isItemsTruncate ? ' truncate' : '') +
          (!showIcon ? ' ml-2' : '')
        }
      >
        {item.name}
      </span>

      {item.tag && <Tag className="ml-1 truncate" {...item.tag} />}

      {item.warningMessage && !disabled && (
        <Hint type="warning" customModal={modalElement} iconSize="0.9rem" className="ml-0.5">
          {item.warningMessage}
        </Hint>
      )}

      {inactiveReason && !disabled ? (
        <div className="ml-auto shrink-0">
          <Hint customModal={modalElement}>{inactiveReason}</Hint>
        </div>
      ) : (
        <div className="ml-auto shrink-0 flex items-center justify-end min-w-6">
          {item.price !== undefined && priceConversionHandlerFunction && (
            <div className="pl-2 pr-1">{priceConversionHandlerFunction(item.price)}</div>
          )}

          {isSelectedTreeItem && (
            <>
              <TreeItemQuantity
                show={showQuantity && !item.isFolder}
                value={quantity}
                disabled={disabled}
                onChange={value => {
                  onClick?.({ ...item, quantity: value }, isInactive)
                }}
              />

              {showCheckmark &&
                (item.isFolder ? <IoCheckmarkDone size="1.2rem" /> : <IoCheckmark size="1.2rem" />)}
            </>
          )}

          {isHighlight && showCheckmark && <IoCheckmark size="1.2rem" />}

          {(onEditFolder || onEditItem) && isFocused && !disabled && (
            <EditButton
              type="lightPrimary"
              onClick={e => {
                e.stopPropagation()
                onEditFolder?.(item)
                onEditItem?.(item)
              }}
              disabled={disabled || item.disabled || Boolean(item.inactiveReason)}
            />
          )}

          {onClickCheckmarkAll && isFocused && !disabled && (
            <CheckMarkButton
              onClick={e => {
                e.stopPropagation()
                if (!item.disabled) onClickCheckmarkAll(item, isInactive)
              }}
              disabled={disabled || item.disabled || Boolean(item.inactiveReason)}
            />
          )}
        </div>
      )}
    </div>
  )
}

const getPaddingItem = (level: number) => {
  return PADDING[level]
}
const PADDING = [' pl-0', ' pl-4', ' pl-8', ' pl-12', ' pl-16', ' pl-20', ' pl-24']
