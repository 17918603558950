import { useFetchAnonymouslyBusinessLandingsPaths } from '@expane/data'
import { useCallback } from 'react'

export const useCheckBusinessLandingPathsAvailability = () => {
  const { refetch } = useFetchAnonymouslyBusinessLandingsPaths()

  const checkBusinessLandingPathsAvailability = useCallback(
    async (path: string) => {
      const { data } = await refetch()

      if (!data?.length) return false
      else return !data.some(item => item.path === path)
    },
    [refetch],
  )

  return {
    checkBusinessLandingPathsAvailability,
  }
}
