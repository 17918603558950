import { gql } from 'graphql-request'

export const interbranchServiceByIdFragment = gql`
  fragment interbranchServiceByIdType on service {
    id
    name
    defaultDuration
    description
    price
    costPrice
    picture
    groupId
    archived
    type
    archived
    color
    availableForClient
    availableToAllBranches
    serviceEmployees(where: { employee: { archived: { _is_null: true } } }) {
      id
      branchId
      employee {
        id
        firstName
        lastName
        groupId
      }
    }
    serviceLocations {
      id
      branchId
      location {
        id
        name
      }
    }
    serviceProducts {
      id
      productId
      quantity
      branchId
      product {
        id
        photo
        name
        price
        unit
        criticalQuantity
        groupId
      }
    }
    # Нам нужно узнать используется ли услуга в не архивных абонементах
    cardTemplateServices(where: { cardTemplate: { archived: { _is_null: true } } }) {
      id
    }
    bookingServices {
      booking {
        branchId
        locationId
        employeeId
      }
    }
    groupBookings {
      locationId
      employeeId
      branchId
    }
    transactionsServices {
      id
    }
  }
`

export const extendedInterbranchServiceFragment = gql`
  fragment extendedInterbranchServiceType on service {
    defaultDuration
    description
    id
    name
    price
    costPrice
    picture
    groupId
    archived
    type
    availableToAllBranches
    serviceEmployees(where: { employee: { archived: { _is_null: true } } }) {
      id
      employee {
        id
        firstName
        lastName
        groupId
      }
    }
    serviceLocations {
      location {
        id
        name
      }
    }
    serviceProducts {
      id
      productId
      quantity
      product {
        id
        photo
        name
        price
        unit
        criticalQuantity
        groupId
      }
    }
    servicePermissions(where: { employee: { archived: { _is_null: true } } }) {
      employee {
        id
      }
    }
    # Нам нужно узнать используется ли услуга в не архивных абонементах
    cardTemplateServices(where: { cardTemplate: { archived: { _is_null: true } } }) {
      id
    }
  }
`
