import { PlaceholderString } from '@expane/ui'
import { FC } from 'react'

interface InfoBlockProps {
  title: string
  text: string
}

export const InfoBlock: FC<InfoBlockProps> = ({ text, title }) => (
  <div className={infoBlockStyle}>
    <p className={infoBlockLabelStyle}>{title}:</p>
    <p className={infoBlockDataStyle}>{text}</p>
  </div>
)

export const InfoBlockPlaceholder: FC<Pick<InfoBlockProps, 'title'>> = ({ title }) => (
  <div className={infoBlockStyle}>
    <p className={infoBlockLabelStyle}>{title}:</p>
    <PlaceholderString />
  </div>
)

const infoBlockStyle = 'flex text-sm mb-1'
const infoBlockLabelStyle = 'shrink-0 mr-1 text-gray-400 w-28'
const infoBlockDataStyle = 'text-main-color font-medium pr-2 truncate'
