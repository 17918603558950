import { DayTiming, EmployeeGroupWithSchedule, ScheduleDto, ScheduleType } from '@expane/data'
import { getCurrentEmployeeSchedule } from '../employeeSchedule'
import {
  getIsWorkingDayFromDynamicSchedule,
  getIsWorkingDayFromIntervalSchedule,
  getIsWorkingDayFromWeeklySchedule,
} from '../calendar'
import { createCurrentDate, parse } from '@expane/date'
import i18next from 'i18next'

export const mapDayToScheduleTemplateFormData = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
}

export const defaultScheduleTemplateWorkingHoursForDay: DayTiming = [10, 21]
export const defaultScheduleTemplateWorkingHoursForWeek: DayTiming[] = [
  defaultScheduleTemplateWorkingHoursForDay,
  defaultScheduleTemplateWorkingHoursForDay,
  defaultScheduleTemplateWorkingHoursForDay,
  defaultScheduleTemplateWorkingHoursForDay,
  defaultScheduleTemplateWorkingHoursForDay,
  defaultScheduleTemplateWorkingHoursForDay,
  defaultScheduleTemplateWorkingHoursForDay,
]

export const SCHEDULE_RANGE_MIN = 5
export const SCHEDULE_RANGE_MAX = 23
export const SCHEDULE_RANGE_STEP = 0.5

export interface ScheduleTemplateFormData {
  scheduleName: string
  '0': DayTiming | null
  '1': DayTiming | null
  '2': DayTiming | null
  '3': DayTiming | null
  '4': DayTiming | null
  '5': DayTiming | null
  '6': DayTiming | null
  breakDays: string
  workDays: string
  timing: DayTiming
}

export const formatScheduleTemplateWeeklyData = (formData: ScheduleTemplateFormData) => {
  const formattedData = new Array<DayTiming | null>(7).fill(null)

  for (let i = 0; i < 7; i++) {
    formattedData[i] = formData[i]
  }

  return formattedData
}

export const getWorkingEmployeeIdsByDate = (
  employeeGroups: EmployeeGroupWithSchedule[],
  date: Date,
) => {
  const employeeIds: Array<number> = []

  if (!date) return employeeIds

  for (const group of employeeGroups) {
    for (const employee of group.employees) {
      const employeeSchedule = getCurrentEmployeeSchedule(employee.employeeSchedules, date)
      if (!employeeSchedule) continue

      if (employeeSchedule.isDynamic) {
        const isWorkDay = getIsWorkingDayFromDynamicSchedule(employeeSchedule.dynamicDates, date)

        if (isWorkDay) employeeIds.push(employee.id)
      } else {
        if (employeeSchedule.schedule.type === ScheduleType.INTERVAL) {
          const isWorkDay = getIsWorkingDayFromIntervalSchedule(
            employeeSchedule.schedule,
            // в интервальном точно есть начало
            employeeSchedule.startDate,
            date,
          )
          if (isWorkDay) employeeIds.push(employee.id)
        } else {
          const isWorkDay = getIsWorkingDayFromWeeklySchedule(employeeSchedule.schedule, date)

          if (isWorkDay) employeeIds.push(employee.id)
        }
      }
    }
  }

  return employeeIds
}

export const filterOnlyWeeklySchedules = (schedules: ScheduleDto[]): ScheduleDto[] =>
  schedules.filter(({ type }) => type === ScheduleType.WEEKLY)

export const getWeekDayName = (indexOfDayOfTheWeek: number, timezone: string | undefined) => {
  const dateForParsing = createCurrentDate(timezone)

  const locale = i18next.language

  const parsedDate = parse((indexOfDayOfTheWeek + 1).toString(), 'i', dateForParsing)
  const dateString = parsedDate.toLocaleDateString(locale, {
    weekday: 'long',
  })

  // Разделяем строку по запятой так возвращается день недели с датой
  return dateString.split(',')[0].trim()
}
