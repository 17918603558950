import { EmployeeGroupWithSchedule } from '@expane/data'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterEmployeesProvideServices = <T extends { serviceEmployees: any[] }>(
  employees: T[] | undefined,
): T[] => employees?.filter(employee => employee.serviceEmployees?.length) ?? []

export const getEmployeeByIdFromEmployeeGroups = (
  employeeGroups: EmployeeGroupWithSchedule[] | undefined,
  employeeId: number | undefined,
) => {
  if (!employeeGroups || !employeeId) return undefined

  for (let i = 0; i < employeeGroups.length; i++) {
    for (let s = 0; s < employeeGroups[i].employees.length; s++) {
      if (employeeGroups[i].employees[s].id === employeeId) {
        return employeeGroups[i].employees[s]
      }
    }
  }
}
