import { ThemeModeSwitch } from '@expane/ui'
import { SettingsWrapper } from 'pages/SettingsPage/index'
import { FC } from 'react'
import { reportError } from 'services/sentry'
import { LanguageSwitch } from 'widgets/LanguageSwitch'
import { ThemePickerWidget } from '@expane/widgets'

export const MainSettings: FC = () => {
  return (
    <SettingsWrapper>
      <LanguageSwitch withLabel containerClassName="mb-2" />

      <div className="flex gap-4 items-end">
        <ThemePickerWidget reportError={reportError} />
        <ThemeModeSwitch reportError={reportError} containerClassName="mb-1" />
      </div>
    </SettingsWrapper>
  )
}
