import { firebase } from 'services/firebase'
import { initSentry } from 'services/sentry'
import { initAuthentication } from './auth'

export function initApp() {
  initSentry()

  firebase.init()

  initAuthentication()

  firebase.subscribeToServerStatus()
}

export function reloadApp() {
  window.location.reload()
}
