import { AreManyEmployeesAllowed, Modules, Permissions, RouteType } from 'routes/logic'
import { EMPLOYEE_PERMISSIONS_PATH } from 'routes/navigationRoutes'
import { BRANCH_IMPORT_PATH, MODULES_SETTINGS_PATH } from 'routes/menuRoutes'
import { CREATE_BUSINESS_EMPLOYEES_PATH } from 'routes/createBusinessRoutes'
import { GetFeatureFlagFunc } from '@expane/logic/featureFlags'

export const filterRouteByPermission =
  (employeePermissions: Permissions) =>
  (route: RouteType): boolean => {
    // keep all routes where permissions are not needed
    if (!route.permissions || route.permissions?.length === 0) return true

    // block if the employee has no permissions
    if (employeePermissions === null) return false

    if (employeePermissions) {
      if (route.filterByEveryPermission)
        return route.permissions?.every(p => employeePermissions.includes(p))
      return route.permissions?.some(p => employeePermissions.includes(p))
    }

    return true
  }

export const filterRouteByModules =
  (enabledModules: Modules) =>
  (route: RouteType): boolean => {
    if (enabledModules === null) return true
    if (!route.modules || route.modules.length === 0) return true

    return route.modules.some(moduleKey => enabledModules.includes(moduleKey))
  }

export const filterRouteByFeatureFlags =
  (getFeatureFlag?: GetFeatureFlagFunc) =>
  (route: RouteType): boolean => {
    if (!getFeatureFlag) return true
    if (!route.featureFlags || route.featureFlags.length === 0) return true

    return route.featureFlags.some(moduleKey => getFeatureFlag(moduleKey))
  }

export const filterRouteByMaxEmployees =
  (areManyEmployeesAllowed: AreManyEmployeesAllowed) =>
  (route: RouteType): boolean => {
    if (areManyEmployeesAllowed === null) return true

    if (!areManyEmployeesAllowed && route.path === EMPLOYEE_PERMISSIONS_PATH) return false

    // create business
    if (!areManyEmployeesAllowed && route.path === CREATE_BUSINESS_EMPLOYEES_PATH) return false

    return true
  }

export const filterCreateBusinessRouteByMaxEmployees =
  (areManyEmployeesAllowed: AreManyEmployeesAllowed) =>
  (route: RouteType): boolean => {
    if (areManyEmployeesAllowed === null) return true

    if (!areManyEmployeesAllowed && route.path === EMPLOYEE_PERMISSIONS_PATH) return false

    return true
  }

export const filterRouteByIsOneBranchInBusiness =
  (isOneBranchInBusiness: boolean | undefined) =>
  (route: RouteType): boolean => {
    if (isOneBranchInBusiness === undefined) return true

    if (isOneBranchInBusiness && route.path === BRANCH_IMPORT_PATH) return false

    return true
  }

export const filterRouteByIsEnableBusinessModules =
  (isEnableBusinessModules: boolean | undefined) =>
  (route: RouteType): boolean => {
    if (isEnableBusinessModules === undefined) return true

    if (!isEnableBusinessModules && route.path === MODULES_SETTINGS_PATH) return false

    return true
  }
