import { useBusinessModulesSettings } from '@expane/logic/modules'
import {
  Button,
  Checkbox,
  CloseButton,
  DeleteButtonCell,
  Dialog,
  Paper,
  PlaceholderInput,
  PlaceholderString,
  Spinner,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCashOutline } from 'react-icons/io5'
import { DiscountInput } from 'ui/DiscountInput'
import { getTitles } from './InvoiceTable'

export const DialogPlaceholder: FC<{ close: () => void }> = ({ close }) => {
  const { t } = useTranslation()

  const { getFilteredItemsByModules, getModuleSetting } = useBusinessModulesSettings()
  const isSubscriptionsEnabled = getModuleSetting('subscriptions')

  return (
    <Dialog>
      <Dialog.Title>{t('payment.name')}</Dialog.Title>
      <Dialog.Body className="w-256 h-112">
        <div className="flex justify-between">
          <div className="flex">
            <div className="mb-2 mr-3">
              <p className="mr-1 text-gray-400 text-sm mb-1">{t('client.name')}:</p>
              <p className="mr-1 text-gray-400 text-sm mb-1">{t('onAccount')}: </p>
            </div>

            <div>
              <PlaceholderString className="mb-2" />
              <PlaceholderString />
            </div>
          </div>
        </div>
        <div className="grow">
          <TableContainer className="text-sm">
            <TableHeader>
              <tr>
                {getFilteredItemsByModules(getTitles(isSubscriptionsEnabled)).map(
                  ({ title, width }) => (
                    <TableHeaderCell key={title} className={width}>
                      {t(title)}
                    </TableHeaderCell>
                  ),
                )}
                <TableHeaderCell />
              </tr>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  <PlaceholderString />
                </TableCell>
                <TableCell>
                  <PlaceholderString width="small" />
                </TableCell>
                <TableCell>
                  <PlaceholderString width="small" />
                </TableCell>
                <TableCell>
                  <PlaceholderString width="small" />
                </TableCell>
                <TableCell>
                  <DiscountInput
                    onChange={() => {
                      return
                    }}
                    height="small"
                    disabled
                  />
                </TableCell>
                {isSubscriptionsEnabled && (
                  <TableCell>
                    <PlaceholderString width="small" />
                  </TableCell>
                )}

                <TableCell>
                  <PlaceholderString width="small" />
                </TableCell>
                <DeleteButtonCell />
              </TableRow>
            </TableBody>
          </TableContainer>

          <div className="mt-3 flex items-center justify-end">
            <Checkbox label={t('applyDiscountEntireReceipt')} checked={false} disabled />
            <PlaceholderInput className="w-20 ml-2" height="small" width="small" />
            <div className="flex items-center ml-3 font-medium text-label-color min-w-40">
              {t('total')}: <PlaceholderString />
            </div>
          </div>
        </div>
        <Paper className="mt-7 p-3 h-26.5">
          <Spinner expandCentered />
        </Paper>
      </Dialog.Body>
      <Dialog.Footer>
        <Button disabled Icon={IoCashOutline}>
          {t('payBooking')}
        </Button>
        <CloseButton onClick={close} />
      </Dialog.Footer>
    </Dialog>
  )
}
