import { IconType } from 'react-icons'
import { FC } from 'react'
import { archiveRoutes, navigationRoutes } from 'routes/navigationRoutes'
import {
  createBusinessExtendedStepsRoutes,
  createBusinessFinishStepRoute,
  createBusinessMainInfoStepsRoutes,
  createBusinessRoute,
} from 'routes/createBusinessRoutes'
import { menuRoute } from 'routes/menuRoutes'
import { publicRoutes } from 'routes/publicRoutes'
import { RequiredField } from '@expane/logic/utils/typescript'
import { EnabledModules } from '@expane/logic/modules'
import {
  filterRouteByFeatureFlags,
  filterRouteByIsEnableBusinessModules,
  filterRouteByIsOneBranchInBusiness,
  filterRouteByMaxEmployees,
  filterRouteByModules,
  filterRouteByPermission,
} from 'routes/filters'
import { FEATURE_FLAGS, GetFeatureFlagFunc } from '@expane/logic/featureFlags'

export type Permissions = string[] | null | undefined
export type Modules = EnabledModules | null
export type AreManyEmployeesAllowed = boolean | null
type GetRoutesFunc = (
  permissions: Permissions,
  enabledModules: Modules,
  getFeatureFlag?: GetFeatureFlagFunc,
) => RouteType[]
type GetCommonRouteFunc = (
  permissions: Permissions,
  enabledModules: Modules,
  areManyEmployeesAllowed: AreManyEmployeesAllowed,
  getFeatureFlag?: GetFeatureFlagFunc,
) => RouteType[]
type FilterFunc = (route: RouteType) => boolean

export enum CreateBusinessType {
  quick = 'quick',
  extended = 'extended',
}

interface RoutesType {
  getAllFlatRoutes: GetCommonRouteFunc
  getAllRoutes: GetCommonRouteFunc
  getNavigationFlatRoutes: GetCommonRouteFunc
  getTopBarRoutes: (
    permissions: Permissions,
    enabledModules: Modules,
    areManyEmployeesAllowed: AreManyEmployeesAllowed,
    getFeatureFlag?: GetFeatureFlagFunc,
  ) => NavigationRouteType[]
  getCreateBusinessStepsRoutes: (
    type: CreateBusinessType,
    enabledModules: Modules,
    areManyEmployeesAllowed: AreManyEmployeesAllowed,
    getFeatureFlag?: GetFeatureFlagFunc,
  ) => Array<NavigationRouteType & { step: number }>
  getSettingsRoutes: (dto: {
    permissions: Permissions
    enabledModules: Modules
    isOneBranchInBusiness?: boolean
    isEnableBusinessModules?: boolean
    getFeatureFlag?: GetFeatureFlagFunc
  }) => Array<ChildRouteType>
  getMenu: GetRoutesFunc
  getArchiveRoutes: GetRoutesFunc
}

export type RouteType = {
  name: string
  // https://reactrouter.com/en/main/route/route#layout-routes
  path: string | undefined
  isPrivate: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: FC<any>
  permissions?: string[]
  filterByEveryPermission?: boolean
  Icon?: IconType
  parentRoute?: { path: string; name: string }
  description?: string
  children?: Array<ChildRouteType>
  // Modules required for route
  modules?: Modules
  featureFlags?: Array<keyof typeof FEATURE_FLAGS>
}
export type ChildRouteType = RequiredField<Omit<RouteType, 'children'>, 'path'>
export type NavigationRouteType = RequiredField<RouteType, 'path'>

const filterRoute =
  (filters: FilterFunc[] = []) =>
  (route: RouteType): boolean =>
    filters.every(filterFunc => filterFunc(route))

const flatRoute = (route: RouteType, filters: FilterFunc[]): RouteType[] => {
  const result: RouteType[] = []

  if (filterRoute(filters)(route)) {
    result.push({ ...route, children: undefined })
  }
  if (route.children) {
    for (const childRoute of route.children) {
      if (filterRoute(filters)(childRoute)) {
        result.push({ ...childRoute })
      }
    }
  }

  return result
}

const flatRoutes = (routes: RouteType[], filters: FilterFunc[]): RouteType[] => {
  const result: RouteType[] = []

  for (const route of routes) {
    result.push(...flatRoute(route, filters))
  }

  return result
}

export const routes: RoutesType = {
  getAllFlatRoutes: (permissions, enabledModules, areManyEmployeesAllowed, getFeatureFlag) =>
    flatRoutes(
      [...navigationRoutes, menuRoute, createBusinessRoute, ...publicRoutes, ...archiveRoutes],
      [
        filterRouteByPermission(permissions),
        filterRouteByModules(enabledModules),
        filterRouteByMaxEmployees(areManyEmployeesAllowed),
        filterRouteByFeatureFlags(getFeatureFlag),
      ],
    ),

  getAllRoutes: (permissions, enabledModules, areManyEmployeesAllowed, getFeatureFlag) => {
    // В нас кожен роут навігації все одно малюється в руті на відміну від створення бізнесу і налаштувань
    // Тому ми їх і вирівнюємо
    const flatNavigationRoutes = flatRoutes(navigationRoutes, [
      filterRouteByPermission(permissions),
      filterRouteByModules(enabledModules),
      filterRouteByMaxEmployees(areManyEmployeesAllowed),
      filterRouteByFeatureFlags(getFeatureFlag),
    ])

    return [
      ...flatNavigationRoutes,
      menuRoute,
      createBusinessRoute,
      ...publicRoutes,
      ...archiveRoutes,
    ]
  },

  getNavigationFlatRoutes: (permissions, enabledModules, areManyEmployeesAllowed, getFeatureFlag) =>
    flatRoutes(navigationRoutes, [
      filterRouteByPermission(permissions),
      filterRouteByModules(enabledModules),
      filterRouteByMaxEmployees(areManyEmployeesAllowed),
      filterRouteByFeatureFlags(getFeatureFlag),
    ]),

  getTopBarRoutes: (permissions, enabledModules, areManyEmployeesAllowed, getFeatureFlag) => {
    const routes: NavigationRouteType[] = []

    for (const navigationRoute of navigationRoutes) {
      if (
        filterRoute([filterRouteByPermission(permissions), filterRouteByModules(enabledModules)])(
          navigationRoute,
        )
      ) {
        routes.push({
          ...navigationRoute,
          children: navigationRoute.children?.filter(
            filterRoute([
              filterRouteByPermission(permissions),
              filterRouteByModules(enabledModules),
              filterRouteByMaxEmployees(areManyEmployeesAllowed),
              filterRouteByFeatureFlags(getFeatureFlag),
            ]),
          ),
        })
      } else {
        const allowedChildrenRolutes: NavigationRouteType[] | undefined =
          navigationRoute.children?.filter(
            filterRoute([
              filterRouteByPermission(permissions),
              filterRouteByModules(enabledModules),
              filterRouteByMaxEmployees(areManyEmployeesAllowed),
              filterRouteByFeatureFlags(getFeatureFlag),
            ]),
          )
        if (allowedChildrenRolutes) routes.push(...allowedChildrenRolutes)
      }
    }

    return routes
  },

  getSettingsRoutes: ({
    permissions,
    enabledModules,
    isOneBranchInBusiness,
    isEnableBusinessModules,
    getFeatureFlag,
  }) =>
    menuRoute.children?.filter(
      filterRoute([
        filterRouteByPermission(permissions),
        filterRouteByModules(enabledModules),
        filterRouteByIsOneBranchInBusiness(isOneBranchInBusiness),
        filterRouteByIsEnableBusinessModules(isEnableBusinessModules),
        filterRouteByFeatureFlags(getFeatureFlag),
      ]),
    ) ?? [],

  getCreateBusinessStepsRoutes: (type, enabledModules, areManyEmployeesAllowed, getFeatureFlag) =>
    type === CreateBusinessType.quick
      ? [...createBusinessMainInfoStepsRoutes, createBusinessFinishStepRoute].map((r, index) => ({
          ...r,
          step: index + 1,
        }))
      : [
          ...createBusinessMainInfoStepsRoutes,
          ...createBusinessExtendedStepsRoutes.filter(
            filterRoute([
              filterRouteByModules(enabledModules),
              filterRouteByMaxEmployees(areManyEmployeesAllowed),
              filterRouteByFeatureFlags(getFeatureFlag),
            ]),
          ),
          createBusinessFinishStepRoute,
        ].map((r, index) => ({
          ...r,
          step: index + 1,
        })),
  getMenu: (permissions, enabledModules, getFeatureFlag) => [
    {
      ...menuRoute,
      children: menuRoute.children?.filter(
        filterRoute([
          filterRouteByPermission(permissions),
          filterRouteByModules(enabledModules),
          filterRouteByFeatureFlags(getFeatureFlag),
        ]),
      ),
    },
  ],

  getArchiveRoutes: (permissions, enabledModules, getFeatureFlag) =>
    archiveRoutes.filter(
      filterRoute([
        filterRouteByPermission(permissions),
        filterRouteByModules(enabledModules),
        filterRouteByFeatureFlags(getFeatureFlag),
      ]),
    ),
}
