import {
  InventoryProductInput,
  ServerInventoryByIdType,
  useFetchCurrentBranchTimezone,
  useFetchInventoryById,
  useFetchProducts,
  useFulfillInventory,
  useGetMovementNumber,
  useUpdateInventoryProducts,
} from '@expane/data'
import { getMovementTypesName } from '@expane/logic/movement'
import { permissions } from '@expane/logic/permission'
import { convertUnitValueFromServer, convertUnitValueToServer } from '@expane/logic/product'
import { Button, CloseButton, Dialog, DialogWithClickOutside, Modal } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { DialogProps, useOpenDialog } from 'logic/hooks/useOpenDialog'
import { usePrintWrapper } from 'logic/hooks/usePrintWrapper'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoPrintOutline } from 'react-icons/io5'
import { store } from 'store'
import { SaveButton, SubmitButton } from 'widgets/Buttons'
import { InventoryProductDto } from 'widgets/InventoryCreateDialog'
import { InventoryDocumentList } from 'widgets/InventoryDocumentDialog/InventoryDocumentList'
import {
  ProductDto,
  useOpenMovementDialog,
} from 'widgets/MovementCreateDialogs/ChooseMovementProductsDialog'
import { PostingMovementDialog } from 'widgets/MovementCreateDialogs/PostingMovementDialog'
import { WriteOffMovementDialog } from 'widgets/MovementCreateDialogs/WriteOffMovementDialog'
import { MovementInfoDialog } from 'widgets/MovementInfoDialog'
import { DialogPlaceholder } from './DialogPlaceholder'
import { EditableInventoryList } from './EditableInventoryList'

type InventoryDocumentDialogProps = Omit<DialogProps, 'isCreate'>
export const InventoryDocumentDialog: FC<InventoryDocumentDialogProps> = ({ id, closeDialog }) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { isLoading: isMyPermissionsLoading } = useFetchMyPermissions()
  const { isLoading: isInventoryLoading } = useFetchInventoryById(id, timezone)
  const { isLoading: isProductsLoading } = useFetchProducts(branchId)
  const { isLoading: isMovementNumberLoading } = useGetMovementNumber(branchId)
  const isLoading =
    isMyPermissionsLoading ||
    isInventoryLoading ||
    isProductsLoading ||
    isMovementNumberLoading ||
    !timezone

  return (
    <Modal>
      {isLoading ? (
        <DialogPlaceholder close={closeDialog} />
      ) : (
        <InventoryDocumentDialogLogic id={id} closeDialog={closeDialog} timezone={timezone} />
      )}
    </Modal>
  )
}

const InventoryDocumentDialogLogic: FC<InventoryDocumentDialogProps & { timezone: string }> = ({
  id,
  closeDialog,
  timezone,
}) => {
  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()
  const [showSnackbar] = useSnackbar()
  const branchId = store.branch.branchId

  const { dialog: movementMovementInfoDialog, openEditDialog: openMovementInfoDialog } =
    useOpenDialog(MovementInfoDialog)

  const { data: inventory } = useFetchInventoryById(id, timezone)
  const { data: myPermissions } = useFetchMyPermissions()
  const { data: products } = useFetchProducts(branchId)
  const { data: movementNumber } = useGetMovementNumber(branchId)

  const isEditingAllowed =
    !inventory?.fulfilled && myPermissions?.includes(permissions.inventory.set)

  const { control, formState, handleSubmit } = useForm<InventoryFieldValues>({
    defaultValues: {
      products: inventory ? getProductsForEditableTable(inventory) : [],
    },
  })
  const { mutateAsync: updateInventoryProducts } = useUpdateInventoryProducts()
  const { mutateAsync: fulfillInventory, isLoading: isFulfilling } = useFulfillInventory()

  const { confirmPopup, closePopups } = useShowPopupOnDirtyFormClose(formState, closeDialog)

  const title = inventory
    ? `${t('inventory.name')} ${t('numberFormat', { value: inventory.number })} ${t(
        'dated',
      )} ${dateFormatting('historyDateTime', inventory.inventoryDate)}`
    : t('inventory.name')
  const { PrintWrapper, onPrint } = usePrintWrapper(title)

  const productsForWriteOff = transformProductsForWriteOffDialog(inventory)
  const productsForPosting = transformProductsForPostingDialog(inventory)

  const { dialog: writeOffMovementDialog, openDialog: openWriteOffMovementDialog } =
    useOpenMovementDialog({
      items: productsForWriteOff,
      storageId: inventory?.storage.id,
      products: products ?? [],
      movementNumber: movementNumber ?? 1,
      Component: WriteOffMovementDialog,
      inventoryId: id,
    })

  const { dialog: postingMovementDialog, openDialog: openPostingMovementDialog } =
    useOpenMovementDialog({
      items: productsForPosting,
      storageId: inventory?.storage.id,
      products: products ?? [],
      movementNumber: movementNumber ?? 1,
      Component: PostingMovementDialog,
      inventoryId: id,
    })

  const closeModal = () => {
    if (!writeOffMovementDialog && !postingMovementDialog && !movementMovementInfoDialog)
      closePopups()
  }

  const mutateInventory: SubmitHandler<InventoryFieldValues> = async data => {
    if (formState.isDirty) {
      const result = await updateInventoryProducts(
        transformFormDataToInventoryProductInput(id, data),
      )
      if (
        result.deleteInventoryProducts.affected_rows &&
        result.insertInventoryProducts.affected_rows
      )
        showSnackbar(t('inventory.successfullyUpdated'), 'success')
      else return showSnackbar(t('submitError'), 'error')
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const result = await fulfillInventory(id!)
    if (result?.updateInventoryById?.id) showSnackbar(t('inventory.successfullyUpdated'), 'success')
    else showSnackbar(t('submitError'), 'error')
  }

  const mutateProducts: SubmitHandler<InventoryFieldValues> = async data => {
    const result = await updateInventoryProducts(transformFormDataToInventoryProductInput(id, data))
    if (
      result.deleteInventoryProducts.affected_rows &&
      result.insertInventoryProducts.affected_rows
    )
      showSnackbar(t('inventory.successfullyUpdated'), 'success')
    else showSnackbar(t('submitError'), 'error')

    closeDialog()
  }

  if (!inventory) return null

  const areProductsMovementAllowed = myPermissions?.includes(permissions.movement.set)

  return (
    <DialogWithClickOutside onClickOutside={closeModal}>
      <Dialog.Title>
        <div className="flex justify-between items-center">
          {title}
          {!isEditingAllowed && (
            <div
              className="text-primary-400 hover:text-primary-600 cursor-pointer"
              onClick={onPrint}
            >
              <IoPrintOutline size="1.2rem" />
            </div>
          )}
        </div>
      </Dialog.Title>
      <Dialog.Body className="w-256 h-86 flex flex-col flex-1 overflow-hidden">
        {!isEditingAllowed && (
          <PrintWrapper className="flex flex-col flex-1 overflow-auto print:h-full print:w-256">
            <InventoryDocumentList inventory={inventory} branchId={branchId} />
          </PrintWrapper>
        )}
        {isEditingAllowed && <EditableInventoryList control={control} branchId={branchId} />}

        {inventory.movements.map(movement => (
          <div
            key={movement.id}
            onClick={() => openMovementInfoDialog(movement.id)}
            className="flex cursor-pointer text-underline text-primary-500 text-sm"
          >
            <p className="mr-2">{dateFormatting('historyDateTime', movement.createdAt)}</p>
            <p>{getMovementTypesName(movement.type, t)}</p>
          </div>
        ))}
      </Dialog.Body>
      <Dialog.Footer>
        {areProductsMovementAllowed && !isEditingAllowed && (
          <>
            <Button disabled={!productsForWriteOff.length} onClick={openWriteOffMovementDialog}>
              {t('writeOff.checkout')}
            </Button>
            <Button disabled={!productsForPosting.length} onClick={openPostingMovementDialog}>
              {t('posting.checkout')}
            </Button>
          </>
        )}
        {isEditingAllowed && (
          <>
            <SaveButton
              spinner={formState.isSubmitting}
              disabled={formState.isSubmitting || !formState.isDirty || isFulfilling}
              onClick={handleSubmit(mutateProducts)}
            />
            <SubmitButton
              spinner={isFulfilling}
              disabled={formState.isSubmitting || isFulfilling}
              onClick={handleSubmit(mutateInventory)}
            />
          </>
        )}

        <CloseButton onClick={closeDialog} className={'mr-auto'} />
      </Dialog.Footer>

      {movementMovementInfoDialog}
      {writeOffMovementDialog}
      {postingMovementDialog}
      {confirmPopup}
    </DialogWithClickOutside>
  )
}

const getProductsForEditableTable = (
  inventory: ServerInventoryByIdType,
): InventoryProductDtoWithCalcQuantity[] => {
  return inventory.inventoryProducts.map(({ product, actualQuantity, calculatedQuantity }) => ({
    id: product.id,
    vendorCode: product.vendorCode,
    actualQuantity: convertUnitValueFromServer(actualQuantity, product.unit).toString(),
    name: product.name,
    price: product.price,
    unit: product.unit,
    costPrice: product.costPrice,
    calculatedQuantity: convertUnitValueFromServer(calculatedQuantity, product.unit),
  }))
}

const transformProductsForWriteOffDialog = (
  inventory: ServerInventoryByIdType | undefined,
): ProductDto[] => {
  if (!inventory) return []

  const productsForWriteOff = inventory.inventoryProducts.filter(
    iP => iP.calculatedQuantity - iP.actualQuantity > 0,
  )

  if (inventory.movements.length) {
    const restProductsForWriteOff: ProductDto[] = []

    const movementProducts = inventory.movements
      .map(({ movementProducts }) => movementProducts)
      .flat()

    for (const product of productsForWriteOff) {
      const quantity = movementProducts
        .filter(pr => pr.productId === product.product.id)
        .reduce((acc, value) => acc + value.quantity, 0)

      restProductsForWriteOff.push({
        id: product.product.id,
        name: product.product.name,
        quantity: convertUnitValueFromServer(
          product.calculatedQuantity - product.actualQuantity - quantity,
          product.product.unit,
        ),
        costPrice: product.price,
        unit: product.product.unit,
      })
    }

    return restProductsForWriteOff.filter(product => product.quantity > 0)
  } else
    return (
      productsForWriteOff?.map(product => ({
        id: product.product.id,
        name: product.product.name,
        quantity: convertUnitValueFromServer(
          product.calculatedQuantity - product.actualQuantity,
          product.product.unit,
        ),
        costPrice: product.price,
        unit: product.product.unit,
      })) ?? []
    )
}

const transformProductsForPostingDialog = (
  inventory: ServerInventoryByIdType | undefined,
): ProductDto[] => {
  if (!inventory) return []

  const productsForPosting = inventory.inventoryProducts.filter(
    iP => iP.actualQuantity - iP.calculatedQuantity > 0,
  )

  if (inventory.movements.length) {
    const restProductsForPosting: ProductDto[] = []

    const movementProducts = inventory.movements
      .map(({ movementProducts }) => movementProducts)
      .flat()

    for (const product of productsForPosting) {
      const quantity = movementProducts
        .filter(pr => pr.productId === product.product.id)
        .reduce((acc, value) => acc + value.quantity, 0)

      restProductsForPosting.push({
        id: product.product.id,
        name: product.product.name,
        quantity: convertUnitValueFromServer(
          product.actualQuantity - product.calculatedQuantity - quantity,
          product.product.unit,
        ),
        costPrice: product.price,
        unit: product.product.unit,
      })
    }

    return restProductsForPosting.filter(product => product.quantity > 0)
  } else
    return (
      productsForPosting?.map(product => ({
        id: product.product.id,
        name: product.product.name,
        quantity: convertUnitValueFromServer(
          product.actualQuantity - product.calculatedQuantity,
          product.product.unit,
        ),
        costPrice: product.price,
        unit: product.product.unit,
      })) ?? []
    )
}

type InventoryProductDtoWithCalcQuantity = InventoryProductDto & {
  calculatedQuantity: number
}

export interface InventoryFieldValues {
  products: InventoryProductDtoWithCalcQuantity[]
}

const transformFormDataToInventoryProductInput = (
  inventoryId: number | undefined,
  data: InventoryFieldValues,
): InventoryProductInput[] => {
  const products: InventoryProductInput[] = []
  for (const product of data.products) {
    if (product.price && product.id && inventoryId && product.unit)
      products.push({
        price: product.price,
        productId: product.id,
        inventoryId,
        actualQuantity: convertUnitValueToServer(Number(product.actualQuantity), product.unit),
        calculatedQuantity: convertUnitValueToServer(product.calculatedQuantity, product.unit),
      })
  }

  return products
}
