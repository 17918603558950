import { useFetchArchivedSchedules } from '@expane/data'
import { Page } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { SchedulesPageList } from 'pages/SchedulesPage/ScheduleTemplateSettings/List'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ListNavigationButton } from 'widgets/NavigationButtons'
import { useOpenScheduleDialog } from 'widgets/ScheduleDialog'
import { store } from 'store'

export const ArchiveSchedulesPage: FC = observer(() => {
  const branchId = store.branch.branchId

  const { t } = useTranslation()
  const { data, isLoading } = useFetchArchivedSchedules()

  const { openEditDialog, dialog } = useOpenScheduleDialog()

  return (
    <Page>
      <ListNavigationButton title={t('schedule.scheduleTemplates')} className="mb-2" />

      <SchedulesPageList
        onRowClick={openEditDialog}
        schedules={data}
        branchId={branchId}
        isLoading={isLoading}
        archive
      />

      {dialog}
    </Page>
  )
})
