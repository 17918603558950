import {
  ErrorMessage,
  ErrorMessageType,
  getCommonInputStyles,
  InputLabel,
  modalsHTMLElement,
  usePopupOpenState,
} from '@expane/ui'
import { useFloating } from '@floating-ui/react-dom'
import { TEMPLATE_STRINGS } from 'logic/messageTemplate'
import { FC, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoAddSharp } from 'react-icons/io5'
import { DropdownMenu } from './DropdownMenu'

export interface MessageTemplateInputProps {
  value?: string
  onChange: (value: string) => void
  placeholder: string
  label?: string
  hint?: string
  containerClassName?: string
  errorMessage?: ErrorMessageType
  rows?: number
  disabled?: boolean
  required?: boolean
  // для storybook
  customModal?: HTMLElement | null
}
export const MessageTemplateInput: FC<MessageTemplateInputProps> = ({
  label,
  hint,
  value,
  onChange,
  placeholder,
  containerClassName = '',
  errorMessage,
  rows = 4,
  disabled,
  customModal,
}) => {
  const { t } = useTranslation()
  const { isOpen, closePopup, openPopup } = usePopupOpenState()
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
  })

  const textAreaStyles =
    getCommonInputStyles(errorMessage?.isShown, disabled) +
    'block w-full grow px-3 text-sm border-2 rounded-lg focus:ring-0 transition-all resize-none pr-10 '

  let buttonStyles = `
  absolute top-2 right-2 p-1
  rounded-md border-2 font-medium 
  focus:outline-none ring-btn transition-all inline-flex 
  items-center justify-center shadow-sm border-transparent text-btn-primary `

  if (disabled) {
    buttonStyles += 'bg-btn-disabled-primary cursor-default '
  } else {
    buttonStyles += 'bg-btn-primary focus:ring-primary-400 '
  }

  const menuItems = TEMPLATE_STRINGS.map(el => ({
    name: `${el.template} - ${t(el.description)}`,
    onClick: () => {
      onChange(value + ` ${el.template}`)
      textareaRef.current?.focus()
    },
  }))

  return (
    <div className={'flex flex-col ' + containerClassName}>
      {label && <InputLabel label={label} hint={hint} />}
      <div className="relative">
        <textarea
          onKeyDown={event => event.stopPropagation()}
          className={textAreaStyles}
          rows={rows}
          placeholder={placeholder}
          disabled={disabled}
          onChange={e => onChange(e.target.value)}
          value={value}
          ref={textareaRef}
        />

        <button className={buttonStyles} onClick={() => openPopup()} ref={reference}>
          <IoAddSharp size="1.2rem" />
        </button>

        {isOpen &&
          createPortal(
            <>
              <div className="fixed inset-0" onClick={closePopup} />
              <div
                ref={floating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
              >
                <DropdownMenu
                  menu={menuItems}
                  close={closePopup}
                  className="min-w-max left-0"
                  underlineLast={false}
                  withoutBackground
                />
              </div>
            </>,
            customModal ?? modalsHTMLElement,
          )}
      </div>

      <ErrorMessage errorMessage={errorMessage} />
    </div>
  )
}
