import {
  invalidateBookings,
  ServerReceiptStatus,
  ServerReceiptWithStatus,
  useCheckboxCreateBulkReturnReceipts,
  useCreateTransactionsFromAccount,
  useCreateTransactionsRefundBySubscription,
  useFetchAccounts,
  useFetchBookingById,
  useFetchCurrentBranchTimezone,
  useGetBranchDefaultAccountId,
} from '@expane/data'
import { getIsSomeServicesPaid } from '@expane/logic/booking'
import { prepareDtoForFullRefund } from '@expane/logic/cancelPayment'
import {
  handleUpdateCreateBulkReceiptsResponse,
  transformRefundByCashForCheckboxBulkReceipts,
} from '@expane/logic/payment/checkbox'
import { usePopupOpenState } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { generateUUIDv4 } from 'logic/utils'
import { JSX, useCallback, useEffect, useRef } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/sentry'
import { store } from 'store'
import {
  BookingCancelDialogForm,
  BookingCancelPaymentDialog,
} from 'widgets/BookingCancelPaymentDialog'

type HookProps = {
  bookingId: number
}

export const useCancelPaymentBookingPopup = ({ bookingId }: HookProps) => {
  const onCloseDialog = useRef<() => void>()
  const { isOpen, openPopup, closePopup } = usePopupOpenState()
  const [openSnackBar] = useSnackbar()
  const { t } = useTranslation()

  const closeCancelPaymentBooking = useCallback(() => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }, [closePopup])

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: bookingById } = useFetchBookingById(bookingId, timezone, branchId)
  const { data: accounts } = useFetchAccounts(branchId)
  const { mutateAsync: refundByCashBookingTransactions } = useCreateTransactionsFromAccount()
  const { mutateAsync: refundBySubscriptionBookingTransactions } =
    useCreateTransactionsRefundBySubscription()
  const { mutateAsync: createBulkReturnReceipts } = useCheckboxCreateBulkReturnReceipts()

  const { data: defaultAccountId } = useGetBranchDefaultAccountId(branchId)

  const { control, handleSubmit, setValue, formState } = useForm<BookingCancelDialogForm>({
    defaultValues: {
      accountId: defaultAccountId,
      refundToClientAccount: false,
      returnConsumables: true,
    },
  })

  const openCancelPaymentBookingPopup = (onClose?: () => void) => {
    onCloseDialog.current = onClose
    openPopup()
  }

  const onConfirm: SubmitHandler<BookingCancelDialogForm> = async data => {
    if (!branchId) {
      reportError('branchId is required for payment refund', 'error')
      throw new Error('branchId is required for payment refund')
    }
    if (!bookingById || bookingById.isGroupBooking) {
      // Такої ситуації не повинно бути
      closeCancelPaymentBooking()
      return
    }

    const { refundByCash, refundBySubscription } = prepareDtoForFullRefund({
      transactions: bookingById?.transactions,
      bookingId: bookingId,
      fromAccountId: data.accountId,
      refundToClientAccount: data.refundToClientAccount,
      returnConsumables: data.returnConsumables,
      branchId,
    })

    if (refundByCash.length > 0) {
      const dataForCheckboxReturnBulkReceipts = transformRefundByCashForCheckboxBulkReceipts({
        clientId: bookingById.client?.id,
        fromAccountId: data.accountId,
        accounts: accounts ?? [],
        refundByCash,
        transactions: bookingById.transactions,
        generateUUIDv4,
      })

      let receipts: ServerReceiptWithStatus[] | null | undefined = null
      if (
        dataForCheckboxReturnBulkReceipts &&
        dataForCheckboxReturnBulkReceipts.refundByCashWithReceiptId.length > 0 &&
        dataForCheckboxReturnBulkReceipts.checkboxBulkReturnReceipts.receipts.length > 0
      ) {
        const result = await createBulkReturnReceipts(
          dataForCheckboxReturnBulkReceipts.checkboxBulkReturnReceipts,
        )

        receipts = handleUpdateCreateBulkReceiptsResponse({
          t,
          onError: message => openSnackBar(message, 'error', 3000),
          createBulkReceiptsResult: result.checkboxCreateBulkReceipts,
        })

        if (!receipts) {
          closeCancelPaymentBooking()
          return
        }

        // Додатково фільтруємо тільки ті транзакції в яких чеки пройшли
        const filteredTransactionsFromAccount =
          dataForCheckboxReturnBulkReceipts.refundByCashWithReceiptId.filter(transaction =>
            receipts?.some(
              ({ receiptId, status }) =>
                receiptId === transaction.transactionReceipts?.data[0].receiptId &&
                status === ServerReceiptStatus.Done,
            ),
          )

        if (!filteredTransactionsFromAccount.length) {
          openSnackBar(t('submitError'), 'error')
          closeCancelPaymentBooking()
          return
        }

        if (
          filteredTransactionsFromAccount.length !==
          dataForCheckboxReturnBulkReceipts.refundByCashWithReceiptId.length
        )
          openSnackBar(t('softwarePOS.bulkReceiptsError'), 'error')

        await refundByCashBookingTransactions(
          {
            transactionsFromAccount: filteredTransactionsFromAccount,
          },
          {
            onSuccess: () =>
              invalidateBookings({ id: bookingId, date: bookingById?.startDate, branchId }),
          },
        )
      } else {
        await refundByCashBookingTransactions(
          { transactionsFromAccount: refundByCash },
          {
            onSuccess: () =>
              invalidateBookings({ id: bookingId, date: bookingById?.startDate, branchId }),
          },
        )
      }
    }
    if (refundBySubscription.length > 0) {
      await refundBySubscriptionBookingTransactions(
        {
          transactionRefundBySubscription: refundBySubscription,
        },
        {
          onSuccess: () =>
            invalidateBookings({ id: bookingId, date: bookingById?.startDate, branchId }),
        },
      )
    }
    closeCancelPaymentBooking()
  }

  useEffect(() => {
    // При изменении транзакций записи проверяем есть ли оплата за какую-нибудь услугу, если нет - это значит, что все оплаты отменены.
    if (isOpen && bookingById?.isGroupBooking === false) {
      const isSomeServicesPaid = getIsSomeServicesPaid(
        bookingById.bookingServices.map(({ service }) => ({
          id: service.id,
        })),
        bookingById.transactions,
      )
      if (!isSomeServicesPaid) closeCancelPaymentBooking()
    }
  }, [bookingById, bookingById?.transactions, closeCancelPaymentBooking, isOpen])

  let cancelPaymentBookingPopup: JSX.Element | null = null

  if (isOpen)
    cancelPaymentBookingPopup = (
      <BookingCancelPaymentDialog
        isSubmitting={formState.isSubmitting}
        transactions={bookingById?.transactions ?? []}
        control={control}
        onConfirm={onConfirm}
        setValue={setValue}
        handleSubmit={handleSubmit}
        closeCancelPaymentBooking={closeCancelPaymentBooking}
      />
    )

  return {
    cancelPaymentBookingPopup,
    openCancelPaymentBookingPopup,
  }
}
