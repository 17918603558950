import { request, useQuery, reportError, reportGqlError } from '../../api'
import { gql } from 'graphql-request'
import {
  ServerLastSalarySettingType,
  ServerSalaryRateSettingType,
  ServerSalarySettingType,
} from '../../generated/graphql-types'
import {
  lastSalarySettingFragment,
  salaryRateSettingFragment,
  salarySettingFragment,
} from './salarySetting.fragments'
import {
  COUNT_SALARY_SETTINGS_QUERY_KEY,
  LAST_SALARY_SETTINGS_QUERY_KEY,
  SALARY_RATE_SETTINGS_QUERY_KEY,
  SALARY_SETTINGS_QUERY_KEY,
} from './queryKeys'
import { parseDatesInSalarySettingGqlResponse } from './logic'
import { DEFAULT_TIMEZONE } from '@expane/date'

export const useFetchSalarySettingsById = (id: number | undefined, timezone: string | undefined) =>
  useQuery<ServerSalarySettingType | undefined>(
    [SALARY_SETTINGS_QUERY_KEY, { id }],
    async () => {
      const result = await request<{ salarySettingById: ServerSalarySettingType }>(
        gql`
          ${salarySettingFragment}
          query ($id: Int!) {
            salarySettingById(id: $id) {
              ...salarySettingType
            }
          }
        `,
        {
          id,
        },
      )

      if (result?.salarySettingById) {
        return parseDatesInSalarySettingGqlResponse(
          result.salarySettingById,
          timezone ?? DEFAULT_TIMEZONE,
        )
      } else {
        reportError(new Error('error in useFetchSalarySettingById'), 'warning', {
          result,
        })
        return undefined
      }
    },
    {
      queryName: 'useFetchSalarySettingById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone),
    },
  )

export const useFetchSalarySettingsByEmployeeId = (
  employeeId: number | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) =>
  useQuery<ServerSalarySettingType[] | undefined>(
    [SALARY_SETTINGS_QUERY_KEY, { employeeId, branchId }],
    async () => {
      const result = await request<{ salarySettings: ServerSalarySettingType }>(
        gql`
          ${salarySettingFragment}
          query ($employeeId: Int!, $branchId: Int!) {
            salarySettings(
              order_by: { start: desc }
              where: { employeeId: { _eq: $employeeId }, branchId: { _eq: $branchId } }
            ) {
              ...salarySettingType
            }
          }
        `,
        { employeeId, branchId },
      )

      if (Array.isArray(result?.salarySettings)) {
        return result.salarySettings.map(settings =>
          parseDatesInSalarySettingGqlResponse(settings, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('error in useFetchSalarySettingByEmployeeId'), 'warning', {
          result,
        })
        return undefined
      }
    },
    {
      queryName: 'useFetchSalarySettingsByEmployeeId',
      onError: reportGqlError,
      enabled: Boolean(employeeId) && Boolean(timezone) && Boolean(branchId),
    },
  )

export const useFetchLastSalarySettingsByEmployeeId = (
  employeeId: number | undefined,
  branchId: number | undefined,
) =>
  useQuery<ServerLastSalarySettingType[] | undefined>(
    [SALARY_SETTINGS_QUERY_KEY, LAST_SALARY_SETTINGS_QUERY_KEY, { employeeId, branchId }],
    async () => {
      const result = await request<{ salarySettings: ServerLastSalarySettingType[] }>(
        gql`
          ${lastSalarySettingFragment}
          query ($employeeId: Int!, $branchId: Int!) {
            salarySettings(
              order_by: { start: desc }
              where: {
                employeeId: { _eq: $employeeId }
                end: { _is_null: true }
                branchId: { _eq: $branchId }
              }
            ) {
              ...lastSalarySettingType
            }
          }
        `,
        { employeeId, branchId },
      )

      if (Array.isArray(result?.salarySettings)) {
        // Error: Query data cannot be undefined - потому возвращаем либо пустой массив, либо масив с одним елементом
        return result.salarySettings
      } else {
        reportError(new Error('error in useFetchLastSalarySettingsByEmployeeId'), 'warning', {
          result,
        })
        return undefined
      }
    },
    {
      queryName: 'useFetchLastSalarySettingsByEmployeeId',
      onError: reportGqlError,
      enabled: Boolean(employeeId) && Boolean(branchId),
    },
  )

export const useFetchSalarySettings = (
  timezone: string | undefined,
  branchId: number | undefined,
) =>
  useQuery<ServerSalarySettingType[] | undefined>(
    [SALARY_SETTINGS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request<{ salarySettings: ServerSalarySettingType }>(
        gql`
          ${salarySettingFragment}
          query ($branchId: Int!) {
            # Когда end=null то значит это последняя настроена ЗП,
            # так как при добавлении новой настройки, запускается тригер для проставления end
            salarySettings(
              order_by: { start: desc }
              where: { end: { _is_null: true }, branchId: { _eq: $branchId } }
            ) {
              ...salarySettingType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.salarySettings)) {
        return result.salarySettings.map(settings =>
          parseDatesInSalarySettingGqlResponse(settings, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('error in useFetchSalarySettings'), 'warning', {
          result,
        })
        return undefined
      }
    },
    {
      queryName: 'useFetchSalarySettings',
      enabled: Boolean(timezone) && Boolean(branchId),
      onError: reportGqlError,
    },
  )

export const useFetchSalaryRateSettings = (
  timezone: string | undefined,
  branchId: number | undefined,
) =>
  useQuery<ServerSalaryRateSettingType[] | undefined>(
    [SALARY_RATE_SETTINGS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request<{ salarySettings: ServerSalarySettingType }>(
        gql`
          ${salaryRateSettingFragment}
          query ($branchId: Int!) {
            salarySettings(where: { branchId: { _eq: $branchId } }, order_by: { start: desc }) {
              ...salaryRateSettingType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.salarySettings)) {
        return result.salarySettings.map(settings =>
          parseDatesInSalarySettingGqlResponse(settings, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('error in useFetchSalaryRateSettings'), 'warning', {
          result,
        })
        return undefined
      }
    },
    {
      queryName: 'useFetchSalaryRateSettings',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )

export const useFetchCountSalarySettingsByEmployeeId = (
  employeeId: number | undefined,
  branchId: number | undefined,
) =>
  useQuery<number | undefined>(
    [SALARY_SETTINGS_QUERY_KEY, COUNT_SALARY_SETTINGS_QUERY_KEY, { employeeId, branchId }],
    async () => {
      const result = await request<{ salarySettingsAggregate: { aggregate: { count: number } } }>(
        gql`
          query ($employeeId: Int!, $branchId: Int!) {
            salarySettingsAggregate(
              where: { employeeId: { _eq: $employeeId }, branchId: { _eq: $branchId } }
            ) {
              aggregate {
                count
              }
            }
          }
        `,
        { employeeId, branchId },
      )

      return result?.salarySettingsAggregate?.aggregate?.count
    },
    {
      queryName: 'useFetchSalarySettingsByEmployeeId',
      onError: reportGqlError,
      enabled: Boolean(employeeId) && Boolean(branchId),
    },
  )
