import { HorizontalButtonSwitch } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoLocationSharp, IoPeopleSharp } from 'react-icons/io5'
import { BookingCalendarType } from '.'

interface Props {
  calendarType: BookingCalendarType
  setCalendarType: (type: BookingCalendarType) => void
}
export const CalendarTypeSelect: FC<Props> = ({ setCalendarType, calendarType }) => {
  const { t } = useTranslation()

  const calendarOptions = [
    {
      id: 'employees',
      name: t('employees.name'),
      Icon: IoPeopleSharp,
    },
    {
      id: 'locations',
      name: t('locations.name'),
      Icon: IoLocationSharp,
    },
  ]

  return (
    <HorizontalButtonSwitch
      containerClassName="mr-2 mt-2"
      value={calendarType}
      onChange={setCalendarType as (id: string) => void}
      options={calendarOptions}
    />
  )
}
