import { FC } from 'react'
import { CashBoxInfoItem } from 'pages/FinancesPage/FinancesInfoBlock'
import { ServerAccountType, ServerTransactionType } from '@expane/data'
import { calcAllRevenueOnAccount } from 'logic/finances/calculation'
import { useTranslation } from 'react-i18next'
import { PropsWithBranchId } from '@expane/logic/branch'

interface RevenueValuesProps {
  accounts?: ServerAccountType[]
  transactions: ServerTransactionType[]
  revenueWithoutDepositsValue: number
  depositsValue: number
}

export const RevenueValues: FC<PropsWithBranchId<RevenueValuesProps>> = ({
  accounts,
  transactions,
  revenueWithoutDepositsValue,
  depositsValue,
  branchId,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ul className={listStyle}>
        {depositsValue !== 0 ? (
          <CashBoxInfoItem
            title={t('addedToClientsAccounts')}
            value={depositsValue}
            className={additionalStyle}
            branchId={branchId}
          />
        ) : null}

        {revenueWithoutDepositsValue !== 0 ? (
          <CashBoxInfoItem
            title={t('otherRevenues')}
            value={revenueWithoutDepositsValue}
            className={additionalStyle}
            branchId={branchId}
          />
        ) : null}
      </ul>

      <ul className={listStyle}>
        {depositsValue !== 0 || revenueWithoutDepositsValue !== 0 ? (
          <li className="text-primary-600 font-medium my-2">{t('accounts')}</li>
        ) : null}

        {accounts?.map(account => (
          <RevenueValuesOfDayByAccount
            key={account.id}
            id={account.id}
            name={account.name}
            transactions={transactions}
            branchId={branchId}
          />
        ))}
      </ul>
    </>
  )
}

interface CashBoxAccountsTotalRevenueValuesOfDayProps {
  id: number
  name: string
  transactions: ServerTransactionType[]
}

const RevenueValuesOfDayByAccount: FC<
  PropsWithBranchId<CashBoxAccountsTotalRevenueValuesOfDayProps>
> = ({ id, name, transactions, branchId }) => {
  const value = calcAllRevenueOnAccount(id, transactions)

  if (value === 0) return null

  return (
    <CashBoxInfoItem title={name} value={value} className="text-main-color" branchId={branchId} />
  )
}

const listStyle = 'text-sm ml-2 mb-2'
const additionalStyle = 'text-primary-500'
