import { HistoryBookingUnion } from '@expane/data'
import { getServiceInfoByTransaction } from '@expane/logic/booking'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { transformPersonName } from '@expane/logic/utils'
import { EmptyPlaceholder, Table, useShowWarningPopup } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkOutline, IoListOutline } from 'react-icons/io5'
import { useOpenTransactionsDialog } from 'widgets/TransactionsDialog'
import { BookingInfoHint, BookingStatusIcons } from './BookingHistoryListItemIcons'
import { getBookingHistoryWithoutReferralTransactions } from './logic'
import { BookingServiceCellContent } from 'widgets/BookingServiceListCell'
import { InformationPopup } from 'widgets/InformationPopup'

interface BookingsHistoryProps {
  history: HistoryBookingUnion[]
  isRefundAllowed: boolean
}

export const BookingsHistory: FC<BookingsHistoryProps> = ({ history, isRefundAllowed }) => {
  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()
  const { openTransactionsDialog, transactionsDialog } = useOpenTransactionsDialog()
  const { warningModal, showWarningPopup: openPopup } = useShowWarningPopup(
    t('booking.name'),
    t('booking.noPayment'),
  )

  const { getModuleSetting } = useBusinessModulesSettings()
  const isSubscriptionsEnabled = getModuleSetting('subscriptions')

  const columns = useMemo<ColumnDef<HistoryBookingUnion>[]>(() => {
    const subscriptionCellSize = 90

    const subscriptionCell: ColumnDef<HistoryBookingUnion> = {
      id: 'isBySubscription',
      header: t('subscription.name'),
      cell: data => {
        const booking = data.row.original
        if (booking.isGroupBooking) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return getServiceInfoByTransaction(booking.service!.id, booking.transactions)
            .isServicePaidByCard ? (
            <IoCheckmarkOutline size="1.2rem" className="text-primary-500" />
          ) : (
            <p>-</p>
          )
        } else {
          return (
            <div>
              {booking.bookingServices.map(bookingService =>
                getServiceInfoByTransaction(bookingService.service.id, booking.transactions)
                  .isServicePaidByCard ? (
                  <IoCheckmarkOutline
                    key={bookingService.service.id}
                    size="1.2rem"
                    className="text-primary-500"
                  />
                ) : (
                  <p key={bookingService.service.id}>-</p>
                ),
              )}
            </div>
          )
        }
      },
      size: subscriptionCellSize,
    }

    const statusCell: ColumnDef<HistoryBookingUnion> = {
      id: 'status',
      header: t('status'),
      cell: data => {
        const booking = data.row.original
        if (booking.isGroupBooking) {
          return (
            <BookingStatusIcons
              isBookingPaid={booking.isPaid ?? false}
              isCanceled={Boolean(booking.canceledDate)}
              isReturned={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                getServiceInfoByTransaction(booking.service!.id, booking.transactions)
                  .isServiceReturned
              }
            />
          )
        }
        return (
          <div>
            {booking.bookingServices.map(bookingService => (
              <BookingStatusIcons
                key={bookingService.service.id}
                isBookingPaid={booking.isPaid ?? false}
                isCanceled={Boolean(booking.canceledDate)}
                isReturned={
                  getServiceInfoByTransaction(bookingService.service.id, booking.transactions)
                    .isServiceReturned
                }
              />
            ))}
          </div>
        )
      },
      size: 60,
    }

    return [
      {
        id: 'infoHint',
        cell: data => {
          const booking = data.row.original
          const authorName = booking.author ? transformPersonName(booking.author) : '-'

          return (
            <BookingInfoHint
              authorName={authorName}
              createdAt={dateFormatting('historyDateTime', booking.createdAt)}
            />
          )
        },
        size: 40,
      },
      {
        accessorKey: 'startDate',
        header: t('bookingsDate'),
        cell: data => (
          <span className="line-clamp-1">
            {dateFormatting('historyDateTime', data.getValue<Date>())}
          </span>
        ),
      },
      {
        id: 'services',
        header: t('services'),
        cell: data => {
          const booking = data.row.original

          return <BookingServiceCellContent booking={booking} />
        },
        size: 240,
      },
      {
        id: 'employeeName',
        accessorFn: booking => (booking.employee ? transformPersonName(booking.employee) : '-'),
        header: t('employee.name'),
        cell: data => <span className="line-clamp-1 h-full">{data.getValue<string>()}</span>,
        size: 240,
      },
      {
        id: 'comment',
        header: t('comment.title'),
        cell: data => {
          const employeeNote = data.row.original.note

          const note = data.row.original.canceledDate
            ? data.row.original.canceledReason
            : employeeNote

          const clientNote = data.row.original.clientNote

          return (
            <div>
              <div className={'flex justify-between'}>
                <p>{note}</p>
                {employeeNote ? (
                  <InformationPopup size="1.2rem">
                    {<p>{t('comment.employee')}</p>}
                  </InformationPopup>
                ) : null}
              </div>

              {clientNote ? (
                <div className={'flex justify-between'}>
                  <p>{clientNote}</p>

                  {employeeNote ? (
                    <InformationPopup size="1.2rem">
                      {<p>{t('comment.client')}</p>}
                    </InformationPopup>
                  ) : null}
                </div>
              ) : null}
            </div>
          )
        },
        size: isSubscriptionsEnabled ? 176 : 176 + subscriptionCellSize,
      },
      ...(isSubscriptionsEnabled ? [subscriptionCell, statusCell] : [statusCell]),
    ]
  }, [dateFormatting, isSubscriptionsEnabled, t])

  return history.length > 0 ? (
    <>
      <Table
        columns={columns}
        data={history}
        customCellContainer
        onRowClick={booking => {
          if (!isRefundAllowed) return

          if (booking.isPaid)
            openTransactionsDialog(
              getBookingHistoryWithoutReferralTransactions(booking.transactions),
            )
          else openPopup()
        }}
      />
      {transactionsDialog}
      {warningModal}
    </>
  ) : (
    <EmptyPlaceholder Icon={IoListOutline} text={t('noBookings')} />
  )
}
