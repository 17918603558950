import {
  useFetchAccounts,
  useFetchCurrentBranchTimezone,
  useFetchTransactionById,
  useFetchTransactionsByParentId,
  useGetBranchDefaultAccountId,
} from '@expane/data'
import { usePopupOpenState } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { store } from 'store'
import { TransactionRefundDialogLogic } from 'widgets/TransactionDialog/RefundDialog/TransactionRefundDialogLogic'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'

interface Props {
  onSuccess: () => void
  transactionId: number
  closeDialog: () => void
}

const TransactionRefundDialog: FC<Props> = observer(({ transactionId, onSuccess, closeDialog }) => {
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: transaction, isLoading: isTransactionLoading } = useFetchTransactionById(
    transactionId,
    timezone,
    branchId,
  )

  const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)
  const { data: defaultAccountId, isLoading: isLoadingDefaultAccountId } =
    useGetBranchDefaultAccountId(branchId)
  const { data: refundsForTransaction, isLoading: isLoadingRefundsForTransaction } =
    useFetchTransactionsByParentId(transaction?.id, timezone, branchId)

  if (
    isLoadingAccounts ||
    isLoadingDefaultAccountId ||
    isLoadingRefundsForTransaction ||
    isTransactionLoading
  )
    return null

  if (!defaultAccountId || !transaction || !refundsForTransaction || !accounts) {
    useErrorOpeningDialog(true, closeDialog)
    return null
  }

  return (
    <TransactionRefundDialogLogic
      defaultAccountId={defaultAccountId}
      transaction={transaction}
      refundsForTransaction={refundsForTransaction}
      closeDialog={closeDialog}
      onSuccess={onSuccess}
      accounts={accounts}
      branchId={branchId}
    />
  )
})

interface OpenTransactionRefundDialogProps {
  transactionId: number
  onSuccess: () => void
}

export const useOpenTransactionRefundDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()
  const props = useRef<OpenTransactionRefundDialogProps | null>(null)

  const openTransactionRefundDialog = ({
    transactionId,
    onClose,
    onSuccess,
  }: OpenTransactionRefundDialogProps & {
    onClose?: () => void
  }) => {
    props.current = { transactionId, onSuccess }
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const transactionRefundDialog =
    isOpen && props.current ? (
      <TransactionRefundDialog closeDialog={closeDialog} {...props.current} />
    ) : null

  return { openTransactionRefundDialog, transactionRefundDialog }
}
