import { useFetchCurrentBranchTimezone, useFetchGiftCards } from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { Page } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { CardsList } from 'pages/CardsPage/List'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'
import { GiftCardDialog } from 'widgets/GiftCardDialog'
import { ArchiveListNavigationButton } from 'widgets/NavigationButtons'

export const GiftCardsPage = observer(() => {
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: cards, isLoading } = useFetchGiftCards(timezone, branchId)

  const { data: myPermissions } = useFetchMyPermissions()
  const { openCreateDialog, openEditDialog, dialog } = useOpenDialog(GiftCardDialog)

  const isEditingAllowed = myPermissions?.includes(permissions.card.set)

  if (!cards && !isLoading) return null

  return (
    <Page>
      {isEditingAllowed && (
        <div className="flex mb-2">
          <SaveButton onClick={() => openCreateDialog()} className="self-start" isCreate />

          <ArchiveListNavigationButton className="ml-auto" />
        </div>
      )}

      <CardsList
        onRowClick={openEditDialog}
        cards={cards}
        isLoading={isLoading}
        branchId={branchId}
      />
      {dialog}
    </Page>
  )
})
