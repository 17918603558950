import {
  BranchWithSchedule,
  EmployeeGroupWithSchedule,
  ServerStorageType,
  useCreateStorage,
  useFetchBranchById,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchStorageById,
  useUpdateBranchDefaults,
  useUpdateStorage,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import {
  Button,
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  Modal,
  PlaceholderInput,
  PlaceholderTextarea,
  SelectDropDownItem,
  Tag,
  Textarea,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { MultiSelect } from 'ui/MultiSelect'
import { SaveButton } from 'widgets/Buttons'

export const StorageDialog: FC<DialogProps> = observer(
  ({ closeDialog, id: storageId, isCreate }) => {
    const branchId = store.branch.branchId

    const timezone = useFetchCurrentBranchTimezone(branchId)

    const { data: storageById, isLoading: isLoadingStorageById } = useFetchStorageById(storageId)
    const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } = useFetchEmployeesGroups(
      timezone,
      branchId,
    )
    const { data: branch, isLoading: isLoadingBranch } = useFetchBranchById(branchId)

    const isLoading =
      (!isCreate && isLoadingStorageById) || isLoadingEmployeeGroups || isLoadingBranch
    const isNoData = (!isCreate && !storageById) || !employeeGroups || !branch

    if (isLoading) return <StorageDialogLogicPlaceholder closeDialog={closeDialog} />
    else if (isNoData) return null

    return (
      <StorageDialogLogic
        isCreate={isCreate}
        storageById={storageById}
        employeeGroups={employeeGroups}
        closeDialog={closeDialog}
        branch={branch}
      />
    )
  },
)

const StorageDialogLogic: FC<StorageDialogLogicProps> = observer(
  ({ isCreate, storageById, employeeGroups, closeDialog, branch }) => {
    const { t } = useTranslation()

    const { data: myPermission } = useFetchMyPermissions()

    const { formState, control, handleSubmit } = useForm<StorageDialogFormValues>({
      defaultValues: {
        name: storageById?.name ?? '',
        employeeGroups:
          storageById?.storageEmployeeGroups?.map(({ employeeGroup }) => ({
            id: employeeGroup.id,
            name: employeeGroup.name,
          })) ?? [],
        description: storageById?.description ?? '',
      },
    })

    const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
    const [openSnackbar] = useSnackbar()

    const isDefaultStorage = !isCreate && branch.defaultStorage?.id === storageById?.id

    const { mutateAsync: createStorage } = useCreateStorage()
    const { mutateAsync: updateStorage } = useUpdateStorage()
    const { mutateAsync: updateBranchDefaults, isLoading: isLoadingOnSetAsDefault } =
      useUpdateBranchDefaults()

    const setStorageAsDefault = async () => {
      await updateBranchDefaults({
        branchId: branch.id,
        branchSetInput: {
          defaultStorageId: storageById?.id,
        },
      })
    }

    const isPermissionsEditingAllowed = myPermission?.includes(permissions.rolePermission.set)

    const mutateStorage: SubmitHandler<StorageDialogFormValues> = async ({
      name,
      description,
      employeeGroups,
    }) => {
      if (isCreate) {
        const result = await createStorage({
          name,
          description,
          storageEmployeeGroups: {
            data: isPermissionsEditingAllowed
              ? employeeGroups.map(g => ({ employeeGroupId: g.id }))
              : [],
          },
          branchId: branch.id,
        })
        if (result?.insertStorage?.id) openSnackbar(t('storage.createSuccess'), 'success', 3000)
        else openSnackbar(t('submitError'), 'error', 3000)
      } else if (storageById) {
        const result = await updateStorage({
          id: storageById.id,
          storageSetInput: { name, description },
          storageEmployeeGroupsInsertInput: isPermissionsEditingAllowed
            ? employeeGroups.map(g => ({
                storageId: storageById.id,
                employeeGroupId: g.id,
              }))
            : undefined,
        })
        if (result?.updateStorageById?.id) openSnackbar(t('storage.updateSuccess'), 'success', 3000)
        else openSnackbar(t('submitError'), 'error', 3000)
      }

      closeDialog()
    }

    const isEditingAllowed = myPermission?.includes(permissions.storage.set)
    const isBranchSettingsEditingAllowed = myPermission?.includes(permissions.branch.set)

    const myEmployeeGroup = employeeGroups.find(eG =>
      eG.employees.find(e => e.id === store.me.employeeId),
    )

    const watchedEmployeeGroups = useWatch({ control, name: 'employeeGroups' })

    const isSetDefaultStorageAllowed =
      isBranchSettingsEditingAllowed && watchedEmployeeGroups.length === 0

    return (
      <>
        <Modal
          close={closePopups}
          confirm={() => {
            if (isEditingAllowed && !formState.isSubmitting && formState.isDirty)
              handleSubmit(mutateStorage)()
          }}
          animation="onlyFadeOut"
        >
          <Dialog>
            <Dialog.Title>
              {t('storage.name')}{' '}
              {isDefaultStorage && <Tag name={t('defaultStorage')} color="primary-500" />}
            </Dialog.Title>

            <Dialog.Body className="w-172">
              <div className="flex">
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label={t('title')}
                      required
                      errorMessage={{
                        isShown: Boolean(formState.errors.name),
                        text: t('formError.required'),
                      }}
                      value={value}
                      onChange={onChange}
                      disabled={!isEditingAllowed}
                      containerClassName="w-1/2 mr-2"
                      autoFocus
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="employeeGroups"
                  rules={{
                    validate: values => {
                      if (values.length === 0) return true
                      return Boolean(values.find(group => group.id === myEmployeeGroup?.id))
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <MultiSelect
                      className="w-1/2"
                      items={employeeGroups ?? []}
                      onItemSelect={onChange}
                      selectedItems={value as SelectDropDownItem[]}
                      label={t('employeeGroups.name')}
                      dropdownInputPlaceholder=""
                      disabled={!isPermissionsEditingAllowed || !isEditingAllowed}
                      errorMessage={{
                        isShown: Boolean(formState.errors.employeeGroups),
                        text: `${t('addYourGroup')} (${myEmployeeGroup?.name ?? ''})`,
                      }}
                    />
                  )}
                />
              </div>

              <Controller
                control={control}
                name="description"
                render={({ field: { value, onChange } }) => (
                  <Textarea
                    label={t('description')}
                    value={value}
                    onChange={onChange}
                    placeholder={t('placeholders.storageDescription')}
                    disabled={!isEditingAllowed}
                  />
                )}
              />
            </Dialog.Body>
            <Dialog.Footer>
              {isEditingAllowed && (
                <SaveButton
                  onClick={handleSubmit(mutateStorage)}
                  disabled={formState.isSubmitting || !formState.isDirty || isLoadingOnSetAsDefault}
                  spinner={formState.isSubmitting}
                  isCreate={isCreate}
                />
              )}

              <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />
              {!isCreate && !isDefaultStorage && isSetDefaultStorageAllowed && (
                <Button
                  onClick={setStorageAsDefault}
                  type="outline"
                  className="mr-auto"
                  disabled={isLoadingOnSetAsDefault}
                  spinner={isLoadingOnSetAsDefault}
                >
                  {t('setAsDefaultStorage')}
                </Button>
              )}
            </Dialog.Footer>
          </Dialog>
        </Modal>
        {confirmPopup}
      </>
    )
  },
)

const StorageDialogLogicPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('storage.name')} </Dialog.Title>

        <Dialog.Body className="w-172">
          <div className="flex mb-4">
            <PlaceholderInput label={t('title')} className="w-1/2 mr-2" />
            <PlaceholderInput label={t('employeeGroups.name')} className="w-1/2" />
          </div>

          <PlaceholderTextarea label={t('description')} />
        </Dialog.Body>
        <Dialog.Footer>
          <SaveButton disabled />

          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

type StorageDialogFormValues = {
  name: string
  description?: string
  employeeGroups: SelectDropDownItem[]
}

interface StorageDialogLogicProps extends Omit<DialogProps, 'id'> {
  storageById: ServerStorageType | undefined
  employeeGroups: EmployeeGroupWithSchedule[]
  branch: BranchWithSchedule
}
