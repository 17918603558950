import { gql } from 'graphql-request'

export const promoCodeFragment = gql`
  fragment promoCodeType on promoCode {
    id
    createdAt
    description
    endAt
    term
    discount
    businessPromoCodes {
      businessId
    }
    referrer {
      id
      name
      email
    }
  }
`

// Повинно бути таким же як і promoCodeFragment бо використовуємо ServerPromoCodeType в запиті
export const promoCodeAsReferrerFragment = gql`
  fragment promoCodeAsReferrerType on promoCodeReferrerView {
    id
    createdAt
    description
    endAt
    term
    discount
    businessPromoCodes {
      businessId
    }
    referrer {
      id
      name
      email
    }
  }
`
