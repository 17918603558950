import {
  addDays,
  addMinutes,
  formatTimeFromDate,
  getTimeFromDate,
  setTimeForDateByFloat,
} from '@expane/date'
import {
  BookingUnion,
  ClientPhones,
  DayTiming,
  ExtendedEmployee,
  ServerLocationWithServiceIdsType,
} from '@expane/data'
import { REM_TO_PX_RATIO, TAILWIND_TO_REM_RATIO } from 'logic/ui'
import { transformPersonName } from '@expane/logic/utils'
import { DraggableBounds, DraggableData } from 'react-draggable'
import { BookingCalendarSettingsStore } from 'store/BookingCalendarSettings'
import { CardPositionDto } from '../logic'
import type { BookingCalendarType } from 'pages/BookingsPage'

const checkNewLocationCanHaveServices = (
  location: ServerLocationWithServiceIdsType,
  services: { id: number }[],
) => {
  return services.every(service =>
    location.serviceLocations.some(sL => sL.serviceId === service.id),
  )
}

const checkNewEmployeeCanHaveServices = (
  employee: ExtendedEmployee,
  services: { id: number }[],
) => {
  return services.every(service =>
    employee.serviceEmployees.some(sE => sE.service.id === service.id),
  )
}

export type MutateBookingDto = {
  newStartDate?: Date
  newEmployeeId?: number
  newLocationId?: number
}

export const getNewPositionDtoFromDrag = ({
  data,
  employeeIsSelected,
  columnIndex,
  cellHeight,
  axisXEnabled,
  columnWidthInPx,
  calendarType,
  locations,
  servicesInBooking,
  employees,
  bookingStartDate,
}: {
  data: DraggableData
  calendarType: BookingCalendarType
  columnIndex: number
  columnWidthInPx: number
  employeeIsSelected: boolean
  cellHeight: number
  axisXEnabled: boolean
  locations: ServerLocationWithServiceIdsType[]
  employees: ExtendedEmployee[]
  bookingStartDate: Date
  servicesInBooking: { id: number }[]
}) => {
  const dto: MutateBookingDto = {}
  const columnDiff = axisXEnabled ? data.lastX / columnWidthInPx : 0
  if (data.lastX && !employeeIsSelected) {
    const newColumnIndex = columnIndex + columnDiff
    if (newColumnIndex < 0) return {}

    if (calendarType === 'locations') {
      const newLocation = locations[newColumnIndex]

      if (!checkNewLocationCanHaveServices(newLocation, servicesInBooking)) {
        throw new Error('serviceError.wrongLocation')
      }

      dto.newLocationId = newLocation.id
    }
    if (calendarType === 'employees') {
      const newEmployee = employees[newColumnIndex]
      if (!checkNewEmployeeCanHaveServices(newEmployee, servicesInBooking)) {
        throw new Error('serviceError.wrongEmployee')
      }
      dto.newEmployeeId = newEmployee.id
    }
  }

  const newStartHour =
    data.lastY / (cellHeight * TAILWIND_TO_REM_RATIO * REM_TO_PX_RATIO) +
    getTimeFromDate(bookingStartDate)
  const newStartDate = setTimeForDateByFloat(newStartHour, bookingStartDate)
  if (employeeIsSelected) {
    const dateOfNewColumn = addDays(newStartDate, columnDiff)
    dto.newStartDate = dateOfNewColumn
  } else {
    dto.newStartDate = newStartDate
  }
  return dto
}

export const getPositionOfBookingCard = ({ indexInTimeFrame, countOfCards }: CardPositionDto) => {
  const widthInPercents = 100 / countOfCards
  const leftOffsetInPercents = indexInTimeFrame * widthInPercents

  return { widthInPercents, leftOffsetInPercents }
}

export const getDraggableBounds = (
  dayTiming: DayTiming,
  cellHeight: number,
  startDate: Date,
  duration: number,
): DraggableBounds => {
  const top =
    (dayTiming[0] - getTimeFromDate(startDate)) *
    (cellHeight * TAILWIND_TO_REM_RATIO * REM_TO_PX_RATIO)
  const bottom =
    (dayTiming[1] - getTimeFromDate(startDate) - duration / 60) *
    (cellHeight * TAILWIND_TO_REM_RATIO * REM_TO_PX_RATIO)

  return { top, bottom }
}

export const getBookingInfo = (
  booking: BookingUnion,
  settings: BookingCalendarSettingsStore,
  ageDeclension: (date: Date) => string,
  clientPhones?: ClientPhones,
): string => {
  const { startDate, duration } = booking
  const infoToDisplay: string[] = []

  const {
    isAgeShown,
    isFirstNameShown,
    isLastNameShown,
    isStartTimeShown,
    isEndTimeShown,
    isPhoneShown,
    isServiceShown,
  } = settings

  let timeInfo = ''
  if (isStartTimeShown && isEndTimeShown) {
    const endDate = addMinutes(startDate, duration)
    timeInfo = formatTimeFromDate(startDate) + ' - ' + formatTimeFromDate(endDate) + ' '
  } else if (isStartTimeShown) {
    timeInfo = formatTimeFromDate(startDate) + ' '
  } else if (isEndTimeShown) {
    const endDate = addMinutes(startDate, duration)
    timeInfo = formatTimeFromDate(endDate) + ' '
  }

  if (booking.isGroupBooking) {
    if (booking.service) infoToDisplay.push(booking.service.name)
    if (booking.employee) infoToDisplay.push(transformPersonName(booking.employee))
  }

  if (!booking.isGroupBooking) {
    // если запись не групповая - выводим информацию о клиенте
    if (booking.client)
      if (isFirstNameShown && isLastNameShown) {
        infoToDisplay.push(transformPersonName(booking.client))
      } else if (isFirstNameShown) {
        infoToDisplay.push(booking.client.firstName)
      } else if (isLastNameShown && booking.client?.lastName) {
        infoToDisplay.push(booking.client.lastName)
      }

    if (isAgeShown && booking.client?.birthDate) {
      const age = ageDeclension(booking.client.birthDate)

      if (age) infoToDisplay.push(age)
    }

    if (isPhoneShown && clientPhones?.phone) infoToDisplay.push(clientPhones.phone)

    if (isServiceShown)
      infoToDisplay.push(booking.bookingServices.map(({ service }) => service.name).join(','))
  }

  return timeInfo + infoToDisplay.join(', ')
}
