import {
  ClientByIdType,
  useFetchClientBookingHistory,
  useFetchClientNotificationByClientId,
  useFetchClientTransactions,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { HorizontalButtonSwitch, Spinner } from '@expane/ui'
import { translateData } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline, IoChatboxEllipsesOutline, IoWalletOutline } from 'react-icons/io5'
import { IconType } from 'react-icons/lib'
import { store } from 'store'
import { AdditionalInfo } from 'widgets/ClientDialog/HistoryTab/AdditionalInfo'
import { BookingsHistory } from 'widgets/ClientDialog/HistoryTab/BookingsHistory'
import { NotificationsHistory } from 'widgets/ClientDialog/HistoryTab/NotificationsHistory'
import { TransactionsHistory } from 'widgets/ClientDialog/HistoryTab/TransactionsHistory'

interface Props {
  client: ClientByIdType
  isRefundAllowed: boolean
  isClientMessagesAllowed: boolean
}

type SwitchTypes = 'bookings' | 'transactions' | 'messages'

export const ClientDialogHistoryTab: FC<Props> = observer(
  ({ client, isRefundAllowed, isClientMessagesAllowed }) => {
    const [currentSwitchTab, setCurrentSwitchTab] = useState<SwitchTypes>('bookings')

    const { t } = useTranslation()

    const branchId = store.branch.branchId

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { data: bookingsHistory, isLoading: isLoadingBookingsHistory } =
      useFetchClientBookingHistory(client.id, timezone, branchId)

    // TODO: make it endless
    // And because there wouldn't be all transactions on the client
    // Refactor current balance for transactions by decreasing value from useFetchClientBalance
    const { data: transactionsHistory, isLoading: isLoadingTransactionsHistory } =
      useFetchClientTransactions(client.id, timezone, branchId)
    const { data: clientMessagesHistory, isLoading: isLoadingClientMessagesHistory } =
      useFetchClientNotificationByClientId(client.id, timezone)

    const translatedSwitchOptions = translateData(switchOptions, t)

    const filteredSwitchOptions = isClientMessagesAllowed
      ? translatedSwitchOptions
      : translatedSwitchOptions.filter(s => s.id !== 'messages')

    if (isLoadingBookingsHistory || isLoadingTransactionsHistory || isLoadingClientMessagesHistory)
      return <Spinner expandCentered />

    if (!client || !bookingsHistory || !clientMessagesHistory || !transactionsHistory) return null

    return (
      <div className="flex flex-col h-full">
        <div className="mb-3 flex items-center justify-between">
          <AdditionalInfo
            history={bookingsHistory}
            transactions={transactionsHistory}
            branchId={branchId}
          />

          <HorizontalButtonSwitch
            options={filteredSwitchOptions}
            value={currentSwitchTab}
            onChange={id => setCurrentSwitchTab(id as SwitchTypes)}
          />
        </div>

        {currentSwitchTab === 'bookings' && (
          <BookingsHistory history={bookingsHistory} isRefundAllowed={isRefundAllowed} />
        )}
        {currentSwitchTab === 'transactions' && (
          <TransactionsHistory history={transactionsHistory} branchId={branchId} />
        )}
        {currentSwitchTab === 'messages' && (
          <NotificationsHistory history={clientMessagesHistory} />
        )}
      </div>
    )
  },
)

const switchOptions: { id: SwitchTypes; name: string; Icon: IconType }[] = [
  { id: 'bookings', name: 'bookings.title', Icon: IoCalendarOutline },
  { id: 'transactions', name: 'statementOfAccount', Icon: IoWalletOutline },
  { id: 'messages', name: 'notifications', Icon: IoChatboxEllipsesOutline },
]
