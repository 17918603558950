import { gql } from 'graphql-request'
import {
  LONG_STALE_TIME,
  reportError,
  reportGqlError,
  request,
  requestAnonymously,
  useQuery,
} from '../../api'
import {
  ServerBusinessByIdType,
  ServerBusinessToSwitch,
  ServerBusinessWithOwnerIdType,
} from '../../generated/graphql-types'
import {
  BUSINESS_ANONYMOUSLY_QUERY_KEY,
  BUSINESS_COUNT_BY_OWNER_EMAIL_QUERY_KEY,
  BUSINESS_QUERY_KEY,
} from './queryKeys'
import {
  anonymouslyBusinessByIdFragment,
  businessByIdFragment,
  businessWithOwnerIdFragment,
} from './business.fragments'
import { AnonymouslyBusinessByIdType } from './logic'

export function useFetchBusinessesToSwitch() {
  return useQuery<ServerBusinessToSwitch[]>(
    [BUSINESS_QUERY_KEY, 'toSwitch'],
    async () => {
      const result = await request(
        gql`
          query {
            getBusinessToSwitch {
              asClient
              asEmployee
              id
              name
            }
          }
        `,
      )

      if (Array.isArray(result?.getBusinessToSwitch)) {
        return result.getBusinessToSwitch
      } else {
        reportError(new Error('businesses is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchBusinessesToSwitch',
      onError: reportGqlError,
    },
  )
}

export function useFetchMyBusinesses(isAuthorised: boolean) {
  // В CreateBusiness/MainInfoStep, после создания бизнеса идет перезагрузка приложение,
  // а в useEffect мы проверяем, если есть бизнес, то переходим на следующий шаг.
  // По этому нужна проверка на isAuthorised, что бы не фетчились бизнесы, пока нет доступ

  return useQuery<ServerBusinessWithOwnerIdType[]>(
    [BUSINESS_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${businessWithOwnerIdFragment}
          query {
            businesses(order_by: { createdAt: desc }) {
              ...businessWithOwnerIdType
            }
          }
        `,
      )

      if (Array.isArray(result?.businesses)) {
        return result.businesses
      } else {
        reportError(new Error('businesses is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchMyBusinesses',
      staleTime: LONG_STALE_TIME,
      onError: reportGqlError,
      enabled: Boolean(isAuthorised),
    },
  )
}

export function useFetchBusinessById(businessId: number | null) {
  return useQuery<ServerBusinessByIdType>(
    [BUSINESS_QUERY_KEY, { businessId }],
    async () => {
      const result = await request(
        gql`
          ${businessByIdFragment}
          query ($businessId: Int!) {
            businessById(id: $businessId) {
              ...businessByIdType
            }
          }
        `,
        { businessId },
      )

      if (result?.businessById) {
        return result?.businessById
      } else {
        reportError(new Error('businessById does not exist'), 'warning', { result })
        return undefined
      }
    },
    {
      queryName: 'useFetchBusinessById',
      staleTime: LONG_STALE_TIME,
      onError: reportGqlError,
      enabled: Boolean(businessId),
    },
  )
}

export function useFetchBusinessCountByOwnerEmail(ownerEmail: string | null) {
  return useQuery(
    [BUSINESS_QUERY_KEY, BUSINESS_COUNT_BY_OWNER_EMAIL_QUERY_KEY, { ownerEmail }],
    async () => {
      const result = await request<{ businessesAggregate: { aggregate: { count: number } } }>(
        gql`
          query ($ownerEmail: String = "") {
            businessesAggregate(limit: 1, where: { owner: { email: { _eq: $ownerEmail } } }) {
              aggregate {
                count
              }
            }
          }
        `,
        {
          ownerEmail,
        },
      )

      return result.businessesAggregate.aggregate.count
    },
    {
      queryName: 'useFetchBusinessCountByOwnerEmail',
      staleTime: LONG_STALE_TIME,
      onError: reportGqlError,
      enabled: Boolean(ownerEmail),
    },
  )
}

export const useFetchAnonymouslyBusinessById = (id: number | null | undefined) => {
  return useQuery<AnonymouslyBusinessByIdType | null>(
    [BUSINESS_ANONYMOUSLY_QUERY_KEY, { id }],
    async () => {
      const result = await requestAnonymously<{
        businessById: AnonymouslyBusinessByIdType
      }>(
        gql`
          ${anonymouslyBusinessByIdFragment}
          query ($id: Int!) {
            businessById(id: $id) {
              ...anonymouslyBusinessByIdType
            }
          }
        `,
        { id },
      )

      return result.businessById
    },
    {
      enabled: Boolean(id),
      queryName: 'useFetchAnonymouslyBusinessById',
      onError: reportGqlError,
    },
  )
}
