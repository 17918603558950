import { commonSelectIconStyle } from '@expane/ui'
import { useTranslation } from 'react-i18next'
import { IoChevronDown, IoChevronDownOutline, IoClose, IoFilter } from 'react-icons/io5'
import { DisplayedItemsOptions, getWidthItem, SelectedItem } from 'ui/MultiSelect'
import { TreeMenuItem } from 'ui/TreeMenu'
import { findTreeMenuItem, removeAllItemsExceptDisabled } from 'ui/TreeMenu/logic.common'
import { onChangeMultiTreeMenu } from 'ui/TreeMenu/logic.onChange'

type MultiPickModeButtonProps<MTreeMenuItem extends TreeMenuItem> = {
  selectedItems: MTreeMenuItem[]
  items: MTreeMenuItem[]
  disabled: boolean
  isOpen: boolean
  placeholder: string | undefined
  isFilter: boolean
  displayedItems: DisplayedItemsOptions
  onSelected: (items: MTreeMenuItem[]) => void
}
export const MultiPickModeButton = <MTreeMenuItem extends TreeMenuItem>({
  selectedItems,
  disabled,
  isOpen,
  placeholder,
  isFilter,
  displayedItems,
  onSelected,
  items,
}: MultiPickModeButtonProps<MTreeMenuItem>) => {
  const { t } = useTranslation()

  let filterIconStyle = 'shrink-0 ml-1 text-gray-200 hover:text-primary-500'
  if (isOpen) {
    filterIconStyle += ' text-primary-500'
  }

  const handleSelectedItems = (item: MTreeMenuItem) => {
    if (item.disabled || item.status === 'inactive') return

    onSelected(onChangeMultiTreeMenu<MTreeMenuItem>(item, selectedItems, items))
  }

  return !selectedItems?.length ? (
    <div className="h-full w-full flex items-center justify-between">
      <span className="truncate text-gray-300">{disabled ? '' : placeholder}</span>
      <div className={filterIconStyle}>
        {isFilter ? (
          <IoFilter size="1.2rem" />
        ) : (
          <IoChevronDownOutline
            size="1.2rem"
            className={'transition-transform ease-out' + (isOpen ? ' rotate-180' : '')}
          />
        )}
      </div>
    </div>
  ) : (
    <div className="max-w-full grow flex h-full justify-between items-center">
      <div className="grow text-left line-clamp-1 text-gray-500">
        {selectedItems?.map(selectedItem => (
          <SelectedItem
            key={selectedItem.id}
            label={t(selectedItem.name)}
            itemWidth={getWidthItem(displayedItems, selectedItems.length)}
            onClick={() => {
              const item = findTreeMenuItem(selectedItem.id, Boolean(selectedItem.isFolder), items)
              if (!item) throw new Error('Item not found')
              handleSelectedItems(item)
            }}
          />
        ))}
      </div>

      <div
        onClick={e => {
          e.stopPropagation()

          onSelected(removeAllItemsExceptDisabled(selectedItems))
        }}
        className={filterIconStyle}
      >
        <IoClose size="1.2rem" />
      </div>
    </div>
  )
}

type SinglePickModeButtonProps<MTreeMenuItem extends TreeMenuItem> = {
  selectedItem: MTreeMenuItem | undefined
  disabled: boolean
  placeholder: string | undefined
  isFilter: boolean
}
export const SinglePickModeButton = <MTreeMenuItem extends TreeMenuItem>({
  disabled,
  selectedItem,
  placeholder,
  isFilter,
}: SinglePickModeButtonProps<MTreeMenuItem>) => {
  let valueStyle = 'flex items-center justify-between w-full -ml-1'
  if (selectedItem?.name) {
    valueStyle += ' text-main-color '
  } else {
    valueStyle += ' text-placeholder-color '
  }

  return (
    <>
      <div className="flex items-center w-full overflow-hidden pl-1">
        <div className={valueStyle}>
          {selectedItem?.Icon && <selectedItem.Icon size="0.9rem" className="mr-1" />}
          <div className="truncate">{selectedItem?.name ?? (!disabled && placeholder)}</div>
        </div>
      </div>

      <div className={commonSelectIconStyle}>
        {isFilter ? <IoFilter size="1.2rem" /> : <IoChevronDown size="1.2rem" />}
      </div>
    </>
  )
}
