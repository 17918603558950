import { DEFAULT_UTC_DIFFERENCE, getTimezoneById, TIMEZONES } from '@expane/date'
import { ErrorMessageType, InputLabel, SelectDropdown } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface TimezonesSelectProps {
  onSelectChange: (value: number | null) => void
  value: number | undefined
  className?: string
  containerClassName?: string
  disabled?: boolean
  errorMessage?: ErrorMessageType
  required?: boolean
}

export const TimezonesSelect: FC<TimezonesSelectProps> = ({
  value,
  onSelectChange,
  className = '',
  containerClassName = '',
  disabled,
  errorMessage,
  required,
}) => {
  const { t } = useTranslation()

  return (
    <div className={containerClassName}>
      <InputLabel label={t('timezone.name')} required={required} />

      <div className="flex items-center">
        <SelectDropdown
          value={value}
          items={TIMEZONES}
          placeholder={t('placeholders.defaultSelect')}
          noDataMessage={t('noSuchRateType')}
          onSelectChange={onSelectChange}
          disabled={disabled}
          className={className}
          errorMessage={errorMessage}
        />

        <p className="ml-2 pb-4 text-sm font-medium text-gray-500">
          {value ? getTimezoneById(value)?.time : DEFAULT_UTC_DIFFERENCE}
        </p>
      </div>
    </div>
  )
}
