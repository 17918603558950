import { store } from 'store'
import { useFetchCurrentBranchTimezone } from '@expane/data'
import { useDateFormatting as useDateFormattingOriginal } from '@expane/logic/useDateFormatting'

export const useDateFormatting = () => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  return useDateFormattingOriginal(timezone)
}
