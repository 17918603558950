import { compareAsc } from '@expane/date'
import {
  BookingUnion,
  CardType,
  MOVEMENT_TYPES,
  PaymentTransaction,
  ServerServiceBriefWithProductType,
  ServerTransactionInsertInput,
  TRANSACTION_TYPES,
} from '@expane/data'
import { calcDiscountSum, DEFAULT_DISCOUNT, DiscountInputInfo } from '../../utils'
import { getActiveSubscriptions } from '../../cards'
import { getCountOfPaymentAndRefundTransactionsByService, getIsServicePaid } from '../../booking'

export type ServiceDto = {
  id: number
  // actual control
  discount: DiscountInputInfo
  // these do not change during interaction
  name: string
  price: number
  costPrice: number
  serviceId: number
  bookingId: number
  subscriptionId: number | null
}

export const DEFAULT_SERVICE_QUANTITY = 1

export const calcServiceTotalSum = (servicesDto: ServiceDto[]): number =>
  servicesDto.reduce((sum, item) => {
    if (item.subscriptionId) {
      return sum
    }
    const itemDiscount = calcDiscountSum({ discount: item.discount, price: item.price })

    return sum + (item.price - itemDiscount)
  }, 0)

export const formServicePaymentTransaction = ({
  amount,
  clientId,
  bookingId,
  employeeId,
  fromStorageId,
  servicesForTransactions,
  consumablesForServices,
  debt,
  branchId,
}: {
  // If greater than 0, then make payment in credit
  debt: number
  amount: number
  bookingId: number
  clientId: number
  employeeId: number | undefined
  fromStorageId: number
  servicesForTransactions: {
    serviceId: number
    price: number
    costPrice: number
    discount?: number | undefined
  }[]
  consumablesForServices: {
    productId: number
    quantity: number
    price: number
  }[]
  branchId: number
}): PaymentTransaction => {
  const paymentIsInCredit = debt > 0

  const childTransactions: ServerTransactionInsertInput[] = []
  if (paymentIsInCredit && amount > debt)
    childTransactions.push({
      amount: amount - debt,
      type: TRANSACTION_TYPES.repaymentDeposit.id,
      typeVariation:
        TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByClientBalance.id,
      clientId,
      branchId,
    })

  return {
    amount,
    type: paymentIsInCredit ? TRANSACTION_TYPES.paymentInCredit.id : TRANSACTION_TYPES.payment.id,
    typeVariation: TRANSACTION_TYPES.payment.variations.service.id,
    bookingId,
    clientId,
    employeeId,
    transactionsServices: { data: servicesForTransactions },
    movement: consumablesForServices.length
      ? {
          data: {
            fromStorageId,
            fulfilled: true,
            movementProducts: { data: consumablesForServices },
            type: MOVEMENT_TYPES.consumablesWriteOff.id,
            // При списании расходников не указываем номер накладной расхода
            number: '',
            branchId,
          },
        }
      : null,
    childTransactions: childTransactions.length ? { data: childTransactions } : null,
    branchId,
  }
}

export const getServicesWithDiscount = (servicesDto: ServiceDto[]) => {
  const result: {
    serviceId: number
    price: number
    costPrice: number
    discount?: number
  }[] = []

  for (const serviceDto of servicesDto) {
    if (serviceDto.discount) {
      const discount = calcDiscountSum({
        discount: serviceDto.discount,
        price: serviceDto.price,
      })
      const price = serviceDto.price - discount

      result.push({
        serviceId: serviceDto.serviceId,
        price,
        costPrice: serviceDto.costPrice,
        discount,
      })
    } else {
      result.push({
        serviceId: serviceDto.serviceId,
        price: serviceDto.price,
        costPrice: serviceDto.costPrice,
      })
    }
  }

  return result
}

const getSubscriptionIdByService = (activeSubscriptions: CardType[], serviceId: number) => {
  const subscription = activeSubscriptions.find(
    subscription =>
      subscription.subscriptionInfo[serviceId] &&
      subscription.subscriptionInfo[serviceId].used <
        subscription.subscriptionInfo[serviceId].quantity,
  )

  return subscription
}

export const prepareServicesDto = (
  bookings: BookingUnion[] | undefined,
  clientId: number,
  clientCards: CardType[] = [],
  timezone: string,
): ServiceDto[] => {
  if (!bookings) return []

  // исключаем абонементы, которые закончились
  const activeSubscriptions = getActiveSubscriptions(clientCards, timezone).sort((a, b) => {
    // чем раньше был активирован абонемент тем выше у него приоритет для использования
    if (!a.activatedAt) return 1
    if (!b.activatedAt) return -1
    return compareAsc(a.activatedAt, b.activatedAt)
  })

  const services: ServiceDto[] = []

  for (const booking of bookings) {
    const { paymentsByService, refundsByService } = getCountOfPaymentAndRefundTransactionsByService(
      booking.transactions.filter(transaction => transaction.clientId === clientId),
    )
    if (!booking.isGroupBooking) {
      const servicesToPay: ServerServiceBriefWithProductType[] = []
      booking.bookingServices.forEach(({ service }) => {
        if (!getIsServicePaid({ serviceId: service.id, paymentsByService, refundsByService }))
          servicesToPay.push(service)
      })

      const servicesFromBooking: ServiceDto[] = servicesToPay.map(service => {
        const subscriptionById = getSubscriptionIdByService(activeSubscriptions, service.id)

        const priceBySubscription = subscriptionById?.cardTemplate.cardTemplateServices.find(
          ({ serviceId }) => service.id === serviceId,
        )?.servicePrice

        return {
          id: service.id,
          name: service.name,
          serviceId: service.id,
          price: priceBySubscription ?? service.price,
          costPrice: service.costPrice,
          bookingId: booking.id,
          discount: DEFAULT_DISCOUNT,
          subscriptionId: subscriptionById?.id ?? null,
        }
      })
      services.push(...servicesFromBooking)
    } else {
      const subscriptionById = booking.service
        ? getSubscriptionIdByService(activeSubscriptions, booking.service.id)
        : undefined

      if (
        booking.service &&
        !getIsServicePaid({ serviceId: booking.service.id, paymentsByService, refundsByService })
      )
        services.push({
          id: booking.service.id,
          name: booking.service.name,
          serviceId: booking.service.id,
          price: booking.service.price,
          costPrice: booking.service.costPrice,
          bookingId: booking.id,
          discount: DEFAULT_DISCOUNT,
          subscriptionId: subscriptionById?.id ?? null,
        })
    }
  }

  return services
}
