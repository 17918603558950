import { BookingUnion, ClientPhones } from '@expane/data'
import { transformPersonName } from '@expane/logic/utils'
import { Tag } from '@expane/ui'
import { useAgeDeclension } from 'i18n/declensions'
import { BookingCalendarType } from 'pages/BookingsPage'
import { FC } from 'react'
import {
  IoCashOutline,
  IoChatboxOutline,
  IoPeopleCircleOutline,
  IoRepeatOutline,
} from 'react-icons/io5'
import { store } from 'store'
import { Phone } from 'ui/Phone'
import { InformationPopup } from 'widgets/InformationPopup'
import { getIsBookingRecurring } from '@expane/logic/booking'

interface Props {
  booking: BookingUnion
  clientPhones?: ClientPhones
  bgColor: string
  isShort: boolean
  isNarrow: boolean
  isBookingPaid: boolean
  calendarType: BookingCalendarType
  boundaryElement: HTMLDivElement | undefined
}
export const Icons: FC<Props> = ({
  booking,
  clientPhones,
  bgColor,
  isShort,
  isBookingPaid,
  isNarrow,
  calendarType,
  boundaryElement,
}) => {
  const {
    bookingSettings: { isCommentIconShown },
  } = store
  let className =
    'absolute flex justify-end items-end top-0 overflow-hidden rounded-tr-md bottom-0 right-0 left-0 text-black'
  if (isNarrow) className += ' flex-col'

  const ageDeclension = useAgeDeclension()

  const clientAge =
    !booking.isGroupBooking && booking.client?.birthDate
      ? ageDeclension(booking.client?.birthDate)
      : undefined

  const clientTags = !booking.isGroupBooking
    ? booking.client?.clientTags?.map(item => item.tag) ?? []
    : undefined

  const servicesNames = getServiceNames(booking)

  const isRecurring = getIsBookingRecurring(booking)

  return (
    <>
      <div className={className}>
        {booking.isGroupBooking && !isShort && (
          <IoPeopleCircleOutline className={`bg-${bgColor} rounded-full shrink-0`} />
        )}
        {(booking.note || booking.clientNote) && !isShort && isCommentIconShown && (
          <IoChatboxOutline className={`bg-${bgColor} shrink-0`} />
        )}
        {isBookingPaid && !isShort && <IoCashOutline className={`bg-${bgColor} shrink-0`} />}
        {isRecurring && !isShort && <IoRepeatOutline className={`bg-${bgColor} shrink-0`} />}
        <InformationPopup boundaryElement={boundaryElement}>
          {booking.isGroupBooking && booking.bookingClients.length > 0 && (
            <p>{booking.bookingClients.map(gBC => gBC.client.firstName).join('|')}</p>
          )}
          {!booking.isGroupBooking && (
            <>
              <p>
                {booking.client && transformPersonName(booking.client)}
                {clientAge && `, ${clientAge}`}
                {clientTags?.map(tag => (
                  <Tag key={tag.id} name={tag.name} color={tag.color} className="ml-1" />
                ))}
              </p>

              <p>{clientPhones?.phone ? <Phone number={clientPhones.phone} /> : ''}</p>
            </>
          )}

          {booking.isGroupBooking && booking.bookingClients.length === 0 ? null : (
            <div>
              <hr className="border-input-disabled-color bg-icon my-3" />
            </div>
          )}

          <p>{servicesNames}</p>
          <p>
            {calendarType === 'locations'
              ? `${booking.employee?.firstName ?? ''} ${booking.employee?.lastName ?? ''}`
              : ''}
            {calendarType === 'employees' ? booking.location?.name : ''}
          </p>

          {(booking.note || booking.clientNote) && (
            <>
              <div>
                <hr className="border-input-disabled-color bg-icon my-3" />
              </div>

              <p>{booking.note || booking.clientNote}</p>
            </>
          )}
        </InformationPopup>
      </div>
    </>
  )
}

const getServiceNames = (booking: BookingUnion) => {
  // если букинг групповой, то услуга только одна
  if (booking.isGroupBooking) return booking.service?.name

  const services = booking.bookingServices.map(bS => bS.service)
  return services.reduce((text, service, index) => {
    if (index === services.length - 1) {
      return text + service.name
    }
    return text + `${service.name}|`
  }, '')
}
