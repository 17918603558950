import { ServerRolePermissionType } from '@expane/data'
import {
  PermissionPageFormData,
  transformPermissionsIntoFormData,
} from '@expane/logic/permission/logic'
import { RoleWithWarningMessage } from '@expane/logic/role'
import { SelectDropdown, useShowConfirmationPopup } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface GroupAndRoleSelectProps {
  roles: RoleWithWarningMessage[]
  rolePermissions: ServerRolePermissionType[]
  control: Control<PermissionPageFormData>
  setValue: UseFormSetValue<PermissionPageFormData>
}

export const RoleSelect: FC<GroupAndRoleSelectProps> = ({
  roles,
  control,
  setValue,
  rolePermissions,
}) => {
  const { t } = useTranslation()

  const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

  return (
    <>
      <Controller
        name="roleId"
        control={control}
        render={({ field: { onChange, value }, formState: { dirtyFields } }) => {
          const id = roles.find(role => role.roleId === value)?.id

          const arePermissionsDirty = Boolean(dirtyFields.permissions)

          const handleChange = (id: number | null) => {
            const roleId = roles.find(role => role.id === id)?.roleId

            if (roleId) {
              onChange(roleId)
              setValue('permissions', transformPermissionsIntoFormData({ roleId, rolePermissions }))
            }
          }

          const onChangeWithConfirmation = (id: number | null) => {
            if (arePermissionsDirty) {
              showConfirmation({
                title: t('permission.name'),
                description: t('permission.notSaved'),
                onConfirm: () => handleChange(id),
              })
            } else {
              handleChange(id)
            }
          }

          return (
            <SelectDropdown
              className="mr-2 w-70"
              label={t('accessRoles')}
              onSelectChange={onChangeWithConfirmation}
              value={id}
              items={roles}
            />
          )
        }}
      />
      {confirmationModal}
    </>
  )
}
