import { ServerStorageType } from '@expane/data'
import { Input } from '@expane/ui'
import { DateTimePicker } from '@expane/widgets'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InventoryCreateProps } from 'widgets/InventoryCreateDialog'
import { StorageSelectDropdown } from 'widgets/StoragesSelectDropdown'

interface InventoryCreateInfoProps extends InventoryCreateProps {
  storages: ServerStorageType[]
  defaultStorageId: number
  timezone: string
}

export const InventoryCreateInfo: FC<InventoryCreateInfoProps> = ({
  control,
  storages,
  defaultStorageId,
  timezone,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex">
      <Controller
        control={control}
        name="storageId"
        rules={{ required: true }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <StorageSelectDropdown
            required
            label={t('storage.name')}
            className="w-1/3 pr-2"
            storages={storages}
            defaultStorageId={defaultStorageId}
            onSelectChange={onChange}
            value={value}
            errorMessage={{ isShown: Boolean(errors.storageId), text: t('formError.required') }}
          />
        )}
      />
      <Controller
        control={control}
        name="inventoryNumber"
        rules={{ required: true }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <Input
            containerClassName="w-1/3 pr-2"
            required
            label={`${t('inventory.name')}, ${t('numberFormat', { value: '' })}`}
            value={value}
            onChange={onChange}
            errorMessage={{
              isShown: Boolean(errors.inventoryNumber),
              text: t('formError.required'),
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="inventoryDate"
        render={({ field: { onChange, value } }) => (
          <DateTimePicker
            className="w-1/3"
            timezone={timezone}
            label={t('dateTitle')}
            type="dateTime"
            value={value}
            onChange={onChange}
          />
        )}
      />
    </div>
  )
}
