export const getElementSafelyById = (id: string) => {
  const elementRef = document.getElementById(id)
  if (elementRef) return elementRef
  else {
    const div = document.createElement('div')
    div.id = id
    document.body.insertAdjacentElement('afterbegin', div)

    return div
  }
}
