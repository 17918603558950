import { FC, Fragment } from 'react'
import {
  DynamicDateType,
  DynamicSchedule,
  EmployeeGroupWithSchedule,
  useFetchCurrentBranchTimezone,
  useMutateDynamicDates,
  WeeklySchedule,
} from '@expane/data'
import { CalendarCell, CalendarColumn } from 'ui/Calendar'
import { IoAddCircleOutline, IoCloseCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5'
import {
  useFieldArray,
  Control,
  useWatch,
  UseFieldArrayRemove,
  UseFieldArrayAppend,
} from 'react-hook-form'
import {
  EmployeesScheduleDynamicDatesForm,
  EmployeesScheduleDynamicDateType,
} from 'pages/SchedulesPage/index'
import { getWeekDayNumber, isEqual, isSameDay, fromZonedTime } from '@expane/date'
import { getCurrentEmployeeSchedule } from '@expane/logic/employeeSchedule'
import { getWorkingEmployeeIdsByDate } from '@expane/logic/schedule'
import { store } from 'store'
import { useTranslation } from 'react-i18next'
import { useShowConfirmationPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'

interface SchedulePageCalendarRowsType {
  employeeGroups: EmployeeGroupWithSchedule[]
  date: Date
  isLast: boolean
  control: Control<EmployeesScheduleDynamicDatesForm>
  branchSchedule: WeeklySchedule
}

export const SchedulePageCalendarRows: FC<SchedulePageCalendarRowsType> = ({
  employeeGroups,
  date,
  isLast,
  control,
  branchSchedule,
}) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const branchTiming = branchSchedule.data[getWeekDayNumber(date)]

  const { mutateAsync: createDynamicDates } = useMutateDynamicDates()

  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

  const { append, remove } = useFieldArray({ control, name: 'dynamicDates' })

  const dynamicDates = useWatch({ control, name: 'dynamicDates' })

  const workingEmployeesIds = getWorkingEmployeeIdsByDate(employeeGroups, date)

  const employeeGroupsWithEmployees = employeeGroups.filter(group => group.employees.length !== 0)

  const handleRemoveDynamicDate = (
    dynamicDates: DynamicDateType[],
    date: Date,
    employeeScheduleId: number | undefined,
  ) => {
    showConfirmation({
      title: t('schedule.removingDays'),
      description: t('schedule.addingDaysConfirmation'),
      onConfirm: () => mutateDynamic(dynamicDates, date, employeeScheduleId),
    })
  }

  const mutateDynamic = async (
    dynamicDates: DynamicDateType[],
    date: Date,
    employeeScheduleId: number | undefined,
  ) => {
    const dynamicScheduleDates = dynamicDates.filter(
      dynamicDate => !isSameDay(dynamicDate.date, date),
    )

    const dynamicDateToDelete = dynamicDates.find(dynamicDate => isSameDay(dynamicDate.date, date))

    if (timezone) {
      try {
        const result = await createDynamicDates({
          dynamicScheduleDates: dynamicScheduleDates.map(dynamicDate => ({
            ...dynamicDate,
            date: fromZonedTime(dynamicDate.date, timezone),
            generatedId: undefined,
            employeeScheduleId,
          })),
          dynamicDateIdsToDelete: dynamicDateToDelete ? [dynamicDateToDelete?.id] : [],
        })

        if (result?.deleteDynamicScheduleDates?.affected_rows) {
          openSnackbar(t('schedule.successfullyReassigned'), 'success')
        } else openSnackbar(t('submitError'), 'error')
      } catch (error) {
        openSnackbar(t('submitError'), 'error')
      }
    }
  }

  return (
    <CalendarColumn className="grow basis-0 relative">
      {employeeGroupsWithEmployees.map((group, index) => {
        const isFirst = index === 0

        const additionalIsFirstStyles = isFirst
          ? ' border-t border-t-primary-100  dark:border-t-primary-100/30'
          : ''

        return (
          group.employees.length !== 0 && (
            <Fragment key={group.id}>
              <div className={cellStyle + additionalIsFirstStyles} />

              {group.employees.map(({ id: employeeId, employeeSchedules }) => {
                const isWorking = workingEmployeesIds.includes(employeeId)

                const currentEmployeeSchedule = getCurrentEmployeeSchedule(employeeSchedules, date)

                const isDynamicSchedule = currentEmployeeSchedule?.isDynamic ?? false

                const isSelected = checkIsCellSelected(dynamicDates, employeeId, date)
                // филиал не работает
                const isDisabled = branchTiming === null || !currentEmployeeSchedule

                let className
                if (isDisabled) {
                  className = ''
                } else {
                  className = isWorking ? 'bg-primary-200 dark:bg-primary-darkened' : 'bg-block'
                }

                return (
                  <CalendarCell key={employeeId} fullHour className={className} lastColumn={isLast}>
                    <CalendarCellContent
                      isWorking={isWorking}
                      isDynamicSchedule={isDynamicSchedule}
                      isDisabled={isDisabled}
                      isSelected={isSelected}
                      remove={remove}
                      append={append}
                      onRemoveDynamicDate={() =>
                        handleRemoveDynamicDate(
                          (currentEmployeeSchedule as DynamicSchedule)?.dynamicDates,
                          date,
                          currentEmployeeSchedule?.id,
                        )
                      }
                      dynamicDates={dynamicDates}
                      employeeId={employeeId}
                      employeeScheduleId={currentEmployeeSchedule?.id}
                      date={date}
                    />
                  </CalendarCell>
                )
              })}
            </Fragment>
          )
        )
      })}
      {confirmationModal}
    </CalendarColumn>
  )
}

interface CalendarCellContentProps {
  isWorking: boolean
  isDynamicSchedule: boolean
  isDisabled: boolean
  isSelected: boolean
  remove: UseFieldArrayRemove
  append: UseFieldArrayAppend<EmployeesScheduleDynamicDatesForm, 'dynamicDates'>
  dynamicDates: Array<EmployeesScheduleDynamicDateType>
  employeeId: number
  // schedule may be undefined if employee hasn't started working yet
  employeeScheduleId: number | undefined
  date: Date
  onRemoveDynamicDate
}
const CalendarCellContent: FC<CalendarCellContentProps> = ({
  isWorking,
  isDynamicSchedule,
  isDisabled,
  isSelected,
  remove,
  append,
  dynamicDates,
  employeeId,
  employeeScheduleId,
  date,
  onRemoveDynamicDate,
}) => {
  if (isWorking && isDynamicSchedule)
    return <RemoveWorkingDynamicDateButton onClick={onRemoveDynamicDate} />
  if (isDisabled || !employeeScheduleId) return null
  if (!isDynamicSchedule && !isDisabled) return <DisabledDateButton />

  return isSelected ? (
    <RemoveDynamicDateButton
      onClick={() => {
        const index = dynamicDates.findIndex(
          field => field.employeeId === employeeId && isEqual(field.date, date),
        )

        remove(index)
      }}
    />
  ) : (
    <AddDynamicDateButton
      onClick={() =>
        append({
          employeeId,
          date,
          employeeScheduleId,
        })
      }
    />
  )
}

const AddDynamicDateButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button onClick={onClick} className={buttonContainerStyle + 'opacity-0 hover:opacity-100'}>
      <IoAddCircleOutline size="1.5rem" className="text-primary-600" />
    </button>
  )
}

const RemoveWorkingDynamicDateButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className={buttonContainerStyle + 'cursor-default opacity-0 hover:opacity-100'}
    >
      <IoCloseCircleOutline size="1.5rem" className="text-error-600" />
    </button>
  )
}

const DisabledDateButton: FC = () => {
  return (
    <div className={buttonContainerStyle + 'cursor-default opacity-0 hover:opacity-100'}>
      <IoRemoveCircleOutline size="1.5rem" className="text-gray-300" />
    </div>
  )
}

const RemoveDynamicDateButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className={buttonContainerStyle + ' bg-primary-300 border-2 border-primary-600'}
    >
      <IoCloseCircleOutline size="1.5rem" className="text-error-600" />
    </button>
  )
}

const buttonContainerStyle =
  'hover:transition-opacity flex-centered flex-col w-full h-full cursor-pointer '
const cellStyle = `text-center h-10 px-1 bg-primary-50 dark:bg-gray-500 flex-centered leading-3 text-xs whitespace-pre-line line-clamp-3 `

const checkIsCellSelected = (
  dynamicDates: Array<EmployeesScheduleDynamicDateType>,
  employeeId: number,
  date: Date,
) =>
  dynamicDates.some(
    dynamicDate => dynamicDate.employeeId === employeeId && isEqual(dynamicDate.date, date),
  )
