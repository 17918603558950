import { productFragment } from '../product/product.fragments'
import { gql } from 'graphql-request'

export const productGroupFragment = gql`
  ${productFragment}
  fragment productGroupType on productGroup {
    id
    name
    description
    parentId
    archived
    products(where: { branchId: { _eq: $branchId } }) {
      ...productType
    }
    productGroupEmployeeGroups {
      employeeGroup {
        id
        name
      }
    }
  }
`

export const productGroupBriefNotArchivedFragment = gql`
  ${productFragment}
  fragment productGroupBriefNotArchivedType on productGroup {
    id
    name
    products(where: { branchId: { _eq: $branchId }, archived: { _is_null: true } }) {
      ...productType
    }
  }
`

export const productGroupBriefArchivedFragment = gql`
  ${productFragment}
  fragment productGroupBriefArchivedType on productGroup {
    id
    name
    products(where: { branchId: { _eq: $branchId }, archived: { _is_null: false } }) {
      ...productType
    }
  }
`
