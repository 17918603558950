import {
  ClientBriefWithTagType,
  ClientPhones,
  Connections,
  ServerClientPhoneView,
  ServerTransactionBriefWithClient,
  useFetchTransactionsBriefWithClientByDate,
} from '@expane/data'
import { findById, transformPersonName } from '../utils'
import { getTransactionsWithClientsDepositsAndPayments } from '../finances/filters'
import { isBefore, isSameDay, max } from '@expane/date'
import { defineStartAndEndDateByPeriodOfTime, PERIODS_OF_TIME } from '../analytics'

export type ClientWithPhones<T extends { id: number }> = T &
  Pick<ServerClientPhoneView, 'phone' | 'altPhone'>

export const addPhonesToClient = <T extends { id: number }>(
  client: T,
  clientPhones: ClientPhones | undefined,
): ClientWithPhones<T> => ({
  ...client,
  phone: clientPhones?.phone ?? null,
  altPhone: clientPhones?.altPhone ?? null,
})

export const addPhonesToClients = <T extends { id: number }>(
  clients?: T[],
  clientPhones?: ClientPhones[],
): Array<ClientWithPhones<T>> =>
  clients?.map(client => {
    const phones = findById(client.id, clientPhones)

    return addPhonesToClient(client, phones)
  }) ?? []

export const getClientsQuantity = (transactions: ServerTransactionBriefWithClient[]): number => {
  const uniqueClientsByPeriod = groupTransactionsByClientId(transactions)

  return Object.keys(uniqueClientsByPeriod).length
}

export const groupTransactionsByClientId = (
  transactions: ServerTransactionBriefWithClient[],
): { [key: number]: ServerTransactionBriefWithClient[] } =>
  transactions.reduce((client, obj) => {
    client[obj.clientId] = (client[obj.clientId] || []).concat(obj)
    return client
  }, {})

type ActiveClients = {
  activeClientsIds: Array<string>
  activeClientsQuantity: number
  activeClientsLoading: boolean
}

// считаем сколько в базе клиентов, которые совершали какую-либо оплату за 30 дней (это и есть
// активные клиенты)
export const useGetActiveClients = (
  timezone: string | undefined,
  branchId: number | undefined,
  isFetchingAllowed = true,
): ActiveClients => {
  const [startDate, endDate] = defineStartAndEndDateByPeriodOfTime(
    PERIODS_OF_TIME.thirtyDays.id,
    timezone,
  )

  const { data: transactions, isLoading } = useFetchTransactionsBriefWithClientByDate({
    startDate,
    endDate,
    timezone,
    branchId,
    isFetchingAllowed,
  })

  const activeClients: ActiveClients = {
    activeClientsIds: [],
    activeClientsQuantity: 0,
    activeClientsLoading: isLoading,
  }

  if (!transactions || isLoading) return activeClients

  // сумма транзакции не должна быть 0 так как тогда это потенциальный клиент
  const trWithClientsByLastMonth = getTransactionsWithClientsDepositsAndPayments(
    transactions,
  ).filter(tr => tr.amount !== 0)

  const clientsIdsByTransactions = Object.keys(
    groupTransactionsByClientId(trWithClientsByLastMonth),
  )

  activeClients.activeClientsIds = [...clientsIdsByTransactions]
  activeClients.activeClientsQuantity = clientsIdsByTransactions.length

  return activeClients
}

// должны прийти транзакции уже отфильтрованные(с клиентом)
export const getNewClients = (
  transactionsByPeriod: ServerTransactionBriefWithClient[],
  transactionsAll: ServerTransactionBriefWithClient[],
): { newClientsIds: Array<string>; newClientsQuantity: number } => {
  // поскольку транзакции за период могут быть за какой-то период, после которого идут другие
  // транзакции то нам нужно найти только те, которые до этого выбранного периода
  const transactionsByPeriodDates = transactionsByPeriod.map(({ date }) => date)

  const maxDate = max(transactionsByPeriodDates)

  const transactionsBeforeTransactionsByPeriod = transactionsAll?.filter(tr =>
    isBefore(tr.date, maxDate),
  )

  const allTransactionsBeforeTransactionsByPeriod = transactionsBeforeTransactionsByPeriod?.filter(
    ({ date: trDate }) =>
      !transactionsByPeriod?.some(({ date: trByPeriodDate }) => isSameDay(trDate, trByPeriodDate)),
  )
  const clientsIdsByPeriod = Object.keys(groupTransactionsByClientId(transactionsByPeriod))

  const clientsIdsLifeTime = Object.keys(
    groupTransactionsByClientId(allTransactionsBeforeTransactionsByPeriod),
  )

  // находим тех клиентов которые не совершали транзакции ранее
  const newClients = clientsIdsByPeriod.filter(
    clientId => !clientsIdsLifeTime.some(trId => clientId === trId),
  )

  return { newClientsIds: newClients, newClientsQuantity: newClients.length }
}

export const getHasClientMessageSendConnections = (preferredConnections?: Connections) =>
  preferredConnections ? Object.values(preferredConnections).some(connection => connection) : false

export const getIsClientArchived = <T extends { archivedInBranches: { branchId: number }[] }>(
  client: T,
  branchId: number,
): boolean =>
  client.archivedInBranches.some(archivedInBranch => archivedInBranch.branchId === branchId)

export type Gender = 'm' | 'f'

export const getClientWithSamePhoneNumberFullName = <T extends ClientBriefWithTagType>(
  clientsWithPhones: Array<ClientWithPhones<T>>,
  phone: string,
  clientId?: number,
) => {
  const clientWithSamePhoneNumber = clientsWithPhones.find(client => client.phone === phone)

  if (clientId && clientWithSamePhoneNumber) {
    return clientWithSamePhoneNumber.id === clientId
      ? ''
      : transformPersonName(clientWithSamePhoneNumber)
  }

  return clientWithSamePhoneNumber ? transformPersonName(clientWithSamePhoneNumber) : ''
}
