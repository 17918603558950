import { queryClient, request, useQuery, reportError, reportGqlError } from '../../api'
import { ServerLeadType } from '../../generated/graphql-types'
import { gql } from 'graphql-request'
import { leadFragment } from './lead.fragments'
import { LEADS_QUERY_KEY } from './queryKeys'

export function useFetchLeads() {
  return useQuery<ServerLeadType[]>(
    [LEADS_QUERY_KEY],
    async () => {
      const result = await request(gql`
        ${leadFragment}
        query {
          leads(order_by: { createdAt: desc }) {
            ...leadType
          }
        }
      `)

      if (Array.isArray(result?.leads)) {
        return result.leads
      }

      reportError(new Error('leads is not an array'), 'error', { result })
      return []
    },
    {
      queryName: 'useFetchLeads',
      onSuccess: data => {
        for (const lead of data) {
          queryClient.setQueryData([LEADS_QUERY_KEY, lead.id], lead)
        }
      },
      onError: reportGqlError,
    },
  )
}
