import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { BusinessPromoCodeViewType } from '@expane/data'

export const NotUsedPromoCode: FC<{ businessPromoCode: BusinessPromoCodeViewType }> = ({
  businessPromoCode,
}) => {
  const { t } = useTranslation()

  const className = 'flex items-center border-2 rounded-lg p-4 mb-4 border-primary-400 bg-accent'

  return (
    <div className={className}>
      <IoAlertCircleOutline size="3.5rem" className={'mr-2 text-billing-main-color'} />

      <div>
        <p className={'font-medium text-lg text-billing-main-color'}>
          {t('notUsedPromoCode.description')}{' '}
          <span className="font-bold">{businessPromoCode.promoCodeId}</span>
        </p>
        <p className="text-billing-secondary-color">
          {t('notUsedPromoCode.additionalDescription', { discount: businessPromoCode.discount })}
        </p>
      </div>
    </div>
  )
}
