import { ServerExtendedServiceType, SERVICE_TYPE } from '@expane/data'
import {
  RATE_FROM_SERVICES_FALLBACK_TYPES,
  RATE_FROM_SERVICES_TYPES,
  SalarySettingServiceType,
} from '@expane/logic/salarySettings'
import { findById } from '@expane/logic/utils'
import { Input, InputLabel, SelectDropdown } from '@expane/ui'
import { translateData } from 'logic/utils'
import { FC } from 'react'
import {
  Control,
  Controller,
  SubmitHandler,
  useForm,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ApplyButton } from 'widgets/Buttons'
import { SalarySettingFormValues, validateServiceRateSetting } from '../logic'
import { Checkbox } from '@expane/ui'
import { PLACEHOLDERS } from '@expane/logic/form'

interface AllServicesRateFromServicesProps {
  services: ServerExtendedServiceType[]
  control: Control<SalarySettingFormValues>
  setValue: UseFormSetValue<SalarySettingFormValues>
  resetField: UseFormResetField<SalarySettingFormValues>
}

type AllServicesFormValues = Omit<SalarySettingServiceType, 'serviceId' | 'name'>

export const AllServicesRateFromServices: FC<AllServicesRateFromServicesProps> = ({
  services,
  control,
  setValue,
  resetField,
}) => {
  const { t } = useTranslation()

  const watchedSalarySettingServices = useWatch({ control, name: 'salarySettingServices' })

  const {
    control: allServiceControl,
    setValue: allServicesSetValue,
    handleSubmit,
  } = useForm<AllServicesFormValues>({
    defaultValues: {
      type: undefined,
      value: '',
      dependsOnRealizedValue: false,
      fallbackValue: '',
      fallbackType: undefined,
    },
  })

  const watchedType = useWatch({ control: allServiceControl, name: 'type' })
  const watchedFallbackType = useWatch({ control: allServiceControl, name: 'fallbackType' })
  const watchedDependsOnRealizedValue = useWatch({
    control: allServiceControl,
    name: 'dependsOnRealizedValue',
  })

  const handleApply: SubmitHandler<AllServicesFormValues> = ({
    type,
    value,
    dependsOnRealizedValue,
    fallbackType,
    fallbackValue,
  }) => {
    const salarySettingsServices = watchedSalarySettingServices.map(item => {
      const service = findById(item.serviceId, services)
      const isServiceForSale = service?.type === SERVICE_TYPE.forSale

      return {
        ...item,
        type,
        value,
        dependsOnRealizedValue: dependsOnRealizedValue,
        fallbackType: !isServiceForSale && dependsOnRealizedValue ? fallbackType : undefined,
        fallbackValue: !isServiceForSale && dependsOnRealizedValue ? fallbackValue : undefined,
      }
    })

    resetField('salarySettingServices')
    setValue('salarySettingServices', salarySettingsServices)
  }

  const noServicesToSetValue = watchedSalarySettingServices.length === 0

  return (
    <div className="flex items-center justify-end">
      <InputLabel className="pb-4" label={`${t('servicesAll')}: `} />

      <Controller
        control={allServiceControl}
        name="type"
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <SelectDropdown
            value={value}
            items={translateData(Object.values(RATE_FROM_SERVICES_TYPES), t)}
            placeholder={t('placeholders.defaultSelect')}
            required
            noDataMessage={t('noSuchService')}
            onSelectChange={id => {
              if (id === RATE_FROM_SERVICES_TYPES.fixedValue.id)
                allServicesSetValue('dependsOnRealizedValue', false)
              onChange(id)
            }}
            className="w-64 mx-2"
            errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
            disabled={noServicesToSetValue}
          />
        )}
      />

      <Controller
        control={allServiceControl}
        name="value"
        rules={{
          validate: value =>
            validateServiceRateSetting(value, watchedType, watchedDependsOnRealizedValue),
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            type="number"
            placeholder={PLACEHOLDERS.servicePrice}
            value={value}
            onChange={onChange}
            required
            containerClassName="w-20 mr-2"
            errorMessage={{ isShown: Boolean(error), text: t('formError.invalidValue') }}
            disabled={noServicesToSetValue}
          />
        )}
      />

      <Controller
        control={allServiceControl}
        name="dependsOnRealizedValue"
        render={({ field: { value, onChange } }) => (
          <Checkbox
            label={t('fromRealized')}
            checked={value}
            onChange={onChange}
            disabled={
              !watchedType ||
              watchedType === RATE_FROM_SERVICES_TYPES.fixedValue.id ||
              noServicesToSetValue
            }
          />
        )}
      />

      <Controller
        control={allServiceControl}
        name="fallbackType"
        rules={{ required: watchedDependsOnRealizedValue && true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <SelectDropdown
            value={value}
            items={translateData(Object.values(RATE_FROM_SERVICES_FALLBACK_TYPES), t)}
            placeholder={t('placeholders.defaultSelect')}
            required
            noDataMessage={t('noSuchService')}
            onSelectChange={onChange}
            disabled={!watchedDependsOnRealizedValue || noServicesToSetValue}
            className="w-64 mx-2"
            errorMessage={{
              isShown: watchedDependsOnRealizedValue && Boolean(error),
              text: t('formError.required'),
            }}
          />
        )}
      />

      <Controller
        control={allServiceControl}
        name="fallbackValue"
        rules={{
          validate: value =>
            validateServiceRateSetting(value, watchedFallbackType, watchedDependsOnRealizedValue),
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            type="number"
            placeholder={PLACEHOLDERS.servicePrice}
            value={value}
            onChange={onChange}
            required
            disabled={!watchedDependsOnRealizedValue || noServicesToSetValue}
            containerClassName="w-20 mr-2"
            errorMessage={{
              isShown: watchedDependsOnRealizedValue && Boolean(error),
              text: t('formError.invalidValue'),
            }}
          />
        )}
      />

      <div className="pb-4">
        <ApplyButton
          type="outline"
          onClick={handleSubmit(handleApply)}
          disabled={noServicesToSetValue}
        />
      </div>
    </div>
  )
}
