import { gql } from 'graphql-request'
import { queryClient, reportError, reportGqlError, request, useQuery } from '../../api'
import { ServerOnlineBookingUrlType } from '../../generated/graphql-types'
import { ONLINE_BOOKING_URL_QUERY_KEY } from './queryKeys'
import { onlineBookingURLFragment } from './onlineBookingURL.fragments'

export const useFetchOnlineBookingURLs = () => {
  return useQuery<ServerOnlineBookingUrlType[]>(
    [ONLINE_BOOKING_URL_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${onlineBookingURLFragment}
          query {
            onlineBookingURLs {
              ...onlineBookingURLType
            }
          }
        `,
      )

      if (Array.isArray(result?.onlineBookingURLs)) {
        return result.onlineBookingURLs
      } else {
        reportError(new Error('onlineBookingURLs is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchOnlineBookingURLs',
      onError: reportGqlError,
      onSuccess: data => {
        for (const onlineBookingURL of data) {
          queryClient.setQueryData(
            [ONLINE_BOOKING_URL_QUERY_KEY, { id: onlineBookingURL.id }],
            onlineBookingURL,
          )
        }
      },
    },
  )
}

export const useFetchOnlineBookingURLById = (id: number | undefined) => {
  return useQuery<ServerOnlineBookingUrlType>(
    [ONLINE_BOOKING_URL_QUERY_KEY, { id }],
    async () => {
      const result = await request(
        gql`
          ${onlineBookingURLFragment}
          query ($id: Int!) {
            onlineBookingURLById(id: $id) {
              ...onlineBookingURLType
            }
          }
        `,
        { id },
      )

      if (result?.onlineBookingURLById) {
        return result.onlineBookingURLById
      } else {
        reportError(new Error('onlineBookingURLById does not exist'), 'warning', { result })
      }
    },
    {
      enabled: Boolean(id),
      queryName: 'useFetchOnlineBookingURLById',
      onError: reportGqlError,
    },
  )
}
