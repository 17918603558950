import { permissions } from '../permission'

export const ReportsPermissions = {
  financialTurnover: { id: 0, permission: permissions.report.revenueExpenses },
  revenueByAccounts: { id: 1, permission: permissions.report.revenueByAccount },
  servicesSales: { id: 2, permission: permissions.report.saleOfServices },
  subscriptionsSales: { id: 3, permission: permissions.report.saleOfCards },
  giftCardsSales: { id: 4, permission: permissions.report.saleOfCards },
  productsSales: { id: 5, permission: permissions.report.saleOfProducts },
  leads: { id: 6, permission: permissions.report.leadsOfClients },
  visits: { id: 7, permission: permissions.report.leadsOfClients },
  incomingPayments: { id: 8, permission: permissions.report.incomingPayments },
  outgoingPayments: { id: 9, permission: permissions.report.outgoingPayments },
}
