import { gql } from 'graphql-request'
import {
  LONG_STALE_TIME,
  reportError,
  reportGqlError,
  request,
  requestAnonymously,
  useQuery,
} from '../../api'
import {
  ServerBusinessSettingsBriefType,
  ServerBusinessTelegramIntegrationView,
  ServerModulesSettingsInput as ModulesSettings,
} from '../../generated/graphql-types'
import {
  businessSettingsBriefFragment,
  businessSettingsFragment,
} from './businessSettings.fragments'
import { BusinessSettingsType } from './logic'
import {
  ANONYMOUSLY_TELEGRAM_INTEGRATION_QUERY_KEY,
  BUSINESS_MODULES_SETTINGS_QUERY_KEY,
  BUSINESS_SETTINGS_BRIEF_QUERY_KEY,
  BUSINESS_SETTINGS_QUERY_KEY,
} from './queryKeys'

export function useFetchBusinessSettingsBrief() {
  return useQuery(
    [BUSINESS_SETTINGS_BRIEF_QUERY_KEY],
    async () => {
      const result = await request<{
        multipleBusinessSettings: ServerBusinessSettingsBriefType[]
      }>(gql`
        ${businessSettingsBriefFragment}
        query {
          multipleBusinessSettings {
            ...businessSettingsBriefType
          }
        }
      `)

      if (result?.multipleBusinessSettings) return result.multipleBusinessSettings[0]

      reportError(new Error('Error while trying to get business settings'), 'error', { result })
    },
    {
      staleTime: LONG_STALE_TIME,
      queryName: 'useFetchBusinessSettingsBrief',
      onError: reportGqlError,
    },
  )
}

export function useFetchBusinessSettings(enabled = true) {
  return useQuery<BusinessSettingsType | undefined>(
    [BUSINESS_SETTINGS_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${businessSettingsFragment}
          query {
            multipleBusinessSettings {
              ...businessSettingsType
            }
          }
        `,
      )

      if (result?.multipleBusinessSettings[0]) {
        return result.multipleBusinessSettings[0]
      }

      reportError(new Error('No settings for this business'), 'error', { result })
      return null
    },
    {
      queryName: 'useFetchBusinessSettings',
      staleTime: LONG_STALE_TIME,
      onError: reportGqlError,
      enabled,
    },
  )
}

export function useFetchBusinessModulesSettings(enabled = true) {
  return useQuery<ModulesSettings | undefined>(
    [BUSINESS_MODULES_SETTINGS_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          query {
            multipleBusinessSettings {
              modules
            }
          }
        `,
      )

      // Business settings fetches by businessId in JWT
      // Therefore, our settings will always be at the 0 index
      if (result?.multipleBusinessSettings[0]) {
        return result.multipleBusinessSettings[0].modules
      }

      reportError(new Error('No modules settings for this business'), 'error', { result })
      return null
    },
    {
      queryName: 'useFetchBusinessModulesSettings',
      staleTime: LONG_STALE_TIME,
      onError: reportGqlError,
      enabled,
    },
  )
}

export function useFetchAnonymouslyTelegramIntegrationByBusinessById(
  businessId: number | null | undefined,
) {
  return useQuery<ServerBusinessTelegramIntegrationView | null>(
    [ANONYMOUSLY_TELEGRAM_INTEGRATION_QUERY_KEY, { businessId }],
    async () => {
      const result = await requestAnonymously<{
        businessTelegramIntegrationView: ServerBusinessTelegramIntegrationView[]
      }>(
        gql`
          query ($businessId: Int!) {
            businessTelegramIntegrationView(where: { businessId: { _eq: $businessId } }) {
              businessId
              name
              isUseDefault
            }
          }
        `,
        { businessId },
      )

      return result.businessTelegramIntegrationView[0] || null
    },
    {
      queryName: 'useFetchAnonymouslyTelegramIntegrationByBusinessById',
      enabled: Boolean(businessId),
      onError: reportGqlError,
    },
  )
}
