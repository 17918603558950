import { Pencil, stopEnterPropagation } from '@expane/ui'
import { FC, MouseEvent } from 'react'

export interface EditButtonProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  className?: string
  disabled?: boolean
  size?: string
  type?: 'primary' | 'lightPrimary'
}

export const EditButton: FC<EditButtonProps> = ({
  onClick,
  className,
  disabled,
  size = '1.2rem',
  type = 'primary',
}) => {
  let style = 'rounded p-0.5 '

  if (disabled) style += 'cursor-default text-gray-200 '

  if (type === 'primary' && !disabled) {
    style += 'text-primary-500  hover:text-primary-700 focus:ring-1 ring-primary-500 '
  }
  if (type === 'lightPrimary' && !disabled) {
    style += `text-primary-200 dark:text-primary-500/50 
      hover:text-primary-500 hover:dark:text-primary-500 
      focus:ring-1 ring-primary-200 dark:ring-primary-500 `
  }

  if (className) style += className

  return (
    <button
      onClick={onClick}
      className={style}
      disabled={disabled}
      onKeyDown={stopEnterPropagation}
    >
      <Pencil size={size} />
    </button>
  )
}
