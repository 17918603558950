import { ExtendedEmployee, ServerLocationWithServiceIdsType } from '@expane/data'
import {
  PlaceholderString,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckedBooking, PickedBookingListItem } from './PickedBookingListItem'
import { DEFAULT_TIMEZONE } from '@expane/date'

interface PickedBookingsListProps {
  bookings: CheckedBooking[]
  employees: ExtendedEmployee[] | undefined
  locations: ServerLocationWithServiceIdsType[] | undefined
  isLoading: boolean
  timezone: string | undefined
}

export const PickedBookingsList: FC<PickedBookingsListProps> = ({
  bookings,
  employees = [],
  locations = [],
  isLoading,
  timezone,
}) => {
  const { t } = useTranslation()

  return (
    <TableContainer className="w-205 max-h-64">
      <TableHeader>
        <tr>
          <TableHeaderCell className="w-4/12">{t('dateTitle')}</TableHeaderCell>
          <TableHeaderCell className="w-4/12">{t('location.name')}</TableHeaderCell>
          <TableHeaderCell className="w-4/12" borderless>
            {t('employee.name')}
          </TableHeaderCell>
          <TableHeaderCell borderless />
          <TableHeaderCell borderless />
        </tr>
      </TableHeader>
      <TableBody>
        {isLoading ? (
          <PlaceholderRows />
        ) : (
          bookings.map((booking, index) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const employee = employees!.find(employee => employee.id === booking.employeeId)!
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const location = locations!.find(location => location.id === booking.locationId)!
            return (
              <PickedBookingListItem
                timezone={timezone ?? DEFAULT_TIMEZONE}
                key={booking.specialId}
                booking={booking}
                employee={employee}
                location={location}
                initial={index === 0}
              />
            )
          })
        )}
      </TableBody>
    </TableContainer>
  )
}

const PlaceholderRow: FC = () => (
  <TableRow>
    <TableCell>
      <PlaceholderString />
    </TableCell>
    <TableCell>
      <PlaceholderString />
    </TableCell>
    <TableCell>
      <PlaceholderString />
    </TableCell>
  </TableRow>
)

const PlaceholderRows: FC = () => {
  return (
    <>
      <PlaceholderRow />
      <PlaceholderRow />
      <PlaceholderRow />
    </>
  )
}
