import { CloseButton, Dialog, Modal, Spinner } from '@expane/ui'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { SaveButton } from 'widgets/Buttons'

interface SimpleEntityPlaceholderProps extends Pick<DialogProps, 'closeDialog'> {
  title: string
  height?: 'normal' | 'small'
}
export const SimpleEntityPlaceholder: FC<SimpleEntityPlaceholderProps> = ({
  closeDialog,
  title,
  height = 'normal',
}) => {
  const style = height === 'normal' ? 'h-70' : 'h-20'

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{title}</Dialog.Title>

        <Dialog.Body className={style}>
          <Spinner expandCentered />
        </Dialog.Body>

        <Dialog.Footer className="flex justify-between">
          <SaveButton disabled />
          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
