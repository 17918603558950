import {
  BusinessSettingsType,
  ClientByIdType,
  Connections,
  useFetchBusinessSettings,
} from '@expane/data'
import { ErrorMessageType } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TreeMenuItem } from 'ui/TreeMenu'
import { TreeSelect } from 'ui/TreeSelect'
import { ExtendedSelectDropdownItem } from 'widgets/ClientDialog'

interface SelectPreferredConnectionsProps {
  onChange: () => void
  value: ExtendedSelectDropdownItem[]
  clientById: ClientByIdType | undefined
  className?: string
  required?: boolean
  errorMessage?: ErrorMessageType
  disabled?: boolean
}

export const SelectPreferredConnections: FC<SelectPreferredConnectionsProps> = ({
  onChange,
  value,
  clientById,
  className,
  required,
  errorMessage,
  disabled = false,
}) => {
  const { data: businessSettings } = useFetchBusinessSettings()
  const { t } = useTranslation()

  const preferredConnections = usePreferredConnectionsByClient(clientById, businessSettings)

  return (
    <TreeSelect
      type="MultiPickMode"
      items={preferredConnections}
      label={t('preferredConnections')}
      onSelected={onChange}
      selected={value}
      className={className}
      required={required}
      errorMessage={errorMessage}
      disabled={disabled}
    />
  )
}

const PREFERRED_CONNECTIONS = [
  { id: 1, name: 'Telegram', key: 'telegram' },
  { id: 2, name: 'Viber', key: 'viber' },
  { id: 3, name: 'Sms', key: 'sms' },
  { id: 4, name: 'mobileApp', key: 'expo' },
] as const

const usePreferredConnectionsByClient = (
  client: ClientByIdType | undefined,
  businessSettings: BusinessSettingsType | undefined,
): TreeMenuItem[] => {
  const { t } = useTranslation()

  return PREFERRED_CONNECTIONS.map(preferredConnection => {
    if (preferredConnection.key === 'telegram' && !client?.hasTelegram)
      return {
        ...preferredConnection,
        inactiveReason: t('preferredConnectionsHints.telegram'),
        disabled: true,
      }

    if (preferredConnection.key === 'viber' && !client?.hasViber)
      return {
        ...preferredConnection,
        inactiveReason: t('preferredConnectionsHints.viber'),
        disabled: true,
      }

    if (preferredConnection.key === 'sms' && !businessSettings?.smsToken)
      return {
        ...preferredConnection,
        inactiveReason: t('preferredConnectionsHints.sms'),
        disabled: true,
      }

    if (preferredConnection.key === 'expo') {
      return {
        ...preferredConnection,
        name: t(preferredConnection.name),
      }
    }

    return preferredConnection
  })
}

export const transformPreferredConnectionsForForms = (client: ClientByIdType): TreeMenuItem[] => {
  const {
    clientSettings: { preferredConnections },
  } = client

  if (!preferredConnections) return []

  const result: TreeMenuItem[] = []

  for (const key in preferredConnections) {
    if (preferredConnections[key]) {
      const connection = PREFERRED_CONNECTIONS.find(p => p.key === key)
      if (connection) result.push(connection)
    }
  }

  return result
}

export const transformFormValuesForMutation = (data: {
  preferredConnections: ExtendedSelectDropdownItem[]
}): Connections => {
  const preferredConnections: Connections = {
    sms: false,
    telegram: false,
    viber: false,
    expo: false,
  }
  for (const item of data.preferredConnections) {
    if (Object.keys(preferredConnections).includes(item.key)) preferredConnections[item.key] = true
  }

  return preferredConnections
}
