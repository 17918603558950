import { useFetchCurrentBusiness } from 'gql/business'
import { checkBusinessIsNotPaid } from '@expane/logic/business'
import { FC, PropsWithChildren, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CalendarCell, CalendarCellProps } from 'ui/Calendar'
import { useSnackbar } from '@expane/widgets'
import { useContextMenu } from 'logic/hooks/useContextMenu'

export const CalendarCellWithPlanBillingCheck: FC<PropsWithChildren<CalendarCellProps>> = props => {
  const { t } = useTranslation()
  const { data: currentBusiness } = useFetchCurrentBusiness()

  const [openSnackbar] = useSnackbar()

  const { onClick, onLongPress, contextMenuItems, ...restProps } = props

  const onClickWithBillingCheck = useCallback(() => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else onClick?.()
  }, [currentBusiness, onClick, openSnackbar, t])

  const onLongPressWithBillingCheck = useCallback(() => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else onLongPress?.()
  }, [currentBusiness, onLongPress, openSnackbar, t])

  const { contextMenu, handleContextMenu } = useContextMenu(
    contextMenuItems,
    props.onContextMenuItemClick,
  )

  return (
    <>
      <CalendarCell
        {...restProps}
        onClick={onClickWithBillingCheck}
        onLongPress={onLongPressWithBillingCheck}
        onContextMenu={handleContextMenu}
      />
      {contextMenu}
    </>
  )
}
