import { gql } from 'graphql-request'
import { clientBriefForCalendarFragment } from '../client/client.fragments'
import { employeeWithScheduleFragment } from '../employee/employee.fragments'
import { locationBriefFragment, locationByIdFragment } from '../location/location.fragments'
import { productBriefFragment } from '../product/product.fragments'
import { serviceBriefWithProductFragment, serviceFragment } from '../service/service.fragments'
import { transactionInBookingFragment } from '../transaction/transaction.fragments'

export const bookingAsClientFragment = gql`
  ${serviceFragment}
  ${locationBriefFragment}
  fragment bookingAsClientType on booking {
    id
    isGroupBooking
    isPartlyPaid
    startDate
    duration
    clientNote
    location {
      ...locationBriefType
    }
    bookingServices {
      service {
        ...serviceType
      }
    }
    service {
      ...serviceType
    }
    employee {
      id
      firstName
      lastName
      photo
    }
    bookingClients {
      clientId
    }
    review {
      clientId
    }
  }
`

export const bookingProductFragment = gql`
  fragment bookingProductType on bookingProduct {
    id
    productId
    quantity
    product {
      id
      photo
      name
      price
      unit
      criticalQuantity
    }
  }
`

export const bookingByIdFragment = gql`
  ${clientBriefForCalendarFragment}
  ${locationByIdFragment}
  ${employeeWithScheduleFragment}
  ${serviceBriefWithProductFragment}
  ${productBriefFragment}
  ${transactionInBookingFragment}
  ${bookingProductFragment}
  fragment bookingByIdType on booking {
    id
    duration
    startDate
    note
    status
    isGroupBooking
    canceledDate
    canceledReason
    isPaid
    branchId
    clientNote
    author {
      firstName
      lastName
    }
    client {
      ...clientBriefForCalendarType
    }
    bookingClients {
      id
      client {
        ...clientBriefForCalendarType
      }
    }
    location {
      ...locationByIdType
    }
    employee {
      ...employeeWithScheduleType
    }
    service {
      ...serviceBriefWithProductType
    }
    bookingServices {
      id
      service {
        ...serviceBriefWithProductType
      }
    }
    transactions {
      ...transactionInBookingType
    }
    bookingProducts {
      ...bookingProductType
    }
    salaryIssues {
      salaryTransactionId
    }
    unitedBooking {
      id
    }
    recurringBooking {
      id
    }
  }
`

export const bookingHistoryFragment = gql`
  fragment bookingHistoryType on booking {
    id
    isPaid
    isGroupBooking
    createdAt
    author {
      firstName
      lastName
    }
    startDate
    employee {
      firstName
      lastName
    }
    bookingServices {
      service {
        name
        id
        price
      }
    }
    service {
      id
      price
      name
    }
    note
    clientNote
    canceledReason
    canceledDate
    recurringBooking {
      id
    }
  }
`

export const bookingInReviewFragment = gql`
  fragment bookingInReviewType on booking {
    isGroupBooking
    service {
      name
    }
    bookingServices {
      service {
        name
      }
    }
    employee {
      firstName
      lastName
      photo
    }
  }
`
