export type MobileAppType = 'app' | 'client'
type Platform = 'ios' | 'android'

const itunesItemIds: Record<MobileAppType, number> = {
  app: 1570967413,
  client: 6698871064,
}

const androidPackageNames: Record<MobileAppType, string> = {
  app: 'pro.expane.app',
  client: 'pro.expane.client',
}

export const STORE_LINKS: Record<Platform, Record<MobileAppType, string>> = {
  ios: {
    client: `https://apps.apple.com/ua/app/expane/id${itunesItemIds.client}`,
    app: `https://apps.apple.com/ua/app/expane/id${itunesItemIds.app}`,
  },
  android: {
    client: `https://play.google.com/store/apps/details?id=${androidPackageNames.client}`,
    app: `https://play.google.com/store/apps/details?id=${androidPackageNames.app}`,
  },
}

export const REVIEW_STORE_LINKS: Record<Platform, Record<MobileAppType, string>> = {
  ios: {
    client: `https://apps.apple.com/app/apple-store/id${itunesItemIds.client}?action=write-review`,
    app: `https://apps.apple.com/app/apple-store/id${itunesItemIds.app}?action=write-review`,
  },
  android: {
    client: `https://play.google.com/store/apps/details?id=${androidPackageNames.client}&showAllReviews=true`,
    app: `https://play.google.com/store/apps/details?id=${androidPackageNames.app}&showAllReviews=true`,
  },
}
