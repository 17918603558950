import { queryClient, request, useMutation, reportGqlError } from '../../api'
import { gql } from 'graphql-request'
import { ServerSalarySettingInsertInput } from '../../generated/graphql-types'
import { EMPLOYEE_GROUPS_QUERY_KEY } from '../employeeGroup/queryKeys'
import { SALARY_RATE_SETTINGS_QUERY_KEY, SALARY_SETTINGS_QUERY_KEY } from './queryKeys'
import { EMPLOYEES_QUERY_KEY } from '../employee/queryKeys'

export type SalarySettingInsertInput = ServerSalarySettingInsertInput &
  Required<
    Pick<
      ServerSalarySettingInsertInput,
      'employeeId' | 'rateType' | 'rateValue' | 'start' | 'branchId'
    >
  >

export const useCreateEmployeeSalarySettings = () => {
  return useMutation(
    async (
      salarySettingInsertInput: SalarySettingInsertInput,
    ): Promise<{
      insertSalarySetting: {
        id: number
      }
    }> => {
      return await request(
        gql`
          mutation ($salarySettingInsertInput: salarySetting_insert_input!) {
            insertSalarySetting(object: $salarySettingInsertInput) {
              id
            }
          }
        `,
        { salarySettingInsertInput },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(SALARY_SETTINGS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(SALARY_RATE_SETTINGS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEE_GROUPS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEES_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}

export const useCreateEmployeesSalarySettings = () => {
  return useMutation(
    async (
      salarySettingsInsertInputs: SalarySettingInsertInput[],
    ): Promise<{
      insertMultipleSalarySetting: {
        affectedRows: number
      }
    }> => {
      return await request(
        gql`
          mutation ($salarySettingsInsertInputs: [salarySetting_insert_input!]!) {
            insertMultipleSalarySetting(objects: $salarySettingsInsertInputs) {
              affectedRows: affected_rows
            }
          }
        `,
        { salarySettingsInsertInputs },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(SALARY_SETTINGS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(SALARY_RATE_SETTINGS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEE_GROUPS_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}
