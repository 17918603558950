import {
  mapDayToScheduleTemplateFormData,
  SCHEDULE_RANGE_MAX,
  SCHEDULE_RANGE_MIN,
  SCHEDULE_RANGE_STEP,
  ScheduleTemplateFormData,
} from '@expane/logic/schedule'
import { convertFloatToMinutes } from '@expane/logic/utils'
import { Input } from '@expane/ui'
import { FC, useMemo } from 'react'
import { Controller, useForm, UseFormGetValues, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ApplyButton } from 'widgets/Buttons'

interface Props {
  label?: string
  setValue: UseFormSetValue<ScheduleTemplateFormData>
  getValues: UseFormGetValues<ScheduleTemplateFormData>
  disabled: boolean
}

export const QuickFixTimeInput: FC<Props> = ({ label, setValue, getValues, disabled }) => {
  const { t } = useTranslation()

  const { control, handleSubmit } = useForm()

  const MIN_TIME = useMemo(
    () => transformStringToTimeInputString(convertFloatToMinutes(SCHEDULE_RANGE_MIN)),
    [],
  )
  const MAX_TIME = useMemo(
    () => transformStringToTimeInputString(convertFloatToMinutes(SCHEDULE_RANGE_MAX)),
    [],
  )

  const watchedStart = useWatch({ control, name: 'start', defaultValue: MIN_TIME })
  const watchedFinish = useWatch({ control, name: 'finish', defaultValue: MAX_TIME })

  return (
    <div className="flex justify-end w-full items-center">
      {label && <p className="block mr-3 text-sm leading-3 font-medium text-gray-500">{label}</p>}

      <Controller
        name="start"
        control={control}
        defaultValue={MIN_TIME}
        rules={{
          validate: value =>
            validateTime({
              value,
              valueToCompare: watchedFinish,
              type: 'min',
            }),
        }}
        render={({ field, fieldState: { error } }) => (
          <Input
            placeholder={MIN_TIME}
            containerClassName="w-24 mr-3"
            disabled={disabled}
            type="time"
            errorMessage={{
              isShown: Boolean(error),
              text: t('scheduleQuickFixTimeErrors.timeIsLessThanMin', {
                time: MIN_TIME,
                step: convertFloatToMinutes(SCHEDULE_RANGE_STEP),
              }),
              reserveIndent: false,
            }}
            {...field}
          />
        )}
      />

      <Controller
        name="finish"
        control={control}
        rules={{
          validate: value =>
            validateTime({
              value,
              valueToCompare: watchedStart,
              type: 'max',
            }),
        }}
        defaultValue={MAX_TIME}
        render={({ field, fieldState: { error } }) => (
          <Input
            placeholder={MAX_TIME}
            containerClassName="w-24 mr-3"
            disabled={disabled}
            type="time"
            errorMessage={{
              isShown: Boolean(error),
              text: t('scheduleQuickFixTimeErrors.timeIsBiggerThanMax', {
                time: MAX_TIME,
                step: convertFloatToMinutes(SCHEDULE_RANGE_STEP),
              }),
              reserveIndent: false,
            }}
            {...field}
          />
        )}
      />

      <ApplyButton
        type="outline"
        onClick={handleSubmit(data => {
          // узнаем значения по всем дням, для того чтобы не добавлять значение там где оно уже null (выключен день)
          const values = getValues()

          for (let i = 0; i < 7; i++) {
            if (values[i] !== null)
              setValue(mapDayToScheduleTemplateFormData[i], transformData(data), {
                shouldDirty: true,
              })
          }
        })}
        disabled={disabled}
      />
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformData = (data: { start: string; finish: string } | { [x: string]: any }) => {
  const transformedData = Object.values(data)

  return transformedData.map(el => {
    const splitStr = el.split(':')
    if (parseInt(splitStr[1]) >= 30) return parseInt(el) + 0.5
    if (parseInt(splitStr[1]) < 30) return parseInt(el)

    return el
  })
}

const convertTimeToNumber = (time: string) => {
  return parseInt(time.substring(0, 2))
}

const validateTime = (dto: { value: string; valueToCompare: string; type: 'min' | 'max' }) => {
  const { value, valueToCompare, type } = dto

  const convertedValue = convertTimeToNumber(value)
  const convertedValueToCompare = convertTimeToNumber(valueToCompare)

  if (type === 'min')
    return (
      convertedValue >= SCHEDULE_RANGE_MIN &&
      convertedValue <= convertedValueToCompare - SCHEDULE_RANGE_STEP
    )

  if (type === 'max')
    return (
      convertedValue <= SCHEDULE_RANGE_MAX &&
      convertedValue >= convertedValueToCompare + SCHEDULE_RANGE_STEP
    )
}

const transformStringToTimeInputString = (str: string) => {
  const colonIndex = str.indexOf(':') // находим позицию двоеточия в строке

  let newStr: string

  // если перед двоеточием только один символ
  // добавляем "0" перед строкой
  if (colonIndex === 1) {
    newStr = '0' + str
  } else {
    newStr = str
  }

  return newStr
}
