import { ServerProductType } from '@expane/data'
import { ProductsAmount } from '../movement'

export type ProductWithLeftover = ServerProductType & {
  leftover: number
}

export const generateProductsWithLeftoversList = (
  products: ServerProductType[] | undefined,
  leftovers: ProductsAmount,
) => {
  if (!products) return []
  const leftoversKeys = Object.keys(leftovers)

  return leftoversKeys.reduce((acc, productId) => {
    const product = products.find(product => product.id === Number(productId))

    if (product) acc.push({ ...product, leftover: leftovers[productId] })
    return acc
  }, [] as ProductWithLeftover[])
}

export const isCriticalProductQuantity = (product: ProductWithLeftover) =>
  product.leftover <= product.criticalQuantity
