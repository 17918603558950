import {
  BookingUnion,
  ClientBriefsAndCardsType,
  ClientPhones,
  ExtendedEmployee,
  ServerRecurringBookingByIdType,
  useCancelBookings,
  useFetchBookingsOfDate,
  useFetchClientsBriefsAndCards,
  useFetchClientsPhones,
  useFetchCurrentBranchTimezone,
  useFetchExtendedEmployees,
  useUpdateBookingsStatuses,
} from '@expane/data'
import { createCurrentDate } from '@expane/date'
import { BOOKING_STATUSES, getBookingsThatCanBeCanceled } from '@expane/logic/booking'
import { addPhonesToClients } from '@expane/logic/client'
import { permissions } from '@expane/logic/permission'
import { findById, transformPersonName, translateItemsName } from '@expane/logic/utils'
import {
  Button,
  Checkbox,
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Modal,
  RadioGroupOption,
  PlaceholderInput,
  SelectDropdown,
  Table,
  usePopupOpenState,
  useShowConfirmationPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { ColumnDef } from '@tanstack/react-table'
import { useFetchMyPermissions } from 'gql/employee'
import { bookingCountDeclension } from 'i18n/declensions'
import { filterEmployeesProvideServices } from 'logic/employees'
import { useShowCancelBookingPopup } from 'logic/hooks/popup/useShowCancelBookingPopup'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { transformPersonsForSelect } from 'logic/utils'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkDoneSharp, IoTrashBin } from 'react-icons/io5'
import { store } from 'store'
import { RangeDatePicker } from 'ui/RangeDatePicker'
import { useOpenBookingDialog } from 'widgets/BookingDialog'
import { useOpenBookingMultiServiceDialog } from 'widgets/BookingMultiServicesDialog'
import { useBookingPaymentDialog } from 'widgets/BookingPaymentDialog'
import { BookingsSelectionList } from 'widgets/BookingsSelectionDialog/BookingsSelectionList'
import { PayButton } from 'widgets/Buttons'
import { ClientSelectDropdown } from 'widgets/ClientSelectDropdown'
import { useOpenGroupBookingDialog } from 'widgets/GroupBookingDialog'
import {
  bookingStatusItems,
  filterBookings,
  getBookingsFromSelectedBookingsForCancel,
  getBookingsForChangeStatusToDone,
  getBookingsForPaymentSubmit,
  validateCancel,
  validateChangeStatusToDone,
  validatePayment,
  getAreSelectedBookings,
} from './logic'
import { RecurringEditType, useOpenRecurringEditDialog } from 'widgets/RecurringEditDialog'

export interface BookingsSelectionDialogProps {
  clientId?: number | null
  date?: Date
  closeDialog: () => void
}

const BookingsSelectionDialog: FC<BookingsSelectionDialogProps> = props => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { data: clients, isLoading: isLoadingClients } = useFetchClientsBriefsAndCards(
    timezone,
    branchId,
  )
  const { data: clientsPhones, isLoading: isLoadingClientsPhones } = useFetchClientsPhones()
  const { data: employees, isLoading: isLoadingEmployees } = useFetchExtendedEmployees(
    timezone,
    branchId,
  )
  const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()

  const isLoading =
    isLoadingClients ||
    isLoadingClientsPhones ||
    isLoadingEmployees ||
    isLoadingMyPermissions ||
    !timezone

  const isNoData = !clients || !clientsPhones || !employees || !myPermissions

  useErrorOpeningDialog(!isLoading && isNoData, props.closeDialog)
  if (!isLoading && isNoData) return null

  return (
    <Modal close={props.closeDialog}>
      <Dialog>
        {isLoading ? (
          <BookingSelectionDialogPlaceholder closeDialog={props.closeDialog} />
        ) : (
          <BookingSelectionLogicDialog
            clients={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              clients!
            }
            clientsPhones={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              clientsPhones!
            }
            employees={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              employees!
            }
            myPermissions={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              myPermissions!
            }
            timezone={timezone}
            {...props}
          />
        )}
      </Dialog>
    </Modal>
  )
}

interface BookingSelectionLogicDialogProps extends BookingsSelectionDialogProps {
  clients: ClientBriefsAndCardsType[]
  clientsPhones: ClientPhones[]
  employees: ExtendedEmployee[]
  myPermissions: string[]
  timezone: string
}

const BookingSelectionLogicDialog: FC<BookingSelectionLogicDialogProps> = ({
  clients,
  clientsPhones,
  employees,
  myPermissions,
  closeDialog,
  clientId,
  date,
  timezone,
}) => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()
  const formatDate = useDateFormatting()

  const [customPeriod, setCustomPeriod] = useState(() => {
    const initialDate = date ?? createCurrentDate(timezone)
    return [initialDate, initialDate]
  })
  const [pickedClientId, setPickedClientId] = useState<number | null>(clientId ?? null)
  const [pickedStatus, setPickedStatus] = useState<number | null>(null)
  const [pickedEmployeeId, setPickedEmployeeId] = useState<number | null>(null)

  const { control, reset, handleSubmit, setValue, getValues } =
    useForm<BookingsSelectionDialogFormType>({
      defaultValues: {
        selectedBookings: {},
      },
    })

  const [openSnackbar] = useSnackbar()
  const { cancelBookingModal, showConfirmCancelBooking } = useShowCancelBookingPopup()
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const clientsWithPhones = addPhonesToClients(clients, clientsPhones)

  const employeesWithServices = filterEmployeesProvideServices(employees)
  const employeesForSelect = transformPersonsForSelect(employeesWithServices)

  const { data: allBookings, isLoading: areBookingsLoading } = useFetchBookingsOfDate(
    customPeriod,
    timezone,
    branchId,
  )
  const ableToEditPast = myPermissions.includes(permissions.booking.editPast)

  const { mutateAsync: cancelBookings } = useCancelBookings()
  const { mutateAsync: updateBookingsStatuses } = useUpdateBookingsStatuses()

  const filteredBookings = useMemo<BookingUnion[]>(
    () =>
      allBookings
        ? filterBookings({
            bookings: allBookings,
            pickedEmployeeId,
            pickedClientId,
            pickedStatus,
          })
        : [],
    [allBookings, pickedEmployeeId, pickedClientId, pickedStatus],
  )

  const { openPaymentDialog, paymentDialog } = useBookingPaymentDialog()
  const { openEditBookingDialog, bookingDialog } = useOpenBookingDialog()
  const { openEditGroupBookingDialog, groupBookingDialog } = useOpenGroupBookingDialog()
  const { openEditBookingMultiServicesDialog, bookingMultiServicesDialog } =
    useOpenBookingMultiServiceDialog()

  const { recurringEditDialog, openRecurringEditDialog } = useOpenRecurringEditDialog()

  const mutateCancel = async <T extends { id: number }>(
    selectedBookingsForCancel: T[],
    reason: string | null,
  ) => {
    const result = await cancelBookings({
      ids: selectedBookingsForCancel.map(booking => booking.id),
      canceledReason: reason ?? null,
    })

    if (result.updateBookings.affectedRows) {
      openSnackbar(t('cancelBookings.successfully'), 'success', 3000)
    } else {
      openSnackbar(t('submitError'), 'error', 3000)
    }
  }

  const cancelExistingRecurringBooking = async (
    recurringBookings: ServerRecurringBookingByIdType[],
    type: RadioGroupOption | undefined,
    reason: string | null,
  ) => {
    if (type && type.id === RecurringEditType.single) {
      const firstSelectedRecurringBooking = recurringBookings[0]

      await mutateCancel([firstSelectedRecurringBooking], reason)
    } else {
      const recurringBookingsToCancel = getBookingsThatCanBeCanceled(recurringBookings)

      await mutateCancel(recurringBookingsToCancel, reason)
    }
  }

  const handleCancel = ({ selectedBookings }: BookingsSelectionDialogFormType) => {
    if (!getAreSelectedBookings(selectedBookings)) {
      openSnackbar(t('cancelValidation.noBookings'), 'error', 3000)
      return
    }

    const selectedBookingsForCancel = getBookingsFromSelectedBookingsForCancel(
      filteredBookings,
      selectedBookings,
    )

    const firstSelectedBookingRecurring = selectedBookingsForCancel[0]
    const firstSelectedBookingRecurringId = selectedBookingsForCancel[0].recurringBooking?.id

    // если выбрано: один букинг и он из повторяющихся
    if (selectedBookingsForCancel.length === 1 && firstSelectedBookingRecurringId) {
      openRecurringEditDialog({
        recurringId: firstSelectedBookingRecurringId,
        startDate: firstSelectedBookingRecurring.startDate,
        onSave: (type, recurringBookings) =>
          showConfirmCancelBooking({
            onConfirm: reason => {
              cancelExistingRecurringBooking(recurringBookings, type, reason)
            },
          }),
      })
    } else {
      const validationErrorMessage = validateCancel({
        t,
        timezone,
        ableToEditPast,
        bookingsForSubmit: selectedBookingsForCancel,
        startOfPeriod: customPeriod[0],
      })

      if (validationErrorMessage) {
        openSnackbar(validationErrorMessage, 'error', 5000)
        return
      }

      showConfirmCancelBooking({
        onConfirm: reason => mutateCancel(selectedBookingsForCancel, reason),
        description: t('cancelBookings.question', {
          count: selectedBookingsForCancel.length,
          declension: bookingCountDeclension(selectedBookingsForCancel.length, t),
        }),
      })
    }
  }

  const handlePayment = ({ selectedBookings }: BookingsSelectionDialogFormType) => {
    const bookingsForSubmit = getBookingsForPaymentSubmit(filteredBookings, selectedBookings)

    const validationErrorMessage = validatePayment({
      clientId: pickedClientId,
      bookingsForSubmit,
      t,
      filteredBookings,
      areSelectedBookings: getAreSelectedBookings(selectedBookings),
    })

    if (validationErrorMessage) {
      openSnackbar(validationErrorMessage, 'error', 5000)
      return
    }

    if (pickedClientId)
      openPaymentDialog({
        bookingsIds: bookingsForSubmit.map(({ id }) => id),
        clientId: pickedClientId,
      })
  }

  const handleChangeStatusToDone = (data: BookingsSelectionDialogFormType) => {
    const { bookingsForChangeStatus, bookingsWithStatusDone } = getBookingsForChangeStatusToDone(
      filteredBookings,
      data.selectedBookings,
      timezone,
    )

    const validationErrorMessage = validateChangeStatusToDone(
      bookingsForChangeStatus,
      data.selectedBookings,
      t,
    )

    if (validationErrorMessage) {
      openSnackbar(validationErrorMessage, 'error', 5000)
      return
    }

    const mutateUpdateStatuses = async () => {
      const result = await updateBookingsStatuses({
        ids: bookingsForChangeStatus.map(({ id }) => id),
        status: BOOKING_STATUSES.done,
      })

      if (result.updateBookingStatuses.affectedRows) {
        openSnackbar(t('makeDone.updateSuccess'), 'success', 3000)
      } else {
        openSnackbar(t('submitError'), 'error', 3000)
      }
    }

    showConfirmation({
      onConfirm: mutateUpdateStatuses,
      title: t('warning'),
      description: (
        <div className="w-144">
          {/* Показываем строку предупреждения если есть выбранные букинги, у которых нельзя изменить статус */}
          {bookingsForChangeStatus.length !==
            Object.keys(data.selectedBookings).length - bookingsWithStatusDone.length && (
            <p className="mb-2">{t('makeDone.warning')}</p>
          )}
          <p>{t('makeDone.question')}</p>
          <ul className="overflow-y-auto max-h-52">
            {bookingsForChangeStatus.map((booking, index) => (
              <li key={booking.id} className="pl-2">
                {index + 1}) {formatDate('shortDateTime', booking.startDate)}
                {booking.isGroupBooking
                  ? ' - ' + t('groupBooking.name')
                  : booking.client && ' - ' + transformPersonName(booking.client)}
                {' - ' + booking.location.name}
              </li>
            ))}
          </ul>
        </div>
      ),
    })
  }

  useEffect(() => {
    // reset selection on filter change
    reset({
      selectedBookings: {},
    })
  }, [filteredBookings, reset])

  return (
    <>
      <Dialog.Title>{t('selectionOfBookings')}</Dialog.Title>
      <Dialog.Body className="w-256">
        <div className="flex flex-col">
          <div className="flex w-full mb-2">
            <RangeDatePicker
              timezone={timezone}
              label={t('dateTitle')}
              value={customPeriod}
              onChange={setCustomPeriod}
              className="w-1/4 mr-2"
            />

            <ClientSelectDropdown
              clients={clientsWithPhones}
              className="grow mr-2"
              value={pickedClientId}
              onChange={setPickedClientId}
              isClearable
            />

            <SelectDropdown
              className="w-1/5 mr-2"
              label={t('employee.name')}
              value={pickedEmployeeId}
              onSelectChange={setPickedEmployeeId}
              items={employeesForSelect}
              isClearable
            />

            <SelectDropdown
              label={t('paymentState')}
              className="w-1/6"
              value={pickedStatus}
              onSelectChange={setPickedStatus}
              items={translateItemsName(bookingStatusItems, t)}
              isClearable
            />
          </div>

          <div className="h-96">
            <BookingsSelectionList
              bookings={filteredBookings}
              control={control}
              isLoading={areBookingsLoading}
              setValue={setValue}
              getValues={getValues}
              onRowClick={id => {
                const booking = findById(id, allBookings)

                if (booking) {
                  if (booking.unitedBooking?.id)
                    openEditBookingMultiServicesDialog(booking.unitedBooking.id)
                  else {
                    if (!booking.isGroupBooking) openEditBookingDialog(booking.id)
                    else openEditGroupBookingDialog(booking.id)
                  }
                }
              }}
            />
          </div>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <div className="flex gap-2 justify-between items-center w-full">
          <div>
            <Button
              disabled={filteredBookings.length === 0}
              onClick={handleSubmit(handleCancel)}
              type="danger"
              Icon={IoTrashBin}
            >
              {t('cancelBookings.action')}
            </Button>

            <Button
              disabled={filteredBookings.length === 0}
              onClick={handleSubmit(handleChangeStatusToDone)}
              type="outline"
              Icon={IoCheckmarkDoneSharp}
              className={'ml-2'}
            >
              {t('makeDone.action')}
            </Button>

            <PayButton
              onClick={handleSubmit(handlePayment)}
              disabled={filteredBookings.length === 0}
              className={'ml-2'}
            />
          </div>

          <CloseButton onClick={closeDialog} />
        </div>
      </Dialog.Footer>

      {recurringEditDialog}
      {cancelBookingModal}
      {paymentDialog}
      {bookingDialog}
      {groupBookingDialog}
      {confirmationModal}
      {bookingMultiServicesDialog}
    </>
  )
}

const BookingSelectionDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  const columns = useMemo<ColumnDef<{ id: number }>[]>(
    () => [
      {
        id: 'select',
        header: () => <Checkbox containerClassName="ml-2" disabled />,
        size: 40,
      },
      {
        id: 'startDate',
        header: t('dateTitle'),
        size: 120,
      },
      {
        id: 'client',
        header: t('client.name'),
        size: 170,
      },
      {
        id: 'location',
        header: t('location.name'),
        size: 170,
      },
      {
        id: 'employee',
        header: t('employee.name'),
        size: 170,
      },
      {
        id: 'totalSum',
        header: () => <div className="text-right">{t('toPay')}</div>,
      },
      {
        id: 'isPaid',
        header: () => <span className="text-right w-full">{t('paid')}</span>,
        size: 80,
      },
    ],
    [t],
  )
  return (
    <>
      <Dialog.Title>{t('selectionOfBookings')}</Dialog.Title>
      <Dialog.Body className="w-256">
        <div className="flex flex-col">
          <div className="flex w-full mb-2">
            <PlaceholderInput label={t('dateTitle')} className="w-1/4 mr-2" />
            <PlaceholderInput label={t('client.name')} className="grow mr-2" />
            <PlaceholderInput label={t('employee.name')} className="w-1/5 mr-2" />
            <PlaceholderInput label={t('paymentState')} className="w-1/6" />
          </div>

          <div className="h-96">
            <Table isLoading columns={columns} data={undefined} />
          </div>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}

export type SelectedBookingType = { [index: string]: boolean }

export type BookingsSelectionDialogFormType = {
  selectedBookings: SelectedBookingType
}

export const useBookingsSelectionDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogDate = useRef<Date | undefined>(undefined)
  const dialogClientId = useRef<number | null>(null)
  const onCloseDialog = useRef<() => void>()

  const openBookingsSelectionDialog = (
    props?: Omit<BookingsSelectionDialogProps, 'closeDialog'> & { onClose?: () => void },
  ) => {
    dialogClientId.current = props?.clientId ?? null
    dialogDate.current = props?.date
    onCloseDialog.current = props?.onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const bookingSelectionDialog = isOpen ? (
    <BookingsSelectionDialog
      closeDialog={closeDialog}
      date={dialogDate.current}
      clientId={dialogClientId.current}
    />
  ) : null

  return {
    openBookingsSelectionDialog,
    bookingSelectionDialog,
  }
}
