import { AsYouType, onChangePhoneInput, splitPhone } from '@expane/logic/phone'
import { searchCountries } from '@expane/logic/phone/countryList'
import {
  ErrorMessage,
  ErrorMessageType,
  FadeInTranslateTop,
  getCommonInputStyles,
  Input,
  InputLabel,
  modalsHTMLElement,
  useHandleClickOutside,
  usePopupOpenState,
} from '@expane/ui'
import { useFloating } from '@floating-ui/react-dom'
import { ChangeEvent, FC, forwardRef, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoCaretDownSharp, IoSearch } from 'react-icons/io5'

export interface PhoneInputProps {
  placeholder?: string
  containerClassName?: string
  label?: string
  required?: boolean
  hint?: string
  disabled?: boolean
  errorMessage?: ErrorMessageType
  value: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: any) => void
  onBlur?: () => void
  customModal?: HTMLElement | null
}

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>((props, ref) => {
  const {
    placeholder,
    containerClassName,
    label,
    hint,
    required,
    disabled,
    errorMessage,
    onChange,
    value,
    onBlur,
    customModal,
    ...restProps
  } = props

  const inputStyle =
    getCommonInputStyles(errorMessage?.isShown, disabled) +
    'w-full h-full px-2 text-sm border-2 rounded-r-lg transition-all focus:ring-0'

  let countryCodeStyle =
    'w-18 h-full flex items-center justify-between px-1 border-2 rounded-l-lg text-sm cursor-pointer'

  if (errorMessage?.isShown) {
    countryCodeStyle += ' border-error-500 bg-error-500 outline-none focus:outline-none '
  } else {
    countryCodeStyle += disabled
      ? ' border-input-disabled-color bg-gray-50 dark:bg-gray-600 text-placeholder-color pointer-events-none'
      : ' bg-btn-primary border-primary-500 hover:border-primary-700 text-btn-primary'
  }

  const phoneButtonRef = useRef(null)
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null)
  const { isOpen, closePopup, openPopup } = usePopupOpenState()

  useHandleClickOutside([phoneButtonRef.current, menuRef], closePopup, isOpen)

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  useLayoutEffect(() => {
    reference(phoneButtonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, phoneButtonRef.current])
  useLayoutEffect(() => {
    floating(menuRef)
  }, [floating, menuRef])

  const inputRef = useRef<HTMLInputElement | null>(null)

  const onOptionClicked = (code: string) => {
    onChange(new AsYouType().input(code))

    closePopup()
    inputRef.current?.focus()
  }

  const { dialCode, phone } = splitPhone(value)

  return (
    <div className={containerClassName}>
      <InputLabel label={label} hint={hint} required={required} />

      <div className="flex">
        <div className="relative">
          <div className={countryCodeStyle} ref={phoneButtonRef} onClick={openPopup}>
            <div className="grow flex items-center justify-center">
              <span className="px-1">{dialCode}</span>
            </div>
            <IoCaretDownSharp size="0.5rem" className="shrink-0" />
          </div>
        </div>

        <input
          ref={ref}
          onChange={e => {
            onChangePhoneInput(e.target.value, dialCode, onChange)
          }}
          type="tel"
          className={inputStyle}
          placeholder={placeholder}
          value={phone}
          disabled={disabled}
          onBlur={onBlur}
          {...restProps}
        />
      </div>

      <ErrorMessage errorMessage={errorMessage} />
      {isOpen &&
        createPortal(
          <div
            ref={setMenuRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <DialCodeMenu onOptionCLicked={onOptionClicked} close={closePopup} />
          </div>,
          customModal ?? modalsHTMLElement,
        )}
    </div>
  )
})

const DialCodeMenu: FC<{ onOptionCLicked: (dialCode: string) => void; close: () => void }> = ({
  onOptionCLicked,
  close,
}) => {
  const { i18n } = useTranslation()

  const [searchValue, setSearchValue] = useState<string>('')
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const filteredCountries = searchCountries(searchValue, i18n.language)

  return (
    <FadeInTranslateTop
      className="mt-1.5 border-2 border-primary-400 bg-block rounded-md"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <Input
        value={searchValue}
        onChange={handleInput}
        containerClassName="m-1"
        Icon={IoSearch}
        height="medium"
      />
      <div className="overflow-auto max-h-40 mr-1">
        {filteredCountries.map(country => (
          <button
            key={country.code}
            className="flex justify-between w-64 items-center rounded-md mx-1 px-1 py-1 text-sm text-primary-500 bg-hover hover:text-primary-700 transition-all"
            role="menuitem"
            onClick={() => {
              onOptionCLicked(country.dialCode)
              close()
            }}
          >
            <div className="mr-1">{country.flag}</div>
            <div className="line-clamp-1">{country[i18n.language] ?? country.en}</div>
            <div className="ml-1">{country.dialCode}</div>
          </button>
        ))}
      </div>
    </FadeInTranslateTop>
  )
}
