import {
  BookingUnion,
  MOVEMENT_TYPES,
  PaymentTransaction,
  ServerMovementProductInsertInput,
  ServerProductType,
  ServerTransactionInsertInput,
  TRANSACTION_TYPES,
} from '@expane/data'
import { calcDiscountSum, DEFAULT_DISCOUNT, DiscountInputInfo } from '../../utils'
import { convertUnitValueFromServer } from '../../product'
import { countProductAmountsInTransactions, getIsProductPaid } from '../../booking'

export type ConsumableDto = {
  id: number
  productId: number
  quantity: string
  name: string
  price: number
  discount: DiscountInputInfo
  bookingId: number
  unit: number
}

export const calcConsumableTotalSum = (consumablesDto: ConsumableDto[]) => {
  const result = consumablesDto.reduce((sum, item) => {
    const convertedQuantity = convertUnitValueFromServer(Number(item.quantity), item.unit)

    const itemDiscount = calcDiscountSum({
      discount: item.discount,
      price: item.price,
      quantity: convertedQuantity,
    })
    const totalPrice = item.price * convertedQuantity

    return sum + (totalPrice - itemDiscount)
  }, 0)

  return result
}

export const getOptionalConsumables = (
  items: ConsumableDto[],
): ServerMovementProductInsertInput[] =>
  items.map(consumable => {
    const discount = calcDiscountSum({
      price: consumable.price,
      discount: consumable.discount,
    })
    return {
      price: consumable.price - discount,
      productId: consumable.productId,
      quantity: Number(consumable.quantity),
      discount: discount !== 0 ? discount : null,
    }
  })

export const formConsumablePaymentTransaction = ({
  amount,
  bookingId,
  clientId,
  employeeId,
  fromStorageId,
  optionalConsumablesForPayment,
  debt,
  branchId,
}: {
  // If greater than 0, then make payment in credit
  debt: number
  amount: number
  bookingId: number
  clientId: number
  employeeId: number | undefined
  fromStorageId: number
  optionalConsumablesForPayment: ServerMovementProductInsertInput[]
  branchId: number
}): PaymentTransaction => {
  const paymentIsInCredit = debt > 0
  const childTransactions: ServerTransactionInsertInput[] | null =
    paymentIsInCredit && amount > debt
      ? [
          {
            amount: amount - debt,
            type: TRANSACTION_TYPES.repaymentDeposit.id,
            typeVariation:
              TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByClientBalance.id,
            clientId,
            branchId,
          },
        ]
      : null

  return {
    amount,
    type: paymentIsInCredit ? TRANSACTION_TYPES.paymentInCredit.id : TRANSACTION_TYPES.payment.id,
    typeVariation: TRANSACTION_TYPES.payment.variations.product.id,
    bookingId,
    clientId,
    employeeId,
    movement: {
      data: {
        fromStorageId,
        fulfilled: true,
        movementProducts: {
          data: optionalConsumablesForPayment,
        },
        type: MOVEMENT_TYPES.consumablesWriteOff.id,
        number: '',
        branchId,
      },
    },
    childTransactions: childTransactions ? { data: childTransactions } : null,
    branchId,
  }
}

export const prepareConsumablesDto = (
  bookings: BookingUnion[] | undefined,
  products: ServerProductType[] | undefined,
): ConsumableDto[] => {
  if (!bookings || bookings.length === 0 || !products) return []

  const result: ConsumableDto[] = []

  for (const booking of bookings) {
    const paymentTransactions = booking.transactions.filter(
      transaction => transaction.type === TRANSACTION_TYPES.payment.id,
    )
    const paidProductAmounts = countProductAmountsInTransactions(paymentTransactions)

    const refundTransactions = booking.transactions.filter(
      transaction => transaction.type === TRANSACTION_TYPES.refund.id,
    )
    const refundedProductAmounts = countProductAmountsInTransactions(refundTransactions)

    booking.bookingProducts.forEach(({ productId, quantity }) => {
      if (getIsProductPaid({ productId, quantity }, paidProductAmounts, refundedProductAmounts))
        return

      const quantityToPay =
        quantity - ((paidProductAmounts[productId] ?? 0) - (refundedProductAmounts[productId] ?? 0))
      const product = products.find(product => product.id === productId)

      if (product)
        result.push({
          id: product.id,
          name: product.name,
          productId,
          price: product.price,
          bookingId: booking.id,
          quantity: quantityToPay.toString(),
          discount: DEFAULT_DISCOUNT,
          unit: product.unit,
        })
    })
  }

  return result
}
