import { useFetchCurrentBranchTimezone } from '@expane/data'
import {
  createCurrentDate,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from '@expane/date'
import { translateItemsName } from '@expane/logic/utils'
import { HorizontalButtonSwitch } from '@expane/ui'
import { DateTimePicker, PickerType } from '@expane/widgets'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { RangeDatePicker } from 'ui/RangeDatePicker'

export type DatePickerType = PickerType | 'custom'

type UseCustomDatePickerOptions = {
  initialType?: DatePickerType
  containerClassName?: string
  datePickerClassName?: string
  defaultPeriod?: Date[]
}

export const useCustomDatePicker = (options?: UseCustomDatePickerOptions) => {
  const { t } = useTranslation()
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const initialType = options?.initialType ?? 'month'
  const containerClassName = options?.containerClassName
  const datePickerClassName = options?.datePickerClassName
  const defaultPeriod = options?.defaultPeriod

  const [datePickerType, setDatePickerType] = useState<DatePickerType>(initialType)
  const [period, setPeriod] = useState<Date[]>(defaultPeriod ?? [createCurrentDate(timezone)])

  const start = datePickerType === 'custom' ? period[0] : getStartDate(period[0], datePickerType)
  const end = datePickerType === 'custom' ? period[1] : getEndDate(start, datePickerType)

  const changeDatePickerType = (type: DatePickerType) => {
    const now = createCurrentDate(timezone)

    if (type === 'date') setPeriod([now])
    if (type === 'custom')
      setPeriod([
        startOfWeek(now, {
          weekStartsOn: 1,
        }),
        endOfWeek(now, {
          weekStartsOn: 1,
        }),
      ])
    setDatePickerType(type)
  }

  const reset = () => {
    setDatePickerType(initialType)
    setPeriod([createCurrentDate(timezone)])
  }

  const customDatePicker = timezone ? (
    <div className={'flex ' + (containerClassName ?? '')}>
      <div className={'w-56 mr-2 ' + (datePickerClassName ?? '')}>
        {datePickerType === 'custom' ? (
          <RangeDatePicker timezone={timezone} value={period} onChange={setPeriod} />
        ) : (
          <DateTimePicker
            timezone={timezone}
            showQuickButtons
            nextPreviousButtons
            value={period[0]}
            onChange={value => setPeriod([value])}
            type={datePickerType}
          />
        )}
      </div>
      <HorizontalButtonSwitch
        value={datePickerType}
        onChange={type => changeDatePickerType(type as DatePickerType)}
        options={translateItemsName(CALENDAR_OPTIONS, t)}
      />
    </div>
  ) : null

  return { customDatePicker, period: { start, end }, datePickerType, reset }
}

export const CALENDAR_OPTIONS: { id: DatePickerType; name: string }[] = [
  { id: 'date', name: 'calendarPeriod.day' },
  { id: 'week', name: 'calendarPeriod.week' },
  { id: 'month', name: 'calendarPeriod.month' },
  { id: 'custom', name: 'calendarPeriod.period' },
]

const getEndDate = (fromDate: Date, type: PickerType) => {
  if (type === 'month') return endOfMonth(fromDate)
  if (type === 'week')
    return endOfWeek(fromDate, {
      weekStartsOn: 1,
    })
  return endOfDay(fromDate)
}

const getStartDate = (date: Date, type: PickerType) => {
  if (type === 'month') return startOfMonth(date)
  if (type === 'week')
    return startOfWeek(date, {
      weekStartsOn: 1,
    })
  return startOfDay(date)
}
