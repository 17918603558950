import { gql } from 'graphql-request'
import { reportError, reportGqlError, request, useQuery } from '../../api'
import { ServerPromoCodeType } from '../../generated/graphql-types'
import { promoCodeAsReferrerFragment, promoCodeFragment } from './promoCode.fragments'
import { BUSINESS_PROMO_CODE_QUERY_KEY, PROMO_CODE_QUERY_KEY } from './queryKeys'
import { BusinessPromoCodeViewType } from './logic'
import { AS_REFERRER_QUERY_KEY } from '../referrer'

export function useFetchPromoCodes() {
  return useQuery<ServerPromoCodeType[]>(
    [PROMO_CODE_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${promoCodeFragment}
          query {
            promoCodes {
              ...promoCodeType
            }
          }
        `,
      )

      if (Array.isArray(result?.promoCodes)) {
        return result.promoCodes.map(promoCode => ({
          ...promoCode,
          createdAt: new Date(promoCode.createdAt),
          endAt: promoCode.endAt ? new Date(promoCode.endAt) : undefined,
        }))
      } else {
        reportError(new Error('promoCodes is not an array'), 'error', { result })
        return []
      }
    },
    {
      queryName: 'useFetchPromoCodes',
      onError: reportGqlError,
    },
  )
}

export function useFetchPromoCodesAsReferrer() {
  return useQuery<ServerPromoCodeType[]>(
    [PROMO_CODE_QUERY_KEY, AS_REFERRER_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${promoCodeAsReferrerFragment}
          query {
            promoCodesReferrerView {
              ...promoCodeAsReferrerType
            }
          }
        `,
      )

      if (Array.isArray(result?.promoCodesReferrerView)) {
        return result.promoCodesReferrerView.map(promoCode => ({
          ...promoCode,
          createdAt: new Date(promoCode.createdAt),
          endAt: promoCode.endAt ? new Date(promoCode.endAt) : undefined,
        }))
      } else {
        reportError(new Error('promoCodes is not an array'), 'error', { result })
        return []
      }
    },
    {
      queryName: 'useFetchPromoCodesAsReferrer',
      onError: reportGqlError,
    },
  )
}

export function useFetchBusinessPromoCode() {
  return useQuery<BusinessPromoCodeViewType | null>(
    [BUSINESS_PROMO_CODE_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          query {
            businessPromoCodesView {
              promoCodeId
              businessId
              discount
            }
          }
        `,
      )

      if (Array.isArray(result?.businessPromoCodesView)) {
        // Поки що маємо на увазі що промокод може бути один на бізнес
        return result.businessPromoCodesView[0] || null
      } else {
        reportError(new Error('businessPromoCodesView is not an array'), 'error', { result })
        return null
      }
    },
    {
      queryName: 'useFetchBusinessPromoCodes',
      onError: reportGqlError,
    },
  )
}

export function useFetchPromoCodeById(id: string | undefined) {
  return useQuery<ServerPromoCodeType>(
    [PROMO_CODE_QUERY_KEY, { id }],
    async () => {
      const result = await request(
        gql`
          ${promoCodeFragment}
          query ($id: String!) {
            promoCodeById(id: $id) {
              ...promoCodeType
            }
          }
        `,
        { id },
      )

      if (result?.promoCodeById) {
        return {
          ...result.promoCodeById,
          createdAt: new Date(result.promoCodeById.createdAt),
          endAt: result.promoCodeById.endAt ? new Date(result.promoCodeById.endAt) : undefined,
        }
      } else {
        reportError(new Error('promoCode does not exist'), 'error', { result })
        return undefined
      }
    },
    {
      queryName: 'useFetchPromoCodeById',
      onError: reportGqlError,
      enabled: Boolean(id),
    },
  )
}
