import { DayTiming, WeeklySchedule as WeeklyScheduleType } from '@expane/data'
import {
  defaultScheduleTemplateWorkingHoursForDay,
  mapDayToScheduleTemplateFormData,
  SCHEDULE_RANGE_MAX,
  SCHEDULE_RANGE_MIN,
  SCHEDULE_RANGE_STEP,
  ScheduleTemplateFormData,
} from '@expane/logic/schedule'
import { ErrorMessage, Switch } from '@expane/ui'
import { FC, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Range } from 'ui/Range'

interface Props {
  defaultValues?: DayTiming | null
  branchSchedule: WeeklyScheduleType
  isThisBranchSchedule: boolean
  label: string
  day: number
  control: Control<ScheduleTemplateFormData>
  isBreakDay: boolean
  disabled: boolean
}

export const WeeklyScheduleByDay: FC<Props> = ({
  defaultValues,
  branchSchedule,
  isThisBranchSchedule,
  control,
  day,
  isBreakDay,
  label,
  disabled,
}) => {
  const { t } = useTranslation()
  const [isSwitch, setIsSwitch] = useState(isBreakDay)

  return (
    <Controller
      name={mapDayToScheduleTemplateFormData[day]}
      control={control}
      defaultValue={defaultValues}
      rules={{
        validate: value => {
          if (isThisBranchSchedule) return true

          const isBranchWorkInThisDay = Boolean(branchSchedule.data[day])

          return !(!isBranchWorkInThisDay && value)
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        let containerStyle = 'my-0.5 flex items-center pl-2 pr-6 pt-3 pb-6 rounded-xl border'
        if (error) containerStyle += ' border-error-500'
        else containerStyle += ' border-transparent'

        return (
          <>
            <div className={containerStyle}>
              <Switch
                label={label}
                onChange={on => {
                  setIsSwitch(!on)
                  if (on) {
                    onChange(defaultScheduleTemplateWorkingHoursForDay)
                  } else {
                    onChange(null)
                  }
                }}
                checked={!isSwitch}
                containerClassName="w-56 text-primary-500"
                disabled={disabled}
              />

              <Range
                className="w-full"
                markType="time"
                min={SCHEDULE_RANGE_MIN}
                max={SCHEDULE_RANGE_MAX}
                step={SCHEDULE_RANGE_STEP}
                values={value}
                labeled={!isSwitch}
                disabled={disabled || isSwitch}
                onChange={onChange}
                markSeparators
                placeholderValues={defaultScheduleTemplateWorkingHoursForDay}
              />
            </div>
            <ErrorMessage
              className="-mt-5 ml-3"
              errorMessage={{
                isShown: Boolean(error),
                text: t('branchIsClosed'),
                reserveIndent: false,
              }}
            />
          </>
        )
      }}
    />
  )
}
