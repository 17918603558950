import { useFetchAllExtendedServices, useFetchServiceGroups } from '@expane/data'
import { Paper, Spinner } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { ProceedToNextStep, useCreateBusinessSteps } from 'pages/CreateBusinessPage/index'
import { stepStyle } from 'pages/CreateBusinessPage/logic'
import { ServicePageData } from 'pages/ServicesPage/ServicePageData'
import { FC } from 'react'
import { store } from 'store'

export const ServicesStep: FC = observer(() => {
  const { proceedToNextStep } = useCreateBusinessSteps()
  const branchId = store.branch.branchId

  const { data: services, isLoading: areServicesLoading } = useFetchAllExtendedServices(
    branchId,
    'service',
  )
  const { data: serviceGroups, isLoading: areServiceGroupsLoading } =
    useFetchServiceGroups(branchId)
  const isLoading = areServicesLoading || areServiceGroupsLoading

  return (
    <div className={'flex items-end'}>
      <Paper className={stepStyle + ' flex flex-col w-160'}>
        <div className="h-126">
          {isLoading ? (
            <Spinner expandCentered />
          ) : (
            <ServicePageData
              services={services ?? []}
              serviceGroups={serviceGroups ?? []}
              type="createBusiness"
            />
          )}
        </div>
      </Paper>

      <ProceedToNextStep onClick={proceedToNextStep} />
    </div>
  )
})
