import { useTranslation } from 'react-i18next'

export const TransactionInCreditExplanation = () => {
  const { t } = useTranslation()

  return (
    <div className="text-secondary-color text-xs">
      <p className="mb-2">
        <span className="text-main-color">* </span>
        {t('transactionInCreditExplanation.1')}
      </p>
      <p>
        <span className="text-main-color">* </span>
        {t('transactionInCreditExplanation.2')}
      </p>
    </div>
  )
}

export const SomeTransactionInCreditExplanation = () => {
  const { t } = useTranslation()

  return (
    <div className="text-secondary-color text-xs">
      <p>
        <span className="text-main-color">* </span>
        {t('transactionInCreditExplanation.3')}
      </p>
    </div>
  )
}
