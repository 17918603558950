import {
  DataForCheckboxServiceReceipt,
  ServerAccountType,
  ServerCreateServiceReceiptType,
  TRANSACTION_TYPES,
  useCheckboxCreateServiceReceipt,
  useCreateTransaction,
  useFetchAccounts,
  useGetBranchDefaultAccountId,
} from '@expane/data'
import { filterAccountsWithoutChosen, getCurrentAccount } from '@expane/logic/accounts'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { checkOnlyPositiveAmount, checkWithdrawPossibility, PLACEHOLDERS } from '@expane/logic/form'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import {
  Button,
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  Modal,
  PlaceholderInput,
  usePopupOpenState,
  useShowWarningPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { showAccountErrorMessage } from 'logic/form'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoRepeatOutline } from 'react-icons/io5'
import { store } from 'store'
import {
  checkIfAllAccountsHavePOS,
  handleUpdateCreateReceiptResponse,
} from '@expane/logic/payment/checkbox'
import { findById } from '@expane/logic/utils'
import { AccountSelectDropdown } from 'widgets/AccountSelectDropdown'

type MovingBetweenAccountsFormValues = {
  fromAccountId: number
  toAccountId: number
  amount: string
}

interface MovingBetweenAccountsDialogProps {
  closeDialog: () => void
}

interface MovingBetweenAccountsDialogLogicProps extends MovingBetweenAccountsDialogProps {
  accounts: ServerAccountType[]
  defaultAccountId: number
  branchId: number
}

const MovingBetweenAccountsDialog: FC<MovingBetweenAccountsDialogProps> = observer(
  ({ closeDialog }) => {
    const branchId = store.branch.branchId

    const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)
    const { data: defaultAccountId, isLoading: isLoadingDefaultAccountId } =
      useGetBranchDefaultAccountId(branchId)

    const isLoading = isLoadingAccounts || isLoadingDefaultAccountId
    const isNoData = !accounts || !defaultAccountId || !branchId

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
    if (isLoading) return <MovingBetweenAccountsDialogPlaceholder closeDialog={closeDialog} />
    else if (isNoData) return null

    return (
      <MovingBetweenAccountsDialogLogic
        accounts={accounts}
        defaultAccountId={defaultAccountId}
        closeDialog={closeDialog}
        branchId={branchId}
      />
    )
  },
)

const MovingBetweenAccountsDialogLogic: FC<MovingBetweenAccountsDialogLogicProps> = ({
  closeDialog,
  accounts,
  defaultAccountId,
  branchId,
}) => {
  const { t } = useTranslation()

  const { formState, control, handleSubmit } = useForm<MovingBetweenAccountsFormValues>({
    defaultValues: {
      fromAccountId: defaultAccountId,
      toAccountId: filterAccountsWithoutChosen(accounts ?? [], defaultAccountId)?.[0]?.id,
      amount: '',
    },
  })

  const { mutateAsync: insertTransaction } = useCreateTransaction()
  const { mutateAsync: createServiceReceipt } = useCheckboxCreateServiceReceipt()

  const { getModuleSetting } = useBusinessModulesSettings()
  const isCheckboxEnabled = getModuleSetting('checkbox')

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const { warningModal, showWarningPopup } = useShowWarningPopup(
    t('softwarePOS.movingBetweenAccountsWarningTitle'),
    <>
      <p>{t('softwarePOS.movingBetweenAccountsWarningDescription')}</p>
      <p>{t('softwarePOS.movingBetweenAccountsWarningDescription2')}</p>
    </>,
  )
  const [openSnackBar] = useSnackbar()

  const fromAccountId = useWatch({ control, name: 'fromAccountId' })

  const mutateAccounts: SubmitHandler<MovingBetweenAccountsFormValues> = async data => {
    const { fromAccountId, toAccountId, amount } = data

    try {
      if (isCheckboxEnabled && checkIfAllAccountsHavePOS([fromAccountId, toAccountId], accounts)) {
        showWarningPopup()

        return
      }

      const fromAccount = findById(fromAccountId, accounts)
      const toAccount = findById(toAccountId, accounts)

      let dataForCheckboxServiceReceipt: DataForCheckboxServiceReceipt | undefined
      if (fromAccount?.accountSoftwarePOS?.[0]?.softwarePOS?.id && isCheckboxEnabled) {
        dataForCheckboxServiceReceipt = {
          softwarePOSId: fromAccount.accountSoftwarePOS[0].softwarePOS.id,
          type: ServerCreateServiceReceiptType.BetweenAccounts,
          value: -Number(amount),
        }
      }

      if (toAccount?.accountSoftwarePOS?.[0]?.softwarePOS?.id && isCheckboxEnabled) {
        dataForCheckboxServiceReceipt = {
          softwarePOSId: toAccount.accountSoftwarePOS[0].softwarePOS.id,
          type: ServerCreateServiceReceiptType.BetweenAccounts,
          value: Number(amount),
        }
      }

      let receiptId: string | undefined
      if (dataForCheckboxServiceReceipt) {
        const result = await createServiceReceipt(dataForCheckboxServiceReceipt)

        receiptId = handleUpdateCreateReceiptResponse({
          t,
          onError: message => openSnackBar(message, 'error', 3000),
          createReceiptResult: result.checkboxCreateServiceReceipt,
        })

        if (!receiptId) return false
      }

      await insertTransaction({
        type: TRANSACTION_TYPES.operation.id,
        toAccountId,
        fromAccountId,
        amount: Number(amount),
        branchId,
        transactionReceipts:
          receiptId && dataForCheckboxServiceReceipt
            ? {
                data: [{ receiptId, softwarePOSId: dataForCheckboxServiceReceipt.softwarePOSId }],
              }
            : null,
      })

      openSnackBar(t('transaction.movingCompletedSuccessfully'), 'success')
    } catch (error) {
      if (isRestrictionError(error)) openSnackBar(t('planRestriction'), 'error')
      else if (isPlanError(error)) openSnackBar(t('planInfo.noPlan'), 'error')
      else openSnackBar(t('submitError'), 'error')
    }

    closeDialog()
  }

  return (
    <>
      <Modal close={closePopups} confirm={handleSubmit(mutateAccounts)} animation="onlyFadeOut">
        <Dialog>
          <Dialog.Title>{t('transactionNames.movingBetweenAccounts')}</Dialog.Title>

          <Dialog.Body>
            <Controller
              name="fromAccountId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value: fromAccountId } }) => (
                <div className="flex">
                  <AccountSelectDropdown
                    value={fromAccountId}
                    label={t('fromAccount')}
                    accounts={accounts}
                    onSelectChange={onChange}
                    className="w-1/2 mr-2"
                    popupHeight="small"
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.fromAccountId),
                      text: t('formError.required'),
                    }}
                  />

                  <Controller
                    name="amount"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        checkWithdrawPossibility: value =>
                          checkWithdrawPossibility({
                            currentAccount: getCurrentAccount(accounts, fromAccountId),
                            value: Number(value),
                          }),
                        checkOnlyPositiveAmount,
                      },
                    }}
                    render={({ field: { onChange, value: amount } }) => (
                      <Input
                        type="number"
                        label={t('amount')}
                        placeholder={PLACEHOLDERS.servicePrice}
                        value={amount}
                        onChange={onChange}
                        containerClassName="w-1/2"
                        required
                        errorMessage={{
                          isShown: Boolean(formState.errors.amount),
                          text: showAccountErrorMessage(formState.errors.amount?.type ?? '', t),
                        }}
                      />
                    )}
                  />
                </div>
              )}
            />

            <Controller
              name="toAccountId"
              control={control}
              rules={{ required: true, validate: value => value !== fromAccountId }}
              render={({ field: { onChange, value } }) => (
                <AccountSelectDropdown
                  className="w-1/2 pr-1"
                  value={value}
                  label={t('toAccount')}
                  accounts={filterAccountsWithoutChosen(accounts ?? [], fromAccountId)}
                  onSelectChange={onChange}
                  popupHeight="small"
                  required
                  errorMessage={{
                    isShown: Boolean(formState.errors.toAccountId),
                    text: t('formError.required'),
                  }}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            <Button
              onClick={handleSubmit(mutateAccounts)}
              disabled={formState.isSubmitting}
              spinner={formState.isSubmitting}
              Icon={IoRepeatOutline}
            >
              {t('transfer')}
            </Button>

            <CloseButton
              onClick={closePopups}
              disabled={formState.isSubmitting}
              className="mr-auto"
            />
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {confirmPopup}
      {warningModal}
    </>
  )
}

const MovingBetweenAccountsDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('transactionNames.movingBetweenAccounts')}</Dialog.Title>

        <Dialog.Body>
          <div className="flex">
            <PlaceholderInput label={t('fromAccount')} className="w-1/2 mr-2" />
            <PlaceholderInput label={t('amount')} className="w-1/2" />
          </div>

          <PlaceholderInput label={t('toAccount')} className="w-1/2 pr-1" />
        </Dialog.Body>

        <Dialog.Footer>
          <Button disabled spinner Icon={IoRepeatOutline}>
            {t('transfer')}
          </Button>

          <CloseButton onClick={closeDialog} className="mr-auto" />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

export const useOpenMovingBetweenAccountsDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()

  const openMovingBetweenAccountsDialog = (onClose?: () => void) => {
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const movingBetweenAccountsDialog = isOpen ? (
    <MovingBetweenAccountsDialog closeDialog={closeDialog} />
  ) : null

  return { openMovingBetweenAccountsDialog, movingBetweenAccountsDialog }
}
