import { BookingPaymentData } from '@expane/logic/payment/booking'
import { Dialog } from '@expane/ui'
import { FC } from 'react'
import { Control, UseFormClearErrors, UseFormSetValue } from 'react-hook-form'
import { ClientEmployeeInfo } from './ClientEmployeeInfo'
import { InvoiceTable } from './InvoiceTable'
import { PaymentInputs } from './PaymentInputs'

type Props = {
  control: Control<BookingPaymentData>
  clientId: number
  employeeFullName: string | undefined
  setValue: UseFormSetValue<BookingPaymentData>
  clearErrors: UseFormClearErrors<BookingPaymentData>
}

export const BookingPaymentDialogBody: FC<Props> = ({
  clientId,
  employeeFullName,
  control,
  setValue,
  clearErrors,
}) => {
  return (
    <Dialog.Body className="w-256 h-112 flex flex-col flex-1">
      <ClientEmployeeInfo clientId={clientId} employeeFullName={employeeFullName} />

      <InvoiceTable control={control} setValue={setValue} />

      <PaymentInputs
        clientId={clientId}
        control={control}
        clearErrors={clearErrors}
        setValue={setValue}
      />
    </Dialog.Body>
  )
}
