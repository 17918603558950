import { ServerExtendedServiceType } from '@expane/data'
import { TFunction } from 'i18next'
import { FieldArrayWithId, FieldNamesMarkedBoolean, UseFormStateReturn } from 'react-hook-form'
import { TreeMenuItem } from 'ui/TreeMenu'

export type SubscriptionServiceDto = {
  serviceItem: TreeMenuItem | undefined
  serviceQuantity: string
  servicePrice: string
}

export type SubscriptionFormValues = {
  name: string
  cardPeriod: string
  price: string
  cardTemplateServices: SubscriptionServiceDto[]
  isItCopy: boolean
  availableToAllBranches: boolean
}

export const transformCardServiceDefaultValueTreeItem = (
  cS: { serviceId: number },
  services: ServerExtendedServiceType[],
) => {
  const service = services.find(s => s.id === cS.serviceId)
  const parentId = service?.groupId

  const treeMenuItem = { ...service, parentId } as TreeMenuItem

  return treeMenuItem
}

// мы получаем объект с полями формы и значением true/false если оно изменилось
// функция нужна для того чтоб проверить какие именно поля были изменены
// сделана под форму абонементов, с учетом того что мы знаем что у нас один уровень вложенности
export const getDirtyFields = <T extends { name: string }>(fields: FieldNamesMarkedBoolean<T>) => {
  let areFieldsExceptNameDirty = false

  for (const field in fields) {
    const fieldValue = fields[field]

    const isFieldValueArray = Array.isArray(fieldValue)

    if (isFieldValueArray) {
      for (const valueOfField of fieldValue) {
        // если хотя бы одно значение true - то поле isDirty
        if (fieldValue[valueOfField] && field !== 'name') areFieldsExceptNameDirty = true
      }
    }

    if (!isFieldValueArray && fieldValue && field !== 'name') areFieldsExceptNameDirty = true
  }

  // TODO: Покращити логіку
  let isNameDirty = false
  if (typeof fields !== 'boolean') isNameDirty = fields.name ?? false

  return { isNameDirty, areFieldsExceptNameDirty }
}

export const isSomeServiceInSelectedFields = (
  fields: FieldArrayWithId<SubscriptionFormValues, 'cardTemplateServices', 'id'>[],
  id: number,
) => fields.some(field => field.serviceItem?.id === id)

export const getServiceItemsWithInactiveReason = (
  serviceItems: TreeMenuItem[],
  fields: FieldArrayWithId<SubscriptionFormValues, 'cardTemplateServices', 'id'>[],
  t: TFunction,
) => {
  const transformedTreeMenu: TreeMenuItem[] = []

  for (const serviceItem of serviceItems) {
    if (serviceItem.nodes) {
      transformedTreeMenu.push({
        ...serviceItem,
        nodes: serviceItem.nodes.map(service =>
          isSomeServiceInSelectedFields(fields, service.id)
            ? { ...service, inactiveReason: t('alreadyInUse'), disabled: true }
            : service,
        ),
      })
    } else {
      transformedTreeMenu.push(
        isSomeServiceInSelectedFields(fields, serviceItem.id)
          ? { ...serviceItem, inactiveReason: t('alreadyInUse'), disabled: true }
          : serviceItem,
      )
    }
  }

  return transformedTreeMenu
}

export const calcTotalSum = (subscriptionServices: SubscriptionServiceDto[]): number =>
  subscriptionServices.reduce(
    (acc, s) => acc + Number(s.servicePrice) * Number(s.serviceQuantity),
    0,
  )

export const calcServiceInSubscriptionPrice = (quantity: string, price: string) =>
  (Number(quantity) * Number(price)).toString()

export const generateSubscriptionServicesErrorMessage = (
  formState: UseFormStateReturn<SubscriptionFormValues>,
  index: number,
  t: TFunction,
): string | undefined => {
  if (!formState.errors.cardTemplateServices?.[index]) return undefined

  const requiredFields: string[] = []

  if (formState.errors.cardTemplateServices?.[index]?.serviceItem)
    requiredFields.push(`"${t('service.name')}"`.toLocaleLowerCase())
  if (formState.errors.cardTemplateServices?.[index]?.serviceQuantity)
    requiredFields.push(`"${t('qty')}"`.toLocaleLowerCase())
  if (formState.errors.cardTemplateServices?.[index]?.servicePrice?.type === 'checkValueNotZero')
    requiredFields.push(`"${t('price')}"`.toLocaleLowerCase())

  if (!requiredFields.length) return undefined
  return requiredFields.length === 1
    ? t('required.field', { name: requiredFields[0] })
    : t('required.fields', { names: requiredFields.join(', ') })
}

export const generateServicePriceErrorMessage = ({
  convertToMoneyCode,
  type,
  costPrice,
  serviceInSubscriptionPrice,
  t,
}: {
  convertToMoneyCode: (value: number) => string
  type?: string
  costPrice?: number
  serviceInSubscriptionPrice?: string
  t: TFunction
}) => {
  if (type === 'checkValueNotZero' || type === 'required') return ' '
  if (type === 'checkPriceMoreCostPrice' && costPrice)
    if (Number(serviceInSubscriptionPrice) < costPrice)
      return `${t('costPrice')}: ${convertToMoneyCode(costPrice)}`
}
