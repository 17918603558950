import { FC, useRef, useState } from 'react'
import {
  CloseButton,
  Dialog,
  Modal,
  RadioGroupButtons,
  RadioGroupOption,
  usePopupOpenState,
} from '@expane/ui'
import { translateData } from 'logic/utils'
import { useTranslation } from 'react-i18next'
import { SaveButton } from 'widgets/Buttons'
import { useFetchFutureRecurringBookingsByRecurringBookingId } from '@expane/data/src/gql/recurringBooking/queries'
import { ServerRecurringBookingByIdType, useFetchCurrentBranchTimezone } from '@expane/data'
import { store } from 'store'
import { SimpleEntityPlaceholder } from 'widgets/SimpleEntitiesDialog/SimpleEntityPlaceholder'

const RecurringEditDialog: FC<{
  recurringId: number
  startDate: Date
  onSave: (
    type: RadioGroupOption | undefined,
    recurringBookings: ServerRecurringBookingByIdType[],
  ) => void
  closeDialog: () => void
}> = ({ recurringId, startDate, onSave, closeDialog }) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { data: recurringBookings, isLoading } =
    useFetchFutureRecurringBookingsByRecurringBookingId(recurringId, startDate, timezone, branchId)

  const [type, setType] = useState<RadioGroupOption | undefined>()

  const { t } = useTranslation()

  const handleOnSave = () => {
    if (type && recurringBookings) onSave(type, recurringBookings)
    closeDialog()
  }

  if (isLoading)
    return (
      <SimpleEntityPlaceholder
        title={t('recurringBookings.edit.title')}
        closeDialog={closeDialog}
        height={'small'}
      />
    )

  if (!isLoading && !recurringBookings) return null

  return (
    <Modal close={closeDialog} animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>{t('recurringBookings.edit.title')}</Dialog.Title>
        <Dialog.Body>
          <RadioGroupButtons
            options={translateData(Object.values(recurringEditTypes), t)}
            value={type}
            onChange={setType}
          />
        </Dialog.Body>

        <Dialog.Footer>
          <SaveButton onClick={handleOnSave} disabled={Boolean(!type)} />

          <CloseButton onClick={closeDialog} className="mr-auto" />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

export const RecurringEditType = {
  single: 1,
  all: 2,
}

const recurringEditTypes = [
  { id: RecurringEditType.single, name: 'recurringBookings.edit.single' },
  { id: RecurringEditType.all, name: 'recurringBookings.edit.all' },
]

export const useOpenRecurringEditDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const recurringIdDialog = useRef<number>()
  const startDateDialog = useRef<Date>()
  const onCloseDialog = useRef<() => void>()
  const onSaveDialog =
    useRef<
      (
        type: RadioGroupOption | undefined,
        recurringBookings: ServerRecurringBookingByIdType[],
      ) => void
    >()

  const openRecurringEditDialog = ({
    recurringId,
    startDate,
    onSave,
    onClose,
  }: {
    recurringId: number
    startDate: Date
    onSave: (
      type: RadioGroupOption | undefined,
      recurringBookings: ServerRecurringBookingByIdType[],
    ) => void
    onClose?: () => void
  }) => {
    onCloseDialog.current = onClose
    onSaveDialog.current = onSave
    startDateDialog.current = startDate
    recurringIdDialog.current = recurringId
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const recurringEditDialog =
    isOpen && onSaveDialog.current && startDateDialog.current && recurringIdDialog.current ? (
      <RecurringEditDialog
        onSave={onSaveDialog.current}
        closeDialog={closeDialog}
        startDate={startDateDialog.current}
        recurringId={recurringIdDialog.current}
      />
    ) : null

  return { openRecurringEditDialog, recurringEditDialog }
}
