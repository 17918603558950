import { queryClient, request, useMutation, reportGqlError } from '../../api'
import { ServerSalaryIssueInsertInput } from '../../generated/graphql-types'
import { gql } from 'graphql-request'
import { SALARY_ISSUES_QUERY_KEY } from './queryKeys'

export function useCreateSalaryIssue() {
  return useMutation(
    async (
      salaryIssueInsertInput: ServerSalaryIssueInsertInput &
        Required<Pick<ServerSalaryIssueInsertInput, 'type' | 'value' | 'employeeId' | 'branchId'>>,
    ) => {
      await request(
        gql`
          mutation ($salaryIssueInsertInput: salaryIssue_insert_input!) {
            insertSalaryIssue(object: $salaryIssueInsertInput) {
              id
            }
          }
        `,
        { salaryIssueInsertInput },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([SALARY_ISSUES_QUERY_KEY]),
    },
  )
}
