import { gql } from 'graphql-request'
import { queryClient, request, useMutation, reportGqlError } from '../../api'
import {
  ServerInventoryInsertInput,
  ServerInventoryProductInsertInput,
} from '../../generated/graphql-types'
import { INVENTORIES_QUERY_KEY } from './queryKeys'

export function useCreateInventory() {
  return useMutation(
    (
      inventoryInsertInput: ServerInventoryInsertInput &
        Required<
          Pick<
            ServerInventoryInsertInput,
            'inventoryDate' | 'inventoryProducts' | 'number' | 'storageId' | 'branchId'
          >
        >,
    ): Promise<{ insertInventory: { id: number } }> => {
      return request(
        gql`
          mutation ($inventoryInsertInput: inventory_insert_input!) {
            insertInventory(object: $inventoryInsertInput) {
              id
            }
          }
        `,
        { inventoryInsertInput },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([INVENTORIES_QUERY_KEY]),
    },
  )
}

export function useFulfillInventory() {
  return useMutation(
    (id: number): Promise<{ updateInventoryById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!) {
            updateInventoryById(pk_columns: { id: $id }, _set: { fulfilled: true }) {
              id
            }
          }
        `,
        { id },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([INVENTORIES_QUERY_KEY]),
    },
  )
}

export type InventoryProductInput = Required<
  Pick<
    ServerInventoryProductInsertInput,
    'productId' | 'actualQuantity' | 'calculatedQuantity' | 'price' | 'inventoryId'
  >
>

export function useUpdateInventoryProducts() {
  return useMutation(
    (
      inventoryProducts: InventoryProductInput[],
    ): Promise<{
      // eslint-disable-next-line camelcase
      deleteInventoryProducts: { affected_rows: number[] }
      // eslint-disable-next-line camelcase
      insertInventoryProducts: { affected_rows: number[] }
    }> => {
      return request(
        gql`
          mutation ($inventoryId: Int!, $inventoryProducts: [inventoryProduct_insert_input!]!) {
            deleteInventoryProducts(where: { inventoryId: { _eq: $inventoryId } }) {
              affected_rows
            }
            insertInventoryProducts(objects: $inventoryProducts) {
              affected_rows
            }
          }
        `,
        { inventoryProducts, inventoryId: inventoryProducts[0].inventoryId },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([INVENTORIES_QUERY_KEY]),
    },
  )
}
