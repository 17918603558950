import {
  useFetchBranchById,
  useFetchCurrentBranchTimezone,
  useFetchExtendedEmployees,
  useFetchGiftCards,
  useFetchProductGroups,
  useFetchProductsForSale,
  useFetchServicesBriefsForSale,
  useFetchStorageById,
  useFetchStorages,
  useFetchSubscriptions,
  useGetBranchDefaultStorageId,
  useGetLeftovers,
} from '@expane/data'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { ItemWithModules, useBusinessModulesSettings } from '@expane/logic/modules'
import {
  addInactiveReasonByConsumablesForServices,
  addInactiveReasonsAndWarningsForProducts,
  addServicesWithInactiveReasons,
} from '@expane/logic/quickSale'
import {
  CardItemForPayment,
  ProductItemForPayment,
  ServiceItemForPayment,
} from '@expane/logic/quickSale/types'
import { DEFAULT_DISCOUNT, translateItemsName } from '@expane/logic/utils'
import { EmptyPlaceholder, HorizontalButtonSwitch, PlaceholderString, Spinner } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCartOutline } from 'react-icons/io5'
import { store } from 'store'
import { TreeMenu } from 'ui/TreeMenu'
import { extractItemsFromFolders, transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { onChangeMultiTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { customProductSearchFilterFunction } from 'ui/TreeMenu/logic.product'
import { StorageSelectDropdown } from 'widgets/StoragesSelectDropdown'
import { SubmitQuickSaleDialogFormValues } from './logic'

interface SaleItemsInputProps {
  control: Control<SubmitQuickSaleDialogFormValues>
  setValue: UseFormSetValue<SubmitQuickSaleDialogFormValues>
}

export const SaleItemsInput: FC<SaleItemsInputProps> = ({ control, setValue }) => {
  const { t } = useTranslation()

  const { getFilteredItemsByModules } = useBusinessModulesSettings()

  const paymentItemsSections = translateItemsName(getFilteredItemsByModules(saleSections), t)

  const [currentType, setCurrentType] = useState(
    paymentItemsSections.length > 0 ? paymentItemsSections[0].id : undefined,
  )

  return (
    <div className="flex flex-col w-95 h-128">
      {paymentItemsSections.length === 0 ? (
        <EmptyPlaceholder text={t('emptyPlaceholder.quickSale')} Icon={IoCartOutline} />
      ) : (
        <>
          <HorizontalButtonSwitch
            options={paymentItemsSections}
            onChange={setCurrentType}
            value={currentType}
            containerClassName="mb-3"
            isFullWidth
          />
          <div className="grow flex flex-col overflow-auto justify-between">
            {currentType === 'certificates' && (
              <GiftCardTreeMenu control={control} setValue={setValue} />
            )}
            {currentType === 'subscriptions' && (
              <SubscriptionTreeMenu control={control} setValue={setValue} />
            )}
            {currentType === 'services' && (
              <ServiceTreeMenu control={control} setValue={setValue} />
            )}
            {currentType === 'products' && (
              <ProductTreeMenu control={control} setValue={setValue} />
            )}
          </div>
        </>
      )}
    </div>
  )
}

type QuickSaleTreeMenuProps = {
  control: Control<SubmitQuickSaleDialogFormValues>
  setValue: UseFormSetValue<SubmitQuickSaleDialogFormValues>
}
const GiftCardTreeMenu: FC<QuickSaleTreeMenuProps> = observer(({ control, setValue }) => {
  const branchId = store.branch.branchId

  const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

  const watchedTotalDiscount = useWatch({ control, name: 'totalDiscount' })

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: giftCards } = useFetchGiftCards(timezone, branchId)

  const watchedGiftCardItems = useWatch({ control, name: 'giftCardItems' })
  const setGiftCardItems = (items: CardItemForPayment[]) => setValue('giftCardItems', items)

  return (
    <TreeMenu
      type="MultiPickMode"
      items={giftCards}
      selected={watchedGiftCardItems}
      onSelected={item => {
        const itemWithDiscount: CardItemForPayment = {
          ...item,
          discount: watchedTotalDiscount
            ? { value: watchedTotalDiscount, type: 'percent' }
            : DEFAULT_DISCOUNT,
          quantity: DEFAULT_QUANTITY,
        }
        return setGiftCardItems(
          onChangeMultiTreeMenu(
            itemWithDiscount,
            watchedGiftCardItems,
            // @ts-expect-error we don't need discount data in all items
            giftCards,
          ),
        )
      }}
      priceConversionHandlerFunction={convertToMoneyCode}
    />
  )
})

const SubscriptionTreeMenu: FC<QuickSaleTreeMenuProps> = observer(({ control, setValue }) => {
  const branchId = store.branch.branchId

  const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

  const watchedTotalDiscount = useWatch({ control, name: 'totalDiscount' })

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: subscriptions } = useFetchSubscriptions(timezone, branchId)

  const watchedSubscriptionItems = useWatch({ control, name: 'subscriptionItems' })
  const setSubscriptionItems = (items: CardItemForPayment[]) => setValue('subscriptionItems', items)

  return (
    <TreeMenu
      type="MultiPickMode"
      items={subscriptions}
      selected={watchedSubscriptionItems}
      onSelected={item => {
        const itemWithDiscount: CardItemForPayment = {
          ...item,
          discount: watchedTotalDiscount
            ? { value: watchedTotalDiscount, type: 'percent' }
            : DEFAULT_DISCOUNT,
          quantity: DEFAULT_QUANTITY,
        }
        return setSubscriptionItems(
          onChangeMultiTreeMenu(
            itemWithDiscount,
            watchedSubscriptionItems,
            // @ts-expect-error we don't need discount data in all items
            subscriptions,
          ),
        )
      }}
      priceConversionHandlerFunction={convertToMoneyCode}
    />
  )
})

const ServiceTreeMenu: FC<QuickSaleTreeMenuProps> = ({ control, setValue }) => {
  const branchId = store.branch.branchId

  const { t } = useTranslation()
  const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

  const watchedStorageId = useWatch({
    control,
    name: 'storageId',
  })
  const { data: leftoverProducts, isLoading: isLoadingLeftoverProducts } = useGetLeftovers(
    branchId,
    watchedStorageId,
  )
  const watchedTotalDiscount = useWatch({ control, name: 'totalDiscount' })
  const { data: defaultBranch, isLoading: isLoadingDefaultBranch } = useFetchBranchById(branchId)

  const watchedEmployeeId = useWatch({ control, name: 'employeeId' })
  const { data: employees, isLoading: isLoadingEmployees } = useFetchExtendedEmployees(
    defaultBranch?.timezone,
    branchId,
  )
  const storageIdByEmployee = employees?.find(employee => employee.id === watchedEmployeeId)
    ?.employeeGroup?.storageId
  const defaultStorageId = storageIdByEmployee ?? defaultBranch?.defaultStorage?.id

  const { data: storageById, isLoading: isLoadingStorageById } =
    useFetchStorageById(defaultStorageId)
  const { data: services } = useFetchServicesBriefsForSale(branchId)

  const watchedServiceItems = useWatch({ control, name: 'serviceItems' })
  useEffect(() => {
    if (watchedServiceItems.length === 0 && watchedEmployeeId) setValue('employeeId', undefined)
  }, [setValue, watchedEmployeeId, watchedServiceItems.length])

  // If we add an employee then we need to remove all services that require no employee
  useEffect(() => {
    if (!watchedEmployeeId) return

    const selectedServiceItemsWithEmployee = watchedServiceItems.filter(serviceItem =>
      serviceItem.serviceEmployees.some(({ employee }) => employee.id === watchedEmployeeId),
    )

    if (selectedServiceItemsWithEmployee.length !== watchedServiceItems.length)
      setValue('serviceItems', selectedServiceItemsWithEmployee)
  }, [setValue, watchedEmployeeId, watchedServiceItems])

  const servicesWithInactiveReasons = addServicesWithInactiveReasons({
    services,
    watchedEmployeeId,
    watchedServiceItems,
    t,
  })

  const servicesItems = addInactiveReasonByConsumablesForServices(
    servicesWithInactiveReasons,
    leftoverProducts ?? {},
    t,
  )

  const servicesForTreeMenu = transformDataForTreeMenu<ServiceItemForPayment>([], servicesItems)

  const setServiceItems = (items: ServiceItemForPayment[]) => setValue('serviceItems', items)

  const isLoading =
    isLoadingDefaultBranch ||
    isLoadingEmployees ||
    isLoadingStorageById ||
    isLoadingLeftoverProducts

  return (
    <>
      {isLoading ? (
        <div className="grow">
          <Spinner expandCentered />
        </div>
      ) : (
        <TreeMenu
          type="MultiPickMode"
          items={servicesForTreeMenu}
          selected={watchedServiceItems}
          onSelected={item => {
            const itemWithDiscount: ServiceItemForPayment = {
              ...item,
              discount: watchedTotalDiscount
                ? { value: watchedTotalDiscount, type: 'percent' }
                : DEFAULT_DISCOUNT,
              quantity: DEFAULT_QUANTITY,
            }
            return setServiceItems(
              onChangeMultiTreeMenu(itemWithDiscount, watchedServiceItems, servicesForTreeMenu),
            )
          }}
          priceConversionHandlerFunction={convertToMoneyCode}
        />
      )}

      <p className="text-gray-400 text-sm flex gap-1">
        {t('storage.name')}: {isLoading ? <PlaceholderString /> : storageById?.name}
      </p>
    </>
  )
}

const ProductTreeMenu: FC<QuickSaleTreeMenuProps> = observer(({ control, setValue }) => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()
  const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

  const watchedTotalDiscount = useWatch({ control, name: 'totalDiscount' })
  const watchedStorageId = useWatch({
    control,
    name: 'storageId',
  })

  const { data: leftoverProducts, isLoading } = useGetLeftovers(branchId, watchedStorageId)
  const { data: productGroups } = useFetchProductGroups(branchId)
  const { data: products } = useFetchProductsForSale(branchId)
  const { data: storages } = useFetchStorages(branchId)
  const { data: defaultStorageId } = useGetBranchDefaultStorageId(branchId)
  const productsWithInactiveReasons = products
    ? addInactiveReasonsAndWarningsForProducts(products, leftoverProducts, t)
    : []

  const dataForProductsTreeMenu = transformDataForTreeMenu<ProductItemForPayment>(
    productGroups,
    productsWithInactiveReasons,
  )

  const watchedProductItems = useWatch({ control, name: 'productItems' })
  const setProductItems = (items: ProductItemForPayment[]) => setValue('productItems', items)

  return (
    <>
      {isLoading ? (
        <div className="grow">
          <Spinner expandCentered />
        </div>
      ) : (
        <TreeMenu
          type="MultiPickMode"
          items={dataForProductsTreeMenu}
          onSelected={(item, isInactive) => {
            if (!isInactive) {
              const newValue = onChangeProductItems({
                item,
                productPaymentItems: watchedProductItems,
                treeMenuInitItems: dataForProductsTreeMenu,
                totalDiscount: watchedTotalDiscount,
              })
              setProductItems(newValue)
            }
          }}
          selected={watchedProductItems}
          className="grow overflow-auto text-sm"
          priceConversionHandlerFunction={convertToMoneyCode}
          customSearchFilterFunction={customProductSearchFilterFunction}
        />
      )}

      <Controller
        name="storageId"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <StorageSelectDropdown
              label={t('storage.name')}
              onSelectChange={onChange}
              value={value}
              storages={storages}
              defaultStorageId={defaultStorageId}
              className="mt-1 h-16 px-1"
            />
          )
        }}
      />
    </>
  )
})

const DEFAULT_QUANTITY = 1

export const saleSections: ItemWithModules<{
  id: string
  name: string
}>[] = [
  { id: 'subscriptions', name: 'subscriptions', modules: ['subscriptions'] },
  { id: 'products', name: 'products', modules: ['productsForSale'] },
  { id: 'certificates', name: 'certificates', modules: ['giftCards'] },
  { id: 'services', name: 'additionalServicesShort', modules: ['servicesForSale'] },
]

const onChangeProductItems = ({
  item,
  treeMenuInitItems,
  productPaymentItems,
  totalDiscount,
}: {
  productPaymentItems: ProductItemForPayment[]
  treeMenuInitItems: ProductItemForPayment[]
  item: ProductItemForPayment
  totalDiscount: string
}) => {
  const selectedItemsWithFolders = onChangeMultiTreeMenu(
    item,
    productPaymentItems,
    treeMenuInitItems,
  )
  const selectedItems = extractItemsFromFolders(selectedItemsWithFolders) as ProductItemForPayment[]

  const result = productPaymentItems.filter(item => {
    return selectedItems.some(selectedItem => selectedItem.id === item.id)
  })

  const newSelectedItems = selectedItems.filter(selectedItem =>
    productPaymentItems.every(item => !(item.id === selectedItem.id)),
  )

  if (newSelectedItems.length > 0) {
    const newItemsWithSellInfo: ProductItemForPayment[] = newSelectedItems.map(item => ({
      ...item,
      price: item.price ?? 0,
      quantity: item.quantity ?? DEFAULT_QUANTITY,
      discount: totalDiscount ? { value: totalDiscount, type: 'percent' } : DEFAULT_DISCOUNT,
    }))
    result.push(...newItemsWithSellInfo)
  }

  return result
}
