import { permissions } from '@expane/logic/permission'
import { Page } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { SaveButton } from 'widgets/Buttons'
import { WaitingListDialog } from 'widgets/WaitingListDialog'
import { WaitingList } from './List'

export const WaitingListPage: FC = () => {
  const { data: myPermissions } = useFetchMyPermissions()
  const { openCreateDialog, openEditDialog, dialog } = useOpenDialog(WaitingListDialog)

  const isEditingAllowed = myPermissions?.includes(permissions.waitingList.set)

  return (
    <Page>
      {isEditingAllowed && (
        <SaveButton onClick={() => openCreateDialog()} className="mb-2 self-start" isCreate />
      )}

      <WaitingList onRowClick={waitingList => openEditDialog(waitingList.id)} />

      {dialog}
    </Page>
  )
}
