import { useFetchAccounts, useGetBranchDefaultAccountId } from '@expane/data'
import { getCurrentAccount } from '@expane/logic/accounts'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { checkWithdrawPossibility } from '@expane/logic/form'
import { useAccountsWithoutCheckbox } from '@expane/logic/payment/checkbox'
import { findById } from '@expane/logic/utils'
import { Input, PlaceholderString, SelectDropdown } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { IssueSalaryDialogFormValues } from 'widgets/SalaryIssueDialog/index'
import { BalanceOnAccountAfterTransaction } from './BalanceOnAccountAfterTransaction'

interface SalaryIssueTotalProps {
  control: Control<IssueSalaryDialogFormValues>
  totalNotPaidSalaries: number
}

export const SalaryIssueTotal: FC<SalaryIssueTotalProps> = ({ control, totalNotPaidSalaries }) => {
  const branchId = store.branch.branchId

  const { data: accounts } = useFetchAccounts(branchId)
  const { data: defaultAccountId, isLoading: isLoadingDefaultAccountId } =
    useGetBranchDefaultAccountId(branchId)

  const { accountsWithoutCheckbox } = useAccountsWithoutCheckbox(accounts ?? [])

  const { t } = useTranslation()
  const convertToMoneyCode = useConvertNumberToMoney(branchId)

  if (isLoadingDefaultAccountId)
    return (
      <div className="grow flex items-end flex-row-reverse">
        <PlaceholderString />
      </div>
    )

  return (
    <div className="flex flex-1 mt-2">
      <Input
        label={t('totalAmount')}
        value={convertToMoneyCode(totalNotPaidSalaries)}
        containerClassName="w-52 ml-auto"
        disabled
      />

      <Controller
        control={control}
        name="accountId"
        defaultValue={findById(defaultAccountId, accountsWithoutCheckbox)?.id}
        rules={{
          required: true,
          validate: value =>
            checkWithdrawPossibility({
              currentAccount: getCurrentAccount(accountsWithoutCheckbox, value),
              value: totalNotPaidSalaries,
            }),
        }}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <div className="w-72 ml-2">
            <SelectDropdown
              value={value}
              onSelectChange={onChange}
              required
              items={accountsWithoutCheckbox}
              label={t('salary.issueFromAccount')}
              errorMessage={{
                isShown: Boolean(errors.accountId),
                text: ' ',
                reserveIndent: false,
              }}
            />
            <BalanceOnAccountAfterTransaction
              accounts={accountsWithoutCheckbox}
              accountId={value}
              sum={totalNotPaidSalaries}
            />
          </div>
        )}
      />
    </div>
  )
}
