import { useFetchBranches } from '@expane/data'
import { usePopupOpenState } from '@expane/ui'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { BranchImportSuppliersDialogLogic } from 'widgets/BranchImportSuppliersDialog/ImportSuppliersDialogLogic'
import { BranchImportSuppliersDialogPlaceholder } from 'widgets/BranchImportSuppliersDialog/ImportSuppliersDialogPlaceholder'

const BranchImportSuppliersDialog: FC<Pick<DialogProps, 'closeDialog'>> = observer(
  ({ closeDialog }) => {
    const { data: branches, isLoading: isLoading } = useFetchBranches()

    useErrorOpeningDialog(!isLoading && !branches, closeDialog)
    if (!branches) return null

    return (
      <>
        {isLoading ? (
          <BranchImportSuppliersDialogPlaceholder closeDialog={closeDialog} />
        ) : (
          <BranchImportSuppliersDialogLogic closeDialog={closeDialog} branches={branches} />
        )}
      </>
    )
  },
)

export const useOpenBranchImportSuppliersDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const openBranchImportSuppliersDialog = () => {
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
  }

  const branchImportSuppliersDialog = isOpen ? (
    <BranchImportSuppliersDialog closeDialog={closeDialog} />
  ) : null

  return { openBranchImportSuppliersDialog, branchImportSuppliersDialog }
}
