import { gql } from 'graphql-request'

export const timeOffReasonFragment = gql`
  fragment timeOffReasonType on timeOffReason {
    id
    name
    timeOffReasonEmployeeGroups {
      employeeGroupId
      employeeGroup {
        name
      }
    }
  }
`
