import { CloseButton, Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Instruction } from 'pages/SettingsPage/BusinessLandingSettings/Instruction/index'
import {
  instructionTabs,
  InstructionTabs,
} from 'pages/SettingsPage/BusinessLandingSettings/Instruction/Tabs'

const InstructionDialog: FC<Pick<DialogProps, 'closeDialog'>> = ({ closeDialog }) => {
  const { t } = useTranslation()

  const [activeTabId, setActiveTabId] = useState(instructionTabs[0].id)

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Body className={'w-256'}>
          <InstructionTabs
            activeTabId={activeTabId}
            setActiveTabId={setActiveTabId}
            title={t('instruction')}
          />

          <div className={'h-108 overflow-y-scroll'}>
            <Instruction activeTabId={activeTabId} />
          </div>
        </Dialog.Body>

        <Dialog.Footer>
          <CloseButton onClick={closeDialog} className="ml-auto" />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

export const useOpenInstructionDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()

  const openInstructionDialog = (onClose?: () => void) => {
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const instructionDialog = isOpen ? <InstructionDialog closeDialog={closeDialog} /> : null

  return { openInstructionDialog, instructionDialog }
}
