import { ClientByIdType, ServerAccountType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { ProductsAmount } from '@expane/logic/movement'
import { addClientAccountToAccountsForPayment } from '@expane/logic/payment'
import { FC } from 'react'
import { Control, UseFormClearErrors, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ClientInfo } from 'widgets/QuickSaleDialog/QuickSalePayment/ClientInfo'
import { Invoice } from 'widgets/QuickSaleDialog/QuickSalePayment/Invoice'
import { PaymentInputs } from 'widgets/QuickSaleDialog/QuickSalePayment/PaymentInputs'
import { SubmitQuickSaleDialogFormValues } from '../logic'

interface QuickSalePaymentProps {
  control: Control<SubmitQuickSaleDialogFormValues>
  setValue: UseFormSetValue<SubmitQuickSaleDialogFormValues>
  clearErrors: UseFormClearErrors<SubmitQuickSaleDialogFormValues>
  containerClassName?: string
  clientBalance: number
  clientById: ClientByIdType
  accounts: ServerAccountType[]
  leftoverProducts: ProductsAmount
  isErrors: boolean
}

export const QuickSalePayment: FC<PropsWithBranchId<QuickSalePaymentProps>> = ({
  control,
  setValue,
  clearErrors,
  containerClassName,
  clientById,
  clientBalance,
  accounts,
  leftoverProducts,
  isErrors,
  branchId,
}) => {
  const { t } = useTranslation()

  const allAccounts = addClientAccountToAccountsForPayment(accounts, t, clientById.id)

  return (
    <div className={'flex flex-col h-full ' + containerClassName}>
      <ClientInfo
        client={clientById}
        clientBalance={clientBalance}
        clientCreditLimit={clientById?.maximumDebt ?? 0}
        branchId={branchId}
      />

      <Invoice control={control} setValue={setValue} leftoverProducts={leftoverProducts} />

      <PaymentInputs
        control={control}
        setValue={setValue}
        accounts={allAccounts}
        clientBalance={
          clientBalance
            ? clientBalance + (clientById?.maximumDebt ?? 0)
            : clientById?.maximumDebt ?? 0
        }
        clearErrors={clearErrors}
        isErrors={isErrors}
        branchId={branchId}
      />
    </div>
  )
}
