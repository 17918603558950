import { gql } from 'graphql-request'

export const accountFragment = gql`
  fragment accountType on account {
    id
    name
    description
    cash
    commission
    forPayments
    maximumDebt
    balance
    withdrawalToAccountId
    defaultForBranch {
      defaultAccountId
    }
    accountSoftwarePOS {
      softwarePOS {
        id
        name
      }
    }
  }
`

export const accountByIdFragment = gql`
  ${accountFragment}
  fragment accountByIdType on account {
    ...accountType
    withdrawalToAccountId
    exchangeAmount
    accountEmployeeGroups {
      employeeGroup {
        id
        name
      }
    }
  }
`
