import { useFetchCurrentBranchTimezone } from '@expane/data'
import { ProductsAmount } from '@expane/logic/movement'
import { TableBody, TableContainer, TableHeader, TableHeaderCell, TableRow } from '@expane/ui'
import { useFetchMyEmployee } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { Control, useFieldArray, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { SubmitQuickSaleDialogFormValues } from '../../logic'
import { GiftCardSaleItem } from './GiftCardSaleItem'
import { ProductSaleItem } from './ProductSaleItem'
import { ServiceSaleItem } from './ServiceSaleItem'
import { SubscriptionSaleItem } from './SubscriptionSaleItem'
import { TotalDiscountInput } from './TotalDiscountInput'

interface Props {
  control: Control<SubmitQuickSaleDialogFormValues>
  setValue: UseFormSetValue<SubmitQuickSaleDialogFormValues>
  leftoverProducts: ProductsAmount
}

export const Invoice: FC<Props> = observer(({ control, setValue, leftoverProducts }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: myEmployee } = useFetchMyEmployee(timezone, branchId)

  const { fields: productFields, remove: removeProduct } = useFieldArray({
    control,
    name: 'productItems',
    keyName: 'generatedId',
  })
  const { fields: serviceFields, remove: removeService } = useFieldArray({
    control,
    name: 'serviceItems',
    keyName: 'generatedId',
  })
  const { fields: subscriptionFields, remove: removeSubscription } = useFieldArray({
    control,
    name: 'subscriptionItems',
    keyName: 'generatedId',
  })
  const { fields: giftCardFields, remove: removeGiftCard } = useFieldArray({
    control,
    name: 'giftCardItems',
    keyName: 'generatedId',
  })

  const [isDiscountForEveryone, setIsDiscountForEveryone] = useState(false)

  const noItemsSelected =
    productFields.length === 0 &&
    serviceFields.length === 0 &&
    subscriptionFields.length === 0 &&
    giftCardFields.length === 0

  return (
    <div className="grow">
      <TableContainer className="max-h-44">
        <TableHeader>
          <tr>
            <TableHeaderCell className="w-68">{t('title')}</TableHeaderCell>
            <TableHeaderCell className="w-22 text-right">{t('price')}</TableHeaderCell>
            <TableHeaderCell className="w-24">{t('qty')}</TableHeaderCell>
            <TableHeaderCell className="w-14">{t('unit.shortName')}</TableHeaderCell>
            <TableHeaderCell className="w-24">{t('discount')}</TableHeaderCell>
            <TableHeaderCell className="w-22 text-right">{t('amount')}</TableHeaderCell>
            <TableHeaderCell className="w-10" />
          </tr>
        </TableHeader>

        <TableBody>
          {subscriptionFields.map((subscriptionField, index) => (
            <SubscriptionSaleItem
              key={subscriptionField.generatedId}
              myEmployee={myEmployee}
              subscription={subscriptionField}
              index={index}
              control={control}
              isDiscountForEveryone={isDiscountForEveryone}
              onDelete={() => removeSubscription(index)}
              branchId={branchId}
            />
          ))}
          {productFields.map((productField, index) => (
            <ProductSaleItem
              key={productField.generatedId}
              myEmployee={myEmployee}
              item={productField}
              index={index}
              control={control}
              leftoverProducts={leftoverProducts}
              isDiscountForEveryone={isDiscountForEveryone}
              onDelete={() => removeProduct(index)}
              branchId={branchId}
            />
          ))}
          {serviceFields.map((serviceItem, index) => (
            <ServiceSaleItem
              key={serviceItem.generatedId}
              myEmployee={myEmployee}
              index={index}
              control={control}
              onDelete={() => removeService(index)}
              service={serviceItem}
              isDiscountForEveryone={isDiscountForEveryone}
              branchId={branchId}
            />
          ))}
          {giftCardFields.map((giftCardField, index) => (
            <GiftCardSaleItem
              key={giftCardField.generatedId}
              myEmployee={myEmployee}
              giftCard={giftCardField}
              index={index}
              control={control}
              isDiscountForEveryone={isDiscountForEveryone}
              onDelete={() => removeGiftCard(index)}
              branchId={branchId}
            />
          ))}
          {noItemsSelected && (
            <TableRow>
              <td colSpan={7} className="text-center p-1 text-gray-400">
                {t('productNotSelected')}
              </td>
            </TableRow>
          )}
        </TableBody>
      </TableContainer>

      <TotalDiscountInput
        myEmployee={myEmployee}
        control={control}
        setValue={setValue}
        isDiscountForEveryone={isDiscountForEveryone}
        setIsDiscountForEveryone={setIsDiscountForEveryone}
        branchId={branchId}
      />
    </div>
  )
})
