import { toZonedTime } from '@expane/date'

export type SalarySettingToParse = {
  start?: string | Date | null
  end?: string | Date | null
}
export const parseDatesInSalarySettingGqlResponse = <T extends SalarySettingToParse>(
  salarySetting: T,
  timezone: string,
) => {
  if (salarySetting.start) {
    salarySetting.start = toZonedTime(salarySetting.start, timezone)
  }
  if (salarySetting.end) {
    salarySetting.end = toZonedTime(salarySetting.end, timezone)
  }

  return salarySetting
}
