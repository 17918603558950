import { formatFileSize } from '@expane/logic/file'
import { FC, FormEvent, forwardRef, InputHTMLAttributes, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IoCloseSharp,
  IoCloudDownloadOutline,
  IoCloudUploadOutline,
  IoDocumentOutline,
} from 'react-icons/io5'
import { ErrorMessage, ErrorMessageType } from '../ErrorMessage'

export interface UploadInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeFile: (e: FormEvent<HTMLInputElement>) => void
  onClickDownload?: () => void
  onDeleteFile?: () => void
  fileName?: string
  fileSize?: number
  containerClassName?: string
  errorMessage?: ErrorMessageType
  accept?: string
  disabled?: boolean
}

export const UploadInput: FC<UploadInputProps> = forwardRef<HTMLInputElement, UploadInputProps>(
  (props, ref) => {
    const {
      onChangeFile,
      onClickDownload,
      onDeleteFile,
      containerClassName,
      errorMessage,
      fileName,
      fileSize,
      accept = '.jpg,.png,.jpeg',
      disabled,
      ...restProps
    } = props
    const [isHoverFile, setIsHoverFile] = useState(false)
    const [isHover, setIsHover] = useState(false)

    const { t } = useTranslation()

    let styles = `flex items-center flex-col ${
      fileName ? 'justify-start' : 'justify-center'
    } relative w-full h-full rounded-md border-2 border-dashed p-2`

    if (disabled) {
      styles += ' text-gray-300 cursor-default '
    } else {
      if (isHoverFile) {
        styles += ' bg-primary-50'
      }
      if (errorMessage?.isShown) {
        styles += ' border-error-500'
      } else {
        styles += ' border-gray-200'
      }
      styles += ' text-gray-300 cursor-pointer '
    }

    return (
      <div
        className={containerClassName + ' w-96 h-64 block text-sm relative'}
        onMouseEnter={() => {
          if (!isHover) setIsHover(true)
        }}
        onMouseLeave={() => {
          if (isHover) setIsHover(false)
        }}
      >
        <div className={styles}>
          <input
            onDragOver={() => {
              if (!isHoverFile) {
                setIsHoverFile(true)
              }
            }}
            onDragLeave={() => {
              if (isHoverFile) {
                setIsHoverFile(false)
              }
            }}
            onDrop={() => {
              if (isHoverFile) {
                setIsHoverFile(false)
              }
            }}
            onChange={e => {
              e.stopPropagation()
              if (onChangeFile) onChangeFile(e)
            }}
            ref={ref}
            type="file"
            name="uploadFile"
            className="block absolute inset-0 w-full opacity-0"
            accept={accept}
            disabled={disabled}
            {...restProps}
          />

          <IoCloudUploadOutline size="3.5rem" className={`mb-2 ${fileName ? 'mt-8' : ''}`} />

          <div className={`text-xs break-words text-center overflow-hidden text-gray-400`}>
            {t('file.editOrDragFile')}
          </div>

          {fileName ? (
            <div className="pl-1 flex justify-between rounded-md bg-gray-50 border border-gray-200 items-center absolute h-12 bottom-3 w-56">
              <div className="flex item-center">
                <button className="text-gray-300 shrink-0">
                  <IoDocumentOutline size="1.8rem" />
                </button>

                <div className="text-left pr-2 pl-1">
                  <p className="text-gray-400 line-clamp-1 break-all overflow-hidden">{fileName}</p>
                  {fileSize && <p className="text-gray-300 text-xs">{formatFileSize(fileSize)}</p>}
                </div>
              </div>

              <div className="flex-centered flex-col pr-2">
                {onDeleteFile && fileName && !disabled && (
                  <button className="text-primary-500" onClick={onClickDownload}>
                    <IoCloudDownloadOutline size="1rem" />
                  </button>
                )}

                {onClickDownload && fileName && !disabled && (
                  <button className="text-error-500" onClick={onDeleteFile}>
                    <IoCloseSharp size="1rem" />
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </div>
        <ErrorMessage
          errorMessage={errorMessage}
          className="block pt-2 text-base leading-3 font-small"
        />
      </div>
    )
  },
)
