import { Button } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoArrowBackOutline, IoFileTrayFullOutline } from 'react-icons/io5'
import { useLocation, useNavigate } from 'react-router-dom'

interface NavigationButtonProps {
  className?: string
}

export const ArchiveListNavigationButton: FC<NavigationButtonProps> = ({ className }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const archiveRoute = location.pathname + '/archive'

  return (
    <Button
      type="outline"
      onClick={() => navigate(archiveRoute)}
      className={'self-start ' + className}
      Icon={IoFileTrayFullOutline}
    >
      {t('archive.name')}
    </Button>
  )
}

interface NavigationListButtonProps extends NavigationButtonProps {
  title: string
}

export const ListNavigationButton: FC<NavigationListButtonProps> = ({ className, title }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const parentRoute = location.pathname.replace('/archive', '') ?? '/'

  return (
    <Button
      onClick={() => navigate(parentRoute)}
      className={'self-start ' + className}
      Icon={IoArrowBackOutline}
    >
      {title}
    </Button>
  )
}
