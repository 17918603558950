import { gql } from 'graphql-request'

export const serviceGroupFragment = gql`
  fragment serviceGroupType on serviceGroup {
    description
    id
    name
    parentId
    archived
    services {
      id
      name
      archived
    }
  }
`
