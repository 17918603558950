import {
  useFetchCurrentBranchTimezone,
  useFetchTransactionsBriefWithMovementsByDate,
} from '@expane/data'
import { defineStartAndEndDateByPeriodOfTime, usePeriodOfTime } from '@expane/logic/analytics'
import { convertTransactionMovementsQuantity } from '@expane/logic/movement'
import { calcReportItemsTotalPrice } from '@expane/logic/reports/logic'
import {
  convertTransactionsToProductReportItem,
  getProductReportForSaleItems,
} from '@expane/logic/reports/products'
import { Paper } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { AnalyticsBlockBody, AnalyticsBlockTitle } from 'pages/AnalyticsPage'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'

export const ProductsBlock: FC<{ className?: string }> = observer(({ className }) => {
  const { t } = useTranslation()
  const { periodOfTimeName, periodOfTimeId, onSelectChange } = usePeriodOfTime()
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const [startDate, endDate] = defineStartAndEndDateByPeriodOfTime(periodOfTimeId, timezone)

  const { data: transactions, isLoading } = useFetchTransactionsBriefWithMovementsByDate(
    startDate,
    endDate,
    timezone,
    branchId,
  )

  const convertedTransactions = convertTransactionMovementsQuantity(transactions ?? [])
  // считаем только проданные товары
  const productReportItems = convertTransactionsToProductReportItem(convertedTransactions)
  const productForSaleReportItems = getProductReportForSaleItems(productReportItems)
  const productsTotalValue = calcReportItemsTotalPrice(productForSaleReportItems)

  return (
    <Paper className={`p-4 ${className ?? ''}`}>
      <AnalyticsBlockTitle
        title={t('products')}
        onSelectChange={onSelectChange}
        namePeriodOfTime={periodOfTimeName}
        periodOfTimeId={periodOfTimeId}
        hintMessage={t('productsBlock.itemsSold')}
        isLoading={isLoading}
      />

      <AnalyticsBlockBody
        value={productsTotalValue}
        isValueMoney
        text={`${t('productsBlock.fromTheSale')} ${periodOfTimeName}`}
        isLoading={isLoading}
      />
    </Paper>
  )
})
