import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Control, Controller, useWatch } from 'react-hook-form'
import { BookingMultiServicesDialogFormValues } from 'widgets/BookingMultiServicesDialog/index'
import { ClientSelectDropdown } from 'widgets/ClientSelectDropdown'
import { BookingClientBlockWidget } from 'widgets/BookingClientBlockWidget'
import {
  useFetchClientById,
  useFetchClientsBriefs,
  useFetchClientsPhones,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { addPhonesToClients } from '@expane/logic/client'
import { store } from 'store'

interface BookingMultiServicesClientBlock {
  control: Control<BookingMultiServicesDialogFormValues>
  isSomeBookingDone: boolean
}

export const BookingMultiServicesClientBlock: FC<BookingMultiServicesClientBlock> = ({
  control,
  isSomeBookingDone,
}) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clients } = useFetchClientsBriefs(branchId, timezone)
  const { data: clientsPhones } = useFetchClientsPhones()

  const clientsWithPhones = addPhonesToClients(clients, clientsPhones)

  const watchedClientId = useWatch({ control, name: 'clientId' })
  // дата нужна для проверки срока действия документа
  // так как это мультисервис и запись точно на один день - неважно какую multiServicesDto мы смотрим
  const watchedDate = useWatch({ control, name: `multiServicesDto.0.startDate` })

  const { data: watchedClient } = useFetchClientById(watchedClientId, timezone)
  const tags = watchedClient?.clientTags.map(cT => cT.tag) ?? []

  const { t } = useTranslation()

  return (
    <div className="relative">
      <Controller
        control={control}
        name="clientId"
        rules={{
          required: true,
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <ClientSelectDropdown
            value={value}
            onChange={onChange}
            clients={clientsWithPhones}
            errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
            isCreateClient
            required
            tags={tags}
            disabled={isSomeBookingDone}
          />
        )}
      />
      <BookingClientBlockWidget
        watchedClientId={watchedClientId}
        watchedDate={watchedDate}
        subscriptionBlockHeight="small"
      />
    </div>
  )
}
