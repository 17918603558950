import {
  CARD_TYPES,
  DataForCheckboxReceipt,
  ServerTransactionInsertInput,
  useBookingPaymentMutation,
  useCheckboxCreateReceipt,
  useFetchAccounts,
  useFetchBookingsByIds,
  useFetchBranchById,
  useFetchCardsByClientId,
  useFetchClientBalance,
  useFetchProducts,
} from '@expane/data'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { calcDebtAmount, CLIENT_ACCOUNT_ID, PAYMENT_OPTIONS } from '@expane/logic/payment'
import {
  BookingPaymentData,
  generateDepositTransactions,
  generatePaymentTransactionsForSubscriptions,
  getItemsForSale,
} from '@expane/logic/payment/booking'
import {
  getPaymentType,
  handleUpdateCreateReceiptResponse,
  transformBookingPaymentDataForCheckboxReceipt,
} from '@expane/logic/payment/checkbox'
import { useShowWarningPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { generateUUIDv4 } from 'logic/utils'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/sentry'
import { store } from 'store'

export const usePayBooking = (clientId: number, bookingIds: number[]) => {
  const { t } = useTranslation()
  const [openSnackBar] = useSnackbar()

  const {
    warningModal: severalAccountsWarningModal,
    showWarningPopup: showSeveralAccountsWarningPopup,
  } = useShowWarningPopup(
    t('softwarePOS.severalAccountsWarningTitle'),
    t('softwarePOS.severalAccountsWarningDescription'),
  )

  const branchId = store.branch.branchId
  const { data: branch } = useFetchBranchById(branchId)
  const { data: bookings } = useFetchBookingsByIds(bookingIds, branch?.timezone, branchId)
  const { data: clientBalance } = useFetchClientBalance(clientId, branchId)
  const { data: clientCards } = useFetchCardsByClientId(clientId, branch?.timezone, branchId)
  const defaultBranchStorageId = branch?.defaultStorage?.id

  const { data: products } = useFetchProducts(branchId)

  const { data: accounts } = useFetchAccounts(branchId)
  const standingAccounts = accounts?.filter(account => account.forPayments) ?? []

  const { mutateAsync } = useBookingPaymentMutation()
  const { mutateAsync: createReceipt } = useCheckboxCreateReceipt()

  const { getModuleSetting } = useBusinessModulesSettings()
  const isCheckboxEnabled = getModuleSetting('checkbox')

  const payBooking = async (data: BookingPaymentData): Promise<boolean> => {
    try {
      // branchId та defaultBranchStorageId завжди повинні бути на момент оплати
      if (!branchId) {
        reportError('branchId is required for payment', 'error')
        openSnackBar(t('submitError'), 'error')

        return false
      }
      if (!defaultBranchStorageId) {
        reportError('defaultBranchStorageId is required for payment', 'error')
        openSnackBar(t('submitError'), 'error')

        return false
      }

      const paymentType = isCheckboxEnabled
        ? getPaymentType({
            paymentOption: data.paymentOption,
            paymentToAccounts: data.paymentToAccounts,
            accountId: data.accountId,
            accounts: standingAccounts,
          })
        : undefined
      if (paymentType && paymentType.type === 'softwarePOS' && paymentType.error) {
        showSeveralAccountsWarningPopup()

        return false
      }

      let dataForCheckboxReceipt: DataForCheckboxReceipt | undefined = undefined
      if (paymentType && paymentType.type === 'softwarePOS')
        dataForCheckboxReceipt = transformBookingPaymentDataForCheckboxReceipt({
          bookingPaymentData: data,
          clientId,
          softwarePOSId: paymentType.softwarePOSId,
          generateUUIDv4,
        })

      const depositTransactions = generateDepositTransactions(
        data,
        standingAccounts,
        clientId,
        branchId,
      )

      const debt = calcDebtAmount({
        severalAccounts: data.paymentOption.id === PAYMENT_OPTIONS[1].id,
        paymentAmount: Number(data.paymentAmount),
        paymentAmounts: data.paymentToAccounts,
        clientBalance: clientBalance ?? 0,
        byClientBalance: data.accountId === CLIENT_ACCOUNT_ID,
      })

      let receiptId: string | undefined
      if (dataForCheckboxReceipt && dataForCheckboxReceipt.receipt.goods.length > 0) {
        const result = await createReceipt(dataForCheckboxReceipt)

        receiptId = handleUpdateCreateReceiptResponse({
          t,
          onError: message => openSnackBar(message, 'error', 3000),
          createReceiptResult: result.checkboxCreateReceipt,
        })

        if (!receiptId) return false
      }

      const saleTransactions = getItemsForSale({
        products,
        servicesDto: data.servicesDto,
        defaultBranchStorageId,
        optionalConsumables: data.consumablesDto,
        bookings,
        clientId,
        debt,
      })

      const modifiedSaleTransactions =
        receiptId && dataForCheckboxReceipt?.softwarePOSId
          ? saleTransactions.map(transaction => ({
              ...transaction,
              transactionReceipts: {
                data: [{ receiptId, softwarePOSId: dataForCheckboxReceipt?.softwarePOSId }],
              },
            }))
          : saleTransactions

      const { transactionsBySubscriptions, subscriptionIdsToActivate } =
        generatePaymentTransactionsForSubscriptions({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          bookings: bookings!,
          servicesDto: data.servicesDto,
          clientId,
          subscriptions: clientCards?.filter(card => card.type === CARD_TYPES.subscription) ?? [],
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          products: products!,
          defaultBranchStorageId,
        })

      const mutationDto: ServerTransactionInsertInput[] = [
        ...depositTransactions,
        ...modifiedSaleTransactions,
        ...transactionsBySubscriptions,
      ]

      await mutateAsync({ transactions: mutationDto, subscriptionIds: subscriptionIdsToActivate })

      openSnackBar(t('payment.successful'), 'success', 3000)
      return true
    } catch (error) {
      if (isRestrictionError(error)) openSnackBar(t('planRestriction'), 'error')
      else if (isPlanError(error)) openSnackBar(t('planInfo.noPlan'), 'error')
      else {
        openSnackBar(t('submitError'), 'error')
        reportError(error, 'error', { data })
      }

      return false
    }
  }

  return { payBooking, warningModal: severalAccountsWarningModal }
}
