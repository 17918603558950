import { TAGS_QUERY_KEY } from '../tag/queryKeys'
import { LEADS_QUERY_KEY } from '../lead/queryKeys'

export const CLIENTS_QUERY_KEY = 'clients'
export const CLIENTS_AMOUNT_QUERY_KEY = 'clientsAmount'
export const DEACTIVATED_CLIENTS_QUERY_KEY = 'deactivatedClients'
export const CLIENTS_BRIEFS_QUERY_KEY = 'clientsBriefs'
export const LAST_VISIT_QUERY_KEY = 'lastVisit'
export const MY_CLIENT_QUERY_KEY = 'myClient'
export const AS_CLIENT_QUERY_KEY = 'asClient'
export const CLIENTS_PHONE_QUERY_KEY = 'clientsPhone'
export const CLIENT_BALANCE_QUERY_KEY = 'clientBalance'

export const clientByIdQueryKeys = (clientId: number | undefined | null) => [
  CLIENTS_QUERY_KEY,
  TAGS_QUERY_KEY,
  LEADS_QUERY_KEY,
  clientId,
]
