import { Paper, Spinner } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoDesktopOutline, IoPersonCircleOutline, IoWarningOutline } from 'react-icons/io5'

export const AuthOnLoginWithPopupWidget: FC = () => {
  const { t } = useTranslation()

  return (
    <Paper className="py-12 px-8">
      <div className="flex justify-center gap-2">
        <h2 className="text-2xl text-primary-600 mb-10 text-center font-medium">
          {t('authLoginPopUp.title')}
        </h2>
        <Spinner />
      </div>

      <div className="flex items-start">
        <div className="flex-centered flex-col w-56 mx-6">
          <IoPersonCircleOutline size="1.75rem" className="text-secondary-color mb-2" />
          <p className="text-center text-main-color">{t('authLoginPopUp.completeAuthorization')}</p>
        </div>

        <div className="flex-centered flex-col w-56 mx-6">
          <IoDesktopOutline size="1.75rem" className="text-secondary-color mb-2" />
          <p className="text-center text-main-color">
            {t('authLoginPopUp.afterCompleteAuthorization')}
          </p>
        </div>
      </div>

      <div className="flex-centered flex-col w-64 m-auto mt-10">
        <IoWarningOutline size="1.75rem" className="text-secondary-color mb-2" />
        <p className="text-center text-main-color">{t('authLoginPopUp.closePopUp')}</p>
      </div>
    </Paper>
  )
}
