import { SelectDropdown } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SubmitQuickSaleDialogFormValues } from './logic'

interface EmployeeInputProps {
  control: Control<SubmitQuickSaleDialogFormValues>
  employeesForServices: { id: number; name: string }[]
}

export const EmployeeInput: FC<EmployeeInputProps> = ({ control, employeesForServices }) => {
  const { t } = useTranslation()

  return (
    <div className="w-1/2 pl-3">
      <Controller
        name="employeeId"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <SelectDropdown
            required
            label={t('employee.name')}
            value={value}
            items={employeesForServices}
            placeholder={t('placeholders.defaultSelect')}
            noDataMessage={t('noSuchEmployee')}
            onSelectChange={onChange}
            errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
            isClearable
          />
        )}
      />
    </div>
  )
}
