import { ServerCloudFunctionResult } from '../../generated/graphql-types'

export enum ManipulateSuperAdminResponseCodes {
  error,
  success,
  noRequiredFields,
  noPermission,
  doesNotHaveSuperAdmin,
  deleted,
  alreadyCreated,
}

export interface ManipulateSuperAdminResult extends ServerCloudFunctionResult {
  code: ManipulateSuperAdminResponseCodes
}
