import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  ServerArchiveTypes,
  ServerServiceGroupInsertInput,
  ServerServiceGroupSetInput,
} from '../../generated/graphql-types'
import { gql } from 'graphql-request'
import { DEACTIVATED_SERVICE_GROUPS_QUERY_KEY, SERVICE_GROUPS_QUERY_KEY } from './queryKeys'
import { CloudFunctionResult } from '../responses'
import { DEACTIVATED_SERVICES_QUERY_KEY, SERVICES_QUERY_KEY } from '../service/queryKeys'

type ServiceGroupInsertInput = ServerServiceGroupInsertInput &
  Required<Pick<ServerServiceGroupInsertInput, 'branchId' | 'name'>>

export function useCreateServiceGroup() {
  return useMutation(
    async (
      serviceGroupInsertInput: ServiceGroupInsertInput &
        Required<Pick<ServerServiceGroupInsertInput, 'branchId' | 'name'>>,
    ): Promise<{ insertServiceGroup?: { id: number; name: string } }> => {
      return request(
        gql`
          mutation ($serviceGroupInsertInput: serviceGroup_insert_input!) {
            insertServiceGroup(object: $serviceGroupInsertInput) {
              id
              name
            }
          }
        `,
        { serviceGroupInsertInput },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([SERVICE_GROUPS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useUpdateServiceGroup() {
  return useMutation(
    async (dto: {
      id: number
      serviceGroupSetInput: ServerServiceGroupSetInput
    }): Promise<{ updateServiceGroupById?: { id?: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $serviceGroupSetInput: serviceGroup_set_input!) {
            updateServiceGroupById(pk_columns: { id: $id }, _set: $serviceGroupSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([SERVICE_GROUPS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useArchiveServiceGroup() {
  return useMutation(
    async (dto: {
      serviceGroupId: number
      archiveType: ServerArchiveTypes
    }): Promise<{ archiveServiceGroup: CloudFunctionResult }> => {
      return request(
        gql`
          mutation ($serviceGroupId: Int!, $archiveType: ArchiveTypes!) {
            archiveServiceGroup(serviceGroupId: $serviceGroupId, archiveType: $archiveType) {
              message
              code
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: (_, { archiveType }) => {
        if (archiveType === ServerArchiveTypes.Check) return
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(SERVICE_GROUPS_QUERY_KEY),
        })
        queryClient.invalidateQueries([DEACTIVATED_SERVICE_GROUPS_QUERY_KEY])
        queryClient.invalidateQueries([DEACTIVATED_SERVICES_QUERY_KEY])
        queryClient.invalidateQueries([SERVICES_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
