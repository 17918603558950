import { TRANSACTION_TYPES } from '@expane/data'

export type Transaction = {
  type: number
  typeVariation?: number | null
  cardId?: number | null
  amount: number
}

// список транзакций, которых отображать на странице
export const getTransactionsListToDisplay = <T extends Transaction>({
  transactions,
  hideClientsDeposit,
  page,
}: {
  transactions: T[]
  hideClientsDeposit?: boolean | null
  page: 'finance' | 'client'
}) => {
  const transactionsToFilter =
    page === 'finance'
      ? getTransactionsListByTypesToDisplayFinance(transactions)
      : getTransactionsListByTypesToDisplayClient(transactions)

  if (hideClientsDeposit) {
    return transactionsToFilter.filter(
      transaction => !checkIsTransactionDepositToHideOrNot(transaction),
    )
  } else return transactionsToFilter
}

// транзакции которые не отображаются в диалоге клиента/состояние счёта
const getTransactionsListByTypesToDisplayClient = <T extends Transaction>(transactions: T[]) =>
  transactions.filter(
    transaction =>
      // не отображаем которые были по абонементу и возврат по абонементу
      !(transaction.type === TRANSACTION_TYPES.payment.id && transaction.cardId) &&
      !(transaction.type === TRANSACTION_TYPES.referralPercentage.id) &&
      !(
        transaction.type === TRANSACTION_TYPES.refund.id &&
        transaction.typeVariation === TRANSACTION_TYPES.refund.variations.refundBySubscription.id
      ) &&
      !(
        transaction.type === TRANSACTION_TYPES.repaymentDeposit.id &&
        transaction.typeVariation ===
          TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByClientBalance.id
      ),
  )

// какие транзакции НЕ отображаем на странице
const getTransactionsListByTypesToDisplayFinance = <T extends Transaction>(transactions: T[]) =>
  transactions.filter(
    transaction =>
      !(
        transaction.cardId ||
        transaction.type === TRANSACTION_TYPES.activateGiftCard.id ||
        (transaction.type === TRANSACTION_TYPES.refund.id &&
          transaction.typeVariation ===
            TRANSACTION_TYPES.refund.variations.refundBySubscription.id) ||
        transaction.type === TRANSACTION_TYPES.salaryRate.id ||
        transaction.type === TRANSACTION_TYPES.openWorkingShift.id ||
        transaction.type === TRANSACTION_TYPES.closeWorkingShift.id ||
        transaction.type === TRANSACTION_TYPES.referralPercentage.id ||
        (transaction.type === TRANSACTION_TYPES.operation.id && transaction.amount === 0) ||
        (transaction.type === TRANSACTION_TYPES.repaymentDeposit.id &&
          transaction.typeVariation ===
            TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByClientBalance.id)
      ),
  )

const checkIsTransactionDepositToHideOrNot = <T extends Transaction>(transaction: T) =>
  transaction.typeVariation === TRANSACTION_TYPES.deposit.variations.depositDuringPayment.id &&
  transaction.type === TRANSACTION_TYPES.deposit.id

export const findTransactionAccountNameForShortInfo = (
  toAccount: { id: number; name: string } | undefined | null,
  fromAccount: { id: number; name: string } | undefined | null,
) => {
  if (toAccount?.name) return toAccount?.name
  if (fromAccount?.name) return fromAccount?.name
  else return '-'
}
