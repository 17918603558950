import { gql } from 'graphql-request'
import { request, useQuery, reportGqlError } from '../../api'
import { EMPLOYEE_SCHEDULES_QUERY_KEY, SCHEDULES_QUERY_KEY } from '../schedule/queryKeys'
import { employeeScheduleFragment } from '../schedule/schedule.fragments'
import { EmployeeScheduleDto, parseDatesInEmployeeScheduleGqlResponse } from './logic'
import { DEFAULT_TIMEZONE } from '@expane/date'

export function useFetchEmployeeScheduleById(id: number | undefined, timezone: string | undefined) {
  return useQuery(
    [EMPLOYEE_SCHEDULES_QUERY_KEY, SCHEDULES_QUERY_KEY, { id }],
    async (): Promise<EmployeeScheduleDto> => {
      const result = await request(
        gql`
          ${employeeScheduleFragment}
          query ($id: Int!) {
            employeeScheduleById(id: $id) {
              ...employeeScheduleType
            }
          }
        `,
        { id },
      )
      return parseDatesInEmployeeScheduleGqlResponse(
        result.employeeScheduleById,
        timezone ?? DEFAULT_TIMEZONE,
      )
    },
    {
      enabled: Boolean(id) && Boolean(timezone),
      onError: reportGqlError,
      queryName: 'useFetchEmployeeScheduleById',
    },
  )
}
