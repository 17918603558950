import {
  CloseButton,
  Dialog,
  PlaceholderInput,
  PlaceholderString,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PlaceholderSwitch } from 'ui/PlaceholderContent'
import { SaveButton } from 'widgets/Buttons'

export const CreateEmployeesSchedulesDialogPlaceholder: FC<{ closeDialog: () => void }> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('employeeSchedules.adding')}</Dialog.Title>
      <Dialog.Body className="w-192 h-74.5 flex flex-col">
        <TableContainer>
          <TableHeader>
            <tr>
              <TableHeaderCell className="w-48">{t('employee.name')}</TableHeaderCell>
              <TableHeaderCell className="w-48">{t('startDate')}</TableHeaderCell>
              <TableHeaderCell className="w-40">{t('type')}</TableHeaderCell>
              <TableHeaderCell className="w-48 flex items-center gap-1">
                {t('schedule.name')}{' '}
              </TableHeaderCell>
            </tr>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>
                <PlaceholderString />
              </TableCell>
              <TableCell>
                <PlaceholderInput />
              </TableCell>
              <TableCell>
                <div className="flex gap-1 items-center">
                  <PlaceholderSwitch />
                  {t('scheduleType.regular')}
                </div>
              </TableCell>
              <TableCell>
                <PlaceholderInput />
              </TableCell>
            </TableRow>
          </TableBody>
        </TableContainer>
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton disabled />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
