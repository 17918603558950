import { useFetchCurrentBranchTimezone, useFetchExtendedEmployees } from '@expane/data'
import { SelectDropdown } from '@expane/ui'
import { filterEmployeesProvideServices } from 'logic/employees'
import { transformPersonsForSelect } from 'logic/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'

interface Props {
  employeeId: number | undefined
  setEmployeeId: (employeeId: number | undefined) => void
}
export const EmployeeSelect: FC<Props> = ({ employeeId, setEmployeeId }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: employees } = useFetchExtendedEmployees(timezone, branchId)

  return (
    <SelectDropdown
      items={employees ? transformPersonsForSelect(filterEmployeesProvideServices(employees)) : []}
      value={employeeId}
      placeholder={t('placeholders.employeeSelect')}
      noDataMessage={t('noSuchEmployee')}
      className="w-40 mt-2"
      onSelectChange={value => {
        if (value) {
          setEmployeeId(value)
        } else {
          setEmployeeId(undefined)
        }
      }}
      isClearable
    />
  )
}
