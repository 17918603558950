import {
  ServerTransactionToCalcBalanceType,
  useFetchAccounts,
  useFetchCurrentBranchTimezone,
  useFetchTransactionsToCalcBalance,
} from '@expane/data'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import {
  calcBalanceOnAccount,
  calcTotalBalanceOnAllAccounts,
} from '@expane/logic/finances/calculation'
import { useAccountsWithCheckbox } from '@expane/logic/payment/checkbox'
import { Paper, PlaceholderString } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { AnalyticsBlockBody, AnalyticsBlockTitle } from 'pages/AnalyticsPage'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'

export const AccountsBlock: FC<{ className?: string }> = observer(({ className }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)
  const { data: transactionsToCalcBalance, isLoading: isLoadingTransactionsToCalcBalance } =
    useFetchTransactionsToCalcBalance(timezone, branchId)

  const { totalValue } = calcTotalBalanceOnAllAccounts(transactionsToCalcBalance ?? [])

  const isLoading = isLoadingAccounts || isLoadingTransactionsToCalcBalance

  const { accountsWithPOSLabels } = useAccountsWithCheckbox(accounts ?? [])

  return (
    <Paper className={`p-4 ${className ?? ''}`}>
      <AnalyticsBlockTitle
        title={t('accounts')}
        hintMessage={t('accountsBlock.amountOfMoney')}
        namePeriodOfTime={t('accountsBlock.today')}
        isLoading={isLoading}
      />

      <AnalyticsBlockBody
        value={totalValue}
        text={t('accountsBlock.nowInAccounts')}
        isValueMoney
        isLoading={isLoading}
      >
        {isLoading ? (
          <>
            <PlaceholderString />
            <PlaceholderString />
          </>
        ) : (
          accountsWithPOSLabels?.map(account => (
            <ValuesByAccount
              key={account.id}
              id={account.id}
              name={account.name}
              transactions={transactionsToCalcBalance}
            />
          ))
        )}
      </AnalyticsBlockBody>
    </Paper>
  )
})

interface ValuesByAccountProps {
  id: number
  name: string
  transactions?: ServerTransactionToCalcBalanceType[]
}

const ValuesByAccount: FC<ValuesByAccountProps> = observer(({ id, name, transactions }) => {
  const branchId = store.branch.branchId
  const value = calcBalanceOnAccount(transactions, id)

  const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

  if (value === 0) return null

  return (
    <li className="flex justify-between items-center">
      <p>{name}</p>
      <p>{convertToMoneyCode(value)}</p>
    </li>
  )
})
