import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { PermissionPageBlockProps, allSelectedPermissions } from '../logic'
import { PermissionBlock, PermissionBlockTitle, PermissionCheckbox } from '.'
import { PermissionPageRowProps } from '@expane/logic/permission/logic'

export const AnalyticsBlock: FC<PermissionPageBlockProps> = ({
  control,
  checkRelation,
  checkRelationBulk,
  isItOwnerRole,
  setValue,
}) => {
  const { t } = useTranslation()

  const onSelectAll = () => {
    checkRelationBulk({
      permissions: [
        'permissions.analytics.get',
        'permissions.report.clientsVisits',
        'permissions.report.incomingPayments',
        'permissions.report.leadsOfClients',
        'permissions.report.outgoingPayments',
        'permissions.report.revenueByAccount',
        'permissions.report.revenueExpenses',
        'permissions.report.saleOfCards',
        'permissions.report.saleOfProducts',
        'permissions.report.saleOfServices',
      ],
      onConfirm: () => {
        setValue('permissions.analytics', allSelectedPermissions.analytics)
        setValue('permissions.report', allSelectedPermissions.report)
      },
    })
  }

  return (
    <>
      <PermissionBlockTitle onSelectAll={onSelectAll} title={t('analytics')} />

      <AnalyticsPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <ReportPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
    </>
  )
}

const AnalyticsPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('analytics')}>
      <Controller
        name="permissions.analytics.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.analytics.get',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.analytics.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const ReportPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('reports.name')}>
      <Controller
        name="permissions.report.saleOfServices"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.report.saleOfServices',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.report.saleOfServices')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.report.saleOfCards"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.report.saleOfCards',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.report.saleOfCards')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.report.saleOfProducts"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.report.saleOfProducts',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.report.saleOfProducts')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.report.clientsVisits"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.report.clientsVisits',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.report.clientsVisits')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.report.leadsOfClients"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.report.leadsOfClients',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.report.leadsOfClients')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.report.incomingPayments"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.report.incomingPayments',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.report.incomingPayments')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.report.outgoingPayments"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.report.outgoingPayments',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.report.outgoingPayments')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.report.revenueExpenses"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.report.revenueExpenses',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.report.revenueExpenses')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.report.revenueByAccount"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.report.revenueByAccount',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.report.revenueByAccount')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}
