import { gql } from 'graphql-request'
import { anonymouslyBranchFragment } from '../branch'

export const businessByIdFragment = gql`
  fragment businessByIdType on business {
    id
    onHold
    name
    photo
  }
`

export const businessWithOwnerIdFragment = gql`
  fragment businessWithOwnerIdType on business {
    id
    ownerId
    name
    photo
  }
`

export const anonymouslyBusinessByIdFragment = gql`
  ${anonymouslyBranchFragment}
  fragment anonymouslyBusinessByIdType on business {
    id
    name
    branches {
      ...anonymouslyBranchType
    }
  }
`
