import { FC, useRef } from 'react'
import { getYear } from '@expane/date'
import i18next from 'i18next'
import Calendar, { ViewCallback, CalendarTileProperties } from 'react-calendar'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'

type CustomCalendarProps = {
  value: Date[]
  onChange: (date: Date) => void
  activeStartDate: Date
  className?: string
  onViewChange: ViewCallback
  onChevronForward: () => void
  onChevronBack: () => void
  tileClassName: (props: CalendarTileProperties) => string
  tileContent: (props: CalendarTileProperties) => JSX.Element
}

export const CustomCalendar: FC<CustomCalendarProps> = ({
  value,
  onChange,
  activeStartDate,
  onViewChange,
  onChevronForward,
  onChevronBack,
  tileClassName,
  tileContent,
  className,
}) => {
  const locale = i18next.language

  const usedDate = useRef<Date | null>(null)

  // range date picker with `allowPartialRange === true` has its own logic of changing value,
  // but we need only the last clicked date
  const handleChange = (value: Date[]) => {
    if (value.length === 1) {
      const date = value[0]
      onChange(date)
      usedDate.current = date
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const notUsedDate = value.find(date => date.getTime() !== usedDate.current?.getTime())!
      onChange(notUsedDate)
      usedDate.current = null
    }
  }

  return (
    <Calendar
      value={value}
      onChange={handleChange}
      activeStartDate={activeStartDate}
      onViewChange={onViewChange}
      selectRange
      allowPartialRange
      className={className}
      nextLabel={
        <IoChevronForward onClick={onChevronForward} size="1.5rem" className="text-primary-500" />
      }
      next2Label={null}
      prevLabel={
        <IoChevronBack onClick={onChevronBack} size="1.5rem" className="text-primary-500" />
      }
      prev2Label={null}
      tileClassName={tileClassName}
      tileContent={tileContent}
      formatMonthYear={(defaultLocale, date) =>
        date.toLocaleDateString(locale ?? defaultLocale, {
          month: 'long',
        }) + ` ${getYear(date)}`
      }
      formatShortWeekday={(defaultLocale, date) =>
        date.toLocaleDateString(locale ?? defaultLocale, {
          weekday: 'short',
        })
      }
      formatMonth={(defaultLocale, date) =>
        date.toLocaleDateString(locale ?? defaultLocale, {
          month: 'long',
        })
      }
    />
  )
}
