import {
  ServerAccountType,
  ServerTransactionToCalcBalanceType,
  ServerTransactionType,
  TRANSACTION_TYPES,
} from '@expane/data'
import {
  getTransactionsProductsReturn,
  getTransactionsTransportCosts,
  getTransactionsWithAllTypesExpensesOnAccount,
  getTransactionsWithCashOut,
  getTransactionsWithClientsDeposits,
  getTransactionsWithOtherBusinessExpenses,
  getTransactionsWithOtherBusinessRevenue,
  getTransactionsWithProductsPurchase,
  getTransactionsWithRefundsFromAccount,
  getTransactionsWithSalaries,
} from './filters'
import { calcValues } from './../utils'
import {
  convertTransactionsToServiceReportItem,
  getServiceReportItemsNotByCard,
} from '../reports/services'
import { calcReportItemsTotalPrice } from '../reports/logic'
import {
  convertTransactionsToCardReportItem,
  getGiftCardReportItems,
  getNotRefundedReportItems,
  getSubscriptionReportItems,
} from '../reports/cards'
import { convertTransactionMovementsQuantity } from '../movement'
import {
  convertTransactionsToProductReportItem,
  getProductReportConsumableItems,
  getProductReportForSaleItems,
} from '../reports/products'
import {
  getTransactionsWithPaymentInCredit,
  getTransactionsWithPaymentInCreditRefund,
  getTransactionsWithRepaymentDeposit,
} from './filters'

export const calcProductsReturn = (transactions: ServerTransactionType[]): number => {
  const productsReturn = getTransactionsProductsReturn(transactions)

  return productsReturn ? calcValues(productsReturn) : 0
}
export const calcOtherBusinessRevenue = (transactions: ServerTransactionType[]): number => {
  const otherBusinessRevenueTransactions = getTransactionsWithOtherBusinessRevenue(transactions)

  return otherBusinessRevenueTransactions ? calcValues(otherBusinessRevenueTransactions) : 0
}
export const calcClientsDeposits = <
  T extends { client?: { id?: number } | null; type: number; amount: number },
>(
  transactions: T[],
) => {
  const clientsDeposits = getTransactionsWithClientsDeposits(transactions)

  return clientsDeposits ? calcValues(clientsDeposits) : 0
}

export const calcClientsPayments = (transactions: ServerTransactionType[]) => {
  // услуги
  const serviceReportItems = convertTransactionsToServiceReportItem(transactions)
  const serviceReportItemsWithoutCard = getServiceReportItemsNotByCard(serviceReportItems)
  // в подсчет приходят и групповые услуги
  const servicesTotalValue = calcReportItemsTotalPrice(serviceReportItemsWithoutCard)

  // карты
  const cardReportItems = convertTransactionsToCardReportItem(transactions)
  // подарочные сертификаты
  const giftCardReportItems = getGiftCardReportItems(getNotRefundedReportItems(cardReportItems))
  const giftCardsTotalValue = calcReportItemsTotalPrice(giftCardReportItems)
  // абонементы
  const subscriptionReportItems = getSubscriptionReportItems(
    getNotRefundedReportItems(cardReportItems),
  )
  const subscriptionsTotalValue = calcReportItemsTotalPrice(subscriptionReportItems)

  // товары
  const convertedTransactions = convertTransactionMovementsQuantity(transactions)
  const productReportItems = convertTransactionsToProductReportItem(convertedTransactions)
  // только товары для продажи(исключаем расходники)
  const productReportForSaleItems = getProductReportForSaleItems(productReportItems)
  const productsTotalValue = calcReportItemsTotalPrice(productReportForSaleItems)
  // опциональные расходники
  const consumableProductReportItems = getProductReportConsumableItems(productReportItems)
  const consumablesTotalValue = calcReportItemsTotalPrice(consumableProductReportItems)

  const clientsPaymentsTotalValue =
    servicesTotalValue +
    giftCardsTotalValue +
    subscriptionsTotalValue +
    productsTotalValue +
    consumablesTotalValue

  return {
    clientsPaymentsTotalValue,
    servicesTotalValue,
    giftCardsTotalValue,
    subscriptionsTotalValue,
    productsTotalValue,
    consumablesTotalValue,
  }
}

export const calcRefundsExpenses = (transactions: ServerTransactionType[]): number => {
  const refundsTransactions = getTransactionsWithRefundsFromAccount(transactions)

  return refundsTransactions ? calcValues(refundsTransactions) : 0
}

export const calcSalariesExpenses = (transactions: ServerTransactionType[]): number => {
  const salariesTransactions = getTransactionsWithSalaries(transactions)

  return salariesTransactions ? calcValues(salariesTransactions) : 0
}

export const calcProductsPurchase = (transactions: ServerTransactionType[]): number => {
  const productsPurchase = getTransactionsWithProductsPurchase(transactions)

  return productsPurchase ? calcValues(productsPurchase) : 0
}

export const calcTransportCosts = (transactions: ServerTransactionType[]): number => {
  const transportCosts = getTransactionsTransportCosts(transactions)

  return transportCosts ? calcValues(transportCosts) : 0
}

export const calcOtherBusinessExpenses = (transactions: ServerTransactionType[]): number => {
  const otherBusinessExpenses = getTransactionsWithOtherBusinessExpenses(transactions)

  return otherBusinessExpenses ? calcValues(otherBusinessExpenses) : 0
}

// подсчет денег на конкретном счету
export const calcBalanceOnAccount = (
  transactions: ServerTransactionToCalcBalanceType[] | undefined,
  accountId: number,
) => {
  const toAccount = transactions?.filter(t => t.toAccountId === accountId)
  const fromAccount = transactions?.filter(t => t.fromAccountId === accountId)

  const toAccountValue = toAccount ? calcValues(toAccount) : 0
  const fromAccountValue = fromAccount ? calcValues(fromAccount) : 0

  return toAccountValue - fromAccountValue
}

export const calcAllExpensesOnAccount = (id: number, transactions?: ServerTransactionType[]) => {
  const allTypesExpensesOnAccount = getTransactionsWithAllTypesExpensesOnAccount(id, transactions)

  return allTypesExpensesOnAccount ? calcValues(allTypesExpensesOnAccount) : 0
}

// подсчёт общей суммы денег на всех счетах
export const calcTotalBalanceOnAllAccounts = (
  transactions: ServerTransactionToCalcBalanceType[],
) => {
  const result = { toAccountTotalValue: 0, fromAccountTotalValue: 0, totalValue: 0 }

  // исключаются транзакции перемещений между счетами
  const toAccount = transactions.filter(
    t => t.toAccountId !== null && t.type !== TRANSACTION_TYPES.operation.id,
  )
  const fromAccount = transactions.filter(
    t => t.fromAccountId !== null && t.type !== TRANSACTION_TYPES.operation.id,
  )

  result.toAccountTotalValue = toAccount ? calcValues(toAccount) : 0
  result.fromAccountTotalValue = fromAccount ? calcValues(fromAccount) : 0
  result.totalValue = result.toAccountTotalValue - result.fromAccountTotalValue

  return result
}

export const getAccountsWithBalance = (
  accounts: ServerAccountType[] | undefined,
  transactionsToCalcBalance: ServerTransactionToCalcBalanceType[] | undefined,
) => {
  if (!accounts || !transactionsToCalcBalance) return []
  else
    return accounts
      ?.map(account => {
        return {
          ...account,
          balance: calcBalanceOnAccount(transactionsToCalcBalance, account.id),
        }
      })
      .filter(account => account.balance !== 0)
}

export const calcCashOut = (transactions: ServerTransactionType[]): number => {
  const cashOut = getTransactionsWithCashOut(transactions)

  return cashOut ? calcValues(cashOut) : 0
}

export const calcNotPaidCredit = (transactions: ServerTransactionType[]): number => {
  const transactionsWithPaymentInCredit = getTransactionsWithPaymentInCredit(transactions)
  const transactionsWithPaymentInCreditRefunds =
    getTransactionsWithPaymentInCreditRefund(transactions)
  const transactionsWithRepaymentDeposit = getTransactionsWithRepaymentDeposit(transactions)

  const paymentInCreditValue = calcValues(transactionsWithPaymentInCredit ?? [])
  const paymentInCreditRefundsValue = calcValues(transactionsWithPaymentInCreditRefunds ?? [])
  const repaymentDepositsValue = calcValues(transactionsWithRepaymentDeposit ?? [])

  return paymentInCreditValue - paymentInCreditRefundsValue - repaymentDepositsValue
}
