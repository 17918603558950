import { gql } from 'graphql-request'
import { movementBriefFragment } from '../movement/movement.fragments'

export const transactionBriefFragment = gql`
  fragment transactionBriefType on transaction {
    id
    amount
    date
    type
    typeVariation
    employeeId
  }
`

export const transactionByIdFragment = gql`
  ${transactionBriefFragment}
  ${movementBriefFragment}
  fragment transactionByIdType on transaction {
    ...transactionBriefType
    bookingId
    booking {
      status
    }
    comment
    cardId
    parentId
    startPeriod
    endPeriod
    transactionsServices {
      price
      discount
      service {
        id
        name
        serviceProducts(where: { branchId: { _eq: $branchId } }) {
          productId
          quantity
        }
      }
    }
    toAccount {
      id
      name
    }
    fromAccount {
      id
      name
    }
    client {
      id
      firstName
      lastName
    }
    employee {
      lastName
      firstName
      id
    }
    author {
      lastName
      firstName
    }
    revenueReason {
      name
    }
    expensesReason {
      name
    }
    transactionsCards {
      price
      discount
      card {
        type
        id
        activatedAt
        cardTemplate {
          id
          name
          price
          type
        }
      }
    }
    movement {
      ...movementBriefType
    }
    transactionReceipts {
      receiptId
    }
  }
`

export const transactionByEmployeeIdFragment = gql`
  fragment transactionByEmployeeIdType on transaction {
    id
    date
    amount
    type
    cardId
    transactionsServices {
      price
      service {
        name
      }
    }
    transactionsCards {
      price
      card {
        type
        id
        cardTemplate {
          id
          name
          price
          type
        }
      }
    }
  }
`

export const transactionToCalcBalanceFragment = gql`
  fragment transactionToCalcBalanceType on transaction {
    id
    amount
    type
    typeVariation
    date
    fromAccountId
    toAccountId
  }
`

export const transactionBriefWithCardsFragment = gql`
  ${transactionBriefFragment}
  fragment transactionBriefWithCardsType on transaction {
    ...transactionBriefType
    parentId
    cardId
    client {
      id
    }
    transactionsCards {
      price
      discount
      card {
        type
        id
        cardTemplate {
          id
          name
          price
          type
        }
      }
    }
  }
`

export const transactionBriefWithMovementsFragment = gql`
  ${transactionBriefFragment}
  ${movementBriefFragment}
  fragment transactionBriefWithMovementsType on transaction {
    ...transactionBriefType
    parentId
    client {
      id
    }
    movement {
      ...movementBriefType
    }
  }
`

export const transactionBriefWithClientFragment = gql`
  ${transactionBriefFragment}
  fragment transactionBriefWithClientType on transaction {
    ...transactionBriefType
    cardId
    clientId
    client {
      id
    }
  }
`

export const transactionFragment = gql`
  ${transactionBriefFragment}
  ${movementBriefFragment}
  fragment transactionType on transaction {
    ...transactionBriefType
    toAccountId
    fromAccountId
    parentId
    cardId
    clientId
    employeeId
    typeVariation
    startPeriod
    endPeriod
    bookingId
    fromAccount {
      id
      name
    }
    toAccount {
      id
      name
    }
    author {
      firstName
      lastName
    }
    transactionsCards {
      price
      discount
      card {
        type
        id
        cardTemplate {
          id
          name
          price
          type
        }
      }
    }
    client {
      id
      firstName
      lastName
    }
    employee {
      lastName
      firstName
      id
      groupId
    }
    transactionsServices {
      price
      discount
      costPrice
      service {
        name
        id
        price
        type
      }
    }
    revenueReason {
      id
      name
    }
    expensesReason {
      id
      name
    }
    movement {
      ...movementBriefType
    }
  }
`

export const transactionInBookingFragment = gql`
  ${movementBriefFragment}
  fragment transactionInBookingType on transaction {
    id
    amount
    type
    typeVariation
    clientId
    employeeId
    cardId
    date
    parentId
    bookingId
    transactionsServices {
      serviceId
      price
      discount
      service {
        name
        serviceProducts(where: { branchId: { _eq: $branchId } }) {
          productId
          quantity
        }
      }
    }
    transactionsCards {
      cardId
      price
      card {
        type
        cardTemplate {
          name
        }
      }
    }
    movement {
      ...movementBriefType
    }
    transactionReceipts {
      receiptId
    }
  }
`

export const transactionSalaryFragment = gql`
  fragment transactionSalaryType on transaction {
    id
    amount
    type
    startPeriod
    endPeriod
    employeeId
    date
    comment
    employee {
      lastName
      firstName
      id
      groupId
    }
  }
`

export const transactionBriefWithBookingLocationFragment = gql`
  fragment transactionBriefWithBookingLocationType on transaction {
    id
    amount
    date
    type
    typeVariation
    bookingId
    booking {
      location {
        id
      }
    }
  }
`
