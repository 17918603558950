import { RefObject, useCallback, useEffect, useState } from 'react'

export const useGetFocusedElement = (trackedElements: RefObject<Element>[]) => {
  const [focusedElement, setFocusedElement] = useState<Element | null>(null)

  const handleFocusIn = useCallback(() => {
    if (
      trackedElements.some(
        element => element.current !== null && element.current === document.activeElement,
      )
    )
      setFocusedElement(document.activeElement)

    if (trackedElements.every(element => document.activeElement !== element.current))
      setFocusedElement(null)
  }, [trackedElements])

  useEffect(() => {
    document.addEventListener('focusin', handleFocusIn)
    return () => {
      document.removeEventListener('focusin', handleFocusIn)
    }
  }, [handleFocusIn])

  return focusedElement
}
