import { EmployeeScheduleDto, ServerDynamicScheduleDateType } from '@expane/data'
import { createCurrentDate, startOfDay } from '@expane/date'
import { TAILWIND_TO_REM_RATIO } from 'logic/ui'
import { CELL_HEIGHT, SMALL_CELL_HEIGHT } from 'ui/Calendar'
import { SCHEDULE_TYPES } from '@expane/logic/employeeSchedule'

export const TITLE_CELL_HEIGHT_IN_REM = CELL_HEIGHT / TAILWIND_TO_REM_RATIO
export const HALF_HOUR_STEP = 0.5

export const getPositionStylesForDynamicDateBox = (
  dynamicDate: ServerDynamicScheduleDateType,
  calendarStartHour: number,
  calendarEndHour: number,
) => {
  const top =
    ((dynamicDate.timingStart - calendarStartHour) / HALF_HOUR_STEP) *
      (SMALL_CELL_HEIGHT / TAILWIND_TO_REM_RATIO) +
    TITLE_CELL_HEIGHT_IN_REM +
    'rem'
  const bottom =
    ((calendarEndHour - dynamicDate.timingEnd) / HALF_HOUR_STEP) *
      (SMALL_CELL_HEIGHT / TAILWIND_TO_REM_RATIO) +
    'rem'
  return { top, bottom, left: 1, right: 1, position: 'absolute' }
}

export type ScheduleDialogFormValues = {
  scheduleType: { id: number; name: string }
  schedule: number | null
  scheduleStartDate: Date
  dynamicDates: ServerDynamicScheduleDateType[]
  branchId: number
}

export const getDefaultValuesForForm = (
  employeeSchedule: EmployeeScheduleDto | undefined,
  timezone: string | undefined,
  branchId: number,
): ScheduleDialogFormValues => {
  if (!employeeSchedule) {
    return {
      scheduleType: SCHEDULE_TYPES.schedule,
      schedule: null,
      scheduleStartDate: startOfDay(createCurrentDate(timezone)),
      dynamicDates: [],
      branchId,
    }
  }

  if (employeeSchedule.isDynamic) {
    return {
      scheduleType: SCHEDULE_TYPES.dynamic,
      dynamicDates: employeeSchedule.dynamicDates,
      schedule: null,
      scheduleStartDate: employeeSchedule.startDate,
      branchId,
    }
  }

  return {
    scheduleType: SCHEDULE_TYPES.schedule,
    schedule: employeeSchedule.schedule.id,
    scheduleStartDate: employeeSchedule.startDate,
    dynamicDates: [],
    branchId,
  }
}
