import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAddCircle, IoCheckboxOutline, IoClose, IoSaveOutline, IoTrashBin } from 'react-icons/io5'
import { Button, ButtonProps, OtherButtonProps } from './'

export const CloseButton: FC<OtherButtonProps> = props => {
  const { t } = useTranslation()

  return (
    <Button type="outline" Icon={IoClose} {...props}>
      {t('close')}
    </Button>
  )
}

export const OpenButton: FC<ButtonProps> = props => {
  const { t } = useTranslation()

  return <Button {...props}>{t('open')}</Button>
}

export const ResetFilterButton: FC<Omit<OtherButtonProps, 'twoLines'>> = props => {
  const { t } = useTranslation()

  return (
    <Button type="outline" twoLines Icon={IoClose} {...props}>
      {t('resetFilters')}
    </Button>
  )
}

export const CancelButton: FC<ButtonProps> = ({ type = 'danger', ...props }) => {
  const { t } = useTranslation()
  return (
    <Button Icon={IoTrashBin} type={type} {...props}>
      {t('cancel')}
    </Button>
  )
}

export const ChooseButton: FC<OtherButtonProps> = props => {
  const { t } = useTranslation()
  return (
    <Button Icon={IoCheckboxOutline} {...props}>
      {t('choose')}
    </Button>
  )
}

export const SaveButtonWithoutBillingCheck: FC<OtherButtonProps & { isCreate?: boolean }> = ({
  isCreate,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Button type="primary" Icon={isCreate ? IoAddCircle : IoSaveOutline} {...props}>
      {isCreate ? t('create') : t('save')}
    </Button>
  )
}
