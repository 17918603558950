import {
  useFetchCurrentBranchTimezone,
  useFetchTransactionById,
  useFetchTransactionsByParentId,
} from '@expane/data'
import { Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { store } from 'store'
import { TransactionSubscriptionDialog } from 'widgets/TransactionSubscriptionDialog/SubscriptionDialog'
import { TransactionSubscriptionListDialog } from 'widgets/TransactionSubscriptionDialog/SubscriptionListDialog'
import { TransactionSubscriptionDialogPlaceholder } from 'widgets/TransactionSubscriptionDialog/TransactionSubscriptionDialogPlaceholder'

interface TransactionSubscriptionDialogProps {
  transactionId: number
  closeDialog: () => void
}

export const useOpenTransactionSubscriptionDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()
  const dialogTransactionId = useRef<number>()

  const openTransactionSubscriptionDialog = (transactionId: number, onClose?: () => void) => {
    dialogTransactionId.current = transactionId
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const transactionSubscriptionDialog =
    isOpen && dialogTransactionId.current ? (
      <TransactionSubscriptionDialogLogic
        transactionId={dialogTransactionId.current}
        closeDialog={closeDialog}
      />
    ) : null

  return {
    openTransactionSubscriptionDialog,
    transactionSubscriptionDialog,
  }
}

export const TransactionSubscriptionDialogLogic: FC<TransactionSubscriptionDialogProps> = observer(
  ({ transactionId, closeDialog }) => {
    const branchId = store.branch.branchId

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { data: transactionById, isLoading: isLoadingTransactionById } = useFetchTransactionById(
      transactionId,
      timezone,
      branchId,
    )
    const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()
    const { data: refundsForTransaction, isLoading: isLoadingRefundsForTransaction } =
      useFetchTransactionsByParentId(transactionId, timezone, branchId)

    const isLoading =
      isLoadingTransactionById || isLoadingMyPermissions || isLoadingRefundsForTransaction
    const isNoData = !transactionById || !myPermissions || !refundsForTransaction

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
    if (!isLoading && isNoData) return null

    const isOneSubscriptionInTransaction = transactionById?.transactionsCards.length === 1

    return (
      <Modal close={closeDialog}>
        <Dialog>
          {isLoading ? (
            <TransactionSubscriptionDialogPlaceholder closeDialog={closeDialog} />
          ) : (
            <>
              {isOneSubscriptionInTransaction ? (
                <TransactionSubscriptionDialog
                  closeDialog={closeDialog}
                  subscriptionId={transactionById.transactionsCards[0].card.id}
                  transactionById={transactionById}
                  myPermissions={
                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    myPermissions!
                  }
                  refundsForTransaction={
                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    refundsForTransaction!
                  }
                  branchId={branchId}
                />
              ) : (
                <TransactionSubscriptionListDialog
                  closeDialog={closeDialog}
                  transactionById={
                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    transactionById!
                  }
                  myPermissions={
                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    myPermissions!
                  }
                  refundsForTransaction={
                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    refundsForTransaction!
                  }
                  branchId={branchId}
                />
              )}
            </>
          )}
        </Dialog>
      </Modal>
    )
  },
)
