import {
  CloudFunctionResponseCodes,
  ExtendedEmployee,
  ServerArchiveEmployeeTypes,
  ServerEmployeeGroupType,
  useArchiveEmployee,
  useCreateEmployee,
  useFetchArchivedEmployeesGroups,
  useFetchBranchById,
  useFetchEmployeeByIdExtended,
  useFetchEmployees,
} from '@expane/data'
import { createCurrentDate } from '@expane/date'
import { permissions } from '@expane/logic/permission'
import { transformPersonName } from '@expane/logic/utils'
import {
  Input,
  OpenButton,
  SelectDropdown,
  useShowConfirmationPopup,
  useShowCustomWarningPopup,
} from '@expane/ui'
import { DateTimePicker, useSnackbar } from '@expane/widgets'
import { useShowArchiveConfirmationPopup } from 'logic/hooks/popup/useShowArchiveConfirmationPopup'
import { observer } from 'mobx-react-lite'
import { removeArchivedEmployeeFromCalendarSelect } from 'pages/BookingsPage/logic'
import { PageDataType } from 'pages/CreateBusinessPage/logic'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/sentry'
import { store } from 'store'
import { Avatar } from 'ui/Avatar'
import { MultiSelect } from 'ui/MultiSelect'
import { PhoneInput } from 'ui/PhoneInput'
import { ArchiveButton, DuplicateButton } from 'widgets/Buttons'

export interface EmployeeQuickInfoProps {
  employee: Omit<ExtendedEmployee, 'employeeSchedules'> | undefined
  myPermissions: string[]
  employeeGroups: Omit<ServerEmployeeGroupType, 'employees'>[]
  editEmployee: (id: number) => void
  type?: PageDataType
}

export const EmployeeQuickInfo: FC<EmployeeQuickInfoProps> = observer(
  ({ employee, editEmployee, employeeGroups, myPermissions, type = 'page' }) => {
    const { t } = useTranslation()

    const isArchivingAllowed = myPermissions.includes(permissions.employee.archive)
    const isEditingAllowed = myPermissions.includes(permissions.employee.set)

    const branchId = store.branch.branchId
    const { data: defaultBranch } = useFetchBranchById(branchId)

    const { data: archivedEmployeeGroups } = useFetchArchivedEmployeesGroups(branchId)
    const { data: employeeById } = useFetchEmployeeByIdExtended(
      isArchivingAllowed ? employee?.id : undefined,
      defaultBranch?.timezone,
      branchId,
    )
    const { data: employees } = useFetchEmployees(
      defaultBranch?.timezone,
      branchId,
      Boolean(isArchivingAllowed),
    )
    const { mutateAsync: archiveMutation, isLoading: isLoadingArchiveMutation } =
      useArchiveEmployee()
    const { mutateAsync: createEmployee } = useCreateEmployee()

    const [openSnackbar] = useSnackbar()
    const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

    const mutateArchiveEmployee = async () => {
      if (employee) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const branchId = defaultBranch!.id
          removeArchivedEmployeeFromCalendarSelect(employee.id, branchId)
          await archiveMutation({
            employeeId: employee.id,
            archiveType: ServerArchiveEmployeeTypes.Archive,
          })
        } catch (error) {
          reportError(error)
          openSnackbar(t('submitError'), 'error')
        }
      }
    }

    const mutateCreateEmployee = async (isEmailInUse = false) => {
      if (!defaultBranch?.timezone) {
        openSnackbar(t('submitError'), 'error')
        return
      }

      if (!branchId) {
        openSnackbar(t('submitError'), 'error')
        return
      }

      if (employeeById) {
        const startAt = createCurrentDate(defaultBranch.timezone)
        const result = await createEmployee({
          firstName: employeeById.firstName,
          lastName: employeeById.lastName,
          middleName: employeeById.middleName,
          birthDate: employeeById.birthDate ?? null,
          phone: employeeById.phone ?? null,
          photo: employeeById.photo ?? null,
          gender: employeeById.gender ?? null,
          email: isEmailInUse ? null : employeeById.email ?? null,
          groupId: employeeById.groupId,
          employeeSchedules: {
            data: defaultBranch?.schedule?.id
              ? [{ branchId, startDate: startAt, scheduleId: defaultBranch.schedule.id }]
              : [],
          },
          serviceEmployees: {
            data:
              employeeById.serviceEmployees.map(({ service }) => ({
                serviceId: service.id,
                branchId,
              })) ?? [],
          },
        })

        if (result?.insertEmployee?.id)
          openSnackbar(
            t('employee.savingSuccessful', { name: transformPersonName(employeeById) }),
            'success',
          )
        else openSnackbar(t('submitError'), 'error')
      }
    }

    const handleCreateNewEmployee = async () => {
      const isSameEmail = isEmailAlreadyInUse(employeeById?.email, employees ?? [])

      if (isSameEmail) {
        const description = (
          <>
            <p>
              {t('email')} <span className="italic">{employeeById?.email}</span>{' '}
              {t('alreadyInUse').toLocaleLowerCase()}
            </p>
            <p>{t('saveWithoutEmail')}</p>
          </>
        )
        showConfirmation({
          title: t('saveConfirmation'),
          description,
          onConfirm: async () => {
            await mutateCreateEmployee(true)
          },
        })
      } else await mutateCreateEmployee()
    }

    const { showCustomWarningPopup, customWarningModal } = useShowCustomWarningPopup()

    const { archiveConfirmationModal, showArchiveConfirmationPopup } =
      useShowArchiveConfirmationPopup(
        employee ? transformPersonName(employee) : '',
        mutateArchiveEmployee,
        t('employeeArchiveWarnings.archivedEmployeeLosesAccess'),
      )

    const handleOnArchive = async () => {
      try {
        if (employeeById) {
          const checkingResult = await archiveMutation({
            employeeId: employeeById.id,
            archiveType: ServerArchiveEmployeeTypes.Check,
          })

          const code = checkingResult.archiveEmployee.code

          const customWarningPopupTitle = t('warning')
          if (code === CloudFunctionResponseCodes.cannotArchiveHimself) {
            showCustomWarningPopup(
              customWarningPopupTitle,
              t('employeeArchiveWarnings.cannotHimself') +
                ' ' +
                t('employeeArchiveWarnings.archivedEmployeeLosesAccess'),
            )
            return
          }

          if (code === CloudFunctionResponseCodes.cannotArchiveOwner) {
            showCustomWarningPopup(
              customWarningPopupTitle,
              t('employeeArchiveWarnings.cannotOwner'),
            )
            return
          }

          if (code === CloudFunctionResponseCodes.cannotArchiveWithActiveSchedule) {
            showCustomWarningPopup(
              customWarningPopupTitle,
              t('employeeArchiveWarnings.hasActiveSchedules'),
            )
            return
          }

          if (code === CloudFunctionResponseCodes.cannotArchiveWithFutureBookings) {
            showCustomWarningPopup(
              customWarningPopupTitle,
              t('employeeArchiveWarnings.hasFutureBookings'),
            )
            return
          }

          if (code === CloudFunctionResponseCodes.successful) {
            showArchiveConfirmationPopup()

            return
          }
        }
        // Всі інші коди то помилки
        openSnackbar(t('submitError'), 'error')
      } catch (e) {
        openSnackbar(t('submitError'), 'error')
      }
    }

    const servicesByEmployee = employee?.serviceEmployees.map(sE => sE.service)

    if (employee === undefined || employee.id === undefined || !defaultBranch) return null

    const quickInfoWidth = type === 'page' ? 'w-1/3' : 'w-2/3'

    return (
      <div className={quickInfoWidth + ' py-5 px-2'}>
        {type !== 'createBusiness' && (
          <div className="flex-centered">
            <Avatar
              name={employee?.firstName ?? ''}
              type="big"
              url={employee?.photo ?? ''}
              reportErrorHandlerFunction={reportError}
            />
          </div>
        )}

        <div className="flex justify-between mt-3">
          <Input
            label={t('firstName')}
            containerClassName="w-1/2 mr-3"
            value={employee?.firstName ?? ''}
            disabled
          />

          <Input
            label={t('lastName')}
            containerClassName="w-1/2"
            value={employee?.lastName ?? ''}
            disabled
          />
        </div>

        <div className="flex mt-3">
          <PhoneInput
            label={t('phone')}
            disabled
            value={employee?.phone ?? ''}
            onChange={() => {
              return
            }}
            containerClassName="w-1/2 mr-3"
          />
          <Input
            containerClassName="w-1/2"
            value={employee?.email ?? ''}
            label={t('email')}
            disabled
          />
        </div>

        {type !== 'createBusiness' && (
          <DateTimePicker
            timezone={defaultBranch.timezone}
            className="w-1/2 pr-1.5 mt-3"
            label={t('birthDate')}
            value={employee?.birthDate ?? undefined}
            onChange={() => {
              return
            }}
            type="date"
            disabled
          />
        )}

        <SelectDropdown
          label={t('category')}
          items={[...employeeGroups, ...(archivedEmployeeGroups ?? [])]}
          className="mt-3"
          onSelectChange={() => {
            return
          }}
          value={employee?.groupId ?? undefined}
          disabled
        />

        {servicesByEmployee?.length && type !== 'createBusiness' ? (
          <MultiSelect
            items={servicesByEmployee ?? []}
            onItemSelect={() => {
              return
            }}
            selectedItems={servicesByEmployee ?? []}
            dropdownInputPlaceholder={''}
            label={t('services')}
            className="mt-3"
            disabled
          />
        ) : null}

        <div
          className={`flex ${
            employee.archived || !isArchivingAllowed || type === 'createBusiness'
              ? 'justify-end'
              : 'justify-between'
          } mt-5`}
        >
          {type !== 'createBusiness' && (
            <>
              {isArchivingAllowed && !employee.archived && (
                <ArchiveButton
                  className="mr-auto"
                  onClick={handleOnArchive}
                  spinner={isLoadingArchiveMutation}
                />
              )}
              {isEditingAllowed && employee.archived && (
                <DuplicateButton className="mr-auto" onClick={handleCreateNewEmployee} />
              )}
            </>
          )}

          <OpenButton
            onClick={() => editEmployee(employee.id)}
            disabled={isLoadingArchiveMutation}
          />
        </div>
        {archiveConfirmationModal}
        {confirmationModal}
        {customWarningModal}
      </div>
    )
  },
)

export const isEmailAlreadyInUse = <T extends { email?: string | null }>(
  email: string | null | undefined,
  persons: T[],
): boolean => Boolean(persons?.find(person => person.email && email && email === person.email))
