import { gql } from 'graphql-request'

export const bookingClientSettingsBriefFragment = gql`
  fragment bookingClientSettingsBriefType on bookingClientSettingsView {
    allowableUpdateTime
    allowableTimeForEditingStartDate
    allowedToCreateWithFunds
    allowedToCreateWithoutFunds
    allowedToCreateWithSubscription
    note
    isClientNoteEnabled
  }
`

export const bookingClientSettingsFragment = gql`
  fragment bookingClientSettingsType on bookingClientSettings {
    id
    allowableUpdateTime
    allowableTimeForEditingStartDate
    allowedToCreateWithFunds
    allowedToCreateWithoutFunds
    allowedToCreateWithSubscription
    isNoteEnabled
    note
    isClientNoteEnabled
  }
`

export const anonymouslyBookingClientSettingsFragment = gql`
  fragment anonymouslyBookingClientSettingsType on bookingClientSettingsView {
    businessId
    allowedToCreateWithoutFunds
    note
    isClientNoteEnabled
  }
`
