import {
  ServerModulesSettingsInput as ModulesSettings,
  ServerUpdateModulesSettingsResult,
  useFetchBusinessModulesSettings,
} from '@expane/data'
import { FEATURE_FLAGS, useFeatureFlags } from '../featureFlags'
import { useCallback, useMemo } from 'react'
import { TFunction } from 'react-i18next'

export type ModuleKey = keyof ModulesSettings
export type EnabledModules = Array<keyof ModulesSettings>
export type ItemWithModules<T> = T & { modules?: EnabledModules }
const allModulesOn: ModulesSettings = {
  branches: true,
  consumables: true,
  giftCards: true,
  managingEmployee: true,
  productsForSale: true,
  servicesForSale: true,
  subscriptions: true,
  checkbox: true,
}

export const useBusinessModulesSettings = (dto?: { enabled: boolean }) => {
  const { data: moduleSetting, isLoading: isLoadingBusinessModulesSettings } =
    useFetchBusinessModulesSettings(dto?.enabled || true)
  const { getFeatureFlag } = useFeatureFlags()
  const isEnableBusinessModules = getFeatureFlag(FEATURE_FLAGS.enableBusinessModules)
  /*
    If we disable business modules, we always return *true*
  */
  const getModuleSetting = (module: keyof ModulesSettings): boolean =>
    isEnableBusinessModules ? moduleSetting?.[module] ?? false : true
  /*
    If we disable business modules, we always return all modules on
   */
  const enabledModules = useMemo(() => {
    const data = isEnableBusinessModules ? moduleSetting : allModulesOn
    return data
      ? Object.keys(data).reduce((acc, currentKey) => {
          if (data[currentKey] && !acc.includes(currentKey as ModuleKey))
            acc.push(currentKey as ModuleKey)

          return acc
        }, [] as EnabledModules)
      : null
  }, [moduleSetting, isEnableBusinessModules])

  /**
   * Filters an array of items based on the presence of certain modules.
   * If business modules are enabled, it filters the items to include only those
   * that have at least one of the specified modules in `enabledModules`.
   * If business modules are not enabled, it returns all items without filtering.
   *
   * @param {Array<ItemWithModules<T>>} items - The array of items to filter. Each item might have a `modules` property.
   * @returns {T[]} Returns a filtered array of items. Each item in the array includes at least one of the modules specified in `enabledModules` or all items if business modules are not enabled.
   *
   * @template T - The type of the items.
   *
   * @example
   * const items = [
   *   { id: 1, modules: ['moduleA', 'moduleB'] },
   *   { id: 2, modules: ['moduleC'] }
   * ];
   * const enabledModules = ['moduleA', 'moduleD'];
   *
   * const filteredItems = getFilteredItemsByModules(items);
   * // `filteredItems` will include items that have at least one of the `enabledModules` or all items if business modules are not enabled.
   */
  const getFilteredItemsByModules = useCallback(
    <T>(items: Array<ItemWithModules<T>>): T[] => {
      if (!isEnableBusinessModules) return items
      const filtered: T[] = []
      for (const item of items) {
        if (item.modules && item.modules?.length > 0) {
          const isSomeModuleProvided = item.modules.some(moduleKey =>
            enabledModules?.includes(moduleKey),
          )

          if (isSomeModuleProvided) filtered.push(item)
        } else filtered.push(item)
      }
      return filtered
    },
    [enabledModules, isEnableBusinessModules],
  )
  return {
    enabledModules,
    getFilteredItemsByModules,
    getModuleSetting,
    isLoadingBusinessModulesSettings,
  }
}

export const useGetIsShownProductsByModules = () => {
  const { getModuleSetting } = useBusinessModulesSettings()

  const isProductsForSaleEnabled = getModuleSetting('productsForSale')
  const isConsumablesEnabled = getModuleSetting('consumables')

  return {
    isProductsForSaleEnabled,
    isConsumablesEnabled,
  }
}

export const useGetIsSaleByModules = () => {
  const { getModuleSetting } = useBusinessModulesSettings()

  return (
    getModuleSetting('subscriptions') ||
    getModuleSetting('giftCards') ||
    getModuleSetting('servicesForSale') ||
    getModuleSetting('productsForSale')
  )
}

export const DEFAULT_MODULES_SETTINGS = {
  managingEmployee: true,
  branches: true,
  subscriptions: true,
  giftCards: true,
  servicesForSale: true,
  productsForSale: true,
  consumables: true,
  checkbox: false,
}

export type ModuleSettingsForm = ModulesSettings

export const getDefaultBusinessModuleSettingsValues = (storage: ModuleSettingsForm | undefined) => {
  const managingEmployee = storage?.managingEmployee ?? DEFAULT_MODULES_SETTINGS.managingEmployee
  const branches = storage?.branches ?? DEFAULT_MODULES_SETTINGS.branches
  const subscriptions = storage?.subscriptions ?? DEFAULT_MODULES_SETTINGS.subscriptions
  const giftCards = storage?.giftCards ?? DEFAULT_MODULES_SETTINGS.giftCards
  const servicesForSale = storage?.servicesForSale ?? DEFAULT_MODULES_SETTINGS.servicesForSale
  const productsForSale = storage?.productsForSale ?? DEFAULT_MODULES_SETTINGS.productsForSale
  const consumables = storage?.consumables ?? DEFAULT_MODULES_SETTINGS.consumables
  const checkbox = storage?.checkbox ?? DEFAULT_MODULES_SETTINGS.checkbox

  return {
    managingEmployee,
    branches,
    subscriptions,
    giftCards,
    servicesForSale,
    productsForSale,
    consumables,
    checkbox,
  }
}

export const getModulesWarningMessage = (
  result: ServerUpdateModulesSettingsResult,
  t: TFunction,
): string => {
  const modulesKeys: string[] = []

  // Собираем ключи модулей, которые нельзя обновить
  if (result?.modulesSettingsThatCanUpdate) {
    for (const [key, value] of Object.entries(result.modulesSettingsThatCanUpdate)) {
      if (!value) {
        modulesKeys.push(t(`modules.${key}.name`))
      }
    }
  }

  if (modulesKeys.length === 0) {
    return ''
  } else {
    const translatedModules = modulesKeys.join(', ')
    return t('modulesWarningMessage', { moduleNames: translatedModules })
  }
}

export const getModulesKeysThatAreUnUpdatable = (result: ServerUpdateModulesSettingsResult) => {
  const modulesKeys: string[] = []

  // Собираем ключи модулей, которые нельзя обновить
  if (result?.modulesSettingsThatCanUpdate) {
    for (const [key, value] of Object.entries(result.modulesSettingsThatCanUpdate)) {
      if (!value) {
        // ключ соответсвует файлу перевода!
        modulesKeys.push(`modules.${key}.name`)
      }
    }
  }

  return modulesKeys
}
