import { FC } from 'react'
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form'
import { Input, InputLabel } from '@expane/ui'
import { useTranslation } from 'react-i18next'
import { gMapApi, useGeocodeAddress } from '@expane/data'
import { config } from 'config'
import { useSnackbar } from '@expane/widgets'
import { MapLocationIcon } from 'ui/Icons'
import { ButtonWithBillingCheck } from 'widgets/Buttons'
import { BranchCreateFormData } from 'widgets/BranchCreateDialog/index'

interface BranchAddressProps {
  control: Control<BranchCreateFormData>
  setValue: UseFormSetValue<BranchCreateFormData>
}

export const BranchAddress: FC<BranchAddressProps> = ({ control, setValue }) => {
  const { t } = useTranslation()

  const [openSnackBar] = useSnackbar()

  const { mutateAsync: geocodeAddress } = useGeocodeAddress()

  const watchedAddress = useWatch({ control, name: 'address' })
  const watchedGeocode = useWatch({ control, name: 'geocode' })

  const onFindClick = async () => {
    if (watchedAddress) {
      const result = await geocodeAddress({
        address: watchedAddress,
        apiKey: config.FIREBASE_API_KEY,
      })

      if (!result) {
        openSnackBar(t('geocodeError'), 'error')

        return
      }

      setValue(
        'geocode',
        {
          formatted_address: result.formatted_address,
          geometry: result.geometry,
          place_id: result.place_id,
        },
        { shouldDirty: true },
      )
    }
  }

  const onDeleteClick = () => {
    setValue('address', '')
    setValue('geocode', null)
  }

  return (
    <div>
      <InputLabel label={t('branchAddress')} />
      <div className="flex">
        <Controller
          name="address"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              value={value}
              onChange={onChange}
              containerClassName="grow"
              className="rounded-r-none rounded-b-none"
            />
          )}
        />
        <ButtonWithBillingCheck
          onClick={onFindClick}
          className="rounded-l-none rounded-r-none"
          disabled={!watchedAddress}
        >
          {t('find')}
        </ButtonWithBillingCheck>
        <ButtonWithBillingCheck
          onClick={onDeleteClick}
          className="rounded-b-none rounded-l-none"
          type="danger"
          disabled={!watchedGeocode}
        >
          {t('delete')}
        </ButtonWithBillingCheck>
      </div>

      <div className="w-full h-94">
        {watchedGeocode ? (
          <iframe
            loading="lazy"
            className="rounded-b-md w-full h-full"
            referrerPolicy="no-referrer-when-downgrade"
            src={gMapApi.getIFrameURL(watchedGeocode.formatted_address, config.FIREBASE_API_KEY)}
          ></iframe>
        ) : (
          <div className="flex flex-centered border-2 border-gray-100 dark:border-gray-500 rounded-b-md w-full h-full">
            <MapLocationIcon size="8rem" className="text-gray-100 dark:text-gray-500" />
          </div>
        )}
      </div>
    </div>
  )
}
