import { createCurrentDate, DEFAULT_TIMEZONE, startOfDay } from '@expane/date'
import { labelClassName } from '@expane/ui'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { TimeInput } from 'ui/TimeInput'
import { ApplyButton } from 'widgets/Buttons'

export const SettAllDatesInput: FC<{ timezone: string | undefined }> = ({ timezone }) => {
  const { t } = useTranslation()

  const {
    multiBooking: { setTimeForAllBookings },
  } = store

  const [date, setDate] = useState(() => startOfDay(createCurrentDate(timezone)))
  const [dateHasChanged, setDateHasChanged] = useState(false)

  const handleSubmit = () => {
    setTimeForAllBookings(date)
    setDate(startOfDay(createCurrentDate(timezone)))
    setDateHasChanged(false)
  }
  return (
    <div className="flex mb-2 justify-end items-center">
      <p className={labelClassName}>{t('bookings.all')}</p>

      <TimeInput
        timezone={timezone ?? DEFAULT_TIMEZONE}
        date={date}
        onChange={date => {
          setDate(date)
          if (!dateHasChanged) setDateHasChanged(true)
        }}
        className="w-16 ml-2"
        portal
      />
      <ApplyButton
        disabled={!dateHasChanged}
        className="ml-2"
        onClick={handleSubmit}
        type="outline"
      />
    </div>
  )
}
