import { TreeMenuItem } from 'ui/TreeMenu'
import { ServerSupplierBriefForImportType, SupplierInsertInput } from '@expane/data'

export type SuppliersTreeMenuItem = TreeMenuItem & ServerSupplierBriefForImportType

export const transformItemForMutation = (
  items: SuppliersTreeMenuItem[],
  branchId: number,
): SupplierInsertInput[] => {
  const supplierInsertInput: SupplierInsertInput[] = []

  for (const item of items) {
    supplierInsertInput.push({
      name: item.name,
      phone: item.phone,
      email: item.email,
      webSite: item.webSite,
      note: item.note,
      branchId,
      address: {
        data: {
          postcode: item.address?.postcode,
          country: item.address?.country,
          city: item.address?.city,
          street: item.address?.street,
          house: item.address?.house,
          office: item.address?.office,
        },
      },
    })
  }

  return supplierInsertInput
}
