import { SpinnerSmall } from '@expane/ui'
import { FC, memo, useState } from 'react'

export type AvatarProps = {
  name: string
  type?: 'small' | 'big' | 'medium'
  url?: string
  className?: string
  isLoading?: boolean
  onClick?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reportErrorHandlerFunction?: (e: Error, level: 'error', extra?: Record<string, any>) => void
}
export const Avatar: FC<AvatarProps> = memo(
  ({
    url,
    name,
    type = 'medium',
    className,
    isLoading = false,
    onClick,
    reportErrorHandlerFunction,
  }) => {
    const [isError, setIsError] = useState(false)

    let styles = 'rounded-full ' + (className ?? '')

    if (onClick) styles += ' cursor-pointer'

    if (type === 'small') styles += ' w-6 h-6'
    if (type === 'medium') styles += ' w-8 h-8'
    if (type === 'big') styles += ' w-24 h-24'

    const defaultAvatarStyle = styles + ' bg-primary-500 flex-centered'

    return !isError && url && !isLoading ? (
      <img
        onClick={onClick}
        alt={name}
        className={styles}
        src={url}
        onError={() => {
          reportErrorHandlerFunction?.(new Error('Error loading image'), 'error', { url })
          setIsError(true)
        }}
      />
    ) : (
      <div onClick={onClick} className={defaultAvatarStyle}>
        {isLoading ? (
          <SpinnerSmall className="text-white" />
        ) : (
          <span className={`${type === 'big' ? bigText : smallText} text-white`}>{name[0]}</span>
        )}
      </div>
    )
  },
)

const bigText = 'text-4xl'
const smallText = 'text-lg'
