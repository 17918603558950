import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  ServerArchiveTypes,
  ServerLocationGroupInsertInput,
  ServerLocationGroupSetInput,
} from '../../generated/graphql-types'
import { gql } from 'graphql-request'
import { LOCATION_GROUPS_QUERY_KEY } from './queryKeys'
import { LOCATIONS_QUERY_KEY } from '../location'
import { CloudFunctionResult } from '../responses'

export function useCreateLocationGroup() {
  return useMutation(
    async (
      locationGroupInsertInput: ServerLocationGroupInsertInput &
        Required<Pick<ServerLocationGroupInsertInput, 'branchId' | 'name'>>,
    ): Promise<{ insertLocationGroup?: { id: number; name: string } }> => {
      return request(
        gql`
          mutation ($locationGroupInsertInput: locationGroup_insert_input!) {
            insertLocationGroup(object: $locationGroupInsertInput) {
              id
              name
            }
          }
        `,
        { locationGroupInsertInput },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([LOCATION_GROUPS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useUpdateLocationGroup() {
  return useMutation(
    async (dto: {
      id: number
      locationGroupSetInput: ServerLocationGroupSetInput
    }): Promise<{ updateLocationGroupById?: { id?: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $locationGroupSetInput: locationGroup_set_input!) {
            updateLocationGroupById(pk_columns: { id: $id }, _set: $locationGroupSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(LOCATIONS_QUERY_KEY),
        })
        queryClient.invalidateQueries([LOCATION_GROUPS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useArchiveLocationGroup() {
  return useMutation(
    async (dto: {
      locationGroupId: number
      archiveType: ServerArchiveTypes
    }): Promise<{ archiveLocationGroup: CloudFunctionResult }> => {
      return request(
        gql`
          mutation ($locationGroupId: Int!, $archiveType: ArchiveTypes!) {
            archiveLocationGroup(locationGroupId: $locationGroupId, archiveType: $archiveType) {
              message
              code
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: (_, { archiveType }) => {
        if (archiveType === ServerArchiveTypes.Check) return
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(LOCATION_GROUPS_QUERY_KEY),
        })
        queryClient.invalidateQueries([LOCATIONS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
