import { gql } from 'graphql-request'
import {
  ServerSupplierBriefForImportType,
  ServerSupplierBriefType,
  ServerSupplierMutualSettlementType,
  ServerSupplierType,
} from '../../generated/graphql-types'
import {
  supplierBriefFragment,
  supplierBriefFragmentForImport,
  supplierFragment,
  supplierMutualSettlementFragment,
} from './supplier.fragments'
import { request, useQuery, reportError, reportGqlError } from '../../api'
import { MOVEMENTS_QUERY_KEY } from '../movement/queryKeys'
import {
  ALL_SUPPLIERS_QUERY_KEY,
  SUPPLIERS_BRIEF_QUERY_KEY,
  SUPPLIERS_MUTUAL_SETTLEMENTS,
  SUPPLIERS_QUERY_KEY,
} from './queryKeys'
import { parseDatesInMovementGqlResponse } from '../movement/logic'
import { DEFAULT_TIMEZONE } from '@expane/date'

export function useFetchSuppliersBrief(branchId: number | undefined) {
  return useQuery(
    [SUPPLIERS_QUERY_KEY, SUPPLIERS_BRIEF_QUERY_KEY, { branchId }],
    async (): Promise<ServerSupplierBriefType[]> => {
      const result = await request(
        gql`
          ${supplierBriefFragment}
          query ($branchId: Int!) {
            suppliers(where: { branchId: { _eq: $branchId } }, order_by: { createdAt: desc }) {
              ...supplierBriefType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.suppliers)) {
        return result.suppliers
      } else {
        reportError(new Error('suppliers is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchSuppliersBrief',
      onError: reportGqlError,
      enabled: Boolean(branchId),
    },
  )
}

export function useFetchSupplierById(supplierId: number | undefined) {
  return useQuery(
    [SUPPLIERS_QUERY_KEY, { supplierId }],
    async (): Promise<ServerSupplierType | undefined> => {
      if (supplierId === undefined) return undefined
      const dto = { supplierId }
      const result = await request(
        gql`
          ${supplierFragment}
          query ($supplierId: Int!) {
            supplierById(id: $supplierId) {
              ...supplierType
            }
          }
        `,
        dto,
      )

      if (result?.supplierById) {
        return result.supplierById
      } else {
        reportError(new Error('supplierById does not exist'), 'error', { dto, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchSupplierById',
      onError: reportGqlError,
      enabled: Boolean(supplierId),
    },
  )
}

export function useFetchSuppliersMutualSettlements(
  end: Date,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery(
    [SUPPLIERS_QUERY_KEY, SUPPLIERS_MUTUAL_SETTLEMENTS, MOVEMENTS_QUERY_KEY, { end, branchId }],
    async (): Promise<ServerSupplierMutualSettlementType[]> => {
      const result = await request(
        gql`
          ${supplierMutualSettlementFragment}
          # variable $end used in supplierMutualSettlementFragment
          # noinspection GraphQLSchemaValidation
          query ($end: timestamptz!, $branchId: Int!) {
            suppliers(where: { branchId: { _eq: $branchId } }, order_by: { name: asc }) {
              ...supplierMutualSettlementType
            }
          }
        `,
        { end, branchId },
      )

      if (Array.isArray(result?.suppliers)) {
        return result.suppliers.map(supplier => ({
          ...supplier,
          movements: supplier.movements.map(movement =>
            parseDatesInMovementGqlResponse(movement, timezone ?? DEFAULT_TIMEZONE),
          ),
        }))
      } else {
        reportError(new Error('suppliers is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchSuppliersMutualSettlements',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchSuppliersBriefForImport(branchId: number | null) {
  return useQuery(
    [SUPPLIERS_QUERY_KEY, SUPPLIERS_BRIEF_QUERY_KEY, ALL_SUPPLIERS_QUERY_KEY, { branchId }],
    async (): Promise<ServerSupplierBriefForImportType[]> => {
      const result = await request(
        gql`
          ${supplierBriefFragmentForImport}
          query ($branchId: Int!) {
            suppliers(where: { branchId: { _eq: $branchId } }, order_by: { createdAt: desc }) {
              ...supplierBriefForImportType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.suppliers)) {
        return result.suppliers
      } else {
        reportError(new Error('suppliers is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchSuppliersBriefForImport',
      onError: reportGqlError,
      enabled: Boolean(branchId),
    },
  )
}
