import type { DayTiming, ExtendedEmployee, ServerLocationWithServiceIdsType } from '@expane/data'
import { useFetchExtendedServices, validateBooking } from '@expane/data'
import { getTimeFromDate } from '@expane/date'
import { generateMessageAfterValidation } from '@expane/logic/bookingChecks'
import { ConfirmationArgs } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { TAILWIND_TO_REM_RATIO } from 'logic/ui'
import { FC, useLayoutEffect, useRef, useState } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { ConfirmationDescription } from 'widgets/ConfirmationDescription'
import {
  BOOKING_CARD_DEFAULT_OFFSET_REM,
  BOOKING_CARD_HORIZONTAL_OFFSET_PERCENTS,
} from './BookingCard'
import {
  getDraggableBounds,
  getNewPositionDtoFromDrag,
  getPositionOfBookingCard,
  MutateBookingDto,
} from './BookingCard/logic'
import { getTopPositionForBooking } from './columns/logic'
import type { BookingUpdateDataWithPosition } from './logic'

type CommonProps = {
  bookingData: BookingUpdateDataWithPosition
  dayTiming: DayTiming
  firstCellTime: number
  cellHeight: number
  titleCellHeight: number
  shrinked: boolean
  employeeIsSelected: boolean
  columnIndex: number
  columnRefWidth: number
  showConfirmation: (dto: ConfirmationArgs) => void
}
type LocationProps = CommonProps & {
  calendarType: 'locations'
  locations: ServerLocationWithServiceIdsType[]
}
type EmployeeProps = CommonProps & {
  calendarType: 'employees'
  employees: ExtendedEmployee[]
}
type Props = LocationProps | EmployeeProps

export const UnitedBookingUpdateCard: FC<Props> = props => {
  const {
    bookingData,
    firstCellTime,
    cellHeight,
    titleCellHeight,
    dayTiming,
    shrinked,
    employeeIsSelected,
    columnIndex,
    calendarType,
    columnRefWidth,
    showConfirmation,
  } = props
  const {
    unitedBookingUpdate: {
      updateBookingData,
      clientId,
      attachBookingCardRef,
      removeBookingCardRef,
    },
  } = store
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!

  const { t } = useTranslation()
  const [openSnackbar] = useSnackbar()

  const [y, setY] = useState(0)
  const [onTop, setOnTop] = useState(false)
  let className =
    'absolute bg-orange-500 border border-orange-800 rounded-md pl-1.5 cursor-move overflow-hidden hover:z-10 shadow'
  if (onTop) className += ' z-20'

  const { data: services } = useFetchExtendedServices(branchId, 'all')
  const servicesInBooking = services
    ? bookingData.serviceIds.map(serviceId => {
        const foundService = services?.find(service => service.id === serviceId)
        if (!foundService) throw new Error('no service found')
        return foundService
      })
    : []
  const serviceLabel = servicesInBooking.map(service => service.name).join(', ')

  const nodeRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    attachBookingCardRef(bookingData.id, nodeRef.current)

    return () => {
      removeBookingCardRef(bookingData.id)
    }
  }, [attachBookingCardRef, bookingData.id, removeBookingCardRef])

  const topInCells = getTopPositionForBooking(getTimeFromDate(bookingData.startDate), firstCellTime)
  const topPositionInRem = (topInCells * cellHeight + titleCellHeight) / TAILWIND_TO_REM_RATIO

  const scale = cellHeight / 2
  const heightRem = scale * (Number(bookingData.duration) / 60) - BOOKING_CARD_DEFAULT_OFFSET_REM
  const bookingHeight = Math.max(heightRem, cellHeight / 7)

  const { leftOffsetInPercents, widthInPercents } = getPositionOfBookingCard(
    bookingData.positionInfo,
  )
  const bounds = getDraggableBounds(
    dayTiming,
    cellHeight,
    bookingData.startDate,
    Number(bookingData.duration),
  )

  const mutateBooking = async (dto: MutateBookingDto) => {
    const locationId = dto.newLocationId ?? bookingData.locationId

    const employeeId = dto.newEmployeeId ?? bookingData.employeeId

    const { type } = await validateBooking({
      branchId,
      clientId: clientId,
      clientIds: null,
      serviceIds: bookingData.serviceIds,
      serviceId: null,
      isGroupBooking: false,
      startDate: dto.newStartDate ?? bookingData.startDate,
      duration: Number(bookingData.duration),
      locationId,
      employeeId,
    })
    if (type !== undefined && type !== null) {
      const warning = generateMessageAfterValidation({ warningType: type, t })
      showConfirmation({
        title: t('editing'),
        description: (
          <ConfirmationDescription text={warning} question={t('booking.moveConfirmation')} />
        ),
        onConfirm: () => {
          updateBookingData(bookingData.id, dto)
          setY(0)
        },
        onDeny: () => {
          setY(0)
        },
      })
    } else {
      updateBookingData(bookingData.id, dto)
      setY(0)
    }
  }

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setOnTop(false)

    try {
      const dto = getNewPositionDtoFromDrag({
        data,
        employeeIsSelected,
        columnIndex,
        cellHeight,
        axisXEnabled: !shrinked,
        calendarType,
        locations: props.calendarType === 'locations' ? props.locations : [],
        employees: props.calendarType === 'employees' ? props.employees : [],
        servicesInBooking,
        bookingStartDate: bookingData.startDate,
        columnWidthInPx: columnRefWidth,
      })
      mutateBooking(dto)
    } catch (error) {
      setY(0)
      openSnackbar(t((error as Error).message), 'error')
    }
  }

  return (
    <Draggable
      nodeRef={nodeRef}
      bounds={bounds}
      grid={[columnRefWidth, cellHeight * 4]}
      position={{ x: 0, y }}
      onStart={() => setOnTop(true)}
      onStop={handleDrag}
    >
      <div
        ref={nodeRef}
        className={className}
        style={{
          top: topPositionInRem + 'rem',
          left: leftOffsetInPercents + '%',
          width: widthInPercents - BOOKING_CARD_HORIZONTAL_OFFSET_PERCENTS + '%',
          height: bookingHeight + 'rem',
        }}
      >
        <p className="text-sm break-all text-white leading-4">{serviceLabel}</p>
      </div>
    </Draggable>
  )
}
