import { gql } from 'graphql-request'
import { employeeScheduleFragment } from '../schedule/schedule.fragments'
import { timeOffBriefFragment } from '../timeOff/timeOff.fragments'

export const employeeBriefFragment = gql`
  fragment employeeBriefType on employee {
    id
    firstName
    lastName
    photo
    groupId
  }
`

export const employeeBriefWithBranchFragment = gql`
  fragment employeeBriefWithBranchType on employee {
    id
    firstName
    lastName
    photo
    employeeSchedules {
      branchId
    }
  }
`

export const employeeFragment = gql`
  fragment employeeType on employee {
    id
    firstName
    middleName
    lastName
    phone
    photo
    groupId
    email
    birthDate
    gender
    hasTelegram
    hasViber
  }
`

export const employeeForMobileCalendarFragment = gql`
  ${employeeScheduleFragment}
  fragment employeeForMobileCalendarType on employee {
    id
    firstName
    lastName
    employeeSchedules {
      ...employeeScheduleType
    }
    serviceEmployees(
      where: { branchId: { _eq: $branchId }, service: { archived: { _is_null: true } } }
    ) {
      service {
        id
      }
    }
  }
`

export const employeeWithScheduleFragment = gql`
  ${employeeFragment}
  ${timeOffBriefFragment}
  ${employeeScheduleFragment}
  fragment employeeWithScheduleType on employee {
    ...employeeType
    employeeSchedules(where: { branchId: { _eq: $branchId } }) {
      ...employeeScheduleType
    }
    employeeGroup {
      storageId
    }
    timeoffs(where: { canceledDate: { _is_null: true } }) {
      ...timeOffBriefType
    }
  }
`

export const employeeExtendedFragment = gql`
  ${employeeWithScheduleFragment}
  fragment employeeExtendedType on employee {
    ...employeeWithScheduleType
    employeeGroup {
      id
      name
      storageId
    }
    archived
    birthDate
    serviceEmployees(
      where: { branchId: { _eq: $branchId }, service: { archived: { _is_null: true } } }
    ) {
      service {
        id
        name
        serviceLocations(where: { branchId: { _eq: $branchId } }) {
          locationId
        }
      }
    }
    timeoffs(where: { canceledDate: { _is_null: true } }) {
      id
      start
      end
      employeeId
      timeOffReasonId
      timeOffReason {
        name
      }
    }
    salarySettings_aggregate(where: { branchId: { _eq: $branchId } }) {
      aggregate {
        count
      }
    }
  }
`

export const employeeByIdWithServiceEmployeesFragment = gql`
  ${employeeBriefFragment}
  fragment employeeByIdWithServiceEmployeesType on employee {
    ...employeeBriefType
    information
    serviceEmployees(where: { branchId: { _eq: $branchId } }) {
      serviceId
    }
  }
`

export const employeeByIdExtendedFragment = gql`
  ${employeeFragment}
  ${employeeScheduleFragment}
  ${timeOffBriefFragment}
  fragment employeeByIdExtendedType on employee {
    ...employeeType
    archived
    preferredPayment
    status
    information
    employeeSchedules(order_by: { startDate: desc }) {
      ...employeeScheduleType
    }
    serviceEmployees(
      where: { branchId: { _eq: $branchId }, service: { archived: { _is_null: true } } }
    ) {
      branchId
      service {
        id
        name
        groupId
      }
    }
    ownBusiness {
      id
    }
    timeoffs(where: { canceledDate: { _is_null: true } }) {
      ...timeOffBriefType
    }
    employeeGroup {
      name
      maxDiscount
      roleId
    }
    bookings(where: { branchId: { _eq: $branchId } }) {
      bookingServices {
        serviceId
      }
      serviceId
    }
    employeeSettings {
      preferredConnections
    }
  }
`

export const employeeReviewsFragment = gql`
  fragment employeeReviewsType on employee {
    reviewRating {
      averageRating
      quantity
    }
    reviews {
      id
      text
      rating
      clientFirstName
      clientLastName
    }
  }
`

export const anonymouslyEmployeeFragment = gql`
  ${employeeReviewsFragment}
  fragment anonymouslyEmployeeType on employee {
    id
    firstName
    lastName
    photo
    information
    serviceEmployees {
      serviceId
    }
    ...employeeReviewsType
  }
`
