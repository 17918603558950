import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
} from 'react-router-dom'
import { SeverityLevel } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { config } from 'config'
import { store } from 'store'
import { firebase } from './firebase'

export const initSentry = () => {
  if (config.LOCAL_RUN) return

  Sentry.init({
    dsn: 'https://0979b6f46d8142b792edbf87ca876dc6@o706144.ingest.sentry.io/5778269',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    environment: config.ENV,
    release: config.VERSION,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.75,
  })
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const reportError = (
  e: unknown,
  level: SeverityLevel = 'error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extra?: Record<string, any>,
) => {
  const businessId = store.me.businessId

  if (!config.LOCAL_RUN) {
    const uid = firebase.getCurrentUser()?.uid
    const user = uid ? { id: uid } : undefined
    Sentry.withScope(scope => {
      scope.setTags({ businessId })
      Sentry.captureException(e, { extra, level, user })
    })
  } else {
    console.error(e) // eslint-disable-line
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reportGqlError = (e: unknown, variables?: any) => {
  if (e instanceof Object && Object.hasOwn(e, 'response')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const textError = JSON.stringify(e.response, null, 2)
    return reportError(new Error(textError), 'error', {
      variables: JSON.stringify(variables, null, 2),
    })
  } else {
    reportError(new Error('Unknown GqlError'), 'warning', {
      unknownError: JSON.stringify(e, null, 2),
      variables: JSON.stringify(variables, null, 2),
    })
  }
}
