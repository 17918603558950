export const getLocationDefaultValueForMaxClientsOrEmployees = (
  isCreate: boolean,
  isDefaultLocationForBranch: boolean,
  value?: string,
) => {
  if (isCreate) return '1'

  if (isDefaultLocationForBranch) return ''

  return value
}
