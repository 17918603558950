import {
  useCreateEmployeesSalarySettings,
  useFetchAllExtendedServices,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchSalarySettings,
} from '@expane/data'
import { createCurrentDate, startOfDay } from '@expane/date'
import { RATE_OPTIONS } from '@expane/logic/salarySettings'
import { CloseButton, Dialog, EmptyPlaceholder, Modal, usePopupOpenState } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoListOutline } from 'react-icons/io5'
import { store } from 'store'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import { convertEmployeeGroupsToTreeItems } from 'ui/TreeMenu/logic.employee'
import { TreeSelect } from 'ui/TreeSelect'
import { SaveButton } from 'widgets/Buttons'
import { SalarySetting } from 'widgets/SalarySettingsDialogs/SalarySetting'
import { SalarySettingDialogPlaceholder } from 'widgets/SalarySettingsDialogs/SalarySettingDialogPlaceholder'
import {
  checkAreEmployeesFromGroupsFirstSalarySetting,
  SalarySettingFormValues,
  transformEmployeesToSalarySettingServicesForForm,
  transformGroupSalarySettingsDataToServer,
} from './logic'

interface EmployeeGroupSalarySettingDialogProps {
  employeeGroupId?: number
  closeDialog: () => void
}

const EmployeeGroupSalarySettingDialog: FC<EmployeeGroupSalarySettingDialogProps> = observer(
  props => {
    const branchId = store.branch.branchId

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { isLoading: isLoadingEmployeeGroups, isError: isErrorEmployeeGroups } =
      useFetchEmployeesGroups(timezone, branchId)
    const { isLoading: isLoadingServices, isError: isErrorServices } = useFetchAllExtendedServices(
      branchId,
      'all',
    )

    const isLoading = isLoadingEmployeeGroups || isLoadingServices || !timezone
    const isNoData = isErrorEmployeeGroups || isErrorServices

    useErrorOpeningDialog(!isLoading && isNoData, props.closeDialog)
    if (!isLoading && isNoData) return null

    return (
      <Modal close={props.closeDialog}>
        <Dialog>
          {isLoading ? (
            <SalarySettingDialogPlaceholder
              closeDialog={props.closeDialog}
              isCreate={true}
              type={props.employeeGroupId ? 'group' : 'selectedGroup'}
            />
          ) : (
            <EmployeeGroupSalarySettingDialogLogic {...props} timezone={timezone} />
          )}
        </Dialog>
      </Modal>
    )
  },
)

const EmployeeGroupSalarySettingDialogLogic: FC<
  EmployeeGroupSalarySettingDialogProps & { timezone: string }
> = observer(({ employeeGroupId, closeDialog, timezone }) => {
  const branchId = store.branch.branchId
  const { data: employeeGroups } = useFetchEmployeesGroups(timezone, branchId)
  const { data: services } = useFetchAllExtendedServices(branchId, 'all')
  const { data: salarySettings } = useFetchSalarySettings(timezone, branchId)

  const { mutateAsync: createEmployeesSalariesSettings } = useCreateEmployeesSalarySettings()

  const employeeTreeMenuItems = employeeGroups
    ? convertEmployeeGroupsToTreeItems(employeeGroups)
    : []

  const defaultEmployeeGroup =
    employeeGroupId && employeeGroups
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        convertEmployeeGroupsToTreeItems([employeeGroups.find(gr => gr.id === employeeGroupId)!])
      : undefined

  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const salarySettingServices =
    defaultEmployeeGroup && services
      ? transformEmployeesToSalarySettingServicesForForm(services, defaultEmployeeGroup)
      : []

  const { control, setValue, formState, handleSubmit, resetField, clearErrors } =
    useForm<SalarySettingFormValues>({
      defaultValues: {
        employees: defaultEmployeeGroup,
        start: startOfDay(createCurrentDate(timezone)),
        end: undefined,
        rateType: RATE_OPTIONS[0],
        rateValue: '0',
        referralPercentage: '0',
        salarySettingServices,
      },
    })

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

  const watchedEmployees = useWatch({ control, name: 'employees' })

  const submitHandler: SubmitHandler<SalarySettingFormValues> = async data => {
    if (!branchId) {
      openSnackbar(t('submitError'), 'error')
      closeDialog()

      return
    }

    const transformedData = transformGroupSalarySettingsDataToServer({
      salarySettings,
      data,
      employeeGroups,
      branchId,
      timezone,
    })

    const result = await createEmployeesSalariesSettings(transformedData)

    if (result.insertMultipleSalarySetting.affectedRows) {
      openSnackbar(t('salary.setUpSuccessfully'), 'success')
    } else openSnackbar(t('submitError'), 'error')

    closeDialog()
  }

  const extractedWatchedEmployees = extractItemsFromFolders(watchedEmployees ?? [])

  const employeeIdWhenOneEmployeeSelected = getIdWhenOneEmployeeSelect(watchedEmployees)
  const type = employeeIdWhenOneEmployeeSelected ? 'single' : 'group'

  const areEmployeesFirstSalarySetting = checkAreEmployeesFromGroupsFirstSalarySetting(
    employeeGroups,
    extractedWatchedEmployees.map(({ id }) => id),
  )

  return (
    <>
      <Dialog.Title>{t('salarySetting.name')}</Dialog.Title>
      <Dialog.Body className="w-288 h-138">
        <Controller
          control={control}
          name="employees"
          render={({ field: { onChange, value } }) => (
            <TreeSelect
              type="MultiPickMode"
              items={employeeTreeMenuItems}
              onSelected={value => {
                onChange(value)
                setValue(
                  'salarySettingServices',
                  transformEmployeesToSalarySettingServicesForForm(services, value),
                )
              }}
              selected={value as TreeMenuItem[]}
              placeholder={t('placeholders.defaultSelect')}
              label={t('employees.name')}
              className="w-1/2 mb-2"
            />
          )}
        />
        {watchedEmployees && watchedEmployees?.length > 0 ? (
          <SalarySetting
            timezone={timezone}
            services={services ?? []}
            control={control}
            setValue={setValue}
            isCreate={true}
            resetField={resetField}
            clearErrors={clearErrors}
            employeeId={employeeIdWhenOneEmployeeSelected}
            type={type}
            areEmployeesFirstSalarySetting={areEmployeesFirstSalarySetting}
          />
        ) : (
          <EmptyPlaceholder Icon={IoListOutline} text={t('selectEmployeeOrGroupFirst')} />
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton disabled={!formState.isDirty} onClick={handleSubmit(submitHandler)} />
        <CloseButton onClick={closePopups} />
      </Dialog.Footer>
      {confirmPopup}
    </>
  )
})

export const useOpenGroupSalarySettingDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogEmployeeGroupId = useRef<number | undefined>()

  const openGroupSalarySettingDialog = (employeeGroupId?: number) => {
    dialogEmployeeGroupId.current = employeeGroupId
    openPopup()
  }

  const groupSalarySettingDialog = isOpen ? (
    <EmployeeGroupSalarySettingDialog
      employeeGroupId={dialogEmployeeGroupId.current}
      closeDialog={closePopup}
    />
  ) : null

  return { openGroupSalarySettingDialog, groupSalarySettingDialog }
}

const getIdWhenOneEmployeeSelect = (menuItem: TreeMenuItem[] | undefined): number | undefined => {
  if (menuItem?.length !== 1) return undefined

  if (menuItem[0].isFolder && menuItem[0].nodes?.length === 1) {
    return menuItem[0].nodes[0].id
  }

  if (!menuItem[0].isFolder) return menuItem[0].id
}
