import { gql } from 'graphql-request'
import { request, reportGqlError, useMutation, queryClient } from '../../api'
import {
  ServerAccountEmployeeGroupInsertInput,
  ServerAccountEmployeeGroupMutationResponse,
  ServerAccountInsertInput,
  ServerAccountSetInput,
} from '../../generated/graphql-types'
import { ACCOUNTS_QUERY_KEY } from './queryKeys'

export function useCreateAccount() {
  return useMutation(
    async (
      accountInsertInput: ServerAccountInsertInput &
        Required<Pick<ServerAccountInsertInput, 'cash' | 'name' | 'branchId'>>,
    ): Promise<{ insertAccount: { id: number } }> => {
      return request(
        gql`
          mutation ($accountInsertInput: account_insert_input!) {
            insertAccount(object: $accountInsertInput) {
              id
            }
          }
        `,
        {
          accountInsertInput: {
            ...accountInsertInput,
            accountEmployeeGroups: accountInsertInput.accountEmployeeGroups?.data
              ? accountInsertInput.accountEmployeeGroups
              : null,
          },
        },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([ACCOUNTS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useUpdateAccount() {
  return useMutation(
    async (dto: {
      id: number
      accountSetInput: ServerAccountSetInput
      accountEmployeeGroupInsertInputs?: ServerAccountEmployeeGroupInsertInput[]
    }): Promise<{
      updateAccountById: { id: number }
      deleteAccountEmployeeGroups?: ServerAccountEmployeeGroupMutationResponse
      insertAccountEmployeeGroups?: ServerAccountEmployeeGroupMutationResponse
    }> => {
      return request(
        gql`
          mutation (
            $id: Int!
            $accountSetInput: account_set_input!
            $accountEmployeeGroupInsertInputs: [accountEmployeeGroup_insert_input!]!
            $isUpdateAccountEmployeeGroup: Boolean!
          ) {
            updateAccountById(pk_columns: { id: $id }, _set: $accountSetInput) {
              id
            }
            deleteAccountEmployeeGroups(where: { accountId: { _eq: $id } })
              @include(if: $isUpdateAccountEmployeeGroup) {
              affected_rows
            }
            insertAccountEmployeeGroups(objects: $accountEmployeeGroupInsertInputs)
              @include(if: $isUpdateAccountEmployeeGroup) {
              affected_rows
            }
          }
        `,
        {
          id: dto.id,
          accountSetInput: {
            ...dto.accountSetInput,
            withdrawalToAccountId: dto.accountSetInput?.withdrawalToAccountId ?? null,
            exchangeAmount: dto.accountSetInput?.exchangeAmount ?? null,
          },
          accountEmployeeGroupInsertInputs: dto.accountEmployeeGroupInsertInputs ?? [],
          isUpdateAccountEmployeeGroup: Boolean(dto.accountEmployeeGroupInsertInputs),
        },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([ACCOUNTS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}
