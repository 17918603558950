import { safeWebLocalStorage } from '@expane/web-logic/safeWebLocalStorage'
import { sleep } from '@expane/logic/utils'
import { config } from 'config'
import i18next from 'i18next'
import { firebase } from 'services/firebase'
import { navigation } from 'services/navigation'
import { reportError } from 'services/sentry'
import { store } from 'store'
import { WAIT_FOR_AUTH_CLAIMS_SETTINGS } from '@expane/logic/auth/constants'

export function initAuthentication() {
  firebase.subscribeAuthStateChange(async user => {
    if (config.LOCAL_RUN && user) {
      console.log('Token:', await user.getIdToken()) // eslint-disable-line
      console.log('Claims:', (await user.getIdTokenResult())?.claims) // eslint-disable-line
    }

    if (user) {
      // We need to wait for auth claims to be added by the server if the user is just created
      await waitForAuthClaims()

      // If it is a new owner, we redirect them to create a business
      const claims = (await user.getIdTokenResult(true))?.claims
      if (claims) {
        if (claims['https://hasura.io/jwt/claims']?.['x-hasura-default-role'] === 'new_owner') {
          navigation.replace('/create-business/main')
        }
      }

      store.me.setMyBusinessAndEmployee(await user.getIdTokenResult())
    } else {
      store.me.resetMe()

      // If app is opened from email auth link
      if (firebase.isSignInWithEmailLink(window.location.href)) {
        try {
          await authenticateByEmailUrl(window.location.href)

          return
        } catch (e) {
          // Если ссылка для аутентификации устарела
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e as any).code === 'auth/invalid-action-code') {
            navigation.replace('/auth', {
              errorMessage: i18next.t('authentication.invalidActionCode'),
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } else if ((e as any).message === noEmailEntered) {
            navigation.replace('/auth', {
              errorMessage: i18next.t('authentication.sendSignInLinkError'),
            })
          } else {
            reportError(e as Error)
            navigation.replace('/')
          }
        }
      } else if (store.auth.isInitialAuthStateChangedCalled) {
        navigation.replace('/auth', { from: navigation.currentRoute })
      }
    }

    store.auth.setIsInitialAuthStateChangedCalled(true)
    store.auth.setIsWaitingForAuthAfterAuthPopup(false)
  })
}

export async function getAuthToken(forceRefresh?: boolean) {
  if (!store.auth.isAuthenticated) {
    if (store.auth.isInitialAuthStateChangedCalled)
      throw new Error('Trying to get auth token, while not being authenticated')

    return
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = await firebase.getCurrentUser()!.getIdToken(forceRefresh)

  return token
}

export async function sendAuthEmail(email: string, url: string) {
  await firebase.sendSignInLinkToEmail(email, url)

  safeWebLocalStorage.setItem('emailForSignIn', email)
}

const noEmailEntered = 'No email entered'

async function authenticateByEmailUrl(url: string) {
  const email = safeWebLocalStorage.getItem('emailForSignIn')

  if (email === null) return Promise.reject(new Error(noEmailEntered))

  try {
    const result = await firebase.checkEmailLinkComplete(url, email)
    if (result?.user) {
      safeWebLocalStorage.removeItem('emailForSignIn')

      const from = new URLSearchParams(window.location.search).get('from')
      if (from) navigation.replace(from)
    } else {
      return Promise.reject(new Error('Error in checkEmailLinkComplete'))
    }
  } catch (e) {
    return Promise.reject(e)
  }
}

const waitForAuthClaims = async () => {
  for (let i = 0; i < WAIT_FOR_AUTH_CLAIMS_SETTINGS.steps; i++) {
    const tokenData = await firebase.getCurrentUser()?.getIdTokenResult(true)

    if (tokenData?.claims?.['https://hasura.io/jwt/claims']) return

    await sleep(WAIT_FOR_AUTH_CLAIMS_SETTINGS.timeStep)
  }
}
