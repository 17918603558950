import {
  useFetchClientsBriefs,
  useFetchCurrentBranchTimezone,
  useFetchExtendedEmployees,
  useFetchExtendedServices,
  useFetchLocations,
} from '@expane/data'
import { Button, Paper, useShowConfirmationPopup } from '@expane/ui'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'
import { IoArrowUndoOutline, IoCloseOutline } from 'react-icons/io5'
import { store } from 'store'
import { BookingUpdateData } from 'store/UnitedBookingUpdate'

export const UnitedBookingBar: FC<{
  openMultiServicesCreateDialogFromCalendar: (clientId: number, data: BookingUpdateData[]) => void
}> = observer(({ openMultiServicesCreateDialogFromCalendar }) => {
  const nodeRef = useRef(null)
  const {
    unitedBookingUpdate: { clientId, bookingUpdateData, clearData, isUnitedBookingUpdateMode },
    branch: { branchId },
  } = store
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { t } = useTranslation()
  const formatDate = useDateFormatting()
  const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

  const { data: clients } = useFetchClientsBriefs(branchId, timezone)
  const clientById = clients?.find(client => client.id === clientId)
  const clientLabel = clientById
    ? clientById?.lastName
      ? `${clientById.lastName} ${clientById.firstName}`
      : clientById?.firstName
    : '-'

  const handleCancel = () => {
    showConfirmation({
      title: t('popupOnDirtyFormClose.title'),
      description: t('popupOnDirtyFormClose.description'),
      cancelButton: { text: t('popupOnDirtyFormClose.cancelButton') },
      onConfirm: clearData,
    })
  }
  const handleApply = () => {
    if (clientId) {
      openMultiServicesCreateDialogFromCalendar(clientId, bookingUpdateData)
      clearData()
    }
  }

  const dateLabel = isUnitedBookingUpdateMode
    ? formatDate('date', bookingUpdateData[0].startDate)
    : undefined

  return (
    <Draggable nodeRef={nodeRef} bounds="parent">
      <Paper ref={nodeRef} className="absolute bottom-4 right-4 w-64 p-2">
        <div className="flex justify-between mb-1">
          <div>
            <p className={labelClassName}>{t('client.name')}</p>
            <p className={textClassName}>{clientLabel}</p>
          </div>
          <div>
            <p className={labelClassName}>{t('dateTitle')}</p>
            <p className={textClassName}>{dateLabel}</p>
          </div>
        </div>
        <ul className="overflow-y-auto max-h-64">
          {bookingUpdateData.map(bookingData => (
            <BookingItem key={bookingData.id} data={bookingData} />
          ))}
        </ul>
        <div className="flex justify-between gap-2 mt-2">
          <Button type="danger" onClick={handleCancel} size="small" Icon={IoCloseOutline} twoLines>
            {t('cancelCreation')}
          </Button>

          <Button onClick={handleApply} size="small" Icon={IoArrowUndoOutline} twoLines>
            {t('backToCreation')}
          </Button>
        </div>
        {confirmationModal}
      </Paper>
    </Draggable>
  )
})

const BookingItem: FC<{ data: BookingUpdateData }> = observer(({ data }) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatting()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { employeeId, locationId, serviceIds } = data

  const { data: employees } = useFetchExtendedEmployees(timezone, branchId)

  const employee = employeeId ? employees?.find(employee => employee.id === employeeId) : undefined
  const employeeName = employee ? `${employee.lastName} ${employee.firstName}` : '-'

  const { data: locations } = useFetchLocations(branchId)
  const location = locations?.find(location => location.id === locationId)

  const { data: services } = useFetchExtendedServices(branchId, 'all')
  const foundServices = serviceIds.map(serviceId => {
    const service = services?.find(service => service.id === serviceId)
    if (!service) throw new Error('no service found')
    return service
  })

  return (
    <li
      onClick={() => {
        data.ref?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }}
      className="dark:hover:bg-gray-800 hover:bg-gray-100 border border-gray-200 rounded p-1 mb-1 cursor-pointer"
    >
      <div className="flex justify-between border-b border-gray-200">
        <p className={textClassName + ' pb-1'}>{formatDate('time', data.startDate)}</p>

        <p className={textClassName}>{location?.name}</p>
      </div>

      <p className={labelClassName}>{t('employee.name')}</p>

      <p className={textClassName}>{employeeName}</p>

      <p className={labelClassName}>{t('services')}</p>

      {foundServices.map(service => (
        <p key={service.id} className={textClassName}>
          {service.name}
        </p>
      ))}
    </li>
  )
})

const textClassName = 'text-xs text-main-color'
const labelClassName = 'text-label-color text-xs font-medium'
