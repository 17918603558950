import { CloseButton, CommonPlaceholderDialogProps, Dialog, Spinner } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoBlockPlaceholder } from 'widgets/MovementInfoDialog/InfoBlock'

export const MovementInfoDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('invoice')}</Dialog.Title>

      <Dialog.Body className="w-160">
        <div className="grid grid-cols-2">
          <InfoBlockPlaceholder title={t('number')} />
          <InfoBlockPlaceholder title={t('dateTitle')} />
          <InfoBlockPlaceholder title={t('type')} />
          <InfoBlockPlaceholder title={t('execution')} />
          <InfoBlockPlaceholder title={t('storage.name')} />
          <InfoBlockPlaceholder title={t('created')} />
        </div>

        <div className="my-3 h-64">
          <Spinner expandCentered />
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
