import {
  ServerTransactionBriefWithMovementsType,
  ServerTransactionType,
  TRANSACTION_TYPES,
} from '@expane/data'
import { checkIsTransactionPayment } from '../finances/filters'
import { roundValue } from '../utils/index'

export type ProductReportItemType = {
  id: number
  name: string
  quantity: number
  unit: number
  price: number
  creditPrice: number
  isForSale: boolean
  isConsumable: boolean
  isOptionalConsumable: boolean
}

export const convertTransactionsToProductReportItem = (
  transactions: Array<ServerTransactionType | ServerTransactionBriefWithMovementsType>,
): ProductReportItemType[] => {
  // нужно отфильтровать транзакции, так как приходят любые типы(в том числе приход товара и тд)
  const filteredTransactions = transactions.filter(
    transaction =>
      transaction.movement?.movementProducts.length && checkIsTransactionPayment(transaction),
  )

  const result: ProductReportItemType[] = []

  for (const filteredTransaction of filteredTransactions) {
    if (filteredTransaction.movement) {
      for (const movementProduct of filteredTransaction.movement.movementProducts) {
        const refundTransactions = transactions.filter(
          transaction =>
            transaction.type === TRANSACTION_TYPES.refund.id &&
            transaction.parentId === filteredTransaction.id,
        )

        let quantityThatWasRefunded = 0

        for (const refundTransaction of refundTransactions) {
          if (refundTransaction.movement)
            for (const refundTransactionMovement of refundTransaction.movement?.movementProducts) {
              if (refundTransactionMovement.productId === movementProduct.productId)
                quantityThatWasRefunded += refundTransactionMovement.quantity
            }
        }

        const quantity = movementProduct.quantity - quantityThatWasRefunded

        result.push({
          id: movementProduct.productId,
          name: movementProduct.product.name,
          quantity,
          unit: movementProduct.product.unit,
          // TODO: добавить подсчет
          price: movementProduct.price * quantity,
          creditPrice: 0,
          isForSale: movementProduct.product.forSale,
          // если товар не для продажи и при этом он вместе с услугой в транзакции
          // то это расходник встроенный в услугу и его цена уже включена в стоимость услуги
          isConsumable:
            !movementProduct.product.forSale &&
            checkIsTransactionPayment(filteredTransaction) &&
            filteredTransaction.typeVariation === TRANSACTION_TYPES.payment.variations.service.id,
          // если товар не для продажи и при этом он в отдельной транзакции
          // то это опциональный расходник и его цена НЕ включена в стоимость услуги
          isOptionalConsumable:
            !movementProduct.product.forSale &&
            checkIsTransactionPayment(filteredTransaction) &&
            filteredTransaction.typeVariation === TRANSACTION_TYPES.payment.variations.product.id,
        })
      }
    }
  }

  // возвращаем те товары у которых количество больше 0
  return result.filter(product => product.quantity > 0)
}

// cейчас округляем значения так может быть число 0,2000444
export const calcProductReportItemTotalQuantity = (productReportItems: ProductReportItemType[]) =>
  roundValue(
    productReportItems.reduce((acc, value) => acc + value.quantity, 0),
    'thousandths',
  )

export const getProductReportForSaleItems = (productReportItems: ProductReportItemType[]) =>
  productReportItems.filter(item => item.isForSale)

// если товар не для продажи то он расходних
export const getProductReportConsumableItems = (productReportItems: ProductReportItemType[]) =>
  productReportItems.filter(item => item.isOptionalConsumable)
