import { NOTIFICATION_STATUS, ServerClientNotificationType } from '@expane/data'
import { transformPersonName } from '@expane/logic/utils'
import { EmptyPlaceholder, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC, useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { IoChatboxEllipsesOutline, IoMailOpenOutline, IoPaperPlane } from 'react-icons/io5'
import { ViberIcon } from 'ui/Icons'
import { InformationPopup } from 'widgets/InformationPopup'

interface NotificationsHistoryProps {
  history: ServerClientNotificationType[]
}

export const NotificationsHistory: FC<NotificationsHistoryProps> = ({ history }) => {
  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()

  const columns = useMemo<ColumnDef<ServerClientNotificationType>[]>(
    () => [
      {
        accessorKey: 'sentAt',
        header: t('sent'),
        cell: data => dateFormatting('shortDateTime', data.getValue<Date>()),
        size: 100,
      },
      {
        id: 'employeeName',
        accessorFn: notification =>
          notification.author ? transformPersonName(notification.author) : t('mailing'),
        header: t('sender'),
        cell: data => data.getValue(),
        size: 200,
      },
      {
        accessorKey: 'message',
        header: t('message.name'),
        cell: data => data.getValue(),
        size: 560,
      },
      {
        id: 'status',
        cell: data => <MessengersStatuses notification={data.row.original} />,
        size: 100,
      },
    ],
    [dateFormatting, t],
  )
  return history.length > 0 ? (
    <Table columns={columns} data={history} />
  ) : (
    <EmptyPlaceholder Icon={IoChatboxEllipsesOutline} text={t('noNotifications')} />
  )
}

const MessengersStatuses: FC<{ notification: ServerClientNotificationType }> = ({
  notification,
}) => {
  const { t } = useTranslation()
  const { smsStatus, telegramStatus, viberStatus } = notification

  return (
    <div className="flex gap-1 items-center justify-center">
      {(smsStatus || smsStatus === NOTIFICATION_STATUS.pending) && (
        <InformationPopup
          CustomIcon={IoMailOpenOutline}
          size="1rem"
          containerClassName={getNotificationStatusIconColor(smsStatus)}
        >
          {getNotificationStatusText(smsStatus, t)}
        </InformationPopup>
      )}

      {(telegramStatus || telegramStatus === NOTIFICATION_STATUS.pending) && (
        <InformationPopup
          CustomIcon={IoPaperPlane}
          size="1rem"
          containerClassName={getNotificationStatusIconColor(telegramStatus)}
        >
          {getNotificationStatusText(telegramStatus, t)}
        </InformationPopup>
      )}
      {(viberStatus || viberStatus === NOTIFICATION_STATUS.pending) && (
        <InformationPopup
          CustomIcon={ViberIcon}
          size="1rem"
          containerClassName={getNotificationStatusIconColor(viberStatus)}
        >
          {getNotificationStatusText(viberStatus, t)}
        </InformationPopup>
      )}
    </div>
  )
}

const getNotificationStatusIconColor = (status: NOTIFICATION_STATUS) => {
  if (status === NOTIFICATION_STATUS.pending || status === NOTIFICATION_STATUS.waiting)
    return 'text-gray-500'

  if (status === NOTIFICATION_STATUS.error) return 'text-error-500'

  return 'text-primary-500'
}

const getNotificationStatusText = (status: NOTIFICATION_STATUS, t: TFunction) => {
  if (status === NOTIFICATION_STATUS.pending || status === NOTIFICATION_STATUS.waiting)
    return t('notificationStatus.waiting')

  if (status === NOTIFICATION_STATUS.sent) return t('notificationStatus.sent')

  if (status === NOTIFICATION_STATUS.read) return t('notificationStatus.read')

  // status === NOTIFICATION_STATUS.error
  return t('notificationStatus.error')
}
