import { FC, useMemo } from 'react'
import { CalendarColumn, CalendarTitleCell } from 'ui/Calendar'
import { WeeklyColumnData } from 'pages/BookingsPage/BookingsCalendar/logic'
import i18next from 'i18next'
import { formatDayMonth, isSameDay } from '@expane/date'
import { SchedulePageCalendarRows } from 'pages/SchedulesPage/ScheduleCalendar/Rows'
import { EmployeeGroupWithSchedule, WeeklySchedule } from '@expane/data'
import { EmployeesScheduleDynamicDatesForm } from 'pages/SchedulesPage/index'
import { Control } from 'react-hook-form'

interface SchedulePageCalendarColumnProps {
  column: WeeklyColumnData
  currentTime: Date
  employeeGroups: EmployeeGroupWithSchedule[]
  isLast: boolean
  control: Control<EmployeesScheduleDynamicDatesForm>
  branchSchedule: WeeklySchedule
}

export const SchedulePageCalendarColumn: FC<SchedulePageCalendarColumnProps> = ({
  column,
  currentTime,
  employeeGroups,
  isLast,
  control,
  branchSchedule,
}) => {
  const isCurrentDay = useMemo<boolean>(
    () => isSameDay(currentTime, column.date),
    [currentTime, column],
  )

  const locale = i18next.language
  const columnName = column.date.toLocaleDateString(locale, {
    weekday: 'short',
  })
  const columnWeekdayLabel = formatDayMonth(column.date)

  let columnStyle = 'grow basis-0 relative '
  let titleStyle = 'p-1 flex '
  if (isCurrentDay) {
    columnStyle += isLast
      ? 'border-r border-r-primary-100 dark:border-r-primary-100/30'
      : 'border-l-2 border-r-2 border-r-primary-600 dark:border-r-primary-400  border-l-primary-600 dark:border-l-primary-400'
    titleStyle += 'font-medium text-primary-700 dark:text-primary-300'
  }

  return (
    <CalendarColumn className={columnStyle}>
      <CalendarTitleCell rightBorder={!isLast}>
        <div className={titleStyle}>
          <p className="text-center mr-1">{columnName}</p>
          <p>{columnWeekdayLabel}</p>
        </div>
      </CalendarTitleCell>

      <SchedulePageCalendarRows
        employeeGroups={employeeGroups}
        date={column.date}
        isLast={isLast}
        control={control}
        branchSchedule={branchSchedule}
      />
    </CalendarColumn>
  )
}
