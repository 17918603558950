import { gql } from 'graphql-request'
import { reportError, reportGqlError, request, useQuery } from '../../api'
import { ARCHIVED_SCHEDULES_QUERY_KEY, SCHEDULES_QUERY_KEY } from './queryKeys'
import { scheduleFragment } from './schedule.fragments'
import { ScheduleDto, ScheduleDtoWithBranchIdAndEmployee } from './logic'

export function useFetchSchedules() {
  return useQuery<ScheduleDto[]>(
    [SCHEDULES_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${scheduleFragment}
          query {
            schedules(order_by: { createdAt: desc }, where: { archived: { _is_null: true } }) {
              ...scheduleType
            }
          }
        `,
      )

      if (Array.isArray(result?.schedules)) {
        return result.schedules
      } else {
        reportError(new Error('schedules is not an array'), 'warning', { result })
        return []
      }
    },
    { queryName: 'useFetchSchedules', onError: reportGqlError },
  )
}

export function useFetchArchivedSchedules() {
  return useQuery<ScheduleDto[]>(
    [SCHEDULES_QUERY_KEY, ARCHIVED_SCHEDULES_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${scheduleFragment}
          query {
            schedules(order_by: { createdAt: desc }, where: { archived: { _is_null: false } }) {
              ...scheduleType
            }
          }
        `,
      )

      if (Array.isArray(result?.schedules)) {
        return result.schedules
      } else {
        reportError(new Error('schedules is not an array'), 'warning', { result })
        return []
      }
    },
    { queryName: 'useFetchArchivedSchedules', onError: reportGqlError },
  )
}

export function useFetchScheduleById(id: number | undefined) {
  return useQuery<ScheduleDtoWithBranchIdAndEmployee>(
    [SCHEDULES_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${scheduleFragment}
          query ($id: Int!) {
            scheduleById(id: $id) {
              ...scheduleType
              employeeSchedules {
                employeeId
                employee {
                  firstName
                  lastName
                }
              }
              branch {
                id
                name
              }
            }
          }
        `,
        { id },
      )

      if (result?.scheduleById) {
        return result.scheduleById
      } else {
        reportError(new Error('scheduleById does not exist'), 'warning', { id, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchScheduleById',
      enabled: Boolean(id),
      onError: reportGqlError,
    },
  )
}
