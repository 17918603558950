import { TreeMenuItem } from 'ui/TreeMenu'
import { IdHashMap } from '@expane/logic/service'
import { TFunction } from 'i18next'

export const SERVICE_COLORS_LIST: { bgColor: string; borderColor: string }[] = [
  { bgColor: 'pink-100', borderColor: 'pink-300' },
  { bgColor: 'pink-300', borderColor: 'pink-500' },
  { bgColor: 'red-300', borderColor: 'red-500' },
  { bgColor: 'yellow-100', borderColor: 'yellow-300' },
  { bgColor: 'yellow-300', borderColor: 'yellow-500' },
  { bgColor: 'blue-100', borderColor: 'blue-300' },
  { bgColor: 'purple-100', borderColor: 'purple-300' },
  { bgColor: 'blue-300', borderColor: 'blue-500' },
  { bgColor: 'purple-300', borderColor: 'purple-500' },
]

// we just have this string in code and it guarantees these classes will be present on production
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const COLOR_STYLES = `bg-pink-100 border-pink-100 ring-pink-100 border-pink-300 
  bg-yellow-100 border-yellow-100 ring-yellow-100 border-yellow-300
  bg-red-300 border-red-300 ring-red-300 border-red-500
  bg-pink-300 border-pink-300 ring-pink-300 border-pink-500
  bg-purple-300 border-purple-300 ring-purple-300 border-purple-500
  bg-blue-100 border-blue-100 ring-blue-100 border-blue-300
  bg-yellow-300 border-yellow-300 ring-yellow-300 border-yellow-500
  bg-blue-300 border-blue-300 ring-blue-300 border-blue-500
  bg-purple-100 border-purple-100 ring-purple-100 border-purple-300`

export const DURATIONS = [
  { id: 30, name: '30' },
  { id: 45, name: '45' },
  { id: 60, name: '60' },
  { id: 90, name: '90' },
  { id: 120, name: '120' },
]

export const DEFAULT_CONSUMABLE_QUANTITY = '1'

// Disable employee for unselect if it was used
export const provideDefaultSelectedForEmployeeItems = (
  items: TreeMenuItem[],
  employeeIdsHashMap: IdHashMap,
  t: TFunction,
): TreeMenuItem[] => {
  return items.map(item => {
    if (item.isFolder && item.nodes)
      return {
        ...item,
        nodes: provideDefaultSelectedForEmployeeItems(item.nodes, employeeIdsHashMap, t),
      }

    if (employeeIdsHashMap[item.id])
      return { ...item, disabled: true, warningMessage: t('service.employeeWasUsed') }

    return item
  })
}

// Disable location for unselect if it was used
export const provideDisabledForUsedLocationsByIds = (
  items: TreeMenuItem[],
  locationIdsHashMap: IdHashMap,
  t: TFunction,
) => {
  return items.map(item => {
    if (item.isFolder && item.nodes)
      return {
        ...item,
        nodes: provideDisabledForUsedLocationsByIds(item.nodes, locationIdsHashMap, t),
      }

    if (locationIdsHashMap[item.id])
      return { ...item, disabled: true, warningMessage: t('service.locationWasUsed') }

    return item
  })
}
