import { DeleteButtonInTable, Input, stopEnterPropagation, TableCell, TableRow } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller, UseFieldArrayRemove } from 'react-hook-form'
import { IoCheckmarkOutline } from 'react-icons/io5'
import { SimpleEntitiesDialogFormValues, SimpleEntityInForm } from './logic'

interface SimpleEntityRowProps {
  control: Control<SimpleEntitiesDialogFormValues>
  index: number
  remove: UseFieldArrayRemove
}

export const SimpleEntityRow: FC<SimpleEntityRowProps> = ({ control, index, remove }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onBlur = (value: SimpleEntityInForm, onChange: (...event: any[]) => void) => {
    onChange({ ...value, editable: false, updatable: true })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFocus = (value: SimpleEntityInForm, onChange: (...event: any[]) => void) => {
    onChange({ ...value, editable: true })
  }

  return (
    <Controller
      name={`items.${index}` as 'items.0'}
      rules={{ validate: value => Boolean(value.name.length) }}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <TableRow
          className="h-10"
          onClick={value.editable ? undefined : () => onFocus(value, onChange)}
        >
          <TableCell>
            {value.editable ? (
              <div className={`flex items-center gap-1 ${value.editable ? '' : 'hidden'}`}>
                <Input
                  autoFocus
                  onKeyDown={e => {
                    if (e.key === 'Enter') onBlur(value, onChange)
                  }}
                  containerClassName="grow"
                  height="small"
                  value={value.name}
                  onChange={e => {
                    onChange({ ...value, name: e.target.value })
                  }}
                  onBlur={() => {
                    if (value.name.trim()) onBlur(value, onChange)
                  }}
                  errorMessage={{ isShown: Boolean(error), text: '', reserveIndent: false }}
                />

                {value.id ? (
                  <>
                    <div className="px-2 flex-centered h-full">
                      <button
                        onClick={() => onBlur(value, onChange)}
                        className={`rounded-full text-gray-300 transition-color hover:text-primary-500 focus:ring-1 focus:text-primary-500 ring-primary-500 ${
                          Boolean(error) || !value.name.trim()
                            ? 'pointer-events-none'
                            : 'cursor-pointer'
                        }`}
                        onKeyDown={stopEnterPropagation}
                        disabled={Boolean(error) || !value.name.trim()}
                      >
                        <IoCheckmarkOutline size="1.4rem" />
                      </button>
                    </div>
                  </>
                ) : (
                  <DeleteButtonInTable onClick={() => remove(index)} />
                )}
              </div>
            ) : (
              <div className={`pl-3 pr-2 ${value.editable ? 'hidden' : ''}`}>{value.name}</div>
            )}
          </TableCell>
        </TableRow>
      )}
    />
  )
}
