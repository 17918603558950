import { FC } from 'react'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import {
  useFetchAllEmployeesForURLSBriefs,
  useFetchALlServicesForURLSBriefs,
  useFetchBranchesBrief,
  useFetchOnlineBookingURLById,
} from '@expane/data'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { OnlineBookingURLDialogLogic } from 'widgets/OnlineBookingURLDialog/OnlineBookingURLDialogLogic'

export const OnlineBookingURLDialog: FC<DialogProps> = ({
  id: onlineBookingUrlId,
  closeDialog,
  isCreate,
}) => {
  const { data: onlineBookingURLById, isLoading: isLoadingOnlineBookingURLById } =
    useFetchOnlineBookingURLById(onlineBookingUrlId)

  const { data: branches, isLoading: isLoadingBranches } = useFetchBranchesBrief()
  const { data: employees, isLoading: isLoadingEmployees } = useFetchAllEmployeesForURLSBriefs()
  const { data: services, isLoading: isLoadingServices } = useFetchALlServicesForURLSBriefs()

  const isLoading =
    (isLoadingOnlineBookingURLById && !isCreate) ||
    isLoadingEmployees ||
    isLoadingServices ||
    isLoadingBranches

  const isNoData = (!isCreate && !onlineBookingURLById) || !employees || !services || !branches

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

  if (isLoading) return <></>
  else if (isNoData) return null

  return (
    <OnlineBookingURLDialogLogic
      onlineBookingURLById={onlineBookingURLById}
      closeDialog={closeDialog}
      branches={branches}
      services={services}
      employees={employees}
    />
  )
}
