import { COLOR_THEMES, Theme } from '@expane/web-logic/theme'
import { flip, useFloating } from '@floating-ui/react-dom'
import { FC, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useHandleClickOutside, usePopupOpenState } from '../../hooks'
import { modalsHTMLElement } from '../../logic'
import { FadeInTranslateTop } from '../animations'
import { InputLabel } from '../InputLabel'
import { Paper } from '../Paper'

interface ThemePickerProps {
  className?: string
  value: Theme
  onChange: (theme: Theme) => void
  disabled?: boolean
  label?: string
}

export const ThemePicker: FC<ThemePickerProps> = ({
  className,
  value,
  onChange,
  disabled,
  label,
}) => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [popoverRef, setPopoverRef] = useState<HTMLDivElement | null>(null)

  const { strategy, x, y, floating, reference } = useFloating({
    placement: 'bottom-start',
    middleware: [flip()],
  })

  // We need direct control of element, so we store our own ref and sync it with useFloating
  useLayoutEffect(() => {
    reference(buttonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, buttonRef.current])
  useLayoutEffect(() => {
    floating(popoverRef)
  }, [floating, popoverRef])

  useHandleClickOutside([buttonRef.current, popoverRef], closePopup, isOpen)

  const inlineRingStyle = 'w-full h-full rounded-full border-2 border-current'

  const containerStyle = `relative max-w-min  ${disabled ? 'pointer-events-none' : ''} ${className}`

  return (
    <div className={containerStyle}>
      {label && <InputLabel label={label} />}
      <div className="h-9.5 flex-centered">
        <button
          ref={buttonRef}
          onClick={openPopup}
          className={`w-64 ${commonButtonStyle} ${mapStylesToGradient[value]}`}
        >
          <div className={inlineRingStyle} />
        </button>
      </div>
      {isOpen &&
        createPortal(
          <div
            ref={setPopoverRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <div onClick={closePopup} />

            <FadeInTranslateTop
              as={Paper}
              className="absolute py-1 px-1.5 left-0 mt-2 overflow-hidden"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="color-picker"
              onClick={closePopup}
            >
              <div className="flex flex-col gap-2 transition-all">
                {COLOR_THEMES.map(color => (
                  <button
                    className={`w-60 ${commonButtonStyle} ${mapStylesToGradient[color]}`}
                    onClick={() => {
                      if (color !== value) onChange(color)
                    }}
                    key={color}
                  >
                    <div className={inlineRingStyle} />
                  </button>
                ))}
              </div>
            </FadeInTranslateTop>
          </div>,
          modalsHTMLElement,
        )}
    </div>
  )
}

const commonButtonStyle =
  'h-8 p-1 flex-centered text-transparent hover:text-white hover:dark:text-gray-600 rounded-2xl bg-gradient-to-r'

const mapStylesToGradient: Record<Theme, string> = {
  primary: 'from-expane-50 to-expane-600',
  indigo: 'from-indigo-50 to-indigo-600',
  sky: 'from-sky-50 to-sky-600',
  pink: 'from-pink-50 to-pink-600',
  stone: 'from-stone-50 to-stone-600',
}
