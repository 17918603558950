import { Button, PlaceholderInput, PlaceholderTextarea } from '@expane/ui'
import { NotificationSettingsBlock } from 'pages/SettingsPage/NotificationsSettings/index'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChatboxEllipses, IoQrCodeOutline } from 'react-icons/io5'
import { URLContainer } from 'ui/URLContainer'
import { SaveButton } from 'widgets/Buttons'
import { Checkbox } from '@expane/ui'

export const NotificationsSettingsPlaceholder: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="w-1/2">
      <NotificationSettingsBlock
        title={t('integration.telegram')}
        icon={
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 496 512"
            height="1.6em"
            width="1.6em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"></path>
          </svg>
        }
      >
        <Checkbox containerClassName="mb-4" label={t('useDefaultBot')} checked={true} disabled />

        <div className="flex items-center gap-1 text-secondary-color">
          <p>{t('link')}: </p>
          <URLContainer url="https://t.me/expane_bot" />
          <Button className="ml-auto" type="outline" disabled Icon={IoQrCodeOutline}>
            {t('QRcode')}
          </Button>
        </div>
      </NotificationSettingsBlock>

      <NotificationSettingsBlock
        title={t('integration.turboSms')}
        icon={
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1.6em"
            width="1.6em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7 1.3 3 4.1 4.8 7.3 4.8 66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32zM128.2 304H116c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6 0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1 0-21.3 19-38.6 42.4-38.6H156c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6 0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1.1 21.3-19 38.6-42.4 38.6zm191.8-8c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-68.2l-24.8 55.8c-2.9 5.9-11.4 5.9-14.3 0L224 227.8V296c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8V192c0-8.8 7.2-16 16-16h16c6.1 0 11.6 3.4 14.3 8.8l17.7 35.4 17.7-35.4c2.7-5.4 8.3-8.8 14.3-8.8h16c8.8 0 16 7.2 16 16v104zm48.3 8H356c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6 0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1 0-21.3 19-38.6 42.4-38.6H396c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6 0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1.1 21.3-18.9 38.6-42.3 38.6z"></path>
          </svg>
        }
      >
        <PlaceholderInput label={t('key')} />
      </NotificationSettingsBlock>

      <NotificationSettingsBlock
        title={t('messageTemplate')}
        icon={<IoChatboxEllipses size="1.6rem" />}
      >
        <PlaceholderTextarea className="mt-2" label={' '} />
      </NotificationSettingsBlock>

      <SaveButton className="my-2" disabled />
    </div>
  )
}
