import { TreeMenuItem } from 'ui/TreeMenu'
import {
  ServerLocationWithServiceIdsType,
  ServerLocationGroupInsertInput,
  ServerLocationGroupType,
  ServerLocationInsertInput,
} from '@expane/data'

type LocationTreeMenuItem = TreeMenuItem &
  ServerLocationWithServiceIdsType & {
    isFolder: false
  }

type LocationGroupMenuItem = TreeMenuItem &
  ServerLocationGroupType & {
    isFolder: true
  }

export type LocationUnionTreeMenuItem = LocationTreeMenuItem | LocationGroupMenuItem

export const transformItemForMutation = (
  items: LocationUnionTreeMenuItem[],
  branchId: number | undefined,
): {
  locationGroupInsertInputs: ServerLocationGroupInsertInput[]
  locationInsertInputs: ServerLocationInsertInput[]
} => {
  const locationInsertInputs: ServerLocationInsertInput[] = []
  const locationGroupInsertInputs: ServerLocationGroupInsertInput[] = []

  for (const item of items) {
    if (item.isFolder) {
      const locationsInGroup: ServerLocationInsertInput[] = []
      // nodes can be only LocationTreeMenuItem[]
      for (const node of (item.nodes as LocationTreeMenuItem[]) ?? []) {
        locationsInGroup.push({
          name: node.name,
          maxClients: node.maxClients,
          maxEmployees: node.maxEmployees,
          description: node.description,
          branchId,
        })
      }
      locationGroupInsertInputs.push({
        name: item.name,
        description: item.description,
        branchId,
        locations: { data: locationsInGroup },
      })
    } else {
      locationInsertInputs.push({
        name: item.name,
        maxClients: item.maxClients,
        maxEmployees: item.maxEmployees,
        description: item.description,
        branchId,
      })
    }
  }

  return { locationInsertInputs, locationGroupInsertInputs }
}
