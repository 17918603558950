import {
  CloudFunctionResponseCodes,
  ServerArchiveTypes,
  ServerLocationWithServiceIdsType,
  useArchiveLocation,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { Input, OpenButton, Textarea, useShowConfirmationPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { PageDataType } from 'pages/CreateBusinessPage/logic'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ArchiveButton, RestoreButton } from 'widgets/Buttons'

interface LocationQuickInfoProps {
  editLocation: (id: number) => void
  location: ServerLocationWithServiceIdsType | undefined
  type?: PageDataType
}
export const LocationQuickInfo: FC<LocationQuickInfoProps> = ({
  location,
  editLocation,
  type = 'page',
}) => {
  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()

  const { mutateAsync: archiveLocation, isLoading } = useArchiveLocation()

  const [openSnackbar] = useSnackbar()

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  if (!location) return null

  const handleOnArchive = async () => {
    try {
      const checkingResult = await archiveLocation({
        locationId: location.id,
        archiveType: ServerArchiveTypes.Check,
      })

      const code = checkingResult.archiveLocation.code

      if (
        code === CloudFunctionResponseCodes.insufficientPermissions &&
        Boolean(location.defaultForBranch?.id)
      ) {
        openSnackbar(t('locationArchive.defaultBranch'), 'error')
        return
      }

      if (code === CloudFunctionResponseCodes.insufficientPermissions) {
        openSnackbar(t('locationArchive.noPermission'), 'error')
        return
      }

      if (code === CloudFunctionResponseCodes.cannotArchiveWithFutureBookings) {
        openSnackbar(t('locationArchive.futureBookings', { name: location.name }), 'error')
        return
      }

      if (code === CloudFunctionResponseCodes.successful) {
        const result = await archiveLocation({
          locationId: location.id,
          archiveType: ServerArchiveTypes.Archive,
        })

        if (result.archiveLocation.code === CloudFunctionResponseCodes.successful)
          openSnackbar(t('locationArchive.archivingSuccessful', { name: location.name }), 'success')
        else openSnackbar(t('submitError'), 'error')

        return
      }

      // Всі інші коди то помилки
      openSnackbar(t('submitError'), 'error')
    } catch (e) {
      openSnackbar(t('submitError'), 'error')
    }
  }

  const handleOnRestore = async () => {
    try {
      const checkingResult = await archiveLocation({
        locationId: location.id,
        archiveType: ServerArchiveTypes.Check,
      })

      const code = checkingResult.archiveLocation.code

      if (code === CloudFunctionResponseCodes.insufficientPermissions) {
        openSnackbar(t('locationArchive.noPermission'), 'error')
        return
      }

      const onSuccess = async () => {
        try {
          const result = await archiveLocation({
            locationId: location.id,
            archiveType: ServerArchiveTypes.Restore,
          })

          if (result.archiveLocation.code === CloudFunctionResponseCodes.successful)
            openSnackbar(
              t('locationArchive.restorationSuccessful', { name: location.name }),
              'success',
            )
          else openSnackbar(t('submitError'), 'error')
        } catch {
          openSnackbar(t('submitError'), 'error')
        }
      }

      if (code === CloudFunctionResponseCodes.inArchivedGroup) {
        showConfirmation({
          title: t('restore.name'),
          description: t('locationArchive.inArchivedGroup', { name: location.name }),
          onConfirm: onSuccess,
        })
        return
      }

      if (code === CloudFunctionResponseCodes.successful) {
        await onSuccess()
        return
      }

      // Всі інші коди то помилки
      openSnackbar(t('submitError'), 'error')
    } catch (e) {
      openSnackbar(t('submitError'), 'error')
    }
  }

  const isDefaultLocationForBranch = Boolean(location?.defaultForBranch?.id)
  const isArchivingAllowed =
    Boolean(myPermissions?.includes(permissions.location.archive)) && !isDefaultLocationForBranch

  return (
    <div className="flex flex-col py-5 px-2">
      <Input
        label={t('title')}
        placeholder={t('placeholders.locationName')}
        value={location.name}
        readOnly
        disabled
      />

      <div className="flex justify-between mt-2">
        <Input
          type="number"
          label={t('maximumClients')}
          placeholder=""
          containerClassName="mr-2"
          value={isDefaultLocationForBranch ? '' : location.maxClients}
          readOnly
          disabled
        />

        <Input
          type="number"
          label={t('maximumEmployees')}
          containerClassName="ml-2"
          value={isDefaultLocationForBranch ? '' : location.maxEmployees}
          readOnly
          disabled
        />
      </div>

      <Textarea
        label={t('description')}
        placeholder=""
        value={
          isDefaultLocationForBranch ? t('defaultLocationDescription') : location.description ?? ''
        }
        containerClassName="mt-2"
        readOnly
        disabled
      />

      <div
        className={`flex ${
          location.archived || !isArchivingAllowed ? 'justify-end' : 'justify-between'
        } mt-5`}
      >
        {isArchivingAllowed &&
          type !== 'createBusiness' &&
          (!location.archived ? (
            <ArchiveButton
              className="mr-auto"
              onClick={handleOnArchive}
              spinner={isLoading}
              disabled={isLoading}
            />
          ) : (
            <RestoreButton onClick={handleOnRestore} spinner={isLoading} disabled={isLoading} />
          ))}

        {location.archived ? null : <OpenButton onClick={() => editLocation(location.id)} />}
      </div>
      {confirmationModal}
    </div>
  )
}
