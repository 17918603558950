import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { BOOKING_HISTORY_QUERY_KEY, BookingInsertInput, BOOKINGS_QUERY_KEY } from '../booking'
import { RECURRING_BOOKING_QUERY_KEY } from './queryKeys'

export const useCreateRecurringBookings = () =>
  useMutation(
    async (dto: {
      branchId: number
      bookingInsertInput: Array<BookingInsertInput>
    }): Promise<
      | { insertRecurringBooking: { id: number; bookings: Array<{ id: number }> | undefined } }
      | undefined
    > => {
      return request(
        gql`
          mutation ($branchId: Int!, $bookingInsertInput: [booking_insert_input!]!) {
            insertRecurringBooking(
              object: { branchId: $branchId, bookings: { data: $bookingInsertInput } }
            ) {
              bookings {
                id
              }
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKINGS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKING_HISTORY_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(RECURRING_BOOKING_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
