import { gql } from 'graphql-request'
import {
  ServerMutationRootPaySalaryArgs,
  ServerPaySalaryResult,
} from '../../generated/graphql-types'
import { queryClient, request, useMutation } from '../../api'
import { SALARY_ISSUES_QUERY_KEY } from '../salaryIssue/queryKeys'
import { BOOKINGS_QUERY_KEY } from '../booking/queryKeys'
import { TRANSACTIONS_QUERY_KEY } from '../transaction/queryKeys'
import { ACCOUNTS_QUERY_KEY } from '../account/queryKeys'

const invalidateOnPaySalary = async () => {
  await queryClient.invalidateQueries({
    predicate: query => query.queryKey.includes(SALARY_ISSUES_QUERY_KEY),
  })
  await queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])
  await queryClient.invalidateQueries([TRANSACTIONS_QUERY_KEY])
  await queryClient.invalidateQueries([ACCOUNTS_QUERY_KEY])
}

export function usePaySalary() {
  return useMutation(
    async (
      dto: ServerMutationRootPaySalaryArgs,
    ): Promise<{
      paySalary: ServerPaySalaryResult
    }> => {
      return await request(
        gql`
          mutation (
            $accountId: Int!
            $startPeriod: timestamptz!
            $endPeriod: timestamptz!
            $salaryRateIssues: [SalaryRateIssue!]!
            $employeeIds: [Int!]!
          ) {
            paySalary(
              accountId: $accountId
              startPeriod: $startPeriod
              endPeriod: $endPeriod
              salaryRateIssues: $salaryRateIssues
              employeeIds: $employeeIds
            ) {
              success
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: invalidateOnPaySalary,
    },
  )
}
