import { TreeMenuItem } from 'ui/TreeMenu'
import { createCurrentDate, startOfDay } from '@expane/date'
import { EmployeeScheduleInsertInput } from '@expane/data'

interface EmployeeScheduleFormData {
  employeeId: number
  employeeName: string
  startDate: Date
  isDynamic: boolean
  scheduleId: number
}

export interface ImportEmployeesSchedulesFormData {
  employeesSchedules: EmployeeScheduleFormData[]
}

export const getDefaultValuesForImportEmployeesSchedulesForm = (
  chosenEmployees: TreeMenuItem[],
  branchScheduleId: number,
  timezone: string | undefined,
): EmployeeScheduleFormData[] => {
  const initialDate = startOfDay(createCurrentDate(timezone))

  const schedules: EmployeeScheduleFormData[] = []

  for (const employee of chosenEmployees) {
    schedules.push({
      employeeName: employee.name,
      employeeId: employee.id,
      isDynamic: false,
      startDate: initialDate,
      scheduleId: branchScheduleId,
    })
  }

  return schedules
}

export const getDataForEmployeesSchedulesMutation = (
  data: ImportEmployeesSchedulesFormData,
  currentBranchId: number,
) => {
  const employeesSchedulesInsertInput: EmployeeScheduleInsertInput[] = []

  for (const employeesSchedule of data.employeesSchedules) {
    employeesSchedulesInsertInput.push({
      employeeId: employeesSchedule.employeeId,
      startDate: employeesSchedule.startDate,
      isDynamic: employeesSchedule.isDynamic,
      scheduleId: employeesSchedule.isDynamic ? undefined : employeesSchedule.scheduleId,
      branchId: currentBranchId,
    })
  }

  return employeesSchedulesInsertInput
}
