export interface SimpleEntity {
  id: number
  name: string
}

export interface SimpleEntityInForm {
  id?: number
  name: string
  editable: boolean
  updatable: boolean
}

export interface SimpleEntitiesDialogFormValues {
  items: SimpleEntityInForm[]
}

export type SimpleEntitiesMutateFunc = (insertInput: SimpleEntityInsertInput[]) => Promise<void>

export const generateDefaultSimpleEntitiesValues = (
  items: SimpleEntity[],
): SimpleEntityInForm[] => {
  const defaultItems: SimpleEntityInForm[] = []

  for (const item of items) {
    defaultItems.push({
      id: item.id,
      name: item.name,
      editable: false,
      updatable: false,
    })
  }

  return defaultItems
}

export interface SimpleEntityInsertInput {
  id?: number | null
  name: string
}

export const generateSimpleEntitiesDataForMutation = (
  data: SimpleEntitiesDialogFormValues,
): SimpleEntityInsertInput[] => {
  const dataForMutation: SimpleEntityInsertInput[] = []

  for (const item of data.items) {
    if (item.updatable || !item.id) {
      dataForMutation.push({
        id: item.id,
        name: item.name,
      })
    }
  }

  return dataForMutation
}
