import {
  BusinessSettingsType,
  TelegramIntegration,
  useFetchBusinessSettings,
  useUpdateBusinessSettingsById,
} from '@expane/data'
import { Button, Hint, Paper } from '@expane/ui'
import { SettingsWrapper } from 'pages/SettingsPage/index'
import { NotificationsSettingsPlaceholder } from 'pages/SettingsPage/NotificationsSettings/NotificationsSettingsPlaceholder'
import { TelegramIntegrationSettings } from 'pages/SettingsPage/NotificationsSettings/TelegramIntegrationSettings'
import { TurboSmsIntegrationSettings } from 'pages/SettingsPage/NotificationsSettings/TurboSmsIntegrationSettings'
import { ViberIntegrationSettings } from 'pages/SettingsPage/NotificationsSettings/ViberIntegrationSettings'
import { FC, PropsWithChildren, ReactNode } from 'react'
import { Control, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from '@expane/widgets'
import { SaveButton } from 'widgets/Buttons'
import { IoChatboxEllipses } from 'react-icons/io5'
import { useOpenMessageTemplateSettingsDialog } from 'widgets/MessageTemplateSettingsDialog'

interface NotificationsSettingsFormData {
  messageTemplate: string
  smsToken: string
  telegramIntegration: TelegramIntegration
}

export interface NotificationsBlockProps {
  control: Control<NotificationsSettingsFormData>
}

export const NotificationsSettings: FC = () => {
  const { data: businessSettings, isLoading } = useFetchBusinessSettings()

  if (!isLoading && !businessSettings) return null

  return (
    <SettingsWrapper>
      {isLoading ? (
        <NotificationsSettingsPlaceholder />
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <NotificationsSettingsLogic businessSettings={businessSettings!} />
      )}
    </SettingsWrapper>
  )
}

export const NotificationsSettingsLogic: FC<{ businessSettings: BusinessSettingsType }> = ({
  businessSettings,
}) => {
  const { t } = useTranslation()

  const { mutateAsync: updateBusinessSettings } = useUpdateBusinessSettingsById()

  const { openMessageTemplateSettingsDialog, messageTemplateSettingsDialog } =
    useOpenMessageTemplateSettingsDialog()

  const { control, handleSubmit, formState } = useForm<NotificationsSettingsFormData>({
    defaultValues: {
      smsToken: businessSettings.smsToken ?? '',
      messageTemplate: businessSettings.messageTemplate ?? '',
      telegramIntegration: businessSettings.telegramIntegration ?? {
        name: '',
        token: '',
        isUseDefault: true,
      },
    },
  })

  const [openSnackbar] = useSnackbar()

  const handleUpdateBusinessSettings: SubmitHandler<NotificationsSettingsFormData> = async data => {
    const result = await updateBusinessSettings({
      id: businessSettings.id,
      businessSettingsSetInput: {
        messageTemplate: data.messageTemplate,
        smsToken: data.smsToken,
        telegramIntegration: data.telegramIntegration,
      },
    })

    if (result?.updateBusinessSettingsById?.id) openSnackbar(t('changesSaved'), 'success', 3000)
    else openSnackbar(t('submitError'), 'error', 3000)
  }

  return (
    <div className="w-1/2">
      <TelegramIntegrationSettings control={control} businessSettings={businessSettings} />

      <ViberIntegrationSettings />

      <TurboSmsIntegrationSettings control={control} />

      <div className="mt-2">
        <Button type="outline" Icon={IoChatboxEllipses} onClick={openMessageTemplateSettingsDialog}>
          {t('messageTemplate.edit')}
        </Button>
      </div>

      <SaveButton
        className="mt-4"
        onClick={handleSubmit(handleUpdateBusinessSettings)}
        disabled={!formState.isDirty}
      />

      {messageTemplateSettingsDialog}
    </div>
  )
}

export const NotificationSettingsBlock: FC<
  PropsWithChildren<{
    title: string
    icon?: ReactNode
    hint?: string
    className?: string
  }>
> = ({ title, icon, hint, className, children }) => {
  return (
    <Paper className={'p-2 ' + className}>
      <div className={'flex justify-between items-center mb-2'}>
        <h2 className="flex items-center justify-between gap-x-1 text-gray-500 font-medium">
          {title}
          {icon}
        </h2>
        {hint && <Hint>{hint}</Hint>}
      </div>

      {children}
    </Paper>
  )
}
