import {
  ServerExtendedServiceType,
  useFetchEmployeesByServiceId,
  useFetchServiceGroups,
} from '@expane/data'
import { PlaceholderInput, SelectDropdown } from '@expane/ui'
import { transformPersonsForSelect } from 'logic/utils'
import { FC } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { TreeMenuItem } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { TreeSelect } from 'ui/TreeSelect'
import { WaitingListFormValues } from './waitingListDialogLogic'

interface Props {
  control: Control<WaitingListFormValues>
  services: ServerExtendedServiceType[] | undefined
  disabled: boolean
}

export const WatchingListServicesEmployeeInput: FC<Props> = ({ control, services, disabled }) => {
  const branchId = store.branch.branchId

  const { t } = useTranslation()

  const watchedService = useWatch({
    control,
    name: 'service',
  })

  const { data: serviceGroups } = useFetchServiceGroups(branchId)

  const { data: employees } = useFetchEmployeesByServiceId(watchedService?.id, branchId)

  const serviceItems = transformDataForTreeMenu(serviceGroups, services, {
    keepEmptyFolders: true,
  })

  return (
    <div className="flex justify-between">
      <Controller
        control={control}
        name="service"
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TreeSelect
            items={serviceItems}
            type="SinglePickMode"
            onSelected={onChange}
            selected={value as TreeMenuItem}
            placeholder={t('placeholders.defaultSelect')}
            className="pr-2 w-1/2"
            label={t('service.name')}
            errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
            disabled={disabled}
            required
          />
        )}
      />

      <Controller
        control={control}
        name="employeeId"
        render={({ field: { value, onChange } }) => (
          <SelectDropdown
            label={t('employee.name')}
            value={value}
            items={employees ? transformPersonsForSelect(employees) : []}
            placeholder={t('placeholders.defaultSelect')}
            noDataMessage={t('noSuchEmployee')}
            className="pl-2 w-1/2"
            onSelectChange={onChange}
            disabled={!employees?.length || disabled}
            hint={t('waitingList.desirableEmployee')}
          />
        )}
      />
    </div>
  )
}

export const WatchingListServicesEmployeeInputPlaceholder: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-between pb-4">
      <PlaceholderInput className="pr-2 w-1/2" label={t('service.name')} />

      <PlaceholderInput label={t('employee.name')} className="pl-2 w-1/2" />
    </div>
  )
}
