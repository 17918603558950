import { useFetchCurrentBranchTimezone, useFetchReviews } from '@expane/data'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReviewsList } from './List'
import { Page } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { ArchiveListNavigationButton } from 'widgets/NavigationButtons'
import { permissions } from '@expane/logic/permission'

export const ReviewsPage = observer(() => {
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: reviews, isLoading: isLoadingReviews } = useFetchReviews(branchId, timezone)
  const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()

  const isLoading = isLoadingMyPermissions || isLoadingReviews

  const isEditingAllowed = myPermissions?.includes(permissions.review.set)

  return (
    <Page>
      {isEditingAllowed && (
        <div className="flex mb-2">
          <ArchiveListNavigationButton className="ml-auto" />
        </div>
      )}

      <ReviewsList reviews={reviews} myPermissions={myPermissions} isLoading={isLoading} />
    </Page>
  )
})
