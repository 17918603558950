import { gql } from 'graphql-request'

export const supplierBriefFragment = gql`
  fragment supplierBriefType on supplier {
    id
    name
    phone
    email
    webSite
    note
    addressId
  }
`

export const supplierBriefFragmentForImport = gql`
  ${supplierBriefFragment}
  fragment supplierBriefForImportType on supplier {
    ...supplierBriefType
    address {
      postcode
      country
      city
      street
      house
      office
    }
  }
`

export const supplierBriefWithMovementsFragment = gql`
  ${supplierBriefFragment}
  fragment supplierBriefWithMovementsType on supplier {
    ...supplierBriefType
    movements {
      discount
      discountType
      transportCosts
      movementProducts {
        quantity
        price
        product {
          unit
        }
      }
      transactions {
        type
        amount
      }
    }
  }
`

export const addressFragment = gql`
  fragment addressType on address {
    id
    postcode
    country
    city
    street
    house
    office
  }
`

export const bankDetailsFragment = gql`
  fragment bankDetailsType on bankDetails {
    id
    name
    edrpou
    itn
    vatCertificate
    accountType
    accountNumber
    bic
    iban
  }
`

export const supplierContactFacesFragment = gql`
  fragment supplierContactFacesType on human {
    id
    firstName
    lastName
    middleName
    phone
    email
    note
  }
`

export const supplierFragment = gql`
  ${supplierBriefFragment}
  ${addressFragment}
  ${bankDetailsFragment}
  ${supplierContactFacesFragment}
  fragment supplierType on supplier {
    ...supplierBriefType
    address {
      ...addressType
    }
    bankDetails {
      ...bankDetailsType
    }
    supplierContactFaces {
      ...supplierContactFacesType
    }
  }
`

const movementInSupplierMutualSettlementFragment = gql`
  fragment movementInSupplierMutualSettlementType on movement {
    id
    createdAt
    number
    transportCosts
    discount
    discountType
    type
    description
    movementProducts {
      quantity
      price
      product {
        unit
      }
    }
    transactions {
      id
      type
      amount
      date
      comment
    }
  }
`

export const supplierMutualSettlementFragment = gql`
  ${supplierBriefFragment}
  ${movementInSupplierMutualSettlementFragment}
  fragment supplierMutualSettlementType on supplier {
    ...supplierBriefType
    movements(where: { createdAt: { _lt: $end } }) {
      ...movementInSupplierMutualSettlementType
    }
  }
`
