import { Button, Page } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const NoFoundPage: FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <Page className="flex-centered">
      <div className="text-center border-b-2 border-gray-200 dark:border-gray-600">
        <h2 className="mt-3 text-3xl text-gray-600 dark:text-gray-400">{t('errorPage.1')}</h2>
        <p className="my-3 text-gray-600 dark:text-gray-400">{t('errorPage.2')}</p>
      </div>

      <Button onClick={onClick} className="mt-5">
        {t('errorPage.3')}
      </Button>
    </Page>
  )
}
