import {
  ExtendedEmployee,
  ServerLocationWithServiceIdsType,
  useFetchBookingsOfDate,
  useFetchCurrentBranchTimezone,
  WeeklySchedule,
} from '@expane/data'
import {
  createCurrentDate,
  DEFAULT_TIMEZONE,
  getTimeFromDate,
  isDateBefore,
  isSameDay,
  isToday as getIsToday,
  setTimeForDateByFloat,
} from '@expane/date'
import { defineDayTiming } from '@expane/logic/calendar'
import { permissions } from '@expane/logic/permission'
import { SpinnerSmall, useShowConfirmationPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { ContextMenuItem } from 'logic/hooks/useContextMenu'
import { useDebounce } from 'logic/hooks/useDebounce'
import { TAILWIND_TO_REM_RATIO } from 'logic/ui'
import { observer } from 'mobx-react-lite'
import { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import {
  CalendarColumn,
  CalendarTitleCell,
  CELL_HEIGHT,
  SMALL_CELL_HEIGHT,
  TITLE_CELL_HEIGHT,
} from 'ui/Calendar'
import { useOpenBookingDialog } from 'widgets/BookingDialog'
import { useOpenBookingMultiServiceDialog } from 'widgets/BookingMultiServicesDialog'
import { CalendarCellWithPlanBillingCheck } from 'widgets/CalendarCellWithPlanBillingCheck'
import { useOpenGroupBookingDialog } from 'widgets/GroupBookingDialog'
import { BookingCard } from '../BookingCard'
import { CalendarTimeLine } from '../CalendarTimeComponents'
import { GhostBookingCard } from '../GhostBookingCard'
import {
  CardsType,
  getCellWarningMessage,
  getWarningsForCell,
  GetWarningsForCellArgs,
  markPositionForCards,
} from '../logic'
import { UnitedBookingUpdateCard } from '../UnitedBookingUpdateCard'
import {
  BOOKING_DIALOG_CONTEXT_MENU_ID,
  filterBookingsForLocationColumn,
  getCalendarColumnClassName,
  getCellClassName,
  getTopPositionForBooking,
  GROUP_BOOKING_DIALOG_CONTEXT_MENU_ID,
  MULTI_SERVICE_BOOKING_DIALOG_CONTEXT_MENU_ID,
} from './logic'

interface LocationColumnProps {
  location: ServerLocationWithServiceIdsType
  employeesFromFilter: ExtendedEmployee[]
  branchSchedule: WeeklySchedule | undefined
  hours: number[]
  date: Date
  last: boolean
  locations: ServerLocationWithServiceIdsType[]
  columnIndex: number
  boundaryElement: HTMLDivElement | undefined
  shrinked: boolean
  rightBorder?: boolean
  grouped?: boolean
  contextMenuItems: ContextMenuItem[]
}

export const LocationColumn = observer<LocationColumnProps>(
  ({
    hours,
    date,
    location,
    employeesFromFilter,
    branchSchedule,
    last,
    locations,
    columnIndex,
    shrinked,
    boundaryElement,
    rightBorder = false,
    grouped = false,
    contextMenuItems,
  }) => {
    const columnRef = useRef<HTMLDivElement>(null)
    const [columnWidth, setColumnWidth] = useState(0)
    useLayoutEffect(() => {
      const newColumnWidth = columnRef.current?.offsetWidth
      if (newColumnWidth && newColumnWidth !== columnWidth) setColumnWidth(newColumnWidth)
    }, [columnWidth])

    const { t } = useTranslation()

    const cellHeight = shrinked ? SMALL_CELL_HEIGHT : CELL_HEIGHT

    const {
      multiBooking: { firstBooking, pushBooking, additionalBookings, removeBooking },
      bookingSettings: { locationColumnWidth },
      unitedBookingUpdate: { isUnitedBookingUpdateMode, bookingUpdateData },
    } = store
    const bookingUpdateDataWithThisLocation = bookingUpdateData.filter(
      data => data.locationId === location.id && isSameDay(data.startDate, date),
    )

    const [isHover, setIsHover] = useState(false)
    const debouncedIsHover = useDebounce<boolean>(isHover, 75)

    const isMultiBookingMode = firstBooking !== null
    const isFirstBookingInThisLocation =
      isMultiBookingMode && firstBooking.locationId === location.id
    const firstBookingTopInCells = firstBooking
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getTopPositionForBooking(getTimeFromDate(firstBooking.startDate!), hours[0])
      : 0
    const firstBookingTopPositionInRem =
      (firstBookingTopInCells * cellHeight + TITLE_CELL_HEIGHT) / TAILWIND_TO_REM_RATIO
    const firstBookingHeightInRem = firstBooking
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (cellHeight / 2) * (firstBooking.duration! / 60)
      : 0
    const multiBookingsInThisColumn = additionalBookings.filter(
      booking => booking.locationId === location.id && isSameDay(booking.date, date),
    )

    const branchDayTiming = defineDayTiming(branchSchedule, date)

    const branchId = store.branch.branchId
    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { data: bookings, isFetching } = useFetchBookingsOfDate(date, timezone, branchId)

    const filteredBookings = filterBookingsForLocationColumn({
      bookings,
      locationId: location.id,
      employees: employeesFromFilter,
      dayTiming: branchDayTiming,
    })

    const markedBookings = markPositionForCards({
      bookings: filteredBookings ?? [],
      unitedBookingUpdateData: bookingUpdateDataWithThisLocation,
    })

    const { data: myPermissions } = useFetchMyPermissions()
    const isEditingAllowed =
      (myPermissions?.includes(permissions.booking.set) ||
        myPermissions?.includes(permissions.booking.setOwn)) ??
      false
    const canCreateInPast = myPermissions?.includes(permissions.booking.editPast)

    const { bookingDialog, openCreateBookingDialog, openEditBookingDialog } = useOpenBookingDialog()
    const { openEditGroupBookingDialog, groupBookingDialog, openCreateGroupBookingDialog } =
      useOpenGroupBookingDialog()
    const {
      openCreateBookingMultiServicesDialog,
      openEditBookingMultiServicesDialog,
      bookingMultiServicesDialog,
    } = useOpenBookingMultiServiceDialog()

    const [openSnackbar] = useSnackbar()
    const { showConfirmation, confirmationModal } = useShowConfirmationPopup()
    const isToday = getIsToday(date, timezone)

    const handleOpenDialog = (id: number, branchWarning: boolean, cellDate: Date): void => {
      if (isUnitedBookingUpdateMode) return

      if (branchWarning) {
        openSnackbar(t('cellWarning.branch'), 'error', 3000)
        return
      }

      if (!canCreateInPast && isDateBefore(cellDate, createCurrentDate(timezone))) {
        openSnackbar(t('bookingValidation.notAllowedCreatePast'), 'error')
        return
      }

      if (id === BOOKING_DIALOG_CONTEXT_MENU_ID) {
        if (isMultiBookingMode) {
          pushBooking({ date: cellDate, locationId: location.id })
        }
        if (!isMultiBookingMode) {
          openCreateBookingDialog({
            locationId: location.id,
            employeeId: undefined,
            startDate: cellDate,
          })
        }
      }

      if (id === GROUP_BOOKING_DIALOG_CONTEXT_MENU_ID) {
        openCreateGroupBookingDialog({
          startDate: cellDate,
          employeeId: undefined,
          locationId: location.id,
        })
      }

      if (id === MULTI_SERVICE_BOOKING_DIALOG_CONTEXT_MENU_ID) {
        openCreateBookingMultiServicesDialog({
          startDate: cellDate,
          employeeId: undefined,
          locationId: location.id,
        })
      }
    }

    return (
      <CalendarColumn
        ref={columnRef}
        className={getCalendarColumnClassName({
          shrinked,
          columnWidth: locationColumnWidth,
          isHover: debouncedIsHover,
          last,
        })}
        onMouseOver={() => {
          if (shrinked) setIsHover(true)
        }}
        onMouseOut={() => {
          if (shrinked) setIsHover(false)
        }}
      >
        <div>
          {hours.map(hour => {
            const cellDate = setTimeForDateByFloat(hour, date)
            const dto: GetWarningsForCellArgs = {
              locations: [location],
              employees: employeesFromFilter,
              cellDate,
              branchSchedule,
              bookings: bookings ?? [],
              isEditingAllowed,
            }
            const cellWarnings = getWarningsForCell(dto)
            const cellWarningMessage = getCellWarningMessage(cellWarnings, t)

            const cellClassName = getCellClassName(isEditingAllowed, cellWarningMessage)

            return (
              <CalendarCellWithPlanBillingCheck
                key={hour}
                className={cellClassName}
                lastColumn={last}
                disabled={!isEditingAllowed}
                onClick={() =>
                  handleOpenDialog(
                    BOOKING_DIALOG_CONTEXT_MENU_ID,
                    Boolean(cellWarnings.branch),
                    cellDate,
                  )
                }
                small={shrinked}
                contextMenuItems={contextMenuItems}
                onContextMenuItemClick={id =>
                  handleOpenDialog(id, Boolean(cellWarnings.branch), cellDate)
                }
              >
                {Number.isInteger(hour) && columnIndex % 2 === 1 && (
                  <div className="absolute left-1 top-0 text-calendar-time-color font-medium text-lg">
                    {hour}:00
                  </div>
                )}
                <span
                  className={`p-1 text-center invisible group-hover:visible${
                    isEditingAllowed ? '' : ' cursor-default'
                  }`}
                >
                  {cellWarningMessage}
                </span>
              </CalendarCellWithPlanBillingCheck>
            )
          })}
        </div>
        <div>
          {markedBookings?.map(booking => {
            if (booking.cardsType === CardsType.unitedBookingUpdate)
              return (
                <UnitedBookingUpdateCard
                  key={booking.cardsType + booking.id}
                  columnRefWidth={columnWidth}
                  shrinked={shrinked}
                  bookingData={booking}
                  dayTiming={branchDayTiming}
                  firstCellTime={hours[0]}
                  cellHeight={cellHeight}
                  titleCellHeight={TITLE_CELL_HEIGHT}
                  employeeIsSelected={false}
                  columnIndex={columnIndex}
                  calendarType="locations"
                  locations={locations}
                  showConfirmation={showConfirmation}
                />
              )

            return booking.cardsType === CardsType.booking ? (
              <BookingCard
                timezone={timezone ?? DEFAULT_TIMEZONE}
                columnRefWidth={columnWidth}
                key={booking.id}
                shrinked={shrinked}
                booking={booking}
                calendarType="locations"
                dayTiming={branchDayTiming}
                firstCellTime={hours[0]}
                cellHeight={cellHeight}
                titleCellHeight={TITLE_CELL_HEIGHT}
                columnIndex={columnIndex}
                locations={locations}
                employeeIsSelected={false}
                showConfirmation={showConfirmation}
                onClick={() => {
                  if (booking.unitedBooking?.id)
                    openEditBookingMultiServicesDialog(booking.unitedBooking.id)
                  else {
                    if (!booking.isGroupBooking) openEditBookingDialog(booking.id)
                    else openEditGroupBookingDialog(booking.id)
                  }
                }}
                boundaryElement={boundaryElement}
              />
            ) : null
          })}
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            isFirstBookingInThisLocation && isSameDay(firstBooking!.startDate!, date) && (
              <GhostBookingCard
                topPositionInRem={firstBookingTopPositionInRem}
                heightInRem={firstBookingHeightInRem}
                initial
              />
            )
          }
          {multiBookingsInThisColumn.map(booking => {
            const topInCells = getTopPositionForBooking(getTimeFromDate(booking.date), hours[0])
            const topPositionInRem =
              (topInCells * cellHeight + TITLE_CELL_HEIGHT) / TAILWIND_TO_REM_RATIO

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const heightInRem = (cellHeight / 2) * (firstBooking!.duration! / 60)
            return (
              <GhostBookingCard
                key={booking.date.getTime()}
                topPositionInRem={topPositionInRem}
                heightInRem={heightInRem}
                onClick={() => removeBooking(booking.specialId)}
              />
            )
          })}
        </div>
        {isToday && (
          <CalendarTimeLine
            // Ugly vertical desync with the time column
            className="mt-[1px]"
            cellHeight={cellHeight}
            calendarStartHour={hours[0]}
          />
        )}

        <CalendarTitleCell rightBorder={rightBorder} grouped={grouped}>
          <span className={'truncate max-w-full' + (shrinked ? ' text-xs' : '')}>
            {location.name}
          </span>
          {isFetching && <SpinnerSmall className="absolute right-2" />}
        </CalendarTitleCell>
        {bookingDialog}
        {groupBookingDialog}
        {confirmationModal}
        {bookingMultiServicesDialog}
      </CalendarColumn>
    )
  },
)
