import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { PermissionPageBlockProps, allSelectedPermissions } from '../logic'
import { PermissionBlock, PermissionBlockTitle, PermissionCheckbox } from '.'
import { PermissionPageRowProps } from '@expane/logic/permission/logic'

export const BookingBlock: FC<PermissionPageBlockProps> = ({
  control,
  checkRelation,
  checkRelationBulk,
  isItOwnerRole,
  setValue,
}) => {
  const { t } = useTranslation()

  const onSelectAll = () => {
    checkRelationBulk({
      permissions: [
        'permissions.booking.editPast',
        'permissions.booking.get',
        'permissions.booking.set',
        'permissions.booking.setOwn',
        'permissions.bookingStatus.set',
        'permissions.bookingStatus.unset',
        'permissions.location.get',
        'permissions.location.set',
        'permissions.waitingList.get',
        'permissions.waitingList.set',
      ],
      onConfirm: () => {
        setValue('permissions.booking', allSelectedPermissions.booking)
        setValue('permissions.bookingStatus', allSelectedPermissions.bookingStatus)
        setValue('permissions.location', allSelectedPermissions.location)
        setValue('permissions.waitingList', allSelectedPermissions.waitingList)
      },
    })
  }

  return (
    <>
      <PermissionBlockTitle onSelectAll={onSelectAll} title={t('bookingsTitle')} />

      <BookingPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <BookingStatusPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <LocationPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <WaitingListPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
    </>
  )
}

const BookingPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('bookings.title')}>
      <Controller
        name="permissions.booking.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.booking.get',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.booking.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.booking.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.booking.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.booking.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.booking.editPast"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.booking.editPast',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.booking.editPast')}
            hint={t('permissions.booking.editPastHint')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.booking.setOwn"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.booking.setOwn',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.booking.setOwn')}
            hint={t('permissions.booking.setOwnHint')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const BookingStatusPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('bookingStatus')}>
      <Controller
        name="permissions.bookingStatus.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.bookingStatus.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.bookingStatus.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.bookingStatus.unset"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.bookingStatus.unset',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.bookingStatus.unset')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const LocationPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('locations.name')}>
      <Controller
        name="permissions.location.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.location.get',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.location.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.location.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.location.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.location.set')}
            disabled={isItOwnerRole}
          />
        )}
      />

      <Controller
        name="permissions.location.archive"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.location.archive',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.location.archive')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const WaitingListPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('waitingList.name')}>
      <Controller
        name="permissions.waitingList.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.waitingList.get',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.waitingList.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.waitingList.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.waitingList.set',
                isSelect: checked,
                onConfirm: () => onChange(checked),
              })
            }}
            checked={value}
            label={t('permissions.waitingList.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}
