import { gql } from 'graphql-request'
import { ServerProductInsertInput, ServerProductSetInput } from '../../generated/graphql-types'
import { queryClient, request, useMutation, reportError, reportGqlError } from '../../api'
import {
  ARCHIVED_PRODUCT_GROUPS_QUERY_KEY,
  PRODUCT_GROUPS_QUERY_KEY,
} from '../productGroup/queryKeys'
import { ARCHIVED_PRODUCTS_QUERY_KEY, PRODUCTS_QUERY_KEY } from './queryKeys'
import { SERVICES_QUERY_KEY } from '../service/queryKeys'

export function useCreateProduct() {
  return useMutation(
    (
      productInsertInput: ServerProductInsertInput &
        Required<
          Pick<
            ServerProductInsertInput,
            'name' | 'price' | 'costPrice' | 'barcode' | 'vendorCode' | 'forSale' | 'branchId'
          >
        >,
    ): Promise<{ insertProduct?: { id?: number } }> => {
      return request(
        gql`
          mutation ($productInsertInput: product_insert_input!) {
            insertProduct(object: $productInsertInput) {
              id
            }
          }
        `,
        { productInsertInput },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries([PRODUCTS_QUERY_KEY])
        queryClient.invalidateQueries([SERVICES_QUERY_KEY])
        queryClient.invalidateQueries([PRODUCT_GROUPS_QUERY_KEY])
      },
    },
  )
}

export function useUpdateProduct() {
  return useMutation(
    (dto: {
      id: number
      productSetInput: ServerProductSetInput &
        Required<
          Pick<
            ServerProductSetInput,
            'name' | 'price' | 'costPrice' | 'barcode' | 'vendorCode' | 'forSale'
          >
        >
    }): Promise<{ updateProductById?: { id?: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $productSetInput: product_set_input!) {
            updateProductById(pk_columns: { id: $id }, _set: $productSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries([PRODUCTS_QUERY_KEY])
        queryClient.invalidateQueries([PRODUCT_GROUPS_QUERY_KEY])
      },
    },
  )
}

export function useArchiveProduct() {
  return useMutation(
    async (dto: { id: number; archived: boolean }) => {
      // eslint-disable-next-line camelcase
      const result = await request<{ updateProductArchives: { affected_rows: number } }>(
        gql`
          mutation ($id: Int!, $archived: timestamptz) {
            updateProductArchives(where: { id: { _eq: $id } }, _set: { archived: $archived }) {
              affected_rows
            }
          }
        `,
        { id: dto.id, archived: dto.archived ? new Date().toISOString() : null },
      )
      if (!result || result.updateProductArchives.affected_rows === 0) {
        reportError(
          new Error('The employee does not have permission to archive the product.'),
          'error',
          {
            productId: dto.id,
            archived: dto.archived,
          },
        )
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PRODUCTS_QUERY_KEY])
        queryClient.invalidateQueries([PRODUCT_GROUPS_QUERY_KEY])
        queryClient.invalidateQueries([ARCHIVED_PRODUCTS_QUERY_KEY])
        queryClient.invalidateQueries([ARCHIVED_PRODUCT_GROUPS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useResetProductProductGroup() {
  return useMutation(
    async (
      id: number,
    ): Promise<{
      updateProductById: { id: number }
    }> =>
      request(
        gql`
          mutation ($id: Int!) {
            updateProductById(pk_columns: { id: $id }, _set: { groupId: null }) {
              id
            }
          }
        `,
        { id },
      ),
    {
      onSuccess: () => queryClient.invalidateQueries([PRODUCTS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}
