import {
  EmployeeByIdExtended,
  ServerSalarySettingType,
  useFetchCurrentBranchTimezone,
  useFetchSalarySettingsByEmployeeId,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { getRateTypeName } from '@expane/logic/salarySettings'
import { Button, EmptyPlaceholder, Spinner, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useFetchMyPermissions } from 'gql/employee'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { CoinsIcon } from 'ui/Icons'
import { PremiumButton } from 'widgets/PremiumDialog/PremiumButton'
import { useOpenSalarySettingDialog } from 'widgets/SalarySettingsDialogs/EmployeeSalarySettingDialog'

interface SalarySettingsTabProps {
  employeeById: EmployeeByIdExtended
}

export const SalarySettingsTab: FC<SalarySettingsTabProps> = observer(({ employeeById }) => {
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: salarySettings, isLoading } = useFetchSalarySettingsByEmployeeId(
    employeeById.id,
    timezone,
    branchId,
  )
  const { data: myPermissions } = useFetchMyPermissions()

  const { t } = useTranslation()
  const formatDate = useDateFormatting()

  const { openEditSalarySettingDialog, openCreateSalarySettingDialog, salarySettingDialog } =
    useOpenSalarySettingDialog()

  const columns = useMemo<ColumnDef<ServerSalarySettingType>[]>(
    () => [
      {
        accessorKey: 'start',
        header: t('startDate'),
        cell: data => formatDate('shortDate', data.getValue<Date>()),
      },
      {
        accessorKey: 'end',
        header: t('endDate'),
        cell: data => {
          const endDate = data.getValue<Date | null>()
          return endDate ? formatDate('shortDate', endDate) : '-'
        },
      },
      {
        accessorKey: 'rateType',
        header: t('salary.rateType'),
        cell: data => getRateTypeName(data.getValue<number>(), t),
      },
      {
        accessorKey: 'rateValue',
        header: () => <span className="w-full text-right">{t('value')}</span>,
        cell: data => <div className="text-right">{data.getValue<number>()}</div>,
      },
    ],
    [formatDate, t],
  )

  const isEmployeeArchived = employeeById?.archived

  if (isLoading) return <Spinner expandCentered />

  const isThisOwner = Boolean(employeeById.ownBusiness)
  const canEditOwner = !isThisOwner || store.me.employeeId === employeeById.id
  const isEditingAllowed = myPermissions?.includes(permissions.salary.set) && canEditOwner

  return (
    <>
      {!isEmployeeArchived && isEditingAllowed && (
        <div className="flex gap-2 mb-2">
          <Button
            onClick={() => openCreateSalarySettingDialog({ employee: employeeById })}
            Icon={CoinsIcon}
          >
            {t('setUpSalary')}
          </Button>
          {Boolean(salarySettings?.length) && (
            <>
              <PremiumButton
                type="premium"
                myPermissions={myPermissions}
                employeeId={employeeById.id}
              />
              <PremiumButton
                type="penalty"
                myPermissions={myPermissions}
                employeeId={employeeById.id}
              />
            </>
          )}
        </div>
      )}

      <div className="h-[90%]">
        {salarySettings && salarySettings.length > 0 ? (
          <Table
            columns={columns}
            data={salarySettings}
            onRowClick={salarySetting =>
              openEditSalarySettingDialog({
                salarySettingId: salarySetting.id,
                employee: employeeById,
              })
            }
          />
        ) : (
          <EmptyPlaceholder text={t('noSalary')} Icon={CoinsIcon} />
        )}
      </div>
      {salarySettingDialog}
    </>
  )
})
