import { useEffect } from 'react'
import { useSnackbar } from '@expane/widgets'
import { CallNumbers } from 'widgets/IncomingCallDialog'
import { formatPhoneNumber } from 'logic/ui'
import { useTranslation } from 'react-i18next'
import { useFetchBusinessSettings } from '@expane/data'
import { store } from 'store'

// ANSWER - успешный звонок;
// TRANSFER - успешный звонок который был переведен;
// NOANSWER - неуспешный звонок по причине нет ответа;
// VM - голосовая почта без сообщения;
type Disposition = 'CANCEL' | 'ANSWER' | 'TRANSFER' | 'NOANSWER' | 'VM'

interface BinotelCallStart {
  assignedToEmployeeName: string
  callType: number
  companyID: number
  customerDescription: string
  customerName: string
  eventName: 'callStart'
  externalNumber: string
  generalCallID: string
  internalNumbers: string[]
  isNewCall: 0 | 1
  linkToCrm2Title: string
  linkToCrm2Url: string
  linkToCrmTitle: string
  linkToCrmUrl: string
  // номер на который звонили
  pbxNumber: string
  pbxNumberName: string
  // Unix time
  startTime: number
}

interface BinotelCallStop {
  eventName: 'callStop'
  billsec: number
  disposition: Disposition
  generalCallID: string
  stopTime: number
}

interface BinotelCallAnswer {
  eventName: 'callAnswer'
  answeredAt: number
  assignedToEmployeeName: string
  callType: number
  customerDescription: string
  customerName: string
  externalNumber: string
  generalCallID: string
  internalNumber: string
  linkToCrm2Title: string
  linkToCrm2Url: string
  linkToCrmTitle: string
  linkToCrmUrl: string
}

type BinotelCall = BinotelCallAnswer | BinotelCallStart | BinotelCallStop

interface UseTelephonySocketProps {
  onCall: (params: CallNumbers) => void
  onCallStop: () => void
}

export const useTelephonySocket: (props: UseTelephonySocketProps) => void = ({
  onCall,
  onCallStop,
}) => {
  const { t } = useTranslation()
  const { data: businessSetting } = useFetchBusinessSettings(
    store.me.isAuthorised && store.me.isEmployeeMode,
  )

  const [openSnackbar] = useSnackbar()

  useEffect(() => {
    const binotelIntegration = businessSetting?.binotelIntegration

    let webSocket: WebSocket | undefined
    if (binotelIntegration?.key && binotelIntegration?.secret) {
      webSocket = new WebSocket('wss://ws.binotel.com:9002')

      webSocket.onmessage = (msg: MessageEvent) => {
        if (msg.data.indexOf('Connected to Binotel WebSocket. Please, authorize!') !== -1) {
          webSocket?.send(
            JSON.stringify({
              task: 'authLikeService',
              key: binotelIntegration.key,
              secret: binotelIntegration.secret,
            }),
          )
          return
        }

        if (msg.data.indexOf('You are logged in!') !== -1) {
          return
        }

        if (msg.data.indexOf('Bad auth data!') !== -1) {
          openSnackbar(t('telephonyConnectionError'), 'error')
          return
        }

        const data = JSON.parse(msg.data) as BinotelCall
        if (data.eventName === 'callStart') {
          onCall({ incomingNumber: data.externalNumber, toNumber: data.pbxNumber })
        }
        if (data.eventName === 'callAnswer') {
          openSnackbar(
            `${t('telephonyAnswerCall')} ${formatPhoneNumber(
              formatPhoneFromBinotel(data.externalNumber),
            )} `,
            'success',
          )
        }
        if (data.eventName === 'callStop') {
          onCallStop()
          if (data.billsec > 0)
            openSnackbar(`${t('telephonyCallEnded')}: ${data.billsec}`, 'success')
        }
      }

      webSocket.onerror = () => {
        openSnackbar(t('telephonyConnectionClosed'), 'error')
      }
      webSocket.onclose = () => {
        openSnackbar(t('telephonyConnectionClosed'), 'error')
      }
    }
    return () => {
      if (webSocket) webSocket.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessSetting?.binotelIntegration, t])
}

export const formatPhoneFromBinotel = (number: string): string => {
  if (number.length === 10)
    return (
      '+38' + `${number.slice(0, 1)} ${number.slice(1, 3)} ${number.slice(3, 6)} ${number.slice(6)}`
    )

  return number
}
