import { FC } from 'react'
import { getTimeFromDate } from '@expane/date'
import { useCurrentTime } from 'logic/hooks/useCurrentTime'
import {
  CalendarColumn,
  CELL_HEIGHT,
  SMALL_CELL_HEIGHT,
  SMALL_TITLE_HEIGHT,
  TALL_TITLE_CELL_HEIGHT,
  TITLE_CELL_HEIGHT,
} from 'ui/Calendar'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ScaleSwitch } from 'ui/ScaleSwitch'
import { TAILWIND_TO_REM_RATIO } from 'logic/ui'

interface Props {
  calendarStartHour: number
  cellHeight: number
  enableGroupOffset?: boolean
  tall?: boolean
  className?: string
}
export const CalendarTimeLine: FC<Props> = ({
  cellHeight,
  calendarStartHour,
  className,
  tall = false,
  enableGroupOffset = false,
}) => {
  const currentTime = useCurrentTime()

  let offsetInTailwind = tall ? TALL_TITLE_CELL_HEIGHT : TITLE_CELL_HEIGHT
  if (enableGroupOffset) offsetInTailwind += SMALL_TITLE_HEIGHT
  const offsetInRem = offsetInTailwind / TAILWIND_TO_REM_RATIO

  if (getTimeFromDate(currentTime) < calendarStartHour) return null
  return (
    <div className="w-full h-full overflow-hidden absolute invisible">
      <div
        className={basicStyle + className}
        style={{
          top:
            (getTimeFromDate(currentTime) - calendarStartHour) * (cellHeight / 2) +
            offsetInRem +
            'rem',
        }}
      />
    </div>
  )
}

const basicStyle =
  'pointer-events-none absolute h-px w-full left-0 border-solid bg-primary-500 h-0.5 visible '

interface CalendarTimeColumnProps {
  rows: {
    startHour: number
    title: string
  }[]
  tall?: boolean
  isSelectedEmployee: boolean
  enableGroupOffset?: boolean
  isToday: boolean
  columnsAreShrinked: boolean
  setColumnsAreShrinked: (shrinked: boolean) => void
  showScaleSwitch: boolean
}
export const CalendarTimeColumn: FC<CalendarTimeColumnProps> = observer(
  ({
    rows,
    isSelectedEmployee,
    isToday,
    columnsAreShrinked,
    setColumnsAreShrinked,
    tall = false,
    enableGroupOffset,
    showScaleSwitch,
  }) => {
    const {
      bookingSettings: { isCalendarAlwaysOpen },
    } = store
    const timeColumnStyle = 'sticky bg-block ' + (isCalendarAlwaysOpen ? 'left-70' : 'left-0')

    let className =
      'grow flex flex-col text-main-color leading-none px-1 bg-block transition-all mr-2'

    if (enableGroupOffset) className += tall ? ' mt-14' : ' mt-10'
    else className += tall ? ' mt-8' : ' mt-4'

    const cellHeightInRem = columnsAreShrinked ? SMALL_CELL_HEIGHT / 2 : CELL_HEIGHT / 2

    return (
      <CalendarColumn className={timeColumnStyle} isTime>
        <div className={className}>
          {rows.map((row, index) => {
            if (Number.isInteger(row.startHour)) {
              let fullHour = true
              if (index === rows.length - 1) fullHour = false
              return (
                <div
                  style={{ height: (fullHour ? cellHeightInRem : cellHeightInRem / 2) + 'rem' }}
                  className="flex flex-col relative justify-around py-2.5"
                  key={row.title}
                >
                  <div className="absolute -top-2">{row.title}</div>
                  {!columnsAreShrinked && <div className={minutesLineStyle + 'w-4'} />}
                  {fullHour && (
                    <>
                      <div className={minutesLineStyle + 'w-8'} />
                      {!columnsAreShrinked && <div className={minutesLineStyle + 'w-4'} />}
                    </>
                  )}
                </div>
              )
            }
            return null
          })}
        </div>

        {isToday && !isSelectedEmployee && (
          <CalendarTimeLine
            cellHeight={columnsAreShrinked ? SMALL_CELL_HEIGHT : CELL_HEIGHT}
            calendarStartHour={rows[0].startHour}
            tall={tall}
            enableGroupOffset={enableGroupOffset}
          />
        )}
        <div className="order-first h-6 sticky -top-0.5 bg-block pl-1">
          {showScaleSwitch && (
            <ScaleSwitch
              iconSize="1.3rem"
              className="mt-1"
              shrinked={columnsAreShrinked}
              onChange={setColumnsAreShrinked}
            />
          )}
        </div>
      </CalendarColumn>
    )
  },
)

const minutesLineStyle = 'border-t border-gray-300 dark:border-gray-600 '
