import { EmployeeScheduleDto, ServerSalaryRateSettingType } from '@expane/data'
import {
  getOverlappingSalaryRateSettingAndPeriod,
  isSalaryRateDaily,
  isSalaryRateMonthly,
} from '../salarySettings'
import {
  eachMonthOfInterval,
  endOfDay,
  endOfMonth,
  getDaysInMonth,
  getOverlappingDaysInIntervals,
  isBefore,
  isSameDay,
  isWithinInterval,
  max,
  min,
  startOfDay,
  startOfMonth,
} from '@expane/date'
import { checkFromDate, getAmountOfDaysInRange } from './helpers'

export interface AccruedRateType {
  totalSum: number
  rateSalaryRequestData: RateSalaryRequestDataWithoutEmployeeType
}

export interface RateSalaryRequestDataType {
  value: number
  employeeId: number
  salarySettingId: number
  startPeriod: Date
  endPeriod: Date
}

export type RateSalaryRequestDataWithoutEmployeeType = Array<
  Omit<RateSalaryRequestDataType, 'employeeId'>
>

// в отдельном файле так как иначе recycle imports warning
export const calcAccruedRate = ({
  fromDate,
  toDate,
  salaryRateSetting,
  employeeSchedules,
  contractStartDate,
}: {
  fromDate: Date
  toDate: Date
  salaryRateSetting: ServerSalaryRateSettingType | undefined
  employeeSchedules: EmployeeScheduleDto[]
  contractStartDate: Date
}): AccruedRateType => {
  // нужно проверять в том случае, если начало выбранного периода до начала действия контракта
  const checkedFromDate = checkFromDate(contractStartDate, fromDate, toDate)

  let totalSum = 0
  const rateSalaryRequestData: Array<Omit<RateSalaryRequestDataType, 'employeeId'>> = []

  if (!salaryRateSetting) return { totalSum, rateSalaryRequestData }

  // пересечение выбранной даты и текущей ставки
  const overLappingPeriodAndRate = getOverlappingSalaryRateSettingAndPeriod({
    salaryRateSetting,
    toDate,
    fromDate: checkedFromDate,
  })

  // если выбранная дата не пересекается с текущей формулой то начисления = 0
  if (!overLappingPeriodAndRate.length) return { totalSum, rateSalaryRequestData }

  const overLappingPeriodAndRateStart = min(overLappingPeriodAndRate)
  const overLappingPeriodAndRateEnd = max(overLappingPeriodAndRate)

  // если выбранная дата до начала действия контракта, то количество рабочих дней(или ставка за месяц) 0
  const amountOfWorkingDays = isBefore(toDate, contractStartDate)
    ? 0
    : getAmountOfDaysInRange({
        employeeSchedules,
        fromDate: overLappingPeriodAndRateStart,
        toDate: overLappingPeriodAndRateEnd,
      })

  // если выбранная дата до начала действия контракта, то количество рабочих дней(или ставка за месяц) 0

  const monthRate = countMonthRate({
    fromDate: checkedFromDate,
    toDate,
    overLappingPeriodAndRateStart,
    overLappingPeriodAndRateEnd,
    rateValue: salaryRateSetting.rateValue,
    contractStartDate,
  })

  const monthRateTotalSum = isBefore(toDate, contractStartDate) ? 0 : monthRate.totalSum

  if (isSalaryRateMonthly(salaryRateSetting)) {
    totalSum += monthRateTotalSum

    for (const rate of monthRate.rateSalaryRequestData) {
      rateSalaryRequestData.push({
        ...rate,
        salarySettingId: salaryRateSetting.id,
      })
    }
  }
  if (isSalaryRateDaily(salaryRateSetting)) {
    for (const day of overLappingPeriodAndRate) {
      const daysCount = getAmountOfDaysInRange({
        employeeSchedules,
        fromDate: startOfDay(day),
        toDate: endOfDay(day),
      })

      if (daysCount === 1) {
        rateSalaryRequestData.push({
          value: salaryRateSetting.rateValue,
          salarySettingId: salaryRateSetting.id,
          startPeriod: startOfDay(day),
          endPeriod: endOfDay(day),
        })
      }
    }

    totalSum += salaryRateSetting.rateValue * amountOfWorkingDays
  }

  return { totalSum, rateSalaryRequestData }
}

const countMonthRate = ({
  fromDate,
  toDate,
  contractStartDate,
  overLappingPeriodAndRateStart,
  overLappingPeriodAndRateEnd,
  rateValue,
}: {
  fromDate: Date
  toDate: Date
  overLappingPeriodAndRateStart: Date
  overLappingPeriodAndRateEnd: Date
  rateValue: number
  contractStartDate: Date
}) => {
  const monthsInPeriod = eachMonthOfInterval({
    start: overLappingPeriodAndRateStart,
    end: overLappingPeriodAndRateEnd,
  })

  const rateSalaryRequestData: Array<
    Omit<RateSalaryRequestDataType, 'employeeId' | 'salarySettingId'>
  > = []

  const rates = monthsInPeriod.map(month => {
    const rateSalaryInfo = {
      value: rateValue,
      startPeriod: startOfMonth(month),
      endPeriod: endOfMonth(month),
    }

    if (
      isWithinInterval(contractStartDate, {
        start: startOfMonth(month),
        end: endOfMonth(month),
      })
    ) {
      // если начало контракта это не начало месяца, то нужно сделать перерасчет на месячной ставки на дни
      if (!isSameDay(month, contractStartDate)) {
        const overlappingDays = getOverlappingDaysInIntervals(
          { start: startOfMonth(month), end: endOfMonth(month) },
          { start: fromDate, end: toDate },
        )

        rateSalaryRequestData.push({
          value: (rateValue / getDaysInMonth(month)) * overlappingDays,
          startPeriod: contractStartDate,
          endPeriod: endOfMonth(month),
        })

        return (rateValue / getDaysInMonth(month)) * overlappingDays
      } else {
        rateSalaryRequestData.push(rateSalaryInfo)

        return rateValue
      }
    } else {
      rateSalaryRequestData.push(rateSalaryInfo)

      return rateValue
    }
  })

  return {
    totalSum: rates.reduce((acc, value) => acc + value, 0),
    rateSalaryRequestData,
  }
}
