import { makeAutoObservable } from 'mobx'

export class ServerStatusStore {
  constructor() {
    makeAutoObservable(this)
  }

  offline = false
  setOffline = (value: boolean) => {
    this.offline = value
  }
}
