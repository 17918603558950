import {
  addDays,
  createCurrentDate,
  formatDayMonth,
  formatFullDate,
  isSameMonth,
  parseNewDateFromText,
  startOfMonth,
  startOfWeek,
} from '@expane/date'
import {
  FadeInTranslateTop,
  modalsHTMLElement,
  useHandleClickOutside,
  usePopupOpenState,
} from '@expane/ui'
import { flip, offset, Placement, shift, useFloating } from '@floating-ui/react-dom'
import { FC, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline } from 'react-icons/io5'
import { dateTimePickerPopupStyle } from './'
import { CalendarView, DatePicker, PickerType } from './DatePicker'
import { getInputStyle, PLACEHOLDERS, transformToDateFormat } from './logic'

interface DateButtonProps {
  className: string
  calendarType: PickerType
  value: Date | undefined
  onChange: (date: Date | undefined) => void
  disabled: boolean
  isClearable?: boolean
  customDatePopupPlacement?: Placement | undefined
  showQuickButtons?: boolean
  customPopupsElement?: HTMLDivElement
  timezone: string
}

export const DateButton: FC<DateButtonProps> = ({
  className,
  value,
  onChange,
  calendarType,
  disabled,
  isClearable,
  customDatePopupPlacement,
  showQuickButtons,
  customPopupsElement,
  timezone,
}) => {
  const { t } = useTranslation()
  const getDateForDisplay = (date: Date | undefined, type: PickerType) => {
    if (date === undefined) return ''

    if (type === 'week') {
      const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 })
      const endOfWeek = addDays(startOfWeekDate, 6)
      return `${formatDayMonth(startOfWeekDate)} - ${formatFullDate(endOfWeek)}`
    }

    if (type === 'month') return t('fullMonthWithYear', { date: startOfMonth(date) })

    return formatFullDate(date)
  }
  const displayedDate = getDateForDisplay(value, calendarType)

  const [textInputValue, setTextInputValue] = useState<string | undefined>()
  const [view, setView] = useState<CalendarView>('month')
  const [activeStartDate, setActiveStartDate] = useState(
    startOfMonth(value ?? createCurrentDate(timezone)),
  )

  const inputRef = useRef<HTMLInputElement | null>(null)

  const {
    isOpen: dateIsOpen,
    closePopup: closeDatePopup,
    openPopup: openDatePopup,
  } = usePopupOpenState()

  const handleChange = () => {
    let newDate: Date | undefined
    if (isClearable) {
      // если ввели дату в инпут или инпут не изменялся
      if (textInputValue || (textInputValue === undefined && value)) {
        newDate = parseNewDateFromText(textInputValue ?? '', value, timezone)
      }

      // это условие выполняется только если в инпуте стереть значение
      if (textInputValue?.length === 0) newDate = undefined
    }
    if (!isClearable) newDate = parseNewDateFromText(textInputValue ?? '', value, timezone)

    onChange(newDate)
    setTextInputValue(undefined)
    closeDatePopup()
  }

  const [datePopoverRef, setDatePopoverRef] = useState<HTMLDivElement | null>(null)
  const dateButtonRef = useRef<HTMLDivElement | null>(null)
  useHandleClickOutside([dateButtonRef.current, datePopoverRef], handleChange, dateIsOpen)
  const { reference, floating, strategy, x, y } = useFloating({
    placement: customDatePopupPlacement,
    middleware: [offset(5), flip(), shift({ padding: 17 })],
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  // useLayoutEffect instead of useEffect for proper position animation
  useLayoutEffect(() => {
    reference(dateButtonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floating, dateButtonRef.current])
  useLayoutEffect(() => {
    floating(datePopoverRef)
  }, [datePopoverRef, floating])

  const checkActiveStartDate = (date: Date | undefined) => {
    if (date && !isSameMonth(date, activeStartDate)) {
      setActiveStartDate(date)
    }
  }

  return (
    <>
      <div
        ref={dateButtonRef}
        className={className}
        onClick={() => {
          if (disabled) return

          openDatePopup()
          if (calendarType === 'month') {
            setView('year')
          } else {
            setView('month')
          }
          setActiveStartDate(startOfMonth(value ?? createCurrentDate(timezone)))
        }}
      >
        <button
          disabled={disabled}
          className={`ml-1.5 text-placeholder-color hover:text-placeholder-hover-color ${
            disabled ? 'pointer-events-none' : ''
          }`}
        >
          <IoCalendarOutline />
        </button>
        <input
          ref={inputRef}
          disabled={calendarType === 'month' || calendarType === 'week' || disabled}
          className={getInputStyle(disabled)}
          value={textInputValue ?? displayedDate}
          placeholder={calendarType === 'week' ? PLACEHOLDERS.week : PLACEHOLDERS.date}
          onChange={e => {
            if (calendarType === 'date' || calendarType === 'dateTime') {
              setTextInputValue(transformToDateFormat(e.target.value))
            } else {
              setTextInputValue(e.target.value)
            }
          }}
          onKeyDown={e => {
            e.stopPropagation()
            if (e.key === 'Enter') {
              handleChange()
              inputRef.current?.blur()
            }
          }}
        />
      </div>

      {dateIsOpen &&
        createPortal(
          <div
            ref={setDatePopoverRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <FadeInTranslateTop
              className={dateTimePickerPopupStyle}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="date-picker"
            >
              <DatePicker
                timezone={timezone}
                value={value}
                onChange={date => {
                  checkActiveStartDate(date)
                  setTextInputValue(undefined)
                  onChange(date)
                }}
                view={view}
                setView={setView}
                activeStartDate={activeStartDate}
                setActiveStartDate={setActiveStartDate}
                type={calendarType}
                closeDatePopup={closeDatePopup}
                showQuickButtons={showQuickButtons}
              />
            </FadeInTranslateTop>
          </div>,
          customPopupsElement ?? modalsHTMLElement,
        )}
    </>
  )
}
