import { gql } from 'graphql-request'
import { queryClient, request, useMutation, useQuery, reportError, reportGqlError } from '../../api'
import {
  ServerExpensesReasonType,
  ServerExpensesReasonInsertInput,
  ServerRevenueReasonInsertInput,
} from '../../generated/graphql-types'
import { EXPENSES_REASONS_QUERY_KEY } from './queryKeys'
import { ExpensesReasonType } from './expensesReason.fragments'

export function useFetchExpensesReasons(branchId: number | undefined) {
  return useQuery<ServerExpensesReasonType[]>(
    [EXPENSES_REASONS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${ExpensesReasonType}
          query ($branchId: Int!) {
            expensesReasons(order_by: { id: desc }, where: { branchId: { _eq: $branchId } }) {
              ...expensesReasonType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.expensesReasons)) {
        return result.expensesReasons
      } else {
        reportError(new Error('expensesReasons is not an array'), 'warning', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchExpensesReasons',
      onError: reportGqlError,
      onSuccess: expenseReasons => {
        expenseReasons.forEach(eR =>
          queryClient.setQueryData([EXPENSES_REASONS_QUERY_KEY, eR.id], eR),
        )
      },
    },
  )
}

export function useCreateExpensesReason() {
  return useMutation(
    async (
      expensesReasonInsertInput: ServerExpensesReasonInsertInput &
        Required<Pick<ServerExpensesReasonInsertInput, 'branchId'>>,
    ): Promise<{ insertExpensesReason: { id: number; name: string } }> => {
      return request(
        gql`
          mutation ($expensesReasonInsertInput: expensesReason_insert_input!) {
            insertExpensesReason(object: $expensesReasonInsertInput) {
              id
              name
            }
          }
        `,
        { expensesReasonInsertInput },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([EXPENSES_REASONS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useMutateExpenseReasons() {
  return useMutation(
    (
      expensesReasonInsertInput: ServerRevenueReasonInsertInput[],
    ): Promise<{ insertExpensesReasons: { affectedRows: number } }> => {
      return request(
        gql`
          mutation ($expensesReasonInsertInput: [expensesReason_insert_input!]!) {
            insertExpensesReasons(
              objects: $expensesReasonInsertInput
              on_conflict: { constraint: expensesReason_pkey, update_columns: [name] }
            ) {
              affectedRows: affected_rows
            }
          }
        `,
        { expensesReasonInsertInput },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([EXPENSES_REASONS_QUERY_KEY]),
    },
  )
}
