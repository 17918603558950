import { MOVEMENT_TYPES, ServerMovementType } from '@expane/data'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders, findTreeMenuItem } from 'ui/TreeMenu/logic.common'
import { getFinancialInfoAboutMovement } from '@expane/logic/movement'

const filterMovementsByType = (movements: ServerMovementType[], typeId: number | undefined) => {
  if (typeId === undefined) return movements

  return movements.filter(movement => movement.type === typeId)
}

const filterMovementsBySupplier = (
  movements: ServerMovementType[],
  supplierId: number | undefined,
) => {
  if (supplierId === undefined) return movements

  return movements.filter(movement => movement.supplierId === supplierId)
}

const filterMovementsByFromStorage = (
  movements: ServerMovementType[],
  fromStorageId: number | undefined,
) => {
  if (fromStorageId === undefined) return movements

  return movements.filter(movement => movement.fromStorageId === fromStorageId)
}

const filterMovementsByToStorage = (
  movements: ServerMovementType[],
  toStorageId: number | undefined,
) => {
  if (toStorageId === undefined) return movements

  return movements.filter(movement => movement.toStorageId === toStorageId)
}

const filterMovementsByProducts = (movements: ServerMovementType[], products?: TreeMenuItem[]) => {
  if (!products || products.length === 0) return movements

  const extractedProducts = extractItemsFromFolders(products)

  return movements.filter(movement =>
    movement.movementProducts.some(mP =>
      extractedProducts.some(extractedProduct => mP.product.id === extractedProduct.id),
    ),
  )
}

const filterMovementsByStatusId = (
  movements: ServerMovementType[],
  statusId: number | undefined,
) => {
  if (statusId === undefined) return movements
  return movements.filter(movement => {
    const { isPaid } = getFinancialInfoAboutMovement(movement)
    if (statusId === statusItems.paid.id) {
      if (movement?.type === MOVEMENT_TYPES.arrival.id) return isPaid
    }
    if (statusId === statusItems.notPaid.id) {
      if (movement?.type === MOVEMENT_TYPES.arrival.id) return !isPaid
      if (movement?.type === MOVEMENT_TYPES.return.id) return movement.fulfilled
    }
    if (statusId === statusItems.returned.id) {
      if (movement?.type === MOVEMENT_TYPES.return.id) return movement.fulfilled
    }
    if (statusId === statusItems.notReturned.id) {
      if (movement?.type === MOVEMENT_TYPES.return.id) return !movement.fulfilled
    }
    if (statusId === statusItems.fulfilled.id) {
      return movement.fulfilled
    }
    if (statusId === statusItems.notFulfilled.id) {
      return !movement.fulfilled
    }

    return false
  })
}

export const filterMovements = ({
  movements,
  typeId,
  supplierId,
  fromStorageId,
  toStorageId,
  products,
  statusId,
}: {
  movements: ServerMovementType[] | undefined
  typeId?: number | null
  supplierId?: number | null
  fromStorageId?: number | null
  toStorageId?: number | null
  products?: TreeMenuItem[]
  statusId?: number | null
}) => {
  if (movements === undefined) return []

  const filteredByType = filterMovementsByType(movements, typeId ?? undefined)
  const filteredBySupplier = filterMovementsBySupplier(filteredByType, supplierId ?? undefined)
  const filteredByFromStorage = filterMovementsByFromStorage(
    filteredBySupplier,
    fromStorageId ?? undefined,
  )
  const filteredByToStorage = filterMovementsByToStorage(
    filteredByFromStorage,
    toStorageId ?? undefined,
  )
  const filteredByProducts = filterMovementsByProducts(filteredByToStorage, products)
  const filteredByStatus = filterMovementsByStatusId(filteredByProducts, statusId ?? undefined)

  return filteredByStatus
}

export const statusItems = {
  paid: {
    id: 1,
    name: 'paid',
  },
  notPaid: {
    id: 2,
    name: 'notPaid',
  },
  returned: {
    id: 3,
    name: 'returned',
  },
  notReturned: {
    id: 4,
    name: 'notReturned',
  },
  fulfilled: {
    id: 5,
    name: 'fulfilled',
  },
  notFulfilled: {
    id: 6,
    name: 'notFulfilled',
  },
}

export const getFullInfoForProducts = (
  presetProducts: { id: number; isFolder: boolean }[],
  allProducts: TreeMenuItem[],
) => {
  const result: TreeMenuItem[] = []

  presetProducts.forEach(({ id, isFolder }) => {
    const fullInfo = findTreeMenuItem(id, isFolder, allProducts)

    if (fullInfo) result.push(fullInfo)
  })

  return result
}
