import { ChangeEvent, forwardRef, InputHTMLAttributes } from 'react'
import { InputLabel } from '../InputLabel'
import { ErrorMessage } from '../ErrorMessage'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  className?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  errorMessage?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { required, label, onChange, className, errorMessage, ...restProps } = props

  return (
    <div className={className}>
      {label ? <InputLabel label={label} required={required} /> : null}

      <input
        ref={ref}
        className={`p-4 dark:text-white w-full rounded border bg-input ${
          errorMessage ? 'border-error-500' : 'border-gray-300 focus:border-primary-500'
        } `}
        onChange={onChange}
        {...restProps}
      />

      {errorMessage ? <ErrorMessage errorMessage={errorMessage} /> : null}
    </div>
  )
})
