import { FC } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
import { stopEnterPropagation } from '../../logic'
import { Hint } from '../Hint'

export interface InputLabelProps {
  label?: string
  hint?: string
  required?: boolean
  className?: string
  onPlusClick?: () => void
}
export const InputLabel: FC<InputLabelProps> = props => {
  const { label, hint, required, onPlusClick, className = '' } = props

  return (
    <>
      {label && (
        <div className={className ? containerClassName + ' ' + className : containerClassName}>
          <div className="flex">
            <p className={labelClassName}>
              {label}
              {required && <span className="text-error-600">*</span>}
            </p>
            {onPlusClick && (
              <div className="relative w-6">
                <button
                  onKeyDown={stopEnterPropagation}
                  onClick={onPlusClick}
                  className="text-primary-500 absolute -top-0.5 hover:text-primary-700 hover:dark:text-primary-600 rounded-full focus:ring-1 ring-primary-500"
                >
                  <IoAddCircleOutline size="1.4rem" />
                </button>
              </div>
            )}
          </div>

          {hint && <Hint>{hint}</Hint>}
        </div>
      )}
    </>
  )
}
const containerClassName = 'relative flex justify-between items-center'
export const labelClassName = 'text-sm font-medium text-label-color'
