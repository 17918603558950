import { FC } from 'react'

export interface SpinnerSmallProps {
  className?: string
}

/**
 * Spinner color === text color (inherited from parent). You can use className to set the text color
 */
export const SpinnerSmall: FC<SpinnerSmallProps> = ({ className = '' }) => {
  return <div className={'inline w-5 h-5 ' + spinnerAfterStyles + ' ' + className} />
}

const spinnerAfterStyles = `
after:content-[' '] 
after:block 
after:rounded-full 
after:m-0.5 
after:w-0 
after:h-0 
after:box-border 
after:border-8 
after:border-x-current 
after:border-y-transparent 
after:animate-spinner 
`
