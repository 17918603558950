import { Button, CloseButton, Dialog, PlaceholderInput, PlaceholderString } from '@expane/ui'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline } from 'react-icons/io5'
import { labelStyle } from './'

export const StopEmployeeScheduleDialogPlaceholder: FC<Pick<DialogProps, 'closeDialog'>> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Body>
        <div className="flex">
          <div className="mr-2">
            <p className={labelStyle}>{t('employee.name')}:</p>
            <p className={labelStyle}>{t('branch.name')}:</p>
            <p className={labelStyle}>{t('startDate')}:</p>
            <p className={labelStyle}>{t('endDate')}:</p>
          </div>

          <div>
            <PlaceholderString className="py-2" />
            <PlaceholderString className="py-2" />
            <PlaceholderString className="py-2" />
            <PlaceholderInput className="pb-4" />
          </div>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <Button Icon={IoCalendarOutline} disabled>
          {t('stopSchedule.shortName')}
        </Button>
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
