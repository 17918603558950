import { Hint, Input, InputLabel, Paper, Switch } from '@expane/ui'
import { FC } from 'react'
import { Controller, Control, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SoftwarePOSFormValues } from 'widgets/SoftwarePOSDialog/SoftwarePOSDialogLogic'

interface SoftwarePosCredentialsDialogProps {
  control: Control<SoftwarePOSFormValues>
  isEditingAllowed: boolean
  isCreate: boolean
}

export const SoftwarePosCredentialsDialog: FC<SoftwarePosCredentialsDialogProps> = ({
  control,
  isEditingAllowed,
  isCreate,
}) => {
  const { t } = useTranslation()

  const watchedIsChangingCredentials = useWatch({ control, name: 'isChangingCredentials' })
  const watchedIsChangingLicenseKey = useWatch({ control, name: 'isChangingLicenseKey' })

  const disabledCredentials = !isEditingAllowed || !watchedIsChangingCredentials
  const disabledLicenseKey = !isEditingAllowed || !watchedIsChangingLicenseKey

  return (
    <>
      <Paper className="px-2 pt-2">
        <div className="flex gap-2">
          <InputLabel required label={t('softwarePOS.credentials')} />

          {!isCreate ? (
            <>
              <Controller
                name="isChangingCredentials"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    containerClassName="ml-auto"
                    label={t('change')}
                    onChange={onChange}
                    checked={value}
                  />
                )}
              />

              <Hint type="warning">{t('softwarePOS.credentialsWarning')}</Hint>
            </>
          ) : null}
        </div>

        <div className="flex gap-2">
          <Controller
            name="login"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={t('softwarePOS.login')}
                containerClassName="grow"
                errorMessage={{
                  isShown: Boolean(error),
                  text: t('formError.required'),
                }}
                value={value}
                onChange={onChange}
                disabled={disabledCredentials}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: watchedIsChangingCredentials }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={t('softwarePOS.password')}
                containerClassName="grow"
                errorMessage={{
                  isShown: Boolean(error),
                  text: t('formError.required'),
                }}
                value={value}
                onChange={onChange}
                disabled={disabledCredentials}
              />
            )}
          />
        </div>
      </Paper>
      <Paper className="px-2 pt-2 mt-2">
        <div className="flex gap-2 mb-1">
          <InputLabel label={t('softwarePOS.licenseKey')} required />

          {!isCreate ? (
            <>
              <Controller
                name="isChangingLicenseKey"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    containerClassName="ml-auto"
                    label={t('change')}
                    onChange={onChange}
                    checked={value}
                  />
                )}
              />
              <Hint type="warning">{t('softwarePOS.licenseKeyWarning')}</Hint>
            </>
          ) : null}
        </div>

        <Controller
          name="licenseKey"
          control={control}
          rules={{ required: watchedIsChangingLicenseKey }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              errorMessage={{
                isShown: Boolean(error),
                text: t('formError.required'),
              }}
              value={value}
              onChange={onChange}
              disabled={disabledLicenseKey}
            />
          )}
        />
      </Paper>
    </>
  )
}
