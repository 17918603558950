import { FC } from 'react'
import { CalendarTitleCell } from 'ui/Calendar'
import { ExtendedEmployee, ServerLocationWithServiceIdsType, WeeklySchedule } from '@expane/data'
import { LocationColumn } from 'pages/BookingsPage/BookingsCalendar/columns/LocationColumn'
import { GROUP_TITLE_FOR_LOCATIONS_WITHOUT_GROUP } from '..'
import { ContextMenuItem } from 'logic/hooks/useContextMenu'

interface LocationColumnProps {
  employeesFromFilter: ExtendedEmployee[]
  branchSchedule: WeeklySchedule | undefined
  hours: number[]
  date: Date
  locations: ServerLocationWithServiceIdsType[]
  locationGroup: {
    id: string | number
    name: string
  }
  boundaryElement: HTMLDivElement | undefined
  shrinked: boolean
  contextMenuItems: ContextMenuItem[]
}

export const LocationGroupColumn: FC<LocationColumnProps> = ({
  hours,
  date,
  employeesFromFilter,
  branchSchedule,
  locations,
  locationGroup,
  shrinked,
  boundaryElement,
  contextMenuItems,
}) => {
  const locationsFromThisGroup = locations.filter(location => {
    // If location is without group
    if (locationGroup.name === GROUP_TITLE_FOR_LOCATIONS_WITHOUT_GROUP && !location.groupId)
      return true

    return location.groupId === locationGroup.id
  })

  if (locationsFromThisGroup.length === 0) return null

  return (
    <div className={shrinked ? undefined : 'grow'}>
      <CalendarTitleCell rightBorder height="small">
        <span className={shrinked ? 'text-xs truncate text-center w-0 grow' : undefined}>
          {locationGroup.name}
        </span>
      </CalendarTitleCell>

      <div className="flex">
        {locationsFromThisGroup.map((location, locationIndex) => {
          const columnIndex = locations.findIndex(
            locationFromAll => locationFromAll.id === location.id,
          )
          const last = columnIndex === locations.length - 1
          return (
            <LocationColumn
              contextMenuItems={contextMenuItems}
              key={location.id}
              location={location}
              employeesFromFilter={employeesFromFilter}
              last={last}
              rightBorder={locationIndex === locationsFromThisGroup.length - 1 && !last}
              branchSchedule={branchSchedule}
              hours={hours}
              date={date}
              locations={locations}
              columnIndex={columnIndex}
              shrinked={shrinked}
              boundaryElement={boundaryElement}
              grouped
            />
          )
        })}
      </div>
    </div>
  )
}
