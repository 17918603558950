import { Button } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IoArchiveOutline,
  IoCalendarOutline,
  IoPersonRemoveOutline,
  IoSettingsOutline,
} from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { useOpenTimeOffsDialog } from 'widgets/TimeOffsDialog'
import { ButtonWithBillingCheck } from 'widgets/Buttons'
import { useOpenTimeOffsArchiveDialog } from 'widgets/TimeOffsArchiveDialog'

export const ScheduleNavigationButton: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const route = '/employees/schedules'

  return (
    <Button
      type="outline"
      onClick={() => navigate(route)}
      className={'w-32 ' + className}
      twoLines
      Icon={IoCalendarOutline}
    >
      {t('schedule.name')}
    </Button>
  )
}

export const ScheduleTemplateSettingsNavigationButton: FC<{ className?: string }> = ({
  className,
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const route = '/employees/schedules/templates'

  return (
    <Button
      type="outline"
      onClick={() => navigate(route)}
      className={'w-44 ' + className}
      twoLines
      Icon={IoSettingsOutline}
    >
      {t('schedule.scheduleTemplatesSettings')}
    </Button>
  )
}

export const CreateTimeOffsButton: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()

  const { openCreateTimeOffsDialog, timeOffsDialog } = useOpenTimeOffsDialog()

  return (
    <>
      <ButtonWithBillingCheck
        type="outline"
        onClick={openCreateTimeOffsDialog}
        className={'w-32 ' + className}
        twoLines
        Icon={IoPersonRemoveOutline}
      >
        {t('timeOffs.createTimeOffs')}
      </ButtonWithBillingCheck>

      {timeOffsDialog}
    </>
  )
}

export const ArchiveTimeOffsButton: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()

  const { openTimeOffsArchiveDialog, timeOffsArchiveDialog } = useOpenTimeOffsArchiveDialog()

  return (
    <>
      <ButtonWithBillingCheck
        type="outline"
        onClick={openTimeOffsArchiveDialog}
        className={'w-40 ' + className}
        twoLines
        Icon={IoArchiveOutline}
      >
        {t('timeOffs.archiveTimeOffs')}
      </ButtonWithBillingCheck>

      {timeOffsArchiveDialog}
    </>
  )
}
