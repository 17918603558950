import { useRef, useState } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { PlaceholderString } from '../PlaceholderContent'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from './components'

export * from './components'

type TableProps<T> = {
  columns: ColumnDef<T>[]
  data: T[] | undefined
  onRowClick?: (item: T) => void
  isLoading?: boolean
  customRowClassName?: (item: T) => string | undefined
  customCellContainer?: boolean
  // Selection state. Both properties should be present
  rowSelection?: RowSelectionState // keys are the indexes of selected rows
  onRowSelectionChange?: OnChangeFn<RowSelectionState>
  containerClassName?: string
}
// Most of all logic was taken from the official example
// https://github.com/TanStack/table/blob/main/examples/react/virtualized-rows/src/main.tsx
export const Table = <T,>({
  columns,
  data,
  onRowClick,
  isLoading = false,
  customRowClassName,
  rowSelection,
  onRowSelectionChange,
  customCellContainer = false,
  containerClassName,
}: TableProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: data ?? [],
    columns,
    state: {
      sorting,
      rowSelection,
    },
    onRowSelectionChange,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
  })

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const { rows } = table.getRowModel()

  return (
    <TableContainer className={containerClassName} ref={tableContainerRef}>
      <TableHeader>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <TableHeaderCell
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: header.getSize() }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={
                        header.column.getCanSort()
                          ? 'cursor-pointer select-none text-left flex'
                          : 'text-left'
                      }
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' ▲',
                        desc: ' ▼',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                  <div
                    onMouseDown={header.getResizeHandler()}
                    className="absolute right-0 top-0 h-full w-1 cursor-col-resize select-none hover:bg-primary-300 active:bg-primary-300 dark:hover:bg-gray-300 dark:active:bg-gray-300 rounded transition-colors"
                  />
                </TableHeaderCell>
              )
            })}
          </tr>
        ))}
      </TableHeader>
      <TableBody>
        {isLoading && (
          <>
            <PlaceholderRow columns={columns} />
            <PlaceholderRow columns={columns} />
            <PlaceholderRow columns={columns} />
          </>
        )}
        {!isLoading &&
          rows.map(row => {
            let className: string | undefined = undefined
            if (customRowClassName) {
              const addedStyles = customRowClassName(row.original)
              if (addedStyles) className = addedStyles
            }
            return (
              <TableRow
                key={row.id}
                className={className}
                onClick={onRowClick ? () => onRowClick(row.original) : undefined}
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableCell customContainer={customCellContainer} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
      </TableBody>
    </TableContainer>
  )
}

const PlaceholderRow = <T,>({ columns }: { columns: ColumnDef<T>[] }) => {
  return (
    <TableRow>
      {columns.map(column => (
        // @ts-expect-error there is either accessorKey or id
        <TableCell key={column.accessorKey ?? column.id} style={{ width: column.size }}>
          <PlaceholderString width="full" />
        </TableCell>
      ))}
    </TableRow>
  )
}
