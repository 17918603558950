import { arrow, flip, offset, shift, Side, useFloating } from '@floating-ui/react-dom'
import { FC, memo, PropsWithChildren, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { IoHelpCircleOutline, IoWarningOutline } from 'react-icons/io5'
import { modalsHTMLElement } from '../../logic'
import { getPlacementStylesForArrow } from '../../logic/popupArrow'
import { IconType } from 'react-icons'

type HintType = 'info' | 'warning'

export interface HintProps {
  type?: HintType
  customModal?: HTMLElement | null
  color?: 'normal' | 'bright'
  iconSize?: `${number}rem`
  CustomIcon?: IconType
  className?: string
  // Used if Hint is provided inside component with click outside handler
  addRef?: (ref: HTMLDivElement) => void
  removeRef?: (ref: HTMLDivElement) => void
}

export const Hint: FC<PropsWithChildren<HintProps>> = memo(
  ({
    children,
    customModal = modalsHTMLElement,
    type = 'info',
    color = 'normal',
    iconSize = '1.1rem',
    CustomIcon,
    className,
    addRef,
    removeRef,
  }) => {
    const [isOpen, setIsOpen] = useState(false)

    const [floatingRef, setFloatingRef] = useState<HTMLDivElement | null>(null)

    const arrowRef = useRef(null)
    const {
      reference,
      floating,
      strategy,
      x,
      y,
      middlewareData: { arrow: arrowData, shift: shiftData },
      placement,
    } = useFloating({
      middleware: [flip(), offset(5), arrow({ element: arrowRef }), shift()],
    })

    useLayoutEffect(() => {
      if (floatingRef) {
        floating(floatingRef)
        addRef?.(floatingRef)

        return () => {
          removeRef?.(floatingRef)
        }
      }
    }, [floatingRef, addRef, removeRef, floating])

    const side = placement.split('-')[0] as Side
    const arrowPlacementStyles = getPlacementStylesForArrow(side, arrowData, shiftData)

    const Icon = getIcon(type, CustomIcon)

    const iconClassName = getIconClassName(type, color)

    let arrowStyle = 'absolute transform rotate-45 w-2 h-2'

    arrowStyle += type === 'info' ? ' bg-primary-400 dark:bg-primary-600' : ' bg-yellow-600'

    let indentStyle = 'absolute left-0 w-full h-5'
    if (side === 'bottom') {
      indentStyle += ' -top-5'
    } else {
      indentStyle += ' -bottom-5'
    }

    return (
      <div className={className} onMouseLeave={() => setIsOpen(false)}>
        <div ref={reference} className="cursor-pointer" onMouseEnter={() => setIsOpen(true)}>
          <Icon size={iconSize} className={iconClassName} />
        </div>
        {isOpen &&
          customModal &&
          createPortal(
            <div
              ref={setFloatingRef}
              style={{
                position: strategy,
                top: y ?? 0 + 'px',
                left: x ?? 0 + 'px',
              }}
              className={
                messageStyle +
                (type === 'info' ? ' bg-primary-400 dark:bg-primary-600' : ' bg-yellow-600')
              }
            >
              <div ref={arrowRef} style={arrowPlacementStyles} className={arrowStyle} />
              <div className={indentStyle} />
              {children}
            </div>,
            customModal,
          )}
      </div>
    )
  },
)
const messageStyle = 'max-w-sm px-2 py-1 text-sm rounded text-btn-primary whitespace-pre-line'

const getIconClassName = (type?: 'info' | 'warning', color?: 'normal' | 'bright') => {
  if (type === 'info') {
    if (color === 'bright')
      return 'text-primary-500 dark:text-primary-300 hover:text-primary-800 hover:dark:text-primary-600 ml-0.5'
    else
      return 'text-primary-100 dark:text-primary-500 hover:text-primary-500 hover:dark:text-primary-700'
  } else {
    if (color === 'bright') return 'text-yellow-500 hover:text-yellow-800'
    else return 'text-yellow-300 hover:text-yellow-600'
  }
}

const getIcon = (type: HintType, CustomIcon?: IconType) => {
  if (CustomIcon) return CustomIcon
  else if (type === 'info') return IoHelpCircleOutline
  else return IoWarningOutline
}
