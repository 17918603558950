import {
  ServerClientBriefWithTagType,
  ServerTransactionBriefWithClient,
  useFetchTransactionsBriefWithClient,
  useFetchTransactionsBriefWithClientByDate,
} from '@expane/data'
import { isBefore } from '@expane/date'
import {
  defineStartAndEndDateByPeriodOfTime,
  SIX_MONTHS,
  THREE_MONTHS,
} from '@expane/logic/analytics'
import { getTransactionsWithClientsDepositsAndPayments } from '@expane/logic/finances/filters'
import { getNewClients, groupTransactionsByClientId } from '@expane/logic/client'

// находим транзакции конкретных клиентов (например по определенному типу источника)
export const getTransactionsByClients = <Client extends { id: number }>(
  transactions: Array<ServerTransactionBriefWithClient>,
  clients: Client[],
): Array<ServerTransactionBriefWithClient> =>
  transactions.filter(transaction => clients.some(({ id }) => transaction?.client?.id === id))

type LostClients = {
  lostClientsIds: Array<string>
  lostClientsQuantity: number
  lostClientsLoading: boolean
}

// считаем сколько в базе клиентов, которые не совершали оплату на протяжении 6 месяцев (бывшие
// клиенты)
export const useGetLostClients = (
  isFetchingAllowed: boolean,
  timezone: string | undefined,
  branchId: number | undefined,
): LostClients => {
  const [startDate, endDate] = defineStartAndEndDateByPeriodOfTime(SIX_MONTHS.id, timezone)

  const { data: transactions, isLoading: isLoadingTransactionsByDate } =
    useFetchTransactionsBriefWithClientByDate({
      startDate,
      endDate,
      timezone,
      branchId,
      isFetchingAllowed,
    })
  const { data: allTransactions, isLoading: isLoadingAllTransactions } =
    useFetchTransactionsBriefWithClient(timezone, branchId, isFetchingAllowed)

  const lostClients: LostClients = {
    lostClientsIds: [],
    lostClientsQuantity: 0,
    lostClientsLoading: Boolean(isLoadingTransactionsByDate || isLoadingAllTransactions),
  }

  if (!transactions || !allTransactions || isLoadingTransactionsByDate || isLoadingAllTransactions)
    return lostClients

  const trWithClientsByLastSixMonths = getTransactionsWithClientsDepositsAndPayments(
    transactions ?? [],
  )

  const clientsIdsByLastSixMonths = Object.keys(
    groupTransactionsByClientId(trWithClientsByLastSixMonths),
  )

  const trWithClientsLifeTime = getTransactionsWithClientsDepositsAndPayments(allTransactions)

  const clientsIdsLifeTime = Object.keys(groupTransactionsByClientId(trWithClientsLifeTime))

  const lostClientsIds = clientsIdsLifeTime.filter(
    clientId => !clientsIdsByLastSixMonths.some(trId => clientId === trId),
  )

  lostClients.lostClientsIds = [...lostClientsIds]
  lostClients.lostClientsQuantity = lostClientsIds.length

  return lostClients
}

type PotentialClients = {
  potentialClientsIds: Array<string>
  potentialClientsQuantity: number
  potentialClientsLoading: boolean
}

// считаем сколько в базе клиентов, которые совершили оплату на сумму 0(то есть тестовую услугу) за
// последние 3 месяца или были созданы но еще не совершили никакую оплату
export const useGetPotentialClients = (
  isFetchingAllowed: boolean,
  timezone: string | undefined,
  branchId: number | undefined,
  clients?: ServerClientBriefWithTagType[],
): PotentialClients => {
  const [startDate, endDate] = defineStartAndEndDateByPeriodOfTime(THREE_MONTHS.id, timezone)

  const { data: transactions, isLoading: isLoadingTransactionsByDate } =
    useFetchTransactionsBriefWithClientByDate({
      startDate,
      endDate,
      timezone,
      branchId,
      isFetchingAllowed,
    })

  const { data: allTransactions, isLoading: isLoadingAllTransactions } =
    useFetchTransactionsBriefWithClient(timezone, branchId, isFetchingAllowed)

  const potentialClients: PotentialClients = {
    potentialClientsIds: [],
    potentialClientsQuantity: 0,
    potentialClientsLoading: Boolean(isLoadingTransactionsByDate || isLoadingAllTransactions),
  }

  if (
    !transactions ||
    !allTransactions ||
    isLoadingTransactionsByDate ||
    isLoadingAllTransactions ||
    !clients
  )
    return potentialClients

  const transactionsWithClientsByPeriod =
    getTransactionsWithClientsDepositsAndPayments(transactions)

  const { newClientsIds } = getNewClients(
    transactionsWithClientsByPeriod,
    getTransactionsWithClientsDepositsAndPayments(allTransactions),
  )

  const newClientsTransactions = transactionsWithClientsByPeriod.filter(tr =>
    newClientsIds.some(clientId => tr.clientId === Number(clientId)),
  )

  const groupedTransactionsByClientId = groupTransactionsByClientId(newClientsTransactions)

  const potentialClientsIds: Array<string> = []

  if (Object.keys(groupedTransactionsByClientId).length !== 0) {
    // проходимся по транзакция новых клиентов и смотрим совершали ли они оплату только на сумму 0 или
    // нет
    for (const key in groupedTransactionsByClientId) {
      if (groupedTransactionsByClientId[key].every(tr => tr.amount === 0)) {
        potentialClientsIds.push(groupedTransactionsByClientId[key][0].clientId.toString())
      }
    }
  }

  // клиенты которые были созданы за последние 3 месяца
  const lastThreeMonthsCreatedClients = clients.filter(cl => !isBefore(cl.createdAt, startDate))

  // клиенты которые были созданы за последние 3 месяца но еще не сделали никакую оплату
  const newCreatedClients = lastThreeMonthsCreatedClients.filter(
    ({ id }) => !Object.keys(groupedTransactionsByClientId).includes(id.toString()),
  )

  potentialClients.potentialClientsIds = [
    ...potentialClientsIds,
    ...newCreatedClients.map(({ id }) => id.toString()),
  ]
  potentialClients.potentialClientsQuantity = potentialClientsIds.length

  return potentialClients
}
