import {
  CARD_TYPES,
  ServerTransactionBriefWithCardsType,
  ServerTransactionType,
  TRANSACTION_TYPES,
} from '@expane/data'
import { checkIsTransactionPayment } from '../finances/filters'

export type CardReportItemType = {
  id: number
  name: string
  price: number
  creditPrice: number
  isSubscription: boolean
  isGiftCard: boolean
  isRefunded: boolean
}

export const convertTransactionsToCardReportItem = (
  transactions: Array<ServerTransactionType | ServerTransactionBriefWithCardsType>,
): CardReportItemType[] => {
  const result: CardReportItemType[] = []

  // для подсчета берем только транзакции оплат
  const filteredTransactions = transactions.filter(
    transaction => transaction.transactionsCards.length && checkIsTransactionPayment(transaction),
  )

  for (const filteredTransaction of filteredTransactions) {
    for (const transactionCard of filteredTransaction.transactionsCards) {
      // проверяем были ли возвраты за эту карту во всех транзакциях
      const refundTransaction = transactions.find(
        transaction =>
          transaction.type === TRANSACTION_TYPES.refund.id &&
          transaction.parentId === filteredTransaction.id &&
          transaction.transactionsCards.some(
            card => card.card.cardTemplate.id === transactionCard.card.cardTemplate.id,
          ),
      )

      // возвращать абонементы мы можем только по одному поэтому сумма транзакции и есть сумма возврата по этому абонементу
      const refundedPrice = refundTransaction?.amount ?? 0

      // в подсчетах абонементов, логика реализована таким образом что мы возвращаем и те абонементы, которые были возвращены, и их потом фильтруем
      result.push({
        id: transactionCard.card.cardTemplate.id,
        name: transactionCard.card.cardTemplate.name,
        // берем цену за которую карта продалась, а не та что по умолчанию
        // если абонемент не был возвращен то refundedPrice = 0 и на сумму не влияет
        // если был возвращен, то в дальнейшем в подсчетах можем учитывать "невозвращенную" разницу если она есть
        price: transactionCard.price - refundedPrice,
        // TODO сделать расчет
        creditPrice: 0,
        isSubscription: transactionCard.card.cardTemplate.type === CARD_TYPES.subscription,
        isGiftCard: transactionCard.card.cardTemplate.type === CARD_TYPES.giftCard,
        isRefunded: Boolean(refundTransaction),
      })
    }
  }

  return result
}

export const getSubscriptionReportItems = (cardReportItems: CardReportItemType[]) =>
  cardReportItems.filter(item => item.isSubscription)

// так как возврат по абонементам может быть частичный, то нам нужно учитывать в подсчетах такие абонементы
export const getNotRefundedReportItems = <T extends { isRefunded: boolean; price: number }>(
  reportItems: T[],
) => reportItems.filter(item => !item.isRefunded || (item.isRefunded && item.price > 0))

export const getGiftCardReportItems = (cardReportItems: CardReportItemType[]) =>
  cardReportItems.filter(item => item.isGiftCard)
