import {
  BranchWithSchedule,
  ServerEmployeeAllBranchesGroupType,
  ServerInterbranchServiceByIdType,
  ServerLocationAllBranchesBriefType,
  useCreateInterbranchService,
  useUpdateService,
} from '@expane/data'
import { useUpdateFile } from '@expane/logic/file'
import { permissions } from '@expane/logic/permission'
import { CloseButton, Dialog, Modal } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SaveButton } from 'widgets/Buttons'
import {
  generateBranchServices,
  InterbranchServiceDialogFormData,
  transformFormDataToInterbranchServiceCreate,
  transformFormDataToInterbranchServiceUpdate,
} from '../logic'
import { InterbranchServiceByBranchList } from './InterbranchServiceByBranchList'
import { InterbranchServiceCommonFields } from './InterbranchServiceCommonFields'

interface InterbranchServiceLogicProps extends Pick<DialogProps, 'closeDialog' | 'isCreate'> {
  interbranchServiceById: ServerInterbranchServiceByIdType | undefined
  branches: BranchWithSchedule[]
  employeeGroups: ServerEmployeeAllBranchesGroupType[]
  locations: ServerLocationAllBranchesBriefType[]
  myPermissions: string[]
}

export const InterbranchServiceLogic: FC<InterbranchServiceLogicProps> = ({
  interbranchServiceById,
  branches,
  closeDialog,
  employeeGroups,
  locations,
  myPermissions,
  isCreate,
}) => {
  const { t } = useTranslation()

  const { mutateAsync: createInterbranchService } = useCreateInterbranchService()
  const { mutateAsync: updateInterbranchService } = useUpdateService()
  const updatePhoto = useUpdateFile()

  const [openSnackbar] = useSnackbar()

  const { control, formState, setValue, handleSubmit, trigger, getFieldState } =
    useForm<InterbranchServiceDialogFormData>({
      defaultValues: {
        name: interbranchServiceById?.name ?? '',
        defaultDuration: interbranchServiceById?.defaultDuration.toString() ?? '',
        description: interbranchServiceById?.description ?? '',
        picture: interbranchServiceById?.picture ?? '',
        color: interbranchServiceById?.color ?? undefined,
        price: interbranchServiceById?.price.toString() ?? '',
        costPrice: interbranchServiceById?.costPrice.toString() ?? '',
        type: interbranchServiceById?.type ?? undefined,
        availableForClient: interbranchServiceById?.availableForClient ?? true,
        branchServices: generateBranchServices(branches, interbranchServiceById, employeeGroups, t),
      },
    })

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

  const onSubmit: SubmitHandler<InterbranchServiceDialogFormData> = async data => {
    const pictureUrl = await updatePhoto({
      prevFile: interbranchServiceById?.picture ?? undefined,
      file: data.picture,
    })

    if (isCreate) {
      const result = await createInterbranchService(
        transformFormDataToInterbranchServiceCreate(data, pictureUrl),
      )

      if (result?.insertService?.id) {
        openSnackbar(t('interbranchService.createdSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else if (interbranchServiceById) {
      const result = await updateInterbranchService(
        transformFormDataToInterbranchServiceUpdate(data, interbranchServiceById, pictureUrl),
      )

      if (result?.updateServiceById?.id)
        openSnackbar(t('interbranchService.updatedSuccessfully'), 'success')
      else openSnackbar(t('submitError'), 'error')
    }

    closeDialog()
  }

  const isEditingAllowed = myPermissions.includes(permissions.service.set)

  return (
    <Modal close={closePopups} animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>{t('interbranchService.name')}</Dialog.Title>
        <Dialog.Body className="w-224">
          <InterbranchServiceCommonFields
            control={control}
            setValue={setValue}
            disabled={!isEditingAllowed}
          />

          <InterbranchServiceByBranchList
            interbranchServiceById={interbranchServiceById}
            employeeGroups={employeeGroups}
            locations={locations}
            control={control}
            disabled={!isEditingAllowed}
            formTrigger={trigger}
            formGetFieldState={getFieldState}
            setValue={setValue}
          />
        </Dialog.Body>
        <Dialog.Footer>
          <SaveButton
            onClick={handleSubmit(onSubmit)}
            spinner={formState.isSubmitting}
            disabled={!isEditingAllowed || !formState.isDirty}
          />
          <CloseButton onClick={closePopups} />
        </Dialog.Footer>
      </Dialog>

      {confirmPopup}
    </Modal>
  )
}
