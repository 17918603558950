import { IconType } from 'react-icons'
import { TreeMenuSelectedItem, TreeMenuItem } from '.'
import { TreeItem, TreeItemProps } from './TreeItem'

interface TreeFolderProps<MTreeMenuItem extends TreeMenuItem>
  extends Omit<TreeItemProps<MTreeMenuItem>, 'isSelectedTreeItem'> {
  defaultCollapseIcon: IconType
  defaultExpandIcon: IconType
  isSelectedTreeFolder: boolean
  selectedItems: TreeMenuSelectedItem[]
  disabled: boolean
  singlePickMode: boolean
  openedFolders: MTreeMenuItem[]
  setOpenedFolders: (folders: MTreeMenuItem[]) => void
  isAlwaysOpen?: boolean
  showQuantity: boolean
  showHighlight: boolean
  priceConversionHandlerFunction?: (value: number) => string
}

export const TreeFolder = <MTreeMenuItem extends TreeMenuItem>({
  item,
  defaultCollapseIcon,
  defaultExpandIcon,
  Icon,
  isSelectedTreeFolder,
  onClick,
  onEditFolder,
  onEditItem,
  selectedItems,
  level,
  disabled,
  modalElement,
  singlePickMode,
  openedFolders,
  setOpenedFolders,
  isAlwaysOpen,
  focusedItem,
  setFocusedItem,
  showQuantity,
  isItemsTruncate,
  customInactiveReasonRender,
  showHighlight,
  priceConversionHandlerFunction,
}: TreeFolderProps<MTreeMenuItem>) => {
  const expanded =
    isAlwaysOpen ||
    openedFolders.find(folder => folder.id === item.id) ||
    (disabled && isSelectedTreeFolder)
  const flatItems = flatTreeFolderAndItems(item.nodes)

  const folderIcon = expanded ? defaultCollapseIcon : defaultExpandIcon

  const folderItemsLevel = level + 1

  const { id } = item

  let expandedFolderLineStyle = 'h-full w-px my-4 border-r'

  expandedFolderLineStyle += disabled ? ' border-gray-300' : ' border-primary-500'

  const onClickFolder = () => {
    if (expanded) setOpenedFolders(openedFolders.filter(folder => folder.id !== item.id))
    else setOpenedFolders([...openedFolders, item])
  }

  const onClickCheckmarkAll = singlePickMode || showQuantity ? undefined : onClick

  return (
    <div className="flex flex-col">
      <TreeItem
        key={id}
        modalElement={modalElement}
        item={item}
        Icon={folderIcon}
        onClickCheckmarkAll={onClickCheckmarkAll}
        onClick={disabled ? undefined : onClickFolder}
        onEditFolder={singlePickMode ? onEditFolder : undefined}
        isSelectedTreeItem={isSelectedTreeFolder}
        level={level}
        isHighlight={
          showHighlight
            ? selectedItems.some(
                s =>
                  flatItems.some(i => s.id === i.id) &&
                  flatItems.some(i => s.parentId === i.id || s.parentId === id),
              )
            : false
        }
        disabled={disabled}
        focusedItem={focusedItem}
        setFocusedItem={setFocusedItem}
        showQuantity={showQuantity}
        isItemsTruncate={isItemsTruncate}
        customInactiveReasonRender={customInactiveReasonRender}
        priceConversionHandlerFunction={priceConversionHandlerFunction}
      />

      {expanded && (
        <div className="flex flex-col relative">
          {item.nodes?.map(node => {
            const isSelected =
              isSelectedTreeFolder ||
              selectedItems.some(el => el.id === node.id && el.parentId === id)

            if (node.isFolder) {
              return (
                <TreeFolder
                  key={node.id}
                  modalElement={modalElement}
                  disabled={disabled}
                  item={{ ...node, parentId: id }}
                  Icon={Icon}
                  defaultCollapseIcon={defaultCollapseIcon}
                  defaultExpandIcon={defaultExpandIcon}
                  onClick={disabled ? undefined : onClick}
                  isSelectedTreeFolder={isSelected}
                  selectedItems={selectedItems}
                  level={folderItemsLevel}
                  setOpenedFolders={setOpenedFolders}
                  openedFolders={openedFolders}
                  singlePickMode={singlePickMode}
                  isAlwaysOpen={isAlwaysOpen}
                  focusedItem={focusedItem}
                  setFocusedItem={setFocusedItem}
                  showQuantity={showQuantity}
                  onEditFolder={onEditFolder}
                  onEditItem={onEditItem}
                  isItemsTruncate={isItemsTruncate}
                  customInactiveReasonRender={customInactiveReasonRender}
                  showHighlight={showHighlight}
                  priceConversionHandlerFunction={priceConversionHandlerFunction}
                />
              )
            } else {
              return (
                <TreeItem
                  key={node.id}
                  modalElement={modalElement}
                  item={{ ...node, parentId: id }}
                  Icon={Icon}
                  onClick={disabled ? undefined : onClick}
                  onEditItem={onEditItem}
                  isSelectedTreeItem={isSelected}
                  level={folderItemsLevel}
                  disabled={disabled}
                  focusedItem={focusedItem}
                  setFocusedItem={setFocusedItem}
                  showQuantity={showQuantity}
                  quantity={
                    selectedItems.find(el => el.id === node.id && el.parentId === id)?.quantity
                  }
                  isItemsTruncate={isItemsTruncate}
                  customInactiveReasonRender={customInactiveReasonRender}
                  priceConversionHandlerFunction={priceConversionHandlerFunction}
                />
              )
            }
          })}
          <div className={'h-full w-px ml-3 absolute flex flex-col' + getFolderLinePosition(level)}>
            <div className={expandedFolderLineStyle}></div>
          </div>
        </div>
      )}
    </div>
  )
}

const getFolderLinePosition = (level: number) => {
  return LinePosition[level]
}

const LinePosition = [
  ' top-0 left-0',
  ' top-0 left-4',
  ' top-0 left-8',
  ' top-0 left-12',
  ' top-0 left-16',
  ' top-0 left-20',
  ' top-0 left-24',
]

// используется для поиска выбранных дочерних элементов, по возможности переделать
const flatTreeFolderAndItems = (
  items: TreeMenuItem[] | undefined,
): Omit<TreeMenuItem, 'nodes'>[] => {
  if (items === undefined) return []
  const getNodesItems = (items: TreeMenuItem[]) => {
    return items
      .filter(el => el.nodes)
      .map(({ nodes, id }) => (nodes ? nodes.map(n => ({ ...n, parentId: id })) : []))
      .flat()
  }

  const notNodesItems = (items: TreeMenuItem[]) => {
    return items.map(i => ({ ...i, nodes: undefined }))
  }

  const result: Omit<TreeMenuItem, 'nodes'>[] = []
  let nodesItems: TreeMenuItem[] = [...items]

  while (nodesItems.length > 0) {
    result.push(...notNodesItems(nodesItems))
    nodesItems = getNodesItems(nodesItems)
  }

  return result
}
