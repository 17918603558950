import {
  ServerServiceBriefWithProductType,
  ServerServiceByIdType,
  useCreateService,
  useUpdateService,
} from '@expane/data'
import { useUpdateFile } from '@expane/logic/file'
import {
  getConsumablesDiff,
  getEmployeeIdsDiff,
  getIsServiceForSale,
  getLocationIdsDiff,
  getPermissionEmployeeDiff,
} from '@expane/logic/service'
import { CloseButton, ConfirmationArgs } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useAttachFunctionForModal } from 'logic/hooks/useAttachFunctionForModal'
import { OnCreateFunc } from 'logic/hooks/useOpenDialog'
import { FC, MutableRefObject } from 'react'
import { FormState, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import { DuplicateButton, SaveButton } from 'widgets/Buttons'
import { ServiceDialogFormData } from 'widgets/ServiceDialog/ServiceDialogLogic'
import { ServiceDuplicateConfirmation } from 'widgets/ServiceDialog/ServiceDuplicateConfirmation'
import { OnCreateServiceDto } from './'

interface ServiceDialogFooterProps {
  handleSubmit: UseFormHandleSubmit<ServiceDialogFormData>
  serviceById: ServerServiceByIdType | undefined
  services: ServerServiceBriefWithProductType[]
  branchId: number
  branchLocationId: number
  closeDialog: () => void
  closePopups: () => void
  isEditingAllowed: boolean
  isCreate: boolean
  formState: FormState<ServiceDialogFormData>
  disabledCopy: boolean
  showConfirmation: (dto: ConfirmationArgs) => void
  onSubmitForm: MutableRefObject<(() => void) | undefined>
  onCreate?: OnCreateFunc<OnCreateServiceDto>
}

export const ServiceDialogFooter: FC<ServiceDialogFooterProps> = ({
  handleSubmit,
  serviceById,
  services,
  branchId,
  branchLocationId,
  closeDialog,
  closePopups,
  disabledCopy,
  formState,
  isEditingAllowed,
  isCreate,
  showConfirmation,
  onSubmitForm,
  onCreate,
}) => {
  const { t } = useTranslation()

  const { mutateAsync: createMutation } = useCreateService()
  const { mutateAsync: updateMutation } = useUpdateService()

  const [openSnackbar] = useSnackbar()

  const updatePhoto = useUpdateFile()

  const mutateService: SubmitHandler<ServiceDialogFormData> = async data => {
    const mutate = async (data: ServiceDialogFormData) => {
      const {
        isItCopy,
        defaultDuration,
        description,
        name,
        price,
        costPrice,
        employees,
        locations,
        type,
        picture,
        groupId,
        servicePermissions,
        availableForClient,
      } = data

      const pictureUrl = await updatePhoto({
        prevFile: serviceById?.picture ?? undefined,
        file: picture,
      })

      const employeesWithoutGroups = employees ? extractItemsFromFolders(employees) : []
      const employeesIds = employeesWithoutGroups.map(employee => employee.id)

      const locationsIds = getIsServiceForSale(type)
        ? [branchLocationId]
        : // локация должна быть обязательно в таком случае
          extractItemsFromFolders(locations ?? []).map(location => location.id)

      const permissionEmployeesForServer = extractItemsFromFolders(servicePermissions ?? [])
      const duration = getIsServiceForSale(type) ? 15 : defaultDuration

      if (isCreate || isItCopy) {
        const result = await createMutation({
          name,
          defaultDuration: Number(duration),
          description,
          price: Number(price),
          costPrice: Number(costPrice),
          picture: pictureUrl,
          groupId,
          branchId,
          color: data.color ?? null,
          serviceEmployees: { data: employeesIds.map(employeeId => ({ employeeId, branchId })) },
          serviceLocations: { data: locationsIds.map(locationId => ({ locationId, branchId })) },
          servicePermissions: {
            data: permissionEmployeesForServer.map(i => ({ employeeId: i.id })),
          },
          serviceProducts: {
            data: data.consumables.map(consumable => {
              return {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                productId: consumable.productId!,
                quantity: Number(consumable.quantity),
                branchId,
              }
            }),
          },
          type,
          availableForClient,
        })

        if (result?.insertService?.id) {
          openSnackbar(t('service.createdSuccessfully'), 'success')
          onCreate?.(result.insertService.id, { name, groupId: groupId ?? undefined })
        } else openSnackbar(t('submitError'), 'error')
      } else if (serviceById) {
        const { serviceEmployeeIdsToDelete, employeeIdsToInsert } = getEmployeeIdsDiff(
          serviceById.serviceEmployees,
          employeesIds,
        )
        const { locationIdsToDelete, locationIdsToInsert } = getLocationIdsDiff(
          serviceById.serviceLocations,
          locationsIds,
        )
        const permissionEmployeesForServerIds = permissionEmployeesForServer.map(({ id }) => id)

        const { permissionEmployeeIdsToInsert, permissionEmployeeIdsToDelete } =
          getPermissionEmployeeDiff(serviceById, permissionEmployeesForServerIds)
        const { consumablesToInsert, consumableIdsToDelete } = getConsumablesDiff(
          serviceById.serviceProducts,
          data.consumables,
        )

        const result = await updateMutation({
          id: serviceById.id,
          serviceSetInput: {
            name,
            description,
            price: Number(price),
            costPrice: Number(costPrice),
            defaultDuration: Number(duration),
            picture: pictureUrl ?? null,
            groupId: groupId ?? null,
            branchId,
            color: data.color ?? null,
            type,
            availableForClient,
          },
          serviceEmployeeInsertInputs: employeeIdsToInsert.map(employeeId => ({
            employeeId,
            serviceId: serviceById.id,
            branchId,
          })),
          serviceEmployeeIdsToDelete,
          serviceLocationInsertInputs: locationIdsToInsert.map(locationId => ({
            locationId,
            serviceId: serviceById.id,
            branchId,
          })),
          locationIdsToDelete,
          servicePermissionInsertInputs: permissionEmployeeIdsToInsert.map(employeeId => ({
            employeeId,
            serviceId: serviceById.id,
          })),
          permissionEmployeeIdsToDelete,
          serviceProductInsertInputs: consumablesToInsert.map(consumable => {
            return {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              productId: consumable.productId!,
              quantity: Number(consumable.quantity),
              serviceId: serviceById.id,
              branchId,
            }
          }),
          consumableIdsToDelete,
        })

        if (result?.updateServiceById?.id) openSnackbar(t('service.updatedSuccessfully'), 'success')
        else openSnackbar(t('submitError'), 'error')
      }

      closeDialog()
    }

    const duplicateServices = getDuplicatedServices({
      isCreate,
      services,
      currentServiceId: serviceById?.id,
      serviceName: data.name,
    })
    if (duplicateServices.length > 0 && data.name !== serviceById?.name) {
      showConfirmation({
        title: t('saveConfirmation'),
        description: (
          <ServiceDuplicateConfirmation duplicateServices={duplicateServices} name={data.name} />
        ),
        onConfirm: () => mutate(data as ServiceDialogFormData),
      })
    } else {
      return mutate(data as ServiceDialogFormData)
    }
  }

  useAttachFunctionForModal(() => {
    if (isEditingAllowed && !formState.isSubmitting && formState.isDirty)
      handleSubmit(mutateService)()
  }, onSubmitForm)

  return (
    <>
      {isEditingAllowed && (
        <>
          <SaveButton
            onClick={handleSubmit(mutateService)}
            disabled={formState.isSubmitting || !formState.isDirty}
            spinner={formState.isSubmitting}
            isCreate={isCreate}
          />
          {!isCreate && (
            <DuplicateButton
              onClick={handleSubmit(data => {
                return mutateService({
                  ...data,
                  isItCopy: true,
                })
              })}
              disabled={disabledCopy}
            />
          )}
        </>
      )}

      <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />
    </>
  )
}

const getDuplicatedServices = (dto: {
  isCreate: boolean
  serviceName: string
  currentServiceId: number | undefined
  services: ServerServiceBriefWithProductType[]
}) => {
  const { isCreate, serviceName, services, currentServiceId } = dto
  if (isCreate) {
    return services.filter(el => el.name.toLowerCase() === serviceName.toLowerCase())
  } else {
    return services.filter(
      el => el.name.toLowerCase() === serviceName.toLowerCase() && el.id !== currentServiceId,
    )
  }
}
