import { ServerCardType } from '../../generated/graphql-types'
import { reportError, reportGqlError, request, useQuery } from '../../api'
import { gql } from 'graphql-request'
import { cardFragment } from './card.fragments'
import { TRANSACTIONS_QUERY_KEY } from '../transaction/queryKeys'
import { GiftCardType, parseDatesInCardGqlResponse } from './logic'
import { DEFAULT_TIMEZONE } from '@expane/date'
import { CLIENTS_QUERY_KEY } from '../client'
import { CARD_TYPES } from '../cardTemplate'
import { CARDS_QUERY_KEY } from './queryKeys'

export type SubscriptionInfo = Record<string, { used: number; quantity: number }>

export interface CardType extends ServerCardType {
  subscriptionInfo: SubscriptionInfo
}

export function useFetchCardsByClientId(
  clientId: number | undefined | null,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<CardType[]>(
    [CARDS_QUERY_KEY, TRANSACTIONS_QUERY_KEY, { clientId, branchId }],
    async () => {
      const result = await request(
        gql`
          ${cardFragment}
          query ($clientId: Int!, $branchId: Int!) {
            cards(
              order_by: { createdAt: desc }
              where: {
                clientId: { _eq: $clientId }
                cardTemplate: {
                  _or: [{ branchId: { _eq: $branchId } }, { availableToAllBranches: { _eq: true } }]
                }
              }
            ) {
              ...cardType
            }
          }
        `,
        { clientId, branchId },
      )

      return result.cards.map(card =>
        parseDatesInCardGqlResponse(card, timezone ?? DEFAULT_TIMEZONE),
      )
    },
    {
      queryName: 'useFetchCardsByClientId',
      onError: reportGqlError,
      enabled: Boolean(clientId) && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchSubscriptionsByClientId(
  clientId: number | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<CardType[]>(
    [CLIENTS_QUERY_KEY, CARDS_QUERY_KEY, CARDS_QUERY_KEY, { clientId, branchId }],
    async () => {
      const result = await request(
        gql`
          ${cardFragment}
          query ($clientId: Int!, $branchId: Int!) {
            cards(
              where: {
                cardTemplate: {
                  _or: [{ branchId: { _eq: $branchId } }, { availableToAllBranches: { _eq: true } }]
                }
                clientId: { _eq: $clientId }
                canceledDate: { _is_null: true }
              }
            ) {
              ...cardType
            }
          }
        `,
        { clientId, branchId },
      )

      if (result?.cards) {
        return result.cards
          .filter(card => card.type === CARD_TYPES.subscription)
          .map(card => parseDatesInCardGqlResponse(card, timezone ?? DEFAULT_TIMEZONE))
      }

      reportError(new Error('Error while trying to get clientCards'), 'error', {
        result,
        clientId,
      })
      return undefined
    },
    {
      queryName: 'useFetchSubscriptionsByClientId',
      enabled: Boolean(clientId) && Boolean(timezone) && Boolean(branchId),
      onError: reportGqlError,
    },
  )
}

export function useFetchNotActivatedGiftCards(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<GiftCardType[]>(
    [CLIENTS_QUERY_KEY, CARDS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${cardFragment}
          query ($branchId: Int!) {
            cards(
              where: {
                cardTemplate: { branchId: { _eq: $branchId } }
                canceledDate: { _is_null: true }
                activatedAt: { _is_null: true }
              }
            ) {
              ...cardType
            }
          }
        `,
        { branchId },
      )

      if (result?.cards) {
        return result.cards
          .filter(card => card.type === CARD_TYPES.giftCard)
          .map(card => parseDatesInCardGqlResponse(card, timezone ?? DEFAULT_TIMEZONE))
      }

      reportError(new Error('Error while trying to get clientCards'), 'error', {
        result,
      })
      return undefined
    },
    {
      queryName: 'useFetchNotActivatedGiftCards',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchNotActivatedGiftCardsByClientId(
  clientId: number,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<GiftCardType[]>(
    [CARDS_QUERY_KEY, { clientId, branchId }],
    async () => {
      const result = await request(
        gql`
          ${cardFragment}
          query ($clientId: Int!, $branchId: Int!) {
            cards(
              where: {
                cardTemplate: { branchId: { _eq: $branchId } }
                canceledDate: { _is_null: true }
                clientId: { _eq: $clientId }
                activatedAt: { _is_null: true }
              }
            ) {
              ...cardType
            }
          }
        `,
        { branchId, clientId },
      )

      if (result?.cards) {
        return result.cards
          .filter(card => card.type === CARD_TYPES.giftCard)
          .map(card => parseDatesInCardGqlResponse(card, timezone ?? DEFAULT_TIMEZONE))
      }

      reportError(new Error('Error while trying to get clientCards'), 'error', {
        result,
      })
      return undefined
    },
    {
      queryName: 'useFetchNotActivatedGiftCards',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}
