export const getCommonInputStyles = (error: boolean | undefined, disabled: boolean | undefined) => {
  let styles = ' text-main-color placeholder-color '

  styles += disabled ? ' bg-input-disabled ' : ' bg-input '

  if (error) styles += ' border-input-error-color '
  else styles += disabled ? ' border-input-disabled-color ' : ' border-input-color '

  return styles
}

export const getButtonHeightStyle = (height?: 'small' | 'normal' | 'medium') => {
  let btnStyle = ''

  if (height === 'normal') btnStyle += ' h-9.5 '
  if (height === 'medium') btnStyle += ' h-8 '
  if (height === 'small') btnStyle += ' h-7 '

  return btnStyle
}

export const getCommonSelectButtonStyle = ({
  isError,
  height = 'normal',
  isOpen,
  isDisabled,
}: {
  height?: 'small' | 'normal' | 'medium'
  isDisabled: boolean
  isError: boolean
  isOpen: boolean
}) => {
  let filterBtnStyle =
    'flex items-center cursor-pointer justify-between block w-full border-2 rounded-lg text-sm transition-all px-2'

  filterBtnStyle += getButtonHeightStyle(height)

  if (isDisabled) {
    filterBtnStyle +=
      ' border-input-disabled-color bg-input-disabled pointer-events-none input-text-color '
  } else {
    filterBtnStyle += ' bg-input '
    if (isOpen) {
      filterBtnStyle += ' text-primary-500 border-primary-500'
    } else {
      filterBtnStyle += isError ? ' text-error-500 border-input-error-color' : ' border-input-color'
    }
  }

  return filterBtnStyle
}

export const commonSelectIconStyle = 'shrink-0 ml-2 text-gray-200 hover:text-primary-500'
export const commonSelectClearIconStyle = 'shrink-0 ml-2 text-gray-200 hover:text-error-600'
