import { gql } from 'graphql-request'
import { reportGqlError, request, useQueryOriginal } from '../../api'
import { subscriptionPeriodFragment } from './subscriptionPeriod.fragments'
import { SUBSCRIPTION_PERIODS_QUERY_KEY } from './queryKeys'
import { SubscriptionPeriodType } from './logic'

export function useFetchSubscriptionPeriods() {
  return useQueryOriginal<SubscriptionPeriodType[]>(
    [SUBSCRIPTION_PERIODS_QUERY_KEY],
    async () => {
      const result = await request(gql`
        ${subscriptionPeriodFragment}
        query {
          subscriptionPeriods {
            ...subscriptionPeriodType
          }
        }
      `)

      return result.subscriptionPeriods
    },
    {
      onError: reportGqlError,
    },
  )
}
