import { ServerBookingInsertInput } from '@expane/data'
import { AdditionalBookingDto } from 'store/MultiBooking'

export type BookingInsertInputWithSpecialId = ServerBookingInsertInput & {
  // используется для key при отображении
  specialId: number
}

export const getAllBookings = (
  firstBooking: ServerBookingInsertInput | null,
  additionalBookings: AdditionalBookingDto[],
): BookingInsertInputWithSpecialId[] | undefined => {
  if (firstBooking === null) return

  const fullAdditionalBookings: BookingInsertInputWithSpecialId[] = additionalBookings.map(
    booking => ({
      ...firstBooking,
      startDate: booking.date,
      locationId: booking.locationId ?? firstBooking?.locationId,
      employeeId: booking.employeeId ?? firstBooking?.employeeId,
      specialId: booking.specialId,
    }),
  )

  return [{ ...firstBooking, specialId: 0 }, ...fullAdditionalBookings]
}
