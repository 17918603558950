import { FC } from 'react'
import { CashBoxInfoItem } from 'pages/FinancesPage/FinancesInfoBlock'
import { ServerAccountType, ServerTransactionType } from '@expane/data'
import { useTranslation } from 'react-i18next'
import { calcAllExpensesOnAccount } from '@expane/logic/finances/calculation'
import { PropsWithBranchId } from '@expane/logic/branch'

interface ExpensesValuesProps {
  accounts?: ServerAccountType[]
  transactions: ServerTransactionType[]
  refundsValue: number
  dailyExpensesWithoutRefundsValue: number
}

export const ExpensesValues: FC<PropsWithBranchId<ExpensesValuesProps>> = ({
  accounts,
  transactions,
  refundsValue,
  dailyExpensesWithoutRefundsValue,
  branchId,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ul className={listStyle}>
        {refundsValue !== 0 ? (
          <CashBoxInfoItem
            title={t('returns')}
            value={refundsValue}
            className={additionalStyle}
            branchId={branchId}
          />
        ) : null}

        {dailyExpensesWithoutRefundsValue !== 0 ? (
          <CashBoxInfoItem
            title={t('otherExpenses')}
            value={dailyExpensesWithoutRefundsValue}
            className={additionalStyle}
            branchId={branchId}
          />
        ) : null}
      </ul>

      <ul className={listStyle}>
        {refundsValue !== 0 || dailyExpensesWithoutRefundsValue !== 0 ? (
          <li className="text-error-700 font-medium mb-2">{t('accounts')}</li>
        ) : null}

        {accounts?.map(account => (
          <ExpensesValuesOfDayByAccount
            key={account.id}
            id={account.id}
            name={account.name}
            transactions={transactions}
            branchId={branchId}
          />
        ))}
      </ul>
    </>
  )
}

interface CashBoxAccountsTotalExpensesValuesOfDayProps {
  id: number
  name: string
  transactions: ServerTransactionType[]
}

const ExpensesValuesOfDayByAccount: FC<
  PropsWithBranchId<CashBoxAccountsTotalExpensesValuesOfDayProps>
> = ({ id, name, transactions, branchId }) => {
  const value = calcAllExpensesOnAccount(id, transactions)

  if (value === 0) return null

  return (
    <CashBoxInfoItem title={name} value={value} className={additionalStyle} branchId={branchId} />
  )
}

const listStyle = 'text-sm ml-2 mb-2'
const additionalStyle = 'text-error-600'
