import {
  ServerUpdateModulesSettingsTypes,
  UpdateModulesSettingsResponseCodes,
  useUpdateBusinessModulesSettings,
} from '@expane/data'
import {
  getModulesKeysThatAreUnUpdatable,
  ModuleSettingsForm,
  useBusinessModulesSettings,
} from '@expane/logic/modules'
import { Spinner, useShowConfirmationPopup, useShowWarningPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { ModulesSettingsTable } from 'pages/SettingsPage/BusinessModulesSettings/ModulesSettingsTable'
import { SettingsWrapper } from 'pages/SettingsPage/index'
import { FC, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/sentry'
import { ApplyButton } from 'widgets/Buttons'

export const BusinessModulesSettings: FC = () => {
  const { isLoadingBusinessModulesSettings } = useBusinessModulesSettings()

  if (isLoadingBusinessModulesSettings) return <Spinner expandCentered />

  return <BusinessModuleSettingsLogic />
}

export const BusinessModuleSettingsLogic: FC = () => {
  const { t } = useTranslation()

  const { getModuleSetting } = useBusinessModulesSettings()
  const { mutateAsync: mutateUpdateBusinessModulesSettings } = useUpdateBusinessModulesSettings()

  const {
    formState: { isDirty, isSubmitting },
    control,
    handleSubmit,
    reset,
  } = useForm<ModuleSettingsForm>({
    defaultValues: {
      managingEmployee: getModuleSetting('managingEmployee'),
      branches: getModuleSetting('branches'),
      subscriptions: getModuleSetting('subscriptions'),
      giftCards: getModuleSetting('giftCards'),
      servicesForSale: getModuleSetting('servicesForSale'),
      productsForSale: getModuleSetting('productsForSale'),
      consumables: getModuleSetting('consumables'),
      checkbox: getModuleSetting('checkbox'),
    },
  })

  const checkbox = getModuleSetting('checkbox')
  const watchedCheckbox = useWatch({ control, name: 'checkbox' })

  const [openSnackBar] = useSnackbar()

  const [unUpdatableModules, setUnUpdatableModules] = useState<string[] | null>(null)

  const warningContent = (
    <div className="max-w-2xl">
      <p>
        {t('modules.warningMessage')}:{' '}
        <span className="italic">{unUpdatableModules?.map(key => t(key)).join(', ')}</span>
      </p>
    </div>
  )

  const { warningModal, showWarningPopup } = useShowWarningPopup(t('error'), warningContent)

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const preUpdateChecksHandler = () => {
    if (checkbox && watchedCheckbox === false)
      showConfirmation({
        title: t('warning'),
        description: t('modules.checkbox.confirmation'),
        onConfirm: handleSubmit(handleUpdatingModuleSettings),
      })
    else handleSubmit(handleUpdatingModuleSettings)()
  }

  const handleUpdatingModuleSettings: SubmitHandler<ModuleSettingsForm> = async data => {
    try {
      const result = await mutateUpdateBusinessModulesSettings({
        type: ServerUpdateModulesSettingsTypes.Update,
        modules: data,
      })

      if (result.updateModulesSettings.modulesSettingsThatCanUpdate) {
        const unUpdatableModulesKeys = getModulesKeysThatAreUnUpdatable(
          result.updateModulesSettings,
        )

        setUnUpdatableModules(unUpdatableModulesKeys)
        showWarningPopup()

        return
      }

      if (result.updateModulesSettings?.code === UpdateModulesSettingsResponseCodes.success) {
        reset({}, { keepValues: true })
        openSnackBar(t('changesSaved'), 'success', 3000)
      } else openSnackBar(t('submitError'), 'error', 3000)
    } catch (e) {
      reportError(e as Error)
      openSnackBar(t('submitError'), 'error', 3000)
    }
  }

  return (
    <SettingsWrapper>
      <ModulesSettingsTable control={control} />

      <ApplyButton
        className="w-40 mt-4"
        disabled={!isDirty || isSubmitting}
        spinner={isSubmitting}
        onClick={preUpdateChecksHandler}
      />
      {warningModal}
      {confirmationModal}
    </SettingsWrapper>
  )
}
