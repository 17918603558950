import i18next from 'i18next'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HorizontalButtonSwitch,
  HorizontalButtonSwitchItem,
  HorizontalButtonSwitchProps,
} from '../HorizontalButtonSwitch'
import { EnFlag, RuFlag, UkFlag } from './flags'

export interface LanguageSwitchProps extends Pick<HorizontalButtonSwitchProps, 'size'> {
  onChange: (id: string) => void
  withLabel?: boolean
  containerClassName?: string
}

export const LanguageSwitch: FC<LanguageSwitchProps> = ({
  withLabel = false,
  containerClassName,
  onChange,
  size,
}) => {
  const { t } = useTranslation()

  return (
    <HorizontalButtonSwitch
      label={withLabel ? t('language') : undefined}
      options={languageOptions}
      value={i18next.language}
      onChange={onChange}
      containerClassName={containerClassName}
      size={size}
    />
  )
}

const languageOptions: HorizontalButtonSwitchItem[] = [
  {
    id: 'en',
    name: 'English',
    ComponentAsIcon: EnFlag,
  },
  {
    id: 'uk',
    name: 'Українська',
    ComponentAsIcon: UkFlag,
  },
  {
    id: 'ru',
    name: 'Русский',
    ComponentAsIcon: RuFlag,
  },
]

export * from './flags'
