import { gql } from 'graphql-request'
import { accountSoftwarePOSFragment, softwarePOSFragment } from './softwarePOS.fragments'
import { LONG_STALE_TIME, reportError, reportGqlError, request, useQuery } from '../../api'
import {
  ServerAccountSoftwarePosType,
  ServerCheckboxGetShiftsResult,
  ServerSoftwarePos,
  ServerSoftwarePosType,
} from '../../generated/graphql-types'
import { getReceiptPngUrl } from './logic'
import {
  ACCOUNT_SOFTWARE_POSS_QUERY_KEY,
  RECEIPT_QUERY_KEY,
  SOFTWARE_POSS_QUERY_KEY,
  SOFTWARE_POSS_SHIFT_QUERY_KEY,
} from './queryKeys'
import { CloudFunctionResult } from '../responses'

export const useFetchSoftwarePOSs = (branchId: number | undefined) => {
  return useQuery<ServerSoftwarePos[]>(
    [SOFTWARE_POSS_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          query ($branchId: Int!) {
            softwarePOSs(where: { branchId: { _eq: $branchId } }, order_by: { id: asc }) {
              id
              name
              login
              password
              token
              licenseKey
              authorId
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.softwarePOSs)) {
        return result.softwarePOSs
      }

      reportError(new Error('softwarePOSs is not an array'), 'error', { result })
      return []
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchSoftwarePOSs',
      onError: reportGqlError,
    },
  )
}

export const useFetchSoftwarePOSById = (id: number | undefined) => {
  return useQuery<ServerSoftwarePosType>(
    [SOFTWARE_POSS_QUERY_KEY, { id }],
    async () => {
      const result = await request(
        gql`
          ${softwarePOSFragment}
          query ($id: Int!) {
            softwarePOSById(id: $id) {
              ...softwarePOSType
            }
          }
        `,
        { id },
      )

      if (result.softwarePOSById) return result.softwarePOSById

      reportError(new Error('softwarePOSById does not exist'), 'error', { result })
    },
    {
      queryName: 'useFetchSoftwarePOSById',
      onError: reportGqlError,
      enabled: Boolean(id),
    },
  )
}

export const useFetchShiftStatusBySoftwarePOSId = (softwarePOSId: number | undefined) => {
  return useQuery<CloudFunctionResult & Pick<ServerCheckboxGetShiftsResult, 'openedAt'>>(
    [SOFTWARE_POSS_SHIFT_QUERY_KEY, { softwarePOSId }],
    async () => {
      const result = await request(
        gql`
          query ($softwarePOSId: Int!) {
            checkboxGetShifts(softwarePOSId: $softwarePOSId) {
              code
              message
              openedAt
            }
          }
        `,
        { softwarePOSId },
      )

      if (result.checkboxGetShifts) return result.checkboxGetShifts

      reportError(new Error('checkboxGetShifts does not exist'), 'error', { result })
    },
    {
      queryName: 'useFetchShiftStatusBySoftwarePOSId',
      onError: reportGqlError,
      enabled: Boolean(softwarePOSId),
    },
  )
}

export const useFetchAccountSoftwarePOSs = (branchId: number | undefined) => {
  return useQuery<ServerAccountSoftwarePosType[]>(
    [ACCOUNT_SOFTWARE_POSS_QUERY_KEY],
    async () => {
      const result = await request(
        gql`
          ${accountSoftwarePOSFragment}
          query ($branchId: Int!) {
            accountSoftwarePOSs(where: { softwarePOS: { branchId: { _eq: $branchId } } }) {
              ...accountSoftwarePOSType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result.accountSoftwarePOSs)) return result.accountSoftwarePOSs

      reportError(new Error('softwarePOSById does not exist'), 'error', { result })
      return []
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchAccountSoftwarePOSs',
      onError: reportGqlError,
    },
  )
}

export const useFetchReceiptPngById = (receiptId: string) => {
  return useQuery(
    [RECEIPT_QUERY_KEY, { receiptId }],
    async () => {
      const response = await fetch(getReceiptPngUrl(receiptId))
      if (!response.ok) {
        throw new Error('Error on fetch checkbox receipt by id')
      }

      return await response.blob()
    },
    {
      queryName: 'useFetchReceiptPdfById',
      onError: reportGqlError,
      staleTime: LONG_STALE_TIME,
    },
  )
}
