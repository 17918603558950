/* eslint-disable no-restricted-syntax */

// User can disable localStorage in the browser
export const safeWebLocalStorage: Storage = {
  getItem(key: string): string | null {
    try {
      return localStorage.getItem(key)
    } catch (error) {
      return null
    }
  },
  setItem(key: string, value: string): void {
    try {
      localStorage.setItem(key, value)
    } catch (error) {}
  },
  removeItem(key: string): void {
    try {
      localStorage.removeItem(key)
    } catch (error) {}
  },
  key(index: number): string | null {
    try {
      return localStorage.key(index)
    } catch (error) {
      return null
    }
  },
  get length() {
    try {
      return localStorage.length
    } catch (error) {
      return 0
    }
  },
  clear(): void {
    try {
      localStorage.clear()
    } catch (error) {}
  },
}
