import {
  useFetchBookingById,
  useFetchCurrentBranchTimezone,
  useUpdateBookingStatus,
} from '@expane/data'
import {
  BOOKING_STATUSES,
  checkIsSalaryIssuedByBooking,
  getIsBookingDone,
} from '@expane/logic/booking'
import { permissions } from '@expane/logic/permission'
import { Checkbox, useShowConfirmationPopup, useShowWarningPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useCurrentTime } from 'logic/hooks/useCurrentTime'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { checkBusinessIsNotPaid } from '@expane/logic/business'
import { useFetchCurrentBusiness } from 'gql/business'

type Props = {
  bookingId: number
  isDirty: boolean
  salarySettingsWarning: boolean
}

export const DoneStatusCheckbox: FC<Props> = ({ bookingId, isDirty, salarySettingsWarning }) => {
  const [showSnackbar] = useSnackbar()
  const { t } = useTranslation()

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const currentTime = useCurrentTime()

  const { showWarningPopup, warningModal } = useShowWarningPopup(
    t('booking.name'),
    t('needToSaveChanges'),
  )

  const { data: groupBookingById } = useFetchBookingById(bookingId, timezone, branchId)
  const { mutateAsync: updateBookingStatus } = useUpdateBookingStatus()

  const { data: myPermissions } = useFetchMyPermissions()

  const { data: currentBusiness } = useFetchCurrentBusiness()

  const isSalaryIssued = checkIsSalaryIssuedByBooking(groupBookingById)
  const isBookingDone = getIsBookingDone(groupBookingById)

  const disabledSetStatus = !myPermissions?.includes(permissions.bookingStatus.set)
  const disabledUnsetStatus =
    !myPermissions?.includes(permissions.bookingStatus.unset) || isSalaryIssued

  const handleConfirm = async () => {
    const status = isBookingDone ? BOOKING_STATUSES.booking : BOOKING_STATUSES.done

    const result = await updateBookingStatus({ id: bookingId, status })

    if (!result?.updateBookingStatuses.affectedRows) {
      showSnackbar(t('submitError'), 'error')
    }
  }

  const handleChangeStatus = () => {
    if (isDirty) {
      showWarningPopup()
      return
    } else {
      if (checkBusinessIsNotPaid(currentBusiness)) showSnackbar(t('planInfo.noPlan'), 'error')
      else {
        const nameOfStatus = isBookingDone ? t('statusItems.booking') : t('statusItems.done')

        const description = salarySettingsWarning
          ? `${t('employeeSalaryAlert')}.
${t('employeeSalaryAlertEffects')}.
${t('changeStatus.confirmation', { nameOfStatus })}`
          : t('changeStatus.confirmation', { nameOfStatus })

        showConfirmation({
          title: t('changeStatus.title'),
          description,
          onConfirm: () => handleConfirm(),
        })
      }
    }
  }

  const disabledByTime = groupBookingById && groupBookingById?.startDate > currentTime

  return (
    <>
      <Checkbox
        containerClassName="mt-2 ml-auto"
        label={t('statusItems.done')}
        checked={isBookingDone}
        onChange={handleChangeStatus}
        disabled={disabledSetStatus || (disabledUnsetStatus && isBookingDone) || disabledByTime}
      />
      {confirmationModal}
      {warningModal}
    </>
  )
}
