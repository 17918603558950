import {
  BranchWithSchedule,
  useFetchSuppliersBriefForImport,
  useImportSupplier,
} from '@expane/data'
import { CloseButton, Dialog, Modal, Paper, SelectDropdown, Spinner } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useBranchImportState } from 'logic/hooks/useBranchImportState'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { TreeMenu } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { onChangeMultiTreeMenu } from 'ui/TreeMenu/logic.onChange'
import {
  SuppliersTreeMenuItem,
  transformItemForMutation,
} from 'widgets/BranchImportSuppliersDialog/logic'
import { ImportButton } from 'widgets/Buttons'

interface ImportSuppliersDialogLogicProps extends Pick<DialogProps, 'closeDialog'> {
  branches: BranchWithSchedule[]
}

export const BranchImportSuppliersDialogLogic: FC<ImportSuppliersDialogLogicProps> = ({
  closeDialog,
  branches,
}) => {
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!

  const { selectedBranchId, setSelectedBranchId, branchesWithoutCurrent } =
    useBranchImportState(branches)

  const { data: suppliers, isFetching } = useFetchSuppliersBriefForImport(selectedBranchId)

  const { mutateAsync: importSuppliers } = useImportSupplier()

  const [openSnackbar] = useSnackbar()

  const [selectedItems, setSelectedItems] = useState<SuppliersTreeMenuItem[]>([])

  const suppliersTreeItems: SuppliersTreeMenuItem[] = useMemo(
    () => transformDataForTreeMenu([], suppliers),
    [suppliers],
  )

  const mutateImportSuppliers = async () => {
    const result = await importSuppliers(transformItemForMutation(selectedItems, branchId))

    if (result.insertSuppliers?.affectedRows)
      openSnackbar(t('importSuppliers.importingSuccess'), 'success', 3000)
    else openSnackbar(t('submitError'), 'error', 3000)

    closeDialog()
  }

  return (
    <Modal close={closeDialog} animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>{t('importSuppliers.name')}</Dialog.Title>
        <Dialog.Body className="flex flex-col h-120">
          {branchesWithoutCurrent.length > 1 ? (
            <SelectDropdown
              items={branchesWithoutCurrent}
              value={selectedBranchId}
              onSelectChange={value => {
                setSelectedItems([])
                setSelectedBranchId(value)
              }}
              label={t('branch.name')}
              className="mb-2"
            />
          ) : null}
          <Paper className={'flex-1 py-4 px-2 overflow-y-scroll'}>
            {isFetching ? (
              <Spinner expandCentered />
            ) : (
              <TreeMenu
                items={suppliersTreeItems}
                type="MultiPickMode"
                selected={selectedItems}
                onSelected={item =>
                  setSelectedItems(onChangeMultiTreeMenu(item, selectedItems, suppliersTreeItems))
                }
                className="mr-2 h-64"
              />
            )}
          </Paper>
        </Dialog.Body>
        <Dialog.Footer>
          <ImportButton onClick={mutateImportSuppliers} disabled={selectedItems.length === 0} />

          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
