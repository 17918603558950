import { AuthStore } from './auth'
import { BookingCalendarSettingsStore } from './BookingCalendarSettings'
import { BranchStore } from './branch'
import { MeStore } from './me'
import { MultiBookingStore } from './MultiBooking'
import { ServerStatusStore } from './serverStatus'
import { UnitedBookingUpdate } from './UnitedBookingUpdate'

export class Store {
  auth = new AuthStore()
  me = new MeStore()
  bookingSettings = new BookingCalendarSettingsStore()
  multiBooking = new MultiBookingStore()
  serverStatus = new ServerStatusStore()
  unitedBookingUpdate = new UnitedBookingUpdate()
  branch = new BranchStore(this)
}

export const store = new Store()
