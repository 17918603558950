import { formatTimeFromDate, parseNewTimeFromText } from '@expane/date'
import { FadeInTranslateTop, Input, modalsHTMLElement, usePopupOpenState } from '@expane/ui'
import { dateTimePickerPopupStyle, TimePickerPopup, transformToTimeFormat } from '@expane/widgets'
import { flip, offset, useFloating } from '@floating-ui/react-dom'
import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

interface TimeInputProps {
  timezone: string
  date: Date
  onChange: (date: Date) => void
  height?: 'small' | 'medium' | 'normal'
  className?: string
  portal?: boolean
}

export const TimeInput: FC<TimeInputProps> = ({
  timezone,
  height,
  date,
  onChange,
  className,
  portal = false,
}) => {
  const [textValue, setTextValue] = useState<string | undefined>()
  const { isOpen, closePopup, openPopup } = usePopupOpenState()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { reference, floating, strategy, x, y } = useFloating({
    middleware: [offset(5), flip()],
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  useEffect(() => {
    reference(inputRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, inputRef.current])

  const handleTimeChange = () => {
    const newDate = parseNewTimeFromText(textValue ?? '', date, timezone)
    onChange(newDate)
    setTextValue(undefined)
    closePopup()
  }

  const popup = (
    <div
      ref={floating}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }}
      onMouseDownCapture={e => e.preventDefault()} // prevents blurring the text input
    >
      <FadeInTranslateTop
        className={dateTimePickerPopupStyle}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="time-picker"
      >
        <TimePickerPopup
          timezone={timezone}
          value={date}
          onChange={date => {
            setTextValue(undefined)
            onChange(date)
          }}
          closeTimePopup={() => {
            closePopup()
            inputRef.current?.blur()
          }}
        />
      </FadeInTranslateTop>
    </div>
  )

  return (
    <>
      <Input
        ref={inputRef}
        value={textValue ?? formatTimeFromDate(date)}
        onFocus={openPopup}
        onChange={e => setTextValue(transformToTimeFormat(e.target.value))}
        onKeyDown={handleKeyDown}
        onBlur={handleTimeChange}
        containerClassName={className}
        height={height}
      />
      {isOpen && portal && createPortal(popup, modalsHTMLElement)}
      {isOpen && !portal && popup}
    </>
  )
}

const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
  if (e.code === 'Enter' || e.code === 'NumpadEnter') e.currentTarget.blur()
}
