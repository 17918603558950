import { useFetchBranchByIdBrief, useFetchProductsForConsumables } from '@expane/data'
import { usePopupOpenState } from '@expane/ui'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC, useRef } from 'react'
import {
  InterbranchServiceFormControl,
  InterbranchServiceFormGetFieldState,
  InterbranchServiceFormSetValue,
  InterbranchServiceFormTrigger,
} from 'widgets/InterbranchService/logic'
import { InterbranchServiceConsumableDialogLogic } from './InterbranchServiceConsumableDialogLogic'
import { InterbranchServiceConsumableDialogLogicPlaceholder } from './InterbranchServiceConsumableDialogLogicPlaceholder'

type OpenInterbranchServiceConsumableDialogParams = {
  branchId: number
  control: InterbranchServiceFormControl
  formTrigger: InterbranchServiceFormTrigger
  formGetFieldState: InterbranchServiceFormGetFieldState
  setValue: InterbranchServiceFormSetValue
  disabled: boolean
  index: number
}

export type InterbranchServiceConsumableDialogProps = Pick<DialogProps, 'closeDialog'> &
  OpenInterbranchServiceConsumableDialogParams

const InterbranchServiceConsumableDialog: FC<InterbranchServiceConsumableDialogProps> = ({
  branchId,
  closeDialog,
  control,
  disabled,
  index,
  formTrigger,
  formGetFieldState,
  setValue,
}) => {
  const { data: products, isLoading: isLoadingProducts } = useFetchProductsForConsumables(branchId)
  const { data: branch, isLoading: isLoadingBranch } = useFetchBranchByIdBrief(branchId)

  const isLoading = isLoadingBranch || isLoadingProducts
  const isNoData = !products || !branch

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

  if (isLoadingProducts)
    return <InterbranchServiceConsumableDialogLogicPlaceholder closeDialog={closeDialog} />
  else if (isNoData) return null

  return (
    <InterbranchServiceConsumableDialogLogic
      control={control}
      products={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        products!
      }
      closeDialog={closeDialog}
      branchId={branchId}
      disabled={disabled}
      index={index}
      formTrigger={formTrigger}
      formGetFieldState={formGetFieldState}
      branch={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        branch!
      }
      setValue={setValue}
    />
  )
}

export const useOpenInterbranchServiceConsumableDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const props = useRef<OpenInterbranchServiceConsumableDialogParams | null>(null)

  const openInterbranchServiceConsumableDialog = ({
    branchId,
    control,
    disabled,
    formGetFieldState,
    formTrigger,
    index,
    setValue,
  }: OpenInterbranchServiceConsumableDialogParams) => {
    props.current = { branchId, control, disabled, formGetFieldState, formTrigger, index, setValue }

    openPopup()
  }

  const interbranchServiceConsumableDialog =
    isOpen && props.current ? (
      <InterbranchServiceConsumableDialog closeDialog={closePopup} {...props.current} />
    ) : null

  return { openInterbranchServiceConsumableDialog, interbranchServiceConsumableDialog }
}
