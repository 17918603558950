import { NotificationSettings } from '@expane/data'
import { InputLabel, Paper } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@expane/ui'

interface NotificationSettingsWidgetProps {
  notificationSettings: NotificationSettings
  className?: string
}

export const NotificationSettingsInfo: FC<NotificationSettingsWidgetProps> = ({
  notificationSettings,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <InputLabel label={t('notificationSettings.name')} />
      <Paper className="p-2 max-w-fit">
        <Checkbox
          checked={notificationSettings.receivingMailing}
          label={t('notificationSettings.receivingMailing')}
          disabled
        />
        <Checkbox
          checked={notificationSettings.receivingOnCreate}
          label={t('notificationSettings.receivingOnCreate')}
          disabled
        />
        <Checkbox
          checked={notificationSettings.receivingOnChange}
          label={t('notificationSettings.receivingOnChange')}
          disabled
        />
        <Checkbox
          checked={notificationSettings.receivingOnCancel}
          label={t('notificationSettings.receivingOnCancel')}
          disabled
        />
      </Paper>
    </div>
  )
}
