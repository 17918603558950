import { FC } from 'react'
import { SettingsWrapper } from 'pages/SettingsPage'
import { useFetchBusinessLandingSettings, useUpdateBusinessLandingSettings } from '@expane/data'
import { Button, Spinner, Switch } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { BusinessLandingsLogic } from 'pages/SettingsPage/BusinessLandingSettings/BusinessLandingsLogic'
import { useSnackbar } from '@expane/widgets'
import { useTranslation } from 'react-i18next'
import { useOpenInstructionDialog } from 'pages/SettingsPage/BusinessLandingSettings/Instruction/useOpenInstructionDialog'

export const BusinessLandingSettings: FC = () => {
  const { data: businessLandingSettings, isLoading: isLoadingBusinessLandingSettings } =
    useFetchBusinessLandingSettings()
  const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()

  const { t } = useTranslation()

  const [openSnackBar] = useSnackbar()

  const { mutateAsync: mutateUpdateBusinessLandingSettings } = useUpdateBusinessLandingSettings()

  const { openInstructionDialog, instructionDialog } = useOpenInstructionDialog()

  const isLoading = isLoadingBusinessLandingSettings || isLoadingMyPermissions

  return (
    <SettingsWrapper>
      {isLoading ? (
        <Spinner expandCentered />
      ) : businessLandingSettings && myPermissions ? (
        <>
          <div className={'flex justify-between'}>
            <Switch
              containerClassName="mb-1"
              label={t('businessLandingSettings.enableLanding')}
              checked={businessLandingSettings.enabled}
              onChange={async isChecked => {
                const result = await mutateUpdateBusinessLandingSettings({
                  id: businessLandingSettings.id,
                  serverBusinessLandingSetInput: {
                    enabled: isChecked,
                  },
                })

                if (result.updateBusinessLandingById.id) {
                  openSnackBar(t('changesSaved'), 'success', 3000)
                } else openSnackBar(t('submitError'), 'error', 3000)
              }}
            />
            {businessLandingSettings.enabled && (
              <Button onClick={() => openInstructionDialog()}>{t('instruction')}</Button>
            )}
          </div>

          <BusinessLandingsLogic businessLandingSettings={businessLandingSettings} />
        </>
      ) : null}

      {instructionDialog}
    </SettingsWrapper>
  )
}
