import {
  useFetchClientById,
  useFetchCurrentBranchTimezone,
  useSendNotification,
} from '@expane/data'
import { transformPersonName } from '@expane/logic/utils'
import {
  Button,
  CloseButton,
  Dialog,
  InputLabel,
  Modal,
  PlaceholderString,
  Textarea,
  usePopupOpenState,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoPaperPlane } from 'react-icons/io5'
import { store } from 'store'
import { ExtendedSelectDropdownItem } from 'widgets/ClientDialog'
import {
  SelectPreferredConnections,
  transformFormValuesForMutation,
  transformPreferredConnectionsForForms,
} from 'widgets/ClientDialog/InfoTab/SelectPreferredConnections'

type SendMessageDialogProps = {
  clientId: number | undefined
  closeDialog: () => void
  textMessage?: string
}

const SendMessageDialog: FC<SendMessageDialogProps> = ({
  clientId,
  closeDialog,
  textMessage = '',
}) => {
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { isLoading } = useFetchClientById(clientId, timezone)

  if (isLoading || !timezone) return null

  return (
    <SendMessageDialogLogic
      clientId={clientId}
      closeDialog={closeDialog}
      textMessage={textMessage}
      timezone={timezone}
    />
  )
}

const SendMessageDialogLogic: FC<
  SendMessageDialogProps & {
    timezone: string
  }
> = ({ clientId, closeDialog, textMessage = '', timezone }) => {
  const { t } = useTranslation()

  const { data: client } = useFetchClientById(clientId, timezone)

  const { formState, control, handleSubmit } = useForm<SendMessageFormValues>({
    defaultValues: {
      message: textMessage,
      preferredConnections: client ? transformPreferredConnectionsForForms(client) : [],
    },
  })

  const { mutateAsync: sendNotification } = useSendNotification()

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackbar] = useSnackbar()

  const mutateSendMessage: SubmitHandler<SendMessageFormValues> = async ({
    message,
    preferredConnections,
  }) => {
    if (clientId) {
      const messengers = transformFormValuesForMutation({
        preferredConnections: preferredConnections as ExtendedSelectDropdownItem[],
      })

      const result = await sendNotification({ clientId, message, messengers })

      if (result.insertNotification?.id) {
        openSnackbar(t('message.sent'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else openSnackbar(t('submitError'), 'error')
    closeDialog()
  }

  if (!clientId) return null

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (!formState.isSubmitting) handleSubmit(mutateSendMessage)
        }}
      >
        <Dialog>
          <Dialog.Title>{t('message.send')}</Dialog.Title>

          <Dialog.Body>
            <div className="flex items-end pr-2">
              <InputLabel label={t('message.recipient') + ':'} />
              {client ? (
                <div className="ml-2 text-sm font-medium text-main-color">
                  {transformPersonName(client)}
                </div>
              ) : (
                <PlaceholderString />
              )}
            </div>

            <Controller
              name="preferredConnections"
              control={control}
              rules={{ required: true, validate: value => value.length > 0 }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <SelectPreferredConnections
                  onChange={onChange}
                  value={value as ExtendedSelectDropdownItem[]}
                  clientById={client}
                  required
                  errorMessage={{
                    isShown: Boolean(error),
                    text: t('formError.required'),
                  }}
                />
              )}
            />

            <Controller
              name="message"
              control={control}
              rules={{ required: true, validate: value => value.trim() !== '' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Textarea
                  label={t('message.name')}
                  onAction={() => {
                    if (!formState.isSubmitting) handleSubmit(mutateSendMessage)()
                  }}
                  placeholder={t('placeholders.sendMessage')}
                  value={value}
                  onChange={onChange}
                  required
                  errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                  autoFocus
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            <Button
              onClick={handleSubmit(mutateSendMessage)}
              disabled={formState.isSubmitting}
              spinner={formState.isSubmitting}
              Icon={IoPaperPlane}
            >
              {t('send')}
            </Button>

            <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {confirmPopup}
    </>
  )
}

type SendMessageFormValues = {
  message: string
  preferredConnections: ExtendedSelectDropdownItem[]
}

export const useOpenSendMessageDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const id = useRef<number | undefined>()
  const textMessage = useRef<string | undefined>()
  const onCloseFunction = useRef<() => void | undefined>()

  const openDialog = (clientId: number, message?: string, onClose?: () => void) => {
    textMessage.current = message
    onCloseFunction.current = onClose
    id.current = clientId
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseFunction.current) onCloseFunction.current()
  }

  const dialog = isOpen ? (
    <SendMessageDialog
      clientId={id.current}
      closeDialog={closeDialog}
      textMessage={textMessage.current}
    />
  ) : null

  return { dialog, openDialog }
}
