import { addMinutes, compareAsc, formatTimeFromDate } from '@expane/date'
import { BookingUnion, BookingWarningsTypes, ServerValidationResult } from '@expane/data'
import type { TFunction } from 'react-i18next'
import { transformPersonName } from '../utils'

export const checkBookingsOverlap = (
  newBooking: {
    startDate: Date
    endDate: Date
  },
  booking: {
    startDate: Date
    endDate: Date
  },
): boolean => {
  if (compareAsc(newBooking.startDate, booking.startDate) === 0) return true // bookings start at
  // the same time
  if (compareAsc(newBooking.endDate, booking.endDate) === 0) return true // bookings end at the
  // same time

  if (
    compareAsc(newBooking.startDate, booking.startDate) === 1 &&
    compareAsc(newBooking.startDate, booking.endDate) === -1
  )
    return true // new booking start in time of existing booking
  if (
    compareAsc(newBooking.endDate, booking.startDate) === 1 &&
    compareAsc(newBooking.endDate, booking.endDate) === -1
  )
    return true // new booking ends in time of existing booking

  if (
    compareAsc(booking.startDate, newBooking.startDate) === 1 &&
    compareAsc(booking.startDate, newBooking.endDate) === -1
  )
    return true // existing booking starts in time of new booking
  if (
    compareAsc(booking.endDate, newBooking.startDate) === 1 &&
    compareAsc(booking.endDate, newBooking.endDate) === -1
  )
    return true // existing booking ends in time of new booking

  return false
}

// пока что мы на моб никак не делаем валидацию по сервисам
// а на вебе у нас TreeMenu в котором добавляется warningMessage и поэтому параметру потом проверяем услугу
type ServicesDTOtoValidateType = {
  warningMessage?: string
  name: string
}

export type ValidatedBookingWarningType = {
  text: string
  type: number
}

export const generateMessageAfterValidation = ({
  warningType,
  t,
  plural = false,
  clientNames,
}: {
  warningType: number
  t: TFunction
  plural?: boolean
  clientNames?: string[]
}): ValidatedBookingWarningType[] => {
  const busyClientsMessage = clientNames ? ': ' + clientNames.join(', ') + '.' : undefined
  switch (warningType) {
    case BookingWarningsTypes.employeesNotWorking:
      return [
        {
          text: plural
            ? t('bookingValidation.employeesNotWorking')
            : t('bookingValidation.employeeNotWorking'),
          type: BookingWarningsTypes.employeesNotWorking,
        },
      ]
    case BookingWarningsTypes.employeesAreBusy:
      return [
        {
          text: plural
            ? t('bookingValidation.employeesAreBusy')
            : t('bookingValidation.employeeIsBusy'),
          type: BookingWarningsTypes.employeesAreBusy,
        },
      ]
    case BookingWarningsTypes.locationsAreFull:
      return [
        {
          text: plural
            ? t('bookingValidation.locationsAreFull')
            : t('bookingValidation.locationIsFull'),
          type: BookingWarningsTypes.locationsAreFull,
        },
      ]
    case BookingWarningsTypes.locationsAreFullForEmployees:
      return [
        {
          text: plural
            ? t('bookingValidation.locationIsFullForEmployees')
            : t('bookingValidation.locationIsFullForEmployees'),
          type: BookingWarningsTypes.locationsAreFullForEmployees,
        },
      ]
    case BookingWarningsTypes.clientHasBookings:
      return [
        {
          text: busyClientsMessage
            ? t('bookingValidation.clientsHaveBookings') + busyClientsMessage
            : t('bookingValidation.clientHasBookings'),
          type: BookingWarningsTypes.clientHasBookings,
        },
      ]
    case BookingWarningsTypes.businessIsClosed:
      return [
        {
          text: t('bookingValidation.businessIsClosed'),
          type: BookingWarningsTypes.businessIsClosed,
        },
      ]
    case BookingWarningsTypes.consumables:
      return [
        {
          text: t('bookingValidation.consumables'),
          type: BookingWarningsTypes.consumables,
        },
      ]
    default:
      return [
        {
          text: '',
          type: -1,
        },
      ]
  }
}

type MultiServiceDto = {
  bookingId: number | undefined
  locationId: number | undefined
  employeeId: number | null
  services?: { warningMessage?: string; name: string }[]
  startDate: Date
}
export const generateMessageAfterValidationForMultiBooking = (
  {
    validationResults,
    multiServicesDto,
    employees,
    locations,
  }: {
    validationResults: ServerValidationResult[]
    multiServicesDto: MultiServiceDto[]
    employees: { id: number; firstName: string; lastName: string }[]
    locations: { id: number; name: string }[]
  },
  t: TFunction,
): ValidatedBookingWarningType[] => {
  const groupedWarnings: { [x: number]: string[] } = {}
  const result: ValidatedBookingWarningType[] = []

  for (let i = 0; i < multiServicesDto.length; i++) {
    const multiServiceDto = multiServicesDto[i]
    const validationResult = validationResults[i]
    if (!validationResult)
      throw new Error('validationResults must be the same length as multiServicesDto')

    const { type } = validationResult
    if (type === null) continue

    // Employee related
    if (
      type === BookingWarningsTypes.employeesNotWorking ||
      type === BookingWarningsTypes.employeesAreBusy
    ) {
      const employee = employees.find(employee => employee.id === multiServiceDto.employeeId)
      if (!employee) throw new Error('employee not found')
      const employeeName = transformPersonName(employee)

      if (groupedWarnings[type]) {
        groupedWarnings[type].push(employeeName)
      } else {
        groupedWarnings[type] = [employeeName]
      }
    }

    // Location related
    if (
      type === BookingWarningsTypes.locationsAreFull ||
      type === BookingWarningsTypes.locationsAreFullForEmployees
    ) {
      const location = locations.find(location => location.id === multiServiceDto.locationId)
      if (!location) throw new Error('location not found')
      if (groupedWarnings[type]) {
        groupedWarnings[type].push(location.name)
      } else {
        groupedWarnings[type] = [location.name]
      }
    }

    if (type === BookingWarningsTypes.clientHasBookings) {
      result.push({
        text:
          t('bookingValidation.clientHasBookings') +
          ': ' +
          formatTimeFromDate(multiServiceDto.startDate),
        type: BookingWarningsTypes.clientHasBookings,
      })
    }
    if (type === BookingWarningsTypes.businessIsClosed) {
      result.push({
        text:
          t('bookingValidation.businessIsClosed') +
          ': ' +
          formatTimeFromDate(multiServiceDto.startDate),
        type: BookingWarningsTypes.businessIsClosed,
      })
    }

    // Not the part of server validation
    // TODO: Figure out how to move it to server validation
    const serviceWarnings = multiServiceDto.services
      ? checkServicesForConsumables(multiServiceDto.services, t('bookingValidation.forService'))
      : undefined
    if (serviceWarnings) {
      const foo = serviceWarnings.map(warning => ({
        text: warning,
        type: BookingWarningsTypes.consumables,
      }))
      result.push(...foo)
    }
  }
  for (const [type, names] of Object.entries(groupedWarnings)) {
    const message = generateMessageAfterValidation({ warningType: Number(type), t, plural: true })
    if (message.length > 0) {
      result.push({
        text: message[0].text + ': ' + names.join(', '),
        type: Number(type),
      })
    }
  }

  return result
}

function checkServicesForConsumables(
  services: Array<ServicesDTOtoValidateType>,
  serviceTranslation: string,
) {
  const servicesWithWarnings = services.filter(service => service.warningMessage)
  if (servicesWithWarnings.length)
    return servicesWithWarnings.map(
      service => `${serviceTranslation}: ${service.name} ${service.warningMessage}`,
    )

  return undefined
}

export function checkLocationIsAvailableForClient(params: {
  locationId: number
  maxClients: number
  allBookings: BookingUnion[]
  startDate: Date
  endDate: Date
  clientsInBookingCount?: number
}) {
  const { locationId, maxClients, allBookings, startDate, endDate, clientsInBookingCount } = params

  const bookingsWithLocation = allBookings.filter(booking => booking.location?.id === locationId)
  const overlapCount = countClientsFromOverlaps(bookingsWithLocation, { startDate, endDate })
  const clientsCount = clientsInBookingCount
    ? clientsInBookingCount + overlapCount
    : overlapCount + 1
  return clientsCount <= maxClients
}

export function checkLocationIsAvailableForEmployee(params: {
  locationId: number
  maxEmployees: number
  allBookings: BookingUnion[]
  startDate: Date
  endDate: Date
}) {
  const { locationId, maxEmployees, allBookings, startDate, endDate } = params
  const bookingsWithLocationAndEmployees = allBookings.filter(
    booking => locationId === booking.location.id && Boolean(booking.employee),
  )

  const overlapCount = countOverlaps(bookingsWithLocationAndEmployees, { startDate, endDate })
  return overlapCount < maxEmployees
}

function countClientsFromOverlaps(
  bookings: BookingUnion[],
  newBookingTiming: { startDate: Date; endDate: Date },
) {
  return bookings.reduce((clientCount, booking) => {
    const bookingEndDate = addMinutes(booking.startDate, booking.duration)
    if (
      checkBookingsOverlap(newBookingTiming, {
        startDate: booking.startDate,
        endDate: bookingEndDate,
      })
    ) {
      const clientsInBooking = booking.isGroupBooking
        ? booking.bookingClients.length
        : CLIENT_COUNT_IN_USUAL_BOOKING
      return clientCount + clientsInBooking
    }

    return clientCount
  }, 0)
}

const CLIENT_COUNT_IN_USUAL_BOOKING = 1

function countOverlaps(
  bookings: BookingUnion[],
  newBookingTiming: { startDate: Date; endDate: Date },
) {
  return bookings.reduce((acc, booking) => {
    const bookingEndDate = addMinutes(booking.startDate, booking.duration)
    if (
      checkBookingsOverlap(newBookingTiming, {
        startDate: booking.startDate,
        endDate: bookingEndDate,
      })
    )
      return acc + 1
    return acc
  }, 0)
}

export type BookingInBookingsCreatingDto = {
  bookingId: null
  branchId: number
  isGroupBooking: boolean
  serviceIds: number[]
  clientId: number
  employeeId: number | null
  locationId: number
  duration: number
  startDate: Date
}

export const generateMessageAfterValidationBookings = (
  {
    validationResults,
    bookings,
    employees,
    locations,
  }: {
    validationResults: ServerValidationResult[]
    bookings: BookingInBookingsCreatingDto[]
    employees: { id: number; firstName: string; lastName: string }[]
    locations: { id: number; name: string }[]
  },
  t: TFunction,
): ValidatedBookingWarningType[] => {
  const groupedWarnings: { [x: number]: string[] } = {}
  const result: ValidatedBookingWarningType[] = []

  for (let i = 0; i < bookings.length; i++) {
    const booking = bookings[i]
    const validationResult = validationResults[i]
    if (!validationResult)
      throw new Error('validationResults must be the same length as multiServicesDto')

    const { type } = validationResult
    if (type === null) continue

    // Employee related
    if (
      type === BookingWarningsTypes.employeesNotWorking ||
      type === BookingWarningsTypes.employeesAreBusy
    ) {
      const employee = employees.find(employee => employee.id === booking.employeeId)
      if (!employee) throw new Error('employee not found')
      const employeeName = transformPersonName(employee)

      if (groupedWarnings[type]) {
        groupedWarnings[type].push(employeeName)
      } else {
        groupedWarnings[type] = [employeeName]
      }
    }

    // Location related
    if (
      type === BookingWarningsTypes.locationsAreFull ||
      type === BookingWarningsTypes.locationsAreFullForEmployees
    ) {
      const location = locations.find(location => location.id === booking.locationId)
      if (!location) throw new Error('location not found')
      if (groupedWarnings[type]) {
        groupedWarnings[type].push(location.name)
      } else {
        groupedWarnings[type] = [location.name]
      }
    }

    if (type === BookingWarningsTypes.clientHasBookings) {
      result.push({
        text:
          t('bookingValidation.clientHasBookings') + ': ' + formatTimeFromDate(booking.startDate),
        type: BookingWarningsTypes.clientHasBookings,
      })
    }
    if (type === BookingWarningsTypes.businessIsClosed) {
      result.push({
        text:
          t('bookingValidation.businessIsClosed') + ': ' + formatTimeFromDate(booking.startDate),
        type: BookingWarningsTypes.businessIsClosed,
      })
    }

    // нет проверки на расходники, так как при создании мы не добавляем расходники
    // если добавим эту логику то нужно будет добавить проверку checkServicesForConsumables
  }
  for (const [type, names] of Object.entries(groupedWarnings)) {
    const message = generateMessageAfterValidation({ warningType: Number(type), t, plural: true })
    if (message.length > 0) {
      result.push({
        text: message[0].text + ': ' + names.join(', '),
        type: Number(type),
      })
    }
  }

  return result
}
