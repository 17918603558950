import { useFetchBranches } from '@expane/data'
import { useShowConfirmationPopup } from '@expane/ui'
import { useTextInputPopup } from 'logic/hooks/popup/useTextInputPopup'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PresetSelect } from 'ui/PresetSelect'
import {
  getMovementPresetsFromLocalStorage,
  MovementPreset,
  setNewMovementPresetsToLocalStorage,
} from './logic'

interface MovementPresetSelectProps {
  value: number | null
  className?: string
  onChange: (value: number | null) => void
  selectedTypeId: number | null
  setSelectedTypeId: (value: number | null) => void
  selectedSupplierId: number | null
  setSelectedSupplierId: (value: number | null) => void
  selectedFromStorageId: number | null
  setSelectedFromStorageId: (value: number | null) => void
  selectedToStorageId: number | null
  setSelectedToStorageId: (value: number | null) => void
  selectedProducts: { id: number; isFolder: boolean }[]
  setSelectedProducts: (value: { id: number; isFolder: boolean }[]) => void
  selectedStatus: number | null
  setSelectedStatus: (value: number | null) => void
}

export const MovementPresetSelect: FC<MovementPresetSelectProps> = ({
  value,
  onChange,
  selectedFromStorageId,
  setSelectedFromStorageId,
  selectedProducts,
  setSelectedProducts,
  selectedSupplierId,
  setSelectedSupplierId,
  selectedToStorageId,
  setSelectedToStorageId,
  selectedTypeId,
  setSelectedTypeId,
  selectedStatus,
  setSelectedStatus,
  className,
}) => {
  const { t } = useTranslation()
  const { data: branches } = useFetchBranches()
  const [presets, setPresets] = useState<MovementPreset[]>([])
  useEffect(() => {
    if (branches === undefined) return

    const newPresets = getMovementPresetsFromLocalStorage(branches[0].id)
    if (newPresets !== null) setPresets(newPresets)
  }, [branches])

  const { inputPopup, showInputPopup } = useTextInputPopup()
  const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

  const handleChange = (presetId: number) => {
    const preset = presets.find(preset => preset.id === presetId)
    if (preset) {
      setSelectedTypeId(preset.typeId)
      setSelectedSupplierId(preset.supplierId)
      setSelectedFromStorageId(preset.fromStorageId)
      setSelectedToStorageId(preset.toStorageId)
      setSelectedProducts(preset.products)
      setSelectedStatus(preset.statusId)
    }

    onChange(presetId)
  }

  const handleCreatePreset = (name: string, closeCallback: () => void) => {
    const presetWithThisName = presets.find(preset => preset.name === name)
    if (presetWithThisName !== undefined) {
      showConfirmation({
        title: t('overwrite'),
        description: t('preset.alreadyExists'),
        onConfirm: () => {
          handleOverwritePreset(presetWithThisName.id, name)
          closeCallback()
        },
      })
      return
    }

    const newPresetId = presets.reduce((id, preset) => Math.max(id, preset.id), 0) + 1
    const newPreset = getNewPreset(newPresetId, name)

    const payload = [...presets, newPreset]
    setPresets(payload)
    onChange(newPresetId)
    if (branches) setNewMovementPresetsToLocalStorage(payload, branches[0].id)
    closeCallback()
  }

  const handleDeletePreset = (id: number, closeCallback: () => void) => {
    if (value === id) {
      onChange(null)
      setSelectedTypeId(null)
      setSelectedSupplierId(null)
      setSelectedFromStorageId(null)
      setSelectedToStorageId(null)
      setSelectedProducts([])
      setSelectedStatus(null)
    }

    const payload = presets.filter(preset => preset.id !== id)
    if (branches) setNewMovementPresetsToLocalStorage(payload, branches[0].id)
    setPresets(payload)
    closeCallback()
  }

  const handleOverwritePreset = (id: number, name: string) => {
    const newPreset = getNewPreset(id, name)

    const payload = [...presets.filter(preset => preset.id !== id), newPreset]
    onChange(id)
    setPresets(payload)
    if (branches) setNewMovementPresetsToLocalStorage(payload, branches[0].id)
  }

  const getNewPreset = (id: number, name: string): MovementPreset => {
    return {
      id,
      name,
      typeId: selectedTypeId,
      supplierId: selectedSupplierId,
      fromStorageId: selectedFromStorageId,
      toStorageId: selectedToStorageId,
      products: selectedProducts,
      statusId: selectedStatus,
    }
  }

  return (
    <>
      <PresetSelect
        value={value}
        onCreate={closeCallback =>
          showInputPopup({
            title: t('preset.new'),
            description: t('preset.enterName'),
            onConfirm: name => handleCreatePreset(name, closeCallback),
          })
        }
        onChange={handleChange}
        onDelete={(preset, closeCallback) =>
          showConfirmation({
            title: t('delete'),
            description: t('preset.deleteConfirmation', { name: preset.name }),
            onConfirm: () => handleDeletePreset(preset.id, closeCallback),
          })
        }
        className={className}
        items={presets}
      />
      {confirmationModal}
      {inputPopup}
    </>
  )
}
