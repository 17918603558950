import { Button, CloseButton, Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkSharp, IoChevronForwardSharp } from 'react-icons/io5'

export interface ChooseBusinessCreationProcessDialogProps {
  createBusinessAction: () => void
  createFullProcessBusinessAction: () => void
  closeDialog: () => void
}

export const ChooseBusinessCreationProcessDialog: FC<ChooseBusinessCreationProcessDialogProps> = ({
  createBusinessAction,
  createFullProcessBusinessAction,
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <Modal close={closeDialog} animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>{t('createBusiness.process')}</Dialog.Title>

        <Dialog.Body className="w-172 text-main-color">
          <p>{t('createBusiness.creationProcess.almostHere')}</p>
          <p className={'mt-4'}>{t('createBusiness.creationProcess.description')}</p>
          <p className={'mt-4'}>{t('createBusiness.creationProcess.confirmation')}</p>
        </Dialog.Body>

        <Dialog.Footer>
          <Button onClick={createBusinessAction} Icon={IoCheckmarkSharp} iconSide="right">
            {t('createBusiness.action')}
          </Button>

          <Button
            onClick={createFullProcessBusinessAction}
            Icon={IoChevronForwardSharp}
            iconSide="right"
          >
            {t('createBusiness.creationProcess.go')}
          </Button>

          <CloseButton onClick={closeDialog} className="mr-auto" />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

export const useOpenChooseBusinessCreationProcessDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCreateBusinessFunction = useRef<() => void | undefined>()
  const onCreateFullProcessBusinessFunction = useRef<() => void | undefined>()

  const chooseBusinessCreationProcess = ({
    onCreateBusiness,
    onCreateFullProcessBusiness,
  }: {
    onCreateBusiness: () => void
    onCreateFullProcessBusiness: () => void
  }) => {
    onCreateBusinessFunction.current = onCreateBusiness
    onCreateFullProcessBusinessFunction.current = onCreateFullProcessBusiness

    openPopup()
  }

  const createBusinessAction = () => {
    closePopup()
    onCreateBusinessFunction.current?.()
  }

  const createFullProcessBusinessAction = () => {
    closePopup()
    onCreateFullProcessBusinessFunction.current?.()
  }

  const closeDialog = () => {
    closePopup()
  }

  const chooseBusinessCreationProcessDialog = isOpen ? (
    <ChooseBusinessCreationProcessDialog
      closeDialog={closeDialog}
      createBusinessAction={createBusinessAction}
      createFullProcessBusinessAction={createFullProcessBusinessAction}
    />
  ) : null

  return { chooseBusinessCreationProcess, chooseBusinessCreationProcessDialog }
}
