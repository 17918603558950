import { gql } from 'graphql-request'
import { reportError, useQuery, queryClient, request, useMutation, reportGqlError } from '../../api'
import {
  ServerRolePermissionInsertInput,
  ServerRolePermissionMutationResponse,
  ServerRolePermissionType,
} from '../../generated/graphql-types'
import { ROLE_PERMISSIONS_QUERY_KEY } from './queryKeys'
import { rolePermissionFragment } from './role.fragments'

export function useFetchRolePermissions() {
  return useQuery(
    [ROLE_PERMISSIONS_QUERY_KEY],
    async (): Promise<ServerRolePermissionType[]> => {
      const result = await request(
        gql`
          ${rolePermissionFragment}
          query {
            rolePermission(order_by: { permissionId: asc }) {
              ...rolePermissionType
            }
          }
        `,
      )

      if (Array.isArray(result?.rolePermission)) {
        return result.rolePermission
      } else {
        reportError(new Error('rolePermissions is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchRolePermissions',
      onError: reportGqlError,
    },
  )
}

export function useUpdateRolesPermissions() {
  return useMutation(
    async (dto: {
      roleId: string
      rolePermissionInsertInput: ServerRolePermissionInsertInput[]
      isItMyRole: boolean
    }): Promise<{
      deleteRolePermissions?: ServerRolePermissionMutationResponse
      insertRolePermissions?: ServerRolePermissionMutationResponse
    }> => {
      return request(
        gql`
          mutation (
            $roleId: String!
            $rolePermissionInsertInput: [rolePermission_insert_input!]!
            $permissionId: String!
          ) {
            deleteRolePermissions(
              where: { roleId: { _eq: $roleId }, permissionId: { _neq: $permissionId } }
            ) {
              affected_rows
            }
            insertRolePermissions(objects: $rolePermissionInsertInput) {
              affected_rows
            }
          }
        `,
        {
          roleId: dto.roleId,
          rolePermissionInsertInput: dto.rolePermissionInsertInput,
          permissionId: dto.isItMyRole ? 'rolePermission.set' : '',
        },
      )
    },
    {
      onSuccess: () => {
        // Invalidate every query in the cache
        queryClient.invalidateQueries()
      },
      onError: reportGqlError,
    },
  )
}
