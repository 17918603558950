import {
  useFetchArchivedNestedProductGroups,
  useFetchArchivedUngroupedProducts,
  useFetchNestedProductGroups,
  useFetchUngroupedProducts,
} from '@expane/data'
import { Spinner } from '@expane/ui'
import { ProductsPageData } from 'pages/ProductsPage/ProductsPageData'
import { FC } from 'react'
import { store } from 'store'
import { getProductsForTreeMenu } from 'ui/TreeMenu/logic.transform'

export const ProductsPage: FC = () => {
  const branchId = store.branch.branchId
  const { data: nestedProductGroups, isLoading: isNestedProductGroupsLoading } =
    useFetchNestedProductGroups(branchId)
  const { data: ungroupedProducts, isLoading: isUngroupedProductsLoading } =
    useFetchUngroupedProducts(branchId)
  const isLoading = isNestedProductGroupsLoading || isUngroupedProductsLoading

  const treeItems = getProductsForTreeMenu(nestedProductGroups, ungroupedProducts)

  return !isLoading ? <ProductsPageData items={treeItems} /> : <Spinner expandCentered />
}

export const ArchiveProductsPage: FC = () => {
  const branchId = store.branch.branchId
  const { data: nestedProductGroups, isLoading: isNestedProductGroupsLoading } =
    useFetchArchivedNestedProductGroups(branchId)
  const { data: ungroupedProducts, isLoading: isUngroupedProductsLoading } =
    useFetchArchivedUngroupedProducts(branchId)
  const isLoading = isNestedProductGroupsLoading || isUngroupedProductsLoading

  const treeItems = getProductsForTreeMenu(nestedProductGroups, ungroupedProducts)

  return !isLoading ? <ProductsPageData items={treeItems} archive /> : <Spinner expandCentered />
}
