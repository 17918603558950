import { BookingUnion, CardType, ServerServiceBriefWithProductType } from '@expane/data'
import { compareAsc, createCurrentDate, isBefore, startOfDay } from '@expane/date'
import {
  BOOKING_STATUSES,
  getBookingsThatCanBeCanceled,
  getIsBookingPaid,
} from '@expane/logic/booking'
import { getActiveSubscriptions, getSubscriptionInfo } from '@expane/logic/cards'
import { SelectDropDownItem } from '@expane/ui'
import { RowSelectionState } from '@tanstack/react-table'
import { TFunction } from 'react-i18next'
import { SelectedBookingType } from 'widgets/BookingsSelectionDialog/index'

export const bookingStatusItems: SelectDropDownItem[] = [
  {
    id: 1,
    name: 'notPaid',
  },
  {
    id: 2,
    name: 'paid',
  },
]
export const filterBookings = ({
  bookings,
  pickedClientId,
  pickedEmployeeId,
  pickedStatus,
}: {
  bookings: BookingUnion[]
  pickedClientId: number | null
  pickedEmployeeId: number | null
  pickedStatus: number | null
}) => {
  let filteredBookings: BookingUnion[] = bookings

  // Apply filter by client ID if specified
  if (pickedClientId !== null) {
    filteredBookings = filteredBookings.filter(booking => {
      if (booking.isGroupBooking) {
        return booking.bookingClients.some(({ client: { id } }) => id === pickedClientId)
      } else {
        return booking.client?.id === pickedClientId
      }
    })
  }

  // Apply filter by employee ID if specified
  if (pickedEmployeeId !== null) {
    filteredBookings = filteredBookings.filter(booking => booking.employee?.id === pickedEmployeeId)
  }

  // Apply filter by status if specified
  if (pickedStatus !== null) {
    filteredBookings = filteredBookings.filter(booking => {
      const isBookingPaid = getIsBookingPaid(booking)
      if (pickedStatus === bookingStatusItems[0].id) {
        return !isBookingPaid
      } else {
        return isBookingPaid
      }
    })
  }

  // Sort bookings by transaction presence and start date
  filteredBookings.sort((a, b) => {
    if (a.transactions.length && !b.transactions.length) return 1
    if (!a.transactions.length && b.transactions.length) return -1
    return compareAsc(a.startDate, b.startDate)
  })

  return filteredBookings
}

export const validateCancel = ({
  startOfPeriod,
  bookingsForSubmit,
  ableToEditPast,
  t,
  timezone,
}: {
  startOfPeriod: Date
  bookingsForSubmit: BookingUnion[]
  ableToEditPast: boolean
  t: TFunction
  timezone: string
}): string | undefined => {
  if (isBefore(startOfPeriod, startOfDay(createCurrentDate(timezone))) && !ableToEditPast)
    return t('bookingValidation.notAllowedCancelPast')
  if (bookingsForSubmit.length === 0) return t('bookingValidation.noBookingsToUndo')
}

export const validatePayment = ({
  clientId,
  bookingsForSubmit,
  filteredBookings,
  areSelectedBookings,
  t,
}: {
  clientId: number | null
  bookingsForSubmit: BookingUnion[]
  filteredBookings: BookingUnion[]
  areSelectedBookings: boolean
  t: TFunction
}): string | undefined => {
  if (!clientId) return t('paymentValidation.noClient')

  if (!areSelectedBookings) return t('paymentValidation.noBookings')

  if (bookingsForSubmit.length === 0 && filteredBookings.length !== 0 && areSelectedBookings)
    return t('paymentValidation.allPaid')
}

export const validateChangeStatusToDone = (
  bookingsForChangeStatus: BookingUnion[],
  selectedBookings: RowSelectionState,
  t: TFunction,
) => {
  if (!getAreSelectedBookings(selectedBookings)) {
    return t('makeDone.noSelected')
  }
  if (bookingsForChangeStatus.length === 0) {
    return t('makeDone.noBookings')
  }
}

export const getBookingsForPaymentSubmit = (
  filteredBookings: BookingUnion[],
  selectedBookings: RowSelectionState,
) =>
  filteredBookings.filter((booking, index) => selectedBookings[index] && !getIsBookingPaid(booking))

export const getBookingsFromSelectedBookingsForCancel = (
  filteredBookings: BookingUnion[],
  selectedBookingsIndexes: RowSelectionState,
) => {
  const selectedBookings = filteredBookings.filter(
    (booking, index) => selectedBookingsIndexes[index],
  )

  return getBookingsThatCanBeCanceled(selectedBookings)
}

export const getBookingsForChangeStatusToDone = (
  allBookings: BookingUnion[],
  selectedBookings: RowSelectionState,
  timezone: string,
) => {
  const current = createCurrentDate(timezone)

  const bookingsWithStatusDone = allBookings.filter(
    (booking, index) => selectedBookings[index] && booking.status === BOOKING_STATUSES.done,
  )

  const bookingsForChangeStatus = allBookings.filter(
    (booking, index) =>
      selectedBookings[index] &&
      booking.status !== BOOKING_STATUSES.done &&
      booking.startDate < current,
  )

  return {
    bookingsForChangeStatus,
    bookingsWithStatusDone,
  }
}

type ServiceDiscount = {
  value: string
  type: 'percent' | 'fixed'
}

type ServicesBySubscription = {
  subscriptionId: number | undefined
  serviceId: number
  servicePrice: number
  activatedAt: Date | undefined
}

export type ServiceForPayment = ServerServiceBriefWithProductType & {
  quantity?: number
  discount?: ServiceDiscount
  bySubscription?: ServicesBySubscription
  bookingIds?: number[]
}
export const getServicesWithSubscription = (
  items: ServerServiceBriefWithProductType[],
  clientsSubscriptions: CardType[],
  timezone: string | undefined,
) => {
  const result: ServiceForPayment[] = []
  if (!timezone) return []

  // исключаем абонементы которые закончились
  const activeSubscriptions = getActiveSubscriptions(clientsSubscriptions, timezone).sort(
    (a, b) => {
      // чем раньше был активирован абонемент тем выше у него приоритет для использования
      if (!a.activatedAt) return 1
      if (!b.activatedAt) return -1
      return compareAsc(a.activatedAt, b.activatedAt)
    },
  )

  items.forEach(item => {
    let servicePriceBySubscription: number
    const subscriptionsForItem = activeSubscriptions.find(cs => {
      const usedSubscriptionServices = getSubscriptionInfo(cs)

      const subscriptionForService = cs.cardTemplate.cardTemplateServices.find(ss => {
        return ss.serviceId === item.id
      })

      if (subscriptionForService) {
        // есть ли в этом абонементе свободная данная услуга
        const isFreeServiceInSubscription =
          usedSubscriptionServices[subscriptionForService.serviceId] &&
          usedSubscriptionServices[subscriptionForService.serviceId].used <
            usedSubscriptionServices[subscriptionForService.serviceId].quantity

        if (isFreeServiceInSubscription) {
          servicePriceBySubscription = subscriptionForService.servicePrice
          return true
        }
      }

      return false
    })

    if (subscriptionsForItem) {
      result.push({
        ...item,
        bySubscription: {
          subscriptionId: subscriptionsForItem?.id,
          serviceId: item.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          servicePrice: servicePriceBySubscription!,
          activatedAt: subscriptionsForItem.activatedAt ?? undefined,
        },
      })
    } else {
      result.push(item)
    }
  })

  return result
}

export const getBookingsServices = (bookings: BookingUnion[]) => {
  const services = bookings
    .map(booking => {
      const bookingIds = [booking.id]
      const services = booking.isGroupBooking
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          [booking.service!]
        : booking.bookingServices.map(bookingService => bookingService.service)

      return services.map(service => ({ ...service, bookingIds }))
    })
    .flat()

  return services
}

export const getAreSelectedBookings = (selectedBookings: SelectedBookingType) =>
  Object.keys(selectedBookings).length !== 0
