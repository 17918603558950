import { FadeInTranslateTop, InputLabel, modalsHTMLElement, Paper } from '@expane/ui'
import { flip, useFloating } from '@floating-ui/react-dom'
import { FC, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoTrashBinOutline } from 'react-icons/io5'

export interface ColorPickerProps {
  value: string | undefined
  onChange: (color: string) => void
  label?: string
  hint?: string
  colors: string[]
  disabled?: boolean
  clearFunction?: (color: string | undefined) => void
}

export const ColorPicker: FC<ColorPickerProps> = ({
  onChange,
  value,
  label,
  hint,
  colors,
  disabled,
  clearFunction,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { strategy, x, y, floating, reference } = useFloating({
    placement: 'bottom-start',
    middleware: [flip()],
  })

  const { t } = useTranslation()

  const pickerClassName = `w-8 h-8 flex-centered rounded-full group border-2 border-gray-100 dark:border-gray-500 bg-${
    value ?? WHITE_COLOR
  }${disabled ? ' pointer-events-none' : ''}`

  return (
    <div className="relative max-w-min">
      <InputLabel label={label} hint={hint} />
      <div className="h-9.5 flex-centered">
        <button
          ref={reference}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          className={pickerClassName}
          disabled={disabled}
        >
          <div className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-500 invisible group-hover:visible" />
        </button>
      </div>
      {isOpen &&
        createPortal(
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <div onClick={() => setIsOpen(false)} />

            <FadeInTranslateTop
              as={Paper}
              className="absolute py-1 px-1.5 left-0 mt-2 overflow-hidden w-36"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="color-picker"
              onClick={() => setIsOpen(false)}
            >
              <div className="flex justify-center flex-wrap transition-all">
                {colors.map(color => (
                  <button
                    className={`w-6 h-6 m-2 rounded-full flex-centered cursor-pointer
              bg-${color} border-2 border-${color} ring-1 ring-${color} hover:border-white hover:dark:border-gray-700
              hover:scale-125`}
                    onClick={() => {
                      if (color !== value) onChange(color)
                    }}
                    key={color}
                  />
                ))}
                {clearFunction && value && (
                  <div
                    className="cursor-pointer text-xs text-gray-300 mt-2 px-4 flex justify-between w-full"
                    onClick={() => clearFunction(undefined)}
                  >
                    <p>{t('remove')}</p>
                    <IoTrashBinOutline size="1rem" color="text-gray-300" />
                  </div>
                )}
              </div>
            </FadeInTranslateTop>
          </div>,
          modalsHTMLElement,
        )}
    </div>
  )
}

const WHITE_COLOR = 'white'
