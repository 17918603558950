import { useEffect } from 'react'

export const useHandleClickOutside = (
  elements: Array<HTMLElement | null>,
  onClick: () => void,
  enabled: boolean,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!elements.some(element => element?.contains(event.target as Node))) onClick()
    }
    if (enabled) {
      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [onClick, elements, enabled])
}
