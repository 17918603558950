import { FC } from 'react'
import { ExtendedSelectDropdownItem } from 'widgets/ClientDialog'
import { TreeSelect } from 'ui/TreeSelect'
import { TreeMenuItem } from 'ui/TreeMenu'
import { useTranslation } from 'react-i18next'
import { EmployeeConnections, EmployeeByIdExtended } from '@expane/data'

interface SelectPreferredConnectionsProps {
  onChange: () => void
  value: ExtendedSelectDropdownItem[]
  employeeById: EmployeeByIdExtended | undefined
  className?: string
  disabled?: boolean
}

export const SelectPreferredConnections: FC<SelectPreferredConnectionsProps> = ({
  onChange,
  value,
  employeeById,
  className,
  disabled = false,
}) => {
  const { t } = useTranslation()

  const items = useGetPreferredConnectionsForEmployee(employeeById)

  return (
    <TreeSelect
      type="MultiPickMode"
      items={items}
      label={t('preferredConnections')}
      onSelected={onChange}
      selected={value}
      className={className}
      disabled={disabled}
    />
  )
}

const PREFERRED_CONNECTIONS: ExtendedSelectDropdownItem[] = [
  { id: 1, name: 'Telegram', key: 'telegram' },
  { id: 2, name: 'Viber', key: 'viber' },
  { id: 4, name: 'mobileApp', key: 'expo' },
]

const useGetPreferredConnectionsForEmployee = (
  employee: EmployeeByIdExtended | undefined,
): TreeMenuItem[] => {
  const { t } = useTranslation()

  return PREFERRED_CONNECTIONS.map(preferredConnection => {
    if (preferredConnection.key === 'telegram' && !employee?.hasTelegram)
      return {
        ...preferredConnection,
        inactiveReason: t('preferredConnectionsHints.telegram'),
        disabled: true,
      }

    if (preferredConnection.key === 'viber' && !employee?.hasViber)
      return {
        ...preferredConnection,
        inactiveReason: t('preferredConnectionsHints.viber'),
        disabled: true,
      }

    return preferredConnection
  })
}

export const transformPreferredConnectionsForForms = (
  employee: EmployeeByIdExtended,
): TreeMenuItem[] => {
  const {
    employeeSettings: { preferredConnections },
  } = employee

  if (!preferredConnections) return []

  const result: TreeMenuItem[] = []

  for (const key in preferredConnections) {
    if (preferredConnections[key]) {
      const connection = PREFERRED_CONNECTIONS.find(p => p.key === key)
      if (connection) result.push(connection)
    }
  }

  return result
}

export const transformPreferredConnectionsFormValuesForMutation = (
  preferredConnections: ExtendedSelectDropdownItem[],
): EmployeeConnections => {
  const preferredConnectionsForMutation: EmployeeConnections = {
    telegram: false,
    viber: false,
    expo: false,
  }
  for (const item of preferredConnections) {
    if (Object.keys(preferredConnectionsForMutation).includes(item.key))
      preferredConnectionsForMutation[item.key] = true
  }

  return preferredConnectionsForMutation
}
