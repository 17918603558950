import {
  useFetchCurrentBranchTimezone,
  useFetchEmployeeById,
  useFetchEmployeeScheduleById,
} from '@expane/data'
import { Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { StopEmployeeScheduleDialogLogic } from './StopEmployeeScheduleDialogLogic'
import { StopEmployeeScheduleDialogPlaceholder } from './StopEmployeeScheduleDialogPlaceholder'
import { DEFAULT_TIMEZONE } from '@expane/date'

interface StopEmployeeScheduleDialog extends Pick<DialogProps, 'closeDialog'> {
  employeeId: number
  employeeScheduleId: number
}

const StopEmployeeScheduleDialog: FC<StopEmployeeScheduleDialog> = observer(
  ({ closeDialog, employeeScheduleId, employeeId }) => {
    const { t } = useTranslation()

    const branchId = store.branch.branchId
    const timezone = useFetchCurrentBranchTimezone(branchId)

    const { data: employeeScheduleById, isLoading: isLoadingEmployeeSchedule } =
      useFetchEmployeeScheduleById(employeeScheduleId, timezone)
    const { data: employee, isLoading: isLoadingEmployee } = useFetchEmployeeById(
      employeeId,
      timezone,
    )

    const isLoading = isLoadingEmployeeSchedule || isLoadingEmployee
    const isNoData = !employeeScheduleById || !employee || !timezone
    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

    return (
      <Modal close={closeDialog}>
        <Dialog>
          <Dialog.Title>{t('stopSchedule.name')}</Dialog.Title>
          {isLoading ? (
            <StopEmployeeScheduleDialogPlaceholder closeDialog={closeDialog} />
          ) : (
            <StopEmployeeScheduleDialogLogic
              employeeSchedule={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                employeeScheduleById!
              }
              employee={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                employee!
              }
              closeDialog={closeDialog}
              timezone={timezone ?? DEFAULT_TIMEZONE}
            />
          )}
        </Dialog>
      </Modal>
    )
  },
)

interface ShowStopEmployeeScheduleDialogProps {
  employeeScheduleId: number
  employeeId: number
}

export const useOpenStopEmployeeScheduleDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const props = useRef<ShowStopEmployeeScheduleDialogProps | null>(null)

  const showStopEmployeeScheduleDialog = (dto: ShowStopEmployeeScheduleDialogProps) => {
    props.current = dto
    openPopup()
  }

  const stopEmployeeScheduleDialog =
    isOpen && props.current ? (
      <StopEmployeeScheduleDialog closeDialog={closePopup} {...props.current} />
    ) : null

  return {
    stopEmployeeScheduleDialog,
    showStopEmployeeScheduleDialog,
  }
}

export const labelStyle = 'mr-1 text-gray-400 text-sm py-2'
export const textStyle = 'text-main-color font-medium text-sm py-2'
