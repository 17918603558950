import { FC } from 'react'
import { useFetchExpensesReasons, useMutateExpenseReasons } from '@expane/data'
import { store } from 'store'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { SimpleEntityPlaceholder } from 'widgets/SimpleEntitiesDialog/SimpleEntityPlaceholder'
import { useTranslation } from 'react-i18next'
import { SimpleEntitiesDialogLogic } from 'widgets/SimpleEntitiesDialog'
import { SimpleEntitiesMutateFunc } from 'widgets/SimpleEntitiesDialog/logic'
import { useSnackbar } from '@expane/widgets'

export const ExpenseReasonDialog: FC<DialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!
  const { data: expensesReasons, isLoading } = useFetchExpensesReasons(branchId)
  const { mutateAsync: mutateExpenseReason } = useMutateExpenseReasons()

  useErrorOpeningDialog(!isLoading && !expensesReasons, closeDialog)

  const [openSnackbar] = useSnackbar()

  const mutateFunc: SimpleEntitiesMutateFunc = async insertInputs => {
    const dataForMutation = insertInputs.map(item => ({
      ...item,
      branchId,
    }))

    const result = await mutateExpenseReason(dataForMutation)
    if (result.insertExpensesReasons.affectedRows) {
      openSnackbar(t('transaction.expenseReasonUpdatedSuccessfully'), 'success', 3000)
    } else {
      openSnackbar(t('submitError'), 'error', 3000)
    }
  }

  return isLoading ? (
    <SimpleEntityPlaceholder closeDialog={closeDialog} title={t('transaction.expenseReason')} />
  ) : (
    <SimpleEntitiesDialogLogic
      items={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        expensesReasons!
      }
      closeDialog={closeDialog}
      title={t('transaction.expenseReason')}
      mutateFunc={mutateFunc}
    />
  )
}
