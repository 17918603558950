import {
  CloseButton,
  Dialog,
  Modal,
  PlaceholderInput,
  PlaceholderString,
  PlaceholderTextarea,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { SERVICE_COLORS_LIST } from 'logic/service'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorPicker } from 'ui/ColorPicker'
import { PlaceholderEditablePhoto, PlaceholderSwitch } from 'ui/PlaceholderContent'
import { SaveButton } from 'widgets/Buttons'

export const InterbranchServicePlaceholder: FC<Pick<DialogProps, 'closeDialog'>> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('interbranchService.name')}</Dialog.Title>
        <Dialog.Body className="w-224">
          <div className="flex w-full mb-2">
            <div className="w-5/6">
              <div className="flex gap-2 mb-4">
                <PlaceholderInput className="w-1/2" label={t('title')} />
                <PlaceholderInput className="w-1/4" label={t('type')} />
                <PlaceholderInput className="w-1/4" label={t('defaultDuration.name')} />
              </div>
              <div className="flex gap-2">
                <PlaceholderTextarea className="w-1/2" label={t('description')} rows={2} />
                <PlaceholderInput className="w-1/4" label={t('price')} />

                <PlaceholderInput className="w-1/4" label={t('costPrice')} />
              </div>
              <PlaceholderSwitch containerClassName="mt-4" label={t('availableForClient.name')} />
            </div>
            <div className="flex flex-col items-center w-1/6 gap-2">
              <PlaceholderEditablePhoto className="mt-5" size="medium" />

              <ColorPicker
                value={undefined}
                label={t('color')}
                onChange={() => {
                  return
                }}
                colors={SERVICE_COLORS_LIST.map(color => color.bgColor)}
                disabled
              />
            </div>
          </div>

          <div className="h-48">
            <TableContainer className="max-h-48">
              <TableHeader>
                <tr>
                  <TableHeaderCell className="w-52">{t('branch.name')}</TableHeaderCell>
                  <TableHeaderCell className="w-60">{t('locations.name')}</TableHeaderCell>
                  <TableHeaderCell className="w-60">{t('employee.name')}</TableHeaderCell>
                  <TableHeaderCell className="w-60 flex gap-1 items-center">
                    {t('whoCanSellService')}
                  </TableHeaderCell>
                </tr>
              </TableHeader>

              <TableBody>
                <TableRow>
                  <TableCell>
                    <PlaceholderString />
                  </TableCell>

                  <TableCell>
                    <PlaceholderInput />
                  </TableCell>

                  <TableCell>
                    <PlaceholderInput />
                  </TableCell>

                  <TableCell>
                    <PlaceholderInput />
                  </TableCell>
                </TableRow>
              </TableBody>
            </TableContainer>
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <SaveButton disabled />
          <CloseButton disabled />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
