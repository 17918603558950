import { useFetchArchivedReviews, useFetchCurrentBranchTimezone } from '@expane/data'
import { Page } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ListNavigationButton } from 'widgets/NavigationButtons'
import { store } from 'store'
import { ReviewsList } from 'pages/ReviewsPage/List'
import { useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'

export const ArchiveReviewsPage: FC = observer(() => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: reviews, isLoading: isLoadingReviews } = useFetchArchivedReviews(branchId, timezone)
  const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()

  const isLoading = isLoadingMyPermissions || isLoadingReviews

  return (
    <Page>
      <ListNavigationButton title={t('reviews.name')} className="mb-2" />

      <ReviewsList reviews={reviews} myPermissions={myPermissions} isLoading={isLoading} archive />
    </Page>
  )
})
