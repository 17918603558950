import { gql } from 'graphql-request'
import { queryClient, request, useMutation, useQuery, reportError, reportGqlError } from '../../api'
import {
  ServerProductGroupBriefNotArchivedType,
  ServerProductGroupEmployeeGroupInsertInput,
  ServerProductGroupEmployeeGroupMutationResponse,
  ServerProductGroupInsertInput,
  ServerProductGroupSetInput,
  ServerProductGroupType,
} from '../../generated/graphql-types'
import { ARCHIVED_PRODUCT_GROUPS_QUERY_KEY, PRODUCT_GROUPS_QUERY_KEY } from './queryKeys'
import {
  productGroupBriefArchivedFragment,
  productGroupBriefNotArchivedFragment,
  productGroupFragment,
} from './productGroup.fragments'
import { PRODUCTS_QUERY_KEY } from '../product/queryKeys'

export function useFetchProductGroups(branchId: number | undefined | null) {
  return useQuery<ServerProductGroupType[]>(
    [PRODUCT_GROUPS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${productGroupFragment}
          query ($branchId: Int!) {
            productGroups(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, archived: { _is_null: true } }
            ) {
              ...productGroupType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.productGroups)) {
        return result.productGroups
      }

      reportError(new Error('productGroups is not an array'), 'error', { result })
      return []
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchProductGroups',
      onError: reportGqlError,
      onSuccess: productGroups => {
        productGroups.forEach(productGroup =>
          queryClient.setQueryData([PRODUCT_GROUPS_QUERY_KEY, productGroup.id], productGroup),
        )
      },
    },
  )
}

export type NestedProductGroupType = {
  childGroups?: NestedProductGroupType[]
} & ServerProductGroupBriefNotArchivedType

export function useFetchNestedProductGroups(branchId: number | undefined) {
  return useQuery<NestedProductGroupType[]>(
    [PRODUCT_GROUPS_QUERY_KEY, PRODUCTS_QUERY_KEY, 'nested', { branchId }],
    async () => {
      const result = await request(
        gql`
          ${productGroupBriefNotArchivedFragment}
          query ($branchId: Int!) {
            productGroups(
              order_by: { createdAt: desc }
              where: {
                branchId: { _eq: $branchId }
                archived: { _is_null: true }
                _or: [
                  { parentId: { _is_null: true } }
                  { parentGroup: { archived: { _is_null: false } } }
                ]
              }
            ) {
              ...productGroupBriefNotArchivedType
              childGroups(where: { archived: { _is_null: true } }) {
                ...productGroupBriefNotArchivedType
                childGroups(where: { archived: { _is_null: true } }) {
                  ...productGroupBriefNotArchivedType
                }
              }
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.productGroups)) {
        return result.productGroups
      } else {
        reportError(new Error('useFetchNestedProductGroups is not an array'), 'error', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchNestedProductGroups',
      onError: reportGqlError,
    },
  )
}

export function useFetchArchivedNestedProductGroups(branchId: number | undefined) {
  return useQuery<NestedProductGroupType[]>(
    [ARCHIVED_PRODUCT_GROUPS_QUERY_KEY, 'nested', { branchId }],
    async () => {
      const result = await request(
        gql`
          ${productGroupBriefArchivedFragment}
          query ($branchId: Int!) {
            productGroups(
              where: {
                branchId: { _eq: $branchId }
                _or: [
                  { parentId: { _is_null: true } }
                  { parentGroup: { archived: { _is_null: true } } }
                ]
                archived: { _is_null: false }
              }
              order_by: { id: asc }
            ) {
              ...productGroupBriefArchivedType
              childGroups(where: { archived: { _is_null: false } }) {
                ...productGroupBriefArchivedType
                childGroups(where: { archived: { _is_null: false } }) {
                  ...productGroupBriefArchivedType
                }
              }
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.productGroups)) {
        return result.productGroups
      } else {
        reportError(new Error('useFetchArchivedNestedProductGroups is not an array'), 'error', {
          result,
        })
        return []
      }
    },
    {
      queryName: 'useFetchArchivedNestedProductGroups',
      onError: reportGqlError,
      enabled: Boolean(branchId),
    },
  )
}

export function useFetchDeactivatedProductGroups(branchId: number | undefined) {
  return useQuery<ServerProductGroupType[]>(
    [ARCHIVED_PRODUCT_GROUPS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${productGroupFragment}
          query ($branchId: Int!) {
            productGroups(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, archived: { _is_null: false } }
            ) {
              ...productGroupType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.productGroups)) {
        return result.productGroups
      }

      reportError(new Error('deactivatedProductGroups is not an array'), 'error', { result })
      return []
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchDeactivatedProductGroups',
      onError: reportGqlError,
      onSuccess: productGroups =>
        productGroups.forEach(productGroup =>
          queryClient.setQueryData(
            [ARCHIVED_PRODUCT_GROUPS_QUERY_KEY, productGroup.id],
            productGroup,
          ),
        ),
    },
  )
}

export function useFetchProductGroupById(id: number | undefined, branchId: number | undefined) {
  return useQuery<ServerProductGroupType>(
    [PRODUCT_GROUPS_QUERY_KEY, ARCHIVED_PRODUCT_GROUPS_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${productGroupFragment}
          query ($id: Int!, $branchId: Int!) {
            productGroupById(id: $id) {
              ...productGroupType
            }
          }
        `,
        { id, branchId },
      )

      if (result?.productGroupById) {
        return result.productGroupById
      }

      reportError(new Error('productGroupById does not exist'), 'error', { result, id })
      return undefined
    },
    {
      queryName: 'useFetchProductGroupById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(branchId),
    },
  )
}

export function useCreateProductGroup() {
  return useMutation(
    (
      productGroupInsertInput: ServerProductGroupInsertInput &
        Required<Pick<ServerProductGroupInsertInput, 'name' | 'branchId'>>,
    ): Promise<{ insertProductGroup: { id: number; name: string } }> => {
      return request(
        gql`
          mutation ($productGroupInsertInput: productGroup_insert_input!) {
            insertProductGroup(object: $productGroupInsertInput) {
              id
              name
            }
          }
        `,
        { productGroupInsertInput },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries([PRODUCT_GROUPS_QUERY_KEY])
        queryClient.invalidateQueries([PRODUCTS_QUERY_KEY])
      },
    },
  )
}

export function useUpdateProductGroup() {
  return useMutation(
    (dto: {
      id: number
      productGroupSetInput: ServerProductGroupSetInput
      productGroupProductEmployeeInsertInputs:
        | ServerProductGroupEmployeeGroupInsertInput[]
        | undefined
    }): Promise<{
      updateProductGroupById: { id: number }
      deleteProductGroupEmployeeGroups: ServerProductGroupEmployeeGroupMutationResponse
      insertProductGroupEmployeeGroups: ServerProductGroupEmployeeGroupMutationResponse
    }> => {
      return request(
        gql`
          mutation (
            $id: Int!
            $productGroupSetInput: productGroup_set_input!
            $productGroupProductEmployeeInsertInputs: [productGroupEmployeeGroup_insert_input!]!
            $isUpdateProductGroupEmployeeGroup: Boolean!
          ) {
            updateProductGroupById(pk_columns: { id: $id }, _set: $productGroupSetInput) {
              id
            }
            deleteProductGroupEmployeeGroups(where: { productGroupId: { _eq: $id } })
              @include(if: $isUpdateProductGroupEmployeeGroup) {
              affected_rows
            }
            insertProductGroupEmployeeGroups(objects: $productGroupProductEmployeeInsertInputs)
              @include(if: $isUpdateProductGroupEmployeeGroup) {
              affected_rows
            }
          }
        `,
        {
          ...dto,
          productGroupProductEmployeeInsertInputs:
            dto.productGroupProductEmployeeInsertInputs ?? [],
          isUpdateProductGroupEmployeeGroup: Boolean(dto.productGroupProductEmployeeInsertInputs),
        },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries([PRODUCT_GROUPS_QUERY_KEY])
        queryClient.invalidateQueries([PRODUCTS_QUERY_KEY])
      },
    },
  )
}

export function useArchiveProductGroup() {
  return useMutation(
    async (dto: { id: number; archived: boolean }) => {
      // eslint-disable-next-line camelcase
      const result = await request<{ updateProductGroupArchives: { affected_rows: number } }>(
        gql`
          mutation ($id: Int!, $archived: timestamptz) {
            updateProductGroupArchives(where: { id: { _eq: $id } }, _set: { archived: $archived }) {
              affected_rows
            }
          }
        `,
        { id: dto.id, archived: dto.archived ? new Date().toISOString() : null },
      )
      if (!result || result.updateProductGroupArchives.affected_rows === 0) {
        reportError(
          new Error('The employee does not have permission to archive the productGroup.'),
          'error',
          {
            productId: dto.id,
            archived: dto.archived,
          },
        )
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ARCHIVED_PRODUCT_GROUPS_QUERY_KEY])
        queryClient.invalidateQueries([PRODUCT_GROUPS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
