import { ChangeEvent, FC, useState } from 'react'
import { searchCountries } from '@expane/logic/phone/countryList'
import { useNoScroll } from '@expane/logic/utils'
import { FadeInTranslateTop } from '@expane/ui'
import { useTranslation } from 'react-i18next'

export const DialCodeMenu: FC<{
  onOptionCLicked: (dialCode: string) => void
  close: () => void
}> = ({ onOptionCLicked, close }) => {
  const { i18n, t } = useTranslation()

  const [searchValue, setSearchValue] = useState<string>('')
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const filteredCountries = searchCountries(searchValue, i18n.language)

  useNoScroll({ isActive: true })

  return (
    <FadeInTranslateTop
      className="mt-1.5 border border-primary-500 w-72 bg-block rounded p-4"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <input
        className={
          'px-4 py-2 w-full rounded border border-primary-500 focus:border-primary-500 focus:ring-0'
        }
        onChange={handleInput}
        value={searchValue}
      />

      <div className="overflow-auto w-full h-40 mt-4">
        {filteredCountries.length === 0 ? (
          <div className={'flex h-full flex-col items-center justify-center'}>
            <p>{t('noItems')}</p>
          </div>
        ) : (
          filteredCountries.map(country => (
            <button
              key={country.code}
              className="w-full flex justify-between items-center rounded p-2 text-sm dark:text-white bg-hover transition-all"
              role="menuitem"
              onClick={() => {
                onOptionCLicked(country.dialCode)
                close()
              }}
            >
              <div>{country.flag}</div>
              <div className="line-clamp-1 mx-1 flex-1">{country[i18n.language] ?? country.en}</div>
              <div>{country.dialCode}</div>
            </button>
          ))
        )}
      </div>
    </FadeInTranslateTop>
  )
}
