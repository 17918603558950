import { TableRow } from '@expane/ui'
import { FC } from 'react'
import { ModulesSettingsRowTitleProps } from './logic'

export const ModulesSettingsRowTitle: FC<ModulesSettingsRowTitleProps> = ({ title }) => (
  <TableRow>
    <td className="bg-tr-accent p-1 pl-1.5 text-sm text-primary-500 font-medium" colSpan={3}>
      {title}
    </td>
  </TableRow>
)
