import { formatPhoneNumber } from 'logic/ui'
import { FC } from 'react'
import { IoCall } from 'react-icons/io5'

export interface PhoneProps {
  number?: string | null
}

export const Phone: FC<PhoneProps> = ({ number }) => {
  if (!number) return null

  return (
    <span className="flex items-center text-secondary-color">
      <a
        className="flex-centered text-icon-color mr-1 hover:text-primary-700 w-5 h-5 rounded-full bg-icon"
        href={`tel:+${number.split(' ').join('')}`}
        onClick={e => e.stopPropagation()}
        onMouseDown={e => e.stopPropagation()} // for Draggable events in BookingCard
      >
        <IoCall size="0.75rem" />
      </a>
      {formatPhoneNumber(number)}
    </span>
  )
}
