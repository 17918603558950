import { forwardRef, InputHTMLAttributes } from 'react'
import { stopEnterPropagation } from '../../logic'
import { Hint } from '../Hint'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  containerClassName?: string
  disabled?: boolean
  hint?: string
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, containerClassName, disabled, hint, ...props }, ref) => {
    let checkboxStyle = 'h-4 w-4 mr-1 rounded transition-all ring-offset-color '
    let containerStyle = 'flex text-sm items-center '

    if (disabled) {
      checkboxStyle += ' text-icon-color border-input-disabled-color '
      checkboxStyle += props.checked ? '' : ' dark:bg-gray-500 '
      containerStyle += ' text-icon-color '
    } else {
      checkboxStyle += ' text-primary-500 border-checkbox-color focus:ring-primary-500 '
      checkboxStyle += props.checked ? '' : ' bg-input '
      containerStyle += ' text-main-color '
    }

    if (containerClassName) containerStyle += containerClassName

    return (
      <div className={containerStyle}>
        <label className={'flex items-center' + (hint ? ' mr-1' : '')}>
          <input
            ref={ref}
            type="checkbox"
            className={checkboxStyle}
            disabled={disabled}
            onKeyDown={stopEnterPropagation}
            {...props}
          />
          {label ?? ''}
        </label>
        {hint && <Hint>{hint}</Hint>}
      </div>
    )
  },
)
