import {
  useExtendBilling,
  useFetchBusinessPromoCode,
  useFetchBusinessTurnover,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesCount,
  useFetchLastBillingInfo,
} from '@expane/data'
import { useFetchPlans } from '@expane/data/src/gql/plan'
import { FEATURE_FLAGS, useFeatureFlags } from '@expane/logic/featureFlags'
import { Spinner } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { SettingsWrapper } from 'pages/SettingsPage/index'
import { FC, useState } from 'react'
import { useWayForPayScript } from 'services/wayforpay'
import { store } from 'store'
import { CurrentPlanInfo } from './CurrentPlanInfo'
import { CurrentRestrictions } from './CurrentRestrictions'
import { useFetchSubscriptionPeriods } from '../../../../../../packages/data/src/gql/subscriptionPeriod'
import { NotUsedPromoCode } from 'pages/SettingsPage/BillingSettings/NotUsedPromoCode'
import { PlanTabs } from 'pages/SettingsPage/BillingSettings/PlanTabs'
import { PlanCard } from 'pages/SettingsPage/BillingSettings/PlanCard'

export const BillingSettings: FC = () => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { getFeatureFlag } = useFeatureFlags()
  const isEnableCheckBillingTurnover = getFeatureFlag(FEATURE_FLAGS.enableCheckBillingTurnover)

  const { data: plans, isLoading: isPlansLoading } = useFetchPlans()
  const { data: subscriptionPeriods, isLoading: isSubscriptionPeriodsLoading } =
    useFetchSubscriptionPeriods()
  const { data: businessPromoCode, isLoading: isLoadingBusinessPromoCode } =
    useFetchBusinessPromoCode()

  const { mutateAsync: mutateExtendBilling, isLoading: mutateExtendBillingIsLoading } =
    useExtendBilling()

  const { isLoading: isBillingInfoLoading } = useFetchLastBillingInfo(timezone)
  const { isLoading: isTurnoverLoading } = useFetchBusinessTurnover(isEnableCheckBillingTurnover)
  const { isLoading: isEmployeeCountLoading } = useFetchEmployeesCount()
  const { isLoading: isLoadingMyPermissions } = useFetchMyPermissions()
  const [isLoadingWayForPayScript, isWayForPayError] = useWayForPayScript()

  const [activeTabId, setActiveTabId] = useState(1)

  const isLoading =
    isPlansLoading ||
    isBillingInfoLoading ||
    (isEnableCheckBillingTurnover && isTurnoverLoading) ||
    isEmployeeCountLoading ||
    isLoadingWayForPayScript ||
    isLoadingMyPermissions ||
    isSubscriptionPeriodsLoading ||
    isLoadingBusinessPromoCode ||
    !timezone

  if (isLoading) return <Spinner expandCentered />

  if (!plans) return null

  return (
    <SettingsWrapper>
      <CurrentPlanInfo />
      {businessPromoCode && <NotUsedPromoCode businessPromoCode={businessPromoCode} />}
      <CurrentRestrictions />

      <PlanTabs activeTabId={activeTabId} setActiveTabId={setActiveTabId} />

      <div className={'flex items-center mt-10 gap-4'}>
        {subscriptionPeriods?.map((subscriptionPeriod, index) => {
          return (
            <PlanCard
              key={index}
              plan={plans.find(plan => plan.id === activeTabId) ?? plans[0]}
              subscriptionPeriod={subscriptionPeriod}
              timezone={timezone}
              isWayForPayError={isWayForPayError}
              mutateExtendBilling={mutateExtendBilling}
              mutateExtendBillingIsLoading={mutateExtendBillingIsLoading}
            />
          )
        })}
      </div>
    </SettingsWrapper>
  )
}
