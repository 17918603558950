import {
  DayTiming,
  ScheduleDto,
  ScheduleType,
  ServerExtendedServiceType,
  ServerWaitingListType,
} from '@expane/data'
import { TreeMenuItem } from 'ui/TreeMenu'
import { getWeekDay } from '@expane/logic/calendar'
import { getHours, getMinutes, set } from '@expane/date'
import { transformPersonName } from '@expane/logic/utils'

export const setTime = (date: Date, time: number) => {
  if (Math.trunc(time) === time) {
    return set(date, { hours: time, minutes: 0 })
  } else {
    const minutes = (time - Math.trunc(time)) * 60
    return set(date, { hours: Math.trunc(time), minutes: minutes })
  }
}

export const getTimeForRange = (date: Date) => {
  return getHours(date) + getMinutes(date) / 60
}

export const defineWorkHours = (schedule: ScheduleDto | undefined, date: Date): DayTiming => {
  const closedDayTiming = [10, 21]

  if (schedule?.type === ScheduleType.WEEKLY)
    return schedule?.data[getWeekDay(date)] ?? (closedDayTiming as DayTiming)
  return schedule?.data.timing ?? [7, 22]
}

export const convertResponsibleEmployeesToTreeItems = (
  waitingList: ServerWaitingListType | undefined,
): TreeMenuItem[] =>
  waitingList?.responsibleEmployees
    .filter(({ employee }) => Boolean(employee))
    .map(({ employee }) => ({
      id: employee.id,
      name: transformPersonName(employee),
      parentId: employee.groupId ?? undefined,
    })) ?? []

export const transformWaitingListServiceDefaultValueTreeItem = (
  services: ServerExtendedServiceType[],
  cS?: { id: number; name: string },
) => {
  const service = services?.find(service => service.id === cS?.id)
  const parentId = service?.groupId

  return { ...service, parentId } as TreeMenuItem
}
