import { reportError, reportGqlError, request, useQuery } from '../../api'
import { gql } from 'graphql-request'
import { RECURRING_BOOKING_QUERY_KEY } from './queryKeys'
import { BOOKINGS_QUERY_KEY } from '.././booking/queryKeys'
import { TRANSACTIONS_QUERY_KEY } from '.././transaction/queryKeys'
import { toZonedTime } from '@expane/date'
import { recurringBookingByIdFragment } from './recurrningBooking.fragments'
import { ServerRecurringBookingByIdType } from '../../generated/graphql-types'

export function useFetchFutureRecurringBookingsByRecurringBookingId(
  id: number | undefined,
  startDate: Date | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerRecurringBookingByIdType[] | undefined>(
    [
      RECURRING_BOOKING_QUERY_KEY,
      BOOKINGS_QUERY_KEY,
      TRANSACTIONS_QUERY_KEY,
      id,
      { branchId },
      startDate,
    ],
    async () => {
      const result = await request(
        gql`
          ${recurringBookingByIdFragment}
          query ($id: Int!, $branchId: Int!, $startDate: timestamptz!) {
            recurringBookingById(id: $id) {
              bookings(
                order_by: { id: asc }
                where: { canceledDate: { _is_null: true }, startDate: { _gte: $startDate } }
              ) {
                ...recurringBookingByIdType
              }
            }
          }
        `,
        { id, branchId, startDate },
      )

      if (result?.recurringBookingById && timezone) {
        return result.recurringBookingById.bookings.map(booking => ({
          ...booking,
          startDate: toZonedTime(booking.startDate, timezone),
        }))
      }

      reportError(new Error('useFetchBookingsIdsByRecurringBookingId does not exist'), 'warning', {
        id,
        result,
      })
      return undefined
    },
    {
      queryName: 'useFetchBookingsIdsByRecurringBookingId',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone),
    },
  )
}
