import { TableBody, TableContainer, TableHeader, TableHeaderCell } from '@expane/ui'
import { translateTableTitles } from 'logic/utils'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { MODULES_SETTINGS_ROWS, MODULES_TITLES, ModuleSettingsControl } from './logic'
import { ModulesSettingsRow } from './ModulesSettingsRow'
import { ModulesSettingsRowTitle } from './ModulesSettingsRowTitle'

export const ModulesSettingsTable: FC<ModuleSettingsControl> = ({ control }) => {
  const { t } = useTranslation()

  return (
    <TableContainer>
      <TableHeader>
        <tr>
          {translateTableTitles(MODULES_TITLES, t).map(({ title, width }, index) => (
            <TableHeaderCell
              style={{ textAlign: 'start' }}
              key={index}
              className={width}
              borderless
            >
              {title}
            </TableHeaderCell>
          ))}
        </tr>
      </TableHeader>
      <TableBody>
        {MODULES_SETTINGS_ROWS.map(row =>
          row.type === 'title' ? (
            <ModulesSettingsRowTitle key={row.title} title={t(row.title)} />
          ) : (
            <ModulesSettingsRow
              key={row.name}
              name={row.name}
              moduleName={t(row.moduleName)}
              moduleDescr={t(row.moduleDescr)}
              control={control}
            />
          ),
        )}
      </TableBody>
    </TableContainer>
  )
}
