import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { Connections } from '../clientSettings'
import {
  CLIENT_NOTIFICATIONS_QUERY_KEY,
  DONE_NOTIFICATIONS_QUERY_KEY,
  NOTIFICATIONS_AGGREGATE_QUERY_KEY,
  NOTIFICATIONS_QUERY_KEY,
} from './queryKeys'
import { NOTIFICATION_STATUS } from './logic'

export function useSendNotification() {
  return useMutation(
    async ({
      clientId,
      message,
      messengers,
    }: {
      clientId: number
      message: string
      messengers: Connections
    }): Promise<{
      insertNotification: { id: number }
    }> => {
      return request(
        gql`
          mutation (
            $clientId: Int!
            $message: String!
            $telegramStatus: Int
            $viberStatus: Int
            $smsStatus: Int
          ) {
            insertNotification(
              object: {
                clientId: $clientId
                message: $message
                type: 1
                telegramStatus: $telegramStatus
                viberStatus: $viberStatus
                smsStatus: $smsStatus
              }
            ) {
              id
            }
          }
        `,
        {
          clientId,
          message,
          telegramStatus: messengers.telegram ? NOTIFICATION_STATUS.pending : null,
          viberStatus: messengers.viber ? NOTIFICATION_STATUS.pending : null,
          smsStatus: messengers.sms ? NOTIFICATION_STATUS.pending : null,
        },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([CLIENT_NOTIFICATIONS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useMakeDoneInternalNotifications() {
  return useMutation(
    async (
      ids: Array<number>,
    ): Promise<{
      updateNotifications: { affectedRows: number }
    }> => {
      return request(
        gql`
          mutation ($ids: [Int!]!, $doneStatus: smallint) {
            updateNotifications(where: { id: { _in: $ids } }, _set: { status: $doneStatus }) {
              affectedRows: affected_rows
            }
          }
        `,
        { ids, doneStatus: NOTIFICATION_STATUS.read },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([NOTIFICATIONS_AGGREGATE_QUERY_KEY])
        queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY])
        queryClient.invalidateQueries([DONE_NOTIFICATIONS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
