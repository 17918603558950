import { forwardRef } from 'react'
import { stopEnterPropagation } from '../../logic'
import { InputLabel } from '../InputLabel'

export interface SwitchProps {
  label?: string
  hint?: string
  containerClassName?: string
  checked?: boolean
  disabled?: boolean
  onChange: (isChecked: boolean) => void
  size?: 'normal' | 'big'
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    { label, hint, containerClassName, checked, disabled, onChange, size = 'normal', ...restProps },
    ref,
  ) => {
    let switchStyle =
      'flex items-center justify-start rounded-full relative transition-all ring-offset-1 focus:ring-1 focus:ring-primary-500 '
    let switchButtonStyle = 'rounded-full duration-200 bg-icon border '

    if (size === 'normal') {
      switchStyle += 'w-10 h-5 '
      switchButtonStyle += 'w-5 h-5 '
    } else {
      switchStyle += 'w-16 h-8 '
      switchButtonStyle += 'w-8 h-8 '
    }

    switchStyle += disabled ? '' : ' cursor-pointer'

    if (checked) {
      switchStyle += disabled
        ? ' bg-primary-200 dark:bg-primary-500/30'
        : ' bg-primary-500 border-primary-500'
      switchButtonStyle += disabled
        ? ' translate-x-full border-primary-200 dark:border-primary-400/50'
        : ' translate-x-full border-primary-500'
    } else {
      switchStyle += disabled ? ' bg-gray-200 dark:bg-gray-600' : ' bg-gray-400 dark:bg-gray-800'
      switchButtonStyle += disabled
        ? ' border-gray-200 dark:border-gray-400/50'
        : ' border-gray-500 dark:border-gray-800'
    }

    return (
      <div
        className={'flex items-center ' + (containerClassName ?? '')}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.stopPropagation()
            onChange(!checked)
          }
        }}
      >
        <label tabIndex={0} className="flex items-center" onKeyDown={stopEnterPropagation}>
          <div className={switchStyle}>
            <input
              disabled={disabled}
              checked={checked}
              onChange={e => onChange(e.target.checked)}
              type="checkbox"
              className="invisible w-0 h-0 absolute top-0 left-0"
              ref={ref}
              {...restProps}
            />
            <span className={switchButtonStyle} />
          </div>

          {label && <InputLabel className="ml-2" label={label} hint={hint} />}
        </label>
      </div>
    )
  },
)
