import { FC } from 'react'
import { IoTrashBinOutline } from 'react-icons/io5'

interface PresetItemProps {
  name: string
  isSelected: boolean
  onSelect: () => void
  onDelete: () => void
}

export const PresetItem: FC<PresetItemProps> = ({ name, isSelected, onSelect, onDelete }) => {
  let className = 'flex text-left text-sm transition-colors rounded-md'
  className += isSelected ? ' text-primary-500' : ' text-main-color bg-hover'

  return (
    <li className={className}>
      <button
        className={
          'h-full grow py-1.5 pl-1.5 text-left transition-colors ' +
          (isSelected ? '' : ' hover:text-primary-300')
        }
        onClick={onSelect}
      >
        {name}
      </button>
      <button onClick={onDelete} className="px-1.5 hover:text-error-400">
        <IoTrashBinOutline className="mx-1" />
      </button>
    </li>
  )
}
