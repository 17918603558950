import { useFetchStorages, useGetBranchDefaultStorageId } from '@expane/data'
import { Input } from '@expane/ui'
import { FC } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { ProductsMovementProps } from 'widgets/MovementCreateDialogs/ProductsMovementDialog'
import { StorageSelectDropdown } from 'widgets/StoragesSelectDropdown'

export const ProductsMovementInfo: FC<ProductsMovementProps> = ({ control }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId

  const { data: storages } = useFetchStorages(branchId)
  const { data: defaultStorageId } = useGetBranchDefaultStorageId(branchId)

  const watchedFromStorageId = useWatch({ control, name: 'fromStorageId' })

  return (
    <div className="flex">
      <Controller
        control={control}
        name="fromStorageId"
        rules={{ required: true }}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <StorageSelectDropdown
            label={t('fromStorage')}
            required
            className="w-1/3 pr-2"
            onSelectChange={onChange}
            value={value}
            storages={storages}
            defaultStorageId={defaultStorageId}
            errorMessage={{ isShown: Boolean(errors.fromStorageId), text: t('formError.required') }}
          />
        )}
      />

      <Controller
        control={control}
        name="toStorageId"
        rules={{
          required: true,
          validate: value => watchedFromStorageId !== value,
        }}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <StorageSelectDropdown
            label={t('toStorage')}
            required
            className="w-1/3 pr-2"
            onSelectChange={onChange}
            value={value}
            storages={storages}
            defaultStorageId={defaultStorageId}
            errorMessage={{
              isShown: Boolean(errors.toStorageId),
              text:
                errors.toStorageId?.type === 'required'
                  ? t('formError.required')
                  : t('formError.invalidToStorageId'),
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="movementNumber"
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Input
            required
            containerClassName="w-1/3"
            label={`${t('invoice')}, ${t('numberFormat', { value: '' })}`}
            value={value}
            onChange={onChange}
            errorMessage={{
              isShown: Boolean(errors.movementNumber),
              text: t('formError.required'),
            }}
          />
        )}
      />
    </div>
  )
}
