import {
  EmployeeByIdExtended,
  EmployeeFromGroup,
  EmployeeGroupWithSchedule,
  SalarySettingInsertInput,
  ServerExtendedServiceType,
  ServerSalarySettingType,
} from '@expane/data'
import { checkOnlyPositiveAmount } from '@expane/logic/form'
import { IoCardOutline, IoCashOutline } from 'react-icons/io5'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import { validateLessOrEqual100 } from 'logic/form'
import {
  checkAreEmployeesFirstSalarySetting,
  RATE_FROM_SERVICES_TYPES,
  SalarySettingServiceType,
} from '@expane/logic/salarySettings'
import { replaceDateTimezone } from '@expane/date'

export interface SalarySettingFormValues {
  start: Date
  end: Date
  rateType: { id: number; name: string }
  rateValue: string
  referralPercentage: string
  salarySettingServices: SalarySettingServiceType[]
  employees?: undefined | TreeMenuItem[]
}

export const transformSalarySettingServicesForForm = (
  salarySetting: ServerSalarySettingType,
): SalarySettingServiceType[] =>
  salarySetting.salarySettingServices.map(item => ({
    id: item.id,
    serviceId: item.service.id,
    name: item.service.name,
    type: item.type,
    value: item.value.toString(),
    fallbackType: item.fallbackType ?? undefined,
    fallbackValue: item.fallbackValue ? item.fallbackValue.toString() : '',
    dependsOnRealizedValue: item.dependsOnRealizedValue,
  }))

export const transformEmployeeToSalarySettingServicesForForm = (
  employeeById: EmployeeByIdExtended,
): SalarySettingServiceType[] =>
  employeeById.serviceEmployees.map(({ service }) => ({
    id: service.id,
    serviceId: service.id,
    name: service.name,
    type: RATE_FROM_SERVICES_TYPES.fixedValue.id,
    value: '0',
    fallbackType: undefined,
    fallbackValue: '',
    dependsOnRealizedValue: false,
  }))

export const transformEmployeesToSalarySettingServicesForForm = (
  services: ServerExtendedServiceType[] | undefined,
  watchedEmployees: TreeMenuItem[] | undefined,
): SalarySettingServiceType[] => {
  let result: SalarySettingServiceType[] = []

  if (!services || !watchedEmployees) return result

  const employeesIds = extractItemsFromFolders(watchedEmployees).map(({ id }) => id)

  // находим те услуги которые предоставляет хотя бы один сотрудник
  const filteredServicesByEmployeesIds = services.filter(({ serviceEmployees }) =>
    employeesIds.some(id => serviceEmployees.some(employee => employee.employee.id === id)),
  )

  result = filteredServicesByEmployeesIds.map(service => ({
    id: service.id,
    serviceId: service.id,
    name: service.name,
    type: RATE_FROM_SERVICES_TYPES.fixedValue.id,
    value: '0',
    fallbackType: undefined,
    fallbackValue: '',
    dependsOnRealizedValue: false,
  }))

  return result
}

export const transformGroupSalarySettingsDataToServer = (dto: {
  data: SalarySettingFormValues
  employeeGroups: EmployeeGroupWithSchedule[] | undefined
  salarySettings: ServerSalarySettingType[] | undefined
  branchId: number
  timezone: string
}): SalarySettingInsertInput[] => {
  // все сотрудники
  const employees = dto.employeeGroups?.map(eG => eG.employees).flat()

  // Выбранные сотрудники
  const checkedEmployees = extractItemsFromFolders(dto.data.employees as TreeMenuItem[])

  const commonSettings: Pick<
    SalarySettingInsertInput,
    'rateType' | 'rateValue' | 'start' | 'referralPercentage' | 'branchId'
  > = {
    rateType: dto.data.rateType.id,
    rateValue: Number(dto.data.rateValue),
    start: replaceDateTimezone(dto.data.start, dto.timezone),
    referralPercentage: Number(dto.data.referralPercentage),
    branchId: dto.branchId,
  }

  const salarySettingServices = dto.data.salarySettingServices
    // Исключаем все настройки услуг, где значение - 0
    .filter(item => Number(item.value) !== 0)
    .map(item => ({
      serviceId: item.serviceId,
      type: item.type,
      value: Number(item.value),
      dependsOnRealizedValue: item.dependsOnRealizedValue,
      fallbackType: item.dependsOnRealizedValue && item.fallbackType ? item.fallbackType : null,
      fallbackValue:
        item.dependsOnRealizedValue && item.fallbackValue ? Number(item.fallbackValue) : null,
    }))

  const salarySettingInsertInputs: SalarySettingInsertInput[] = []

  for (let i = 0; i < checkedEmployees.length; i += 1) {
    // Сервисы которые сотрудник предоставляет
    const employeeServicesIds = employees
      ?.find(employee => employee.id === checkedEmployees[i].id)
      ?.serviceEmployees.map(({ serviceId }) => serviceId)

    // Если у сотрудника нет предоставляемых услуг, то просто записываем общие настройки ЗП
    if (!employeeServicesIds) {
      salarySettingInsertInputs.push({
        employeeId: checkedEmployees[i].id,
        ...commonSettings,
      })
      continue
    }

    // Предидущие настройки ЗП сотрудника
    const salarySettings = dto.salarySettings?.find(
      item => item.employeeId === checkedEmployees[i].id,
    )

    // фильтруем только те сервисы, которые предоставляют сотрудники
    const newSalarySettingServices = salarySettingServices.filter(({ serviceId }) =>
      employeeServicesIds.includes(serviceId),
    )

    if (salarySettings && salarySettings.salarySettingServices) {
      for (let j = 0; j < salarySettings.salarySettingServices.length; j += 1) {
        const isServiceInNewSettings = Boolean(
          newSalarySettingServices.find(
            ({ serviceId }) => serviceId === salarySettings.salarySettingServices[j].service.id,
          ),
        )
        // Если нет новых настроек, то вставляем старые
        if (!isServiceInNewSettings)
          newSalarySettingServices.push({
            serviceId: salarySettings.salarySettingServices[j].service.id,
            value: salarySettings.salarySettingServices[j].value,
            dependsOnRealizedValue: salarySettings.salarySettingServices[j].dependsOnRealizedValue,
            type: salarySettings.salarySettingServices[j].type,
            fallbackType: salarySettings.salarySettingServices[j].fallbackType ?? null,
            fallbackValue: salarySettings.salarySettingServices[j].fallbackValue ?? null,
          })
      }
    }

    salarySettingInsertInputs.push({
      employeeId: checkedEmployees[i].id,
      ...commonSettings,
      salarySettingServices: { data: newSalarySettingServices },
    })
  }

  return salarySettingInsertInputs
}

export const validateServiceRateSetting = (
  value: string | undefined,
  rateType: number | undefined,
  dependOnRealized: boolean,
) => {
  if (!checkOnlyPositiveAmount(value)) return false

  if (value === undefined) return false

  if (dependOnRealized && Number(value) === 0) return false

  if (rateType && rateType !== RATE_FROM_SERVICES_TYPES.fixedValue.id)
    return validateLessOrEqual100(value)

  return true
}

export const PREFERRED_PAYMENT_OPTIONS = [
  { id: '1', name: 'salary.cash', Icon: IoCashOutline },
  { id: '2', name: 'salary.cashless', Icon: IoCardOutline },
]

export const checkAreEmployeesFromGroupsFirstSalarySetting = (
  employeeGroups: EmployeeGroupWithSchedule[] | undefined,
  employeeIds: number[],
) => {
  if (!employeeGroups) return undefined

  const employees: EmployeeFromGroup[] = []

  for (const group of employeeGroups) {
    for (const employee of group.employees) {
      const selectedEmployeeFullInfo = employeeIds.find(id => id === employee.id)

      if (selectedEmployeeFullInfo) employees.push(employee)
    }
  }

  return checkAreEmployeesFirstSalarySetting(employees)
}
