import { useFetchArchivedTags } from '@expane/data'
import { Page } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { TagsList } from 'pages/TagsPage/List'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ListNavigationButton } from 'widgets/NavigationButtons'
import { TagsDialog } from 'widgets/TagDialog'

export const ArchiveTagsPage: FC = () => {
  const { t } = useTranslation()

  const { data, isLoading } = useFetchArchivedTags()

  const { openEditDialog, dialog } = useOpenDialog(TagsDialog)

  return (
    <Page>
      <ListNavigationButton title={t('tags')} className="mb-2" />

      <TagsList
        onRowClick={tag => openEditDialog(tag.id)}
        tags={data}
        isLoading={isLoading}
        archive
      />

      {dialog}
    </Page>
  )
}
