import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../api'
import { ServerMutationRootBranchImportServicesArgs } from '../generated/graphql-types'
import { SERVICES_QUERY_KEY } from './service/queryKeys'
import { SERVICE_GROUPS_QUERY_KEY } from './serviceGroup/queryKeys'

export function useBranchImportServices() {
  return useMutation(
    (dto: ServerMutationRootBranchImportServicesArgs) => {
      return request(
        gql`
          mutation (
            $serviceDtos: [ServiceImportDto!]!
            $serviceGroupIds: [Int!]!
            $toBranchId: Int!
          ) {
            branchImportServices(
              serviceDtos: $serviceDtos
              serviceGroupIds: $serviceGroupIds
              toBranchId: $toBranchId
            ) {
              success
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes(SERVICES_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes(SERVICE_GROUPS_QUERY_KEY),
        })
      },
    },
  )
}
