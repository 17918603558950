import { usePopupOpenState } from '@expane/ui'
import { FC, useRef } from 'react'

export type OnCreateFunc<DTO> = (id: number, dto?: DTO) => void

export interface DialogProps<DTO = Record<string, unknown>> {
  closeDialog: () => void
  isCreate: boolean
  id?: number
  onCreate?: OnCreateFunc<DTO>
}

export const useOpenDialog = <OnCreateDto = Record<string, unknown>,>(
  Component: FC<DialogProps<OnCreateDto>>,
) => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogId = useRef<number | undefined>()
  const dialogIsCreate = useRef<boolean>(false)
  const dialogOnCreate = useRef<(id: number) => void>()
  const onCloseCreateDialog = useRef<() => void>()
  const onCloseEditDialog = useRef<() => void>()

  const openEditDialog = (id: number, onClose?: () => void) => {
    dialogId.current = id
    dialogIsCreate.current = false
    onCloseEditDialog.current = onClose
    openPopup()
  }

  const openCreateDialog = (onCreate?: OnCreateFunc<OnCreateDto>, onClose?: () => void) => {
    dialogId.current = undefined
    dialogIsCreate.current = true
    dialogOnCreate.current = onCreate
    onCloseCreateDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()

    if (dialogIsCreate.current) {
      if (onCloseCreateDialog.current) onCloseCreateDialog.current()
    }

    if (!dialogIsCreate.current) {
      if (onCloseEditDialog.current) onCloseEditDialog.current()
    }
  }

  const dialog = isOpen ? (
    <Component
      closeDialog={closeDialog}
      id={dialogId.current}
      isCreate={dialogIsCreate.current}
      onCreate={dialogOnCreate.current}
    />
  ) : null

  return { openEditDialog, openCreateDialog, dialog }
}
