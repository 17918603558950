import { gql } from 'graphql-request'
import { DEFAULT_TIMEZONE, isFuture } from '@expane/date'
import { queryClient, request, useMutation, useQuery, reportError, reportGqlError } from '../../api'
import {
  ServerClientInsertInput,
  ServerWaitingListEmployeeInsertInput,
  ServerWaitingListInsertInput,
  ServerWaitingListSetInput,
  ServerWaitingListType,
} from '../../generated/graphql-types'
import { SERVICES_QUERY_KEY } from '../service/queryKeys'
import { EMPLOYEES_QUERY_KEY } from '../employee/queryKeys'
import { CLIENTS_QUERY_KEY } from '../client/queryKeys'
import { waitingListFragment } from './waitingList.fragments'
import { WAITING_LISTS_QUERY_KEY } from './queryKeys'
import { parseDatesInWaitingListGqlResponse } from './logic'

export function useFetchWaitingLists(timezone: string | undefined, branchId: number | undefined) {
  return useQuery(
    [
      WAITING_LISTS_QUERY_KEY,
      SERVICES_QUERY_KEY,
      EMPLOYEES_QUERY_KEY,
      CLIENTS_QUERY_KEY,
      { branchId },
    ],
    async (): Promise<ServerWaitingListType[]> => {
      const result = await request(
        gql`
          ${waitingListFragment}
          query ($branchId: Int!) {
            waitingLists(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, closedAt: { _is_null: true } }
            ) {
              ...waitingListType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.waitingLists)) {
        // TODO: Two cycles - can be optimized
        return result.waitingLists
          .map(wl => parseDatesInWaitingListGqlResponse(wl, timezone ?? DEFAULT_TIMEZONE))
          .filter(el => isFuture(el.end))
      } else {
        reportError(new Error('waitingLists is not an array'), 'warning', { result })
        return []
      }
    },
    {
      enabled: Boolean(timezone) && Boolean(branchId),
      queryName: 'useFetchWaitingLists',
      onError: reportGqlError,
      onSuccess: waitingLists =>
        waitingLists.forEach(waitingList =>
          queryClient.setQueryData(
            [
              WAITING_LISTS_QUERY_KEY,
              SERVICES_QUERY_KEY,
              EMPLOYEES_QUERY_KEY,
              CLIENTS_QUERY_KEY,
              waitingList.id,
            ],
            waitingList,
          ),
        ),
    },
  )
}

export function useFetchWaitingListById(id: number | undefined, timezone: string | undefined) {
  return useQuery(
    [WAITING_LISTS_QUERY_KEY, SERVICES_QUERY_KEY, EMPLOYEES_QUERY_KEY, CLIENTS_QUERY_KEY, id],
    async (): Promise<ServerWaitingListType | undefined> => {
      const result = await request(
        gql`
          ${waitingListFragment}
          query ($id: Int!) {
            waitingListById(id: $id) {
              ...waitingListType
            }
          }
        `,
        { id },
      )

      if (result?.waitingListById) {
        return parseDatesInWaitingListGqlResponse(
          result.waitingListById,
          timezone ?? DEFAULT_TIMEZONE,
        )
      }

      reportError(new Error('waitingListById does not exist'), 'warning', { id, result })
      return undefined
    },
    {
      queryName: 'useFetchWaitingListById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone),
    },
  )
}

export function useUpdateWaitingList() {
  return useMutation(
    async (dto: {
      id: number
      waitingListSetInput: ServerWaitingListSetInput
      serverWaitingListEmployeeInsertInput?: ServerWaitingListEmployeeInsertInput[]
    }): Promise<{ updateWaitingListById?: { id?: number } }> => {
      return request(
        gql`
          mutation (
            $waitingListSetInput: waitingList_set_input!
            $id: Int!
            $serverWaitingListEmployeeInsertInput: [waitingListEmployee_insert_input!]!
            $isWaitingListsEmployeesUpdate: Boolean!
          ) {
            updateWaitingListById(pk_columns: { id: $id }, _set: $waitingListSetInput) {
              id
            }
            deleteWaitingListsEmployees(where: { waitingListId: { _eq: $id } })
              @include(if: $isWaitingListsEmployeesUpdate) {
              affected_rows
            }
            insertWaitingListsEmployees(objects: $serverWaitingListEmployeeInsertInput)
              @include(if: $isWaitingListsEmployeesUpdate) {
              affected_rows
            }
          }
        `,
        {
          ...dto,
          serverWaitingListEmployeeInsertInput: dto.serverWaitingListEmployeeInsertInput ?? [],
          isWaitingListsEmployeesUpdate: Boolean(dto?.serverWaitingListEmployeeInsertInput),
        },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([WAITING_LISTS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useCreateWaitingList() {
  return useMutation(
    async (dto: {
      waitingListInsertInput: ServerWaitingListInsertInput &
        Required<Pick<ServerWaitingListInsertInput, 'branchId'>>
      clientInsertInput?: ServerClientInsertInput
    }): Promise<{ insertWaitingList?: { id?: number } }> => {
      if (dto.clientInsertInput) {
        return request(
          gql`
            mutation (
              $serviceId: Int!
              $employeeId: Int
              $start: timestamptz
              $end: timestamptz
              $description: String
              $clientInsertInput: client_insert_input!
            ) {
              insertWaitingList(
                object: {
                  client: { data: $clientInsertInput }
                  serviceId: $serviceId
                  employeeId: $employeeId
                  start: $start
                  end: $end
                  description: $description
                }
              ) {
                id
              }
            }
          `,
          {
            serviceId: dto.waitingListInsertInput.serviceId,
            employeeId: dto.waitingListInsertInput.employeeId,
            start: dto.waitingListInsertInput.start,
            end: dto.waitingListInsertInput.end,
            description: dto.waitingListInsertInput.description,
            clientsInsertInput: dto.clientInsertInput,
          },
        )
      }

      return request(
        gql`
          mutation ($waitingListInsertInput: waitingList_insert_input!) {
            insertWaitingList(object: $waitingListInsertInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WAITING_LISTS_QUERY_KEY])
        queryClient.invalidateQueries([CLIENTS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
