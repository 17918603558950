import { ServerExtendedServiceType, ServerServiceBriefWithProductType } from '@expane/data'
import { TFunction } from 'i18next'
import { transformPersonName } from '@expane/logic/utils'
import { TreeMenuItem } from 'ui/TreeMenu'
import { findTreeMenuItem } from 'ui/TreeMenu/logic.common'
import { getIsGroupService } from '@expane/logic/service'
import { ProductsAmount } from '@expane/logic/movement'

export const convertServicesToTreeItems = (
  services: {
    groupId?: number | null
    id: number
    name: string
    defaultDuration: number
  }[],
): ServiceGroupTreeItem[] => {
  return services.map(service => ({
    ...service,
    parentId: service.groupId ?? undefined,
  }))
}

export type InactiveReasonForService = {
  locations?: { id: number; name: string }[]
  employees?: { id: number; name: string }[]
}

export interface ServiceGroupTreeItem extends TreeMenuItem {
  defaultDuration: number
  customInactiveReason?: InactiveReasonForService
  nodes?: ServiceGroupTreeItem[]
}

type ServiceWithInactiveReason = ServerExtendedServiceType & {
  customInactiveReason?: InactiveReasonForService
  inactiveReason?: string
  warningMessage?: string
  disabled?: boolean
}

export const addInactiveReasonsAndWarningMessagesForServices = ({
  services,
  locationId,
  employeeId,
  leftovers,
  userId,
  t,
}: {
  services: ServerExtendedServiceType[]
  locationId: number | undefined
  employeeId: number | undefined | null
  leftovers: ProductsAmount | undefined
  userId: number
  t: TFunction
}): ServiceWithInactiveReason[] => {
  const inactiveByLocation: ServiceWithInactiveReason[] = services.map(service => {
    const isActiveByLocation = service.serviceLocations.some(
      serviceLocation => serviceLocation.location?.id === locationId,
    )
    if (isActiveByLocation) return service

    const locations = service.serviceLocations.map(serviceLocation => serviceLocation.location)

    return {
      ...service,
      status: 'inactive',
      customInactiveReason: {
        locations,
      },
    }
  })

  const inactiveByEmployee: ServiceWithInactiveReason[] = inactiveByLocation.map(service => {
    const isActiveByEmployee = employeeId
      ? service.serviceEmployees?.some(
          serviceEmployee => serviceEmployee.employee?.id === employeeId,
        )
      : !service.serviceEmployees?.length
    if (isActiveByEmployee) return service

    const employees = service.serviceEmployees.map(serviceEmployee => ({
      id: serviceEmployee.employee.id,
      name: transformPersonName(serviceEmployee.employee),
    }))

    return {
      ...service,
      status: 'inactive',
      customInactiveReason: service.customInactiveReason
        ? { ...service.customInactiveReason, employees }
        : { employees },
    }
  })

  const inactiveByConsumables = leftovers
    ? inactiveByEmployee.map(service => {
        const missingProducts = service.serviceProducts.filter(
          serviceProduct => (leftovers[serviceProduct.product.id] ?? 0) < serviceProduct.quantity,
        )

        if (missingProducts.length === 0) return service

        const missingProductNames = missingProducts.map(
          missingProduct => missingProduct.product.name,
        )

        return {
          ...service,
          warningMessage: `${t('notEnoughConsumables')}: ` + missingProductNames.join(', '),
        }
      })
    : inactiveByEmployee

  const inactiveByUser = markInactiveServicesByUser(
    inactiveByConsumables,
    userId,
    t('noPermissionForService'),
  )

  return inactiveByUser
}

type ServiceWithDisabled = ServiceWithInactiveReason & {
  disabled?: boolean
}

export const addDisabledForServices = (
  services: ServiceWithInactiveReason[],
  paidServices: { id: number }[],
): ServiceWithDisabled[] => {
  const result: ServiceWithDisabled[] = services.map(service => {
    if (
      paidServices.some(paidService => service.id === paidService.id) ||
      service.customInactiveReason
    )
      return { ...service, disabled: true }

    return service
  })

  return result
}

const markInactiveServicesByUser = (
  services: ServiceWithInactiveReason[],
  userId: number,
  noPermissionMessage: string,
): ServiceWithInactiveReason[] => {
  return services.map(service => {
    // if no permissions provided - all employees can attach this service
    if (service.servicePermissions.length === 0) return service

    if (
      service.servicePermissions.some(
        servicePermission => servicePermission.employee?.id === userId,
      )
    )
      return service

    return {
      ...service,
      warningMessage: noPermissionMessage,
      disabled: true,
      // We don't need to display inactive reason if user doesn't have a permission to add this service
      customInactiveReason: undefined,
    }
  })
}

export const filterInactiveItemsFromSelected = (
  selectedItems: ServiceGroupTreeItem[],
  allItems: ServiceGroupTreeItem[],
) => {
  const result: ServiceGroupTreeItem[] = []

  for (const selectedItem of selectedItems) {
    const thisItemInAll = findTreeMenuItem(
      selectedItem.id,
      Boolean(selectedItem.isFolder),
      allItems,
    )
    if (thisItemInAll) {
      if (!thisItemInAll.inactiveReason && !thisItemInAll.customInactiveReason)
        result.push({ ...selectedItem })
    }
  }

  return result
}
export const getServicesForTreeMenu = ({
  checkArchived,
  services,
  archivedServices,
  servicesFromBooking,
}: {
  services: ServerExtendedServiceType[] | undefined
  archivedServices: ServerExtendedServiceType[] | undefined
  servicesFromBooking: ServerServiceBriefWithProductType[]
  checkArchived: boolean
}) => {
  const notGroupServices = services?.filter(service => !getIsGroupService(service.type)) ?? []

  if (checkArchived) {
    const notGroupArchivedServices =
      archivedServices?.filter(service => !getIsGroupService(service.type)) ?? []
    const archivedServicesInBooking = filterArchivedServicesByPickedInBooking(
      notGroupArchivedServices,
      servicesFromBooking,
    )

    return [...notGroupServices, ...archivedServicesInBooking]
  }

  return notGroupServices
}
const filterArchivedServicesByPickedInBooking = (
  archivedServices: ServerExtendedServiceType[],
  servicesFromBooking: ServerServiceBriefWithProductType[],
) =>
  archivedServices.filter(archivedService =>
    servicesFromBooking.some(servicesFromBooking => servicesFromBooking.id === archivedService.id),
  )
