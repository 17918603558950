import {
  ErrorMessage,
  ErrorMessageType,
  FadeInTranslateTop,
  getCommonSelectButtonStyle,
  InputLabel,
  modalsHTMLElement,
  useHandleClickOutside,
  usePopupOpenState,
} from '@expane/ui'
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react-dom'
import { KeyboardEvent, ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { DisplayedItemsOptions } from '../MultiSelect'
import { TreeMenu, TreeMenuItem } from '../TreeMenu'
import { onChangeMultiTreeMenu, onChangeSingleTreeMenu } from '../TreeMenu/logic.onChange'
import { MultiPickModeButton, SinglePickModeButton } from './buttons'

type TypeTreeSelect<MTreeMenuItem extends TreeMenuItem> =
  | {
      type: 'MultiPickMode'
      selected: MTreeMenuItem[] | undefined
      onSelected: (items: MTreeMenuItem[]) => void
    }
  | {
      type: 'SinglePickMode'
      selected: MTreeMenuItem | undefined
      onSelected: (item: MTreeMenuItem | undefined) => void
    }

type GeneralTreeSelectProps<MTreeMenuItem extends TreeMenuItem> = {
  items: MTreeMenuItem[]
  className?: string
  disabled?: boolean
  errorMessage?: ErrorMessageType
  customModal?: HTMLElement | null
  label?: string
  hint?: string
  onPlusClick?: () => void
  required?: boolean
  placeholder?: string
  displayedItems?: DisplayedItemsOptions
  size?: 'small' | 'default'
  isFilter?: boolean
  customSearchFilterFunction?: (
    items: MTreeMenuItem[] | undefined,
    searchValue: string,
  ) => MTreeMenuItem[]
  customInactiveReasonRender?: (item: MTreeMenuItem) => ReactElement | null
}

export type TreeSelectProps<MTreeMenuItem extends TreeMenuItem> = TypeTreeSelect<MTreeMenuItem> &
  GeneralTreeSelectProps<MTreeMenuItem>

export const TreeSelect = <MTreeMenuItem extends TreeMenuItem>(
  props: TreeSelectProps<MTreeMenuItem>,
) => {
  const {
    disabled = false,
    errorMessage,
    className,
    customModal,
    label,
    onPlusClick,
    hint,
    required,
    items,
    placeholder,
    displayedItems = 3,
    size = 'default',
    isFilter = false,
    customSearchFilterFunction,
    customInactiveReasonRender,
  } = props

  let selectedItems: MTreeMenuItem[] = []

  if (props.type === 'MultiPickMode') {
    if (props.selected) selectedItems = props.selected
  }
  if (props.type === 'SinglePickMode') {
    if (props.selected) selectedItems = [props.selected]
  }

  const { isOpen, closePopup, openPopup } = usePopupOpenState()

  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const [filterRef, setFilterRef] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isOpen && filterRef) {
      filterRef.getElementsByTagName('input')[0]?.focus()
    }
  }, [isOpen, filterRef])

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (e.key === 'Tab') {
      buttonRef.current?.focus()
      closePopup()
    }
    if (e.key === 'Escape') {
      buttonRef.current?.focus()
      closePopup()
    }
  }

  useHandleClickOutside([buttonRef.current, filterRef], closePopup, isOpen)

  const { reference, floating, strategy, x, y } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [offset(5), flip()],
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  useLayoutEffect(() => {
    reference(buttonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, buttonRef.current])
  useLayoutEffect(() => {
    floating(filterRef)
  }, [filterRef, floating])

  const filterBtnStyle = getCommonSelectButtonStyle({
    isDisabled: disabled,
    isOpen,
    isError: Boolean(errorMessage?.isShown),
  })

  const handlePopUp = () => {
    if (isOpen) {
      closePopup()
    } else {
      openPopup()
    }
  }

  const handleSelectedItems = (item: MTreeMenuItem) => {
    if (item.disabled || item.status === 'inactive') return

    if (props.type === 'SinglePickMode') {
      props.onSelected(onChangeSingleTreeMenu<MTreeMenuItem>(item, selectedItems?.[0]))
      handlePopUp()
    } else {
      props.onSelected(onChangeMultiTreeMenu<MTreeMenuItem>(item, selectedItems, items))
    }
  }

  let popupContainerStyle =
    'border-2 border-primary-400 overflow-y-auto rounded-md box-border bg-block '
  popupContainerStyle += size === 'small' ? 'max-h-52' : 'max-h-80'

  return (
    <div className={className}>
      <div>
        <InputLabel label={label} hint={hint} required={required} onPlusClick={onPlusClick} />
        <button
          ref={buttonRef}
          className={filterBtnStyle}
          disabled={disabled}
          onFocus={() => {
            if (!isOpen) {
              openPopup()
            }
          }}
          onClick={() => {
            if (!isOpen) openPopup()
          }}
          onKeyDown={e => {
            if (isOpen) {
              if (e.key === 'Tab') {
                closePopup()
              } else {
                filterRef?.getElementsByTagName('input')?.[0].focus()
              }
            }
          }}
        >
          {props.type === 'MultiPickMode' ? (
            <MultiPickModeButton
              isOpen={isOpen}
              onSelected={props.onSelected}
              selectedItems={selectedItems}
              items={items}
              disabled={disabled}
              placeholder={placeholder}
              isFilter={isFilter}
              displayedItems={displayedItems}
            />
          ) : (
            <SinglePickModeButton
              selectedItem={selectedItems[0]}
              disabled={disabled}
              placeholder={placeholder}
              isFilter={isFilter}
            />
          )}
        </button>
        <ErrorMessage errorMessage={errorMessage} />
      </div>
      {isOpen &&
        createPortal(
          <div
            ref={setFilterRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            onKeyDown={handleKeyDown}
            tabIndex={0}
          >
            <FadeInTranslateTop
              className={popupContainerStyle}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="tree-items-list"
              style={{ minWidth: buttonRef.current?.offsetWidth }}
            >
              {props.type === 'MultiPickMode' ? (
                // условие нужно для того чтобы TS явно понимал какой type и selected передаем в TreeMenu
                <TreeMenu
                  type={props.type}
                  items={items}
                  selected={props.selected}
                  onSelected={handleSelectedItems}
                  className="text-sm pt-1"
                  // анимация не работает если у инпута autoFocus
                  searchInputAutoFocus={false}
                  customSearchFilterFunction={customSearchFilterFunction}
                  customInactiveReasonRender={customInactiveReasonRender}
                />
              ) : (
                <TreeMenu
                  type={props.type}
                  items={items}
                  selected={props.selected}
                  onSelected={handleSelectedItems}
                  className="text-sm pt-1"
                  // анимация не работает если у инпута autoFocus
                  searchInputAutoFocus={false}
                  customInactiveReasonRender={customInactiveReasonRender}
                  customSearchFilterFunction={customSearchFilterFunction}
                />
              )}
            </FadeInTranslateTop>
          </div>,
          customModal ?? modalsHTMLElement,
        )}
    </div>
  )
}
