import { FC } from 'react'
import { CalendarContainer } from 'ui/Calendar'
import { generateWeeklyColumns } from 'pages/BookingsPage/BookingsCalendar/logic'
import { SchedulePageCalendarColumn } from 'pages/SchedulesPage/ScheduleCalendar/Column'
import { SchedulePageCalendarEmployeeColumn } from 'pages/SchedulesPage/ScheduleCalendar/EmployeeColumn'
import { useFetchBranchById, useFetchEmployeesGroups } from '@expane/data'
import { Control } from 'react-hook-form'
import { EmployeesScheduleDynamicDatesForm } from 'pages/SchedulesPage/index'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { useCurrentTime } from 'logic/hooks/useCurrentTime'

interface ScheduleCalendarProps {
  pickedDate: Date
  control: Control<EmployeesScheduleDynamicDatesForm>
}

export const SchedulePageCalendar: FC<ScheduleCalendarProps> = observer(
  ({ pickedDate, control }) => {
    const branchId = store.branch.branchId
    const { data: branch } = useFetchBranchById(branchId)
    const { data: employeeGroups } = useFetchEmployeesGroups(branch?.timezone, branchId)
    const branchSchedule = branch?.schedule

    const currentTime = useCurrentTime()

    const columns = generateWeeklyColumns(pickedDate)

    if (!employeeGroups || !branchSchedule) return null

    return (
      <CalendarContainer fullWidth>
        <SchedulePageCalendarEmployeeColumn employeeGroups={employeeGroups} />

        {columns.map((column, index) => (
          <SchedulePageCalendarColumn
            key={column.id}
            column={column}
            currentTime={currentTime}
            employeeGroups={employeeGroups}
            isLast={index === columns.length - 1}
            control={control}
            branchSchedule={branchSchedule}
          />
        ))}
      </CalendarContainer>
    )
  },
)
