import { FC } from 'react'
import { IoArrowForwardOutline } from 'react-icons/io5'

interface ArrowButtonProps {
  arrowDirection: 'left' | 'right'
  active: boolean
  onClick: () => void
}

export const ArrowButton: FC<ArrowButtonProps> = ({ onClick, arrowDirection, active }) => {
  let buttonClassName = 'w-6 h-6 flex-centered border-2 rounded-full transition'
  if (arrowDirection === 'left') buttonClassName += ' -rotate-180'
  buttonClassName += active
    ? ' text-primary-400 border-primary-400'
    : ' text-gray-400 border-white pointer-events-none'

  return (
    <div className={buttonClassName} onClick={onClick}>
      <IoArrowForwardOutline />
    </div>
  )
}
