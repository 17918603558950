import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  PlaceholderString,
  Spinner,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const TransactionDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('transaction.name')}</Dialog.Title>

      <Dialog.Body className="w-148">
        <div className="flex">
          <div className="mb-3 mr-3">
            <p className={labelStyle}>{`${t('dateTitle')}:`}</p>
            <p className={labelStyle}>{`${t('type')}:`}</p>
            <p className={labelStyle}>{`${t('additionalInfo.short')}:`} </p>
          </div>

          <div>
            <PlaceholderString className="mb-2" />
            <PlaceholderString className="mb-2" />
            <PlaceholderString />
          </div>
        </div>
        <div className="h-16">
          <Spinner expandCentered />
        </div>
        <div className="flex items-center w-38 mt-3 ml-auto text-right text-main-color">
          {t('amount')} <PlaceholderString />
        </div>
      </Dialog.Body>

      <Dialog.Footer>
        <CloseButton onClick={closeDialog} className="mr-auto" />
      </Dialog.Footer>
    </>
  )
}

const labelStyle = 'mr-1 text-gray-400 text-sm mb-1'
