import {
  MOVEMENT_TYPES,
  ServerAccountType,
  ServerMovementType,
  SUPPLIERS_MUTUAL_SETTLEMENTS,
  TRANSACTION_TYPES,
  TransactionFromAccount,
  useCreateTransaction,
  useCreateTransactionsFromAccount,
  useFetchAccounts,
  useFetchCurrentBranchTimezone,
  useFetchMovementById,
  useUpdateMovementStatus,
} from '@expane/data'
import { useConvertNumberToMoney, useConvertNumberToMoneyCode } from '@expane/logic/currency'
import {
  getFinancialInfoAboutMovement,
  getMovementFulfilledStatus,
  getMovementTypesName,
  MOVEMENT_DISCOUNT_TYPES,
} from '@expane/logic/movement'
import { permissions } from '@expane/logic/permission'
import { convertUnitValueFromServer, getUnitsName } from '@expane/logic/product'
import { findById, transformPersonName } from '@expane/logic/utils'
import { Button, Checkbox, CloseButton, Dialog, Modal, Table } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { ColumnDef } from '@tanstack/react-table'
import { useFetchMyPermissions } from 'gql/employee'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { queryClient } from 'services/api'
import { reportError } from 'services/sentry'
import { store } from 'store'
import { SubmitButton } from 'widgets/Buttons'
import { InfoBlock } from 'widgets/MovementInfoDialog/InfoBlock'
import { MovementInfoDialogPlaceholder } from 'widgets/MovementInfoDialog/MovementInfoDialogPlaceholder'
import { PaymentBlock } from 'widgets/MovementInfoDialog/PaymentBlock'

export interface MovementInfoDialogFormValues {
  accountId: number
  amount: string
  fulfilled: boolean
  toPay: boolean
}

export const MovementInfoDialog: FC<DialogProps> = observer(({ closeDialog, id }) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: movement, isLoading: isLoadingMovement } = useFetchMovementById(id, timezone)
  const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)
  const { data: myPermission, isLoading: isLoadingMyPermission } = useFetchMyPermissions()

  const isLoading = isLoadingMovement || isLoadingAccounts || isLoadingMyPermission || !timezone
  const isNoData = !movement || !accounts || !myPermission || !branchId

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
  if (!isLoading && isNoData) return null

  return (
    <Modal close={closeDialog}>
      <Dialog>
        {isLoading ? (
          <MovementInfoDialogPlaceholder closeDialog={closeDialog} />
        ) : (
          <MovementInfoDialogLogic
            closeDialog={closeDialog}
            movement={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              movement!
            }
            accounts={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              accounts!
            }
            myPermission={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              myPermission!
            }
            branchId={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              branchId!
            }
          />
        )}
      </Dialog>
    </Modal>
  )
})

interface MovementInfoDialogLogicProps extends Pick<DialogProps, 'closeDialog'> {
  movement: ServerMovementType
  accounts: ServerAccountType[]
  myPermission: string[]
  branchId: number
}
type MovementProduct = ServerMovementType['movementProducts'][0]

export const MovementInfoDialogLogic: FC<MovementInfoDialogLogicProps> = ({
  closeDialog,
  movement,
  accounts,
  myPermission,
  branchId,
}) => {
  const { t } = useTranslation()
  const convertNumberToMoneyCode = useConvertNumberToMoneyCode({ branchId })
  const convertNumberToMoney = useConvertNumberToMoney(branchId)
  const dateFormatting = useDateFormatting()

  const { mutateAsync: createTransactionsFromAccount } = useCreateTransactionsFromAccount()
  const { mutateAsync: createTransaction } = useCreateTransaction()
  const { mutateAsync: updateMovementStatus } = useUpdateMovementStatus()
  const movementFulfilledStatus = getMovementFulfilledStatus(movement, t)

  const { control, handleSubmit, formState } = useForm<MovementInfoDialogFormValues>({
    defaultValues: { toPay: false },
  })

  const [openSnackBar] = useSnackbar()
  const columns = useMemo<ColumnDef<MovementProduct>[]>(
    () => [
      {
        accessorKey: 'product.name',
        header: t('name'),
        cell: data => data.getValue(),
        size: 300,
      },
      {
        accessorKey: 'price',
        header: () => <span className="w-full text-right">{t('price')}</span>,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
        size: 160,
      },
      {
        id: 'quantity',
        header: () => <span className="text-right w-full">{t('qty')}</span>,
        accessorFn: ({ quantity, product }) => {
          return (
            convertUnitValueFromServer(quantity, product.unit) + ' ' + getUnitsName(product.unit, t)
          )
        },
        cell: data => <div className="text-right">{data.getValue<string>()}</div>,
        size: 160,
      },
    ],
    [convertNumberToMoney, t],
  )
  const accountId = useWatch({ control, name: 'accountId' })
  const toPay = useWatch({ control, name: 'toPay' })

  if (!movement) return null

  const { overallPrice, transportCosts, isPaid, totalPaidSum, paidSumTransport } =
    getFinancialInfoAboutMovement(movement)

  const priceToPay = overallPrice - (totalPaidSum ?? 0)

  const accountBalance = findById(accountId, accounts)?.balance ?? 0

  const handleOnClick: SubmitHandler<MovementInfoDialogFormValues> = async ({
    accountId,
    amount,
    fulfilled,
    toPay,
  }) => {
    if (movement.type === MOVEMENT_TYPES.arrival.id)
      try {
        if (fulfilled) {
          await updateMovementStatus({ id: movement.id, movementInsertInput: { fulfilled: true } })
        }

        if (toPay || movement.fulfilled) {
          const unpaidTransportCost = paidSumTransport
            ? transportCosts - paidSumTransport
            : transportCosts
          const transportPaymentAmount =
            Number(amount) > unpaidTransportCost ? unpaidTransportCost : Number(amount)
          const productPaymentAmount = Number(amount) - transportPaymentAmount

          if (productPaymentAmount > 0) {
            const transactionsFromAccount: TransactionFromAccount[] = [
              {
                fromAccountId: accountId,
                amount: productPaymentAmount,
                type: TRANSACTION_TYPES.productsPurchase.id,
                movementId: movement.id,
                branchId,
              },
            ]

            if (transportPaymentAmount) {
              transactionsFromAccount.push({
                fromAccountId: accountId,
                amount: transportPaymentAmount,
                type: TRANSACTION_TYPES.transportCosts.id,
                movementId: movement.id,
                branchId,
              })
            }
            await createTransactionsFromAccount({ transactionsFromAccount })
          }
        }

        openSnackBar(t('arrival.operationSuccess'), 'success', 3000)
        await queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(SUPPLIERS_MUTUAL_SETTLEMENTS),
        })
      } catch (error) {
        openSnackBar(t('submitError'), 'error', 3000)
        reportError(error, 'error', { movement })
      }

    if (movement.type === MOVEMENT_TYPES.return.id)
      try {
        await updateMovementStatus({ id: movement.id, movementInsertInput: { fulfilled: true } })

        await createTransaction({
          type: TRANSACTION_TYPES.productsReturn.id,
          movementId: movement.id,
          toAccountId: accountId,
          amount: overallPrice,
          branchId,
        })
        openSnackBar(t('supplierRefundSuccess'), 'success', 3000)
        await queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(SUPPLIERS_MUTUAL_SETTLEMENTS),
        })
      } catch (error) {
        openSnackBar(t('submitError'), 'error', 3000)
        reportError(error, 'error', { movement })
      }

    closeDialog()
  }

  const isPaidAllowed = myPermission?.includes(permissions.transaction.set)

  return (
    <>
      <Dialog.Title>{t('invoice')}</Dialog.Title>

      <Dialog.Body className="w-160">
        <div className="grid grid-cols-2">
          <InfoBlock title={t('number')} text={movement.number} />
          <InfoBlock
            title={t('dateTitle')}
            text={dateFormatting('historyDateTime', movement.createdAt)}
          />
          <InfoBlock title={t('type')} text={getMovementTypesName(movement.type, t)} />
          <InfoBlock title={t('execution')} text={movementFulfilledStatus ?? ''} />
          {movement.supplier && (
            <InfoBlock title={t('supplier.name')} text={movement.supplier.name} />
          )}
          {movement.fromStorage && (
            <InfoBlock title={t('fromStorage')} text={movement.fromStorage.name} />
          )}
          {movement.toStorage && (
            <InfoBlock title={t('toStorage')} text={movement.toStorage.name} />
          )}
          {movement.author && (
            <InfoBlock title={t('created')} text={transformPersonName(movement.author)} />
          )}
          {movement.description && (
            <InfoBlock title={t('description')} text={movement.description} />
          )}
        </div>

        <Table columns={columns} data={movement.movementProducts} />

        <div className="flex text-right justify-end mb-1">
          <div className="mr-2 text-gray-500">
            {movement.type === MOVEMENT_TYPES.arrival.id && (
              <>
                {Boolean(movement.transportCosts) && movement.transportCosts !== 0 && (
                  <p>{`${t('transactionNames.transportCosts')}:`}</p>
                )}
                {Boolean(movement.discount) && movement.discountType && (
                  <p>{`${t('discount')}:`}</p>
                )}
                {Boolean(totalPaidSum) && <p>{`${t('paid')}:`}</p>}
              </>
            )}
            {movement.type !== MOVEMENT_TYPES.movement.id && !isPaid && <p>{`${t('toPay')}:`}</p>}
          </div>

          <div className="text-main-color">
            {movement.type === MOVEMENT_TYPES.arrival.id && (
              <>
                {movement.transportCosts !== 0 && movement.transportCosts && (
                  <p>{convertNumberToMoneyCode(movement.transportCosts)}</p>
                )}

                {movement.discount !== 0 && movement.discount && movement.discountType && (
                  <p>
                    {movement.discountType === MOVEMENT_DISCOUNT_TYPES.percent.id
                      ? movement.discount + ' ' + MOVEMENT_DISCOUNT_TYPES.percent.name
                      : convertNumberToMoneyCode(movement.discount)}
                  </p>
                )}
                {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  Boolean(totalPaidSum) && convertNumberToMoneyCode(totalPaidSum!)
                }
              </>
            )}
            {movement.type !== MOVEMENT_TYPES.movement.id && !isPaid && (
              <p>{convertNumberToMoneyCode(priceToPay)}</p>
            )}
          </div>
        </div>

        {movement.type === MOVEMENT_TYPES.arrival.id && (
          <>
            <div className="flex justify-end mb-2">
              {!movement.fulfilled && (
                <Controller
                  control={control}
                  name="fulfilled"
                  defaultValue={movement.fulfilled}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      containerClassName="mr-2"
                      label={t('arrival.fulfill')}
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
              )}
              {!movement.fulfilled && !isPaid && isPaidAllowed && (
                <Controller
                  control={control}
                  name="toPay"
                  render={({ field: { value, onChange } }) => (
                    <Checkbox label={t('pay')} onChange={onChange} checked={value} />
                  )}
                />
              )}
            </div>
            {!isPaid && isPaidAllowed && (toPay || movement.fulfilled) && (
              <PaymentBlock
                movementType={MOVEMENT_TYPES.arrival.id}
                control={control}
                accounts={accounts}
                t={t}
                priceToPay={priceToPay}
                accountBalance={accountBalance}
              />
            )}
          </>
        )}

        {/* не рабочая логика */}
        {movement.type === MOVEMENT_TYPES.return.id && !isPaid && isPaidAllowed && (
          <PaymentBlock
            movementType={MOVEMENT_TYPES.return.id}
            control={control}
            accounts={accounts}
            t={t}
            priceToPay={priceToPay}
            accountBalance={accountBalance}
          />
        )}
      </Dialog.Body>

      <Dialog.Footer>
        {movement.type === MOVEMENT_TYPES.arrival.id &&
          ((!isPaid && isPaidAllowed) || !movement.fulfilled ? (
            <SubmitButton
              onClick={handleSubmit(handleOnClick)}
              disabled={formState.isSubmitting || !formState.isDirty}
              spinner={formState.isSubmitting}
            />
          ) : null)}

        {/* не рабочая логика */}
        {movement.type === MOVEMENT_TYPES.return.id && !isPaid && (
          <Button
            onClick={handleSubmit(handleOnClick)}
            disabled={formState.isSubmitting}
            spinner={formState.isSubmitting}
          >
            {t('get')}
          </Button>
        )}
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
