import {
  ServerProductType,
  useFetchProductGroups,
  useFetchProducts,
  useGetMovementNumber,
} from '@expane/data'
import { findById } from '@expane/logic/utils'
import { Dialog, Modal, usePopupOpenState, useShortCut } from '@expane/ui'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders, transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { ProductTreeMenuItem } from 'ui/TreeMenu/logic.product'
import { ChooseProductDialog, ChooseProductDialogPlaceholder } from 'widgets/ChooseProductDialog'
import { ArrivalMovementDialog } from 'widgets/MovementCreateDialogs/ArrivalMovementDialog'
import { PostingMovementDialog } from 'widgets/MovementCreateDialogs/PostingMovementDialog'
import { ProductsMovementDialog } from 'widgets/MovementCreateDialogs/ProductsMovementDialog'
import { WriteOffMovementDialog } from 'widgets/MovementCreateDialogs/WriteOffMovementDialog'

export interface ProductDto extends Pick<ServerProductType, 'id' | 'costPrice' | 'name' | 'unit'> {
  quantity: number
}

interface UseOpenMovementDialogProps {
  closeParentDialog?: () => void
  items: ProductDto[]
  products: ServerProductType[]
  movementNumber: number
  storageId?: number
  Component: FC<MovementDialogsProps>
  onClose?: () => void
  inventoryId?: number
}

export interface MovementDialogsProps {
  closeParentDialog?: () => void
  closeDialog: () => void
  productsForDefaultFormValues: ProductDto[]
  movementNumber: number
  storageId?: number
  inventoryId?: number
}

interface ChooseProductsDialogProps extends Pick<DialogProps, 'closeDialog' | 'id'> {
  title: string
  type: 'arrival' | 'writeOff' | 'movement' | 'return' | 'posting'
}

export const ChooseProductsArrivalDialog: FC<DialogProps> = ({ closeDialog, id: storageId }) => {
  const { t } = useTranslation()
  return (
    <ChooseMovementProductsDialog
      closeDialog={closeDialog}
      id={storageId}
      title={t('selectionOfProducts.arrival')}
      type="arrival"
    />
  )
}

export const ChooseProductsWriteOffDialog: FC<DialogProps> = ({ closeDialog, id: storageId }) => {
  const { t } = useTranslation()
  return (
    <ChooseMovementProductsDialog
      closeDialog={closeDialog}
      id={storageId}
      title={t('selectionOfProducts.writeOff')}
      type="writeOff"
    />
  )
}

export const ChooseProductsMovementDialog: FC<DialogProps> = ({ closeDialog, id: storageId }) => {
  const { t } = useTranslation()
  return (
    <ChooseMovementProductsDialog
      closeDialog={closeDialog}
      id={storageId}
      title={t('selectionOfProducts.movement')}
      type="movement"
    />
  )
}

export const ChooseReturnProductDialog: FC<DialogProps> = ({ closeDialog, id: storageId }) => {
  const { t } = useTranslation()
  return (
    <ChooseMovementProductsDialog
      closeDialog={closeDialog}
      id={storageId}
      title={t('selectionOfProducts.return')}
      type="return"
    />
  )
}

export const ChoosePostingProductsDialog: FC<DialogProps> = ({ closeDialog, id: storageId }) => {
  const { t } = useTranslation()
  return (
    <ChooseMovementProductsDialog
      closeDialog={closeDialog}
      id={storageId}
      title={t('selectionOfProducts.posting')}
      type="posting"
    />
  )
}

const ChooseMovementProductsDialog: FC<ChooseProductsDialogProps> = observer(
  ({ closeDialog, id: storageId, title, type }) => {
    const [selectedItems, onSelectedItems] = useState<TreeMenuItem[]>([])

    const branchId = store.branch.branchId
    const { data: productGroups, isLoading: isLoadingProductGroups } =
      useFetchProductGroups(branchId)
    const { data: products, isLoading: isLoadingProducts } = useFetchProducts(branchId)

    const { data: movementNumber, isLoading: isLoadingMovementNumber } =
      useGetMovementNumber(branchId)

    const dialogProps: Omit<UseOpenMovementDialogProps, 'Component' | 'onClose'> = {
      closeParentDialog: closeDialog,
      items: extractItemsFromFolders(selectedItems),
      storageId,
      movementNumber: movementNumber ?? 1,
      products: products ?? [],
    }

    const { openDialog: openArrivalMovementDialog, dialog: arrivalMovementDialog } =
      useOpenMovementDialog({
        ...dialogProps,
        Component: ArrivalMovementDialog,
      })

    const { openDialog: openProductsMovementDialog, dialog: productsMovementDialog } =
      useOpenMovementDialog({
        ...dialogProps,
        Component: ProductsMovementDialog,
      })

    const { openDialog: openWriteOffMovementDialog, dialog: writeOffMovementDialog } =
      useOpenMovementDialog({
        ...dialogProps,
        Component: WriteOffMovementDialog,
      })

    const { dialog: postingMovementDialog, openDialog: openPostingMovementDialog } =
      useOpenMovementDialog({
        ...dialogProps,
        Component: PostingMovementDialog,
      })

    useShortCut(['Escape'], () => {
      // Close the dialog only if the child dialogs are closed.
      if (arrivalMovementDialog || writeOffMovementDialog || productsMovementDialog) return

      closeDialog()
    })

    const isLoading = isLoadingMovementNumber || isLoadingProducts || isLoadingProductGroups
    const isNoData = !productGroups || !products

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

    const items = transformDataForTreeMenu<ProductTreeMenuItem>(productGroups, products)

    const onChoose = useCallback(() => {
      if (type === 'arrival') openArrivalMovementDialog()
      if (type === 'writeOff') openWriteOffMovementDialog()
      if (type === 'movement') openProductsMovementDialog()
      if (type === 'posting') openPostingMovementDialog()
    }, [
      openArrivalMovementDialog,
      openPostingMovementDialog,
      openProductsMovementDialog,
      openWriteOffMovementDialog,
      type,
    ])

    return (
      <>
        <Modal
          close={closeDialog}
          confirm={() => {
            if (selectedItems.length !== 0) onChoose()
          }}
        >
          <Dialog>
            {isLoading ? (
              <ChooseProductDialogPlaceholder closeDialog={closeDialog} title={title} />
            ) : (
              <ChooseProductDialog
                closeDialog={closeDialog}
                items={items}
                selectedItems={selectedItems}
                onSelectedItems={onSelectedItems}
                title={title}
                onChoose={onChoose}
              />
            )}
          </Dialog>
        </Modal>

        {arrivalMovementDialog}
        {writeOffMovementDialog}
        {productsMovementDialog}
        {postingMovementDialog}
      </>
    )
  },
)

export const useOpenMovementDialog = ({
  items,
  products,
  storageId,
  movementNumber,
  closeParentDialog,
  Component,
  onClose,
  inventoryId,
}: UseOpenMovementDialogProps) => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const closeDialog = () => {
    closePopup()
    if (onClose) onClose()
  }

  const dialog = isOpen ? (
    <Component
      closeParentDialog={closeParentDialog}
      closeDialog={closeDialog}
      productsForDefaultFormValues={transformItemsForDefaultFormValues(items, products)}
      storageId={storageId}
      movementNumber={movementNumber}
      inventoryId={inventoryId}
    />
  ) : null

  return { openDialog: openPopup, dialog }
}

const transformItemsForDefaultFormValues = (
  items: ProductDto[],
  products: ServerProductType[],
): ProductDto[] =>
  items.map(item => ({
    id: item.id,
    name: item.name,
    costPrice: findById(item.id, products)?.costPrice ?? 0,
    quantity: item.quantity ?? 0,
    unit: findById(item.id, products)?.unit ?? 0,
  }))
