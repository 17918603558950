import { addDays, createCurrentDate, isAfter } from '@expane/date'
import { Card } from '@expane/logic/cards'

export const checkIfAnyServiceWasUsedInSubscription = <T extends Card>(clientSubscription: T) => {
  return Object.values(clientSubscription.subscriptionInfo).some(({ used }) => used > 0)
}

// не активированные в контексте сертификатов значит, что он был продан, но деньги на счет клиента не поступили
export const getGiftCardsThatWasNotActivated = <T extends Card & { createdAt: Date }>(
  clientCards: T[],
  timezone: string | undefined,
) => {
  return clientCards.filter(({ activatedAt, cardPeriod, createdAt }) => {
    // не активированные и при этом нет срока действия
    if (!activatedAt && cardPeriod === 0) return true
    // не активированные и при этом есть срок действия, то проверяем или сертификат не просрочен
    return (
      !activatedAt &&
      cardPeriod !== 0 &&
      isAfter(addDays(createdAt, cardPeriod), createCurrentDate(timezone))
    )
  })
}
