import { FC } from 'react'

export interface TreeItemQuantityProps {
  show: boolean
  value: number | undefined
  onChange: (value?: number) => void
  disabled: boolean
}

export const TreeItemQuantity: FC<TreeItemQuantityProps> = ({
  show,
  value,
  onChange,
  disabled,
}) => {
  if (!show) return null

  let inputClassName =
    'mr-1 h-6 w-10 block border-2 rounded-lg px-1.5 text-sm focus:ring-0 transition-all focus:ring-0 hover:border-gray-200 border-gray-100 focus:border-primary-400'

  inputClassName += disabled ? ' bg-gray-50' : ' hover:border-gray-200'

  return (
    <>
      <span className="pl-2 pr-1">Кол-во:</span>

      <input
        value={!value || value === 0 ? '' : value.toString()}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          const inputValue = e.target.value.replace(/[^\d.]/g, '')
          if (inputValue === '') onChange(0)
          if (Number(inputValue) > 0) onChange(Number(inputValue))
        }}
        onBlur={e => {
          if (e.target.value === '') onChange(0)
        }}
        className={inputClassName}
        disabled={disabled}
      />
    </>
  )
}
