import {
  MOVEMENT_TYPES,
  PostingMovementInsertInput,
  ServerMovementProductInsertInput,
  ServerTransactionInBookingType,
  TRANSACTION_TYPES,
  TransactionFromAccount,
  TransactionRefundBySubscription,
} from '@expane/data'
import { getIsSomeServicesPaid, getIsSomeProductsPaid } from '../booking'
import { calcTotalPrice } from '../utils'

export const prepareDtoForFullRefund = ({
  transactions,
  bookingId,
  fromAccountId,
  refundToClientAccount,
  returnConsumables,
  branchId,
}: {
  transactions: ServerTransactionInBookingType[]
  bookingId: number
  fromAccountId?: number
  refundToClientAccount: boolean
  returnConsumables: boolean
  branchId: number
}) => {
  const dtoForRefund: {
    refundByCash: TransactionFromAccount[]
    refundBySubscription: TransactionRefundBySubscription[]
  } = {
    refundByCash: [],
    refundBySubscription: [],
  }

  const refundsTransactions = transactions.filter(
    ({ type, typeVariation }) =>
      type === TRANSACTION_TYPES.refund.id &&
      typeVariation !== TRANSACTION_TYPES.refund.variations.refundPaymentInCredit.id,
  )
  const creditRefundTransactions = transactions.filter(
    ({ type, typeVariation }) =>
      type === TRANSACTION_TYPES.refund.id &&
      typeVariation === TRANSACTION_TYPES.refund.variations.refundPaymentInCredit.id,
  )

  const paymentTransactions = transactions.filter(
    ({ type, typeVariation }) =>
      type === TRANSACTION_TYPES.payment.id &&
      (typeVariation === TRANSACTION_TYPES.payment.variations.service.id ||
        typeVariation === TRANSACTION_TYPES.payment.variations.product.id),
  )

  const paymentCreditTransactions = transactions.filter(
    ({ type, typeVariation }) =>
      type === TRANSACTION_TYPES.paymentInCredit.id &&
      (typeVariation === TRANSACTION_TYPES.paymentInCredit.variations.service.id ||
        typeVariation === TRANSACTION_TYPES.paymentInCredit.variations.product.id),
  )

  paymentTransactions.forEach(transaction => {
    const isTransactionNotRefund = !refundsTransactions.some(
      refundTransaction => refundTransaction.parentId === transaction.id,
    )

    if (isTransactionNotRefund) {
      if (transaction.cardId && !refundToClientAccount) {
        dtoForRefund.refundBySubscription.push(
          getItemForRefundBySubscription({
            bookingId,
            transaction,
            refundToClientAccount,
            returnConsumables,
            branchId,
          }),
        )
      } else {
        dtoForRefund.refundByCash.push(
          getItemForRefundByCash({
            bookingId,
            fromAccountId,
            transaction,
            transactionsServices: omitServiceFromTransactionServices(
              transaction.transactionsServices,
            ),
            refundToClientAccount,
            returnConsumables,
            isCredit: false,
            branchId,
          }),
        )
      }
    } else {
      const isSomeServicesPaid = getIsSomeServicesPaid(
        transaction.transactionsServices.map(({ serviceId }) => ({
          id: serviceId,
        })),
        transactions.filter(tr => tr.id === transaction.id || tr.parentId === transaction.id),
      )
      const isSomeProductsPaid = transaction.movement
        ? getIsSomeProductsPaid(
            transaction.movement.movementProducts,
            transactions.filter(tr => tr.id === transaction.id || tr.parentId === transaction.id),
          )
        : false

      if (isSomeServicesPaid || isSomeProductsPaid) {
        const servicesForRefund = getServicesForRefund(refundsTransactions, transaction)
        const refundSum = calcTotalPrice(servicesForRefund)

        if (transaction.cardId) {
          dtoForRefund.refundBySubscription.push(
            getItemForRefundBySubscription({
              bookingId,
              transaction,
              transactionsServices: servicesForRefund,
              refundToClientAccount,
              returnConsumables,
              branchId,
            }),
          )
        } else {
          dtoForRefund.refundByCash.push(
            getItemForRefundByCash({
              bookingId,
              fromAccountId,
              transaction,
              amount: refundSum,
              transactionsServices: servicesForRefund,
              refundToClientAccount,
              returnConsumables,
              isCredit: false,
              branchId,
            }),
          )
        }
      }
    }
  })

  for (const creditTransaction of paymentCreditTransactions) {
    const isTransactionNotRefund = !creditRefundTransactions.some(
      creditRefundTransaction => creditRefundTransaction.parentId === creditTransaction.id,
    )

    if (isTransactionNotRefund) {
      dtoForRefund.refundByCash.push(
        getItemForRefundByCash({
          bookingId,
          fromAccountId,
          transaction: creditTransaction,
          refundToClientAccount,
          returnConsumables,
          isCredit: true,
          branchId,
        }),
      )
      continue
    }

    const isSomeServicesPaid = getIsSomeServicesPaid(
      creditTransaction.transactionsServices.map(({ serviceId }) => ({
        id: serviceId,
      })),
      transactions.filter(
        tr => tr.id === creditTransaction.id || tr.parentId === creditTransaction.id,
      ),
    )
    const isSomeProductsPaid = creditTransaction.movement
      ? getIsSomeProductsPaid(
          creditTransaction.movement.movementProducts,
          transactions.filter(
            tr => tr.id === creditTransaction.id || tr.parentId === creditTransaction.id,
          ),
        )
      : false

    if (isSomeServicesPaid || isSomeProductsPaid) {
      const servicesForRefund = getServicesForRefund(creditRefundTransactions, creditTransaction)
      const refundSum = servicesForRefund.reduce((sum, service) => sum + service.price, 0)

      dtoForRefund.refundByCash.push(
        getItemForRefundByCash({
          bookingId,
          fromAccountId,
          transaction: creditTransaction,
          amount: refundSum,
          transactionsServices: servicesForRefund,
          refundToClientAccount,
          returnConsumables,
          isCredit: true,
          branchId,
        }),
      )
    }
  }

  return dtoForRefund
}

const getItemForRefundByCash = (dto: {
  bookingId: number
  fromAccountId: number | undefined
  transaction: ServerTransactionInBookingType
  amount?: number
  transactionsServices?: {
    serviceId: number
    price: number
  }[]
  refundToClientAccount: boolean
  returnConsumables: boolean
  isCredit: boolean
  branchId: number
}): TransactionFromAccount => {
  const {
    bookingId,
    fromAccountId,
    transaction,
    amount,
    transactionsServices,
    refundToClientAccount,
    isCredit,
    returnConsumables,
    branchId,
  } = dto

  let typeVariation: number = transaction.cardId
    ? TRANSACTION_TYPES.refund.variations.refundBySubscription.id
    : TRANSACTION_TYPES.refund.variations.refundFromAccount.id

  if (refundToClientAccount)
    typeVariation = TRANSACTION_TYPES.refund.variations.refundToClientAccount.id

  if (isCredit) typeVariation = TRANSACTION_TYPES.refund.variations.refundPaymentInCredit.id

  const movement = generateReturnConsumableMovement(
    returnConsumables,
    transaction,
    transactionsServices,
    branchId,
  )

  return {
    bookingId,
    fromAccountId: isCredit ? null : fromAccountId ?? null,
    parentId: transaction.id,
    clientId: transaction.clientId,
    employeeId: transaction.employeeId,
    type: TRANSACTION_TYPES.refund.id,
    typeVariation,
    amount: amount ?? transaction.amount,
    transactionsServices: {
      data:
        transactionsServices ??
        omitServiceFromTransactionServices(transaction.transactionsServices),
    },
    movement,
    branchId,
  }
}

const getItemForRefundBySubscription = ({
  transaction,
  transactionsServices,
  bookingId,
  returnConsumables,
  refundToClientAccount,
  branchId,
}: {
  bookingId: number
  transaction: ServerTransactionInBookingType
  transactionsServices?:
    | {
        serviceId: number
        price: number
      }[]
  returnConsumables: boolean
  refundToClientAccount: boolean
  branchId: number
}): TransactionRefundBySubscription => {
  const transactionServicesByTransaction = omitServiceFromTransactionServices(
    transaction.transactionsServices,
  )

  const movement = generateReturnConsumableMovement(
    returnConsumables,
    transaction,
    transactionsServices,
    branchId,
  )

  return {
    bookingId,
    parentId: transaction.id,
    clientId: transaction.clientId,
    employeeId: transaction.employeeId,
    type: TRANSACTION_TYPES.refund.id,
    typeVariation: refundToClientAccount
      ? TRANSACTION_TYPES.refund.variations.refundToClientAccount.id
      : TRANSACTION_TYPES.refund.variations.refundBySubscription.id,
    amount: transaction.amount,
    cardId: transaction.cardId,
    transactionsServices: {
      data: transactionsServices ?? transactionServicesByTransaction,
    },
    movement,
    branchId,
  }
}

// If we try to create transactionService with relation to service by id
// and to create new service by passing name of new service
// there will be error
const omitServiceFromTransactionServices = (
  transactionServices: {
    serviceId: number
    price: number
    service: {
      name: string
    }
  }[],
) => {
  return transactionServices.map(tS => {
    // Omit service from object
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { service, ...other } = tS
    return other
  })
}

const generateReturnConsumableMovement = (
  isRefundConsumables: boolean,
  transaction: ServerTransactionInBookingType,
  transactionsServices:
    | {
        serviceId: number
        price: number
      }[]
    | undefined,
  branchId,
): { data: PostingMovementInsertInput } | null => {
  const returnedConsumables: ServerMovementProductInsertInput[] = []

  // Якщо це вбудовані росхідники і ми не повертаємо розхідники на склад
  if (!isRefundConsumables && transactionsServices?.length && transaction.movement) return null

  // Обов'язково повинен бути fromStorageId
  if (!transaction.movement?.fromStorageId) return null

  if (transactionsServices?.length && transaction.movement) {
    // Если есть transactionsServices и transaction.movement это расходник в услуге
    for (let i = 0; i < transactionsServices.length; i++) {
      // Мы смотрим в возвращаемые сервисы, а не в transaction.movement,
      // что бы посчитать сколько нужно вернуть расходников
      const service = transaction.transactionsServices.find(
        item => item.serviceId === transactionsServices[i].serviceId,
      )?.service
      if (service) {
        for (let j = 0; j < service.serviceProducts.length; j++) {
          const consumableProductId = service.serviceProducts[j].productId
          const consumableQuantity = service.serviceProducts[j].quantity

          const productInTransactionMovement = transaction.movement.movementProducts.find(
            item => item.productId === consumableProductId,
          )

          if (productInTransactionMovement)
            returnedConsumables.push({
              price: productInTransactionMovement.price,
              productId: consumableProductId,
              quantity: consumableQuantity,
            })
        }
      }
    }
  }
  // Если есть transaction.movement, но нет transactionsServices, то это просто расходник в записи
  else if (!transactionsServices?.length && transaction.movement) {
    const movementProducts = transaction.movement.movementProducts
    for (let i = 0; i < movementProducts.length; i += 1)
      returnedConsumables.push({
        price: movementProducts[i].price,
        productId: movementProducts[i].productId,
        quantity: movementProducts[i].quantity,
      })
  } else return null

  return {
    data: {
      toStorageId: transaction.movement.fromStorageId,
      fulfilled: true,
      movementProducts: { data: returnedConsumables },
      type: MOVEMENT_TYPES.consumablesReturn.id,
      number: '',
      branchId,
    },
  }
}

const getServicesForRefund = (
  allRefundsTransactions: ServerTransactionInBookingType[],
  transaction: ServerTransactionInBookingType,
) => {
  const refundsTransactions = allRefundsTransactions.filter(
    refundTransaction => refundTransaction.parentId === transaction.id,
  )
  const quantityServicesMap = getQuantityServicesMap(refundsTransactions)
  // фильтруем-убираем из списка сервисы по которым уже провели возврат
  const servicesForRefund = transaction.transactionsServices.filter(transactionService => {
    return !quantityServicesMap?.[transactionService.serviceId]
  })

  return omitServiceFromTransactionServices(servicesForRefund)
}

const getQuantityServicesMap = (refundsForTransaction: ServerTransactionInBookingType[]) => {
  if (refundsForTransaction.length === 0) return undefined

  // keys === id, value === количество
  const result: Record<number, number> = {}

  refundsForTransaction.forEach(transaction => {
    if (transaction.transactionsServices) {
      transaction.transactionsServices.forEach(el => {
        if (!result[el.serviceId]) {
          result[el.serviceId] = 1
        } else {
          result[el.serviceId] += 1
        }
      })
    }
  })

  return result
}
