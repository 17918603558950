import { gql } from 'graphql-request'
import { reportError, reportGqlError, request, requestAnonymously, useQuery } from '../../api'
import {
  BUSINESS_ANONYMOUSLY_LANDINGS_SETTINGS_QUERY_KEY,
  BUSINESS_LANDINGS_PATHS_QUERY_KEY,
  BUSINESS_LANDINGS_SETTINGS_QUERY_KEY,
} from './queryKeys'
import {
  anonymouslyBusinessLandingSettingsFragment,
  businessLandingSettingsFragment,
} from './businessLandings.fragments'
import { BusinessLandingSettingsType } from './logic'
import { ServerAnonymouslyBusinessLandingSettingsType } from '../../generated/graphql-types'

export function useFetchBusinessLandingSettings() {
  return useQuery(
    [BUSINESS_LANDINGS_SETTINGS_QUERY_KEY],
    async () => {
      const result = await request<{
        businessLandings: BusinessLandingSettingsType[]
      }>(gql`
        ${businessLandingSettingsFragment}
        query {
          businessLandings {
            ...businessLandingSettingsType
          }
        }
      `)

      if (result?.businessLandings) return result.businessLandings[0]

      reportError(new Error('Error while trying to get business landing settings'), 'error', {
        result,
      })
    },
    {
      queryName: 'useFetchBusinessLandingSettings',
      onError: reportGqlError,
    },
  )
}

export function useFetchAnonymouslyBusinessLandingsPaths() {
  return useQuery(
    [BUSINESS_ANONYMOUSLY_LANDINGS_SETTINGS_QUERY_KEY, BUSINESS_LANDINGS_PATHS_QUERY_KEY],
    async () => {
      const result = await requestAnonymously<{
        businessLandingViews: { path: string }[]
      }>(gql`
        query {
          businessLandingViews {
            path
          }
        }
      `)

      if (Array.isArray(result?.businessLandingViews)) return result.businessLandingViews

      return []
    },
    {
      queryName: 'useFetchAnonymouslyBusinessLandingsPaths',
      onError: reportGqlError,
    },
  )
}

export function useFetchAnonymouslyBusinessLandingSettings(path: string | undefined) {
  return useQuery(
    [BUSINESS_ANONYMOUSLY_LANDINGS_SETTINGS_QUERY_KEY, { path }],
    async () => {
      const result = await requestAnonymously<{
        businessLandingViews: ServerAnonymouslyBusinessLandingSettingsType[]
      }>(
        gql`
          ${anonymouslyBusinessLandingSettingsFragment}
          query ($path: String!) {
            businessLandingViews(where: { path: { _eq: $path } }) {
              ...anonymouslyBusinessLandingSettingsType
            }
          }
        `,
        { path },
      )

      if (result?.businessLandingViews?.[0]) return result.businessLandingViews[0]

      return undefined
    },
    {
      enabled: Boolean(path),
      queryName: 'useFetchAnonymouslyBusinessLandingSettings',
      onError: reportGqlError,
    },
  )
}
