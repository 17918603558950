import { FC } from 'react'
import { useFetchPlans } from '@expane/data'
import { useTranslation } from 'react-i18next'

export interface HorizontalTabsProps {
  activeTabId: number
  setActiveTabId: (id: number) => void
}

export const PlanTabs: FC<HorizontalTabsProps> = ({ activeTabId, setActiveTabId }) => {
  const { data: plans } = useFetchPlans()

  const { t } = useTranslation()

  return (
    <div className="flex items-end justify-between">
      {plans?.map(plan => {
        const selected = plan.id === activeTabId

        let styles =
          'w-1/3 flex-centered flex-col px-4 dark:text-gray-300 first:rounded-tl-lg last:rounded-tr-lg cursor-pointer'

        styles += selected
          ? ' h-28 shadow-inner dark:bg-gray-200/20 text-gray-500 font-bold rounded-t-lg '
          : ' h-20 bg-gray-50 text-gray-500 dark:bg-gray-500/20'

        return (
          <div
            key={plan.id}
            onClick={() => {
              if (!selected) setActiveTabId(plan.id)
            }}
            className={styles}
          >
            <p className={'text-xl'}>{t('plan.' + plan.name)}</p>

            {selected && (
              <p className={'text-sm mt-4'}>
                {plan.maxEmployees
                  ? t('plan.maxEmployees', { maxEmployees: plan.maxEmployees })
                  : t('plan.unlimitedEmployees')}
              </p>
            )}
          </div>
        )
      })}
    </div>
  )
}
