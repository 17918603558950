import {
  ServerServiceBriefWithProductType,
  useCreateBooking,
  useUpdateBooking,
  validateBooking,
} from '@expane/data'
import { fromZonedTime } from '@expane/date'
import { generateMessageAfterValidation } from '@expane/logic/bookingChecks'
import { useShowConfirmationPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { prepareBookingData } from 'logic/booking'
import { Control, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import { ConfirmationDescription } from 'widgets/ConfirmationDescription'
import { BookingDialogFormValues } from '.'

interface UseSubmitBookingArgs {
  isCreate: boolean
  // if not create, we have bookingId
  bookingId: number | undefined
  oldStartDate: Date
  dayStart: number
  dayEnd: number
  control: Control<BookingDialogFormValues>
  initialBookingProducts: { id: number; productId: number; quantity: number }[]
  initialBookingServices: { id: number; service: ServerServiceBriefWithProductType }[]
  timezone: string
}

export const useSubmitBooking = ({
  isCreate,
  bookingId,
  control,
  oldStartDate,
  initialBookingProducts,
  initialBookingServices,
  timezone,
}: UseSubmitBookingArgs) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!
  const { t } = useTranslation()

  const { dirtyFields } = useFormState({ control })

  const { mutateAsync: createBooking } = useCreateBooking()
  const { mutateAsync: updateBooking } = useUpdateBooking()

  const [openSnackbar] = useSnackbar()
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const submitBooking = async (
    data: BookingDialogFormValues,
    onSuccess: (bookingId?: number) => void,
  ) => {
    const extractedServices = extractItemsFromFolders(data.services)
    const serviceIds: number[] = extractedServices.map(service => service.id)

    const {
      bookingServicesToBeRemovedIds,
      bookingServiceInsertInput,
      bookingProductsToBeRemovedIds,
      bookingProductInsertInput,
    } = prepareBookingData({
      initialBookingServices,
      serviceIds,
      initialBookingProducts,
      consumables: data.consumables,
      bookingId,
    })

    const startDate = fromZonedTime(data.startDate, timezone)
    const needToValidate =
      isCreate ||
      Boolean(dirtyFields.employeeId) ||
      Boolean(dirtyFields.locationId) ||
      Boolean(dirtyFields.clientId) ||
      Boolean(dirtyFields.duration) ||
      Boolean(dirtyFields.startDate)
    const { type } = needToValidate
      ? await validateBooking({
          branchId,
          bookingId: bookingId ?? null,
          startDate,
          clientId: data.clientId,
          duration: Number(data.duration),
          isGroupBooking: false,
          serviceIds: data.services.map(service => service.id),
          serviceId: null,
          clientIds: null,
          employeeId: data.employeeId ?? null,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          locationId: data.locationId!,
        })
      : { type: null }

    const confirmValidation = async () => {
      if (isCreate) {
        const booking = await createBooking({
          duration: Number(data.duration),
          employeeId: data.employeeId,
          locationId: data.locationId,
          bookingServices: { data: serviceIds.map(serviceId => ({ serviceId })) },
          startDate,
          note: data.note,
          clientId: data.clientId,
          isGroupBooking: false,
          bookingProducts: {
            data: data.consumables.map(product => ({
              ...product,
              quantity: Number(product.quantity),
            })),
          },
          branchId,
        })

        if (booking?.insertBooking?.id) {
          openSnackbar(t('booking.createSuccess'), 'success')
          onSuccess(booking.insertBooking.id)
        } else openSnackbar(t('submitError'), 'error')
      } else if (bookingId && data.clientId) {
        const result = await updateBooking({
          branchId,
          id: bookingId,
          bookingSetInput: {
            startDate,
            clientId: data.clientId,
            employeeId: data.employeeId ?? null,
            locationId: data.locationId,
            duration: Number(data.duration),
            note: data.note,
          },
          bookingServiceInsertInput,
          oldStartDate,
          bookingProductInsertInput,
          bookingProductsToBeRemovedIds,
          bookingServicesToBeRemovedIds,
        })
        if (result?.updateBookingById?.id) {
          openSnackbar(t('booking.updateSuccess'), 'success')
          onSuccess(result.updateBookingById.id)
        } else openSnackbar(t('submitError'), 'error')
      }
    }

    if (type === undefined || type === null) await confirmValidation()
    else {
      const warning = generateMessageAfterValidation({ warningType: type, t })
      showConfirmation({
        title: isCreate ? t('creating') : t('editing'),
        onConfirm: confirmValidation,
        description: (
          <ConfirmationDescription
            text={warning}
            question={t(isCreate ? 'booking.createConfirmation' : 'booking.updateConfirmation')}
          />
        ),
      })
    }
  }

  return { submitBooking, confirmationModal }
}
