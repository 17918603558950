import { makeAutoObservable } from 'mobx'
import { firebase } from 'services/firebase'

export class AuthStore {
  constructor() {
    makeAutoObservable(this)
  }

  get isAuthenticated() {
    const user = firebase.getCurrentUser()

    if (user) return true

    return false
  }

  // To figure out whether user is authenticated, we need to wait until firebase
  // onAuthStateChange callback will be called for the first time, only then we can
  // check this.isAuthenticated
  isInitialAuthStateChangedCalled = false
  setIsInitialAuthStateChangedCalled = (value: boolean) => {
    this.isInitialAuthStateChangedCalled = value
  }

  // For the spinner to appear after authorization using Apple or Google pop-ups
  isWaitingForAuthAfterAuthPopup = false
  setIsWaitingForAuthAfterAuthPopup = (value: boolean) => {
    this.isWaitingForAuthAfterAuthPopup = value
  }
}
