import { gql } from 'graphql-request'
import {
  LONG_STALE_TIME,
  reportError,
  reportGqlError,
  request,
  requestAnonymously,
  useQuery,
} from '../../api'
import { ServerBookingClientSettingsType } from '../../generated/graphql-types'
import {
  ANONYMOUSLY_BOOKING_CLIENT_SETTINGS_QUERY_KEY,
  BOOKING_CLIENT_SETTINGS_QUERY_KEY,
} from './queryKeys'
import {
  anonymouslyBookingClientSettingsFragment,
  bookingClientSettingsBriefFragment,
  bookingClientSettingsFragment,
} from './bookingClientSettings.fragments'
import { AS_CLIENT_QUERY_KEY } from '../client'
import { AnonymouslyBookingClientSettingsType, BookingClientSettingsBriefType } from './logic'

export function useFetchBookingClientSettings() {
  return useQuery(
    [BOOKING_CLIENT_SETTINGS_QUERY_KEY],
    async () => {
      const result = await request<{
        bookingClientSettings: ServerBookingClientSettingsType[]
      }>(gql`
        ${bookingClientSettingsFragment}
        query {
          bookingClientSettings {
            ...bookingClientSettingsType
          }
        }
      `)

      if (result?.bookingClientSettings) return result.bookingClientSettings[0]

      reportError(new Error('Error while trying to get booking client settings'), 'error', {
        result,
      })
    },
    {
      staleTime: LONG_STALE_TIME,
      queryName: 'useFetchBookingClientSettings',
      onError: reportGqlError,
    },
  )
}

export function useFetchBookingClientSettingsAsClient() {
  return useQuery(
    [BOOKING_CLIENT_SETTINGS_QUERY_KEY, AS_CLIENT_QUERY_KEY],
    async () => {
      const result = await request<{
        bookingClientSettingsView: BookingClientSettingsBriefType[]
      }>(gql`
        ${bookingClientSettingsBriefFragment}
        query {
          bookingClientSettingsView {
            ...bookingClientSettingsBriefType
          }
        }
      `)

      if (result?.bookingClientSettingsView) return result.bookingClientSettingsView[0]

      reportError(new Error('Error while trying to get booking client settings'), 'error', {
        result,
      })
    },
    {
      staleTime: LONG_STALE_TIME,
      queryName: 'useFetchBookingClientSettingsAsClient',
      onError: reportGqlError,
    },
  )
}

export function useFetchAnonymouslyBookingClientSettings(businessId: number | null | undefined) {
  return useQuery(
    [ANONYMOUSLY_BOOKING_CLIENT_SETTINGS_QUERY_KEY],
    async () => {
      const result = await requestAnonymously<{
        bookingClientSettingsView: AnonymouslyBookingClientSettingsType[]
      }>(
        gql`
          ${anonymouslyBookingClientSettingsFragment}
          query ($businessId: Int!) {
            bookingClientSettingsView(where: { businessId: { _eq: $businessId } }) {
              ...anonymouslyBookingClientSettingsType
            }
          }
        `,
        { businessId },
      )

      if (result?.bookingClientSettingsView) return result.bookingClientSettingsView[0]

      reportError(new Error('Error while trying to get booking client settings'), 'error', {
        result,
      })
    },
    {
      enabled: Boolean(businessId),
      staleTime: LONG_STALE_TIME,
      queryName: 'useFetchBookingClientSettingsAnonymously',
      onError: reportGqlError,
    },
  )
}
