import { CSSProperties } from 'react'
import { Side } from '@floating-ui/react-dom'

const ARROW_SIDES_MAP = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
}
export const getPlacementStylesForArrow = (
  side: Side,
  arrowData: { x?: number; y?: number } | undefined,
  shiftData?: { x?: number },
): CSSProperties => {
  const staticSide = ARROW_SIDES_MAP[side]

  return {
    // https://floating-ui.com/docs/tutorial#arrow-middleware and https://floating-ui.com/docs/tutorial#shift-middleware
    left: `${(arrowData?.x ?? 0) - (shiftData?.x ?? 0)}px`,
    top: arrowData?.y != null ? `${arrowData.y}px` : '',
    right: '',
    bottom: '',
    [staticSide]: '-4px',
  }
}

export const getClassNameForArrow = (side: Side) => {
  let className = 'absolute rotate-45 w-2 h-2 bg-block border'

  if (side === 'bottom')
    className += ' border-l-gray-500 border-t-gray-500 border-b-transparent border-r-transparent'
  if (side === 'top')
    className += ' border-r-gray-500 border-b-gray-500 border-t-transparent border-l-transparent'

  return className
}
