import { FC } from 'react'
import { IconType } from 'react-icons/lib'

export interface HorizontalTabsProps {
  title: string
  tabs: Array<{
    id: number
    label: string
    Icon?: IconType
  }>
  activeTabId: number
  setActiveTabId: (id: number) => void
  disabledTabs?: number[]
}

export const HorizontalTabs: FC<HorizontalTabsProps> = ({
  title,
  tabs,
  activeTabId,
  setActiveTabId,
  disabledTabs,
}) => {
  return (
    <div className="flex pt-3 pr-3 items-end justify-between border-b-2 border-gray-100 dark:border-gray-600">
      <p className="pb-2.5 pl-3 text-lg font-medium text-gray-500 dark:text-gray-300">{title}</p>

      <div className="flex items-end">
        {tabs.map(tab => {
          if (disabledTabs?.includes(tab.id)) return null

          const selected = tab.id === activeTabId

          let styles =
            'flex-centered px-2 border-t-2 first:border-l-2 first:rounded-tl-lg last:rounded-tr-lg cursor-pointer'

          styles += selected
            ? ' h-9 text-primary-700 dark:text-primary-400 border-primary-100 dark:border-primary-500/30 -mb-2px -mx-2px rounded-t-lg border-l-2 border-r-2 border-b-2 border-b-white dark:border-b-gray-700 '
            : ' h-8 text-gray-400 border-gray-100 dark:border-gray-600'

          if (tab.id !== activeTabId - 1) styles += ' border-r-2'

          return (
            <div
              key={tab.id}
              onClick={() => {
                if (!selected) setActiveTabId(tab.id)
              }}
              className={styles}
            >
              {tab.Icon && <tab.Icon className="mr-1" />}

              <p>{tab.label}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
