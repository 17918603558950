import { ReactNode, createContext, FC, useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { useFetchCurrentBranchTimezone } from '@expane/data'
import { MINUTE_IN_MS, createCurrentDate } from '@expane/date'
import { store } from 'store'

const CurrentTimeContext = createContext<Date>(new Date())

export const CurrentTimeProvider: FC<{ children: ReactNode }> = observer(({ children }) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const [date, setDate] = useState(() => createCurrentDate(timezone))

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDate(createCurrentDate(timezone))
    }, MINUTE_IN_MS)

    return () => {
      clearInterval(intervalId)
    }
  }, [timezone])

  return <CurrentTimeContext.Provider value={date}>{children}</CurrentTimeContext.Provider>
})

export const useCurrentTime = () => {
  return useContext(CurrentTimeContext)
}
