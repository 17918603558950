import { gql } from 'graphql-request'

export const billingInfoFragment = gql`
  fragment billingInfoType on billingInfo {
    id
    fromDate
    toDate
    value
    paid
    planId
  }
`
