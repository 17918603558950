import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ServerTransactionType, TRANSACTION_TYPES } from '@expane/data'
import { TransactionDialog } from 'widgets/TransactionDialog/index'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'

interface RefundsForTransactionInfoProps {
  refundsForTransaction: ServerTransactionType[]
}

export const TransactionRefundInfo: FC<RefundsForTransactionInfoProps> = ({
  refundsForTransaction,
}) => {
  const { t } = useTranslation()

  const { dialog: transactionDialog, openEditDialog: openTransactionDialog } =
    useOpenDialog(TransactionDialog)

  if (!refundsForTransaction || refundsForTransaction.length === 0) return null

  return (
    <>
      <div className="mt-3 text-sm flex flex-col">
        <div className="text-secondary-color">{`${t('transaction.createdRefunds')}:`}</div>
        {refundsForTransaction.map(refundTransaction => {
          return (
            <div
              key={refundTransaction.id}
              className="text-primary-400 cursor-pointer hover:text-primary-500 max-w-max"
              onClick={() => openTransactionDialog(refundTransaction.id)}
            >
              {`${t(TRANSACTION_TYPES.refund.variations.refundFromAccount.name)} №${
                refundTransaction.id
              }`}
            </div>
          )
        })}
      </div>

      {transactionDialog}
    </>
  )
}
