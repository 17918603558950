import { permissions } from '@expane/logic/permission'
import {
  Button,
  ChooseButton,
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Paper,
  Spinner,
} from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IoAddCircleOutline,
  IoCheckmarkDoneCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { onChangeMultiTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { customProductSearchFilterFunction, ProductTreeMenuItem } from 'ui/TreeMenu/logic.product'
import { OnCreateProductFunc, useOpenProductDialog } from 'widgets/ProductDialog'

interface ChooseProductDialogProps {
  closeDialog: () => void
  title: string
  items: ProductTreeMenuItem[]
  selectedItems: TreeMenuItem[]
  onSelectedItems: (selectedItems: ProductTreeMenuItem[]) => void
  onChoose: () => void
}

export const ChooseProductDialog: FC<ChooseProductDialogProps> = observer(
  ({ closeDialog, title, items, selectedItems, onSelectedItems, onChoose }) => {
    const { t } = useTranslation()

    const { data: myPermissions } = useFetchMyPermissions()

    const { dialog: productDialog, openCreateDialog: openCreateProductDialog } =
      useOpenProductDialog()

    const onCreate: OnCreateProductFunc = (id, name, forSale, groupId) => {
      onSelectedItems(onChangeMultiTreeMenu({ id, name, parentId: groupId }, selectedItems, items))
    }

    const isEditingProductAllowed = myPermissions?.includes(permissions.product.set)

    return (
      <>
        <Dialog.Title>
          <div className="flex items-center justify-between">
            {title}{' '}
            {isEditingProductAllowed && (
              <button
                className="text-primary-500 hover:text-primary-700 hover:dark:text-primary-600 rounded-full focus:ring-1 ring-primary-500"
                onClick={() => {
                  openCreateProductDialog(onCreate)
                }}
              >
                <IoAddCircleOutline size="1.8rem" />
              </button>
            )}
          </div>
        </Dialog.Title>
        <Dialog.Body className="h-122">
          <Paper className="p-3 h-full overflow-auto">
            <TreeMenu
              type="MultiPickMode"
              items={items}
              selected={selectedItems}
              onSelected={item =>
                onSelectedItems(onChangeMultiTreeMenu(item, selectedItems, items))
              }
              className="text-sm"
              customSearchFilterFunction={customProductSearchFilterFunction}
            />
          </Paper>
        </Dialog.Body>
        <Dialog.Footer>
          <ChooseButton onClick={onChoose} disabled={selectedItems.length === 0} />

          <CloseButton onClick={closeDialog} />
          <Button
            className="mr-auto"
            type="outline"
            onClick={() => {
              onSelectedItems(items)
            }}
            Icon={IoCheckmarkDoneCircleOutline}
            disabled={items.length === selectedItems.length}
          >
            {t('selectAll')}
          </Button>
          <Button
            type="outline"
            onClick={() => {
              onSelectedItems([])
            }}
            Icon={IoCloseCircleOutline}
            disabled={selectedItems.length === 0}
          >
            {t('discardAll')}
          </Button>
        </Dialog.Footer>

        {productDialog}
      </>
    )
  },
)

export const ChooseProductDialogPlaceholder: FC<
  CommonPlaceholderDialogProps & { title: string }
> = ({ closeDialog, title }) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Body className="w-144 h-122">
        <Paper className="p-3 h-full overflow-auto">
          <Spinner expandCentered />
        </Paper>
      </Dialog.Body>
      <Dialog.Footer>
        <ChooseButton disabled />
        <CloseButton onClick={closeDialog} />
        <Button className="mr-auto" type="outline" disabled Icon={IoCheckmarkDoneCircleOutline}>
          {t('selectAll')}
        </Button>
        <Button type="outline" disabled Icon={IoCloseCircleOutline}>
          {t('discardAll')}
        </Button>
      </Dialog.Footer>
    </>
  )
}
