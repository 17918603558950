import { ClientWithPhones } from '@expane/logic/client'
import { ErrorMessageType, InputLabel, SelectDropdown, Tag } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { transformPersonsForSelect } from 'logic/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ClientDialog } from './ClientDialog'

type ClientForSelect = {
  id: number
  firstName: string
  lastName: string | null
}
type ClientSelectDropdownProps = {
  clients: ClientWithPhones<ClientForSelect>[] | null
  value: number | null
  onChange: (id: number | null) => void
  disabled?: boolean
  errorMessage?: ErrorMessageType
  isCreateClient?: boolean
  required?: boolean
  isClearable?: boolean
  className?: string
  tags?: {
    id: number
    name: string
    color: string
  }[]
  shiftFocus?: () => void
  inline?: boolean
  isFilter?: boolean
  onSearchValueChange?: (value: string) => void
  // Використовується там де прокидуються тільки знайдені items(пошук клієнтів в інших філіях)
  customSearchPlaceholder?: string
}

export const ClientSelectDropdown: FC<ClientSelectDropdownProps> = ({
  clients,
  value,
  onChange,
  disabled = false,
  errorMessage,
  isCreateClient = false,
  required = false,
  isClearable = false,
  className = 'w-full',
  tags,
  shiftFocus,
  inline = false,
  isFilter,
  onSearchValueChange,
  customSearchPlaceholder,
}) => {
  const { t } = useTranslation()

  const {
    dialog: clientDialog,
    openEditDialog: openEditClientDialog,
    openCreateDialog: openCreateClientDialog,
  } = useOpenDialog(ClientDialog)

  const clientsForSelect = transformPersonsForSelect(clients ?? [], true)

  let onPlusClick: (() => void) | undefined
  if (isCreateClient) {
    onPlusClick = () => openCreateClientDialog(id => onChange(id), shiftFocus)
  }
  let onEditClick: ((id: number) => void) | undefined
  if (!isFilter) {
    onEditClick = id => openEditClientDialog(id, shiftFocus)
  }

  return (
    <div className={className}>
      {!inline && (
        <div className="flex items-start">
          <InputLabel label={t('client.name')} onPlusClick={onPlusClick} required={required} />
          <div className="line-clamp-1 overflow-hidden grow-1 -mt-1.5">
            {tags?.map(tag => (
              <Tag key={tag.id} name={tag.name} color={tag.color} className="ml-0.5 inline-block" />
            ))}
          </div>
        </div>
      )}
      <SelectDropdown
        onSearchValueChange={onSearchValueChange}
        value={value}
        items={clientsForSelect}
        placeholder={t(inline ? 'client.name' : 'placeholders.defaultSelect')}
        required={required}
        customSearchPlaceholder={customSearchPlaceholder}
        noDataMessage={t('noClient')}
        onSelectChange={onChange}
        disabled={disabled}
        errorMessage={errorMessage}
        onEditClick={onEditClick}
        isClearable={isClearable || isFilter}
        isFilter={isFilter}
      />
      {clientDialog}
    </div>
  )
}
