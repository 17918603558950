import { gql } from 'graphql-request'
import { request } from '../../api'

export const validateEmployeeScheduleStartDate = async (
  startDate: Date,
  employeeId: number,
  branchId: number,
): Promise<boolean> => {
  const result = await request<{ employeeSchedulesAggregate: { aggregate: { count: number } } }>(
    gql`
      query ($startDate: timestamptz!, $employeeId: Int!, $branchId: Int!) {
        employeeSchedulesAggregate(
          limit: 1
          where: {
            _or: [{ endDate: { _gte: $startDate } }, { startDate: { _gte: $startDate } }]
            employeeId: { _eq: $employeeId }
            branchId: { _eq: $branchId }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    { startDate, employeeId, branchId },
  )

  return result.employeeSchedulesAggregate.aggregate.count === 0
}
