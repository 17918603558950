import { IconType } from 'react-icons'
import { generateUniqueId } from '../../logic/utils'

export const InstagramIcon: IconType = ({ className }) => {
  const uniqueId = generateUniqueId('icon-close')
  const gradientId = `${uniqueId}-paint0_linear`
  const clipPathId = `${uniqueId}-clip0`
  return (
    <svg
      className={className}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${clipPathId})`}>
        <path
          d="M18 35.9614C27.9198 35.9614 35.9614 27.9198 35.9614 18C35.9614 8.0802 27.9198 0.0386047 18 0.0386047C8.0802 0.0386047 0.0386047 8.0802 0.0386047 18C0.0386047 27.9198 8.0802 35.9614 18 35.9614Z"
          fill={`url(#${gradientId})`}
        />
        <path
          d="M21.8158 8.02145H14.1922C10.7915 8.02145 8.02945 10.7835 8.02945 14.1842V21.8078C8.02945 25.2085 10.7915 27.9706 14.1922 27.9706H21.8158C25.2165 27.9706 27.9786 25.2085 27.9786 21.8078V14.1842C27.9786 10.7835 25.2165 8.02145 21.8158 8.02145ZM25.7514 21.8158C25.7514 23.9871 23.9872 25.7593 21.8078 25.7593H14.1842C12.0129 25.7593 10.2407 23.9951 10.2407 21.8158V14.1922C10.2407 12.0209 12.0049 10.2487 14.1842 10.2487H21.8078C23.9792 10.2487 25.7514 12.0129 25.7514 14.1922V21.8158Z"
          fill="white"
        />
        <path
          d="M18 12.8989C15.1901 12.8989 12.899 15.19 12.899 18C12.899 20.8099 15.1901 23.101 18 23.101C20.81 23.101 23.1011 20.8099 23.1011 18C23.1011 15.19 20.81 12.8989 18 12.8989ZM18 21.0973C16.2917 21.0973 14.9027 19.7083 14.9027 18C14.9027 16.2916 16.2917 14.9026 18 14.9026C19.7084 14.9026 21.0974 16.2916 21.0974 18C21.0974 19.7083 19.7084 21.0973 18 21.0973Z"
          fill="white"
        />
        <path
          d="M23.4896 13.4583C23.9595 13.3821 24.2787 12.9393 24.2026 12.4694C24.1264 11.9994 23.6836 11.6802 23.2137 11.7564C22.7437 11.8326 22.4245 12.2753 22.5007 12.7453C22.5769 13.2152 23.0196 13.5344 23.4896 13.4583Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id={gradientId}
          x1="4.32372"
          y1="31.6763"
          x2="29.8489"
          y2="6.15115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEE411" />
          <stop offset="0.0518459" stopColor="#FEDB16" />
          <stop offset="0.1381" stopColor="#FEC125" />
          <stop offset="0.2481" stopColor="#FE983D" />
          <stop offset="0.3762" stopColor="#FE5F5E" />
          <stop offset="0.5" stopColor="#FE2181" />
          <stop offset="1" stopColor="#9000DC" />
        </linearGradient>
        <clipPath id={clipPathId}>
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const TelegramIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#0088CC" />
      <path
        d="M28.384 10.894L21.278 24.952C21.124 25.26 20.75 25.37 20.464 25.172L16.79 22.84L14.656 25.766C14.414 26.096 13.886 25.92 13.886 25.502L13.974 21.19L20.31 15.844L11.862 19.672L7.814 17.076C7.396 16.812 7.484 16.174 7.968 16.042L27.702 10.08C28.186 9.94799 28.604 10.454 28.384 10.894Z"
        fill="white"
      />
    </svg>
  )
}

export const ViberIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_195_18037)">
        <path
          d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
          fill="#7360F2"
        />
        <path
          d="M28.397 12.1602V12.1336C28.1557 11.1559 27.5257 10.1454 26.6993 9.29454C25.7339 8.29636 24.5045 7.51295 23.3305 7.25931H23.3059C19.8046 6.58841 16.2077 6.58841 12.7064 7.25931H12.6818C10.5014 7.73181 8.13478 10.0104 7.60705 12.1336V12.1602C6.95866 15.1038 6.95866 18.1532 7.60705 21.0968V21.1214C7.98341 22.635 9.29251 24.2264 10.8021 25.1877C11.295 25.509 11.8317 25.7577 12.3955 25.9261V28.2948C12.3963 28.4841 12.4534 28.6688 12.5596 28.8255C12.6658 28.9822 12.8162 29.1038 12.9918 29.1746C13.1673 29.2455 13.36 29.2625 13.5452 29.2235C13.7304 29.1844 13.8998 29.0911 14.0318 28.9554L16.4332 26.46C16.9527 26.4886 17.4764 26.505 17.9959 26.505C19.7744 26.5044 21.5489 26.336 23.2957 26.0018H23.3202C25.4946 25.5273 27.8632 23.2486 28.3868 21.1254V21.1009C29.0392 18.1568 29.0427 15.1059 28.397 12.1602ZM26.4968 20.6591C26.145 22.0439 24.3491 23.7682 22.9193 24.0852C21.0623 24.441 19.1722 24.5953 17.282 24.5454C17.2636 24.5443 17.2452 24.5474 17.2282 24.5545C17.2112 24.5616 17.196 24.5725 17.1839 24.5864L15.4309 26.3864L13.5655 28.2989C13.5356 28.3304 13.497 28.3523 13.4546 28.3616C13.4122 28.3709 13.368 28.3673 13.3276 28.3512C13.2873 28.3351 13.2527 28.3073 13.2284 28.2714C13.2041 28.2354 13.1911 28.193 13.1911 28.1495V24.2223C13.1912 24.1907 13.1802 24.1601 13.16 24.1358C13.1398 24.1115 13.1117 24.0951 13.0807 24.0893C12.7825 24.0185 12.495 23.9085 12.2257 23.762C11.0107 23.1136 9.78546 21.7841 9.50319 20.6591C8.91851 17.9983 8.91851 15.2423 9.50319 12.5816C9.85296 11.2029 11.6591 9.48272 13.0786 9.16363C16.3294 8.54164 19.6686 8.54164 22.9193 9.16363C23.7068 9.33954 24.6068 9.93886 25.3105 10.6752C25.8893 11.2745 26.3332 11.9659 26.4968 12.5877C27.0806 15.2465 27.0806 18.0003 26.4968 20.6591Z"
          fill="white"
        />
        <path
          d="M22.9705 20.8636C22.7047 21.4701 22.2478 21.9729 21.6696 22.2954C21.4881 22.3724 21.2998 22.432 21.1071 22.4734C20.8882 22.4079 20.6796 22.3629 20.4934 22.2832C18.9429 21.6798 17.4995 20.831 16.2184 19.7693C15.8379 19.4448 15.4804 19.0942 15.1486 18.72C14.3293 17.7764 13.6413 16.7265 13.1032 15.5986C12.8414 15.0648 12.6205 14.5125 12.3955 13.9623C12.1909 13.4611 12.4936 12.9395 12.8046 12.5652C13.1089 12.2181 13.4832 11.9392 13.903 11.747C14.0507 11.6728 14.2197 11.6526 14.3807 11.69C14.5418 11.7273 14.6846 11.8199 14.7846 11.9516C15.3072 12.5387 15.7678 13.1783 16.1591 13.86C16.2824 14.0582 16.3246 14.2963 16.277 14.5248C16.2294 14.7533 16.0956 14.9548 15.9034 15.0873C15.8011 15.1588 15.6989 15.2407 15.6109 15.3204C15.5242 15.3847 15.4494 15.4636 15.39 15.5536C15.3387 15.6379 15.3076 15.7329 15.2991 15.8312C15.2906 15.9295 15.3049 16.0284 15.3409 16.1202C15.7357 17.2063 16.335 18.1125 17.2309 18.7486C17.5341 18.963 17.8608 19.1421 18.2046 19.2825C18.4291 19.4046 18.6813 19.4666 18.9368 19.4625C19.3827 19.4093 19.53 18.9163 19.8409 18.6627C19.9836 18.5459 20.1602 18.4782 20.3445 18.4697C20.5287 18.4612 20.7108 18.5124 20.8636 18.6157C21.195 18.8202 21.5141 19.0513 21.8332 19.2763C22.1488 19.4917 22.4526 19.7239 22.7434 19.9718C22.8794 20.0706 22.9766 20.2138 23.018 20.3767C23.0595 20.5396 23.0427 20.7118 22.9705 20.8636Z"
          fill="white"
        />
        <path
          d="M18.587 11.0618H18.3252C18.4091 11.0577 18.497 11.0618 18.587 11.0618Z"
          fill="white"
        />
        <path
          d="M23.1136 17.192C22.9091 17.192 22.8089 17.0182 22.7946 16.828C22.768 16.4536 22.7475 16.0773 22.6964 15.705C22.608 15.0769 22.3892 14.4742 22.0541 13.9357C21.7097 13.3746 21.2514 12.8921 20.7088 12.5194C20.1661 12.1466 19.5513 11.8918 18.9041 11.7716C18.6096 11.7184 18.3089 11.7061 18.0123 11.6755C17.8241 11.655 17.5766 11.6427 17.5357 11.4095C17.5296 11.3643 17.5332 11.3182 17.5463 11.2744C17.5594 11.2306 17.5817 11.1902 17.6118 11.1557C17.6418 11.1212 17.6788 11.0936 17.7203 11.0746C17.7619 11.0555 17.807 11.0456 17.8527 11.0455C17.9018 11.0426 17.951 11.0426 18 11.0455C18.8941 11.053 19.7725 11.281 20.5574 11.7091C21.3424 12.1373 22.0096 12.7524 22.5 13.5C22.8872 14.1026 23.15 14.7765 23.2732 15.482C23.3509 15.9239 23.3796 16.378 23.4143 16.828C23.4213 16.8721 23.4188 16.9171 23.4071 16.9602C23.3953 17.0033 23.3745 17.0434 23.3461 17.0778C23.3177 17.1122 23.2822 17.1402 23.2422 17.1599C23.2021 17.1796 23.1583 17.1906 23.1136 17.192Z"
          fill="white"
        />
        <path
          d="M22.0071 16.2368C22.0059 16.2976 21.9977 16.358 21.9825 16.4168C21.9646 16.476 21.929 16.5282 21.8804 16.5663C21.8318 16.6045 21.7727 16.6267 21.711 16.6301C21.6493 16.6334 21.5881 16.6177 21.5357 16.585C21.4832 16.5524 21.4421 16.5044 21.418 16.4475C21.3954 16.3649 21.3851 16.2795 21.3873 16.1939C21.392 15.7364 21.3015 15.2829 21.1214 14.8623C21.0805 14.7723 21.0355 14.6802 20.9843 14.5923C20.6915 14.0876 20.257 13.6801 19.7346 13.4202C19.4194 13.2714 19.0847 13.1681 18.7405 13.1134C18.5891 13.0889 18.4377 13.0745 18.2864 13.0541C18.247 13.0521 18.2084 13.0421 18.173 13.0246C18.1377 13.0071 18.1063 12.9826 18.0808 12.9525C18.0553 12.9224 18.0362 12.8874 18.0248 12.8496C18.0134 12.8118 18.0098 12.7721 18.0143 12.733C18.0143 12.6929 18.0228 12.6532 18.0393 12.6167C18.0557 12.5801 18.0796 12.5474 18.1096 12.5208C18.1395 12.4941 18.1748 12.4741 18.213 12.462C18.2512 12.4499 18.2916 12.4461 18.3314 12.4507C18.932 12.4739 19.5204 12.6274 20.0557 12.9007C20.7094 13.2296 21.2427 13.7565 21.5796 14.4061C21.7693 14.7855 21.8964 15.193 21.9559 15.613C21.9669 15.6677 21.9751 15.723 21.9805 15.7786C21.9907 15.9014 21.9948 16.0241 22.005 16.1755C22.005 16.1898 22.0071 16.2082 22.0071 16.2368Z"
          fill="white"
        />
        <path
          d="M20.6059 15.9545C20.5943 16.0064 20.5675 16.0536 20.5289 16.09C20.4902 16.1265 20.4415 16.1505 20.3891 16.1591H20.3441C20.2771 16.1634 20.2105 16.1458 20.1544 16.1089C20.0983 16.072 20.0557 16.0178 20.0332 15.9545C20.0205 15.921 20.0122 15.8859 20.0086 15.8502C19.9979 15.7126 19.9774 15.5759 19.9473 15.4411C19.8865 15.1683 19.7315 14.9257 19.5095 14.7559C19.4023 14.6789 19.2826 14.6207 19.1557 14.5841C18.9941 14.537 18.8243 14.5493 18.6648 14.5104C18.5859 14.4965 18.5158 14.4518 18.4698 14.3862C18.4238 14.3207 18.4056 14.2396 18.4193 14.1607C18.4345 14.0851 18.4768 14.0176 18.5382 13.9709C18.5995 13.9243 18.6758 13.9015 18.7527 13.907C19.6895 13.9745 20.3891 14.4245 20.5691 15.4288C20.5876 15.5221 20.6006 15.6163 20.608 15.7111C20.6194 15.7919 20.6187 15.8739 20.6059 15.9545Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_195_18037">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const FacebookIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#3B5998" />
      <path
        d="M19.4638 27V18.7894H22.3585L22.7919 15.5895H19.4638V13.5465C19.4638 12.6201 19.7339 11.9888 21.1294 11.9888L22.9091 11.988V9.12607C22.6013 9.08715 21.5447 9 20.3158 9C17.7497 9 15.993 10.4912 15.993 13.2297V15.5895H13.0909V18.7894H15.993V26.9999H19.4638V27Z"
        fill="white"
      />
    </svg>
  )
}

export const CloseIcon: IconType = ({ className, size }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.21967 1.28033C-0.0732234 0.987437 -0.0732234 0.512563 0.21967 0.21967C0.512563 -0.0732233 0.987439 -0.0732233 1.28033 0.21967L9 7.93934L16.7197 0.21967C17.0126 -0.0732233 17.4874 -0.0732233 17.7803 0.21967C18.0732 0.512563 18.0732 0.987437 17.7803 1.28033L10.0607 9L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L9 10.0607L1.28033 17.7803C0.987438 18.0732 0.512564 18.0732 0.219671 17.7803C-0.0732224 17.4874 -0.0732224 17.0126 0.219671 16.7197L7.93934 9L0.21967 1.28033Z"
        fill={'currentColor'}
      />
    </svg>
  )
}

export const PhoneIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.65387 1.32849C3.40343 1.00649 2.92745 0.976861 2.639 1.26531L1.60508 2.29923C1.1216 2.78271 0.94387 3.46766 1.1551 4.06847C2.00338 6.48124 3.39215 8.74671 5.32272 10.6773C7.25329 12.6078 9.51876 13.9966 11.9315 14.8449C12.5323 15.0561 13.2173 14.8784 13.7008 14.3949L14.7347 13.361C15.0231 13.0726 14.9935 12.5966 14.6715 12.3461L12.3653 10.5524C12.2008 10.4245 11.9866 10.3793 11.7845 10.4298L9.59541 10.9771C9.00082 11.1257 8.37183 10.9515 7.93845 10.5181L5.48187 8.06155C5.04849 7.62817 4.87427 6.99919 5.02292 6.40459L5.57019 4.21553C5.62073 4.01336 5.57552 3.79918 5.44758 3.63468L3.65387 1.32849ZM1.88477 0.511076C2.62689 -0.231039 3.8515 -0.154797 4.49583 0.673634L6.28954 2.97983C6.6187 3.40304 6.73502 3.95409 6.60498 4.47423L6.05772 6.66329C5.99994 6.8944 6.06766 7.13888 6.2361 7.30732L8.69268 9.7639C8.86113 9.93235 9.1056 10.0001 9.33671 9.94229L11.5258 9.39502C12.0459 9.26499 12.597 9.3813 13.0202 9.71047L15.3264 11.5042C16.1548 12.1485 16.231 13.3731 15.4889 14.1152L14.455 15.1492C13.7153 15.8889 12.6089 16.2137 11.5778 15.8512C9.01754 14.9511 6.61438 13.4774 4.56849 11.4315C2.5226 9.38562 1.04895 6.98246 0.148838 4.42225C-0.213682 3.39112 0.11113 2.28472 0.85085 1.545L1.88477 0.511076Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const MenuIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="47"
      height="27"
      viewBox="0 0 47 27"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="46"
        y1="1"
        x2="1"
        y2="0.999998"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="33"
        y1="13"
        x2="1"
        y2="13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="26"
        y1="26"
        x2="1"
        y2="26"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export const SunSwitchIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="52"
      height="32"
      viewBox="0 0 52 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="32" rx="16" fill="currentColor" />
      <rect x="4" y="4" width="24" height="24" rx="12" fill="white" />
      <path
        d="M16 19.5C17.933 19.5 19.5 17.933 19.5 16C19.5 14.067 17.933 12.5 16 12.5C14.067 12.5 12.5 14.067 12.5 16C12.5 17.933 14.067 19.5 16 19.5Z"
        fill="currentColor"
      />
      <path
        d="M16 9C16.2416 9 16.4375 9.19588 16.4375 9.4375V11.1875C16.4375 11.4291 16.2416 11.625 16 11.625C15.7584 11.625 15.5625 11.4291 15.5625 11.1875V9.4375C15.5625 9.19588 15.7584 9 16 9Z"
        fill="currentColor"
      />
      <path
        d="M16 20.375C16.2416 20.375 16.4375 20.5709 16.4375 20.8125V22.5625C16.4375 22.8041 16.2416 23 16 23C15.7584 23 15.5625 22.8041 15.5625 22.5625V20.8125C15.5625 20.5709 15.7584 20.375 16 20.375Z"
        fill="currentColor"
      />
      <path
        d="M23 16C23 16.2416 22.8041 16.4375 22.5625 16.4375H20.8125C20.5709 16.4375 20.375 16.2416 20.375 16C20.375 15.7584 20.5709 15.5625 20.8125 15.5625H22.5625C22.8041 15.5625 23 15.7584 23 16Z"
        fill="currentColor"
      />
      <path
        d="M11.625 16C11.625 16.2416 11.4291 16.4375 11.1875 16.4375H9.4375C9.19588 16.4375 9 16.2416 9 16C9 15.7584 9.19588 15.5625 9.4375 15.5625H11.1875C11.4291 15.5625 11.625 15.7584 11.625 16Z"
        fill="currentColor"
      />
      <path
        d="M20.9497 11.0503C21.1206 11.2211 21.1206 11.4981 20.9497 11.669L19.7123 12.9064C19.5415 13.0773 19.2644 13.0773 19.0936 12.9064C18.9227 12.7356 18.9227 12.4586 19.0936 12.2877L20.331 11.0503C20.5019 10.8794 20.7789 10.8794 20.9497 11.0503Z"
        fill="currentColor"
      />
      <path
        d="M12.9064 19.0936C13.0773 19.2645 13.0773 19.5415 12.9064 19.7123L11.669 20.9498C11.4981 21.1206 11.2211 21.1206 11.0503 20.9498C10.8794 20.7789 10.8794 20.5019 11.0503 20.3311L12.2877 19.0936C12.4585 18.9228 12.7356 18.9228 12.9064 19.0936Z"
        fill="currentColor"
      />
      <path
        d="M20.9497 20.9498C20.7789 21.1206 20.5019 21.1206 20.331 20.9498L19.0936 19.7123C18.9227 19.5415 18.9227 19.2645 19.0936 19.0936C19.2644 18.9228 19.5415 18.9228 19.7123 19.0936L20.9497 20.3311C21.1206 20.5019 21.1206 20.7789 20.9497 20.9498Z"
        fill="currentColor"
      />
      <path
        d="M12.9064 12.9064C12.7356 13.0773 12.4585 13.0773 12.2877 12.9064L11.0503 11.669C10.8794 11.4982 10.8794 11.2211 11.0503 11.0503C11.2211 10.8794 11.4981 10.8794 11.669 11.0503L12.9064 12.2877C13.0773 12.4586 13.0773 12.7356 12.9064 12.9064Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const MoonSwitchIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="52"
      height="32"
      viewBox="0 0 52 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="32" rx="16" fill="currentColor" />
      <rect x="24" y="4" width="24" height="24" rx="12" fill="white" />
      <path
        d="M34.2503 9.24352C34.4097 9.43788 34.4641 9.73081 34.3206 9.99411C33.8301 10.8941 33.5513 11.9251 33.5513 13.0223C33.5513 16.5401 36.4203 19.3886 39.9551 19.3886C40.4157 19.3886 40.8645 19.3403 41.2967 19.2488C41.5923 19.1862 41.8611 19.3198 42.0052 19.525C42.1539 19.7368 42.1851 20.0528 41.9779 20.3071C40.6404 21.9487 38.5942 23 36.3006 23C32.2667 23 29 19.7506 29 15.7463C29 12.7326 30.8499 10.148 33.4831 9.05258C33.7873 8.92602 34.0858 9.04296 34.2503 9.24352Z"
        fill="currentColor"
      />
      <path
        d="M38.445 11.7547C38.5027 11.5818 38.7473 11.5818 38.805 11.7547L39.1439 12.7713C39.2949 13.2245 39.6505 13.5801 40.1037 13.7312L41.1203 14.07C41.2932 14.1277 41.2932 14.3723 41.1203 14.43L40.1037 14.7688C39.6505 14.9199 39.2949 15.2755 39.1439 15.7287L38.805 16.7453C38.7473 16.9182 38.5027 16.9182 38.445 16.7453L38.1062 15.7286C37.9551 15.2755 37.5995 14.9199 37.1464 14.7688L36.1297 14.43C35.9568 14.3723 35.9568 14.1277 36.1297 14.07L37.1464 13.7311C37.5995 13.5801 37.9551 13.2245 38.1062 12.7713L38.445 11.7547Z"
        fill="currentColor"
      />
      <path
        d="M41.13 9.08647C41.1685 8.97117 41.3316 8.97118 41.37 9.08647L41.5959 9.76423C41.6966 10.0663 41.9337 10.3034 42.2358 10.4041L42.9135 10.63C43.0288 10.6685 43.0288 10.8315 42.9135 10.87L42.2358 11.0959C41.9337 11.1966 41.6966 11.4337 41.5959 11.7358L41.37 12.4135C41.3316 12.5288 41.1685 12.5288 41.13 12.4135L40.9041 11.7358C40.8034 11.4337 40.5663 11.1966 40.2642 11.0959L39.5865 10.87C39.4712 10.8315 39.4712 10.6685 39.5865 10.63L40.2642 10.4041C40.5663 10.3034 40.8034 10.0663 40.9041 9.76423L41.13 9.08647Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const GeoIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1658 8.93977C11.6418 10.0015 10.9325 11.0601 10.2058 12.01C9.48132 12.957 8.75442 13.7768 8.20768 14.3605C8.13503 14.438 8.06566 14.5113 8 14.5801C7.93434 14.5113 7.86497 14.438 7.79232 14.3605C7.24558 13.7768 6.51868 12.957 5.79425 12.01C5.06754 11.0601 4.35825 10.0015 3.83423 8.93977C3.3048 7.86708 3 6.86191 3 6C3 3.23858 5.23858 1 8 1C10.7614 1 13 3.23858 13 6C13 6.86191 12.6952 7.86708 12.1658 8.93977ZM8 16C8 16 14 10.3137 14 6C14 2.68629 11.3137 0 8 0C4.68629 0 2 2.68629 2 6C2 10.3137 8 16 8 16Z"
        fill="currentColor"
      />
      <path
        d="M8 8C6.89543 8 6 7.10457 6 6C6 4.89543 6.89543 4 8 4C9.10457 4 10 4.89543 10 6C10 7.10457 9.10457 8 8 8ZM8 9C9.65685 9 11 7.65685 11 6C11 4.34315 9.65685 3 8 3C6.34315 3 5 4.34315 5 6C5 7.65685 6.34315 9 8 9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const ImagePlaceholderIcon: IconType = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 80 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.242 70.4851C28.5229 70.4851 16.8038 70.4851 5.08474 70.4851C4.51227 70.4851 3.9324 70.4851 3.35747 70.4259C0.423584 70.152 -0.755893 68.1755 0.495142 65.5032C1.58085 63.1961 2.76526 60.9481 3.91513 58.6657C9.34369 47.9188 14.7722 37.1735 20.2008 26.4299C20.3686 26.1018 20.5438 25.7736 20.7387 25.4602C22.1057 23.269 24.3265 22.9606 26.076 24.8507C27.3097 26.1733 28.3535 27.6588 29.454 29.0924C35.0471 36.4012 40.6401 43.7133 46.2332 51.0287C48.676 54.2118 51.753 54.3845 54.6228 51.5222C56.0465 50.1009 57.4382 48.6426 58.8792 47.2336C61.4775 44.6921 64.4262 44.8451 66.5483 47.8086C70.8073 53.7553 74.9601 59.781 79.1327 65.787C79.4588 66.2581 79.7086 66.7778 79.8729 67.3267C80.2776 68.706 79.7051 69.8164 78.3061 70.1841C77.4288 70.3941 76.5282 70.4911 75.6263 70.4728C63.8381 70.4925 52.0434 70.4966 40.242 70.4851Z"
        fill="#F6F6F6"
      />
      <path
        d="M66.5236 11.5977C66.5206 13.8915 65.8366 16.1328 64.5582 18.0375C63.2799 19.9421 61.4648 21.4242 59.3431 22.296C57.2213 23.1678 54.8885 23.39 52.6404 22.9343C50.3922 22.4787 48.33 21.3658 46.7151 19.7367C45.1003 18.1076 44.0055 16.0357 43.5696 13.7837C43.1336 11.5316 43.3762 9.20086 44.2666 7.08685C45.1569 4.97285 46.6549 3.17081 48.5707 1.9092C50.4864 0.647588 52.7336 -0.0167797 55.0274 0.000322047C58.0818 0.039137 60.9987 1.27664 63.1492 3.44605C65.2997 5.61547 66.5115 8.54305 66.5236 11.5977Z"
        fill="#F6F6F6"
      />
    </svg>
  )
}

export const ArrowForwardIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width={className}
      height={className}
      viewBox="0 0 22 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L20 20L2 38"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ArrowDownIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00006 11.1666C7.86735 11.1672 7.73998 11.1143 7.64673 11.0199L2.31339 5.68656C2.12982 5.48956 2.13524 5.18256 2.32565 4.99215C2.51605 4.80174 2.82305 4.79633 3.02006 4.9799L8.00006 9.9599L12.9801 4.9799C13.1771 4.79633 13.4841 4.80174 13.6745 4.99215C13.8649 5.18256 13.8703 5.48956 13.6867 5.68656L8.35339 11.0199C8.26014 11.1143 8.13277 11.1672 8.00006 11.1666Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const ArrowTopIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="14"
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 12.25L12 1.75L1.5 12.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const BranchIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 0H4C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4L0 32H18.6667V4C18.6667 2.93913 18.2452 1.92172 17.4951 1.17157C16.7449 0.421427 15.7275 0 14.6667 0ZM8 25.3333H4V22.6667H8V25.3333ZM8 20H4V17.3333H8V20ZM8 14.6667H4V12H8V14.6667ZM8 9.33333H4V6.66667H8V9.33333ZM14.6667 25.3333H10.6667V22.6667H14.6667V25.3333ZM14.6667 20H10.6667V17.3333H14.6667V20ZM14.6667 14.6667H10.6667V12H14.6667V14.6667ZM14.6667 9.33333H10.6667V6.66667H14.6667V9.33333ZM28 6.66667H21.3333V32H32V10.6667C32 9.6058 31.5786 8.58839 30.8284 7.83824C30.0783 7.08809 29.0609 6.66667 28 6.66667ZM28 25.3333H25.3333V22.6667H28V25.3333ZM28 20H25.3333V17.3333H28V20ZM28 14.6667H25.3333V12H28V14.6667Z"
        fill="white"
      />
    </svg>
  )
}

export const EmployeeIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 17.3333C8.81331 17.3333 7.65327 16.9814 6.66658 16.3222C5.67988 15.6629 4.91085 14.7258 4.45672 13.6294C4.0026 12.5331 3.88378 11.3267 4.11529 10.1628C4.3468 8.99891 4.91824 7.92981 5.75736 7.0907C6.59647 6.25158 7.66557 5.68014 8.82946 5.44862C9.99334 5.21711 11.1997 5.33593 12.2961 5.79006C13.3925 6.24418 14.3295 7.01322 14.9888 7.99991C15.6481 8.98661 16 10.1466 16 11.3333C15.9982 12.9241 15.3655 14.4492 14.2407 15.574C13.1159 16.6989 11.5908 17.3316 10 17.3333ZM20 26.6667C19.9979 24.8992 19.2948 23.2047 18.045 21.955C16.7953 20.7052 15.1008 20.0021 13.3333 20H6.66667C4.89921 20.0021 3.20474 20.7052 1.95496 21.955C0.705176 23.2047 0.00211714 24.8992 0 26.6667L0 32H20V26.6667ZM23.3333 12C22.1466 12 20.9866 11.6481 19.9999 10.9888C19.0132 10.3295 18.2442 9.39246 17.7901 8.2961C17.3359 7.19975 17.2171 5.99335 17.4486 4.82946C17.6801 3.66557 18.2516 2.59648 19.0907 1.75736C19.9298 0.918247 20.9989 0.346802 22.1628 0.115291C23.3267 -0.11622 24.5331 0.00259972 25.6294 0.456726C26.7258 0.910851 27.6629 1.67989 28.3222 2.66658C28.9814 3.65328 29.3333 4.81331 29.3333 6C29.3316 7.59076 28.6989 9.11586 27.574 10.2407C26.4492 11.3655 24.9241 11.9982 23.3333 12ZM25.3333 14.6667H18.6667C18.4327 14.6774 18.1994 14.701 17.968 14.7373C17.4832 15.8591 16.7672 16.8659 15.8667 17.692C17.8218 18.2452 19.5432 19.4206 20.7703 21.04C21.9974 22.6595 22.6631 24.6348 22.6667 26.6667H32V21.3333C31.9979 19.5659 31.2948 17.8714 30.045 16.6216C28.7953 15.3718 27.1008 14.6688 25.3333 14.6667Z"
        fill="white"
      />
    </svg>
  )
}

export const ServiceIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 16C6.25467 16 2.66667 12.412 2.66667 8C2.66667 3.588 6.25467 0 10.6667 0C15.0787 0 18.6667 3.588 18.6667 8C18.6667 12.412 15.0787 16 10.6667 16ZM30 24C30 23.3867 29.8813 22.8067 29.7093 22.2493L31.5973 21.1613L30.2667 18.8507L28.3853 19.9347C27.5787 19.0653 26.528 18.4347 25.3333 18.1613V16H22.6667V18.1613C21.472 18.4347 20.42 19.0653 19.6147 19.9347L17.7333 18.8507L16.4027 21.1613L18.2907 22.2493C18.1187 22.8067 18 23.3867 18 24C18 24.6133 18.1187 25.1933 18.2907 25.7507L16.4027 26.8387L17.7333 29.1493L19.6147 28.0653C20.4213 28.9347 21.472 29.5653 22.6667 29.8387V32H25.3333V29.8387C26.528 29.5653 27.58 28.9347 28.3853 28.0653L30.2667 29.1493L31.5973 26.8387L29.7093 25.7507C29.8813 25.1933 30 24.6133 30 24ZM24 26C22.8973 26 22 25.1027 22 24C22 22.8973 22.8973 22 24 22C25.1027 22 26 22.8973 26 24C26 25.1027 25.1027 26 24 26ZM13.3333 24C13.3333 22.0547 13.8627 20.2373 14.772 18.6667H6C2.692 18.6667 0 21.3587 0 24.6667V32H16.9613C14.7413 30.0453 13.3333 27.1907 13.3333 24Z"
        fill="white"
      />
    </svg>
  )
}

export const SearchIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16671 14.5639C11.8486 14.5639 14.8334 11.5791 14.8334 7.89718C14.8334 4.21526 11.8486 1.23047 8.16671 1.23047C4.48479 1.23047 1.5 4.21526 1.5 7.89718C1.5 11.5791 4.48479 14.5639 8.16671 14.5639Z"
        stroke="currentColor"
        strokeWidth="1.50001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 16.2305L12.875 12.6055"
        stroke="currentColor"
        strokeWidth="1.50001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const CalendarIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6667 2.66667H24V2C24 0.894667 23.104 0 22 0C20.896 0 20 0.894667 20 2V2.66667H12V2C12 0.894667 11.104 0 10 0C8.896 0 8 0.894667 8 2V2.66667H7.33333C3.29067 2.66667 0 5.956 0 10V24.6667C0 28.7107 3.29067 32 7.33333 32H24.6667C28.7093 32 32 28.7107 32 24.6667V10C32 5.956 28.7093 2.66667 24.6667 2.66667ZM24.6667 28H7.33333C5.49467 28 4 26.504 4 24.6667V12H28V24.6667C28 26.504 26.5053 28 24.6667 28ZM13.3333 16.6667V19.3333C13.3333 20.4373 12.4373 21.3333 11.3333 21.3333H8.66667C7.56267 21.3333 6.66667 20.4373 6.66667 19.3333V16.6667C6.66667 15.5627 7.56267 14.6667 8.66667 14.6667H11.3333C12.4373 14.6667 13.3333 15.5627 13.3333 16.6667Z"
        fill="white"
      />
    </svg>
  )
}

export const EmailIcon: IconType = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4847 9.03585L22.6359 3.31675V16.0381L16.4847 9.03585ZM7.51531 9.03478L10.7184 12.011C11.0575 12.3192 11.4995 12.5054 11.9825 12.5054H11.9995H12.0125C12.4965 12.5054 12.9385 12.3181 13.2816 12.0078L13.2786 12.0099L16.4847 9.03585L22.6359 16.0381H1.36506L7.51531 9.03478ZM1.98608 1.96081H22.0169L12.3955 10.9033C12.2873 10.9949 12.1534 11.0447 12.0155 11.0446H12.0015H11.9885C11.8501 11.0448 11.7157 10.9946 11.6075 10.9023L11.6085 10.9033L1.98608 1.96081ZM1.36506 3.31568L7.51531 9.03478L1.36506 16.0381V3.31568ZM22.966 0.703337C22.7259 0.574914 22.4439 0.5 22.1449 0.5H1.85908C1.56923 0.500074 1.28336 0.572249 1.02404 0.710828L1.03504 0.705477C0.724604 0.869335 0.463167 1.12283 0.280266 1.43732C0.0973647 1.75182 0.00028736 2.11478 0 2.48521L0 15.5116C0.000529426 16.0388 0.196461 16.5442 0.544803 16.917C0.893146 17.2898 1.36545 17.4994 1.85808 17.5H22.1419C22.6346 17.4994 23.1069 17.2898 23.4552 16.917C23.8035 16.5442 23.9995 16.0388 24 15.5116V2.48521C24 1.70718 23.581 1.03296 22.971 0.708688L22.96 0.703337H22.966Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const SuccessIcon: IconType = ({ className, size }) => {
  return (
    <svg
      className={className}
      width={size || '27'}
      height={size || '28'}
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4999 0.880859C6.26468 0.880859 0.379883 6.76566 0.379883 14.0009C0.379883 21.2361 6.26468 27.1209 13.4999 27.1209C20.7351 27.1209 26.6199 21.2361 26.6199 14.0009C26.6199 6.76566 20.7351 0.880859 13.4999 0.880859ZM20.6711 9.97206L11.7111 18.9321C11.5863 19.0569 11.4231 19.1209 11.2599 19.1209C11.0967 19.1209 10.9335 19.0569 10.8087 18.9321L6.32868 14.4521C6.07588 14.2025 6.07588 13.7993 6.32868 13.5497C6.57828 13.2969 6.98148 13.2969 7.23108 13.5497L11.2599 17.5753L19.7687 9.06966C20.0183 8.81686 20.4215 8.81686 20.6711 9.06966C20.9239 9.31926 20.9239 9.72246 20.6711 9.97206Z"
        fill="#00B075"
      />
    </svg>
  )
}

export const ExpaneIcon: IconType = ({ className }) => (
  <svg
    className={className}
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    version="1.1"
    viewBox="0 0 950 264"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor">
      <path d="m76.074 129.53c0.631 0.019 6.413 0.035 7.82 0.045 7.227 0.054 16.945 0.085 24.169 0.107 6.188 0.018 12.668 0.052 18.937 0.081 0.593 3e-3 5.755 0.068 5.755 0.061" />
      <path d="m132.69 117.84 2e-3 -3e-3s-5.098-0.058-5.634-0.061c-6.276-0.029-12.762-0.063-18.957-0.082-7.208-0.021-16.906-0.053-24.117-0.106-1.358-0.01-6.937-0.022-7.546-0.04-6.615-0.2-12.148 5.008-12.347 11.623-0.2 6.614 5.008 12.147 11.623 12.347 0.653 0.02 6.638 0.04 8.094 0.051 7.242 0.053 16.982 0.084 24.221 0.106 6.182 0.019 12.655 0.052 18.917 0.082 0.532 2e-3 4.748 0.055 5.586 0.061 11.003 0.073 12.215-11.855 12.215-11.935 0.031-6.617-5.316-12.015-11.934-12.046l-0.123 3e-3z" />
      <path d="m144.73 129.79c0.352-37.796-29.543-68.154-67.351-68.918-38.076-0.771-69.614 29.502-70.384 67.559-0.769 38.057 29.518 69.58 67.594 70.351 16.142 0.326 31.888-5.015 44.496-15.094l-15.301-19.121c-8.132 6.501-18.288 9.946-28.699 9.736-24.559-0.497-44.095-20.83-43.598-45.377 0.496-24.547 20.838-44.072 45.397-43.575 24.386 0.493 43.832 20.512 43.605 44.891l24.241-0.452z" />
      <path d="m110.8 174.26c0.899-0.346 0.425-0.215 1.395-0.313" />
      <path d="m114.12 185.78c6.218-1 10.656-6.701 10.011-13.048-0.67-6.584-6.559-11.385-13.143-10.715-3.126 0.318-1.587-0.064-4.486 1.051-6.176 2.376-9.262 9.319-6.886 15.496 2.25 5.848 8.594 8.925 14.504 7.216z" />
    </g>
    <g fill="currentColor">
      <path d="m874.37 129.7c0.632 0.019 6.414 0.035 7.821 0.046 7.227 0.053 16.945 0.084 24.169 0.106 6.188 0.018 12.668 0.052 18.937 0.082 0.593 2e-3 5.755 0.067 5.755 0.061" />
      <path d="m930.98 118.01 2e-3 -3e-3s-5.098-0.058-5.634-0.061c-6.276-0.029-12.762-0.063-18.957-0.081-7.208-0.022-16.906-0.053-24.117-0.106-1.358-0.01-6.937-0.023-7.547-0.041-6.614-0.2-12.147 5.008-12.346 11.623-0.2 6.615 5.008 12.147 11.622 12.347 0.654 0.02 6.639 0.04 8.095 0.051 7.242 0.053 16.982 0.084 24.221 0.106 6.182 0.019 12.654 0.053 18.917 0.082 0.532 2e-3 4.748 0.055 5.586 0.061 11.003 0.073 12.215-11.855 12.215-11.934 0.031-6.618-5.316-12.016-11.934-12.047l-0.123 3e-3z" />
      <path d="m943.02 129.96c0.352-37.795-29.543-68.153-67.351-68.918-38.076-0.771-69.614 29.502-70.384 67.559s29.518 69.581 67.594 70.351c16.142 0.326 31.888-5.015 44.496-15.094l-15.301-19.121c-8.132 6.501-18.288 9.946-28.699 9.736-24.56-0.497-44.095-20.829-43.599-45.376 0.497-24.547 20.839-44.073 45.398-43.576 24.386 0.493 43.832 20.513 43.605 44.891l24.241-0.452z" />
      <path d="m909.1 174.43c0.899-0.346 0.425-0.214 1.395-0.313" />
      <path d="m912.41 185.95c6.218-1 10.656-6.701 10.011-13.048-0.67-6.583-6.559-11.385-13.143-10.715-3.126 0.318-1.588-0.064-4.486 1.051-6.176 2.376-9.262 9.32-6.886 15.496 2.249 5.848 8.593 8.925 14.504 7.216z" />
    </g>
    <g fill="currentColor">
      <path d="m185.65 194.52 97.037-113.92c4.291-5.038 3.685-12.612-1.353-16.903s-12.612-3.685-16.903 1.353l-97.036 113.92c-4.292 5.038-3.685 12.612 1.352 16.903 5.038 4.292 12.612 3.685 16.903-1.352z" />
      <path d="m282.69 178.97-97.037-113.92c-4.291-5.038-11.865-5.644-16.903-1.353-5.037 4.291-5.644 11.865-1.352 16.903l97.036 113.92c4.291 5.037 11.865 5.644 16.903 1.352 5.038-4.291 5.644-11.865 1.353-16.903z" />
    </g>
    <g fill="currentColor">
      <path d="m339.39 249.19c-0.113-8.324-0.188-16.643-0.238-24.952-0.183-30.772-0.014-61.408-0.1-91.65-0.019-6.618-5.407-11.975-12.025-11.957-6.617 0.019-11.975 5.407-11.956 12.025 0.087 30.267-0.083 60.928 0.101 91.725 0.05 8.37 0.125 16.75 0.239 25.135 0.09 6.617 5.536 11.916 12.153 11.826s11.916-5.535 11.826-12.152z" />
      <path d="m383.83 60.544c37.932 0 68.729 30.951 68.729 69.073s-30.797 69.073-68.729 69.073c-37.933 0-68.73-30.951-68.73-69.073s30.797-69.073 68.73-69.073zm0 25.687c23.826 0 43.17 19.44 43.17 43.386s-19.344 43.386-43.17 43.386c-23.827 0-43.171-19.44-43.171-43.386s19.344-43.386 43.171-43.386z" />
    </g>
    <g fill="currentColor">
      <path d="m675.96 187.26c-0.347-23.056 9e-3 -46.049 0.015-68.668 1e-3 -6.618-5.37-11.992-11.988-11.994-6.617-2e-3 -11.991 5.37-11.993 11.987-6e-3 22.74-0.361 45.856-0.012 69.035 0.099 6.617 5.552 11.909 12.169 11.809s11.909-5.553 11.809-12.169z" />
      <path d="m765.6 187.44c-0.348-23.118 7e-3 -46.172 0.013-68.852" />
      <path d="m777.59 187.26c-0.347-23.056 8e-3 -46.049 0.014-68.668 2e-3 -6.618-5.369-11.992-11.987-11.994s-11.992 5.37-11.994 11.987c-5e-3 22.74-0.36 45.856-0.012 69.035 0.1 6.617 5.553 11.909 12.17 11.809s11.908-5.553 11.809-12.169z" />
      <path d="m777.69 124.36c0.379-17.605-6.64-34.571-19.351-46.774-25.056-24.055-64.902-23.268-88.927 1.756-11.544 12.026-17.833 28.15-17.482 44.826l23.934-0.472c-0.217-10.329 3.678-20.316 10.828-27.764 14.88-15.5 39.56-15.987 55.079-1.088 7.873 7.558 12.221 18.067 11.986 28.97l23.933 0.546z" />
    </g>
    <path
      d="m550.2 60.884c-37.933 0-68.73 30.951-68.73 69.073s30.797 69.073 68.73 69.073c37.932 0 68.729-30.951 68.729-69.073s-30.797-69.073-68.729-69.073zm0 25.686c-23.827 0-43.171 19.441-43.171 43.387 0 23.945 19.344 43.386 43.171 43.386 23.826 0 43.17-19.441 43.17-43.386 0-23.946-19.344-43.387-43.17-43.387z"
      fill="currentColor"
    />
    <path
      d="m606.66 139.34-11.761-11.727 6e-3 1.864c-0.103-0.626-0.155-1.263-0.154-1.905-6e-3 11.455 0.014 22.94 0.082 34.444 0.05 8.37 0.126 16.75 0.24 25.135 0.09 6.596 5.491 11.881 12.088 11.827 6.597-0.055 11.911-5.428 11.891-12.025l-0.172-59.41-0.149 0.149v-0.108l-11.656 11.651c1.622-2.889 12.798-23.648-0.218-23.648-8.476 0-1.076 21.307-0.197 23.753z"
      fill="currentColor"
    />
  </svg>
)

export const ExpaneMiniIcon: IconType = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6318 15.4521C15.7615 15.4561 16.9496 15.4593 17.2387 15.4614C18.7237 15.4725 20.7205 15.4789 22.2049 15.4834C23.4764 15.4871 24.8079 15.4941 26.0961 15.5C26.2179 15.5006 27.2786 15.514 27.2786 15.5126"
      fill="#D1D1D1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.2653 13.0504L27.2657 13.0498C27.2657 13.0498 26.2182 13.0379 26.108 13.0373C24.8184 13.0313 23.4857 13.0243 22.2128 13.0204C20.7317 13.0161 18.7389 13.0095 17.2572 12.9986C16.9782 12.9966 15.8318 12.9941 15.7067 12.9904C14.3474 12.9493 13.2105 14.0194 13.1696 15.3787C13.1285 16.7377 14.1986 17.8746 15.5579 17.9157C15.6921 17.9199 16.9219 17.924 17.221 17.9262C18.7091 17.9371 20.7105 17.9435 22.198 17.948C23.4682 17.9519 24.7983 17.9587 26.085 17.9649C26.1943 17.9653 27.0606 17.9762 27.2328 17.9774C29.4937 17.9924 29.7428 15.5414 29.7428 15.525C29.7491 14.1653 28.6504 13.0562 27.2906 13.0498L27.2653 13.0504Z"
      fill="#D1D1D1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.7392 15.506C29.8116 7.73969 23.6687 1.50174 15.9 1.34476C8.07614 1.18633 1.59573 7.40681 1.43751 15.2267C1.2795 23.0467 7.50286 29.524 15.3267 29.6824C18.6435 29.7494 21.879 28.6519 24.4697 26.5809L21.3257 22.6519C19.6547 23.9878 17.5679 24.6956 15.4286 24.6525C10.3822 24.5504 6.36799 20.3724 6.47012 15.3285C6.57203 10.2846 10.7519 6.27256 15.7983 6.37469C20.8091 6.47599 24.8048 10.5895 24.7582 15.5989L29.7392 15.506Z"
      fill="#D1D1D1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7671 24.6434C22.9518 24.5723 22.8544 24.5992 23.0537 24.5791L22.7671 24.6434Z"
      fill="#D1D1D1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4497 27.0109C24.7273 26.8054 25.6392 25.6339 25.5067 24.3298C25.369 22.9769 24.159 21.9904 22.8061 22.1281C22.1638 22.1934 22.48 22.1149 21.8843 22.344C20.6153 22.8322 19.9812 24.2589 20.4694 25.5281C20.9317 26.7298 22.2353 27.362 23.4497 27.0109Z"
      fill="#D1D1D1"
    />
  </svg>
)

export const NoFoundIcon: IconType = ({ className }) => (
  <svg className={className} viewBox="0 0 961 455" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M265.312 311.218H235.233V363H183.622V311.218H76.9814V274.475L186.527 113.146H235.233V270.202H265.312V311.218ZM183.622 270.202V227.819C183.622 223.832 183.679 219.104 183.793 213.635C184.021 208.166 184.249 202.697 184.477 197.229C184.704 191.76 184.932 186.918 185.16 182.702C185.502 178.373 185.73 175.354 185.844 173.645H184.477C182.312 178.316 180.033 182.873 177.641 187.316C175.248 191.646 172.457 196.203 169.267 200.988L123.466 270.202H183.622Z"
      fill="currentColor"
    />
    <path
      d="M479.5 425C588.576 425 677 336.576 677 227.5C677 118.424 588.576 30 479.5 30C370.424 30 282 118.424 282 227.5C282 336.576 370.424 425 479.5 425Z"
      fill="#C0E7D4"
    />
    <path
      d="M335.497 220.279C329.847 220.248 324.197 220.231 318.547 220.188C318.34 220.186 318.132 220.188 317.925 220.184C317.325 220.175 316.729 220.136 316.129 220.109C315.954 220.101 314.956 219.973 315.952 220.117C315.567 220.061 315.182 219.989 314.802 219.911C311.697 219.278 310.815 218.914 308.318 217.353C307.841 217.055 307.376 216.739 306.921 216.41C307.691 216.966 306.375 215.94 306.166 215.752C305.75 215.377 305.347 214.989 304.958 214.586C304.698 214.317 304.45 214.039 304.198 213.763C304.131 213.689 303.426 212.85 303.772 213.285C302.506 211.693 301.353 209.403 300.791 207.588C300.621 207.037 300.471 206.481 300.342 205.919C300.276 205.632 299.966 203.854 300.103 204.914C299.975 203.926 299.942 202.926 299.937 201.931C299.936 201.773 299.939 201.614 299.94 201.456C299.942 200.475 299.95 199.495 299.956 198.515C299.978 194.323 300 190.13 300.023 185.938C300.098 171.947 300.172 157.956 300.247 143.965C300.326 129.142 300.406 114.318 300.485 99.495C300.527 91.5608 300.817 83.5836 300.613 75.6507C300.61 75.5269 300.614 75.4027 300.615 75.2793C300.632 72.0642 297.868 69.3301 294.665 69.3301C291.425 69.3301 288.733 72.053 288.716 75.2793C288.68 82.065 288.644 88.8503 288.607 95.636C288.528 110.461 288.449 125.286 288.37 140.11C288.292 154.729 288.213 169.347 288.135 183.966C288.111 188.522 288.086 193.078 288.062 197.633C288.044 200.924 288.01 204.087 288.538 207.368C290.434 219.127 300.22 229.202 311.925 231.44C318.818 232.759 326.233 232.128 333.213 232.165C333.975 232.169 334.735 232.174 335.497 232.177C338.712 232.195 341.446 229.431 341.446 226.228C341.446 222.987 338.723 220.296 335.497 220.279Z"
      fill="#3D3D3D"
    />
    <path
      d="M623.518 233.718C629.168 233.748 634.818 233.765 640.468 233.808C640.675 233.81 640.882 233.808 641.089 233.812C641.69 233.821 642.285 233.86 642.885 233.887C643.06 233.896 644.059 234.024 643.063 233.879C643.448 233.935 643.832 234.007 644.213 234.085C647.318 234.718 648.199 235.082 650.696 236.643C651.173 236.941 651.638 237.257 652.094 237.586C651.324 237.03 652.64 238.056 652.848 238.244C653.265 238.619 653.667 239.008 654.057 239.41C654.316 239.679 654.565 239.957 654.816 240.233C654.884 240.307 655.589 241.146 655.242 240.711C656.508 242.303 657.662 244.593 658.223 246.408C658.394 246.959 658.543 247.516 658.673 248.077C658.739 248.365 659.049 250.143 658.911 249.082C659.039 250.07 659.072 251.07 659.077 252.065C659.078 252.224 659.075 252.382 659.075 252.541C659.072 253.521 659.064 254.501 659.059 255.481C659.036 259.674 659.014 263.866 658.992 268.058C658.917 282.049 658.842 296.04 658.767 310.032C658.688 324.855 658.609 339.678 658.529 354.501C658.487 362.435 658.198 370.413 658.402 378.346C658.405 378.469 658.401 378.594 658.4 378.717C658.382 381.932 661.146 384.666 664.349 384.666C667.589 384.666 670.281 381.943 670.298 378.717C670.334 371.931 670.371 365.146 670.407 358.36C670.486 343.535 670.566 328.711 670.645 313.886C670.723 299.267 670.801 284.649 670.879 270.03C670.904 265.475 670.928 260.919 670.952 256.363C670.97 253.072 671.005 249.909 670.476 246.628C668.581 234.868 658.795 224.794 647.089 222.555C640.196 221.237 632.781 221.868 625.801 221.83C625.04 221.826 624.279 221.822 623.518 221.818C620.303 221.801 617.569 224.565 617.569 227.767C617.569 231.009 620.292 233.701 623.518 233.718Z"
      fill="#3D3D3D"
    />
    <path
      d="M591.535 240.774L591.676 214.427L597.497 214.729L600.544 214.88L604.842 215.106L607.888 215.258L612.186 215.484L615.233 215.636L619.531 215.862L622.577 216.014L624.549 216.118C626.706 216.222 628.398 218.017 628.387 220.175L628.305 235.418C628.297 236.967 627.418 238.308 626.128 238.97C625.618 239.246 625.042 239.413 624.424 239.435L623.078 239.487L618.777 239.667L615.73 239.786L611.429 239.966L608.382 240.085L604.082 240.265L601.034 240.384L596.734 240.564L593.686 240.683L591.535 240.774Z"
      fill="#3D3D3D"
    />
    <path
      d="M591.535 240.774L591.605 227.597L628.345 227.793L628.305 235.419C628.296 236.968 627.417 238.309 626.127 238.97C625.618 239.247 625.041 239.414 624.423 239.435L623.077 239.487L618.777 239.668L615.73 239.787L611.429 239.967L608.382 240.086L604.081 240.266L601.034 240.385L596.733 240.565L593.686 240.684L591.535 240.774Z"
      fill="#454545"
    />
    <path
      d="M623.078 239.486L623.127 230.329C623.137 228.365 626.184 228.39 626.174 230.345L626.128 238.969C625.618 239.246 625.042 239.412 624.424 239.434L623.078 239.486Z"
      fill="#171717"
    />
    <path
      d="M615.729 239.786L615.78 230.29C615.79 228.326 618.837 228.351 618.827 230.306L618.777 239.667L615.729 239.786Z"
      fill="#171717"
    />
    <path
      d="M608.381 240.085L608.434 230.25C608.444 228.287 611.491 228.312 611.48 230.267L611.428 239.966L608.381 240.085Z"
      fill="#171717"
    />
    <path
      d="M601.033 240.385L601.087 230.211C601.098 228.248 604.145 228.272 604.134 230.228L604.081 240.266L601.033 240.385Z"
      fill="#171717"
    />
    <path
      d="M593.685 240.683L593.741 230.171C593.751 228.208 596.798 228.232 596.788 230.188L596.732 240.564L593.685 240.683Z"
      fill="#171717"
    />
    <path
      d="M619.481 225.18L619.531 215.861L622.577 216.013L622.528 225.196C622.517 227.159 619.47 227.143 619.481 225.18Z"
      fill="#171717"
    />
    <path
      d="M612.134 225.139L612.186 215.482L615.232 215.634L615.181 225.156C615.171 227.119 612.124 227.103 612.134 225.139Z"
      fill="#171717"
    />
    <path
      d="M604.788 225.102L604.842 215.106L607.888 215.258L607.835 225.118C607.824 227.081 604.778 227.065 604.788 225.102Z"
      fill="#171717"
    />
    <path
      d="M597.442 225.061L597.497 214.728L600.543 214.879L600.489 225.078C600.478 227.041 597.431 227.025 597.442 225.061Z"
      fill="#171717"
    />
    <path
      d="M504.161 251.994L504.094 264.516C504.083 266.449 505.642 268.024 507.574 268.034L543.48 268.226C545.413 268.236 546.988 266.678 546.998 264.746C547.003 263.818 547.377 262.93 548.036 262.277L557.508 252.905C558.168 252.253 559.06 251.889 559.988 251.893L581.891 252.01C583.823 252.021 585.398 250.462 585.409 248.53L585.427 245.149L585.615 209.987L585.633 206.605C585.643 204.673 584.085 203.098 582.153 203.088L560.25 202.97C559.322 202.966 558.434 202.592 557.781 201.932L548.41 192.46C547.758 191.8 547.394 190.908 547.399 189.98C547.409 188.048 545.851 186.473 543.918 186.463L508.012 186.271C506.079 186.26 504.504 187.818 504.494 189.751L504.427 202.273L504.36 214.855L504.228 239.41L504.161 251.994Z"
      fill="#3D3D3D"
    />
    <path
      d="M585.409 248.531C585.399 250.464 583.824 252.022 581.891 252.012L559.989 251.894C559.061 251.89 558.169 252.253 557.509 252.906L548.036 262.277C547.377 262.93 547.003 263.818 546.998 264.746C546.988 266.679 545.413 268.237 543.481 268.227L507.575 268.034C505.642 268.024 504.084 266.449 504.094 264.517L504.161 251.995L504.2 244.716L585.428 245.15L585.409 248.531Z"
      fill="#454545"
    />
    <path
      d="M585.633 206.607C585.643 204.675 584.085 203.1 582.153 203.09L560.25 202.972C559.322 202.968 558.434 202.594 557.781 201.934L548.41 192.462C547.758 191.802 547.394 190.91 547.398 189.982C547.409 188.05 545.851 186.475 543.918 186.465L508.012 186.273C506.079 186.262 504.504 187.82 504.494 189.753L504.427 202.275L504.388 209.554L585.615 209.988L585.633 206.607Z"
      fill="#171717"
    />
    <path
      d="M504.282 224.3L504.252 229.9L538.705 230.083L538.735 224.482L504.282 224.3Z"
      fill="white"
    />
    <path
      d="M537.441 222.888L504.318 222.711L504.309 224.334L538.762 224.518C538.766 223.621 538.174 222.892 537.441 222.888Z"
      fill="#171717"
    />
    <path
      d="M504.27 231.558L537.393 231.735C538.127 231.739 538.726 231.015 538.731 230.119L504.279 229.935L504.27 231.558Z"
      fill="#171717"
    />
    <path
      d="M583.642 195.741L587.741 195.763C590.43 195.778 592.599 197.97 592.585 200.659L592.296 254.551C592.282 257.24 590.09 259.409 587.4 259.395L583.302 259.373C580.612 259.359 578.443 257.166 578.458 254.477L578.746 200.585C578.76 197.895 580.952 195.727 583.642 195.741Z"
      fill="#3D3D3D"
    />
    <path
      d="M583.302 259.373L583.642 195.741C580.953 195.727 578.761 197.896 578.746 200.585L578.458 254.477C578.443 257.166 580.612 259.359 583.302 259.373Z"
      fill="#171717"
    />
    <path
      d="M475.591 253.467C478.772 253.484 481.365 250.919 481.382 247.737L481.395 245.222C481.412 242.041 478.847 239.448 475.666 239.431L438.16 239.23L438.085 253.266L475.591 253.467Z"
      fill="white"
    />
    <path
      d="M481.397 245.575L481.385 247.752C481.368 250.932 478.774 253.499 475.594 253.482L438.082 253.281L438.094 251.104L475.605 251.305C478.785 251.322 481.38 248.755 481.397 245.575Z"
      fill="#E7E7E7"
    />
    <path
      d="M446.22 239.253L441.863 239.229L441.789 253.28L446.145 253.303L446.22 239.253Z"
      fill="#E7E7E7"
    />
    <path
      d="M475.8 214.518C478.981 214.535 481.574 211.97 481.59 208.788L481.604 206.273C481.621 203.092 479.056 200.499 475.874 200.482L438.369 200.281L438.294 214.317L475.8 214.518Z"
      fill="white"
    />
    <path
      d="M481.605 206.625L481.593 208.802C481.576 211.982 478.982 214.549 475.802 214.532L438.29 214.331L438.302 212.154L475.813 212.355C478.993 212.372 481.588 209.805 481.605 206.625Z"
      fill="#E7E7E7"
    />
    <path
      d="M446.426 200.302L442.07 200.279L441.995 214.33L446.352 214.353L446.426 200.302Z"
      fill="#E7E7E7"
    />
    <path
      d="M367.48 213.229L367.339 239.576L361.517 239.275L358.471 239.123L354.173 238.897L351.127 238.745L346.828 238.519L343.782 238.367L339.484 238.141L336.438 237.989L334.466 237.886C332.308 237.781 330.617 235.986 330.628 233.828L330.71 218.585C330.718 217.036 331.597 215.695 332.887 215.033C333.397 214.757 333.973 214.59 334.591 214.568L335.937 214.516L340.238 214.336L343.285 214.217L347.585 214.037L350.633 213.918L354.933 213.738L357.981 213.619L362.281 213.439L365.329 213.319L367.48 213.229Z"
      fill="#3D3D3D"
    />
    <path
      d="M367.48 213.229L367.41 226.407L330.67 226.21L330.71 218.585C330.719 217.036 331.598 215.695 332.888 215.033C333.397 214.756 333.974 214.59 334.592 214.568L335.938 214.516L340.238 214.336L343.286 214.216L347.586 214.037L350.633 213.918L354.934 213.738L357.981 213.618L362.282 213.438L365.329 213.319L367.48 213.229Z"
      fill="#454545"
    />
    <path
      d="M335.937 214.517L335.888 223.674C335.878 225.638 332.831 225.613 332.842 223.658L332.888 215.034C333.397 214.757 333.973 214.591 334.591 214.569L335.937 214.517Z"
      fill="#171717"
    />
    <path
      d="M343.286 214.218L343.235 223.714C343.225 225.677 340.178 225.653 340.188 223.698L340.238 214.337L343.286 214.218Z"
      fill="#171717"
    />
    <path
      d="M350.634 213.918L350.581 223.752C350.571 225.716 347.524 225.691 347.534 223.736L347.586 214.037L350.634 213.918Z"
      fill="#171717"
    />
    <path
      d="M357.982 213.618L357.927 223.791C357.917 225.755 354.87 225.73 354.881 223.775L354.934 213.737L357.982 213.618Z"
      fill="#171717"
    />
    <path
      d="M365.33 213.32L365.273 223.832C365.263 225.796 362.216 225.771 362.226 223.816L362.282 213.44L365.33 213.32Z"
      fill="#171717"
    />
    <path
      d="M339.534 228.822L339.484 238.14L336.438 237.988L336.487 228.805C336.497 226.842 339.545 226.858 339.534 228.822Z"
      fill="#171717"
    />
    <path
      d="M346.88 228.861L346.828 238.518L343.782 238.366L343.833 228.844C343.844 226.881 346.891 226.897 346.88 228.861Z"
      fill="#171717"
    />
    <path
      d="M354.227 228.901L354.173 238.896L351.127 238.744L351.18 228.884C351.19 226.921 354.237 226.937 354.227 228.901Z"
      fill="#171717"
    />
    <path
      d="M361.573 228.94L361.517 239.274L358.471 239.122L358.526 228.924C358.537 226.96 361.583 226.976 361.573 228.94Z"
      fill="#171717"
    />
    <path
      d="M443.889 201.951L443.956 189.428C443.967 187.496 442.408 185.921 440.476 185.911L415.534 185.777C413.601 185.767 412.026 187.326 412.016 189.258C412.011 190.186 411.637 191.074 410.978 191.727L401.506 201.098C400.846 201.751 399.954 202.115 399.026 202.11L377.123 201.993C375.191 201.982 373.616 203.54 373.606 205.473L373.588 208.854L373.399 244.016L373.381 247.397C373.371 249.329 374.929 250.904 376.862 250.914L398.764 251.032C399.692 251.037 400.58 251.41 401.233 252.07L410.604 261.542C411.257 262.202 411.621 263.094 411.616 264.022C411.606 265.954 413.164 267.529 415.096 267.539L440.038 267.673C441.971 267.683 443.546 266.125 443.556 264.192L443.623 251.67L443.69 239.088L443.822 214.533L443.889 201.951Z"
      fill="#3D3D3D"
    />
    <path
      d="M373.605 205.472C373.616 203.54 375.191 201.982 377.123 201.992L399.025 202.109C399.953 202.114 400.845 201.75 401.506 201.097L410.978 191.726C411.637 191.073 412.011 190.185 412.016 189.258C412.026 187.325 413.601 185.767 415.533 185.777L440.476 185.911C442.408 185.921 443.966 187.496 443.956 189.428L443.889 201.951L443.85 209.229L373.587 208.854L373.605 205.472Z"
      fill="#454545"
    />
    <path
      d="M373.381 247.397C373.371 249.33 374.929 250.904 376.862 250.915L398.764 251.032C399.692 251.037 400.58 251.41 401.233 252.07L410.604 261.542C411.257 262.202 411.621 263.094 411.616 264.022C411.606 265.954 413.164 267.529 415.096 267.539L440.038 267.673C441.971 267.683 443.546 266.125 443.556 264.192L443.623 251.67L443.662 244.391L373.399 244.016L373.381 247.397Z"
      fill="#171717"
    />
    <path
      d="M412.647 223.81L412.618 229.411L443.72 229.576L443.749 223.975L412.647 223.81Z"
      fill="white"
    />
    <path
      d="M414.254 231.075L443.733 231.232L443.741 229.61L412.64 229.443C412.635 230.34 413.357 231.07 414.254 231.075Z"
      fill="#171717"
    />
    <path
      d="M443.78 222.386L414.301 222.229C413.405 222.224 412.674 222.946 412.67 223.843L443.771 224.009L443.78 222.386Z"
      fill="#171717"
    />
    <path
      d="M375.372 258.26L371.274 258.238C368.584 258.223 366.416 256.032 366.43 253.342L366.718 199.45C366.732 196.761 368.924 194.592 371.614 194.607L375.713 194.628C378.402 194.643 380.571 196.835 380.557 199.524L380.268 253.416C380.254 256.105 378.062 258.274 375.372 258.26Z"
      fill="#3D3D3D"
    />
    <path
      d="M375.712 194.628L375.372 258.259C378.062 258.274 380.254 256.105 380.268 253.416L380.556 199.524C380.571 196.834 378.402 194.643 375.712 194.628Z"
      fill="#171717"
    />
    <path
      d="M881.312 311.218H851.233V363H799.622V311.218H692.981V274.475L802.527 113.146H851.233V270.202H881.312V311.218ZM799.622 270.202V227.819C799.622 223.832 799.679 219.104 799.793 213.635C800.021 208.166 800.249 202.697 800.477 197.229C800.704 191.76 800.932 186.918 801.16 182.702C801.502 178.373 801.73 175.354 801.844 173.645H800.477C798.312 178.316 796.033 182.873 793.641 187.316C791.248 191.646 788.457 196.203 785.267 200.988L739.466 270.202H799.622Z"
      fill="currentColor"
    />
  </svg>
)

export const GoogleIcon: IconType = ({ className }) => (
  <svg
    className={className}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6419 10.0264H13.7123C13.3185 10.0264 12.9998 10.3451 12.9998 10.7389V13.5936C12.9998 13.9873 13.3185 14.3061 13.7123 14.3061H18.7419C18.1935 15.7357 17.1623 16.9311 15.8544 17.6904L17.9966 21.4029C21.4326 19.4154 23.4669 15.9232 23.4669 12.0186C23.4669 11.4607 23.4248 11.0623 23.3451 10.617C23.2794 10.2748 22.9841 10.0264 22.6419 10.0264Z"
      fill="#167EE6"
    />
    <path
      d="M12.5216 18.5906C10.0607 18.5906 7.91378 17.2453 6.76066 15.2578L3.04816 17.3953C4.93722 20.6719 8.47628 22.875 12.5216 22.875C14.5091 22.875 16.3794 22.3406 17.9966 21.4078V21.4031L15.8544 17.6906C14.87 18.2625 13.7357 18.5906 12.5216 18.5906Z"
      fill="#12B347"
    />
    <path
      d="M17.9919 21.4076V21.4029L15.8497 17.6904C14.87 18.2576 13.7357 18.5904 12.5216 18.5904V22.8748C14.5091 22.8748 16.3794 22.3404 17.9919 21.4076Z"
      fill="#0F993E"
    />
    <path
      d="M5.86067 11.9295C5.86067 10.7154 6.19348 9.58105 6.76067 8.60137L3.04817 6.46387C2.11067 8.07168 1.57629 9.9373 1.57629 11.9295C1.57629 13.9217 2.11067 15.7873 3.04817 17.3951L6.76067 15.2576C6.18879 14.2779 5.86067 13.1436 5.86067 11.9295Z"
      fill="#FFD500"
    />
    <path
      d="M12.5216 5.26875C14.1247 5.26875 15.6013 5.84063 16.7497 6.7875C17.0357 7.02188 17.4482 7.00312 17.706 6.74531L19.7263 4.725C20.0216 4.42969 19.9982 3.94688 19.6841 3.675C17.7575 1.99688 15.2497 0.984375 12.5216 0.984375C8.47628 0.984375 4.93722 3.1875 3.04816 6.46406L6.76066 8.60156C7.91378 6.61406 10.0607 5.26875 12.5216 5.26875Z"
      fill="#FF4B26"
    />
    <path
      d="M16.7497 6.7875C17.0357 7.02188 17.4482 7.00312 17.706 6.74531L19.7263 4.725C20.0216 4.42969 19.9982 3.94688 19.6841 3.675C17.7575 1.99688 15.2497 0.984375 12.5216 0.984375V5.26875C14.1247 5.26875 15.6013 5.83594 16.7497 6.7875Z"
      fill="#D93F21"
    />
  </svg>
)

export const AppleIcon: IconType = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7712 0.0120105C16.7202 -0.0449895 14.8827 0.0345106 13.2837 1.77001C11.6847 3.50401 11.9307 5.49301 11.9667 5.54401C12.0027 5.59501 14.2467 5.67451 15.6792 3.65701C17.1117 1.63951 16.8222 0.0705105 16.7712 0.0120105ZM21.7422 17.6115C21.6702 17.4675 18.2547 15.7605 18.5727 12.4785C18.8907 9.19501 21.0852 8.29501 21.1197 8.19751C21.1542 8.10001 20.2242 7.01251 19.2387 6.46201C18.5151 6.07389 17.7143 5.85152 16.8942 5.81101C16.7322 5.80651 16.1697 5.66851 15.0132 5.98501C14.2512 6.19351 12.5337 6.86851 12.0612 6.89551C11.5872 6.92251 10.1772 6.11251 8.66068 5.89801C7.69018 5.71051 6.66118 6.09451 5.92468 6.39001C5.18968 6.68401 3.79168 7.52101 2.81368 9.74551C1.83568 11.9685 2.34718 15.4905 2.71318 16.5855C3.07918 17.679 3.65068 19.4715 4.62268 20.7795C5.48668 22.2555 6.63268 23.28 7.11118 23.628C7.58968 23.976 8.93968 24.207 9.87568 23.7285C10.6287 23.2665 11.9877 23.001 12.5247 23.0205C13.0602 23.04 14.1162 23.2515 15.1977 23.829C16.0542 24.1245 16.8642 24.0015 17.6757 23.6715C18.4872 23.34 19.6617 22.083 21.0327 19.5345C21.5532 18.3495 21.7902 17.709 21.7422 17.6115Z"
      fill="#171717"
    />
    <path
      d="M16.7712 0.0120105C16.7202 -0.0449895 14.8827 0.0345106 13.2837 1.77001C11.6847 3.50401 11.9307 5.49301 11.9667 5.54401C12.0027 5.59501 14.2467 5.67451 15.6792 3.65701C17.1117 1.63951 16.8222 0.0705105 16.7712 0.0120105ZM21.7422 17.6115C21.6702 17.4675 18.2547 15.7605 18.5727 12.4785C18.8907 9.19501 21.0852 8.29501 21.1197 8.19751C21.1542 8.10001 20.2242 7.01251 19.2387 6.46201C18.5151 6.07389 17.7143 5.85152 16.8942 5.81101C16.7322 5.80651 16.1697 5.66851 15.0132 5.98501C14.2512 6.19351 12.5337 6.86851 12.0612 6.89551C11.5872 6.92251 10.1772 6.11251 8.66068 5.89801C7.69018 5.71051 6.66118 6.09451 5.92468 6.39001C5.18968 6.68401 3.79168 7.52101 2.81368 9.74551C1.83568 11.9685 2.34718 15.4905 2.71318 16.5855C3.07918 17.679 3.65068 19.4715 4.62268 20.7795C5.48668 22.2555 6.63268 23.28 7.11118 23.628C7.58968 23.976 8.93968 24.207 9.87568 23.7285C10.6287 23.2665 11.9877 23.001 12.5247 23.0205C13.0602 23.04 14.1162 23.2515 15.1977 23.829C16.0542 24.1245 16.8642 24.0015 17.6757 23.6715C18.4872 23.34 19.6617 22.083 21.0327 19.5345C21.5532 18.3495 21.7902 17.709 21.7422 17.6115Z"
      fill="#171717"
    />
  </svg>
)

export const SignOutIcon: IconType = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 12.5C10 12.7761 9.77614 13 9.5 13L1.5 13C1.22386 13 1 12.7761 1 12.5L1 3.5C1 3.22386 1.22386 3 1.5 3L9.5 3C9.77614 3 10 3.22386 10 3.5L10 5.5C10 5.77614 10.2239 6 10.5 6C10.7761 6 11 5.77614 11 5.5L11 3.5C11 2.67157 10.3284 2 9.5 2L1.5 2C0.671574 2 4.95181e-07 2.67157 4.5897e-07 3.5L6.55671e-08 12.5C2.93554e-08 13.3284 0.671573 14 1.5 14L9.5 14C10.3284 14 11 13.3284 11 12.5L11 10.5C11 10.2239 10.7761 10 10.5 10C10.2239 10 10 10.2239 10 10.5L10 12.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8536 8.35355C16.0488 8.15829 16.0488 7.84171 15.8536 7.64645L12.8536 4.64645C12.6583 4.45118 12.3417 4.45118 12.1464 4.64645C11.9512 4.84171 11.9512 5.15829 12.1464 5.35355L14.2929 7.5L5.5 7.5C5.22386 7.5 5 7.72386 5 8C5 8.27614 5.22386 8.5 5.5 8.5L14.2929 8.5L12.1464 10.6464C11.9512 10.8417 11.9512 11.1583 12.1464 11.3536C12.3417 11.5488 12.6583 11.5488 12.8536 11.3536L15.8536 8.35355Z"
      fill="white"
    />
  </svg>
)

export const GroupBookingIcon: IconType = ({ className }) => (
  <svg
    className={className}
    width={className}
    height={className}
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.76979 7.20002C5.74259 6.84002 5.53379 6.63602 5.53379 6.63602C5.53379 6.63602 5.93699 6.02642 5.96819 5.63522C5.96819 5.63522 6.81299 4.57682 6.30419 4.08002C6.30419 4.08002 6.76499 1.57202 4.51139 1.57202C2.25779 1.57202 2.71859 4.08002 2.71859 4.08002C2.20739 4.57682 3.05459 5.63522 3.05459 5.63522C3.08819 6.02642 3.48899 6.63602 3.48899 6.63602C3.48899 6.63602 3.23699 6.88082 1.94819 7.30322C0.659388 7.72802 -0.396612 9.46802 0.222588 10.0872C0.443388 10.308 2.37539 10.4232 4.35299 10.428C4.32659 10.2336 4.34099 10.0224 4.39619 9.79682C4.61939 8.84882 5.56499 7.63682 6.76979 7.20002Z"
      fill="currentColor"
    />
    <path
      d="M18.0521 7.30322C16.7633 6.88082 16.5113 6.63602 16.5113 6.63602C16.5113 6.63602 16.9145 6.02642 16.9457 5.63522C16.9457 5.63522 17.7905 4.57682 17.2817 4.08002C17.2817 4.08002 17.7425 1.57202 15.4889 1.57202C13.2353 1.57202 13.6961 4.08002 13.6961 4.08002C13.1849 4.57682 14.0321 5.63522 14.0321 5.63522C14.0657 6.02642 14.4665 6.63602 14.4665 6.63602C14.4665 6.63602 14.2553 6.84002 13.2305 7.20002C14.4377 7.63682 15.3809 8.84882 15.6065 9.79682C15.6593 10.0224 15.6737 10.236 15.6473 10.428C17.6249 10.4232 19.5569 10.308 19.7777 10.0872C20.3969 9.46802 19.3433 7.72562 18.0521 7.30322Z"
      fill="currentColor"
    />
    <path
      d="M13.0244 7.53599C11.5028 7.03679 11.2076 6.74879 11.2076 6.74879C11.2076 6.74879 11.6828 6.02879 11.7212 5.56799C11.7212 5.56799 12.7196 4.31999 12.1172 3.73439C12.1172 3.73439 12.662 0.777588 10.0028 0.777588C7.34361 0.777588 7.88841 3.73439 7.88841 3.73439C7.28601 4.31999 8.28441 5.56799 8.28441 5.56799C8.32281 6.02879 8.79801 6.74879 8.79801 6.74879C8.79801 6.74879 8.50281 7.03679 6.98121 7.53599C5.45961 8.03519 4.21401 10.0896 4.94601 10.8192C5.48361 11.3544 14.5268 11.3544 15.0644 10.8192C15.7916 10.092 14.546 8.03759 13.0244 7.53599Z"
      fill="currentColor"
    />
  </svg>
)

export const NotActivatedSubscriptionIcon: IconType = ({ className, size }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_197_4080)">
        <path
          d="M9 16.875C6.91142 16.875 4.90838 16.0453 3.43153 14.5685C1.95468 13.0916 1.125 11.0886 1.125 9C1.125 6.91142 1.95468 4.90838 3.43153 3.43153C4.90838 1.95468 6.91142 1.125 9 1.125C11.0886 1.125 13.0916 1.95468 14.5685 3.43153C16.0453 4.90838 16.875 6.91142 16.875 9C16.875 11.0886 16.0453 13.0916 14.5685 14.5685C13.0916 16.0453 11.0886 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z"
          fill="currentColor"
        />
        <path
          d="M5.22675 5.22675C5.279 5.17437 5.34107 5.13281 5.40941 5.10445C5.47775 5.07609 5.55101 5.06149 5.625 5.06149C5.69899 5.06149 5.77225 5.07609 5.84059 5.10445C5.90893 5.13281 5.971 5.17437 6.02325 5.22675L9 8.20463L11.9767 5.22675C12.029 5.17445 12.0911 5.13297 12.1595 5.10466C12.2278 5.07636 12.301 5.06179 12.375 5.06179C12.449 5.06179 12.5222 5.07636 12.5905 5.10466C12.6589 5.13297 12.721 5.17445 12.7733 5.22675C12.8255 5.27905 12.867 5.34114 12.8953 5.40947C12.9236 5.4778 12.9382 5.55104 12.9382 5.625C12.9382 5.69896 12.9236 5.7722 12.8953 5.84053C12.867 5.90886 12.8255 5.97095 12.7733 6.02325L9.79537 9L12.7733 11.9767C12.8255 12.029 12.867 12.0911 12.8953 12.1595C12.9236 12.2278 12.9382 12.301 12.9382 12.375C12.9382 12.449 12.9236 12.5222 12.8953 12.5905C12.867 12.6589 12.8255 12.721 12.7733 12.7733C12.721 12.8255 12.6589 12.867 12.5905 12.8953C12.5222 12.9236 12.449 12.9382 12.375 12.9382C12.301 12.9382 12.2278 12.9236 12.1595 12.8953C12.0911 12.867 12.029 12.8255 11.9767 12.7733L9 9.79537L6.02325 12.7733C5.97095 12.8255 5.90886 12.867 5.84053 12.8953C5.7722 12.9236 5.69896 12.9382 5.625 12.9382C5.55104 12.9382 5.4778 12.9236 5.40947 12.8953C5.34114 12.867 5.27905 12.8255 5.22675 12.7733C5.17445 12.721 5.13297 12.6589 5.10466 12.5905C5.07636 12.5222 5.06179 12.449 5.06179 12.375C5.06179 12.301 5.07636 12.2278 5.10466 12.1595C5.13297 12.0911 5.17445 12.029 5.22675 11.9767L8.20463 9L5.22675 6.02325C5.17437 5.971 5.13281 5.90893 5.10445 5.84059C5.07609 5.77225 5.06149 5.69899 5.06149 5.625C5.06149 5.55101 5.07609 5.47775 5.10445 5.40941C5.13281 5.34107 5.17437 5.279 5.22675 5.22675Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_197_4080">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ExpiredSubscriptionIcon: IconType = ({ className, size }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 1.6875C2.25 1.53832 2.30926 1.39524 2.41475 1.28975C2.52024 1.18426 2.66332 1.125 2.8125 1.125H15.1875C15.3367 1.125 15.4798 1.18426 15.5852 1.28975C15.6907 1.39524 15.75 1.53832 15.75 1.6875C15.75 1.83668 15.6907 1.97976 15.5852 2.08525C15.4798 2.19074 15.3367 2.25 15.1875 2.25H14.0625V3.375C14.0627 4.33016 13.7927 5.2659 13.2837 6.07413C12.7747 6.88235 12.0474 7.53008 11.1859 7.9425C10.8596 8.09888 10.6875 8.36663 10.6875 8.60625V9.39375C10.6875 9.63338 10.8608 9.90112 11.1859 10.0575C12.0474 10.4699 12.7747 11.1177 13.2837 11.9259C13.7927 12.7341 14.0627 13.6698 14.0625 14.625V15.75H15.1875C15.3367 15.75 15.4798 15.8093 15.5852 15.9148C15.6907 16.0202 15.75 16.1633 15.75 16.3125C15.75 16.4617 15.6907 16.6048 15.5852 16.7102C15.4798 16.8157 15.3367 16.875 15.1875 16.875H2.8125C2.66332 16.875 2.52024 16.8157 2.41475 16.7102C2.30926 16.6048 2.25 16.4617 2.25 16.3125C2.25 16.1633 2.30926 16.0202 2.41475 15.9148C2.52024 15.8093 2.66332 15.75 2.8125 15.75H3.9375V14.625C3.93727 13.6698 4.20726 12.7341 4.71628 11.9259C5.2253 11.1177 5.95259 10.4699 6.81413 10.0575C7.14038 9.90112 7.3125 9.63338 7.3125 9.39375V8.60625C7.3125 8.36663 7.13925 8.09888 6.81413 7.9425C5.95259 7.53008 5.2253 6.88235 4.71628 6.07413C4.20726 5.2659 3.93727 4.33016 3.9375 3.375V2.25H2.8125C2.66332 2.25 2.52024 2.19074 2.41475 2.08525C2.30926 1.97976 2.25 1.83668 2.25 1.6875ZM5.0625 2.25V3.375C5.0623 4.11797 5.2723 4.84584 5.66826 5.47451C6.06421 6.10318 6.62996 6.607 7.30012 6.92775C7.89975 7.21575 8.4375 7.81763 8.4375 8.60513V9.39487C8.4375 9.39487 8.64 9.5625 9 9.5625C9.36 9.5625 9.5625 9.39375 9.5625 9.39375V8.60625C9.5625 7.81763 10.1002 7.21575 10.6999 6.92775C11.37 6.607 11.9358 6.10318 12.3317 5.47451C12.7277 4.84584 12.9377 4.11797 12.9375 3.375V2.25H5.0625Z"
        fill="#EAB308"
      />
    </svg>
  )
}

export const EndedSubscriptionIcon: IconType = ({ className, size }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16.875C4.65076 16.875 1.125 13.3492 1.125 9C1.125 4.65076 4.65076 1.125 9 1.125C13.3492 1.125 16.875 4.65076 16.875 9C16.875 13.3492 13.3492 16.875 9 16.875ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
        fill="currentColor"
      />
      <path
        d="M4.5 9C4.5 8.68934 4.75184 8.4375 5.0625 8.4375H12.9375C13.2482 8.4375 13.5 8.68934 13.5 9C13.5 9.31066 13.2482 9.5625 12.9375 9.5625H5.0625C4.75184 9.5625 4.5 9.31066 4.5 9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const ActivatedSubscriptionIcon: IconType = ({ className, size }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75012 12.1274L3.62262 8.99988L2.55762 10.0574L6.75012 14.2499L15.7501 5.24988L14.6926 4.19238L6.75012 12.1274Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const CancelBookingIcon: IconType = ({ className }) => {
  return (
    <svg
      width="30"
      height="27"
      className={className}
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.97638 27H28.0236C29.5503 27 30.485 25.3175 29.7373 23.9841L16.7136 1C15.9659 -0.333333 14.0653 -0.333333 13.3175 1L0.26274 24.0159C-0.485027 25.3492 0.449682 27 1.97638 27ZM15.0312 8.07936C15.8724 8.07936 16.5267 8.77778 16.5267 9.63492L16.2463 18.3968C16.2151 19.0952 15.6855 19.6349 15 19.6349C14.3145 19.6349 13.7849 19.0952 13.7537 18.3968L13.5045 9.66667C13.5045 8.80952 14.1899 8.07936 15.0312 8.07936ZM15 20.746C15.8412 20.746 16.5578 21.4444 16.5578 22.3333C16.5578 23.1905 15.8724 23.9206 15 23.9206C14.1588 23.9206 13.4422 23.2222 13.4422 22.3333C13.4422 21.4444 14.1588 20.746 15 20.746Z"
        fill="#FF6464"
      />
    </svg>
  )
}

export const SandClockIcon: IconType = ({ className, size }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 1.6875C2.25 1.53832 2.30926 1.39524 2.41475 1.28975C2.52024 1.18426 2.66332 1.125 2.8125 1.125H15.1875C15.3367 1.125 15.4798 1.18426 15.5852 1.28975C15.6907 1.39524 15.75 1.53832 15.75 1.6875C15.75 1.83668 15.6907 1.97976 15.5852 2.08525C15.4798 2.19074 15.3367 2.25 15.1875 2.25H14.0625V3.375C14.0627 4.33016 13.7927 5.2659 13.2837 6.07413C12.7747 6.88235 12.0474 7.53008 11.1859 7.9425C10.8596 8.09888 10.6875 8.36663 10.6875 8.60625V9.39375C10.6875 9.63338 10.8608 9.90112 11.1859 10.0575C12.0474 10.4699 12.7747 11.1177 13.2837 11.9259C13.7927 12.7341 14.0627 13.6698 14.0625 14.625V15.75H15.1875C15.3367 15.75 15.4798 15.8093 15.5852 15.9148C15.6907 16.0202 15.75 16.1633 15.75 16.3125C15.75 16.4617 15.6907 16.6048 15.5852 16.7102C15.4798 16.8157 15.3367 16.875 15.1875 16.875H2.8125C2.66332 16.875 2.52024 16.8157 2.41475 16.7102C2.30926 16.6048 2.25 16.4617 2.25 16.3125C2.25 16.1633 2.30926 16.0202 2.41475 15.9148C2.52024 15.8093 2.66332 15.75 2.8125 15.75H3.9375V14.625C3.93727 13.6698 4.20726 12.7341 4.71628 11.9259C5.2253 11.1177 5.95259 10.4699 6.81413 10.0575C7.14038 9.90112 7.3125 9.63338 7.3125 9.39375V8.60625C7.3125 8.36663 7.13925 8.09888 6.81413 7.9425C5.95259 7.53008 5.2253 6.88235 4.71628 6.07413C4.20726 5.2659 3.93727 4.33016 3.9375 3.375V2.25H2.8125C2.66332 2.25 2.52024 2.19074 2.41475 2.08525C2.30926 1.97976 2.25 1.83668 2.25 1.6875ZM5.0625 2.25V3.375C5.0623 4.11797 5.2723 4.84584 5.66826 5.47451C6.06421 6.10318 6.62996 6.607 7.30012 6.92775C7.89975 7.21575 8.4375 7.81763 8.4375 8.60513V9.39487C8.4375 9.39487 8.64 9.5625 9 9.5625C9.36 9.5625 9.5625 9.39375 9.5625 9.39375V8.60625C9.5625 7.81763 10.1002 7.21575 10.6999 6.92775C11.37 6.607 11.9358 6.10318 12.3317 5.47451C12.7277 4.84584 12.9377 4.11797 12.9375 3.375V2.25H5.0625Z"
      fill="currentColor"
    />
  </svg>
)

export const PlusCircleIcon: IconType = ({ className, size }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16.875C4.65076 16.875 1.125 13.3492 1.125 9C1.125 4.65076 4.65076 1.125 9 1.125C13.3492 1.125 16.875 4.65076 16.875 9C16.875 13.3492 13.3492 16.875 9 16.875ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
      fill="white"
    />
    <path
      d="M9 4.5C9.31066 4.5 9.5625 4.75184 9.5625 5.0625V8.4375H12.9375C13.2482 8.4375 13.5 8.68934 13.5 9C13.5 9.31066 13.2482 9.5625 12.9375 9.5625H9.5625V12.9375C9.5625 13.2482 9.31066 13.5 9 13.5C8.68934 13.5 8.4375 13.2482 8.4375 12.9375V9.5625H5.0625C4.75184 9.5625 4.5 9.31066 4.5 9C4.5 8.68934 4.75184 8.4375 5.0625 8.4375H8.4375V5.0625C8.4375 4.75184 8.68934 4.5 9 4.5Z"
      fill="white"
    />
  </svg>
)

export const ContactUsIcon: IconType = ({ className, size }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9.32256 6.64424C8.44718 7.00834 6.69765 7.76194 4.07397 8.90503C3.64792 9.07445 3.42474 9.2402 3.40442 9.40226C3.37009 9.67616 3.71308 9.78401 4.18015 9.93088C4.24368 9.95085 4.30951 9.97155 4.377 9.99349C4.83652 10.1429 5.45467 10.3176 5.77601 10.3246C6.0675 10.3309 6.39284 10.2107 6.75203 9.96404C9.20341 8.30929 10.4688 7.4729 10.5483 7.45487C10.6043 7.44215 10.682 7.42615 10.7346 7.47292C10.7872 7.5197 10.7821 7.60828 10.7765 7.63204C10.7425 7.77689 9.39614 9.0286 8.69939 9.67636C8.48218 9.8783 8.3281 10.0215 8.2966 10.0543C8.22605 10.1275 8.15414 10.1969 8.08503 10.2635C7.65812 10.675 7.33798 10.9836 8.10275 11.4876C8.47028 11.7298 8.76437 11.9301 9.05776 12.1299C9.37818 12.3481 9.69776 12.5657 10.1113 12.8368C10.2166 12.9058 10.3172 12.9776 10.4152 13.0474C10.7881 13.3133 11.1231 13.5521 11.537 13.514C11.7775 13.4919 12.0259 13.2657 12.1521 12.5913C12.4502 10.9974 13.0363 7.54388 13.1717 6.12077C13.1836 5.99609 13.1686 5.83652 13.1567 5.76648C13.1447 5.69643 13.1196 5.59663 13.0286 5.52275C12.9207 5.43525 12.7543 5.4168 12.6798 5.41811C12.3413 5.42407 11.822 5.60466 9.32256 6.64424Z"
      fill="currentColor"
    />
  </svg>
)
