import { useFetchBranchByIdBrief, useFetchBusinessSettings } from '@expane/data'
import { Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useFetchMyEmployee } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { store } from 'store'
import { MessageTemplateSettingsDialogPlaceholder } from './MessageTemplateSettingsDialogPlaceholder'
import { MessageTemplateSettingsDialogLogic } from './MessageTemplateSettingsDialogLogic'

const MessageTemplateSettingsDialog: FC<{ closeDialog: () => void }> = observer(
  ({ closeDialog }) => {
    const { data: businessSettings, isLoading: isLoadingBusinessSettings } =
      useFetchBusinessSettings()
    const { data: branch, isLoading: isLoadingBranch } = useFetchBranchByIdBrief(
      store.branch.branchId,
    )
    const { data: myEmployee, isLoading: isLoadingMyEmployee } = useFetchMyEmployee(
      branch?.timezone,
      store.branch.branchId,
    )

    const isLoading = isLoadingBusinessSettings || isLoadingBranch || isLoadingMyEmployee
    const isNoData = !businessSettings || !branch || !myEmployee

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

    if (!isLoading && isNoData) return null

    return (
      <Modal>
        <Dialog>
          {isLoading ? (
            <MessageTemplateSettingsDialogPlaceholder closeDialog={closeDialog} />
          ) : (
            <MessageTemplateSettingsDialogLogic
              closeDialog={closeDialog}
              businessSettings={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                businessSettings!
              }
              branch={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                branch!
              }
              myEmployee={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                myEmployee!
              }
            />
          )}
        </Dialog>
      </Modal>
    )
  },
)

export const useOpenMessageTemplateSettingsDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const messageTemplateSettingsDialog = isOpen ? (
    <MessageTemplateSettingsDialog closeDialog={closePopup} />
  ) : null

  return {
    openMessageTemplateSettingsDialog: openPopup,
    messageTemplateSettingsDialog,
  }
}
