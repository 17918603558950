import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PermissionPageBlockProps, allSelectedPermissions } from '../logic'
import { PermissionBlock, PermissionBlockTitle, PermissionCheckbox } from '.'
import { PermissionPageRowProps } from '@expane/logic/permission/logic'

export const ServiceBlock: FC<PermissionPageBlockProps> = ({
  control,
  checkRelation,
  checkRelationBulk,
  isItOwnerRole,
  setValue,
}) => {
  const { t } = useTranslation()

  const onSelectAll = () => {
    checkRelationBulk({
      permissions: [
        'permissions.service.archive',
        'permissions.service.get',
        'permissions.service.set',
        'permissions.card.get',
        'permissions.card.set',
      ],
      onConfirm: () => {
        setValue('permissions.service', allSelectedPermissions.service)
        setValue('permissions.card', allSelectedPermissions.card)
      },
    })
  }

  return (
    <>
      <PermissionBlockTitle onSelectAll={onSelectAll} title={t('services')} />

      <ServicePermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <CardPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
    </>
  )
}

const ServicePermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('services')}>
      <Controller
        name="permissions.service.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.service.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.service.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.service.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.service.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.service.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.service.archive"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.service.archive',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.service.archive')}
            hint={t('permissions.service.archiveHint')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const CardPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('card/subscription')}>
      <Controller
        name="permissions.card.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.card.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.card.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.card.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.card.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.card.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}
