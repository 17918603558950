import {
  ServerClientDocumentType,
  useFetchClientDocumentsByClientId,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { createCurrentDate, isDateSameDayOrAfter } from '@expane/date'
import { transformPersonName } from '@expane/logic/utils'
import { EmptyPlaceholder, PlaceholderString, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCloudDownloadOutline, IoFileTrayOutline } from 'react-icons/io5'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'
import {
  getNameOfDocumentType,
  useOpenClientDocumentDialog,
} from 'widgets/ClientDialog/DocumentsTab/ClientDocumentDialog'

interface DocumentsTabProps {
  clientId: number
  isEditingAllowed: boolean
}

export const ClientDialogDocumentsTab: FC<DocumentsTabProps> = ({ clientId, isEditingAllowed }) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clientDocuments, isLoading } = useFetchClientDocumentsByClientId(clientId, timezone)

  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()

  const { clientDocumentDialog, openCreateClientDocumentDialog, openEditClientDocumentDialog } =
    useOpenClientDocumentDialog(clientId)
  const columns = useMemo<ColumnDef<ServerClientDocumentType>[]>(
    () => [
      {
        accessorKey: 'type',
        header: t('type'),
        cell: data => getNameOfDocumentType(data.getValue<number>(), t),
        size: 100,
      },
      {
        accessorKey: 'name',
        header: t('name'),
        cell: data => data.getValue(),
        size: 300,
      },
      {
        id: 'authorName',
        accessorFn: clientDocument => transformPersonName(clientDocument.author),
        header: t('employee.name'),
        cell: data => data.getValue(),
        size: 200,
      },
      {
        accessorKey: 'startDate',
        header: t('document.issueDate'),
        cell: data => dateFormatting('historyDate', data.getValue<Date>()),
      },
      {
        accessorKey: 'endDate',
        header: t('document.endDate'),
        cell: data => dateFormatting('historyDate', data.getValue<Date>()),
      },
      {
        id: 'fileLink',
        header: t('file.name'),
        cell: data => {
          const item = data.row.original
          return item ? (
            item.fileUrl ? (
              <IoCloudDownloadOutline
                className="text-primary-600"
                onClick={e => {
                  e.stopPropagation()
                  if (item.fileUrl) window.open(item.fileUrl, '_blank', 'popup=yes')
                }}
              />
            ) : (
              '-'
            )
          ) : (
            <PlaceholderString />
          )
        },
        size: 50,
      },
    ],
    [dateFormatting, t],
  )

  return (
    <div className="flex flex-col h-full">
      {isEditingAllowed && (
        <div className="mb-3">
          <SaveButton onClick={() => openCreateClientDocumentDialog()} isCreate />
        </div>
      )}

      <div className="h-full overflow-auto">
        {clientDocuments?.length === 0 && !isLoading ? (
          <EmptyPlaceholder Icon={IoFileTrayOutline} text={t('noDocuments')} />
        ) : (
          <Table
            columns={columns}
            data={clientDocuments}
            onRowClick={clientDocument => openEditClientDocumentDialog(clientDocument.id)}
            isLoading={isLoading}
            customRowClassName={item => {
              const isOverdue = item?.endDate
                ? isDateSameDayOrAfter(createCurrentDate(timezone), item.endDate)
                : false

              return isOverdue ? 'text-error-600' : undefined
            }}
          />
        )}
      </div>

      {clientDocumentDialog}
    </div>
  )
}
