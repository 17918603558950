import {
  BranchWithSchedule,
  ExpenseMovementInsertInput,
  ExtendedEmployee,
  MOVEMENT_TYPES,
  PaymentTransaction,
  ServerProductType,
  ServerTransactionInsertInput,
  TRANSACTION_TYPES,
} from '@expane/data'
import {
  GiftCardsWithCodeAndDiscountType,
  ProductWithDiscountType,
  ServiceWithDiscountType,
  SubscriptionWithDiscountType,
} from './index'
import { getConsumablesForServices } from '../payment'
import { ServiceItemForPayment } from './types'

export const generateSubscriptionsPaymentTransactionsForQuickSale = (dto: {
  debt: number
  totalPriceForSubscriptions: number
  clientId: number
  subscriptionsWithDiscount: SubscriptionWithDiscountType[]
  isPaymentOnCredit: boolean
  branchId: number
}) => {
  const {
    debt,
    totalPriceForSubscriptions,
    clientId,
    subscriptionsWithDiscount,
    isPaymentOnCredit,
    branchId,
  } = dto

  const transactions: PaymentTransaction[] = []

  let childTransactions: ServerTransactionInsertInput[] | null = null

  if (isPaymentOnCredit && totalPriceForSubscriptions > debt)
    childTransactions = [
      {
        amount: totalPriceForSubscriptions - debt,
        type: TRANSACTION_TYPES.repaymentDeposit.id,
        typeVariation:
          TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByClientBalance.id,
        clientId,
        branchId,
      },
    ]

  transactions.push({
    amount: totalPriceForSubscriptions,
    type: isPaymentOnCredit ? TRANSACTION_TYPES.paymentInCredit.id : TRANSACTION_TYPES.payment.id,
    typeVariation: TRANSACTION_TYPES.payment.variations.subscription.id,
    clientId,
    transactionsCards: { data: subscriptionsWithDiscount },
    childTransactions: childTransactions ? { data: childTransactions } : null,
    branchId,
  })

  return transactions
}

export const generateGiftCardsPaymentTransactionsForQuickSale = (dto: {
  debt: number
  totalPriceForGiftCards: number
  clientId: number
  giftCardsWithCodeAndDiscount: GiftCardsWithCodeAndDiscountType[]
  isPaymentOnCredit: boolean
  branchId: number
}) => {
  const transactions: PaymentTransaction[] = []

  const {
    debt,
    isPaymentOnCredit,
    totalPriceForGiftCards,
    clientId,
    giftCardsWithCodeAndDiscount,
    branchId,
  } = dto

  let childTransactions: ServerTransactionInsertInput[] | null = null

  if (isPaymentOnCredit && totalPriceForGiftCards > debt)
    childTransactions = [
      {
        amount: totalPriceForGiftCards - debt,
        type: TRANSACTION_TYPES.repaymentDeposit.id,
        typeVariation:
          TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByClientBalance.id,
        clientId,
        branchId,
      },
    ]

  transactions.push({
    amount: totalPriceForGiftCards,
    type: isPaymentOnCredit ? TRANSACTION_TYPES.paymentInCredit.id : TRANSACTION_TYPES.payment.id,
    typeVariation: TRANSACTION_TYPES.payment.variations.giftCard.id,
    clientId,
    transactionsCards: { data: giftCardsWithCodeAndDiscount },
    childTransactions: childTransactions ? { data: childTransactions } : null,
    branchId,
  })

  return transactions
}

export const generateProductsPaymentTransactionsForQuickSale = (dto: {
  debt: number
  totalPriceForProducts: number
  clientId: number
  productsWithDiscount: ProductWithDiscountType[]
  isPaymentOnCredit: boolean
  storageId: number
  branchId: number
}) => {
  const {
    debt,
    isPaymentOnCredit,
    storageId,
    totalPriceForProducts,
    clientId,
    productsWithDiscount,
    branchId,
  } = dto

  const transactions: PaymentTransaction[] = []

  let childTransactions: ServerTransactionInsertInput[] | null = null

  if (isPaymentOnCredit && totalPriceForProducts > debt)
    childTransactions = [
      {
        amount: totalPriceForProducts - debt,
        type: TRANSACTION_TYPES.repaymentDeposit.id,
        typeVariation:
          TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByClientBalance.id,
        clientId,
        branchId,
      },
    ]

  transactions.push({
    amount: totalPriceForProducts,
    type: isPaymentOnCredit ? TRANSACTION_TYPES.paymentInCredit.id : TRANSACTION_TYPES.payment.id,
    typeVariation: TRANSACTION_TYPES.payment.variations.product.id,
    clientId,
    movement: {
      data: {
        fromStorageId: storageId,
        fulfilled: true,
        movementProducts: {
          data: productsWithDiscount.map(product => ({ ...product, unit: undefined })),
        },
        type: MOVEMENT_TYPES.expense.id,
        // При продаже товаров не указываем номер накладной расхода
        number: '',
        branchId,
      },
    },
    childTransactions: childTransactions ? { data: childTransactions } : null,
    branchId,
  })

  return transactions
}

export const generateServicesPaymentTransactionsForQuickSale = (dto: {
  debt: number
  allProducts: ServerProductType[] | undefined
  serviceItems: ServiceItemForPayment[]
  employees: ExtendedEmployee[] | undefined
  branch: BranchWithSchedule | undefined
  employeeId: number | null
  isPaymentOnCredit: boolean
  servicesWithDiscount: ServiceWithDiscountType[]
  clientId: number
  totalPriceForServices: number
  branchId: number
}) => {
  const {
    debt,
    serviceItems,
    allProducts,
    employees,
    employeeId,
    branch,
    isPaymentOnCredit,
    servicesWithDiscount,
    clientId,
    totalPriceForServices,
    branchId,
  } = dto

  const transactions: PaymentTransaction[] = []

  // Define consumables for picked services
  const consumablesForServices = getConsumablesForServices(allProducts ?? [], serviceItems)
  let consumableMovement: ExpenseMovementInsertInput | null = null

  if (consumablesForServices.length) {
    consumableMovement = getMovementFromPaymentTransaction({
      allProducts,
      serviceItems,
      employees,
      employeeId,
      branch,
      branchId,
    })
  }

  let serviceChildTransactions: ServerTransactionInsertInput[] | null = null

  if (isPaymentOnCredit && totalPriceForServices > debt)
    serviceChildTransactions = [
      {
        amount: totalPriceForServices - debt,
        type: TRANSACTION_TYPES.repaymentDeposit.id,
        typeVariation:
          TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByClientBalance.id,
        clientId,
        branchId,
      },
    ]

  transactions.push({
    amount: totalPriceForServices,
    type: isPaymentOnCredit ? TRANSACTION_TYPES.paymentInCredit.id : TRANSACTION_TYPES.payment.id,
    typeVariation: TRANSACTION_TYPES.payment.variations.service.id,
    clientId,
    employeeId,
    movement: consumableMovement ? { data: consumableMovement } : null,
    transactionsServices: { data: servicesWithDiscount },
    childTransactions: serviceChildTransactions ? { data: serviceChildTransactions } : null,
    branchId,
  })

  return transactions
}

const getMovementFromPaymentTransaction = (dto: {
  allProducts: ServerProductType[] | undefined
  serviceItems: ServiceItemForPayment[]
  employees: ExtendedEmployee[] | undefined
  branch: BranchWithSchedule | undefined
  branchId: number
  employeeId: number | null
}) => {
  const { allProducts, serviceItems, employees, employeeId, branch, branchId } = dto

  // Define consumables for picked services
  const consumablesForServices = getConsumablesForServices(allProducts ?? [], serviceItems)
  let consumableMovement: ExpenseMovementInsertInput | null = null

  if (consumablesForServices.length) {
    const storageIdByEmployee = employees?.find(employee => employee.id === employeeId)
      ?.employeeGroup?.storageId
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain,@typescript-eslint/no-non-null-assertion
    const storageForConsumablesId = storageIdByEmployee ?? branch?.defaultStorage?.id!

    consumableMovement = {
      fromStorageId: storageForConsumablesId,
      fulfilled: true,
      movementProducts: { data: consumablesForServices },
      type: MOVEMENT_TYPES.consumablesWriteOff.id,
      // При списании расходников не указываем номер накладной расхода
      number: '',
      branchId,
    }
  }

  return consumableMovement
}
