import { FC } from 'react'
import { IconType } from 'react-icons/lib'
import { stopEnterPropagation } from '../../logic'
import { InputLabel } from '../InputLabel'

export interface HorizontalButtonSwitchItem {
  id: string
  name: string
  Icon?: IconType
  ComponentAsIcon?: FC<{ className?: string }>
}

export interface HorizontalButtonSwitchProps {
  options: Array<HorizontalButtonSwitchItem>
  onChange: (id: string) => void
  containerClassName?: string
  value: string | undefined
  label?: string
  hint?: string
  disabled?: boolean
  size?: 'default' | 'small'
  isFullWidth?: boolean
}

export const HorizontalButtonSwitch: FC<HorizontalButtonSwitchProps> = ({
  options,
  onChange,
  containerClassName,
  value,
  hint,
  label,
  disabled = false,
  size = 'default',
  isFullWidth = false,
}) => {
  let styles = `flex flex-row justify-between items-center 
  border-2 px-2 py-2 border-l-0 border-solid first:border-l-2 first:rounded-l-lg last:rounded-r-lg 
  text-sm font-medium transition-all leading-4`

  if (isFullWidth) styles += ' grow'

  styles += disabled
    ? ' border-input-disabled-color text-placeholder-color pointer-events-none'
    : ' border-btn-outline text-btn-primary'

  const pickedButtonStyle = disabled
    ? ' bg-input '
    : ' bg-btn-primary focus:ring-1 ring-primary-500 '

  const unpickedButtonStyle = disabled
    ? ' bg-input-disabled '
    : ' bg-input text-primary-500 hover:text-primary-700 focus:ring-1 ring-primary-500'

  return (
    <div className={containerClassName}>
      {label && <InputLabel label={label} hint={hint} />}

      <div className={`flex flex-row ${size === 'default' ? 'h-9.5' : 'h-6'}`}>
        {options.map(option => (
          <button
            key={option.id}
            onClick={() => {
              if (option.id !== value) onChange(option.id)
            }}
            onKeyDown={stopEnterPropagation}
            className={styles + (option.id === value ? pickedButtonStyle : unpickedButtonStyle)}
          >
            {option.Icon && <option.Icon size="1.1rem" className="mr-1" />}
            {option.ComponentAsIcon && (
              <option.ComponentAsIcon className="w-5 h-4 border border-input-disabled-color mr-1" />
            )}
            <p>{option.name}</p>
          </button>
        ))}
      </div>
    </div>
  )
}
