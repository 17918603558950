import { DayTiming } from '../DateTimePicker/logic'
import { FC } from 'react'
import {
  getHours,
  setHours,
  getMinutesFromFloat,
  setMinutes,
  getMinutes,
  createCurrentDate,
} from '@expane/date'
import { IoMoonOutline, IoSunnyOutline } from 'react-icons/io5'

interface TimePickerProps {
  value: Date | undefined
  onChange: (date: Date) => void
  closeTimePopup: () => void
  timing?: DayTiming
  timezone: string
}
export const TimePickerPopup: FC<TimePickerProps> = ({
  value,
  onChange,
  closeTimePopup,
  timing,
  timezone,
}) => {
  const { dayHours, nightHours } = generateHours(timing)
  const minutes = generateMinutes(timing, value)

  const handleChangeHour = (hour: number) => {
    let newDate = setHours(value ?? createCurrentDate(timezone), hour)
    if (timing && value) {
      const [timingStart, timingEnd] = timing
      const minutesFromValue = getMinutes(value)

      const minutesFromStart = getMinutesFromFloat(timingStart)
      if (Math.trunc(timingStart) === hour && minutesFromStart > minutesFromValue)
        newDate = setMinutes(newDate, minutesFromStart)

      const minutesFromEnd = getMinutesFromFloat(timingEnd)
      if (Math.trunc(timingEnd) === hour && minutesFromEnd <= minutesFromValue)
        newDate = setMinutes(newDate, minutesFromEnd - MINUTES_STEP)
    }
    onChange(newDate)
  }

  return (
    <>
      <div className="flex">
        <div className="flex flex-col border-r border-datepicker-btn w-10">
          <div className="w-full grow flex-centered border-b border-datepicker-btn">
            <IoSunnyOutline size="1.4rem" className="text-icon-primary-color" />
          </div>
          <div className="w-full h-10 flex-centered bg-accent ">
            <IoMoonOutline size="1.2rem" className="text-main-color" />
          </div>
        </div>
        <div className="flex flex-col w-[18.3rem]">
          <div className="flex flex-wrap ml-1 mb-1 w-full">
            {dayHours.map(hour => (
              <HourItem
                picked={value !== undefined && getHours(value) === hour.value}
                key={hour.value}
                value={hour.value}
                disabled={hour.disabled}
                onClick={handleChangeHour}
              />
            ))}
          </div>
          <div className="flex border-t border-datepicker-btn pl-1 pb-1">
            {nightHours.map(hour => (
              <HourItem
                picked={value !== undefined && getHours(value) === hour.value}
                key={hour.value}
                value={hour.value}
                disabled={hour.disabled}
                onClick={handleChangeHour}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="flex w-full border-t border-datepicker-btn p-1">
        {minutes.map(minute => (
          <MinuteItem
            key={minute.value}
            picked={value !== undefined && getMinutes(value) === minute.value}
            value={minute.value}
            disabled={minute.disabled}
            onClick={minute => onChange(setMinutes(value ?? createCurrentDate(timezone), minute))}
          />
        ))}
      </div>
      <button
        onClick={closeTimePopup}
        className="flex-centered w-full py-0.5 border-t border-datepicker-btn grow transition-colors text-primary-500 bg-hover"
      >
        OK
      </button>
    </>
  )
}

type TimeDto = {
  value: number
  disabled: boolean
}
const DAY_HOURS = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
const NIGHT_HOURS = [22, 23, 0, 1, 2, 3, 4, 5]
const generateHours = (timing?: DayTiming) => {
  const timingStart = Math.trunc(timing?.[0] ?? 0)
  const timingEnd = timing?.[1] ?? 24

  const dayHours = DAY_HOURS.map(value => ({
    value,
    disabled: value < timingStart || value >= timingEnd,
  }))

  const nightHours = NIGHT_HOURS.map(value => ({
    value,
    disabled: value < timingStart || value >= timingEnd,
  }))

  return { dayHours, nightHours }
}

interface ItemProps {
  value: number
  picked: boolean
  disabled: boolean
  onClick: (value: number) => void
}
const HourItem: FC<ItemProps> = ({ value, onClick, picked, disabled }) => {
  let className = 'flex-centered w-8 h-8 rounded cursor-pointer mt-1 mr-1'
  if (picked) {
    className += ' bg-primary-500 text-btn-primary'
  } else {
    className += disabled
      ? ' text-icon-color pointer-events-none'
      : ' bg-block text-main-color bg-hover'
  }
  return (
    <div
      className={className}
      onMouseDown={e => e.stopPropagation()}
      onClick={() => onClick(value)}
    >
      {value.toString().length === 1 ? '0' + value : value}
    </div>
  )
}

const MinuteItem: FC<ItemProps> = ({ value, onClick, picked, disabled }) => {
  const isMainValue = value % 15 === 0
  const twoDigitsValue = value < 10 ? '0' + value : value.toString()

  const valueToDisplay = isMainValue ? ':' + twoDigitsValue : twoDigitsValue

  let className = 'flex-centered w-1/12 h-8 rounded cursor-pointer'
  if (!isMainValue) className += ' text-[0.7rem]'
  if (picked) {
    className += ' bg-primary-500 text-white'
  } else {
    if (disabled) className += ' text-icon-color pointer-events-none'
    else
      className +=
        value % 15 === 0
          ? ' text-main-color bg-hover'
          : ' text-gray-400 dark:text-primary-200/40 bg-hover'
  }
  return (
    <div
      className={className}
      onMouseDown={e => e.stopPropagation()}
      onClick={() => onClick(value)}
    >
      {valueToDisplay}
    </div>
  )
}

const MINUTES_STEP = 5
const generateMinutes = (timing: DayTiming | undefined, date: Date | undefined) => {
  const minutes: TimeDto[] = []
  const timingStart = timing?.[0] ?? 0
  const timingEnd = timing?.[1] ?? 24
  const hour = date ? getHours(date) : undefined

  for (let i = 0; i < 60; i += MINUTES_STEP) {
    let disabled = false
    if (hour === Math.trunc(timingStart)) {
      const minutesToStart = getMinutesFromFloat(timingStart)
      disabled = i < minutesToStart
    }
    if (hour === Math.trunc(timingEnd)) {
      const minutesToEnd = getMinutesFromFloat(timingEnd)
      disabled = i >= minutesToEnd
    }

    minutes.push({
      value: i,
      disabled,
    })
  }
  return minutes
}
