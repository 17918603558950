import { useFetchCurrentBranchTimezone, useFetchInventories, useFetchStorages } from '@expane/data'
import {
  createCurrentDate,
  DEFAULT_TIMEZONE,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from '@expane/date'
import { permissions } from '@expane/logic/permission'
import { HorizontalButtonSwitch, Page, ResetFilterButton, SelectDropDownItem } from '@expane/ui'
import { DateTimePicker, PickerType } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { InventoryList } from 'pages/InventoryPage/InventoryList'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { MultiSelect } from 'ui/MultiSelect'
import { RangeDatePicker } from 'ui/RangeDatePicker'
import { SaveButton } from 'widgets/Buttons'
import { InventoryCreateDialog } from 'widgets/InventoryCreateDialog'
import { InventoryDocumentDialog } from 'widgets/InventoryDocumentDialog'

type DatePickerType = PickerType | 'custom'

export const InventoryPage: FC = observer(() => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: myPermission } = useFetchMyPermissions()
  const { data: storages } = useFetchStorages(branchId)
  // TODO: use useFetchInventoriesByDate
  const { data: inventories, isLoading: isLoadingInventories } = useFetchInventories(
    timezone,
    branchId,
  )

  const [pickerType, setPickerType] = useState<DatePickerType>('month')
  const [period, setPeriod] = useState<Date[]>([startOfMonth(createCurrentDate(timezone))])
  const [selectedStorages, setSelectedStorages] = useState<SelectDropDownItem[]>([])

  const handleChangeDatePickerType = (type: DatePickerType) => {
    const now = startOfDay(createCurrentDate(timezone))

    if (type === 'date') setPeriod([now])
    if (type === 'month') setPeriod([startOfMonth(now)])
    if (type === 'custom')
      setPeriod([
        startOfWeek(now, {
          weekStartsOn: 1,
        }),
        endOfWeek(now, {
          weekStartsOn: 1,
        }),
      ])
    setPickerType(type)
  }

  const handleFilterReset = () => {
    setSelectedStorages([])
    setPeriod([startOfMonth(createCurrentDate(timezone))])
    setPickerType('month')
  }

  const filteredInventories =
    inventories
      ?.filter(i => {
        if (pickerType === 'custom')
          return i.inventoryDate > period[0] && i.inventoryDate < period[1]
        if (pickerType === 'month')
          return startOfMonth(i.inventoryDate).toString() === period[0].toString()
        return startOfDay(i.inventoryDate).toString() === period[0].toString()
      })
      .filter(i => !selectedStorages.length || selectedStorages.some(s => s.id === i.storage.id)) ??
    []

  const { openCreateDialog: openInventoryProductsDialog, dialog: inventoryProductsDialog } =
    useOpenDialog(InventoryCreateDialog)

  const { openEditDialog: openInventoryDocumentDialog, dialog: inventoryDocumentDialog } =
    useOpenDialog(InventoryDocumentDialog)

  const isInventoryEditingAllowed = myPermission?.includes(permissions.inventory.set)

  const calendarOptions: { id: DatePickerType; name: string }[] = useMemo(
    () => [
      { id: 'date', name: t('calendarPeriod.day') },
      { id: 'month', name: t('calendarPeriod.month') },
      { id: 'custom', name: t('calendarPeriod.period') },
    ],
    [t],
  )

  return (
    <Page>
      {isInventoryEditingAllowed && (
        <SaveButton isCreate onClick={() => openInventoryProductsDialog()} className="w-fit mb-2" />
      )}
      <div className="mb-2 flex">
        <div className="w-56 mr-2">
          {pickerType === 'custom' ? (
            <RangeDatePicker
              disabled={!timezone}
              timezone={timezone ?? DEFAULT_TIMEZONE}
              value={period}
              onChange={setPeriod}
            />
          ) : (
            <DateTimePicker
              disabled={!timezone}
              timezone={timezone ?? DEFAULT_TIMEZONE}
              showQuickButtons
              nextPreviousButtons
              value={period[0]}
              onChange={value => {
                setPeriod([value])
              }}
              type={pickerType}
            />
          )}
        </div>
        <HorizontalButtonSwitch
          containerClassName="mr-2"
          value={pickerType}
          onChange={type => handleChangeDatePickerType(type as DatePickerType)}
          options={calendarOptions}
        />
        <MultiSelect
          items={storages ?? []}
          onItemSelect={setSelectedStorages}
          selectedItems={selectedStorages}
          dropdownInputPlaceholder={t('primaryStorage')}
          placeholder={t('storage.name')}
          className="w-52 mr-2"
          isFilter
        />
        <ResetFilterButton
          className="w-28"
          disabled={
            !selectedStorages.length &&
            pickerType === 'month' &&
            period[0].toString() === startOfMonth(createCurrentDate(timezone)).toString()
          }
          onClick={handleFilterReset}
        />
      </div>

      <InventoryList
        inventories={filteredInventories}
        onRowClick={openInventoryDocumentDialog}
        isLoading={isLoadingInventories}
      />
      {inventoryDocumentDialog}
      {inventoryProductsDialog}
    </Page>
  )
})
