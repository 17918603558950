import { ServerClientNoteType, useUpdateClientNoteById } from '@expane/data'
import { CloseButton, Dialog, Modal, Textarea, usePopupOpenState } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SaveButton } from 'widgets/Buttons'
import { CommentLabel } from 'widgets/ClientDialog/CommentsTab/CommentLabel'

interface EditCommentDialogProps {
  closeDialog: () => void
  comment: ServerClientNoteType
  isEditingAllowed: boolean
}

interface FormValues {
  text: string
}

export type OpenEditCommentDialogFunc = (
  comment: ServerClientNoteType,
  isEditingAllowed: boolean,
) => void

const EditCommentDialog: FC<EditCommentDialogProps> = ({
  comment,
  isEditingAllowed,
  closeDialog,
}) => {
  const { t } = useTranslation()
  const [openSnackBar] = useSnackbar()

  const { mutateAsync: mutateUpdateClientNote } = useUpdateClientNoteById()

  const { control, formState, handleSubmit } = useForm<FormValues>({
    defaultValues: { text: comment.text },
  })

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

  const updateComment: SubmitHandler<FormValues> = async ({ text }) => {
    const result = await mutateUpdateClientNote({ id: comment.id, text })

    if (result.updateClientNoteById.id) openSnackBar(t('comment.updatedSuccessfully'), 'success')
    else openSnackBar(t('submitError'), 'error')

    closeDialog()
  }

  return (
    <Modal close={closePopups}>
      <Dialog>
        <Dialog.Title>{t('comment.title')}</Dialog.Title>

        <Dialog.Body>
          <CommentLabel comment={comment} />

          <Controller
            name="text"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Textarea
                value={value}
                onChange={onChange}
                disabled={!isEditingAllowed}
                placeholder={t('placeholders.clientComment')}
                rows={3}
              />
            )}
          />
        </Dialog.Body>
        <Dialog.Footer>
          <SaveButton
            onClick={handleSubmit(updateComment)}
            disabled={!isEditingAllowed || !formState.isDirty || formState.isSubmitting}
            spinner={formState.isSubmitting}
          />
          <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />
        </Dialog.Footer>
      </Dialog>
      {confirmPopup}
    </Modal>
  )
}

export const useOpenEditCommentDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const commentRef = useRef<ServerClientNoteType>()
  const isEditingAllowedRef = useRef<boolean>(false)

  const openEditClientNoteDialog: OpenEditCommentDialogFunc = (comment, isEditingAllowed) => {
    commentRef.current = comment
    isEditingAllowedRef.current = isEditingAllowed
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
  }

  const editCommentDialog =
    isOpen && commentRef.current ? (
      <EditCommentDialog
        closeDialog={closeDialog}
        comment={commentRef.current}
        isEditingAllowed={isEditingAllowedRef.current}
      />
    ) : null

  return { openEditClientNoteDialog, editCommentDialog }
}
