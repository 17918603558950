import { useFetchCurrentBranchTimezone, useFetchSubscriptions } from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { Page } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { SubscriptionsPageList } from 'pages/SubscriptionsPage/List'
import { FC } from 'react'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'
import { ArchiveListNavigationButton } from 'widgets/NavigationButtons'
import { SubscriptionDialog } from 'widgets/SubscriptionDialog'

export const SubscriptionsPage: FC = () => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: subscriptions, isLoading } = useFetchSubscriptions(timezone, branchId)

  const { data: myPermissions } = useFetchMyPermissions()
  const { openEditDialog, openCreateDialog, dialog } = useOpenDialog(SubscriptionDialog)

  const isEditingAllowed = myPermissions?.includes(permissions.card.set)

  return (
    <Page>
      {isEditingAllowed && (
        <div className="flex mb-2">
          <SaveButton onClick={() => openCreateDialog()} className="self-start" isCreate />

          <ArchiveListNavigationButton className="ml-auto" />
        </div>
      )}

      <SubscriptionsPageList
        onRowClick={subscription => openEditDialog(subscription.id)}
        subscriptions={subscriptions}
        isLoading={isLoading}
        branchId={branchId}
      />

      {dialog}
    </Page>
  )
}
