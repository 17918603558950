import { useAreManyEmployeesAllowed } from '@expane/logic/billing'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { FadeInTranslateTop, modalsHTMLElement, usePopupOpenState } from '@expane/ui'
import { offset, useFloating } from '@floating-ui/react-dom'
import { useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { ButtonHTMLAttributes, FC, Fragment } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { routes, RouteType } from 'routes/logic'
import { store } from 'store'
import { useFeatureFlags } from '@expane/logic/featureFlags'

type RouteLinkStatus = 'active' | 'activeChild' | 'inactive'

export const MainMenu: FC = observer(function MainMenu() {
  const location = useLocation()

  const { t } = useTranslation()
  const { data: myPermissions } = useFetchMyPermissions()
  const { enabledModules } = useBusinessModulesSettings()
  const { areManyEmployeesAllowed } = useAreManyEmployeesAllowed()
  const { getFeatureFlag } = useFeatureFlags()

  return (
    <>
      {routes
        .getTopBarRoutes(myPermissions, enabledModules, areManyEmployeesAllowed, getFeatureFlag)
        .map(route => {
          let status: RouteLinkStatus = 'inactive'

          if (location.pathname === route.path) status = 'active'
          else if (location.pathname.includes(route.path)) status = 'activeChild'

          return (
            <MenuItem
              key={route.name}
              routePath={route.path}
              status={status}
              dropdownMenu={route.children}
            >
              {route.Icon && <route.Icon size="1rem" className="mr-1" />}
              {t(route.name)}
            </MenuItem>
          )
        })}
    </>
  )
})

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  routePath: string | undefined
  dropdownMenu?: RouteType[]
  status: RouteLinkStatus
}

const MenuItem: FC<Props> = observer(({ children, routePath, dropdownMenu, status, ...props }) => {
  const { t } = useTranslation()
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const {
    unitedBookingUpdate: { isUnitedBookingUpdateMode },
    multiBooking: { isMultiBookingMode },
  } = store

  const location = useLocation()
  const navigate = useNavigate()

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(5)],
  })

  const currentRoute = location.pathname

  const handleClick = (path: string | undefined) => {
    if (!path) return
    if (currentRoute === path) return
    navigate(path)
    closePopup()
  }

  let className = tabStyle
  if (status === 'active') className = selectedTabStyle
  if (status === 'activeChild') className = selectedTabChildStyle

  const disabled = isMultiBookingMode || isUnitedBookingUpdateMode
  let linkComponent: JSX.Element | null = null
  if (routePath) {
    linkComponent = disabled ? (
      <button className={className} {...props} disabled>
        {children}
      </button>
    ) : (
      <Link to={routePath}>
        <button className={className} {...props}>
          {children}
        </button>
      </Link>
    )
  }

  return (
    <div className="relative" ref={reference} onMouseOver={openPopup} onMouseLeave={closePopup}>
      {linkComponent}
      {isOpen && dropdownMenu && Boolean(dropdownMenu.length) && (
        <div className="absolute inset-x-0 -bottom-10 h-10" />
      )}

      {isOpen &&
        dropdownMenu &&
        Boolean(dropdownMenu.length) &&
        createPortal(
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <FadeInTranslateTop
              className="border-2 border-primary-50 dark:border-primary-700 bg-white dark:bg-gray-700 rounded-md p-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-list"
            >
              {dropdownMenu.map(({ Icon, name, path }) => (
                <Fragment key={name}>
                  <button
                    className={
                      path && location.pathname.includes(path)
                        ? selectedMenuItemStyle
                        : hoverMenuItemStyle
                    }
                    role="menuitem"
                    onClick={() => handleClick(path)}
                  >
                    {Icon && <Icon size="1rem" className="mr-1" />}
                    {t(name)}
                  </button>
                </Fragment>
              ))}
            </FadeInTranslateTop>
          </div>,
          modalsHTMLElement,
        )}
    </div>
  )
})

const basicStyle = 'flex flex-row items-center px-1.5 py-1.5 rounded-md text-sm transition-all'
const tabStyle =
  'text-primary-500 bg-hover hover:dark:text-primary-500 hover:text-primary-700 ' + basicStyle
const selectedTabChildStyle =
  'text-white dark:text-gray-300 bg-primary-200 dark:bg-primary-500/40 hover:bg-primary-400 ' +
  basicStyle
const selectedTabStyle = 'bg-primary-500 text-white dark:text-gray-300 cursor-default ' + basicStyle
const hoverMenuItemStyle =
  'min-w-max w-full text-left text-sm text-primary-500 bg-hover hover:text-primary-700 hover:dark:text-primary-500 cursor-pointer ' +
  basicStyle
const selectedMenuItemStyle =
  'min-w-max w-full text-left text-sm text-white bg-primary-500 cursor-default ' + basicStyle
