import { gql } from 'graphql-request'

export const tagBriefFragment = gql`
  fragment tagBriefType on tag {
    id
    name
    color
  }
`

export const tagFragment = gql`
  ${tagBriefFragment}
  fragment tagType on tag {
    ...tagBriefType
    description
    archived
    clientTags {
      client {
        firstName
        lastName
      }
    }
  }
`
