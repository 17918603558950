import { queryClient, request, useQuery, reportError, reportGqlError } from '../../api'
import { ServerLocationGroupType } from '../../generated/graphql-types'
import { gql } from 'graphql-request'
import { ARCHIVED_LOCATION_GROUPS_QUERY_KEY, LOCATION_GROUPS_QUERY_KEY } from './queryKeys'
import { locationGroupFragment } from './locationGroup.fragments'

export function useFetchLocationGroups(branchId: number | undefined | null) {
  return useQuery(
    [LOCATION_GROUPS_QUERY_KEY, { branchId }],
    async (): Promise<ServerLocationGroupType[]> => {
      const result = await request(
        gql`
          ${locationGroupFragment}
          query ($branchId: Int!) {
            locationGroups(where: { branchId: { _eq: $branchId }, archived: { _is_null: true } }) {
              ...locationGroupType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.locationGroups)) {
        return result.locationGroups
      } else {
        reportError(new Error('locationGroups is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchLocationGroups',
      onError: reportGqlError,
      onSuccess: locationGroups =>
        locationGroups.forEach(locationGroup =>
          queryClient.setQueryData([LOCATION_GROUPS_QUERY_KEY, locationGroup.id], locationGroup),
        ),
      enabled: Boolean(branchId),
    },
  )
}

export function useFetchArchivedLocationGroups(branchId: number | undefined) {
  return useQuery(
    [LOCATION_GROUPS_QUERY_KEY, ARCHIVED_LOCATION_GROUPS_QUERY_KEY, { branchId }],
    async (): Promise<ServerLocationGroupType[]> => {
      const result = await request(
        gql`
          ${locationGroupFragment}
          query ($branchId: Int!) {
            locationGroups(where: { branchId: { _eq: $branchId }, archived: { _is_null: false } }) {
              ...locationGroupType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.locationGroups)) {
        return result.locationGroups
      } else {
        reportError(new Error('locationGroups is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchArchivedLocationGroups',
      onError: reportGqlError,
      enabled: Boolean(branchId),
    },
  )
}

export function useFetchLocationGroupById(id: number | undefined) {
  return useQuery<ServerLocationGroupType | undefined>(
    [LOCATION_GROUPS_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${locationGroupFragment}
          query ($id: Int!) {
            locationGroupById(id: $id) {
              ...locationGroupType
            }
          }
        `,
        { id },
      )

      if (result?.locationGroupById) {
        return result.locationGroupById
      }

      reportError(new Error('locationGroupById does not exist'), 'warning', { id, result })
      return undefined
    },
    { queryName: 'useFetchLocationGroupById', enabled: Boolean(id), onError: reportGqlError },
  )
}
