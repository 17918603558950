import { ChooseButton, CloseButton, Dialog, Modal, Paper, Spinner } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const BranchImportEmployeesDialogPlaceholder: FC<{ closeDialog: () => void }> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <Modal close={closeDialog} animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>{t('Вибір співробітників')}</Dialog.Title>
        <Dialog.Body className="h-120">
          <Paper className="h-full py-4 px-2">
            <Spinner expandCentered />
          </Paper>
        </Dialog.Body>
        <Dialog.Footer>
          <ChooseButton disabled />

          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
