import {
  ServerPlanType,
  useFetchBusinessTurnover,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesCount,
  useFetchLastBillingInfo,
} from '@expane/data'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetchPlans } from '@expane/data/src/gql/plan'
import { calcPercentage } from '@expane/logic/utils'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { Meter } from 'ui/Meter'
import { FEATURE_FLAGS, useFeatureFlags } from '@expane/logic/featureFlags'

export const CurrentRestrictions: FC = observer(() => {
  const branchId = store.branch.branchId

  const { getFeatureFlag } = useFeatureFlags()
  const isEnableCheckBillingTurnover = getFeatureFlag(FEATURE_FLAGS.enableCheckBillingTurnover)

  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { data: plans } = useFetchPlans()
  const { data: billingInfo } = useFetchLastBillingInfo(timezone)
  const currentPlan = billingInfo ? plans?.find(plan => plan.id === billingInfo.planId) : undefined

  return (
    <div className="mb-2">
      {isEnableCheckBillingTurnover && <CurrentTurnoverMeter currentPlan={currentPlan} />}

      <MaxEmployeeMeter currentPlan={currentPlan} />
    </div>
  )
})

interface MeterProps {
  currentPlan: ServerPlanType | undefined
}

const MaxEmployeeMeter: FC<MeterProps> = ({ currentPlan }) => {
  const { t } = useTranslation()

  const { data: employeesCount } = useFetchEmployeesCount()

  return currentPlan?.maxEmployees && currentPlan.maxEmployees > 1 ? (
    <Meter
      label={`${t('currentNumberOfEmployees')}: ${employeesCount}`}
      value={employeesCount ?? 0}
      max={currentPlan.maxEmployees}
    />
  ) : null
}

const CurrentTurnoverMeter: FC<MeterProps> = observer(({ currentPlan }) => {
  const { t } = useTranslation()
  const branchId = store.branch.branchId

  const convertMoneyCode = useConvertNumberToMoneyCode({ branchId })
  const { data: currentTurnover } = useFetchBusinessTurnover(true)

  if (!currentPlan?.maxTurnover || !currentTurnover) return null

  const percentage = calcPercentage(currentPlan.maxTurnover, currentTurnover)
  const formattedTurnover = convertMoneyCode(currentTurnover)

  return (
    <Meter label={`${t('currentTurnover')}: ${formattedTurnover}`} value={percentage} max={100} />
  )
})
