import { TFunction } from 'i18next'
import { getHours, getMinutes } from '@expane/date'
import { ServerBusinessTelegramIntegrationView, ServerTransaction } from '@expane/data'
import { useEffect } from 'react'

export const sleep = (seconds: number) =>
  new Promise(resolve => setTimeout(resolve, seconds * 1000))

export const translateItemsName = <T extends { name: string }>(items: T[], t: TFunction): T[] =>
  items.map(item => ({ ...item, name: t(item.name) }))

const GMAIL_DOMAIN = '@gmail.com'
export const checkEmailIsGoogle = (email: string) =>
  email.slice(-GMAIL_DOMAIN.length) === GMAIL_DOMAIN

export interface Person {
  firstName: string
  lastName?: string | undefined | null
}

export interface PersonWithPhoto extends Person {
  photo?: string | null
}

export const transformPersonName = <T extends Person>(person: T): string =>
  `${person.lastName ?? ''} ${person.firstName}`.trim()

export const findById = <T extends { id: number }>(
  id: number | undefined,
  items: T[] | undefined,
): T | undefined => items?.find(i => i.id === id)

export type DiscountInputInfo = { value: string; type: 'percent' | 'fixed' }

export const DEFAULT_DISCOUNT: DiscountInputInfo = {
  value: '',
  type: 'percent',
}

export const DEFAULT_QUANTITY = 1

export const calcDiscountSum = ({
  discount,
  quantity = DEFAULT_QUANTITY,
  price,
}: {
  discount: DiscountInputInfo
  quantity?: number
  price: number
}) => {
  let result = 0

  if (discount) {
    const discountAmount = Number(discount.value)

    if (discount.type === 'fixed') {
      result = discountAmount
    } else if (discount.type === 'percent') {
      result = ((price * quantity) / 100) * discountAmount
    }
  }

  return result
}

export const getPercentOfAmount = (amount: number, percent: number) => {
  return (amount / 100) * percent
}

export const calcPercentage = (max: number, current: number) => {
  return (current * 100) / max
}

export const getPercentageRate = (originalPrice: number, discountedPrice: number) => {
  const discount = originalPrice - discountedPrice
  return Math.round((discount / originalPrice) * 100)
}

export const DAYSWEEK = [
  'day.mon',
  'day.tue',
  'day.wed',
  'day.thu',
  'day.fri',
  'day.sat',
  'day.sun',
]

export const convertFloatToMinutes = (hours: number) => {
  if (Number.isInteger(hours)) return hours + ':00'
  return Math.trunc(hours) + ':30'
}

export const convertDateToNumberForDayTiming = (date: Date) => {
  let number: number
  const minutes = getMinutes(date)

  // если минуты меньше 30, то число равно часам
  if (minutes < 30) {
    number = getHours(date)
  }
  // иначе число равно часам плюс 0.5
  else {
    number = getHours(date) + 0.5
  }

  return number
}
export const calcValues = (data: Array<{ amount: number } | ServerTransaction>) =>
  data?.reduce(
    (balance, transaction) => balance + transaction.amount,

    0,
  )

export const roundValue = (value: number, type?: 'hundredths' | 'thousandths' | 'tenths') => {
  let roundedTo
  if (type === 'tenths') roundedTo = 10
  if (type === 'hundredths' || !type) roundedTo = 100
  if (type === 'thousandths') roundedTo = 1000

  return Math.round((value + Number.EPSILON) * roundedTo) / roundedTo
}

export const isEven = (n: number) => {
  return n % 2 === 0
}

export const calcTotalPrice = <T extends { price: number }>(items: T[]) =>
  items.reduce((sum, item) => {
    return item.price + sum
  }, 0)

export const createGiftCardCode = (): string =>
  'xxxx-xxxx-xxxx-xxxx'.replace(/x/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

export const checkNameIsBySearch = ({ searchValue, name }) =>
  name.toLowerCase().includes(searchValue.toLowerCase())

export const checkVendorCodeIsBySearch = ({
  searchValue,
  vendorCode,
}: {
  searchValue: string
  vendorCode?: string | null
}) => vendorCode?.toLowerCase().includes(searchValue.toLowerCase(), 0)

export const filterItemsByItemsIds = <T extends { id: number }>(
  items: T[] | undefined,
  itemsIds: number[],
) => items?.filter(item => itemsIds.some(id => id === item.id))

export const GENDER_OPTIONS = {
  male: 'm',
  female: 'f',
}

export const sortItemsByAlphabet = <T extends { name: string }>(items: T[] | undefined) =>
  items?.sort((a, b) => a.name.localeCompare(b.name))

export const checkIfNameWasNotChanged = (name: string, newName: string) => {
  return name.trim() === newName.trim()
}

export type ReportErrorFunc = (
  e: unknown,
  level?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug',
  extra?: Record<string, unknown>,
) => void

export const useNoScroll = ({ isActive, clear }: { isActive: boolean; clear?: boolean }) => {
  useEffect(() => {
    // чтобы работало и в Safari и в Chrome
    const noScrollClass = 'no-scroll'

    if (isActive) {
      document.body.classList.add(noScrollClass)
    } else {
      document.body.classList.remove(noScrollClass)
    }

    return () => {
      if (clear) document.body.classList.remove(noScrollClass)
    }
  }, [isActive, clear])
}

export const getExpaneTelegramBotLink = (
  telegramIntegration: ServerBusinessTelegramIntegrationView | null | undefined,
  isDev: boolean,
): string => {
  if (telegramIntegration && !telegramIntegration.isUseDefault && telegramIntegration.name !== null)
    return `https://t.me/${telegramIntegration.name}`

  return isDev ? 'https://t.me/expane_dev_bot' : 'https://t.me/expane_bot'
}

export const getExpaneViberBotLink = (isDev: boolean): string =>
  isDev ? 'viber://pa?chatURI=expane' : 'viber://pa?chatURI=expaneBot'

export const removeSpacesAndBrackets = (text: string) => {
  // Удаляем пробелы, скобки, и дефисы
  return text.replace(/[\s()-]/g, '')
}
