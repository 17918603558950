import { gql } from 'graphql-request'
import {
  ServerAddressSetInput,
  ServerBankDetailsInsertInput,
  ServerBankDetailsSetInput,
  ServerHumanSetInput,
  ServerSupplierInsertInput,
  ServerSupplierSetInput,
} from '../../generated/graphql-types'
import { queryClient, request, useMutation, reportGqlError } from '../../api'
import { SUPPLIERS_QUERY_KEY } from './queryKeys'

export type SupplierInsertInput = ServerSupplierInsertInput &
  Required<Pick<ServerSupplierInsertInput, 'name' | 'phone' | 'address' | 'branchId'>>

export function useCreateSupplier() {
  return useMutation(
    async (dto: SupplierInsertInput): Promise<{ insertSupplier?: { id?: number } }> => {
      return request(
        gql`
          mutation ($object: supplier_insert_input!) {
            insertSupplier(object: $object) {
              id
            }
          }
        `,
        { object: dto },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]),
    },
  )
}

export function useUpdateSupplier() {
  return useMutation(
    async (dto: {
      supplierId: number
      addressId: number
      supplierSetInput: ServerSupplierSetInput
      addressSetInput: ServerAddressSetInput
    }): Promise<{ updateSuppliersById?: { id?: number }; updateAddressById?: { id?: number } }> => {
      return request(
        gql`
          mutation (
            $supplierId: Int!
            $supplierSetInput: supplier_set_input!
            $addressId: Int!
            $addressSetInput: address_set_input!
          ) {
            updateSuppliersById(pk_columns: { id: $supplierId }, _set: $supplierSetInput) {
              id
            }
            updateAddressById(pk_columns: { id: $addressId }, _set: $addressSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]),
    },
  )
}

export function useCreateSupplierContactFace() {
  return useMutation(
    async (dto: ServerHumanSetInput): Promise<{ insertHuman: { id: number } }> => {
      return request(
        gql`
          mutation ($object: human_insert_input!) {
            insertHuman(object: $object) {
              id
            }
          }
        `,
        { object: dto },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]),
    },
  )
}

export function useUpdateSupplierContactFace() {
  return useMutation(
    async (dto: {
      id: number
      supplierContactFaceSetInput: ServerHumanSetInput
    }): Promise<{ updateHumanById: { id: number }; updateAddressById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $supplierContactFaceSetInput: human_set_input!) {
            updateHumanById(pk_columns: { id: $id }, _set: $supplierContactFaceSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]),
    },
  )
}

export function useCreateSupplierBankDetails() {
  return useMutation(
    async (
      dto: Omit<ServerBankDetailsInsertInput, 'supplier'>,
    ): Promise<{ insertBankDetails?: { id?: number } }> => {
      return request(
        gql`
          mutation ($object: bankDetails_insert_input!) {
            insertBankDetails(object: $object) {
              id
            }
          }
        `,
        { object: dto },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]),
    },
  )
}

export function useUpdateSupplierBankDetails() {
  return useMutation(
    async (dto: {
      id: number
      bankDetailsSetInput: Omit<ServerBankDetailsSetInput, 'supplierId'>
    }): Promise<{ updateBankDetailsById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $bankDetailsSetInput: bankDetails_set_input) {
            updateBankDetailsById(pk_columns: { id: $id }, _set: $bankDetailsSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([SUPPLIERS_QUERY_KEY]),
    },
  )
}

export function useImportSupplier() {
  return useMutation(
    async (
      supplierInsertInput: SupplierInsertInput[],
    ): Promise<{ insertSuppliers?: { affectedRows?: number } }> => {
      return request(
        gql`
          mutation ($supplierInsertInput: [supplier_insert_input!]!) {
            insertSuppliers(objects: $supplierInsertInput) {
              affectedRows: affected_rows
            }
          }
        `,
        { supplierInsertInput },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes(SUPPLIERS_QUERY_KEY),
        })
      },
    },
  )
}
