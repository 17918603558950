import { FC, useMemo } from 'react'
import {
  ServerBranchBriefType,
  ServerEmployeeBriefWithBranchType,
  ServerOnlineBookingUrlType,
  ServerServiceBriefWithEmployeeType,
  useCreateOnlineBookingURL,
  useUpdateOnlineBookingURL,
} from '@expane/data'
import { Checkbox, CloseButton, Dialog, Input, Modal, SelectDropdown, Textarea } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { transformPersonName } from '@expane/logic/utils'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from '@expane/widgets'
import { SaveButton } from 'widgets/Buttons'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'

interface OnlineBookingURLDialogLogicProps {
  onlineBookingURLById: ServerOnlineBookingUrlType | undefined
  branches: ServerBranchBriefType[]
  employees: ServerEmployeeBriefWithBranchType[]
  services: ServerServiceBriefWithEmployeeType[]
  closeDialog: () => void
}

interface OnlineBookingURLFormValues {
  name: string
  description: string | undefined
  branchId: number
  employeeId: number | undefined
  serviceId: number | undefined
  disableEmployeeSelection: boolean
}

export const OnlineBookingURLDialogLogic: FC<OnlineBookingURLDialogLogicProps> = observer(
  ({ onlineBookingURLById, branches, employees, services, closeDialog }) => {
    const { t } = useTranslation()

    const currentBranchId = store.branch.branchId

    const { mutateAsync: mutateCreateOnlineBookingURL } = useCreateOnlineBookingURL()
    const { mutateAsync: mutateUpdateOnlineBookingURL } = useUpdateOnlineBookingURL()

    const [openSnackbar] = useSnackbar()

    const { control, setValue, handleSubmit, formState } = useForm<OnlineBookingURLFormValues>({
      defaultValues: {
        name: onlineBookingURLById?.name || '',
        description: onlineBookingURLById?.description || '',
        branchId: onlineBookingURLById?.branchId || currentBranchId,
        employeeId: onlineBookingURLById?.employeeId ?? undefined,
        serviceId: onlineBookingURLById?.serviceId ?? undefined,
        disableEmployeeSelection: onlineBookingURLById?.disableEmployeeSelection ?? true,
      },
    })

    const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

    const submitHandler: SubmitHandler<OnlineBookingURLFormValues> = async data => {
      if (!onlineBookingURLById) {
        const result = await mutateCreateOnlineBookingURL(data)

        if (result?.insertOneOnlineBookingURL?.id) {
          openSnackbar(t('onlineBookingURL.successfullyCreated'), 'success')
        } else openSnackbar(t('submitError'), 'error')
      } else {
        const result = await mutateUpdateOnlineBookingURL({
          id: onlineBookingURLById.id,
          onlineBookingURLSetInput: data,
        })

        if (result?.updateOnlineBookingURLById?.id) {
          openSnackbar(t('onlineBookingURL.successfullyUpdated'), 'success')
        } else openSnackbar(t('submitError'), 'error')
      }
      closeDialog()
    }

    const watchedBranchId = useWatch({ control, name: 'branchId' })
    const watchedEmployeeId = useWatch({ control, name: 'employeeId' })

    const transformedEmployees = useMemo(
      () =>
        employees.map(employee => ({
          ...employee,
          name: transformPersonName(employee),
        })),
      [employees],
    )

    const filteredEmployee = useMemo(
      () =>
        transformedEmployees.filter(employee =>
          employee.employeeSchedules.some(eS => eS.branchId === watchedBranchId),
        ),
      [transformedEmployees, watchedBranchId],
    )

    const filteredServices = useMemo(
      () =>
        watchedEmployeeId
          ? services.filter(
              service =>
                (service.branchId === watchedBranchId || service.branchId === null) &&
                service.serviceEmployees.some(sE => sE.employeeId === watchedEmployeeId),
            )
          : [],
      [services, watchedBranchId, watchedEmployeeId],
    )

    return (
      <>
        <Modal close={closePopups}>
          <Dialog>
            <Dialog.Title>{t('link')}</Dialog.Title>
            <Dialog.Body className="w-144">
              <div className="flex gap-2 mb-4">
                <div className="w-1/2">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Input
                        label={t('internalName')}
                        required
                        value={value}
                        onChange={onChange}
                        errorMessage={{ text: t('formError.required'), isShown: Boolean(error) }}
                      />
                    )}
                  />

                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Textarea
                        label={t('description')}
                        value={value}
                        onChange={onChange}
                        placeholder={t('onlineBookingURL.descriptionPlaceholder')}
                      />
                    )}
                  />
                </div>

                <div className="w-1/2">
                  <Controller
                    name="branchId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <SelectDropdown
                        label={t('branch.name')}
                        required
                        items={branches}
                        value={value}
                        onSelectChange={value => {
                          onChange(value)
                          setValue('employeeId', undefined)
                          setValue('serviceId', undefined)
                        }}
                        errorMessage={{ text: t('formError.required'), isShown: Boolean(error) }}
                      />
                    )}
                  />

                  <Controller
                    name="employeeId"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <SelectDropdown
                        label={t('employee.name')}
                        items={filteredEmployee}
                        value={value}
                        onSelectChange={value => {
                          onChange(value)
                          setValue('serviceId', undefined)
                        }}
                        className="mb-1"
                      />
                    )}
                  />

                  <Controller
                    name="serviceId"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <SelectDropdown
                        label={t('service.name')}
                        items={filteredServices}
                        value={value}
                        onSelectChange={onChange}
                      />
                    )}
                  />
                </div>
              </div>
              <Controller
                name="disableEmployeeSelection"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    label={t('disableEmployeeSelection')}
                  />
                )}
              />
            </Dialog.Body>
            <Dialog.Footer>
              <SaveButton onClick={handleSubmit(submitHandler)} />
              <CloseButton onClick={closePopups} />
            </Dialog.Footer>
          </Dialog>
        </Modal>

        {confirmPopup}
      </>
    )
  },
)
