import {
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchSalaryIssues,
} from '@expane/data'
import { checkBusinessIsNotPaid } from '@expane/logic/business'
import { permissions } from '@expane/logic/permission'
import { getEmployeeGroupsWithEmployeesWithSalarySettings } from '@expane/logic/salaryIssues/filters'
import {
  CountedSalariesFullInfoType,
  getCountedSalariesFullInfo,
} from '@expane/logic/salaryIssues/logic'
import { Button, EmptyPlaceholder, Page } from '@expane/ui'
import { useFetchCurrentBusiness } from 'gql/business'
import { useFetchMyPermissions } from 'gql/employee'
import { useCustomDatePicker } from 'logic/hooks/useCustomDatePicker'
import { observer } from 'mobx-react-lite'
import { SalaryList } from 'pages/SalaryPage/List'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoWalletOutline } from 'react-icons/io5'
import { store } from 'store'
import { CoinsIcon } from 'ui/Icons'
import { useSnackbar } from '@expane/widgets'
import { PremiumButton } from 'widgets/PremiumDialog/PremiumButton'
import { useOpenIssueSalaryDialog } from 'widgets/SalaryIssueDialog'
import { useOpenGroupSalarySettingDialog } from 'widgets/SalarySettingsDialogs/EmployeeGroupSalarySettingDialog'

export const SalaryPage: FC = observer(() => {
  const { data: myPermissions } = useFetchMyPermissions()
  const { data: currentBusiness } = useFetchCurrentBusiness()

  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: salaryIssues, isLoading: isLoadingSalaryIssues } = useFetchSalaryIssues(
    timezone,
    branchId,
  )

  const { data: employeeGroups, isLoading: isLoadingEmployeesGroups } = useFetchEmployeesGroups(
    timezone,
    branchId,
  )

  const { customDatePicker, period, datePickerType } = useCustomDatePicker({
    containerClassName: 'mr-2',
  })

  const { groupSalarySettingDialog, openGroupSalarySettingDialog } =
    useOpenGroupSalarySettingDialog()

  const { t } = useTranslation()
  const [openSnackbar] = useSnackbar()

  const employeeGroupsWithEmployeesWithSalarySettings =
    getEmployeeGroupsWithEmployeesWithSalarySettings(employeeGroups, period.start, period.end)

  const countedSalariesFullInfo: Array<CountedSalariesFullInfoType> = getCountedSalariesFullInfo({
    salaryIssues,
    employeeGroups: employeeGroupsWithEmployeesWithSalarySettings,
    fromDate: period.start,
    toDate: period.end,
  })

  const { openIssueSalaryDialog, issueSalaryDialog } = useOpenIssueSalaryDialog({
    fromDate: period.start,
    toDate: period.end,
  })

  const handleIssueSalaryDialog = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else openIssueSalaryDialog()
  }

  const isSalaryEditingAllowed = Boolean(myPermissions?.includes(permissions.salary.set))
  const areEmployees =
    employeeGroupsWithEmployeesWithSalarySettings.length && !isLoadingEmployeesGroups

  const isLoading = isLoadingEmployeesGroups || isLoadingSalaryIssues

  if ((!employeeGroups || !salaryIssues) && !isLoading) return null

  return (
    <Page>
      <div className="flex mb-2 gap-2">
        {customDatePicker}

        {areEmployees && isSalaryEditingAllowed ? (
          <>
            <PremiumButton type="premium" myPermissions={myPermissions} />
            <PremiumButton type="penalty" myPermissions={myPermissions} />

            <Button
              type="outline"
              twoLines
              className="w-28"
              onClick={handleIssueSalaryDialog}
              Icon={CoinsIcon}
            >
              {t('salary.issueSalary')}
            </Button>

            <Button
              type="outline"
              twoLines
              className="w-32"
              onClick={() => openGroupSalarySettingDialog()}
              Icon={CoinsIcon}
            >
              {t('setUpSalary')}
            </Button>
          </>
        ) : null}
      </div>

      {areEmployees ? (
        <SalaryList
          employeeGroups={employeeGroupsWithEmployeesWithSalarySettings}
          countedSalariesFullInfo={countedSalariesFullInfo}
          fromDate={period.start}
          toDate={period.end}
          isLoading={isLoading}
          datePickerType={datePickerType}
          branchId={branchId}
        />
      ) : (
        !isLoading && (
          <EmptyPlaceholder Icon={IoWalletOutline} text={t('emptyPlaceholder.archive')} />
        )
      )}

      {issueSalaryDialog}
      {groupSalarySettingDialog}
    </Page>
  )
})
