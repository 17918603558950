import { useFetchEmployeeByIdExtended, useFetchEmployeePermission } from '@expane/data'
import { store } from 'store'

export function useFetchMyEmployee(timezone: string | undefined, branchId: number | undefined) {
  const myEmployeeId = store.me.employeeId ?? undefined

  return useFetchEmployeeByIdExtended(myEmployeeId, timezone, branchId)
}

export function useFetchMyPermissions(enabled = true) {
  const myEmployeeId = store.me.employeeId

  return useFetchEmployeePermission(enabled ? myEmployeeId : null)
}
