import { FC } from 'react'

export const InputLabel: FC<{ label: string; required?: boolean; className?: string }> = ({
  label,
  required,
  className = '',
}) => {
  return (
    <p className={`dark:text-white mb-1.5 ${className}`}>
      {label}
      {required ? <span className="text-error-500">*</span> : ''}
    </p>
  )
}
