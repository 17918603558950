import {
  GroupBooking,
  useFetchBookingById,
  useFetchCurrentBranchTimezone,
  useFetchExtendedServices,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { Dialog, Modal, SelectDropDownItem, usePopupOpenState } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useCheckBookingDateEditable } from 'logic/calendar'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { transformPersonsForSelect } from 'logic/utils'
import { FC, MutableRefObject, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { GroupBookingDialogPlaceholder } from 'widgets/BookingDialogPlaceholder'
import { GroupBookingDialogBody } from './Body'
import { GroupBookingFooter } from './Footer'
import { getGroupBookingDefaultDate, InitData } from './logic'
import { getIsBookingRecurring } from '@expane/logic/booking'
import { BookingRepeatLabel } from 'widgets/BookingRepeatLabel'
import { getIsBookingDone } from '@expane/logic/booking'
import { DEFAULT_TIMEZONE } from '@expane/date'

import { RecurringType } from '@expane/logic/recurringBookings'

interface GroupBookingProps {
  closeDialog: () => void
  initData: InitData
}

const GroupBookingDialog: FC<GroupBookingProps> = props => {
  const mutableCloseFunction = useRef(props.closeDialog)

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { isLoading: isBookingLoading, isRefetching: isBookingRefetching } = useFetchBookingById(
    props.initData.id,
    timezone,
    branchId,
  )
  const { isLoading: isServicesLoading } = useFetchExtendedServices(branchId, 'all')
  const isLoading = isBookingLoading || isServicesLoading || !timezone || isBookingRefetching

  const showPlaceholder = !props.initData.isCreate && isLoading

  const closeModal = () => {
    // если передавать ref в модал, компонент не реагирует, что поменялась функция закрытия
    // и будет вызываться первая фун-ция которая была в ref
    mutableCloseFunction.current()
  }

  return (
    <Modal close={closeModal}>
      <Dialog>
        {showPlaceholder ? (
          <GroupBookingDialogPlaceholder closeDialog={props.closeDialog} />
        ) : (
          <LoadedGroupBookingDialog
            {...props}
            mutableCloseFunction={mutableCloseFunction}
            timezone={timezone ?? DEFAULT_TIMEZONE}
          />
        )}
      </Dialog>
    </Modal>
  )
}

const LoadedGroupBookingDialog: FC<
  GroupBookingProps & {
    mutableCloseFunction: MutableRefObject<() => void>
    timezone: string
  }
> = ({ closeDialog, initData, mutableCloseFunction, timezone }) => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()
  const { data: groupBookingById } = useFetchBookingById(initData.id, timezone, branchId)
  const defaultClients =
    (groupBookingById as GroupBooking)?.bookingClients.map(gBC => gBC.client) ?? []

  const { formState, getValues, control, handleSubmit, setValue } =
    useForm<GroupBookingDialogFormValues>({
      defaultValues: {
        employeeId: initData.isCreate ? initData.employeeId : groupBookingById?.employee?.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        serviceId: (groupBookingById as GroupBooking)?.service!.id,
        startDate: getGroupBookingDefaultDate(groupBookingById?.startDate, initData),
        clients: transformPersonsForSelect(defaultClients),
        locationId: initData.isCreate ? initData.locationId : groupBookingById?.location.id,
        duration: groupBookingById?.duration.toString() ?? '0',
        note: groupBookingById?.note ?? '',
      },
    })

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  useEffect(() => {
    mutableCloseFunction.current = closePopups
  }, [closePopups, mutableCloseFunction])

  const dateIsEditable = useCheckBookingDateEditable(
    getGroupBookingDefaultDate(groupBookingById?.startDate, initData),
  )

  const isEditable = initData.isCreate
    ? true
    : dateIsEditable && Boolean(myPermissions?.includes(permissions.booking.set))

  const isCancelled = Boolean(groupBookingById?.canceledDate)
  const formIsDisabled = isCancelled || !isEditable || getIsBookingDone(groupBookingById)

  const isTransactionsGetAllowed = myPermissions?.includes(permissions.transaction.get) ?? false
  const isRecurring = groupBookingById ? getIsBookingRecurring(groupBookingById) : false

  return (
    <>
      <Dialog.Title>
        <div className={'flex items-center'}>
          {t('groupBooking.name')}
          {isRecurring && <BookingRepeatLabel className={'ml-2'} />}
        </div>
      </Dialog.Title>

      <GroupBookingDialogBody
        timezone={timezone}
        control={control}
        initData={initData}
        setValue={setValue}
        formIsDisabled={formIsDisabled}
        groupBookingById={groupBookingById as GroupBooking | undefined}
        getValues={getValues}
      />

      <GroupBookingFooter
        id={initData.id}
        isCreate={initData.isCreate}
        isEditable={isEditable}
        isTransactionsGetAllowed={isTransactionsGetAllowed}
        control={control}
        handleSubmit={handleSubmit}
        formIsDisabled={formIsDisabled}
        closeDialog={closeDialog}
        closePopups={closePopups}
      />
      {confirmPopup}
    </>
  )
}

export type GroupBookingDialogFormValues = {
  clients: SelectDropDownItem[]
  employeeId: number
  locationId: number
  serviceId: number | undefined
  startDate: Date
  duration: string
  isDone: boolean
  recurring: RecurringType | null
  note?: string
}

export const useOpenGroupBookingDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogIsCreate = useRef<boolean>(false)

  // ON EDIT
  const dialogId = useRef<number | undefined>()

  // ON CREATE
  const dialogStartDate = useRef<Date>()
  const dialogEmployeeId = useRef<number | undefined>()
  const dialogLocationId = useRef<number | undefined>()

  const onCloseEditDialog = useRef<() => void>()
  const onCloseCreateDialog = useRef<() => void>()

  const openEditGroupBookingDialog = (id: number, onClose?: () => void) => {
    dialogId.current = id
    dialogIsCreate.current = false
    onCloseEditDialog.current = onClose
    openPopup()
  }

  const openCreateGroupBookingDialog = ({
    startDate,
    employeeId,
    locationId,
    onClose,
  }: {
    startDate: Date
    employeeId: number | undefined
    locationId: number | undefined
    onClose?: () => void
  }) => {
    dialogId.current = undefined
    dialogIsCreate.current = true
    dialogStartDate.current = startDate
    dialogEmployeeId.current = employeeId
    dialogLocationId.current = locationId
    onCloseCreateDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()

    if (dialogIsCreate.current) onCloseCreateDialog.current?.()
    if (!dialogIsCreate.current) onCloseEditDialog.current?.()
  }

  const groupBookingDialog = isOpen ? (
    <GroupBookingDialog
      closeDialog={closeDialog}
      // @ts-expect-error isCreate can be both true or false
      initData={{
        id: dialogId.current,
        isCreate: dialogIsCreate.current,
        startDate: dialogStartDate.current,
        employeeId: dialogEmployeeId.current,
        locationId: dialogLocationId.current,
      }}
    />
  ) : null

  return { openEditGroupBookingDialog, openCreateGroupBookingDialog, groupBookingDialog }
}
