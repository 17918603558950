import {
  useFetchCurrentBranchTimezone,
  useFetchMovementsByDate,
  useFetchTransactionsToCalcBalance,
} from '@expane/data'
import {
  defineStartAndEndDateByPeriodOfTime,
  useGetTransactionsByPeriod,
} from '@expane/logic/analytics'
import { calcTotalBalanceOnAllAccounts } from '@expane/logic/finances/calculation'
import { calcWriteOffMovementProductsPrice, convertMovementsQuantity } from '@expane/logic/movement'
import { Paper } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { AnalyticsBlockBody, AnalyticsBlockItem, AnalyticsBlockTitle } from 'pages/AnalyticsPage'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'

export const RevenueExpensesBlock: FC<{ className?: string }> = observer(({ className }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: transactionsToCalcBalance, isLoading } = useFetchTransactionsToCalcBalance(
    timezone,
    branchId,
  )

  const { transactionsByPeriod, periodOfTimeName, periodOfTimeId, onSelectChange } =
    useGetTransactionsByPeriod(transactionsToCalcBalance ?? [], timezone)

  const { toAccountTotalValue, fromAccountTotalValue } =
    calcTotalBalanceOnAllAccounts(transactionsByPeriod)

  const [startDate, endDate] = defineStartAndEndDateByPeriodOfTime(periodOfTimeId, timezone)

  const { data: movements } = useFetchMovementsByDate(startDate, endDate, timezone, branchId)

  const convertedMovements = convertMovementsQuantity(movements ?? [])

  const writeOffTotalValue = calcWriteOffMovementProductsPrice(convertedMovements)
  const expensesWithWriteOff = fromAccountTotalValue + writeOffTotalValue
  const totalValue = toAccountTotalValue - expensesWithWriteOff

  return (
    <Paper className={`p-4 ${className ?? ''}`}>
      <AnalyticsBlockTitle
        title={t('revenueExpensesBlock.name')}
        onSelectChange={onSelectChange}
        namePeriodOfTime={periodOfTimeName}
        periodOfTimeId={periodOfTimeId}
        hintMessage={t('revenueExpensesBlock.totalRevenue')}
        isLoading={isLoading}
      />

      <AnalyticsBlockBody
        value={toAccountTotalValue}
        text={`${t('revenueExpensesBlock.totalRevenue')} ${periodOfTimeName}`}
        isLoading={isLoading}
        isValueMoney
      >
        <AnalyticsBlockItem
          title={t('expenses')}
          value={expensesWithWriteOff}
          isValueMoney
          isLoading={isLoading}
        />

        <AnalyticsBlockItem
          title={t('profit')}
          value={totalValue}
          isValueMoney
          isLoading={isLoading}
        />
      </AnalyticsBlockBody>
    </Paper>
  )
})
