import { GoogleIcon } from '../Icons'
import { stopEnterPropagation } from '../../logic'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoLogoApple } from 'react-icons/io5'

export const GoogleButton: FC<{ onClick: () => void; disabled: boolean }> = ({
  onClick,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <button
      className={
        styles +
        ' text-originBlack bg-white hover:bg-gray-100 dark:hover:bg-gray-200 border-blue-300 focus:ring-blue-200 w-1/2'
      }
      disabled={disabled}
      onClick={onClick}
      onKeyDown={stopEnterPropagation}
    >
      <GoogleIcon size={'1.5rem'} className="shrink-0 mr-1" />
      {t('authForm.loginWithGoogle')}
    </button>
  )
}

export const AppleButton: FC<{ onClick: () => void; disabled: boolean }> = ({
  onClick,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <button
      className={
        styles +
        'border-originBlack text-white bg-originBlack hover:bg-originBlack/80 dark:hover:bg-originBlack/50 focus:ring-originBlack w-1/2'
      }
      disabled={disabled}
      onClick={onClick}
      onKeyDown={stopEnterPropagation}
    >
      <IoLogoApple size={'1.5rem'} className="shrink-0 mr-1 text-white" />
      {t('authForm.loginWithApple')}
    </button>
  )
}

const styles =
  'h-9.5 px-4 inline-flex items-center justify-center rounded-md border-2 font-medium ring-btn transition-all '
