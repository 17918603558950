import { AsYouType, onChangePhoneInput, splitPhone } from '@expane/logic/phone'
import { modalsHTMLElement, useHandleClickOutside, usePopupOpenState } from '@expane/ui'
import { useFloating } from '@floating-ui/react-dom'
import { forwardRef, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { ArrowDownIcon, ErrorMessage, InputLabel } from '../../ui'
import { DialCodeMenu } from './DialCodeMenu'

export interface PhoneInputProps {
  customLabel?: string
  className?: string
  required?: boolean
  errorMessage?: string
  value: string
  onChange: (value: string) => void
}

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>((props, ref) => {
  const { customLabel, className, required, errorMessage, onChange, value, ...restProps } = props

  const { t } = useTranslation()

  let countryCodeStyle =
    'w-20 flex items-center justify-center h-full text-white bg-primary-500 border border-primary-500 rounded-l cursor-pointer'

  if (errorMessage) {
    countryCodeStyle += ' border-error-500 bg-error-500 '
  }

  const phoneButtonRef = useRef(null)
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null)
  const { isOpen, closePopup, openPopup } = usePopupOpenState()

  useHandleClickOutside([phoneButtonRef.current, menuRef], closePopup, isOpen)

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  useLayoutEffect(() => {
    reference(phoneButtonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, phoneButtonRef.current])
  useLayoutEffect(() => {
    floating(menuRef)
  }, [floating, menuRef])

  const inputRef = useRef<HTMLInputElement | null>(null)

  const onOptionClicked = (code: string) => {
    onChange(new AsYouType().input(code))

    closePopup()
    inputRef.current?.focus()
  }

  const { dialCode, phone } = splitPhone(value)

  return (
    <div className={className}>
      <InputLabel label={customLabel || t('phone')} required={required} />

      <div className="flex">
        <div className="relative">
          <div className={countryCodeStyle} ref={phoneButtonRef} onClick={openPopup}>
            <p>{dialCode}</p>
            <ArrowDownIcon className="ml-1" />
          </div>
        </div>

        <input
          ref={ref}
          className={`p-4 w-full rounded border dark:text-white bg-input -ml-1 focus:border-primary-500 focus:ring-0 ${
            errorMessage ? 'border-error-500' : 'border-gray-300 '
          } `}
          type="tel"
          onChange={e => {
            onChangePhoneInput(e.target.value, dialCode, onChange)
          }}
          value={phone}
          required={required}
          {...restProps}
        />
      </div>

      {errorMessage ? <ErrorMessage errorMessage={errorMessage} /> : null}

      {isOpen &&
        createPortal(
          <div
            ref={setMenuRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <DialCodeMenu onOptionCLicked={onOptionClicked} close={closePopup} />
          </div>,
          modalsHTMLElement,
        )}
    </div>
  )
})
