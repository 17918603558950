import { useFetchArchivedGiftCards } from '@expane/data'
import { Page } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { CardsList } from 'pages/CardsPage/List'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { GiftCardDialog } from 'widgets/GiftCardDialog'
import { ListNavigationButton } from 'widgets/NavigationButtons'

export const ArchiveGiftCardsPage: FC = observer(() => {
  const branchId = store.branch.branchId
  const { data: cards, isLoading } = useFetchArchivedGiftCards(branchId)

  const { t } = useTranslation()

  const { openEditDialog, dialog } = useOpenDialog(GiftCardDialog)

  return (
    <Page>
      <ListNavigationButton title={t('cards')} className="mb-2" />

      <CardsList
        onRowClick={openEditDialog}
        cards={cards}
        isLoading={isLoading}
        archive
        branchId={branchId}
      />
      {dialog}
    </Page>
  )
})
