import { Checkbox, Input, InputLabel, Textarea, useShowConfirmationPopup } from '@expane/ui'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ServerBookingClientSettingsType, useUpdateBookingClientSettings } from '@expane/data'
import { reportError } from 'services/sentry'
import { ApplyButton } from 'widgets/Buttons'
import { useSnackbar } from '@expane/widgets'
import { SettingsWrapper } from 'pages/SettingsPage/index'

interface OnlineBookingSettingsLogicProps {
  bookingClientSettings: ServerBookingClientSettingsType
}

export const OnlineBookingSettingsLogic: FC<OnlineBookingSettingsLogicProps> = ({
  bookingClientSettings,
}) => {
  const { t } = useTranslation()

  const [openSnackBar] = useSnackbar()

  const { mutateAsync: updateBookingClientSettings } = useUpdateBookingClientSettings()

  const {
    formState: { isDirty, isSubmitting },
    control,
    handleSubmit,
    setValue,
    reset,
  } = useForm<BookingTimeSettingsForm>({
    defaultValues: {
      allowableUpdateTime: bookingClientSettings.allowableUpdateTime || undefined,
      allowableTimeForEditingStartDate:
        bookingClientSettings.allowableTimeForEditingStartDate || undefined,
      allowedToCreateWithoutFunds: bookingClientSettings.allowedToCreateWithoutFunds,
      allowedToCreateWithSubscription: bookingClientSettings.allowedToCreateWithSubscription,
      allowedToCreateWithFunds: bookingClientSettings.allowedToCreateWithFunds,
      isNoteEnabled: bookingClientSettings.isNoteEnabled ?? false,
      note: bookingClientSettings.note ?? '',
      isClientNoteEnabled: bookingClientSettings.isClientNoteEnabled ?? false,
    },
  })

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const handleUpdatingBusiness: SubmitHandler<BookingTimeSettingsForm> = async ({
    allowableUpdateTime,
    allowableTimeForEditingStartDate,
    allowedToCreateWithoutFunds,
    allowedToCreateWithFunds,
    allowedToCreateWithSubscription,
    note,
    isNoteEnabled,
    isClientNoteEnabled,
  }) => {
    try {
      const mutation = async () => {
        await updateBookingClientSettings({
          id: bookingClientSettings.id,
          bookingClientSettingsSetInput: {
            allowableUpdateTime,
            allowableTimeForEditingStartDate,
            allowedToCreateWithoutFunds,
            allowedToCreateWithFunds,
            allowedToCreateWithSubscription,
            isNoteEnabled,
            note: Boolean(note) ? note : null, // гарантуємо null, якщо note порожній
            isClientNoteEnabled,
          },
        })
      }

      if (!allowedToCreateWithoutFunds) {
        showConfirmation({
          title: t('editing'),
          description: (
            <div className={'max-w-2xl'}>
              <p>{t('onlineBookingSettings.confirmationCreateWithSubscription')}</p>
            </div>
          ),
          onConfirm: async () => {
            await mutation()
            openSnackBar(t('changesSaved'), 'success', 3000)
          },
        })
      } else {
        await mutation()
        openSnackBar(t('changesSaved'), 'success', 3000)
      }

      // Обновляем значения формы
      reset({
        allowableUpdateTime,
        allowableTimeForEditingStartDate,
        allowedToCreateWithoutFunds,
        allowedToCreateWithFunds,
        allowedToCreateWithSubscription,
        isNoteEnabled,
        note,
        isClientNoteEnabled,
      })
    } catch (e) {
      reportError(e as Error)
      openSnackBar(t('submitError'), 'error', 3000)
    }
  }

  const watchedAllowedToCreateBookingsByClientWithSubscription = useWatch({
    control,
    name: 'allowedToCreateWithSubscription',
  })

  const isNoteEnabled = useWatch({ control, name: 'isNoteEnabled' })

  return (
    <SettingsWrapper>
      <InputLabel label={t('onlineBookingSettings.conditions')} className={'mb-1'} />

      <Controller
        name="allowedToCreateWithSubscription"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            label={t('businessSettings.permissionLevels.withSubscription')}
            hint={t('businessSettings.permissionLevels.withSubscriptionHint')}
            checked={value}
            containerClassName="w-64"
            onChange={onChange}
          />
        )}
      />

      <Controller
        name="allowedToCreateWithoutFunds"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            label={t('businessSettings.permissionLevels.withoutFunds')}
            hint={t('businessSettings.permissionLevels.withoutFundsHint')}
            checked={value}
            containerClassName="w-64"
            onChange={e => {
              const isChecked = e.target.checked

              // если выбран уровень доступа "всегда",
              // то это означает что остальные уровни доступа автоматом включены
              if (isChecked && !watchedAllowedToCreateBookingsByClientWithSubscription) {
                setValue('allowedToCreateWithSubscription', true)
                onChange(isChecked)
              } else {
                onChange(isChecked)
              }
            }}
          />
        )}
      />

      <div className={'mt-4'}>
        <InputLabel label={t('businessSettings.timeForEditingBookingStartDate')} />

        <Controller
          name="allowableTimeForEditingStartDate"
          control={control}
          rules={{ required: true, validate: value => value >= 0 }}
          render={({ field: { value, onChange } }) => (
            <Input
              onChange={onChange}
              type="number"
              min={0}
              value={value.toString()}
              height="small"
              containerClassName="w-32"
            />
          )}
        />

        <InputLabel label={t('businessSettings.bookingUpdateTime')} className={'mt-4'} />

        <Controller
          name="allowableUpdateTime"
          control={control}
          rules={{ required: true, validate: value => value >= 0 }}
          render={({ field: { value, onChange } }) => (
            <Input
              onChange={onChange}
              type="number"
              min={0}
              value={value.toString()}
              height="small"
              containerClassName="w-32 mb-4"
            />
          )}
        />
      </div>

      <Controller
        name="isClientNoteEnabled"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            containerClassName="my-2"
            label={t('onlineBookingSettings.clientNote')}
            checked={value}
            onChange={onChange}
          />
        )}
      />

      <Controller
        name="isNoteEnabled"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            containerClassName="my-2"
            label={t('onlineBookingSettings.note')}
            checked={value}
            onChange={onChange}
          />
        )}
      />

      <Controller
        name="note"
        control={control}
        rules={{ required: isNoteEnabled }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Textarea
            value={value}
            onChange={onChange}
            placeholder={t('onlineBookingSettings.notePlaceholder')}
            disabled={!isNoteEnabled}
            required={isNoteEnabled}
            errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
            className={`${error ? 'mb-4' : ''}`}
          />
        )}
      />

      <ApplyButton
        className="w-40"
        disabled={!isDirty || isSubmitting}
        spinner={isSubmitting}
        onClick={handleSubmit(handleUpdatingBusiness)}
      />
      {confirmationModal}
    </SettingsWrapper>
  )
}

export interface BookingTimeSettingsForm {
  allowableUpdateTime: number
  allowableTimeForEditingStartDate: number
  allowedToCreateWithoutFunds: boolean
  allowedToCreateWithSubscription: boolean
  allowedToCreateWithFunds: boolean
  isNoteEnabled: boolean
  note: string | undefined
  isClientNoteEnabled: boolean
}
