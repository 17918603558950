import { getReceiptPngUrl } from '@expane/data'
import { CloseButton, Dialog, InputLabel } from '@expane/ui'
import { usePrintWrapper } from 'logic/hooks/usePrintWrapper'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoPrintOutline } from 'react-icons/io5'
import { URLContainer } from 'ui/URLContainer'

export const ReceiptDialogLogic: FC<{
  receiptId: string
  src: string
  closeDialog: () => void
}> = ({ receiptId, src, closeDialog }) => {
  const { t } = useTranslation()

  const { PrintWrapper, onPrint } = usePrintWrapper(t('receipt.title'))

  return (
    <>
      <Dialog.Title>
        <div className="flex items-center">
          {t('receipt.title')}
          <div
            className="ml-auto text-primary-400 hover:text-primary-600 cursor-pointer"
            onClick={onPrint}
          >
            <IoPrintOutline size="1.2rem" />
          </div>
        </div>
      </Dialog.Title>
      <Dialog.Body className="flex flex-col items-center justify-center">
        <div className="flex mb-2">
          <InputLabel label={t('receipt.link')} className="mr-2" />
          <URLContainer className="w-64" url={getReceiptPngUrl(receiptId)} />
        </div>

        <div className="w-80 h-128 overflow-auto">
          <PrintWrapper className="print:w-112 print:h-max h-max flex flex-centered">
            <img alt={receiptId} src={src} className="w-full" />
          </PrintWrapper>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
