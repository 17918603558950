import {
  ServerSupplierType,
  useCreateSupplierContactFace,
  useUpdateSupplierContactFace,
} from '@expane/data'
import { PLACEHOLDERS, validateEmail } from '@expane/logic/form'
import { checkValidPhone } from '@expane/logic/phone'
import { Input, Textarea } from '@expane/ui'
import { showPhoneErrorMessage } from 'logic/form'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PhoneInput } from 'ui/PhoneInput'
import { useSnackbar } from '@expane/widgets'
import {
  SupplierTabAdditionalSavingProps,
  useAttachFunctionAndFormState,
} from 'widgets/SupplierDialog'

interface SupplierEmployeesTabProps
  extends SupplierTabAdditionalSavingProps<SupplierEmployeesTabFormValues> {
  supplierById: ServerSupplierType | undefined
  disabled: boolean
}

export const SupplierEmployeesTab: FC<SupplierEmployeesTabProps> = ({
  supplierById,
  additionalProps,
  disabled,
}) => {
  const { t } = useTranslation()
  const supplierContactFace = supplierById?.supplierContactFaces?.[0]

  const { formState, control, handleSubmit } = useForm<SupplierEmployeesTabFormValues>({
    defaultValues: {
      firstName: supplierContactFace?.firstName ?? '',
      lastName: supplierContactFace?.lastName ?? '',
      middleName: supplierContactFace?.middleName ?? '',
      phone: supplierContactFace?.phone ?? '',
      email: supplierContactFace?.email ?? '',
      note: supplierContactFace?.note ?? '',
    },
  })

  const [openSnackbar] = useSnackbar()

  const { mutateAsync: createSupplierContactFace } = useCreateSupplierContactFace()
  const { mutateAsync: updateSupplierContactFace } = useUpdateSupplierContactFace()

  const mutateSupplier: SubmitHandler<SupplierEmployeesTabFormValues> = async data => {
    if (supplierContactFace) {
      const result = await updateSupplierContactFace({
        id: supplierContactFace.id,
        supplierContactFaceSetInput: {
          firstName: data.firstName,
          lastName: data.lastName,
          middleName: data.middleName,
          note: data.note,
          email: data.email,
          phone: data.phone,
        },
      })

      if (result?.updateHumanById?.id) {
        openSnackbar(t('contactPersons.updatedSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else {
      const result = await createSupplierContactFace({
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        note: data.note,
        email: data.email,
        phone: data.phone,
        supplierId: supplierById?.id,
      })
      if (result?.insertHuman?.id) {
        openSnackbar(t('contactPersons.createdSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    }
    additionalProps.closeDialog()
  }

  useAttachFunctionAndFormState<SupplierEmployeesTabFormValues>({
    calledFunction: handleSubmit(mutateSupplier),
    formState,
    additionalProps,
  })

  return (
    <>
      <div className="flex">
        <Controller
          name="firstName"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input
              label={t('firstName')}
              placeholder={t('placeholders.firstName')}
              value={value}
              onChange={onChange}
              containerClassName="w-1/3 mr-3"
              required
              errorMessage={{
                isShown: Boolean(formState.errors.firstName),
                text: t('formError.required'),
              }}
              disabled={disabled}
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              label={t('lastName')}
              placeholder={t('placeholders.lastName')}
              value={value}
              onChange={onChange}
              containerClassName="w-1/3 mr-3"
              disabled={disabled}
            />
          )}
        />

        <Controller
          name="middleName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              label={t('middleName')}
              placeholder={t('placeholders.middleName')}
              value={value}
              onChange={onChange}
              containerClassName="grow"
              disabled={disabled}
            />
          )}
        />
      </div>

      <div className="flex">
        <Controller
          name="phone"
          control={control}
          rules={{ required: true, validate: checkValidPhone }}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              label={t('phone')}
              value={value}
              onChange={onChange}
              containerClassName="w-1/3 mr-3"
              placeholder={PLACEHOLDERS.phone}
              required
              errorMessage={{
                isShown: Boolean(formState.errors.phone),
                text: showPhoneErrorMessage(formState.errors.phone?.type ?? '', t),
              }}
              disabled={disabled}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          rules={{
            validate: email => (email ? validateEmail(email) : true),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              label={t('email')}
              value={value}
              onChange={onChange}
              containerClassName="w-1/3"
              placeholder={t('placeholders.email')}
              disabled={disabled}
              errorMessage={{ isShown: Boolean(error), text: t('authForm.wrongEmail') }}
            />
          )}
        />
      </div>

      <Controller
        name="note"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Textarea
            label={t('note')}
            placeholder={t('placeholders.note')}
            value={value}
            onChange={onChange}
            containerClassName="w-1/2"
            disabled={disabled}
          />
        )}
      />
    </>
  )
}

export type SupplierEmployeesTabFormValues = {
  firstName: string
  lastName: string
  middleName: string
  phone: string
  email: string
  note: string
}
