import { ServerMovementType } from '@expane/data'
import { checkBusinessIsNotPaid } from '@expane/logic/business'
import { Button } from '@expane/ui'
import { useFetchCurrentBusiness } from 'gql/business'
import { SelectMovementsFormType } from 'pages/MovementsPage'
import { FC } from 'react'
import { Control, SubmitHandler, UseFormHandleSubmit, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCashOutline } from 'react-icons/io5'
import { useSnackbar } from '@expane/widgets'
import { MovementsDialogsButtons } from 'widgets/MovementCreateDialogs/MovementsDialogsButtons'
import { usePaymentMovementsDialog } from 'widgets/PaymentMovementsDialog'

type ButtonsBarType = {
  customDatePicker: JSX.Element | null
  handleSubmit: UseFormHandleSubmit<SelectMovementsFormType>
  control: Control<SelectMovementsFormType>
  showProductsMovementButton: boolean
  isPaidAllowed: boolean
  selectedStorageId: number | null
  allMovements: ServerMovementType[]
}

export const ButtonsBar: FC<ButtonsBarType> = ({
  customDatePicker,
  handleSubmit,
  control,
  showProductsMovementButton,
  isPaidAllowed,
  selectedStorageId,
  allMovements,
}) => {
  const { t } = useTranslation()
  const { data: currentBusiness } = useFetchCurrentBusiness()
  const [openSnackbar] = useSnackbar()

  const { openPaymentMovementsDialog, paymentMovementsDialog } = usePaymentMovementsDialog()

  const watchedSelectMovements = useWatch({ control, name: 'selected', defaultValue: {} })

  const isSelectedMovement = Object.keys(watchedSelectMovements).length > 0

  const onPaymentMovements: SubmitHandler<SelectMovementsFormType> = ({ selected }) => {
    const movementsForPayment = allMovements.filter((movement, index) => {
      return selected[index]
    })

    openPaymentMovementsDialog({
      movements: movementsForPayment,
    })
  }

  const handlePayMovements = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else handleSubmit(onPaymentMovements)()
  }

  return (
    <div className="flex flex-wrap items-stretch">
      {customDatePicker}
      <MovementsDialogsButtons
        selectedStorageId={selectedStorageId}
        showProductsMovementButton={showProductsMovementButton}
      />

      {isPaidAllowed && (
        <Button
          onClick={handlePayMovements}
          twoLines
          type="outline"
          className="w-min pr-3 items-center mb-2"
          disabled={!isSelectedMovement}
          Icon={IoCashOutline}
        >
          {t('payInvoices')}
        </Button>
      )}
      {paymentMovementsDialog}
    </div>
  )
}
