import { ServerTransactionType, TRANSACTION_TYPES } from '@expane/data'
import { checkIsTransactionPayment } from '../finances/filters'
import { getIsGroupService } from '../service'

export type ServiceReportItemType = {
  id: number
  name: string
  quantity: number
  price: number
  creditPrice: number
  isByCard: boolean
  isServiceGroup: boolean
}

export const convertTransactionsToServiceReportItem = (
  transactions: ServerTransactionType[],
): ServiceReportItemType[] => {
  const result: ServiceReportItemType[] = []

  const filteredTransactions = transactions.filter(
    transaction => transaction.transactionsServices.length,
  )
  // эта трансформация нам нужна так как у нас нет как такого понятия как "количество" в услугах
  // в местах где нужно количество, мы создаем транзакцию где несколько одинаковых услуг и потом
  // где нужно объединяем и отображаем как количество
  // для правильного подсчета легче добавить это количество, иначе сложно высчитывать возвращенное количество по услуге
  const transactionsWithServiceQuantity: ServerTransactionTypeWithServiceQuantity[] =
    getTransactionsWithServiceQuantity(filteredTransactions)

  for (const filteredTransaction of transactionsWithServiceQuantity) {
    for (const transactionService of filteredTransaction.transactionsServices) {
      const refundTransactions = transactionsWithServiceQuantity.filter(
        transaction =>
          transaction.type === TRANSACTION_TYPES.refund.id &&
          transaction.parentId === filteredTransaction.id,
      )

      let quantityThatWasRefunded = 0

      for (const refundTransaction of refundTransactions) {
        for (const refundTransactionService of refundTransaction.transactionsServices) {
          if (refundTransactionService.service.id === transactionService.service.id)
            quantityThatWasRefunded += refundTransactionService.service.quantity
        }
      }

      const quantity = transactionService.service.quantity - quantityThatWasRefunded

      // проверка нужна для того что не возвращались транзакции возврата
      if (checkIsTransactionPayment(filteredTransaction))
        result.push({
          id: transactionService.service.id,
          name: transactionService.service.name,
          quantity,
          // берем цену за которую услуга продалась, а не та что по умолчанию
          price: transactionService.price * quantity,
          // TODO сделать расчет
          creditPrice: 0,
          isByCard: Boolean(filteredTransaction.cardId),
          isServiceGroup: getIsGroupService(transactionService.service.type),
        })
    }
  }
  return result.filter(service => service.quantity > 0)
}

export const calcServiceReportItemsTotalQuantity = (serviceReportItems: ServiceReportItemType[]) =>
  serviceReportItems.reduce((acc, value) => acc + value.quantity, 0)

export const getServiceReportItemsNotByCard = (serviceReportItems: ServiceReportItemType[]) =>
  serviceReportItems.filter(item => !item.isByCard)

export const getNotGroupServiceReportItems = (serviceReportItems: ServiceReportItemType[]) =>
  serviceReportItems.filter(item => !item.isServiceGroup)

type ServerTransactionTypeWithServiceQuantity = Omit<
  ServerTransactionType,
  'transactionsServices'
> & {
  transactionsServices: Array<{
    price: number
    discount?: number | null
    costPrice: number
    service: { name: string; id: number; price: number; type: number; quantity: number }
  }>
}

export const getTransactionsWithServiceQuantity = (
  transactions: ServerTransactionType[],
): ServerTransactionTypeWithServiceQuantity[] => {
  const transactionsWithServiceQuantity: ServerTransactionTypeWithServiceQuantity[] = []

  for (const transaction of transactions) {
    for (const transactionService of transaction.transactionsServices) {
      const sameServices = transaction.transactionsServices.filter(
        service => transactionService.service.id === service.service.id,
      )

      const isTransactionServiceAlreadyAdded = transactionsWithServiceQuantity.some(
        transactionWithServiceQuantity =>
          transactionWithServiceQuantity.id === transaction.id &&
          transactionWithServiceQuantity.transactionsServices.some(
            service => service.service.id === transactionService.service.id,
          ),
      )

      if (!isTransactionServiceAlreadyAdded) {
        transactionsWithServiceQuantity.push({
          ...transaction,
          transactionsServices: [
            {
              ...transactionService,
              service: {
                ...transactionService.service,
                quantity: sameServices.length,
              },
            },
          ],
        })
      }
    }
  }

  return transactionsWithServiceQuantity
}
