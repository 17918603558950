import { ReactElement, useEffect, useRef } from 'react'
import { Hint } from '../Hint'
import { Tag } from '../Tag'
import { SelectDropDownItem } from './'
import { formatPhoneNumberForSelect } from './logic'

type DropdownItemProps<T extends SelectDropDownItem> = {
  isSelected?: boolean
  onMouseEnter: () => void
  item: T
  onClick: () => void
  isActive?: boolean
  customInactiveReasonRender?: (item: T) => ReactElement | null
  customModal?: HTMLElement | null
  // Used if customInactiveReasonRender is provided inside component with click outside handler
  addRef?: (ref: HTMLDivElement) => void
  removeRef?: (ref: HTMLDivElement) => void
}

export const DropdownItem = <T extends SelectDropDownItem>({
  isSelected = false,
  onMouseEnter,
  item,
  onClick,
  isActive = false,
  customInactiveReasonRender,
  customModal,
  addRef,
  removeRef,
}: DropdownItemProps<T>) => {
  const { name, Icon, phoneItem, hint } = item
  const listItem = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (isActive && listItem.current) {
      const listItemPosition = listItem.current.getBoundingClientRect()
      const parentPosition = listItem.current.parentElement?.getBoundingClientRect()

      if (listItemPosition && parentPosition) {
        if (listItemPosition.bottom > parentPosition.bottom)
          listItem.current.scrollIntoView({ block: 'end' })
        if (listItemPosition.top < parentPosition.top)
          listItem.current.scrollIntoView({ block: 'start' })
      }
    }
  }, [isActive, listItem])

  const inactiveReason = customInactiveReasonRender?.(item) ?? item.inactiveReason ?? null

  const disabled = Boolean(inactiveReason)

  return (
    <li
      ref={listItem}
      onMouseEnter={onMouseEnter}
      className={addAdditionalStyles(isSelected, isActive, disabled)}
    >
      <button
        onClick={() => {
          if (!disabled) onClick()
        }}
        className="w-full flex items-center justify-between"
      >
        <div className="shrink overflow-hidden flex items-center pr-2">
          {Icon && <Icon size="0.9rem" className="mr-1" />}
          <div className="truncate">{name}</div>
          {Boolean(item.warningMessage) && <Hint type="warning">{item.warningMessage}</Hint>}
        </div>
        {item?.tag && <Tag name={item.tag.name} color={item.tag.color} />}
        {phoneItem && (
          <div className="whitespace-nowrap">{formatPhoneNumberForSelect(phoneItem)}</div>
        )}
        {hint && <Hint>{hint}</Hint>}
        {inactiveReason ? (
          <Hint addRef={addRef} removeRef={removeRef} customModal={customModal}>
            {inactiveReason}
          </Hint>
        ) : null}
      </button>
    </li>
  )
}

const addAdditionalStyles = (isSelected: boolean, isActive: boolean, isDisabled: boolean) => {
  let additionalStyles = 'w-full flex items-center px-1.5 min-h-7 rounded-md'

  if (isDisabled) {
    additionalStyles += ' cursor-default'
    additionalStyles += isActive
      ? ' bg-placeholder text-gray-500 dark:text-gray-400'
      : ' text-placeholder-color '
    return additionalStyles
  }

  additionalStyles += ' cursor-pointer'
  if (isSelected) additionalStyles += ' text-primary-500 font-medium'
  if (isActive)
    additionalStyles += ' bg-primary-50 dark:bg-primary-500/20 rounded-md text-primary-600'
  return additionalStyles
}
