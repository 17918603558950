import { gql } from 'graphql-request'

export const clientNoteBriefFragment = gql`
  fragment clientNoteBriefType on clientNote {
    id
    employeeId
    text
    createdAt
    edited
  }
`

export const clientNoteFragment = gql`
  fragment clientNoteType on clientNote {
    text
    id
    edited
    employee {
      id
      firstName
      lastName
      photo
      archived
    }
    createdAt
    clientId
  }
`
