import { useFetchBranches } from '@expane/data'
import { usePopupOpenState } from '@expane/ui'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { store } from 'store'
import { BranchImportLocationsDialogLogic } from 'widgets/BranchImportLocationsDialog/ImportLocationsDialogLogic'
import { BranchImportLocationsDialogPlaceholder } from 'widgets/BranchImportLocationsDialog/ImportLocationsDialogPlaceholder'

const BranchImportLocationsDialog: FC<Pick<DialogProps, 'closeDialog'>> = observer(
  ({ closeDialog }) => {
    const branchId = store.branch.branchId

    const { data: branches, isLoading: isLoadingBranches } = useFetchBranches()

    const isNoData = !branches

    useErrorOpeningDialog(!isLoadingBranches && isNoData, closeDialog)

    return (
      <>
        {isLoadingBranches ? (
          <BranchImportLocationsDialogPlaceholder closeDialog={closeDialog} />
        ) : (
          <BranchImportLocationsDialogLogic
            closeDialog={closeDialog}
            branchId={branchId}
            branches={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              branches!
            }
          />
        )}
      </>
    )
  },
)

export const useOpenBranchImportLocationsDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const openBranchImportLocationsDialog = () => {
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
  }

  const branchImportLocationsDialog = isOpen ? (
    <BranchImportLocationsDialog closeDialog={closeDialog} />
  ) : null

  return { openBranchImportLocationsDialog, branchImportLocationsDialog }
}
