import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { ServerTransactionInsertInput } from '../../generated/graphql-types'
import { BOOKING_HISTORY_QUERY_KEY } from '../booking/queryKeys'
import { CARD_TEMPLATES_KEY } from '../cardTemplate/queryKeys'
import { CLIENTS_QUERY_KEY } from '../client/queryKeys'
import { LEADS_QUERY_KEY } from '../lead/queryKeys'
import { MOVEMENTS_QUERY_KEY } from '../movement/queryKeys'
import { SOFTWARE_POSS_SHIFT_QUERY_KEY } from '../softwarePOS'
import { TAGS_QUERY_KEY } from '../tag/queryKeys'
import { invalidateTransactions } from './logic'
import {
  PaymentTransactionsInsertTransactions,
  TransactionFromAccount,
  TransactionInsertInput,
  TransactionRefundBySubscription,
  TransactionRefundWithoutFromAccount,
  TransactionToClientBalance,
} from './mutationsTypes'
import { TRANSACTION_SERVICES_QUERY_KEY, TRANSACTIONS_SALARIES_QUERY_KEY } from './queryKeys'
import { CARDS_QUERY_KEY } from '../card/queryKeys'

async function requestCreateTransaction(dto: {
  transactionInsertInput: ServerTransactionInsertInput
}): Promise<{
  insertTransaction?: { id?: number; transactionsCards?: Array<{ cardId: number }> }
}> {
  return request(
    gql`
      mutation ($transactionInsertInput: transaction_insert_input!) {
        insertTransaction(object: $transactionInsertInput) {
          id
          transactionsCards {
            cardId
          }
        }
      }
    `,
    dto,
  )
}

export function useCreateTransaction() {
  const createTransaction = (transactionInsertInput: TransactionInsertInput) =>
    requestCreateTransaction({ transactionInsertInput })

  return useMutation(createTransaction, {
    onSuccess: invalidateTransactions,
    onError: reportGqlError,
  })
}

export function useCreateTransactionToClientBalance() {
  const createTransaction = (transactionInsertInput: TransactionToClientBalance) =>
    requestCreateTransaction({ transactionInsertInput })

  return useMutation(createTransaction, {
    onSuccess: invalidateTransactions,
    onError: reportGqlError,
  })
}

export function useCreateTransactionRefundWithoutFromAccount() {
  const createTransaction = (transactionInsertInput: TransactionRefundWithoutFromAccount) =>
    requestCreateTransaction({ transactionInsertInput })

  return useMutation(createTransaction, {
    onSuccess: () => {
      invalidateTransactions()
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(CARDS_QUERY_KEY),
      })
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(BOOKING_HISTORY_QUERY_KEY),
      })
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(TRANSACTION_SERVICES_QUERY_KEY),
      })
    },
  })
}

export function useCreateTransactionFromAccount() {
  const createTransaction = (transactionInsertInput: TransactionFromAccount) =>
    requestCreateTransaction({ transactionInsertInput })

  return useMutation(createTransaction, {
    onSuccess: () => {
      invalidateTransactions()
      queryClient.invalidateQueries([MOVEMENTS_QUERY_KEY])
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(TRANSACTION_SERVICES_QUERY_KEY),
      })
    },
    onError: reportGqlError,
  })
}

export function useCreateTransactionsFromAccount() {
  return useMutation(
    async (dto: {
      transactionsFromAccount: TransactionFromAccount[]
    }): Promise<{
      insertTransactions?: { returning?: Array<{ id: number; employeeId: number }> }
    }> => {
      return request(
        gql`
          mutation ($transactionsFromAccount: [transaction_insert_input!]!) {
            insertTransactions(objects: $transactionsFromAccount) {
              returning {
                id
                employeeId
              }
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: (result, dto) => {
        invalidateTransactions()
        queryClient.invalidateQueries([TRANSACTIONS_SALARIES_QUERY_KEY])
        queryClient.invalidateQueries([MOVEMENTS_QUERY_KEY])
        queryClient.invalidateQueries([
          CLIENTS_QUERY_KEY,
          TAGS_QUERY_KEY,
          LEADS_QUERY_KEY,
          CARDS_QUERY_KEY,
          dto.transactionsFromAccount[0].clientId,
        ])
      },
    },
  )
}

export function useCreateTransactions() {
  return useMutation(
    async (dto: {
      paymentTransactions: TransactionInsertInput[]
    }): Promise<PaymentTransactionsInsertTransactions> => {
      return request(
        gql`
          mutation ($paymentTransactions: [transaction_insert_input!]!) {
            insertTransactions(objects: $paymentTransactions) {
              returning {
                id
                transactionsServices {
                  id
                  serviceId
                }
                transactionsCards {
                  card {
                    id
                    type
                    code
                    cardTemplate {
                      name
                    }
                  }
                }
              }
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        invalidateTransactions()
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(CARDS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(CARD_TEMPLATES_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(MOVEMENTS_QUERY_KEY),
        })

        queryClient.invalidateQueries([SOFTWARE_POSS_SHIFT_QUERY_KEY])
      },
    },
  )
}

export function useCreateTransactionRefundBySubscription() {
  const createTransaction = (transactionInsertInput: TransactionRefundBySubscription) =>
    requestCreateTransaction({ transactionInsertInput })

  return useMutation(createTransaction, {
    onSuccess: () => {
      invalidateTransactions()
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(CARDS_QUERY_KEY),
      })
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(BOOKING_HISTORY_QUERY_KEY),
      })
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(TRANSACTION_SERVICES_QUERY_KEY),
      })
    },
    onError: reportGqlError,
  })
}

export function useCreateTransactionsRefundBySubscription() {
  return useMutation(
    async (dto: {
      transactionRefundBySubscription: TransactionRefundBySubscription[]
    }): Promise<{
      insertTransactions?: { returning?: Array<{ id: number; employeeId: number }> }
    }> => {
      return request(
        gql`
          mutation ($transactionRefundBySubscription: [transaction_insert_input!]!) {
            insertTransactions(objects: $transactionRefundBySubscription) {
              returning {
                id
                employeeId
              }
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: (result, dto) => {
        invalidateTransactions()
        queryClient.invalidateQueries([TRANSACTIONS_SALARIES_QUERY_KEY])
        queryClient.invalidateQueries([MOVEMENTS_QUERY_KEY])
        queryClient.invalidateQueries([
          CLIENTS_QUERY_KEY,
          TAGS_QUERY_KEY,
          LEADS_QUERY_KEY,
          CARDS_QUERY_KEY,
          dto.transactionRefundBySubscription[0].clientId,
        ])
      },
    },
  )
}
