import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { ServerTimeOffReasonInsertInput } from '../../generated/graphql-types'
import { TIME_OFF_REASONS_QUERY_KEY } from './queryKeys'

export const useCreateTimeOffReason = () => {
  return useMutation(
    (
      timeOffReasonInsertInput: ServerTimeOffReasonInsertInput,
    ): Promise<{ insertTimeOffReason: { id: number } }> => {
      return request(
        gql`
          mutation ($timeOffReasonInsertInput: timeOffReason_insert_input!) {
            insertTimeOffReason(object: $timeOffReasonInsertInput) {
              id
            }
          }
        `,
        { timeOffReasonInsertInput },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([TIME_OFF_REASONS_QUERY_KEY]),
    },
  )
}

export function useMutateTimeOffReasons() {
  return useMutation(
    (
      timeOffReasonInsertInputs: ServerTimeOffReasonInsertInput[],
    ): Promise<{ insertTimeOffReasons: { affectedRows: number } }> => {
      return request(
        gql`
          mutation ($timeOffReasonInsertInputs: [timeOffReason_insert_input!]!) {
            insertTimeOffReasons(
              objects: $timeOffReasonInsertInputs
              on_conflict: { constraint: timeOffReason_pkey, update_columns: [name] }
            ) {
              affectedRows: affected_rows
            }
          }
        `,
        { timeOffReasonInsertInputs },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([TIME_OFF_REASONS_QUERY_KEY]),
    },
  )
}
