import { EmployeeGroupWithSchedule, ServerEmployee } from '@expane/data'
import { TreeMenuItem } from '.'
import { findTreeMenuItem, removeEmptyFolders } from './logic.common'
import { transformPersonName } from '@expane/logic/utils'
import { getEmployeeByIdFromEmployeeGroups } from 'logic/employees'

export const convertEmployeeGroupsToTreeItems = <
  T extends {
    id: number
    name: string
    employees: Pick<ServerEmployee, 'id' | 'firstName' | 'lastName'>[]
  },
>(
  groups: T[],
  options?: { keepEmptyFolders?: boolean },
): TreeMenuItem[] => {
  const treeItems = groups.map(group => ({
    ...group,
    isFolder: true,
    nodes: group.employees.map(employee => ({
      id: employee.id,
      name: transformPersonName(employee),
      parentId: group.id,
    })),
  }))

  return options?.keepEmptyFolders ? treeItems : removeEmptyFolders(treeItems)
}

export const transformEmployeeIdToTreeItem = (
  employeeId: number,
  employeeGroups: EmployeeGroupWithSchedule[],
): TreeMenuItem[] => {
  const employee = getEmployeeByIdFromEmployeeGroups(employeeGroups, employeeId)
  const name = employee ? transformPersonName(employee) : ''
  const parentId = employeeGroups.find(gr => gr.employees.find(e => e.id === employeeId))?.id

  return [{ id: employeeId, name, parentId }]
}

// Does not pass disabled prop. To include disabled use generateDefaultServiceEmployees
export const convertServiceEmployeesToTreeItems = (
  employees: { employee: Pick<ServerEmployee, 'id' | 'firstName' | 'lastName' | 'groupId'> }[],
): TreeMenuItem[] =>
  employees
    .filter(({ employee }) => Boolean(employee))
    .map(({ employee }) => ({
      id: employee.id,
      name: transformPersonName(employee),
      parentId: employee.groupId ?? undefined,
    }))

export const generateDefaultServiceEmployees = (
  employeeIds: number[],
  employeeTreeItems: TreeMenuItem[],
) => {
  const result: TreeMenuItem[] = []

  for (const employeeId of employeeIds) {
    const employeeItem = findTreeMenuItem(employeeId, false, employeeTreeItems)
    if (employeeItem) result.push(employeeItem)
  }

  return result
}
