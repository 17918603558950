import {
  DayTiming,
  ExtendedEmployee,
  ServerLocationWithServiceIdsType,
  useFetchClientsPhonesByClientId,
  useUpdateBooking,
  validateBooking,
} from '@expane/data'
import { getTimeFromDate, fromZonedTime } from '@expane/date'
import { generateMessageAfterValidation } from '@expane/logic/bookingChecks'
import { getServerColorFromServices } from '@expane/logic/service'
import { ConfirmationArgs } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useAgeDeclension } from 'i18n/declensions'
import { useCheckBookingDateEditable } from 'logic/calendar'
import { SERVICE_COLORS_LIST } from 'logic/service'
import { TAILWIND_TO_REM_RATIO } from 'logic/ui'
import { observer } from 'mobx-react-lite'
import {
  getColumnWidthInRem,
  getTopPositionForBooking,
} from 'pages/BookingsPage/BookingsCalendar/columns/logic'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { BOOKING_STATUS_COLORS } from 'widgets/BookingStatusTracker'
import { ConfirmationDescription } from 'widgets/ConfirmationDescription'
import { Booking } from '../logic'
import { Icons } from './Icons'
import {
  getBookingInfo,
  getDraggableBounds,
  getNewPositionDtoFromDrag,
  getPositionOfBookingCard,
  MutateBookingDto,
} from './logic'
import { getIsBookingDone } from '@expane/logic/booking'

interface CommonProps {
  booking: Booking
  onClick: () => void
  showConfirmation: (dto: ConfirmationArgs) => void
  dayTiming: DayTiming
  firstCellTime: number
  columnIndex: number
  employeeIsSelected: boolean
  cellHeight: number
  titleCellHeight: number
  boundaryElement: HTMLDivElement | undefined
  shrinked?: boolean
  columnRefWidth: number
  timezone: string
}

interface LocationBookingCardProps extends CommonProps {
  calendarType: 'locations'
  locations: ServerLocationWithServiceIdsType[]
}

interface EmployeeBookingCardProps extends CommonProps {
  calendarType: 'employees'
  employees: ExtendedEmployee[]
}

export type BookingCardProps = LocationBookingCardProps | EmployeeBookingCardProps

export const BookingCard: FC<BookingCardProps> = observer(props => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!
  const {
    booking,
    onClick,
    calendarType,
    dayTiming,
    showConfirmation,
    firstCellTime,
    columnIndex,
    employeeIsSelected,
    shrinked = false,
    cellHeight,
    titleCellHeight,
    boundaryElement,
    timezone,
    columnRefWidth,
  } = props
  const [y, setY] = useState(0)
  const [invisible, setInvisible] = useState(false)
  const [onTop, setOnTop] = useState(false)
  const cardRef = useRef(null)

  const { t } = useTranslation()
  const ageDeclension = useAgeDeclension()

  // Когда мы делаем драг и отпускаем, то происходит мутация и мы делаем карточку невидимой.
  // Видимой она станет когда придёт новая дата с сервера
  useEffect(() => {
    setY(0)
    setInvisible(false)
  }, [booking.startDate])

  const { data: clientPhones } = useFetchClientsPhonesByClientId(
    booking.isGroupBooking ? undefined : booking.client?.id,
  )
  const { mutateAsync: updateBooking } = useUpdateBooking()

  const [openSnackbar] = useSnackbar()

  const isBookingPaid = useMemo(() => Boolean(booking.isPaid), [booking])

  const topInCells = getTopPositionForBooking(getTimeFromDate(booking.startDate), firstCellTime)
  const topPositionInRem = (topInCells * cellHeight + titleCellHeight) / TAILWIND_TO_REM_RATIO
  const bounds = getDraggableBounds(dayTiming, cellHeight, booking.startDate, booking.duration)

  const bgColor = !booking.isGroupBooking
    ? getServerColorFromServices(booking.bookingServices.map(bS => bS.service))
    : booking.service?.color ?? 'white'
  const borderColor =
    SERVICE_COLORS_LIST.find(color => color.bgColor === bgColor)?.borderColor ?? 'primary-400'

  let cardStyle = `absolute bg-${bgColor} border border-${borderColor} rounded-md pl-1.5 cursor-pointer hover:z-10 shadow`
  if (invisible) cardStyle += ' invisible'
  if (onTop) cardStyle += ' z-20'

  const { leftOffsetInPercents, widthInPercents } = getPositionOfBookingCard(booking.positionInfo)

  const mutateBooking = async (dto: MutateBookingDto) => {
    const startDate = fromZonedTime(dto.newStartDate ?? booking.startDate, timezone)
    const confirmUpdate = () => {
      updateBooking({
        branchId,
        id: booking.id,
        bookingSetInput: {
          startDate,
          employeeId: dto.newEmployeeId,
          locationId: dto.newLocationId,
        },
        oldStartDate: booking.startDate,
      })
    }

    setInvisible(true)
    const { type } = await validateBooking({
      branchId,
      bookingId: booking.id,
      isGroupBooking: booking.isGroupBooking,
      startDate,
      duration: booking.duration,
      locationId: dto.newLocationId ?? booking.location.id,
      employeeId: dto.newEmployeeId ?? booking.employee?.id ?? null,
      serviceIds: !booking.isGroupBooking ? booking.bookingServices.map(bS => bS.service.id) : null,
      serviceId: booking.isGroupBooking ? booking.service?.id : null,
      clientId: !booking.isGroupBooking ? booking.client?.id : null,
      clientIds: booking.isGroupBooking ? booking.bookingClients.map(bC => bC.client.id) : null,
    })
    if (type !== undefined && type !== null) {
      const warning = generateMessageAfterValidation({ warningType: type, t })
      showConfirmation({
        title: t('editing'),
        description: (
          <ConfirmationDescription text={warning} question={t('booking.moveConfirmation')} />
        ),
        onConfirm: () => {
          confirmUpdate()
        },
        onDeny: () => {
          setY(0)
          setInvisible(false)
        },
      })
    } else {
      setInvisible(true)
      confirmUpdate()
    }
  }

  const dateIsEditable = useCheckBookingDateEditable(booking.startDate)

  const isEditable = dateIsEditable && !isBookingPaid && !getIsBookingDone(booking)

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setOnTop(false)

    if (data.lastY === 0 && data.lastX === 0 && onClick) {
      onClick()
    } else {
      try {
        const dto = getNewPositionDtoFromDrag({
          bookingStartDate: booking.startDate,
          data,
          employeeIsSelected,
          columnIndex,
          cellHeight,
          axisXEnabled: !shrinked,
          calendarType,
          locations: props.calendarType === 'locations' ? props.locations : [],
          employees: props.calendarType === 'employees' ? props.employees : [],
          servicesInBooking: booking.isGroupBooking
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              [booking.service!]
            : booking.bookingServices.map(bS => bS.service),
          columnWidthInPx: columnRefWidth,
        })
        mutateBooking(dto)
      } catch (error) {
        setY(0)
        openSnackbar(t((error as Error).message), 'error')
      }
    }
  }

  const info = getBookingInfo(booking, store.bookingSettings, ageDeclension, clientPhones)

  const scale = cellHeight / 2
  const heightRem = scale * (booking.duration / 60) - BOOKING_CARD_DEFAULT_OFFSET_REM

  const bookingHeight = Math.max(heightRem, cellHeight / 7)

  const isShort = bookingHeight < MIN_BOOKING_HEIGHT_REM

  const columnWidthByType =
    calendarType === 'locations'
      ? store.bookingSettings.locationColumnWidth
      : store.bookingSettings.employeeColumnWidth
  const columnWidth = getColumnWidthInRem(shrinked, columnWidthByType)
  const widthInRem = ((columnWidth * TAILWIND_TO_REM_RATIO) / 100) * widthInPercents
  const isNarrow = widthInRem <= MIN_BOOKING_WIDTH_REM

  const hideInfo = getHideInfo(widthInRem, bookingHeight)

  return (
    <>
      <Draggable
        onStart={() => setOnTop(true)}
        axis={shrinked ? 'y' : 'both'}
        onStop={handleDrag}
        bounds={bounds}
        grid={[columnRefWidth, cellHeight * 4]}
        position={{ x: 0, y }}
        disabled={!isEditable}
        nodeRef={cardRef}
      >
        <div
          ref={cardRef}
          // если карточку можно перемещать, клик обрабатывается через handleDrag()
          onClick={isEditable ? undefined : onClick}
          style={{
            top: topPositionInRem + 'rem',
            left: leftOffsetInPercents + '%',
            width: widthInPercents - BOOKING_CARD_HORIZONTAL_OFFSET_PERCENTS + '%',
            height: bookingHeight + 'rem',
          }}
          className={cardStyle}
        >
          <div className="text-sm text-black leading-4 break-all overflow-hidden max-h-full ml-0.5">
            {!hideInfo && info}
          </div>
          <div className="absolute h-full top-0 left-0 border-r-2 border-gray-200">
            <div
              className={`h-full w-1 rounded-l-full bg-${
                BOOKING_STATUS_COLORS[booking.status ?? 0]
              }`}
            />
          </div>
          <Icons
            isBookingPaid={isBookingPaid}
            clientPhones={clientPhones}
            bgColor={bgColor}
            booking={booking}
            isShort={isShort}
            isNarrow={isNarrow}
            boundaryElement={boundaryElement}
            calendarType={calendarType}
          />
        </div>
      </Draggable>
    </>
  )
})

const WIDTH_TO_HIDE_INFO_REM = 40
const HEIGHT_TO_HIDE_IF_MIN_WITH_REM = 5
const getHideInfo = (widthInRem: number, heightInRem: number) => {
  if (widthInRem < WIDTH_TO_HIDE_INFO_REM) return true
  if (widthInRem < MIN_BOOKING_WIDTH_REM) {
    return heightInRem < HEIGHT_TO_HIDE_IF_MIN_WITH_REM
  }

  return heightInRem < MIN_BOOKING_HEIGHT_REM
}

export const BOOKING_CARD_DEFAULT_OFFSET_REM = 0.2
export const BOOKING_CARD_HORIZONTAL_OFFSET_PERCENTS = 3
const MIN_BOOKING_HEIGHT_REM = 1.3
const MIN_BOOKING_WIDTH_REM = 65
