import { QueryClient } from '@tanstack/react-query'
import { getAuthToken } from 'logic/auth'
import { config } from 'config'
import { reportError, reportGqlError } from './sentry'
import { DEFAULT_STALE_TIME, initGraphQLClient } from '@expane/data'
import { getCurrentHub, startTransaction } from '@sentry/react'

console.log('GQL endpoint:', config.HASURA_ENDPOINT) // eslint-disable-line

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: DEFAULT_STALE_TIME,
      refetchOnWindowFocus: true,
    },
  },
})

export const { useMutation, useQuery, request, QueryClientProvider } = initGraphQLClient({
  queryClient,
  endpoint: config.HASURA_ENDPOINT,
  getAuthToken,
  reportError,
  reportGqlError,
  sentry: {
    env: config.ENV,
    getCurrentHub,
    startTransaction,
  },
})
