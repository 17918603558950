import { ServerTransactionType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { getTransactionsWithOtherBusinessRevenue } from '@expane/logic/finances/filters'
import { groupRevenueReason } from '@expane/logic/reports/helpers'
import { calcValues } from '@expane/logic/utils'
import { TableBody, TableContainer, TableHeader, TableHeaderCell } from '@expane/ui'
import { ListItem } from 'pages/ReportsPage/RevenueExpensesReport/ListItem'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface RevenueListProps {
  transactions: ServerTransactionType[]
  servicesValue: number
  clientsDepositTotalValue: number
  productsTotalValue: number
  consumablesTotalValue: number
  subscriptionsValue: number
  productsReturnTotalValue: number
  giftCardsValue: number
  otherBusinessRevenueTotalValue: number
  unspentClientsBalance: number
  isLoading: boolean
}

export const RevenueList: FC<PropsWithBranchId<RevenueListProps>> = ({
  transactions,
  clientsDepositTotalValue,
  servicesValue,
  productsTotalValue,
  consumablesTotalValue,
  subscriptionsValue,
  productsReturnTotalValue,
  giftCardsValue,
  otherBusinessRevenueTotalValue,
  unspentClientsBalance,
  isLoading,
  branchId,
}) => {
  const { t } = useTranslation()

  // считаем доход отдельно по разным видам доходов бизнеса
  const otherBusinessRevenueTransactions = getTransactionsWithOtherBusinessRevenue(transactions)

  const groupedOtherBusinessRevenue = otherBusinessRevenueTransactions
    ? groupRevenueReason(otherBusinessRevenueTransactions)
    : []

  return (
    <TableContainer className="h-fit">
      <TableHeader>
        <tr>
          <TableHeaderCell>{t('revenues')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('amount')}</TableHeaderCell>
        </tr>
      </TableHeader>

      <TableBody>
        {isLoading ? (
          <>
            <ListItem branchId={branchId} />
            <ListItem branchId={branchId} />
            <ListItem branchId={branchId} />
          </>
        ) : (
          <>
            <ListItem
              title={t('reports.addedToClientsAccounts')}
              value={clientsDepositTotalValue}
              isTitle
              branchId={branchId}
            />
            {servicesValue ? (
              <ListItem
                title={t('reports.spentOnServices')}
                value={servicesValue}
                className="pl-8"
                branchId={branchId}
              />
            ) : null}
            {productsTotalValue ? (
              <ListItem
                title={t('reports.spentOnProducts')}
                value={productsTotalValue}
                className="pl-8"
                branchId={branchId}
              />
            ) : null}
            {consumablesTotalValue ? (
              <ListItem
                title={t('reports.spentOnConsumables')}
                value={consumablesTotalValue}
                className="pl-8"
                branchId={branchId}
              />
            ) : null}
            {subscriptionsValue ? (
              <ListItem
                title={t('reports.spentOnSubscriptions')}
                value={subscriptionsValue}
                className="pl-8"
                branchId={branchId}
              />
            ) : null}
            {giftCardsValue ? (
              <ListItem
                title={t('reports.spentOnGiftCards')}
                value={giftCardsValue}
                className="pl-8"
                branchId={branchId}
              />
            ) : null}

            {unspentClientsBalance ? (
              <ListItem
                title={t('reports.leftOnClientsAccounts')}
                value={unspentClientsBalance}
                className="pl-8"
                branchId={branchId}
              />
            ) : null}

            {productsReturnTotalValue ? (
              <ListItem
                title={t('reports.refundToSuppliers')}
                value={productsReturnTotalValue}
                isTitle
                branchId={branchId}
              />
            ) : null}

            {otherBusinessRevenueTotalValue ? (
              <ListItem
                title={t('reports.incomingPayments')}
                value={otherBusinessRevenueTotalValue}
                isTitle
                branchId={branchId}
              />
            ) : null}

            {Object.keys(groupedOtherBusinessRevenue).map(revenue => (
              <ListItem
                key={revenue}
                title={revenue}
                className="pl-8"
                value={calcValues(groupedOtherBusinessRevenue[revenue])}
                branchId={branchId}
              />
            ))}
          </>
        )}
      </TableBody>
    </TableContainer>
  )
}
