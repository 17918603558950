import { FadeInTranslateTop, modalsHTMLElement, usePopupOpenState } from '@expane/ui'
import { flip, offset, useFloating } from '@floating-ui/react-dom'
import { MouseEvent, useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { IconType } from 'react-icons'

export interface ContextMenuPosition {
  top: number
  left: number
}

export interface ContextMenuItem {
  id: number
  name: string
  Icon?: IconType
}

export const useContextMenu = (
  contextMenuItems: ContextMenuItem[] | undefined,
  onItemClick: ((id: number) => void) | undefined,
) => {
  const [anchorPoint, setAnchorPoint] = useState<ContextMenuPosition>({ top: 0, left: 0 })

  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
    middleware: [
      flip(),
      offset(({ placement }) => ({
        mainAxis: -15,
        crossAxis: placement === 'bottom-start' || placement === 'top-start' ? -15 : 15,
      })),
    ],
  })

  const handleContextMenu = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (contextMenuItems) {
        event.preventDefault()
        setAnchorPoint({ top: event.pageY, left: event.pageX })
        openPopup()
      }
    },
    [contextMenuItems, openPopup],
  )

  const contextMenu =
    isOpen && contextMenuItems
      ? createPortal(
          <>
            <div style={{ ...anchorPoint }} ref={reference} className="absolute"></div>
            <div
              style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
              ref={floating}
              onMouseLeave={closePopup}
            >
              <FadeInTranslateTop>
                <div className="bg-primary-25 dark:bg-gray-800 border-2 border-block-color rounded-lg p-1 flex-col">
                  {contextMenuItems.map(({ id, name, Icon }) => (
                    <div key={id} className={contextMenuStyle} onClick={() => onItemClick?.(id)}>
                      {Icon && <Icon size="1rem" className="mr-1" />}
                      <p className="grow">{name}</p>
                    </div>
                  ))}
                </div>
              </FadeInTranslateTop>
            </div>
          </>,
          modalsHTMLElement,
        )
      : null

  return { contextMenu, handleContextMenu }
}

const contextMenuStyle =
  'flex items-center p-1.5 rounded-md transition-all text-sm text-primary-500 hover:bg-primary-100 hover:dark:bg-primary-tinted cursor-pointer'
