import { checkBusinessIsNotPaid } from '@expane/logic/business'
import { permissions } from '@expane/logic/permission'
import { Button, ButtonProps } from '@expane/ui'
import { useFetchCurrentBusiness } from 'gql/business'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IoAddCircleOutline,
  IoEnterOutline,
  IoReaderOutline,
  IoRemoveCircleOutline,
  IoRepeat,
} from 'react-icons/io5'
import { useSnackbar } from '@expane/widgets'
import { InventoryCreateDialog } from 'widgets/InventoryCreateDialog'
import {
  ChoosePostingProductsDialog,
  ChooseProductsArrivalDialog,
  ChooseProductsMovementDialog,
  ChooseProductsWriteOffDialog,
} from 'widgets/MovementCreateDialogs/ChooseMovementProductsDialog'

interface MovementsDialogsButtonsProps {
  selectedStorageId: number | null
  showProductsMovementButton?: boolean
}

export const MovementsDialogsButtons: FC<MovementsDialogsButtonsProps> = ({
  selectedStorageId,
  showProductsMovementButton = true,
}) => {
  const { t } = useTranslation()
  const { data: currentBusiness } = useFetchCurrentBusiness()
  const [openSnackbar] = useSnackbar()

  const { data: myPermission } = useFetchMyPermissions()

  const {
    openCreateDialog: openChooseProductsArrivalDialog,
    openEditDialog: openChooseProductsArrivalDialogWithStorage,
    dialog: chooseProductsArrivalDialog,
  } = useOpenDialog(ChooseProductsArrivalDialog)

  const {
    openCreateDialog: openChooseProductsWriteOffDialog,
    openEditDialog: openChooseProductsWriteOffDialogWithStorage,
    dialog: chooseProductsWriteOffDialog,
  } = useOpenDialog(ChooseProductsWriteOffDialog)

  const {
    openCreateDialog: openChooseProductsMovementDialog,
    openEditDialog: openChooseProductsMovementDialogWithStorage,
    dialog: chooseProductsMovementDialog,
  } = useOpenDialog(ChooseProductsMovementDialog)

  const {
    openCreateDialog: openInventoryProductsDialog,
    openEditDialog: openInventoryProductsDialogWithStorage,
    dialog: inventoryProductsDialog,
  } = useOpenDialog(InventoryCreateDialog)

  const {
    openCreateDialog: openChoosePostingProductsDialog,
    openEditDialog: openChoosePostingProductsDialogWithStorage,
    dialog: choosePostingProductsDialog,
  } = useOpenDialog(ChoosePostingProductsDialog)

  const isInventoryEditingAllowed = myPermission?.includes(permissions.inventory.set)
  const areProductsMovementAllowed = myPermission?.includes(permissions.movement.set)

  const handleArrivalDialog = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else {
      if (selectedStorageId) openChooseProductsArrivalDialogWithStorage(selectedStorageId)
      else openChooseProductsArrivalDialog()
    }
  }
  const handleWriteOffDialog = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else {
      if (selectedStorageId) openChooseProductsWriteOffDialogWithStorage(selectedStorageId)
      else openChooseProductsWriteOffDialog()
    }
  }
  const handlePostingDialog = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else {
      if (selectedStorageId) openChoosePostingProductsDialogWithStorage(selectedStorageId)
      else openChoosePostingProductsDialog()
    }
  }
  const handleMovementDialog = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else {
      if (selectedStorageId) openChooseProductsMovementDialogWithStorage(selectedStorageId)
      else openChooseProductsMovementDialog()
    }
  }
  const handleInventoryDialog = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else {
      if (selectedStorageId) openInventoryProductsDialogWithStorage(selectedStorageId)
      else openInventoryProductsDialog()
    }
  }

  return (
    <>
      {areProductsMovementAllowed && (
        <>
          <Button onClick={handleArrivalDialog} Icon={IoAddCircleOutline} {...COMMON_BUTTON_PROPS}>
            {t('arrival.name')}
          </Button>
          <Button
            onClick={handleWriteOffDialog}
            Icon={IoRemoveCircleOutline}
            {...COMMON_BUTTON_PROPS}
          >
            {t('writeOff.nameWithProducts')}
          </Button>

          <Button onClick={handlePostingDialog} Icon={IoEnterOutline} {...COMMON_BUTTON_PROPS}>
            {t('posting.name')}
          </Button>

          {showProductsMovementButton && (
            <Button onClick={handleMovementDialog} Icon={IoRepeat} {...COMMON_BUTTON_PROPS}>
              {t('movement.name')}
            </Button>
          )}
        </>
      )}

      {isInventoryEditingAllowed && (
        <Button onClick={handleInventoryDialog} Icon={IoReaderOutline} {...COMMON_BUTTON_PROPS}>
          {t('inventory.name')}
        </Button>
      )}

      {chooseProductsArrivalDialog}
      {chooseProductsWriteOffDialog}
      {chooseProductsMovementDialog}
      {inventoryProductsDialog}
      {choosePostingProductsDialog}
    </>
  )
}

const COMMON_BUTTON_PROPS: ButtonProps = {
  twoLines: true,
  type: 'outline',
  className: 'mr-2 w-min pr-3 items-center mb-2',
}
