import { gql } from 'graphql-request'
import { reviewFragment } from '../review'

export const clientNotificationFragment = gql`
  fragment clientNotificationType on notification {
    id
    message
    sentAt
    author {
      firstName
      lastName
    }
    expoStatus
    telegramStatus
    viberStatus
    smsStatus
  }
`

export const notificationFragment = gql`
  ${reviewFragment}
  fragment notificationType on notification {
    id
    type
    typeVariation
    message
    sentAt
    status
    bookingId
    waitingList {
      service {
        name
      }
      client {
        firstName
        lastName
      }
    }
    booking {
      id
      branch {
        name
      }
      client {
        firstName
        lastName
      }
      employee {
        firstName
        lastName
      }
      bookingServices {
        service {
          name
        }
      }
      duration
      startDate
      clientNote
    }
    review {
      ...reviewType
    }
  }
`
