import { gql } from 'graphql-request'
import { request, useMutation, reportGqlError, useQuery, queryClient } from '../../api'
import { Maybe, ServerEmployeeSettingsType } from '../../generated/graphql-types'
import { employeeSettingsFragment } from './employeeSettings.fragments'
import { EMPLOYEE_SETTINGS_QUERY_KEY } from './queryKeys'

export type EmployeeConnections = {
  telegram: Maybe<boolean>
  viber: Maybe<boolean>
  expo: Maybe<boolean>
}

export type EmployeeNotificationSettings = {
  receivingOnChange: boolean
  receivingOnCancel: boolean
  receivingOnCreate: boolean
  receivingOnInPlaceStatus: boolean
}

export type EmployeeSettingsType = Omit<ServerEmployeeSettingsType, 'notificationSettings'> & {
  notificationSettings: EmployeeNotificationSettings
}

export function useFetchEmployeeSettingsByEmployeeId(employeeId: number | null) {
  return useQuery(
    [EMPLOYEE_SETTINGS_QUERY_KEY],
    async () => {
      const result = await request<{
        employeeSettings: EmployeeSettingsType[]
      }>(
        gql`
          ${employeeSettingsFragment}
          query ($employeeId: Int!) {
            employeeSettings(where: { employeeId: { _eq: $employeeId } }) {
              ...employeeSettingsType
            }
          }
        `,
        {
          employeeId,
        },
      )

      if (result?.employeeSettings) return result.employeeSettings[0]

      return undefined
    },
    {
      queryName: 'useFetchMyEmployeeSettings',
      onError: reportGqlError,
      enabled: Boolean(employeeId),
    },
  )
}

export function useUpdateMyEmployeeLanguage() {
  return useMutation(
    (dto: { id: number; lang: string }) => {
      return request(
        gql`
          mutation ($id: Int!, $lang: String!) {
            updateEmployeeSettingsById(pk_columns: { id: $id }, _set: { lang: $lang }) {
              lang
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
    },
  )
}

export function useUpdateEmployeeNotificationSettings() {
  return useMutation(
    async (dto: { id: number; notificationSettings: EmployeeNotificationSettings }) => {
      const result = await request<{ updateClientSettingsById?: { id: number | null } }>(
        gql`
          mutation ($id: Int!, $notificationSettings: jsonb!) {
            updateEmployeeSettingsById(
              pk_columns: { id: $id }
              _set: { notificationSettings: $notificationSettings }
            ) {
              id
            }
          }
        `,
        dto,
      )
      return Boolean(result?.updateClientSettingsById?.id)
    },
    {
      onSuccess: () => queryClient.invalidateQueries([EMPLOYEE_SETTINGS_QUERY_KEY]),
    },
  )
}

export function useUpdateEmployeeConnections() {
  return useMutation(
    async (dto: { id: number; preferredConnections: EmployeeConnections }) => {
      const result = await request<{ updateEmployeeSettingsById?: { id: number | null } }>(
        gql`
          mutation ($id: Int!, $preferredConnections: jsonb!) {
            updateEmployeeSettingsById(
              pk_columns: { id: $id }
              _set: { preferredConnections: $preferredConnections }
            ) {
              id
            }
          }
        `,
        dto,
      )
      return Boolean(result?.updateEmployeeSettingsById?.id)
    },
    {
      onSuccess: () => queryClient.invalidateQueries([EMPLOYEE_SETTINGS_QUERY_KEY]),
    },
  )
}
