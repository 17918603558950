import {
  ServerTransactionBriefWithClient,
  ServerTransactionToCalcBalanceType,
  ServerTransactionType,
  TRANSACTION_TYPES,
} from './../../data'
import { endOfDay, isBefore, subDays } from '@expane/date'

export const getTransactionsProductsReturn = (transactions?: ServerTransactionType[]) =>
  transactions?.filter(transaction => transaction.type === TRANSACTION_TYPES.productsReturn.id)

// транзакции доходов бизнеса, которые не связанные с клиентами
export const getTransactionsWithOtherBusinessRevenue = (transactions?: ServerTransactionType[]) =>
  transactions?.filter(t => t.type === TRANSACTION_TYPES.revenue.id)

// все транзакции депозитов клиентов в том числе и погашение кредитов
export const getTransactionsWithClientsDeposits = <
  T extends { type: number; typeVariation?: number | null },
>(
  transactions?: T[],
) =>
  transactions?.filter(
    transaction =>
      transaction.type === TRANSACTION_TYPES.deposit.id ||
      (transaction.type === TRANSACTION_TYPES.repaymentDeposit.id &&
        transaction.typeVariation ===
          TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByMoney.id),
  )

export const checkIsTransactionPayment = <
  T extends { type: number; typeVariation?: number | null },
>(
  transaction: T,
) =>
  Boolean(
    transaction.type === TRANSACTION_TYPES.payment.id ||
      transaction.type === TRANSACTION_TYPES.paymentInCredit.id,
  )

export const getTransactionsBeforeDate = (
  transactions: ServerTransactionToCalcBalanceType[] | undefined,
  date: Date,
  includeDateDay?: boolean,
) => {
  const checkedDate = includeDateDay ? endOfDay(date) : endOfDay(subDays(date, 1))

  if (!transactions) return []

  return transactions.filter(transaction => isBefore(transaction.date, checkedDate))
}

// транзакции транспортных расходов
export const getTransactionsTransportCosts = (transactions?: ServerTransactionType[]) =>
  transactions?.filter(transaction => transaction.type === TRANSACTION_TYPES.transportCosts.id)

// транзакции зарплат
export const getTransactionsWithSalaries = (transactions?: ServerTransactionType[]) =>
  transactions?.filter(transaction => transaction.type === TRANSACTION_TYPES.salary.id)

// транзакции других затрат бизнеса
export const getTransactionsWithOtherBusinessExpenses = (transactions?: ServerTransactionType[]) =>
  transactions?.filter(transaction => transaction.type === TRANSACTION_TYPES.expenses.id)

// транзакции оплаты контрагенту
export const getTransactionsWithProductsPurchase = (transactions?: ServerTransactionType[]) =>
  transactions?.filter(transaction => transaction.type === TRANSACTION_TYPES.productsPurchase.id)

// транзакции возврата c счета (учитывается при подсчете денег на счету)
export const getTransactionsWithRefundsFromAccount = (transactions?: ServerTransactionType[]) =>
  transactions?.filter(transaction => checkIsTransactionRefundedFromAccount(transaction))

// транзакции всех затрат по конкретному счету
export const getTransactionsWithAllTypesExpensesOnAccount = (
  id: number,
  transactions?: ServerTransactionType[],
) => transactions?.filter(t => t.fromAccountId === id && t.type !== TRANSACTION_TYPES.operation.id)

// проверяет или транзакция возврата с счета
export const checkIsTransactionRefundedFromAccount = <
  T extends { type: number; typeVariation?: number | null },
>(
  transaction: T,
) =>
  Boolean(
    transaction.type === TRANSACTION_TYPES.refund.id &&
      transaction.typeVariation === TRANSACTION_TYPES.refund.variations.refundFromAccount.id,
  )

export const checkIsPaymentTransactionWithSubscription = <
  T extends { type: number; typeVariation?: number | null },
>(
  transaction: T,
) =>
  (transaction.type === TRANSACTION_TYPES.payment.id ||
    transaction.type === TRANSACTION_TYPES.paymentInCredit.id) &&
  transaction.typeVariation === TRANSACTION_TYPES.payment.variations.subscription.id

// проверяет или транзакция возврата любой вариации
export const checkIsRefundTransaction = <T extends { type: number }>(transaction: T) =>
  Boolean(transaction.type === TRANSACTION_TYPES.refund.id)

export const getIsSubscriptionRefunded = (
  refundsForTransaction: ServerTransactionType[],
  subscriptionId: number,
) =>
  refundsForTransaction.some(transaction =>
    transaction.transactionsCards?.some(card => card.card.id === subscriptionId),
  )

// все транзакции депозитов и оплат клиентов
export const getTransactionsWithClientsDepositsAndPayments = (
  transactions: ServerTransactionBriefWithClient[],
) =>
  transactions.filter(
    t => t.type === TRANSACTION_TYPES.payment.id || t.type === TRANSACTION_TYPES.deposit.id,
  )

export const getTransactionsWithCashOut = (transactions?: ServerTransactionType[]) =>
  transactions?.filter(tr => tr.type === TRANSACTION_TYPES.cashOut.id)

export const getTransactionsWithPaymentInCredit = (transactions?: ServerTransactionType[]) =>
  transactions?.filter(transaction => transaction.type === TRANSACTION_TYPES.paymentInCredit.id)

export const getTransactionsWithRepaymentDeposit = (transactions?: ServerTransactionType[]) =>
  transactions?.filter(transaction => transaction.type === TRANSACTION_TYPES.repaymentDeposit.id)

export const getTransactionsWithPaymentInCreditRefund = (transactions?: ServerTransactionType[]) =>
  transactions?.filter(
    transaction =>
      transaction.type === TRANSACTION_TYPES.refund.id &&
      transaction.typeVariation === TRANSACTION_TYPES.refund.variations.refundPaymentInCredit.id,
  )

export const getBankExpensesTransactions = (transactions?: ServerTransactionType[]) => {
  const otherBusinessExpensesTransactions = getTransactionsWithOtherBusinessExpenses(transactions)

  return otherBusinessExpensesTransactions?.filter(expense => expense.expensesReason === null)
}

export const getExpensesTransactionsWithReason = (transactions?: ServerTransactionType[]) => {
  const otherBusinessExpensesTransactions = getTransactionsWithOtherBusinessExpenses(transactions)

  return otherBusinessExpensesTransactions?.filter(ex => ex.expensesReason !== null)
}
