import {
  Button,
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  PlaceholderInput,
  PlaceholderString,
  Spinner,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkDoneOutline } from 'react-icons/io5'

export const SalaryIssueDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>
        <div className="flex items-center justify-between">
          <p>{t('salary.name')}</p>
          <div className="flex items-center text-sm leading-3 font-medium text-gray-500">
            {`${t('period')}: `}
            <PlaceholderString />
          </div>
        </div>
      </Dialog.Title>

      <Dialog.Body className="flex flex-col h-122 w-220">
        <PlaceholderInput label={t('employees.name')} className="w-1/2 mr-2" />

        <div className="mt-3 h-70">
          <Spinner expandCentered />
        </div>
        <div className="flex flex-1 mt-5">
          <PlaceholderInput label={t('totalAmount')} className="w-52 ml-auto" />

          <div className="w-72 ml-2">
            <PlaceholderInput label={t('salary.issueFromAccount')} />
            <div className="h-5" />
          </div>
        </div>
      </Dialog.Body>

      <Dialog.Footer>
        <Button disabled Icon={IoCheckmarkDoneOutline}>
          {t('issue')}
        </Button>

        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
