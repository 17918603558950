import { formatPhoneNumber } from 'logic/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EmployeeDialog } from 'widgets/EmployeeDialog/index'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'

interface EmployeeDuplicateConfirmationProps {
  name: string
  id: number
  phone: string
}

export const EmployeeDuplicateConfirmation: FC<EmployeeDuplicateConfirmationProps> = ({
  name,
  id,
  phone,
}) => {
  const { t } = useTranslation()

  const { openEditDialog, dialog } = useOpenDialog(EmployeeDialog)

  return (
    <div>
      {t('employee.isAlreadyInDatabase', { phone: formatPhoneNumber(phone) })}
      <p
        className=" text-primary-400 cursor-pointer hover:text-primary-500"
        onClick={() => openEditDialog(id)}
      >
        {name}
      </p>
      {dialog}
    </div>
  )
}
