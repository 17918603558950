import { modalsHTMLElement, useShortCut } from '@expane/ui'
import { RefObject, useCallback, useEffect } from 'react'

export const useShortQuickFindCut = (
  elementRef: RefObject<HTMLInputElement>,
  onEscAction: () => void,
) => {
  const handlePressShortcut = useCallback(
    (e: KeyboardEvent) => {
      const isCtrlOrMetaPress = navigator.userAgent.includes('Macintosh') ? e.metaKey : e.ctrlKey

      const notOpenModal = e.target instanceof Node && !modalsHTMLElement?.contains(e.target)

      if (e.code === 'KeyK' && isCtrlOrMetaPress) {
        e.preventDefault()
        if (notOpenModal) elementRef.current?.focus()
      }
    },
    [elementRef],
  )

  useEffect(() => {
    document.addEventListener('keydown', handlePressShortcut, true)
    return () => document.removeEventListener('keydown', handlePressShortcut, true)
  }, [handlePressShortcut])

  useShortCut(['Escape'], () => {
    elementRef.current?.blur()
    onEscAction()
  })
}
