import {
  useFetchAccounts,
  useFetchAccountSoftwarePOSs,
  useFetchSoftwarePOSById,
} from '@expane/data'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { transformAccountsForSelect } from './logic'
import { SoftwarePOSDialogLogic } from './SoftwarePOSDialogLogic'
import { SoftwarePOSDialogPlaceholder } from './SoftwarePOSDialogPlaceholder'

export const SoftwarePOSDialog: FC<DialogProps> = observer(
  ({ id: softwarePOSId, closeDialog, isCreate }) => {
    const { t } = useTranslation()

    const branchId = store.branch.branchId

    const { data: softwarePOSbyId, isLoading: softwarePOSbyIdIsLoading } =
      useFetchSoftwarePOSById(softwarePOSId)
    const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)
    const { data: accountSoftwarePOSs, isLoading: isLoadingAccountSoftwarePOSs } =
      useFetchAccountSoftwarePOSs(branchId)

    const isLoading =
      (softwarePOSbyIdIsLoading && !isCreate) || isLoadingAccounts || isLoadingAccountSoftwarePOSs

    const isNoData = (!isCreate && !softwarePOSbyId) || !accounts || !accountSoftwarePOSs

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

    if (isLoading) return <SoftwarePOSDialogPlaceholder closeDialog={closeDialog} />
    else if (isNoData) return null

    return (
      <SoftwarePOSDialogLogic
        softwarePOSbyId={softwarePOSbyId}
        accounts={transformAccountsForSelect({ accounts, accountSoftwarePOSs, softwarePOSbyId, t })}
        closeDialog={closeDialog}
        isCreate={isCreate}
      />
    )
  },
)
