import { PERIODS_OF_TIME } from '@expane/logic/analytics'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { translateItemsName } from '@expane/logic/utils'
import { Hint, PlaceholderString, SelectDropdown } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { AccountsBlock } from 'pages/AnalyticsPage/AccountsBlock'
import { ClientsBlock } from 'pages/AnalyticsPage/ClientsBlock'
import { ProductsBlock } from 'pages/AnalyticsPage/ProductsBlock'
import { RevenueExpensesBlock } from 'pages/AnalyticsPage/RevenueExpensesBlock'
import { SubscriptionsBlock } from 'pages/AnalyticsPage/SubscriptionsBlock'
import { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'

export const AnalyticsPage: FC = () => {
  const { getModuleSetting } = useBusinessModulesSettings()
  const isSubscriptionsEnabled = getModuleSetting('subscriptions')
  const isProductsForSaleEnabled = getModuleSetting('productsForSale')

  return (
    <div className="p-page gap-2 flex flex-wrap justify-center">
      <AccountsBlock className={blockStyles} />
      <RevenueExpensesBlock className={blockStyles} />
      {isSubscriptionsEnabled && <SubscriptionsBlock className={blockStyles} />}

      <ClientsBlock className={blockStyles} />
      {isProductsForSaleEnabled && <ProductsBlock className={blockStyles} />}
    </div>
  )
}

const blockStyles = 'w-[32%]'

interface AnalyticsBlockTitleProps {
  title: string
  onSelectChange?: (value: number | null) => void
  periodOfTimeId?: number
  namePeriodOfTime?: string
  hintMessage: string
  showPeriodSelection?: boolean
  isLoading?: boolean
}

export const AnalyticsBlockTitle: FC<AnalyticsBlockTitleProps> = ({
  title,
  onSelectChange,
  periodOfTimeId,
  namePeriodOfTime,
  hintMessage,
  showPeriodSelection = true,
  isLoading,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-between items-center">
      <h1 className="w-3/5 text-main-color font-medium text-2xl md:text-xl truncate">{title}</h1>
      <div className="flex w-2/5 justify-end">
        <Hint>{`${hintMessage} ${namePeriodOfTime}`}</Hint>

        {showPeriodSelection && onSelectChange ? (
          <SelectDropdown
            items={translateItemsName(Object.values(PERIODS_OF_TIME), t)}
            onSelectChange={onSelectChange}
            value={periodOfTimeId}
            className="ml-2"
            noSearch
            disabled={isLoading}
          />
        ) : null}
      </div>
    </div>
  )
}

interface AnalyticsBlockBodyProps {
  value: number
  text: string
  isValueMoney?: boolean
  isLoading?: boolean
}

export const AnalyticsBlockBody: FC<PropsWithChildren<AnalyticsBlockBodyProps>> = observer(
  ({ children, value, text, isValueMoney = false, isLoading }) => {
    const branchId = store.branch.branchId
    const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

    return (
      <div>
        <h2 className="xl:text-5xl lg:text-4xl md:text-3xl font-medium my-3 text-primary-500">
          {isLoading ? <PlaceholderString /> : isValueMoney ? convertToMoneyCode(value) : value}
        </h2>

        <p className="text-gray-400 mt-3">{text}</p>

        <ul className="text-xl mt-3 text-secondary-color font-medium">{children}</ul>
      </div>
    )
  },
)

interface AnalyticBlockItemProps {
  title: string
  value: number
  isValueMoney?: boolean
  isLoading?: boolean
}

export const AnalyticsBlockItem: FC<AnalyticBlockItemProps> = observer(
  ({ title, value, isValueMoney = false, isLoading }) => {
    const branchId = store.branch.branchId
    const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

    return (
      <li className="flex justify-between items-center">
        <p className="truncate">{title}</p>
        {isLoading ? (
          <PlaceholderString />
        ) : isValueMoney ? (
          <p>{convertToMoneyCode(value)}</p>
        ) : (
          <p>{value}</p>
        )}
      </li>
    )
  },
)
