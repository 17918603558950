import { gql } from 'graphql-request'

export const onlineBookingURLFragment = gql`
  fragment onlineBookingURLType on onlineBookingURL {
    id
    name
    description
    businessId
    branchId
    employeeId
    serviceId
    disableEmployeeSelection
  }
`
