import {
  BranchWithSchedule,
  EmployeeByIdExtended,
  EmployeeGroupWithSchedule,
  ServerExtendedServiceType,
  ServerServiceEmployeeMutationResponse,
  ServerServiceGroupType,
  useCreateEmployee,
  useFetchExtendedEmployees,
  useFetchSchedules,
  useUpdateEmployee,
  useUpdateEmployeeEmail,
  useUpdateOwnerEmployee,
} from '@expane/data'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { Gender } from '@expane/logic/client'
import {
  getEmployeeWithSamePhoneNumberFullName,
  getFilteredEmployeesGroups,
  getServiceIdDifferencesInEmployee,
  getServiceIdsThatWereUsedWithEmployeeHashMap,
} from '@expane/logic/employee'
import { getIsScheduleTypeDynamic, SCHEDULE_TYPES } from '@expane/logic/employeeSchedule'
import { useUpdateFile } from '@expane/logic/file'
import { PLACEHOLDERS, validateEmail } from '@expane/logic/form'
import { onlyOwnersPermissions, permissions } from '@expane/logic/permission'
import { checkValidPhone, DEFAULT_UKR_PHONE_CODE } from '@expane/logic/phone'
import {
  Input,
  RadioGroupButtons,
  SelectDropdown,
  SelectDropDownItem,
  Textarea,
  useShowConfirmationPopup,
} from '@expane/ui'
import { DateTimePicker, useSnackbar } from '@expane/widgets'
import { showPhoneErrorMessage } from 'logic/form'
import { OnCreateFunc, useOpenDialog } from 'logic/hooks/useOpenDialog'
import { onChangeWithFirstUpperCase } from 'logic/ui'
import { translateData } from 'logic/utils'
import { FC, useCallback } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { queryClient } from 'services/api'
import { reportError } from 'services/sentry'
import { PhoneInput } from 'ui/PhoneInput'
import { TreeMenuItem } from 'ui/TreeMenu'
import {
  extractItemsFromFolders,
  groupInitialTreeItems,
  transformDataForTreeMenu,
} from 'ui/TreeMenu/logic.common'
import { TreeSelect } from 'ui/TreeSelect'
import { ExtendedSelectDropdownItem } from 'widgets/ClientDialog'
import { EditablePhoto } from 'widgets/EditablePhoto'
import {
  EmployeeTabAdditionalSavingProps,
  OnCreateEmployeeDto,
  useAttachFunctionAndFormState,
} from 'widgets/EmployeeDialog'
import {
  SelectPreferredConnections,
  transformPreferredConnectionsForForms,
  transformPreferredConnectionsFormValuesForMutation,
} from 'widgets/EmployeeDialog/InfoTab/SelectPreferredConnections'
import { EmployeeGroupDialog } from 'widgets/EmployeeGroupDialog'
import { GenderPicker } from 'widgets/GenderPicker'
import { ServiceDialog } from 'widgets/ServiceDialog'
import { EmployeeDuplicateConfirmation } from '../EmployeeDuplicateConfirmation'
import {
  generateEmailErrorMessage,
  provideDisabledServicesTreeMenuForUsedServices,
  validateEmployeeEmail,
} from '../logic'

export interface EmployeeDialogInfoTabProps
  extends EmployeeTabAdditionalSavingProps<EmployeeFormValues> {
  employeeById: EmployeeByIdExtended | undefined
  employeeGroups: EmployeeGroupWithSchedule[]
  serviceGroups: ServerServiceGroupType[]
  services: ServerExtendedServiceType[]
  myPermissions: string[]
  currentBranch: BranchWithSchedule
  isCreate: boolean
  timezone: string
  onCreate?: OnCreateFunc<OnCreateEmployeeDto>
  areAllowedManyEmployees: boolean
}

export const EmployeeDialogInfoTab: FC<EmployeeDialogInfoTabProps> = ({
  employeeById,
  employeeGroups,
  serviceGroups,
  services,
  myPermissions,
  currentBranch,
  isCreate,
  onCreate,
  additionalProps,
  timezone,
  areAllowedManyEmployees,
}) => {
  const { t } = useTranslation()

  const servicesByEmployee: TreeMenuItem[] = employeeById?.serviceEmployees.length
    ? employeeById?.serviceEmployees.map(
        (sE): TreeMenuItem => ({
          ...sE.service,
          parentId: sE.service.groupId ?? undefined,
        }),
      ) ?? []
    : []

  const transformedServices = transformDataForTreeMenu(serviceGroups, services, {
    keepEmptyFolders: false,
  })
  const serviceIdsHashMap = getServiceIdsThatWereUsedWithEmployeeHashMap(employeeById)
  const serviceEmployeesItems = provideDisabledServicesTreeMenuForUsedServices(
    transformedServices,
    serviceIdsHashMap,
    t,
  )

  const servicesByEmployeeWithDisabled = provideDisabledServicesTreeMenuForUsedServices(
    servicesByEmployee,
    serviceIdsHashMap,
    t,
  )

  const initialServices = groupInitialTreeItems(
    servicesByEmployeeWithDisabled,
    serviceEmployeesItems,
  )

  const { data: schedules } = useFetchSchedules()
  const { data: employees } = useFetchExtendedEmployees(timezone, currentBranch.id)

  const { mutateAsync: createMutation } = useCreateEmployee()
  const { mutateAsync: updateMutation } = useUpdateEmployee()

  const { mutateAsync: updateOwnerMutation } = useUpdateOwnerEmployee()
  const { mutateAsync: updateEmailMutation } = useUpdateEmployeeEmail()

  const updatePhoto = useUpdateFile()
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const { formState, control, handleSubmit, setValue, watch } = useForm<EmployeeFormValues>({
    defaultValues: {
      firstName: employeeById?.firstName ?? '',
      middleName: employeeById?.middleName ?? '',
      lastName: employeeById?.lastName ?? '',
      groupId: employeeById?.groupId ?? undefined,
      serviceEmployees: initialServices,
      photo: employeeById?.photo ?? '',
      phone: employeeById?.phone ?? '',
      email: employeeById?.email ?? '',
      birthDate: employeeById?.birthDate ?? undefined,
      gender: employeeById?.gender as Gender,
      scheduleType: SCHEDULE_TYPES.schedule,
      status: employeeById?.status ?? undefined,
      information: employeeById?.information ?? undefined,
      preferredConnections: employeeById ? transformPreferredConnectionsForForms(employeeById) : [],
    },
  })
  const scheduleType = watch('scheduleType')
  const watchedIsDynamicSchedule = getIsScheduleTypeDynamic(scheduleType.id)

  const [openSnackbar] = useSnackbar()

  const { openCreateDialog: openEmployeeGroupCreateDialog, dialog: employeeGroupDialog } =
    useOpenDialog(EmployeeGroupDialog)
  const { openCreateDialog: openServiceCreateDialog, dialog: serviceDialog } =
    useOpenDialog(ServiceDialog)

  const isEmployeeArchived = employeeById?.archived

  const isEmailEditingAllowed = employeeById
    ? myPermissions.includes(permissions.employee.email) && !isEmployeeArchived
    : true

  const isItOwner = Boolean(employeeById?.ownBusiness)

  const filteredEmployeeGroups = getFilteredEmployeesGroups(employeeById, employeeGroups)

  let isEditingAllowed: boolean
  if (isItOwner) {
    isEditingAllowed = myPermissions.includes(onlyOwnersPermissions.owner.set)
  } else {
    isEditingAllowed = myPermissions.includes(permissions.employee.set) && !isEmployeeArchived
  }

  const isServiceEditingAllowed = myPermissions.includes(permissions.service.set)

  const submitMutateEmployee = useCallback<SubmitHandler<EmployeeFormValues>>(
    async data => {
      const checkedPhone = data.phone === DEFAULT_UKR_PHONE_CODE ? null : data.phone

      const mutate = async (data: EmployeeFormValues) => {
        const {
          birthDate,
          serviceEmployees,
          groupId,
          email,
          firstName,
          middleName,
          lastName,
          gender,
          photo,
          scheduleId,
          scheduleType,
          status,
          information,
          preferredConnections,
          scheduleStartDate,
        } = data
        const urlPhoto = await updatePhoto({
          prevFile: employeeById?.photo ?? undefined,
          file: photo,
        })

        const scheduleIsDynamic = getIsScheduleTypeDynamic(scheduleType.id)

        const servicesIds =
          extractItemsFromFolders(serviceEmployees).map(service => service.id) ?? []

        if (isCreate) {
          try {
            const result = await createMutation({
              firstName,
              lastName,
              middleName,
              birthDate: birthDate ?? null,
              gender,
              photo: urlPhoto,
              phone: checkedPhone,
              email: email.length > 0 ? email : null,
              groupId,
              serviceEmployees: {
                data: servicesIds.map(serviceId => ({ serviceId, branchId: currentBranch.id })),
              },
              employeeSchedules: {
                data: [
                  {
                    scheduleId: scheduleIsDynamic ? null : scheduleId,
                    isDynamic: scheduleIsDynamic,
                    branchId: currentBranch.id,
                    startDate: scheduleStartDate,
                  },
                ],
              },
              status,
              information,
            })

            openSnackbar(t('employee.createSuccess'), 'success')
            if (result.insertEmployee?.id)
              onCreate?.(result.insertEmployee.id, { firstName, lastName, groupId })
          } catch (error) {
            if (isRestrictionError(error)) openSnackbar(t('planRestriction'), 'error')
            else if (isPlanError(error)) openSnackbar(t('planInfo.noPlan'), 'error')
            else {
              reportError(error)
              openSnackbar(t('submitError'), 'error')
            }
          }
        } else if (employeeById) {
          const isItOwner = Boolean(employeeById.ownBusiness)
          const employeeId = employeeById.id

          let result: {
            updateEmployeeById?: { id?: number }
            updateEmployeeBusinessOwners?: { affected_rows?: number }
            deleteServiceEmployees?: ServerServiceEmployeeMutationResponse
            insertServiceEmployees?: ServerServiceEmployeeMutationResponse
          }

          const { serviceIdsToInsert, serviceIdsToDelete } = getServiceIdDifferencesInEmployee(
            employeeById,
            servicesIds,
          )

          const preferredConnectionsForMutation =
            transformPreferredConnectionsFormValuesForMutation(preferredConnections)

          if (isItOwner)
            result = await updateOwnerMutation({
              id: employeeId,
              branchId: currentBranch.id,
              employeeSetInput: {
                firstName,
                middleName,
                lastName,
                birthDate: birthDate ?? null,
                gender,
                photo: urlPhoto ?? null,
                phone: checkedPhone,
                status,
                information,
              },
              serviceEmployeeInsertInputs: serviceIdsToInsert.map(serviceId => ({
                serviceId,
                employeeId,
                branchId: currentBranch.id,
              })),
              serviceIdsToDelete,
              employeeSettingsSetInput: { preferredConnections: preferredConnectionsForMutation },
            })
          else
            result = await updateMutation(
              {
                id: employeeId,
                branchId: currentBranch.id,
                employeeSetInput: {
                  firstName,
                  middleName,
                  lastName,
                  birthDate,
                  gender,
                  photo: urlPhoto ?? null,
                  phone: checkedPhone,
                  groupId,
                  status,
                  information,
                },
                serviceEmployeeInsertInputs: serviceIdsToInsert.map(serviceId => ({
                  serviceId,
                  employeeId,
                  branchId: currentBranch.id,
                })),
                serviceIdsToDelete,
                employeeSettingsSetInput: { preferredConnections: preferredConnectionsForMutation },
              },
              {
                onSuccess: () => {
                  if (formState.dirtyFields.groupId)
                    queryClient.invalidateQueries(['employeePermissions', employeeById.id])
                },
              },
            )
          if (myPermissions.includes(permissions.employee.email))
            await updateEmailMutation({ id: employeeId, email: email.length > 0 ? email : null })

          if (
            (isItOwner && result.updateEmployeeBusinessOwners?.affected_rows) ||
            result?.updateEmployeeById?.id
          ) {
            openSnackbar(t('employee.updateSuccess'), 'success')
          } else openSnackbar(t('submitError'), 'error')
        }
        additionalProps.closeDialog()
      }

      const { name: employeeWithSamePhoneNumberFullName, id: employeeWithSamePhoneNumberId } =
        getEmployeeWithSamePhoneNumberFullName(employees ?? [], checkedPhone, employeeById?.id)

      if (employeeWithSamePhoneNumberFullName && data.phone !== employeeById?.phone) {
        showConfirmation({
          title: t('saveConfirmation'),
          description: (
            <EmployeeDuplicateConfirmation
              name={employeeWithSamePhoneNumberFullName}
              id={employeeWithSamePhoneNumberId}
              phone={data.phone ?? ''}
            />
          ),
          onConfirm: () => mutate(data as EmployeeFormValues),
        })
      } else {
        return mutate(data as EmployeeFormValues)
      }
    },
    [
      additionalProps,
      createMutation,
      currentBranch.id,
      employeeById,
      employees,
      formState.dirtyFields.groupId,
      isCreate,
      myPermissions,
      onCreate,
      openSnackbar,
      showConfirmation,
      t,
      updateEmailMutation,
      updateMutation,
      updateOwnerMutation,
      updatePhoto,
    ],
  )

  useAttachFunctionAndFormState<EmployeeFormValues>({
    calledFunction: handleSubmit(submitMutateEmployee),
    formState,
    additionalProps,
  })

  return (
    <>
      <div className="flex justify-between">
        <div className="w-3/4">
          <div className="flex justify-between">
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('lastName')}
                  placeholder={t('placeholders.lastName')}
                  required
                  containerClassName="pr-2 w-1/3"
                  errorMessage={{
                    isShown: Boolean(formState.errors.lastName),
                    text: t('formError.required'),
                  }}
                  value={value}
                  onChange={onChangeWithFirstUpperCase(onChange)}
                  disabled={!isEditingAllowed}
                  autoFocus
                />
              )}
            />

            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('firstName')}
                  placeholder={t('placeholders.firstName')}
                  required
                  errorMessage={{
                    isShown: Boolean(formState.errors.firstName),
                    text: t('formError.required'),
                  }}
                  containerClassName="w-1/3"
                  value={value}
                  onChange={onChangeWithFirstUpperCase(onChange)}
                  disabled={!isEditingAllowed}
                />
              )}
            />

            <Controller
              name="middleName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('middleName')}
                  placeholder={t('placeholders.middleName')}
                  containerClassName="pl-2 w-1/3"
                  value={value}
                  onChange={onChangeWithFirstUpperCase(onChange)}
                  disabled={!isEditingAllowed}
                />
              )}
            />
          </div>

          <div className="flex justify-between">
            <Controller
              control={control}
              name="groupId"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SelectDropdown
                  label={t('category')}
                  items={filteredEmployeeGroups}
                  placeholder={t('placeholders.defaultSelect')}
                  required
                  className="w-1/2"
                  onSelectChange={onChange}
                  value={value ?? undefined}
                  errorMessage={{
                    isShown: Boolean(formState.errors.groupId),
                    text: t('formError.required'),
                  }}
                  disabled={isItOwner ? true : !isEditingAllowed}
                  onPlusClick={
                    !areAllowedManyEmployees || !isEditingAllowed || isEmployeeArchived
                      ? undefined
                      : () => openEmployeeGroupCreateDialog(id => setValue('groupId', id))
                  }
                />
              )}
            />

            <Controller
              name="serviceEmployees"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TreeSelect
                  type="MultiPickMode"
                  items={serviceEmployeesItems}
                  onSelected={onChange}
                  selected={value as TreeMenuItem[]}
                  placeholder={t('choose')}
                  label={t('services')}
                  onPlusClick={
                    isServiceEditingAllowed
                      ? () => {
                          openServiceCreateDialog((id, dto) => {
                            if (dto) {
                              const newService: TreeMenuItem = {
                                id,
                                name: dto.name,
                                parentId: dto.groupId,
                              }
                              const newValue = value ? [...value, newService] : [newService]
                              onChange(newValue)
                            }
                          })
                        }
                      : undefined
                  }
                  className="pl-2 w-1/2"
                  disabled={!isEditingAllowed}
                />
              )}
            />
          </div>
        </div>

        <Controller
          name="photo"
          control={control}
          render={({ field: { onChange, value } }) => (
            <EditablePhoto
              defaultPhoto={value}
              onChange={onChange}
              size="big"
              containerClassName="ml-6 grow flex-centered"
              disabled={!isEditingAllowed}
            />
          )}
        />
      </div>

      <div className="flex w-full">
        <div className="flex w-3/4">
          <Controller
            name="phone"
            control={control}
            rules={{
              validate: value =>
                value !== DEFAULT_UKR_PHONE_CODE && value ? checkValidPhone(value) : true,
            }}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                label={t('phone')}
                errorMessage={{
                  isShown: Boolean(formState.errors.phone),
                  text: showPhoneErrorMessage(formState.errors.phone?.type ?? '', t),
                }}
                placeholder={PLACEHOLDERS.phone}
                value={value ?? ''}
                onChange={onChange}
                containerClassName="w-1/3 mr-2"
                disabled={!isEditingAllowed}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            rules={{
              validate: {
                // TODO: какой правильный?
                validateEmployeeEmail: email =>
                  validateEmployeeEmail(email, employees, employeeById?.email ?? undefined),
                validateEmail: email => (email ? validateEmail(email) : true),
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                containerClassName="w-1/3 mr-2"
                placeholder={t('placeholders.email')}
                value={value}
                label={t('email')}
                disabled={!isEditingAllowed || !isEmailEditingAllowed}
                onChange={onChange}
                errorMessage={{
                  isShown: Boolean(error),
                  text: generateEmailErrorMessage(error?.type, t),
                }}
              />
            )}
          />

          {!isCreate && (
            <Controller
              name="preferredConnections"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectPreferredConnections
                  className="w-1/3"
                  onChange={onChange}
                  value={value}
                  employeeById={employeeById}
                  disabled={!isEditingAllowed}
                />
              )}
            />
          )}
        </div>

        <Controller
          control={control}
          name="birthDate"
          render={({ field: { onChange, value } }) => (
            <DateTimePicker
              timezone={timezone}
              className="ml-6"
              label={t('birthDate')}
              value={value}
              onChange={onChange}
              type="date"
              disabled={!isEditingAllowed}
              isClearable
            />
          )}
        />
      </div>

      <div className="flex w-full">
        <div className="flex w-3/4">
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <Textarea
                value={value}
                onChange={onChange}
                placeholder={t('placeholders.employeeStatus')}
                disabled={!isEditingAllowed}
                label={t('status')}
                containerClassName="w-1/2 mr-2"
                hint={t('employeeStatus')}
              />
            )}
          />

          <Controller
            control={control}
            name="information"
            render={({ field: { onChange, value } }) => (
              <Textarea
                value={value}
                onChange={onChange}
                placeholder={t('placeholders.employeeInformation')}
                disabled={!isEditingAllowed}
                label={t('information')}
                containerClassName="w-1/2"
                convertDashToDots
                hint={t('employeeInfo')}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="gender"
          render={({ field: { onChange, value } }) => (
            <div className="w-1/4 flex justify-center">
              <GenderPicker
                containerClassName="mt-4"
                value={value}
                onChange={onChange}
                disabled={!isEditingAllowed}
              />
            </div>
          )}
        />
      </div>

      {isCreate && (
        <div className="flex gap-2 mt-3">
          <div className={'w-1/3'}>
            <Controller
              name="scheduleType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroupButtons
                  label={t('schedule.type.name')}
                  options={translateData(Object.values(SCHEDULE_TYPES), t)}
                  value={value}
                  onChange={option => {
                    onChange(option)

                    const isScheduleTypeDynamic = getIsScheduleTypeDynamic(option.id)
                    if (isScheduleTypeDynamic) setValue('scheduleId', undefined)
                  }}
                  disabled={!isCreate}
                />
              )}
            />
          </div>

          {!watchedIsDynamicSchedule && (
            <Controller
              name="scheduleId"
              control={control}
              rules={{
                required: !watchedIsDynamicSchedule,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <SelectDropdown
                  value={value}
                  items={schedules}
                  label={t('schedule.name')}
                  placeholder={t('chooseTemplate')}
                  noDataMessage={t('noSchedule')}
                  onSelectChange={onChange}
                  className="w-1/3"
                  required={!watchedIsDynamicSchedule}
                  errorMessage={{
                    isShown: Boolean(error),
                    text: t('formError.required'),
                  }}
                />
              )}
            />
          )}

          <Controller
            name="scheduleStartDate"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DateTimePicker
                timezone={timezone}
                required
                label={t('startDate')}
                value={value}
                type="date"
                onChange={date => {
                  onChange(date)
                }}
                className="w-1/3"
                disabled={!isCreate}
                errorMessage={{
                  isShown: Boolean(error),
                  text: t('formError.required'),
                }}
              />
            )}
          />
        </div>
      )}

      {employeeGroupDialog}
      {confirmationModal}
      {serviceDialog}
    </>
  )
}

export type EmployeeFormValues = {
  firstName: string
  middleName: string
  lastName: string
  groupId: number
  phone?: string
  photo?: string
  email: string
  birthDate?: Date
  gender?: Gender
  serviceEmployees: SelectDropDownItem[]
  scheduleId: number | undefined
  scheduleType: { id: number; name: string }
  scheduleStartDate: Date
  status: string | undefined
  information: string
  preferredConnections: ExtendedSelectDropdownItem[]
}
