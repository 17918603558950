import { gql } from 'graphql-request'
import { reportError, reportGqlError, request, useQuery, useQueryOriginal } from '../../api'
import {
  ServerBriefClientDocumentType,
  ServerClientDocumentType,
} from '../../generated/graphql-types'
import { BRIEF_CLIENT_DOCUMENTS_QUERY_KEY, CLIENT_DOCUMENTS_QUERY_KEY } from './queryKeys'
import { briefClientDocumentFragment, clientDocumentFragment } from './clientDocument.fragments'
import { parseDatesInClientDocumentGqlResponse } from '../client/logic'
import { DEFAULT_TIMEZONE } from '@expane/date'

export function useFetchBriefClientDocuments(
  clientId: number | undefined | null,
  timezone: string | undefined,
) {
  return useQueryOriginal(
    [CLIENT_DOCUMENTS_QUERY_KEY, BRIEF_CLIENT_DOCUMENTS_QUERY_KEY, { clientId }],
    async (): Promise<ServerBriefClientDocumentType[]> => {
      const result = await request(
        gql`
          ${briefClientDocumentFragment}
          query ($clientId: Int!) {
            clientDocuments(
              order_by: { createdAt: desc }
              where: { clientId: { _eq: $clientId } }
            ) {
              ...briefClientDocumentType
            }
          }
        `,
        { clientId },
      )

      if (Array.isArray(result?.clientDocuments)) {
        return result.clientDocuments.map(cD =>
          parseDatesInClientDocumentGqlResponse(cD, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('clientDocuments is not an array'), 'warning', { result })
        return []
      }
    },
    { onError: reportGqlError, enabled: Boolean(clientId) && Boolean(timezone) },
  )
}

export function useFetchClientDocumentsByClientId(clientId: number, timezone: string | undefined) {
  return useQuery(
    [CLIENT_DOCUMENTS_QUERY_KEY, { clientId }],
    async (): Promise<ServerClientDocumentType[]> => {
      const result = await request(
        gql`
          ${clientDocumentFragment}
          query ($clientId: Int!) {
            clientDocuments(
              order_by: { createdAt: desc }
              where: { clientId: { _eq: $clientId } }
            ) {
              ...clientDocumentType
            }
          }
        `,
        { clientId },
      )

      if (Array.isArray(result?.clientDocuments)) {
        return result.clientDocuments.map(cD =>
          parseDatesInClientDocumentGqlResponse(cD, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('clientDocuments is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchClientDocumentsByClientId',
      onError: reportGqlError,
      enabled: Boolean(timezone),
    },
  )
}

export function useFetchClientDocumentsById(id: number | undefined, timezone: string | undefined) {
  return useQuery(
    [CLIENT_DOCUMENTS_QUERY_KEY, id],
    async (): Promise<ServerClientDocumentType | undefined> => {
      const result = await request(
        gql`
          ${clientDocumentFragment}
          query ($id: Int!) {
            clientDocumentById(id: $id) {
              ...clientDocumentType
            }
          }
        `,
        { id },
      )

      if (result?.clientDocumentById) {
        return parseDatesInClientDocumentGqlResponse(
          result.clientDocumentById,
          timezone ?? DEFAULT_TIMEZONE,
        )
      } else {
        reportError(new Error('clientDocumentById is not an array'), 'warning', { result, id })
        return undefined
      }
    },
    {
      queryName: 'useFetchClientDocumentsById',
      enabled: Boolean(id) && Boolean(timezone),
      onError: reportGqlError,
    },
  )
}
