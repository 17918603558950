import { ServerPlanType } from '@expane/data'
import { FEATURE_FLAGS, useFeatureFlags } from '@expane/logic/featureFlags'
import { Button, Paper } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCashOutline, IoCheckmarkCircleOutline, IoDuplicateOutline } from 'react-icons/io5'

export const BillingPlanCard: FC<{
  plan: ServerPlanType
  selected: boolean
  onClick: () => void
  onExtend?: () => void
  disabled?: boolean
  type?: 'buy' | 'choose'
}> = ({ plan, selected, onClick, onExtend, type = 'buy', disabled = false }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { getFeatureFlag } = useFeatureFlags()
  const isEnableCheckBillingTurnover = getFeatureFlag(FEATURE_FLAGS.enableCheckBillingTurnover)

  let maxTurnover: string | undefined

  if (isEnableCheckBillingTurnover)
    maxTurnover = plan.maxTurnover
      ? `${t('plan.maxTurnover')} ${new Intl.NumberFormat(language, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: 'currency',
          // TODO: В інших країнах треба перераховувати по курсу???
          currency: 'UAH',
        }).format(plan.maxTurnover)}`.replace('UAH', '₴')
      : t('plan.unlimitedTurnover')

  return (
    <>
      <Paper className="overflow-hidden">
        <div className={'flex-centered text-xl font-medium mt-4 ' + getPlanTextColor(plan.name)}>
          {t('plan.' + plan.name)}
        </div>
        <div className="text-billing-secondary-color text-2xl font-medium text-center">
          {plan.monthlyCost}
          <span className=" text-base">/{t('calendarPeriod.month').toLowerCase()}</span>
        </div>
        <div className="flex-centered h-12 my-4">
          {selected && !onExtend && (
            <IoCheckmarkCircleOutline size="2rem" className="text-primary-400" />
          )}
          {selected && onExtend && (
            <Button
              disabled={disabled}
              className="ml-2"
              onClick={onExtend}
              Icon={IoDuplicateOutline}
            >
              {t('extend')}
            </Button>
          )}
          {!selected && (
            <Button
              disabled={disabled}
              onClick={onClick}
              Icon={type === 'buy' ? IoCashOutline : undefined}
            >
              {t(type)}
            </Button>
          )}
        </div>
      </Paper>
      <ul className="list-disc ml-6 mr-4 mt-4 text-main-color">
        <li>
          {plan.maxEmployees
            ? t('plan.maxEmployees', { maxEmployees: plan.maxEmployees })
            : t('plan.unlimitedEmployees')}
        </li>
        {isEnableCheckBillingTurnover && <li>{maxTurnover}</li>}
      </ul>
    </>
  )
}

const getPlanTextColor = (planName: string) => {
  if (planName === 's') return 'text-primary-300'
  if (planName === 'm') return 'text-primary-400'

  return 'text-primary-600'
}
