import {
  ClientBriefWithTagType,
  useFetchClients,
  useFetchClientsPhones,
  useFetchCurrentBranchTimezone,
  useFetchLeads,
} from '@expane/data'
import { addPhonesToClients, ClientWithPhones, useGetActiveClients } from '@expane/logic/client'
import { permissions } from '@expane/logic/permission'
import { EmptyPlaceholder, Page, SelectDropdown } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useGetLostClients, useGetPotentialClients } from 'logic/clients'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { usePrintWrapper } from 'logic/hooks/usePrintWrapper'
import { translateData } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoPeopleOutline, IoPrintOutline } from 'react-icons/io5'
import { store } from 'store'
import { ButtonWithBillingCheck, SaveButton } from 'widgets/Buttons'
import { ClientDialog } from 'widgets/ClientDialog'
import { ArchiveListNavigationButton } from 'widgets/NavigationButtons'
import { ClientsPageList } from './List'

export const ClientsPage: FC = observer(() => {
  const branchId = store.branch.branchId

  const { data: myPermissions } = useFetchMyPermissions()
  const { data: leads } = useFetchLeads()
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clients, isLoading: clientsIsLoading } = useFetchClients(
    timezone,
    branchId,
    'always',
  )
  const { data: clientsPhones, isLoading: clientsPhonesIsLoading } = useFetchClientsPhones('always')

  const [leadId, setLeadId] = useState<number | undefined>(undefined)
  const [involvementId, setInvolvementId] = useState<number | undefined>(undefined)

  const { t } = useTranslation()

  const { openCreateDialog, dialog } = useOpenDialog(ClientDialog)
  const { PrintWrapper, onPrint } = usePrintWrapper(t('clientList'))

  const isAddingAllowed = myPermissions?.includes(permissions.client.set)

  const { activeClientsIds, activeClientsLoading } = useGetActiveClients(
    timezone,
    branchId,
    Boolean(involvementId),
  )
  const { lostClientsIds, lostClientsLoading } = useGetLostClients(
    Boolean(involvementId),
    timezone,
    branchId,
  )
  const { potentialClientsIds, potentialClientsLoading } = useGetPotentialClients(
    Boolean(involvementId),
    timezone,
    branchId,
    clients,
  )

  const isInvolvementGetAllowed = myPermissions?.includes(permissions.transaction.get)
  const isSendMessageAllowed = Boolean(myPermissions?.includes(permissions.clientMessage.set))
  const isViewClientPhonesAllowed = Boolean(myPermissions?.includes(permissions.client.phone))

  const filteredClients = useMemo<ClientWithPhones<ClientBriefWithTagType>[]>(() => {
    if (!clients) return []

    const clientsWithPhones = addPhonesToClients(clients, clientsPhones)

    const filteredByLead: ClientWithPhones<ClientBriefWithTagType>[] = leadId
      ? clientsWithPhones.filter(c => c.leadId === leadId)
      : [...clientsWithPhones]

    const filteredByInvolvement: ClientWithPhones<ClientBriefWithTagType>[] =
      involvementId && isInvolvementGetAllowed
        ? getFilteredClientsByInvolvement({
            involvementId,
            clients: filteredByLead,
            activeClientsIds,
            lostClientsIds,
            potentialClientsIds,
          })
        : [...filteredByLead]

    return filteredByInvolvement
  }, [
    clients,
    clientsPhones,
    leadId,
    involvementId,
    isInvolvementGetAllowed,
    activeClientsIds,
    lostClientsIds,
    potentialClientsIds,
  ])

  const isLoadingWithFilters =
    Boolean(involvementId) &&
    (activeClientsLoading || lostClientsLoading || potentialClientsLoading)
  const isLoading = isLoadingWithFilters || clientsIsLoading || clientsPhonesIsLoading

  return (
    <Page className="overflow-hidden">
      <div className="flex item-center justify-between mb-2">
        <div className="flex item-center">
          {isAddingAllowed && (
            <SaveButton onClick={() => openCreateDialog()} className="mr-2 self-start" isCreate />
          )}

          {clients && clients.length > 0 && (
            <>
              <SelectDropdown
                items={leads ?? []}
                value={leadId}
                placeholder={t('filter.lead')}
                noDataMessage={t('noSuchLead')}
                className="w-64 mr-2"
                onSelectChange={value => {
                  if (value) {
                    setLeadId(value)
                  } else {
                    setLeadId(undefined)
                  }
                }}
                isClearable
                isFilter
              />
              {isInvolvementGetAllowed && (
                <SelectDropdown
                  items={translateData(Object.values(CLIENTS_INVOLVEMENTS), t)}
                  value={involvementId}
                  placeholder={t('filter.involvement')}
                  noDataMessage={t('noSuchInvolvement')}
                  className="w-64 mr-2"
                  onSelectChange={value => {
                    if (value) {
                      setInvolvementId(value)
                    } else {
                      setInvolvementId(undefined)
                    }
                  }}
                  isClearable
                  isFilter
                />
              )}
            </>
          )}
        </div>
        <div className="flex item-center">
          {clients && clients.length > 0 && (
            <ButtonWithBillingCheck
              type="outline"
              className="w-38 mr-2"
              onClick={onPrint}
              twoLines
              disabled={!isLoading && filteredClients.length === 0}
              Icon={IoPrintOutline}
            >
              {t('print.clientList')}
            </ButtonWithBillingCheck>
          )}

          {isAddingAllowed && <ArchiveListNavigationButton className="ml-auto" />}
        </div>
      </div>

      <div className="h-[92%] flex flex-col justify-between pb-1">
        {filteredClients.length === 0 && clients?.length !== 0 && !isLoading ? (
          <EmptyPlaceholder Icon={IoPeopleOutline} text={t('emptyPlaceholder.archive')} />
        ) : (
          <ClientsPageList
            clientsForPage={filteredClients}
            isLoading={isLoading}
            PrintWrapper={PrintWrapper}
            isSendMessageAllowed={isSendMessageAllowed}
            isViewPhonesAllowed={isViewClientPhonesAllowed}
          />
        )}
      </div>

      {dialog}
    </Page>
  )
})

type Involvement = {
  id: number
  name: string
  hint: string
}

type ClientInvolvements = {
  potential: Involvement
  active: Involvement
  lost: Involvement
}

export const CLIENTS_INVOLVEMENTS: ClientInvolvements = {
  potential: {
    id: 1,
    name: 'clientInvolvements.potential',
    hint: 'clientInvolvements.potentialDescription',
  },
  active: {
    id: 2,
    name: 'clientInvolvements.active',
    hint: 'clientInvolvements.activeDescription',
  },
  lost: {
    id: 3,
    name: 'clientInvolvements.lost',
    hint: 'clientInvolvements.lostDescription',
  },
}

const getFilteredClientsByInvolvement = (params: {
  involvementId: number
  clients: ClientWithPhones<ClientBriefWithTagType>[]
  activeClientsIds: Array<string>
  lostClientsIds: Array<string>
  potentialClientsIds: Array<string>
}): ClientWithPhones<ClientBriefWithTagType>[] => {
  const { involvementId, clients, activeClientsIds, lostClientsIds, potentialClientsIds } = params

  if (involvementId === CLIENTS_INVOLVEMENTS.potential.id)
    return clients.filter(({ id }) => potentialClientsIds.some(clientId => id === Number(clientId)))

  if (involvementId === CLIENTS_INVOLVEMENTS.active.id) {
    return clients.filter(({ id }) => activeClientsIds.some(clientId => id === Number(clientId)))
  } else return clients.filter(({ id }) => lostClientsIds.some(clientId => id === Number(clientId)))
}
