import {
  useFetchBookingsByIds,
  useFetchCardsByClientId,
  useFetchClientById,
  useFetchCurrentBranchTimezone,
  useFetchProductsForConsumables,
  useGetBranchDefaultAccountId,
} from '@expane/data'
import { PAYMENT_OPTIONS } from '@expane/logic/payment'
import { BookingPaymentData } from '@expane/logic/payment/booking'
import { prepareConsumablesDto } from '@expane/logic/payment/booking/consumables'
import { prepareServicesDto } from '@expane/logic/payment/booking/services'
import { transformPersonName, translateItemsName } from '@expane/logic/utils'
import { Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { FC, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { BookingPaymentDialogBody } from './Body'
import { FooterPaymentDialogFooter } from './Footer'
import { DialogPlaceholder } from './Placeholder'
import { DialogProps } from 'logic/hooks/useOpenDialog'

interface PaymentDialogProps extends Pick<DialogProps, 'closeDialog'> {
  bookingsIds: number[]
  clientId: number
}

const BookingPaymentDialog: FC<PaymentDialogProps> = props => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { isLoading: isBookingLoading, isRefetching: areBookingsRefetching } =
    useFetchBookingsByIds(props.bookingsIds, timezone, branchId)
  const { isLoading: isClientLoading } = useFetchClientById(props.clientId, timezone)
  const { isLoading: areProductsLoading } = useFetchProductsForConsumables(branchId)
  const { isLoading: isDefaultAccountLoading } = useGetBranchDefaultAccountId(branchId)
  const { isLoading: isClientCardsLoading } = useFetchCardsByClientId(
    props.clientId,
    timezone,
    branchId,
  )
  const isLoading =
    isBookingLoading ||
    isClientLoading ||
    areProductsLoading ||
    isDefaultAccountLoading ||
    areBookingsRefetching ||
    isClientCardsLoading ||
    !timezone

  return (
    <Modal>
      {isLoading ? (
        <DialogPlaceholder close={props.closeDialog} />
      ) : (
        <LoadedBookingPaymentDialog {...props} timezone={timezone} />
      )}
    </Modal>
  )
}

const LoadedBookingPaymentDialog: FC<
  PaymentDialogProps & {
    timezone: string
  }
> = ({ closeDialog, bookingsIds, clientId, timezone }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId
  const { data: bookings } = useFetchBookingsByIds(bookingsIds, timezone, branchId)
  const { data: clientCards } = useFetchCardsByClientId(clientId, timezone, branchId)
  const { data: products } = useFetchProductsForConsumables(branchId)
  const { data: defaultAccountId } = useGetBranchDefaultAccountId(branchId)

  const paymentOptions = translateItemsName(PAYMENT_OPTIONS, t)
  const { formState, control, setValue, handleSubmit, clearErrors } = useForm<BookingPaymentData>({
    defaultValues: {
      paymentToAccounts: [],
      paymentAmount: '',
      keepChangeOnClientAccount: false,
      servicesDto: prepareServicesDto(bookings, clientId, clientCards, timezone),
      consumablesDto: prepareConsumablesDto(bookings, products),
      paymentOption: paymentOptions[0],
      accountId: defaultAccountId,
    },
  })
  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const employee = bookings?.length ? bookings[0].employee : undefined
  const employeeFullName = employee ? transformPersonName(employee) : undefined

  return (
    <Dialog>
      <Dialog.Title>{t('payment.name')}</Dialog.Title>
      <BookingPaymentDialogBody
        control={control}
        clientId={clientId}
        employeeFullName={employeeFullName}
        setValue={setValue}
        clearErrors={clearErrors}
      />
      <FooterPaymentDialogFooter
        bookingIds={bookingsIds}
        bookings={bookings}
        clientId={clientId}
        control={control}
        handleSubmit={handleSubmit}
        closeDialog={closeDialog}
        closePopups={closePopups}
        timezone={timezone}
      />
      {confirmPopup}
    </Dialog>
  )
}

export const useBookingPaymentDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogOnClose = useRef<(() => void) | undefined>(undefined)
  const props = useRef<Omit<PaymentDialogProps, 'closeDialog'>>()

  const openPaymentDialog = ({
    clientId,
    bookingsIds,
    onClose,
  }: Omit<PaymentDialogProps, 'closeDialog'> & {
    onClose?: () => void
  }) => {
    props.current = { bookingsIds, clientId }
    dialogOnClose.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (dialogOnClose.current) dialogOnClose.current()
  }

  const paymentDialog =
    isOpen && props.current ? (
      <BookingPaymentDialog closeDialog={closeDialog} {...props.current} />
    ) : null

  return {
    openPaymentDialog,
    paymentDialog,
  }
}
