import {
  CardType,
  ServerClientBriefType,
  useActivateClientCardById,
  useFetchClientsBriefs,
  useFetchCurrentBranchTimezone,
  useFetchTransactionById,
  useUpdateClientCardById,
} from '@expane/data'
import { addDays, createCurrentDate, differenceInDays, isBefore, startOfDay } from '@expane/date'
import {
  checkIsFreeServicesInSubscription,
  getActivateSubscriptionWarningMessage,
  getColorBySubscriptionStatus,
  getIsCardEndless,
  getIsSubscriptionReRegisteredByTransactionId,
  getStatusSubscription,
  SUBSCRIPTION_STATUS,
} from '@expane/logic/cards'
import { transformPersonName } from '@expane/logic/utils'
import {
  Button,
  CloseButton,
  Dialog,
  InputLabel,
  Modal,
  RadioGroupButtons,
  SelectDropdown,
  usePopupOpenState,
  useShowConfirmationPopup,
} from '@expane/ui'
import { DateTimePicker, useSnackbar } from '@expane/widgets'
import { transformPersonsForSelect, translateData } from 'logic/utils'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoArrowUndoOutline } from 'react-icons/io5'
import { store } from 'store'
import { ApplyButton } from 'widgets/Buttons'
import { useOpenTransactionSubscriptionRefundDialog } from 'widgets/TransactionSubscriptionDialog/RefundDialog'

interface ClientCardType extends Omit<CardType, 'clientId'> {
  clientId: number
}

const SubscriptionOptionsDialog: FC<{
  id: number
  clientSubscriptions: CardType[]
  closeDialog: () => void
}> = ({ id, clientSubscriptions, closeDialog }) => {
  const { t } = useTranslation()
  const cardById = clientSubscriptions.find(card => card.id === id) as ClientCardType
  const transactionPaymentId = cardById.transactionCards[0].transactionId

  const { activatedAt, clientId, cardPeriod, canceledDate, cardTemplate } = cardById

  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clients } = useFetchClientsBriefs(branchId, timezone)
  const { mutateAsync: updateClientCard } = useUpdateClientCardById()
  const { mutateAsync: activateClientSubscriptionById } = useActivateClientCardById()

  const optionsValues = Object.values(options)

  const [currentButton, setCurrentButton] = useState<{ id: number; name: string }>(options.activate)
  const Component = optionsValues.find(option => option.id === currentButton.id)?.component

  // если абонемент активирован то в стейт записываем дату окончания абонемента. То есть день активации + срок действия
  const endDate = activatedAt ? addDays(activatedAt, cardPeriod) : undefined

  const dateBySubscriptionStatus = activatedAt ? endDate : createCurrentDate(timezone)

  const [date, setDate] = useState<Date | undefined>(dateBySubscriptionStatus)

  const [openSnackbar] = useSnackbar()

  const mutateClientCard = async (clientId: number) => {
    const newCardPeriod =
      endDate && date
        ? cardPeriod + differenceInDays(startOfDay(date), startOfDay(endDate))
        : cardPeriod

    const result = await updateClientCard({
      cardId: id,
      clientId,
      cardPeriod: newCardPeriod,
    })

    if (result?.updateCardById) {
      openSnackbar(t('operation.completedSuccessfully'), 'success')
    } else openSnackbar(t('submitError'), 'error')
  }

  const mutateActivateClientCard = async () => {
    if (!date) {
      openSnackbar(t('submitError'), 'error')

      return
    }

    const result = await activateClientSubscriptionById({
      cardId: id,
      activatedAt: date,
    })

    if (result?.updateCardById) {
      openSnackbar(t('operation.completedSuccessfully'), 'success')
    } else openSnackbar(t('submitError'), 'error')
  }

  const handleButtonsChange = (option: { id: number; name: string }) => {
    // нужно, так как если не применить изменения, будучи на вкладке "переоформления"
    // в стейте останется выбранный клиент и появляются баги
    if (currentButton.id === options.reRegister.id) {
      setCurrentButton(option)
    } else setCurrentButton(option)
  }

  const cardStatus = getStatusSubscription({
    activatedAt,
    canceledDate,
    cardPeriod,
    isFreeServicesInSubscription: checkIsFreeServicesInSubscription(cardById),
    timezone,
  })

  const translatedCardStatus = t(cardStatus)

  const activated = cardStatus === SUBSCRIPTION_STATUS.activated
  const ended = cardStatus === SUBSCRIPTION_STATUS.ended
  const refunded = cardStatus === SUBSCRIPTION_STATUS.refunded
  const isEndless = getIsCardEndless(cardTemplate.cardPeriod)

  const subscriptionStyle = getColorBySubscriptionStatus(translatedCardStatus)

  if (!clients || !timezone) return null

  return (
    <>
      <Modal close={closeDialog}>
        <Dialog>
          <Dialog.Title>
            <div className="flex justify-between items-center">
              {cardById.cardTemplate.name}

              <div>
                <p className="text-sm leading-3 font-medium text-gray-400 mb-2">
                  {`${t('status')}: `}
                  <span
                    className={'text-base text-gray-600 dark:text-gray-300 ' + subscriptionStyle}
                  >
                    {translatedCardStatus}
                  </span>
                </p>
              </div>
            </div>
          </Dialog.Title>
          <Dialog.Body className="w-192">
            {ended || refunded ? (
              <p className="text-sm text-center text-main-color">
                {t('subscriptionsOptions.noOperationForWorking')}
              </p>
            ) : (
              <div className="flex">
                <div className="w-2/5 mr-4">
                  <RadioGroupButtons
                    label={t('operation.name')}
                    options={translateData(optionsValues, t)}
                    value={currentButton}
                    onChange={option => handleButtonsChange(option)}
                  />
                </div>

                <div className="w-3/5">
                  {Component && (
                    <Component
                      timezone={timezone}
                      date={date}
                      onChange={setDate}
                      clientId={clientId}
                      clients={clients}
                      activated={activated}
                      activatedAt={activatedAt}
                      mutateClientCard={mutateClientCard}
                      mutateActivateClientCard={mutateActivateClientCard}
                      closeDialog={closeDialog}
                      transactionPaymentId={transactionPaymentId}
                      cardId={id}
                      endDate={endDate}
                      isEndless={isEndless}
                    />
                  )}
                </div>
              </div>
            )}
          </Dialog.Body>

          <Dialog.Footer>
            <CloseButton onClick={closeDialog} />
          </Dialog.Footer>
        </Dialog>
      </Modal>
    </>
  )
}

const ActivateSubscription: FC<{
  activatedAt?: Date | undefined | null
  activated: boolean
  date: Date | undefined
  timezone: string
  onChange: (date: Date) => void
  mutateActivateClientCard: () => void
  closeDialog: () => void
}> = ({
  date,
  activatedAt,
  activated,
  onChange,
  mutateActivateClientCard,
  closeDialog,
  timezone,
}) => {
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const { t } = useTranslation()

  const message = getActivateSubscriptionWarningMessage(activatedAt, timezone)

  const onSubmit = () =>
    showConfirmation({
      title: t('subscriptionsOptions.activation'),
      description: `${t('subscriptionsOptions.doYouWantToActivateSubscript')}?`,
      onConfirm: () => {
        mutateActivateClientCard()
        closeDialog()
      },
    })

  return (
    <>
      {!activated ? (
        <div>
          <InputLabel label={t('subscriptionsOptions.activateFrom')} />
          <div className="flex">
            <DateTimePicker
              timezone={timezone}
              onChange={data => {
                onChange(data)
              }}
              value={date}
              nextPreviousButtons
              className="w-3/5"
            />

            <ApplyButton type="outline" className="ml-2 w-2/5" onClick={onSubmit} />
          </div>
        </div>
      ) : (
        <p className={textStyle}>{t(message)}</p>
      )}
      {confirmationModal}
    </>
  )
}

const RenewSubscription: FC<{
  date: Date | undefined
  onChange: (date: Date) => void
  activated: boolean
  mutateClientCard: (clientId: number) => void
  clientId: number
  timezone: string
  closeDialog: () => void
  endDate: Date | undefined
  isEndless: boolean
}> = ({
  date,
  clientId,
  onChange,
  activated,
  mutateClientCard,
  closeDialog,
  timezone,
  endDate,
  isEndless,
}) => {
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const onSubmit = () => {
    if (date && endDate && isBefore(date, endDate)) {
      openSnackbar(t('subscriptionsOptions.renewalExpirationDateError'), 'error')
    } else
      showConfirmation({
        title: t('subscriptionsOptions.renewal'),
        description: `${t('subscriptionsOptions.doYouWantToRenewSubscript')}?`,
        onConfirm: () => {
          mutateClientCard(clientId)
          closeDialog()
        },
      })
  }

  return (
    <>
      {isEndless ? (
        <p className={textStyle}>{t('subscriptionsOptions.renewalExpirationEndlessError')}</p>
      ) : activated ? (
        <div>
          <InputLabel label={t('subscriptionsOptions.endDate')} />
          <div className="flex">
            <DateTimePicker
              timezone={timezone}
              onChange={data => {
                onChange(data)
              }}
              value={date}
              nextPreviousButtons
              className="w-3/5"
            />

            <ApplyButton type="outline" className="ml-2 w-2/5" onClick={onSubmit} />
          </div>
        </div>
      ) : (
        <p className={textStyle}>{t('subscriptionsOptions.unactivatedSubscriptCannotBeRenewed')}</p>
      )}
      {confirmationModal}
    </>
  )
}

const ReRegisterSubscription: FC<{
  clients: ServerClientBriefType[]
  clientId: number
  mutateClientCard: (clientId: number) => void
  closeDialog: () => void
}> = ({ clientId, clients, mutateClientCard, closeDialog }) => {
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const [newClientId, setNewClientId] = useState<number | null>(clientId)

  const { t } = useTranslation()

  const onSubmit = () => {
    const currentClient = clients.find(client => client.id === newClientId)
    const clientFullName = currentClient ? transformPersonName(currentClient) : ''

    showConfirmation({
      title: t('subscriptionsOptions.re-registrationSubscript'),
      description: `${t(
        'subscriptionsOptions.doYouWantToReRegisterSubscriptTo',
      )} ${clientFullName}?`,
      onConfirm: () => {
        if (newClientId) mutateClientCard(newClientId)
        closeDialog()
      },
    })
  }

  return (
    <div>
      <InputLabel label={t('client.name')} />

      <div className="flex">
        <SelectDropdown
          items={transformPersonsForSelect(clients)}
          onSelectChange={setNewClientId}
          value={newClientId}
          className="w-3/5"
          popupHeight="small"
        />

        <ApplyButton
          type="outline"
          className="ml-2 w-2/5"
          onClick={onSubmit}
          disabled={clientId === newClientId}
        />
      </div>
      {confirmationModal}
    </div>
  )
}

const RefundSubscription: FC<{
  closeDialog: () => void
  transactionPaymentId: number
  clientId?: number
  cardId: number
}> = ({ closeDialog, clientId, transactionPaymentId, cardId }) => {
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: transactionById } = useFetchTransactionById(
    transactionPaymentId,
    timezone,
    branchId,
  )

  const { openTransactionSubscriptionRefundDialog, transactionSubscriptionRefundDialog } =
    useOpenTransactionSubscriptionRefundDialog()

  const { t } = useTranslation()

  if (transactionById === undefined || !transactionById.client) return null

  const cardOwner = transformPersonName(transactionById.client)
  const isReRegistered = getIsSubscriptionReRegisteredByTransactionId(transactionById, clientId)

  return (
    <div>
      {!isReRegistered ? (
        <>
          <InputLabel label={t('transaction.creatingRefund')} />
          <Button
            type="outline"
            onClick={() =>
              openTransactionSubscriptionRefundDialog({
                onSuccess: closeDialog,
                transactionId: transactionPaymentId,
                subscriptionId: cardId,
              })
            }
            Icon={IoArrowUndoOutline}
          >
            {t('create')}
          </Button>
        </>
      ) : (
        <div className="text-sm font-medium my-3 text-main-color">
          {`${t('subscriptionsOptions.refundIsNotPossible')}: ${cardOwner}`}
        </div>
      )}

      {transactionSubscriptionRefundDialog}
    </div>
  )
}

export const useOpenSubscriptionOptionsDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogId = useRef<number | undefined>()
  const dialogClientSubscriptions = useRef<CardType[]>()

  const openEditDialog = ({
    id,
    clientSubscriptions,
  }: {
    id: number
    clientSubscriptions: CardType[]
  }) => {
    dialogId.current = id
    dialogClientSubscriptions.current = clientSubscriptions

    openPopup()
  }

  const dialog =
    isOpen && dialogId.current && dialogClientSubscriptions.current ? (
      <SubscriptionOptionsDialog
        closeDialog={closePopup}
        id={dialogId.current}
        clientSubscriptions={dialogClientSubscriptions.current}
      />
    ) : null

  return { openEditDialog, dialog }
}

const options = {
  activate: { id: 1, name: 'subscriptionsOptions.activate', component: ActivateSubscription },
  renew: {
    id: 2,
    name: 'subscriptionsOptions.renewalExpirationDate',
    component: RenewSubscription,
  },
  reRegister: {
    id: 3,
    name: 'subscriptionsOptions.re-register',
    component: ReRegisterSubscription,
  },
  createRefund: { id: 4, name: 'transaction.createRefund', component: RefundSubscription },
}

const textStyle = 'text-sm font-medium my-3 text-main-color'
