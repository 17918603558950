import {
  ServerTransactionBriefWithBookingLocationType,
  ServerTransactionType,
  TRANSACTION_TYPES,
} from '@expane/data'
import { checkIsRefundTransaction } from '@expane/logic/finances/filters'

// типы отфильтрованных транзакций
export type ServerTransactionWithBookings = ServerTransactionType &
  Required<Pick<ServerTransactionType, 'bookingId'>>

// транзакции всех доходов по конкретному счету
export const getTransactionsWithAllTypesRevenueOnAccount = (
  id: number,
  transactions?: ServerTransactionType[],
) => transactions?.filter(t => t.toAccountId === id && t.type !== TRANSACTION_TYPES.operation.id)

export const getTransactionsWithBookingsWithoutRefunds = (
  transactions?: Array<ServerTransactionType | ServerTransactionBriefWithBookingLocationType>,
) =>
  transactions?.filter(
    transaction => transaction.bookingId !== null && !checkIsRefundTransaction(transaction),
  ) as ServerTransactionWithBookings[]
