import { FC } from 'react'
import {
  CommonPlaceholderDialogProps,
  PlaceholderDialog,
  PlaceholderInput,
  PlaceholderTextarea,
} from '@expane/ui'
import { useTranslation } from 'react-i18next'

export const EmployeeGroupDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog title={t('employeeGroup.name')} className="w-180" closeDialog={closeDialog}>
      <div className="flex mb-4">
        <PlaceholderInput label={t('title')} className="w-1/2 pr-1" />
        <PlaceholderInput label={t('role.name')} className="w-1/2 pl-1" />
      </div>

      <div className="flex mb-4">
        <PlaceholderInput label={t('maxDiscount')} className="w-1/2 pr-1" />
        <PlaceholderInput label={t('storageForConsumables')} className="w-1/2 pl-1" />
      </div>

      <PlaceholderTextarea label={t('description')} />
    </PlaceholderDialog>
  )
}
