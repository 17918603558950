import { FC } from 'react'
import { IoExpandOutline, IoContractOutline } from 'react-icons/io5'

interface ScaleSwitchProps {
  shrinked: boolean
  onChange: (shrinked: boolean) => void
  iconSize?: string
  className?: string
}

export const ScaleSwitch: FC<ScaleSwitchProps> = ({
  shrinked,
  onChange,
  iconSize,
  className = '',
}) => {
  return (
    <button className={BUTTON_CLASSNAME + className} onClick={() => onChange(!shrinked)}>
      {shrinked ? (
        <IoExpandOutline className="transition-colors" size={iconSize} />
      ) : (
        <IoContractOutline className="transition-colors" size={iconSize} />
      )}
    </button>
  )
}

const BUTTON_CLASSNAME = 'text-primary-300 hover:text-primary-500 '
