import { toZonedTime } from '@expane/date'

type WaitingListToParse = {
  start?: string | Date | null
  end?: string | Date | null
  createdAt?: string | Date | null
}
export const parseDatesInWaitingListGqlResponse = <T extends WaitingListToParse>(
  waitingList: T,
  timezone: string,
) => {
  if (waitingList.start) {
    waitingList.start = toZonedTime(waitingList.start, timezone)
  }
  if (waitingList.end) {
    waitingList.end = toZonedTime(waitingList.end, timezone)
  }
  if (waitingList.createdAt) {
    waitingList.createdAt = toZonedTime(waitingList.createdAt, timezone)
  }

  return waitingList
}
