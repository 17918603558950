import { RadioGroup } from '@headlessui/react'
import { FC } from 'react'
import { IoEllipseOutline } from 'react-icons/io5'
import { stopEnterPropagation } from '../../logic'
import { ErrorMessage, ErrorMessageType } from '../ErrorMessage'
import { RadioButtonIcon } from '../Icons'
import { InputLabel } from '../InputLabel'

export interface RadioGroupOption {
  id: number
  name: string
}

export interface RadioGroupButtonsProps {
  options: Array<RadioGroupOption>
  onChange: (item: RadioGroupOption) => void
  value: RadioGroupOption | undefined
  className?: string
  disabled?: boolean
  label?: string
  hint?: string
  required?: boolean
  errorMessage?: ErrorMessageType
}

export const RadioGroupButtons: FC<RadioGroupButtonsProps> = ({
  options,
  onChange,
  value,
  className,
  disabled,
  label,
  hint,
  required,
  errorMessage,
}) => {
  let selectedOptionStyle = 'mr-2 '
  if (disabled) selectedOptionStyle += 'text-gray-300 dark:text-gray-600'
  else if (errorMessage?.isShown) selectedOptionStyle += 'text-error-500'
  else selectedOptionStyle += 'text-primary-500'

  let notSelectedOptionStyle = 'mr-2 '
  if (errorMessage?.isShown) notSelectedOptionStyle += 'text-error-500'
  else notSelectedOptionStyle += 'text-gray-300 dark:text-gray-500'

  return (
    <div className={className}>
      <InputLabel label={label} hint={hint} required={required} />

      <RadioGroup value={value} onChange={onChange} disabled={disabled}>
        <div className="space-y-1">
          {options.map(option => (
            <RadioGroup.Option
              key={option.id}
              value={option}
              onKeyDown={stopEnterPropagation}
              className={
                disabled
                  ? 'pointer-events-none'
                  : 'cursor-pointer rounded-full focus:ring-1 ring-primary-100 dark:ring-primary-500/20'
              }
            >
              {() => (
                <>
                  <div className="flex items-center w-full p-0.5">
                    {option.id === value?.id ? (
                      <RadioButtonIcon size="1.5rem" className={selectedOptionStyle} />
                    ) : (
                      <IoEllipseOutline size="1.5rem" className={notSelectedOptionStyle} />
                    )}
                    <RadioGroup.Label
                      as="p"
                      className={getColor(option.id === value?.id, disabled)}
                    >
                      {option.name}
                    </RadioGroup.Label>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      <ErrorMessage errorMessage={errorMessage} />
    </div>
  )
}

const getColor = (checked: boolean, disabled: boolean | undefined): string => {
  let styles = 'font-medium text-sm'

  if (checked && !disabled) styles += ' text-main-color'
  else if (disabled || (checked && disabled)) styles += ' text-gray-300'
  else styles += ' text-secondary-color hover:text-gray-800 hover:dark:text-gray-300'

  return styles
}
