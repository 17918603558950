import { KeyboardEvent as ReactKeyboardEvent } from 'react'

/*
 @description Reinsurance if no `<div id="modals"/>` is added to `index.html`
 */
const getModalsHTMLElement = (): HTMLElement => {
  const modalsInHtml = document.getElementById('modals')

  if (modalsInHtml) return modalsInHtml

  const modals = document.createElement('div')
  modals.id = 'modals'

  document.body.insertAdjacentElement('beforeend', modals)

  return modals
}

export const modalsHTMLElement = getModalsHTMLElement()

export const stopEnterPropagation = (e: ReactKeyboardEvent<Element>) => {
  if (e.code === 'Enter') e.stopPropagation()
}

export * from './popupArrow'
export * from './styles'
