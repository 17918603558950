import {
  getClassNameForArrow,
  getPlacementStylesForArrow,
  modalsHTMLElement,
  usePopupOpenState,
} from '@expane/ui'
import { arrow, flip, shift, Side, useFloating } from '@floating-ui/react-dom'
import { FC, PropsWithChildren, useRef } from 'react'
import { createPortal } from 'react-dom'
import { IconBaseProps, IconType } from 'react-icons'
import { IoInformationCircleOutline } from 'react-icons/io5'

interface InformationPopupProps {
  CustomIcon?: IconType
  boundaryElement?: HTMLDivElement
  containerClassName?: string
  openByClick?: boolean
  size?: string
}

export const InformationPopup: FC<PropsWithChildren<InformationPopupProps>> = ({
  CustomIcon,
  boundaryElement,
  children,
  containerClassName = '',
  openByClick = false,
  size,
}) => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const arrowRef = useRef(null)

  const {
    reference,
    floating,
    strategy,
    x,
    y,
    middlewareData: { arrow: arrowData },
    placement,
  } = useFloating({
    middleware: [
      flip(),
      shift({ boundary: boundaryElement }),
      arrow({
        element: arrowRef,
      }),
    ],
  })

  const side = placement.split('-')[0] as Side
  const arrowPlacementStyles = getPlacementStylesForArrow(side, arrowData)
  const arrowClassName = getClassNameForArrow(side)

  const iconProps: IconBaseProps = {
    size,
    className: 'text-current bg-transparent',
  }

  return (
    <div className={`flex-centered ${containerClassName}`} onMouseLeave={closePopup}>
      <div
        ref={reference}
        onMouseEnter={openByClick ? undefined : openPopup}
        onClick={
          openByClick
            ? e => {
                e.stopPropagation()
                openPopup()
              }
            : undefined
        }
      >
        {CustomIcon ? <CustomIcon {...iconProps} /> : <IoInformationCircleOutline {...iconProps} />}
      </div>
      {isOpen &&
        createPortal(
          <div
            ref={floating}
            className={informationBlockStyle}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            onMouseLeave={closePopup}
            onMouseDown={e => e.stopPropagation()} // drag fix
          >
            <div ref={arrowRef} className={arrowClassName} style={arrowPlacementStyles} />

            {children}

            <div className="absolute -top-5 left-0 h-10 w-full" />
          </div>,
          modalsHTMLElement,
        )}
    </div>
  )
}

const informationBlockStyle =
  'min-w-max px-4 pt-2 pb-3 text-sm rounded text-label-color bg-block border border-gray-500 space-y-1 relative'
