import type {
  BookingUnion,
  NotGroupBooking,
  ServerExtendedServiceType,
  ServerServiceGroupType,
} from '@expane/data'
import { groupInitialTreeItems, transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { getIsGroupService } from '@expane/logic/service'
import { convertServicesToTreeItems, getServicesForTreeMenu } from 'logic/bookingServiceLogic'
import type {
  BookingMultiServicesDialogFormValues,
  BookingMultiServicesInitialDto,
  MultiServiceDto,
} from '.'
import { checkBookingIsTodayOrLater } from 'logic/calendar'

export const getTransformedServiceFromIds = (
  serviceIds: number[],
  services: ServerExtendedServiceType[] | undefined,
  serviceGroups: ServerServiceGroupType[] | undefined,
) => {
  if (!services || !serviceGroups) return []

  const initialServices = serviceIds.map(serviceId => {
    const foundService = services.find(service => service.id === serviceId)
    if (!foundService) throw new Error('no found service')
    return foundService
  })
  const initialServiceTreeItems = convertServicesToTreeItems(initialServices)

  const notGroupServices = services?.filter(service => !getIsGroupService(service.type)) ?? []

  const treeItems = transformDataForTreeMenu(serviceGroups, notGroupServices)

  return groupInitialTreeItems(initialServiceTreeItems, treeItems)
}

export const getDefaultTransformedServices = ({
  isCreate,
  dateIsEditable,
  services,
  archivedServices,
  bookingById,
  serviceGroups,
}: {
  isCreate: boolean
  dateIsEditable: boolean
  services: ServerExtendedServiceType[] | undefined
  archivedServices: ServerExtendedServiceType[] | undefined
  bookingById: NotGroupBooking
  serviceGroups: ServerServiceGroupType[] | undefined
}) => {
  const notGroupServices = getServicesForTreeMenu({
    checkArchived: !isCreate && !dateIsEditable,
    services,
    archivedServices,
    servicesFromBooking: bookingById.bookingServices.map(bookingService => bookingService.service),
  })

  const serviceItems = transformDataForTreeMenu(serviceGroups, notGroupServices)
  const convertedInitialServiceItems = isCreate
    ? []
    : convertServicesToTreeItems(bookingById.bookingServices.map(bS => bS.service))

  return groupInitialTreeItems(convertedInitialServiceItems, serviceItems)
}

export const generateDefaultValues = ({
  initialData,
  serviceGroups,
  services,
  unitedBookingById,
  archivedServices,
  timezone,
  canEditPast,
}: {
  initialData: BookingMultiServicesInitialDto
  services: ServerExtendedServiceType[] | undefined
  archivedServices: ServerExtendedServiceType[] | undefined
  serviceGroups: ServerServiceGroupType[] | undefined
  unitedBookingById: {
    id: number
    bookings: Array<BookingUnion>
  }
  timezone: string
  canEditPast: boolean
}): BookingMultiServicesDialogFormValues => {
  if (initialData.fromCalendar) {
    if (initialData.data.length === 0) throw new Error('At least one multi-service data needed')

    const multiServicesDto: MultiServiceDto[] =
      initialData.data.length > 1
        ? initialData.data.map(bookingDto => ({
            employeeId: bookingDto.employeeId,
            locationId: bookingDto.locationId,
            services: getTransformedServiceFromIds(bookingDto.serviceIds, services, serviceGroups),
            consumables: bookingDto.consumables,
            startDate: bookingDto.startDate,
            duration: bookingDto.duration,
            bookingId: undefined,
          }))
        : [
            {
              employeeId: initialData.data[0].employeeId,
              locationId: initialData.data[0].locationId,
              services: getTransformedServiceFromIds(
                initialData.data[0].serviceIds,
                services,
                serviceGroups,
              ),
              consumables: initialData.data[0].consumables,
              startDate: initialData.data[0].startDate,
              duration: initialData.data[0].duration,
              bookingId: undefined,
            },
            {
              locationId: initialData.data[0].locationId,
              employeeId: initialData.data[0].employeeId ?? null,
              services: [],
              startDate: initialData.data[0].startDate,
              bookingId: undefined,
              duration: '5',
              consumables: [],
            },
          ]
    return {
      clientId: initialData.clientId,
      multiServicesDto,
      date: initialData.data[0].startDate,
      note: '',
    }
  }

  if (initialData.isCreate) {
    return {
      clientId: null,
      note: '',
      date: initialData.startDate,
      multiServicesDto: [
        {
          locationId: initialData.locationId,
          employeeId: initialData.employeeId ?? null,
          services: [],
          startDate: initialData.startDate,
          bookingId: undefined,
          duration: '5',
          consumables: [],
        },
        {
          locationId: initialData.locationId,
          employeeId: initialData.employeeId ?? null,
          services: [],
          startDate: initialData.startDate,
          bookingId: undefined,
          duration: '5',
          consumables: [],
        },
      ],
    }
  }

  const bookingToGetCommonInfo = unitedBookingById?.bookings[0] as NotGroupBooking
  const transformedBookings = unitedBookingById?.bookings.map(booking => ({
    bookingId: booking.id,
    locationId: booking.location?.id,
    employeeId: booking.employee?.id ?? null,
    startDate: booking.startDate,
    services: getDefaultTransformedServices({
      isCreate: initialData.isCreate,
      dateIsEditable:
        canEditPast || checkBookingIsTodayOrLater(bookingToGetCommonInfo.startDate, timezone),
      services,
      archivedServices,
      bookingById: booking as NotGroupBooking,
      serviceGroups,
    }),
    consumables: booking.bookingProducts.map(({ id, productId, quantity, product: { price } }) => ({
      bookingProductId: id,
      productId,
      quantity: quantity.toString(),
      price,
    })),
    duration: booking.duration.toString(),
  }))

  return {
    clientId: bookingToGetCommonInfo.client?.id ?? null,
    note: bookingToGetCommonInfo.note ?? '',
    date: bookingToGetCommonInfo.startDate,
    multiServicesDto: transformedBookings,
  }
}
