import { FC } from 'react'

export interface TagType {
  name: string
  color: string
}

export type TagProps = TagType & {
  className?: string
}

export const Tag: FC<TagProps> = ({ name, color, className }) => (
  <span
    className={
      className +
      ` px-2 text-${color} text-xs font-medium rounded-full border-2 border-${color} dark:bg-white/5`
    }
  >
    {name}
  </span>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NOTIFICATION_TAG_COLORS = `
text-cyan-700 border-cyan-700
text-green-600 border-green-600
text-green-400 border-green-400
text-amber-400 border-amber-400
`
