import { Button, CloseButton, Dialog, Hint, Modal, usePopupOpenState } from '@expane/ui'
import { FC, PropsWithChildren, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import {
  IoCheckmarkDoneOutline,
  IoChevronBack,
  IoChevronForward,
  IoChevronForwardSharp,
  IoHammerOutline,
  IoLinkOutline,
  IoMagnetOutline,
  IoSearchOutline,
} from 'react-icons/io5'
import { HorizontalTabs } from 'ui/HorizontalTabs'

const TelegramInstructionDialog: FC<{ close: () => void }> = ({ close }) => {
  const { t } = useTranslation()
  const [activeTabId, setActiveTabId] = useState<number>(0)

  const tabs = useMemo(
    () => [
      { id: 0, label: t('telegramInstruction.titles.info'), Icon: IoSearchOutline },
      { id: 1, label: t('telegramInstruction.titles.creation'), Icon: IoHammerOutline },
      { id: 2, label: t('telegramInstruction.titles.integration'), Icon: IoMagnetOutline },
      { id: 3, label: t('telegramInstruction.titles.done'), Icon: IoCheckmarkDoneOutline },
    ],
    [t],
  )

  return (
    <Modal close={close}>
      <Dialog>
        <HorizontalTabs
          title={t('instruction')}
          tabs={tabs}
          activeTabId={activeTabId}
          setActiveTabId={setActiveTabId}
        />
        <Dialog.Body className="w-148 h-120">
          {activeTabId === 0 && (
            <>
              <InstructionItem Icon={IoLinkOutline}>
                <p>
                  {t('telegramInstruction.botFather.1')}{' '}
                  <a
                    className="text-primary-500 underline"
                    href="https://t.me/botfather"
                    referrerPolicy="no-referrer"
                    target="_blank"
                  >
                    BotFather
                  </a>
                </p>
              </InstructionItem>

              <div className="flex justify-center mt-2">
                <img className="rounded-lg w-64" src="/img/telegramBot/link.png" alt="link" />
              </div>

              <div className={'flex items-center mt-2'}>
                <InstructionItem Icon={IoSearchOutline}>
                  <p>{t('telegramInstruction.botFather.2')} =&gt; BotFather</p>
                </InstructionItem>

                <Hint type={'warning'} className={'ml-2'}>
                  {t('telegramInstruction.botFather.3')}
                </Hint>
              </div>

              <div className="flex justify-center mt-2">
                <img className="rounded-lg w-64" src="/img/telegramBot/search.png" alt="search" />
              </div>
            </>
          )}
          {activeTabId === 1 && (
            <>
              <InstructionItem>
                {t('telegramInstruction.creation.1')}{' '}
                <span className="text-label-color">"/newbot"</span>
              </InstructionItem>

              <InstructionItem>
                <p>{t('telegramInstruction.creation.2')}</p>
              </InstructionItem>

              <div className="flex justify-center my-2">
                <img className="rounded-lg w-128" src="/img/telegramBot/new.png" alt="new" />
              </div>

              <InstructionItem>
                <p>
                  {t('telegramInstruction.creation.3')}{' '}
                  <span className="text-label-color">"Bot"</span>{' '}
                  {t('telegramInstruction.creation.4')}{' '}
                  <span className="text-label-color">"_bot"</span>
                </p>
              </InstructionItem>

              <div className="flex justify-center mt-2">
                <img className="rounded-lg w-128" src="/img/telegramBot/name.png" alt="name" />
              </div>
            </>
          )}

          {activeTabId === 2 && (
            <>
              <h2 className="text-label-color mb-2">{`${t('telegramInstruction.data.title')}:`}</h2>

              <InstructionItem>
                {t('telegramInstruction.data.1')}{' '}
                <span className="text-label-color">{t('telegramInstruction.data.2')}</span>{' '}
                {t('telegramInstruction.data.3')}{' '}
                <span className="text-red-600 font-medium">1</span>
              </InstructionItem>

              <InstructionItem>
                {t('telegramInstruction.data.1')}{' '}
                <span className="text-label-color">{t('telegramInstruction.data.4')}</span>{' '}
                {t('telegramInstruction.data.5')}{' '}
                <span className="text-red-600 font-medium">2</span>
              </InstructionItem>

              <div className="flex justify-center mt-2">
                <img className="rounded-lg w-128" src="/img/telegramBot/token.png" alt="token" />
              </div>
            </>
          )}

          {activeTabId === 3 && (
            <>
              <div className="flex flex-col justify-center h-96 items-center text-label-color w-10/12 m-auto">
                <IoCheckmarkDoneOutline size="3rem" className={'text-main-color'} />
                <p className={'text-main-color mt-4 text-center'}>
                  {t('telegramInstruction.done.1')}
                </p>
              </div>
            </>
          )}
        </Dialog.Body>

        <Dialog.Footer>
          <Button
            onClick={() => setActiveTabId(prevState => prevState + 1)}
            disabled={activeTabId === tabs.length - 1}
            Icon={IoChevronForward}
            iconSide="right"
          >
            {t('forward')}
          </Button>

          <Button
            onClick={() => setActiveTabId(prevState => prevState - 1)}
            disabled={activeTabId === 0}
            Icon={IoChevronBack}
          >
            {t('back')}
          </Button>

          <CloseButton onClick={close} className="mr-auto" />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

const InstructionItem: FC<PropsWithChildren<{ Icon?: IconType }>> = ({ Icon, children }) => (
  <div className={'flex gap-1 items-start text-secondary-color'}>
    <div className={'w-6 h-6 flex flex-col items-center justify-center shrink-0 grow-0'}>
      {Icon ? <Icon size="1.2rem" /> : <IoChevronForwardSharp size="1.2rem" />}
    </div>

    {children}
  </div>
)

export const useOpenTelegramInstructionDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialog = isOpen ? <TelegramInstructionDialog close={closePopup} /> : null

  return { dialog, openDialog: openPopup }
}
