import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import {
  checkUnitCanBeFloat,
  convertUnitValueFromServer,
  getUnitsName,
} from '@expane/logic/product'
import { findById, getPercentOfAmount } from '@expane/logic/utils'
import {
  DeleteButtonCell,
  Input,
  PlaceholderString,
  TableCell,
  TableRow,
  NumberInput,
} from '@expane/ui'
import { ChangeEvent, FC, useState } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ArrowButton } from 'ui/ArrowButton'
import { ArrivalMovementFormValues } from '.'
import { ProductDto } from '../ChooseMovementProductsDialog'

interface ArrivalMovementRowProps {
  index: number
  control: Control<ArrivalMovementFormValues>
  disabled: boolean
  amount: number
  products: ProductDto[]
  onDelete: () => void
  isLoadingLeftovers: boolean
}

export const ArrivalMovementRow: FC<PropsWithBranchId<ArrivalMovementRowProps>> = ({
  control,
  onDelete,
  index,
  disabled,
  amount,
  products,
  branchId,
  isLoadingLeftovers,
}) => {
  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const discount = useWatch({ control, name: 'discount' })

  const [modifiedPrice, setModifiedPrice] = useState<string | null>(null)
  const [modifiedTotalSum, setModifiedTotalSum] = useState<string | null>(null)
  const [arrowDirection, setArrowDirection] = useState<'left' | 'right'>('right')

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (arrowDirection === 'left') setArrowDirection('right')
    if (modifiedTotalSum !== null) setModifiedTotalSum(null)
    setModifiedPrice(e.target.value)
  }

  return (
    <TableRow>
      <Controller
        control={control}
        name={`products.${index}` as 'products.0'}
        rules={{
          validate: {
            biggerThanZero: value => value.quantity > 0 && value.costPrice > 0,
            integer: value => {
              if (checkUnitCanBeFloat(value.unit)) return true

              return Number.isInteger(value.quantity)
            },
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const setNewPrice = () => {
            if (modifiedPrice !== null && modifiedTotalSum === null) {
              onChange({ ...value, costPrice: Number(modifiedPrice) })
              setModifiedPrice(null)
            }
            if (modifiedPrice === null && modifiedTotalSum !== null) {
              const newServicePrice = Number(modifiedTotalSum) / value.quantity
              onChange({ ...value, costPrice: Number(newServicePrice.toFixed(2)) })
              setModifiedTotalSum(null)
            }
          }
          return (
            <>
              <TableCell>{value.name}</TableCell>
              <TableCell>
                <NumberInput
                  onChange={quantity => {
                    onChange({ ...value, quantity })
                  }}
                  value={value.quantity.toString()}
                  errorMessage={{ isShown: Boolean(error), text: ' ', reserveIndent: false }}
                  height="small"
                />
              </TableCell>
              <TableCell>
                {isLoadingLeftovers ? (
                  <PlaceholderString width="small" />
                ) : amount ? (
                  convertUnitValueFromServer(amount, value.unit)
                ) : (
                  0
                )}
              </TableCell>
              <TableCell>{getUnitsName(value.unit, t)}</TableCell>
              <TableCell>
                {discount
                  ? convertNumberToMoney(getPercentOfAmount(value.costPrice, Number(discount)))
                  : '-'}
              </TableCell>
              <TableCell>
                <Input
                  containerClassName={
                    'pr-2 ' + generateCostPriceColor(value.id, products, value.costPrice)
                  }
                  type="number"
                  min={0}
                  height="small"
                  onChange={handlePriceChange}
                  onKeyDown={event => {
                    if (event.code === 'Enter') setNewPrice()
                  }}
                  value={modifiedPrice ?? value.costPrice.toString()}
                  errorMessage={{ isShown: Boolean(error), text: ' ', reserveIndent: false }}
                />
              </TableCell>
              <TableCell>
                <ArrowButton
                  arrowDirection={arrowDirection}
                  active={Boolean(modifiedPrice) || Boolean(modifiedTotalSum)}
                  onClick={setNewPrice}
                />
              </TableCell>
              <TableCell>
                <Input
                  value={modifiedTotalSum ?? getTotalSum(value, discount)}
                  onChange={event => {
                    if (arrowDirection === 'right') setArrowDirection('left')
                    if (modifiedPrice !== null) setModifiedPrice(null)
                    setModifiedTotalSum(event.target.value)
                  }}
                  onKeyDown={event => {
                    if (event.code === 'Enter') setNewPrice()
                  }}
                  type="number"
                  min={0}
                  height="small"
                  containerClassName="w-full"
                />
              </TableCell>
              <DeleteButtonCell onClick={onDelete} disabled={disabled} />
            </>
          )
        }}
      />
    </TableRow>
  )
}

const generateCostPriceColor = (
  productId: number,
  products: ProductDto[],
  newCost: number,
): string => {
  const product = findById(productId, products)

  if (!product) return ''

  if (product.costPrice < newCost) return 'text-error-500'
  if (product.costPrice > newCost) return 'text-primary-500'

  return ''
}

const getTotalSum = (value: ProductDto, discount: string | undefined) => {
  let discountValue = 0

  if (discount) {
    discountValue = getPercentOfAmount(value.costPrice, Number(discount))
  }

  const costPrice = value.costPrice - discountValue

  return (costPrice * value.quantity).toFixed(2)
}
