import { FC, memo } from 'react'
import { IoCopy, IoOpenOutline } from 'react-icons/io5'

export const URLContainer: FC<{ url: string; className?: string }> = memo(({ url, className }) => {
  const handleClick = () => {
    navigator.clipboard.writeText(url)
  }

  return (
    <div className={'flex ' + className || ''}>
      <span className="px-1 border-2 text-main-color text-sm border-gray-400 dark:border-gray-500 bg-icon rounded-l-md line-clamp-1">
        {url}
      </span>
      <button
        className="bg-gray-400 dark:bg-gray-500 text-btn-primary hover:bg-gray-500 hover:dark:bg-gray-600 px-1"
        onClick={handleClick}
      >
        <IoCopy />
      </button>
      <a
        className="flex flex-centered bg-gray-400 dark:bg-gray-500 text-btn-primary hover:bg-gray-500 hover:dark:bg-gray-600 rounded-r-md px-1"
        href={url}
        target="_blank"
      >
        <IoOpenOutline />
      </a>
    </div>
  )
})
