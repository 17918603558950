import { SnackbarProvider } from '@expane/widgets'
import { CurrentTimeProvider } from 'logic/hooks/useCurrentTime'
import { StrictMode } from 'react'
import { AppRoutes, CustomRouter } from 'routes'
import { queryClient, QueryClientProvider } from 'services/api'
import { history } from 'services/navigation'
import './i18n'
import ErrorBoundary from 'widgets/ErrorBoundary'

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <StrictMode>
        <ErrorBoundary>
          <SnackbarProvider>
            <CurrentTimeProvider>
              <CustomRouter children={<AppRoutes />} history={history} />
            </CurrentTimeProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </StrictMode>
      {/* Makes laggy dialog openings. Also makes laggy on query and mutation */}
      {/* Can be used when needed */}
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  )
}
