import { ClientByIdType, useBootstrapCalendar } from '@expane/data'
import { Button, InputLabel } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline } from 'react-icons/io5'
import { useSnackbar } from '@expane/widgets'

interface BootstrapGoogleCalendarProps {
  client: ClientByIdType
  archived: boolean
  disabled: boolean
  className?: string
}

export const BootstrapGoogleCalendar: FC<BootstrapGoogleCalendarProps> = ({
  client,
  archived,
  disabled,
  className,
}) => {
  const { t } = useTranslation()
  const { mutateAsync, isLoading } = useBootstrapCalendar(client.id)

  const [openSnackbar] = useSnackbar()

  const handleBootstrap = async () => {
    const res = await mutateAsync({})

    if (res.bootstrapCalendar.message === 'Calendar successful added!')
      openSnackbar(t('googleCalendar.successfullyAdded'), 'success')
    else openSnackbar(t('submitError'), 'error')
  }

  const isBootstrapped = Boolean(client.calendarId)
  const isBootstrapAllowed = client.emailView?.email && !archived && !client.calendarId

  const hintText = isBootstrapped
    ? t('googleCalendar.hintAlreadyHas')
    : t('googleCalendar.hintMustHave')

  return (
    <div className={className}>
      <div className="w-fit">
        <InputLabel label={t('googleCalendar.name')} hint={hintText} />
      </div>
      <Button
        type="outline"
        disabled={!isBootstrapAllowed || disabled}
        onClick={handleBootstrap}
        spinner={isLoading}
        Icon={IoCalendarOutline}
      >
        {t('googleCalendar.add')}
      </Button>
    </div>
  )
}
