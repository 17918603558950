import { Consumable } from '@expane/logic/payment/booking'
import { makeAutoObservable } from 'mobx'
import { MutateBookingDto } from 'pages/BookingsPage/BookingsCalendar/BookingCard/logic'

export type BookingUpdateData = {
  id: number
  startDate: Date
  locationId: number
  serviceIds: number[]
  consumables: Consumable[]
  employeeId: number | null
  duration: string
  ref?: HTMLDivElement | null
}
export type InitBookingUpdateData = Omit<BookingUpdateData, 'id'>

export class UnitedBookingUpdate {
  constructor() {
    makeAutoObservable(this)
  }

  clientId: number | null = null
  bookingUpdateData: BookingUpdateData[] = []

  setInitialBookingUpdateData = (init: InitBookingUpdateData[], clientId: number) => {
    if (this.bookingUpdateData.length || this.clientId)
      throw new Error('booking update data already initialized')

    this.clientId = clientId
    this.bookingUpdateData = init.map((booking, index) => ({ ...booking, id: index }))
  }

  get isUnitedBookingUpdateMode() {
    return this.bookingUpdateData.length > 0
  }
  updateBookingData = (bookingId: number, dto: MutateBookingDto) => {
    const thisBooking = this.bookingUpdateData.find(booking => booking.id === bookingId)
    if (thisBooking) {
      if (dto.newStartDate) thisBooking.startDate = dto.newStartDate
      if (dto.newLocationId) thisBooking.locationId = dto.newLocationId
      if (dto.newEmployeeId) thisBooking.employeeId = dto.newEmployeeId
    } else {
      throw new Error('No such booking')
    }
  }
  attachBookingCardRef = (bookingId: number, ref: HTMLDivElement | null) => {
    const foundBooking = this.bookingUpdateData.find(booking => booking.id === bookingId)
    if (!foundBooking) throw new Error('No such booking')
    foundBooking.ref = ref
  }
  removeBookingCardRef = (bookingId: number) => {
    const foundBooking = this.bookingUpdateData.find(booking => booking.id === bookingId)
    // In case of submitting back to BookingDialog, the array is already empty
    // So there is no need to remove bookingCardRef
    if (!foundBooking) return
    foundBooking.ref = null
  }

  clearData = () => {
    this.bookingUpdateData = []
    this.clientId = null
  }
}
