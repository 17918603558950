import { FC } from 'react'
import { useSnackbar } from '@expane/widgets'
import { transformPersonName } from '@expane/logic/utils'
import { useTranslation } from 'react-i18next'
import { BootstrapGoogleCalendar } from './BootstrapGoogleCalendar'
import { NotificationSettingsInfo } from './NotificationSettingsInfo'
import { ClientByIdType, useArchiveClient, useUnarchiveClient } from '@expane/data'
import { store } from 'store'
import { useFetchMyPermissions } from 'gql/employee'
import { permissions } from '@expane/logic/permission'
import { ArchiveButton, RestoreButton } from 'widgets/Buttons'
import { useShowArchiveConfirmationPopup } from 'logic/hooks/popup/useShowArchiveConfirmationPopup'

interface OptionsTabProps {
  client: ClientByIdType
  archived: boolean
  disabled: boolean
  closeDialog: () => void
}

export const OptionsTab: FC<OptionsTabProps> = ({ client, archived, disabled, closeDialog }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!
  const { mutateAsync: archiveClient } = useArchiveClient()
  const { mutateAsync: unarchiveClient } = useUnarchiveClient()

  const { data: myPermissions } = useFetchMyPermissions()
  const isArchivingAllowed = myPermissions?.includes(permissions.client.archive)

  const [openSnackbar] = useSnackbar()

  const { t } = useTranslation()

  const mutateArchiveClient = async (deactivated: boolean) => {
    try {
      if (deactivated) {
        await archiveClient({ clientId: client.id, branchId })
      } else {
        await unarchiveClient({ clientId: client.id, branchId })
      }
      openSnackbar(t('client.clientIsSuccessfullyArchived'), 'success', 3000)
    } catch (error) {
      openSnackbar(t('submitError'), 'error', 3000)
    }

    closeDialog()
  }

  const { archiveConfirmationModal, showArchiveConfirmationPopup } =
    useShowArchiveConfirmationPopup(client ? transformPersonName(client) : '', () =>
      mutateArchiveClient(true),
    )

  return (
    <div>
      {client?.id ? (
        !archived ? (
          <ArchiveButton
            className="mr-auto"
            onClick={showArchiveConfirmationPopup}
            disabled={disabled || !isArchivingAllowed}
          />
        ) : (
          <RestoreButton
            className="mr-auto"
            onClick={() => mutateArchiveClient(false)}
            disabled={disabled || !isArchivingAllowed}
          />
        )
      ) : null}

      <BootstrapGoogleCalendar
        className="mt-4"
        client={client}
        archived={archived}
        disabled={disabled}
      />

      <NotificationSettingsInfo
        className="mt-4"
        notificationSettings={client.clientSettings.notificationSettings}
      />

      {archiveConfirmationModal}
    </div>
  )
}
