import { NestedProductGroupType, ServerProductType } from '@expane/data'
import { TreeMenuItem } from '.'

const transformProductGroupsIntoTreeItems = (
  productGroups: NestedProductGroupType[],
  parentId?: number,
): TreeMenuItem[] => {
  const result: TreeMenuItem[] = []

  productGroups.forEach(group => {
    const nodesFromGroups = group.childGroups
      ? transformProductGroupsIntoTreeItems(group.childGroups, group.id)
      : []

    const nodesFromProducts: TreeMenuItem[] = group.products.map(product => ({
      id: product.id,
      name: product.name,
      parentId: group.id,
      isFolder: false,
    }))

    result.push({
      id: group.id,
      name: group.name,
      isFolder: true,
      parentId,
      nodes: [...nodesFromGroups, ...nodesFromProducts],
    })
  })

  return result
}

export const getProductsForTreeMenu = (
  productGroups: NestedProductGroupType[] | undefined,
  products: ServerProductType[] | undefined,
): TreeMenuItem[] => {
  if (!productGroups || !products) return []

  const treeItemsFromGroups = transformProductGroupsIntoTreeItems(productGroups)
  const treeItemsFromProducts: TreeMenuItem[] = products.map(product => ({
    id: product.id,
    name: product.name,
    isFolder: false,
  }))

  return [...treeItemsFromGroups, ...treeItemsFromProducts]
}
