import { gql } from 'graphql-request'

export const waitingListFragment = gql`
  fragment waitingListType on waitingList {
    id
    start
    end
    createdAt
    description
    service {
      id
      name
    }
    employee {
      id
      firstName
      lastName
    }
    client {
      id
      firstName
      lastName
    }
    responsibleEmployees {
      employee {
        id
        firstName
        lastName
        groupId
      }
    }
  }
`
