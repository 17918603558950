import { ServerTransactionByIdType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { getIsSubscriptionRefunded } from '@expane/logic/finances/filters'
import { getIsRefundAllowed } from '@expane/logic/transaction/refund'
import { Button, CloseButton, Dialog, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoArrowUndoOutline } from 'react-icons/io5'
import { TransactionMainInfoDialog } from 'widgets/TransactionDialog/TransactionMainInfo'
import { TransactionRefundInfo } from 'widgets/TransactionDialog/TransactionRefundInfo'
import { useOpenTransactionSubscriptionRefundDialog } from 'widgets/TransactionSubscriptionDialog/RefundDialog'
import { TransactionSubscriptionDialogProps } from 'widgets/TransactionSubscriptionDialog/SubscriptionDialog'

type TransactionCard = ServerTransactionByIdType['transactionsCards'][0]

export const TransactionSubscriptionListDialog: FC<
  PropsWithBranchId<Omit<TransactionSubscriptionDialogProps, 'subscriptionId'>>
> = ({ transactionById, closeDialog, myPermissions, refundsForTransaction, branchId }) => {
  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const { transactionSubscriptionRefundDialog, openTransactionSubscriptionRefundDialog } =
    useOpenTransactionSubscriptionRefundDialog()
  const isRefundAllowed = getIsRefundAllowed(transactionById, myPermissions)

  const columns = useMemo<ColumnDef<TransactionCard>[]>(
    () => [
      {
        accessorKey: 'card.cardTemplate.name',
        header: t('position'),
      },
      {
        accessorKey: 'discount',
        header: t('discount'),
        cell: data => {
          const discount = data.getValue<number>()
          return discount ? convertNumberToMoney(discount) : '-'
        },
      },
      {
        accessorKey: 'price',
        header: t('price'),
        cell: data => convertNumberToMoney(data.getValue<number>()),
      },
      {
        id: 'refundButton',
        cell: data => {
          if (!isRefundAllowed) return null
          const cardTransaction = data.row.original
          const isRefunded = getIsSubscriptionRefunded(
            refundsForTransaction,
            cardTransaction.card.id,
          )

          return isRefunded ? (
            <p className="text-sm text-error-600">{t('returned')}</p>
          ) : (
            <Button
              onClick={() => {
                openTransactionSubscriptionRefundDialog({
                  subscriptionId: cardTransaction.card.id,
                  transactionId: transactionById.id,
                  onSuccess: closeDialog,
                })
              }}
              type="danger"
              twoLines
              Icon={IoArrowUndoOutline}
            >
              {t('transaction.createRefund')}
            </Button>
          )
        },
      },
    ],
    [
      t,
      convertNumberToMoney,
      isRefundAllowed,
      refundsForTransaction,
      openTransactionSubscriptionRefundDialog,
      transactionById.id,
      closeDialog,
    ],
  )

  return (
    <>
      <Dialog.Title>{t('transaction.name')}</Dialog.Title>

      <Dialog.Body className="w-148">
        <TransactionMainInfoDialog transactionById={transactionById} />
        <Table columns={columns} data={transactionById.transactionsCards} />
        <TransactionRefundInfo refundsForTransaction={refundsForTransaction} />
      </Dialog.Body>

      <Dialog.Footer>
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>

      {transactionSubscriptionRefundDialog}
    </>
  )
}
