import {
  ExtendedEmployee,
  ServerLocationGroupType,
  ServerLocationWithServiceIdsType,
  useFetchBranchById,
  useFetchEmployeesGroups,
  useFetchExtendedEmployees,
  useFetchLocationGroups,
  useFetchLocations,
} from '@expane/data'
import { isToday as getIsToday } from '@expane/date'
import {
  CLOSED_DAY_TIMING,
  getCalendarFinishHour,
  getCalendarStartHour,
  getEmployeesToShowInCalendar,
  getWeekDay,
} from '@expane/logic/calendar'
import { EmptyPlaceholder, Spinner, useShortCut } from '@expane/ui'
import { generateRows } from 'logic/calendar'
import { ContextMenuItem } from 'logic/hooks/useContextMenu'
import { observer } from 'mobx-react-lite'
import { LocationGroupColumn } from 'pages/BookingsPage/BookingsCalendar/columns/LocationGroupColumn'
import { FC, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IoCalendarOutline,
  IoPeopleOutline,
  IoPersonRemoveOutline,
  IoReaderOutline,
  IoTodayOutline,
} from 'react-icons/io5'
import { store } from 'store'
import { CalendarContainer } from 'ui/Calendar'
import { BookingCalendarType } from '..'
import { CalendarTimeColumn } from './CalendarTimeComponents'
import { EmployeeColumn } from './columns/EmployeeColumn'
import { EmployeeGroupColumn } from './columns/EmployeeGroupColumn'
import { LocationColumn } from './columns/LocationColumn'
import {
  BOOKING_DIALOG_CONTEXT_MENU_ID,
  GROUP_BOOKING_DIALOG_CONTEXT_MENU_ID,
  MULTI_SERVICE_BOOKING_DIALOG_CONTEXT_MENU_ID,
  sortEmployeesByGroups,
  TIMEOFF_DIALOG_CONTEXT_MENU_ID,
} from './columns/logic'
import { filterEmployeesWhoCanWorkOnLocations, generateWeeklyColumns } from './logic'

interface Props {
  type: BookingCalendarType
  date: Date
  selectedEmployeeId: number | undefined
  selectedEmployees: ExtendedEmployee[]
  selectedLocations: ServerLocationWithServiceIdsType[]
  columnsAreShrinked: boolean
  setColumnsAreShrinked: (shrinked: boolean) => void
  onTitleClick: (id: number) => void
}

const BookingsCalendarWithoutObserver: FC<Props> = ({
  type,
  date,
  selectedEmployeeId,
  selectedEmployees,
  selectedLocations,
  onTitleClick,
  columnsAreShrinked,
  setColumnsAreShrinked,
}) => {
  const { t } = useTranslation()

  const contextMenuItems = useMemo<ContextMenuItem[]>(
    () =>
      type === 'locations'
        ? [
            {
              id: BOOKING_DIALOG_CONTEXT_MENU_ID,
              name: t('booking.name'),
              Icon: IoReaderOutline,
            },
            {
              id: GROUP_BOOKING_DIALOG_CONTEXT_MENU_ID,
              name: t('groupBooking.name'),
              Icon: IoPeopleOutline,
            },
            {
              id: MULTI_SERVICE_BOOKING_DIALOG_CONTEXT_MENU_ID,
              name: t('multiServicesBooking.name'),
              Icon: IoCalendarOutline,
            },
          ]
        : [
            {
              id: BOOKING_DIALOG_CONTEXT_MENU_ID,
              name: t('booking.name'),
              Icon: IoReaderOutline,
            },
            {
              id: GROUP_BOOKING_DIALOG_CONTEXT_MENU_ID,
              name: t('groupBooking.name'),
              Icon: IoPeopleOutline,
            },
            {
              id: MULTI_SERVICE_BOOKING_DIALOG_CONTEXT_MENU_ID,
              name: t('multiServicesBooking.name'),
              Icon: IoCalendarOutline,
            },
            {
              id: TIMEOFF_DIALOG_CONTEXT_MENU_ID,
              name: t('timeOff.name'),
              Icon: IoPersonRemoveOutline,
            },
          ],
    [type, t],
  )

  useShortCut(['KeyZ', 'AltLeft'], () => {
    if (isWeekView) return
    setColumnsAreShrinked(!columnsAreShrinked)
  })

  const containerRef = useRef<HTMLDivElement | null>(null)

  const {
    bookingSettings: { isEmployeesSortedByGroups, isLocationsSortedByGroups },
    branch: { branchId },
  } = store

  const { data: branch, isLoading: areBranchLoading } = useFetchBranchById(branchId)
  const { data: locations, isLoading: areLocationsLoading } = useFetchLocations(branchId)
  const { data: locationGroups, isLoading: areLocationGroupsLoading } =
    useFetchLocationGroups(branchId)

  const { data: employees, isLoading: areEmployeesLoading } = useFetchExtendedEmployees(
    branch?.timezone,
    branchId,
  )
  const { data: employeeGroups, isLoading: areEmployeeGroupsLoading } = useFetchEmployeesGroups(
    branch?.timezone,
    branchId,
  )

  const isLoading =
    areBranchLoading ||
    areLocationsLoading ||
    areEmployeesLoading ||
    areEmployeeGroupsLoading ||
    areLocationGroupsLoading
  if (isLoading) return <Spinner expandCentered />

  const isToday = getIsToday(date, branch?.timezone)
  const isWeekView = type === 'employees' && Boolean(selectedEmployeeId)

  const employeesToShowInCalendar = getEmployeesToShowInCalendar(employees, date)

  const employeesWhoWorkOnLocations = selectedLocations.length
    ? filterEmployeesWhoCanWorkOnLocations(employeesToShowInCalendar, selectedLocations)
    : employeesToShowInCalendar
  const employeesForDisplay = isEmployeesSortedByGroups
    ? sortEmployeesByGroups(employeesWhoWorkOnLocations, employeeGroups ?? [])
    : employeesWhoWorkOnLocations

  const locationsForDisplay =
    (type === 'locations' ? getLocationsForDisplay(locations, selectedLocations) : locations) ?? []

  const locationGroupsForDisplay =
    type === 'locations' && isLocationsSortedByGroups
      ? getLocationsWithGroupsForDisplay({
          locationGroups,
          locations,
        })
      : locationGroups

  const branchSchedule = branch?.schedule
  const branchCalendarStartHour = getCalendarStartHour(branchSchedule ?? undefined)
  const branchCalendarEndHour = getCalendarFinishHour(branchSchedule ?? undefined)

  const currentDateBranchDayTiming = branchSchedule?.data[getWeekDay(date)] ?? CLOSED_DAY_TIMING

  const ROWS = isWeekView
    ? generateRows(branchCalendarStartHour, branchCalendarEndHour)
    : generateRows(Math.trunc(currentDateBranchDayTiming[0]), currentDateBranchDayTiming[1])

  const weeklyColumns = generateWeeklyColumns(date)
  const selectedEmployee = selectedEmployeeId
    ? employees?.find(employee => employee.id === selectedEmployeeId)
    : undefined

  if (ROWS.length === 0 && !isLoading)
    return (
      <div className="h-full w-full flex flex-col justify-between">
        <EmptyPlaceholder Icon={IoTodayOutline} text={t('branchIsClosed')} />
      </div>
    )

  const enableGroupOffset =
    !isWeekView &&
    ((type === 'locations' && isLocationsSortedByGroups) ||
      (type === 'employees' && isEmployeesSortedByGroups))

  return (
    <CalendarContainer fullWidth={isWeekView} shrinked={columnsAreShrinked}>
      <CalendarTimeColumn
        rows={ROWS}
        isToday={isToday}
        isSelectedEmployee={Boolean(selectedEmployeeId)}
        tall={columnsAreShrinked && type === 'employees' && !isWeekView}
        showScaleSwitch={!isWeekView}
        enableGroupOffset={enableGroupOffset}
        columnsAreShrinked={columnsAreShrinked}
        setColumnsAreShrinked={setColumnsAreShrinked}
      />
      {/* Need this for proper boundaries of booking info */}
      <div ref={containerRef} className={`flex ${columnsAreShrinked ? '' : 'grow'}`}>
        {type === 'locations' &&
          !isLocationsSortedByGroups &&
          locationsForDisplay?.map((location, index) => (
            <LocationColumn
              key={location.id}
              contextMenuItems={contextMenuItems}
              location={location}
              employeesFromFilter={selectedEmployees}
              last={index === locationsForDisplay.length - 1}
              branchSchedule={branchSchedule ?? undefined}
              hours={ROWS.map(row => row.startHour)}
              date={date}
              // если есть locationsForDisplay, то и locations тоже есть
              locations={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                locations!
              }
              columnIndex={index}
              shrinked={columnsAreShrinked}
              boundaryElement={containerRef.current ?? undefined}
            />
          ))}

        {type === 'locations' &&
          isLocationsSortedByGroups &&
          locationGroupsForDisplay?.map(locationGroup => {
            return (
              <LocationGroupColumn
                contextMenuItems={contextMenuItems}
                key={locationGroup.id}
                locations={locationsForDisplay}
                locationGroup={locationGroup}
                employeesFromFilter={selectedEmployees}
                branchSchedule={branchSchedule ?? undefined}
                hours={ROWS.map(row => row.startHour)}
                date={date}
                shrinked={columnsAreShrinked}
                boundaryElement={containerRef.current ?? undefined}
              />
            )
          })}

        {type === 'employees' &&
          !isWeekView &&
          !isEmployeesSortedByGroups &&
          employeesForDisplay?.map((employee, index) => (
            <EmployeeColumn
              contextMenuItems={contextMenuItems}
              key={employee.id}
              employee={employee}
              locationsFromFilter={selectedLocations}
              last={index === employeesForDisplay.length - 1}
              branchSchedule={branchSchedule ?? undefined}
              hours={ROWS.map(row => row.startHour)}
              date={date}
              employees={employeesForDisplay}
              columnIndex={index}
              onTitleClick={onTitleClick}
              shrinked={columnsAreShrinked}
              boundaryElement={containerRef.current ?? undefined}
            />
          ))}
        {type === 'employees' &&
          !isWeekView &&
          isEmployeesSortedByGroups &&
          employeeGroups?.map(employeeGroup => (
            <EmployeeGroupColumn
              contextMenuItems={contextMenuItems}
              key={employeeGroup.id}
              employeeGroup={employeeGroup}
              employees={employeesForDisplay}
              locationsFromFilter={selectedLocations}
              branchSchedule={branchSchedule ?? undefined}
              hours={ROWS.map(row => row.startHour)}
              date={date}
              onTitleClick={onTitleClick}
              shrinked={columnsAreShrinked}
              boundaryElement={containerRef.current ?? undefined}
            />
          ))}
        {type === 'employees' &&
          isWeekView &&
          weeklyColumns.map((dateColumn, index) => (
            <EmployeeColumn
              key={dateColumn.id}
              contextMenuItems={contextMenuItems}
              employee={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                selectedEmployee!
              }
              locationsFromFilter={selectedLocations}
              last={index === weeklyColumns.length - 1}
              branchSchedule={branchSchedule ?? undefined}
              hours={ROWS.map(row => row.startHour)}
              date={dateColumn.date}
              employees={employeesWhoWorkOnLocations}
              columnIndex={index}
              isWeekly
              shrinked={columnsAreShrinked}
              boundaryElement={containerRef.current ?? undefined}
            />
          ))}
      </div>
    </CalendarContainer>
  )
}

export const BookingsCalendar = observer(BookingsCalendarWithoutObserver)

const getLocationsWithGroupsForDisplay = ({
  locationGroups,
  locations,
}: {
  locationGroups: ServerLocationGroupType[] | undefined
  locations: ServerLocationWithServiceIdsType[] | undefined
}) => {
  const allLocationGroups: Omit<ServerLocationGroupType, 'description'>[] = locationGroups
    ? [...locationGroups]
    : []
  if (locations?.some(location => location.groupId === null))
    allLocationGroups.push({
      // формируем уникальный id, для того чтобы он не был как у категории локаций
      id: -1,
      name: GROUP_TITLE_FOR_LOCATIONS_WITHOUT_GROUP,
      archived: null,
    })

  return allLocationGroups
}

export const GROUP_TITLE_FOR_LOCATIONS_WITHOUT_GROUP = '-'

const getLocationsForDisplay = (
  locations: ServerLocationWithServiceIdsType[] | undefined,
  selectedLocations: ServerLocationWithServiceIdsType[],
) => {
  if (selectedLocations.length > 0)
    return locations?.filter(location =>
      selectedLocations.some(selectedLocation => selectedLocation.id === location.id),
    )
  else return locations
}
