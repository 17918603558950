import { gql } from 'graphql-request'

export const inventoryFragment = gql`
  fragment inventoryType on inventory {
    id
    inventoryDate
    number
    storage {
      id
      name
    }
  }
`

export const inventoryProductFragment = gql`
  fragment inventoryProductType on inventoryProduct {
    product {
      id
      name
      vendorCode
      unit
      price
      costPrice
    }
    actualQuantity
    calculatedQuantity
    price
  }
`

export const inventoryByIdFragment = gql`
  ${inventoryProductFragment}
  fragment inventoryByIdType on inventory {
    id
    fulfilled
    inventoryDate
    number
    storage {
      id
      name
    }
    movements {
      id
      type
      createdAt
      movementProducts {
        productId
        quantity
        product {
          unit
        }
      }
    }
    inventoryProducts {
      ...inventoryProductType
    }
  }
`
