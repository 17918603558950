import { gql } from 'graphql-request'
import { reportError, reportGqlError, request, useQuery } from '../../api'
import { ServerMovementType } from '../../generated/graphql-types'
import { MOVEMENT_NUMBER_QUERY_KEY, MOVEMENTS_QUERY_KEY } from './queryKeys'
import { TRANSACTIONS_QUERY_KEY } from '../transaction/queryKeys'
import { SUPPLIERS_QUERY_KEY } from '../supplier/queryKeys'
import { STORAGES_QUERY_KEY } from '../storage/queryKeys'
import { movementFragment } from './movement.fragments'
import { parseDatesInMovementGqlResponse } from './logic'
import { DEFAULT_TIMEZONE } from '@expane/date'

export const useGetMovementNumber = (branchId: number | undefined) =>
  useQuery<number | undefined>(
    [MOVEMENTS_QUERY_KEY, MOVEMENT_NUMBER_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          query ($branchId: Int!) {
            movementsAggregate(where: { branchId: { _eq: $branchId } }) {
              aggregate {
                count(columns: id)
              }
            }
          }
        `,
        { branchId },
      )

      if (result?.movementsAggregate?.aggregate?.count !== undefined)
        return result.movementsAggregate.aggregate.count + 1
      else reportError(new Error('error in count movementNumber'), 'error', { result })

      return 1
    },
    { queryName: 'useGetMovementNumber', enabled: Boolean(branchId) },
  )

export const useFetchMovementById = (id: number | undefined, timezone: string | undefined) =>
  useQuery<ServerMovementType>(
    [MOVEMENTS_QUERY_KEY, TRANSACTIONS_QUERY_KEY, SUPPLIERS_QUERY_KEY, STORAGES_QUERY_KEY, id],
    async () => {
      const result = await request<{ movementById }>(
        gql`
          ${movementFragment}
          query ($id: Int!) {
            movementById(id: $id) {
              ...movementType
            }
          }
        `,
        { id },
      )

      if (result?.movementById) {
        return parseDatesInMovementGqlResponse(result.movementById, timezone ?? DEFAULT_TIMEZONE)
      } else {
        reportError(new Error('movementById does not exist'), 'error', { result, id })
        return undefined
      }
    },
    {
      queryName: 'useFetchMovementById',
      enabled: Boolean(id) && Boolean(timezone),
      onError: reportGqlError,
    },
  )

export const useFetchMovementsByDate = (
  start: Date,
  end: Date,
  timezone: string | undefined,
  branchId: number | undefined,
) =>
  useQuery<ServerMovementType[]>(
    [MOVEMENTS_QUERY_KEY, { start, end, branchId }],
    async () => {
      const result = await request(
        gql`
          ${movementFragment}
          query ($start: timestamptz!, $end: timestamptz!, $branchId: Int!) {
            movements(
              order_by: { createdAt: desc }
              where: { createdAt: { _gt: $start, _lt: $end }, branchId: { _eq: $branchId } }
            ) {
              ...movementType
            }
          }
        `,
        { start, end, branchId },
      )

      if (Array.isArray(result?.movements)) {
        return result.movements.map(m =>
          parseDatesInMovementGqlResponse(m, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('movementsByDate is not an array'), 'error', { result, start, end })
        return []
      }
    },
    {
      queryName: 'useFetchMovementsByDate',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
