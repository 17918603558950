import { ServerStorageType } from '@expane/data'
import {
  InputLabel,
  SelectDropdown,
  SelectDropDownItem,
  SelectDropdownProps,
  Tag,
} from '@expane/ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface StorageSelectDropdownProps
  extends Omit<SelectDropdownProps<SelectDropDownItem>, 'items'> {
  storages: ServerStorageType[] | undefined
  defaultStorageId: number | undefined
}

export const StorageSelectDropdown: FC<StorageSelectDropdownProps> = ({
  storages,
  defaultStorageId,
  className,
  label,
  required,
  ...restProps
}) => {
  const { t } = useTranslation()

  const transformedStorages = useTransformStoragesForSelectDropdown(storages, defaultStorageId)

  const isThisDefaultStorage = restProps.value === defaultStorageId

  return (
    <div className={className}>
      {label && (
        <div className="flex items-start">
          <InputLabel label={label} required={required} />
          {isThisDefaultStorage && (
            <Tag name={t('default')} color="primary-300" className="ml-0.5 -mt-0.5 inline-block" />
          )}
        </div>
      )}

      <SelectDropdown items={transformedStorages} {...restProps} />
    </div>
  )
}

const useTransformStoragesForSelectDropdown = (
  storages: ServerStorageType[] | undefined,
  defaultStorageId: number | undefined,
) => {
  const { t } = useTranslation()

  return useMemo(() => {
    const items: SelectDropDownItem[] = []

    for (const storage of storages ?? []) {
      if (storage.id === defaultStorageId)
        items.unshift({ ...storage, tag: { name: t('default'), color: 'primary-300' } })
      else items.push(storage)
    }

    return items
  }, [storages, defaultStorageId, t])
}
