import { gql } from 'graphql-request'

export const planFragment = gql`
  fragment planType on plan {
    id
    name
    monthlyCost
    maxTurnover
    maxEmployees
  }
`
