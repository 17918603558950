import { blobToBase64 } from '@expane/logic/file'
import {
  CloseButton,
  Dialog,
  ErrorMessageType,
  Modal,
  SaveButtonWithoutBillingCheck,
  usePopupOpenState,
  useShowConfirmationPopup,
} from '@expane/ui'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/sentry'
import { ImageCropper, PropsWithImageCropperOptions } from 'ui/ImageCropper'
import { SizeTypes, UploadPhoto } from 'ui/UploadPhoto'

interface Props {
  containerClassName?: string
  size?: SizeTypes
  onChange: (base64File?: string) => void
  defaultPhoto?: string
  disabled?: boolean
  errorMessage?: ErrorMessageType
}

export const EditablePhoto: FC<PropsWithImageCropperOptions<Props>> = ({
  onChange,
  containerClassName,
  defaultPhoto,
  size,
  disabled = false,
  errorMessage,
  cropShape,
  croppedSize,
}) => {
  const { t } = useTranslation()

  const [urlPhoto, setUrlPhoto] = useState(defaultPhoto ?? '')

  const getPhoto = (blob?: Blob) => {
    if (blob) {
      setUrlPhoto(URL.createObjectURL(blob))
      blobToBase64(blob).then(base64File => onChange(base64File))
    }
  }
  const { confirmationModal, setInputImg } = useImageCropPopup({ getPhoto, cropShape, croppedSize })

  const { confirmationModal: confirmDeletePopup, showConfirmation: openDeletePopup } =
    useShowConfirmationPopup()

  return (
    <>
      <UploadPhoto
        containerClassName={containerClassName}
        onSelectFile={file => {
          if (file) {
            setInputImg(file)
          } else {
            openDeletePopup({
              title: t('delete'),
              description: t('photo.deleteConfirmation'),
              onConfirm: () => {
                setUrlPhoto('')
                onChange()
              },
            })
          }
        }}
        urlPhoto={urlPhoto}
        setUrlPhoto={setUrlPhoto}
        disabled={disabled}
        size={size}
        reportErrorHandlerFunction={reportError}
        cropShape={cropShape}
        errorMessage={errorMessage}
      />
      {confirmationModal}
      {confirmDeletePopup}
    </>
  )
}

interface UseImageCropPopupProps {
  getPhoto: (blob?: Blob) => void
}

const useImageCropPopup = ({
  cropShape,
  getPhoto,
  croppedSize,
}: PropsWithImageCropperOptions<UseImageCropPopupProps>) => {
  const [blob, setBlob] = useState<Blob>()
  const [inputImg, setInputImg] = useState<File>()
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const getBlob = (blob: Blob) => {
    setBlob(blob)
  }

  useEffect(() => {
    if (inputImg) openPopup()
  }, [inputImg, openPopup])

  const confirmationModal = isOpen ? (
    <Modal close={closePopup}>
      <Dialog>
        <Dialog.Body>
          <ImageCropper
            getBlob={getBlob}
            inputImg={inputImg}
            cropShape={cropShape}
            className="w-128 h-128"
            croppedSize={croppedSize}
          />
        </Dialog.Body>

        <Dialog.Footer>
          <SaveButtonWithoutBillingCheck
            onClick={() => {
              getPhoto(blob)
              closePopup()
            }}
          />

          <CloseButton onClick={closePopup} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  ) : null

  return {
    confirmationModal,
    setInputImg,
  }
}
