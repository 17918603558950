import { FC } from 'react'
import { useDeclensionOfTimeWithUs } from 'i18n/declensions'
import { useTranslation } from 'react-i18next'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { HistoryBookingUnion, ServerTransactionType, TRANSACTION_TYPES } from '@expane/data'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { PropsWithBranchId } from '@expane/logic/branch'

interface AdditionalInfoProps {
  history?: HistoryBookingUnion[]
  transactions?: ServerTransactionType[]
}

export const AdditionalInfo: FC<PropsWithBranchId<AdditionalInfoProps>> = ({
  history,
  transactions,
  branchId,
}) => {
  const dateFormatting = useDateFormatting()
  const convertToMoney = useConvertNumberToMoneyCode({ branchId })
  const declensionOfTimeWithUs = useDeclensionOfTimeWithUs()

  const { t } = useTranslation()

  if (!history) return null

  const totalVisits = history?.reduce(
    (accumulator, booking) => (!booking.canceledDate ? accumulator + 1 : accumulator),
    0,
  )

  const firstVisit = history?.[history?.length - 1]?.startDate

  const totalSum = transactions?.reduce((accumulator, transaction) => {
    if (
      transaction.type === TRANSACTION_TYPES.payment.id ||
      transaction.type === TRANSACTION_TYPES.paymentInCredit.id
    )
      return accumulator + transaction.amount
    if (transaction.type === TRANSACTION_TYPES.refund.id) return accumulator - transaction.amount

    return accumulator
  }, 0)

  return (
    <div className="w-1/2 text-xs text-gray-500 dark:text-gray-400">
      <div className="flex">
        <p className={itemStyle}>{`${t('totalBookings')}: ${totalVisits}`}</p>
        {totalSum !== 0 && (
          <p className={itemStyle}>
            {`${t('spent')}:
            ${totalSum ? convertToMoney(totalSum) : undefined}`}
          </p>
        )}
      </div>

      {firstVisit && (
        <div className="flex">
          <p className={itemStyle}>{`${t('during')}: ${declensionOfTimeWithUs(firstVisit)}`}</p>
          <p className={itemStyle}>{`${t('firstBooking')}: ${dateFormatting(
            'historyDate',
            firstVisit,
          )}`}</p>
        </div>
      )}
    </div>
  )
}

const itemStyle = 'mr-3 w-1/2'
