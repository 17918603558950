import { Paper } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { AccountsList } from 'pages/AccountsPage/List'
import { ProceedToNextStep, useCreateBusinessSteps } from 'pages/CreateBusinessPage'
import { stepStyle } from 'pages/CreateBusinessPage/logic'
import { FC } from 'react'
import { AccountDialog } from 'widgets/AccountsDialog'
import { SaveButton } from 'widgets/Buttons'

export const AccountsStep: FC = () => {
  const { proceedToNextStep } = useCreateBusinessSteps()

  const { openEditDialog, openCreateDialog, dialog } = useOpenDialog(AccountDialog)

  return (
    <div className={'flex items-end'}>
      <Paper className={stepStyle + ' flex flex-col h-126 w-160'}>
        <div className="h-101">
          <SaveButton onClick={() => openCreateDialog()} className="mb-2" isCreate />

          <div className="h-86">
            <AccountsList onRowClick={account => openEditDialog(account.id)} />
          </div>
        </div>
      </Paper>

      {dialog}
      <ProceedToNextStep className="mt-2" onClick={proceedToNextStep} />
    </div>
  )
}
