import {
  useFetchBranchById,
  useFetchEmployeesGroups,
  useFetchExtendedEmployees,
} from '@expane/data'
import { useShowConfirmationPopup } from '@expane/ui'
import { useTextInputPopup } from 'logic/hooks/popup/useTextInputPopup'
import { observer } from 'mobx-react-lite'
import { BookingCalendarType } from 'pages/BookingsPage'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { PresetSelect } from 'ui/PresetSelect'
import {
  filterPresetsWithArchivedEmployees,
  getBookingCalendarPresetsFromLocalStorage,
  setNewPresetsToLocalStorage,
  TabPreset,
} from './logic'

interface BookingPresetSelectProps {
  value: number | null
  onChange: (value: number | null) => void
  className?: string
  calendarType: BookingCalendarType
  setCalendarType: (type: BookingCalendarType) => void
  employees: { id: number; isFolder: boolean }[]
  setEmployees: (employees: { id: number; isFolder: boolean }[]) => void
  employeeId: number | undefined
  setEmployeeId: (id: number | undefined) => void
  locations: { id: number }[]
  setLocations: (locations: { id: number }[]) => void
  columnsAreShrinked: boolean
  setColumnsAreShrinked: (value: boolean) => void
}

export const BookingPresetSelect: FC<BookingPresetSelectProps> = observer(
  ({
    className = '',
    onChange,
    value,
    setCalendarType,
    calendarType,
    setColumnsAreShrinked,
    columnsAreShrinked,
    setEmployeeId,
    employeeId,
    setEmployees,
    employees,
    setLocations,
    locations,
  }) => {
    const { t } = useTranslation()
    const branchId = store.branch.branchId
    const { data: branch } = useFetchBranchById(branchId)
    const { data: allEmployees } = useFetchExtendedEmployees(branch?.timezone, branchId)
    const { data: allEmployeeGroups } = useFetchEmployeesGroups(branch?.timezone, branchId)
    const [presets, setPresets] = useState<TabPreset[]>([])
    useEffect(() => {
      if (branch === undefined || allEmployees === undefined || allEmployeeGroups === undefined)
        return

      const branchId = branch.id

      const presetsFromStorage = getBookingCalendarPresetsFromLocalStorage(branchId)
      if (presetsFromStorage === null) return

      const filteredPresets = filterPresetsWithArchivedEmployees(
        presetsFromStorage,
        allEmployees,
        allEmployeeGroups,
      )

      if (filteredPresets.length !== presetsFromStorage.length)
        setNewPresetsToLocalStorage(filteredPresets, branchId)

      setPresets(filteredPresets)
    }, [branch, allEmployees, allEmployeeGroups])

    const { inputPopup, showInputPopup } = useTextInputPopup()
    const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

    const handleChange = (value: number) => {
      const preset = presets.find(preset => preset.id === value)

      if (preset) {
        if (preset.type === 'locations') {
          setCalendarType('locations')
          setEmployees(preset.employees ?? [])
          setEmployeeId(undefined)
        }
        if (preset.type === 'employees') {
          setCalendarType('employees')
          setEmployeeId(preset.employeeId)
          setEmployees([])
        }
        setLocations(preset.locations ?? [])
        setColumnsAreShrinked(preset.shrinkColumns ?? false)

        onChange(value)
      }
    }

    const handleCreatePreset = (name: string, closeCallback: () => void) => {
      const presetWithThisName = presets.find(preset => preset.name === name)
      if (presetWithThisName !== undefined) {
        showConfirmation({
          title: t('overwrite'),
          description: t('preset.alreadyExists'),
          onConfirm: () => {
            handleOverwritePreset(presetWithThisName.id, name)
            closeCallback()
          },
        })
        return
      }

      const newPresetId = presets.reduce((id, preset) => Math.max(id, preset.id), 0) + 1
      const newPreset = getNewPreset(newPresetId, name)

      const payload = [...presets, newPreset]
      setPresets(payload)
      onChange(newPresetId)
      if (branch) setNewPresetsToLocalStorage(payload, branch.id)
      closeCallback()
    }

    const handleOverwritePreset = (id: number, name: string) => {
      const newPreset = getNewPreset(id, name)

      const payload = [...presets.filter(preset => preset.id !== id), newPreset]
      onChange(id)
      setPresets(payload)
      if (branch) setNewPresetsToLocalStorage(payload, branch.id)
    }

    const handleDeletePreset = (id: number, closeCallback: () => void) => {
      if (value === id) {
        onChange(null)
        setCalendarType('locations')
        setEmployeeId(undefined)
        setEmployees([])
        setLocations([])
        setColumnsAreShrinked(false)
      }

      const payload = presets.filter(preset => preset.id !== id)
      if (branch) setNewPresetsToLocalStorage(payload, branch.id)
      setPresets(payload)
      closeCallback()
    }

    const getNewPreset = (id: number, name: string) => {
      const newPreset: TabPreset =
        calendarType === 'locations'
          ? {
              id,
              name,
              type: 'locations',
              employees,
              locations,
              shrinkColumns: columnsAreShrinked,
            }
          : {
              id,
              name,
              type: 'employees',
              employeeId,
              locations,
              shrinkColumns: columnsAreShrinked,
            }
      return newPreset
    }

    const createButtonDisabled =
      calendarType === 'locations' &&
      columnsAreShrinked === false &&
      employeeId === undefined &&
      employees.length === 0 &&
      locations.length === 0

    return (
      <>
        <PresetSelect
          value={value}
          onCreate={closeCallback =>
            showInputPopup({
              title: t('preset.new'),
              description: t('preset.enterName'),
              onConfirm: name => handleCreatePreset(name, closeCallback),
            })
          }
          onChange={handleChange}
          onDelete={(preset, closeCallback) =>
            showConfirmation({
              title: t('delete'),
              description: t('preset.deleteConfirmation', { name: preset.name }),
              onConfirm: () => handleDeletePreset(preset.id, closeCallback),
            })
          }
          className={className}
          items={presets}
          createButtonDisabled={createButtonDisabled}
        />
        {confirmationModal}
        {inputPopup}
      </>
    )
  },
)
