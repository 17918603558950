import { CountedNotPaidSalaryType, CountedSalaryType } from '@expane/logic/salaryIssues/logic'

export const sumTotalDebtSalary = (salaries: Array<{ debtSalary: CountedNotPaidSalaryType }>) =>
  salaries.reduce((sum, { debtSalary: { totalSum } }) => sum + totalSum, 0)

export const sumTotalPaidSalary = (salaries: Array<{ paidSalary: number }>) =>
  salaries.reduce((sum, { paidSalary }) => sum + paidSalary, 0)

export const sumTotalAccruedSalary = (
  salaries: Array<CountedSalaryType | { accruedSalary: number }>,
) => salaries.reduce((sum, { accruedSalary }) => sum + accruedSalary, 0)
