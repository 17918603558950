import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PermissionPageBlockProps, allSelectedPermissions } from '../logic'
import { PermissionBlock, PermissionBlockTitle, PermissionCheckbox } from '.'
import { PermissionPageRowProps } from '@expane/logic/permission/logic'

export const FinancesBlock: FC<PermissionPageBlockProps> = ({
  control,
  checkRelation,
  checkRelationBulk,
  isItOwnerRole,
  setValue,
}) => {
  const { t } = useTranslation()

  const onSelectAll = () => {
    checkRelationBulk({
      permissions: [
        'permissions.account.get',
        'permissions.account.set',
        'permissions.transaction.betweenAccounts',
        'permissions.transaction.editPast',
        'permissions.transaction.get',
        'permissions.transaction.set',
        'permissions.transaction.setRefund',
        'permissions.salary.get',
        'permissions.salary.set',
        'permissions.revenueReason.set',
        'permissions.expensesReason.set',
      ],
      onConfirm: () => {
        setValue('permissions.account', allSelectedPermissions.account)
        setValue('permissions.transaction', allSelectedPermissions.transaction)
        setValue('permissions.salary', allSelectedPermissions.salary)
        setValue('permissions.revenueReason', allSelectedPermissions.revenueReason)
        setValue('permissions.expensesReason', allSelectedPermissions.expensesReason)
      },
    })
  }

  return (
    <>
      <PermissionBlockTitle onSelectAll={onSelectAll} title={t('finances')} />

      <AccountPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <TransactionPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <SalaryPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <RevenueReasonPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
      <ExpenseReasonPermissionBlock
        control={control}
        checkRelation={checkRelation}
        isItOwnerRole={isItOwnerRole}
      />
    </>
  )
}

const AccountPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('accounts')}>
      <Controller
        name="permissions.account.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.account.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.account.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.account.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.account.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.account.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const TransactionPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('transactions')}>
      <Controller
        name="permissions.transaction.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.transaction.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.transaction.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.transaction.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.transaction.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.transaction.set')}
            hint={t('permissions.transaction.setHint')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.transaction.betweenAccounts"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.transaction.betweenAccounts',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.transaction.betweenAccounts')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.transaction.editPast"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.transaction.editPast',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.transaction.editPast')}
            hint={t('permissions.transaction.editPastHint')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.transaction.setRefund"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.transaction.setRefund',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.transaction.setRefund')}
            hint={t('permissions.transaction.setRefundHint')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const SalaryPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('salary.name')}>
      <Controller
        name="permissions.salary.get"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.salary.get',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.salary.get')}
            disabled={isItOwnerRole}
          />
        )}
      />
      <Controller
        name="permissions.salary.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.salary.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.salary.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const RevenueReasonPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('revenueReason')}>
      <Controller
        name="permissions.revenueReason.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.revenueReason.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.revenueReason.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}

const ExpenseReasonPermissionBlock: FC<PermissionPageRowProps> = ({
  control,
  checkRelation,
  isItOwnerRole,
}) => {
  const { t } = useTranslation()

  return (
    <PermissionBlock title={t('expenseReason')}>
      <Controller
        name="permissions.expensesReason.set"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PermissionCheckbox
            onChange={event => {
              const checked = event.target.checked

              checkRelation({
                permission: 'permissions.expensesReason.set',
                onConfirm: () => onChange(checked),
                isSelect: checked,
              })
            }}
            checked={value}
            label={t('permissions.expenseReason.set')}
            disabled={isItOwnerRole}
          />
        )}
      />
    </PermissionBlock>
  )
}
