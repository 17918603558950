import { FC } from 'react'
import { ServerSupplierMutualSettlementType } from '@expane/data'
import { useTranslation } from 'react-i18next'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { getMutualSettlementsInfoFromSupplier, Period } from '@expane/logic/suppliers'
import { PropsWithBranchId } from '@expane/logic/branch'

interface SupplierMutualSettlementsInfoBlockProps {
  supplier: ServerSupplierMutualSettlementType
  period?: Period
  className?: string
}

export const SupplierMutualSettlementsInfoBlock: FC<
  PropsWithBranchId<SupplierMutualSettlementsInfoBlockProps>
> = ({ supplier, period, className, branchId }) => {
  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const { startBalance, endBalance, expense, arrival } = getMutualSettlementsInfoFromSupplier(
    period,
    supplier,
  )

  return (
    <div className={className + ' flex text-md text-gray-500 dark:text-gray-400'}>
      <p className={itemStyle}>
        {t('initialBalance')}: {convertNumberToMoney(startBalance || Math.abs(startBalance))}
      </p>
      <p className={itemStyle}>
        {t('coming')}: {convertNumberToMoney(arrival)}
      </p>
      <p className={itemStyle}>
        {t('expense')}: {convertNumberToMoney(expense)}
      </p>
      <p className={itemStyle}>
        {t('endBalance')}: {convertNumberToMoney(endBalance)}
      </p>
    </div>
  )
}

const itemStyle = 'mr-4'
