import { Input, modalsHTMLElement, TagType, useShortCut } from '@expane/ui'
import {
  KeyboardEvent as ReactKeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import { IoCaretDown, IoCaretForward, IoSearch } from 'react-icons/io5'
import { FolderClose, FolderOpen } from 'ui/Icons'
import { sortTreeMenuItemsByAlphabet } from 'ui/TreeMenu/logic.common'
import {
  defaultSearchFilterFunction,
  findAllParentsForSelectedItems,
  getAllFoldersFromItems,
  getNewFocusedItemOnArrowDown,
  getNewFocusedItemOnArrowUp,
  markFoldersWithInactive,
} from './internalLogic'
import { TreeFolder } from './TreeFolder'
import { TreeItem } from './TreeItem'

// workaround for typescript error ts(2615)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface TreeMenuItemArray extends Array<any> {
  [key: number]: Omit<TreeMenuItem, ''>
}

export interface TreeMenuItem {
  id: number
  name: string
  isFolder?: boolean
  nodes?: TreeMenuItemArray
  Icon?: IconType
  parentId?: number
  inactiveReason?: string
  warningMessage?: string
  status?: 'active' | 'inactiveNodes' | 'inactive'
  quantity?: number
  price?: number
  disabled?: boolean
  tag?: TagType
}

export interface TreeMenuSelectedItem {
  id: number
  isFolder?: boolean
  parentId?: number
  quantity?: number
}

type GeneralTreeMenuProps<MTreeMenuItem extends TreeMenuItem> = {
  className?: string
  defaultCollapseIcon?: IconType
  defaultExpandIcon?: IconType
  defaultItemIcon?: IconType
  items: MTreeMenuItem[] | undefined
  onSelected: (item: MTreeMenuItem, isInactive: boolean) => void
  onEditFolder?: (folder: TreeMenuItem) => void
  onEditItem?: (item: TreeMenuItem) => void
  isSearch?: boolean
  inputPlaceholder?: string
  disabled?: boolean
  alwaysOpen?: boolean
  openSwitch?: boolean
  showQuantity?: boolean
  showHighlight?: boolean
  customModal?: HTMLElement | null
  isItemsTruncate?: boolean
  customInactiveReasonRender?: (item: MTreeMenuItem) => ReactElement | null
  searchInputAutoFocus?: boolean
  priceConversionHandlerFunction?: (value: number) => string
  customSearchFilterFunction?: (
    items: MTreeMenuItem[] | undefined,
    searchValue: string,
  ) => MTreeMenuItem[]
  sortByAlphabet?: boolean
}

type SelectedProps =
  | { type: 'MultiPickMode'; selected: TreeMenuSelectedItem[] | undefined }
  | { type: 'SinglePickMode'; selected: TreeMenuSelectedItem | undefined }

export type TreeMenuProps<MTreeMenuItem extends TreeMenuItem> = SelectedProps &
  GeneralTreeMenuProps<MTreeMenuItem>

export const TreeMenu = <MTreeMenuItem extends TreeMenuItem>(
  props: TreeMenuProps<MTreeMenuItem>,
) => {
  const {
    className,
    defaultCollapseIcon = IoCaretDown,
    defaultExpandIcon = IoCaretForward,
    defaultItemIcon,
    items = [],
    onSelected,
    onEditFolder,
    onEditItem,
    isSearch = true,
    inputPlaceholder,
    disabled = false,
    alwaysOpen = false,
    openSwitch = false,
    showHighlight = true,
    showQuantity = false,
    customModal,
    isItemsTruncate = true,
    customInactiveReasonRender,
    searchInputAutoFocus = true,
    priceConversionHandlerFunction,
    customSearchFilterFunction,
    sortByAlphabet = true,
  } = props

  const singlePickMode = props.type === 'SinglePickMode'

  let selectedItems: TreeMenuSelectedItem[] = []

  if (props.type === 'MultiPickMode') {
    if (props.selected) selectedItems = props.selected
  }
  if (props.type === 'SinglePickMode') {
    if (props.selected) selectedItems = [props.selected]
  }

  const { t } = useTranslation()

  const translatedInputPlaceholder = inputPlaceholder ?? t('search')

  const markedItems = markFoldersWithInactive(items)

  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [searchValue, setSearchValue] = useState<string>('')

  const searchFilterFunction = customSearchFilterFunction ?? defaultSearchFilterFunction
  const filteredItems = searchFilterFunction(markedItems, searchValue)

  const sortedItems = useMemo(
    () => (sortByAlphabet ? sortTreeMenuItemsByAlphabet(filteredItems) : [...filteredItems]),
    [filteredItems, sortByAlphabet],
  )

  const [focusedItem, setFocusedItem] = useState<MTreeMenuItem | undefined>(undefined)

  const [openedFolders, setOpenedFolders] = useState<MTreeMenuItem[]>(
    findAllParentsForSelectedItems(filteredItems, selectedItems),
  )

  const handleKeyDown = useCallback(
    (event: ReactKeyboardEvent) => {
      if (event.code === 'ArrowRight' && focusedItem && Boolean(focusedItem.isFolder))
        setOpenedFolders([...openedFolders, focusedItem])

      if (event.code === 'ArrowLeft' && focusedItem && Boolean(focusedItem.isFolder))
        setOpenedFolders(
          openedFolders.filter(
            folder => !(folder.id === focusedItem.id && folder.parentId === focusedItem.parentId),
          ),
        )

      if (event.code === 'ArrowDown') {
        const newFocusedItem = getNewFocusedItemOnArrowDown({
          focusedItem,
          openedFolders,
          allTree: sortedItems,
          alwaysOpen,
        })
        if (newFocusedItem === undefined) {
          if (focusedItem) {
            inputRef.current?.focus()
            containerRef.current?.parentElement?.scrollIntoView({ block: 'start' })
          }
          if (!focusedItem) return setFocusedItem(sortedItems[0])
        }

        setFocusedItem(newFocusedItem)
      }

      if (event.code === 'ArrowUp') {
        const newFocusedItem = getNewFocusedItemOnArrowUp<MTreeMenuItem>({
          focusedItem,
          allTree: sortedItems,
          openedFolders,
        })

        if (newFocusedItem === undefined) {
          inputRef.current?.focus()
          containerRef.current?.parentElement?.scrollIntoView({ block: 'start' })
        }

        setFocusedItem(newFocusedItem)
      }

      if (event.code === 'Enter' && focusedItem) {
        if (singlePickMode && focusedItem.isFolder) {
          if (onEditFolder) return onEditFolder(focusedItem)
          else return
        }

        if (!focusedItem.disabled) onSelected(focusedItem, Boolean(focusedItem.inactiveReason))
      }
    },
    [focusedItem, openedFolders, sortedItems, alwaysOpen, singlePickMode, onSelected, onEditFolder],
  )

  useEffect(() => {
    if (alwaysOpen) setOpenedFolders([])
  }, [alwaysOpen])

  const allFoldersFromItems = useMemo(() => getAllFoldersFromItems(items), [items])

  useShortCut(['ShiftLeft', 'Minus'], () => setOpenedFolders([]))
  useShortCut(['ShiftLeft', 'Equal'], () => setOpenedFolders(allFoldersFromItems))

  const isNoFolderInTree = sortedItems.every(item => !item.isFolder)

  return (
    <div className={className} onMouseLeave={() => setFocusedItem(undefined)}>
      <div
        ref={containerRef}
        tabIndex={0}
        className="flex flex-col relative"
        onKeyDown={handleKeyDown}
      >
        {isSearch && (
          <div className="flex mx-1 mb-1">
            <Input
              ref={inputRef}
              disabled={disabled}
              placeholder={translatedInputPlaceholder}
              value={searchValue}
              onChange={event => {
                // если начали вводить поиск, то открываем все папки
                if (event.target.value.length > 0 && searchValue.length === 0) {
                  setOpenedFolders(allFoldersFromItems)
                }
                // если очистили поиск, то закрываем все папки
                if (event.target.value.length === 0 && searchValue.length > 0) {
                  setOpenedFolders([])
                }

                setSearchValue(event.target.value)
              }}
              autoFocus={searchInputAutoFocus}
              containerClassName="grow pr-1"
              height="medium"
              autoComplete="off"
              Icon={IoSearch}
            />
            {openSwitch && (
              <button
                className="text-primary-300 hover:text-primary-600"
                onClick={() => {
                  if (openedFolders.length === 0) setOpenedFolders(allFoldersFromItems)
                  else setOpenedFolders([])
                }}
              >
                {openedFolders.length !== 0 ? (
                  <div className="flex items-center">
                    <IoCaretDown size="1rem" className="mr-0.5" /> <FolderOpen size="1.5rem" />
                  </div>
                ) : (
                  <div className="flex items-center">
                    <IoCaretForward size="1rem" className="mr-0.5" /> <FolderClose size="1.5rem" />
                  </div>
                )}
              </button>
            )}
          </div>
        )}
        {sortedItems.length === 0 && (
          <span className="text-sm text-center text-gray-500 flex items-left px-2 pt-2 pb-3 cursor-pointer">
            {items.length === 0 ? t('noItems') : t('nothingFoundForRequest')}
          </span>
        )}
        {sortedItems.map(item => {
          if (item.isFolder) {
            return (
              <TreeFolder
                key={item.id + 'folder'}
                item={item}
                modalElement={customModal ?? modalsHTMLElement}
                disabled={disabled}
                Icon={defaultItemIcon}
                defaultCollapseIcon={defaultCollapseIcon}
                defaultExpandIcon={defaultExpandIcon}
                onClick={onSelected}
                onEditFolder={onEditFolder}
                onEditItem={onEditItem}
                isSelectedTreeFolder={selectedItems.some(
                  el => el.id === item.id && Boolean(el.isFolder),
                )}
                selectedItems={selectedItems}
                level={0}
                setOpenedFolders={setOpenedFolders}
                openedFolders={openedFolders}
                singlePickMode={singlePickMode}
                isAlwaysOpen={alwaysOpen}
                focusedItem={focusedItem}
                setFocusedItem={setFocusedItem}
                showQuantity={showQuantity}
                isItemsTruncate={isItemsTruncate}
                customInactiveReasonRender={customInactiveReasonRender}
                showHighlight={showHighlight}
                priceConversionHandlerFunction={priceConversionHandlerFunction}
              />
            )
          } else {
            return (
              <TreeItem
                key={item.id + 'item'}
                modalElement={customModal ?? modalsHTMLElement}
                item={item}
                Icon={defaultItemIcon}
                onClick={onSelected}
                isSelectedTreeItem={selectedItems.some(
                  el => el.id === item.id && el.parentId === item.parentId && !el.isFolder,
                )}
                level={0}
                disabled={disabled}
                focusedItem={focusedItem}
                onEditItem={onEditItem}
                setFocusedItem={setFocusedItem}
                showQuantity={showQuantity}
                quantity={
                  selectedItems.find(
                    el => el.id === item.id && el.parentId === item.parentId && !el.isFolder,
                  )?.quantity
                }
                showIcon={!isNoFolderInTree}
                isItemsTruncate={isItemsTruncate}
                customInactiveReasonRender={customInactiveReasonRender}
                priceConversionHandlerFunction={priceConversionHandlerFunction}
              />
            )
          }
        })}
      </div>
    </div>
  )
}
