import {
  CARD_TYPES,
  CardType,
  SubscriptionInfo,
  useFetchCardsByClientId,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { addDays, compareDesc } from '@expane/date'
import { checkIsCardActivated, getActiveSubscriptions } from '@expane/logic/cards'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { permissions } from '@expane/logic/permission'
import { InputLabel, Paper, Spinner } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { store } from 'store'

interface InfoTabClientSubscriptionProps {
  className?: string
  clientId: number | null
  height?: 'normal' | 'small'
}

export const InfoTabClientSubscription: FC<InfoTabClientSubscriptionProps> = ({
  className,
  clientId,
  height = 'normal',
}) => {
  const { data: myPermissions } = useFetchMyPermissions()
  const { getModuleSetting } = useBusinessModulesSettings()
  const isSubscriptionsEnabled = getModuleSetting('subscriptions')

  const areCardsGetAllowed = myPermissions?.includes(permissions.card.get)

  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: cards, isFetching } = useFetchCardsByClientId(clientId, timezone, branchId)

  const { t } = useTranslation()

  const paperHeight = height === 'normal' ? 'h-52' : 'h-36'

  if (!areCardsGetAllowed) return null
  if (!isSubscriptionsEnabled) return null

  if (isFetching || !timezone)
    return <InfoTabClientSubscriptionPlaceholder className={className} height={height} />

  const clientSubscriptions = areCardsGetAllowed
    ? cards?.filter(card => card.type === CARD_TYPES.subscription) ?? []
    : []

  const activeSubscriptions = getActiveSubscriptions(clientSubscriptions, timezone).sort((a, b) => {
    if (!a.activatedAt) return 1
    if (!b.activatedAt) return -1
    return compareDesc(a.activatedAt, b.activatedAt)
  })

  const showLastVisitMessage = checkLastVisitMessage(activeSubscriptions)

  return (
    <div className={'flex flex-col text-sm ' + className}>
      <div className="flex justify-between items-center">
        <InputLabel label={t('subscriptions')} />

        {showLastVisitMessage ? (
          <p className="text-xs text-error-500 flex items-center mb-2">
            <IoAlertCircleOutline size="1rem" className="mr-1" /> {t('lastVisitBySubscription')}
          </p>
        ) : null}
      </div>

      <Paper
        className={`${
          activeSubscriptions.length === 0 ? 'w-full flex-centered ' : ' '
        } ${paperHeight} overflow-auto divide-y divide-table`}
      >
        {activeSubscriptions.length === 0 && (
          <span className="text-sm text-gray-300">{t('noActiveSubscriptions')}</span>
        )}

        {activeSubscriptions.map(fs => (
          <InfoTabClientSubscriptionItem clientSubscription={fs} key={fs.id} />
        ))}
      </Paper>
    </div>
  )
}

export const InfoTabClientSubscriptionPlaceholder: FC<
  Omit<InfoTabClientSubscriptionProps, 'clientId'>
> = ({ className, height = 'normal' }) => {
  const { t } = useTranslation()
  const paperHeight = height === 'normal' ? 'h-52' : 'h-36'

  const { getModuleSetting } = useBusinessModulesSettings()
  const isSubscriptionsEnabled = getModuleSetting('subscriptions')

  if (!isSubscriptionsEnabled) return null

  return (
    <div className={'flex flex-col text-sm ' + className}>
      <InputLabel label={t('subscriptions')} />

      <Paper className={`${paperHeight} overflow-auto divide-y divide-primary-100`}>
        <Spinner expandCentered />
      </Paper>
    </div>
  )
}

interface InfoTabClientSubscriptionItemProps {
  clientSubscription: CardType
}

const InfoTabClientSubscriptionItem: FC<InfoTabClientSubscriptionItemProps> = ({
  clientSubscription,
}) => {
  const { activatedAt, cardTemplate, cardPeriod, subscriptionInfo } = clientSubscription

  const { t } = useTranslation()

  const dateFormatting = useDateFormatting()

  const isCardActivated = checkIsCardActivated(activatedAt)

  return (
    <div className="p-2">
      <div className="flex items-center justify-between">
        <span className="text-sm font-medium text-gray-600 dark:text-gray-400 truncate w-7/12">
          {cardTemplate.name}
        </span>
        {isCardActivated && activatedAt ? (
          <span className="text-xs text-main-color text-right">
            {cardPeriod > 0 && // if no period
              `${t('until')} ${dateFormatting('historyDate', addDays(activatedAt, cardPeriod))}`}
          </span>
        ) : (
          <p className="text-gray-500 text-right text-xs font-medium">
            {t('subscriptionStatus.notActivated')}
          </p>
        )}
      </div>

      <div className="flex justify-between mt-1">
        <div className="w-7/12">
          {cardTemplate.cardTemplateServices.map(ss => {
            return (
              <div className="flex justify-between pl-1.5 text-xs" key={ss.serviceId}>
                <div className="flex items-center">
                  <IoAlertCircleOutline
                    size="1rem"
                    className={`mr-1 ${
                      checkLastVisitByService(subscriptionInfo, ss.serviceId)
                        ? 'text-error-500'
                        : 'text-main-color'
                    }`}
                  />
                  <span className="leading-4 pr-2 truncate mr-2 text-main-color">
                    {ss.service.name}
                  </span>
                </div>

                <span className="text-main-color">
                  {subscriptionInfo[ss.serviceId].used}/{subscriptionInfo[ss.serviceId].quantity}
                </span>
              </div>
            )
          })}
        </div>

        {isCardActivated ? (
          <p className="text-primary-500 text-xs font-medium text-right">
            {t('subscriptionStatus.activated')}
          </p>
        ) : null}
      </div>
    </div>
  )
}

const checkLastVisitByService = (subscriptionInfo: SubscriptionInfo, serviceId: number) => {
  const isLast = subscriptionInfo[serviceId].used + 1 === subscriptionInfo[serviceId].quantity
  const isOver = subscriptionInfo[serviceId].used === subscriptionInfo[serviceId].quantity

  return isLast || isOver
}

const checkLastVisitMessage = (clientSubscriptions: CardType[]): boolean => {
  for (const clientSubscription of clientSubscriptions) {
    if (
      Object.values(clientSubscription.subscriptionInfo).some(
        info => info.used + 1 === info.quantity,
      ) &&
      !clientSubscription.canceledDate
    )
      return true
  }

  return false
}
