import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import {
  calcCashOut,
  calcClientsDeposits,
  calcClientsPayments,
  calcNotPaidCredit,
  calcOtherBusinessExpenses,
  calcOtherBusinessRevenue,
  calcProductsPurchase,
  calcProductsReturn,
  calcRefundsExpenses,
  calcSalariesExpenses,
  calcTransportCosts,
} from '@expane/logic/finances/calculation'
import { getBankExpensesTransactions } from '@expane/logic/finances/filters'
import { calcWriteOffMovementProductsPrice, convertMovementsQuantity } from '@expane/logic/movement'
import { calcValues } from '@expane/logic/utils'
import { Paper, PlaceholderString } from '@expane/ui'
import { ReportProps } from 'pages/ReportsPage'
import { ExpensesList } from 'pages/ReportsPage/RevenueExpensesReport/ExpensesList'
import { RevenueList } from 'pages/ReportsPage/RevenueExpensesReport/RevenueList'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const RevenueExpensesReport: FC<PropsWithBranchId<ReportProps>> = ({
  transactions,
  movements,
  isLoading,
  branchId,
}) => {
  const { t } = useTranslation()

  // положено на счета клиентов (считаем общую сумму депозитов клиентов)
  const clientsDepositTotalValue = calcClientsDeposits(transactions)

  // потрачено со счетов клиентов
  // подсчет в котором исключены все возвраты, учитываются так же продажи в кредит
  const {
    clientsPaymentsTotalValue,
    servicesTotalValue,
    giftCardsTotalValue,
    subscriptionsTotalValue,
    productsTotalValue,
    consumablesTotalValue,
  } = calcClientsPayments(transactions)

  // доходы
  const otherBusinessRevenueTotalValue = calcOtherBusinessRevenue(transactions)

  // расходы
  const salariesTotalValue = calcSalariesExpenses(transactions)
  const refundsTotalValue = calcRefundsExpenses(transactions)
  const otherBusinessExpensesTotalValue = calcOtherBusinessExpenses(transactions)
  const productsPurchaseTotalValue = calcProductsPurchase(transactions)
  const transportCostsTotalValue = calcTransportCosts(transactions)
  const productsReturnTotalValue = calcProductsReturn(transactions)
  const cashOutTotalValue = calcCashOut(transactions)

  // находим транзакции у которых нет "причины" -> сейчас это банковские транзакции и это пока единственный способ их найти
  const bankExpensesTransactions = getBankExpensesTransactions(transactions)

  const bankExpensesTotalValue = bankExpensesTransactions ? calcValues(bankExpensesTransactions) : 0

  const convertedMovements = convertMovementsQuantity(movements)

  const writeOffExpensesTotalValue = calcWriteOffMovementProductsPrice(convertedMovements)

  // подсчитываем ИТОГО
  // всего не погашенных кредитов
  const totalNotPaidCredit = calcNotPaidCredit(transactions)

  // остаток на счетах
  const unspentClientsBalance =
    clientsDepositTotalValue - (clientsPaymentsTotalValue - totalNotPaidCredit) - refundsTotalValue

  // productsReturnTotalValue - логика пока что исключена из проекта
  const totalRevenue =
    clientsDepositTotalValue + otherBusinessRevenueTotalValue + productsReturnTotalValue

  const totalExpenses =
    salariesTotalValue +
    refundsTotalValue +
    otherBusinessExpensesTotalValue +
    productsPurchaseTotalValue +
    transportCostsTotalValue +
    writeOffExpensesTotalValue +
    cashOutTotalValue

  const totalProfit = totalRevenue - totalExpenses

  return (
    <div className="grid grid-rows-1 grid-cols-3 gap-2 h-full">
      <RevenueList
        transactions={transactions}
        clientsDepositTotalValue={clientsDepositTotalValue}
        servicesValue={servicesTotalValue}
        productsTotalValue={productsTotalValue}
        consumablesTotalValue={consumablesTotalValue}
        subscriptionsValue={subscriptionsTotalValue}
        giftCardsValue={giftCardsTotalValue}
        productsReturnTotalValue={productsReturnTotalValue}
        otherBusinessRevenueTotalValue={otherBusinessRevenueTotalValue}
        unspentClientsBalance={unspentClientsBalance}
        isLoading={isLoading}
        branchId={branchId}
      />

      <Paper className="p-4">
        <p className="font-medium my-2 text-primary-500">{`${t('total')}:`}</p>

        <ul className="text-main-color">
          {isLoading ? (
            <>
              <PlaceholderString />
              <PlaceholderString />
              <PlaceholderString />
            </>
          ) : (
            <>
              <ReportTotalItem title={t('revenues')} value={totalRevenue} branchId={branchId} />
              <ReportTotalItem
                title={t('expenses')}
                value={totalExpenses}
                className="border-b border-primary-50 pb-2"
                branchId={branchId}
              />
              <ReportTotalItem
                title={t('profit')}
                value={totalProfit}
                className="pt-2"
                branchId={branchId}
              />
            </>
          )}
        </ul>
      </Paper>

      <ExpensesList
        transactions={transactions}
        clientsPaymentsTotalValue={clientsPaymentsTotalValue}
        totalCredit={totalNotPaidCredit}
        refundsTotalValue={refundsTotalValue}
        salariesTotalValue={salariesTotalValue}
        productsPurchaseTotalValue={productsPurchaseTotalValue}
        transportCostsTotalValue={transportCostsTotalValue}
        otherBusinessExpensesTotalValue={otherBusinessExpensesTotalValue}
        bankExpensesTotalValue={bankExpensesTotalValue}
        writeOffExpensesTotalValue={writeOffExpensesTotalValue}
        cashOutTotalValue={cashOutTotalValue}
        isLoading={isLoading}
        branchId={branchId}
      />
    </div>
  )
}

interface ReportItemProps {
  title: string
  value: number
  className?: string
}

export const ReportTotalItem: FC<PropsWithBranchId<ReportItemProps>> = ({
  title,
  value,
  className,
  branchId,
}) => {
  const convertToMoney = useConvertNumberToMoneyCode({ branchId })
  return (
    <li className={'flex justify-between items-center text-sm font-medium ' + className}>
      <p className="truncate mr-2">{title}</p>
      <p>{convertToMoney(value)}</p>
    </li>
  )
}
