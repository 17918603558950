import { queryClient, request, useMutation, reportGqlError } from '../../api'
import { ServerLeadInsertInput } from '../../generated/graphql-types'
import { gql } from 'graphql-request'
import { LEADS_QUERY_KEY } from './queryKeys'

export function useMutateLeads() {
  return useMutation(
    (
      leadInsertInputs: ServerLeadInsertInput[],
    ): Promise<{
      insertLeads: {
        affectedRows: number
        returning: {
          id: number
          name: string
        }[]
      }
    }> => {
      return request(
        gql`
          mutation ($leadInsertInputs: [lead_insert_input!]!) {
            insertLeads(
              objects: $leadInsertInputs
              on_conflict: { constraint: lead_pkey, update_columns: [name] }
            ) {
              affectedRows: affected_rows
              returning {
                id
                name
              }
            }
          }
        `,
        { leadInsertInputs },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries([LEADS_QUERY_KEY])
      },
    },
  )
}
