import { Input } from '@expane/ui'
import {
  NotificationsBlockProps,
  NotificationSettingsBlock,
} from 'pages/SettingsPage/NotificationsSettings/index'
import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SmsIcon } from 'ui/Icons'
import { PLACEHOLDERS } from '@expane/logic/form'

export const TurboSmsIntegrationSettings: FC<NotificationsBlockProps> = ({ control }) => {
  const { t } = useTranslation()

  return (
    <NotificationSettingsBlock
      title={t('integration.turboSms')}
      icon={<SmsIcon size={'1.3rem'} />}
      className={'mt-2'}
    >
      <Controller
        name="smsToken"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Input
            value={value}
            onChange={onChange}
            placeholder={PLACEHOLDERS.token}
            label={t('key')}
            containerClassName={'mt-2'}
          />
        )}
      />
    </NotificationSettingsBlock>
  )
}
