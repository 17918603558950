import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

export const NotificationsInstruction = () => {
  const { t } = useTranslation()

  return (
    <div>
      <p className={'mt-4 italic '}>{t('landingSettingsInstruction.notifications.2')}</p>

      <p className={'mt-4'}>
        {t('landingSettingsInstruction.notifications.description.1')}{' '}
        <NavLink to={NOTIFICATIONS_SETTINGS_ROUTE} className={'underline cursor-pointer'}>
          {t('landingSettingsInstruction.notifications.description.2')}
        </NavLink>
        <span className={'ml-1'}>{t('landingSettingsInstruction.notifications.1')}</span>
      </p>

      <p className={'mt-4'}>{t('landingSettingsInstruction.notifications.template.1')}</p>
      <p>{t('landingSettingsInstruction.notifications.template.2')}</p>
      <p>{t('landingSettingsInstruction.notifications.template.3')}</p>
    </div>
  )
}

const NOTIFICATIONS_SETTINGS_ROUTE = '/settings/notifications'
