import {
  ServerAccountType,
  ServerProductType,
  TRANSACTION_TYPES,
  TransactionInsertInput,
} from '@expane/data'
import { TFunction } from 'i18next'

export type ClientDepositTransactionInputDto = Pick<
  TransactionInsertInput,
  'toAccountId' | 'fromAccountId' | 'type' | 'typeVariation' | 'clientId' | 'amount' | 'branchId'
>

type ServiceDto = {
  serviceProducts: {
    productId: number
    quantity: number
  }[]
  quantity?: number
}

export enum PAYMENT_OPTION {
  oneAccount = 1,
  multipleAccounts,
}

export interface PaymentOption {
  id: PAYMENT_OPTION
  name: string
}

export interface PaymentToAccount {
  // which accounts to use in multi account mode
  id: number
  value: string
}

export const PAYMENT_OPTIONS: PaymentOption[] = [
  { id: PAYMENT_OPTION.oneAccount, name: 'paymentOption.oneAccount' },
  { id: PAYMENT_OPTION.multipleAccounts, name: 'paymentOption.multipleAccounts' },
]

export const CLIENT_ACCOUNT_ID = -1

export const generateOneAccountDepositTransactionsDto = ({
  accountId,
  amount,
  clientId,
  commission,
  branchId,
}: {
  commission: number | null
  accountId: number
  amount: number
  clientId: number
  branchId: number
}) => {
  const result: ClientDepositTransactionInputDto[] = []

  const depositTransaction: ClientDepositTransactionInputDto = {
    toAccountId: accountId,
    type: TRANSACTION_TYPES.deposit.id,
    amount,
    clientId,
    typeVariation: TRANSACTION_TYPES.deposit.variations.depositDuringPayment.id,
    branchId,
  }
  result.push(depositTransaction)

  if (commission) {
    const commissionAmount = (amount / 100) * commission

    const commissionTransaction: ClientDepositTransactionInputDto = {
      fromAccountId: accountId,
      type: TRANSACTION_TYPES.expenses.id,
      amount: commissionAmount,
      branchId,
    }
    result.push(commissionTransaction)
  }

  return result
}

export const generateSeveralAccountDepositTransactionsDto = ({
  paymentToAccounts,
  standingAccounts,
  clientId,
  branchId,
}: {
  clientId: number
  // how much to deposit to each account
  paymentToAccounts: {
    id: number
    value: string
  }[]
  standingAccounts: ServerAccountType[] // accounts info
  branchId: number
}) => {
  const result: ClientDepositTransactionInputDto[] = []
  const accountsForPayment = paymentToAccounts.filter(
    // filtering all accounts with actual value provided and not client account
    pa => Number(pa.value) > 0 && pa.id !== CLIENT_ACCOUNT_ID,
  )

  for (const accountDto of accountsForPayment) {
    const accountInfo = standingAccounts.find(accountInfo => accountInfo.id === accountDto.id)
    if (!accountInfo) throw new Error('no account in array with this id: ' + accountDto.id)

    const transactionsDto = generateOneAccountDepositTransactionsDto({
      accountId: accountDto.id,
      amount: Number(accountDto.value),
      clientId,
      commission: accountInfo.commission ?? null,
      branchId,
    })
    result.push(...transactionsDto)
  }

  return result
}

export const calcDebtAmount = ({
  byClientBalance,
  clientBalance,
  paymentAmount,
  paymentAmounts,
  severalAccounts,
}: {
  byClientBalance: boolean
  clientBalance: number
  paymentAmount: number
  paymentAmounts: { id: number; value: string }[]
  severalAccounts: boolean
}) => {
  // if we pay only by client balance
  if (!severalAccounts) {
    return calcDebtAmountWhenOneAccount({ byClientBalance, clientBalance, paymentAmount })
  }

  // several payments
  const clientPaymentAmount = paymentAmounts.find(payment => payment.id === CLIENT_ACCOUNT_ID)
  if (!clientPaymentAmount) return 0

  return Number(clientPaymentAmount.value) - clientBalance
}

export const calcDebtAmountWhenOneAccount = ({
  byClientBalance,
  clientBalance,
  paymentAmount,
}: {
  byClientBalance: boolean
  clientBalance: number
  paymentAmount: number
}) => {
  if (byClientBalance) return paymentAmount - clientBalance

  return 0
}

export const getConsumablesForServices = (
  products: ServerProductType[],
  services: ServiceDto[],
) => {
  const consumables: {
    productId: number
    quantity: number
  }[] = []

  for (const service of services) {
    if (!service.serviceProducts) continue

    const serviceQuantity = service.quantity ?? 1
    for (const serviceProduct of service.serviceProducts) {
      consumables.push({
        productId: serviceProduct.productId,
        quantity: serviceProduct.quantity * serviceQuantity,
      })
    }
  }

  const consumablesQuantity: Record<number, number> = {}

  consumables.forEach(consumable => {
    if (!consumablesQuantity[consumable.productId]) {
      consumablesQuantity[consumable.productId] = consumable.quantity
    } else {
      consumablesQuantity[consumable.productId] += consumable.quantity
    }
  })

  const result = Object.keys(consumablesQuantity).map(el => {
    return {
      productId: Number(el),
      quantity: consumablesQuantity[el],
      price: products.find(product => product.id === Number(el))?.price ?? 0,
    }
  })

  return result
}

export const addClientAccountToAccountsForPayment = (
  accounts: ServerAccountType[],
  t: TFunction,
  clientId?: number,
): ServerAccountType[] => {
  return clientId
    ? [
        ...accounts,
        {
          id: CLIENT_ACCOUNT_ID,
          cash: false,
          name: t('fromClientAccount'),
          description: '',
        } as ServerAccountType,
      ]
    : accounts
}

export const spreadPaymentItemsQuantity = <T extends { quantity: number }>(items: T[]) => {
  const result: T[] = []

  for (const item of items) {
    if (item.quantity && item.quantity > 1) {
      for (let i = 0; i < item.quantity; i++) {
        result.push(item)
      }
    } else result.push(item)
  }

  return result
}
