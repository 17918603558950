import { FC, ReactNode } from 'react'
import { IconType } from 'react-icons'

interface EmptyPlaceholderProps {
  Icon?: IconType
  text: string | ReactNode
  size?: 'small' | 'normal'
}

export const EmptyPlaceholder: FC<EmptyPlaceholderProps> = ({ Icon, text, size = 'normal' }) => {
  const textStyle = size === 'normal' ? 'text-xl' : 'text-lg'

  return (
    <div className="flex-1 flex-centered flex-col h-full text-placeholder-color ">
      {Icon && <Icon size="6rem" className="mb-6" />}
      <h2 className={textStyle}>{text}</h2>
    </div>
  )
}
