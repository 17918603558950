import { useGetIsShownProductsByModules } from '@expane/logic/modules'
import { Dialog } from '@expane/ui'
import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ConsumablesBlock } from 'widgets/BookingDialog/ConsumablesBlock'
import { BookingDialogFormValues } from '.'
import { ClientBlock } from './ClientBlock'
import { CommonBlock } from './CommonBlock'
import { ServiceBlock } from './ServiceBlock'

interface BookingDialogBodyProps {
  form: UseFormReturn<BookingDialogFormValues>
  disabled: boolean
  isSomeServicesPaid: boolean
  bookingId: number | undefined
  isCreate: boolean
}

export const BookingDialogBody: FC<BookingDialogBodyProps> = ({
  form,
  disabled,
  isSomeServicesPaid,
  isCreate,
  bookingId,
}) => {
  const { control } = form

  const { isConsumablesEnabled } = useGetIsShownProductsByModules()

  return (
    <Dialog.Body className="w-288 h-120 flex">
      <ClientBlock
        control={control}
        disabled={disabled}
        isSomeServicesPaid={isSomeServicesPaid}
        isCreate={isCreate}
        bookingId={bookingId}
      />
      <div className="w-100">
        <CommonBlock
          form={form}
          disabled={disabled}
          isSomeServicesPaid={isSomeServicesPaid}
          bookingId={bookingId}
          isCreate={isCreate}
        />
        {isConsumablesEnabled && (
          <ConsumablesBlock bookingId={bookingId} control={control} disabled={disabled} />
        )}
      </div>

      <ServiceBlock form={form} disabled={disabled} isCreate={isCreate} bookingId={bookingId} />
    </Dialog.Body>
  )
}
