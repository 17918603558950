import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { transformPersonName } from '@expane/logic/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useFetchClientBalance,
  useFetchClientById,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { store } from 'store'
import { observer } from 'mobx-react-lite'

type Props = {
  clientId: number
  employeeFullName: string | undefined
}

export const ClientEmployeeInfo: FC<Props> = observer(({ clientId, employeeFullName }) => {
  const branchId = store.branch.branchId

  const { t } = useTranslation()
  const convertToMoneyCode = useConvertNumberToMoneyCode({ branchId })

  const { data: clientBalance } = useFetchClientBalance(clientId, branchId)
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: client } = useFetchClientById(clientId, timezone)

  const balanceWithCredit = (clientBalance ?? 0) + (client?.maximumDebt ?? 0)
  return (
    <div className="flex justify-between">
      <div className="flex">
        <div className="mb-2 mr-3">
          <p className="mr-1 text-gray-400 text-sm mb-1">{t('client.name')}:</p>
          <p className="mr-1 text-gray-400 text-sm mb-1">{t('onAccount')}: </p>
        </div>

        <div>
          {client && (
            <p className="text-main-color font-medium text-sm mb-1">
              {transformPersonName(client)}
            </p>
          )}
          {clientBalance !== undefined && (
            <p className="text-main-color font-medium text-sm mb-1">
              {convertToMoneyCode(clientBalance)}
              <span className="text-gray-500 text-xs pl-1">
                ({t('withCreditLimit')}: {convertToMoneyCode(balanceWithCredit)})
              </span>
            </p>
          )}
        </div>
      </div>

      {employeeFullName && (
        <div className="flex">
          <div className="mb-3 mr-3 text-gray-400 text-sm">{t('employee.name')}:</div>

          <div className="text-main-color font-medium text-sm mb-1">{employeeFullName}</div>
        </div>
      )}
    </div>
  )
})
