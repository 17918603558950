import {
  ServerBranchType,
  useCreateAccount,
  useUpdateAccount,
  useUpdateBranchDefaults,
} from '@expane/data'
import { isAccountForPayments } from '@expane/logic/accounts'
import { Button, CloseButton, Dialog } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useAttachFunctionForModal } from 'logic/hooks/useAttachFunctionForModal'
import { FC, MutableRefObject } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SaveButton } from 'widgets/Buttons'
import { AccountsFormValues } from './AccountDialogLogic'

interface Props {
  form: UseFormReturn<AccountsFormValues>
  branch: ServerBranchType
  accountId: number | undefined
  showSetDefaultAccountButton: boolean
  isEditingAllowed: boolean
  isPermissionEditingAllowed: boolean
  closePopups: () => void
  closeDialog: () => void
  onSubmitForm: MutableRefObject<(() => void) | undefined>
}

export const AccountDialogFooter: FC<Props> = ({
  form,
  accountId,
  closePopups,
  closeDialog,
  showSetDefaultAccountButton,
  isEditingAllowed,
  isPermissionEditingAllowed,
  branch,
  onSubmitForm,
}) => {
  const { t } = useTranslation()

  const {
    formState: { isSubmitting, dirtyFields, isDirty },
    handleSubmit,
  } = form
  const { mutateAsync: createMutation } = useCreateAccount()
  const { mutateAsync: updateMutation } = useUpdateAccount()
  const { mutateAsync: updateBranchDefaults } = useUpdateBranchDefaults()

  const [openSnackbar] = useSnackbar()

  const mutateAccount: SubmitHandler<AccountsFormValues> = async data => {
    const {
      name,
      description,
      cash,
      commission,
      forPayments,
      employeeGroups,
      withdrawalToAccountId,
      exchangeAmount,
      withdrawal,
      maximumDebt,
    } = data

    if (accountId) {
      const result = await updateMutation({
        id: accountId,
        accountSetInput: {
          name,
          cash,
          description,
          maximumDebt: cash ? 0 : Number(maximumDebt),
          commission: cash ? 0 : Number(commission),
          forPayments: isAccountForPayments(forPayments),
          withdrawalToAccountId: withdrawal ? withdrawalToAccountId : undefined,
          exchangeAmount: withdrawal && exchangeAmount ? Number(exchangeAmount) : undefined,
        },
        accountEmployeeGroupInsertInputs:
          dirtyFields.employeeGroups && isPermissionEditingAllowed
            ? employeeGroups?.map(({ id }) => ({
                employeeGroupId: id,
                accountId: accountId,
              }))
            : undefined,
      })

      if (result?.updateAccountById) openSnackbar(t('account.updateSuccess'), 'success', 3000)
      else openSnackbar(t('submitError'), 'error', 3000)
    } else {
      const result = await createMutation({
        name,
        cash,
        description,
        maximumDebt: cash ? 0 : Number(maximumDebt),
        commission: cash ? 0 : Number(commission),
        forPayments: isAccountForPayments(forPayments),
        accountEmployeeGroups: {
          data: employeeGroups?.map(({ id }) => ({ employeeGroupId: id })),
        },
        withdrawalToAccountId: withdrawal ? withdrawalToAccountId : undefined,
        exchangeAmount: withdrawal && exchangeAmount ? Number(exchangeAmount) : undefined,
        branchId: branch.id,
      })

      if (result?.insertAccount) openSnackbar(t('account.createSuccess'), 'success', 3000)
      else openSnackbar(t('submitError'), 'error', 3000)
    }

    closeDialog()
  }

  const updateBranchDefaultAccount = async () => {
    if (branch?.id && accountId) {
      const result = await updateBranchDefaults({
        branchId: branch.id,
        branchSetInput: { defaultAccountId: accountId },
      })

      if (result?.updateBranchById) openSnackbar(t('account.updateDefaultSuccess'), 'success', 3000)
      else openSnackbar(t('submitError'), 'error', 3000)

      closeDialog()
    }
  }

  useAttachFunctionForModal(() => {
    if (isEditingAllowed && (!isSubmitting || isDirty)) handleSubmit(mutateAccount)()
  }, onSubmitForm)

  return (
    <Dialog.Footer>
      {isEditingAllowed && (
        <SaveButton
          onClick={handleSubmit(mutateAccount)}
          disabled={isSubmitting || !isDirty}
          spinner={isSubmitting}
          isCreate={!accountId}
        />
      )}

      <CloseButton onClick={closePopups} disabled={isSubmitting} />

      {showSetDefaultAccountButton && (
        <Button
          type="outline"
          disabled={isSubmitting}
          className="mr-auto"
          onClick={updateBranchDefaultAccount}
        >
          {t('setAsDefaultAccount')}
        </Button>
      )}
    </Dialog.Footer>
  )
}
