import {
  ServerClientBriefType,
  useFetchClientsBriefs,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import {
  DeleteButtonCell,
  SelectDropdown,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { transformPersonsForSelect } from 'logic/utils'
import { FC } from 'react'
import { Control, Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline } from 'react-icons/io5'
import { store } from 'store'
import { ClientDialog } from 'widgets/ClientDialog'
import { ClientInfoFormValues } from '.'

interface Props {
  clientId: number | undefined
  control: Control<ClientInfoFormValues>
  className: string
  disabled: boolean
}

export const ClientRelations: FC<Props> = ({ clientId, control, className, disabled }) => {
  const { fields, remove, append } = useFieldArray({ control, name: 'relations' })

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clients } = useFetchClientsBriefs(branchId, timezone)

  const { t } = useTranslation()

  const clientsWithoutCurrentClient = getClientsWithoutCurrentClient(clients, clientId)
  const clientsForSelect = clientsWithoutCurrentClient
    ? transformPersonsForSelect(clientsWithoutCurrentClient)
    : undefined

  const { dialog: clientDialog, openEditDialog: openEditClientDialog } = useOpenDialog(ClientDialog)

  const relationsForSelect = transformRelationForSelect(CLIENT_RELATION_MAP, t)

  return (
    <div className={className + ' flex flex-col h-full overflow-hidden'}>
      {fields.length ? (
        <TableContainer className="max-h-28">
          <TableHeader>
            <tr>
              <TableHeaderCell className="w-80">{t('client.name')}</TableHeaderCell>
              <TableHeaderCell className="w-48">{t('relation')}</TableHeaderCell>

              <th className="cursor-pointer w-6">
                <div className="px-2 flex-centered">
                  <button
                    onClick={() => append({ clientId: undefined, type: undefined })}
                    disabled={disabled}
                  >
                    <IoAddCircleOutline
                      size="1.3rem"
                      className={disabled ? 'text-icon-color' : 'text-primary-500'}
                    />
                  </button>
                </div>
              </th>
            </tr>
          </TableHeader>
          <TableBody>
            {fields.map((relation, index) => (
              <TableRow className="text-sm" key={relation.id}>
                <TableCell>
                  <Controller
                    control={control}
                    name={`relations.${index}.clientId`}
                    rules={{ required: true }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <SelectDropdown
                        value={value}
                        onSelectChange={onChange}
                        items={clientsForSelect?.filter(client => {
                          if (client.id === relation.clientId) return true

                          return !fields.some(relation => relation.clientId === client.id)
                        })}
                        onEditClick={openEditClientDialog}
                        errorMessage={{ isShown: Boolean(error), text: ' ', reserveIndent: false }}
                        height="small"
                        popupHeight="small"
                        disabled={disabled}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    control={control}
                    name={`relations.${index}.type`}
                    rules={{ required: true }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <SelectDropdown
                        value={value}
                        onSelectChange={onChange}
                        items={relationsForSelect}
                        errorMessage={{ isShown: Boolean(error), text: ' ', reserveIndent: false }}
                        height="small"
                        popupHeight="small"
                        disabled={disabled}
                      />
                    )}
                  />
                </TableCell>
                <DeleteButtonCell onClick={() => remove(index)} disabled={disabled} />
              </TableRow>
            ))}
          </TableBody>
          {clientDialog}
        </TableContainer>
      ) : (
        <button
          onClick={() => append({ clientId: undefined, type: undefined })}
          className={`self-end flex items-center ${
            disabled ? 'pointer-events-none text-gray-200' : 'text-gray-500 hover:text-primary-400'
          }`}
          disabled={disabled}
        >
          <IoAddCircleOutline size="1.3rem" className="mr-1" />
          <p> {t('addRelation')}</p>
        </button>
      )}
    </div>
  )
}

type ClientRelationMap = {
  parent: { id: number; name: string }
  spouse: { id: number; name: string }
  sibling: { id: number; name: string }
  child: { id: number; name: string }
}

export const CLIENT_RELATION_MAP: ClientRelationMap = {
  parent: { id: 1, name: 'parent' },
  spouse: { id: 2, name: 'spouse' },
  sibling: { id: 3, name: 'sibling' },
  child: { id: 4, name: 'child' }, // при отправке на сервер инвертируется в parent
}

const getClientsWithoutCurrentClient = (
  clients: ServerClientBriefType[] | undefined,
  clientId: number | undefined,
): ServerClientBriefType[] | undefined => {
  if (!clients) return undefined
  else {
    if (clientId) return clients.filter(c => c.id !== clientId)
    else return clients
  }
}

const transformRelationForSelect = (relationMap: ClientRelationMap, t: (key: string) => string) =>
  Object.values(relationMap).map(({ id, name }) => ({ id, name: t(name) }))
