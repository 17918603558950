import { useFetchBranchById, useFetchSchedules } from '@expane/data'
import { Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC, useRef } from 'react'
import { store } from 'store'
import { TreeMenuItem } from 'ui/TreeMenu'
import { CreateEmployeesSchedulesDialogLogic } from 'widgets/BranchImportEmployeesDialog/CreateEmployeesSchedulesDialogLogic'
import { CreateEmployeesSchedulesDialogPlaceholder } from 'widgets/BranchImportEmployeesDialog/CreateEmployeesSchedulesDialogPlaceholder'

export interface CreateEmployeesSchedulesDialogProps extends Pick<DialogProps, 'closeDialog'> {
  items: TreeMenuItem[]
  closeChooseEmployeesDialog: () => void
}

const CreateEmployeesSchedulesDialog: FC<CreateEmployeesSchedulesDialogProps> = ({
  closeDialog,
  items,
  closeChooseEmployeesDialog,
}) => {
  const branchId = store.branch.branchId

  const { data: currentBranch, isLoading: isLoadingCurrentBranch } = useFetchBranchById(branchId)
  const { data: schedules, isLoading: isLoadingSchedules } = useFetchSchedules()
  const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()

  const isLoading = isLoadingCurrentBranch || isLoadingSchedules || isLoadingMyPermissions
  const isNoData = !currentBranch || !schedules || !myPermissions

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

  return (
    <Modal close={closeDialog}>
      <Dialog>
        {isLoading ? (
          <CreateEmployeesSchedulesDialogPlaceholder closeDialog={closeDialog} />
        ) : (
          <CreateEmployeesSchedulesDialogLogic
            closeChooseEmployeesDialog={closeChooseEmployeesDialog}
            closeDialog={closeDialog}
            currentBranch={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              currentBranch!
            }
            items={items}
            myPermissions={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              myPermissions!
            }
            schedules={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              schedules!
            }
          />
        )}
      </Dialog>
    </Modal>
  )
}

export const useOpenCreateEmployeesSchedulesDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const props = useRef<Omit<CreateEmployeesSchedulesDialogProps, 'closeDialog'>>()

  const openCreateEmployeesSchedulesDialog = (
    dto: Omit<CreateEmployeesSchedulesDialogProps, 'closeDialog'>,
  ) => {
    props.current = dto

    openPopup()
  }

  const closeDialog = () => {
    closePopup()
  }

  const createEmployeesSchedulesDialog =
    isOpen && props.current ? (
      <CreateEmployeesSchedulesDialog closeDialog={closeDialog} {...props.current} />
    ) : null

  return {
    openCreateEmployeesSchedulesDialog,
    createEmployeesSchedulesDialog,
  }
}
