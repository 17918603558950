import { useBusinessModulesSettings } from '@expane/logic/modules'

export const SHOW_MANAGING_EMPLOYEE_TITLE = 'salary.refPercentage'

export const useShowSalaryIssuesTitleByBusinessSettings = <T extends { title: string }>(
  titles: T[],
) => {
  const { getModuleSetting } = useBusinessModulesSettings()

  const showManagingEmployee = getModuleSetting('managingEmployee')

  const tableTitlesByShowingManagingEmployee = !showManagingEmployee
    ? titles.filter(title => title.title !== SHOW_MANAGING_EMPLOYEE_TITLE)
    : [...titles]

  return { tableTitlesByShowingManagingEmployee, showManagingEmployee }
}
