export const calcReportItemsTotalPrice = <T extends { price: number }>(serviceReportItems: T[]) =>
  serviceReportItems.reduce((acc, value) => acc + value.price, 0)

export const groupReportItemsById = <T extends { id: number }>(reportItems: T[]) => {
  return reportItems.reduce((group, item) => {
    const { id } = item

    group[id] = group[id] ?? []
    group[id].push(item)

    return group
  }, {})
}

export const calcReportItemsCreditTotalPrice = <T extends { creditPrice: number }>(
  serviceReportItems: T[],
) => serviceReportItems.reduce((acc, value) => acc + value.creditPrice, 0)

export const calcReportItemsCreditTotalQuantity = <T extends { creditPrice: number }>(
  serviceReportItems: T[],
) => serviceReportItems.filter(item => item.creditPrice > 0).length
