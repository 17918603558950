import { TFunction } from 'i18next'
import {
  ServerTransactionByIdType,
  ServerTransactionInBookingType,
  ServerTransactionType,
  TRANSACTION_TYPES,
  TransactionInsertInput,
} from '@expane/data'
import { formatFullDate } from '@expane/date'
import { checkIfOptionalConsumablesPayment } from '../booking'
import { convertUnitValueFromServer } from '../product'
import { roundValue } from '../utils'

export const transformTransactionType = (type: number | undefined) =>
  Object.keys(TRANSACTION_TYPES)
    .map(key => TRANSACTION_TYPES[key])
    .find(transaction => transaction.id === type)?.name

export const getAdditionalInfoByTransactionType = (
  transaction: ServerTransactionType | ServerTransactionByIdType,
  t: TFunction,
) => {
  if (transaction.type === TRANSACTION_TYPES.operation.id)
    return t('transactionNames.movingBetweenAccounts')

  if (
    transaction.type === TRANSACTION_TYPES.salary.id &&
    transaction.startPeriod &&
    transaction.endPeriod
  )
    return `${formatFullDate(transaction.startPeriod)} - ${formatFullDate(transaction.endPeriod)}`

  if (transaction.type === TRANSACTION_TYPES.transportCosts.id)
    return `${t('transactionNames.invoice')} ${t('numberFormat', {
      value: transaction.movement?.number,
    })}`

  if (transaction.type === TRANSACTION_TYPES.productsPurchase.id)
    return transaction.movement?.supplier?.name

  if (transaction.type === TRANSACTION_TYPES.revenue.id) return transaction.revenueReason?.name

  if (transaction.type === TRANSACTION_TYPES.expenses.id)
    if (transaction.expensesReason === null) return t('transactionNames.commissionOfBank')
    else return transaction.expensesReason?.name

  if (
    transaction.type === TRANSACTION_TYPES.payment.id ||
    transaction.type === TRANSACTION_TYPES.refund.id
  )
    return getPaymentAndRefundTransactionAdditionalInfo(transaction, t)
  else return '-'
}

export const getPaymentAndRefundTransactionAdditionalInfo = (
  transaction: ServerTransactionInBookingType | ServerTransactionType | ServerTransactionByIdType,
  t,
) => {
  if (
    transaction.type === TRANSACTION_TYPES.payment.id &&
    transaction.typeVariation === TRANSACTION_TYPES.payment.variations.service.id
  )
    if (transaction.transactionsServices.length === 1)
      return transaction.transactionsServices[0].service.name
    else return t('transactionNames.servicesSales')

  if (
    transaction.type === TRANSACTION_TYPES.payment.id &&
    transaction.typeVariation === TRANSACTION_TYPES.payment.variations.product.id
  )
    if (transaction.bookingId) return t('transactionNames.consumables')
    else if (transaction.movement?.movementProducts?.length === 1)
      return transaction.movement?.movementProducts[0].product.name
    else return t('transactionNames.productsSales')

  if (
    transaction.type === TRANSACTION_TYPES.payment.id &&
    transaction.typeVariation === TRANSACTION_TYPES.payment.variations.subscription.id
  )
    if (transaction.transactionsCards.length === 1)
      return transaction.transactionsCards[0].card.cardTemplate.name
    else return t('transactionNames.subscriptionsSales')

  if (
    transaction.type === TRANSACTION_TYPES.payment.id &&
    transaction.typeVariation === TRANSACTION_TYPES.payment.variations.giftCard.id
  )
    if (transaction.transactionsCards.length === 1)
      return transaction.transactionsCards[0].card.cardTemplate.name
    else return t('transactionNames.giftCardsSales')

  if (
    transaction.type === TRANSACTION_TYPES.refund.id &&
    transaction.typeVariation === TRANSACTION_TYPES.refund.variations.refundFromAccount.id
  )
    if (checkIfOptionalConsumablesPayment(transaction)) return t('transactionNames.consumables')
    else return t('transactionNames.refundToClient')

  if (
    transaction.type === TRANSACTION_TYPES.refund.id &&
    transaction.typeVariation === TRANSACTION_TYPES.refund.variations.refundToClientAccount.id
  )
    return t('transactionNames.refundToClientAccount')

  if (
    transaction.type === TRANSACTION_TYPES.refund.id &&
    transaction.typeVariation === TRANSACTION_TYPES.refund.variations.refundBySubscription.id
  )
    return t('transactionNames.refundBySubscription')
  else return '-'
}

export const checkIsTransactionPaymentInCredit = <
  T extends { type: number; typeVariation?: number | null },
>(
  transaction: T,
) => Boolean(transaction.type === TRANSACTION_TYPES.paymentInCredit.id)

export const checkAreEveryTransactionPaymentInCredit = <
  T extends { type: number; typeVariation?: number | null }[],
>(
  transactions: T,
) =>
  transactions.every(transaction =>
    Boolean(transaction.type === TRANSACTION_TYPES.paymentInCredit.id),
  )

export const checkIsSomeTransactionPaymentInCredit = <
  T extends { type: number; typeVariation?: number | null }[],
>(
  transactions: T,
) =>
  transactions.some(transaction =>
    Boolean(transaction.type === TRANSACTION_TYPES.paymentInCredit.id),
  )

type TransactionType = 'service' | 'card' | 'product'

export const getQuantityByTransactionType = (
  type: TransactionType,
  quantity?: number,
  unit?: number,
): number => {
  if (type === 'product' && unit && quantity) {
    return convertUnitValueFromServer(quantity, unit)
  }
  if (type === 'service' && quantity) return quantity
  else return DEFAULT_QUANTITY
}

const DEFAULT_QUANTITY = 1

export const getDepositTransactions = ({
  clientBalance,
  amount,
  clientId,
  toAccountId,
  branchId,
}: {
  clientBalance: number
  amount: string
  clientId: number
  toAccountId: number
  branchId: number
}) => {
  const depositTransactions: Array<TransactionInsertInput> = []

  const convertedAmount = Number(amount.replace(',', '.'))

  // если сумма счёта клиента = 0 или больше, создаем обычную транзакцию пополнения счёта клиента
  if (clientBalance >= 0)
    depositTransactions.push({
      type: TRANSACTION_TYPES.deposit.id,
      amount: convertedAmount,
      clientId,
      toAccountId,
      typeVariation: TRANSACTION_TYPES.deposit.variations.depositWithoutPayment.id,
      branchId,
    })
  else {
    // переводим негативный баланс клиентов в позитивное число для вычеслений
    const convertedNegativeClientBalance = Math.abs(clientBalance)

    // проверяем или сумма пополнения больше суммы долга
    const differenceBetweenClientBalanceAndAmount = convertedAmount - convertedNegativeClientBalance

    if (differenceBetweenClientBalanceAndAmount > 0) {
      depositTransactions.push({
        type: TRANSACTION_TYPES.repaymentDeposit.id,
        amount: convertedNegativeClientBalance,
        clientId,
        toAccountId,
        typeVariation: TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByMoney.id,
        branchId,
      })
      depositTransactions.push({
        type: TRANSACTION_TYPES.deposit.id,
        amount: differenceBetweenClientBalanceAndAmount,
        clientId,
        toAccountId,
        typeVariation: TRANSACTION_TYPES.deposit.variations.depositWithoutPayment.id,
        branchId,
      })
    } else {
      depositTransactions.push({
        type: TRANSACTION_TYPES.repaymentDeposit.id,
        amount: convertedAmount,
        clientId,
        toAccountId,
        typeVariation: TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByMoney.id,
        branchId,
      })
    }
  }

  return depositTransactions
}

// подсчет денег на счету клиента: сюда должны прийти транзакции того клиента чей баланс мы считаем
export const calcClientBalance = <
  T extends {
    type: number
    typeVariation?: number | null
    amount: number
    cardId?: number | null
  },
>(
  transactionsByClient: T[],
): number => {
  return transactionsByClient.reduce((balance, transaction) => {
    if (
      transaction.type === TRANSACTION_TYPES.deposit.id ||
      transaction.type === TRANSACTION_TYPES.activateGiftCard.id ||
      (transaction.type === TRANSACTION_TYPES.refund.id &&
        (transaction.typeVariation ===
          TRANSACTION_TYPES.refund.variations.refundToClientAccount.id ||
          transaction.typeVariation ===
            TRANSACTION_TYPES.refund.variations.refundPaymentInCredit.id)) ||
      (transaction.type === TRANSACTION_TYPES.repaymentDeposit.id &&
        transaction.typeVariation !==
          TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByClientBalance.id)
    )
      return roundValue(balance + transaction.amount)
    if (
      ((transaction.type === TRANSACTION_TYPES.payment.id ||
        transaction.type === TRANSACTION_TYPES.paymentInCredit.id) &&
        transaction.cardId === null) ||
      transaction.type === TRANSACTION_TYPES.cashOut.id
    )
      return roundValue(balance - transaction.amount)

    return roundValue(balance)
  }, 0)
}

export interface TransactionWiThClientBalance extends ServerTransactionType {
  clientBalance: number
}
export const addClientBalanceToTransactions = (
  transactions: ServerTransactionType[],
): TransactionWiThClientBalance[] => {
  const resultTransactions: TransactionWiThClientBalance[] = []

  for (let index = 0; index < transactions.length; ++index) {
    const transaction = transactions[index]

    resultTransactions.unshift({
      ...transaction,
      clientBalance: calcClientBalance(transactions.slice(0, index + 1)),
    })
  }

  return resultTransactions
}
