import { useFetchStorages, useGetBranchDefaultStorageId } from '@expane/data'
import { Input } from '@expane/ui'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { WriteOffMovementProps } from 'widgets/MovementCreateDialogs/WriteOffMovementDialog'
import { StorageSelectDropdown } from 'widgets/StoragesSelectDropdown'

export const WriteOffMovementInfo: FC<WriteOffMovementProps> = ({ control }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId

  const { data: storages } = useFetchStorages(branchId)
  const { data: defaultStorageId } = useGetBranchDefaultStorageId(branchId)

  return (
    <div className="flex">
      <Controller
        control={control}
        name="movementNumber"
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Input
            required
            containerClassName="w-1/2 pr-2"
            label={`${t('invoice')}, ${t('numberFormat', { value: '' })}`}
            value={value}
            onChange={onChange}
            errorMessage={{
              isShown: Boolean(errors.movementNumber),
              text: t('formError.required'),
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="fromStorageId"
        rules={{ required: true }}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <StorageSelectDropdown
            label={t('fromStorage')}
            required
            className="w-1/2 pr-2"
            onSelectChange={onChange}
            value={value}
            storages={storages}
            defaultStorageId={defaultStorageId}
            errorMessage={{ isShown: Boolean(errors.fromStorageId), text: t('formError.required') }}
          />
        )}
      />
    </div>
  )
}
