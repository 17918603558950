import { gql } from 'graphql-request'

export const productBriefFragment = gql`
  fragment productBriefType on product {
    id
    name
    price
    groupId
    photo
    archived
  }
`

export const productFragment = gql`
  ${productBriefFragment}
  fragment productType on product {
    ...productBriefType
    costPrice
    vendorCode
    description
    barcode
    forSale
    criticalQuantity
    unit
    serviceProducts(where: { branchId: { _eq: $branchId } }) {
      serviceId
    }
    productGroup {
      id
      name
    }
  }
`
