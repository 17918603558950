import {
  createCurrentDate,
  getDate,
  getDay,
  getMonth,
  getYear,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isSameYear,
  set,
} from '@expane/date'
import i18next from 'i18next'
import { Dispatch, FC, SetStateAction } from 'react'
import Calendar, { CalendarTileProperties } from 'react-calendar'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import { getNewActiveStartDateOnBack, getNewActiveStartDateOnForward } from './logic'
import { QuickDateButtons } from './QuickDateButtons'

export type PickerType = 'date' | 'dateTime' | 'week' | 'month'
export type CalendarView = 'month' | 'year' | 'decade'

interface DatePickerProps {
  value: Date | undefined
  onChange: (date: Date) => void
  type: PickerType
  activeStartDate: Date
  setActiveStartDate: Dispatch<SetStateAction<Date>>
  view: CalendarView
  setView: Dispatch<SetStateAction<CalendarView>>
  closeDatePopup?: () => void
  className?: string
  showQuickButtons?: boolean
  disabled?: boolean
  timezone: string
}
export const DatePicker: FC<DatePickerProps> = ({
  value,
  onChange,
  type,
  activeStartDate,
  setActiveStartDate,
  view,
  setView,
  closeDatePopup,
  className,
  showQuickButtons,
  disabled,
  timezone,
}) => {
  const locale = i18next.language

  const handleChange = (date: Date | Date[]) => {
    if (disabled) return

    if (date instanceof Date) {
      closeDatePopup?.()
      onChange(
        set(value ?? createCurrentDate(timezone), {
          date: getDate(date),
          month: getMonth(date),
          year: getYear(date),
        }),
      )
    }
  }

  const handleChevronForward = () => {
    const newActiveStartDate = getNewActiveStartDateOnForward(activeStartDate, view)
    setActiveStartDate(newActiveStartDate)
  }

  const handleChevronBack = () => {
    const newActiveStartDate = getNewActiveStartDateOnBack(activeStartDate, view)
    setActiveStartDate(newActiveStartDate)
  }

  const getTileClassName = ({ date, activeStartDate, view }: CalendarTileProperties) => {
    let tileStyle = 'transition-all py-0.5 text-sm my-0.5'

    if (type === 'month') {
      if (value && isSameYear(activeStartDate, value)) {
        if (isSameMonth(value, date)) {
          return (
            tileStyle +
            (disabled
              ? ' bg-tile-disabled text-btn-primary rounded'
              : ' bg-primary-500 text-btn-primary rounded')
          )
        }
      }
    }

    if (value && isSameMonth(activeStartDate, value)) {
      if (
        type === 'week' &&
        isSameWeek(date, value ?? createCurrentDate(timezone), {
          weekStartsOn: 1,
        })
      ) {
        if (getDay(date) === 1) tileStyle += ' rounded-l'
        if (getDay(date) === 0) tileStyle += ' rounded-r'
        return (
          tileStyle +
          (disabled ? ' bg-tile-disabled text-btn-primary' : ' bg-primary-500 text-btn-primary')
        )
      }
      if (isSameDay(date, value ?? createCurrentDate(timezone)))
        return (
          tileStyle +
          (disabled
            ? ' bg-tile-disabled rounded text-btn-primary'
            : ' bg-primary-500 rounded text-btn-primary')
        )
    }

    if (!isSameMonth(date, activeStartDate) && view === 'month')
      return tileStyle + ' text-placeholder-color'

    return (
      tileStyle +
      (disabled ? ' text-placeholder-color rounded' : ' text-main-color rounded bg-hover')
    )
  }

  return (
    <div className={className}>
      <Calendar
        nextLabel={
          <IoChevronForward
            size="1.5rem"
            onClick={handleChevronForward}
            className={disabled ? 'text-gray-300' : 'text-primary-500'}
          />
        }
        next2Label={null}
        prevLabel={
          <IoChevronBack
            size="1.5rem"
            onClick={handleChevronBack}
            className={disabled ? 'text-gray-300' : 'text-primary-500'}
          />
        }
        prev2Label={null}
        view={view}
        value={value}
        activeStartDate={activeStartDate}
        onChange={handleChange}
        onDrillUp={() => {
          if (disabled) return
          if (view === 'month') setView('year')
          if (view === 'year') setView('decade')
        }}
        onClickMonth={value => {
          if (disabled) return
          if (type === 'month') {
            handleChange(value)
          } else {
            setActiveStartDate(value)
            setView('month')
          }
        }}
        onClickYear={value => {
          if (disabled) return
          setActiveStartDate(value)
          setView('year')
        }}
        showFixedNumberOfWeeks
        tileClassName={getTileClassName}
        formatMonthYear={(defaultLocale, date) =>
          date.toLocaleDateString(locale ?? defaultLocale, {
            month: 'long',
          }) + ` ${getYear(date)}`
        }
        formatShortWeekday={(defaultLocale, date) =>
          date.toLocaleDateString(locale ?? defaultLocale, {
            weekday: 'short',
          })
        }
        formatMonth={(defaultLocale, date) =>
          date.toLocaleDateString(locale ?? defaultLocale, {
            month: 'long',
          })
        }
        className={'ml-3 mt-3 mr-3 w-64' + (showQuickButtons ? '' : ' mb-3')}
      />
      {showQuickButtons && (
        <QuickDateButtons
          timezone={timezone}
          value={value}
          onChange={onChange}
          activeStartDate={activeStartDate}
          setActiveStartDate={setActiveStartDate}
          view={view}
          setView={setView}
          type={type}
          className="mt-3"
          closePopup={closeDatePopup}
          disabled={disabled}
        />
      )}
    </div>
  )
}
