import { gql } from 'graphql-request'

export const expaneInfoFragment = gql`
  fragment expaneInfoType on expaneInfo {
    id
    image
    titleEn
    titleUk
    titleRu
    descriptionEn
    descriptionUk
    descriptionRu
    percentageOfSatisfied
    activeUsers
    teamMembers
    companyGrowthPercentage
  }
`

export const expaneInfoCategoryFragment = gql`
  fragment expaneInfoCategoryType on expaneInfoCategory {
    id
    image
    titleEn
    titleUk
    titleRu
  }
`
