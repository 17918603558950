import { FC } from 'react'
import { CalendarColumn, CalendarContainer, CalendarTitleCell } from 'ui/Calendar'
import { generateRows } from 'logic/calendar'
import { getCalendarStartHour, getCalendarFinishHour } from '@expane/logic/calendar'
import { generateWeeklyColumns } from 'pages/BookingsPage/BookingsCalendar/logic'
import { CalendarCellWithPlanBillingCheck } from 'widgets/CalendarCellWithPlanBillingCheck'
import { ScheduleDto, ServerDynamicScheduleDateType, WeeklySchedule } from '@expane/data'
import { ScheduleCalendarColumn } from './Column'
import { HALF_HOUR_STEP, ScheduleDialogFormValues } from './logic'
import { Control, useWatch } from 'react-hook-form'

interface Props {
  control: Control<ScheduleDialogFormValues>
  pickedSchedule: ScheduleDto | undefined
  branchSchedule: WeeklySchedule
  defaultDynamicDates: ServerDynamicScheduleDateType[]
  pickedDate: Date
  scheduleEndDate?: Date
  isEditAllowed: boolean
  isEditPastAllowed: boolean
}

export const ScheduleCalendar: FC<Props> = ({
  pickedSchedule,
  branchSchedule,
  control,
  defaultDynamicDates,
  pickedDate,
  scheduleEndDate,
  isEditAllowed,
  isEditPastAllowed,
}) => {
  const pickedStartDate = useWatch({
    control,
    name: 'scheduleStartDate',
  })

  const startHour = Math.trunc(getCalendarStartHour(branchSchedule))
  const endHour = getCalendarFinishHour(branchSchedule)
  const rows = generateRows(startHour, endHour, HALF_HOUR_STEP)

  const columns = generateWeeklyColumns(pickedDate)

  return (
    <>
      <CalendarContainer fullWidth>
        <CalendarColumn className="w-32 border-r border-l border-calendar-cell bg-block">
          <CalendarTitleCell />

          {rows.map(row => (
            <CalendarCellWithPlanBillingCheck key={row.title} solidBorder small fullHour lastColumn>
              {row.title}
            </CalendarCellWithPlanBillingCheck>
          ))}
        </CalendarColumn>
        {columns.map((column, columnIndex) => (
          <ScheduleCalendarColumn
            key={column.id}
            control={control}
            column={column}
            columnIndex={columnIndex}
            rows={rows}
            isEditAllowed={isEditAllowed}
            isEditPastAllowed={isEditPastAllowed}
            last={columnIndex === columns.length - 1}
            pickedSchedule={pickedSchedule}
            pickedStartDate={pickedStartDate}
            branchSchedule={branchSchedule}
            startHour={startHour}
            endHour={endHour}
            defaultDynamicDates={defaultDynamicDates}
            scheduleEndDate={scheduleEndDate}
          />
        ))}
      </CalendarContainer>
    </>
  )
}
